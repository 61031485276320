import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import {ADDPROJECT, itemUpdateRemoveAddAC, getClassmatesDataAC} from '../../store/actions/profileActions';
import styled from 'styled-components';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';


const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 25%);

    @media(max-width: 1180px) {
        grid-template-columns: repeat(2, 50%);
    }

`;

const SaveButton = styled.button`
    border-radius: 32px;
    background:  ${({ active }) => (active ? "linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%)" : "grey")};
    margin: auto;
    text-align: center;
    width: 98px;
    padding: 8px;
    color: white;
    margin-right: 0;
    border: none;
`

class AddProject extends Component {

    state = {
        openContent : false,
        contentUrl: '',
        contentType: null,
        title: null,
        company: null,
        location: null,
        start_year: null,
        end_year: null,
        description: null,
        selected_students: []
    }

    handleChange = (e)=> {
        let students = this.state.selected_students;
        let new_list = [];
        let deleted_list = [];
        if (Array.isArray(e)) {
            e.map((x)=> {
                if (students.indexOf(x.value)==-1) {
                    students.push(x.value);
                    new_list.push(x.value);
                }
            });
            students = students.filter((skill)=> {
                let index = e.findIndex((x)=> {
                    return x.value == skill
                })
                if (index == -1) {
                    deleted_list.push(skill);
                    return false
                } else {
                    return true
                }
            })
        }

        this.setState({selected_students: students});

    }
    componentDidMount = () => {
        this.props.getClassmatesData();
    }

    onChangeHandler = (event, type)=> {
        event.stopPropagation();
        event.preventDefault();
        
        this.setState({[type] : event.target.value});
    }

    onFileChangeHandler = (event) => {
        this.setState({files: event.target.files})
    }

    onEndDateChange = (date) => {
        try {
            let dateFormat = moment(date).format('MMM yyyy').toString();
            
            if (this.state.start_time) {
                if (date < this.state.start_time) {
                    this.setState({ endTimeError: true, endTimeErrorMessage: 'end time cannot be lesser than start year' })
                } else {
                    this.setState({ end_time: date, end_year: dateFormat, endTimeError: false, endTimeErrorMessage: '' });

                }
            } else {
                if (dateFormat) {
                    this.setState({  endTimeError: true, endTimeErrorMessage: 'please select start year first' });
                }
            }
        } catch (error) {
            console.log(error);
        }
        this.forceUpdate()
    }

    onStartDateChange = (date) => {
        try {
            let dateFormat = moment(date).format('MMM yyyy').toString();
            
            if (this.state.end_time) {
                if (date > this.state.end_time) {
                    this.setState({ startTimeError: true, startTimeErrorMessage: 'start year cannot be greater than end year' })
                } else {
                    this.setState({ start_time: date, start_year: dateFormat, startTimeError: false, startTimeErrorMessage: '' });

                }
            } else {
                this.setState({ start_time: date, start_year: dateFormat, startTimeError: false, startTimeErrorMessage: '' });
            }
            
        } catch (error) {
            console.log(error);
        }
        this.forceUpdate()
    }

    closeProjectEditor = () => {
        this.setState({
            title: null,
            company: null,
            location: null,
            start_year: null,
            end_year: null,
            description: null,
            files: null,
            selected_students: []
        });
        this.props.clearStatus();
        this.props.close();
    }

    addProjectHandler = () => {
        const formdata = new FormData();
        formdata.append('title', this.state.title);
        formdata.append('company', this.state.company);
        formdata.append('location', this.state.location);
        formdata.append('description', this.state.description);
        formdata.append('from_date', this.state.start_year);
        formdata.append('to', this.state.end_year);
        formdata.append('team', JSON.stringify(this.state.selected_students));
        formdata.append('attachments', '');
        if (this.state.files && this.state.files.length != 0) {
            Array.from(this.state.files).map((file)=> {
              console.log(file)
              formdata.append('attachments', file);
            })
        }

        let item = {};
        item.type = ADDPROJECT;
        item.title = this.state.title;
        item.company = this.state.company;
        item.location = this.state.location;
        item.description = this.state.description;
        item.from_year = this.state.start_year;
        item.to_year = this.state.end_year;
        item.files = this.state.files;


        this.props.addProject(item, formdata);
    }
    

    render () {
        console.log(this.state);
        console.log(this.props);
        if (this.props.loading_classmates) {
            return <div>Loading...</div>
        } else if (!this.props.loading_classmates && this.props.error_loading_classmates) {
            return <div>Error loading classemates. Please try after some time!!!!</div>;
        }
        let options = this.props.classmates.map((mate)=> {
            let opt = {};
            opt['value'] = mate.id;
            opt['label'] = mate.name;
            return opt

        })

        let selected_options = [];
        if (this.state.selected_students && this.state.selected_students.length != 0) {
            selected_options = []
            selected_options = options.filter((opt)=> {
                return this.state.selected_students.includes(opt.value);
            })
        }
        return (
            <div style={{}}>
                <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '20px'}}>Add Project</div>

                <div style={{border: '1px solid #eee', borderRadius: '4px',  textAlign: 'left', padding: '10px', margin: '20px 0'}}>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Title : </div>
                        <div style={{width: '80%'}}>
                            <input onChange={(event)=>this.onChangeHandler(event, 'title')} value={this.state.title} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Company : </div>
                        <div style={{width: '80%'}}>
                            <input onChange={(event)=>this.onChangeHandler(event, 'company')} value={this.state.company} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Location : </div>
                        <div style={{width: '80%'}}>
                            <input onChange={(event)=>this.onChangeHandler(event, 'location')} value={this.state.location} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px', alignItems: 'flex-end'}}>
                        <div style={{marginRight: '20px', width: '50%', display: 'flex', flexDirection: 'row',}}>
                            <div style={{width: '40%', marginRight: '20px',textAlign: 'right'}}>Start Year : </div>
                            <div style={{width: '60%'}}>
                                <DatePicker
                                    selected={this.state.start_time}
                                    onChange={this.onStartDateChange}
                                    showMonthYearPicker
                                    dateFormat="MMM yyyy"
                                />
                                {/* <input onChange={(event)=>this.onChangeHandler(event, 'start_year')} value={this.state.start_year} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input> */}
                            </div>
                        </div>
                        <div style={{width: '50%',display: 'flex', flexDirection: 'row',}}>
                            <div style={{width: '40%', marginRight: '20px',textAlign: 'right'}}>End Year : </div>
                            <div style={{width: '60%'}}>
                                <DatePicker
                                    selected={this.state.end_time}
                                    onChange={this.onEndDateChange}
                                    showMonthYearPicker
                                    dateFormat="MMM yyyy"
                                />
                                {/* <input onChange={(event)=>this.onChangeHandler(event, 'end_year')} value={this.state.end_year} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input> */}
                            </div>
                        </div>
                        
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Description : </div>
                        <div style={{margin: '10px 0 20px auto', width: '80%'}}>
                            <textarea onChange={(event)=>this.onChangeHandler(event, 'description')} value={this.state.description} style={{width: '80%', height: '70px',border: '1px solid #bbb',borderRadius: '4px'}} required></textarea>
                        </div>
                    </div>
                    
                    <div style={{margin: '20px 10px', display: 'flex'}}>
                        <div style={{width: '20%', textAlign: 'right', margin: 'auto '}}>Select your team mates</div>
                        <div style={{width: '80%', margin: 'auto 20px'}}>
                            <Select style={{width: '80%'}}
                                value={selected_options}
                                onChange={this.handleChange}
                                options={options}
                                isMulti
                                isClearable
                            />
                        </div>
                        
                    </div>
                    {this.props.added_project_successfully? <div style={{color: 'green', textAlign: 'left'}}>Project Added Successfully.</div> : null}
                    
                    {this.props.added_project_successfully?  
                    <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto', marginTop: '25px'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeProjectEditor}>OK</div>
                    </div>
                    :<div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeProjectEditor}>Cancel</div>
                        <div>
                            <SaveButton active={this.state.title && this.state.title != ''  && this.state.company && this.state.company != '' && this.state.location && this.state.location != '' && this.state.start_year && this.state.end_year && moment(this.state.end_year) > moment(this.state.start_year) }  onClick={this.addProjectHandler}>Save</SaveButton>
                        </div>
                    </div>}
                </div>
                <style jsx scoped="true">{`
                    .react-datepicker-wrapper,
                    .react-datepicker__input-container,
                    .datePicker input {
                        width: 100%;
                        // border: 0;
                        height: 22px;
                        border-radius: 4px;
                    }
                
                
                `}</style>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.prof.loading,
        name: state.prof.name,
        error_adding_project: state.prof.error_adding_project,
        added_project_successfully: state.prof.added_project_successfully,
        classmates: state.prof.classmates,
        error_loading_classmates: state.prof.error_loading_classmates,
        classmates_loaded_successfully: state.prof.classmates_loaded_successfully,
        loading_classmates: state.prof.loading_classmates
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addProject: (item, formdata) => dispatch(itemUpdateRemoveAddAC(item, formdata)),
        clearStatus: () => dispatch({type:'CLEAR_ADD_PROJECT_STATUS'}),
        getClassmatesData: () => dispatch(getClassmatesDataAC())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddProject));