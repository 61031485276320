import { useEffect, useState } from 'react';
import { fetchDataFromApi } from '../helpers/Api';
const useFetch = url => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(null);
	const [error, setError] = useState(null);
	const [response, setResponse] = useState(null);
	const [message, setMessage] = useState('fetched fail');
	const [status, setStatus] = useState(null);

	useEffect(() => {
		setLoading('loading...');
		setData(null);
		setError(null);

		fetchDataFromApi(url)
			.then(res => {
				setLoading(false);
				setResponse(res);
				setData(res.data);
				setMessage(res.message)
				setStatus(res.status)
			})
			.catch(err => {
				setLoading(false);
				setError(`Something went wrong! -> ${err}`);
				setMessage(`Something went wrong! -> ${err}`);
				setStatus(`Something went wrong! -> ${err}`);
			});
	}, [url]);

	return { response, data, loading, error, message, status };
};

export default useFetch;
