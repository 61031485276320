import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
//import correct from 'public/../correct-icon.png';
import React from "react";
import ReactDOM from "react-dom";
import "./joinchallange.css";
import styled, { keyframes, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import ReactCountdownClock from "react-countdown-clock";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdOutlinePending } from "react-icons/md";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Ably from "ably/promises";
import { channel, realtime } from "./joinchallange";
import Chatwidget from "./chatwindow/chatroom";
import { AiFillCloseCircle } from "react-icons/ai";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { messages } from "./chatwindow/chatroom";
import * as joinchallenge from "../../store/actions/joinchallengeactions";
import Toast from "react-bootstrap/Toast";
import { IoMdArrowBack } from "react-icons/io";
import Studentspanel from "./otherteams";
import Modal from "react-bootstrap/Modal";
import { AiFillLock } from "react-icons/ai";
import { getLoggedInUser } from "../../helpers/Auth";
// import firebase from "firebase";
// import db from "./chatwindow/chatroom";
// import { getDatabase, ref, remove } from "firebase/database";
let getlocalstorage = localStorage.getItem("user");
getlocalstorage = JSON.parse(getlocalstorage);
class Leaderboard2 extends Component {
  state = {
    showdetails: null,
    showResults: false,
    showA: true,
    showTimer: true,
    newmsgrecieved: false,
    showquestiondetails: false,
    currentquestionid: this.props.current_questionid,
    deletechat: false,
  };
  openchatwidget = () => {
    this.setState({ showResults: true, newmsgrecieved: false });
    setTimeout(() => {
      document
        .getElementById("scrollintoview")
        .scrollIntoView({ behavior: "smooth" });
    }, 10);
  };
  closechatwidget = () =>
    this.setState({ showResults: false, newmsgrecieved: false });
  toggleShowA = () => this.setState({ showA: false });
  onFinished = () => this.setState({ showTimer: false });
  msgrecieved = () => {
    if (!this.state.showResults) {
      this.setState({ newmsgrecieved: true });
    }
  };
  viewdetails = (index) => {
    //   this.setState({count:this.state.count+1})

    // console.log("index" + index);
    this.setState({ showdetails: null });
    setTimeout(() => {
      this.setState({ showdetails: index });
    }, 100);
    if (index || index == 0) {
      this.setState({ showdetailsmodal: true });
    }
  };
  goBack = () => {
    let chatmessages = JSON.stringify(messages);
    let chatmessagesJsonB64 = btoa(chatmessages).toString("base64");
    const user = getLoggedInUser();
    if (
      this.props.results &&
      this.props.challengedetails.participation_type &&
      this.props.challengedetails.is_member
    ) {
      {
        this.props.teamdetails.members.map((val, key) => {
          if (val.student_id == user.user_id && val.is_captain) {
            this.props.SendChatData(
              this.props.challengedetails.id,
              chatmessagesJsonB64,
              this.props.teamdetails.id
            );
            this.setState({ deletechat: true });
          }
        });
      }
    }
    setTimeout(() => {
      window.history.back();
    }, 2000);
  };
  questiondetails = () => {
    this.setState({ showquestiondetails: true });
  };
  removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  }
  componentDidMount = () => {
    let chatmessages = JSON.stringify(messages);
    let chatmessagesJsonB64 = btoa(chatmessages).toString("base64");
    const user = getLoggedInUser();
    if (
      this.props.results &&
      this.props.challengedetails.participation_type &&
      this.props.challengedetails.is_member
    ) {
      {
        this.props.teamdetails.members.map((val, key) => {
          if (val.student_id == user.user_id && val.is_captain) {
            this.props.SendChatData(
              this.props.challengedetails.id,
              chatmessagesJsonB64,
              this.props.teamdetails.id
            );
          }
        });
      }
    }
  };

  // const avatars=["https://www.w3schools.com/howto/img_avatar2.png","https://www.w3schools.com/w3images/avatar2.png","https://www.w3schools.com/w3images/avatar6.png","https://www.w3schools.com/w3images/avatar5.png","https://www.w3schools.com/howto/img_avatar.png","https://www.w3schools.com/howto/img_avatar.png"]
  render() {
    // console.log(getlocalstorage["name"]);

    const colors = [
      "rgb(225 54 58 / 85%)",
      "rgb(72 103 246 / 83%)",
      "rgb(102 175 110 / 86%)",
      "rgb(221 165 57 / 86%)",
      "rgb(235 133 187)",
      "rgb(225 54 58 / 85%)",
      "rgb(72 103 246 / 83%)",
      "rgb(102 175 110 / 86%)",
      "rgb(221 165 57 / 86%)",
      "rgb(235 133 187)",
      "rgb(225 54 58 / 85%)",
      "rgb(72 103 246 / 83%)",
      "rgb(102 175 110 / 86%)",
      "rgb(221 165 57 / 86%)",
      "rgb(235 133 187)",
      "rgb(225 54 58 / 85%)",
      "rgb(72 103 246 / 83%)",
      "rgb(102 175 110 / 86%)",
      "rgb(221 165 57 / 86%)",
      "rgb(235 133 187)",
      "rgb(225 54 58 / 85%)",
      "rgb(72 103 246 / 83%)",
      "rgb(102 175 110 / 86%)",
      "rgb(221 165 57 / 86%)",
      "rgb(235 133 187)",
      "rgb(225 54 58 / 85%)",
      "rgb(72 103 246 / 83%)",
      "rgb(102 175 110 / 86%)",
      "rgb(221 165 57 / 86%)",
      "rgb(235 133 187)",
      "rgb(225 54 58 / 85%)",
      "rgb(72 103 246 / 83%)",
      "rgb(102 175 110 / 86%)",
      "rgb(221 165 57 / 86%)",
      "rgb(235 133 187)",
      "rgb(225 54 58 / 85%)",
      "rgb(72 103 246 / 83%)",
      "rgb(102 175 110 / 86%)",
      "rgb(221 165 57 / 86%)",
      "rgb(235 133 187)",
    ];
    // const avatars = [
    //   "https://cdn-icons-png.flaticon.com/512/476/476863.png",
    //   "https://cdn-icons-png.flaticon.com/512/1165/1165674.png",
    //   "https://cdn-icons-png.flaticon.com/512/1256/1256650.png",
    //   "https://cdn-icons-png.flaticon.com/512/3280/3280979.png",
    //   "https://cdn-icons-png.flaticon.com/512/7829/7829198.png",
    //   "https://cdn-icons-png.flaticon.com/512/3791/3791146.png",
    //   "https://cdn-icons-png.flaticon.com/512/3369/3369157.png",
    //   "https://cdn-icons-png.flaticon.com/512/2967/2967475.png",
    //   "https://cdn-icons-png.flaticon.com/512/476/476863.png",
    //   "https://cdn-icons-png.flaticon.com/512/1165/1165674.png",
    //   "https://cdn-icons-png.flaticon.com/512/1256/1256650.png",
    //   "https://cdn-icons-png.flaticon.com/512/3280/3280979.png",
    //   "https://cdn-icons-png.flaticon.com/512/7829/7829198.png",
    //   "https://cdn-icons-png.flaticon.com/512/3791/3791146.png",
    //   "https://cdn-icons-png.flaticon.com/512/3369/3369157.png",
    //   "https://cdn-icons-png.flaticon.com/512/2967/2967475.png",
    // ];
    const lineargradient = [
      "rgb(242 111 116 / 71%)",
      "#a7b5f6",
      "#9ce1a4",
      "#fbd793",
      "#fdcee8",
      "rgb(242 111 116 / 71%)",
      "#a7b5f6",
      "#9ce1a4",
      "#fbd793",
      "#fdcee8",
      "rgb(242 111 116 / 71%)",
      "#a7b5f6",
      "#9ce1a4",
      "#fbd793",
      "#fdcee8",
      "rgb(242 111 116 / 71%)",
      "#a7b5f6",
      "#9ce1a4",
      "#fbd793",
      "#fdcee8",
      "rgb(242 111 116 / 71%)",
      "#a7b5f6",
      "#9ce1a4",
      "#fbd793",
      "#fdcee8",
      "rgb(242 111 116 / 71%)",
      "#a7b5f6",
      "#9ce1a4",
      "#fbd793",
      "#fdcee8",
      "rgb(242 111 116 / 71%)",
      "#a7b5f6",
      "#9ce1a4",
      "#fbd793",
      "#fdcee8",
      "rgb(242 111 116 / 71%)",
      "#a7b5f6",
      "#9ce1a4",
      "#fbd793",
      "#fdcee8",
    ];
    const options = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
    const optionbgcolors = [
      "#f7d5b2",
      "#fee091",
      "#f1c7db",
      "#bdcaff",
      "#f7d5b2",
      "#fee091",
      "#f1c7db",
      "#bdcaff",
    ];
    let finalleaderboard_gif;
    if (this.props.results) {
      finalleaderboard_gif = "/gifs/animation_final_leaderboard.gif";
    } else {
      finalleaderboard_gif = "";
    }

    //  let hasimageoption = false;
    //  let question_data;
    //    this.props.data[0].questions.map((questiondata, index) => {
    //     if (questiondata.id==this.props.lastquestionid) {
    //      question_data = questiondata.question_data;

    //     }
    //    });
    //     question_data.map((optiondata, index) => {
    //       if (optiondata.option_img) {
    //         hasimageoption = true;
    //       }
    //     });
    return (
      <Maindiv style={{ backgroundImage: `url(${finalleaderboard_gif})` }}>
        {!this.props.results && this.state.showTimer ? (
          <div style={{ float: "right", marginRight: "50px" }}>
            {/*<span style={{opacity:"0.5",fontWeight:"700",marginRight:"-24px"}}>Time Left:-</span>*/}
            <ReactCountdownClock
              seconds={this.props.challengedetails.leaderBoard_timer}
              alpha={0.9}
              color="red"
              size={70}
              weight={5}
              showMilliseconds={false}
              onComplete={this.onFinished}
            />
          </div>
        ) : this.state.showTimer ? (
          <>
            <Toast
              style={{ position: "absolute", right: "0px" }}
              onClose={this.toggleShowA}
              show={this.state.showA}
            >
              <Toast.Header>
                <strong className="me-auto">Final Leaderboard</strong>
              </Toast.Header>
              <Toast.Body>
                Thank you for participating in the challenge
              </Toast.Body>
            </Toast>
            <div style={{ float: "left" }}>
              <button className="btn btn-primary" onClick={this.goBack}>
                <IoMdArrowBack style={{ fontSize: "20px" }} />
                Back
              </button>
            </div>
          </>
        ) : null}
        <h2 style={{ color: "red" }}>{this.props.challengedetails.title}</h2>
        {/* <h3 style={{fontWeight:"700"}}>01:00</h3>*/}
        <h3>Leaderboard</h3>
        <div
          className="row"
          style={{ padding: "18px", justifyContent: "center" }}
        >
          {/* <div className="col-lg-1">
            <button
              className="btn btn-primary"
              style={{ fontSize: "13px", padding: "1px" }}
              onClick={this.questiondetails}
            >
              Last Question details
            </button>
          </div> */}
          <div
            className="col-lg-10 col-md-12"
            style={{ height: "464px", overflow: "auto" }}
          >
            {this.props.data.map((students, index) => {
              let height = 0;
              let fontsize = 0;
              let questionsfontsize = 0;
              let animationprop = "";
              let rankfontsize,
                avatarheight,
                avatarwidth,
                student_score_font_size;
              let total_time_taken = 0;
              if (index == 0) {
                height = 65;
                fontsize = 20;
                rankfontsize = 40;
                animationprop = true;
                questionsfontsize = 10;
                avatarwidth = 60;
                avatarheight = 63;
                student_score_font_size = 27;
              } else {
                height = 55;
                fontsize = 15;
                rankfontsize = 30;
                questionsfontsize = 9;
                animationprop = false;
                avatarwidth = 54;
                avatarheight = 47;
                student_score_font_size = 22;
              }
              const user = getLoggedInUser();
              return (
                <>
                  {(this.props.challengedetails.participation_type &&
                    this.props.challengedetails.is_member &&
                    this.props.teamdetails.id == students.team_id) ||
                  (!this.props.challengedetails.participation_type &&
                    this.props.challengedetails.is_member &&
                    user.user_id == students.student_id) ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          fontWeight: "600",
                        }}
                      >
                        {this.props.challengedetails.participation_type
                          ? "Your Team"
                          : "Your Rank"}
                      </div>
                      <Rotate
                        className="col-lg-12 leaderboardrow"
                        primary={index + 1}
                        top={null}
                        style={{
                          marginBottom: "10px",
                          background:
                            "linear-gradient(45deg, #ebc8af, #f1b2c5)",
                          height: `${height}px`,
                          color: "black",
                        }}
                        key={index}
                      >
                        <div
                          className="col-lg-1 col-md-1 col-sm-2"
                          style={{ fontSize: `${rankfontsize}px` }}
                        >
                          {index + 1}
                        </div>
                        <div
                          className="col-lg-9 col-md-6 col-sm-6"
                          style={{ fontSize: `${fontsize}px` }}
                        >
                          <div>
                            {this.props.challengedetails.participation_type
                              ? students.team_name
                              : students.student_name
                              ? students.student_name
                              : students.faculty_name}
                          </div>
                          <div
                            className="questiondetails"
                            style={{ fontSize: `${questionsfontsize}px` }}
                          >
                            {students.questions
                              .slice(
                                0,
                                parseInt(this.props.current_pos) + parseInt(1)
                              )
                              .map((questiondetails, index) => {
                                let questionstatus = "";
                                if (questiondetails.status == "correct") {
                                  questionstatus =
                                    process.env.PUBLIC_URL +
                                    "/correct-icon.png";
                                } else if (questiondetails.status == "wrong") {
                                } else {
                                  questionstatus = "";
                                }
                                total_time_taken =
                                  parseInt(total_time_taken) +
                                  parseInt(questiondetails.time_taken);
                                return (
                                  <span>
                                    <div style={{ color: "black" }}>
                                      {index + 1}
                                    </div>
                                    <div style={{ paddingTop: "2px" }}>
                                      {questiondetails.is_correct === 0 ? (
                                        <span
                                          className="leaderboardquestionstatus"
                                          style={{ backgroundColor: "red" }}
                                        ></span>
                                      ) : questiondetails.is_correct == 1 ? (
                                        <span
                                          className="leaderboardquestionstatus"
                                          style={{ backgroundColor: "green" }}
                                        ></span>
                                      ) : (
                                        <span
                                          className="leaderboardquestionstatus"
                                          style={{ backgroundColor: "grey" }}
                                        ></span>
                                      )}
                                    </div>
                                  </span>
                                );
                              })}
                          </div>
                        </div>
                        <div
                          className="col-lg-1 col-md-3 col-sm-2"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span
                            style={{ fontSize: `${student_score_font_size}px` }}
                          >
                            {students.total_correct_answers}/
                            {students.questions.length}
                          </span>
                          <button
                            className="btn btn-success"
                            style={{
                              padding: "1px",
                              height: "30px",
                              fontSize: "10px",
                              width: "70px",
                            }}
                            onClick={() => this.viewdetails(index)}
                          >
                            View question details
                          </button>
                        </div>
                        <div
                          className="col-lg-1 col-md-2 col-sm-2"
                          style={{
                            fontSize: "12px",
                            padding: "17px 0px 0px 13px",
                          }}
                        >
                          Time taken:- {`${students.total_time_taken} Sec`}
                        </div>
                      </Rotate>
                    </>
                  ) : (
                    <div></div>
                  )}
                </>
              );
            })}

            {this.props.data.map((students, index) => {
              let height = 0;
              let fontsize = 0;
              let questionsfontsize = 0;
              let animationprop = "";
              let rankfontsize,
                avatarheight,
                avatarwidth,
                student_score_font_size;
              let total_time_taken = 0;
              if (index == 0) {
                height = 65;
                fontsize = 20;
                rankfontsize = 40;
                animationprop = true;
                questionsfontsize = 10;
                avatarwidth = 60;
                avatarheight = 63;
                student_score_font_size = 27;
              } else {
                height = 55;
                fontsize = 15;
                rankfontsize = 30;
                questionsfontsize = 9;
                animationprop = false;
                avatarwidth = 54;
                avatarheight = 47;
                student_score_font_size = 22;
              }
              let top;
              if (index == 1) {
                top = 325;
              } else if (index == 6) {
                top = -325;
              } else if (index == 2) {
                top = 130;
              } else if (index == 4) {
                top = -65;
              } else if (index == 3) {
                top = -65;
              } else {
                top = "";
              }
              const user = getLoggedInUser();
              return (
                <>
                  {index == 0 && this.props.data.length > 1 ? (
                    <div
                      style={{
                        display: "flex",
                        fontWeight: "600",
                      }}
                    >
                      {this.props.challengedetails.participation_type
                        ? "Other Teams"
                        : "Other participants"}
                    </div>
                  ) : null}
                  {(this.props.challengedetails.participation_type &&
                    this.props.teamdetails.id != students.team_id) ||
                  (!this.props.challengedetails.participation_type &&
                    user.user_id != students.student_id) ? (
                    <>
                      <Rotate
                        className="col-lg-12 leaderboardrow"
                        primary={index + 1}
                        top={null}
                        style={{
                          marginBottom: "10px",
                          background:
                            "linear-gradient(45deg, #ebc8af, #f1b2c5)",
                          height: `${height}px`,
                          color: "black",
                        }}
                        key={index}
                      >
                        <div
                          className="col-lg-1 col-md-1 col-sm-2"
                          style={{ fontSize: `${rankfontsize}px` }}
                        >
                          {index + 1}
                        </div>
                        <div
                          className="col-lg-9 col-md-6 col-sm-6"
                          style={{ fontSize: `${fontsize}px` }}
                        >
                          <div>
                            {this.props.challengedetails.participation_type
                              ? students.team_name
                              : students.student_name
                              ? students.student_name
                              : students.faculty_name}
                          </div>
                          <div
                            className="questiondetails"
                            style={{ fontSize: `${questionsfontsize}px` }}
                          >
                            {students.questions
                              .slice(
                                0,
                                parseInt(this.props.current_pos) + parseInt(1)
                              )
                              .map((questiondetails, index) => {
                                let questionstatus = "";
                                if (questiondetails.status == "correct") {
                                  questionstatus =
                                    process.env.PUBLIC_URL +
                                    "/correct-icon.png";
                                } else if (questiondetails.status == "wrong") {
                                } else {
                                  questionstatus = "";
                                }
                                total_time_taken =
                                  parseInt(total_time_taken) +
                                  parseInt(questiondetails.time_taken);
                                return (
                                  <span>
                                    <div style={{ color: "black" }}>
                                      {index + 1}
                                    </div>
                                    <div style={{ paddingTop: "2px" }}>
                                      {questiondetails.is_correct === 0 ? (
                                        <span
                                          className="leaderboardquestionstatus"
                                          style={{ backgroundColor: "red" }}
                                        ></span>
                                      ) : // <AiOutlineCloseCircle
                                      //   title={`Time taken - ${questiondetails.time_taken} seconds`}
                                      //   style={{ color: "red", fontSize: "20px" }}
                                      // />
                                      questiondetails.is_correct == 1 ? (
                                        <span
                                          className="leaderboardquestionstatus"
                                          style={{ backgroundColor: "green" }}
                                        ></span>
                                      ) : (
                                        // <AiOutlineCheckCircle
                                        //   title={`Time taken - ${questiondetails.time_taken} seconds`}
                                        //   style={{ color: "green", fontSize: "20px" }}
                                        // />
                                        <span
                                          className="leaderboardquestionstatus"
                                          style={{ backgroundColor: "grey" }}
                                        ></span>
                                        // <MdOutlinePending
                                        //   style={{ color: "grey", fontSize: "20px" }}
                                        // />
                                      )}
                                    </div>
                                  </span>
                                );
                              })}
                          </div>
                        </div>
                        <div
                          className="col-lg-1 col-md-3 col-sm-2"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span
                            style={{ fontSize: `${student_score_font_size}px` }}
                          >
                            {students.total_correct_answers}/
                            {students.questions.length}
                          </span>

                          <button
                            className="btn btn-success"
                            style={{
                              padding: "1px",
                              height: "30px",
                              fontSize: "10px",
                              width: "70px",
                            }}
                            onClick={() => this.viewdetails(index)}
                          >
                            View question details
                          </button>
                        </div>
                        <div
                          className="col-lg-1 col-md-2 col-sm-2"
                          style={{
                            fontSize: "12px",
                            padding: "17px 0px 0px 13px",
                          }}
                        >
                          Time taken:- {`${students.total_time_taken} Sec`}
                          {/* <img
                        src={avatars[index]}
                        style={{
                          width: `${avatarwidth}px`,
                          height: `${avatarheight}px`,
                          borderRadius: "10px",
                          marginTop: "0px",
                        }}
                      /> */}
                        </div>
                      </Rotate>
                    </>
                  ) : null}
                </>
              );
            })}
          </div>
          {this.state.showdetails || this.state.showdetails == 0 ? (
            <>
              {this.props.data[this.state.showdetails].questions.map(
                (questionsdata, index) => {
                  return (
                    <>
                      {questionsdata.id == this.state.currentquestionid ? (
                        <Modal
                          size="lg"
                          show={this.state.showdetailsmodal}
                          onHide={() =>
                            this.setState({ showdetailsmodal: false })
                          }
                          aria-labelledby="example-modal-sizes-title-sm"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm">
                              Last attempted question details
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {/* <div style={{ fontSize: "20px", fontWeight: "700" }}>
                Q{this.props.current_pos + 1}
              </div> */}
                              <div
                                className="questionsection_review"
                                style={{ display: "flex", width: '100%' }}
                              >
                                <div className="classdiv" dir="auto">
                                  <span role="heading" aria-level="1">
                                    {this.removeTags(
                                      questionsdata.question_data.question
                                    )}
                                  </span>
                                  {questionsdata.question_data.question_img ? (
                                    <span role="heading" aria-level="1">
                                      <img
                                        src={
                                          questionsdata.question_data
                                            .question_img
                                        }
                                        alt="question"
                                        style={{
                                          height: "56px",
                                          width: "max-content",
                                        }}
                                      />
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            {questionsdata.question_data.field_type == 0 ? (
                              <div className="answersdiv_review">
                                {questionsdata.question_data.options.map(
                                  (optiondetails, index) => {
                                    let is_correct_option;
                                    let is_correct_option_selected;
                                    if (optiondetails.is_correct === 1) {
                                      is_correct_option = true;
                                    }
                                    if (
                                      optiondetails.id ==
                                        questionsdata.question_data
                                          .answered_option &&
                                      !optiondetails.is_correct
                                    ) {
                                      // console.log("is selected option");
                                      is_correct_option_selected = true;
                                    }
                                    return (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            color="#e21b3c"
                                            // onClick={() => this.optionselected(id)}
                                            className="answermaindiv"
                                            // style={{ pointerEvents: none }}
                                          >
                                            <div className="">
                                              <div
                                                className="card-icon__icon"
                                                style={{
                                                  background: `${optionbgcolors[index]}`,
                                                  opacity: "0.9",
                                                }}
                                              >
                                                {options[index]}
                                              </div>
                                            </div>

                                            <span
                                              aria-live="off"
                                              dir="auto"
                                              className="answertext"
                                              style={{ fontSize: "15px" }}
                                            >
                                              <span>
                                                {this.removeTags(
                                                  optiondetails.option_name
                                                )}
                                              </span>
                                            </span>
                                            {is_correct_option ? (
                                              <AiOutlineCheckCircle
                                                style={{
                                                  color: "green",
                                                  fontSize: "40px",
                                                }}
                                              />
                                            ) : null}
                                            {is_correct_option_selected ? (
                                              <AiFillLock
                                                style={{
                                                  color: "red",
                                                  fontSize: "40px",
                                                }}
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            ) : null}
                          </Modal.Body>
                        </Modal>
                      ) : null}
                    </>
                  );
                }
              )}
            </>
          ) : null}
          {this.props.challengedetails.participation_type &&
          this.props.challengedetails.is_member == 1 &&
          this.props.challengedetails.is_viewer == 0 ? (
            <>
              {this.props.data.map((students, index) => {
                let detailsreport;
                if (
                  this.props.challengedetails.participation_type &&
                  this.props.teamdetails.id == students.team_id
                ) {
                  detailsreport = true;
                }
                return (
                  <>
                    {this.props.challengedetails.participation_type &&
                    this.props.teamdetails.id == students.team_id ? (
                      <>
                        {/* <div className="col-lg-1"></div>
                        <div className="col-lg-3 detailedreport showdetailedreport">
                          <div className="teamreport">Your team stats</div>
                          <p className="teamstat">Current Rank:{index + 1}</p>
                          {/* <p className="teamstat">Speed: Optimal</p>
                        <p className="teamstat">Team Engagement: Low</p> */}
                        {/* <div className="questionstat">
                            Last attempted question stat:
                          </div>
                          <p className="teamstat">
                            Result :-
                            {students.questions[students.questions.length - 1]
                              .is_correct === 1
                              ? "Correct"
                              : "Wrong"}
                          </p>
                          <div className="questionstat">Question List</div>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {students.questions.map((students, index) => {
                              return (
                                <div
                                  className="numberCircle"
                                  style={{
                                    background: `${
                                      students.is_correct == 1
                                        ? "#0080008c"
                                        : students.is_correct === 0
                                        ? "#ff000094"
                                        : "grey"
                                    }`,
                                  }}
                                >
                                  {index + 1}
                                </div>
                              );
                            })}
                          </div>
                        </div> */}
                      </>
                    ) : !this.props.challengedetails.participation_type &&
                      getlocalstorage["name"] == students.student_name ? (
                      <>
                        {/* <div className="col-lg-1"></div>
                        <div className="col-lg-3 detailedreport showdetailedreport">
                          <div className="teamreport">Your stats</div>
                          <p className="teamstat">Current Rank:{index + 1}</p>
                          {/* <p className="teamstat">Speed: Optimal</p>
                        <p className="teamstat">Team Engagement: Low</p> */}
                        {/* <div className="questionstat">
                            Last attempted question stat:
                          </div>
                          <p className="teamstat">
                            Result :-
                            {students.questions[students.questions.length - 1]
                              .is_correct === 1
                              ? "Correct"
                              : "Wrong"}
                          </p>
                          <div className="questionstat">Question List</div>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {students.questions.map((students, index) => {
                              return (
                                <div
                                  className="numberCircle"
                                  style={{
                                    background: `${
                                      students.is_correct == 1
                                        ? "#0080008c"
                                        : students.is_correct === 0
                                        ? "#ff000094"
                                        : "grey"
                                    }`,
                                  }}
                                >
                                  {index + 1}
                                </div>
                              );
                            })}
                          </div>
                        </div> */}
                      </>
                    ) : null}
                  </>
                );
              })}
            </>
          ) : null}
        </div>
        {this.props.challengedetails.participation_type &&
        this.props.challengedetails.is_member == 1 &&
        this.props.challengedetails.is_viewer == 0 ? (
          <>
            <div
              style={{
                cursor: "pointer",
                position: "absolute",
                bottom: "20px",
                right: "17px",
              }}
              onClick={this.openchatwidget}
              title={`Chat with team`}
            >
              {this.state.newmsgrecieved ? (
                <img
                  src="/gifs/new-message-recieved.gif"
                  style={{
                    position: "absolute",
                    top: "-15px",
                    left: "-39px",
                    width: "41px",
                    borderRadius: "20px",
                  }}
                ></img>
              ) : null}
              <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
              {/* <div>{`Team`}</div> */}
            </div>

            <div
              className={this.state.showResults ? "fadeIn" : "fadeOut"}
              id="supportmonboxid"
            >
              <div
                className="supportmonbox_head col-md-12 col-sm-12"
                id="supbox"
                style={{ display: "block", height: "41px" }}
              >
                <div className="col-xs-1" style={{ float: "right" }}>
                  <AiFillCloseCircle
                    onClick={this.closechatwidget}
                    style={{ fontSize: "26px", cursor: "pointer" }}
                  />
                </div>
              </div>
              {this.state.deletechat ? (
                <Chatwidget
                  roomid={`${this.props.challengedetails.id}_${this.props.teamdetails.id}`}
                  foundnewmsg={this.msgrecieved}
                  deletechat={true}
                />
              ) : (
                <Chatwidget
                  roomid={`${this.props.challengedetails.id}_${this.props.teamdetails.id}`}
                  foundnewmsg={this.msgrecieved}
                  deletechat={false}
                />
              )}
            </div>
          </>
        ) :this.props.challengedetails.is_member == 0 &&
        this.props.challengedetails.is_viewer == 1  ?
        (
          <>
            <div
              style={{
                float: "right",
                cursor: "pointer",
                position: "relative",
                bottom: "50px",
              }}
              onClick={this.openchatwidget}
              title={`Chat with team`}
            >
              {this.state.newmsgrecieved ? (
                <img
                  src="/gifs/new-message-recieved.gif"
                  style={{
                    position: "absolute",
                    top: "-15px",
                    left: "-39px",
                    width: "41px",
                    borderRadius: "20px",
                  }}
                ></img>
              ) : null}
              <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
              {/* <div>{`Team`}</div> */}
            </div>

            <div
              className={this.state.showResults ? "fadeIn" : "fadeOut"}
              id="supportmonboxid"
            >
              <div
                className="supportmonbox_head col-md-12 col-sm-12"
                id="supbox"
                style={{ display: "block", height: "41px" }}
              >
                <div className="col-xs-1" style={{ float: "right" }}>
                  <AiFillCloseCircle
                    onClick={this.closechatwidget}
                    style={{ fontSize: "26px", cursor: "pointer" }}
                  />
                </div>
              </div>

              <Chatwidget
                roomid={`${this.props.challengedetails.id}_Viewer`}
                foundnewmsg={this.msgrecieved}
              />
            </div>
          </>
        )
        : null}
        {this.props.challengedetails.participation_type &&
        this.props.challengedetails.is_member ? (
          <Studentspanel data={this.props.otherteams} />
        ) : null}
      </Maindiv>
    );
  }
}
const Maindiv = styled.div`
  height: auto;
  padding: 12px;
  @media (max-width: 768px) {
    height: 530px;
  }
  @media (max-width: 1024px) {
    height: auto;
  }
`;
// const Button = styled.button`
//   background: transparent;
//   border-radius: 3px;
//   border: 2px solid palevioletred;
//   color: palevioletred;
//   margin: 0.5em 1em;
//   padding: 0.25em 1em;
// `;
const movediv = (top) => keyframes`
  from {
   
   top:0px;
  }

  to {
  
   top:${top}px;
   margin:5px;
`;
const movediv1 = keyframes`
  from {
   
   top:0px;
  }

  to {
  
   top:-200px;
  }
`;
const Rotate = styled.div`
  ${(props) =>
    props.top &&
    css`
      animation-delay: 5s;
      animation-fill-mode: forwards;
      animation: ${(props) => movediv(props.top)} 5s forwards;
    `}
`;
function mapStateToProps(state) {
  return {
    challengedetails: state.joinchallenge.challangedetails,
    teamdetails: state.joinchallenge.teamdetails,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    SendChatData: (weekly_challenge_id, messages, team_id) =>
      dispatch(
        joinchallenge.SendChatData(weekly_challenge_id, messages, team_id)
      ),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Leaderboard2)
);
//export default Leaderboard2;
