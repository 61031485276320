import styled from '@emotion/styled';
import { Box, Typography, useTheme } from '@mui/material';
import React from 'react'
import Equation from '../../../../utils';

// choice -> right or wrong


const McqChoice = ({data, choice, title, variant = 'MCQ' }) => {

    const theme = useTheme()

    const ChoiceWrapper = styled('div')(({ theme }) => ({
        borderRadius: '4px',
        background: choice === 'right' ? theme.palette.success[200] : choice === 'wrong' ? theme.palette.extraredpalette[100] : theme.palette.grey[100],
        color: choice === 'right' ? theme.palette.success[800] : choice === 'wrong' ? theme.palette.extraredpalette[700] : theme.palette.grey[800],
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        gap: '10px',
        height: '60px',
        overflowY: 'scroll'

    }));


    const Choice2 = styled('div')(({ theme }) => ({
        width: '100%',
        height: '188px',
        borderRadius: '4px',
        border: `1px solid ${theme.palette.grey[200]}`,
    }));

    const ChoiceImage2 = styled('div')(({ theme }) => ({
        width: '100%',
        height: 'calc(100% - 50px)',
        paddingTop: '10px',
        img: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
        }
    }));


    const ChoiceWrapper2 = styled('div')(({ theme }) => ({
        width: '100%',
        height: '50px',
        background: choice === 'right' ? theme.palette.success[200] : choice === 'wrong' ? theme.palette.extraredpalette[100] : theme.palette.grey[100],
        color: choice === 'right' ? theme.palette.success[800] : choice === 'wrong' ? theme.palette.extraredpalette[700] : theme.palette.grey[800],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '10px'

    }));

    return (
        <>

            {variant === 'MCQ' ? <ChoiceWrapper>
                <Typography component={'p'} sx={{
                    fontFamily: 'Poppins-Medium',
                    fontSize: '14px',
                }}><Equation equation={title}  math_type={title?.includes('$') ? 1 : 0} /></Typography>
                {/* right svg */}
                {
                    choice === 'right' && <Box sx={{
                        width: 20,
                        height: 20,
                        svg: {
                            path: {
                                stroke: (theme) => theme.palette.success[800]
                            }
                        }
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10.0007 17.9167C14.3548 17.9167 17.9173 14.3542 17.9173 10C17.9173 5.64587 14.3548 2.08337 10.0007 2.08337C5.64648 2.08337 2.08398 5.64587 2.08398 10C2.08398 14.3542 5.64648 17.9167 10.0007 17.9167Z" stroke={theme.palette.success[800]} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.63477 10.0001L8.87518 12.2405L13.3639 7.75964" stroke={theme.palette.success[800]} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Box>
                }
                {/* wrong svg */}
                {
                    choice === 'wrong' && <Box sx={{
                        width: 20,
                        height: 20,
                        svg: {
                            path: {
                                stroke: theme => theme.palette.extraredpalette[700]
                            }
                        }
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10.0007 17.9166C14.3548 17.9166 17.9173 14.3541 17.9173 9.99998C17.9173 5.64581 14.3548 2.08331 10.0007 2.08331C5.64648 2.08331 2.08398 5.64581 2.08398 9.99998C2.08398 14.3541 5.64648 17.9166 10.0007 17.9166Z" stroke={theme.palette.extraredpalette[700]} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.75977 12.2404L12.2406 7.75958" stroke={theme.palette.extraredpalette[700]} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.2406 12.2404L7.75977 7.75958" stroke={theme.palette.extraredpalette[700]} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Box>
                }
            </ChoiceWrapper> :
                <>
        <Choice2>
            <ChoiceImage2><img src={data.option_img} alt='DemoImageChoice' /></ChoiceImage2>
            <ChoiceWrapper2>

                <Typography component={'p'} sx={{
                    fontFamily: 'Poppins-Medium',
                    fontSize: '14px',
                }}>{title}</Typography>
                {/* right svg */}
                {
                    choice === 'right' && <Box sx={{
                        width: 20,
                        height: 20,
                        svg: {
                            path: {
                                stroke: (theme) => theme.palette.success[800]
                            }
                        }
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10.0007 17.9167C14.3548 17.9167 17.9173 14.3542 17.9173 10C17.9173 5.64587 14.3548 2.08337 10.0007 2.08337C5.64648 2.08337 2.08398 5.64587 2.08398 10C2.08398 14.3542 5.64648 17.9167 10.0007 17.9167Z" stroke={theme.palette.success[800]} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.63477 10.0001L8.87518 12.2405L13.3639 7.75964" stroke={theme.palette.success[800]} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Box>
                }
                {/* wrong svg */}
                {
                    choice === 'wrong' && <Box sx={{
                        width: 20,
                        height: 20,
                        svg: {
                            path: {
                                stroke: theme => theme.palette.extraredpalette[700]
                            }
                        }
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10.0007 17.9166C14.3548 17.9166 17.9173 14.3541 17.9173 9.99998C17.9173 5.64581 14.3548 2.08331 10.0007 2.08331C5.64648 2.08331 2.08398 5.64581 2.08398 9.99998C2.08398 14.3541 5.64648 17.9166 10.0007 17.9166Z" stroke={theme.palette.extraredpalette[700]} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.75977 12.2404L12.2406 7.75958" stroke={theme.palette.extraredpalette[700]} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.2406 12.2404L7.75977 7.75958" stroke={theme.palette.extraredpalette[700]} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Box>
                }

            </ChoiceWrapper2>
        </Choice2>
                </>
            }

        </>
    )
}

export default McqChoice