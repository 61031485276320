import React, { useState } from 'react'
import Grid from '@mui/system/Unstable_Grid';
import McqChoice from './../McqChoice';
import { Box, Stack } from '@mui/material';
import DummyQuestionImage from './../../../../../assets/remedial/images/dummyQuestionImage.png'
import styled from '@emotion/styled';

const McqChoiceGrid = ({ data }) => {
    // const [selectedAnswer, setSelectedAnswer] = useState();

    const dummyArray = [{ title: 'Enter your option 1', choice: 'right' },
    { title: 'Enter your option 2', choice: null },
    { title: 'Enter your option 3', choice: null },
    { title: 'Enter your option 4', choice: 'wrong' },
    ]

    const ImageBox = styled('div')(({ theme }) => ({
        width: '100%',
        height: '97px',
        img: {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        },
        borderRadius: '4px',
        borderWidth: '1px',
        borderColor: theme.palette.grey[200],
        borderStyle: 'solid'
    }));


    const handleQuestionChoices = (selected, rightAnswer) => {
        // right wrong none
        if (selected === 1 && rightAnswer) {
            return 'right'
        }
        if (selected === 1 && !rightAnswer) {
            return 'wrong'
        }
        if (rightAnswer) {
            return 'right'
        }
        else {
            return 'none'
        }
    }


    return (
        <>
            <Stack gap={'20px'}>
                {/* {console.log(data, 'aasdfg')} */}

                <Grid className='mcq-grid' container spacing={'20px'} padding={0}>
                    {data.question_img &&<ImageBox> <img src={data.question_img} alt='DemoImageChoice' /></ImageBox>}
                    {data.test_questions_options.map((item, index) => {
                        return <Grid xs={6}><McqChoice data={item} choice={handleQuestionChoices(item.selected_option_id, item.is_answer)} title={item.name} variant={item.option_img ? 'photoMCQ' : 'MCQ'} /></Grid>
                    })
                    }
                </Grid>

            </Stack>
        </>
    )
}

export default McqChoiceGrid