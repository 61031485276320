import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react';
import RemedialPathWrapper from '../RemedialPathWrapper/RemedialPathWrapper';
import BannerMovingAnimation from './BannerMovingAnimation';
import Logo from './../../../../assets/remedial/svg/Edwisely-white-logo.svg';
import { Box } from '@mui/material';
import useFetch from '../../../../hooks/useFetch';
import { useRemedialContext } from '../../hooks/useRemedial';
import { timerHelper } from '../../../../helpers/timerHelper';
import { useParams } from 'react-router-dom';
import ThemeSwitchButton from '../../../../components/common/ThemeSwitchButton/ThemeSwitchButton';

const Banner = styled('div')(({ theme }) => ({
	width: '100vw',
	height: '254px',
	minHeight: '254px',
	position: 'relative',
	// background: theme.palette.externalInfo[200],
	zIndex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	justifyContent: 'center',
	overflowX: 'scroll'
}));

const RemedialBanner = ({ endpoint }) => {
	const { testId } = useParams();
	const { activeBannerIndex, updateBannerIndex, setBannerData, bannerData, setTimer, directBonusPoints, setDirectBonusPoints } = useRemedialContext();
	const { data } = useFetch(endpoint);
	const BannerRef = useRef(null);
	useEffect(() => {
		setBannerData(data);
	}, [data]);

	useEffect(() => {
		console.log(bannerData)
		if (bannerData) {
			const leftTime = timerHelper(bannerData);
			setTimer(leftTime);
		}
	}, [bannerData]);

	const handleLightIndex = stages => {
		let lightIndex = 0;
		stages.forEach((item, index) => {
			if (item.is_position === 1) {
				lightIndex = index;
			}
		});
		return lightIndex;
	};

	// stages && handleLightIndex(stages)

	useEffect(() => {
		if (bannerData) {
			console.log("direct bonus points in  remedial banner: " + directBonusPoints);
			console.log("Banner data : ");
			console.log(bannerData);
			if (directBonusPoints === '1' && bannerData.stages.length === 4 && bannerData.stages[3].id === 5 && bannerData.stages[3].is_position === 1) {
				console.log("Yes");
				updateBannerIndex(handleLightIndex(bannerData.stages)+1);
				// setDirectBonusPoints('0');
			}
			else {
				updateBannerIndex(handleLightIndex(bannerData.stages));
			}
		}
	}, [bannerData]);


	useEffect(() => {
		if (BannerRef?.current) {
			setTimeout(() => {
				BannerRef?.current?.scrollTo({
					left: activeBannerIndex * 160,
					behavior: "smooth",
				});
			}, 1000);
		}
	}, [activeBannerIndex])


	return (
		<Box sx={{ position: 'relative', width: '100%', overflow: 'hidden'}}>
			<Banner ref={BannerRef}>
				<RemedialPathWrapper stages={bannerData?.stages} activeBannerIndex={activeBannerIndex} />
			</Banner>
			<BannerMovingAnimation />
			<Box sx={{ position: 'absolute', top: '20px', left: '30px', zIndex: '1' }}><img src={Logo} alt="Logo" /></Box>
			<Box sx={{ position: 'absolute', top: '20px', right: '30px', zIndex: '100' }}><ThemeSwitchButton /></Box>
		</Box>
	);
};

export default RemedialBanner;
