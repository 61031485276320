import React, { Component } from "react";
import styles from "./AssessmentPage.module.css";
import AssessmentInfo from "../../components/CodingComponents/components/AssessmentInfo/AssessmentInfo";
import QuestionPagination from "../../components/CodingComponents/components/QuestionPagination/QuestionPagination";
import AppLayout from "../../components/Layout/Layout";

import MonogramTimer from "../../components/CodingComponents/components/MonogramTimer/MonogramTimer";
import SphereEngine from "../../components/CodingComponents/components/SphereEngine/SphereEngine";
import nextWhite from "../../assets/coding/next_white.svg";
import next from "../../assets/coding/next.svg";
import axios from "axios";
import moment from "moment";
import ReportDialog from '../../components/reportModal';
import "./AssessmentPage.module.css";

let data = {
  problems: [],
  name: "",
  subject_name: "",
  timelimit: 0,
};

const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
let timeInterval = null;

class AssessmentPage extends Component {
  constructor(props) {
    super(props);
    let { problems, name, subject_name, timelimit, doe } = localStorage.getItem(
      "coding_data"
    )
      ? JSON.parse(localStorage.getItem("coding_data"))
      : data;
    let doe_data = moment(doe);
    let current = moment();
    const totalTime = timelimit;
    if (doe_data.diff(current, "seconds") < timelimit) {
      timelimit = doe_data.diff(current, "seconds");
    }
    this.state = {
      noOfWindowSwitches:0,
      windowChangeWarningModalOpen:false,
      windowFullScreenWarningModalOpen:false,
      assessment_title: name,
      portion: subject_name,
      time: parseInt(timelimit), // In seconds
      total_assessments: problems.length,
      assessments: [],
      selectedGroup: 1,
      selectedAssessment: "",
      isOpen: false,  
      isAssessmentCompleted: false,
      window: window.SE,
      currentTime: 0,
      test_id: Number(
        localStorage.getItem("coding_test_id")
          ? JSON.parse(localStorage.getItem("coding_test_id"))
          : 0
      ),
      questionStartTime: parseInt(timelimit),
      nextButtonText: "Next",
      reload: 0,
      testStarted: false,
      showTestStartedDialog: false,
      totalTime:parseInt(totalTime),
      reportItemId: null,
      openReportDialog: false,
      itemToBeReported: null,
      user_key: localStorage.getItem("coding_data") ? JSON.parse(localStorage.getItem("coding_data"))?.user_unique_id : null,
      practice: localStorage.getItem("coding_data") ? JSON.parse(localStorage.getItem("coding_data"))?.practice : false,
      subject_id: localStorage.getItem("coding_data") ? JSON.parse(localStorage.getItem("coding_data"))?.subject_id : null,
      dialogTitle: "Warning: You are NOT allowed to switch apps or turn off fullscreen during the test!",
      practiceTime: 0
    };

    let groupCount = Math.ceil(problems.length / 10);
    let k = 1,
      j = 0;
    for (let i = 0; i < problems.length; i++) {
      let obj = {
        marked: false,
        completed: false,
        groupId: k,
        number: 1 + i,
      };

      if (j === 10 && k <= groupCount) {
        k++;
        j = 0;
      }
      problems[i + j] = { ...problems[i + j], ...obj };
    }

    this.state.assessments = problems;
    this.state.selectedAssessment = problems[0];
    this.state.selectedGroup = 1;
    this.state.groupCount = groupCount;
  }

  handleSelectedGroup = (Assessment, groupId) => {
    this.setState({ selectedGroup: groupId, selectedAssessment: Assessment });
    this.trackCodingAction(2);
  };
  handleSelectedAssessment = (Assessment) => {
    this.setState({ 
      selectedAssessment: Assessment,
      nextButtonText: this.state.assessments[Assessment.number - 1].number >= this.state.assessments.length 
          ? "Proceed to Submit" : "Next"
    });
    this.trackCodingAction(2);
    this.submitCodeProblem();
  };

  onVisibilityChangeCallBack = () => {
    const noOfWindowSwitches = this.state.noOfWindowSwitches + 1;
    if([3,4].includes(noOfWindowSwitches)){
      this.setState({
        windowChangeWarningModalOpen: true,
        dialogTitle: "Warning: You are NOT allowed to switch apps or turn off fullscreen during the test!",
        noOfWindowSwitches: noOfWindowSwitches
      });
    }
    else if (noOfWindowSwitches === 5) {
      this.submitAssessment();
    }
    else
      this.setState({
        noOfWindowSwitches: noOfWindowSwitches
      })
  };

  openFullscreen = () => {
    try {
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    } catch (error) {
      console.log(error);
    }
  };

  closeFullscreen = () => {
    try {
      document.removeEventListener('fullscreenchange', this.showFullScreenModal);
        if (document.fullscreenElement) {
            if (document.exitFullscreen) {
            document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
            /* Safari */
            document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
            /* IE11 */
            document.msExitFullscreen();
            }
        }
    } catch (error) {
    console.log(error);
    }
};

  focused = (callback) => {
    if (!this.state.visible) {
      this.setState({ visible: true });
      callback(true);
    }
  };

  unfocused = (callback) => {
    if (this.state.visible) {
      this.setState({ visible: false });
      callback(false);
    }
  };

  mozVisiblityChangeFunction = (callBack) => {
    (document.mozHidden ? this.unfocused : this.focused)(callBack);
  };
  visiblityChangeFunction = (callBack) => {
    (document.hidden ? this.unfocused : this.focused)(callBack);
  };
  webkitVisiblityChangeFunction = (callBack) => {
    (document.webkitHidden ? this.unfocused : this.focused)(callBack);
  };
  msVisiblityChangeFunction = (callBack) => {
    (document.msHidden ? this.unfocused : this.focused)(callBack);
  };

  onVisibilityChange(callback) {
    if (!callback) {
      throw new Error("no callback given");
    }
    // Standards:
    if ("hidden" in document) {
      document.addEventListener("visibilitychange", () =>
        this.visiblityChangeFunction(callback)
      );
    }
    if ("mozHidden" in document) {
      document.addEventListener("mozvisibilitychange", () =>
        this.mozVisiblityChangeFunction(callback)
      );
    }
    if ("webkitHidden" in document) {
      document.addEventListener("webkitvisibilitychange", () =>
        this.webkitVisiblityChangeFunction(callback)
      );
    }
    if ("msHidden" in document) {
      document.addEventListener("msvisibilitychange", () =>
        this.msVisiblityChangeFunction(callback)
      );
    }
    // IE 9 and lower:
    // if ("onfocusin" in document) {
    //   document.onfocusin = () => this.focused(callback);
    //   document.onfocusout = () => this.unfocused(callback);
    // }
    // All others:
    // window.onpageshow = window.onfocus = () => this.focused(callback);
    // window.onpagehide = window.onblur = () => this.unfocused(callback);
  }

  componentDidMount() {
    setTimeout(() => {
      for (let i = 0; i < window.SE.widgets.length; i++) {
        let SEWidget = window.SE.widget(window.SE.widgets[i].id);
        SEWidget.events.subscribe("afterSendSubmission", this.handleSubmission);
      }
      // console.log("Handler Added!");
    }, 3000);
    // this.askBeforeUserLeaving();
    this.setState({showTestStartedDialog: true});
    if(this.state.practice){
      timeInterval = setInterval(() => {
        this.setCurrentTime(0);
      }, 1000);
    }
  }

  askBeforeUserLeaving() {
    try {
      this.onVisibilityChange(this.onVisibilityChangeCallBack);
    } catch (error) {
      console.log(error);
    }
  }

  handleSubmission = (data) => {
    const hs = new FormData();
    hs.append("test_id", this.state.test_id);
    hs.append("apiSubmissionId", data.apiSubmissionId);
      hs.append( "time_spent", this.state.practice ? this.state.practiceTime : this.state.questionStartTime - this.state.currentTime);
    let headers = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .post(`${BASE_API_URL}/codeEditor/${this.state.practice ? "" : "v2/"}on${this.state.practice ? "Practice" : ""}ProblemExecution`, hs, headers)
      .then((res) => {
        console.log("Submission Added to database!");
        console.log(res);
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.setState({ questionStartTime: this.state.currentTime });
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            headers = {
              headers: {
                Authorization: `Bearer ${refresh_token}`,
              },
            };
            axios
              .get(`${BASE_API_URL}/auth/refresh`, headers)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                headers = {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                };
                axios
                  .post(
                    `${BASE_API_URL}/codeEditor/${this.state.practice ? "" : "v2/"}on${this.state.practice ? "Practice" : ""}ProblemExecution`,
                    hs,
                    headers
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        this.setState({
                          questionStartTime: this.state.currentTime,
                        });
                      }
                    }
                  });
              });
          }
        }
      });
  };

  nextAssessment = () => {
    // if(this.state.assessments && this.state.assessments.length== 0 ) return;
    let assessments = this.state.assessments;
    let pIndex = this.state.assessments.findIndex(
      (i) => i.number === this.state.selectedAssessment.number
    );

    assessments[pIndex].completed = true;
    assessments[pIndex].marked = false;

    // this.setState({ assessments });

    if (this.state.selectedAssessment.number < this.state.assessments.length) {
      this.submitCodeProblem();
      this.setState({
        selectedAssessment: this.state.assessments[this.state.selectedAssessment.number],
        questionStartTime: this.state.currentTime,
        assessments: assessments,
        practiceTime: 0,
        nextButtonText: this.state.assessments[this.state.selectedAssessment.number].number >= this.state.assessments.length 
          ? "Proceed to Submit" : "Next"
      });
    } else {
      this.setState({ 
        isOpen: true,
        assessments: assessments,
        practiceTime: 0,
        nextButtonText: this.state.assessments[this.state.selectedAssessment.number - 1].number >= this.state.assessments.length
          ? "Proceed to Submit" : "Next"
      });
    }

    // if (
    //   this.state.selectedAssessment.number >=
    //   this.state.assessments.length - 1
    // ) {
    //   this.setState({ nextButtonText: "Proceed to Submit" });
    // } else {
    //   this.setState({ nextButtonText: "Next" });
    // }
  };

  reCheck = () => {
    this.setState({ isOpen: false });
  };

  submitCodeProblem = () => {
    const scp = new FormData();
    scp.append("test_id", this.state.test_id);
    scp.append("problem_id", this.state.selectedAssessment.id);
    scp.append("time_spent",this.state.practice ? this.state.practiceTime : this.state.questionStartTime - this.state.currentTime);
    let headers = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .post(`${BASE_API_URL}/codeEditor/${this.state.practice ? "" : "v2/"}submit${this.state.practice ? "Practice" : ""}CodingProblem`, scp, headers)
      .then((res) => {  
        console.log("Submission Added to database!");
        console.log(res);
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.setState({ questionStartTime: this.state.currentTime });
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            headers = {
              headers: {
                Authorization: `Bearer ${refresh_token}`,
              },
            };
            axios
              .get(`${BASE_API_URL}/auth/refresh`, headers)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                headers = {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                };
                axios
                  .post(
                    `${BASE_API_URL}/codeEditor/${this.state.practice ? "" : "v2/"}submit${this.state.practice ? "Practice" : ""}CodingProblem`,
                    scp,
                    headers
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        this.setState({
                          questionStartTime: this.state.currentTime,
                        });
                      }
                    }
                  });
              });
          }
        }
      });
  };

  trackCodingAction = (action_type) => {
    const tca = new FormData();
    tca.append("test_id", this.state.test_id);
    tca.append("problem_id", this.state.selectedAssessment.id);
    tca.append("action_type", action_type);
    tca.append("time_spent", this.state.practice ? this.state.practiceTime : this.state.questionStartTime - this.state.currentTime);
    let headers = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .post(`${BASE_API_URL}/codeEditor/${this.state.practice ? "" : "v2/"}track${this.state.practice ? "Practice" : ""}CodingAction`, tca, headers)
      .then((res) => {
        console.log("Submission Added to database!");
        console.log(res);
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.setState({ questionStartTime: this.state.currentTime });
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            headers = {
              headers: {
                Authorization: `Bearer ${refresh_token}`,
              },
            };
            axios
              .get(`${BASE_API_URL}/auth/refresh`, headers)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                headers = {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                };
                axios
                  .post(
                    `${BASE_API_URL}/codeEditor/${this.state.practice ? "" : "v2/"}track${this.state.practice ? "Practice" : ""}CodingAction`,
                    tca,
                    headers
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        this.setState({
                          questionStartTime: this.state.currentTime,
                        });
                      }
                    }
                  });
              });
          }
        }
      });
  };

  submitAssessment = () => {
    this.setState({ questionStartTime: this.state.currentTime });
    this.closeFullscreen();
    this.submitCodeProblem();
    clearInterval(timeInterval);
    setTimeout(() => {
      if(this.state.practice) 
        this.props.history.push({
          pathname: '/course/practice',
          state: {subject_id: this.state.subject_id}
        });
      else
        this.props.history.push("/home");
    }, 1500);
  };

  handleMarkAsDoubt = () => {
    let assessments = this.state.assessments;

    let pIndex = this.state.assessments.findIndex(
      (i) => i.number === this.state.selectedAssessment.number
    );

    assessments[pIndex].marked = true;
    assessments[pIndex].completed = false;

    // this.setState({ assessments });

    if (this.state.selectedAssessment.number < this.state.assessments.length) {
      this.setState({
        selectedAssessment: this.state.assessments[this.state.selectedAssessment.number],
        questionStartTime: this.state.currentTime,
        practiceTime: 0,
        assessments: assessments,
        nextButtonText: this.state.assessments[this.state.selectedAssessment.number].number >= this.state.assessments.length 
          ? "Proceed to Submit" : "Next"
      });
    } else {
      this.setState({ 
        isOpen: true, 
        assessments: assessments,
        practiceTime: 0,
        nextButtonText: this.state.assessments[this.state.selectedAssessment.number].number >= this.state.assessments.length 
          ? "Proceed to Submit" : "Next" 
      });
    }

    this.trackCodingAction(1);
    // if (
    //   this.state.selectedAssessment.number >=
    //   this.state.assessments.length - 1
    // ) {
    //   this.setState({ nextButtonText: "Proceed to Submit" });
    // } else {
    //   this.setState({ nextButtonText: "Next" });
    // }
  };

  setCurrentTime = (time) => {
    if(this.state.currentTime === 1){
      this.setState({currentTime : 0});
      this.submitAssessment();
    }
    else{
      let practiceTime = this.state.practiceTime;
      this.setState({ currentTime: time, practiceTime: this.state.practice ? practiceTime++ : 0 });

    } 
  };

  onCloseWargningWindow = () => {
    this.setState({
      windowChangeWarningModalOpen: false
    }); 
  };
  
  onCloseFullScreenWarningWindow = () =>{
    this.openFullscreen();
    this.setState({windowFullScreenWarningModalOpen: false})
  }

  showFullScreenModal = () => {
    if(!document.fullscreenElement || /* Standard syntax */
      !document.webkitFullscreenElement || /* Safari and Opera syntax */
      !document.msFullscreenElement)
        this.setState({windowFullScreenWarningModalOpen: true})
  }

  startTest=()=> {
    this.openFullscreen();
    this.setState({showTestStartedDialog: false, testStarted: true});
    document.addEventListener('fullscreenchange', this.showFullScreenModal);
  }

  reportItem = (item)=> {
    document.body.style.overflow = 'hidden';
    // let assessments = this.state.assessments;
    let id = this.state.selectedAssessment.id;
    this.setState({openReportDialog: true, itemToBeReported: item, reportItemId: id});
  }

  closeReport = ()=> {
      document.body.style.overflow = 'auto';
      this.setState({openReportDialog: false, itemToBeReported: null, reportItemId: null});
  }

  onBackClick = () => {
    this.setState({ questionStartTime: this.state.currentTime });
    this.closeFullscreen();
    this.submitCodeProblem();
    if(this.state.subject_id){
      this.props.history.push({
        pathname: '/course/practice',
        state: {subject_id: this.state.subject_id}
      });
    }
  }

  render() {
    console.log(this.state);
    let startedDialog = this.state.showTestStartedDialog && !this.state.testStarted ? (
      <div className={styles.overlay}>
        <div className={styles.dialog}>
          <h1>Warning!</h1>
          <p>
            Your test is getting Started. Your test will be shown in full screen. Please dont switch screens.
          </p>
          <div
            style={{
              borderBottom: "1px solid rgb(0,0,0,.3)",
              margin: "30px 0",
            }}
          ></div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={this.startTest} className={styles.next}>
              OK
            </button>
          </div>
        </div>
      </div>
    ) : null;
    let dialog = this.state.isOpen ? (
      <div className={styles.overlay}>
        <div className={styles.dialog}>
          <h1>You have almost finished the assessment!</h1>
          <p>
            Would like to re-check the assessment submissions? If 'Yes' click
            'Re-check' else 'Submit'{" "}
          </p>
          <div
            style={{
              borderBottom: "1px solid rgb(0,0,0,.3)",
              margin: "30px 0",
            }}
          ></div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button onClick={this.reCheck} className={styles.doubt}>
              Re-check{" "}
            </button>{" "}
            <button onClick={this.submitAssessment} className={styles.next}>
              Submit
            </button>
          </div>
        </div>
      </div>
    ) : null;
    let warningWindow = this.state.windowChangeWarningModalOpen ? (
      <div className={styles.overlay}>
        <div className={styles.dialog}>
          <h1>Warning!</h1>
          <p>
            {this.state.dialogTitle}
          </p>
          <div
            style={{
              borderBottom: "1px solid rgb(0,0,0,.3)",
              margin: "30px 0",
            }}
          ></div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={this.onCloseWargningWindow} className={styles.next}>
              OK
            </button>
          </div>
        </div>
      </div>
    ) : null;
    let fullScreenWindow = this.state.windowFullScreenWarningModalOpen ? (
      <div className={styles.overlay}>
        <div className={styles.dialog}>
          <h1>Warning!</h1>
          <p>
            You are not allowed to exit the full screen!
          </p>
          <div
            style={{
              borderBottom: "1px solid rgb(0,0,0,.3)",
              margin: "30px 0",
            }}
          ></div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={this.onCloseFullScreenWarningWindow} className={styles.next}>
              OK
            </button>
          </div>
        </div>
      </div>
    ) : null;

    let se = this.state.assessments.map((p) => {
      return (
        <div
          key={p.id}
          style={
            this.state.selectedAssessment.widget_code === p.widget_code
              ? { display: "block" }
              : { display: "none" }
          }
        >
          {/* <div style={{ textAlign: 'right' }}>Marks: {p.marks}</div> */}
          <SphereEngine onLoad={this.handleSub} wcode={p.widget_code} user_key={this.state.user_key} />
        </div>
      );
    });

    return (
      <div 
        onCopy={(e) => {
          e.preventDefault();
          return false;
        }}
        onCut={(e) => {
          e.preventDefault();
          return false;
        }}
        onPaste={(e) => {
          e.preventDefault();
          return false;
        }}>
        <AppLayout logoutDisable={true}></AppLayout>

        <div className={styles.AssessmentPage}>
          {startedDialog}
          {dialog}
          {warningWindow}
          {fullScreenWindow}
          {this.state.openReportDialog ? <ReportDialog 
            open={this.state.openReportDialog} 
            close={this.closeReport} 
            reportItem={this.state.itemToBeReported}
            reportItemId={this.state.reportItemId}>
          </ReportDialog> : null}
          <div className={styles.topSection}>
            <div className={styles.left}>
              {this.state.practice ? <div style={{margin:'10px 0',textAlign: 'left', cursor: 'pointer'}} onClick={this.onBackClick}>
                <img src ='/images/left_arrow.svg' alt="back"/>
                &nbsp;Back
              </div> : null}
              <AssessmentInfo
                title={this.state.assessment_title}
                portion={this.state.portion}
                time={!this.state.practice ? this.state.time : null}
                qcount={this.state.total_assessments}
                totalTime={this.state.totalTime}
              />

              <QuestionPagination
                selectedAssessment={this.state.selectedAssessment}
                selectedGroup={this.state.selectedGroup}
                assessments={this.state.assessments}
                groupCount={this.state.groupCount}
                onUpdateSelectGroup={this.handleSelectedGroup}
                onUpdateSelectAssessment={this.handleSelectedAssessment}
                onClick={() => this.handleStartTimer}
              />
            </div>
            <div className={styles.right}>
              { !(this.state.showTestStartedDialog && !this.state.testStarted) && !this.state.practice ? <MonogramTimer
                seconds={this.state.time}
                onTimerUpdate={this.setCurrentTime}
              /> : !this.state.practice ? <MonogramTimer
                seconds={0}
                onTimerUpdate={this.setCurrentTime}
              />
              : null}
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{width: '90%'}} className={styles.SphereEngine}>{se}</div>
            <div style={{width: '10%', marginTop: '10px'}}>
                <div style={{width: '100%'}}>
                    <img style={{marginLeft: '0'}} onClick={()=>this.reportItem('coding_questions')} src='/images/report.svg' alt="report"></img>
                </div>
            </div>
          </div>
          <div className={styles.bottomSection}>
            <button className={styles.doubt} onClick={this.handleMarkAsDoubt}>
              Mark as Doubt and {this.state.nextButtonText === "Next" ? 'Next' : 'Submit'} <img src={next} alt="" />
            </button>{" "}
            <button onClick={this.nextAssessment} className={styles.next}>
              {this.state.nextButtonText} <img src={nextWhite} alt="" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default AssessmentPage;
