import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components';
import { addVideoResumeAC } from '../../store/actions/profileActions';


const SaveButton = styled.button`
    border-radius: 32px;
    background:  ${({ active }) => (active ? "linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%)" : "grey")};
    margin: auto;
    text-align: center;
    width: 98px;
    padding: 8px;
    color: white;
    margin-right: 0;
    border: none;
`

class AddVideoResume extends Component {

    state = {
        openContent : false,
        contentUrl: '',
        contentType: null,
        summary: this.props.summary
    }

    uploadVideo = (event)=> {
        event.stopPropagation();
        event.preventDefault();
        this.setState({new_video: event.target.files[0]});
    }

    closeVideoEditor = () => {
        this.setState({
            new_video: null
        });
        this.props.clearUpdateVideoResumeMessages();
        this.props.close();
    }

    render () {
        console.log(this.props.video_resume);
        let data = (this.props.video_resume && (!this.props.video_resume.includes('youtube') || !this.props.video_resume.includes('youtu'))) ?
        <video height="200px" controls><source src={this.props.video_resume} type="video/mp4" /></video> : this.props.video_resume ?
        <iframe allow="autoplay" src={this.props.video_resume} title={'Video Resume'} frameBorder="0" width="70%" height="200px"></iframe> :
        null;
        return (
            <div style={{}}>                    
                <div  style={{ textAlign: 'left', margin: '20px 0'}}>
                    <div style={{ margin: '0 auto 20px auto', fontWeight: 'bold',textAlign: 'left', fontSize: '20px'}}>Upload your Video Resume : </div>
                    {this.props.video_resume ? <div>
                        {data}
                    </div> : null}
                    {this.state.new_video ? <video height="250px" controls key = {Date.now()}><source src={URL.createObjectURL(this.state.new_video)} type="video/mp4" /></video>
                    : null ? <div>
                        {data}
                    </div>
                    : null}
                    <div style={{margin: '10px auto'}}>
                        <input type='file' onChange={this.uploadVideo}></input>
                    </div>
                    {this.props.error_updating_video? <div style={{color: 'red', textAlign: 'left'}}>Error Uploading Video. Please try again after some time!!!!</div> : null}
                    {this.props.video_updated_successfully? <div style={{color: 'green', textAlign: 'left'}}>Video Resume Uploaded Successfully.</div> : null}

                    <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeVideoEditor}>Cancel</div>
                        <div>
                            <SaveButton active={this.state.new_video} onClick={()=>this.props.updateVideoResume(this.state.new_video)}>Save</SaveButton>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.prof.loading,
        college: state.prof.college,
        college_university_degree_department_id: state.prof.college_university_degree_department_id,
        department_details: state.prof.department_details,

        id: state.prof.id,
        roll_number: state.prof.roll_number,
        semester_id: state.prof.semester_id,
        university_degree_department_id: state.prof.university_degree_department_id,
        
        video_resume: state.prof.video_resume,
        error_updating_video: state.prof.error_updating_video,
        video_updated_successfully: state.prof.video_updated_successfully

    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateVideoResume: (video)=>dispatch(addVideoResumeAC(video)),
        clearUpdateVideoResumeMessages: ()=>dispatch({type: 'CLEAR_UPDATE_VIDEO_RESUME_STATUS'})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddVideoResume));