import axios from "../../interceptor";
import * as Sentry from "@sentry/react";
import { getLoggedInUser } from "../../helpers/Auth";
import moment from "moment";
import { getPersonalityQuestionAtTriggerAC } from "./personalityAssessmentActions";
import {history} from '../../utils'

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
const environment = process.env.REACT_APP_ENVIRONEMNT;
const plain_error_obj = {
  api_name: null,
  status: null,
  api_status: null,
  user_id: getLoggedInUser()?.user_id,
  timestamp: null,
};

export function setGlobalToken() {
  try {
    let token = localStorage.getItem("token");
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } catch (error) {
    console.log(error);
  }
}

export const GETDASHBOARDDATA = "GET_DASHBOARD_DATA";
export const GETWALLDATA = "GET_WALL_DATA";
export const GETANALYTICSDATA = "GET_ANALYTICS_DATA";
export const GETANALYTICSSEMESTERDATA = "GET_ANALYTICS_SEMESTER_DATA";
export const GETSEARCHDATA = "GET_SEARCH_DATA";
export const SUBMITQUESTIONOFTHEDAY = "SUBMIT_QUESTION_OF_THE_DAY";
export const ADDORDELETEBOOKMARKQUESTIONOFTHEDAY =
  "ADD_OR_DELETE_BOOKMARK_QUESTION_OF_THE_DAY";
export const ADDORDELETEBOOKMARKTOPICOFTHEDAY =
  "ADD_OR_DELETE_BOOKMARK_TOPIC_OF_THE_DAY";
export const ADDQODINTEREST = "ADD_QOD_INTEREST";
export const GETDECKITEMS = "GET_DECK_ITEMS";
export const OPENTOPICOFTHEDAYREADMORE = "OPEN_TOPIC_OF_THE_DAY_READMORE";
export const CLOSETOPICOFTHEDAYREADMORE = "CLOSE_TOPIC_OF_THE_DAY_READMORE";
export const OPENQUESTIONOFTHEDAYREADMORE = "OPEN_QUESTION_OF_THE_DAY_READMORE";
export const CLOSEQUESTIONOFTHEDAYREADMORE =
  "CLOSE_QUESTION_OF_THE_DAY_READMORE";
export const OPENRECOMMENDEDDECK = "OPEN_RECOMMENDED_DECK";
export const CLOSERECOMMENDEDDECK = "CLOSE_RECOMMENDED_DECK";
export const GETRECOMMENDEDDECKS = "GET_RECOMMENDED_DECKS";
export const GETSEARCHEDTOPICDECK = "GET_SEARCHED_TOPIC_DECK";
export const GETSEARCHEDTOPICVIDEO = "GET_SEARCHED_TOPIC_VIDEO";
export const GETSEARCHEDTOPICDOCS = "GET_SEARCHED_TOPIC_DOCS";
export const GETIOSAPPLINK = "GETIOSAPPLINK";
export const GETANSWEREDSTUDENTS = "GET_ANSWERED_STUDENTS";
export const GETUNANSWEREDSTUDENTS = "GET_UNANSWERED_STUDENTS";

export const ALLSECTIONSGETPERMORMANCESEVENDAYSDATA = "ALL_SECTIONS_GET_PERMORMANCE_SEVEN_DAYS_DATA";
export const ALLSECTIONSGETPERMORMANCETHIRTYDAYSDATA = "ALL_SECTIONS_GET_PERMORMANCE_THIRTY_DAY_SDATA";
export const ALLSECTIONSGETPARTICIPATIONSEVENDAYSDATA = "ALL_SECTIONS_GET_PARTICIPATION_SEVEN_DAYS_DATA";
export const ALLSECTIONSGETPARTICIPATIONTHIRTYDAYSDATA = "ALL_SECTIONS_GET_PARTICIPATION_THIRTY_DAYS_DATA";
export const GETCLASSROOMACTIVITYNEW = "GET_CLASSROOM_ACTIVITY_NEW";

export const getClassroomActivityA = (res, err) => {
  return {
    type: GETCLASSROOMACTIVITYNEW,
    res: res,
    error: err,
  };
};


export const getSearchedTopicDeckA = (code, res, err) => {
  return {
    type: GETSEARCHEDTOPICDECK,
    code: code,
    res: res,
    error: err,
  };
};

export const getSearchedTopicVideoA = (code, res, err) => {
  return {
    type: GETSEARCHEDTOPICVIDEO,
    code: code,
    res: res,
    error: err,
  };
};

export const getSearchedTopicDocsA = (code, res, err) => {
  return {
    type: GETSEARCHEDTOPICDOCS,
    code: code,
    res: res,
    error: err,
  };
};

export const getRecommendedDecksA = (res, err) => {
  return {
    type: GETRECOMMENDEDDECKS,
    res: res,
    error: err,
  };
};
export const getDeckItemA = (res, err) => {
  return {
    type: GETDECKITEMS,
    res: res,
    error: err,
  };
};

export const addBookmarkA = (bookmark_type, err) => {
  if (bookmark_type == "questionoftheday") {
    return {
      type: ADDORDELETEBOOKMARKQUESTIONOFTHEDAY,
      bookmark: 1,
      error: err,
    };
  }
  if (bookmark_type == "topicoftheday") {
    return {
      type: ADDORDELETEBOOKMARKTOPICOFTHEDAY,
      bookmark: 1,
      error: err,
    };
  }
};

export const deleteBookmarkA = (bookmark_type, err) => {
  if (bookmark_type == "questionoftheday") {
    return {
      type: ADDORDELETEBOOKMARKQUESTIONOFTHEDAY,
      bookmark: 0,
      error: err,
    };
  }
  if (bookmark_type == "topicoftheday") {
    return {
      type: ADDORDELETEBOOKMARKTOPICOFTHEDAY,
      bookmark: 0,
      error: err,
    };
  }
};

export const submitQuestionOfTheDayA = (res, err) => {
  return {
    type: SUBMITQUESTIONOFTHEDAY,
    res: res,
    error: err,
  };
};

export const addQodInterestA = (res, err) => {
  return {
    type: ADDQODINTEREST,
    show_more_of_this_kind: res,
    error: err,
  };
};

export const getSearchDataA = (res, err) => {
  return {
    type: GETSEARCHDATA,
    res: res,
    error: err,
  };
};

export const getDashboardDataA = (res, user, err) => {
  return {
    type: GETDASHBOARDDATA,
    res: res,
    error: err,
    user: user,
  };
};

export const getWallDataA = (res, err) => {
  return {
    type: GETWALLDATA,
    res: res,
    error: err,
  };
};

export const getStatsDataA = (res, err) => {
  return {
    type: GETANALYTICSDATA,
    res: res,
    error: err,
  };
};
export const getStatsDataSemesterA = (res, err) => {
  return {
    type: GETANALYTICSSEMESTERDATA,
    res: res,
    error: err,
  };
};


export const getIosAppLinkA = (res, err) => {
  return {
    type: GETIOSAPPLINK,
    res: res,
    error: err,
  };
};
export const getAnsweredStudentsA = (res, err) => {
  return {
    type: GETANSWEREDSTUDENTS,
    res: res,
    error: err,
  };
};
export const getUnAnsweredStudentsA = (res, err) => {
  return {
    type: GETUNANSWEREDSTUDENTS,
    res: res,
    error: err,
  };
};
export const getAllsectionLeaderboardAc = (res,activitydays,type,err) => {
  if (err) {
    return {
      type: ALLSECTIONSGETPERMORMANCESEVENDAYSDATA,
      res: res,
      error: err,
    };
  }
  if (activitydays=="7" && type=="Performance") {
    return {
      type: ALLSECTIONSGETPERMORMANCESEVENDAYSDATA,
      res: res,
      error: err,
    };
  }else if (activitydays=="30" && type=="Performance") {
    return {
      type: ALLSECTIONSGETPERMORMANCETHIRTYDAYSDATA,
      res: res,
      error: err,
    };
  }else if (activitydays=="7" && type=="Participation") {
    return {
      type: ALLSECTIONSGETPARTICIPATIONSEVENDAYSDATA,
      res: res,
      error: err,
    };
  }else if (activitydays=="30" && type=="Participation") {
    return {
      type: ALLSECTIONSGETPARTICIPATIONTHIRTYDAYSDATA,
      res: res,
      error: err,
    };
  }
 
};

export const getDashboardDataAC = () => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let cuddid = user.college_university_degree_department_id;
    let sem_id = user.semester_id;
    let sec_id = user.section_id;
    let d_id = user.department_id;
    let rbm_id = user.regulation_batch_mapping_id;
    let careerPath= user.display_modules?.career_path
   console.log(careerPath)   
    if(!rbm_id ){
      localStorage.clear();
      history.push('/')
      window.location.reload()
      return;
    }
    const error_obj = { ...plain_error_obj };
    error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
    error_obj.api_name = `/v3/getDashboardWebData`;
    error_obj.payload = {
      college_university_degree_department_id: cuddid,
      semester_id: sem_id,
      section_id: sec_id,
      department_id: d_id,
      regulation_batch_mapping_id:rbm_id
    };
    http
      .get(
        `https://mwxwy0mup5.execute-api.ap-south-1.amazonaws.com/${environment}/studentwebdashboard?college_university_degree_department_id=${cuddid}&semester_id=${sem_id}&section_id=${sec_id}&department_id=${d_id}&delta_days=20&upcoming_delta_days=3&regulation_batch_mapping_id=${rbm_id}`
      )
      .then((res) => {
        error_obj.status = res.status;
        error_obj.api_status = res.data?.status;
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getDashboardDataA(res.data, user, false));
            dispatch(getClassroomActivityA(res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();

                http
                  .get(
                    `https://mwxwy0mup5.execute-api.ap-south-1.amazonaws.com/${environment}/studentwebdashboard?college_university_degree_department_id=${cuddid}&semester_id=${sem_id}&section_id=${sec_id}&department_id=${d_id}&delta_days=20&upcoming_delta_days=3&regulation_batch_mapping_id=${rbm_id}`
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getDashboardDataA(res.data, user, false));
                        dispatch(getClassroomActivityA(res.data, false));
                      } else {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        dispatch(getDashboardDataA(null, user, true));
                      }
                    } else {
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                      dispatch(getDashboardDataA(null, user, true));
                    }
                  })
                  .catch((err) => {
                    error_obj.status = err?.request?.status;
                    Sentry.captureException(error_obj, (scope) => {
                      scope.setTransactionName(error_obj.api_name);
                      return scope;
                    });
                    dispatch(getDashboardDataA(null, user, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(getDashboardDataA(null, user, true));
          }
        } else {
          console.log("in error dispatch 3");
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
          dispatch(getDashboardDataA(null, user, true));
        }
      })
      .catch((err) => {
        console.log(err);
        error_obj.status = err?.request?.status;
        Sentry.captureException(error_obj, (scope) => {
          scope.setTransactionName(error_obj.api_name);
          return scope;
        });
        dispatch(getDashboardDataA(null, user, true));
      });
  };
};

export const getWallData = (query_date) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
  

    const error_obj = { ...plain_error_obj };
    error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
    error_obj.api_name = `/lambda/getwalldata`;
    error_obj.payload = {
      delta_days: 20,
      query_date: query_date,
    };
    http
      .get(
        `https://pg8nxvff1b.execute-api.ap-south-1.amazonaws.com/${environment}?delta_days=20&query_date=${query_date}`
      )
      .then((res) => {
        error_obj.status = res.status;
        error_obj.api_status = res.data?.status;
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getWallDataA(res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();

                http
                  .get(
                    `https://pg8nxvff1b.execute-api.ap-south-1.amazonaws.com/${environment}?delta_days=20&query_date=${query_date}`
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getWallDataA(res.data, false));
                      } else {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        dispatch(getWallDataA(null, true));
                      }
                    } else {
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                      dispatch(getWallDataA(null, true));
                    }
                  })
                  .catch((err) => {
                    error_obj.status = err?.request?.status;
                    Sentry.captureException(error_obj, (scope) => {
                      scope.setTransactionName(error_obj.api_name);
                      return scope;
                    });
                    dispatch(getWallDataA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(getWallDataA(null, true));
          }
        } else {
          console.log("in error dispatch 3");
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
          dispatch(getWallDataA(null, true));
        }
      })
      .catch((err) => {
        console.log(err);
        error_obj.status = err?.request?.status;
        Sentry.captureException(error_obj, (scope) => {
          scope.setTransactionName(error_obj.api_name);
          return scope;
        });
        dispatch(getWallDataA(null, true));
      });
  };
}

export const getStatsData = (activity_days) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const error_obj = { ...plain_error_obj };
    error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
    error_obj.api_name = `/lambda/studentdashboardanalytics`;
    error_obj.payload = {
      activity_days:7,
    };
    http
      .get(
        `https://awsnzhabh8.execute-api.ap-south-1.amazonaws.com/${environment}/studentdashboardanalytics?activity_days=7`
      )
      .then((res) => {
        error_obj.status = res.status;
        error_obj.api_status = res.data?.status;
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getStatsDataA(res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
              // https://i84ux8ski7.execute-api.ap-south-1.amazonaws.com/${environment}/studentdashboardanalytics?activity_days=7  old endpoint before db re structure
                http
                  .get(
                    `https://awsnzhabh8.execute-api.ap-south-1.amazonaws.com/${environment}/studentdashboardanalytics?activity_days=7`
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getStatsDataA(res.data, false));
                      } else {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        dispatch(getStatsDataA(null, true));
                      }
                    } else {
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                      dispatch(getStatsDataA(null, true));
                    }
                  })
                  .catch((err) => {
                    error_obj.status = err?.request?.status;
                    Sentry.captureException(error_obj, (scope) => {
                      scope.setTransactionName(error_obj.api_name);
                      return scope;
                    });
                    dispatch(getStatsDataA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(getStatsDataA(null, true));
          }
        } else {
          console.log("in error dispatch 3");
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
          dispatch(getStatsDataA(null, true));
        }
      })
      .catch((err) => {
        console.log(err);
        error_obj.status = err?.request?.status;
        Sentry.captureException(error_obj, (scope) => {
          scope.setTransactionName(error_obj.api_name);
          return scope;
        });
        dispatch(getStatsDataA(null, true));
      });
  };
}

export const getAnsweredStudents = (id,type) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const error_obj = { ...plain_error_obj };
    error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
    error_obj.api_name = `/v2/getAnsweredStudents`;
    error_obj.payload = {
      activity_days:7,
    };
    http
    .get(`${BASE_API_URL}/dashboard_web/v2/getAnsweredStudents?test_id=${id}&test_type=${type}`)
      .then((res) => {
        error_obj.status = res.status;
        error_obj.api_status = res.data?.status;
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getAnsweredStudentsA(res.data.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();

                http
                .get(`${BASE_API_URL}/dashboard_web/v2/getAnsweredStudents?test_id=${id}&test_type=${type}`)
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getAnsweredStudentsA(res.data.data, false));
                      } else {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        dispatch(getAnsweredStudentsA(null, true));
                      }
                    } else {
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                      dispatch(getAnsweredStudentsA(null, true));
                    }
                  })
                  .catch((err) => {
                    error_obj.status = err?.request?.status;
                    Sentry.captureException(error_obj, (scope) => {
                      scope.setTransactionName(error_obj.api_name);
                      return scope;
                    });
                    dispatch(getAnsweredStudentsA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(getAnsweredStudentsA(null, true));
          }
        } else {
          console.log("in error dispatch 3");
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
          dispatch(getAnsweredStudentsA(null, true));
        }
      })
      .catch((err) => {
        console.log(err);
        error_obj.status = err?.request?.status;
        Sentry.captureException(error_obj, (scope) => {
          scope.setTransactionName(error_obj.api_name);
          return scope;
        });
        dispatch(getAnsweredStudentsA(null, true));
      });
  };
}

export const getUnAnsweredStudents = (id,type) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const error_obj = { ...plain_error_obj };
    error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
    error_obj.api_name = `/v2/getAnsweredStudents`;
    error_obj.payload = {
      activity_days:7,
    };
    http
    .get(`${BASE_API_URL}/dashboard_web/v2/getUnansweredStudents?test_id=${id}&test_type=${type}`)
      .then((res) => {
        error_obj.status = res.status;
        error_obj.api_status = res.data?.status;
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getUnAnsweredStudentsA(res.data.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();

                http
                .get(`${BASE_API_URL}/dashboard_web/v2/getUnansweredStudents?test_id=${id}&test_type=${type}`)
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getUnAnsweredStudentsA(res.data.data, false));
                      } else {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        dispatch(getUnAnsweredStudentsA(null, true));
                      }
                    } else {
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                      dispatch(getUnAnsweredStudentsA(null, true));
                    }
                  })
                  .catch((err) => {
                    error_obj.status = err?.request?.status;
                    Sentry.captureException(error_obj, (scope) => {
                      scope.setTransactionName(error_obj.api_name);
                      return scope;
                    });
                    dispatch(getUnAnsweredStudentsA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(getUnAnsweredStudentsA(null, true));
          }
        } else {
          console.log("in error dispatch 3");
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
          dispatch(getUnAnsweredStudentsA(null, true));
        }
      })
      .catch((err) => {
        console.log(err);
        error_obj.status = err?.request?.status;
        Sentry.captureException(error_obj, (scope) => {
          scope.setTransactionName(error_obj.api_name);
          return scope;
        });
        dispatch(getUnAnsweredStudentsA(null, true));
      });
  };
}

export const getStatsDataSemester = (activity_days) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const error_obj = { ...plain_error_obj };
    error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
    error_obj.api_name = `/lambda/studentdashboardanalytics`;
    error_obj.payload = {
      activity_days:"all",
    };
    http
      .get(
        `https://awsnzhabh8.execute-api.ap-south-1.amazonaws.com/${environment}/studentdashboardanalytics?activity_days=`
      )
      .then((res) => {
        error_obj.status = res.status;
        error_obj.api_status = res.data?.status;
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getStatsDataSemesterA(res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();

                http
                  .get(
                    `https://awsnzhabh8.execute-api.ap-south-1.amazonaws.com/${environment}/studentdashboardanalytics?activity_days=`
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getStatsDataSemesterA(res.data, false));
                      } else {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        dispatch(getStatsDataSemesterA(null, true));
                      }
                    } else {
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                      dispatch(getStatsDataSemesterA(null, true));
                    }
                  })
                  .catch((err) => {
                    error_obj.status = err?.request?.status;
                    Sentry.captureException(error_obj, (scope) => {
                      scope.setTransactionName(error_obj.api_name);
                      return scope;
                    });
                    dispatch(getStatsDataSemesterA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(getStatsDataSemesterA(null, true));
          }
        } else {
          console.log("in error dispatch 3");
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
          dispatch(getStatsDataA(null, true));
        }
      })
      .catch((err) => {
        console.log(err);
        error_obj.status = err?.request?.status;
        Sentry.captureException(error_obj, (scope) => {
          scope.setTransactionName(error_obj.api_name);
          return scope;
        });
        dispatch(getStatsDataA(null, true));
      });
  };
}

export const getAllsectionLeaderboard = (activity_days,type) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const error_obj = { ...plain_error_obj };
    error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
    error_obj.api_name = `/lambda/student_alldashboardleaderboard`;
    error_obj.payload = {
      activity_days:"all",
    };
    http
      .get(
      `https://qwydagdrhk.execute-api.ap-south-1.amazonaws.com/${environment}/student_alldashboardleaderboard?activity_days=${activity_days}&leaderboard_type=${type}`
      )
      .then((res) => {
        error_obj.status = res.status;
        error_obj.api_status = res.data?.status;
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getAllsectionLeaderboardAc(res.data,activity_days,type, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();

                http
                  .get(
                    `https://qwydagdrhk.execute-api.ap-south-1.amazonaws.com/${environment}/student_alldashboardleaderboard?activity_days=${activity_days}&leaderboard_type=${type}`
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getAllsectionLeaderboardAc(res.data,activity_days,type, false));
                      } else {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        dispatch(getAllsectionLeaderboardAc(null,activity_days,type,true));
                      }
                    } else {
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                      dispatch(getAllsectionLeaderboardAc(null,activity_days,type,true));
                    }
                  })
                  .catch((err) => {
                    error_obj.status = err?.request?.status;
                    Sentry.captureException(error_obj, (scope) => {
                      scope.setTransactionName(error_obj.api_name);
                      return scope;
                    });
                    dispatch(getAllsectionLeaderboardAc(null,activity_days,type,true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(getAllsectionLeaderboardAc(null,activity_days,type,true));
          }
        } else {
          console.log("in error dispatch 3");
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
          dispatch(getAllsectionLeaderboardAc(null,activity_days,type,true));
        }
      })
      .catch((err) => {
        console.log(err);
        error_obj.status = err?.request?.status;
        Sentry.captureException(error_obj, (scope) => {
          scope.setTransactionName(error_obj.api_name);
          return scope;
        });
        dispatch(getAllsectionLeaderboardAc(null,activity_days,type,true));
      });
  };
}

export const getSearchDataAC = () => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
    let cuddid = user.college_university_degree_department_id;
    let sem_id = user.semester_id;
    let sec_id = user.section_id;
    http
      .get(
        `${BASE_API_URL}/dashboard/v2/getSearchData?college_university_degree_department_id=${cuddid}&semester_id=${sem_id}&section_id=${sec_id}`
      )
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getSearchDataA(res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .get(
                    `${BASE_API_URL}/dashboard/v2/getSearchData?college_university_degree_department_id=${cuddid}&semester_id=${sem_id}&section_id=${sec_id}`
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getSearchDataA(res.data, false));
                      } else {
                        dispatch(getSearchDataA(null, true));
                      }
                    } else {
                      dispatch(getSearchDataA(null, true));
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(getSearchDataA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(getSearchDataA(null, true));
          }
        } else {
          console.log("in error dispatch 3");
          dispatch(getSearchDataA(null, true));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(getSearchDataA(null, true));
      });
  };
};

export const submitQuestionOfTheDayAC = (option_index, time_spent) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    let question_id = getState().dash.question_of_the_day.id;
    const formdata = new FormData();
    formdata.append("id", question_id);
    formdata.append("answer", option_index);
    formdata.append("timespent", time_spent);
    http
      .post(`${BASE_API_URL}/dashboard/v3/submitQuestionOfTheDay`, formdata)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(submitQuestionOfTheDayA(res.data.data, false));
            if (res.data.data.correctly_answered === 1) {
              dispatch(getPersonalityQuestionAtTriggerAC(1));
            }            
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(
                    `${BASE_API_URL}/dashboard/v3/submitQuestionOfTheDay`,
                    formdata
                  )
                  .then((res) => {
                    console.log("submit QOD res --> ");
                    console.log(res);
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(submitQuestionOfTheDayA(res.data.data, false));
                        if (res.data.data.correctly_answered === 1) {
                          dispatch(getPersonalityQuestionAtTriggerAC(1));
                        }                        
                      } else {
                        dispatch(submitQuestionOfTheDayA(null, true));
                      }
                    } else {
                      dispatch(submitQuestionOfTheDayA(null, true));
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(submitQuestionOfTheDayA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(submitQuestionOfTheDayA(null, true));
          }
        } else {
          console.log("in error dispatch 3");
          dispatch(submitQuestionOfTheDayA(null, true));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(submitQuestionOfTheDayA(null, true));
      });
  };
};

export const addQodInterestAC = (interested) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    let question_id = getState().dash.question_of_the_day.id;
    const formdata = new FormData();
    formdata.append("id", question_id);
    formdata.append("interested", interested);
    http
      .post(`${BASE_API_URL}/dashboard/v2/qodInterested`, formdata)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(addQodInterestA(interested, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(
                    `${BASE_API_URL}/dashboard/v2/qodInterested`,
                    formdata
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(addQodInterestA(interested, false));
                      } else {
                        dispatch(addQodInterestA(null, true));
                      }
                    } else {
                      dispatch(addQodInterestA(null, true));
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(addQodInterestA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(addQodInterestA(null, true));
          }
        } else {
          console.log("in error dispatch 3");
          dispatch(addQodInterestA(null, true));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(addQodInterestA(null, true));
      });
  };
};

export const addBookmarkAC = (type) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const formdata = new FormData();
    if (type == "questionoftheday") {
      let question_id = getState().dash.question_of_the_day.id;
      formdata.append("id", question_id);
    }
    if (type == "topicoftheday") {
      let topic_id = getState().dash.topic_of_the_day.code;
      formdata.append("id", topic_id);
    }
    formdata.append("type", type);
    http
      .post(`${BASE_API_URL}/v2/addBookmark`, formdata)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(addBookmarkA(type, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(`${BASE_API_URL}/v2/addBookmark`, formdata)
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(addBookmarkA(type, false));
                      } else {
                        dispatch(addBookmarkA(null, true));
                      }
                    } else {
                      dispatch(addBookmarkA(null, true));
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(addBookmarkA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(addBookmarkA(null, true));
          }
        } else {
          console.log("in error dispatch 3");
          dispatch(addBookmarkA(null, true));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(addBookmarkA(null, true));
      });
  };
};

export const deleteBookmarkAC = (type) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const formdata = new FormData();
    if (type == "questionoftheday") {
      let question_id = getState().dash.question_of_the_day.id;
      formdata.append("id", question_id);
    }
    if (type == "topicoftheday") {
      let topic_id = getState().dash.topic_of_the_day.code;
      formdata.append("id", topic_id);
    }
    formdata.append("type", type);
    http
      .post(`${BASE_API_URL}/v2/deleteBookmark`, formdata)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(deleteBookmarkA(type, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(`${BASE_API_URL}/v2/deleteBookmark`, formdata)
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(deleteBookmarkA(type, false));
                      } else {
                        dispatch(deleteBookmarkA(null, true));
                      }
                    } else {
                      dispatch(deleteBookmarkA(null, true));
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(deleteBookmarkA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(deleteBookmarkA(null, true));
          }
        } else {
          console.log("in error dispatch 3");
          dispatch(deleteBookmarkA(null, true));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(deleteBookmarkA(null, true));
      });
  };
};

export const getDeckItemsAC = (deck_id) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    http
      .get(`${BASE_API_URL}/v2/deckItems?deck_id=${deck_id}`)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getDeckItemA(res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .get(`${BASE_API_URL}/v2/deckItems?deck_id=${deck_id}`)
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getDeckItemA(res.data, false));
                      } else {
                        dispatch(getDeckItemA(null, true));
                      }
                    } else {
                      dispatch(getDeckItemA(null, true));
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(getDeckItemA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(getDeckItemA(null, true));
          }
        } else {
          console.log("in error dispatch 3");
          dispatch(getDeckItemA(null, true));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(getDeckItemA(null, true));
      });
  };
};

export const GETDECKDATA = "GET_DECK_DATA";

export const getDeckDataA = (res, err) => {
  return {
    type: GETDECKDATA,
    res: res,
    error: err,
  };
};

export const getDeckDataAC = (deck_code) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    http
      .get(`${BASE_API_URL}/v2/getDeckData?deck_code=${deck_code}`)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getDeckDataA(res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .get(`${BASE_API_URL}/v2/getDeckData?deck_code=${deck_code}`)
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getDeckDataA(res.data, false));
                      } else {
                        dispatch(getDeckDataA(null, true));
                      }
                    } else {
                      dispatch(getDeckDataA(null, true));
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(getDeckDataA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(getDeckDataA(null, true));
          }
        } else {
          console.log("in error dispatch 3");
          dispatch(getDeckDataA(null, true));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(getDeckDataA(null, true));
      });
  };
};

export const getRecommendedDecksAC = (topic_code) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const formdata = new FormData();
    formdata.append("code", topic_code);
    http
      .post(`${BASE_API_URL}/v2/conceptSummaries`, formdata)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getRecommendedDecksA(res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(`${BASE_API_URL}/v2/conceptSummaries`, formdata)
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getRecommendedDecksA(res.data, false));
                      } else {
                        dispatch(getRecommendedDecksA(null, true));
                      }
                    } else {
                      dispatch(getRecommendedDecksA(null, true));
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(getRecommendedDecksA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(getRecommendedDecksA(null, true));
          }
        } else {
          console.log("in error dispatch 3");
          dispatch(getRecommendedDecksA(null, true));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(getRecommendedDecksA(null, true));
      });
  };
};

export const getSearchedTopicDeckAC = (code) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const formdata = new FormData();
    http
      .get(`${BASE_API_URL}/dashboard/v2/getSearchedDeckContent?code=${code}`)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getSearchedTopicDeckA(code, res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .get(
                    `${BASE_API_URL}/dashboard/v2/getSearchedDeckContent?code=${code}`
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getSearchedTopicDeckA(code, res.data, false));
                      } else {
                        dispatch(getSearchedTopicDeckA(code, null, true));
                      }
                    } else {
                      dispatch(getSearchedTopicDeckA(code, null, true));
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(getSearchedTopicDeckA(code, null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(getSearchedTopicDeckA(code, null, true));
          }
        } else {
          console.log("in error dispatch 3");
          dispatch(getSearchedTopicDeckA(code, null, true));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(getSearchedTopicDeckA(code, null, true));
      });
  };
};

export const getSearchedTopicVideoAC = (code) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const formdata = new FormData();
    http
      .get(
        `${BASE_API_URL}/dashboard/v2/getSearchedTopicContent?type=video&code=${code}`
      )
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getSearchedTopicVideoA(code, res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .get(
                    `${BASE_API_URL}/dashboard/v2/getSearchedTopicContent?type=video&code=${code}`
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getSearchedTopicVideoA(code, res.data, false));
                      } else {
                        dispatch(getSearchedTopicVideoA(code, null, true));
                      }
                    } else {
                      dispatch(getSearchedTopicVideoA(code, null, true));
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(getSearchedTopicVideoA(code, null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(getSearchedTopicVideoA(code, null, true));
          }
        } else {
          console.log("in error dispatch 3");
          dispatch(getSearchedTopicVideoA(code, null, true));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(getSearchedTopicVideoA(code, null, true));
      });
  };
};

export const getSearchedTopicDocsAC = (code) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const formdata = new FormData();
    http
      .get(
        `${BASE_API_URL}/dashboard/v2/getSearchedTopicContent?type=doc&code=${code}`
      )
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getSearchedTopicDocsA(code, res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .get(
                    `${BASE_API_URL}/dashboard/v2/getSearchedTopicContent?type=doc&code=${code}`
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getSearchedTopicDocsA(code, res.data, false));
                      } else {
                        dispatch(getSearchedTopicDocsA(code, null, true));
                      }
                    } else {
                      dispatch(getSearchedTopicDocsA(code, null, true));
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(getSearchedTopicDocsA(code, null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(getSearchedTopicDocsA(code, null, true));
          }
        } else {
          console.log("in error dispatch 3");
          dispatch(getSearchedTopicDocsA(code, null, true));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(getSearchedTopicDocsA(code, null, true));
      });
  };
};

export const getIosAppLinkAC = () => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const user = JSON.parse(localStorage.getItem("user"));
    http
      .get(
        `${BASE_API_URL}/college/v2/iOSAppRequestLink?college_id=${user.college_id}`
      )
      .then((res) => {
        if (res.status === 200) {
          if (
            res.data?.status === 200 &&
            res.data?.message !==
              "No iOS app links exist for your college! Please contact customer support."
          ) {
            dispatch(getIosAppLinkA(res.data.message, false));
          } else if (
            res.data.status === 401 &&
            res.data.message === "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .get(
                    `${BASE_API_URL}/college/iOSAppRequestLink?college_id=${user.college_id}`
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      if (
                        res.data?.status === 200 &&
                        res.data?.message !==
                          "No iOS app links exist for your college! Please contact customer support."
                      ) {
                        dispatch(getIosAppLinkA(res.data.message, false));
                      } else if (res.data?.status === 200) {
                        dispatch(getIosAppLinkA(res.data.message, true));
                      } else {
                        dispatch(getIosAppLinkA(null, true));
                      }
                    } else {
                      dispatch(getIosAppLinkA(null, true));
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(getIosAppLinkA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
                dispatch(getIosAppLinkA(null, true));
              });
          } else if (res.data?.status === 200) {
            dispatch(getIosAppLinkA(res.data?.message, true));
          } else {
            dispatch(getIosAppLinkA(null, true));
          }
        } else {
          dispatch(getIosAppLinkA(null, true));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getIosAppLinkA(null, true));
      });
  };
};
