import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import Grid from '@mui/system/Unstable_Grid';
import ScoreCard from '../../features/RemedialPath/components/ScoreCard/ScoreCard';
import RemedialHeader from '../../features/RemedialPath/components/RemedialHeader/RemedialHeader'
import RemedialContainer from '../../features/RemedialPath/components/RemedialContainer/RemedialContainer';
import RecordCard from '../../features/RemedialPath/components/RecordCard/RecordCard';
import Questionnaire from '../../features/RemedialPath/components/Questionnaire/Questionnaire';
import LeaderBoardCard from '../../features/RemedialPath/components/LeaderBoardCard/LeaderBoardCard';
import TimeAnalysisGraph from '../../features/RemedialPath/components/TimeAnalysisGraph/TimeAnalysisGraph';
import useFetch from '../../hooks/useFetch';
import { useParams } from 'react-router-dom'

import logo from './../../assets/remedial/svg/edwisely-logo.svg'
import ThemeSwitchButton from '../../components/common/ThemeSwitchButton/ThemeSwitchButton';

const PerfectScore = () => {
  const { testId } = useParams();
  const { data, loading, error } = useFetch(`/questionnaire/v2/remedialSummary?questionnaire_id=${testId}`);
  const[perfectScore ,setPerfectScore] = useState()

  useEffect(() => {
    if (data) {
      setPerfectScore(data)
    }
  }, [data, loading, error])

  return (
    <RemedialContainer>
      <Box sx={{ marginBottom: '32px' , position: 'relative' }}>
        <img src={logo} width={95} height={35} alt='logo' />
        <Box sx={{ position: 'absolute', top: '20px', right: '30px', zIndex: '100' }}>
        <ThemeSwitchButton />
      </Box>
      </Box>
      <Stack sx={{ gap: '24px' }}>
        <RemedialHeader heading={'Test Result'} testName={perfectScore?.test_details?.name} subject={perfectScore?.test_details?.subject} />
        <Grid container spacing={3}>
          <Grid xs={3}><ScoreCard variant='remedial' data={perfectScore} showButton={false} /></Grid>
          <Grid xs={3} container spacing={2} >
            <Grid xs={6}><RecordCard variant='time' value={perfectScore?.total_time} text='Total time spent by you' /></Grid>
            <Grid xs={6}><RecordCard variant='rank' value={perfectScore?.rank} text='Your Class rank' /></Grid>
            <Grid xs={6}><RecordCard variant='performance' value={perfectScore?.percentage} text='average performance' /></Grid>
            <Grid xs={6}><RecordCard variant='answered' value={perfectScore?.unanswered_count} text='unanswered count' /></Grid>
          </Grid>

          <Grid xs={3}>
            <Questionnaire title='Questionnaire' modalTitle='Questionnaire' endpoint={`/questionnaire/v2/getTestQuestions?test_id=${testId}`} />
          </Grid>
          <Grid xs={3}>
            <Stack gap={'24px'}>
              <LeaderBoardCard data={perfectScore?.leader_board} />
            </Stack>
          </Grid>
        </Grid>
        {perfectScore?.time_per_question && <TimeAnalysisGraph data={perfectScore?.time_per_question} />}
      </Stack>
    </RemedialContainer>
  )
}

export default PerfectScore