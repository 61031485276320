import { Box } from '@mui/material';
import React from 'react';
import TestSheetQuestion from './TestSheetQuestion';

// addQuestionMark -> 1 or Q1

const TestSheet = ({ data, columnWidth, columnMaxWidth = '1fr', addQuestionMark = false, boxPadding = 6, onQuestionClick, onQuestion }) => {

    console.log(data, 'asaassadds')
    return (
        <Box sx={{
            flexGrow: '1',
            display: 'grid',
            gap: '12px',
            rowGap: '12px',
            gridTemplateColumns: `repeat(auto-fit, minmax(${columnWidth}px, ${columnWidth}px))`
        }}>

            {data?.map((item, index) => (
                <TestSheetQuestion
                    key={index}
                    isVisited={item.isVisited}
                    data={item}
                    index={index}
                    onClick={onQuestionClick}
                    onQuestion={onQuestion}
                    boxPadding={boxPadding}
                    variant={item.Type} qNumber={addQuestionMark ? `Q${index + 1}` : index + 1} />
            ))}
        </Box>
    );
};

export default TestSheet;
