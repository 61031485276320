import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import './StudyContentSwiper.css'

// Import Swiper styles
// import 'swiper/swiper.min.css';
// import 'swiper/modules/scrollbar/scrollbar.min.css';
// import 'swiper/modules/navigation/navigation.min.css';


// import required modules
// import Scrollbar from 'swiper/modules/scrollbar/scrollbar';
// import Navigation from 'swiper/modules/navigation/navigation';
import RemedialStudyCard from '../RemedialStudyCard/RemedialStudyCard';
import { Box, styled } from '@mui/material';
import ArrowSvg from './../../../../assets/remedial/icons/swiper-arrow.svg'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';


const StudyContentSwiper = ({ spaceBetween = 18, slidesPerView = 5.2, arrayData, renderer }) => {


    const commonCss = {
        width: '44px',
        height: '44px',
        borderRadius: '50%',
        boxShadow: '0px 0px 13px 0px rgba(0, 0, 0, 0.10)',
    }

    // const SwiperWapper = styled('div')(({ theme }) => ({
    //     position: 'relative',
    //     '.swiper': {
    //         position: 'static;'
    //     },
    //     '.swiper-button-next': {
    //         ...commonCss,
    //         right: '-20px',
    //         backgroundColor: theme.palette.primary.contrastText,

    //         '&::after': {
    //             fontSize: '20px',
    //             color: theme.palette.primary[800]
    //         }
    //     },
    //     '.swiper-button-prev': {
    //         ...commonCss,
    //         left: '-20px',
    //         backgroundColor: theme.palette.primary.contrastText,
    //         '&::after': {
    //             fontSize: '20px',
    //             color: theme.palette.primary[800]
    //         }
    //     }
    // }));


    return (
        <Box className='study-swiper'
            sx={{
                position: 'relative',
                '.swiper': {
                    position: 'static;'
                },
                '.swiper-button-next': {
                    ...commonCss,
                    right: '-20px',
                    backgroundColor: theme => theme.palette.primary.contrastText,

                    '&::after': {
                        fontSize: '20px',
                        color: theme => theme.palette.primary[800]
                    }
                },
                '.swiper-button-prev': {
                    ...commonCss,
                    left: '-20px',
                    backgroundColor: theme => theme.palette.primary.contrastText,
                    '&::after': {
                        fontSize: '20px',
                        color: theme => theme.palette.primary[800]
                    }
                }
            }}
        >
            <Swiper
                spaceBetween={18}
                slidesPerView={5.2}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                navigation={true}
                scrollbar={{
                    hide: true,
                }}
                modules={[Scrollbar, Navigation]}
            >
                {arrayData.map((item, index) => {
                    return (
                        <SwiperSlide key={index}>{renderer(item, index)}</SwiperSlide>
                    )
                })}
            </Swiper>
        </Box>
    )
}

export default StudyContentSwiper