import React from 'react'
import { Stack } from '@mui/material'
import QuestionSheet from '../QuestionSheet/QuestionSheet'
import ResultQuestion from '../TestResultQuestionType/ResultQuestion'
import useFetch from '../../../../hooks/useFetch'
import RemedialModal from '../../../../components/common/RemedialModal/RemedialModal'

const QuestionnaireModal = ({ modalTitle, data, show, handleClose }) => {

  // const Qdata = useFetch('/questionnaire/getTestQuestions?test_id=45'); //Todo: add id in future , since data is only coming for id = 45

  // console.log(Qdata , 'QuestionData')

  return (
    <RemedialModal title={modalTitle} size={'lg'} show={show} onCloseHandle={handleClose}>
      <Stack gap={4}>
        {/* QuestionSheet */}
        <QuestionSheet boxPadding={12} data={data} columnWidth={40} addQuestionMark />
        <Stack gap={'50px'} style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {
            data.map((item, index) => {
              return (
                <ResultQuestion data={item} number={index + 1} questionType='MCQ' />
              )
            })
          }
          {/* <ResultQuestion number={1} questionType='MCQ' />
          <ResultQuestion number={2} questionType='MCQ' />
          <ResultQuestion number={3} questionType='PhotoMCQ' /> */}
        </Stack>
      </Stack>
    </RemedialModal>
  )
}

export default QuestionnaireModal