import { act } from 'react-dom/test-utils';
import {GETCOURSECONTENT, GETCOURSEDETAILS, ADDORDELETEBOOKMARK, ONUNITCHANGE, GETDECKDATA,
     OPENDECKDATA, CLOSEDECK, SUBJECTANALYSIS,SEMESTERSUBJECTANALYSIS,TREEMAPDATA,
     ALLSECTIONSGETPERMORMANCESEVENDAYSSUBDATA,ALLSECTIONSGETPERMORMANCETHIRTYDAYSSUBDATA,
     ALLSECTIONSGETPARTICIPATIONSEVENDAYSSUBDATA,ALLSECTIONSGETPARTICIPATIONTHIRTYDAYSSUBDATA
    } from '../actions/courseContentActions';
const initial_state = {
    loading_course_details: true,
    deck_data: [],
    loading_analytics_data:true,
    error_loading_analytics_data:false,
    loading_semester_analytics_data:true,
    error_loading_semester_analytics_data:false,
    loading_treemap:true,
    error_loading_treemap:false,
    loading_seven_days_all_sections_sub__performance: true,
    error_loading_seven_days_all_sections_sub_performance: false,
    loading_thirty_days_all_sections_sub_performance: true,
    error_loading_thirty_days_all_sections_sub_performance: false,
    loading_seven_days_all_sections_sub_participation: true,
    error_loading_seven_days_all_sections_sub_participation: false,
    loading_thirty_days_all_sections_sub_participation: true,
    error_loading_thirty_days_all_sections_sub_participation: false,
    loading_feedback_questions: true,
    error_loading_feedback_questions: false,
    feedback_average:null
};

function sortObj(obj) {
    return Object.keys(obj).sort().reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
  }
const reducer = (state = initial_state, action) =>{
    if (action.type === GETCOURSEDETAILS) {
        if (action.error) {
            return {
                ...state,
                error_loading_details: true,
                loading_course_details: false
            }
        }
        console.log(action);
        let data = action.response.data;
        let unit_id;
        if (data && data.units.length !== 0) {
            unit_id = data.units[0].id;
        }
        return {
            ...state,
            subject_id: action.subject_id,
            university_degree_department_id: action.user.university_degree_department_id,
            college_university_degree_department_id: action.user.college_university_degree_department_id,
            semester_id: action.user.semester_id,
            student_name: action.user.name,
            error_loading_details: false,
            loading_course_details: false,
            loading_course_content: true,
            subject_name: data.name,
            subject_description: data.description,
            subject_image: data.image,
            subject_semester_id: data.subject_semester_id,
            unit_id: unit_id,
            units: data.units,
            csm_Id:action.csm_Id,
        }
    }
    if (action.type === GETCOURSECONTENT) {
        if (action.error) {
            return {
                ...state,
                error_loading_content : true,
                loading_course_content: false
            }
        }
        console.log(action);
        console.log(action.response.decks);
        return {
            ...state,
            content: action.response.content ? [...action.response.content] : null,
            decks: action.response.decks ? [...action.response.decks] : null,
            loading_course_content: false,
            error_loading_content: false
        }
    }
    if (action.type === ADDORDELETEBOOKMARK) {
        if (action.error) {
            return {
                ...state,
                error_add_or_delete_bookmark: true
            }
        }
        if (action.bookmark_type == 'decks') {
            let decks = [...state.decks];
            let deck_index = decks.findIndex((deck)=> {
                return deck.id == action.learning_content_id
            })
            decks[deck_index].bookmarked = action.bookmarked;
            return {
                ...state,
                decks: decks
            }
        }
        let topics = [...state.content];
        let topic_index = topics.findIndex((topic)=>{
            return topic.id == action.topic_id
        });

        console.log(topic_index)

        let content_index = topics[topic_index].learning_content.findIndex((c)=> {
            return c.material_id == action.learning_content_id
        });

        let content = {...topics[topic_index].learning_content[content_index]};
        content.bookmarked = action.bookmarked;
        topics[topic_index].learning_content[content_index] = content;

        console.log(topics)
        return {
            ...state,
            content: topics
        }
    }
    if (action.type == ONUNITCHANGE) {
        if (action.error) {
            return {
                ...state,
                error_loading_content : true,
                loading_course_content: false
            }
        }
        console.log(action);
        if (action.unit_id == state.unit_id) {
            return {
                ...state,
                unit_id: null,
                content: [],
                loading_course_content: false,
                error_loading_content: false
            }
        }
        return {
            ...state,
            unit_id: action.unit_id,
            content: action.response.content ? [...action.response.content] : null,
            decks: action.response.decks ? [...action.response.decks] : null,
            loading_course_content: false,
            error_loading_content: false
        }
    }
    if (action.type == GETDECKDATA) {
        let response = action.res;
        // let data = response.data.filter(i => i.type !== 'cs');
        return {
            ...state,
            deck_data: response.data,
            deck: action.deck

        }
    }
    if (action.type == OPENDECKDATA) {
        return {
            ...state,
            open_deck: true
        }
    }
    if (action.type == CLOSEDECK ){
        return {
            ...state,
            open_deck: false,
            deck_data: null,
            deck: null
        }
    }
    if (action.type === SUBJECTANALYSIS) {
        if (action.error) {
            return {
                ...state,
                loading_analytics_data:false,
                error_loading_analytics_data:true,
            }
        }
      
        return {
            ...state,
            loading_analytics_data:false,
            error_loading_analytics_data:false,
            analytics_data: action.response
        }
    }
    if (action.type === SEMESTERSUBJECTANALYSIS) {
        if (action.error) {
            return {
                ...state,
                loading_semester_analytics_data:false,
                error_loading_semester_analytics_data:true,
            }
        }
      
        return {
            ...state,
            loading_semester_analytics_data:false,
            error_loading_semester_analytics_data:false,
            semester_analytics_data: action.response
        }
    }
    if (action.type === TREEMAPDATA) {
        if (action.error) {
            return {
                ...state,
                loading_treemap:false,
                error_loading_treemap:true,
            }
        }
       let all_units_data=action.response.data;
       let tree_map_data=[];
       let unit_data=[];
       let filter={};
    //    for (let index = 0; index < all_units_data.length; ++index) {
       
    //     for (let index1 = 0; index1 < all_units_data[index].topics.length; ++index1) {
    //         unit_data.push({x:all_units_data[index].topics[index1].topic_name,y:all_units_data[index].topics[index1].performance.avg_percentage_scored})
           
    //     }
    //     tree_map_data.push([unit_data])
    // }
        all_units_data.forEach(units => console.log("hello")  );
        all_units_data.forEach(function(units){ 
        units.topics.forEach(function(topics) {
       if (topics.performance.avg_percentage_scored!=0) {
        unit_data.push({x:topics.topic_name,y:topics.performance.avg_percentage_scored})      
        }
       
    });
    unit_data.sort(function(a, b) {
        return  parseFloat(b.y)-parseFloat(a.y);
    });
    let unitsdata = {
        unit_id:units.unit_id,
        data: unit_data
      };
     
       tree_map_data.push(unitsdata)
       unit_data=[];
    });

    //    tree_map_data=all_units_data.map((unidetails,index)=> {
    //      return unidetails.topics;
    //    })
       console.log(tree_map_data)
        return {
            ...state,
            loading_treemap:false,
            error_loading_treemap:false,
            tree_map_data: tree_map_data
        }
    }
    if (action.type == "ALL_SECTIONS_GET_PERMORMANCE_SEVEN_DAYS_SUB_DATA") {
        if (action.error) {
          return {
            ...state,
            loading_seven_days_all_sections_sub_performance: false,
            error_loading_seven_days_all_sections_sub_performance: true,
          };
        } else {
          let response = action.res;
          return {
            ...state,
            loading_seven_days_all_sections_sub_performance: false,
            error_loading_seven_days_all_sections_sub_performance: false,
            allsections_sevendays_sub_performance:response,
          };
        }
      }
      if (action.type == "ALL_SECTIONS_GET_PERMORMANCE_THIRTY_DAYS_SUB_DATA") {
        if (action.error) {
          return {
            ...state,
            loading_thirty_days_all_sections_sub_performance: false,
            error_loading_thirty_days_all_sections_sub_performance: true,
          };
        } else {
          let response = action.res;
          return {
            ...state,
            loading_thirty_days_all_sections_sub_performance: false,
            error_loading_thirty_days_all_sections_sub_performance: true,
            allsections_thirtydays_sub_performance:response,
          };
        }
      }
      if (action.type == "ALL_SECTIONS_GET_PARTICIPATION_SEVEN_DAYS_SUB_DATA") {
        if (action.error) {
          return {
            ...state,
            loading_seven_days_all_sections_sub_participation: false,
            error_loading_seven_days_all_sections_sub_participation: true,
          };
        } else {
          let response = action.res;
          return {
            ...state,
            loading_seven_days_all_sections_sub_participation: false,
            error_loading_seven_days_all_sections_sub_participation: false,
            allsections_sevendays_sub_participation:response,
          };
        }
      }
      if (action.type == "ALL_SECTIONS_GET_PARTICIPATION_THIRTY_DAYS_SUB_DATA") {
        if (action.error) {
          return {
            ...state,
            loading_thirty_days_all_sections_sub_participation: false,
            error_loading_thirty_days_all_sections_sub_participation: true,
          };
        } else {
          let response = action.res;
          return {
            ...state,
            loading_thirty_days_all_sections_sub_participation: false,
            error_loading_thirty_days_all_sections_sub_participation: false,
            allsections_thirtydays_sub_participation:response,
          };
        }
      }
      if (action.type == "FEEDBACK_DATA") {
        if (action.error) {
          return {
            ...state,
            loading_feedback_questions: false,
            error_loading_feedback_questions: true,
          };
        } else {
          let response = action.response;
          console.log(action.response)
          let getallquestions=action.response[0].questions;
          let getaverage=0;
          let averagepercentage;
          for (let index = 0; index < getallquestions.length; index++) {
            if (getallquestions[index].value!=="") {
              getaverage +=getallquestions[index].value
            }
           
          }
          averagepercentage=getaverage/getallquestions.length;
          return {
            ...state,
            loading_feedback_questions: false,
            error_loading_feedback_questions: false,
            feedback_questions_data:response,
            feedback_average:averagepercentage,
          };
        }
      }
    if (action.type == 'REMOVE_STATE') {
        return {
            loading_course_details: true,
        }
    }
    if (action.type === "CLEAR_TEST_STATE") {
        return initial_state;
      }
      if (action.type === "CLEAR_FEEDBACK_STATE") {
        return {
            ...state,
            loading_feedback_questions: true,
            error_loading_feedback_questions: false,
            feedback_questions_data:null,
            feedback_average:null,
        }
      }
      if (action.type === "CLEAR_UNIT_STATE") {
        return {
            ...state,
            loading_course_details: false,
            content:null,
            deck_data: [],
        }
      }
    return state;
}

export default reducer;