import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import RedmedialButton from '../RemedialButtons/RedmedialButton';
// import { useTheme } from 'styled-components';
import SpinWheel from '../SpinWheel/SpinWheel';
import { useRemedialContext } from '../../hooks/useRemedial';
import useFetch from '../../../../hooks/useFetch';
import { fetchDataFromApi } from '../../../../helpers/Api';
import { useParams } from 'react-router-dom'
import { updateBannerApi } from '../../../../helpers/updateBanner';
import RemedialModal from '../../../../components/common/RemedialModal/RemedialModal';



const SpinWheelModal = ({ show, onCloseHandle , updateBanner}) => {
    const { testId } = useParams();
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const { updateBannerIndex, spinWheelData , setSpinPrizeNumber , bannerData, setBannerData } = useRemedialContext();

    const handleSpinClick = () => {
        if (!mustSpin) {
            const newPrizeNumber = Math.floor(Math.random() * spinWheelData.length);
            setPrizeNumber(newPrizeNumber);
            setSpinPrizeNumber(newPrizeNumber);
            setMustSpin(true);
        }
    }


    const spinWrapper = {
        transform: 'rotate(-45deg)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }


    const handleOnSpinStop = async () => {
        // updateBannerApi(testId ,bannerData.stages[2].id)
        fetchDataFromApi(`/questionnaire/setSpinWheelCategory?questionnaire_id=${testId}&category_id=${spinWheelData[prizeNumber].id}`)
       
        const res = await updateBannerApi(testId, bannerData.stages[2].id);
        const { status } = await res.data
        if (status === 200) {
            const { data } = await fetchDataFromApi(`/questionnaire/v2/getRemedialPathStages?test_id=${testId}`);

            setBannerData(data)
        }
        updateBannerIndex(2)
        // updateBanner()
        // console.log(spinWheelData[prizeNumber].id , 'SpinWheel Prize ID')
    }


    return (
        <RemedialModal show={show} title={'Spin Wheel'} onCloseHandle={onCloseHandle} size={'sm'}>
            {spinWheelData && <SpinWheel
                mustSpin={mustSpin}
                data={spinWheelData}
                prizeNumber={prizeNumber}
                onStopSpinHandle={() => handleOnSpinStop()}
                setSpinDuration={0.4}
            />}
            <Stack direction={'row'} alignContent={'center'} justifyContent={'center'} sx={{ marginTop: '24px' }}>
                <RedmedialButton disable={mustSpin} onClick={handleSpinClick}>Spin the Wheel</RedmedialButton>
            </Stack>
        </RemedialModal>
    )
}

export default SpinWheelModal