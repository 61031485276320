import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {LinearProgress,Tooltip} from '@mui/material';


const BorderLinearProgress = styled(LinearProgress)(({ theme,width }) => ({
  height: '5px',
  borderRadius: '10px',
  width:`${width}px`,
  backgroundColor: theme.palette.grey[200],
}));



export default function MuiCustomLinearProgress({ value, width, type, tooltip, ...props }) {
  return (
    tooltip ? (
      <Tooltip title={type === 'career' ? `Career fits ${value}%` : `Skill fits ${value}%`} placement="top-start">
        <BorderLinearProgress variant="determinate" value={value} width={width } {...props} />
      </Tooltip>
    ) : (
      <BorderLinearProgress variant="determinate" value={value} width={ width } {...props} />
    )
  );
}
