import { Component } from "react";
import { getLoggedInUser } from "../../../helpers/Auth";

class Report extends Component{

    render(){
        console.log(this.props);
        const user = getLoggedInUser();
        return(
            <div id="questionReport" className="m-4">
                <div className="row text-left no-gutters">
                    <div className="col-4 px-3" style={{border: '1px solid black'}}>
                        <p className="m-0">Test Name - {this.props.test.name}</p>
                        <p>{this.props.test.faculty_name} - {this.props.test.faculty_department}</p>
                        <p className="m-0"></p>
                    </div>
                    <div className="col-4"></div>
                    <div className="col-4 px-3" style={{border: '1px solid black'}}>
                        <p className="m-0">Student Name - {user.name}</p>
                        <p className="m-0">Roll Number - {user.roll_number}</p>
                        <p>{user.department_name} - {user.section_name}</p>
                    </div>
                </div>
                <p className="text-left my-3 p-2" style={{border: '1px solid black'}}>{this.props.problem?.title}</p>
                <p className="text-left my-3 p-2" style={{border: '1px solid black'}} dangerouslySetInnerHTML={{__html: this.props.problem?.body}}></p>
                <div className="row text-left no-gutters">
                    <div className="col-7 p-3" style={{border: '1px solid black'}}>
                        <p>Complete Solution</p>
                        <p>{this.props.problem.source_code}</p>
                    </div>
                    <div className="col-5 px-3 text-left">
                        <table className="w-100 p-2" style={{border: '1px solid black'}}>
                            <tbody>
                                <tr key={0}><th className="px-2" colSpan={3}>Test &nbsp;&nbsp;Cases</th></tr>
                                {this.props.problem.test_cases.map((tc,i) => <tr key={tc.test_case_id}>
                                    <td className="px-2" width="5%">{i+1}{`)`}</td>
                                    <td className="px-2">{tc.input}</td>
                                    <td className="px-2">{tc.output}</td>
                                    <td className="px-2" width="10%">
                                        {/* {tc.status === "accepted" ? <img src="/images/Correct_answer.svg" alt="correct" width="20" /> : <img src="/images/Wrong_answer.svg" alt="wrong" width="20" />} */}
                                        {tc.status}
                                        </td>
                                </tr>)}
                            </tbody>
                        </table>
                        <table className="w-100 my-4 p-2 table table-bordered">
                            <tbody>
                                <tr>
                                    <th className="px-2" style={{border: '1px solid black'}}>Attempts &nbsp;&nbsp;Made - {this.props.problem.student_summary_compilations}</th>
                                    <th className="px-2" style={{border: '1px solid black'}}>Average &nbsp;&nbsp;Run &nbsp;Time - {this.props.problem.student_summary_time}</th>
                                </tr>
                                <tr>
                                    <th className="px-2" style={{border: '1px solid black'}}>Incorrect &nbsp;&nbsp;Answers - {this.props.problem.coding_time.filter(ct => ct.status !== 1).length}</th>
                                    <th className="px-2" style={{border: '1px solid black'}}>Compilation &nbsp;&nbsp;Errors - {this.props.problem.wrong_answers}</th>
                                </tr>
                                {this.props.problem.marks_scored ? 
                                    <tr>
                                        <th className="px-2" style={{border: '1px solid black'}} colSpan={2}>Marks - {this.props.marks_scored}/{this.props.marks_assigned}</th>
                                    </tr>
                                    : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                
            </div>
        );
    }
}
export default Report;