import styled from '@emotion/styled';
import { keyframes } from '@mui/system';

export const treesLayer1 = keyframes`
from {
  transform: translateX(calc(100% + 50%)); 

}
to {
    transform: translateX(calc(100% - 50%)); 
}
`;



export const BannerAnimationWraper = styled('div')(({ theme }) => ({
    background: theme.palette.externalInfo[200],
    top: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    '.banner-layer': {
        position: 'absolute',
        bottom: 0,
        left: 0,
    },
    '.banner-layer.cloud': {
        position: 'absolute',
        top: 10,
        left: 0,
        animation: `${treesLayer1} 8s  linear infinite !important`
    },
    '.banner-layer.banner-layer-1': {
        left: 0,
        animation: `${treesLayer1} 5s  linear infinite`
    },
    '.banner-layer.banner-layer-2': {
        left: -950,
        animation: `${treesLayer1} 5s  linear infinite`
    },
    '.banner-layer.banner-layer-3': {
        left: -1900,
        animation: `${treesLayer1} 5s  linear infinite`
    },
}));