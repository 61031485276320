import React from 'react'
import styled from 'styled-components';
import McqChoiceGrid from './Question-Grid/McqChoiceGrid';
import QuestionHeader from '../QuestionHeader/QuestionHeader';

// questionType -> 'MCQ' , 'PhotoMCQ'


export const QuestionWraper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '16px',
    borderRadius: '8px'
}));


const ResultQuestion = ({ data, questionType = 'MCQ', number = 1 }) => {
    return (
        <QuestionWraper>
            <QuestionHeader number={number} question={data.name} />
            <McqChoiceGrid data={data} />
        </QuestionWraper>
    )
}

export default ResultQuestion