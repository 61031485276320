import React, {useEffect, useState} from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperChild from './SwiperChild';

// Install Autoplay module
SwiperCore.use([Autoplay]);

const AutomaticSwiper = ({slides_data, type}) => {
  

  return (
    <Swiper
      className="custom-swiper-mui-container"
      spaceBetween={20}
      slidesPerView={1}
      loop={false}
      speed={450}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      effect="fade"
      direction="horizontal"
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      {slides_data?.length > 0 && slides_data.map((slide, index) => (
        <SwiperSlide key={index}>
          {type === 'text-box' ? <SwiperChild data={slide} /> : slide}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default AutomaticSwiper;