import axios from '../../interceptor';

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
let CORS = 'https://cors-anywhere.herokuapp.com/';

export function setGlobalToken() {
    try {
        let token = localStorage.getItem('token');
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    } catch (error) {
      console.log(error);
    }
};

const api_item_dictionary = {
    ADD_SKILLS: 'addStudentSkills',
    REMOVE_SKILLS: 'removeStudentSkills',
    UPDATE_SKILLS: 'updateStudentSkills',
    ADD_LANGUAGE: 'addStudentLanguage',
    REMOVE_LANGUAGE: 'removeStudentLanguage',
    UPDATE_LANGUAGE: 'updateStudentLanguage',
    ADD_EDUCATION: 'addEducation',
    REMOVE_EDUCATION: 'deleteEducation',
    UPDATE_EDUCATION: 'updateEducation',
    ADD_EXPERIENCE: 'addExperience',
    REMOVE_EXPERIENCE: 'deleteExperience',
    UPDATE_EXPERIENCE: 'updateExperience',
    ADD_CERTIFICATE: 'addCertificate',
    REMOVE_CERTIFICATE: 'deleteCertificate',
    UPDATE_CERTIFICATE: 'updateCertificate',
    ADD_PROJECT: 'addProject',
    REMOVE_PROJECT: 'deleteProject',
    UPDATE_PROJECT: 'updateProject'
}


export const GETPROFILEDATA = 'GET_PROFILE_DATA';
export const GETCLASSMATESLIST = 'GET_CLASSMATES_LIST';

export const UPDATEPROFILE = 'UPDATE_PROFILE';
export const UPDATEMOBILE = 'UPDATE_MOBILE';
export const OTPVERIFY = 'OTP_VERIFY';
export const UPDATEEMAIL = 'UPDATE_EMAIL';
export const UPDATEPROFILEPIC = 'UPDATE_PROFILE_PIC';
export const ADDVIDEORESUME = 'ADD_VIDEO_RESUME';
export const DELETEVIDEORESUME = 'DELETE_VIDEO_RESUME';

export const ADDSKILLS = 'ADD_SKILLS';
export const REMOVESKILLS = 'REMOVE_SKILLS';
export const UPDATESKILLS = 'UPDATE_SKILLS';
export const LISTSKILLS = 'LIST_ALL_SKILLS';

export const ADDLANGUAGE = 'ADD_LANGUAGE';
export const REMOVELANGUAGE = 'REMOVE_LANGUAGE';
export const UPDATELANGUAGE = 'UPDATE_LANGUAGE';
export const LISTLANGUAGES = 'LIST_ALL_LANGUAGES';

export const ADDEDUCATION = 'ADD_EDUCATION';
export const REMOVEEDUCATION = 'REMOVE_EDUCATION';
export const UPDATEEDUCATION = 'UPDATE_EDUCATION';


export const REMOVESUMMARY= 'REMOVE_SUMMARY';
export const UPDATESUMMARY = 'UPDATE_SUMMARY';

export const ADDEXPERIENCE = 'ADD_EXPERIENCE';
export const REMOVEEXPERIENCE = 'REMOVE_EXPERIENCE';
export const UPDATEEXPERIENCE = 'UPDATE_EXPERIENCE';

export const ADDCERTIFICATE = 'ADD_CERTIFICATE';
export const REMOVECERTIFICATE = 'REMOVE_CERTIFICATE';
export const UPDATECERTIFICATE = 'UPDATE_CERTIFICATE';

export const ADDPROJECT = 'ADD_PROJECT';
export const REMOVEPROJECT = 'REMOVE_PROJECT';
export const UPDATEPROJECT = 'UPDATE_PROJECT';

export const getProfileDataA = (res, err) => {
    return {
        type: GETPROFILEDATA,
        res: res,
        error: err
    }
}

export const getClassmatesDataA = (res, err) => {
    return {
        type: GETCLASSMATESLIST,
        res: res,
        error: err
    }
}

export const getLanguagesListA = (res, err) => {
    return {
        type: LISTLANGUAGES,
        res: res,
        error: err
    }
}

export const getSkillsListA = (res, err) => {
    return {
        type: LISTSKILLS,
        res: res,
        error: err
    }
}

export const updateProfileA = (res, err, name, gender) => {
    return {
        type: UPDATEPROFILE,
        res: res,
        error: err,
        name: name,
        gender: gender
    }
}

export const updateMobileA = (res, err, mobile) => {
    return {
        type: UPDATEMOBILE,
        res: res,
        error: err,
        mobile: mobile
    }
}

export const verifyOtpA = (res, err, verified) => {
    return {
        type: OTPVERIFY,
        type: UPDATEMOBILE,
        res: res,
        error: err,
        mobile_verified: verified
    }
}

export const updateEmailA = (res, err, email) => {
    return {
        type: UPDATEEMAIL,
        res: res,
        error: err,
        email: email
    }
}

export const updateProfilePicA = (res, err, pic) => {
    return {
        type: UPDATEPROFILEPIC,
        res: res,
        error: err, 
        pic: pic
    }
}

export const addVideoResumeA = (res, err, video) => {
    return {
        type: ADDVIDEORESUME,
        res: res,
        error: err,
        video: video
    }
} 

export const deleteVideoResumeA = (res, err) => {
    return {
        type: DELETEVIDEORESUME,
        res: res,
        error: err
    }
} 

export const itemUpdateRemoveAddA = (res, err, type, item) => {
    return {
        type: type,
        res: res,
        error: err,
        item: item
    }
} 

export const updateSummaryA = (res, err, summary) => {
    return {
        type: UPDATESUMMARY,
        res: res,
        error: err,
        summary: summary
    }
} 

export const removeSummaryA = (res, err) => {
    return {
        type: REMOVESUMMARY,
        res: res,
        error: err,
    }
} 


export const updateToken = () => {
    let refresh_token = localStorage.getItem('refresh_token');
    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
    http.get(`${BASE_API_URL}/auth/refresh`)
    .then((refres)=> {
        localStorage.setItem('token', refres.data.token);
        setGlobalToken();
        return true 
    })
    .catch((error)=> {
        console.log(error)
        return false
    })
}

export const getProfileDataAC = () => {
    setGlobalToken();
    return (dispatch, getState) => {
        http.post(`${BASE_API_URL}/user/v2/getProfile`)
        .then((res)=> {
            console.log(res);
            if (res.status == 200) {
                if (res.data.status == 200) {
                    console.log('inside data status ');
                    dispatch(getProfileDataA(res.data, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    if (updateToken) {
                        http.post(`${BASE_API_URL}/user/v2/getProfile`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200) {
                                    dispatch(getProfileDataA(res.data, false));
                                } else {
                                    dispatch(getProfileDataA(null, true));
                                }
                            } else {
                                dispatch(getProfileDataA(null, true));
                            }
                        })
                    } else {
                        dispatch(getProfileDataA(null, true));
                    }                        
                } else {
                    dispatch(getProfileDataA(null, true));
                }
            } else {
                dispatch(getProfileDataA(null, true));
            }
        })
        .catch((err)=> {
            console.log(err);
            dispatch(getProfileDataA(null, true));

        })
    }
}

export const updateProfileDataAC = (name, gender) => {
    setGlobalToken();
    return (dispatch, getState) => {
        const formdata = new FormData();
        formdata.append('name', name);
        formdata.append('gender', gender);

        http.post(`${BASE_API_URL}/user/v2/updateProfile`, formdata)
        .then((res)=> {
            console.log(res);
            if (res.status == 200) {
                if (res.data.status == 200) {
                    console.log('inside data status ');
                    dispatch(updateProfileA(res.data, false, name, gender));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    if (updateToken) {
                    http.post(`${BASE_API_URL}/user/v2/updateProfile`, formdata)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200) {
                                    dispatch(updateProfileA(res.data, false, name, gender));
                                } else {
                                    dispatch(updateProfileA(null, true));
                                }
                            } else {
                                dispatch(updateProfileA(null, true));
                            }
                        })
                    } else {
                        dispatch(updateProfileA(null, true));
                    }                        
                } else {
                    dispatch(updateProfileA(null, true));
                }
            } else {
                dispatch(updateProfileA(null, true));
            }
        })
        .catch((err)=> {
            console.log(err);
            dispatch(updateProfileA(null, true));

        })
    }
}


export const updateMobileAC = (mobile) => {
    setGlobalToken();
    return (dispatch, getState) => {
        const formdata = new FormData();
        formdata.append('mobile', mobile);

        http.post(`${BASE_API_URL}/user/v2/updateMobile`, formdata)
        .then((res)=> {
            console.log(res);
            if (res.status == 200) {
                if (res.data.status == 200) {
                    console.log('inside data status ');
                    dispatch(updateMobileA(res.data, false, mobile));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    if (updateToken) {
                    http.post(`${BASE_API_URL}/user/v2/updateMobile`, formdata)
                        .then((res)=> {
                            console.log(res);
                            if (res.status === 200) {
                                if (res.data.status === 200) {
                                    dispatch(updateMobileA(res.data, false, mobile));
                                } else {
                                    dispatch(updateMobileA(res.data, true));
                                }
                            } else {
                                dispatch(updateMobileA(null, true));
                            }
                        })
                    } else {
                        dispatch(updateMobileA(null, true));
                    }                        
                } else {
                    dispatch(updateMobileA(res.data, true));
                }
            } else {
                dispatch(updateMobileA(null, true));
            }
        })
        .catch((err)=> {
            console.log(err);
            dispatch(updateMobileA(null, true));

        })
    }
}


export const verifyOtpAC = (mobile, otp) => {
    setGlobalToken();
    return (dispatch, getState) => {
        http.post(`${BASE_API_URL}/user/v2/otpVerify?otp=${otp}&mobile=${mobile}`)
        .then((res)=> {
            console.log(res);
            if (res.status == 200) {
                if (res.data.status == 200) {
                    console.log('inside data status ');
                    dispatch(verifyOtpA(res.data, false, 1));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    if (updateToken) {
                    http.post(`${BASE_API_URL}/user/v2/otpVerify?otp=${otp}&mobile=${mobile}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200) {
                                    dispatch(verifyOtpA(res.data, false, 1));
                                } else {
                                    dispatch(verifyOtpA(null, true, 0));
                                }
                            } else {
                                dispatch(verifyOtpA(null, true, 0));
                            }
                        })
                    } else {
                        dispatch(verifyOtpA(null, true, 0));
                    }                        
                } else {
                    dispatch(verifyOtpA(null, true, 0));
                }
            } else {
                dispatch(verifyOtpA(null, true, 0));
            }
        })
        .catch((err)=> {
            console.log(err);
            dispatch(verifyOtpA(null, true, 0));

        })
    }
}

export const updateEmailAC = (email) => {
    setGlobalToken();
    return (dispatch, getState) => {
        const formdata = new FormData();
        formdata.append('email', email);

        http.post(`${BASE_API_URL}/user/v2/updateEmail`, formdata)
        .then((res)=> {
            console.log(res);
            if (res.status === 200) {
                if (res.data.status === 200) {
                    console.log('inside data status ');
                    dispatch(updateEmailA(res.data, false, email));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    if (updateToken) {
                    http.post(`${BASE_API_URL}/user/v2/updateEmail`, formdata)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200) {
                                    dispatch(updateEmailA(res.data, false, email));
                                } else {
                                    dispatch(updateEmailA(null, true));
                                }
                            } else {
                                dispatch(updateEmailA(null, true));
                            }
                        })
                    } else {
                        dispatch(updateEmailA(null, true));
                    }                        
                } else {
                    dispatch(updateEmailA(null, true));
                }
            } else {
                dispatch(updateEmailA(null, true));
            }
        })
        .catch((err)=> {
            console.log(err);
            dispatch(updateEmailA(null, true));

        })
    }
}

export const updateProfilePicAC = (profile_pic) => {
    setGlobalToken();
    return (dispatch, getState) => {
        const formdata = new FormData();
        formdata.append('profile_pic', profile_pic);

        http.post(`${BASE_API_URL}/user/v2/updateProfilePic`, formdata)
        .then((res)=> {
            console.log(res);
            if (res.status === 200) {
                if (res.data.status === 200) {
                    console.log('inside data status ');
                    dispatch(updateProfilePicA(res.data, false, profile_pic));
                } else if (res.data.status === 401 && res.data.message == 'Signature expired. Please log in again.') {
                    if (updateToken) {
                    http.post(`${BASE_API_URL}/user/v2/updateProfilePic`, formdata)
                        .then((res)=> {
                            if (res.status === 200) {
                                if (res.data.status === 200) {
                                    dispatch(updateProfilePicA(res.data, false, profile_pic));
                                } else {
                                    dispatch(updateProfilePicA(null, true));
                                }
                            } else {
                                dispatch(updateProfilePicA(null, true));
                            }
                        })
                    } else {
                        dispatch(updateProfilePicA(null, true));
                    }                        
                } else {
                    dispatch(updateProfilePicA(null, true));
                }
            } else {
                dispatch(updateProfilePicA(null, true));
            }
        })
        .catch((err)=> {
            console.log(err);
            dispatch(updateProfilePicA(null, true));

        })
    }
}

export const addVideoResumeAC = (video) => {
    setGlobalToken();
    return (dispatch, getState) => {
        const formdata = new FormData();
        formdata.append('resume_video', video);

        http.post(`${BASE_API_URL}/user/v2/addVideoResume`, formdata)
        .then((res)=> {
            console.log(res);
            if (res.status == 200) {
                if (res.data.status == 200) {
                    console.log('inside data status ');
                    dispatch(addVideoResumeA(res.data, false, video));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    if (updateToken) {
                    http.post(`${BASE_API_URL}/user/v2/addVideoResume`, formdata)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200) {
                                    dispatch(addVideoResumeA(res.data, false, video));
                                } else {
                                    dispatch(addVideoResumeA(null, true));
                                }
                            } else {
                                dispatch(addVideoResumeA(null, true));
                            }
                        })
                    } else {
                        dispatch(addVideoResumeA(null, true));
                    }                        
                } else {
                    dispatch(addVideoResumeA(null, true));
                }
            } else {
                dispatch(addVideoResumeA(null, true));
            }
        })
        .catch((err)=> {
            console.log(err);
            dispatch(addVideoResumeA(null, true));

        })
    }
}

export const deleteVideoResumeAC = () => {
    setGlobalToken();
    return (dispatch, getState) => {

        http.post(`${BASE_API_URL}/user/v2/deleteVideoResume`)
        .then((res)=> {
            console.log(res);
            if (res.status == 200) {
                if (res.data.status == 200) {
                    console.log('inside data status ');
                    dispatch(deleteVideoResumeA(res.data, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    if (updateToken) {
                    http.post(`${BASE_API_URL}/user/v2/deleteVideoResume`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200) {
                                    dispatch(deleteVideoResumeA(res.data, false));
                                } else {
                                    dispatch(deleteVideoResumeA(null, true));
                                }
                            } else {
                                dispatch(deleteVideoResumeA(null, true));
                            }
                        })
                    } else {
                        dispatch(deleteVideoResumeA(null, true));
                    }                        
                } else {
                    dispatch(deleteVideoResumeA(null, true));
                }
            } else {
                dispatch(deleteVideoResumeA(null, true));
            }
        })
        .catch((err)=> {
            console.log(err);
            dispatch(deleteVideoResumeA(null, true));

        })
    }
}


export const itemUpdateRemoveAddAC = (item, formdata) => {
    setGlobalToken();
    return (dispatch, getState) => {
        http.post(`${BASE_API_URL}/user/v2/${api_item_dictionary[item.type]}`, formdata)
        .then((res)=> {
            console.log(res);
            if (res.status == 200) {
                if (res.data.status == 200) {
                    console.log('inside data status ');
                    dispatch(itemUpdateRemoveAddA(res.data, false,item.type, item));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    if (updateToken) {
                        http.post(`${BASE_API_URL}/user/v2/${api_item_dictionary[item.type]}`, formdata)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200) {
                                    dispatch(itemUpdateRemoveAddA(res.data, false,item.type, item));
                                } else {
                                    dispatch(itemUpdateRemoveAddA(null, true, item.type));
                                }
                            } else {
                                dispatch(itemUpdateRemoveAddA(null, true, item.type));
                            }
                        })
                    } else {
                        dispatch(itemUpdateRemoveAddA(null, true, item.type));
                    }                        
                } else {
                    dispatch(itemUpdateRemoveAddA(null, true, item.type));
                }
            } else {
                dispatch(itemUpdateRemoveAddA(null, true, item.type));
            }
        })
        .catch((err)=> {
            console.log(err);
            dispatch(addVideoResumeA(null, true));

        })
    }
}

export const getClassmatesDataAC = () => {
    setGlobalToken();
    return (dispatch, getState) => {
        http.post(`${BASE_API_URL}/user/v2/listClassMates`)
        .then((res)=> {
            console.log(res);
            if (res.status == 200) {
                if (res.data.status == 200) {
                    console.log('inside data status ');
                    dispatch(getClassmatesDataA(res.data, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    if (updateToken) {
                        http.post(`${BASE_API_URL}/user/v2/listClassMates`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200) {
                                    dispatch(getClassmatesDataA(res.data, false));
                                } else {
                                    dispatch(getClassmatesDataA(null, true));
                                }
                            } else {
                                dispatch(getClassmatesDataA(null, true));
                            }
                        })
                    } else {
                        dispatch(getClassmatesDataA(null, true));
                    }                        
                } else {
                    dispatch(getClassmatesDataA(null, true));
                }
            } else {
                dispatch(getClassmatesDataA(null, true));
            }
        })
        .catch((err)=> {
            console.log(err);
            dispatch(getClassmatesDataA(null, true));

        })
    }
}


export const updateSummaryAC = (summary) => {
    setGlobalToken();
    return (dispatch, getState) => {
        const formdata = new FormData();
        formdata.append('summary', summary);

        http.post(`${BASE_API_URL}/user/v2/updateSummary`, formdata)
        .then((res)=> {
            console.log(res);
            if (res.status == 200) {
                if (res.data.status == 200) {
                    console.log('inside data status ');
                    dispatch(updateSummaryA(res.data, false, summary));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    if (updateToken) {
                    http.post(`${BASE_API_URL}/user/v2/updateSummary`, formdata)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200) {
                                    dispatch(updateSummaryA(res.data, false, summary));
                                } else {
                                    dispatch(updateSummaryA(null, true));
                                }
                            } else {
                                dispatch(updateSummaryA(null, true));
                            }
                        })
                    } else {
                        dispatch(updateSummaryA(null, true));
                    }                        
                } else {
                    dispatch(updateSummaryA(null, true));
                }
            } else {
                dispatch(updateSummaryA(null, true));
            }
        })
        .catch((err)=> {
            console.log(err);
            dispatch(updateSummaryA(null, true));

        })
    }
}

export const removeSummaryAC = () => {
    setGlobalToken();
    return (dispatch, getState) => {

        http.post(`${BASE_API_URL}/user/v2/deleteSummary`)
        .then((res)=> {
            console.log(res);
            if (res.status == 200) {
                if (res.data.status == 200) {
                    console.log('inside data status ');
                    dispatch(removeSummaryA(res.data, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    if (updateToken) {
                    http.post(`${BASE_API_URL}/user/v2/deleteSummary`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200) {
                                    dispatch(removeSummaryA(res.data, false));
                                } else {
                                    dispatch(removeSummaryA(null, true));
                                }
                            } else {
                                dispatch(removeSummaryA(null, true));
                            }
                        })
                    } else {
                        dispatch(removeSummaryA(null, true));
                    }                        
                } else {
                    dispatch(removeSummaryA(null, true));
                }
            } else {
                dispatch(removeSummaryA(null, true));
            }
        })
        .catch((err)=> {
            console.log(err);
            dispatch(removeSummaryA(null, true));

        })
    }
}

export const getLanguagesListAC = () => {
    setGlobalToken();
    return (dispatch, getState) => {
        http.post(`${BASE_API_URL}/user/v2/listAllLanguages`)
        .then((res)=> {
            console.log(res);
            if (res.status == 200) {
                if (res.data.status == 200) {
                    console.log('inside data status ');
                    dispatch(getLanguagesListA(res.data, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    if (updateToken) {
                        http.post(`${BASE_API_URL}/user/v2/listAllLanguages`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200) {
                                    dispatch(getLanguagesListA(res.data, false));
                                } else {
                                    dispatch(getLanguagesListA(null, true));
                                }
                            } else {
                                dispatch(getLanguagesListA(null, true));
                            }
                        })
                    } else {
                        dispatch(getLanguagesListA(null, true));
                    }                        
                } else {
                    dispatch(getLanguagesListA(null, true));
                }
            } else {
                dispatch(getLanguagesListA(null, true));
            }
        })
        .catch((err)=> {
            console.log(err);
            dispatch(getLanguagesListA(null, true));

        })
    }
}


export const getSkillsListAC = () => {
    setGlobalToken();
    return (dispatch, getState) => {
        http.post(`${BASE_API_URL}/user/v2/listAllSkills`)
        .then((res)=> {
            console.log(res);
            if (res.status == 200) {
                if (res.data.status == 200) {
                    console.log('inside data status ');
                    dispatch(getSkillsListA(res.data, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    if (updateToken) {
                        http.post(`${BASE_API_URL}/user/v2/listAllSkills`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200) {
                                    dispatch(getSkillsListA(res.data, false));
                                } else {
                                    dispatch(getSkillsListA(null, true));
                                }
                            } else {
                                dispatch(getSkillsListA(null, true));
                            }
                        })
                    } else {
                        dispatch(getSkillsListA(null, true));
                    }                        
                } else {
                    dispatch(getSkillsListA(null, true));
                }
            } else {
                dispatch(getSkillsListA(null, true));
            }
        })
        .catch((err)=> {
            console.log(err);
            dispatch(getSkillsListA(null, true));

        })
    }
}