import React from 'react'

const LeftArrowSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M9.61984 12.0465L13.6665 7.99979L9.61984 3.95312"
      stroke="#0946C4"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M2.33322 8L13.5532 8" stroke="#0946C4" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
  )
}

export default LeftArrowSvg