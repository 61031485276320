import moment from 'moment';

const initial_state = {
    test_id : 1,
    test_loading: true
};

const reducer = (state = initial_state, action) =>{
    if (action.type === 'GET_CODING_TEST') {
        console.log(action)
        let response = {
            "status": 200,
            "message": "Successfully fetched the data",
            "data": {
                "id": "1",
                "name": "coding test 1",
                "description": "test",
                "subject_id": 45,
                "subject_name": "Fluid Mechanics 2",
                "test_img": "",
                "start_time": "2021-03-02 15:00:54",
                "doe": "2021-03-22 15:00:54",
                "timelimit": "3600",
                "problems": [
                    {
                        "id": 2,
                        "sphere_engine_problem_id": 44909,
                        "code": "RMIH805GS8",
                        "hash": "TyYole7LQ8",
                        "category": 1,
                        "name": "edwisely problem 1",
                        "body": "sum of first N natural numbers input is N",
                        "interactive": true,
                        "master_judge_id": 1001,
                        "master_judge_name": "Score is % of correctly solved sets",
                        "test_cases": [
                            {
                                "number": 0,
                                "id": 1,
                                "active": true,
                                "input": "10",
                                "output": "55",
                                "timelimit": 2,
                                "judge_id": 1,
                                "judge_name": "Ignores extra whitespaces"
                            },
                            {
                                "number": 1,
                                "id": 2,
                                "active": true,
                                "input": "4",
                                "output": "10",
                                "timelimit": 5,
                                "judge_id": 1,
                                "judge_name": "Ignores extra whitespaces"
                            },
                            {
                                "number": 4,
                                "id": 7,
                                "active": true,
                                "input": "11",
                                "output": "66",
                                "timelimit": 1,
                                "judge_id": 1,
                                "judge_name": "Ignores extra whitespaces"
                            }
                        ],
                        "marks": "None"
                    },
                    {
                        "id": 3,
                        "sphere_engine_problem_id": 44910,
                        "code": "DG7YYJQJTL",
                        "hash": "Py6U0JYE1C",
                        "category": 1,
                        "name": "edwisely problem 3",
                        "body": "square of a number",
                        "interactive": true,
                        "master_judge_id": 1001,
                        "master_judge_name": "Score is % of correctly solved sets",
                        "test_cases": [
                            {
                                "number": 1,
                                "id": 4,
                                "active": true,
                                "input": "4",
                                "output": "16",
                                "timelimit": 5,
                                "judge_id": 1,
                                "judge_name": "Ignores extra whitespaces"
                            }
                        ],
                        "marks": "None"
                    },
                    {
                        "id": 5,
                        "sphere_engine_problem_id": 44912,
                        "code": "EEDEH0WV1B",
                        "hash": "PZzSDpVCcs",
                        "category": 1,
                        "name": "edwisely problem 4",
                        "body": "cube of a number",
                        "interactive": true,
                        "master_judge_id": 1001,
                        "master_judge_name": "Score is % of correctly solved sets",
                        "test_cases": [
                            {
                                "number": 1,
                                "id": 6,
                                "active": true,
                                "input": "4",
                                "output": "64",
                                "timelimit": 5,
                                "judge_id": 1,
                                "judge_name": "Ignores extra whitespaces"
                            }
                        ],
                        "marks": "None"
                    }
                ]
            }
        }
        response.data.problems.forEach((prob)=> {
            prob['submissions'] = []
        })
        return {
            ...state,
            ...response.data,
            test_loading: false,
        }
    }
    if (action.type=='ON_PROBLEM_SUBMISSION') {
        let problems = [...state.problems];
        console.log(action.response)
        let sphere_engine_problem_id = action.response.data.problem.id;
        let problem_index = problems.findIndex((prob)=>{
            return prob.sphere_engine_problem_id == sphere_engine_problem_id
        });
        problems[problem_index]['submissions'].push(action.response.data.id);

        return {
            ...state,
            problems: problems
        }
    }
    if (action.type == 'ON_PROBLEM_FINAL_SUBMISSION') {
        return {
            ...state
        }
    }
    return state;
}

export default reducer;