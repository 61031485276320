import React, {useState,useEffect} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, Grid, Slide,Grow, Fade } from '@mui/material';
import CustomizedTreeView from './CustomizedTreeView';
import { useSpring, animated } from 'react-spring';

const SkillsAccordion = ({ title, children,onHandle}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    useEffect(() => {
      onHandle(isExpanded)
  }, [isExpanded]);

  return (
    <>
      <Accordion
        expanded={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
        sx={{
          border: 'none',
          boxShadow: 'none',
          backgroundColor: 'transparent',
          m: '0px !important',
          '& .MuiAccordionSummary-root': {
            padding: '0px',
            transition: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '25px',
            height: '51px',
          },
          '& .MuiAccordionSummary-content': {
            transition: 'none',
            margin: '0px',
          },
          '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
            height: '51px',
            minHeight: '51px',
          }
        }}
        >
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
                <Typography variant='body5' sx={{ color: (theme) => theme.palette.common.black }}>
                    {title}
                </Typography>
            </AccordionSummary>
            <Grow
              in={isExpanded}
              style={{
                transformOrigin: '100% 0 0', 
                transform: isExpanded ? 'scale(1)' : 'scale(0)', 
                transition: 'transform 0.5s ease-in-out',
              }}
              {...(isExpanded ? { timeout: 1000 } : {})}
            > 
              <AccordionDetails 
                sx={{
                  padding: '0px',
                }}
              >
                {children}
              </AccordionDetails>
            </Grow>
      </Accordion>
    </>
  );
}

export default SkillsAccordion;