import React from 'react';
import { Box, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const ControlPanel = (props) => {
  const { file, scale, setScale } = props;




  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOutClass = isMinZoom ? 'disabled' : 'clickable';
  const zoomInClass = isMaxZoom ? 'disabled' : 'clickable';

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }}>

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap:'4px'
      }}>


        <ZoomOutIcon onClick={zoomOut} sx={{ width:'24px' , height: '24px', minWidth:'24px', minHeight:'24px',cursor:'pointer' , color: theme => theme.palette.grey[900]}}  />

        <Typography component='h2' sx={{
          fontFamily: 'Poppins-SemiBold',
          color: 'grey.600',
          fontSize: '14px',
          width:'38px'
        }}>{(scale * 100).toFixed()}%</Typography>



        < ZoomInIcon onClick={zoomIn} sx={{ width:'24px' , height: '24px', minWidth:'24px', minHeight:'24px', cursor:'pointer', color: theme => theme.palette.grey[900]}}  />


      </Box>
      <div className="mx-3">
        <a href={file} download={true} title="download">
          {/* <i className="fas fa-file-download clickable" /> */}
          <DownloadIcon sx={{
            color: theme => theme.palette.primary[800]
          }} />
        </a>
      </div>
    </Box>
  );
};

export default ControlPanel;
