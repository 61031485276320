import React, { useEffect, useState } from 'react'
import RemedialCard from '../RemedialCard/RemedialCard'
import Chart from 'react-apexcharts'
import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import StatisticsSvg from './../../../../assets/remedial/svg/statistics.svg';
import { AVERAGE_TIME_PER_STUDENT_ARRAY, CORRECT_ANSWERS_ARRAY, WRONG_ANSWERS_ARRAY } from '../../utils/constant/graphConstant';




const TimeAnalysisGraph = ({ data }) => {

    const theme = useTheme();


    const randomData = () => {
        let dataArray = [];
        [...Array(20)].forEach((item, index) => {
            let randomNumber = Math.random() * 10
            if (randomNumber === 0) {
                dataArray.push(0)
            } else {
                dataArray.push((Math.random() * 10).toFixed(0))
            }
        })
        return dataArray
    }

    // console.log(randomData())


    const DataHandlerForGraph = (data, type) => {
        let newArrayData = [];
        switch (type) {

            case AVERAGE_TIME_PER_STUDENT_ARRAY:
                data.forEach((item) => {
                    newArrayData.push(item.time_spent_per_question)
                    // console.log(item.time_spent_per_question, 'newArrayData')
                })
                return newArrayData;

            case CORRECT_ANSWERS_ARRAY:
                data.forEach((item) => {
                    if (item.is_correct) {
                        newArrayData.push(item.timetaken)
                    } else {
                        newArrayData.push(0)
                    }
                })
                return newArrayData;

            case WRONG_ANSWERS_ARRAY:
                data.forEach((item) => {
                    if (item.is_correct) {
                        newArrayData.push(0)
                    } else {
                        newArrayData.push(item.timetaken)
                    }
                })
                return newArrayData;

            default:
                return newArrayData
        }
    }

    const handleQuestionCounts = (data) => {
        let questionListArray = [];
        data.forEach((item, index) => {
            questionListArray.push(`Q${index + 1}`)
        })
        return questionListArray
    }


    useEffect(() => {
        DataHandlerForGraph(data, AVERAGE_TIME_PER_STUDENT_ARRAY)
    }, [data])




    const graphData = {

        series: [{
            name: 'Average time spent by all students who answered correctly',
            data: DataHandlerForGraph(data, AVERAGE_TIME_PER_STUDENT_ARRAY)
        }, {
            name: 'Correct answer',
            data: DataHandlerForGraph(data, CORRECT_ANSWERS_ARRAY)
        }, {
            name: 'Incorrect answer',
            data: DataHandlerForGraph(data, WRONG_ANSWERS_ARRAY)
        }],
        options: {
            chart: {
                foreColor: theme.palette.grey[700],
                type: 'bar',
                height: 350
            },
            toolbar: {
                show: false,
                customIcons: []
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 2,
                    columnWidth: '60%',
                    borderRadiusApplication: 'end',
                    hideZeroBarsWhenGrouped: true,

                    // endingShape: 'rounded'
                },
                dataLabels: {
                    position: 'top',
                    style: {
                        // color: '#373d3f',
                        fontSize: '16px',
                        fontFamily: 'Poppins-Medium',
                        fontWeight: 600
                    }

                }
            },
            colors: [theme.palette.externalwarning[0], theme.palette.success[700], theme.palette.extraredpalette[700]],
            legend: {
                position: 'top'
            },
            dataLabels: {
                enabled: false
            },
            grid: {
                show: true
            },

            stroke: {
                show: true,
                width: 0,
                colors: ['transparent']
            },
            xaxis: {
                categories: handleQuestionCounts(data),
            },
            yaxis: {
                title: {
                    text: 'Time in seconds'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return + val + " seconds"
                    }
                }
            }
        },

    };




    return (
        <>
            {data ? <RemedialCard>

                <Box sx={{
                    position: 'relative',
                    '.apexcharts-toolbar': {
                        display: 'none'
                    },
                    '.apexcharts-legend.apexcharts-align-center.apx-legend-position-top': {
                        left: '-300px !important'
                    },
                }}>

                    <Box
                        sx={{
                            position: 'absolute',
                            top: '0',
                            left: '20px',
                            zIndex: 10
                        }}>
                        <Stack direction={'row'} alignItems={'center'} gap={'10px'}>

                            <img src={StatisticsSvg} alt="meter" width={22} height={22} />
                            <Typography component='p' sx={{
                                fontFamily: 'Poppins-SemiBold',
                                color: 'grey.900',
                                fontSize: '16px'
                            }}>Time analysis</Typography>

                        </Stack>
                    </Box>

                    <Chart options={graphData.options} series={graphData.series} type="bar" width={'100%'} height={300} />
                </Box>

            </RemedialCard>
                :
                <RemedialCard>
                    <Skeleton variant='rounded' width={'100%'} height={180} sx={{background: theme => theme.palette.grey[200]}} />
                </RemedialCard>
            }
        </>
    )
}

export default TimeAnalysisGraph