import React from 'react'
import { Box, Stack, Typography } from '@mui/material';
import RewardGif from './../../../../assets/remedial/gif/reward.gif'
import ModalWithNoHeader from '../../../../components/common/ModalWithNoHeader/ModalWithNoHeader';

const BonusPointsModal1 = ({ show, onClose, bonusPoints, studyProgress }) => {
    return (
        <ModalWithNoHeader
            closeButtonText='Continue'
            show={show}
            headBodyGap={32}
            buttonTextColor='primary.contrastText'
            buttonColor='primary.800'
            onCloseHandle={onClose}
        >
            <Stack alignItems={'center'} justifyContent={'center'} gap={'10px'}>
                <Box sx={{
                    position: 'relative',
                    width: '130px',
                    height: '130px',
                    overflow: 'hidden',
                    img: {
                        position: 'absolute',
                        top: '-30px',
                        objectFit: 'cover',
                        width: '200px',
                        height: '200px'
                    }
                }}>
                    <img src={RewardGif} alt="rewardgif" />
                </Box>
                {bonusPoints > 0 ? <Typography
                    component={'p'}
                    sx={{
                        fontFamily: 'Poppins-Medium',
                        fontSize: '22px',
                        color: theme => theme.palette.primary[800],
                    }}>{bonusPoints} points rewarded!!</Typography>
                    : <Typography
                        component={'p'}
                        sx={{
                            fontFamily: 'Poppins-Medium',
                            fontSize: '22px',
                            color: theme => theme.palette.primary[800],
                        }}>You made {studyProgress}% progress</Typography>

                }
                {bonusPoints > 0 ? <Typography
                    component={'p'}
                    sx={{
                        fontFamily: 'Poppins-Medium',
                        fontSize: '12px',
                        color: theme => theme.palette.grey[700],
                    }}>Congratulations! You have earned {bonusPoints} more point!</Typography> :
                    <Typography
                        component={'p'}
                        sx={{
                            fontFamily: 'Poppins-Medium',
                            fontSize: '12px',
                            color: theme => theme.palette.grey[700],
                        }}>Congratulations! You made {studyProgress}% progress!</Typography>
                }
            </Stack>
        </ModalWithNoHeader>
    )
}

export default BonusPointsModal1