import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LiveInstructions from "../../components/LiveInstructions";
// import AppLayout from '../../components/Layout/Layout';
import Flex from 'styled-flex-component';
import Equation from '../../components/Equation';
import * as liveActions from '../../store/actions/liveActions';
import * as Ably from 'ably/promises';
import {getLoggedInUser} from '../../helpers/Auth';
import styled, { keyframes, css } from "styled-components";
import moment from 'moment';
import ImageModal from "../subjective/ImageModal";
import RichTextEditor from "react-rte";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import "./results.css";
import ReactCountdownClock from "react-countdown-clock";
let realtime = null;
let channel = null;
let timeInterval = null;

const OptionFlex = styled.div`
  display:flex;
  margin: 10px;
  padding: 10px;
  margin-left: 0;
  align-items: center;
   width:70%;
  height: auto;
  border-radius: 15px;
  border: 1px solid #b1cee3;
  justify-content:center;
  background-color: white;
    &:hover{
        border: 2px solid #0000ff7d;
    }
`;
const Timerdiv = styled.div`
 ${(props) =>
    props.remainingtime<15 &&
    css`
     animation: shake 0.8s infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    `}
`;
class LiveSurvey extends Component{

    state = {
        loading_ques: false,
        testCode: '',
        ablyConn: false,
        question: null,
        current_ques_id: null,
        selected_option: null,
        time: 0,
        question_count: 0,
        question_submit: false,
        testComplete: false,
        result: false,
        correct_ans: null,
        submitted: false,
        openImage: false,
        image_url: null,
        answer: '',
        answer_text: '',
        codeError: false,
        question_time: 0,
         timetocompoennt:0,
    showtimealert: false,
    closedonce: false,
    }
hidetimeralert = () => {
    this.setState({ showtimealert: false, closedonce: true });
  };
    componentDidMount = async () => {
        const user = getLoggedInUser();
        realtime = new Ably.Realtime({authUrl: `${process.env.REACT_APP_API_URL_TAKE_TEST}/questionnaire/liveTestAuth?user_id=${user.user_id}&roll_number=${user.roll_number}`});
        realtime.connection.once('connected', () => this.updateAblyConn(false));
    }

    updateAblyConn = (loading) => this.setState({ablyConn:!this.state.ablyConn,loading_ques:loading,testStarted:loading});

    updateQuestion = (data) => {
        if(timeInterval) clearInterval(timeInterval);
        if (data.started) {
            if(this.state.question && !this.state.selected_option && !this.state.question_submit) this.onSubmitAnswer();
            let time = 5;
            timeInterval = setInterval(() => {
                // console.log(time);
                if(time <= 0){
                    clearInterval(timeInterval);
                    let ques_count = this.state.question_count + 1;
                    this.setState({
                        time: 0,
                        testStarted: true,
                        loading_ques:false,
                        question:{...data.question},
                        current_ques_id:data.curr_ques_id,
                        question_count: ques_count,
                        result: false,
                        correct_ans: null,
                        timetaken: moment(),
                        selected_option: null,
                        submitted: false,
                        answer: data.question.field_type === 3 ? RichTextEditor.createEmptyValue() : '',
                        question_time: 0,
                         timetocompoennt:0,
                        showtimealert: false,
                        closedonce: false,
                        question_submit:false,
                    });
                    return;
                }
                this.setState({time:time,loading_ques:true});
                time--;
            }, 1000);            
        } 
        else {
            let time = 10;
            timeInterval = setInterval(() => {
                if(time <= 0){
                    clearInterval(timeInterval);
                    this.setState({
                        testStarted: false,
                        testComplete: true,
                        loading_ques:false,
                        question:null,
                        current_ques_id:null,
                        question_count: null,
                        timetaken: null,
                        result: false,
                        correct_ans: null,
                        selected_option: null,
                        question_time: 0,
                         timetocompoennt:0,
                       showtimealert: false,
                        closedonce: false,
                        submitted: false
                    });
                    this.props.history.push({pathname:"/liveSurveyResults",state:{testId:this.props.testId}});
                    return;
                }
                this.setState({time:time,testStarted: false,testComplete: true,question:null});
                time --;
            }, 1000);
        }         
    };

    startQuestionTimer = (data) => {
        if(this.state.question?.id === data.ques_id){
            let time = data.time;
             let timetocompoennt = data.time;
       this.setState({ timetocompoennt: timetocompoennt });
            const endTime = moment(data.end_timestamp);
            timeInterval = setInterval(() => {
                if(time <= 0 || moment().isAfter(endTime)){
                    clearInterval(timeInterval);
                    if(!this.state.submitted) this.onSubmitAnswer();
                    return;
                }
                 if (time <= 10 && time >= 1 && !this.state.closedonce) {
          this.setState({ showtimealert: true });
        }
                this.setState({question_time:time});
                time --;
            }, 1000);
        }
    }

    componentDidUpdate = () => {
        if(this.props.channel_id && !this.props.error_loading_live && this.state.ablyConn){
            channel = realtime.channels.get(String(this.props.channel_id));
            channel.presence.enter();
            channel.subscribe((msg) => {
                console.log(msg);
                if(msg.name === "content") this.updateQuestion({...msg.data});
                else if(msg.name === "content_result") this.showResult({...msg.data});
                else if(msg.name === "ques_timer") this.startQuestionTimer({...msg.data});
            });
            this.updateAblyConn(true);
        }
        if(channel && !this.props.error_submitting_answer && this.state.question_submit){
            console.log("sending message" + this.state.question_submit)
            const user = getLoggedInUser();
            channel.publish("student",{student_id:user.user_id,question_id:this.state.current_ques_id,
                answer:this.state.question.field_type === 1 ? this.state.selected_option : 
                this.state.question.field_type === 3 ? this.state.answer_text : this.state.answer});
            this.setState({question_submit:false,submitted:true});
        }
    }

    showResult = msg => {
        if(this.state.question && this.state.question.id === msg.ques_id)
            this.setState({result:true,correct_ans:msg.correct_ans_id});
    }

    onCodeChange = (event) => this.setState({testCode: event.target.value.trim()});

    startTest = async () =>{
        if(this.state.testCode){
            await this.props.checkCode(this.state.testCode);
        }
        else this.setState({codeError:true});
    }

    onOptionSelected = (option_id) => {
        if(!this.state.result) this.setState({selected_option:option_id});
    }

    onAnswerChange = (event) => {
        let answer = '';
        if(this.state.question.field_type === 3) answer = event;
        else if(this.state.question.field_type === 2) answer = event.target.value.replace(/ /g,"").substring(0,15);
        this.setState({answer:answer});
    }
    
    onSubmitAnswer = async () => {
        if(channel){
            const answer = this.state.question.field_type === 3 ? this.state.answer.toString("html") : this.state.answer;
            if(this.state.question.field_type === 1)
                await this.props.onOptionSelected(this.state.current_ques_id,this.state.selected_option ? this.state.selected_option : '',
                    null,moment().diff(this.state.timetaken,'seconds'),1);
            else if(this.state.question.field_type === 2)
                await this.props.onOptionSelected(this.state.current_ques_id,null,this.state.answer ? this.state.answer : '',
                    moment().diff(this.state.timetaken,'seconds'),2);
            else
                await this.props.onOptionSelected(this.state.current_ques_id,null,answer.replace(/<\/?[^>]+(>|$)/g, "") ? answer : '',
                    moment().diff(this.state.timetaken,'seconds'),3);
            this.setState({question_submit:true,loading_ques:true,time:0,answer_text:answer.replace(/<\/?[^>]+(>|$)/g, "") ? answer : '',question_time:0, timetocompoennt:0,
        showtimealert: false,});
        }
    }

    closeTestAfterSubmission = () => this.props.history.push({pathname: "/home"});

    onImageClick = (url)=> this.setState({openImage: true, image_url: url});

    closeImage = () => this.setState({openImage: false, image_url: null});

    render(){
        console.log(this.props);
        console.log(this.state);
        const option_numbers = {
      0: "A",
      1: "B",
      2: "C",
      3: "D",
      4: "E",
      5: "F",
      6: "G",
      7: "H",
      8: "I",
      9: "J",
    };

        if (this.state.loading_ques && this.state.time === 0 && !this.state.testComplete && !this.state.result && (!this.state.question || this.state.submitted)) {
            return ( <div
          style={{
            height: "inherit",
            background: "white",
            overflow: "hidden",
            padding: "70px",
          }}
         >
          <div className="loader"></div>
          <div className="zoom-in-out-box">
            Please wait until question is loaded
          </div>
        </div>
        )
        }
        
        if (!this.state.testStarted && !this.state.testComplete) {
            return <LiveInstructions showStart startTest={this.startTest} code={this.state.testCode} 
                error={this.props.error_msg} onCodeChange={this.onCodeChange} buttonName="JOIN LIVE SURVEY"></LiveInstructions>
        }

        if(this.state.time && this.state.testStarted){
            return (
        <div
          style={{
            height: "inherit",
            background: "white",
            overflow: "hidden",
            padding: "70px",
          }}
        >
          <div className="loader"></div>
          <div style={{ padding: "20px" }}>
            Next question loading in - {this.state.time} seconds
          </div>
        </div>
      );
        }

        if(this.state.time && !this.state.testStarted && this.state.testComplete){
            return <div style={{marginLeft: '8px',marginTop: '25px', width: '100%'}}>
                Survey results loading in - {this.state.time} seconds
            </div>
        }

        if(this.state.question && this.state.current_ques_id === this.state.question.id && this.state.testStarted)
            return(
                <div style={{overflow: 'auto'}}>
                    <Flex column style = {{marginTop: '40px', marginLeft: '30px',overflowX: 'hidden'}}>
                        {this.state.question_time ?  <>
              <Timerdiv
                style={{
                  // marginRight: "25px",
                  // marginTop: "20px",
                  // width: "100%",
                  // textAlign: "right",
                  position:"relative",
                  marginTop:"28px",
                  left:"90%"
                }}
                remainingtime={this.state.question_time}
              >
                <div className="clockindication1"></div>
                <div className="half-circle"></div>
                <div className="clockindication2"></div>
                {/* <h4 style={{ marginRight: "25px" }}> */}
                {/* <Timerdiv className="timer" duration={10}>
                   <Timermask className="mask" size={100}></Timermask>
                    </Timerdiv> */}
                 <ReactCountdownClock
                className="customclock"   
              seconds={this.state.timetocompoennt}
              alpha={0.9}
              color="red"
              size={70}
              weight={5}
              showMilliseconds={false}
            />
                  {/* Auto submitting in - {this.state.question_time}s
                </h4> */}
                
              </Timerdiv>
              <ToastContainer className="p-3" position="top-center">
                  <Toast
                    animation={true}
                    show={this.state.showtimealert}
                    onClose={this.hidetimeralert}
                  >
                    <Toast.Header
                      style={{ paddingLeft: "23px", background: "#f43e3e" }}
                    >
                      <img
                        src="/gifs/time_alert.gif"
                        style={{ height: "70px" }}
                      />
                      <strong className="me-auto" style={{ color: "white" }}>
                        Alert! Alert! <br /> Running out of time!
                      </strong>
                    </Toast.Header>
                  </Toast>
                </ToastContainer> </> : null}
                        <div style={{marginLeft: '8px',marginTop: '20px'}}>
                            <Flex row style={{}}>
                                <Flex column style={{alignItems:"center", width: '98%'}}>
                                    <Flex column style={{marginBottom:'20px', textAlign: 'left'}}>
                                        <Equation math_type={1} bold equation={this.state.question.name}></Equation>
                                        {this.state.question.question_image && this.state.question.question_image !== ' ' && ['mp3','wav','aac'].includes(this.state.question.question_image.split(/[#?]/)[0].split('.').pop().trim()) ? 
                                            <div style={{width: '324px', height: '216px', margin: '10px'}}>
                                                <audio style={{width:'324px'}} src={encodeURI(this.state.question.question_image)}></audio>
                                            </div>
                                        : this.state.question.question_image && this.state.question.question_image !== ' ' ? <div style={{width: '324px', height: '216px', margin: '10px'}}>
                                                <img width='324px' height='216px' src={this.state.question.question_image} alt="question" 
                                                    onClick={() => this.onImageClick(this.state.question.question_image)}></img>
                                            </div>: null}
                                    </Flex>
                                    
                                    <div className="row" style={{justifyContent:"center"}}>
                                    {this.state.question.field_type === 1 ? this.state.question.options.map(
                                        ({ id, media, name, option_image }, i) => {
                                            const is_selected = this.state.selected_option === id;
                                            const is_selected_border = this.state.selected_option === id ? "2px solid #0000ff7d" : "";
                                            const color = this.state.result && this.state.correct_ans === id ? 'green' : 
                                                        is_selected && this.state.result && this.state.correct_ans !== id ? 'red' : 
                                                        is_selected && !this.state.result ? '#0000ff7d' : null;
                                        return (
                                            <OptionFlex onClick={() => this.onOptionSelected(id)} 
                                                style ={{ color: this.state.selected_option ? '#4E54C8' : null, fontWeight: is_selected ? '600' : '400', alignItems: 'center', 
                                                   backgroundColor: "White",cursor: "pointer",border:is_selected_border,width:this.state.question.question_image ? "calc(50% - 3rem)":"",}} key={id}> 
                                                <Flex row key={i} >
                                                    <div onClick={() => this.onOptionSelected(id)} 
                                                         style={{ margin: "auto", marginRight: "8px",marginLeft: "10px",background:color,}}
                                                     className="optioncircle">{option_numbers[i]}</div>
                                                    <div>
                                                        {media === 1 && ['mp3','wav','aac'].includes(option_image.split(/[#?]/)[0].split('.').pop().trim()) ? 
                                                            <div style={{margin: '10px',marginTop: '0px', width: '150px', height: '100px'}}>
                                                                <audio controls style={{width:'150px'}} src={encodeURI(option_image)}></audio>
                                                            </div>
                                                        : media === 1 ?  <div style={{margin: '10px',marginTop: '0px', width: '150px', height: '100px'}}>
                                                            <img width='150px' height='100px' src={encodeURI(option_image)} alt="option" 
                                                                onClick={() => this.onImageClick(option_image)}/>
                                                            </div> 
                                                        : null}
                                                    </div>
                                                    <div style = {{margin: 'auto',marginLeft: '10px'}} onClick={() => 
                                                        this.onOptionSelected(id)}>
                                                        <Equation bold={is_selected} answered={is_selected} math_type={1} equation={name}></Equation>
                                                    </div>
                                                </Flex>
                                            </OptionFlex>)
                                        }
                                        )
                                    : this.state.question.field_type === 2 ? 
                                        <input type="text" className="form-control" onChange={event => this.onAnswerChange(event)} value={this.state.answer}
                                        style={{borderRadius:'15px',padding:'5px 20px',height:'60px',fontSize:'18px',textAlign:'left'}} />
                                    : <div style={{width: '90%', marginRight: '20px', textAlign: 'left'}}>
                                        <RichTextEditor
                                            className='RTE'
                                            style={{height:'100px'}}
                                            value={this.state.answer}
                                            onChange={this.onAnswerChange}
                                        />
                                        </div>
                                    }
                                    </div>
                                </Flex>
                            </Flex>
                            {!this.state.question_submit && !this.state.result ? 
                                 <div style={ this.state.question.field_type === 1 || this.state.question.field_type === 2  ? { display: "flex", justifyContent: "center",marginTop: "30px"} : { display: "flex", justifyContent: "center",marginTop: "80px"}  }>
                        <button
                    onClick={this.onSubmitAnswer}
                    className="btn btn-danger"
                    style={{}}
                  >
                    Submit Answer
                  </button>
                </div>
                            : null}
                        </div>
                    </Flex>
                    {this.state.openImage && <ImageModal width='500px' height='70%' padding='none' style={{zIndex:12}} 
                        closeDialog={this.closeImage} open={this.state.openImage} url={this.state.image_url}>
                    </ImageModal>}
                </div>
            );
        if (this.state.testComplete)
            return <div style={{marginTop:'25px'}}>
                {/* <Modal open={this.props.testSubmitted}> */}
                    <div style={{marginTop: '20px'}}>Survey is Submitted</div>
                    <div onClick={this.closeTestAfterSubmission}
                        style={{ margin: "20px auto", color: "#6568d9",cursor:'pointer'}}>
                        OK
                    </div>
                {/* </Modal> */}
            </div>
    };
}
const mapStateToProps = state => {
    return {
        error_loading_live: state.live.error_loading_live,
        error_submitting_answer: state.live.error_submitting_answer,
        testId: state.live.testId,
        channel_id: state.live.channel_id,
        questions: state.live.questions,
        title: state.live.title,
        error_msg: state.live.error_msg
    }
}

const mapDispatchToProps = dispatch => {
    return {
        refreshToken : () => dispatch(liveActions.refreshTokenAC()),
        checkCode : (code) => dispatch(liveActions.checkCodeSurveyAC(code)),
        onOptionSelected : (qid,option_id,type,timetaken,field_type) => dispatch(liveActions.onOptionSelectedSurveyAC(qid,option_id,type,timetaken,field_type))
    }
}

export default withRouter(
    connect(mapStateToProps,mapDispatchToProps)(LiveSurvey)
);