import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import LeftArrowSvg from '../../../../assets/remedial/svg/LeftArrowSvg';

const ViewButton = ({ text = 'View All' }) => {
	return (
		<Stack direction={'row'} alignItems={'center'} gap={'5px'}>
			<Typography
				component={'p'}
				sx={{
					fontFamily: 'Poppins-Medium',
					fontSize: '12px',
					color: theme => theme.palette.primary[800],
					cursor: 'pointer'
				}}>
				{text}
			</Typography>
			<Box
				sx={{
					width: 20,
					height: 20,
					svg: {
						path: {
							stroke: theme => theme.palette.primary[800]
						}
					}
				}}>
				<LeftArrowSvg />
			</Box>
		</Stack>
	);
};

export default ViewButton;
