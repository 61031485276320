import React, {Component} from 'react';
import AppLayout from '../../../components/Layout/Layout';
import {connect} from 'react-redux';
import {getPracticeSeriesResultsAC} from '../../../store/actions/courseAssessmentActions';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Report from './report';
import jsPDF from 'jspdf';
import '../../dashboard/dashboard.css';

const QuestionStudentCard = styled.div`
    margin: 10px 0;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid black;
`;

const InfoCircle = styled.div`
    margin: 0 20px;
    width: 5%;
    max-width: 30px;
    height: 30px;
    border-radius: 50px;
    display: inline;
    text-align: center;
    align-items: center;
    padding-top: 3px;
`;

class AssessmentResults extends Component {

    state = {
        filter: "0",
        downloadReport: false,
        problem: null
    }

    componentDidMount = async() => {
        const test_id = this.props.location.state?.test_id;
        if(test_id) await this.props.getPracticeSeriesResults(test_id);
        else this.props.history.goBack();
    }

    onFilterChange = e => {
        const value = e.target.value;
        if(value === "0" || value) this.setState({filter:value});
    }

    downloadSolution = e => {
        const problem = this.props.results?.student?.problems?.find(p => p.id === parseInt(e.target.dataset?.id));
        if(problem) this.setState({downloadReport:true,problem:problem});
    }

    componentDidUpdate(){
        console.log("te");
        if(this.state.downloadReport && this.state.problem){
            const pdf = new jsPDF({ unit: "px", format: "b2", userUnit: "px",orientation: 'landscape' });
            pdf.html(document.getElementById("questionReport")).then(() => {
                pdf.save("test.pdf"); 
                this.setState({downloadReport: false, problem: null});
            });
        }
    }

    render () {
        console.log(this.props);
        if (this.props.loading) {
            return <div>Loading...</div>
        } else if (this.props.error_loading_results && !this.props.loading) {
            return <div>Error Loading Results</div>
        }
        // const current_time = moment().format('YYYY-MM-DD HH:mm:ss');
        // console.log(current_time);
        const filteredQuestions = this.props.results?.student?.problems?.filter(p => {
            if(this.state.filter === "0") return p;
            else if(this.state.filter === "1" && p.problem_status === "accepted") return p;
            else if(this.state.filter === "2" && p.problem_status && p.problem_status !== "accepted" && p.problem_status !== "wrong answer") return p;
            else if(this.state.filter === "3" && p.problem_status === "wrong answer") return p;
            else if(this.state.filter === "4" && !p.problem_status) return p;
            return false;
        });
        return (
            <div style={{overflow: 'hidden'}}>  
                <AppLayout></AppLayout>
                <div style={{margin: 'auto',marginLeft: '2%', position: 'absolute', top: '110px', width: '96%'}}>
                    <div className='mainBox'>
                        <div className="row" style={{border: '1px solid black', margin: '15px 0', padding: '15px 0'}}>
                            <div className="col-2">{this.props.results?.student?.name}</div>
                            <div className="col-2">{this.props.results?.student?.roll_number}</div>
                            <div className="col-3">{this.props.results?.student?.department}</div>  
                            <div className="col-3">{this.props.results?.student?.section}</div>
                        </div>
                        <div className='w-100 my-3' style={{display: 'flex',border: '1px solid black', padding: '10px 0'}}>
                            {filteredQuestions.length > 0 ?
                                filteredQuestions.map((problem,i) =>
                                <InfoCircle style={{backgroundColor:(problem.problem_status === "accepted" ? "lightgreen" : "lightcoral")}} key={i}>
                                    <a href={`#studentQuestionCard${problem.id}`}>Q{i+1}</a></InfoCircle>) : <p>No Questions</p>}
                        </div>
                        <div className='row'>
                            <p className='col-6 text-left'>Questions :</p>
                            <div className='col-6 text-right'>
                                <label htmlFor='questionFilter' className='d-inline'>Filter By:&emsp;</label>
                                <select id='questionFilter' className='form-control d-inline' style={{maxWidth: '200px'}}
                                    onChange={e => this.onFilterChange(e)}>
                                    <option value="0">All</option>
                                    <option value="1">Correct</option>
                                    <option value="2">Partially Correct</option>
                                    <option value="3">Wrong</option>
                                    <option value="4">Unattempted</option>
                                </select>
                            </div>
                        </div>
                        {filteredQuestions.length > 0 ? 
                            filteredQuestions.map((problem,i) => 
                            <QuestionStudentCard key={problem.id} id={`#studentQuestionCard${problem.id}`}>
                                <div className="row">
                                    <div className="col-2">{problem.title}</div>
                                    <div className="col-3" dangerouslySetInnerHTML={{__html: problem.body}}></div>
                                    <div className="col-1">B{problem.blooms_level}</div>
                                    <div className="col-2 text-left">
                                        <small className='mx-3'>Topics</small>
                                        <ul>{problem.topics.map(t => <li key={t.topic_id}>{t.topic_name}</li>)}</ul>
                                    </div>
                                    <div className="col-2 text-left">
                                        <small className='mx-3'>Test Cases</small>
                                        <ul>
                                            {problem.test_cases.map(tc => <li key={tc.test_case_id}>{tc.input} - {tc.output}</li>)}
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <p className="m-3 mt-1 text-left" style={{fontSize:'14px'}}>Complete Solution</p>
                                        <textarea disabled className="form-control" rows="10" value={problem.source_code}></textarea>
                                    </div>
                                    <div className="col-4">
                                        <table className="table table-borderless text-left">
                                            <tbody>
                                                <tr>
                                                    <td>No. of attempts</td>
                                                    <td>{problem.student_summary_compilations}</td>
                                                </tr>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>{problem.problem_status ? problem.problem_status : 
                                                        problem.student_summary_compilations > 0 && !problem.problem_status ? "Not Submitted" : "Unattempted"}</td>
                                                </tr>
                                                <tr>
                                                    <td>Memory Consumption</td>
                                                    <td>{problem.memory_consumption}</td>
                                                </tr>
                                                {problem.error ? 
                                                    <tr>
                                                        <td>Error</td>
                                                        <td>{problem.coding_time.length}</td>
                                                    </tr>
                                                    : null}
                                                {problem.problem_status === "accepted" ? 
                                                    <tr>
                                                        <td colSpan={2}>
                                                            <button className="gameButton" onClick={e => this.downloadSolution(e)} data-id={problem.id}>Download Report</button>
                                                        </td>
                                                    </tr>
                                                    : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </QuestionStudentCard>)
                            : <p>No Questions</p>
                        }
                    </div>
                    {this.state.downloadReport && this.state.problem ? 
                        <Report test={{name: this.props.results?.name,faculty_name: "faculty_name", faculty_department: "test 2"}}
                                problem ={this.state.problem}  />
                        : null
                    }
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        loading: state.cad.loading,
        results: state.cad.results,
        error_loading_results: state.cad.error_loading_results,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPracticeSeriesResults: (subject_id)=>dispatch(getPracticeSeriesResultsAC(subject_id)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssessmentResults));