import axios from '../../interceptor';
import { getPersonalityQuestionAtTriggerAC } from './personalityAssessmentActions';

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;


export function setGlobalToken() {
    try {
        let token = localStorage.getItem('token');
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    } catch (error) {
      console.log(error);
    }
}

export const GETPRACTICETESTRESULTSDATA = 'GET_PRACTICE_TEST_RESULTS_DATA';

export const getPracticeTestResultsDataA = (summary, results, err) => {
    return {
        type: GETPRACTICETESTRESULTSDATA,
        summary: summary,
        results: results,
        error: err
    }
}

export const getPracticeTestResultsDataAC = (test_id) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/v2/practiceSummaryDetails?practice_id=${test_id}`)
        .then((res)=> {
            if (res.status == 200 ) {
                if (res.data.status == 200 ) {
                    http.get(`${BASE_API_URL}/v2/practiceSubmittedAnswers?practice_id=${test_id}`)
                    .then((res1)=>{
                        console.log(res1)
                        if (res1.status == 200){
                            dispatch(getPracticeTestResultsDataA(res.data,res1.data, false));
                            dispatch(getPersonalityQuestionAtTriggerAC(3));                            
                        } else {
                            console.log('in error dispatch 1');
                            dispatch(getPracticeTestResultsDataA(null, null, true));
                        }
                    })
                    .catch((err1)=> {
                        console.log(err1)
                        console.log('in error dispatch 2');
                        dispatch(getPracticeTestResultsDataA(null, null, true));
                    })
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/v2/practiceSummaryDetails?practice_id=${test_id}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    http.get(`${BASE_API_URL}/v2/practiceSubmittedAnswers?practice_id=${test_id}`)
                                    .then((res1)=>{
                                        console.log(res1)
                                        if (res1.status == 200){
                                            dispatch(getPracticeTestResultsDataA(res.data,res1.data, false));
                                            dispatch(getPersonalityQuestionAtTriggerAC(3));
                                        } else {
                                            console.log('in error dispatch 1');
                                            dispatch(getPracticeTestResultsDataA(null, null, true));
                                        }
                                    })
                                    .catch((err1)=> {
                                        console.log(err1)
                                        console.log('in error dispatch 2');
                                        dispatch(getPracticeTestResultsDataA(null, null, true));
                                    })
                                } else {
                                    dispatch(getPracticeTestResultsDataA(null, null, true));
                                }
                            } else {
                                dispatch(getPracticeTestResultsDataA(null, null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(getPracticeTestResultsDataA(null, null, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    });
                } else {
                    dispatch(getPracticeTestResultsDataA(null, null, true));
                }
                
            } else {
                console.log('in error dispatch 3');
                dispatch(getPracticeTestResultsDataA(null, null, true));
            }
            
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(getPracticeTestResultsDataA(null, null, true));
        });
    };
};
