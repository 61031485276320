import { useSwiper } from "swiper/react";

export const SwiperButton = props => {

    const swiper = useSwiper();
    const onButtonClick = () => {
        swiper.slideNext();
        if(!props.accepted) props.onButtonClick();
    }
  
  if(props.accepted) return <button className="swiper-slide__button accepted" onClick={onButtonClick}>Accepted</button>
    
  return <button disabled={props.disabled} className="swiper-slide__button" onClick={onButtonClick}>{props.children}</button>;
};