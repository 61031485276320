import React, { useState } from "react";
import styled from "styled-components";
import Flex from "styled-flex-component";

const ModalWrapper = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: row;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.1);
`;

const ModalTextWrapper = styled(Flex)`
  width: 85%;
  // padding: 2em;
  border: 1px solid #ccc;
  margin: auto;
  background: #fff;
  margin-top: auto;
  height: 250px;
  overflow: auto;
  border-radius: 8px;
  align-items: center;
`;

const Modal = ({url,open,close}) => {

  const [text, setText] = useState("Copy");

  const copyLink = () => {
    navigator.clipboard.writeText(url);
    setText("Copied");
    setTimeout(() => {
      setText("Copy");
      close();
    }, 1000);
  }

    return <ModalWrapper center open={open}>
        <ModalTextWrapper>
          <div>
            <h4 className="text-left px-4">Some Important Points</h4>
            <ul>
              <li>Click/Open the download link on your iPhone/iPad, this will redirect you to the app download page and will start the download and install the app automatically.</li>
              <li>If it does nt automatically start downloading the app, please tap on '<b>GET</b>' to download it. Do not leave the download page before the installation is completed to avoid any issues.</li>
              <li>The link generated is for one-time use only, please generate new link to reinstall.</li>
            </ul>
            <div className="row px-5 my-2">
              <div className="col-10">
                <input type="text" disabled={true} className="form-control form-control-lg px-5" value={url} />
              </div>
              <button className="col-2 gameButton" onClick={copyLink}>{text}</button>
            </div>
          </div>
        </ModalTextWrapper>
    </ModalWrapper>
};

export default Modal;