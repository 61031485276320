import React, {useEffect} from "react";
import { Grid,Stack,Skeleton } from "@mui/material";
import Flow from "../../features/CareerPath/Flow";
import SearchBar from "../../features/CareerPath/SearchBar";
import { getPathData, getRecentSearchesData } from "../../store/actions/careerPathActions";
import { useDispatch,useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
export default function OverviewFlow() {
    const dispatch = useDispatch();
    const history = useHistory();
    const {loadingCareerpath,careerPathData,errorLoadingCareerPath} = useSelector((state) => state.careerpath);
    useEffect(() => {
          dispatch(getPathData())
          dispatch(getRecentSearchesData())
      }, []);

      const handleBackButton = () => {
        history.push('/home');
      }
     
  return (
  <>
   <main style={{ height: "100vh",background:'#fff',overflow:'hidden' }}>
      <Grid row xs={12} container spacing={1.9} sx={{pt:4}} alignItems={'center'}>
        <Grid item xs={1} sx={{paddingTop: '0px !important', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img src={'/images/back_arrow_black.svg'} style={{height: '20px', cursor: 'pointer'}} alt='Back' onClick={handleBackButton}/>
        </Grid>
        <Grid row xs={11} container spacing={1.9} alignItems={'center'}>
          <SearchBar />
        </Grid>
      </Grid>
        {!loadingCareerpath ?
      <Flow data={careerPathData} />
    :<Stack spacing={2} sx={{position:'absolute',top:'40%',left:'40%'}}>
      <Skeleton variant="rounded" width={210} height={30} />
      <Skeleton variant="rounded" width={210} height={30} />
      <Skeleton variant="rounded" width={210} height={30} />
      </Stack>}
    </main>
    </>
  );
};