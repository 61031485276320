import axios from '../../interceptor';

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
// let CORS = 'https://cors-anywhere.herokuapp.com/';


export function setGlobalToken() {
    try {
        let token = localStorage.getItem('token');
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } catch (error) {
      console.log(error);
    }
}

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const CHECK_CODE = 'CHECK_CODE';
export const ON_LIVE_OPTION_SELECTED = 'ON_LIVE_OPTION_SELECTED';
export const GET_LIVE_TEST_RESULTS = "GET_LIVE_TEST_RESULTS";
export const GET_LIVE_SURVEY_RESULTS = "GET_LIVE_SURVEY_RESULTS";

export const refreshTokenA = (err) => {
    return {
        type: REFRESH_TOKEN,
        error: err
    }
}

export const refreshTokenAC = (code) => {
    // test_id = 9;
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        const refresh_token = localStorage.getItem('refresh_token');
        http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
        http.get(`${BASE_API_URL}/auth/refresh`)
        .then((refres)=> {
            localStorage.setItem('token', refres.data.token);
            setGlobalToken();
            dispatch(refreshTokenA(false));
        })
        .catch((err)=> {
            dispatch(refreshTokenA(true));
            console.log(err);
        });
    };

};

export const checkCodeA = (res, err) => {
    return {
        type: CHECK_CODE,
        response: res,
        error: err
    }
}

export const checkCodeAC = (code) => {
    // test_id = 9;
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        // dispatch(checkCodeA(true,null));
        // http.get(`http://127.0.0.1:5001/questionnaire/v2/validateLiveTestCode?code=${code}`)
        http.get(`${BASE_API_URL}/questionnaire/v2/validateLiveTestCode?code=${code}`)
        .then((res)=> {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    console.log(res.data);
                    dispatch(checkCodeA(res.data.data, false));
                } else if (res.data.status === 401 && res.data.message === 'Signature expired. Please log in again.') {
                    const refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/questionnaire/v2/validateLiveTestCode?code=${code}`)
                        .then((res)=> {
                            if (res.status === 200) {
                                if (res.data.status === 200 ) {
                                    dispatch(checkCodeA(res.data.data,false));
                                } else {
                                    dispatch(checkCodeA(res.data, true));
                                }
                            } else {
                                dispatch(checkCodeA(null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(checkCodeA(null, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    });
                } else {
                    dispatch(checkCodeA(res.data, true))
                }
            } else {
                dispatch(checkCodeA(null, true))
            }
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(checkCodeA(null, true))
        });
    };

};

export const checkCodeSurveyAC = (code) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/livesurvey/v2/validateLiveSurveyCode?code=${code}`)
        .then((res)=> {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    console.log(res.data);
                    dispatch(checkCodeA(res.data.data, false));
                } else if (res.data.status === 401 && res.data.message === 'Signature expired. Please log in again.') {
                    const refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/livesurvey/v2/validateLiveSurveyCode?code=${code}`)
                        .then((res)=> {
                            if (res.status === 200) {
                                if (res.data.status === 200 ) {
                                    dispatch(checkCodeA(res.data.data,false));
                                } else {
                                    dispatch(checkCodeA(res.data, true));
                                }
                            } else {
                                dispatch(checkCodeA(null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(checkCodeA(null, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    });
                } else {
                    dispatch(checkCodeA(res.data, true))
                }
            } else {
                dispatch(checkCodeA(null, true))
            }
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(checkCodeA(null, true))
        });
    };

};

export const onOptionSelectedA = (res,err) => {
    return {
        type: ON_LIVE_OPTION_SELECTED,
        response: res,
        error: err
    }
}

export const onOptionSelectedAC = (qid,option_id,type,timetaken) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        const form = new FormData();
        form.append("test_id",getState().live.testId);
        form.append("question_id",qid);
        form.append("question_type",type);
        form.append("answer",option_id);
        form.append("timetaken",timetaken);
        http.post(`${BASE_API_URL}/questionnaire/v2/submitLiveQuestion`,form)
        .then((res)=> {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    console.log(res.data);
                    dispatch(onOptionSelectedA(res.data.data, false));
                } else if (res.data.status === 401 && res.data.message === 'Signature expired. Please log in again.') {
                    const refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.post(`${BASE_API_URL}/questionnaire/v2/submitLiveQuestion`,form)
                        .then((res)=> {
                            if (res.status === 200) {
                                if (res.data.status === 200 ) {
                                    dispatch(onOptionSelectedA(res.data.data,false));
                                } else {
                                    dispatch(onOptionSelectedA(null, true));
                                }
                            } else {
                                dispatch(onOptionSelectedA(null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(onOptionSelectedA(null, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    });
                } else {
                    dispatch(onOptionSelectedA(null, false))
                }
            } else {
                dispatch(onOptionSelectedA(null, true))
            }
        })
        .catch((err)=> {
            // console.log('error in getting practice test')
            console.log(err)
            dispatch(onOptionSelectedA(null, true))
        });
    }
};

export const onOptionSelectedSurveyAC = (qid,option_id,answer,timetaken,field_type) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        const form = new FormData();
        form.append("survey_id",getState().live.testId);
        form.append("question_id",qid);
        form.append("option_id",option_id ? option_id : '');
        form.append("answer_text",answer ? answer : '');
        form.append("timetaken",timetaken);
        form.append("field_type",field_type);
        http.post(`${BASE_API_URL}/livesurvey/v2/submitLiveSurveyQuestion`,form)
        .then((res)=> {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    console.log(res.data);
                    dispatch(onOptionSelectedA(res.data.data, false));
                } else if (res.data.status === 401 && res.data.message === 'Signature expired. Please log in again.') {
                    const refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.post(`${BASE_API_URL}/livesurvey/v2/submitLiveSurveyQuestion`,form)
                        .then((res)=> {
                            if (res.status === 200) {
                                if (res.data.status === 200 ) {
                                    dispatch(onOptionSelectedA(res.data.data,false));
                                } else {
                                    dispatch(onOptionSelectedA(null, true));
                                }
                            } else {
                                dispatch(onOptionSelectedA(null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(onOptionSelectedA(null, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                        dispatch(onOptionSelectedA(null, true));
                    });
                } else {
                    dispatch(onOptionSelectedA(null, false))
                }
            } else {
                dispatch(onOptionSelectedA(null, true))
            }
        })
        .catch((err)=> {
            // console.log('error in getting practice test')
            console.log(err)
            dispatch(onOptionSelectedA(null, true))
        });
    }
};

export const liveResultsA = (res, err) => {
    return {
        type: GET_LIVE_TEST_RESULTS,
        response: res,
        error: err
    }
}

export const getLiveTestResultsAC = (test_id) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        // const formdata = new FormData();
        http.get(`${BASE_API_URL}/questionnaire/v2/getLiveTestResults?test_id=${test_id}`)
        .then((res)=> {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    dispatch(liveResultsA(res.data, false));
                } else if (res.data.status === 401 && res.data.message === 'Signature expired. Please log in again.') {
                    const refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/questionnaire/v2/getLiveTestResults?test_id=${test_id}`)
                        .then((res)=> {
                            if (res.status === 200) {
                                if (res.data.status === 200 ) {
                                    dispatch(liveResultsA(res.data, false));
                                    
                                } else {
                                    dispatch(liveResultsA(null, true));
                                }
                            } else {
                                dispatch(liveResultsA(null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(liveResultsA(null, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                        dispatch(liveResultsA(null, true));
                    });
                } else {
                    dispatch(liveResultsA(null, true));
                }
            } else {
                dispatch(liveResultsA(null, true));
            }            
        })
        .catch((err)=> {
            console.log(err)
            dispatch(liveResultsA(null, true));
        });
    };
};

export const liveSurveyResultsA = (res, err) => {
    return {
        type: GET_LIVE_SURVEY_RESULTS,
        response: res,
        error: err
    }
}

export const getLiveSurveyResultsAC = (survey_id) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        // const formdata = new FormData();
        http.get(`${BASE_API_URL}/livesurvey/v2/getLiveSurveyResults?survey_id=${survey_id}`)
        .then((res)=> {
            if (res.status === 200) {
                console.log(res);
                if (res.data.status === 200) {
                    dispatch(liveSurveyResultsA(res.data, false));
                } else if (res.data.status === 401 && res.data.message === 'Signature expired. Please log in again.') {
                    const refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/questionnaire/v2/getLiveTestResults?test_id=${survey_id}`)
                        .then((res)=> {
                            if (res.status === 200) {
                                if (res.data.status === 200 ) {
                                    dispatch(liveSurveyResultsA(res.data, false));
                                    
                                } else {
                                    dispatch(liveSurveyResultsA(null, true));
                                }
                            } else {
                                dispatch(liveSurveyResultsA(null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(liveSurveyResultsA(null, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                        dispatch(liveSurveyResultsA(null, true));
                    });
                } else {
                    dispatch(liveSurveyResultsA(null, true));
                }
            } else {
                dispatch(liveSurveyResultsA(null, true));
            }            
        })
        .catch((err)=> {
            console.log(err)
            dispatch(liveSurveyResultsA(null, true));
        });
    };
};