import axios from '../../interceptor';
import moment from 'moment';

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
let CORS = 'https://cors-anywhere.herokuapp.com/';

export function setGlobalToken() {
    try {
        let token = localStorage.getItem('token');
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    } catch (error) {
      console.log(error);
    }
};

export const GETSEMESTERFEEDBACKQUESTIONS = 'GET_SEMESTER_FEEDBACK_QUESTIONS';
export const SUBMITSEMESTERFEEDBACK='SUBMIT_SEMESTER_FEEDBACK';
export const getSemesterfeedbackQuestionAC = (res,err) => {
  return {
    type: GETSEMESTERFEEDBACKQUESTIONS,
    res: res,
    err: err,
  }
}



export const getSemesterfeedbackQuestions = (feedback_id) => {
  console.log('in action creator')
  setGlobalToken();
  // const formdata = new FormData();
  // formdata.append('feedback_id', feedback_id);
  return (dispatch, getState) =>{
      http.get(`${BASE_API_URL}/semester_feedback/v2/getSemesterFeedback?feedback_id=${feedback_id}`)
      .then((res)=> {
          if (res.status ==200) {
              if (res.data.status == 200) {
                dispatch(getSemesterfeedbackQuestionAC(res.data, false));
              } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                  let refresh_token = localStorage.getItem('refresh_token');
                  http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                  http.get(`${BASE_API_URL}/auth/refresh`)
                  .then((refres)=> {
                      localStorage.setItem('token', refres.data.token);
                      setGlobalToken();
                      http.get(`${BASE_API_URL}/semester_feedback/v2/getSemesterFeedback?feedback_id=${feedback_id}`)
                      .then((res)=> {
                          if (res.status == 200) {
                              if (res.data.status == 200) {
                                dispatch(getSemesterfeedbackQuestionAC(res.data, false));
                                  
                              } else {
                                  dispatch(getSemesterfeedbackQuestionAC(null, true));
                              }
                          } else {
                            dispatch(getSemesterfeedbackQuestionAC(null, true));
                          }
                      })
                      .catch((err)=>{
                        dispatch(getSemesterfeedbackQuestionAC(null, true));
                      })
                  })
                  .catch((err)=> {
                      console.log(err);
                  });
              } else {
                dispatch(getSemesterfeedbackQuestionAC(null, true));
              }
          } else {
            dispatch(getSemesterfeedbackQuestionAC(null, true));
          }
      })
      .catch((err)=> {
          console.log('error in getting practice test')
          console.log(err)
          dispatch(getSemesterfeedbackQuestionAC(null, true));
      });
  };
};
export const submitFeedbackA = (res, err,loading) => {
    return {
      type: SUBMITSEMESTERFEEDBACK,
      res: res,
      error: err,
      loading:loading,
    };
  };
export const submitFeedbackAC = (feedback_id) => {
    setGlobalToken();
    return (dispatch, getState) => {
      dispatch(submitFeedbackA(false, false,true));
      const formdata = new FormData();
      const feedback_answers = getState().semfeedback.feedback_answers;
      // const answers = {};
      // feedback_answers.forEach((ans, index)=> answers[feedback_data.feedback_questions[index].id] = ans);
      // feedback_answers.forEach((ans, index)=> answers[feedback_data.feedback_questions[index].id] = ans[feedback_data.feedback_questions[index].id]);
      formdata.append("feedback_id", feedback_id);
      formdata.append("question_answers", JSON.stringify(feedback_answers));
      http
        .post(`${BASE_API_URL}/semester_feedback/v2/submitFeedback`, formdata)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            if (res.data.status == 200) {
              console.log("inside data status ");
              dispatch(submitFeedbackA(res.data, false, false));
            } else if (
              res.data.status == 401 &&
              res.data.message == "Signature expired. Please log in again."
            ) {
              let refresh_token = localStorage.getItem("refresh_token");
              http.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${refresh_token}`;
              http
                .get(`${BASE_API_URL}/auth/refresh`)
                .then((refres) => {
                  localStorage.setItem("token", refres.data.token);
                  setGlobalToken();
                  http
                    .post(
                      `${BASE_API_URL}${BASE_API_URL}/semester_feedback/v2/submitFeedback`,
                      formdata
                    )
                    .then((res) => {
                      if (res.status == 200) {
                        if (res.data.status == 200) {
                          dispatch(submitFeedbackA(res.data, false, false));
                        } else {
                          dispatch(submitFeedbackA(null, true, false));
                        }
                      } else {
                        dispatch(submitFeedbackA(null, true, false));
                      }
                    });
                })
                .catch((err) => {
                  dispatch(submitFeedbackA(null, true, false));
                });
            } else {
              dispatch(submitFeedbackA(null, true, false));
            }
          } else {
            dispatch(submitFeedbackA(null, true, false));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(submitFeedbackA(null, true, false));
        });
    };
  };


