import { Stack, Typography } from '@mui/material';
import React from 'react';
import MeterSvg from './../../../../assets/remedial/svg/testmeter.svg';
import RemedialCard from '../RemedialCard/RemedialCard';
import LeaderGroupedAvatar from '../GroupedAvatar/LeaderGroupedAvatar';
import { AnimatedNumber } from '../../../../utils';
import CircularProgressGrade from '../../../../components/common/CircularProgressGrade/CircularProgressGrade';


const RevisedScoreCard = ({ testData, revisedData, width = '100%', height = '100%', showButton = true }) => {

	console.log(testData , 'asasas')
	const calculateMarks = (data) => (data?.length > 0) ? data.reduce((acc, section) => acc + (section.correct_answered_count * section.marks), 0) : 0;
	const calculateBonusMarks = (data) => {
		if (data?.length > 0) {
		  const adjustedTotalMarks = Math.min(calculateMarks(data) +revisedData?.remedial_points, revisedData?.total_questions_count);
		  return adjustedTotalMarks;
		} else {
		  return 0;
		}
	  };
	  
	return (
		<RemedialCard width={width} height={height}>
			<Stack sx={{ gap: '16px' }}>
				<Stack direction={'row'} gap={'8px'}>
					<img src={MeterSvg} alt="meter" width={20} height={20} />
					<Typography
						component={'h4'}
						sx={{
							fontFamily: 'Poppins-SemiBold',
							fontSize: '16px',
							color: 'grey.900'
						}}>
						Your New Score
					</Typography>
				</Stack>

				<Stack direction={'column'} alignItems={'center'} justifyContent={'center'} gap={'12px'}>
					<CircularProgressGrade size={100} value={64} grade={revisedData?.grade_achieved} />
					<Typography
						component={'h4'}
						sx={{
							fontFamily: 'Poppins-SemiBold',
							fontSize: '22px',
							color: (theme) => theme.palette.success[600],
							textAlign: 'center'
						}}>
						<AnimatedNumber n={calculateBonusMarks(revisedData?.sections)} />/{revisedData?.total_questions_count}
					</Typography>
					<Typography
						component={'h6'}
						sx={{
							fontFamily: 'Poppins-Medium',
							fontSize: '14px',
							color: theme => theme.palette.extraredpalette[700],
							textAlign: 'center'
						}}>
						Old Score : <AnimatedNumber n={calculateMarks(revisedData?.sections)} />/{revisedData?.total_questions_count}
					</Typography>
					{revisedData?.remedial_points > 0 &&
					<Typography
						component={'h4'}
						sx={{
							fontFamily: 'Poppins-Medium',
							fontSize: '12px',
							color: 'grey.600',
							textAlign: 'center',
							span: {
								fontFamily: 'Poppins-SemiBold',
								fontSize: '12px',
								color: 'grey.800'
							}
						}}>
						<span>Congratulations!</span> You have improved your score by <span>{revisedData?.remedial_points} points</span>
					</Typography>
					}
					<Stack direction={'row'} alignItems={'center'} gap={'8px'}>
						<LeaderGroupedAvatar data={revisedData?.leader_board} />
						<Typography component='p' sx={{
							fontFamily: 'Poppins-Regular',
							color: 'grey.600',
							fontSize: '10px',
							textAlign: 'start'
						}}>{revisedData?.leader_board.length > 99 ? `${revisedData?.leader_board.length}+` : revisedData?.leader_board.length} Students have attempted remedial path along with you!</Typography>

					</Stack>
				</Stack>
			</Stack>
		</RemedialCard>
	);
};

export default RevisedScoreCard;
