import { Box, Typography } from '@mui/material'
import React from 'react'

//variant -> right, wrong , notAttempt


const TestSheetQuestion = ({isVisited , questionData , data ,qNumber, boxPadding , onClick , index , onQuestion }) => {

    console.log('questionPoints', data , )

// isVisited ? answered ? 'green' : 'red : 'grey
// data.id === data.newSelectedOption



const findMatchingID = (options) => {
const matched = options.filter(item => item.id === data.newSelectedOption)
return matched[0]?.id
}


// console.log(findMatchingID(data.options) , 'asaasassaasd')

    return (
        <Box onClick={() => {onClick(index)}} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: (theme) =>  index === onQuestion ? theme.palette.primary[800] : isVisited ? findMatchingID(data.options) === data.newSelectedOption ? theme.palette.success[200] : theme.palette.extraredpalette[100] : theme.palette.primary[100],
            color: (theme) =>  index === onQuestion ? theme.palette.primary.contrastText : isVisited ? findMatchingID(data.options) === data.newSelectedOption ? theme.palette.success[800] : theme.palette.extraredpalette[700] : theme.palette.primary[800],
            padding: `${boxPadding}px`,
            borderRadius: '4px',
            cursor: 'pointer'
        }}>
            <Typography component={'p'} sx={{
                fontFamily: 'Poppins-Medium',
                fontSize: '14px',
            }}>
                {qNumber}
            </Typography>
        </Box>
    )
}

export default TestSheetQuestion