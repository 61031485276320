import { BarChart, Bar, XAxis, LabelList, ResponsiveContainer } from "recharts";
import React, { Component } from "react";
import styled from "styled-components";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Equation3 from "./Equation3";
import axios from "axios";
import { init, identify, Identify, track } from '@amplitude/analytics-browser';
const AMPLITUDE_API_KEY=process.env.REACT_APP_AMPLITUDE_KEY;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Dot= styled.div`
height: 10px;
    width: 10px;
  // background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor:pointer;
  animation: blinker 2s linear 2;
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;
class QotdGraph extends Component {
  state= {
    open: false,
    qoddata:false,
    solution:'',
   
  };
   handleClickOpen = () => {
   this.setState({open:true})
  };

   handleClose = () => {
    this.setState({open:false})
  };
  questionclick = (index) => {
    // this.setState({})
    const eventProperties = {
      day: index,
    };
     track('qod_last_seven_days_performance', eventProperties);
    axios.get(`${this.props.question_of_the_day.previous_performance[index].solution}`).then(res => {
      this.setState({
        solution:res.data,
        open:true,
        qoddata:this.props.question_of_the_day.previous_performance[index],
      })
    });
    console.log(this.state.qoddata)
  }
  render() {
    console.log(this.state.qoddata)
    let data = this.props.question_of_the_day.previous_performance.map(
      (day) => {
        return { correct_answered: day.correct_answered, value: 0 };
      }
    );
    const radius = 4;
    // console.log(data);
    let renderCustomizedLabel = (props) => {
      const { x, y, width, height, value, index } = props;
      // console.log(props);
      // console.log(this.props.question_of_the_day.previous_performance);
      return this.props.question_of_the_day.previous_performance[index]
        .correct_answered === "correct" ? (
        <g onClick={this.questionclick(index)}>
          <circle cx={x + width / 2} cy={y - radius} r={radius} fill="green" />

        </g>
      ) : this.props.question_of_the_day.previous_performance[index]
        .correct_answered === "wrong" ? (
        <g onClick={this.questionclick(index)}>
          <circle cx={x + width / 2} cy={y - radius} r={radius} fill="red" />

        </g>
      ) : (
        <g onClick={this.questionclick(index)}>
          <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#80808078" />

        </g>
      );
    };
    return (
      <>
      <div style={{display:"flex",justifyContent:"space-evenly"}}>
        {data.map((previousdata, index) => {

          return(

            previousdata
            .correct_answered === "correct" ? (
              <Dot onClick={() =>this.questionclick(index)}  style={{background:"green"}}></Dot>
          ) : previousdata
            .correct_answered === "wrong" ? (
              <Dot onClick={() =>this.questionclick(index)} style={{background:"red"}}></Dot>
          ) : (
            <Dot onClick={() =>this.questionclick(index)} style={{background:"#80808078"}}></Dot>
          )
          ) 

        })}
       
      </div>
     {this.state.qoddata ?
       <Dialog
        open={this.state.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-describedby="alert-dialog-slide-description"
       
      >
        <DialogTitle>Question of the day on {this.state.qoddata.display_date} -
         {this.state.qoddata.correct_answered=="unanswered" ? 
         <span style={{color:"grey"}}>Unanswered</span>
         :this.state.qoddata.correct_answered=="correct" ?
         <span style={{color:"green"}}>Correct</span>
          :<span style={{color:"red"}}>Wrong</span>}
          </DialogTitle>
        <DialogContent  
        // sx={{
        //   overflow: 'hidden',
        // }}
        >
          <DialogContentText id="alert-dialog-slide-description" >
          <div
                    style={{ margin: "10px 4px 2px 5px", textAlign: "left" }}
                  >
                    {this.state.qoddata.question_image ? (
                      <div className="row" style={{ marginTop: "10px" }}>
                       
                        <div className="col-12">
                          <Equation3
                            font_size="12px"
                            math_type={
                              this.state.qoddata.name.includes("$")
                                ? 1
                                : 0
                            }
                            equation={this.state.qoddata.name}
                          ></Equation3>
                        </div>

                        <img
                          src={this.state.qoddata.question_image}
                          alt="question"
                          style={{ width: "40%", height: "100%", cursor: "pointer", marginLeft: "25%" }}
                          onClick={() =>
                            this.onImageClick(
                              this.state.qoddata.question_image
                            )
                          }
                        />
                      </div>
                    ) : (
                      <Equation3
                        font_size="12px"
                        math_type={
                          this.state.qoddata.name.includes("$")
                            ? 1
                            : 0
                        }
                        equation={this.state.qoddata.name}
                      ></Equation3>
                    )}
                  </div>
                  <div className="row" style={{ width: "100%" }}>
                    {this.state.qoddata.options[0].option1 ||
                      this.state.qoddata.options[0].option1_image ? (
                      <div className="col-6 my-1">
                        <div
                          className="qotdOption1"
                         
                          style={this.state.qoddata.options[0].actual_answer==1 ?
                             { background:'#008000b0'} 
                             :this.state.qoddata.options[0].selected_option==1 ?
                              {background : '#ff000091'}:null}
                        >
                          {this.state.qoddata.options[0]
                            .option1_image ? (
                            <div className="row p-1">
                              <div
                                className="col-12 mb-2"
                                onClick={() =>
                                  this.onImageClick(
                                    this.state.qoddata.options[0]
                                      .option1_image
                                  )
                                }
                              >
                                <img
                                  src={
                                    this.state.qoddata.options[0]
                                      .option1_image
                                  }
                                  alt="option1"
                                  style={{
                                    width: "100%",
                                    cursor: "pointer",
                                    maxHeight: "80px",
                                  }}
                                  height="100%"
                                />
                              </div>
                              <div
                                className="col-12"
                               
                              >
                                <Equation3
                                  color="black"
                                  bold
                                  font_size="12px"
                                  math_type={
                                    this.state.qoddata.options[0].option1.includes(
                                      "$"
                                    )
                                      ? 1
                                      : 0
                                  }
                                  equation={
                                    this.state.qoddata.options[0]
                                      .option1
                                  }
                                ></Equation3>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <Equation3
                                bold
                                color="black"
                                font_size="12px"
                                math_type={
                                  this.state.qoddata.options[0].option1.includes(
                                    "$"
                                  )
                                    ? 1
                                    : 0
                                }
                                equation={
                                  this.state.qoddata.options[0]
                                    .option1
                                }
                              ></Equation3>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                    {this.state.qoddata.options[0].option2 ||
                      this.state.qoddata.options[0].option2_image ? (
                      <div className="col-6 my-1">
                        <div
                          className="qotdOption1"
                        
                          style={this.state.qoddata.options[0].actual_answer==2 ?
                            { background:'#008000b0'} 
                            :this.state.qoddata.options[0].selected_option==2 ?
                              {background : '#ff000091'}:null}
                       >
                        
                          {this.state.qoddata.options[0]
                            .option2_image ? (
                            <div className="row">
                              <div
                                className="col-12 mb-2"
                                onClick={() =>
                                  this.onImageClick(
                                    this.state.qoddata.options[0]
                                      .option2_image
                                  )
                                }
                              >
                                <img
                                  src={
                                    this.state.qoddata.options[0]
                                      .option2_image
                                  }
                                  alt="option2"
                                  style={{
                                    width: "100%",
                                    cursor: "pointer",
                                    maxHeight: "80px",
                                  }}
                                  height="100%"
                                />
                              </div>
                              <div className="col-8">
                                <Equation3
                                  color="black"
                                  bold
                                  font_size="12px"
                                  math_type={
                                    this.state.qoddata.options[0].option2.includes(
                                      "$"
                                    )
                                      ? 1
                                      : 0
                                  }
                                  equation={
                                    this.state.qoddata.options[0]
                                      .option2
                                  }
                                ></Equation3>
                              </div>
                            </div>
                          ) : (
                            <div
                            >
                              <Equation3
                                color="black"
                                bold
                                font_size="12px"
                                math_type={
                                  this.state.qoddata.options[0].option2.includes(
                                    "$"
                                  )
                                    ? 1
                                    : 0
                                }
                                equation={
                                  this.state.qoddata.options[0]
                                    .option2
                                }
                              ></Equation3>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                    {this.state.qoddata.options[0].option3 ||
                      this.state.qoddata.options[0].option3_image ? (
                      <div className="col-6 my-1">
                        <div className="qotdOption1" style={this.state.qoddata.options[0].actual_answer==3 ?
                            { background:'#008000b0'} 
                            :this.state.qoddata.options[0].selected_option==3 ?
                              {background : '#ff000091'}:null}>
                          {this.state.qoddata.options[0]
                            .option3_image ? (
                            <div className="row">
                              <div
                                className="col-12 mb-2"
                                onClick={() =>
                                  this.onImageClick(
                                    this.state.qoddata.options[0]
                                      .option3_image
                                  )
                                }
                              >
                                <img
                                  src={
                                    this.state.qoddata.options[0]
                                      .option3_image
                                  }
                                  alt="option3"
                                  style={{
                                    width: "100%",
                                    cursor: "pointer",
                                    maxHeight: "80px",
                                  }}
                                  height="100%"
                                />
                              </div>
                              <div
                                className="col-12"
                              >
                                <Equation3
                                  color="black"
                                  bold
                                  font_size="12px"
                                  math_type={
                                    this.state.qoddata.options[0].option3.includes(
                                      "$"
                                    )
                                      ? 1
                                      : 0
                                  }
                                  equation={
                                    this.state.qoddata.options[0]
                                      .option3
                                  }
                                ></Equation3>
                              </div>
                            </div>
                          ) : (
                            <div
                            >
                              <Equation3
                                color="black"
                                bold
                                font_size="12px"
                                math_type={
                                  this.state.qoddata.options[0].option3.includes(
                                    "$"
                                  )
                                    ? 1
                                    : 0
                                }
                                equation={
                                  this.state.qoddata.options[0]
                                    .option3
                                }
                              ></Equation3>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                    {this.state.qoddata.options[0].option4 ||
                      this.state.qoddata.options[0].option4_image ? (
                      <div className="col-6 my-1">
                        <div className="qotdOption1"  style={this.state.qoddata.options[0].actual_answer==4 ?
                            { background:'#008000b0'} 
                            :this.state.qoddata.options[0].selected_option==4 ?
                              {background : '#ff000091'}:null}>
                          {this.state.qoddata.options[0]
                            .option4_image ? (
                            <div className="row">
                              <div
                                className="col-12 mb-2"
                                onClick={() =>
                                  this.onImageClick(
                                    this.state.qoddata.options[0]
                                      .option4_image
                                  )
                                }
                              >
                                <img
                                  src={
                                    this.state.qoddata.options[0]
                                      .option4_image
                                  }
                                  alt="option4"
                                  style={{
                                    width: "100%",
                                    cursor: "pointer",
                                    maxHeight: "80px",
                                  }}
                                  height="100%"
                                />
                              </div>
                              <div
                                className="col-12"
                              >
                                <Equation3
                                  color="black"
                                  bold
                                  font_size="12px"
                                  math_type={
                                    this.state.qoddata.options[0].option4.includes(
                                      "$"
                                    )
                                      ? 1
                                      : 0
                                  }
                                  equation={
                                    this.state.qoddata.options[0]
                                      .option4
                                  }
                                ></Equation3>
                              </div>
                            </div>
                          ) : (
                            <div
                            >
                              <Equation3
                                color="black"
                                bold
                                font_size="12px"
                                math_type={
                                  this.state.qoddata.options[0].option4.includes(
                                    "$"
                                  )
                                    ? 1
                                    : 0
                                }
                                equation={
                                  this.state.qoddata.options[0]
                                    .option4
                                }
                              ></Equation3>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                    {this.state.qoddata.options[0].option5 ||
                      this.state.qoddata.options[0].option5_image ? (
                      <div className="col-6 my-1">
                        <div className="qotdOption1"  style={this.state.qoddata.options[0].actual_answer==5 ?
                            { background:'#008000b0'} 
                            :this.state.qoddata.options[0].selected_option==5 ?
                              {background : '#ff000091'}:null} >
                          {this.state.qoddata.options[0]
                            .option5_image ? (
                            <div className="row">
                              <div
                                className="col-12 mb-2"
                                onClick={() =>
                                  this.onImageClick(
                                    this.state.qoddata.options[0]
                                      .option5_image
                                  )
                                }
                              >
                                <img
                                  src={
                                    this.state.qoddata.options[0]
                                      .option5_image
                                  }
                                  alt="option5"
                                  style={{
                                    width: "100%",
                                    cursor: "pointer",
                                    maxHeight: "80px",
                                  }}
                                  height="100%"
                                />
                              </div>
                              <div
                                className="col-12"
                              >
                                <Equation3
                                  color="black"
                                  bold
                                  font_size="12px"
                                  math_type={
                                    this.state.qoddata.options[0].option5.includes(
                                      "$"
                                    )
                                      ? 1
                                      : 0
                                  }
                                  equation={
                                    this.state.qoddata.options[0]
                                      .option5
                                  }
                                ></Equation3>
                              </div>
                            </div>
                          ) : (
                            <div
                            >
                              <Equation3
                                color="black"
                                bold
                                font_size="12px"
                                math_type={
                                  this.state.qoddata.options[0].option5.includes(
                                    "$"
                                  )
                                    ? 1
                                    : 0
                                }
                                equation={
                                  this.state.qoddata.options[0]
                                    .option5
                                }
                              ></Equation3>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                    <div>Solution</div>
                     <Equation3 math_type={1} equation={this.state.solution}></Equation3>
                  </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
  
        </DialogActions>
      </Dialog>
      :null}
      <style jsx='true' scoped>{`
          .qotdOption1{
            width: auto;
            border-radius: 24px;
            padding: 4px 14px;
            height: 100%;
            cursor: pointer;
            border: 1px solid rgb(234, 234, 234);
            background: #8080800f;
          }
          .equation p{
            font-size:12px;
          }
        `}
        </style>
      </>
    );
    
  }
}

export default QotdGraph;