import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import styled, { keyframes, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { AiOutlineFieldTime } from "react-icons/ai";
import { VscGraph } from "react-icons/vsc";
import { AiOutlineFileDone } from "react-icons/ai";
import { GrDocumentPerformance } from "react-icons/gr";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import Tooltip from '@mui/material/Tooltip';
import moment from "moment";
import {FiArrowDown} from "react-icons/fi";
import {FiArrowUp} from "react-icons/fi";
const Leaderboardrow = styled.div`
    display: flex;
    font-size: 10px;
   align-items: center;
    font-weight: 600;
    border-bottom: 1px solid #80808052;
    padding: 12px 0px 12px 0px;
    padding-left: 0px !important;
  `;
function Leaderboardresults(props) {
console.log(props.data);
const [showmoreperformancedata,setshowmoreperformancedata]=useState(false);
const [showmoreparticipation,setshowmoreparticipation]=useState(false);

const showperformacedata = () => {
  setshowmoreperformancedata(true);
}
const showlessperformacedata = () => {
  setshowmoreperformancedata(false);
}
const showparticipation = () => {
  setshowmoreparticipation(true);
}
const showlessparticipation = () => {
  setshowmoreparticipation(false);
}
const calculateperformanceindication=(percentage)=>{
  let percentagenotation;
  // console.log(percentage)
if(percentage >= 0 && percentage < 30){
  percentagenotation="Poor";
}else if (percentage >= 31 && percentage <= 60){
  percentagenotation="Average";
}else if (percentage >= 61 && percentage <= 80){
  percentagenotation="Good";
}else if (percentage >= 81 && percentage<=100){
  percentagenotation="Proficient";
}
// console.log(percentagenotation)
return percentagenotation;
}
 
  return (
    <>
        <div className="row col-lg-12" style={{ position: "relative", top: "30px", padding: "2px", background: "white", borderRadius: "20px", marginLeft: "0px" }}>
          {/* <div style={{ display: "flex", padding: "0px 5px 0px 5px" }}>
          <Tooltip title={`Mcq:- ${props.data[1].mcq_avg_percentage_scored} % ,Subjective:-${props.data[1].subjective_avg_percentage_scored} % , ,Coding:-${props.data[1].coding_avg_percentage_scored} %`} followCursor>
            <div style={{
              height: "auto", boxShadow: "#80808061 1px 1px 4px",
              fontSize: "11px", padding: "6px", borderRadius: "10px", marginRight: "9px", position: "relative", top: "15px", backgroung: "white"
             }}>
              <img src="https://www.w3schools.com/howto/img_avatar.png" style={{ height: "25px", borderRadius: "50%" }}></img>
              <div style={{ fontWeight: "600" }}>{props.data[1].student_name}</div>
              <div style={{ fontWeight: "600" }}>{props.data[1].overall_performance} %</div>
              <div className="leaderboardrank" style={{ position: "absolute", top: "-19px", right: "-10px", background: "rgb(255 220 141)" }}>2</div>
            </div>
            </Tooltip>
            <Tooltip title={`Mcq:- ${props.data[0].mcq_avg_percentage_scored} % ,Subjective:-${props.data[0].subjective_avg_percentage_scored} % , ,Coding:-${props.data[0].coding_avg_percentage_scored} %`} followCursor>
            <div style={{
              height: "auto", boxShadow: "#80808061 1px 1px 4px",
              fontSize: "11px", padding: "6px", borderRadius: "10px", marginRight: "14px", position: "relative", backgroung: "white"
            }}>
              <img src="https://www.w3schools.com/howto/img_avatar.png" style={{ height: "35px", borderRadius: "50%" }}></img>
              <div style={{ fontWeight: "600" }}>{props.data[0].student_name}</div>
              <div style={{ fontWeight: "600" }}>{props.data[0].overall_performance} %</div>
              <div className="leaderboardrank" style={{ position: "absolute", top: "-19px", right: "-10px", background: "rgb(253 176 200)" }}>1</div>
            </div>
            </Tooltip>
            <Tooltip title={`Mcq:- ${props.data[2].mcq_avg_percentage_scored} % ,Subjective:-${props.data[2].subjective_avg_percentage_scored} % , ,Coding:-${props.data[2].coding_avg_percentage_scored} %`} followCursor>
            <div style={{
              height: "auto", boxShadow: "#80808061 1px 1px 4px",
              fontSize: "11px", padding: "6px", borderRadius: "10px", position: "relative", top: "15px"
            }}>
              <img src="https://www.w3schools.com/howto/img_avatar.png" style={{ height: "25px", borderRadius: "50%" }}></img>
              <div style={{ fontWeight: "600" }}>{props.data[2].student_name}</div>
              <div style={{ fontWeight: "600" }}>{props.data[2].overall_performance} %</div>
              <div className="leaderboardrank" style={{ position: "absolute", top: "-19px", right: "-10px", background: "#9bdcff" }}>3</div>
            </div>
            </Tooltip>
          </div> */}


     <div style={{ display: "flex", padding: "0px 5px 0px 5px",justifyContent:"space-evenly"}}>
          {/* <Tooltip followCursor> */}
            <div style={{
              height: "auto", boxShadow: "#80808061 1px 1px 4px",
              fontSize: "11px", padding: "6px", borderRadius: "10px", marginRight: "9px", position: "relative", top: "15px", background: "white",width:"30%"
             }}>
              <img src={props.data[1].student_name.gender=="Male" || props.data[1].student_name.gender=="MALE" ? "https://www.w3schools.com/howto/img_avatar.png":"https://www.w3schools.com/howto/img_avatar2.png"} style={{ height: "25px", borderRadius: "50%" }}></img>
              <div style={{ fontWeight: "600" }}>{props.data[1].student_name}</div>
              <div style={{ fontWeight: "600" }}>{props.data[1].mcq_avg_percentage_scored}</div>
              <div className="leaderboardrank" style={{ position: "absolute", top: "-19px", right: "-10px", background: "rgb(255 220 141)" }}>2</div>
            </div>
            {/* </Tooltip>
            <Tooltip  followCursor> */}
            <div style={{
              height: "auto", boxShadow: "#80808061 1px 1px 4px",
              fontSize: "11px", padding: "6px", borderRadius: "10px", marginRight: "14px", position: "relative", backgroung: "white",width:"30%"
            }}>
              <img src={props.data[0].student_name.gender=="Male" || props.data[0].student_name.gender=="MALE" ? "https://www.w3schools.com/howto/img_avatar.png":"https://www.w3schools.com/howto/img_avatar2.png"} style={{ height: "25px", borderRadius: "50%" }}></img>
              <div style={{ fontWeight: "600" }}>{props.data[0].student_name}</div>
              <div style={{ fontWeight: "600" }}>{props.data[0].mcq_avg_percentage_scored}</div>
              <div className="leaderboardrank" style={{ position: "absolute", top: "-19px", right: "-10px", background: "rgb(253 176 200)" }}>1</div>
            </div>
            {/* </Tooltip>
            <Tooltip  followCursor> */}
            <div style={{
              height: "auto", boxShadow: "#80808061 1px 1px 4px",
              fontSize: "11px", padding: "6px", borderRadius: "10px", position: "relative", top: "15px",width:"30%"
            }}>
              <img src={props.data[2].student_name.gender=="Male" || props.data[2].student_name.gender=="MALE" ? "https://www.w3schools.com/howto/img_avatar.png":"https://www.w3schools.com/howto/img_avatar2.png"} style={{ height: "25px", borderRadius: "50%" }}></img>
              <div style={{ fontWeight: "600" }}>{props.data[2].student_name}</div>
              <div style={{ fontWeight: "600" }}>{props.data[2].mcq_avg_percentage_scored}</div>
              <div className="leaderboardrank" style={{ position: "absolute", top: "-19px", right: "-10px", background: "#9bdcff" }}>3</div>
            </div>
            {/* </Tooltip> */}
          </div>



          <div className="row" style={{
            padding: "0px", borderRadius: "20px", margin: "30px 0px 0px 0px", background: "white"
          }}>
          {!showmoreperformancedata ? 
           <>
           
             {props.data.slice(3,5).map((performance_leaderboard, index) => {
              return (
                // <Tooltip title={`Mcq:- ${performance_leaderboard.mcq_avg_percentage_scored} % ,Subjective:-${performance_leaderboard.subjective_avg_percentage_scored} % , ,Coding:-${performance_leaderboard.coding_avg_percentage_scored} %`} followCursor>
                <Leaderboardrow className="col-lg-12">
                  <div className="col-lg-1" style={{ padding: "0px", fontSize: "12px" }}>{index + 4}</div>
                  <div className="col-lg-1" style={{ padding: "0px" }}>
                    <img src={performance_leaderboard.gender=="Male" || performance_leaderboard.gender=="MALE" ? "https://www.w3schools.com/howto/img_avatar.png":"https://www.w3schools.com/howto/img_avatar2.png"} style={{ height: "25px", borderRadius: "50%" }}></img></div>
                  <div className="col-lg-5"><div>{performance_leaderboard.student_name}</div><div style={{ fontSize: "9px", color: "grey" }}>{performance_leaderboard.student_roll_number}</div></div>
                  <div className="col-lg-3" style={{ padding: "0px" }}>{performance_leaderboard.mcq_avg_percentage_scored} %</div>
                  <div className="col-lg-2" style={{ padding: "0px" }}>{calculateperformanceindication(performance_leaderboard.mcq_avg_percentage_scored)}</div>
                </Leaderboardrow>
                // </Tooltip>
              )
              
            })} 
               {props.data.length>5 ? <button className="btn btn-primary" onClick={showperformacedata}
            style={{padding:"2px",fontSize:"10px"}}>Show More <FiArrowDown /></button>:null } 
           </>
          
           : <>{props.data.slice(3).map((performance_leaderboard, index) => {
            return (
              // <Tooltip title={`Mcq:- ${performance_leaderboard.mcq_avg_percentage_scored} % ,Subjective:-${performance_leaderboard.subjective_avg_percentage_scored} % , ,Coding:-${performance_leaderboard.coding_avg_percentage_scored} %`} followCursor>
              <Leaderboardrow className="col-lg-12">
                <div className="col-lg-1" style={{ padding: "0px", fontSize: "12px" }}>{index + 4}</div>
                <div className="col-lg-1" style={{ padding: "0px" }}>
                <img src={performance_leaderboard.gender=="Male"|| performance_leaderboard.gender=="MALE" ? "https://www.w3schools.com/howto/img_avatar.png":"https://www.w3schools.com/howto/img_avatar2.png"} style={{ height: "25px", borderRadius: "50%" }}></img></div>
                <div className="col-lg-5"><div>{performance_leaderboard.student_name}</div><div style={{ fontSize: "9px", color: "grey" }}>{performance_leaderboard.student_roll_number}</div></div>
                <div className="col-lg-3" style={{ padding: "0px" }}>{performance_leaderboard.mcq_avg_percentage_scored} %</div>
                <div className="col-lg-2" style={{ padding: "0px" }}>{calculateperformanceindication(performance_leaderboard.mcq_avg_percentage_scored)}</div>
              </Leaderboardrow>
              // </Tooltip>
            )
            
          })}
            <button className="btn btn-primary" onClick={showlessperformacedata}
          style={{padding:"2px",fontSize:"10px"}}>Show less <FiArrowUp /></button>
          </>
          }
              

          </div>
        </div>
       
    </>
  );
}

function mapStateToProps(state) {
  return {
    query_date: state.dash.query_date,
    loading_analyticsdata: state.dash.loading_analyticsdata,
    error_loading_analyticsdata: state.dash.error_loading_analyticsdata,
    analytics_data: state.dash.analytics_data,
    analytics_data_semester: state.dash.analytics_data_semester,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    //  getWallDatares:(query_date)=>dispatch(getWallData(query_date)),
  };
};
export default withRouter(
  connect(mapStateToProps, null)(Leaderboardresults)
);
