import React, { useEffect, useLayoutEffect, useState } from 'react';
import useTimeSpentTimer from '../../hooks/useTimeSpentTimer';
import {styled, useTheme} from '@mui/material/styles';
import { Box, Button, Chip, Grid, Modal, TextField, Tooltip, Typography } from '@mui/material';
import colors2 from '../../pages/codingscreen/colors2';
import { darkModeColors } from '../../pages/codingscreen/colors2';
import LogDetails from './logDetails';

const TestBox = styled(Box)(({ theme }) => ({
    // background: theme.palette.grey[100],
    padding: 0,
    paddingTop: '15px',
    height: 'calc(100vh - 65px)',
    overflow: 'hidden'
    // background: colors2.secondary,
    // overflowY: 'scroll',
    // overflowX: 'hidden',
    // '&::-webkit-scrollbar': {
    //     width: '2px', 
    // },
    // '&::-webkit-scrollbar-thumb': {
    //     background: theme.palette.grey[300], 
    //     borderRadius: '4px', 
    // },
    // '&::-webkit-scrollbar-thumb:hover': {
    //     background: theme.palette.grey[800], 
    // },


    // '&:hover': {
    //     overflowY: 'scroll', 
    //     '&::-webkit-scrollbar': {
    //         width: '4px', 
    //     },
    //     '&::-webkit-scrollbar-thumb': {
    //         background: theme.palette.grey[600], 
    //         borderRadius: '4px', 
    //     },
    //     '&::-webkit-scrollbar-thumb:hover': {
    //         background: theme.palette.grey[800], 
    //     },
    // },
}));

const CodingTestScreen = (props) => {
    const [inputParameters, setInputParameters] = useState('');
    const [questionSubmissionHistory, setQuestionSubmissionHistory] = useState(null);
    const [subType, setSubType] = useState(0);
    const [isLogOpen, setIsLogOpen] = useState(false);
    const [openLogInfo, setOpenLogInfo] = useState(null);
    const [isInputEmptyForRunCode, setIsInputEmptyForRunCode] = useState(false);
    const [paletteColors, setPaletteColors] = useState(colors2);
    // const [logStatusColor, setLogStatusColor] = useState('#959595');
    // const { startTimer, stopTimer } = useTimeSpentTimer();
    const theme = useTheme();

    // useEffect(() => {
    //     startTimer();
    // }, []);

    useEffect(() => {
        if (props.isDarkMode) {
            setPaletteColors(darkModeColors);
        }
        else {
            setPaletteColors(colors2);
        }
    }, [props.isDarkMode]);

    useEffect(() => {
        const sub = props.submissionHistory.slice().reverse();
        // setInputParameters('');
        console.log(sub);
        setQuestionSubmissionHistory(sub);
        if  (props.openSubmissionPopup === true) {
            if (sub.length>0) {
                handleOpenLog(sub[0]);
            }
            props.handleSubmissionPopup();
        }
    }, [props.submissionHistory]);

    useEffect(() => {
        if (props.isCodeBeingSubmitted) {
            let sub_history = [];
            if (questionSubmissionHistory) {
                sub_history = questionSubmissionHistory;
            }
            const new_submission = {};
            new_submission.status_name = 'running';
            new_submission.submission_timestamp = handleTimeStampFormatConversion('', true);
            new_submission.submission_type = subType;
            sub_history.unshift(new_submission);
            setQuestionSubmissionHistory(sub_history);
        }
    }, [props.isCodeBeingSubmitted]);

    useEffect(() => {
        if (props.errorSubmittingQuestion) {
            const sub_history = questionSubmissionHistory;
            if (sub_history.length > 0) {
                sub_history[0].status_name = 'Unable to Submit';
                setQuestionSubmissionHistory(sub_history);
            }
        } 
    }, [props.errorSubmittingQuestion]);

    const handleOpenLog = (log) => {
        setIsLogOpen(true);
        setOpenLogInfo(log);
        // if (log?.status_code === 15) {
        //     setLogStatusColor('green');
        // }
        // else if (log?.status_code === 14) {
        //     setLogStatusColor('orange');
        // } 
        // else if (log?.status_code === 11 || log?.status_code === 12 || log?.status_code === 19 || log?.status_code === 20) {
        //     setLogStatusColor('red');
        // }
    };

    const handleCloseLog = () => {
        console.log("close");
        setIsLogOpen(false);
        setOpenLogInfo(null);
        // setLogStatusColor('#959595');
    };

    const handleTimeStampFormatConversion = (time, new_submission) => {
        let utcDate;
        let localDate;
        if (!new_submission) {
            utcDate = new Date(time);
            localDate = new Date(utcDate.getTime() + (5.5 * 60 * 60 * 1000));
        }
        else {
            utcDate = new Date();
            localDate = new Date(utcDate.getTime());
        }
        
        // const localDate = new Date(utcDate.getTime() + (5.5 * 60 * 60 * 1000)); // Adjust for India's UTC+5.5
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            // hour: 'numeric',
            // minute: 'numeric',
            // second: 'numeric',
            hour12: false,
        };

        const formattedDate = localDate.toLocaleString('en-US', options).replace('at', '');
        return formattedDate;
    }

    const handleInputParameters = (event) => {
        setInputParameters(event.target.value);
        setIsInputEmptyForRunCode(false);
    }

    const handleClearLog = () => {
        setQuestionSubmissionHistory(null);
    }

    const handleSubmit = () => {
        if (!props.disableSubmit) { //when submit button is not disabled
            handleRunCode(3)
        }
    } 

    const handleRunCode = (submission_type) => {
        setSubType(submission_type);
        // const elapsedTimeInSeconds = stopTimer();
        if (submission_type === 1) {
            if (inputParameters !== '') { 
                setIsInputEmptyForRunCode(false);
                props.handleTimeSpentAndSubmissionTypeAndInputParameters(submission_type, inputParameters); //For running code with custom input
            }
            else {
                setIsInputEmptyForRunCode(true);
            }
        }
        else if (submission_type === 2) {
            props.handleTimeSpentAndSubmissionTypeAndInputParameters(submission_type, ''); //For running code against test cases
            setIsInputEmptyForRunCode(false);
        }
        else if (submission_type === 3) {
            props.handleTimeSpentAndSubmissionTypeAndInputParameters(submission_type, ''); //For running code against test cases
            setIsInputEmptyForRunCode(false);
        }
        // console.log('Elapsed Time:', elapsedTimeInSeconds, 'seconds');
        // startTimer();
    };

    const goToNextQues = () => {
        props.moveToNextQuestion(props.nextQuestionId);
    }; 

    return (
        <>
        <TestBox sx={{background: paletteColors.backgroundSecondary, overflowX: 'hidden'}}>
            <Grid container direction='column'>
                <Grid item>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item sx={{marginLeft: '15px', marginTop: '5px'}}>
                            <Tooltip 
                                title={"Run your code"} 
                                placement='top' 
                                arrow
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: paletteColors.grey[800], 
                                            color: !props.isDarkMode ? paletteColors.backgroundSecondary : paletteColors.common.white,
                                        }
                                    },
                                    arrow: {
                                        sx: {
                                            color: paletteColors.grey[800]
                                        }
                                    }
                                }}
                            >
                                <Button 
                                    variant= {!props.isDarkMode ? 'filled' : 'outlined'} 
                                    size='small' 
                                    sx={{background: !props.isDarkMode ? paletteColors.secondary : paletteColors.grey1, padding: '5px 15px', marginRight: '7.5px', border: !props.isDarkMode ? 'none' : paletteColors.companyTagPrimary, '&:hover': {background: !props.isDarkMode ? paletteColors.secondary : paletteColors.grey1, border: !props.isDarkMode ? 'none' : paletteColors.companyTagPrimary,}}} 
                                    onClick={() => handleRunCode(1)}
                                >
                                    <Typography variant="caption2" sx={{display: 'flex', justifyContent: "center", flexWrap: 'nowrap', color: !props.isDarkMode ? paletteColors.primary : paletteColors.textPrimary}}>
                                        Run Code&nbsp;
                                        <Box component="img" src={!props.isDarkMode ? "/images/run.svg" : "/images/run_white.svg"}/>
                                    </Typography>
                                </Button>
                            </Tooltip>
                            <Tooltip 
                                title={'Evaluate code against test cases'} 
                                placement='top' 
                                arrow
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: paletteColors.grey[800], 
                                            color: !props.isDarkMode ? paletteColors.backgroundSecondary : paletteColors.common.white,
                                        }
                                    },
                                    arrow: {
                                        sx: {
                                            color: paletteColors.grey[800]
                                        }
                                    }
                                }}
                            >
                                <Button
                                    variant= {!props.isDarkMode ? 'filled' : 'outlined'} 
                                    size='small' 
                                    sx={{background: !props.isDarkMode ? paletteColors.secondary : paletteColors.grey1, padding: '5px 15px', border: !props.isDarkMode ? 'none' : paletteColors.companyTagPrimary, '&:hover': {background: !props.isDarkMode ? paletteColors.secondary : paletteColors.grey1, border: !props.isDarkMode ? 'none' : paletteColors.companyTagPrimary,}}}
                                    onClick={() => handleRunCode(2)}
                                >
                                    <Typography variant="caption2" sx={{display: 'flex', justifyContent: "center", flexWrap: 'nowrap',color: !props.isDarkMode ? paletteColors.primary : paletteColors.textPrimary}}>
                                        Run Test Cases&nbsp;
                                        <Box component="img" src={!props.isDarkMode ? "/images/test_tube.svg" : "/images/test_tube_white.svg"}/>
                                    </Typography>
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item sx={{margin: '5px 15px 0px 15px'}}>
                            <Tooltip 
                                title={props.disableSubmit ? 'Get 80% or more in run test cases to unlock' : 'Submit for final evaluation'} 
                                placement='top' 
                                arrow
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: paletteColors.grey[800], 
                                            color: !props.isDarkMode ? paletteColors.backgroundSecondary : paletteColors.common.white,
                                        }
                                    },
                                    arrow: {
                                        sx: {
                                            color: paletteColors.grey[800]
                                        }
                                    }
                                }}
                            >
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                        marginRight: '5px', 
                                        padding: '5px 15px',
                                        background: props.disableSubmit ? (!props.isDarkMode ? paletteColors.grey[500] : paletteColors.backgroundPrimary) : paletteColors.primary, 
                                        '&:hover': {
                                            background: props.disableSubmit ? (!props.isDarkMode ? paletteColors.grey[500] : paletteColors.backgroundPrimary) : paletteColors.primary
                                        }
                                    }} 
                                    // disabled={props.disableSubmit}
                                    onClick={() => handleSubmit()}
                                >
                                    <Typography variant="caption2" color={paletteColors.common.white}>Submit</Typography>
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item sx={{margin: '20px 15px', marginBottom: '5px'}}>
                    <TextField 
                        multiline 
                        label="Input parameters" 
                        value={inputParameters} 
                        variant="outlined" 
                        size='small' 
                        sx={{
                            width: '100%', 
                            border: !props.isDarkMode ? (theme) => `1px solid ${paletteColors.grey[300]}` : `1px solid ${paletteColors.grey1}`, 
                            background: !props.isDarkMode ? paletteColors.grey[100] : paletteColors.backgroundPrimary, 
                            // color: !props.isDarkMode ? paletteColors.textPrimary : paletteColors.textPrimary
                        }} 
                        inputProps={{ style: {color: !props.isDarkMode ? paletteColors.textPrimary : paletteColors.textPrimary, background: !props.isDarkMode ? paletteColors.grey[100] : paletteColors.backgroundPrimary,} }}
                        onChange={handleInputParameters}
                    />
                    {isInputEmptyForRunCode ?
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', marginTop: '1.5px'}}>
                        <Typography variant="caption1" color="red" sx={{textAlign: 'left'}}>*Please enter input parameters to run code</Typography>
                    </Box>
                    : null}
                    <Grid container justifyContent="flex-end" alignItems="center">
                        <Grid item>
                            <Typography variant="caption2" color={paletteColors.textPrimary} sx={{margin: '5px 10px', cursor: 'pointer'}}>Auto-clear</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption2" color={paletteColors.textPrimary} sx={{margin: '5px 10px', cursor: 'pointer'}} onClick={handleClearLog}>Clear log</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {questionSubmissionHistory && questionSubmissionHistory.length > 0 && (<Typography variant='body2' sx={{textAlign: 'left', margin: '5px 15px', color: !props.isDarkMode ? paletteColors.primaryLighter : paletteColors.textPrimary}}>Output Logs</Typography>)}
                {questionSubmissionHistory && questionSubmissionHistory.length > 0 && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        padding: '5px 10px',
                        textAlign: 'left',
                        background: !props.isDarkMode ? paletteColors.secondary : paletteColors.grey1,
                        margin: '0px 15px', 
                        overflow: 'hidden',
                        borderRadius: '5px',
                    }}
                >
                    <Typography variant='caption2' width="35%" sx={{color: !props.isDarkMode ? paletteColors.primary : paletteColors.textSecondary}}>Status</Typography>
                    <Typography variant='caption2' width="45%" sx={{color: !props.isDarkMode ? paletteColors.primary : paletteColors.textSecondary}}>Details</Typography>
                    <Typography variant='caption2' width="20%" sx={{color: !props.isDarkMode ? paletteColors.primary : paletteColors.textSecondary}}>Type</Typography>
                </Box>
                )}
                {questionSubmissionHistory && (
                    <Grid item 
                        sx={{
                            height: 'calc(100vh - 270px)',
                            margin: '0px 15px',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            '&::-webkit-scrollbar': {
                                width: '2px', 
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: `${paletteColors.grey[300]}`, 
                                borderRadius: '4px', 
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                background: `${paletteColors.grey[800]}`, 
                            },
                        }}>
                        {questionSubmissionHistory.map((sub,i) => {
                            // let status_color = '#959595';
                            // if (sub?.status_code === 15) {
                            //     status_color = 'green';
                            // }
                            // else if (sub?.status_code === 14) {
                            //     status_color = 'orange';
                            // }
                            // else if (sub?.status_code === 11 || sub?.status_code === 12 || sub?.status_code === 19 || sub?.status_code === 20) {
                            //     status_color = 'red';
                            // }
                            let status_color = '#959595';
                            let determinedStatus = 'Undefined';
                            
                            if (sub?.status_code === 15 || sub?.status_code === 14) {
                                if (sub?.submission_type === 1) {
                                    determinedStatus = 'Compiled';
                                    status_color = 'green';
                                }
                                else if (sub?.testcases?.length > 0) {
                                    let allStatus15 = false;
                                    let someStatus15 = false;
                                    allStatus15 = sub?.testcases?.every(item => item?.status_code === 15);
                                    if (allStatus15) {
                                        determinedStatus = 'Accepted';
                                        status_color = 'green';
                                    }
                                    else {
                                        someStatus15 = sub?.testcases?.some(item => item?.status_code === 15);
                                        determinedStatus = someStatus15 ? 'Partially Accepted' : 'Wrong Answer';
                                        status_color = 'orange';
                                    }
                                }
                            }
                            else {
                                if (sub?.status_code === 11) {
                                    determinedStatus = 'Compilation error';
                                    status_color = 'red';
                                }
                                else if (sub?.status_code === 12) {
                                    determinedStatus = 'Runtime error';
                                    status_color = 'red';
                                }
                                else if (sub?.status_code === 17) {
                                    determinedStatus = 'Limit exceeded';
                                    status_color = 'red';
                                }
                                else if (sub?.status_code === 19) {
                                    determinedStatus = 'Illegal system call';
                                    status_color = 'red';
                                }
                                else if (sub?.status_code === 20) {
                                    determinedStatus = 'Internal error';
                                    status_color = 'red';
                                }
                                else {
                                    determinedStatus = sub?.status_name;
                                    status_color = '#959595';
                                }
                            }

                            return (
                                <Box
                                    key={i} 
                                    onClick={() => handleOpenLog(sub)}
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        background: status_color === 'red' ? paletteColors.errorSecondary : !props.isDarkMode ? paletteColors.common.white : paletteColors.backgroundPrimary, 
                                        margin: '10px 0px', 
                                        borderRadius: '5px', 
                                        cursor: 'pointer',
                                        padding: '10px',
                                        textAlign: 'left',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            justifyContent: 'center',
                                            // gap: '5px',
                                            width: "35%"
                                        }}
                                    >
                                        {/* <Typography variant='caption3' color={sub?.status_code === 15 ? paletteColors.easy : sub?.status_code === 14 ? paletteColors.medium : sub?.status_code === 11 || sub?.status_code === 12 || sub?.status_code === 19 || sub?.status_code === 20 ? paletteColors.errorPrimary : '#959595'} sx={{textTransform: 'capitalize'}}>{sub?.status_name}</Typography> */}
                                        <Typography variant='caption3' color={status_color === 'green' ? paletteColors.easy : status_color === 'orange' ? paletteColors.medium : status_color === 'red' ? paletteColors.errorPrimary : '#959595'} sx={{textTransform: 'capitalize'}}>{determinedStatus}</Typography>
                                        <Typography variant='caption1' color={paletteColors.textPrimary}>{handleTimeStampFormatConversion(sub?.submission_timestamp)}</Typography>
                                        
                                        {/* <Typography variant='caption3' color={status_color === 'green' ? paletteColors.easy : status_color === 'orange' ? paletteColors.medium : status_color === 'red' ? paletteColors.errorPrimary : '#959595'} sx={{textTransform: 'capitalize'}}>{determinedStatus}</Typography>
                                        <Typography variant='caption1' color={paletteColors.textPrimary}>{handleTimeStampFormatConversion(sub?.submission_timestamp)}</Typography> */}
                                    </Box>
                                    <Typography variant='caption2' width="45%" color={paletteColors.textPrimary}>{sub?.execution_memory}kB / {sub?.execution_time}s / <Typography variant='caption2' color={paletteColors.primary} sx={{display: 'inline-block'}}>{sub?.compiler_name}</Typography></Typography>
                                    <Typography variant='caption2' width="20%" color={paletteColors.textPrimary}>{sub?.submission_type === 1 ? `Run Code` : sub?.submission_type === 2 ? `Run Test Cases` : 'Submit Code'}</Typography>
                                </Box>
                            )
                        })} 
                    </Grid>
                )}
            </Grid>
            <LogDetails 
                openLogInfo={openLogInfo}
                isLogOpen={isLogOpen}
                handleCloseLog={handleCloseLog}
                handleTimeStampFormatConversion={handleTimeStampFormatConversion}
                goToNextQues={goToNextQues}
                isDarkMode={props.isDarkMode}
            />
        </TestBox>
        </>
    )
}

export default CodingTestScreen;