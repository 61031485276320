import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import BorderLinearProgress from '../../../../components/common/BorderLinearProgress/BorderLinearProgress';

//variant can be -> drawer / card

const LabelTopicProgress = ({ variant, value, label }) => {
    return (
        <Box sx={{ padding: variant === 'card' ? '12px 0' : '6px 0' , flexGrow: '1' }}>
            <Stack gap={'4px'}>
                {variant === 'drawer' &&
                    <Typography component={'p'}
                        sx={{
                            fontFamily: 'Poppins-Medium',
                            fontSize: '14px',
                            color: (theme) => theme.palette.grey[900],
                            textAlign: 'left'
                        }}>{label}
                    </Typography>}
                <Stack direction={'row'} alignItems={'center'} gap={'14px'}>
                    {variant === 'card' &&
                        <Typography
                            component={'p'}
                            sx={{
                                fontFamily: 'Poppins-Medium',
                                fontSize: '14px',
                                color: (theme) => theme.palette.grey[900],
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                                width: '100px'
                            }}>{label}
                        </Typography>}

                    <BorderLinearProgress value={value} barColor={'primary.100'} progressColor={'primary.800'} />

                    <Typography
                        component={'p'}
                        sx={{
                            fontFamily: 'Poppins-SemiBold',
                            fontSize: '14px',
                            color: (theme) => theme.palette.primary[800]
                        }}>{value}%</Typography>
                </Stack>

            </Stack>
        </Box>
    );
};

export default LabelTopicProgress;
