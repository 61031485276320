import React, {Component} from 'react';
import { Fragment } from 'react';
// import { withRouter } from "react-router-dom";
import styled from 'styled-components';

const ActiveListItem = styled.li`
    margin: 5px 0;
    font-size: 16px;
    font-weight: 500;
    background-image: linear-gradient(to right, #DCFF73, #8ECA6B);
    left: 0;
    position: absolute;
    width: 23%;
    height: 60px;
    border: 1px solid linear-gradient(to right, #DCFF73, #8ECA6B);
    border-radius: 0 8px 8px 0;

    @media (max-width: 768px) {
        width: 25%;
    }
`;

const menuList = ['content', 'assessment', 'practice'];

const menuListNames = ['Course Content', 'Assessment', 'Practice Series'];

class CourseMenu extends Component {
    render () {
        let active = this.props.active_page;
        return (
            <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                <div style={{width: '90%'}}>
                    <ul style={{listStyleType: 'none', paddingLeft: '20px'}}>
                        {menuList.map((item, index)=> {
                            return <Fragment key={index}> 
                                {active == item ? <ActiveListItem onClick={(event)=>this.props.onMenuBarClicked(event,item)}>
                                    {active === item ? <div  style={{display: 'flex', flexDirection: 'row'}}>
                                        <div style={{width: '80%', marginTop: '20px', textAlign: 'center'}}>
                                            {menuListNames[index]}
                                        </div>
                                        <div style={{width: '20%', marginTop: '20px', textAlign: 'center'}}>
                                            <img src='/images/arrow_triangle.svg'></img>
                                        </div>
                                    </div> : menuListNames[index] }  
                                </ActiveListItem> : 
                                <li onClick={(event)=>this.props.onMenuBarClicked(event,item)} className='listItem'>
                                    {active === item ? <div  style={{display: 'flex', flexDirection: 'row'}}>
                                        <div style={{width: '80%', marginTop: '20px', textAlign: 'center'}}>{menuListNames[index]}</div>
                                        <div style={{width: '20%', marginTop: '20px', textAlign: 'center'}}>
                                            <img src='/images/arrow_triangle.svg'></img>
                                        </div>
                                    </div> : menuListNames[index]}
                                </li>
                                }
                                <li className={active == item ? 'nextActiveListLine' : 'listLine'}></li>
                            </Fragment>
                        })}
                    </ul>
                </div>
                <div style={{width: '10%'}}>
                    <div style={{borderLeft: '1px solid #ddd', height: '100%', marginLeft: '20px'}}></div>
                </div>
                
                <style jsx='true' scoped>{`
                    .listItem{
                        margin: 20px;
                        font-size: 16px;
                        font-weight: 500;
                        margin-left: 5px;
                    }
                    .activeListItem{
                        margin: 5px 0;
                        font-size: 16px;
                        font-weight: 500;
                        background-image: linear-gradient(to right, #DCFF73, #8ECA6B);
                        left: 0;
                        position: absolute;
                        width: 23%;
                        height: 60px;
                        border: 1px solid linear-gradient(to right, #DCFF73, #8ECA6B);
                        border-radius: 0 8px 8px 0;
                    }
                    .listLine{
                        border-bottom: 1px solid #ddd;
                    }
                    .nextActiveListLine{
                        padding: 35px;
                        border-bottom: 1px solid #ddd;
                    }
                `}
                </style>
            </div>
        )
    }
}

export default CourseMenu;