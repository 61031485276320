import React from 'react';
import styles from './NavigationButtons.module.css';

const NavigationButtons = (props) => (
  <div className={styles.NavigationButtons}>
    {props.children}
  </div>
);


export default NavigationButtons;
