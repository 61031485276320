import React, { Component } from "react";
import styled from 'styled-components';

const QuestionNumbersDiv = styled.div`
    border: ${({ current, correct, answered }) => (current ? "1px solid #4E54C8" : correct && answered ? '1px solid green' : answered && !correct ? '1px solid red' :  "1px solid #4E54C8")};
    background-color: ${({ current }) => (current ? "#4E54C8" :  null)};
    color: ${({ current, correct, answered }) => (current ? "#FFFFFF" : correct && answered ? 'green' : answered && !correct ? 'red' :  "#4E54C8")};
    display: inline-block;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    margin: 5px;
`

const QuestionNumbers = styled.p`
    margin-top: 6px;
    margin-bottom: 8px;
    font-size: 14px;
    color: ${({ current, correct, answered }) => (current ? "#FFFFFF" : correct && answered ? 'green' : answered && !correct ? 'red' :  "#4E54C8")};
    font-weight: 500;
`

class Circle extends Component {
    render () {
        return (
            <QuestionNumbersDiv answered={this.props.answered} correct={this.props.correct} current = {this.props.current} onClick={()=>this.props.onQuestionClick(this.props.number - 1)} key = {this.props.index}>
                <QuestionNumbers answered={this.props.answered} correct={this.props.correct} current = {this.props.current} >{this.props.number}</QuestionNumbers>
            </QuestionNumbersDiv>
        )
    }
}

export default Circle;