import React, {Component} from 'react';
import { ComposedChart, ResponsiveContainer, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip,Cell } from 'recharts';

const colors = ['#F38FA2', '#DCFF73', '#FF723B', '#DCFF73', '#F38FA2'];
const redGradient = (
    <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0.0} />
            </linearGradient>
          </defs>
);

const CustomTooltip = ({ active, payload, label }) => {
    console.log(active);
    if (!payload) {
        return;
    }
    if (active) {
      return (
        <div style={{border: '1px solid white', background: 'white', color: 'black', borderRadius: '8px'}}>
          <p className="label">{`${label} : ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };
class AssessmentLogGraph extends Component {
    tooltipFormatter = (value, name) => {
        if (name == 'your score') {
            return `You got ${value} percentage`;
        }
        return Math.floor(value);
    }
    render () {
        let data = this.props.data;
        return (
            <div>
                <ResponsiveContainer width="100%" height={200}>

                    <ComposedChart
                        data={data}
                        margin={{
                        top: 20,
                        right: 20,
                        left: -50,
                        // bottom: 20,
                        }}
                    >
                        {/* <CartesianGrid stroke="#f5f5f5" /> */}
                        <XAxis dataKey="date" padding={{ left: 30, right: 30 }} />
                        <YAxis tick={false}/>
                        <Tooltip formatter={(value, name)=>this.tooltipFormatter(value, name)} labelFormatter={()=>('')} itemStyle={{color: 'black'}} />
                        {/* <Legend /> */}
                        {/* <Tooltip content={<CustomTooltip />} /> */}
                        
                        {/* <Bar dataKey="your score" barSize={20} fill="#413ea0" /> */}
                        <Bar dataKey="your score" fill="#413ea0" barSize={20} radius={[8,8,0,0]}>
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index]} />
                            ))}
                        </Bar>
                        <Area  dataKey="average" fill="#E2DDFF" stroke="#E2DDFF" />
                        {/* <Scatter dataKey="cnt" fill="red" /> */}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        )
    }
}

export default AssessmentLogGraph;