import React, { useEffect, useState } from 'react'
import dummyImage from './../../../../assets/remedial/images/studyContent.png'
import { Avatar, AvatarGroup, Box, Stack, Typography, styled } from '@mui/material';
import GroupedAvatar from '../GroupedAvatar/GroupedAvatar';
import RedmedialButton from '../RemedialButtons/RedmedialButton';
import RemedialStudyModal from '../RemedialStudyModal/RemedialStudyModal';
import { useRemedialContext } from '../../hooks/useRemedial';
import { fetchDataFromApi } from '../../../../helpers/Api';
import { useParams } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import WheelUnlockedModal from '../WheelUnlockedModal/WheelUnlockedModal';
import BorderLinearProgress from '../../../../components/common/BorderLinearProgress/BorderLinearProgress';
import Equation from '../../../../utils';

const RemedialStudyCard = ({ data, index }) => {
  const [studyModal, setStudyModal] = useState(false);
  const { setStudyProgress , studyProgress } = useRemedialContext();
  const [totalPages, setTotalPages] = useState(0)
  const [snippetRead, setSnippetRead] = useState(0);
  const [videoWatched, setVideoWatched] = useState();
  const [documentRead, setdocumentRead] = useState();
  const [cardPercentage, setCardPercentage] = useState();
  const [unlock , setUnlocked] = useState(false);

  const { testId } = useParams();
  let { user_id } = JSON.parse(localStorage.getItem("user"))




  const StudyCard = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '16px',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[1000],
    'cover-image': {
      width: '100%',
      height: '160px',
      img: {
        objectFit: 'contain'
      }
    }
  }));


  const calculateTotalPages = (data, index) => {
    let totalPages = 0
    totalPages = data.snippet_url?.length
    // if (!isNaN(data.document_total)) {
    //   totalPages = totalPages + data.document_total
    // }
    // if (!isNaN(data.video_total)) {
    //   totalPages = totalPages + data.video_total
    // }
    // if (!isNaN(data.snippet_total)) {
    //   totalPages = totalPages + data.snippet_total
    // }
    return totalPages
  }

  // const handleCardReadPercentage = (data, studyPageData) => {
  //   let snippetRead = data.snippet_url.length > 0 ? data.noOfReading > data.snippet_current_read ? data.noOfReading : data.snippet_current_read : 0
  //   let videoWatched = data.video_url.length > 0 ? data.noOfVideos > data.video_current_read ? data.noOfVideos : data.video_current_read : 0
  //   let documentRead = data.document_url.length > 0 ? data.noOfSnippetss > data.document_current_read ? data.noOfSnippets : data.document_current_read : 0
  //   // let snippetRead = data.snippet_url.length > 0 ? data.noOfReading + 1 > data.snippet_current_read ? data.noOfReading + 1 : data.snippet_current_read : 0
  //   // let videoWatched = data.video_url.length > 0 ? data.noOfVideos + 1 > data.video_current_read ? data.noOfVideos + 1 : data.video_current_read : 0
  //   // let documentRead = data.document_url.length > 0 ? data.noOfSnippetss + 1 > data.document_current_read ? data.noOfSnippets + 1 : data.document_current_read : 0

  //   let percentage = snippetRead + videoWatched + documentRead / calculateTotalPages(studyPageData, index) * 100;
  //   return Math.ceil(percentage)
  // }

  const getTopicCode = () => {
    return data.code
  }
  const setMaxRead = (val) => {
    data.snippet_max_read = parseInt(val)
  }
  
console.log("------")
console.log(data)
  const RemedialStudyClose = async () => {
    setStudyModal(false);
    const status1 = await fetchDataFromApi(`/questionnaire/v2/trackRemedialStudy?current_page=${data.noOfReading}&reading_status=1&max_read=${snippetRead}&total_pages=${data.snippet_url?.length}&student_id=${user_id}&questionnaireId=${testId}&topic_code=${data.code}1&type=snippet&topic_learning_id=${data.snippet_material_id}`)
    
    // uncomment this code if video and doc content are ready
    // const status2 = await fetchDataFromApi(`/questionnaire/v2/trackRemedialStudy?current_page=${data.video_current_read}&reading_status=1&max_read=${videoWatched}&total_pages=${data.video_total}&student_id=${user_id}&questionnaireId=${testId}&topic_code=${data.code}1&type=video&topic_learning_id=${data.snippet_material_id}`)
    // const status3 = await fetchDataFromApi(`/questionnaire/v2/trackRemedialStudy?current_page=${data.document_current_read}&reading_status=1&max_read=${documentRead}&total_pages=${data.document_total}&student_id=${user_id}&questionnaireId=${testId}&topic_code=${data.code}1&type=doc&topic_learning_id=${data.snippet_material_id}`)

    // if (status1.status === 200 || status2.status === 200 || status3.status === 200) {
    //   fetchDataFromApi(`/questionnaire/v2/getRemedialStudyProgress?test_id=${testId}&student_id=${user_id}`).then(
    //     ({ data }) => {
    //       setStudyProgress(data.progress_percentage)
    //     }
    //   )
    // }
    if (status1.status === 200) {
      fetchDataFromApi(`/questionnaire/v2/getRemedialStudyProgress?test_id=${testId}&student_id=${user_id}`).then(
        ({ data }) => {
          setStudyProgress(data.progress_percentage)
          let topicCode = getTopicCode()
          let record = data.topics_progress.filter(topic => topic.topic_code === topicCode);
          setMaxRead(record[0].max_read)  
        }
      )
    }
    if(studyProgress > 75){
      setUnlocked(true)
    }
  }


  useEffect(() => {

    let snippetReadCount = data.snippet_url.length > 0 ? data.noOfReading > data.snippet_max_read ? data.noOfReading : data.snippet_max_read : 0
    let videoWatchedCount = data.video_url.length > 0 ? data.noOfVideos > data.video_current_read ? data.noOfVideos : data.video_current_read : 0
    let documentReadCount = data.document_url.length > 0 ? data.noOfSnippets > data.document_current_read ? data.noOfSnippets : data.document_current_read : 0
    setSnippetRead(snippetReadCount)
    setVideoWatched(videoWatchedCount)
    setdocumentRead(documentReadCount)

  }, [data])


  useEffect(() => {
    setTotalPages(calculateTotalPages(data));
    let totalRead = snippetRead 
    // let totalRead = snippetRead + videoWatched + documentRead
    setCardPercentage(Math.ceil(totalRead / totalPages * 100))
  }, [documentRead, snippetRead, videoWatched])








  return (
    <>
      <StudyCard>
        <Box className='cover-image'>
          <img src={dummyImage} alt='study-card-img' />
        </Box>
        <Stack gap={'12px'}>
          <Typography component='p' sx={{
            fontFamily: 'Poppins-SemiBold',
            color: 'grey.900',
            fontSize: '16px',
            textAlign: 'start',
            overflow:'hidden',
            textOverflow: 'ellipsis',
            whiteSpace:'nowrap',
            ':hover':{whiteSpace:'normal', cursor:'pointer'}
          }}><Equation equation={data.name}  math_type={data.name?.includes('$') ? 1 : 0} /></Typography>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} gap={'4px'}>
            <Typography component='p' sx={{
              fontFamily: 'Poppins-Regular',
              color: 'grey.800',
              fontSize: '12px',
              textAlign: 'start'
            }}>{totalPages} Pages</Typography>
            <Typography component='p' sx={{
              fontFamily: 'Poppins-Regular',
              color: 'grey.800',
              fontSize: '12px',
              textAlign: 'start'
            }}>{data.read_time} min read</Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} gap={'20px'}>
            <BorderLinearProgress value={cardPercentage} height={8} progressColor={'primary.800'} barColor={'primary.100'} />
            <Typography component='p' sx={{
              fontFamily: 'Poppins-Medium',
              color: 'primary.800',
              fontSize: '12px',
              textAlign: 'start'
            }}>{cardPercentage}%</Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
            <GroupedAvatar data={data?.students_reading_latest} />
            <Typography component='p' sx={{
              fontFamily: 'Poppins-Regular',
              color: 'grey.600',
              fontSize: '10px',
              textAlign: 'start'
            }}>{data?.students_reading_latest?.length > 99 ? `${data?.students_reading_latest?.length}+` : data?.students_reading_latest?.length} Students are studying</Typography>

          </Stack>


          <RedmedialButton color="primary.100" textColor='primary.600' textSize={12} size='medium' borderRadius={5} onClick={() => { setStudyModal(true) }}>{cardPercentage==100 ?'Read Again':cardPercentage==0?'Start Reading':'Resume Reading'}</RedmedialButton>

        </Stack>

      </StudyCard>

      {studyModal && <RemedialStudyModal percentage={cardPercentage} index={index} data={data} title={data.name} show={studyModal} onCloseHandle={() => RemedialStudyClose()} />}
      {unlock && <WheelUnlockedModal percentage={studyProgress} show={unlock} onClose={() => setUnlocked(false)} />}
    </>
  )
}

export default RemedialStudyCard