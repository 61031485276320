import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { useEffect, useRef, useState } from "react";
import Rating from '@mui/material/Rating';
import Slider from '@mui/material/Slider';
// import ScrollSpy from "react-ui-scrollspy";
import "./semesterfeedback.css";
import { GiConfirmed } from "react-icons/gi";
import {ImHourGlass} from "react-icons/im";
import { Link, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import {
    getSemesterfeedbackQuestions,
    submitFeedbackAC,
} from "../../store/actions/semesterfeedbackActions";
import html2canvas from "html2canvas";
import SideSubNav from "./SideSubNav";
import { TextField } from "@mui/material";

function Semesterfeedback(props) {
    const [Category, setCategory] = useState(false);
    const [Category_submitted, setCategorysubmittedcount] = useState([]);
    const [isVisible, setIsVisible] = useState(true);
    const [sem_feedback_id, setsem_feedback_id] = useState(null);
    const [sliderval, setsliderval] = useState({});
    const [enableSubmitButton, setEnableSubmitButton] = useState(true);
    const [subjectsList, setSubjectsList] = useState([]);
    const [currentSubject, setCurrentSubject] = useState({});
    const [subChanged, setSubChanged] = useState(0);
    const [subSwitched, setSubSwitched] = useState(false);
    const [allSubmitted, setAllSubmitted] = useState(false);
    const [textBasedValues, setTextBasedValues] = useState({});
    const [radioQuestions, setRadioQuestions] = useState([]);
    
    const handleTabChange = (event, newValue) => {
        setCategory(newValue)
    };

    
    useEffect(() => {
        function handlePopState(event) {
            window.location.href='/home';
        }
    
        window.addEventListener('popstate', handlePopState);
    
        return () => {
            window.removeEventListener('popstate', handlePopState);
            console.log("removed event listener");
        };
    }, []);

    useEffect(() => {
        if (props.location?.state?.sem_feedback_id) {
            setsem_feedback_id(props.location.state.sem_feedback_id)
        }
        if (props.location?.state.subjects) {
            console.log("subjects");
            console.log(props.location?.state.subjects)
            const current_subject = props.location.state.subjects.find(sub => sub.id === props.location.state.sem_feedback_id);
            if (current_subject){
                console.log("current subject");
                console.log(current_subject);
                setCurrentSubject(current_subject);
            }
            setSubjectsList(props.location.state.subjects);
        }
        setCategorysubmittedcount([]);
        setsliderval({});
        setTextBasedValues({});
        setRadioQuestions([]);
        props.getSemesterfeedbackQuestionsdata(props.location.state.sem_feedback_id);
        props.onSubjectChange();

    }, [props.location.state.sem_feedback_id]);
    useEffect(() => {
        setCategorysubmittedcount(props.categeory_state)

    }, [props.categeory_state]);

    useEffect(() => {
        if (subSwitched === true) {
            console.log("in use effect of subSwitched - when it is true");
            setSubSwitched(false);
        }
    }, [subSwitched]);

    useEffect(() => {
        if (props.sem_feedback_questions && Object.keys(props.sem_feedback_questions).length > 0) {
            const radioBasedQuestions = props.sem_feedback_questions.feedback_questions.filter(ques => ques.type === 2);
            if (radioBasedQuestions.length > 0) {
                const updatedRadioBasedQuestions = radioBasedQuestions.map(question => ({
                    ...question,
                    value: '' // Initialize value with an empty string
                }));
                setRadioQuestions(updatedRadioBasedQuestions);
            }
        }
    }, [props.sem_feedback_questions]);

    useEffect(() => {
        if (props.sem_feedback_questions) {
            console.log("hello")
            console.log(props.feedback_answers)
            if (props.feedback_answers.length > 0 && props.feedback_answers.length === props.sem_feedback_questions.feedback_questions.length) {
                // console.log(`ans - ${props.feedback_answers.length}, ques - ${props.sem_feedback_questions.feedback_questions.length}`)
                // console.log("feedback answers --> ");
                // console.log(props.feedback_answers);
                // console.log("questions");
                // console.log(props.sem_feedback_questions.feedback_questions);
                const textBasedPresent = props.sem_feedback_questions.feedback_questions.find(ques => ques.type === 1);
                console.log(textBasedPresent)
                if (textBasedPresent) {
                    const isTextBasedAnswered = props.feedback_answers.filter(ques => ques.type === 1).every(ques => (ques.question_answer !== '' && ques.question_answer !== null));
                    console.log(isTextBasedAnswered)
                    if (isTextBasedAnswered) {
                        setEnableSubmitButton(false);
                    }
                    else {
                        setEnableSubmitButton(true);
                    }
                }
                else {
                    setEnableSubmitButton(false);
                }
            }
            else {
                setEnableSubmitButton(true);
            }
        }
    }, [props.feedback_answers]);

    useEffect(() => {
        if (props.submitted_response === true) {
            const current_sub = currentSubject;
            current_sub.submitted = 1;
            setCurrentSubject(current_sub);
            const updatedList = subjectsList.map(subject => {
                if (subject.id === sem_feedback_id) {
                    return { ...subject, submitted: 1 }; // Updating submitted key
                }
                return subject;
            });
            setSubjectsList(updatedList);
            console.log("after submitting");
            console.log(updatedList);
            const allAreSubmitted = updatedList.every(sub => sub.submitted === 1);
            console.log("all are submitted");
            console.log(allAreSubmitted);
            setAllSubmitted(allAreSubmitted);
            // goToNextFeedback();
            // setSubChanged(subChanged + 1);


            if (!allAreSubmitted) {
                console.log("in 1234")
                // goToNextFeedback();
                const next_subject = updatedList.find(sub => sub.submitted === 0);
                props.history.push({
                    pathname: "/semesterfeedback",
                    state: {
                        sem_feedback_id: next_subject.id,
                        subjects: updatedList,
                    },
                });
            }
        }
    }, [props.submitted_response]);
    // useEffect(() => {
    //     const handleVisibilityChange = () => {
    //       if (document.hidden) {
    //         setIsVisible(false);
    //       } else {
    //         setIsVisible(true);
    //         html2canvas(document.body).then((canvas) => {
    //            let dataURL='';
    //            dataURL = canvas.toDataURL();
    //           const img = document.createElement("img");
    //           img.src = dataURL;
    //           document.body.appendChild(img);
    //         });
    //       }
    //     };
    //     document.addEventListener("visibilitychange", handleVisibilityChange);
    //     return () =>
    //       document.removeEventListener("visibilitychange", handleVisibilityChange);
    //   }, []);
      const goBack = () => {
        window.location.replace('/home');
      };
    
    const goToNextFeedback = () => {
        console.log("sub list in goToNextFeedback - ");
        console.log(subjectsList);
        setSubChanged(subChanged + 1);
        const next_subject = subjectsList.find(sub => sub.submitted === 0);
        console.log("next-subject");
        console.log(next_subject);
        props.history.push({
            pathname: "/semesterfeedback",
            state: {
                sem_feedback_id: next_subject.id,
                subjects: subjectsList,
            },
        });
    }
    
    const studentrating = (rating,index, questionid,category_name,grading) => {
        if (Category_submitted[questionid]=="N") {
            let categeory_sub_count=Category_submitted[category_name]
            setCategorysubmittedcount({...Category_submitted,[category_name]:categeory_sub_count+1,[questionid]:"Y"})
            console.log(Category_submitted)
        }
       
        // let selectedoptionid = props.sem_feedback_questions.feedback_questions[index].options.filter(option => option.name && option.name == rating);
        let selected_option_id = rating;
        let selected_question_id = questionid;
        let prevValues = sliderval;
        // if(grading=="Y"){
            setsliderval(prev => ({
                ...prev,
                [index]: rating,
              }));
            prevValues[index] = rating;
           
        // }else{
        //     checkAllQuestionsAnswered(null,grading);
        // }
     
        props.onFeedbackOptionSelected(0, selected_question_id, selected_option_id)
        // checkAllQuestionsAnswered();
       
    }

    const checkAllQuestionsAnswered = () => {
            // let keys = Object.keys(prevValues);
            // let flag = true;
            // console.log(keys)
            // console.log(props.sem_feedback_questions.feedback_questions)
            // if(keys.length > 0 && keys.length === props.sem_feedback_questions.feedback_questions.length){
            //     for(let i=0;i<keys.length;i++){
            //         if(prevValues[keys[i]] <= 0){
            //             flag = false;
            //             break;
            //         }
            //     }
            // }else{
            //     flag = false;
            // }
            // if(flag){
            //     setEnableSubmitButton(false);
            // }else{
            //         setEnableSubmitButton(true);
            // }
            console.log("feedback_answers --> ");
            console.log(props.feedback_answers);
            if (props.feedback_answers.length > 0 && props.feedback_answers.length === props.sem_feedback_questions.feedback_questions.length) {
                setEnableSubmitButton(false);
            }
            else {
                setEnableSubmitButton(true);
            }
    }

    const handleSubjectChange = (id) => {
        if (id !== sem_feedback_id) {
            setSubSwitched(true);
            console.log("in handleSubjectChange");
            props.history.push({
                pathname: "/semesterfeedback",
                state: {
                    sem_feedback_id: id,
                    subjects: subjectsList,
                },
            });
        }
    }

    const handleTextBasedQuestions = (event, id) => {
        const answer = event.target.value;
        const tbv = textBasedValues;
        tbv[id] = answer;
        setTextBasedValues(tbv);
        props.onFeedbackOptionSelected(1, id, answer);
    }

    const handleRadioQuestions = (event, id) => {
        const answer = parseInt(event.target.value, 10);
        const updatedRadioQuestions = radioQuestions.map(question => {
            if (question.id === id) {
                return { ...question, value: answer };
            }
            return question;
        });
        console.log("Radio Questions --> ");
        console.log(updatedRadioQuestions);
        setRadioQuestions(updatedRadioQuestions);
        props.onFeedbackOptionSelected(2, id, answer);
    }
   
    if (props.loading)
        return (<div>Loading</div>)
//    if((props.submitted_response && !props.error_submitted_response && !props.loading_submission)|| 
//      (!props.submitted_response && props.error_submitted_response && !props.loading_submission)){
//     return (
//         <div className="pt-5">
//             <div className="col-sm-12 col-md-12 col-lg-12 d-flex align-items-center" style={{flexDirection: 'column'}}>
//                 <h5>
//                     <strong>
//                         {
//                             props.error_submitted_response 
//                                 ? "There was error submitting your feedback." 
//                                 : "Your feedback has been submitted successfully."
//                         }
//                      </strong>
//                 </h5>
//                 <img
//                 className="img-responsive"
//                 width={250}
//                 height="250"
//                 src={props.error_submitted_response ? "images/gif/test_submit_fail.gif" : "images/gif/test_submitted.gif"}
//                 alt="full screen"
//                 style={{margin: "25px"}}
//                 />
//                 {subjectsList.length < 2 ?
//                 <button className="btn btn-primary gotodashboardBtn" style={{ cursor: "pointer" }}
//                     onClick={()=>goBack()}>
//                     <b style={{marginLeft: '25px'}}>Go to Dashboard</b>&nbsp; <img className="arrowimg" src="/images/Right_arrow_blue.svg" alt="right arrow" />
//                 </button>
//                 : 
//                 <button className="btn btn-primary gotodashboardBtn" style={{ cursor: "pointer" }}
//                     onClick={()=>goToNextFeedback()}>
//                     <b style={{marginLeft: '25px'}}>Go to next subject</b>&nbsp; <img className="arrowimg" src="/images/Right_arrow_blue.svg" alt="right arrow" />
//                 </button>
//                 }
//         </div>
//       </div>

//     )
//    }
    console.log(props.sem_feedback_questions)
    if (!props.loading) {
        let category_name = '';
        return (
            <>
                <div style={{overflowX: 'hidden'}}>
                <div className="row">
                <div className="col-2 p-0">
                    <SideSubNav subjects={subjectsList} subChanged={subChanged} name={props.sem_feedback_questions.name} handleSubjectChange={handleSubjectChange}/>
                </div>
                <div className="col-10 p-0">
                <div className="row col-lg-12 col-md-12 p-0 m-0">
                   
                    <header className="nav1">
                    <div
                        className="studentDiv"
                        style={{ padding: 13, fontSize: 16, fontWeight: 600, textAlign: "left" }}
                    >
                        {currentSubject.subject_name && currentSubject.taught_by && currentSubject.taught_by !== " " && currentSubject.taught_by !== "" ? 
                            <span>{currentSubject.subject_name} (by {currentSubject.taught_by})</span>
                        :
                        currentSubject.subject_name ?
                            <span>{`${currentSubject.subject_name}`}</span>
                        : 
                            <span>{props.sem_feedback_questions.name}</span>
                        }
                    </div>
                        <nav className="nav__container__actions">
                            <ul className="flex categeorylist pt-0">
                                <li className="tab0"><strong>Categories: </strong></li>
                                {props.sem_feedback_questions.feedback_categories.map((item, index) => {
                                    return (
                                        <li className="tab1">
                                            <Link activeClass="active" duration={100} offset={-100} smooth spy to={item.name.replace(" ", "_")} style={{ textDecoration: "none", fontSize: "16px" }}>
                                                {item.name}
                                                {Category_submitted && Category_submitted[item.name.replace(" ", "_")]==props.categeory_count[item.name.replace(" ", "_")] ?
                                                  <GiConfirmed style={{color:"green",fontSize:"20px",marginLeft:"5px"}} />: 
                                                  ""}
	                                            {/* //   <ImHourGlass style={{color:"grey",fontSize:"15px",marginLeft:"5px"}} />} */}
                                                
                                                
                                                
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                    </header>
                    <div className="row">
                    {subSwitched ?
                    <div className="col-12"></div>
                    :
                    currentSubject.submitted === 0 ? 
                    <div className="col-12">
                    {
                        props.sem_feedback_questions.feedback_questions.map((item, index) => {
                            let idnaming = ''
                            if (category_name != item.category_name) {
                                idnaming = item.category_name.replace(" ", "_");
                                category_name = item.category_name;
                            } else {
                                idnaming = ''
                            }
                            return (
                                <section id={idnaming}>
                                    <div className="row eachquestion"  >
                                        <div className="col-lg-7" style={{ marginTop: "19px", textAlign: 'left', fontSize: '13px'  }}>{index+1}) &nbsp;{item.name}</div>
                                        <div className="col-lg-5" style={{ marginTop: "19px",textAlign:"left"}}>
                                            {item.type === 1 ?
                                                <>
                                                    <TextField 
                                                        multiline 
                                                        rows={3}
                                                        size="medium"
                                                        value={textBasedValues[item.id]}
                                                        placeholder="Give your feedback here"
                                                        onChange={(event) => handleTextBasedQuestions(event, item.id)}
                                                        sx={{marginTop: '-19px', width: '70%'}}
                                                    />
                                                </>
                                            :item.type === 2 ? 
                                                <>
                                                    <RadioGroup
                                                        name={`question-${item.id}`}
                                                        value={radioQuestions.find(question => question.id === item.id)?.value || ''}
                                                        onChange={(event) => handleRadioQuestions(event, item.id)}
                                                        row
                                                    >
                                                        {item.options.map((option) => (
                                                            <FormControlLabel
                                                                key={option.id}
                                                                value={option.id}
                                                                control={<Radio />}
                                                                label={option.name}
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                </>
                                            :
                                            props.sem_feedback_questions.grade_points<=5 ?
                                               <Rating className="col-lg-5" name="half-rating"
                                               size="large"
                                               sx={{
                                                   fontSize: '2.7rem',
                                               }}
                                               onChange={(event, newHover) => {
                                                   studentrating(newHover,index,item.id,item.category_name.replace(" ", "_"),'N')
                                               }}
                                               max={props.sem_feedback_questions.grade_points}
                                           />
                                           : <Slider className="col-lg-5"
                                           value={sliderval[index]?sliderval[index]:0}
                                           min={0}
                                           step={1}
                                           defaultValue={5}
                                           valueLabelDisplay="auto"
                                           onChange={(event, newHover) => {
                                            studentrating(newHover,index,item.id,item.category_name.replace(" ", "_"),'Y')
                                        }}
                                        max={props.sem_feedback_questions.grade_points}
                                         />
                                            }
                                        <div>
                                            {item.options?.length>0 && item.options.slice(0).reverse().map((item1,index)=>{
                                                return(
                                                <>
                                                {item.type==0 ?
                                                    <span style={{paddingLeft:'15px',fontSize:'10px'}}>{item1.name}</span> 
                                                    :null 
                                                }
                                                </>
                                                )
                                            })}
                                        </div>
                                        </div>

                                    </div>
                                    <hr style={{ margin: "0px", border: "1px solid #80808038" }}></hr>
                                </section>
                            )
                        })
                    }
                    {/* </div> */}
                    <LoadingButton 
                    variant="contained"
                    sx={{
                        height:'35px',
                        width: "10%", 
                        margin: "auto",
                        marginTop: '25px',
                        marginBottom: '25px',
                        color:'#ffff',
                        // backgroundColor:'#dc3545',
                        // '&:hover': {
                        //     backgroundColor: '#dc3545', 
                        // },
                        backgroundColor: (theme) => theme.palette.primary.main,
                        '&.hover': {
                            backgroundColor: (theme) => theme.palette.primary.main,
                        }
                    }} onClick={() =>
                        props.submitresponse(sem_feedback_id)
                    } 
                    loading={props.loading_submission}
                    disabled={enableSubmitButton}
                    >Submit</LoadingButton>
                    </div>
                    :
                    <div className="col-12">
                        <div className="pt-5">
                            <div className="col-sm-12 col-md-12 col-lg-12 d-flex align-items-center" style={{flexDirection: 'column'}}>
                                <h5>
                                    <strong>
                                        {
                                            props.error_submitted_response 
                                                ? "There was error submitting your feedback." 
                                                : "Your feedback has been submitted successfully."
                                        }
                                    </strong>
                                </h5>
                                <img
                                className="img-responsive"
                                width={250}
                                height="250"
                                src={props.error_submitted_response ? "images/gif/test_submit_fail.gif" : "images/gif/test_submitted.gif"}
                                alt="full screen"
                                style={{margin: "25px"}}
                                />
                                {allSubmitted ?
                                <button className="btn btn-primary gotodashboardBtn" style={{ cursor: "pointer" }}
                                    onClick={()=>goBack()}>
                                    <b style={{marginLeft: '25px'}}>Go to Dashboard</b>&nbsp; <img className="arrowimg" src="/images/Right_arrow_blue.svg" alt="right arrow" />
                                </button>
                                : 
                                <button className="btn btn-primary gotodashboardBtn" style={{ cursor: "pointer" }}
                                    onClick={()=>goToNextFeedback()}>
                                    <b style={{marginLeft: '25px'}}>Go to next subject</b>&nbsp; <img className="arrowimg" src="/images/Right_arrow_blue.svg" alt="right arrow" />
                                </button>
                                }
                            </div>
                        </div>
                    </div>
                    }
                    </div>
                </div>
                </div>
                </div>
                </div>
            </>
        );

    }
}
function mapStateToProps(state) {
    // console.log(state.semfeedback.categeory_state)
    return {
        error_loading_sem_feedbacks: state.semfeedback.error_loading_sem_feedbacks,
        loading: state.semfeedback.loading,
        sem_feedback_questions: state.semfeedback.sem_feedback_questions,
        categeory_count:state.semfeedback.categeory_count,
        categeory_state:state.semfeedback.categeory_state,
        feedback_answers: state.semfeedback.feedback_answers,
        submitted_response : state.semfeedback.submitted_response,
        error_submitted_response:state.semfeedback.error_submitted_response,
        loading_submission:state.semfeedback.loading_submission,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getSemesterfeedbackQuestionsdata: (feedback_id) => dispatch(getSemesterfeedbackQuestions(feedback_id)),
        submitresponse: (feedback_id) => dispatch(submitFeedbackAC(feedback_id)),
        onFeedbackOptionSelected: (question_type, question_id, option_id) =>
            dispatch({
                type: "FEEDBACK_OPTION_SELECTED",
                question_type: question_type,
                question_id: question_id,
                option_id: option_id,
            }),
        onSubjectChange: () => dispatch({type: "ON_SUBJECT_CHANGE"})
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Semesterfeedback)
);

