import React, { Component } from "react";
import styled from "styled-components";
import  "./Layout.module.css";
import Flex from "styled-flex-component";
import { withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { AiOutlineHome } from "react-icons/ai";
import { MdOutlineAssessment } from "react-icons/md";
import { FcSurvey } from "react-icons/fc";
import { SiGoogleclassroom } from "react-icons/si";
import { BiCopy } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { RiLogoutCircleLine } from "react-icons/ri";
import { TbDeviceGamepad2 } from "react-icons/tb";
import Modal from "react-bootstrap/Modal";
import Tooltip from '@mui/material/Tooltip';
import {FaAppStore} from "react-icons/fa"
import { HiOutlineCode } from "react-icons/hi";
import { CareerIcon } from "../../assets/careerPath/CareerIcon";
import {
  getIosAppLinkAC,
} from "../../store/actions/dashboardActions";
import { NewFeatureIndicator } from "../common/NewFeatureIndicator";
var sha256 = require('js-sha256').sha256;
const LogoDiv = styled.div`
  width: 200px;
  height: 120px;
  margin-left: 17px;
  // margin-top: 10px;
  text-align: left;
  border-radius: 8px;
  @media only screen and (max-width: 1025px) {
    margin-left: 0px;
  }
`;

const FixedHeaderDiv = styled.div`
  height: 596px;
  @media only screen and (min-width: 1900px) {
        width: 8% !important;
}
`;

const IconsDivRight = styled.span`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 20px;
  color: #6d6d78;
  transition: all 0.2s;
  cursor: pointer;
  font-size:12px;
  font-weight: 600;
  margin-top: 5px;
  @media only screen and (max-width: 1025px) {
    font-size:10px;
    padding: 6px;
  }
  :hover {
    background: #E7EEFE;
    color: #172239;
  }
`;

const LogOutBtn = styled.span`
  display: inline-flex !imporatnt;
  text-align: center !imporatnt;
  align-items: center !imporatnt;
  justify-content: center !imporatnt;
  height: 64px !imporatnt;
  padding: 0 31px !imporatnt;
  background: #ffdfd6 !imporatnt;
  border: 1px solid rgb(234, 234, 234) !imporatnt;
  border-radius: 20px;
  font-size: 20px !imporatnt;
  font-weight: 600;
  color: #172239;
  transition: background 0.2s, border-color 0.2s, color 0.2s;
  border-color: #eaeaea;
  background: transparent;
  margin-top: auto;
  flex-shrink: 0;
  width: 100%;
  margin: 10px 0px 0px 9px;
  :hover {
    background: #ffdfd6 !important;
    color: #172239 !important;
  }
`;
const SurpriseText = styled.div`
 margin-top: 119px;
  color: blue;
  cursor: pointer;
  display: inline-flex !imporatnt;
  text-align: center !imporatnt;
  align-items: center !imporatnt;
  justify-content: center !imporatnt;
  padding: 0 31px !imporatnt;
  background: #ffdfd6 !imporatnt;
  border: 1px solid rgb(234, 234, 234) !imporatnt;
  border-radius: 20px;
  font-size: 16px !imporatnt;
  font-weight: 500;
  color: #172239;
  
  :hover {
    background: #ffdfd6 !important;
    color: #172239 !important;
  }

  @media (max-width: 1100px) {
    margin-top: 0px;
  }

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;
class Layout1 extends Component {
  state={
  showgames:false,
  }
  onLogout = () => {
    if (!this.props.logoutDisable) {
      localStorage.clear();
      const allCookies = document.cookie.split(";");
      for (let i = 0; i < allCookies.length; i++)
        document.cookie =
          allCookies[i] + "=;expires=" + new Date(0).toUTCString();
      this.props.history.go("/");
    }
  };

  goToHome = () => {
    if (!this.props.logoutDisable) {
      this.props.history.push("/home");
    }
  };
onGameClick = (type) => {
    const BASE_API_URL = process?.env?.REACT_APP_API_URL_TAKE_TEST;
    const token = localStorage.getItem("token");
    if (BASE_API_URL && token) {
      if (type === 1)
        document.location.href = `https://dev.depe7r5p5ejvl.amplifyapp.com/?id=1&url=${BASE_API_URL}&token=${token}`;
      else if (type === 2)
        document.location.href = `https://origin.d3i0yf453va4p3.amplifyapp.com/?id=2&url=${BASE_API_URL}&token=${token}`;
    }
  };
  handlegamesClose = () => this.setState({showgames:false});
   handlegamesShow = () => this.setState({showgames:true});
  goToClassroom = () => {
    console.log("in classroom");
    if (!this.props.logoutDisable) {
      this.props.history.push("/classroom");
    }
  };
  goToProfile = () => {
    console.log("in classroom");
    if (!this.props.logoutDisable) {
      this.props.history.push("/profile");
    }
  };

  goToAutoLib = () => {
    console.log("AutoLib");
    if(!this.logoutDisable){
      this.props.history.push("/autoLib");
    }
  }

  startLiveAssessment = () => {
    if (!this.props.logoutDisable) this.props.history.push("/liveAssessment");
  };

  startLiveSurvey = () => {
    if (!this.props.logoutDisable) this.props.history.push("/liveSurvey");
  };

  codingTrack= () => {
    if (!this.props.logoutDisable) this.props.history.push("/codingscreen");
  };
  
  careerPath= () => {
    if (!this.props.logoutDisable) this.props.history.push("/career-get-started");
    // if (!this.props.logoutDisable) this.props.history.push("/career-path-registration");
  };

  codeTantra= () => {
   const user = JSON.parse(localStorage.getItem("user"));
   console.log(user)
   console.log(process.env)
   let codeTantraURL
   if (user.college_id==27){
    codeTantraURL="https://rmkec.codetantra.com/r/l"
   }else if(user.college_id==29){
    codeTantraURL="https://rmd.codetantra.com/r/l"
   }else{
    codeTantraURL="https://rmkcet.codetantra.com/r/l"
   }
   let codeTantraURL1
   if (user.college_id==27){
    codeTantraURL1="rmkec.codetantra.com"
   }else if(user.college_id==29){
    codeTantraURL1="rmd.codetantra.com"
   }else{
    codeTantraURL1="rmkcet.codetantra.com"
   }
   const currentTimestamp=Date.now()
   const rollNumber=user.roll_number
   let calculateHas=`l&dn=${codeTantraURL1}&id=${rollNumber}&t=${currentTimestamp}&rd=&bCf07yPatB6IzZeED5byOho8rCzwT5bsw352Be5cHny6HAfPoHMjmdUej4RMT3`
   
   let hasedValue=sha256(calculateHas)
   codeTantraURL +=`?id=${rollNumber}&t=${currentTimestamp}&rd=&cs=${hasedValue}`
   window.location.replace(codeTantraURL);

  };
  goToPersonalityAssessment = () => {
    if (!this.props.logoutDisable) this.props.history.push("/personalityAssessment");
  }

  iosLinkModalOpen = async () => {
    await this.props.getIosAppLink();
    this.setState({ iosModelShow: true });
    // if (!this.props.iosLinkError) document.body.style.overflow = "hidden";
  };

 iosLinkModalClose = () => {
    document.body.style.overflow = "auto";
    this.setState({ iosModelShow: false });
  };
  handleioslinkCopy= () => {
    navigator.clipboard.writeText(this.props.iosLink)
    alert(`IOS link copied`);
  };
 
  render() {
    console.log(this.props.iosLink);
    let active = this.props.active_page;
    // console.log("-----------------"+active)
    const user = JSON.parse(localStorage.getItem("user"));
    return (
      <FixedHeaderDiv
        className={`col-lg-2 col-md-3`}
        style={{ opacity: "1", position: "fixed",paddingRight:"20px",width:'226px' }}
      >
        <Flex style={{ overflow: "hidden" }} column>
          <Flex style={{ overflow: "hidden", marginTop: "16px" }} row>
          <LogoDiv onClick={this.goToHome}>
              <img
                src="/rmklogo1.png"
                alt="logo"
                style={{width:"85%",height:"39px"}}
              ></img>
              <div
                style={{
                  position: "absolute",
                  top: "44px",
                  left: "53px",
                  fontSize: "10px",
                  color: "grey",
                  fontStyle: "italic",
                  fontWeight:"400"
                }}
              >
                Powered by Edwisely
              </div>
            </LogoDiv>
          </Flex>
          {!this.props.dontShowIcons ? (
            <div
             className="menudiv"
              column
              style={{
                padding: "4px",
                border: "1px solid #EAEAEA",
                borderRadius: "15px",
                marginLeft: "17px",
                marginTop: "-26px",
              }}
            >
              <IconsDivRight
                onClick={this.goToHome}
                style={{}}
                className={active === "dashboard" ? "activetab" : ""}
              >
                <AiOutlineHome style={{color:"#ffcb57",fontSize:"20px"}} />
                <span style={{paddingLeft:"8px"}}>Home</span>
              </IconsDivRight>
              <IconsDivRight
                onClick={this.startLiveAssessment}
                style={{
                  cursor: "pointer",
                }}
              >
                {/* <img
                  src="/images/live_test.png"
                  width="25"
                  alt="live assessment"
                ></img> */}
                {/* <p
                  style={{
                    fontSize: "10px",
                    lineHeight: "0.7rem",
                    textAlign: "center",
                    color: "black",
                  }}
                > */}
                <MdOutlineAssessment style={{color:"#52c3ff",fontSize:"20px"}} />
                 <span style={{paddingLeft:"8px"}}>Live Assessment</span>
                
                {/* </p> */}
              </IconsDivRight>
              <IconsDivRight
                onClick={this.startLiveSurvey}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                {/* <img
                  src="/images/survey_icon.png"
                  width="25"
                  alt="live assessment"
                ></img>
                <p
                  style={{
                    fontSize: "10px",
                    lineHeight: "0.7rem",
                    textAlign: "center",
                    color: "black",
                  }}
                > */}
                <FcSurvey style={{color:"#ffdfd6",fontSize:"20px"}} />
                 <span style={{paddingLeft:"8px"}}>Live Survey</span>
                
                {/* </p> */}
              </IconsDivRight>

              <IconsDivRight
                onClick={this.codingTrack}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
                className={active === "coding_track" ? "activetab" : ""}
              >
                <HiOutlineCode style={{color:"#172239", fontSize:"20px"}} />
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexGrow: 1}}>
                  <span style={{paddingLeft:"8px", textAlign: 'left'}}>GoCode</span>
                  <NewFeatureIndicator />
                </div>
              </IconsDivRight>
              {JSON.parse(localStorage.getItem("user")).display_modules.career_path==1 ?
              <IconsDivRight
                onClick={this.careerPath}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
                className={active === "careerPath" ? "activetab" : ""}
              >
                <CareerIcon fill={active === "careerPath" ? true : false}  />
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexGrow: 1}}>
                  <span style={{paddingLeft:"8px", textAlign: 'left'}}>Career</span>
                  <NewFeatureIndicator />
                </div>
              </IconsDivRight>:null}

              {/* <IconsDivRight style={{ marginTop: "38px" }}>
                <img src='/images/Live_class.svg'></img>
              </IconsDivRight> */}

              {/* <IconsDivRight onClick={this.goToClassroom} style={{}}> */}
                {/* <img src="/images/Live_class.svg" alt="classroom"></img> */}
                {/* <SiGoogleclassroom style={{color:"#5ae2e2",fontSize:"20px"}} />
                 <span style={{paddingLeft:"8px"}}>Classroom</span>
                
              </IconsDivRight> */}
              {
                user?.college_id 
                  ? [27,29,32].includes(user.college_id)
                    ? (
                        <IconsDivRight
                          onClick={this.goToAutoLib}
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                          // className={active === "coding_track" ? "activetab" : ""}
                        >
                          <img src="/images/autolib_logo.png" style={{height: '23px', width: '23px', borderRadius: '11.5px'}}/>
                            <span style={{paddingLeft:"8px"}}>Library</span>
                        </IconsDivRight>
                    ) 
                    : ""
                  : ""
              }

              <IconsDivRight
                onClick={this.goToPersonalityAssessment}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
                className={active === "personality_test" ? "activetab" : ""}
              >
              
                <img src={'/images/personality_assessment_logo.svg'} alt="Personality" style={{width: "18px", height: "18px"}} />
                <span style={{paddingLeft:"10px", textAlign: 'left'}}>Personality Test</span>
                
              </IconsDivRight>
              {
                user?.college_id 
                  ? [27,29,32].includes(user.college_id)
                    ? (
              <IconsDivRight
                onClick={this.codeTantra}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
                // className={active === "coding_track" ? "activetab" : ""}
              >
                {/* <HiOutlineCode style={{color:"#172239", fontSize:"20px"}} /> */}
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexGrow: 1}}>
                  <span style={{paddingLeft:"8px", textAlign: 'left'}}>CodeTantra</span>
                </div>
              </IconsDivRight>
            ) 
            : ""
          : ""
      }

              <IconsDivRight onClick={this.goToProfile}>
                {/* <img
                  width="32"
                  src="/images/profile/profile_icon.png"
                  alt="profile"
                ></img> */}
                <CgProfile style={{fontSize:"20px"}} />
                <span style={{paddingLeft:"8px"}}>Profile</span>
                
              </IconsDivRight>
               <IconsDivRight onClick={this.handlegamesShow}>
              
                <TbDeviceGamepad2 style={{color:"#172239",fontSize:"20px"}} />
                <span style={{paddingLeft:"4px"}}>Cognitive gaming</span>
                
              </IconsDivRight>

              {/* <IconsDivRight style={{marginTop: '38px', marginRight: '0px'}}>
                            <img src='/images/Menu.svg'></img>
                        </IconsDivRight> */}
            </div>
          ) : null}
        </Flex>
        <style jsx="true" scoped>
          {`
            .activetab {
              background: #E7EEFE;
              color: #0B58F5;
            }
            @media only screen and (max-width: 1025px) {
              .menudiv{
                      margin-left: 0px !important;
                    }
              }
              .iosappdiv{
                margin-top: 1rem;
              color: blue;
              cursor: pointer;
              display: inline-flex !imporatnt;
              text-align: center !imporatnt;
              align-items: center !imporatnt;
              justify-content: center !imporatnt;
              padding: 0 31px !imporatnt;
              background: #ffdfd6 !imporatnt;
              border: 1px solid rgb(234, 234, 234) !imporatnt;
              border-radius: 20px;
              font-size: 16px !imporatnt;
              font-weight: 500;
              color: #172239;
                          }
              //             .iosappdiv:hover {
              //   background: #ffdfd6 !important;
              //   color: #172239 !important;
              // }

              @media (max-width: 1100px) {
                .iosappdiv
                margin-top: 0px;
              }
              }

              @media (max-width: 768px) {
                .iosappdiv{
                margin-top: 0px;
                }
              }
              
          `}
        </style>
        <Tooltip title="Request for IOS app">
        <div className="iosappdiv" onClick={this.iosLinkModalOpen}>
          <FaAppStore /> IOS App!
        </div>
        </Tooltip>
        {this.props.iosLinkError ? (
          <p style={{ color: "red",fontSize:"12px"}}>
            {this.props.iosLinkError}
          </p>
        ) : null}
        {/* <SurpriseText onClick={this.handlegamesShow}>Play Games</SurpriseText> */}
        <LogOutBtn
          onClick={this.onLogout}
          // style={{
          //   color: "#ffffff",
          //   fontSize: "18px",
          //   fontWeight: "bold",
          // }}
          className="btn btn-default"
        >
          <RiLogoutCircleLine />
          Logout
        </LogOutBtn>
          {/* <div style={{fontSize: '9px', marginTop: '15px', padding: '0px', color: '#1cd71c', lineHeight: '1.4'}}>
            <div style={{color: '#1cd71c'}}>Session Time:- 35 Mins Android </div>
            <div style={{color: 'grey'}}>Last Login:-01-01-2022 1:23 PM</div>
            </div> */}
        <Modal show={this.state.showgames} onHide={this.handlegamesClose}>
        <Modal.Header closeButton>
          <Modal.Title>Train Your Brain With</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <div
                style={{ display: "flex", flexDirection: "row", width: "100%",justifyContent:"space-around"}}
               >
                <div className="gamecard col-lg-4 col-sm-6"  onClick={() => this.onGameClick(1)}>
                  <div className="recommendedDeck-games">
                    <img
                      src="/images/img_game_sudoku.png"
                      height="80px"
                      width="80px"
                      alt="sudoku"
                    ></img>
                     <div style={{fontWeight:"600"}}>
                        SUDOKU
                    
                      </div>
                  </div>
                  {/* <div className="recommendedDeck" style={{ padding: "10px" }}>
                    <div
                      style={{
                        margin: "20px 0",
                        textAlign: "center",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <div>
                        SUDOKU
                        <button
                          className="gameButton"
                          onClick={() => this.onGameClick(1)}
                        >
                          Play Game
                        </button>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="gamecard col-lg-4 col-sm-6"  onClick={() => this.onGameClick(2)}>
                  <div className="recommendedDeck-games">
                    <img
                      src="/images/img_game_memory.png"
                      height="80px"
                      alt="memory"
                      width="80px"
                    ></img>
                     <div style={{fontWeight:"600"}}>
                        Memory Game
                       
                      </div>
                  </div>
                  {/* <div className="recommendedDeck" style={{ padding: "5px" }}>
                    <div
                      style={{
                        textAlign: "center",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                     
                    </div>
                  </div> */}
                </div>
              </div>

        </Modal.Body>
       
      </Modal>
      <Modal show={this.props.iosLink &&
            !this.props.iosLinkError &&
            this.state.iosModelShow} onHide={this.iosLinkModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>IOS App</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{display:"flex"}}>
          <div>
        {this.props.iosLink ? this.props.iosLink : "Fetching link..."} 
        </div>
        <div onClick={this.handleioslinkCopy} style={{fontSize:"25px",cursor:"pointer"}}>
          <BiCopy />
        </div>
        </div>
        </Modal.Body>
       
       </Modal>
 <style jsx scoped="true">
          {`
            .gamecard {
    border-radius: 15px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.163981);
    margin-right: 10px;
    cursor:pointer;
    height:136px;
   
}
.recommendedDeck-games{
    padding: 1px;
    background: white;
    height: 80px !important;
    box-sizing: border-box;
    margin: 10px 0;
    padding-left: 20px;
}
          `}
        </style>
      </FixedHeaderDiv>
      
    );
  } 
}
const mapStateToProps = (state) => {
  return {
    iosLink: state.dash.iosLink,
    iosLinkError: state.dash.iosLinkError,
    user_details:state.login.user_details,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    getIosAppLink: () => dispatch(getIosAppLinkAC()),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Layout1)
);

