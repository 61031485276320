import React from "react";

const SearchIcon = () => (

  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" style={{ color: "#2196f3", marginLeft: "1px", marginRight: "11px" }}>
<path d="M10.2179 3.81865H14.5964M10.2179 6.10972H12.4071M15.3261 8.78263C15.3261 12.792 12.2247 16.0377 8.39353 16.0377C4.56236 16.0377 1.46094 12.792 1.46094 8.78263C1.46094 4.77326 4.56236 1.52759 8.39353 1.52759M16.0559 16.8014L14.5964 15.274" stroke="#0B58F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="20"
  //   height="20"
  //   viewBox="0 0 18 19"
  //   fill="none"
  //   style={{ color: "#2196f3", marginLeft: "5px", marginRight: "10px" }}
  // >
  //   <path
  //     d="M10.2179 3.81865H14.5964M10.2179 6.10972H12.4071M15.3261 8.78263C15.3261 12.792 12.2247 16.0377 8.39353 16.0377C4.56236 16.0377 1.46094 12.792 1.46094 8.78263C1.46094 4.77326 4.56236 1.52759 8.39353 1.52759M16.0559 16.8014L14.5964 15.274"
  //     stroke="#0B58F5"
  //     strokeWidth="1.09462"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  // </svg>
);

export default SearchIcon;
