import axios from "../../interceptor";
import { getLoggedInUser } from "../../helpers/Auth";

const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
let http = axios;
const ENVIRONEMNT_VAR = process.env.REACT_APP_ENVIRONEMNT;
// const user = JSON.parse(localStorage.getItem("user"));
export function setGlobalToken() {
    try {
      let token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      console.log(error);
    }
}

export const GET_GRAND_TOPICS = "GET_GRAND_TOPICS";
export const GET_SNIPPET_READ_DATA = "GET_SNIPPET_READ_DATA";
export const FETCHING_DATA = "FETCHING_DATA";

export const getGrandTopicsA = (res, err) => {
    return {
      type: GET_GRAND_TOPICS,
      response: res,
      error: err,
    };
};

export const getSnippetReadDataA = (res, err) => {
    return {
      type: GET_SNIPPET_READ_DATA,
      response: res
    };
};

export const fetchingData = () => {
    return {
      type: FETCHING_DATA
    };
};

export const getGrandTopicsAC = (test_id) => {
    console.log("in action creator");
   
    setGlobalToken();
    return async (dispatch, getState) => {
      // const user_id = getState().login.user_id;
      const user = JSON.parse(localStorage.getItem("user"));
      dispatch(fetchingData());
      axios
        // .get(`https://ymqq4dkeif.execute-api.ap-south-1.amazonaws.com/1/?test_id=3982&student_id=34447`)
        .get(`https://ymqq4dkeif.execute-api.ap-south-1.amazonaws.com/${ENVIRONEMNT_VAR}/?test_id=${test_id}&student_id=${user.user_id}`)
        .then((res) => {
          console.log("res");
          console.log(res);
          if (res.status === 200) {
            dispatch(getGrandTopicsA(res.data, false));
            if(res.data.remedial_path_test_type==0){
              dispatch(getSpinwheelcategories(test_id))
            }
            
          } else {
            dispatch(getGrandTopicsA(null, true));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(getGrandTopicsA(null, true));
        });
    };
};

// export const getSnippetReadDataAC = (test_id, code) => {
//   setGlobalToken();
//   return async (dispatch, getState) => {
//     dispatch(fetchingData());
//     axios
//     .get(`https://dujcbokuh0.execute-api.ap-south-1.amazonaws.com/mysql-snippet-staging?action=get&student_id=34465&questionnaireId=3982&topic_code=${code}`)
//     .then((res) => {
//       console.log("res2");
//       console.log(res);
//       if (res.status === 200) {
//         dispatch(getSnippetReadDataA(res.data, false));
//       }
//     })
//     .catch((err) => {
//       console.log(err);
//       dispatch(getSnippetReadDataA(null, true));
//     });
//   }
// }


export const postSnippetReadDataAC = (test_id,topic_code,current_page,max_read,total_pages,reading_status) => {
  console.log('in action creator')
 
  setGlobalToken();
  return (dispatch, getState) =>{
    // const user_id = getState().login.user_id;
    const user = JSON.parse(localStorage.getItem("user"));
      http.get(`https://7yp7e4z90c.execute-api.ap-south-1.amazonaws.com/${ENVIRONEMNT_VAR}/?action=post&student_id=${user.user_id}&questionnaireId=${test_id}&topic_code=${topic_code}&current_page=${current_page}&max_read=${max_read}&total_pages=${total_pages}&reading_status=0`)
      .then((res)=> {
          if (res.status ==200) {
             
          } else {
           
          }
      }).catch((err)=> {
          console.log('error in getting practice test')
          console.log(err)
          // dispatch(getSubjectiveAssessmentA(null, test_id, true));
      });
  };
};

export const GET_SPIN_CATEGERIOES = "GET_SPIN_CATEGERIOES";

export const getSpinWheeldata = (res, err) => {
    return {
      type: GET_SPIN_CATEGERIOES,
      response: res,
      error: err,
    };
};
export const getSpinwheelcategories = (test_id) => {
  console.log('in action creator')
  setGlobalToken();
  return (dispatch, getState) =>{
      http.get(`${BASE_API_URL}/questionnaire/getRemedialPatCategories?questionnaire_id=${test_id}`)
      .then((res)=> {
          if (res.status ==200) {
            dispatch(getSpinWheeldata(res.data, false));
          } else {
            dispatch(getSpinWheeldata(null, true));
          }
      }).catch((err)=> {
        dispatch(getSpinWheeldata(null, true));
          console.log('error in getting practice test')
          console.log(err)
          // dispatch(getSubjectiveAssessmentA(null, test_id, true));
      });
  };
};


export const GET_TEST_DATA = "GET_TEST_DATA";

export const getTestData = (res, err) => {
    return {
      type: GET_TEST_DATA,
      response: res,
      error: err,
    };
};
export const setSpinwheelcategeory = (test_id,category_id) => {
  console.log('in action creator')
  setGlobalToken();
  return (dispatch, getState) =>{
      http.get(`${BASE_API_URL}/questionnaire/setSpinWheelCategory?questionnaire_id=${test_id}&category_id=${category_id}`)
      .then((res)=> {
          if (res.status ==200) {
            http.get(`${BASE_API_URL}/questionnaire/getRemedialPathTest?questionnaire_id=${test_id}`)
            .then((res)=> {
                if (res.status ==200) {
                  console.log("action file consile")
                  console.log(res)
                  dispatch(getTestData(res.data, false));
                } else {
                  dispatch(getTestData(null, true));
                }
            }).catch((err)=> {
              dispatch(getTestData(null, true));
                console.log('error in getting practice test')
                console.log(err)
                // dispatch(getSubjectiveAssessmentA(null, test_id, true));
            });
            //  dispatch(getTestData(null, true));
          } else {
            dispatch(getTestData(null, true));
          }
      }).catch((err)=> {
        dispatch(getTestData(null, true));
          console.log('error in getting practice test')
          console.log(err)
          // dispatch(getSubjectiveAssessmentA(null, test_id, true));
      });
  };
};


export const SUBMIT_REMEDIAL_TEST = "SUBMIT_REMEDIAL_TEST";

export const submitRemedialTest = (res, err) => {
    return {
      type: SUBMIT_REMEDIAL_TEST,
      response: res,
      error: err,
    };
};
export const submitRemedialAC = () => {
  setGlobalToken();
  return (dispatch, getState) => {
    const formdata = new FormData();
    const feedback_id = getState().remedial.remedial_test_id;
    const remedial_test_answers = getState().remedial.remedial_test_answers;
    // const answers = {};
    // feedback_answers.forEach((ans, index)=> answers[feedback_data.feedback_questions[index].id] = ans);
    // feedback_answers.forEach((ans, index)=> answers[feedback_data.feedback_questions[index].id] = ans[feedback_data.feedback_questions[index].id]);
    formdata.append("questionnaire_remedial_path_id", feedback_id);
    formdata.append("answers", JSON.stringify(remedial_test_answers));
    http
      .post(`${BASE_API_URL}/questionnaire/submitRemedialPathTest`, formdata)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.status == 200) {
            console.log("inside data status ");
            dispatch(submitRemedialTest(res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(
                    `${BASE_API_URL}/questionnaire/submitRemedialPathTest`,
                    formdata
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(submitRemedialTest(res.data, false));
                      } else {
                        dispatch(submitRemedialTest(null, true));
                      }
                    } else {
                      dispatch(submitRemedialTest(null, true));
                    }
                  });
              })
              .catch((err) => {
                dispatch(submitRemedialTest(null, true));
              });
          } else {
            dispatch(submitRemedialTest(null, true));
          }
        } else {
          dispatch(submitRemedialTest(null, true));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(submitRemedialTest(null, true));
      });
  };
};


export const gettestquestions = (test_id) => {
  console.log('in action creator')
  setGlobalToken();
  return (dispatch, getState) =>{
      http.get(`${BASE_API_URL}/questionnaire/getRemedialPathTest?questionnaire_id=${test_id}`)
      .then((res)=> {
          if (res.status ==200) {
            dispatch(getTestData(res.data, false));
          } else {
            dispatch(getTestData(null, true));
          }
      }).catch((err)=> {
        dispatch(getTestData(null, true));
          console.log('error in getting practice test')
          console.log(err)
          // dispatch(getSubjectiveAssessmentA(null, test_id, true));
      });
  };
};



