import React from 'react';
import {Chip, Typography} from '@mui/material';
import colors2 from '../../pages/codingscreen/colors2';

const CustomChip = (props) => {
    // const modifiedLabel = <span style={{whiteSpace :'normal', fontSize: props?.fontSize, fontWeight: '500', color: colors2.common.white}}>{props.label}</span>;
    const modifiedLabel = <Typography variant="caption1" color={props?.textColor ? props.textColor : colors2.primary}>{props.label}</Typography>
    return (
        <Chip 
            size="small" 
            variant="filled" 
            label={modifiedLabel} 
            sx={{
                cursor: props?.cursor ? props?.cursor : 'default', 
                borderRadius: props?.borderRadius, 
                p: 0.3, 
                marginBottom: '5px', 
                background: props?.bgColor ? props.bgColor : colors2.secondary, 
                height: props?.height ? props.height : 'auto', 
                border: props?.chipBorder ? props?.chipBorder : 'none',
                '&:hover': {
                    background: props?.hoverBg ? props.hoverBg : props?.bgColor ? props.bgColor : colors2.secondary,
                }
            }} 
        />
    )
}

export default CustomChip;