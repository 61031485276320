import React, {useEffect, useState} from "react";
import { Grid,Stack,Box, Typography,Button } from "@mui/material";
import Layout1 from "../../components/Layout/Layout1";
import { LandingPageSvg } from "../../assets/careerPath/LandingPageSvg";
import MuiCustomChip1 from "../../components/common/MuiCustomChip1";
import {useHistory} from 'react-router-dom';

export default function LandingPage() {
    const [spacing, setSpacing] = useState(4);
    const [gap, setGap] = useState(10);
    const history = useHistory();

    useEffect(() => {
      function handleResize() {
        const { innerHeight } = window;
        const newSpacing = innerHeight < 625 ? 2.5 : innerHeight < 700 ? 5 : innerHeight < 750 ? 6 : innerHeight < 800 ? 7 : innerHeight < 850 ? 8 : 10;
        const newGap = innerHeight < 625 ? '10px' : innerHeight < 675 ? '15px' : innerHeight < 750 ? '20px' : innerHeight < 800 ? '30px' : innerHeight < 900 ? '40px' : '55px';
        setGap(newGap);
        setSpacing(newSpacing);
      }
  
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
  
      // Call handleResize once on component mount to set initial spacing
      handleResize();
  
      // Clean up event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);

      const handleGetStarted = () => {
        history.push('/overviewflow');
      }
     
  return (
  <>
   <main style={{ height: "100vh",background:'#fff',overflow:'hidden',maxWidth:'1536px' }}>
   <Grid row xs={12} container >

    <Grid item>
     <Layout1 active_page={'careerPath'} />
     </Grid>

     <Grid row xs={12} container sx={{ml:'15rem',mt:11.2,position:'relative'}}>
     <Grid item>
    <Stack direction='column' spacing={spacing}>
        <Stack direction='column' sx={{gap: gap}}>
        <MuiCustomChip1 text={'New feature'} />
        <Typography component={'span'} style={{width:'67%',fontSize:'40px',lineHeight:'normal',fontWeight:600,textAlign:'left'}}>
        Let your ambition lead the way  to a  career you love
        </Typography>
        <Button variant="outlined"  color="secondary" onClick={()=>handleGetStarted()} sx={{p:'15px',
        borderRadius:'10px',border: '1px solid #0B58F5',fontSize:'15px',color:'#0B58F5',width:'fit-content',height:'35px',
        '&:hover': { backgroundColor: '#0B58F5', color: '#fff' }}}>Let’s get started</Button>
        </Stack>
       <Box>
       <Typography variant="h6" style={{textAlign:'left',lineHeight:'normal'}}>
       Explore career which suits you
        </Typography>
        <Typography variant="body5" color='#637381' style={{textAlign:'left',lineHeight:'normal',width:'555px',display:'block'}}>
         Dive in and discover a curated set of career recommendations tailored just for you. Let us guide you towards fulfilling and meaningful career choices. Unveil your potential with our personalized insights.
        </Typography>
       </Box>

       <Box>
       <Typography variant="h6" style={{textAlign:'left',lineHeight:'normal'}}>
       Personalized Just for You
        </Typography>
        <Typography variant="body5" color='#637381' style={{textAlign:'left',lineHeight:'normal',width:'555px',display:'block'}}>
        Our recommendations are tailored to your unique assessments and profile data. We prioritize your preferences and needs to deliver personalized suggestions. 
        Trust in our insights to guide you effectively. Your feedback continually refines our offerings. Experience a curated journey with us.
        </Typography>
       </Box>
    </Stack>
    </Grid>
    <Box style={{position:'relative',top:'-51%',right:'-46%'}}>
    <LandingPageSvg />
    </Box>
   
     {/* <Grid item>
     <LandingPageSvg />
     </Grid> */}

     </Grid>

     </Grid>
      
    </main>
    </>
  );
};


