import React, { Component } from "react";
import AppLayout from "../../../components/Layout/Layout";
import Flex from "styled-flex-component";
import { connect } from "react-redux";
import StudentCourseDetails from "../../../components/studentCourseDetails";
import CourseMenu from "../../../components/CourseMenu";
import { getAssessmentsAC } from "../../../store/actions/courseAssessmentActions";
import moment from "moment";
import AssessmentLogGraph from "../../../components/AssessmentLogGraph";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const AssessmentDataDiv = styled.div`
  width: 75%;
  margin-left: 80px;

  @media (max-width: 930px) {
    margin-left: 40px;
  }
`;

const ListAssessDiv = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1100px) {
    display: block;
    margin-bottom: 20px;
  }
`;

const AssessmentTypesDiv = styled.div`
  width: 50%;
  @media (max-width: 1100px) {
    width: auto;
    margin-top: 20px;
  }
`;

const DateDiv = styled.div`
  width: 15%;
  margin: 10px 2.5%;

  @media (max-width: 930px) {
    width: 12%;
    margin: auto 2.5%;
  }
`;

const AssessNameDiv = styled.div`
  width: 25%;
  margin: 10px 2.5%;

  @media (max-width: 930px) {
    width: 18%;
    margin: auto 2.5%;
  }
`;

const TimeLimitDiv = styled.div`
  width: 10%;
  margin: 10px 2.5%;

  @media (max-width: 930px) {
    width: 15%;
    margin: auto 2.5%;
  }
`;

const AssessmentStatusDiv = styled.div`
  width: 10%;
  margin: 10px 2.5%;

  @media (max-width: 930px) {
    width: 10%;
    margin: auto 2.5%;
  }
`;

const AssessActionDiv = styled.div`
  width: 15%;
  margin: 10px 2.5%;

  @media (max-width: 930px) {
    width: 20%;
    margin: auto 2.5%;
  }
`;

const LeftArrowDiv = styled.div`
  width: 30%;
  margin-top: -10px;

  @media (max-width: 930px) {
    width: 50%;
    margin: auto;
  }
`;

const AssessActionDataDiv = styled.div`
  width: 70%;
  @media (max-width: 930px) {
    width: 50%;
    margin: auto;
  }
`;

class AssessmentDashboard extends Component {
  componentDidMount = async () => {
    document.body.style.backgroundColor = "#f5f5f5";
    console.log("calling action dispatcher");
    let subject_id = this.props.location.state.subject_id;
    // console.log(this.props.location.state)
    // let subject_id = 127;
    await this.props.getAssessments(subject_id);
  };

  onMenuBarClicked = (event, page) => {
    event.preventDefault();
    event.stopPropagation();
    if (page == "content") {
      this.props.history.push({
        pathname: "/course/content",
        state: { subject_id: this.props.subject_id },
      });
    } else if (page == "assessment") {
      this.props.history.push({
        pathname: "/course/assessment",
        state: { subject_id: this.props.subject_id },
      });
    } else if (page == "practice") {
      this.props.history.push({
        pathname: "/course/practice",
        state: { subject_id: this.props.subject_id },
      });
    }
  };
  startAssessment = (id, type) => {
    if (type === "mcq") {
      this.props.history.push({
        pathname: "/assessment",
        state: {
          test_id: id,
          prev_page: "course/assessment",
          subject_id: this.props.subject_id,
        },
      });
    } else if (type === "coding") {
      this.props.history.push({
        pathname: "/startCoding",
        state: {
          test_id: id,
        },
      });
    } else if (type === "subjective") {
      this.props.history.push({
        pathname: "/subjectiveAssessment",
        state: {
          test_id: id,
        },
      });
    }

    // location.reload();
  };

  openExamDetails = (event, id, type) => {
    this.props.history.push({
      pathname: "/questionnaireDetails",
      state: { test_id: id, type: type },
    });
  };

  goToResults = (id, type) => {
    if (type === "mcq") {
      this.props.history.push({
        pathname: "/assessmentResults",
        state: { test_id: id },
      });
    } else if (type === "coding") {
      this.props.history.push({
        pathname: "/codingAssessmentSummary",
        state: { test_id: id },
      });
    } else if (type === "subjective") {
      this.props.history.push({
        pathname: "/subjectiveResults",
        state: { test_id: id },
      });
    }
  };

  render() {
    console.log(this.props);
    if (this.props.loading_assessments) {
      return <div>Loading...</div>;
    } else if (
      this.props.error_loading_assessments &&
      !this.props.loading_assessments
    ) {
      return <div>Error Loading Assessments</div>;
    } else if (this.props.no_assessments && !this.props.loading_assessments) {
      return (
        <div
          style={{ overflow: "hidden", background: "white" }}
          className="row"
        >
          <AppLayout></AppLayout>
          <div className="col-lg-9">
            <StudentCourseDetails
              name={this.props.student_name}
            ></StudentCourseDetails>
            <div className="mainBox">
              <Flex column>
                <div style={{ width: "100%" }}>
                  <CourseMenu
                    onMenuBarClicked={this.onMenuBarClicked}
                    active_page="assessment"
                  ></CourseMenu>
                </div>
                <div
                  style={{
                    width: "75%",
                    margin: "auto",
                    marginLeft: "80px",
                    marginRight: "20px",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    There are no Assessments in this subject{" "}
                  </div>
                </div>
              </Flex>
            </div>
          </div>
          <style jsx="true" scoped>
            {`
              .mainBox {
                display: block;
                width: 100%;
                background-color: white;
                border-radius: 8px;
                margin-top: 20px;
                text-align: left;
                padding: 15px;
                box-sizing: border-box;
                box-shadow: 0 0 10px #ccc;
              }
            `}
          </style>
        </div>
      );
    }
    let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
    console.log(current_time);
    return (
      <div style={{ overflow: "hidden" }}>
        <AppLayout></AppLayout>
        <div
          style={{
            margin: "auto",
            marginLeft: "2%",
            position: "absolute",
            top: "96px",
            width: "96%",
          }}
        >
          <StudentCourseDetails
            name={this.props.student_name}
          ></StudentCourseDetails>
          <div className="mainBox">
            <Flex row>
              <div style={{ width: "20%" }}>
                <CourseMenu
                  onMenuBarClicked={this.onMenuBarClicked}
                  active_page="assessment"
                ></CourseMenu>
              </div>
              <AssessmentDataDiv
                style={{ marginRight: "20px", marginTop: "20px" }}
              >
                <div>
                  <div style={{ textAlign: "left", fontWeight: "500" }}>
                    Logs
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "70%" }}>
                      <AssessmentLogGraph
                        data={this.props.graphData}
                      ></AssessmentLogGraph>
                    </div>
                    <div style={{ width: "30%" }}>
                      <div style={{ margin: "20px 10px", textAlign: "center" }}>
                        <div
                          style={{
                            margin: "10px",
                            fontSize: "24px",
                            fontWeight: "500",
                            textAlign: "center",
                            color: "blue",
                          }}
                        >
                          {this.props.total_assessments}
                        </div>
                        <div style={{ margin: "10px", fontSize: "16px" }}>
                          Total Assessments
                        </div>
                      </div>
                      <div style={{ margin: "20px 10px", textAlign: "center" }}>
                        <div
                          style={{
                            margin: "10px",
                            fontSize: "24px",
                            fontWeight: "500",
                            textAlign: "center",
                            color: "green",
                          }}
                        >
                          {this.props.taken_assessments}
                        </div>
                        <div style={{ margin: "10px", fontSize: "16px" }}>
                          Assessments Submitted
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ margin: "30px 0", borderBottom: "1px solid #ddd" }}
                ></div>
                <ListAssessDiv>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "left",
                      fontWeight: "500",
                    }}
                  >
                    List of Assessments
                  </div>
                  <AssessmentTypesDiv
                    style={{
                      textAlign: "right",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginRight: "15px",
                      }}
                    >
                      <div className="objGradient"></div>Objective
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginRight: "15px",
                      }}
                    >
                      <div className="subGradient"></div>Subjective
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginRight: "15px",
                      }}
                    >
                      <div className="codGradient"></div>Coding Challenge
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginRight: "15px",
                      }}
                    >
                      <div className="assignGradient"></div>Assignment
                    </div>
                  </AssessmentTypesDiv>
                </ListAssessDiv>
                <div>
                  {this.props.assessments.map((assessment) => {
                    let hours = Math.floor(assessment.timelimit / (60 * 60));
                    let seconds = assessment.timelimit - hours * 60 * 60;
                    let minutes;
                    if (seconds > 60) {
                      minutes = Math.floor(seconds / 60);
                      seconds = seconds - minutes * 60;
                    }
                    let timelimit =
                      minutes && hours && seconds
                        ? hours + "hrs " + minutes + "mins " + seconds + "secs"
                        : minutes && !hours && seconds
                        ? minutes + "mins " + seconds + "secs"
                        : minutes && !hours && !seconds
                        ? minutes + "mins"
                        : hours && seconds && !minutes
                        ? hours + "hrs " + seconds + "secs"
                        : !minutes && hours && !seconds
                        ? hours + "hrs"
                        : !minutes && !hours && seconds
                        ? seconds + "secs"
                        : hours && minutes && !seconds
                        ? hours + "hrs " + minutes + "mins"
                        : "0";
                    // let timelimit = minutes && hours ? hours + 'hrs ' + minutes + 'mins' : minutes && !hours ? minutes + 'mins' :hours + 'hrs';
                    return (
                      <div
                        key={assessment.id}
                        className={
                          assessment.type === "coding"
                            ? "assessmentBox codAssess"
                            : assessment.type == "assignment"
                            ? "assessmentBox assignAssess"
                            : assessment.type === "subjective"
                            ? "assessmentBox subAssess"
                            : "assessmentBox"
                        }
                      >
                        <DateDiv style={{ padding: "15px 0" }}>
                          {moment(assessment.created_at).format("Do MMMM YYYY")}
                        </DateDiv>
                        <AssessNameDiv style={{ padding: "15px 0" }}>
                          {assessment.name}
                        </AssessNameDiv>
                        <TimeLimitDiv
                          style={{
                            padding: "15px 0",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div>
                            <img
                              src="/images/time_image.svg"
                              alt="test time"
                            ></img>
                          </div>{" "}
                          <div style={{ marginLeft: "10px" }}>{timelimit}</div>
                        </TimeLimitDiv>
                        {assessment.submitted ? (
                          <AssessmentStatusDiv
                            style={{ color: "green", padding: "15px 0" }}
                          >
                            Submitted
                          </AssessmentStatusDiv>
                        ) : assessment.start_time > current_time &&
                          !assessment.submitted ? (
                          <AssessmentStatusDiv
                            style={{ color: "gray", padding: "15px 0" }}
                          >
                            Yet To Start
                          </AssessmentStatusDiv>
                        ) : assessment.type === "mcq" &&
                          assessment.doe < current_time &&
                          assessment.submitted ? (
                          <AssessmentStatusDiv
                            style={{ color: "green", padding: "15px 0" }}
                          >{`${assessment.percentage}% (${assessment.marks}/${assessment.total_questions})`}</AssessmentStatusDiv>
                        ) : assessment.type === "subjective" &&
                          assessment.submitted &&
                          assessment.doe < current_time &&
                          assessment.results_release_time > current_time ? (
                          <AssessmentStatusDiv
                            style={{ color: "green", padding: "15px 0" }}
                          >
                            Evaluation In Process
                          </AssessmentStatusDiv>
                        ) : (assessment.type === "coding" ||
                            (assessment.type === "subjective" &&
                              assessment.results_release_time >=
                                current_time)) &&
                          assessment.submitted &&
                          assessment.doe < current_time ? (
                          <AssessmentStatusDiv
                            style={{
                              margin: "10px 2.5%",
                              width: "10%",
                              color: "green",
                              padding: "15px 0",
                            }}
                          >{`${assessment.percentage}% (${Math.floor(
                            Number(assessment.student_marks)
                          )}/${Math.floor(
                            Number(assessment.total_marks)
                          )})`}</AssessmentStatusDiv>
                        ) : !assessment.submitted &&
                          assessment.start_time <= current_time &&
                          assessment.doe > current_time ? (
                          <AssessmentStatusDiv
                            style={{ color: "green", padding: "15px 0" }}
                          >
                            Available
                          </AssessmentStatusDiv>
                        ) : (
                          <AssessmentStatusDiv
                            style={{ color: "red", padding: "15px 0" }}
                          >
                            Missed
                          </AssessmentStatusDiv>
                        )}

                        {assessment.type === "coding" &&
                        assessment.submitted &&
                        assessment.doe > current_time ? (
                          <AssessActionDiv
                            style={{
                              padding: "15px 0",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >{`Results expected on ${moment(
                            assessment.doe
                          ).format("Do MMMM, HH:mm A")}`}</AssessActionDiv>
                        ) : assessment.type === "mcq" &&
                          assessment.submitted &&
                          assessment.results_release_time &&
                          assessment.results_release_time > current_time ? (
                          <AssessActionDiv
                            style={{
                              padding: "15px 0",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >{`Results expected on ${moment(
                            assessment.results_release_time
                          ).format("Do MMMM, HH:mm A")}`}</AssessActionDiv>
                        ) : assessment.type === "mcq" &&
                          assessment.submitted &&
                          assessment.doe > current_time ? (
                          <AssessActionDiv
                            style={{
                              padding: "15px 0",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >{`Results expected on ${moment(
                            assessment.results_release_time
                          ).format("Do MMMM, HH:mm A")}`}</AssessActionDiv>
                        ) : assessment.type === "subjective" &&
                          assessment.submitted &&
                          assessment.doe <= current_time &&
                          assessment.results_release_time > current_time ? (
                          <AssessActionDiv
                            style={{
                              padding: "15px 0",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >{`Results expected on ${moment(
                            assessment.results_release_time
                          ).format("Do MMMM, HH:mm A")}`}</AssessActionDiv>
                        ) : assessment.type === "subjective" &&
                          assessment.submitted &&
                          assessment.doe > current_time ? (
                          <AssessActionDiv
                            style={{
                              padding: "15px 0",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            Test In Progress
                          </AssessActionDiv>
                        ) : assessment.type === "subjective" &&
                          assessment.submitted &&
                          assessment.doe <= current_time &&
                          (assessment.results_release_time > current_time ||
                            !assessment.results_release_time) ? (
                          <AssessActionDiv
                            style={{
                              padding: "15px 0",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            Evaluation In Process
                          </AssessActionDiv>
                        ) : (assessment.type === "coding" ||
                            (assessment.type === "subjective" &&
                              assessment.results_release_time <=
                                current_time)) &&
                          assessment.submitted &&
                          assessment.doe <= current_time ? (
                          <AssessActionDiv
                            style={{
                              padding: "15px 0",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <AssessActionDataDiv
                              style={{ marginRight: "20px" }}
                            >
                              View Results
                            </AssessActionDataDiv>
                            <LeftArrowDiv
                              onClick={() =>
                                this.goToResults(assessment.id, assessment.type)
                              }
                              style={{ height: "38px" }}
                            >
                              <img
                                width="38px"
                                height="38px"
                                src="/images/arrow_with_circle.svg"
                                alt="go to results"
                              ></img>
                            </LeftArrowDiv>
                          </AssessActionDiv>
                        ) : assessment.type === "mcq" &&
                          assessment.submitted &&
                          ((assessment.results_release_time &&
                            assessment.results_release_time <= current_time) ||
                            assessment.doe <= current_time) ? (
                          <AssessActionDiv
                            style={{
                              padding: "15px 0",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <AssessActionDataDiv
                              style={{ marginRight: "20px" }}
                            >
                              View Results
                            </AssessActionDataDiv>
                            <LeftArrowDiv
                              onClick={() =>
                                this.goToResults(assessment.id, assessment.type)
                              }
                              style={{ height: "38px" }}
                            >
                              <img
                                width="38px"
                                height="38px"
                                src="/images/arrow_with_circle.svg"
                                alt="go to results"
                              ></img>
                            </LeftArrowDiv>
                          </AssessActionDiv>
                        ) : !assessment.submitted &&
                          assessment.doe > current_time &&
                          assessment.start_time <= current_time ? (
                          <AssessActionDiv
                            onClick={() =>
                              this.startAssessment(
                                assessment.id,
                                assessment.type
                              )
                            }
                            style={{
                              padding: "15px 0",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <AssessActionDataDiv>Start</AssessActionDataDiv>
                            <LeftArrowDiv style={{ height: "38px" }}>
                              <img
                                width="38px"
                                height="38px"
                                src="/images/arrow_with_circle.svg"
                                alt="start test"
                              ></img>
                            </LeftArrowDiv>
                          </AssessActionDiv>
                        ) : assessment.start_time > current_time &&
                          !assessment.submitted ? (
                          <AssessActionDiv
                            style={{
                              padding: "15px 0",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <AssessActionDataDiv
                              style={{ marginRight: "10px" }}
                            >
                              Start
                            </AssessActionDataDiv>
                          </AssessActionDiv>
                        ) : (
                          <AssessActionDiv
                            style={{
                              padding: "15px 0",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <AssessActionDataDiv
                              style={{ marginRight: "10px" }}
                            >
                              Exam Details
                            </AssessActionDataDiv>
                            <LeftArrowDiv
                              onClick={(event) =>
                                this.openExamDetails(
                                  event,
                                  assessment.id,
                                  assessment.type
                                )
                              }
                              style={{ height: "38px" }}
                            >
                              <img
                                width="38px"
                                height="38px"
                                src="/images/arrow_with_circle.svg"
                                alt="exam details"
                              ></img>
                            </LeftArrowDiv>
                          </AssessActionDiv>
                        )}
                      </div>
                    );
                  })}
                </div>
              </AssessmentDataDiv>
            </Flex>
          </div>
        </div>
        <style jsx="true" scoped>
          {`
            .assessmentBox {
              display: flex;
              flex-direction: row;
              width: 100%;
              background-color: white;
              border-radius: 8px;
              margin-top: 20px;
              text-align: left;
              box-sizing: border-box;
              box-shadow: 0 0 10px #ccc;
              font-size: 14px;
              border-left: 5px solid transparent;
              background-image: linear-gradient(#fff, #fff),
                linear-gradient(
                  144.48deg,
                  #f38fa2 9.88%,
                  #eb5c6f 54.05%,
                  #f1825c 116.8%
                );
              background-origin: border-box;
              background-clip: content-box, border-box;
            }
            .objAssess {
              background-image: linear-gradient(#fff, #fff),
                linear-gradient(
                  144.48deg,
                  #f38fa2 9.88%,
                  #eb5c6f 54.05%,
                  #f1825c 116.8%
                );
            }
            .subAssess {
              background-image: linear-gradient(#fff, #fff),
                linear-gradient(
                  149.32deg,
                  #a0f4ff -46.18%,
                  #75dae9 -17.1%,
                  #8459f8 97.41%
                );
            }
            .codAssess {
              background-image: linear-gradient(#fff, #fff),
                linear-gradient(
                  243.37deg,
                  #dcff73 -38.07%,
                  #a9d868 37.1%,
                  #8eca6b 101.61%
                );
            }
            .mainBox {
              display: block;
              width: 100%;
              background-color: white;
              border-radius: 8px;
              margin-top: 20px;
              text-align: left;
              padding: 15px;
              box-sizing: border-box;
              box-shadow: 0 0 10px #ccc;
            }
            .objGradient {
              width: 11px;
              height: 11px;
              background: linear-gradient(
                144.48deg,
                #f38fa2 9.88%,
                #eb5c6f 54.05%,
                #f1825c 116.8%
              );
              border-radius: 50%;
              margin-top: 5.5px;
              margin-right: 10px;
            }
            .subGradient {
              width: 11px;
              height: 11px;
              background: linear-gradient(
                149.32deg,
                #a0f4ff -46.18%,
                #75dae9 -17.1%,
                #8459f8 97.41%
              );
              border-radius: 50%;
              margin-top: 5.5px;
              margin-right: 10px;
            }
            .codGradient {
              width: 11px;
              height: 11px;
              background: linear-gradient(
                243.37deg,
                #dcff73 -38.07%,
                #a9d868 37.1%,
                #8eca6b 101.61%
              );
              border-radius: 50%;
              margin-top: 5.5px;
              margin-right: 10px;
            }
            .assignGradient {
              width: 11px;
              height: 11px;
              background: linear-gradient(
                243.37deg,
                #c207ff -38.07%,
                #bf58e1c9 37.1%,
                #dbb8e1 101.61%
              );
              border-radius: 50%;
              margin-top: 5.5px;
              margin-right: 10px;
            }
          `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    subject_id: state.cad.subject_id,
    student_name: state.cad.student_name,
    assessments: state.cad.assessments,
    graphData: state.cad.graphData,
    loading_assessments: state.cad.loading_assessments,
    error_loading_assessments: state.cad.error_loading_assessments,
    total_correct_answered_questions:
      state.cad.total_correct_answered_questions,
    total_questions: state.cad.total_questions,
    total_assessments: state.cad.total_assessments,
    taken_assessments: state.cad.taken_assessments,
    no_assessments: state.cad.no_assessments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAssessments: (subject_id) => dispatch(getAssessmentsAC(subject_id)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssessmentDashboard)
);
