import React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

const CustomChip = styled(Chip)(({ theme, variant }) => ({
    height: variant === 'outlined' ? '30px' : variant === 'filled' ? '17px' : 'none',
    padding: variant === 'outlined' ? '10px' : variant === 'filled' ? '10px 5px' : 'none',
    borderRadius: variant === 'outlined' ? '28px' : variant === 'filled' ? '19px' : 'none',
    border: variant === 'outlined' ? '1px solid #919EAB' : 'none',
    background: variant === 'outlined' ? '#F9FAFB' : variant === 'filled' ? '#F4F6F8' : 'none',
    color: variant === 'outlined' ? '#637381' : variant === 'filled' ? '#45525B' : 'none',
    fontSize: variant === 'outlined' ? '12px' : variant === 'filled' ? '10px' : 'inherit',
    fontWeight: 400,
    '& .MuiChip-label': {
        padding: 0,
    }
}));

const MuiCustomChip = ({ label = '--', variant = 'outlined', onClick = () => {} }) => {
    const handleClick = () => {
        onClick();
    };

    return (
        <CustomChip
            variant={variant}
            clickable={variant === 'outlined' ? true : false}
            onClick={handleClick}
            label={label}
            size="medium"
        />
    );
};

export default MuiCustomChip;