import { Box, Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import RemedialCard from '../RemedialCard/RemedialCard'
import ActiveStudentSvg from './../../../../assets/remedial/svg/users.svg'
import ActiveStudentListItem from './ActiveStudentListItem'
import { useRemedialContext } from '../../hooks/useRemedial'

const ActiveStudentsCard = ({ showList = false , bannerData }) => {
  return (
    <RemedialCard>
      <Stack gap={'16px'}>
        <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
          <Box>
            <img src={ActiveStudentSvg} width={24} height={24} alt="ActiveStudentSvg" />
          </Box>
          <Typography component='p' sx={{
            fontFamily: 'Poppins-SemiBold',
            color: 'grey.900',
            fontSize: '16px'
          }}>Active Students</Typography>

        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={'20px'}>
          {bannerData?.active_students ? <Typography component='p' sx={{
            fontFamily: 'Poppins-SemiBold',
            color: 'primary.800',
            fontSize: '24px'
          }}>{bannerData?.active_students}</Typography> : <Skeleton variant='rounded' width={30} height={30} />}

          <Typography component='p' sx={{
            fontFamily: 'Poppins-Medium',
            color: 'grey.700',
            fontSize: '12px',
            textAlign: 'start',
            span: {
              fontFamily: 'Poppins-SemiBold',
            }
          }}>Students are studying to improve their test score.
            Don’t stay behind. Start studying now!</Typography>

        </Stack>
        {/* list */}
        {
          showList && bannerData?.most_active_students.map((item, index) => <ActiveStudentListItem key={index} data={item} />)
        }
      </Stack>
    </RemedialCard>
  )
}

export default ActiveStudentsCard