import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import styled, { keyframes, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import AppLayout from "../../components/Layout/Layout";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { AiOutlineFieldTime } from "react-icons/ai";
import { VscGraph } from "react-icons/vsc";
import { AiOutlineFileDone } from "react-icons/ai";
import { GrDocumentPerformance } from "react-icons/gr";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import moment from "moment";
import Leaderboardanalytics from "./leaderboardanalytics";
import ReactTooltip from "react-tooltip";
import Tooltip from '@mui/material/Tooltip';
import {
    getAllsectionLeaderboard
  } from "../../store/actions/dashboardActions";
let getlocalstorage = localStorage.getItem("user");
  getlocalstorage = JSON.parse(getlocalstorage);
const Leaderboardrow = styled.div`
    display: flex;
    font-size: 10px;
   align-items: center;
    font-weight: 600;
    border-bottom: 1px solid #80808052;
    padding: 12px 0px 12px 0px;
    padding-left: 0px !important;
  `;
function Analyticscreen(props) {
    console.log(props)
 console.log("---------")
    // console.log(props.analytics_data)
    const [performanceshow, setperformanceshow] = useState(true)
    const [checkboxstatus, setcheckboxstatus] = useState(false);
    const [subjectpagecheckboxstatus, setsubjectpagecheckboxstatus] = useState(false);
    useEffect(() => {
        if (!props.coursepage) {
            if (props.sevendays_all_section) {
            props.getAllsectionLeaderboarddata("7","Performance")
            props.getAllsectionLeaderboarddata("7","Participation")
            }else if(props.thirty_all_section){
                props.getAllsectionLeaderboarddata("30","Performance")
                props.getAllsectionLeaderboarddata("30","Participation")

            }

        }
      
   
    }, []);
    const showallsections = (event) => {
        console.log(event.target.checked)
        setcheckboxstatus(event.target.checked);
    }
    const showperformance = () => {
        setperformanceshow(true);
    }
    const showparticipation = () => {
        setperformanceshow(false);
    }
    const showallsubjectssections=(event)=>{
        setsubjectpagecheckboxstatus(event.target.checked);
    }
    const secondsToHms = (second) => {
        let dateObj = new Date(second * 1000);
        let hours = dateObj.getUTCHours();
        let minutes = dateObj.getUTCMinutes();
        let seconds = dateObj.getSeconds();

        let timeString = hours.toString().padStart(2, '0')
            + ':' + minutes.toString().padStart(2, '0')
            + ':' + seconds.toString().padStart(2, '0');
        return timeString;
    }
    return (
        <>

            <div className="col-lg-12 row" style={{
                fontSize: "12px", marginTop: "13px", background: "white"
                , boxShadow: "#595e59a3 1px 1px 4px", padding: "0px"
            }}>
                <div className="col-lg-6" data-tip data-for="registerTip" style={{
                    boxShadow: "4px 3px 1px -2px #8888889c",
                    paddingTop: "8px", background: "rgb(82 195 255 / 58%)", height: "70px"
                }}>
                    <div className="timespent" style={{ paddingTop: "3px", fontSize: "10px" }}>Total time spent</div>
                    <div className="timespent_icon" style={{ float: "left", padding: "11px 0px 0px 0px" }}>
                        <AiOutlineFieldTime
                            style={{ fontSize: "26px", color: "#0eb10e" }} />

                    </div>
                    <div className="timespent_content" style={{ fontWeight: "600", padding: "17px 28px 0px 0px" }}>
                        {secondsToHms(props.data.academic_performance.total_time_spent)}
                        {/* {new Date(props.data.academic_performance.total_time_spent * 1000).toISOString().substring(11, 16)} */}
                    </div>
                </div>
                <Tooltip title={<><div>Subjective: {props.data.academic_performance.subjective_attempted}</div>
                    <div>Mcq: {props.data.academic_performance.mcq_attempted}</div>
                    <div>Coding: {props.data.academic_performance.coding_attempted}</div></>} followCursor>
                    <div className="col-lg-6" style={{
                        boxShadow: "0px 1px 4px -2px #8888889c",
                        paddingTop: "8px", background: "rgb(255 203 87 / 68%)", height: "70px"
                    }}>
                        <div className="timespent" style={{ paddingTop: "3px", fontSize: "10px" }}>Total tests taken</div>
                        <div className="timespent_icon" style={{ padding: "11px 0px 0px 0px", float: "left" }}>
                            <AiOutlineFileDone style={{ fontSize: "26px", color: "" }} /></div>
                        <div className="timespent_content" style={{ fontWeight: "600", padding: "17px 28px 0px 0px" }}>
                            {props.data.academic_performance.total_tests_attempted}
                        </div>
                    </div>
                </Tooltip>
                <Tooltip title={<><div>Subjective: {props.data.academic_performance.avg_subjective_performance
                } %</div>
                    <div>Mcq: {props.data.academic_performance.avg_mcq_performance} %</div>
                    <div>Coding: {props.data.academic_performance.avg_coding_performance} %</div>
                    <div>Live tests: {props.data.academic_performance.avg_live_performance} %</div></>} followCursor>
                    <div className="col-lg-6" style={{
                        boxShadow: "rgb(136 136 136 / 61%) 4px 0px 1px -2px",
                        padding: "0px 0px 0px 2px", background: "rgb(255, 223, 214)", height: "70px"
                    }}>
                        <div style={{ paddingTop: "3px", fontSize: "10px" }}>Average academic performance</div>
                        <div style={{ padding: "5px", float: "left" }}>
                            <VscGraph style={{ fontSize: "26px", color: "#b5b51c" }} /></div>
                        <div style={{ fontWeight: "600", padding: "2px 28px 0px 0px" }}>
                            {props.data.academic_performance.overall_performance
                            } %
                        </div>
                    </div>
                </Tooltip>
                {!props.coursepage ?
                    <Tooltip title={<><div>Total: {props.data.aptitude_performance.total_questions}</div>
                        <div>Attempted: {props.data.aptitude_performance.attempted_questions}</div>
                        <div>Correct: {props.data.aptitude_performance.correct_answers}</div></>} followCursor>
                        <div className="col-lg-6" style={{ padding: "0px 0px 0px 2px", background: "rgb(252 119 160 / 58%)", height: "70px" }}>
                            <div style={{ paddingTop: "3px", fontSize: "10px" }}>Average aptitude performance</div>
                            <div style={{ padding: "5px", float: "left" }}>
                                <TbDeviceDesktopAnalytics style={{ fontSize: "26px", color: "#a16363" }} />
                            </div>
                            <div className="aptitudediv" style={{ fontWeight: "600", padding: "4px 28px 0px 0px" }}>
                                {props.data.aptitude_performance.average_performance}
                            </div>
                        </div>
                    </Tooltip>
                    : <Tooltip followCursor>
                        <div className="col-lg-6" style={{ padding: "4px 0px 0px 2px", background: "rgb(252 119 160 / 58%)", height: "70px" }}>
                            <div style={{ paddingTop: "3px", fontSize: "10px" }}>Your Rank</div>
                            <div style={{ padding: "5px", float: "left" }}>
                                <TbDeviceDesktopAnalytics style={{ fontSize: "26px", color: "#a16363" }} />
                            </div>
                            <div className="aptitudediv" style={{ fontWeight: "600", padding: "6px 28px 0px 0px" }}>
                            {props.data.performance_leaderboard.findIndex((x) => x.student_id ===props.user_id )+1}/{props.data.performance_leaderboard.length}
                            </div>
                        </div>
                    </Tooltip>}
            </div>
            {/* </Tooltip> */}
            {props.data.performance_leaderboard.length > 0 ?
                <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 33
                }}
                >
                <div
                    style={{
                    fontWeight: "bold",
                    float: "left",
                    /* marginTop: 30, */ fontSize: 14
                    }}
                >
                    Leaderboard
                </div>
                <div className="form-check">
             {props.coursepage ?
            <>
            {props.allsections_participation && props.allsections_performance ?
            <>
            <input
            className="form-check-input"
            type="checkbox"
            name="subjectpagecheckboxstatus"
            onChange={showallsubjectssections}
            />  <label className="form-check-label" title="Compare with other sections" htmlFor="flexCheckDefault">
            All Sections
            </label></>: <><input
            className="form-check-input"
            disabled="true"
            type="checkbox"
            name="checkboxstatus"
            onChange={showallsections}
            />  <label className="form-check-label" htmlFor="flexCheckDefault">
            All Sections
            </label></>}</>
                :<>{(props.sevendays_all_section && !props.allsections_sevendays_performance &&
                    !props.allsections_sevendays_participation) || (props.thirty_all_section && !props.allsections_thirtydays_performance
                && !props.allsections_thirtydays_participation) ?
                <>
                <input
                className="form-check-input"
                disabled="true"
                type="checkbox"
                name="checkboxstatus"
                onChange={showallsections}
                />  <label className="form-check-label" htmlFor="flexCheckDefault">
                All Sections
                </label></>: <><input
                className="form-check-input"
                type="checkbox"
                name="checkboxstatus"
                onChange={showallsections}
                />  <label className="form-check-label" htmlFor="flexCheckDefault">
                All Sections
                </label></>}</>
                
                }
                   
                  
                </div>
                </div>

                    <div
                        className="col-lg-12 row"
                        style={{
                            background: "white",
                            paddingLeft: 0,
                            borderRadius: 20,
                            paddingRight: 0
                        }}
                    >

                        <div
                            style={{
                                display: "flex",
                                border: 1,
                                justifyContent: "space-around",
                                marginTop: 9,
                                borderRadius: 5,
                                padding: 0,
                                background: "#808080c7",
                                marginLeft: 36,
                                width: "70%",
                                textAlign: "center"
                            }}
                            className="col-lg-12"
                        >
                            <div className={performanceshow ? "activetab_rightpanel1" : "inactivetab_rightpanel1"}
                                onClick={showperformance}>
                                Performance
                            </div>
                            <div
                                className={!performanceshow ? "activetab_rightpanel1" : "inactivetab_rightpanel1"}
                                onClick={showparticipation}
                            >
                                Participation
                            </div>
                        </div>
                        {checkboxstatus && !props.coursepage ?
                        <>
                        {performanceshow && props.sevedays ?
                            <Leaderboardanalytics
                                data={props.allsections_sevendays_performance.leaderboard} performace={true}
                                user_id={props.user_id}
                            />
                            : !performanceshow && props.sevedays ?
                                <Leaderboardanalytics
                                    data={props.allsections_sevendays_participation.leaderboard} performace={false}
                                    user_id={props.user_id}
                                />
                                : performanceshow && !props.sevedays ?
                                    <Leaderboardanalytics
                                        data={props.allsections_thirtydays_performance.leaderboard} performace={true}
                                        user_id={props.user_id}
                                    />
                                    : !performanceshow && !props.sevedays ?
                                        <Leaderboardanalytics
                                            data={props.allsections_thirtydays_participation.leaderboard} performace={false}
                                            user_id={props.user_id}
                                        />
                                        : null

                        }
                        </>
                    :subjectpagecheckboxstatus && props.coursepage ?
                    <>
                    {performanceshow && props.sevedays ?
                        <Leaderboardanalytics
                            data={props.allsections_performance.leaderboard} performace={true}
                            user_id={props.user_id}
                        />
                        : !performanceshow && props.sevedays ?
                            <Leaderboardanalytics
                                data={props.allsections_participation.leaderboard} performace={false}
                                user_id={props.user_id}
                            />
                            : performanceshow && !props.sevedays ?
                                <Leaderboardanalytics
                                    data={props.allsections_performance.leaderboard} performace={true}
                                    user_id={props.user_id}
                                />
                                : !performanceshow && !props.sevedays ?
                                    <Leaderboardanalytics
                                        data={props.allsections_participation.leaderboard} performace={false}
                                        user_id={props.user_id}
                                    />
                                    : null

                    }
                    </>
                    :<> {performanceshow && props.sevedays ?
                        <Leaderboardanalytics
                            data={props.data.performance_leaderboard} performace={true}
                            user_id={props.user_id}
                        />
                        : !performanceshow && props.sevedays ?
                            <Leaderboardanalytics
                                data={props.data.participation_leaderboard} performace={false}
                                user_id={props.user_id}
                            />
                            : performanceshow && !props.sevedays ?
                                <Leaderboardanalytics
                                    data={props.data.performance_leaderboard} performace={true}
                                    user_id={props.user_id}
                                />
                                : !performanceshow && !props.sevedays ?
                                    <Leaderboardanalytics
                                        data={props.data.participation_leaderboard} performace={false}
                                        user_id={props.user_id}
                                    />
                                    : null

                    }</>}

                    </div>
                </>
                : null}


        </>
    );
}

function mapStateToProps(state) {
    console.log("printing props to dstate\\\\")
    console.log(state.dash.allsections_sevendays_performance)
    return {
        query_date: state.dash.query_date,
        loading_analyticsdata: state.dash.loading_analyticsdata,
        error_loading_analyticsdata: state.dash.error_loading_analyticsdata,
        analytics_data: state.dash.analytics_data,
        analytics_data_semester: state.dash.analytics_data_semester,
        loading_seven_days_all_sections_performance:state.dash.loading_seven_days_all_sections_performance,
        loading_thirty_days_all_sections_performance:state.dash.loading_thirty_days_all_sections_performance,
        loading_seven_days_all_sections_participation:state.dash.loading_seven_days_all_sections_participation,
        loading_thirty_days_all_sections_participation:state.dash.loading_thirty_days_all_sections_participation,
        allsections_sevendays_performance:state.dash.allsections_sevendays_performance,
        allsections_thirtydays_performance:state.dash.allsections_thirtydays_performance,
        allsections_sevendays_participation:state.dash.allsections_sevendays_participation,
        allsections_thirtydays_participation:state.dash.allsections_thirtydays_participation,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllsectionLeaderboarddata:(activity_days,type)=>dispatch(getAllsectionLeaderboard(activity_days,type)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Analyticscreen)
);
