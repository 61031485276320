import React, { Component } from "react";
import styled from "@emotion/styled";
import Slide from "./Slide";
import PropTypes from "prop-types";

import NavigationButtons from '../NavigationButtons/NavigationButtons'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

// const NavigationButtons = styled.div`
//   position: relative;
//   display: flex;
//   height: 30px;
//   margin: 0 0 0 auto;
//   width: 11%;
//   margin-top: 1rem;
//   justify-content: space-between;
//   align-items:center

//   img {
//     height: 30px;
//     width: 30px;
//   }

//   span{
//     font-size: 16px;
//     line-height: 32px;
//   }
// `;

const DEFAULT_GO_TO_SLIDE_DELAY = 200;

function mod(a, b) {
    return ((a % b) + b) % b;
}

class Carousel extends Component {
    state = {
        index: 0,
        goToSlide: null,
        prevPropsGoToSlide: 0,
        newSlide: false
    };

    goToIn;

    static propTypes = {
        slides: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.any,
                content: PropTypes.object
            })
        ).isRequired,
        goToSlide: PropTypes.number,
        showNavigation: PropTypes.bool,
        offsetRadius: PropTypes.number,
        animationConfig: PropTypes.object,
        goToSlideDelay: PropTypes.number,
    };

    static defaultProps = {
        offsetRadius: 2,
        animationConfig: { tension: 120, friction: 14 },
        goToSlideDelay: DEFAULT_GO_TO_SLIDE_DELAY,
    };

    static getDerivedStateFromProps(props, state) {
        const { goToSlide } = props;

        if (goToSlide !== state.prevPropsGoToSlide) {
            return { prevPropsGoToSlide: goToSlide, goToSlide, newSlide: true };
        }

        return null;
    }

    componentDidUpdate() {
        const { goToSlideDelay } = this.props;
        const { index, goToSlide, newSlide } = this.state;
        if (typeof goToSlide === "number") {
            if (newSlide) {
                this.handleGoToSlide();
            } else if (index !== goToSlide && typeof window !== "undefined") {
                window.clearTimeout(this.goToIn);
                this.goToIn = window.setTimeout(this.handleGoToSlide, goToSlideDelay);
            } else if (typeof window !== "undefined") {
                window.clearTimeout(this.goToIn);
            }
        }
    }

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.clearTimeout(this.goToIn);
        }
    }

    modBySlidesLength = (index) => {
        return mod(index, this.props.slides.length);
    };

    moveSlide = (direction) => {
        this.setState({
            index: this.modBySlidesLength(this.state.index + direction),
            goToSlide: null
        });
    };

    moveSlideToIndex = (index) => {
        this.setState({
            index: this.modBySlidesLength(index),
            goToSlide: null
        });
    };

    getShortestDirection(from, to) {
        if (from > to) {
            if (from - to > this.props.slides.length - 1 - from + to) {
                return 1;
            } else return -1;
        } else if (to > from) {
            if (to - from > from + this.props.slides.length - 1 - to) {
                return -1;
            } else return 1;
        }
        return 0;
    }

    handleGoToSlide = () => {
        if (typeof this.state.goToSlide !== "number") {
            return;
        }

        const { index } = this.state;

        const goToSlide = mod(this.state.goToSlide, this.props.slides.length);

        if (goToSlide !== index) {
            let direction = this.getShortestDirection(index, goToSlide);
            const isFinished =
                this.modBySlidesLength(index + direction) === goToSlide;

            this.setState({
                index: this.modBySlidesLength(index + direction),
                newSlide: isFinished,
                goToSlide: isFinished ? null : goToSlide
            });
        }
    };

    clampOffsetRadius(offsetRadius) {
        const { slides } = this.props;
        const upperBound = Math.floor((slides.length - 1) / 2);
        if (offsetRadius < 0) {
            return 0;
        }
        if (offsetRadius > upperBound) {
            return upperBound;
        }
        return offsetRadius;
    }

    getPresentableSlides() {
        const { slides } = this.props;
        const { index } = this.state;
        let { offsetRadius } = this.props;
        offsetRadius = this.clampOffsetRadius(offsetRadius);
        const presentableSlides = [];

        for (let i = -offsetRadius; i < 1 + offsetRadius; i++) {
            presentableSlides.push(slides[this.modBySlidesLength(index + i)]);
        }

        return presentableSlides;
    }

    render() {
        const { animationConfig, offsetRadius, showNavigation } = this.props;

        console.log(this.props.slides);
        let navigationButtons = null;
        if (showNavigation) {
            navigationButtons = (
                <NavigationButtons>
                    <img
                        src='/images/decks/left.svg'
                        onClick={() => this.moveSlide(-1)} alt="left"
                        style={{cursor: 'pointer'}}
                    />
                    <span>
                        {this.state.index + 1} of {this.props.slides.length}
                    </span>
                    <img
                        src='/images/decks/right.svg'
                        onClick={() => this.moveSlide(1)} alt="right"
                        style={{cursor: 'pointer'}}
                    />
                </NavigationButtons>
            );
        }

        let dots = [];
        for (let i = 0; i < this.props.slides.length; i++) {
            if (i === this.state.index) {
                dots.push(<div style={{ height: '8px', width: '30px', borderRadius: '8px', background: "linear-gradient(131.37deg, #7175E0 -8.1%, #4D50CB 45.77%, #E25BC8 155.59%)", margin: '0 5px' }} key={i} onClick={() => this.moveSlideToIndex(i)} />);
            }
            else dots.push(<div style={{ height: '8px', width: '10px', borderRadius: '8px', background: '#6852CB', margin: '0 5px' }} key={i} onClick={() => this.moveSlideToIndex(i)} />);
        }

        console.log(this.getPresentableSlides());
        return (
            <React.Fragment>
                <Wrapper style={{ width: '80%', margin: '0 auto' }}>
                    {this.getPresentableSlides().map(
                        (slide, presentableIndex) => (
                            <Slide
                                key={slide.key}
                                content={slide.content}
                                onClick={slide.onClick}
                                offsetRadius={this.clampOffsetRadius(offsetRadius)}
                                index={presentableIndex}
                                animationConfig={animationConfig}
                            />
                        )
                    )}
                </Wrapper>
                {navigationButtons}

                <div style={{ display: "flex", justifyContent: "center" }}>
                    {dots}
                </div>
            </React.Fragment>
        );
    }
}

export default Carousel;
