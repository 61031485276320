import React, { useState } from 'react'
import RemedialCard from '../RemedialCard/RemedialCard'
import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import ViewButton from '../RemedialButtons/ViewButton'
import booksSvg from './../../../../assets/remedial/svg/books.svg'
import LabelTopicProgress from '../LabelTopicProgress/LabelTopicProgress'
import RemedialDrawer from '../../../../components/common/RemedialDrawer/RemedialDrawer'
import CrossSvg from '../../../../assets/remedial/svg/CrossSvg'
const AllTopicsCard = ({ data, width = '100%', height = '100%' }) => {
    const [showDrawer, setShowDrawer] = useState(false);
    const theme = useTheme();
    return (
        <>
            <RemedialCard width={width} height={height}>
                <Stack gap={'16px'}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
                            <img src={booksSvg} width={20} height={20} alt='Questionnaire'></img>
                            <Typography component={'p'} sx={{
                                fontFamily: 'Poppins-SemiBold',
                                fontSize: '16px',
                                color: 'grey.900'
                            }}>All Topics</Typography>
                        </Stack>
                        <Box sx={{ cursor: 'pointer' }} onClick={() => { setShowDrawer(true) }}><ViewButton text='View All' /></Box>
                    </Stack>
                    {data ? <Stack gap={'2px'}>
                        {data?.slice(0, 4).map((item, index) => <LabelTopicProgress key={index} variant={'card'} value={~~(item.correct_questions_count / item.total_questions_count * 100)} label={item.name} />)}
                    </Stack> : <TopicPlaceHolder />}
                </Stack>
            </RemedialCard>
            {/* Drawer */}

            <RemedialDrawer anchor='right' show={showDrawer} onCloseHandle={() => { setShowDrawer(false) }}>
                <Stack sx={{ padding: '20px', width: '394px' }} gap={'16px'}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
                            <img src={booksSvg} width={20} height={20} alt='Questionnaire'></img>
                            <Typography component={'p'} sx={{
                                fontFamily: 'Poppins-SemiBold',
                                fontSize: '16px',
                                color: 'grey.900'
                            }}>All Topics</Typography>
                        </Stack>
                        <Box onClick={() => { setShowDrawer(false) }} sx={{
                            width: 24,
                            height: 24,
                            cursor: 'pointer',
                            svg: {
                                path: {
                                    stroke: 'primary.800'
                                }
                            }
                        }}>
             <CrossSvg />
                        </Box>
                    </Stack>
                    <Stack gap={'2px'}>
                        {data?.map((item, index) => <LabelTopicProgress key={index} variant={'drawer'} value={~~(item.correct_questions_count / item.total_questions_count * 100)} label={item.name} />)}
                    </Stack>
                </Stack>
            </RemedialDrawer>
        </>
    )
}

export default AllTopicsCard



const TopicPlaceHolder = () => {
    return (
        <Stack spacing={1}>
            <Skeleton variant="text" sx={{ fontSize: '1rem',background: theme => theme.palette.grey[200] }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem',background: theme => theme.palette.grey[200] }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem',background: theme => theme.palette.grey[200] }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem',background: theme => theme.palette.grey[200] }} />
        </Stack>
    )
}