import { Box, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import axios from "axios";
import Latex from 'react-latex-next';

const CardContainer = styled('div')(({ theme }) => ({
    width:'100%',
    height: '300px',
    overflowY: 'scroll',
    padding: '28px 20px',
    backgroundColor: theme.palette.grey[1000],
    'paragraph': {
        h3: {
            fontFamily: 'Poppins-SemiBold',
            color: theme.palette.grey[900],
            fontSize: '14px',
            marginBottom: '14px'
        },
        p: {
            fontFamily: 'Poppins-Regular',
            color: theme.palette.grey[800],
            fontSize: '14px',
            marginBottom: '10px'
        }
    }
}));

const ReadingCard = ({ data }) => {

    const [htmlData, setHtmlData] = useState('');


    const fetchData = async (data) => {
        try {
            let endpoint =data.url;
            const response = await axios.get(endpoint);
            setHtmlData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        if(data){
            fetchData(data);
        }
       
    }, [data]);
   
 
    return (
        <CardContainer>
           
            <Box sx={{
                width:'100%',
                height: '100%',
                backgroundColor: theme => theme.palette.primary.contrastText
            }}>
                <Latex>{htmlData}</Latex>
            </Box>
        </CardContainer>
    )
}

export default ReadingCard