import { GET_ALL_PERSONALITY_QUESTIONS, GET_PERSONALITY_QUESTION_AT_TRIGGER, LEAVING_PERSONALITY_PAGE, LOADING_QUESTIONS, SUBMIT_PERSONALITY_QUESTION } from "../actions/personalityAssessmentActions";

const initial_state = {
    loading_all_questions: false,
    error_loading_all_questions: false,
    loading_popover_question: false,
    error_loading_popover_question: false,
    error_submitting_question: false,
    questions: null,
    popover_question: null,
    question_submitted: false,
    submitted_question_id: null,
    submitted_question_option_id: null,
}

const reducer = (state=initial_state, action) => {
    if (action.type === LOADING_QUESTIONS) {
        return {
            ...state,
            loading_all_questions: true,
        }
    }

    if (action.type === GET_ALL_PERSONALITY_QUESTIONS) {
        if (action.error) {
            return {
                ...state, 
                error_loading_all_questions: true,
                loading_all_questions: false,
                questions: null,
            }
        }

        const done = action.response.done || [];
        const toBeDone = action.response.to_be_done || [];

        const doneArray = done.map((item, index) => ({
            ...item, 
            order: index + 1,
            is_answered: 1,
            // option_id: (item.option_id !== NaN ? item.option_id: -1),
        }));   
        const toBeDoneArray = toBeDone.map((item, index) => ({
        ...item,
        order: done.length + index + 1,
        is_answered: 0,
        option_id: -1,
        }));
        const allQuestions = [...doneArray, ...toBeDoneArray];
        return {
            ...state,
            error_loading_all_questions: false,
            loading_all_questions: false,
            questions: allQuestions,
            question_submitted: false,
            submitted_question_id: null,
            submitted_question_option_id: null,
        }
    }

    else if (action.type === GET_PERSONALITY_QUESTION_AT_TRIGGER) {
        if (action.error) {
            return {
                ...state, 
                error_loading_popover_question: true,
                loading_popover_question: false,
                popover_question: null,
            }
        }

        const question = {};
        question.question_id = action.response.id[0];
        question.name = action.response.name[0];
        question.is_answered = 0;
        question.option_id = -1;

        return {
            ...state,
            error_loading_popover_question: false,
            loading_popover_question: false,
            popover_question: question,
            question_submitted: false,
            questions: null,
        }
    }

    else if (action.type === SUBMIT_PERSONALITY_QUESTION) {
        if (action.error) {
            return {
                ...state, 
                error_submitting_question: true,
                question_submitted: false,
                submitted_question_id: null,
                submitted_question_option_id: null,
            }
        }

        return {
            ...state,
            error_submitting_question: false,
            question_submitted: true,
            submitted_question_id: action.question_id,
            submitted_question_option_id: action.option_id,
        }
    }

    else if (action.type === LEAVING_PERSONALITY_PAGE) {
        return {
            ...state,
            questions: null,
        }
    }

    return state;
}

export default reducer;