import { FETCHING_DATA, GET_CODING_QUESTION, GET_CODING_QUESTIONS_LIST, GET_CODING_QUESTION_SUBMISSION_HISTORY, GET_CODING_STATS, GET_CODING_STATUS, GET_CODING_TRACKS, SUBMIT_CODING_QUESTION, UNLOCK_SOLUTION } from "../actions/codingTrackActions";

const initial_state = {
    codingTrackStatus: {},
    codingTracksData: [],
    trackQuestions: [],
    codingStats_keys: [],
    codingStats_values: [],
    leaderboard: [],
    codingQuestionData: [],
    questionSubmissionData: {},
    questionSubmissionHistory: [],
    solution_message: '',
    solutions: [],
    error_loading_codingStatus: false,
    error_loading_codingTracks: false,
    error_loading_questionsList: false,
    error_loading_stats: false,
    error_loading_question: false,
    error_submitting_question: false,
    error_loading_solution: false,
    loading: false,
    loading_coding_tracks: false,
    loading_questions_list: false,
    loading_coding_question: false,
    loading_question_submission_history: false,
    loading_coding_status: false,
    loading_solution: false,
}

const reducer = (state = initial_state, action) => {
    if (action.type === FETCHING_DATA) {
        console.log(action.response);
        if (action.response === 'tracks') {
            return {
                ...state,
                loading_coding_tracks: true,
            }
        }
        else if (action.response === 'questions_list') {
            return {
                ...state,
                loading_questions_list: true,
            }
        }
        else if (action.response === 'coding_question') {
            console.log("Yes -- ")
            return {
                ...state,
                loading_coding_question: true,
            }
        }
        if (action.response === 'status') {
            return {
                ...state,
                loading_status: true,
            }
        }
        if (action.response === 'solution') {
            return {
                ...state,
                loading_solution: true,
            }
        }
    }

    else if (action.type === GET_CODING_TRACKS) {
        if (action.error) {
            return {
                ...state,
                error_loading_codingTracks: true,
                loading_coding_tracks: false,
            }
        }
        console.log("reducer");
        console.log(action.response);
        return {
            ...state,
            codingTracksData: action.response,
            trackQuestions: [],
            error_loading_codingTracks: false,
            loading_coding_tracks: false,
        }
    }

    else if (action.type === GET_CODING_STATUS) {
        if (action.error) {
            return {
                ...state,
                error_loading_codingStatus: true,
                loading_coding_status: false,
            }
        }
        return {
            ...state,
            codingTrackStatus: action.response,
            error_loading_codingStatus: false,
            loading_coding_status: false,
        }
    }

    else if (action.type === GET_CODING_QUESTIONS_LIST) {
        if (action.error) {
            return {
                ...state,
                error_loading_questionsList: true,
                loading_questions_list: false,
            }
        }
        return {
            ...state,
            error_loading_questionsList: false,
            trackQuestions: action.response,
            codingQuestionData: [],
            questionSubmissionData: {},
            loading_questions_list: false,
        }
    }

    else if (action.type === GET_CODING_STATS) {
        if (action.error) {
            return {
                ...state,
                error_loading_stats: true,
            }
        }
        return {
            ...state,
            error_loading_stats: false,
            codingStats_keys: action.response.stats_keys,
            codingStats_values: action.response.stats_values,
            leaderboard: action.response.leaderboard,
        }
    }

    else if (action.type === GET_CODING_QUESTION) {
        if (action.error) {
            return {
                ...state,
                error_loading_question: true,
                loading_coding_question: false,
            }
        }
        return {
            ...state,
            error_loading_question: false,
            codingQuestionData: action.response, 
            questionSubmissionData: {},
            loading_coding_question: false,
            solution_message: '',
            solutions: [],
        }
    }

    else if (action.type === SUBMIT_CODING_QUESTION) {
        if (action.error) {
            return {
                ...state,
                error_submitting_question: true,
            }
        }
        if (action.response?.submission_type === "3" && action.response?.result?.score === 100 && action.response?.solution.length > 0) {
            return {
                ...state,
                error_submitting_question: false,
                questionSubmissionData: action.response,
                solutions: action.response.solution,
                solution_message: 'success',
            }
        }
        return {
            ...state,
            error_submitting_question: false,
            questionSubmissionData: action.response, 
        }
    }

    else if (action.type === GET_CODING_QUESTION_SUBMISSION_HISTORY) {
        if (action.error) {
            return {
                ...state,
                error_loading_question_submission_history: true,
            }
        }
        return {
            ...state,
            error_loading_question_submission_history: false,
            questionSubmissionHistory: action.response, 
        }
    }

    else if (action.type === UNLOCK_SOLUTION) {
        if (action.error) {
            return {
                ...state,
                error_loading_solution: true,
                loading_solution: false,
                solution_message: '',
            }
        }
        if (action.message === 'success') {
            return {
                ...state,
                error_loading_solution: false,
                loading_solution: false,
                solutions: action.response,
                solution_message: 'success',
            }
        }
        else if (action.message === 'max reached') {
            return {
                ...state,
                error_loading_solution: false,
                loading_solution: false,
                solutions: [],
                solution_message: 'max_reached',
            }
        }
    }

    return state;
}

export default reducer;