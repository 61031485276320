import React, { useReducer, useState } from "react";
import { connect } from "react-redux";
import Flex from "styled-flex-component";
import Equation from "./Equation";
import styled from "styled-components";
import moment from "moment";
import {
  getTestAC,
  submitTestAC,
  TESTSUBMITLOAD,
  submitQuestionsFeedbackAC,
  submitQuestionAC,
} from "../store/actions/questionnaireActions";

function Assessmentstatus(props) {
  console.log("metrics re rendered");
  return (
    <>
      <Flex
        style={{
          width: "312px",
          height: "53px",
          backgroundColor: "white",
          boxShadow: "5px 10px 20px #eee",
          padding: "17px 5px",
          boxSizing: "border-box",
          borderRadius: "11px",
          marginTop: "20px",
        }}
      >
        <Flex row style={{ width: "50%", margin: "auto" }}>
          <div style={{ width: "70%", margin: "auto" }}>answered</div>
          <div
            style={{
              width: "30%",
              fontSize: "24px",
              fontWeight: "500",
              color: "lightgreen",
            }}
          >
            {props.answered}
          </div>
        </Flex>
        <Flex row style={{ width: "50%", margin: "auto" }}>
          <div style={{ width: "70%", margin: "auto" }}>remaining</div>
          <div
            style={{
              width: "30%",
              fontSize: "24px",
              fontWeight: "500",
            }}
          >
            {props.remaining_questions}
          </div>
        </Flex>
      </Flex>
      <Flex
        style={{
          width: "312px",
          height: "auto",
          backgroundColor: "white",
          boxShadow: "5px 10px 20px #eee",
          padding: "12px 5px",
          boxSizing: "border-box",
          borderRadius: "11px",
          marginTop: "20px",
        }}
      >
        <Flex column style={{ width: "50%", margin: "auto" }}>
          <Flex row>
            <div style={{ width: "70%", margin: "auto" }}>skipped</div>
            <div
              style={{
                width: "30%",
                fontSize: "24px",
                fontWeight: "500",
                color: "#A5A4A4",
              }}
            >
              {props.skipped.length}
            </div>
          </Flex>
          {props.skipped.length != 0 ? (
            <Flex row style={{ fontSize: "14px", color: "#4E54C8" }}>
              <div
                style={{ width: "70%", cursor: "pointer" }}
                onClick={(reverse) => { props.viewSkipped(reverse,props.qid+1) }}
                // onClick={props.viewSkipped}
              >
                See All
                <img
                  style={{ marginLeft: "10px" }}
                  src="/images/Right_arrow_2.svg"
                  alt="arrow"
                ></img>
              </div>
            </Flex>
          ) : null}
        </Flex>
        <Flex column style={{ width: "50%", margin: "auto" }}>
          <Flex row>
            <div style={{ width: "70%", margin: "auto" }}>marked</div>
            <div
              style={{
                width: "30%",
                fontSize: "24px",
                fontWeight: "500",
                color: "red",
              }}
            >
              {props.marked.length}
            </div>
          </Flex>
          {props.marked.length != 0 ? (
            <Flex
              row
              style={{
                fontSize: "14px",
                color: "#4E54C8",
                marginLeft: "5px",
                cursor: "pointer",
              }}
            >
              <div
               onClick={(reverse) => { props.viewMarked(reverse,props.qid+1) }}
              //  onClick={props.viewMarked} 
              style={{ width: "70%" }}>
                See All
                <img
                  style={{ marginLeft: "10px" }}
                  src="/images/Right_arrow_2.svg"
                  alt="arrow"
                ></img>
              </div>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    // questions: state.ques.questions,
    // answers: state.ques.answers,
    // test_id: state.ques.practice_id,
    // qid: state.ques.qid,
    // timelimit: state.ques.timelimit,
    // name: state.ques.name,
    // attempted: state.ques.attempted,
    // close_test: state.ques.close_test,
    // resume_test: state.ques.resume_test,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOptionSelected: (qid, option_id) =>
      dispatch({ type: "ON_OPTION_SELECTED", qid: qid, option_id: option_id }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assessmentstatus);
