import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import {getPracticeTestResultsDataA, getPracticeTestResultsDataAC} from '../../store/actions/practiceResultsActions';
import AppLayout from '../../components/Layout/Layout';
import moment from 'moment';
import Flex from 'styled-flex-component';
import styled from 'styled-components';
import Equation from '../../components/Equation';
import { BarChart, Bar, Cell, XAxis, YAxis, Scatter, ResponsiveContainer, Text, ComposedChart } from 'recharts';
import GaugeChart from 'react-gauge-chart';
import Modal from './Modal';
import axios from '../../interceptor';
import NotificationPrompt from '../../components/common/NotificationPrompt';

const Rectangle = () => (
    <svg height="220" width="320" fill='#fdfdfd'>
        <polygon points="320,10 320,160 160,220 10,160 10,10 320,10">
        </polygon>
        Sorry, your browser does not support inline SVG.
    </svg>
);

const RectangleWrapper = styled.div`
    // color: #FFFFFF;
    position: absolute;
    top: 100px;
    right: 3%;
    svg {
        // position: absolute;?
        display: block;
        // fill: #FFFFFF;
        width: 100%;
        height: 100%;
        filter: drop-shadow(5px 10px 20px #eee);
        polygon {
            stroke: #FFFFFF;
            // fill: #FFFFFF;
            stroke-width: 1px;
            transition: all 0.3s esae;
        }
        transition: all 0.3s esae;
    }
`

const GaugeContainer = styled.div`
    position: absolute;
    width: 286px;
    height: 213px;
    right: 20px;
    top: 100px;
    background: url("/images/timer/guage_container.svg");
    background-size: cover;
    padding: 20px;
    box-sizing: border-box;
`


class Results extends Component {
    state = {
        openSolutionDialog: false,
        question_solution_index: null,
        solution_data: null,
        solution_template: null

    }

    componentDidMount = async () => {
        let test_id = this.props.location.state ? this.props.location.state.test_id : null;
        // test_id = 227286;
        await this.props.getTestDetails(test_id);
    }

    openSolution = async (event, qid) => {
        document.body.style.overflow = 'hidden';
        let image_url = this.props.results.questions[qid].solution_image;
        let solution = this.props.results.questions[qid].solution;
        let response, template;
        if (image_url) {
            response = await axios.get(`${image_url}`);
            template = response.data
        }
        console.log(solution)
        console.log(template)
        this.setState({openSolutionDialog: true, question_solution_index: qid, solution_data: solution, solution_template: template});
    }
    closeSolution = () => {
        document.body.style.overflow = 'auto';

        this.setState({openSolutionDialog: false, question_solution_index: null, solution_template:null, solution_data: null});
    }
    render () {
        console.log(this.props);
        if (this.props.loading) {
            return <div>Loading....</div>
        }
        if (this.props.errorLoadingTestDetails) {
           return <div>Error loading Test Details Please try again after some time </div>
        }
        console.log(this.props);
        let option_numbers = {0: 'a', 1: 'b', 2: 'c', 3: 'd', 4: 'e'};
        let results = this.props.results;
        let timelimit = this.props.total_time_taken;
        let hours = Math.floor(timelimit / (60 * 60));
        let seconds = (timelimit - hours * 60 * 60);
        let minutes;
        if (seconds > 60) {
            minutes = Math.floor(seconds/60);
            seconds = seconds - (minutes * 60);
        }
        let percentage = Math.floor((this.props.summary.correct_answered_count * 100)/this.props.summary.total_questions_count)
        let timelimit_modified = minutes && hours && seconds ? hours + 'hrs ' + minutes + 'mins ' + seconds + 'secs' : minutes && !hours && seconds ? minutes + 'mins ' + seconds + 'secs' : minutes  && !hours && !seconds ? minutes + 'mins' : !minutes && hours && seconds ? hours + 'hrs ' + seconds + 'secs' : !minutes && hours && !seconds ? hours + 'hrs' : !minutes && !hours && seconds ? seconds + 'secs' : '0';
        // let timelimit_modified = minutes && hours ? hours + 'hrs ' + minutes + 'mins' : minutes && !hours ? minutes + 'mins' :hours + 'hrs';
        let marks = Math.round(this.props.summary.correct_answered_count ? this.props.summary.correct_answered_count : 0 / this.props.summary.total_questions_count);
        return (
            <div>
                {this.state.openSolutionDialog ? <Modal marginTop={'50px'} height={'75%'} width={'70%'} open={this.state.openSolutionDialog} closeDialog={this.closeSolution}>
                    <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '20px', marginTop: '20px'}}>Solution</div>
                    <div style={{borderBottom: '1px solid #ccc', margin: '10px 0'}}></div>
                    <div style={{margin: '10px', textAlign: 'left', fontSize: '18px', display: 'flex', flexDirection: 'row'}}>
                        <Equation fontSize={'18px'} math_type={1} equation={this.state.solution_data}></Equation>
                    </div>
                    <div style={{margin: '10px', textAlign: 'left'}}>
                        <Equation math_type={1} equation={this.state.solution_template}></Equation>
                    </div>
                </Modal> : null}
                <AppLayout></AppLayout>
                    <div style={{boxShadow: '5px 10px 20px #eee', borderRadius: '11px', margin: 'auto', marginTop: '116px', width: '90%', padding: '20px', backgroundColor: 'white'}}>

                        <div style={{borderBottom: '1px solid black', paddingBottom: '30px'}}>
                            <div style={{textAlign: 'left', fontSize: '24px', fontWeight: '600'}}>{results.name}</div>
                            <div style={{display: 'flex', flexDirection: 'row', margin: '10px 0', fontSize: '15px'}}>
                                <div style={{marginRight: '20px'}}>Objective</div>
                                <div style={{borderRight: '1px solid #000'}}></div>
                                <div style={{margin: '0 20px'}}>{moment(results.created_at).format('Do MMMM')}</div>
                                <div style={{borderRight: '1px solid #000'}}></div>
                                <div style={{margin: '0 20px'}}>{timelimit_modified}</div>
                            </div>
                            {/* <div>
                                <RectangleWrapper>
                                    <Rectangle />

                                </RectangleWrapper>
                                <div style={{position: 'absolute', right: '4.5%', top: '120px', width: '260px'}}>
                                    <img width='160px' src='/images/Group 19.svg'></img>
                                    <div style={{marginTop: '-50px', fontSize: '36px', color:'#4E54C8'}}>{this.props.summary.correct_answered_count ? this.props.summary.correct_answered_count : 0} / {this.props.summary.total_questions_count}</div>
                                    <div style={{fontSize: '14px'}}>Your marks</div>
                                    <div style={{whiteSpace: 'nowrap', marginLeft: '10px', marginTop: '10px'}}>Your average percentage is {percentage}%</div>
                                </div>
                            </div> */}
                            <GaugeContainer>
                                    <div style={{ height: 100, overflow: 'hidden' }}>

                                        <GaugeChart id="gauge-chart5"
                                            nrOfLevels={100}
                                            arcsLength={[0.1, 0.25, 0.25, 0.25, .15]}
                                            colors={['#D76D6B', '#FFA287', '#FFC200', '#23DD75', '#00D0FF']}
                                            percent={marks}
                                            textColor={'#6852CB'}
                                            arcPadding={0.01}
                                            cornerRadius={5}
                                            formatTextValue={r => (r /100) + `/${this.props.summary.total_questions_count}`}
                                            style={{ height: 150, color: '#000', fontWeight: 700 }}
                                        />
                                    </div>
                                    <p style={{ textAlign: 'center', margin: 0, fontSize: 14, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Your Marks</p>
                                    <p style={{ textAlign: 'center', margin: 0 }}> Your average percentage is {percentage}%</p>
                                </GaugeContainer>
                        </div>
                        <div style={{marginTop: '50px'}}>
                            <div style={{textAlign: 'left', fontSize: '18px', fontWeight: '600', margin: '20px 0'}}>Time Management</div>
                            <div  style={{boxShadow: '5px 10px 20px #eee', borderRadius: '11px', padding: '10px', marginTop: '20px'}}>
                                <ResponsiveContainer width="100%" height={300}>
                                    <ComposedChart
                                        data={this.props.time_analysis_data}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                    <XAxis dataKey="index" padding={{left: 50, right: 50}}/>
                                    <YAxis 
                                        label={
                                        <Text
                                           x={0}
                                           y={0}
                                           dx={30}
                                           dy={220}
                                           offset={0}
                                           angle={-90}>Time spent ( seconds )</Text>}
                                        />
                                    {/* <Tooltip /> */}
                                    {/* <Legend /> */}
                                    <Bar dataKey="time_taken" barSize={15} radius={[8,8,0,0]} stackId="a" fill="#6852CB" />
                                    {/* <Bar dataKey="average_time" barSize={15} radius={[8,8,0,0]} stackId="a" fill="#4E54C8" /> */}
                                    </ComposedChart>
                                </ResponsiveContainer>
                                <div style={{display: 'flex', flexDirection: 'row',margin: '20px 0'}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div style={{width: '10px', height: '10px', borderRadius: '50%', background: '#6852CB', margin: '5px 20px'}}></div>
                                        <div>Time Taken</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div  style={{marginTop: '40px'}}>
                            <div style={{textAlign: 'left', fontSize: '18px', fontWeight: '600', margin: '20px 0'}}>Topic wise analysis</div>
                            <div  style={{boxShadow: '5px 10px 20px #eee', borderRadius: '11px', padding: '10px', marginTop: '20px'}}>
                                <ResponsiveContainer width="100%" height={400}>
                                    <BarChart
                                        data={this.props.topic_wise_analysis_data}
                                        margin={{
                                            top: 20,
                                            right: 70,
                                            left: 20,
                                            bottom: 100,
                                        }}
                                    >
                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                    <XAxis dataKey="name" angle={35} textAnchor="start" interval={0} />
                                    <YAxis 
                                        label={
                                        <Text
                                           x={0}
                                           y={0}
                                           dx={30}
                                           dy={180}
                                           offset={0}
                                           angle={-90}>No of Questions</Text>}
                                        />
                                    {/* <Tooltip /> */}
                                    {/* <Legend /> */}
                                    <Bar dataKey="correct" barSize={15}  stackId="a" fill="#A0F4FF" />
                                    <Bar dataKey="total" barSize={15} radius={[8,8,0,0]} stackId="a" fill="#4E54C8" />
                                    </BarChart>
                                </ResponsiveContainer>
                                <div style={{display: 'flex', flexDirection: 'row',margin: '20px 0'}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div style={{width: '10px', height: '10px', borderRadius: '50%', background: '#A0F4FF', margin: '5px 20px'}}></div>
                                        <div>Correct answers</div>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div  style={{width: '10px', height: '10px', borderRadius: '50%', background: '#4E54C8',  margin: '5px 20px'}}></div>
                                        <div>Total Questions</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div style={{marginTop: '40px'}}>
                            <div style={{textAlign: 'left', fontSize: '16px', fontWeight: '600', margin: '20px 0'}}>Your answers</div>
                            {results.questions.map((que, qid)=> {
                                return <Flex row style={{marginLeft: '15px'}}>
                                    <div style={{fontWeight: 'bold', fontSize: '16px', marginRight: '8px', width: '2%'}}>
                                        {qid + 1}.
                                    </div>
                                    <Flex column style={{marginLeft: '10px', width: '98%'}}>

                                        <Flex column style={{marginBottom:'20px', textAlign: 'left'}}>
                                            <Flex row>
                                                <div style={{width: '90%'}}>
                                                    <Equation blooms={que.blooms_level} math_type={que.math_type} bold equation={que.name}></Equation>
                                                </div>
                                                {que.solution || que.solution_image ? <div style={{width: '10%', textAlign: 'right', marginRight: '20px'}}>
                                                    <div style={{color: '#4e54c8'}} onClick={(event)=>this.openSolution(event, qid)}>Solution</div>
                                                </div> : null}
                                            </Flex>
                                            {que.question_img && que.question_img != ' ' ? 
                                                <div style={{width: '324px', height: '216px', margin: '10px'}}>
                                                    <img width='324px' height='216px' src={que.question_img}></img>
                                                </div>: null}
                                        </Flex>
                                    
                                        <div>
                                        {que.test_questions_options.map(
                                            ({ id, name, media, option_img, is_answer, selected_option_id }, i) => {
                                            let is_selected = true ? selected_option_id == 1 : false;
                                            let is_correct_selected = is_selected && is_answer == 1;
                                            let is_incorrect = is_selected && is_answer == 0;
                                            let is_correct = true ? is_answer == 1 : false;
                                            return (
                                                <Flex row style ={{margin: '10px',marginBottom: '30px', alignItems:'center', color: is_selected ? '#4E54C8' : null}}> 
                                                    {is_correct_selected || is_correct ? <div style={{width: '24px', height: '24px', marginRight: '10px', marginLeft: '-34px'}}>
                                                        <img src='/images/Correct_answer.svg'></img>
                                                    </div>: null}
                                                    {is_incorrect ? <div style={{width: '24px', height: '24px', marginRight: '10px', marginLeft: '-34px'}}>
                                                        <img src='/images/Wrong_answer.svg'></img>
                                                    </div>: null}

                                                    <Flex row key={i} >
                                                        <div>{option_numbers[i]})</div>
                                                        <div>
                                                            {media === 1 ? <div style={{margin: '10px',marginTop: '0px', width: '150px', height: '100px'}}>
                                                                <img width=  '150px' height = '100px' src={option_img} alt="" />
                                                                </div> : null}
                                                        </div>
                                                        <div style = {{margin: 'auto',marginLeft: '10px'}}>
                                                            <Equation answered={is_selected} math_type={que.math_type} equation={name}></Equation>
                                                        </div>
                                                    </Flex>
                                                </Flex>)
                                            }
                                            )}
                                        </div>
                                    </Flex>
                            </Flex>
                            })}
                        </div>
                    </div>
                    <NotificationPrompt number={3}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.praRes.loading,
        summary: state.praRes.summary,
        results: state.praRes.results,
        errorLoadingTestDetails: state.praRes.errorLoadingTestDetails,
        time_analysis_data: state.praRes.time_analysis_data,
        topic_wise_analysis_data: state.praRes.topic_wise_analysis_data,
        questions: state.praRes.questions,
        total_time_taken: state.praRes.total_time_taken
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTestDetails: (test_id)=> dispatch(getPracticeTestResultsDataAC(test_id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Results));