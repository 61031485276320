import { GETCLASSROOMACTIVITY, GETFEEDBACK, LOADMORECOMMENTS, POSTCOMMENT, SUBMITFEEDBACK,JOINED_CHALLENGE } from "../actions/classroomActions"
import {GETCLASSROOMACTIVITYNEW} from "../actions/dashboardActions"
import moment from 'moment';
import { act } from "react-dom/test-utils";

const initial_state = {
    college_notifications : [
        
    ],
    enrolledweeklychallenge:false,
}

const reducer = (state = initial_state, action) =>{
    if (action.type == GETCLASSROOMACTIVITYNEW) {
        console.log(action);
        let college_notifications = action.res ? action.res.activity_wall : null;
        
        if (college_notifications) {
            // college_notifications.forEach((not)=> {
                // if (not.comments_data) {
                //     not.comments = not.comments_data;
                // }
                // console.log(not.startime)
                // console.log(not.type)
                // if (not.type == 'Subjective' && not.starttime && not.starttime != null) {
                //     // console.log(' subjective not null' , not.id)
                //     not.starttime = not.starttime
                // } else if (not.type == 'Subjective'){
                //     console.log('subjective null ', not.id)
                //     not.starttime = not.created_at
                // }

                // if (not.type == 'assignment' && not.start_date && not.start_date != null) {
                //     console.log(' assignment not null' , not.id)
                //     not.starttime = not.start_date
                // } 
                
                // else if (not.type == 'Subjective'){
                //     // console.log('subjective null ', not.id)
                //     not.starttime = not.created_at
                // }

                // if ((not.type == 'Test' || not.type =='Coding test') && not.start_time && not.start_time != null) {
                //     // console.log('not null')
                //     not.starttime = not.start_time
                // } else if(not.type == 'Test' || not.type == 'Coding Test') {
                //     not.starttime = not.created_at

                // }
            // })
            let old_college_notifications = state.college_notifications;
            let new_college_notifications = old_college_notifications.concat(college_notifications)
            return {
                ...state,
                college_notifications: new_college_notifications,
                from_date: action.res.date_lt
            } 
        } else{
            return {
                ...state
            } 
        }
    }
    if (action.type == POSTCOMMENT) {
        console.log('inside reducer');
        console.log(action);
        if(action.err) {
            return {
                ...state,
                error_posting_comment: true
            }
        } else {
            let college_notifications = state.college_notifications.map((not)=> {
                return {...not};
            });
            let item_index;
            college_notifications.findIndex((item, index)=> {
                if (action.id == item.id) {
                    item_index = index;

                }
            });
             let comments = []
            let user = JSON.parse(localStorage.getItem('user'));
            let comment_dict = {
                "college_account": null,
                "college_account_id": null,
                "comment": action.comment,
                "created_at": moment().format("DDD MMM yyyy"),
                "id": action.id,
                "student": {
                    "id": user.id,
                    "name": user.name,
                    "profile_pic": user.profile_pic
                },
                "student_id": user.id
            };

            if (comments && comments.length !==0) {
                comments.push(comment_dict);

            } else {
                comments = [];
                comments.push(comment_dict);
            }
            // college_notifications[item_index]['comments'] = comments;
            return {
                ...state,
                college_notifications: college_notifications
            }
        }
    }
    if (action.type == LOADMORECOMMENTS) {
        let id = action.id;
        let comments_data = action.res.data;
        // let college_notifications = state.college_notifications.map((not)=> {
        //     return {...not}
        // });
        // let item_index;
        // college_notifications.findIndex((item, index)=> {
        //     if (id == item.id) {
        //         item_index = index;

        //     }
        // });
        // console.log(college_notifications);
        // console.log(item_index);
        // console.log(comments_data)
        // college_notifications[item_index]['comments_data'] = comments_data;
        // college_notifications[item_index]['comments'] = comments_data;
        return{
            ...state,
            comments_data: comments_data
        }
    }
     if (action.type == JOINED_CHALLENGE) {
        let enrolledweeklychallenge=false;
       if(action.error==true){
       enrolledweeklychallenge=true;
       }
        return{
            ...state,
            enrolledweeklychallenge: enrolledweeklychallenge
        }
    }
     if (action.type == JOINED_CHALLENGE) {
        let enrolledweeklychallenge=false;
       if(action.error==true){
       enrolledweeklychallenge=true;
       }
        return{
            ...state,
            enrolledweeklychallenge: enrolledweeklychallenge
        }
    }
    if (action.type == GETFEEDBACK) {
        if (action.err) {
            return {
                ...state
            }
        }

        let feedback_questions = action.res.feedback_questions;
        let feedback_answers = {};
        // feedback_questions.forEach(que=> {
        //     feedback_answers[que.id] = '';
        // });

        console.log(action.res)
        return {
            ...state,
            start_feedback: true,
            feedback_data: action.res,
            feedback_answers: feedback_answers,
            qid: feedback_questions[0]?.id,
            qindex : 0
        }
    }
    if (action.type == 'ON_FEEDBACK_QUESTION_CLICK') {
        const qid = state.feedback_data.feedback_questions[action.qindex]?.id;
        return {
            ...state,
            qindex: action.qindex,
            qid: qid
        }
    }
    if (action.type == 'ON_FEEDBACK_OPTION_SELECTED') {
        let feedback_answers = {...state.feedback_answers};
        feedback_answers[action.qid] = action.option_id;
        return{
            ...state,
            feedback_answers: feedback_answers
        }
    }
    if (action.type == SUBMITFEEDBACK) {
        let college_notifications = [...state.college_notifications];
        if(!action.error){
            let index = college_notifications.findIndex((not, index)=> {
                return not.id === state.feedback_data?.feedback_id && not.type === 'Feedback';
            })
            college_notifications[index].submitted = 1;
        }
        return {
            ...state,
            start_feedback: false,
            feedback_error: action.error,
            feedback_data: null,
            qid: null,
            feedback_answers: null,
            college_notifications: college_notifications
        }
    }
    if ( action.type == 'CLOSE_FEEDBACK') {
        return {
            ...state,
            start_feedback: false,
            feedback_data: null,
            qid: null,
            feedback_answers: null
        }
    }

    if (action.type == 'CLEAR_STATE') {
        return {
            college_notifications: []
        }
    }

    return {...state}
}

export default reducer;

