import axios from '../../interceptor';
import * as Sentry from "@sentry/react";
import { getLoggedInUser } from "../../helpers/Auth";
import moment from "moment";
import {history} from '../../utils'
let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
const environment = process.env.REACT_APP_ENVIRONEMNT;
const plain_error_obj = {
    api_name: null,
    status: null,
    api_status: null,
    user_id: getLoggedInUser()?.user_id,
    timestamp: null,
  };
export function setGlobalToken() {
    try {
        let token = localStorage.getItem('token');
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    } catch (error) {
      console.log(error);
    }
};

export const GETCOURSECONTENT = 'GET_COURSE_CONTENT';
export const GETCOURSEDETAILS = 'GET_COURSE_DETAILS';
export const ADDORDELETEBOOKMARK = 'ADD_OR_DELETE_BOOKMARK';
export const ONUNITCHANGE = 'ON_UNIT_CHANGE';
export const SUBJECTANALYSIS = 'SUBJECT_ANALYSIS';
export const SEMESTERSUBJECTANALYSIS = 'SEMESTER_SUBJECT_ANALYSIS';
export const TREEMAPDATA='TREE_MAP_DATA';
export const ALLSECTIONSGETPERMORMANCESEVENDAYSSUBDATA = "ALL_SECTIONS_GET_PERMORMANCE_SEVEN_DAYS_SUB_DATA";
export const ALLSECTIONSGETPERMORMANCETHIRTYDAYSSUBDATA = "ALL_SECTIONS_GET_PERMORMANCE_THIRTY_DAYS_SUB_DATA";
export const ALLSECTIONSGETPARTICIPATIONSEVENDAYSSUBDATA = "ALL_SECTIONS_GET_PARTICIPATION_SEVEN_DAYS_SUB_DATA";
export const ALLSECTIONSGETPARTICIPATIONTHIRTYDAYSSUBDATA = "ALL_SECTIONS_GET_PARTICIPATION_THIRTY_DAYS_SUB_DATA";
export const FEEDBACKDATA="FEEDBACK_DATA";
export const getCourseContentA = (res, err) => {
    return {
        type: GETCOURSECONTENT,
        response: res,
        error: err
    }
}
export const getAnalysisConentA = (res, err) => {
    return {
        type: SUBJECTANALYSIS,
        response: res,
        error: err
    }
}
export const getAnalysisSemesterConentA = (res, err) => {
    return {
        type: SEMESTERSUBJECTANALYSIS,
        response: res,
        error: err
    }
}

export const getreemapDataA=(res, err)=>{
    return {
        type: TREEMAPDATA,
        response: res,
        error: err
    }
}
export const getfeedbackquestionsdata=(res, err)=>{
  return {
      type: FEEDBACKDATA,
      response: res,
      error: err
  }
}
export const getAllsectionsubjectLeaderboardAc = (res,activitydays,type,err) => {
    if (activitydays=="7" && type=="Performance") {
      return {
        type: ALLSECTIONSGETPERMORMANCESEVENDAYSSUBDATA,
        res: res,
        error: err,
      };
    }else if (activitydays=="30" && type=="Performance") {
      return {
        type: ALLSECTIONSGETPERMORMANCETHIRTYDAYSSUBDATA,
        res: res,
        error: err,
      };
    }else if (activitydays=="7" && type=="Participation") {
      return {
        type: ALLSECTIONSGETPARTICIPATIONSEVENDAYSSUBDATA,
        res: res,
        error: err,
      };
    }else if (activitydays=="30" && type=="Participation") {
      return {
        type: ALLSECTIONSGETPARTICIPATIONTHIRTYDAYSSUBDATA,
        res: res,
        error: err,
      };
    }
   
  };
  

export const getCourseContentAC = (csm_Id) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/v2/getCourseUnitDetails?subject_semester_id=${getState().cc.subject_semester_id}&unit_id=${getState().cc.unit_id}&college_subject_mapping_id=${csm_Id}`)
        .then( async (res)=> {
            if (res.status == 200) {
                if (res.data.status == 200) {
                    dispatch(getCourseContentA(res.data, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();

                        http.get(`${BASE_API_URL}/v2/getCourseUnitDetails?subject_semester_id=${getState().cc.subject_semester_id}&unit_id=${getState().cc.unit_id}&college_subject_mapping_id=${csm_Id}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    dispatch(getCourseContentA(res.data, false));
                                } else {
                                    dispatch(getCourseContentA(null, true));
                                }
                            } else {
                                dispatch(getCourseContentA(null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(getCourseContentA(null, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    })
                    
                } else {
                    dispatch(getCourseContentA(null, true));
                }
            } else {
                dispatch(getCourseContentA(null, true));
            }
        })
        .catch((err)=> {
            console.log('error in getting course content')
            console.log(err)
            dispatch(getCourseContentA(null, true));
        });
    };
};

export const getCourseDetailsA = (res,subject_id, user,csm_Id,err) => {
    return {
        type: GETCOURSEDETAILS,
        response: res,
        subject_id: subject_id,
        user: user,
        csm_Id:csm_Id,
        error: err
    }
}


export const getCourseDetailsAC = (subject_id,csm_Id) => {
  if(!csm_Id){
    localStorage.clear();
    history.push('/')
    window.location.reload()
    return;
  }
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        let user = JSON.parse(localStorage.getItem('user'));
        let udd = user.university_degree_department_id;
        http.get(`${BASE_API_URL}/v2/getCourseDetails?subject_id=${subject_id}&university_degree_department_id=${udd}&college_subject_mapping_id=${csm_Id}`)
        .then((res)=> {
            if (res.status == 200) {
                if (res.data.status == 200) {
                    dispatch(getCourseDetailsA(res.data,subject_id,user,csm_Id, false));
                    dispatch(getCourseContentAC(res.data.data.college_subject_mapping_id));
                    dispatch(getfeedbackquestionsAC());
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/v2/getCourseDetails?subject_id=${subject_id}&university_degree_department_id=${udd}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    dispatch(getCourseDetailsA(res.data,subject_id,user,csm_Id, false));
                                    dispatch(getCourseContentAC(res.data.data.college_subject_mapping_id));
                                    dispatch(getfeedbackquestionsAC());
                                } else {
                                    dispatch(getCourseDetailsA(null,subject_id,user,csm_Id, true))
                                }
                            } else {
                                dispatch(getCourseDetailsA(null,subject_id,user,csm_Id, true))
                            }
                        })
                        .catch((err)=>{
                            dispatch(getCourseDetailsA(null,subject_id,user,csm_Id, true))
                        })
                    })
                    .catch ((err)=> {
                        console.log(err);
                    })
                } else {
                    dispatch(getCourseDetailsA(null,subject_id,user,csm_Id, true))
                }
            } else {
                dispatch(getCourseDetailsA(null,subject_id,user,csm_Id, true))
            }
            
        })
        .catch((err)=> {
            console.log('error in getting course details')
            console.log(err)
            dispatch(getCourseDetailsA(null,subject_id,user,csm_Id, true))
        });
    };
};

export const addOrDeleteBookmarkAC = (topic_id, learning_content_id, type) => {
    console.log(topic_id, learning_content_id, type)
    return (dispatch, getState)=> {
        let content;
        if ( type == 'decks') {
            let decks = getState().cc.decks;

            let deck_index = decks.findIndex((deck)=> {
                return deck.id == learning_content_id
            })
            content = decks[deck_index];

        } else {
            let topics = getState().cc.content;
            let topic_index = topics.findIndex((topic)=>{
                return topic.id == topic_id
            });
    
            console.log(topic_index)
    
            let content_index = topics[topic_index].learning_content.findIndex((c)=> {
                return c.material_id == learning_content_id
            })
            content = topics[topic_index].learning_content[content_index];
        }
        

        let bookmarked = 0
        if (content.bookmarked == 0) {
            bookmarked = 1;
            const formdata = new FormData();
            formdata.append('id', learning_content_id);
            formdata.append('type', type);

            http.post(`${BASE_API_URL}/v2/addBookmark`, formdata)
            .then((res)=>{
                if (res.status == 200) {
                    if (res.data.status == 200) {
                        return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id,bookmarked,type, false))
                    } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                        let refresh_token = localStorage.getItem('refresh_token');
                        http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                        http.get(`${BASE_API_URL}/auth/refresh`)
                        .then((refres)=> {
                            localStorage.setItem('token', refres.data.token);
                            setGlobalToken();
                            http.post(`${BASE_API_URL}/v2/addBookmark`, formdata)
                            .then((res)=> {
                                if (res.status == 200) {
                                    if (res.data.status == 200 ) {
                                        return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id,bookmarked,type, false))
                                    } else {
                                        return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id, bookmarked,type, true ))
                                    }
                                } else {
                                    return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id, bookmarked,type, true ))
                                }
                            })
                            .catch((err)=>{
                                return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id, bookmarked,type, true ))
                            })
                        })
                        .catch((err)=> {
                            console.log(err);
                        });
                    } else {
                        return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id, bookmarked,type,  true ))
                    }
                } else {
                    return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id, bookmarked, type, true ))
                }
            }).catch((error)=>{
                return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id, bookmarked, type,true ))
            })

        } else {
            bookmarked = 0;
            const formdata = new FormData();
            formdata.append('id', learning_content_id);
            formdata.append('type', type);
            http.post(`${BASE_API_URL}/v2/deleteBookmark`, formdata)
            .then((res)=>{
                if (res.status == 200) {
                    if (res.data.status == 200) {
                        return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id, bookmarked,type, false))
                    } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                        let refresh_token = localStorage.getItem('refresh_token');
                        http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                        http.get(`${BASE_API_URL}/auth/refresh`)
                        .then((refres)=> {
                            localStorage.setItem('token', refres.data.token);
                            setGlobalToken();
                            http.post(`${BASE_API_URL}/v2/deleteBookmark`, formdata)
                            .then((res)=> {
                                if (res.status == 200) {
                                    if (res.data.status == 200 ) {
                                        return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id, bookmarked,type, false))
                                    } else {
                                        return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id, bookmarked,type, true ))
                                    }
                                } else {
                                    return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id, bookmarked,type, true ))
                                }
                            })
                            .catch((err)=>{
                                return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id, bookmarked,type, true ))
                            })
                        })
                        .catch((err)=> {
                            console.log(err);
                        });
                    } else {
                        return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id, bookmarked, type, true ))
                    }
                } else {
                    return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id, bookmarked,type, true ))
                }
            }).catch((error)=>{
                return dispatch(addOrDeleteBookmarkA(topic_id, learning_content_id, bookmarked,type, true ))
            })
        }
    }
}

const addOrDeleteBookmarkA = (topic_id, learning_content_id,bookmarked,type, err)=>{
    return {
        type: ADDORDELETEBOOKMARK,
        topic_id: topic_id,
        learning_content_id: learning_content_id,
        bookmark_type: type,
        bookmarked: bookmarked,
        err: err
    }
}

export const onUnitChangeA = (unit_id, res, err) => {
    return {
        type: ONUNITCHANGE,
        unit_id: unit_id,
        response: res,
        error: err
    }
}


export const onUnitChangeAC = (unit_id,csm_Id) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        let current_unit_id = getState().cc.unit_id;
        if (current_unit_id == unit_id) {
            return dispatch(onUnitChangeA(unit_id, null, false))
        }
        http.get(`${BASE_API_URL}/v2/getCourseUnitDetails?subject_semester_id=${getState().cc.subject_semester_id}&unit_id=${unit_id}&college_subject_mapping_id=${csm_Id}`)
        .then((res)=> {
            if (res.status == 200) {
                if (res.data.status == 200) {
                    dispatch(onUnitChangeA(unit_id,res.data, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/v2/getCourseUnitDetails?subject_semester_id=${getState().cc.subject_semester_id}&unit_id=${unit_id}&college_subject_mapping_id=${csm_Id}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    dispatch(onUnitChangeA(unit_id,res.data, false));
                                } else {
                                    dispatch(onUnitChangeA(unit_id, null, true));
                                }
                            } else {
                                dispatch(onUnitChangeA(unit_id, null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(onUnitChangeA(unit_id, null, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    });
                } else {
                    dispatch(onUnitChangeA(unit_id, null, true));
                }
            } else {
                dispatch(onUnitChangeA(unit_id, null, true));
            }
        })
        .catch((err)=> {
            console.log('error in getting course content')
            console.log(err)
            dispatch(onUnitChangeA(unit_id, null, true))
        });
    };
};


export const GETDECKDATA = 'GET_DECK_DATA';
export const OPENDECKDATA= 'OPEN_DECK_DATA';
export const CLOSEDECK = 'CLOSE_DECK';

export const getDeckDataA = (res,deck, err) => {
    return {
        type: GETDECKDATA,
        res: res,
        deck: deck,
        error: err
    }
}

export const getDeckDataAC = (deck_code, deck) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/v2/getDeckData?deck_code=${deck_code}`)
        .then((res)=> {
            if (res.status == 200 ) {
                if (res.data.status == 200 ) {
                    dispatch(getDeckDataA(res.data, deck, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/v2/getDeckData?deck_code=${deck_code}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    dispatch(getDeckDataA(null, deck, true));
                                } else {
                                    dispatch(getDeckDataA(null,deck,  true));
                                }
                            } else {
                                dispatch(getDeckDataA(null,deck, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(getDeckDataA(null,deck, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    });
                } else {
                    dispatch(getDeckDataA(null,deck, true));
                }
            } else {
                console.log('in error dispatch 3');
                dispatch(getDeckDataA(null,deck, true));

            }
            
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(getDeckDataA(null, true));
        });
    };
};


export const getAnalysisConent = (subject_id) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        http.get(`https://535kzzewl0.execute-api.ap-south-1.amazonaws.com/${environment}/student_subjectanalytics?activity_days=7&subject_semester_id=${subject_id}`)
        .then((res)=> {
            if (res.status == 200 ) {
                if (res.data.status == 200 ) {
                    dispatch(getAnalysisConentA(res.data, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`https://535kzzewl0.execute-api.ap-south-1.amazonaws.com/${environment}/student_subjectanalytics?activity_days=7&subject_semester_id=${subject_id}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    dispatch(getAnalysisConentA(res.data, false));
                                } else {
                                    dispatch(getAnalysisConentA(null, true));
                                }
                            } else {
                                dispatch(getAnalysisConentA(null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(getAnalysisConentA(null, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    });
                } else {
                    dispatch(getAnalysisConentA(null, true));
                }
            } else {
                console.log('in error dispatch 3');
                dispatch(getAnalysisConentA(null, true));

            }
            
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(getAnalysisConentA(null, true));
        });
    };
};

export const getAnalysisSemesterConentAc = (subject_id) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        http.get(`https://535kzzewl0.execute-api.ap-south-1.amazonaws.com/${environment}/student_subjectanalytics?activity_days=&subject_semester_id=${subject_id}`)
        .then((res)=> {
            if (res.status == 200 ) {
                if (res.data.status == 200 ) {
                    dispatch(getAnalysisSemesterConentA(res.data, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`https://535kzzewl0.execute-api.ap-south-1.amazonaws.com/${environment}/student_subjectanalytics?activity_days=&subject_semester_id=${getState().cc.subject_semester_id}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    dispatch(getAnalysisSemesterConentA(res.data, false));
                                } else {
                                    dispatch(getAnalysisSemesterConentA(null, true));
                                }
                            } else {
                                dispatch(getAnalysisSemesterConentA(null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(getAnalysisSemesterConentA(null, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    });
                } else {
                    dispatch(getAnalysisSemesterConentA(null, true));
                }
            } else {
                console.log('in error dispatch 3');
                dispatch(getAnalysisSemesterConentA(null, true));

            }
            
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(getAnalysisConentA(null, true));
        });
    };
};


export const getreemapDataAC = (subject_id) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/analytics/getTopicsAnalysis?subject_semester=${subject_id}`)
        .then((res)=> {
            if (res.status == 200 ) {
                if (res.data.status == 200 ) {
                    dispatch(getreemapDataA(res.data, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/analytics/getTopicsAnalysis?subject_semester=${subject_id}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    dispatch(getreemapDataA(res.data, false));
                                } else {
                                    dispatch(getreemapDataA(null, true));
                                }
                            } else {
                                dispatch(getreemapDataA(null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(getreemapDataA(null, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    });
                } else {
                    dispatch(getreemapDataA(null, true));
                }
            } else {
                console.log('in error dispatch 3');
                dispatch(getreemapDataA(null, true));

            }
            
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(getreemapDataA(null, true));
        });
    };
};

export const getAllsectionsubjectLeaderboard = (activity_days,type,subject_id) => {
    console.log("in action creator");
    setGlobalToken();
    return (dispatch, getState) => {
      const error_obj = { ...plain_error_obj };
      error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
      error_obj.api_name = `/lambda/student_alldashboardleaderboard`;
      error_obj.payload = {
        activity_days:"all",
      };
      http
        .get(
        `https://b890i9n4b8.execute-api.ap-south-1.amazonaws.com/${environment}/student_subjectallleaderboard?activity_days=${activity_days}&leaderboard_type=${type}&subject_semester_id=${subject_id}`
        )
        .then((res) => {
          error_obj.status = res.status;
          error_obj.api_status = res.data?.status;
          if (res.status == 200) {
            if (res.data.status == 200) {
              dispatch(getAllsectionsubjectLeaderboardAc(res.data,activity_days,type, false));
            } else if (
              res.data.status == 401 &&
              res.data.message == "Signature expired. Please log in again."
            ) {
              let refresh_token = localStorage.getItem("refresh_token");
              http.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${refresh_token}`;
              http
                .get(`${BASE_API_URL}/auth/refresh`)
                .then((refres) => {
                  localStorage.setItem("token", refres.data.token);
                  setGlobalToken();
  
                  http
                    .get(
                      `https://b890i9n4b8.execute-api.ap-south-1.amazonaws.com/${environment}/student_subjectallleaderboard?activity_days=${activity_days}&leaderboard_type=${type}&subject_semester_id=${subject_id}`
                    )
                    .then((res) => {
                      if (res.status == 200) {
                        if (res.data.status == 200) {
                          dispatch(getAllsectionsubjectLeaderboardAc(res.data,activity_days,type, false));
                        } else {
                          Sentry.captureException(error_obj, (scope) => {
                            scope.setTransactionName(error_obj.api_name);
                            return scope;
                          });
                          dispatch(getAllsectionsubjectLeaderboardAc(null,activity_days,type,true));
                        }
                      } else {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        dispatch(getAllsectionsubjectLeaderboardAc(null,activity_days,type,true));
                      }
                    })
                    .catch((err) => {
                      error_obj.status = err?.request?.status;
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                      dispatch(getAllsectionsubjectLeaderboardAc(null,activity_days,type,true));
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              Sentry.captureException(error_obj, (scope) => {
                scope.setTransactionName(error_obj.api_name);
                return scope;
              });
              dispatch(getAllsectionsubjectLeaderboardAc(null,activity_days,type,true));
            }
          } else {
            console.log("in error dispatch 3");
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(getAllsectionsubjectLeaderboardAc(null,activity_days,type,true));
          }
        })
        .catch((err) => {
          console.log(err);
          error_obj.status = err?.request?.status;
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
          dispatch(getAllsectionsubjectLeaderboardAc(null,activity_days,type,true));
        });
    };
  }

  export const getfeedbackquestionsAC = (res) => {
    console.log("in action creator");
    setGlobalToken();
    return (dispatch, getState) => {
      const error_obj = { ...plain_error_obj };
      error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
      error_obj.api_name = `/faculty_feedback/getFacultyFeedbacks`;
      error_obj.payload = {
        activity_days:"all",
      };
      http
        .get(
        `${BASE_API_URL}/faculty_feedback/getFacultyFeedbacks?unit_id=${getState().cc.unit_id}`
        )
        .then((res) => {
          error_obj.status = res.status;
          error_obj.api_status = res.data?.status;
          if (res.status == 200) {
            if (res.data.status == 200) {
              dispatch(getfeedbackquestionsdata(res.data.data, false));
            } else if (
              res.data.status == 401 &&
              res.data.message == "Signature expired. Please log in again."
            ) {
              let refresh_token = localStorage.getItem("refresh_token");
              http.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${refresh_token}`;
              http
                .get(`${BASE_API_URL}/auth/refresh`)
                .then((refres) => {
                  localStorage.setItem("token", refres.data.token);
                  setGlobalToken();
  
                  http
                    .get(
                      `${BASE_API_URL}/faculty_feedback/getFacultyFeedbacks?unit_id=${getState().cc.unit_id}`
                    )
                    .then((res) => {
                      if (res.status == 200) {
                        if (res.data.status == 200) {
                          dispatch(getfeedbackquestionsdata(res.data.data, false));
                        } else {
                          Sentry.captureException(error_obj, (scope) => {
                            scope.setTransactionName(error_obj.api_name);
                            return scope;
                          });
                          dispatch(getfeedbackquestionsdata(null,true));
                        }
                      } else {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        dispatch(getfeedbackquestionsdata(null,true));
                      }
                    })
                    .catch((err) => {
                      error_obj.status = err?.request?.status;
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                      dispatch(getfeedbackquestionsdata(null,true));
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              Sentry.captureException(error_obj, (scope) => {
                scope.setTransactionName(error_obj.api_name);
                return scope;
              });
              dispatch(getfeedbackquestionsdata(null,true));
            }
          } else {
            console.log("in error dispatch 3");
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(getfeedbackquestionsdata(null,true));
          }
        })
        .catch((err) => {
          console.log(err);
          error_obj.status = err?.request?.status;
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
          dispatch(getfeedbackquestionsdata(null,true));
        });
    };
  }

  export const getfeedbackquestions = (unit_id) => {
    console.log("in action creator");
    setGlobalToken();
    return (dispatch, getState) => {
      const error_obj = { ...plain_error_obj };
      error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
      error_obj.api_name = `/faculty_feedback/getFacultyFeedbacks`;
      error_obj.payload = {
        activity_days:"all",
      };
      http
        .get(
        `${BASE_API_URL}/faculty_feedback/getFacultyFeedbacks?unit_id=${unit_id}`
        )
        .then((res) => {
          error_obj.status = res.status;
          error_obj.api_status = res.data?.status;
          if (res.status == 200) {
            if (res.data.status == 200) {
              dispatch(getfeedbackquestionsdata(res.data.data, false));
            } else if (
              res.data.status == 401 &&
              res.data.message == "Signature expired. Please log in again."
            ) {
              let refresh_token = localStorage.getItem("refresh_token");
              http.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${refresh_token}`;
              http
                .get(`${BASE_API_URL}/auth/refresh`)
                .then((refres) => {
                  localStorage.setItem("token", refres.data.token);
                  setGlobalToken();
  
                  http
                    .get(
                      `${BASE_API_URL}/faculty_feedback/getFacultyFeedbacks?unit_id=${unit_id}`
                    )
                    .then((res) => {
                      if (res.status == 200) {
                        if (res.data.status == 200) {
                          dispatch(getfeedbackquestionsdata(res.data.data, false));
                        } else {
                          Sentry.captureException(error_obj, (scope) => {
                            scope.setTransactionName(error_obj.api_name);
                            return scope;
                          });
                          dispatch(getfeedbackquestionsdata(null,true));
                        }
                      } else {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        dispatch(getfeedbackquestionsdata(null,true));
                      }
                    })
                    .catch((err) => {
                      error_obj.status = err?.request?.status;
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                      dispatch(getfeedbackquestionsdata(null,true));
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              Sentry.captureException(error_obj, (scope) => {
                scope.setTransactionName(error_obj.api_name);
                return scope;
              });
              dispatch(getfeedbackquestionsdata(null,true));
            }
          } else {
            console.log("in error dispatch 3");
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(getfeedbackquestionsdata(null,true));
          }
        })
        .catch((err) => {
          console.log(err);
          error_obj.status = err?.request?.status;
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
          dispatch(getfeedbackquestionsdata(null,true));
        });
    };
  }


  export const submitfeedbackresponse = (feedback_id,question_id,answer) => {
    console.log("in action creator");
    setGlobalToken();
    return (dispatch, getState) => {
      const error_obj = { ...plain_error_obj };
      error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
      error_obj.api_name = `/faculty_feedback/submitFacultyPerformanceFeedbacks`;
      error_obj.payload = {
        activity_days:"all",
      };
      http
        .get(
        `${BASE_API_URL}/faculty_feedback/submitFacultyPerformanceFeedback?feedback_id=${feedback_id}&question_id=${question_id}&answer=${answer}`
        )
        .then((res) => {
          error_obj.status = res.status;
          error_obj.api_status = res.data?.status;
          if (res.status == 200) {
            if (res.data.status == 200) {
            //   dispatch(submitfeedbackresponse(res.data.data, false));
            } else if (
              res.data.status == 401 &&
              res.data.message == "Signature expired. Please log in again."
            ) {
              let refresh_token = localStorage.getItem("refresh_token");
              http.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${refresh_token}`;
              http
                .get(`${BASE_API_URL}/auth/refresh`)
                .then((refres) => {
                  localStorage.setItem("token", refres.data.token);
                  setGlobalToken();
  
                  http
                    .get(
                      `${BASE_API_URL}/faculty_feedback/submitFacultyPerformanceFeedback?feedback_id=${feedback_id}&question_id=${question_id}&answer=${answer}`
                    )
                    .then((res) => {
                      if (res.status == 200) {
                        if (res.data.status == 200) {
                        //   dispatch(getfeedbackquestionsdata(res.data.data, false));
                        } else {
                          Sentry.captureException(error_obj, (scope) => {
                            scope.setTransactionName(error_obj.api_name);
                            return scope;
                          });
                        //   dispatch(getfeedbackquestionsdata(null,true));
                        }
                      } else {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        // dispatch(getfeedbackquestionsdata(null,true));
                      }
                    })
                    .catch((err) => {
                      error_obj.status = err?.request?.status;
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                    //   dispatch(getfeedbackquestionsdata(null,true));
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              Sentry.captureException(error_obj, (scope) => {
                scope.setTransactionName(error_obj.api_name);
                return scope;
              });
            //   dispatch(getfeedbackquestionsdata(null,true));
            }
          } else {
            console.log("in error dispatch 3");
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            // dispatch(getfeedbackquestionsdata(null,true));
          }
        })
        .catch((err) => {
          console.log(err);
          error_obj.status = err?.request?.status;
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
        //   dispatch(getfeedbackquestionsdata(null,true));
        });
    };
  }