import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import MeterSvg from './../../../../assets/remedial/svg/testmeter.svg';
import CongratsSvg from './../../../../assets/remedial/svg/congrats.svg';
import RemedialCard from '../RemedialCard/RemedialCard';
import RedmedialButton from '../RemedialButtons/RedmedialButton';
import { useRemedialContext } from '../../hooks/useRemedial';
import perfectGif from './../../../../assets/remedial/gif/perfect.gif'
import { AnimatedNumber } from '../../../../utils';
import CircularProgressGrade from '../../../../components/common/CircularProgressGrade/CircularProgressGrade';

const ScoreCard = ({ variant = 'remedial', data, width = '100%', height = '100%', showButton = true, onClick }) => {
	const theme = useTheme()
	const { timer } = useRemedialContext();

	const calculateMarks = (data) => (data?.length > 0) ? data.reduce((acc, section) => acc + (section.correct_answered_count * section.marks), 0) : 0;

	  
	return (
		<>
			<RemedialCard width={width} height={height}>
				<Stack sx={{ gap: variant === 'remedial' ? '16px' : '24px' }}>
					<Stack direction={'row'} gap={'8px'}>
						{variant === 'completed' ? <img src={CongratsSvg} alt="meter" width={20} height={20} /> : <img src={MeterSvg} alt="meter" width={20} height={20} />}
						<Typography
							component={'h4'}
							sx={{
								fontFamily: 'Poppins-SemiBold',
								fontSize: '16px',
								color: 'grey.900'
							}}>
							{variant === 'completed' ? 'New Score' : 'Your Score'}
						</Typography>
					</Stack>

					{(variant === 'remedial' || variant === 'completed') && <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'24px'}>
						<Typography
							component={'h4'}
							sx={{
								fontFamily: 'Poppins-SemiBold',
								fontSize: '22px',
								color: 'externalerror.200'
							}}>
								{/* <AnimatedNumber n={Number(data?.percentage)} />/100 */}
								{<AnimatedNumber n={calculateMarks(data?.sections)} />} / {data?.total_questions_count}
						</Typography>

						<CircularProgressGrade size={data?.total_questions_count} value={calculateMarks(data?.sections)} grade={data?.grade_achieved} />
					</Stack>}

					{variant === 'remedial' && <Typography
						component={'h4'}
						sx={{
							fontFamily: 'Poppins-Medium',
							fontSize: '12px',
							color: 'grey.600',
							textAlign: 'start',
							span: {
								fontFamily: 'Poppins-SemiBold',
								fontSize: '12px',
								color: 'grey.800'
							}
						}}>
						<span>Improve</span> your test score by <span>{3} marks/points</span> by taking a small remedial test.(Maximum marks you can earn is {data?.total_questions_count})
					</Typography>}

					{variant === 'completed' &&
						<Stack gap={'8px'}>
							<Typography
								component={'h4'}
								sx={{
									fontFamily: 'Poppins-Medium',
									fontSize: '12px',
									color: 'grey.600',
									textAlign: 'center',
									span: {
										fontFamily: 'Poppins-SemiBold',
										fontSize: '12px',
										color: 'grey.800'
									}
								}}>
								<span>Congratulations!</span> <br />
								You have improved your score by {data?.test_details?.remedial_bonus_points} points by taking the remedial path!
							</Typography>
							{variant === 'completed' && <Stack direction={'row'} display={'flex'} alignItems={'center'} justifyContent={'center'} gap={'5px'} >
								<Typography
									component={'h4'}
									sx={{
										fontFamily: 'Poppins-Medium',
										fontSize: '12px',
										color: theme => theme.palette.primary[800]
									}}>
									View Result
								</Typography>
								<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
									<path d="M9.99093 12.0679L14.0376 8.02128L9.99093 3.97461" stroke={theme.palette.primary[800]} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
									<path d="M2.70432 8.02148L13.9243 8.02148" stroke={theme.palette.primary[800]} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</Stack>
							}

						</Stack>}

					{variant === 'perfect' && <Stack alignItems={'center'} justifyContent={'center'}>
						<img src={perfectGif} alt="perfectGif" width={100} height={100} />
					</Stack>}


					{variant === 'perfect' && <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'24px'}>
						<Typography
							component={'h4'}
							sx={{
								fontFamily: 'Poppins-SemiBold',
								fontSize: '22px',
								color: 'externalerror.200'
							}}>
							100/100
						</Typography>

						<CircularProgressGrade size={100} value={100} grade="A+" />
					</Stack>}

					{variant === 'perfect' && <Typography
						component={'h4'}
						sx={{
							fontFamily: 'Poppins-Medium',
							fontSize: '12px',
							color: 'grey.600',
							textAlign: 'start',
							span: {
								fontFamily: 'Poppins-SemiBold',
								fontSize: '12px',
								color: 'grey.800'
							}
						}}>
						<span>Yay! You have scored full marks. You are topping the chart! </span>
					</Typography>}
					{showButton && variant !== 'completed' && <RedmedialButton  color="externalerror.200" onClick={onClick}>Start Remedial Process</RedmedialButton>}
				</Stack>
			</RemedialCard>
		</>
	);
};

export default ScoreCard;


// () => updateBannerIndex(1)