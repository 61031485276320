import React from 'react';
import {styled} from '@mui/material/styles';
import { Box, Typography, List, ListItem, Grid, Skeleton } from '@mui/material';
import { useEffect } from 'react';
import colors2 from '../../pages/codingscreen/colors2';

const CodingStats = (props) => {
    useEffect(() => {
        console.log(props.stats_keys);
    }, []);
    
    return (
        <>
            <Box
                sx={{
                    width: '90%',
                    height: 'auto',
                    minHeight: '230px',
                    boxShadow: '4px 4px 15px rgba(0, 0, 0, 0.05)',
                    borderRadius: '10px',
                    margin: 'auto',
                    textAlign: 'left'
                }}
            >
                <Box sx={{padding: '15px 20px', marginBottom: '20px', background: colors2.grey[0], borderRadius: '10px 10px 0px 0px'}}>
                    <Typography variant="body4" sx={{textAlign: 'left'}}>Overall statistics</Typography>
                </Box>
                {props.loading ? 
                    <Grid container justifyContent="space-evenly" alignItems="center" spacing={2} sx={{padding: '0px 10px'}}>
                        <Grid item xs={4}>
                            <Skeleton variant='rounded' height={'55px'} />
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton variant='rounded' height={'55px'} />
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton variant='rounded' height={'55px'} />
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton variant='rounded' height={'55px'} />
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton variant='rounded' height={'55px'} />
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton variant='rounded' height={'55px'} />
                        </Grid>
                    </Grid>
                :
                    <Grid container justifyContent="space-evenly" alignItems="center">
                        {props.stats_keys.map((stat, i) => (
                            <Grid item xs={4} key={i}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        margin: '10px',
                                        height: 'auto',
                                        // minHeight: '65px',
                                        padding: '5px 10px',
                                        borderRadius: '5px',
                                        background: colors2.grey[100],
                                        overflowX: 'hidden'
                                    }}
                                >
                                    <Typography variant='body5' textAlign="center">{stat === 'Rank' && props.stats_values[i] === 0 ? 'N/A' : props.stats_values[i]}</Typography>
                                    <Typography variant='caption2' textAlign="center">{stat}</Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                }
            </Box>    
        </>
    )
}

export default CodingStats;