import React, {Component} from 'react';
import AppLayout from '../../components/Layout/Layout';

class CodeEditor extends Component {
    state = {
        loading: true,
        submissions: []
    }

    componentDidMount = async ()=>{
        // console.log(window.SEC);
        // var SEC = window.SEC;
        // SEC.ready(function() {
        //     var afterSendSubmission = function(data) {
        //       localStorage.setItem("submission_id", data.apiSubmissionId);
        //         console.log(data);
        //     };
        //     var SECWidget = SEC.widget("sec-widget");
        //     SECWidget.events.subscribe('checkStatus', afterSendSubmission);
        // });
        // var SECWidget = SEC.widget("sec-widget");
        // SEC.ready(function() {
        //     var afterSendSubmission = function(data) {
        //         console.log(data);
        //         let submissions = this.state.submissions;
        //         submissions.push(data.submissionId)
        //         this.setState({submissions: submissions})
        //     };
        //     SECWidget.events.subscribe('afterSendSubmission', afterSendSubmission);
        // });
        // let options = {
        //     mode: 'cors'
        // }
        // let response = await axios.get('https://d39f76b4.compilers.sphere-engine.com/api/v4/submissions?access_token=ffc855ea5a607081f9df8ca0cbf93e99', options);
        // console.log(response);
    }

    afterSendSubmission = (data) => {
        let submissions = this.state.submissions;
        console.log(data)
        submissions.push(data.submissionId)
        this.setState({})
    }

    render () {
        // if (this.state.loading) {
        //     return <div>Loading...</div>
        // }
        return (
            <div>
                <AppLayout></AppLayout>

                <div style={{backgroundColor: '#eee', margin: 'auto', marginTop: '120px', border: '1px solid #ccc', height: '600px', width: '90%'}}>
                    {/* <div>Sphere Engine Widget</div> */}
                    <div class="sec-widget" data-id="sec-widget" data-widget="bc95ea6a223f770b5b72360b68a4f6c1"></div>

                </div>
                
                {/* <pre id="result" style={{height: "100px"}}>Submit submission to see the result</pre> */}

                {/* <style jsx scoped>
                    {`
                        .sec-widget{
                            height: 100%;
                        }
                    `}
                </style> */}
            </div>
        )
    }
}

export default CodeEditor;