import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;

export const updateBannerApi = async (questionnaireId,stageId) => {
    let TOKEN = localStorage.getItem("token");
    const formData = new FormData()
    formData.append('test_id', questionnaireId)
    formData.append('stage_id', stageId)
    let endpoint = `${BASE_API_URL}/questionnaire/setRemedialPathStage`;
    let options = {
        headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
        },
        body: formData,
        redirect: "follow",
    };
    const response = await axios.post(endpoint, formData, options);

    if (response?.data?.status !== 200) {
        throw response;
    } else{
       // do this
    }
    return response;
};