import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import {getProfileDataAC} from '../../store/actions/profileActions';
import AppLayout from '../../components/Layout/Layout';
import styled from 'styled-components';
import Modal from '../course/content/Modal';
import EditModal from './Modal';
import EditBasicProfileComponent from '../../components/ProfileComponents/EditProfile';
import UpdateSummaryComponent from '../../components/ProfileComponents/UpdateSummary';
import AddVideoResumeComponent from '../../components/ProfileComponents/AddVideoResume';
import AddLanguageComponent from '../../components/ProfileComponents/AddLanguages';
import UpdateLanguageComponent from '../../components/ProfileComponents/EditLanguage';
import UpdateSkillComponent from '../../components/ProfileComponents/UpdateSkill';
import AddSkillsComponent from '../../components/ProfileComponents/AddSkills';
import AddEducationComponent from '../../components/ProfileComponents/AddEducation';
import AddExperienceComponent from '../../components/ProfileComponents/AddExperience';
import AddCertificateComponent from '../../components/ProfileComponents/AddCertificate';
import AddProjectComponent from '../../components/ProfileComponents/AddProject';
import UpdateEducationComponent from '../../components/ProfileComponents/UpdateEducation';
import UpdateExperienceComponent from '../../components/ProfileComponents/UpdateExperience';
import UpdateCertificateComponent from '../../components/ProfileComponents/UpdateCertificate';
import UpdateProjectComponent from '../../components/ProfileComponents/UpdateProject';

const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 25%);

    @media(max-width: 1180px) {
        grid-template-columns: repeat(2, 50%);
    }

`;

const SaveButton = styled.div`
    background: linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%);
    border-radius: 32px;
    margin: auto;
    text-align: center;
    border: 1px solid linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%);
    width: 98px;
    padding: 8px;
    color: white;
     margin-right: 0;
`

class EditProfile extends Component {

    state = {
        openContent : false,
        contentUrl: '',
        contentType: null
    }

    onContentOpen = async (url, type) => {
        let template;
        // if (type != 'MP4') {
        //     template = await getTemplateData(url);
        // }
        document.body.style.overflow = 'hidden';
        this.setState({openContent: true, contentUrl: url, contentType: type, template: template})
    }

    closeContent = () => {
        this.setState({openContent : false,
            contentUrl: '',
            contentType: null,
            template: null})
        document.body.style.overflow = 'auto';
        
    }

    componentDidMount = ()=> {
        this.props.getProfileData();
        document.body.style.backgroundColor = '#f2f2f2';
    }

    editProfile = () => {
        this.props.history.goBack();
    }
    goback= () => {
        this.props.history.push({
            pathname: '/home',
        });
    }

    openBasicProfileEditHandler = () => {
         this.setState({openBasicProfileEdit: true})
    }

    closeBasicProfileEditHandler = () => {
        this.props.getProfileData()
        this.setState({openBasicProfileEdit: false})
    }

    editSummaryHandler = () => {
        this.setState({openSummaryEdit: true});
    }

    closeSummaryEditHandler = () => {
        this.setState({openSummaryEdit: false});

    }

    editVideoResumeHandler = () => {
        this.setState({openVideoResumeEdit: true});
    }

    closeVideoEditHandler = () => {
        this.props.getProfileData()
        this.setState({openVideoResumeEdit: false});

    }

    openAddLanguagueEditHandler = () => {
        this.setState({openAddLanguagueEdit: true});
    }

    closeAddLanguagueHandler = () => {
        this.setState({openAddLanguagueEdit: false});

    }

    openUpdateLanguagueEditHandler = (event, index) => {
        this.setState({openUpdateLanguagueEdit: true, selected_language_index: index});
    }

    closeUpdateLanguagueHandler = () => {
        this.setState({openUpdateLanguagueEdit: false});

    }

    openSkillsEditHandler = () => {
        this.setState({openSkillsEdit: true});
    }

    closeSkillsEditHandler = () => {
        this.setState({openSkillsEdit: false});
    }

    openAddSkillsEditHandler = () => {
        this.setState({openAddSkillsEdit: true});
    }

    closeAddSkillsHandler = () => {
        this.setState({openAddSkillsEdit: false});

    }

    openAddEducationEditHandler = () => {
        this.setState({openAddEducationEdit: true});
    }

    closeAddEducationHandler = () => {
        this.props.getProfileData();
        this.setState({openAddEducationEdit: false});
    }

    openAddExperienceEditHandler = () => {
        this.setState({openAddExperienceEdit: true});
    }

    closeAddExperienceHandler = () => {
        this.props.getProfileData();
        this.setState({openAddExperienceEdit: false});
    }

    openAddCertificateEditHandler = () => {
        this.setState({openAddCertificateEdit: true});
    }

    closeAddCertificateHandler = () => {
        this.props.getProfileData();
        this.setState({openAddCertificateEdit: false});
    }

    openAddProjectEditHandler = () => {
        this.setState({openAddProjectEdit: true});
    }

    closeAddProjectHandler = () => {
        this.props.getProfileData();
        this.setState({openAddProjectEdit: false});
    }

    openUpdateEducationEditHandler = (event, index) => {
        this.setState({openUpdateEducationEdit: true, selected_education_index: index});
    }

    closeUpdateEducationHandler = () => {
        this.props.getProfileData();
        this.setState({openUpdateEducationEdit: false, selected_education_index: null});
    }
    
    openUpdateExperienceEditHandler = (event, index) => {
        this.setState({openUpdateExperienceEdit: true, selected_experience_index: index});
    }

    closeUpdateExperienceHandler = () => {
        this.props.getProfileData();
        this.setState({openUpdateExperienceEdit: false, selected_experience_index: null});
    }

    openUpdateCertificateEditHandler = (event, index) => {
        this.setState({openUpdateCertificateEdit: true, selected_certificate_index: index});
    }

    closeUpdateCertificateHandler = () => {
        this.props.getProfileData();
        this.setState({openUpdateCertificateEdit: false, selected_certificate_index: null});
    }

    openUpdateProjectEditHandler = (event, index) => {
        this.setState({openUpdateProjectEdit: true, selected_project_index: index});
    }

    closeUpdateProjectHandler = () => {
        this.props.getProfileData();
        this.setState({openUpdateProjectEdit: false, selected_project_index: null});
    }

    render () {
        if (this.props.loading) {
            return <div>Loading....</div>
        } else if (this.props.error_loading_data && !this.props.loading) {
            return <div>Error loading profile data</div>
        }
        console.log(this.props);
        return (
            <div style={{color:'#191648'}}>
                <Modal 
                    open={this.state.openContent} 
                    url={this.state.contentUrl} 
                    close={this.closeContent} 
                    type={this.state.contentType}
                    template={this.state.template}>

                </Modal>
                {/* <AppLayout></AppLayout> */}
                <div style={{width: '95%', marginLeft: 'auto', marginRight: 'auto', background:'#F2F2F2'}}>
                    <div style={{margin: '20px auto', marginRight: '0'}}>
                    {/* <SaveButton onClick={this.goback}>Back</SaveButton> */}
                        <SaveButton onClick={this.editProfile}>Save</SaveButton>
                    </div>
                    <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', display:'flex'}}>
                        <div style={{top: '140px', left: '40px',width:'1s0%',float:'left'}}>
                            <img style={{borderRadius: '50% 50%', width: '100px', height: '100px'}} src={this.props.profile_pic} alt='profile'></img>
                            <div style={{position: 'absolute', right: '5px', bottom: '0'}}>
                                <img width='24px' height='24px' src='/images/profile/edit_icon.svg' alt='edit'></img>
                            </div>
                        </div>
                        <div style={{width:'90%'}}>
                            <div style={{display: 'flex', flexDirection: 'row',width:'60%'}}>
                                <div style={{width: '90%',textAlign: 'left', fontSize: '14px', fontWeight: '400', margin: '10px', marginLeft: '70px'}}><strong style={{fontSize: '24px', fontWeight: '500', textAlign: 'left'}}>{this.props.name}&nbsp;&nbsp;&nbsp;&nbsp;</strong>{this.props.roll_number}</div>
                                <div style={{width: '10%', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}} onClick={this.openBasicProfileEditHandler}>
                                    <img src='/images/login/edit_button.png' alt='edit' />
                                </div>
                            </div>
                            <div style={{borderBottom: '1px solid #D4D4D4', margin: '10px', marginLeft: '70px'}}></div>
                            <div style={{margin: '10px',display: 'flex', flexDirection: 'row', textAlign: 'left', fontSize:'16px', marginLeft: '70px'}}>
                                <div style={{width: '40%'}}>{this.props.department_details.full_name}</div>
                                <div style={{width: '50%'}}>{this.props.email}</div>
                            </div>
                            <div style={{margin: '10px',display: 'flex', flexDirection: 'row', textAlign: 'left', fontSize:'16px', marginLeft: '70px'}}>
                                <div style={{width: '40%'}}>{this.props.college.college_full_name}</div>
                                <div style={{width: '40%'}}>{this.props.mobile_number}</div>
                            </div>
                        </div>
                    </div>
                    {this.props.splash && this.props.splash.category_name && this.props.splash.core_name ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{}}>
                                <img src='/images/profile/your_interest.png' alt='your interest'></img>
                            </div>
                            <div style={{marginLeft: '20px'}}>
                                <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Your Interest</div>
                                <div>{`You are interested in ${this.props.splash.category_name} and would like to become a ${this.props.splash.core_name}`}</div>
                            </div>
                            <div style={{width: '10%', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}}>
                                <img src='/images/login/edit_button.png' alt='edit' />
                            </div>
                        </div>
                    </div> : null}
                    {this.props.video_resume  ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{}}>
                                <img src='/images/profile/video.svg' alt='video'></img>
                            </div>
                            {/* <div style={{marginLeft: '20px'}}>
                                <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Video Resume</div>
                                <div style={{marginTop: '20px'}}>
                                    {this.props.video_resume.video_thumb ? <img style={{width: '281px', height: '158px', borderRadius: '4px'}} src={this.props.video_resume.video_thumb} alt='video resume thumbnail'/> : <div style={{width: '281px', height: '158px', borderRadius: '4px',background : 'black'}}/>}
                                </div>
                                <div style={{marginTop: '-100px', marginBottom: '30px'}}>
                                    <img style={{width: '100px', height: '100px',border:'2px solid white',marginLeft : '90px',marginTop : '-90px'}} onClick={() => this.onContentOpen(this.props.video_resume,"mp4")} src='/images/profile/video-play-icon-transparent-27.jpg' alt='play video'/>
                                </div>
                            </div> */}
                            <div style={{ marginLeft: '20px' }}>
                                <div style={{ fontSize: '20px', fontWeight: '500', color: '#6852CB' }}>Video Resume</div>
                                <div style={{ marginTop: '20px', position: 'relative', width: '281px', height: '158px' }}>
                                    {this.props.video_resume.video_thumb ? (
                                        <img
                                            style={{ width: '100%', height: '100%', borderRadius: '4px' }}
                                            src={this.props.video_resume.video_thumb}
                                            alt='video resume thumbnail'
                                        />
                                    ) : (
                                        <div style={{ width: '100%', height: '100%', borderRadius: '4px', background: 'black' }} />
                                    )}
                                    <img
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => this.onContentOpen(this.props.video_resume, "mp4")}
                                        src='/images/profile/video-play-icon-transparent-27.jpg'
                                        alt='play video'
                                    />
                                </div>
                            </div>
                            <div style={{width: '10%', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}} onClick={this.editVideoResumeHandler}>
                                <img src='/images/login/edit_button.png' alt='edit' />
                            </div>
                        </div>
                    </div> : <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{}}>
                                <img src='/images/profile/video.svg' alt='video'></img>
                            </div>
                            <div style={{marginLeft: '20px'}}>
                                <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Video Resume</div>
                            </div>
                            <div style={{width: '10%', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}} onClick={this.editVideoResumeHandler}>
                                <img width='38px' src='/images/profile/plus_icon.png' alt='add new' />
                            </div>
                        </div>
                    </div>}
                    {this.props.summary ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{}}>
                                <img src='/images/profile/career_objective.svg'></img>
                            </div>
                            <div style={{marginLeft: '20px'}}>
                                <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Career Objective</div>
                                <div style={{wordBreak: 'break-word', marginTop: '10px'}}>{this.props.summary}</div>
                            </div>
                            <div onClick={this.editSummaryHandler} style={{width: '10%', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}}>
                                <img src='/images/login/edit_button.png' />
                            </div>

                        </div>
                    </div> : <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{}}>
                                <img src='/images/profile/career_objective.svg'></img>
                            </div>
                            <div style={{marginLeft: '20px'}}>
                                <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Career Objective</div>
                            </div>
                            <div onClick={this.editSummaryHandler} style={{width: '10%', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}}>
                                <img width='38px' src='/images/profile/plus_icon.png' />
                            </div>

                        </div>
                    </div>}

                    {this.props.education && this.props.education.length !== 0 ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{}}>
                                    <img src='/images/profile/education.svg'></img>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Education</div>
                                </div>
                                <div style={{width: '10%', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}}>
                                    <img onClick={this.openAddEducationEditHandler} width='38px' src='/images/profile/plus_icon.png' />
                                </div>
                                
                            </div>

                            {this.props.education.map((edu, edu_index)=> {
                                return <div style={{display: 'flex', flexDirection: 'row'}} key={edu.id}>
                                    <div style={{color: '#191648', fontSize: '14px', marginRight: '10px', width: 'auto'}}>{edu.from_year} - {edu.to_year ? edu.to_year : 'present'}</div>
                                    <div style={{margin: 'auto', borderLeft: '1px solid #4D50CA', position: 'relative', width:'100%', marginLeft: '10px'}}>
                                        <div style={{width:'19px', height: '19px', position: 'absolute', left:'-10px'}}>
                                            <img src='/images/profile/Oval.png'></img>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', marginLeft: '20px', marginTop: '-10px',width: 'auto'}}>
                                            <div style={{marginRight: '10px', width: '5%'}}>
                                                <img src='/images/profile/education_college.svg' />
                                            </div>
                                            <div style={{width: '95%'}}>
                                                <div style={{margin: '10px 10px', fontSize: '18px', color: '#4E54C8', display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{margin: 'auto 15px auto 0', width: '95%'}}>{edu.institute}</div>
                                                    <div onClick={(event)=>this.openUpdateEducationEditHandler(event, edu_index)} style={{fontSize: '14px', width: '5%', color: '#6852cb', textAlign: 'right', margin: 'auto',marginRight: '10px'}}>
                                                        <img src='/images/login/edit_button.png' />
                                                    </div>
                                                    
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {edu.field}
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {edu.degree}
                                                </div>
                                                <div  style={{margin: '10px 10px', color:'#191648', fontSize: '14px', opacity: '0.6'}}>
                                                    {edu.description}
                                                </div>
                                                {edu.files && edu.files.length != 0 ? <ImageGrid>
                                                {edu.files.map((file)=> {                    
                                                    const re = /(?:\.([^.]+))?$/;
                                                    const fileType = re.exec(file.file_url)[1];                              
                                                    return <div style={{marginLeft: '20px'}} key={file.id}>
                                                        <img style={{width: '150px'}} height='114' src={file.thumb_url} alt="" onClick={() => this.onContentOpen(file.file_url,fileType)} />
                                                    </div>
                                                })}
                                                </ImageGrid> : null}
                                            </div>
                                        </div>
                                           

                                    </div>
                                </div>
                            })}
                        </div>
                    </div> : <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{}}>
                                    <img src='/images/profile/education.svg'></img>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Education</div>
                                </div>
                                <div style={{width: '10%', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}}>
                                    <img width='38px' src='/images/profile/plus_icon.png' onClick={this.openAddEducationEditHandler} />
                                </div>
                                
                            </div>
                        </div>
                    </div>}

                    {this.props.experience && this.props.experience.length != 0 ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{}}>
                                    <img src='/images/profile/experience.svg'></img>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Experience and Volunteering</div>
                                </div>
                                <div onClick={this.openAddExperienceEditHandler} style={{width: '10%',textDecoration: 'underline', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}}><img width='38px' src='/images/profile/plus_icon.png' /></div>
                            </div>

                            {this.props.experience.map((exp, exp_index)=> {
                                return <div style={{display: 'flex', flexDirection: 'row'}} key={exp.id}>
                                    <div style={{color: '#191648', fontSize: '14px', marginRight: '10px', width: 'auto'}}>{exp.from_year} - {exp.to_year ? exp.to_year : 'present'}</div>
                                    <div style={{margin: 'auto', borderLeft: '1px solid #4D50CA', position: 'relative', width:'100%', marginLeft: '10px'}}>
                                        <div style={{width:'19px', height: '19px', position: 'absolute', left:'-10px'}}>
                                            <img src='/images/profile/Oval.png'></img>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', marginLeft: '20px', marginTop: '-10px', width: 'auto'}}>
                                            <div style={{marginRight: '10px', width: '5%'}}>
                                                <img src='/images/profile/experince_company.svg' />
                                            </div>
                                            <div style={{width: '95%'}}>
                                            <div style={{margin: '10px 10px', fontSize: '18px', color: '#4E54C8', display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{width: '95%',margin: 'auto 15px auto 0'}}>{exp.title}</div>
                                                    <div style={{width: '5%',textDecoration: 'underline',fontSize: '14px', color: '#6852cb', textAlign: 'right', margin: 'auto',marginRight: '10px'}}>
                                                        <img onClick={(event)=>this.openUpdateExperienceEditHandler(event, exp_index)} src='/images/login/edit_button.png' />
                                                    </div>                                                    
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {exp.experience_type}
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {exp.company}
                                                </div>
                                                <div  style={{margin: '10px 10px', color:'#191648', fontSize: '14px', opacity: '0.6'}}>
                                                    {exp.description}
                                                </div>
                                                {exp.files && exp.files.length != 0 ? <ImageGrid>
                                                {exp.files.map((file)=> {                    
                                                    const re = /(?:\.([^.]+))?$/;
                                                    const fileType = re.exec(file.file_url)[1];                              
                                                    return <div style={{marginLeft: '20px'}} key={file.id}>
                                                        <img style={{width: '150px'}} height='114' src={file.thumb_url} alt="" onClick={() => this.onContentOpen(file.file_url,fileType)} />
                                                    </div>
                                                })}
                                                </ImageGrid> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div> : <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{}}>
                                    <img src='/images/profile/experience.svg'></img>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Experience and Volunteering</div>
                                </div>
                                <div onClick={this.openAddExperienceEditHandler} style={{width: '10%', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}}>
                                    <img width='38px' src='/images/profile/plus_icon.png' />
                                </div>

                            </div>
                            </div>
                    </div>}

                    {this.props.certificate && this.props.certificate.length != 0 ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{}}>
                                    <img src='/images/profile/certificates.svg' alt="certificate" />
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Certificates and Training</div>
                                </div>
                                <div onClick={this.openAddCertificateEditHandler} style={{width: '10%',textDecoration: 'underline', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}}><img width='38px' src='/images/profile/plus_icon.png' /></div>

                            </div>

                            {this.props.certificate.map((cer, cer_index)=> {
                                return <div style={{display: 'flex', flexDirection: 'row'}} key={cer.id}>
                                    <div style={{color: '#191648', fontSize: '14px', marginRight: '10px', width: 'auto'}}>{cer.from_year} - {cer.to_year ? cer.to_year : 'present'}</div>
                                    <div style={{margin: 'auto', borderLeft: '1px solid #4D50CA', position: 'relative', width:'100%', marginLeft: '10px'}}>
                                        <div style={{width:'19px', height: '19px', position: 'absolute', left:'-10px'}}>
                                            <img src='/images/profile/Oval.png' alt=""/>
                                        </div>
                                        <div style={{width: 'auto',display: 'flex', flexDirection: 'row', marginLeft: '20px', marginTop: '-10px'}}>
                                            <div style={{marginRight: '10px',width: '5%'}}>
                                                <img src='/images/profile/certificates_certificate.svg' alt="certificate" />
                                            </div>
                                            <div style={{width: '95%', marginTop: '-8px'}}>
                                            <div style={{margin: '10px 10px', fontSize: '18px', color: '#4E54C8', display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{width: '95%',margin: 'auto 15px auto 0'}}>{cer.title}</div>
                                                    <div style={{width: '5%',textDecoration: 'underline',fontSize: '14px', color: '#6852cb', textAlign: 'right', margin: 'auto',marginRight: '10px'}}>
                                                        <img onClick={(event)=>this.openUpdateCertificateEditHandler(event, cer_index)} src='/images/login/edit_button.png' />    
                                                    </div>
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {cer.company}
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {cer.location}
                                                </div>
                                                <div  style={{margin: '10px 10px', color:'#191648', fontSize: '14px', opacity: '0.6'}}>
                                                    {cer.description}
                                                </div>
                                                {cer.files && cer.files.length != 0 ? <ImageGrid >
                                                {cer.files.map((file)=> {
                                                    const re = /(?:\.([^.]+))?$/;
                                                    const fileType = re.exec(file.file_url)[1]; 
                                                    return <div style={{marginLeft: '20px'}} key={file.id}>
                                                        <img style={{width: '150px'}}  height='114' alt="" src={file.thumb_url} onClick={() => this.onContentOpen(file.file_url,fileType)} />
                                                    </div>
                                                })}
                                                </ImageGrid> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div> : <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{}}>
                                    <img src='/images/profile/certificates.svg' alt="certificate" />
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Certificates and Training</div>
                                </div>
                                <div onClick={this.openAddCertificateEditHandler} style={{width: '10%', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}}>
                                    <img width='38px' src='/images/profile/plus_icon.png' />
                                </div>

                            </div>
                        </div>
                    </div>}

                    {this.props.projects && this.props.projects.length != 0 ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{}}>
                                    <img src='/images/profile/projects.svg'></img>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Projects</div>
                                </div>
                                <div onClick={this.openAddProjectEditHandler} style={{width: '10%', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}}>
                                    <img width='38px' src='/images/profile/plus_icon.png' />
                                </div>

                            </div>

                            {this.props.projects.map((proj, proj_index)=> {
                                return <div style={{display: 'flex', flexDirection: 'row'}} key={proj.id}>
                                    <div style={{color: '#191648', fontSize: '14px', marginRight: '10px', width: 'auto'}}>{proj.from_year} - {proj.to_year ? proj.to_year : 'present'}</div>
                                    <div style={{margin: 'auto', borderLeft: '1px solid #4D50CA', position: 'relative', width:'100%', marginLeft: '10px'}}>
                                        <div style={{width:'19px', height: '19px', position: 'absolute', left:'-10px'}}>
                                            <img src='/images/profile/Oval.png'></img>
                                        </div>
                                        <div style={{width: 'auto',display: 'flex', flexDirection: 'row', marginLeft: '20px', marginTop: '-10px'}}>
                                            <div style={{width: '5%',marginRight: '10px'}}>
                                                <img src='/images/profile/projects_project.svg' />
                                            </div>
                                            <div style={{width: '95%'}}>
                                                <div style={{margin: '10px 10px', fontSize: '18px', color: '#4E54C8', display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{width: '95%',margin: 'auto 15px auto 0'}}>{proj.title}</div>
                                                    <div style={{width: '5%',textDecoration: 'underline',fontSize: '14px', color: '#6852cb', textAlign: 'right', margin: 'auto',marginRight: '10px'}}>
                                                        <img onClick={(event)=>this.openUpdateProjectEditHandler(event, proj_index)} src='/images/login/edit_button.png' />  
                                                    </div>                                                    
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {proj.company}
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {proj.location}
                                                </div>
                                                <div  style={{margin: '10px 10px', color:'#191648', fontSize: '14px', opacity: '0.6'}}>
                                                    {proj.description}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div> : <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{}}>
                                    <img src='/images/profile/projects.svg'></img>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Projects</div>
                                </div>
                                <div onClick={this.openAddProjectEditHandler} style={{width: '10%',textDecoration: 'underline', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}}>
                                    <img width='38px' src='/images/profile/plus_icon.png' />
                                </div>

                            </div>
                        </div>
                    </div>}

                    {this.props.languages && this.props.languages.length != 0 ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{}}>
                                <img src='/images/profile/languages.svg'></img>
                            </div>
                            <div style={{marginLeft: '20px', width:'-webkit-fill-available'}}>
                                <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Languages</div>
                                <div style={{display: 'gird', gridTemplateColumns: 'repeat(3,33.3%)', marginTop: '10px'}}>
                                    {this.props.languages.map((lang, lang_index)=> {
                                        let proficiency = Math.round(((lang.listening ? lang.listening : 0 ) + (lang.speaking ? lang.speaking : 0 ) + (lang.read ? lang.read : 0) +  (lang.write ? lang.write : 0))/4);
                                        
                                        return <div style={{ display:'flex', flexDirection:'row'}} key={lang_index}>
                                            <div>{lang.name} | {proficiency < 2 ? 'Basic' : proficiency > 3 ? 'Fluent' : 'Intermediate'}</div>
                                            <div onClick={(event)=>this.openUpdateLanguagueEditHandler(event,lang_index)} style={{textDecoration: 'underline',fontSize: '14px', color: '#6852cb', textAlign: 'left', margin: 'auto 0 auto 20px'}}>
                                                <img src='/images/login/edit_button.png' />
                                            </div>
                                            {/* <div style={{textDecoration: 'underline',fontSize: '14px',  color: '#6852cb', textAlign: 'left', margin: 'auto 0 auto 20px'}}>Delete</div>                                                     */}
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div style={{width: '10%', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}} onClick={this.openAddLanguagueEditHandler}>
                                <img width='38px' src='/images/profile/plus_icon.png' />
                            </div>

                        </div>
                    </div> : <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{}}>
                                <img src='/images/profile/languages.svg'></img>
                            </div>
                            <div style={{marginLeft: '20px', width:'-webkit-fill-available'}}>
                                <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Languages</div>
                            </div>
                            <div style={{width: '10%', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}} onClick={this.openAddLanguagueEditHandler}>
                                <img width='38px' src='/images/profile/plus_icon.png' />
                            </div>

                        </div>
                    </div> }

                    {this.props.skills && this.props.skills.length != 0 ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{}}>
                                <img src='/images/profile/skills.svg'></img>
                            </div>
                            <div style={{marginLeft: '20px', width:'-webkit-fill-available'}}>
                                <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Skills</div>
                                {this.props.learning_skills && this.props.learning_skills.length != 0 ? <div>
                                    <div style={{color: '#6852CB', margin: '20px 0'}}>Learning</div>
                                    <div style={{}}>{this.props.learning_skills}</div>
                                </div> : null}
                                {this.props.working_skills && this.props.working_skills.length != 0 ? <div>
                                    <div style={{color: '#6852CB', margin: '20px 0'}}>Working</div>
                                    <div style={{}}>{this.props.working_skills}</div>
                                </div> : null}
                                {this.props.advance_skills && this.props.advance_skills.length != 0 ? <div>
                                    <div style={{color: '#6852CB', margin: '20px 0'}}>Advance</div>
                                    <div style={{}}>{this.props.advance_skills}</div>
                                </div> : null}
                                {this.props.other_skills && this.props.other_skills.length != 0 ? <div>
                                    <div style={{color: '#6852CB', margin: '20px 0'}}>Others</div>
                                    <div style={{}}>{this.props.other_skills}</div>
                                </div> : null}
                            </div>
                            <div onClick={this.openAddSkillsEditHandler} style={{ color: '#6852cb', textAlign: 'right', marginRight: '20px'}}>
                                <img width='38px' src='/images/profile/plus_icon.png' />
                            </div>
                            <div onClick={this.openSkillsEditHandler} style={{color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}}>
                                <img src='/images/login/edit_button.png' />
                            </div>
                        </div>
                    </div> : <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{}}>
                                <img src='/images/profile/skills.svg'></img>
                            </div>
                            <div style={{marginLeft: '20px', width:'-webkit-fill-available'}}>
                                <div style={{fontSize: '20px', fontWeight:'500', color: '#6852CB'}}>Skills</div>
                            </div>
                            <div onClick={this.openAddSkillsEditHandler} style={{width: '10%', color: '#6852cb', textAlign: 'right', margin: '10px auto',marginRight: '10px'}}>
                                <img width='38px' src='/images/profile/plus_icon.png' />
                            </div>
                        </div>
                    </div>}
                </div>
                {this.state.openBasicProfileEdit ? <EditModal open={this.state.openBasicProfileEdit} close = {this.closeBasicProfileEditHandler}>
                    <EditBasicProfileComponent close ={this.closeBasicProfileEditHandler}></EditBasicProfileComponent>
                </EditModal> : null}
                {this.state.openSummaryEdit ? <EditModal height={'45%'} padding={'40px'} open={this.state.openSummaryEdit} close = {this.closeSummaryEditHandler}>
                    <UpdateSummaryComponent close ={this.closeSummaryEditHandler}></UpdateSummaryComponent>
                </EditModal> : null}
                {this.state.openVideoResumeEdit ? <EditModal height={'50%'} padding={'40px'} open={this.state.openVideoResumeEdit} close = {this.closeVideoEditHandler}>
                    <AddVideoResumeComponent close ={this.closeVideoEditHandler}></AddVideoResumeComponent>
                </EditModal> : null}
                {this.state.openAddLanguagueEdit ? <EditModal height={'50%'} padding={'40px'} open={this.state.openAddLanguagueEdit} close = {this.closeAddLanguagueHandler}>
                    <AddLanguageComponent close ={this.closeAddLanguagueHandler}></AddLanguageComponent>
                </EditModal> : null}
                {this.state.openUpdateLanguagueEdit ? <EditModal height={'50%'} padding={'40px'} open={this.state.openUpdateLanguagueEdit} close = {this.closeUpdateLanguagueHandler}>
                    <UpdateLanguageComponent close ={this.closeUpdateLanguagueHandler} language_index = {this.state.selected_language_index}></UpdateLanguageComponent>
                </EditModal> : null}
                {this.state.openSkillsEdit ? <EditModal height={'50%'} padding={'40px'} open={this.state.openSkillsEdit} close = {this.closeSkillsEditHandler}>
                    <UpdateSkillComponent close ={this.closeSkillsEditHandler} ></UpdateSkillComponent>
                </EditModal> : null}
                {this.state.openAddSkillsEdit ? <EditModal height={'50%'} padding={'40px'} open={this.state.openAddSkillsEdit} close = {this.closeAddSkillsHandler}>
                    <AddSkillsComponent close ={this.closeAddSkillsHandler} ></AddSkillsComponent>
                </EditModal> : null}
                {this.state.openAddEducationEdit ? <EditModal height={'70%'} width={'70%'} padding={'40px'} open={this.state.openAddEducationEdit} close = {this.closeAddEducationHandler}>
                    <AddEducationComponent close ={this.closeAddEducationHandler} ></AddEducationComponent>
                </EditModal> : null}
                {this.state.openAddExperienceEdit ? <EditModal height={'70%'} width={'70%'} padding={'40px'} open={this.state.openAddExperienceEdit} close = {this.closeAddExperienceHandler}>
                    <AddExperienceComponent close ={this.closeAddExperienceHandler} ></AddExperienceComponent>
                </EditModal> : null}
                {this.state.openAddProjectEdit ? <EditModal height={'70%'} width={'70%'} padding={'40px'} open={this.state.openAddProjectEdit} close = {this.closeAddProjectHandler}>
                    <AddProjectComponent close ={this.closeAddProjectHandler} ></AddProjectComponent>
                </EditModal> : null}
                {this.state.openAddCertificateEdit ? <EditModal height={'70%'} width={'70%'} padding={'40px'} open={this.state.openAddCertificateEdit} close = {this.closeAddCertificateHandler}>
                    <AddCertificateComponent close ={this.closeAddCertificateHandler} ></AddCertificateComponent>
                </EditModal> : null}
                {this.state.openUpdateEducationEdit ? <EditModal height={'70%'} padding={'40px'} open={this.state.openUpdateEducationEdit} close = {this.closeUpdateEducationHandler}>
                    <UpdateEducationComponent close ={this.closeUpdateEducationHandler} education_index = {this.state.selected_education_index}></UpdateEducationComponent>
                </EditModal> : null}
                {this.state.openUpdateExperienceEdit ? <EditModal height={'70%'} padding={'40px'} open={this.state.openUpdateExperienceEdit} close = {this.closeUpdateExperienceHandler}>
                    <UpdateExperienceComponent close ={this.closeUpdateExperienceHandler} experience_index = {this.state.selected_experience_index}></UpdateExperienceComponent>
                </EditModal> : null}
                {this.state.openUpdateCertificateEdit ? <EditModal height={'70%'} padding={'40px'} open={this.state.openUpdateCertificateEdit} close = {this.closeUpdateCertificateHandler}>
                    <UpdateCertificateComponent close ={this.closeUpdateCertificateHandler} certificate_index = {this.state.selected_certificate_index}></UpdateCertificateComponent>
                </EditModal> : null}
                {this.state.openUpdateProjectEdit ? <EditModal height={'70%'} padding={'40px'} open={this.state.openUpdateProjectEdit} close = {this.closeUpdateProjectHandler}>
                    <UpdateProjectComponent close ={this.closeUpdateProjectHandler} project_index = {this.state.selected_project_index}></UpdateProjectComponent>
                </EditModal> : null}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.prof.loading,
        name: state.prof.name,
        certificate: state.prof.certificate,
        college: state.prof.college,
        college_university_degree_department_id: state.prof.college_university_degree_department_id,
        course_id: state.prof.course_id,
        course_title: state.prof.course_title,
        department_details: state.prof.department_details,
        education: state.prof.education,
        email: state.prof.email,
        email_verified: state.prof.email_verified,
        experience: state.prof.experience,
        gender: state.prof.gender,
        id: state.prof.id,
        is_ambassador: state.prof.is_ambassador,
        languages: state.prof.languages,
        mobile_number: state.prof.mobile_number,
        mobile_verified: state.prof.mobile_verified,
        profile_pic: state.prof.profile_pic,
        projects: state.prof.projects,
        roll_number: state.prof.roll_number,
        section_details: state.prof.section_details,
        semester_id: state.prof.semester_id,
        skills: state.prof.skills,
        splash: state.prof.splash,
        summary: state.prof.summary,
        university_degree_department_id: state.prof.university_degree_department_id,
        video_resume: state.prof.video_resume,
        error_loading_data: state.prof.error_loading_data,
        working_skills: state.prof.working_skills,
        advance_skills: state.prof.advance_skills,
        other_skills: state.prof.other_skills,
        learning_skills: state.prof.learning_skills
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProfileData:()=> dispatch(getProfileDataAC()),
        
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditProfile));