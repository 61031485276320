import * as React from "react";
import { render } from "react-dom";

export default function Countdowntimer(props) {
  const [counter, setCounter] = React.useState(props.time);
  const [HMSformat, setHMSformat] = React.useState("00:00:00");
  const convertHMS = (value) => {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
  };
  const converttime = (counter) => {
    return new Date(counter * 1000).toISOString().slice(11, 19);
  };
  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    setHMSformat(convertHMS(counter));
    if (counter == 0) {
      props.timercompleted();
    }
  }, [counter]);

  return <span style={{ fontWeight: "600" }}>{HMSformat}</span>;
}
