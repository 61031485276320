import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import styled from "styled-components";
import moment from 'moment';
import AppLayout from "./Layout/Layout";
// import AssessmentInfo from "./CodingComponents/components/AssessmentInfo/AssessmentInfo";

// const TestDescriptionWrapper = styled.div`
//   display: grid;
//   grid-gap: 2rem;
//   img {
//     width: 100%;
//   }
//   @media (max-width: 768px) {
//     grid-template-columns: auto;
//   }
// `;

class Instructons extends Component {
  state = {};

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    let timelimit = this.props.timelimit;
    let doe = this.props.doe;
    // let timelimit_modified;
    if (timelimit) {
      let doe_data = moment(doe);
      let current = moment();
      if (doe_data.diff(current, 'seconds') < timelimit) {
          timelimit = doe_data.diff(current, 'seconds')
      }
      let hours = Math.floor(timelimit / (60 * 60));
        let minutes = (timelimit - hours * 60 * 60);
        if (minutes > 60) {
            minutes = Math.floor(minutes/60);
        }
        // timelimit_modified = minutes && hours ? hours + 'hrs ' + minutes + 'mins' : minutes && !hours ? minutes + 'mins' :hours + 'hrs';
    }
    return (
      <div>
        {/* {this.props.showStart ? <AppLayout></AppLayout> : null} */}
        {/* <div style={{padding: this.props.showStart ? "20px" : '10px',margin: this.props.showStart ? '16px 64px' : '16px',marginTop: this.props.showStart ? "96px" : "16px", paddingBottom: '0'}}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              background: '#ffffff',boxShadow: this.props.showStart ? "0px 1px 14px rgba(143, 143, 143, 0.2)" : null,borderRadius: "8px",
              padding: "30px",marginBottom: "16px"
            }}
          >
            <div>
              <TestDescriptionWrapper>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <h4 style={{ fontSize: "18px", margin: "0.1em 0", textAlign: 'left', fontWeight: 'normal' , marginBottom: '20px'}}>
                    Points to Remember
                    </h4>
                    <ul style={{ marginTop: "0.2em" }}>
                      <li>
                        <b>
                          Make sure that you don’t switch the app. Switching
                          more than twice submits the test.
                        </b>
                      </li>
                      <li>
                        <b>
                          Please put your phone in Do-Not-Disturb (DND) mode
                          while taking the test.
                        </b>
                      </li>
                      <li>
                        Total Duration of test is allocated by the faculty and
                        is shown on the top of the test screen.
                      </li>
                      <li>
                        The Test should be taken within the time of expiry as
                        mentioned by the faculty.
                      </li>
                      <li>
                        The test will be Multiple choice questions with a
                        single correct answer.
                      </li>
                      <li>
                        There is no negative marking for the incorrectly
                        answered questions.
                      </li>
                      <li>
                        One point will be awarded for each correctly answered
                        question.
                      </li>
                      <li>
                        The Test will be auto-submitted once the allocated
                        duration of the test completes.
                      </li>
                      <li>
                        The results for the test will auto-appear once the
                        test gets expired.
                      </li>
                      <li>
                        The detailed report for the test and correct answers
                        will be shown in the results.
                      </li>
                      <li>
                        WARNING: You are not allowed to take any screenshots
                        during the test, this may effect your scores.{" "}
                      </li>
                      <li>
                        The scores generated in the test will have a huge
                        impact on your performance analytics.
                      </li>
                    </ul>
                  </div>
                </div>
              </TestDescriptionWrapper>

              
            </div>
          </div>
        </div> */}
        {this.props.showStart ? (
                  <div style={{margin: this.props.showStart ? '16px 64px' : '16px',marginTop: this.props.showStart ? "96px" : "16px"}}>
                    <div style={{display:'flex',flexDirection:'column',width:'30%',margin:'0 auto'}}>
                      <span style={{fontWeight:'bold',fontSize:'large',margin:'10px 0'}}>Please enter the code</span>
                      <input type="number" className="form-control" onChange={event => this.props.onCodeChange(event)} value={this.props.code}
                      style={{borderRadius:'15px',padding:'5px 20px',height:'40px',fontSize:'18px',textAlign:'center'}} />
                    </div>
                    {this.props.error ? <p style={{color:'red'}}>{this.props.error}</p> : null}
                    <div
                      style={{ width: "fit-content", margin: "20px auto" ,alignContent:'center',justifyContent:'space-between'}}
                    >
                      <button
                        className="startbutton"
                        onClick={this.props.startTest}
                      >{this.props.buttonName}</button>
                    </div>
                  </div>
                ) : null}
        <style jsx="true" scoped>
          {`            
            .startbutton {
              background: linear-gradient(131.37deg, #7175E0 -8.1%, #4D50CB 45.77%, #E25BC8 155.59%);
              border: none;
              color: white;
              padding: 15px 32px;
              text-align: center;
              text-decoration: none;
              display: inline-block;
              font-size: 16px;
              border-radius: 8px;
              width: auto;
              cursor: pointer !important;
            }
          `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    questions: state.live.questions,
    test_id: state.live.practice_id,
    qid: state.live.qid,
    timelimit: state.live.timelimit,
    name: state.live.name,
    sections: state.live.sections,
    description: state.live.description,
    error_loading_practice_test: state.live.error_loading_practice_test,
    loading: state.live.loading_practice_test,
    date_of_expiry: state.live.date_of_expiry,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTestStart: () => dispatch({ type: "LIVE_TEST_START_FROM_INSTRUCTIONS" }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Instructons)
);
