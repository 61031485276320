import React from 'react'

const OpenBookSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M18.3332 14.4501V4.39174C18.3332 3.39174 17.5165 2.65008 16.5248 2.73341H16.4748C14.7248 2.88341 12.0665 3.77508 10.5832 4.70841L10.4415 4.80008C10.1998 4.95008 9.79984 4.95008 9.55817 4.80008L9.34984 4.67508C7.8665 3.75008 5.2165 2.86674 3.4665 2.72508C2.47484 2.64174 1.6665 3.39174 1.6665 4.38341V14.4501C1.6665 15.2501 2.3165 16.0001 3.1165 16.1001L3.35817 16.1334C5.1665 16.3751 7.95817 17.2917 9.55817 18.1667L9.5915 18.1834C9.8165 18.3084 10.1748 18.3084 10.3915 18.1834C11.9915 17.3001 14.7915 16.3751 16.6082 16.1334L16.8832 16.1001C17.6832 16.0001 18.3332 15.2501 18.3332 14.4501Z" stroke="#0946C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10 5.0752V17.5752" stroke="#0946C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.4585 7.5752H4.5835" stroke="#0946C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7.0835 10.0752H4.5835" stroke="#0946C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
  )
}

export default OpenBookSvg