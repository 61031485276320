import React from 'react'
import styled from 'styled-components';
import { keyframes } from '@mui/system';
import './TestResult.css'
import testGif from '../../../../../assets/remedial/gif/Banner-test-result.gif'

const CircleSvg = styled('div')(({ theme }) => ({
    img:{
        width:'150px',
        height: '150px',
        objectFit:'contain',
        bottom: '-12px',
        position: 'relative'
    },
    svg: {
        minWidth: '108px',
        minHeight: '108px',
        maxWidth: '108px',
        maxHeight: '108px',
        position: 'relative',
        top: 11,
    }
}));


const TestResultCircleAnimation = ({ isActive }) => {
    return (
        <>
            <CircleSvg className='enabled-animation'>
                <img src={testGif} alt="testResultGif" />
            </CircleSvg>
            <CircleSvg className='disabled-animation'>
                <svg width="90" height="155" viewBox="0 0 90 155" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Property 1=Variant3" clip-path="url(#clip0_131_10505)">
                        <g >
                            <g>
                                <path id="Vector" d="M87.1301 112.14V154.85H22.8701L35.3901 89.2598C34.9901 91.8498 43.0001 96.6998 53.9701 101.57C57.9101 103.32 62.3001 105.05 66.9801 106.7C74.5401 109.37 81.5101 111.18 87.1301 112.14Z" fill="#616161" />
                                <path id="Vector_2" d="M87.1301 99.2996V110.73C81.5101 109.77 74.5601 107.97 66.9701 105.3C49.0901 99.0196 35.3301 91.2596 36.1001 87.7796C36.1101 87.7296 36.1301 87.6796 36.1401 87.6396C37.3401 84.2296 53.1801 86.5896 71.3101 92.9496C77.1401 94.9996 82.3901 97.1096 86.8101 99.1396C86.9101 99.1896 87.0201 99.2396 87.1301 99.2996Z" fill="#CDCDCD" />
                            </g>
                            <g>
                                <g id="Group">
                                    <path id="Vector_3" d="M78.31 0.320312C72.04 0.320312 66.97 5.40031 66.97 11.6503V42.7003C66.97 48.9503 61.89 54.0303 55.64 54.0303H10.22C16.49 54.0303 21.55 48.9503 21.55 42.7003V11.6503C21.55 5.39031 26.62 0.320312 32.87 0.320312H78.3H78.31Z" fill="white" />
                                    <path id="Vector_4" d="M55.64 54.35H10.22V53.7C16.29 53.7 21.23 48.76 21.23 42.69V11.65C21.23 5.22 26.46 0 32.88 0H78.31V0.65C72.23 0.65 67.29 5.59 67.29 11.66V42.71C67.29 49.14 62.06 54.36 55.64 54.36V54.35ZM14.05 53.7H55.64C61.71 53.7 66.65 48.76 66.65 42.69V11.65C66.65 6.57 69.93 2.23 74.48 0.64H32.88C26.81 0.64 21.87 5.58 21.87 11.65V42.7C21.87 47.78 18.6 52.12 14.05 53.71V53.7Z" fill="#CDCDCD" />
                                </g>
                                <g id="Group_2">
                                    <g id="Group_3">
                                        <path id="Vector_5" d="M58.45 17.3906H29.52V44.2306H58.45V17.3906Z" fill="white" />
                                        <path id="Vector_6" d="M58.7702 44.5603H29.2002V17.0703H58.7702V44.5603ZM29.8402 43.9103H58.1202V17.7203H29.8402V43.9103Z" fill="#CDCDCD" />
                                    </g>
                                    <path id="Vector_7" d="M58.4303 20.9004H29.5203V21.5504H58.4303V20.9004Z" fill="#CDCDCD" />
                                    <path id="Vector_8" d="M58.4303 24.7305H29.5203V25.3805H58.4303V24.7305Z" fill="#CDCDCD" />
                                    <path id="Vector_9" d="M58.4303 28.5801H29.5203V29.2301H58.4303V28.5801Z" fill="#CDCDCD" />
                                    <path id="Vector_10" d="M58.4303 32.4102H29.5203V33.0602H58.4303V32.4102Z" fill="#CDCDCD" />
                                    <path id="Vector_11" d="M58.43 36.2607H29.52V36.9107H58.43V36.2607Z" fill="#CDCDCD" />
                                    <path id="Vector_12" d="M58.43 40.0898H29.52V40.7398H58.43V40.0898Z" fill="#CDCDCD" />
                                </g>
                                <g id="Group_4">
                                    <path id="Vector_13" d="M66.97 11.6493C66.97 5.41934 71.99 0.369336 78.2 0.319336C84.41 0.369336 89.43 5.41934 89.43 11.6493H66.98H66.97Z" fill="#A0A0A0" />
                                    <path id="Vector_14" d="M89.7399 11.9798H66.6399V11.6598C66.6399 5.28977 71.8199 0.0597656 78.1899 0.00976562C81.2899 0.0397656 84.1899 1.25977 86.3699 3.44977C88.5499 5.64977 89.7499 8.55976 89.7499 11.6598V11.9798H89.7399ZM67.2899 11.3298H89.0799C88.9999 8.52977 87.8799 5.89977 85.8999 3.89977C83.8399 1.82977 81.0999 0.669766 78.1799 0.649766C72.2799 0.699766 67.4599 5.45977 67.2899 11.3298Z" fill="#CDCDCD" />
                                </g>
                                <path id="Vector_15" d="M55.69 54.3203H10.31C5.66 54.2603 1.69 51.3803 0 47.3203H45.34C47.04 51.3903 51.01 54.2603 55.69 54.3203Z" fill="#CDCDCD" />
                                <g id="Group_5">
                                    <g id="Group 10191">
                                        <g id="&#240;&#159;&#166;&#134; emoji &#34;white medium star&#34;">
                                            <path id="Vector_16" d="M38.3082 7.4344L39.0034 9.02009C39.0566 9.14148 39.1718 9.22463 39.304 9.23651L40.9977 9.38837C41.1893 9.41626 41.2657 9.65127 41.1268 9.7866L39.851 10.8584C39.7477 10.9451 39.7007 11.0815 39.7291 11.2132L40.1 12.9492C40.1325 13.1398 39.9326 13.2855 39.7611 13.1951L38.2829 12.3294C38.1692 12.2628 38.0288 12.2628 37.9151 12.3294L36.4369 13.1946C36.2659 13.2844 36.0655 13.1393 36.098 12.9487L36.4689 11.2127C36.4968 11.081 36.4503 10.9446 36.347 10.8579L35.0707 9.78712C34.9323 9.65231 35.0087 9.41678 35.1998 9.38889L36.8935 9.23703C37.0257 9.22515 37.1409 9.14199 37.1941 9.02061L37.8893 7.43491C37.9756 7.26136 38.2224 7.26136 38.3082 7.4344Z" fill="#D1D1D1" />
                                            <path id="Vector_17" d="M38.2577 9.11551L38.1399 7.94716C38.1353 7.88207 38.1218 7.77051 38.2262 7.77051C38.3088 7.77051 38.3538 7.94251 38.3538 7.94251L38.707 8.8805C38.8403 9.23741 38.7856 9.35982 38.6569 9.43213C38.5092 9.51478 38.2913 9.45021 38.2577 9.11551Z" fill="#F2F2F2" />
                                            <path id="Vector_18" d="M39.7147 10.7542L40.7281 9.96338C40.7782 9.92155 40.8686 9.85492 40.7963 9.77899C40.739 9.71907 40.584 9.80533 40.584 9.80533L39.6972 10.1519C39.4327 10.2433 39.2571 10.3787 39.2416 10.5491C39.2215 10.7764 39.4255 10.9515 39.7147 10.7542Z" fill="#B3B3B3" />
                                        </g>
                                        <g id="&#240;&#159;&#166;&#134; emoji &#34;white medium star&#34;_2">
                                            <path id="Vector_19" d="M44.7091 5.12971L45.4043 6.71541C45.4575 6.83679 45.5727 6.91995 45.7049 6.93183L47.3986 7.08368C47.5902 7.11157 47.6666 7.34659 47.5277 7.48191L46.2519 8.55368C46.1486 8.64045 46.1016 8.77681 46.13 8.90853L46.5009 10.6445C46.5334 10.8351 46.3335 10.9808 46.162 10.8904L44.6838 10.0247C44.5701 9.95808 44.4296 9.95808 44.316 10.0247L42.8377 10.8899C42.6668 10.9797 42.4664 10.8346 42.4989 10.644L42.8698 8.90801C42.8977 8.7763 42.8512 8.63994 42.7479 8.55316L41.4716 7.48243C41.3331 7.34762 41.4096 7.11209 41.6007 7.0842L43.2943 6.93234C43.4266 6.92046 43.5417 6.8373 43.5949 6.71592L44.2902 5.13023C44.3764 4.95668 44.6233 4.95668 44.7091 5.12971Z" fill="#D1D1D1" />
                                            <path id="Vector_20" d="M44.6586 6.81082L44.5408 5.64247C44.5361 5.57739 44.5227 5.46582 44.6271 5.46582C44.7097 5.46582 44.7546 5.63782 44.7546 5.63782L45.1079 6.57581C45.2412 6.93272 45.1864 7.05513 45.0578 7.12745C44.9101 7.21009 44.6921 7.14552 44.6586 6.81082Z" fill="#F2F2F2" />
                                            <path id="Vector_21" d="M46.1156 8.44948L47.129 7.6587C47.1791 7.61686 47.2695 7.55023 47.1972 7.4743C47.1399 7.41439 46.9849 7.50064 46.9849 7.50064L46.098 7.84722C45.8336 7.93865 45.658 8.07397 45.6425 8.24442C45.6223 8.47169 45.8264 8.64679 46.1156 8.44948Z" fill="#B3B3B3" />
                                        </g>
                                        <g id="&#240;&#159;&#166;&#134; emoji &#34;white medium star&#34;_3">
                                            <path id="Vector_22" d="M51.1104 7.69026L51.8057 9.27595C51.8589 9.39733 51.974 9.48049 52.1063 9.49237L53.7999 9.64423C53.9915 9.67212 54.068 9.90713 53.9291 10.0425L52.6533 11.1142C52.55 11.201 52.503 11.3374 52.5314 11.4691L52.9022 13.2051C52.9348 13.3957 52.7349 13.5413 52.5634 13.4509L51.0851 12.5853C50.9715 12.5186 50.831 12.5186 50.7174 12.5853L49.2391 13.4504C49.0681 13.5403 48.8677 13.3952 48.9003 13.2046L49.2711 11.4686C49.299 11.3368 49.2525 11.2005 49.1492 11.1137L47.8729 10.043C47.7345 9.90817 47.8109 9.67264 48.0021 9.64475L49.6957 9.49289C49.8279 9.48101 49.9431 9.39785 49.9963 9.27647L50.6915 7.69077C50.7778 7.51722 51.0247 7.51722 51.1104 7.69026Z" fill="#D1D1D1" />
                                            <path id="Vector_23" d="M51.0599 9.37137L50.9422 8.20301C50.9375 8.13793 50.9241 8.02637 51.0284 8.02637C51.1111 8.02637 51.156 8.19837 51.156 8.19837L51.5093 9.13636C51.6426 9.49327 51.5878 9.61568 51.4592 9.68799C51.3115 9.77063 51.0935 9.70607 51.0599 9.37137Z" fill="#F2F2F2" />
                                            <path id="Vector_24" d="M52.5167 11.01L53.5301 10.2192C53.5802 10.1774 53.6706 10.1108 53.5983 10.0348C53.541 9.97493 53.386 10.0612 53.386 10.0612L52.4992 10.4078C52.2347 10.4992 52.0591 10.6345 52.0436 10.805C52.0235 11.0322 52.2275 11.2073 52.5167 11.01Z" fill="#B3B3B3" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g>
                                <path id="Vector_25" d="M87.1298 59.9703V110.73H87.1198C81.4998 109.77 74.5498 107.97 66.9598 105.3C62.2698 103.66 57.8798 101.91 53.9498 100.17L56.0598 88.3903L59.6498 68.3903C48.3998 64.8703 46.5698 54.0403 46.5698 54.0403H55.6198C61.8898 54.0403 66.9498 48.9603 66.9498 42.7103V38.9803H61.1398C57.0598 38.9803 53.7498 34.9703 53.7498 30.8903V29.5703H66.6698C69.0698 29.5703 71.3198 30.7003 72.7598 32.6103L82.2098 45.2303C85.4198 49.4803 87.1398 54.6403 87.1398 59.9603L87.1298 59.9703Z" fill="#616161" />
                                <path id="Vector_26" d="M87.1297 99.3006V110.731H87.1197C81.4997 109.771 74.5497 107.971 66.9597 105.301C62.2697 103.661 57.8797 101.911 53.9497 100.171L56.0597 88.3906C60.7197 89.5306 65.8997 91.0706 71.2897 92.9606C77.1197 95.0106 82.3697 97.1206 86.7797 99.1506C86.9197 99.1906 87.0297 99.2406 87.1397 99.2906L87.1297 99.3006Z" fill="#4B4B4B" />
                                <path id="Vector_27" d="M67.5399 38.9901H61.2999C57.2199 38.9901 53.9099 34.9801 53.9099 30.9001V29.5801H67.5399V38.9901Z" fill="#616161" />
                                <path id="Vector_28" d="M73.0997 66.61C72.6897 60.71 70.0897 57.02 67.9797 54.97C65.6997 52.75 63.5097 51.91 63.4897 51.9L63.7197 51.29C63.8097 51.33 66.0597 52.19 68.4297 54.5C70.6197 56.63 73.3097 60.46 73.7397 66.56L73.0897 66.61H73.0997Z" fill="#4B4B4B" />
                                <path id="Vector_29" d="M65.7797 68.3398H59.6597V68.9898H65.7797V68.3398Z" fill="#4B4B4B" />
                            </g>
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_131_10505">
                            <rect width="89.74" height="154.86" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </CircleSvg>
        </>
    )
}

export default TestResultCircleAnimation