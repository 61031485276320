import { useEffect } from 'react';
import {
  forceSimulation,
  forceLink,
  forceManyBody,
  forceX,
  forceY,
} from 'd3-force';
import { useReactFlow, Node,useStore } from 'reactflow';

const elementCountSelector = (state) =>{
    return  state.nodeInternals.size + state.edges.length;

}
 
const nodesInitializedSelector = (state) =>{
  // console.log(state)
 return  Array.from(state.nodeInternals.values()).every(
    (node) => node.width && node.height
  ) && state.nodeInternals.size;


}


function useForceLayout({ strength = -1000, distance = 150 }) {
  const elementCount = useStore(elementCountSelector);
  const nodesInitialized = useStore(nodesInitializedSelector);
  const { setNodes, getNodes, getEdges,setEdges } = useReactFlow();
 
  useEffect(() => {
    const nodes = getNodes();
    const edges = getEdges();
  //   console.log(nodes)
  //  console.log(edges)
    if (!nodes?.length || !nodesInitialized) {
      return;
    }

    const simulationNodes = nodes.map((node) => ({
      ...node,
      x: node.position.x,
      y: node.position.y,
    }));
  
    const simulationLinks = edges.filter((edge) => {
      const targetId = edge.target;
      return simulationNodes.some((node) => node.id === targetId);
    });
    // console.log(simulationNodes)
    // console.log(simulationLinks)

    const simulation = forceSimulation()
      .nodes(simulationNodes)
      .force('charge', forceManyBody().strength(strength))
      .force(
        'link',
        forceLink(simulationLinks)
          .id((d) => d.id)
          .strength(0.05)
          .distance(distance)
      )
      .force('x', forceX().x(0).strength(0.08))
      .force('y', forceY().y(0).strength(0.08))
      .on('tick', () => {
        setNodes(
          simulationNodes.map((node) => ({
            id: node.id,
            data: node.data,
            position: { x: node.x ?? 0, y: node.y ?? 0 },
            className: node.id==1 ?'career-box-you':'career-box',
            careerId:node.careerId
          }))
        );
      });

    return () => {
      simulation.stop();
    };
  }, [
    elementCount,
    getNodes,
    getEdges,
    setNodes,
    setEdges,
    strength,
    distance,
    nodesInitialized,
  ]);
}

export default useForceLayout;
