import React from 'react';
import {styled} from '@mui/material/styles';
import { Box, Typography, Grid, Button, Stack, Chip, Hidden, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CustomChip from '../../components/common/CustomChip';
import colors2 from '../../pages/codingscreen/colors2';

// const TrackBox = styled(Box)(({ theme }) => ({
//     width: '100%',
//     height: 92,
//     border: `0.5px solid #959595`,
//     borderRadius: '10px',

//     [theme.breakpoints.down('md')]: {
//         height: 84,
//     },

//     [theme.breakpoints.up('xl')]: {
//         height: 105,
//     },

//     '&:hover' : {
//         boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px`,
//         transition: `0.2s ease-in-out`,
//     }
// }));

// const tags = ['Math Fundamentals', 'Factorial', 'Recursion']


const TrackInfo = (props) => {
    const history = useHistory();
    const [isDisabled, setIsDisabled] = useState(false);
    // const [allowed, setAllowed] = useState(false);
    const [allowed, setAllowed] = useState(false);
    const [topics, setTopics] = useState([]);

    useEffect(() => {
        if (props.completed === 1 || props.open === true) {
            setAllowed(true);
        }
        else {
            setAllowed(false);
        }
    }, [props.completed, props.open]);
    
    useEffect(() => {
        if (props.topics_tagged && props.topics_tagged.length > 0) {
            const topicsTagged = props.topics_tagged.filter(tag => tag.id !== null)
            setTopics(topicsTagged);
            // console.log(`topics tagged: ${props.heading}`);
            // console.log(topicsTagged);
        }
    }, [props.topics_tagged]);

    // useEffect(() => {
    //     if (props?.attempted === 1 || props.completed === 1 || props.display_question === props.id) {
    //         setAllowed(true);
    //     }
    //     else if (props.display_question === 0 && props.order === 1 ) {
    //         setAllowed(true);
    //     }
    // }, [props.display_question]);

    const handleCodingButton = (id) => {
        if (allowed) {
            history.push('/codingTrackConsole', {"question_id": id, "display_question": props.display_question});
        }
    }

    return (
        <>
            <Box 
                sx={{
                    width: '100%',
                    // height: 'auto',
                    height: '95px',
                    border: `1px solid ${colors2.grey[100]}`,
                    borderRadius: '15px',
                    boxShadow: '4px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                    // cursor: 'pointer',
                    '&:hover' : {
                        boxShadow: `4px 4px 15px 0px rgba(0, 0, 0, 0.15)`,
                        transition: `0.2s ease-in-out`,
                    }
                }}
                // onClick={() => handleCodingButton(props.id)}
            >
                <Grid container alignItems="center" justifyContent="center" height="100%" width="100%">
                    <Grid item xs={8} height="100%" sx={{maxWidth: '70%', overflowX: 'hidden'}}>
                        <Grid container direction="column" alignItems="flex-start" justifyContent="space-evenly" height="100%" sx={{marginLeft: "3%"}}>
                            <Grid item>
                                {/* <Typography variant='body5' noWrap>{props.number}. {props.heading}</Typography> */}
                                <Typography variant='body5' onClick={() => handleCodingButton(props.id)} sx={{cursor: 'pointer'}} noWrap>{props.heading}</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="flex-start" sx={{flexWrap: 'wrap', maxHeight: '20px', overflow: 'hidden'}}>
                                    {topics.map((tag, index) => {
                                        if (index < 3) {
                                        return (
                                            // <Chip label={tag} variant='filled' color='primary' size='small' sx={{borderRadius: '4px', fontSize: '12px', marginTop: '5px', fontWeight: '600', }}/>
                                            <Grid item sx={{margin: '0px 2.5px'}}>
                                                <CustomChip label={tag.name} bgColor={colors2.secondary} textColor={colors2.primary} borderRadius={'5px'} height={'20px'}/>
                                            </Grid>
                                        )
                                        }
                                        return null;
                                    })}
                                    {topics.length > 3 ? 
                                        <Grid item sx={{margin: '0px 2.5px'}} onClick={() => props.handleOpenPopup(props.id, topics)}>
                                            <CustomChip label={`+${topics.length - 3} More`} cursor={'pointer'} hoverBg={colors2.plusTagHover} bgColor={colors2.backgroundSecondary} textColor={colors2.textPrimary} borderRadius={'5px'} height={'20px'}/>
                                        </Grid>
                                    : null}
                                </Grid>
                            </Grid>
                            {/* <Grid item>
                                <Typography variant="body2" color={props.difficulty === 'Easy' ? 'green' : props.difficulty === 'Medium' ? 'orange' : 'red'} sx={{marginLeft: '5px'}}>{props.difficulty}</Typography>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        {/* <Grid container height="100%" alignItems="center" justifyContent="space-evenly">
                            <Hidden mdDown>
                                <Grid item xs={0} md={4}>
                                    <Typography variant="body1" sx={{textAlign: 'right', marginRight: '10px'}}>{props.percentageDone !== '0%' ? `Score: ${props.percentageDone}` : null}</Typography>
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} md={8}>
                                <Button 
                                    variant="contained" 
                                    size="small" 
                                    onClick={() => handleCodingButton(props.id)}
                                    sx={{pointerEvents: allowed ? 'auto' : 'none',background: props.completed ? colors2.primary : colors2.secondary, opacity: props.completed ? '0.5' : '1', color: props.completed ? colors2.common.white : colors2.primary, height: '30px', borderRadius: '6px', '&:hover': {background: colors2.primary, color: colors2.secondary}}}
                                >
                                    <Typography variant='body1'>{props.completed ? 'Completed' : 'Start coding'}</Typography>
                                </Button>
                            </Grid>
                        </Grid> */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                                paddingRight: '15px',
                                gap: '10px',
                            }}
                        >
                            <Typography variant="caption1" color={props.difficulty === 'Easy' ? colors2.easy : props.difficulty === 'Medium' ? colors2.medium : colors2.hard} sx={{marginLeft: '5px'}}>{props.difficulty}</Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    // paddingRight: '15px'
                                }}
                            >
                                <Typography variant="body1" color={colors2.primaryDarker} sx={{textAlign: 'right', marginRight: '10px'}}>{props.percentageDone !== '0%' ? `Score: ${props.percentageDone}` : null}</Typography>
                                <Tooltip 
                                    title={allowed ? '' : 'Complete earlier questions in the sequence to unlock this one'} 
                                    placement='top' 
                                    arrow
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                bgcolor: colors2.companyTagPrimary, 
                                                color: colors2.backgroundSecondary,
                                            }
                                        },
                                        arrow: {
                                            sx: {
                                                color: colors2.companyTagPrimary
                                            }
                                        }
                                    }}
                                >
                                    <Button 
                                        variant="contained" 
                                        size="small" 
                                        onClick={() => handleCodingButton(props.id)}
                                        sx={{
                                            // pointerEvents: allowed ? 'auto' : 'none',
                                            background: props.completed ? colors2.completedSecondary : allowed ? colors2.secondary : colors2.disabled, 
                                            color: props.completed ? colors2.completedPrimary : allowed ? colors2.primary : colors2.disabledText, 
                                            height: '30px', 
                                            borderRadius: '6px', 
                                            '&:hover': {
                                                background: props.completed ? colors2.completedPrimary : allowed ? colors2.primaryDarker : colors2.disabled, 
                                                color: props.completed ? colors2.completedSecondary : allowed ? colors2.common.white : colors2.disabledText
                                            }
                                        }}
                                    >
                                        <Typography variant='body1'>{props.completed ? 'Completed' : 'Start coding'}</Typography>
                                    </Button>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default TrackInfo;