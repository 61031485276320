import moment from "moment";
import axios from "../../interceptor";
import * as Sentry from "@sentry/react";

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
const ALLOWED_COLLEGES = process.env.REACT_APP_ALLOWED_COLLEGES;

const plain_error_obj = {
  api_name: null,
  status: null,
  api_status: null,
  timestamp: null,
};

export const GETLOGINOTP = "GET_LOGIN_OTP";
export const CHANGELOGINMETHODTOOTP = "CHANGE_LOGIN_METHOD_TO_OTP";
export const OPENFORGOTPASSWORD = "OPEN_FORGOT_PASSWORD";

export function setGlobalToken() {
  try {
    let token = localStorage.getItem("token");
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } catch (error) {
    console.log(error);
  }
}

export const getLoginOtpA = (res, roll_number, resent, err) => {
  return {
    type: GETLOGINOTP,
    res: res,
    error: err,
    roll_number: roll_number,
    resent: resent,
  };
};

export const getLoginOtpAC = (resent) => {
  return (dispatch, getState) => {
    const formdata = new FormData();
    let roll_number = getState().login.roll_number;
    formdata.append("roll_number", roll_number);

    const error_obj = { ...plain_error_obj };
    error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
    error_obj.api_name = `/auth/getLoginOtp`;
    error_obj.roll_number = roll_number;

    http
      .post(`${BASE_API_URL}/auth/getLoginOtp`, formdata)
      .then((res) => {
        error_obj.status = res.status;
        error_obj.api_status = res.data?.status;
        if (res.status === 200) {
          if (res.data.status === 200) {
            dispatch(getLoginOtpA(res.data, roll_number, resent, false));
          } else {
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(getLoginOtpA(null, roll_number, resent, true));
          }
        } else {
          console.log("in error dispatch 3");
          dispatch(getLoginOtpA(null, roll_number, resent, true));
        }
      })
      .catch((err) => {
        console.log(err);
        error_obj.status = err?.request?.status;
        Sentry.captureException(error_obj, (scope) => {
          scope.setTransactionName(error_obj.api_name);
          return scope;
        });
        dispatch(getLoginOtpA(null, roll_number, resent, true));
      });
  };
};

export const GETUSERDETAILS = "GET_USER_DETAILS";
export const OPENENTERROLLNUMBERAGAIN = "OPEN_ENTER_ROLLNUMBER_AGAIN";
export const GETUSERPASSWORDDETAILS = "GET_USER_PASSWORD_DETAILS";
export const UPDATEPASSWORD = "UPDATE_PASSWORD";

export const getUserDetailsA = (res, err, message, incorrect_attempts, last_incorrect_time) => {
  return {
    type: GETUSERDETAILS,
    res: res,
    error: err,
    message: message,
    incorrect_attempts: incorrect_attempts,
    last_incorrect_time: last_incorrect_time,
  };
};

export const getUserPasswordDetailsA = (res, err, roll_number, msg, last_incorrect_attempt) => {
  return {
    type: GETUSERPASSWORDDETAILS,
    res: res,
    error: err,
    roll_number: roll_number,
    message: msg,
    last_incorrect_attempt: last_incorrect_attempt
  };
};

export const updatePasswordA = (res, err) => {
  return {
    type: UPDATEPASSWORD,
    res: res,
    error: err,
  };
};
export const getUserDetailsAC = (otp, password) => {
  // console.log(otp);
  return (dispatch, getState) => {
    let roll_number = getState().login.roll_number;
    let payload;
    if (otp) payload = `roll_number=${roll_number}&otp=${otp}`;
    if (password) payload = `roll_number=${roll_number}&password=${password}`;

    const error_obj = { ...plain_error_obj };
    error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
    error_obj.api_name = `/auth/v3/getUserDetails`;
    error_obj.roll_number = roll_number;

    http
      .get(`${BASE_API_URL}/auth/v3/getUserDetails?${payload}`)
      .then((res) => {
        error_obj.status = res.status;
        error_obj.api_status = res.data?.status;
        if (res.status === 200) {
          if (
            res.data.status === 200
          ) {
            if (res.data.data?.password_disabled && password)
              dispatch(
                getUserDetailsA(
                  null,
                  true,
                  res.data.data?.message
                )
              );
            else {
              localStorage.setItem("user", JSON.stringify(res.data.data));
              localStorage.setItem("token", res.data.data.token);
              localStorage.setItem(
                "refresh_token",
                res.data.data.refresh_token
              );
              dispatch(getUserDetailsA(res.data, false));
            }
          } else if (res.data.status === 422) {
            if (res.data.message === "Enter valid OTP")
              dispatch(
                getUserDetailsA(
                  null,
                  true,
                  "Invalid OTP, Please enter a valid OTP",
                  res.data.password_incorrect_attempts,
                  res.data.last_password_incorrect
                )
              );
            else if (res.data.message === "Enter valid password")
              dispatch(
                getUserDetailsA(
                  null,
                  true,
                  "Invalid Password, Please enter valid password",
                  res.data.password_incorrect_attempts,
                  res.data.last_password_incorrect
                )
              );
            else if (password)
              dispatch(
                getUserDetailsA(null, true, "Multiple incorrect attempts")
              );
            else dispatch(getUserDetailsA(null, true, "Error occured"));
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
          } else {
            dispatch(
              getUserDetailsA(null, true, "Please enter a valid roll number")
            );
          }
        } else {
          dispatch(getUserDetailsA(null, true));
        }
      })
      .catch((err) => {
        console.log(err);
        error_obj.status = err?.request?.status;
        Sentry.captureException(error_obj, (scope) => {
          scope.setTransactionName(error_obj.api_name);
          return scope;
        });
        dispatch(getUserDetailsA(null, true));
      });
  };
};

export const getUserPasswordDetailsAC = (roll_number) => {
  return (dispatch, getState) => {
    const error_obj = { ...plain_error_obj };
    error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
    error_obj.api_name = `/auth/v3/getUserPasswordDetails`;
    error_obj.roll_number = roll_number;

    http
      .get(
        `${BASE_API_URL}/auth/v2/getUserPasswordDetails?roll_number=${roll_number}`
      )
      .then((res) => {
        error_obj.status = res.status;
        error_obj.api_status = res.data?.status;
        if (
          res.status === 200 &&
          res.data?.status === 200 &&
          !res.data.password_disabled
        ) {
          dispatch(getUserPasswordDetailsA(res.data, false, roll_number));
        } else if (
          res.status === 200 &&
          res.data?.status === 200 &&
          res.data.password_disabled
        ) {
          dispatch(getUserPasswordDetailsA(res.data, true, roll_number, res.data.display_message,res.data.last_incorrect_attempt));
        } else {
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
          dispatch(getUserPasswordDetailsA(null, true, roll_number));
        }
      })
      .catch((err) => {
        error_obj.status = err?.request?.status;
        Sentry.captureException(error_obj, (scope) => {
          scope.setTransactionName(error_obj.api_name);
          return scope;
        });
        dispatch(getUserPasswordDetailsA(null, true, roll_number));
      });
  };
};

export const updateToken = () => {
  let refresh_token = localStorage.getItem("refresh_token");
  http.defaults.headers.common["Authorization"] = `Bearer ${refresh_token}`;
  http
    .get(`${BASE_API_URL}/auth/refresh`)
    .then((refres) => {
      localStorage.setItem("token", refres.data.token);
      setGlobalToken();
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const updatePasswordAC = (password) => {
  setGlobalToken();
  return (dispatch, getState) => {
    const formdata = new FormData();
    let roll_number = getState().login.roll_number;
    formdata.append("password", password);
    formdata.append("roll_number", roll_number);

    http
      .post(`${BASE_API_URL}/auth/v2/updatePassword`, formdata)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.status == 200) {
            console.log("inside data status ");
            dispatch(updatePasswordA(res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            if (updateToken) {
              http
                .post(`${BASE_API_URL}/auth/v2/updatePassword`, formdata)
                .then((res) => {
                  if (res.status == 200) {
                    if (res.data.status == 200) {
                      dispatch(updatePasswordA(res.data, false));
                    } else if (res.data.status === 422) {
                      dispatch(updatePasswordA(res.data, true));
                    } else dispatch(updatePasswordA(null, true));
                  } else {
                    dispatch(updatePasswordA(null, true));
                  }
                });
            } else {
              dispatch(updatePasswordA(null, true));
            }
          } else if (res.data.status === 422)
            dispatch(updatePasswordA(res.data, true));
          else {
            dispatch(updatePasswordA(null, true));
          }
        } else {
          dispatch(updatePasswordA(null, true));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updatePasswordA(null, true));
      });
  };
};