import React, {useState, useEffect} from 'react';
import { Box, Button, Divider, Modal, Typography } from '@mui/material';
import colors2, { darkModeColors } from '../../pages/codingscreen/colors2';
import {styled, useTheme} from '@mui/material/styles';
import { CloseX } from '../../assets/codingModule/CloseX';

const LogModalBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    width: '65%',
    maxHeight: '70%',
    outline: 0,
    borderRadius: '10px',
    p: 4,
    // paddingLeft: '1%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
        width: '4px', 
    },
    '&::-webkit-scrollbar-thumb': {
        background: colors2.grey[600], 
        borderRadius: '4px', 
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: colors2.grey[800], 
    },
    [theme.breakpoints.down('md')]: {
        width: '75%',
    },
}));

const StreamBox = styled(Box)(({ theme }) => ({
    // background: theme.palette.grey[100],
    // color: (theme) => theme.palette.grey[500],
    padding: '10px',
    margin: '10px 30px',
    marginBottom: '25px',
    // border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '5px',
    width: '95%',
    maxheight: '250px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
        width: '4px', 
    },
    '&::-webkit-scrollbar-thumb': {
        background: colors2.grey[600], 
        borderRadius: '4px', 
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: colors2.grey[800], 
    },
}));

const LogDetails = ({openLogInfo, isLogOpen, handleCloseLog, handleTimeStampFormatConversion, goToNextQues, isDarkMode}) => {
    const [paletteColors, setPaletteColors] = useState(colors2);
    const [statusName, setStatusName] = useState('Undefined');
    const [statusColor, setStatusColor] = useState('#959595');

    useEffect(() => {
        const handleKeyDown = (e) => {
          // Prevent Ctrl+C (or Cmd+C on Mac) for copying
          if ((e.ctrlKey || e.metaKey) && (e.key === 'c' || e.key === 'v')) {
            e.preventDefault();
          }
        };
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (isDarkMode) {
            setPaletteColors(darkModeColors);
        }
        else {
            setPaletteColors(colors2);
        }
    }, [isDarkMode]);

    useEffect(() => {
        if (openLogInfo?.status_code) {
            let status_color = '#959595';
            let determinedStatus = 'Undefined';
            
            if (openLogInfo?.status_code === 15 || openLogInfo?.status_code === 14) {
                if (openLogInfo?.submission_type === 1) {
                    determinedStatus = 'Compiled';
                    status_color = 'green';
                }
                else if (openLogInfo?.testcases?.length > 0) {
                    let allStatus15 = false;
                    let someStatus15 = false;
                    allStatus15 = openLogInfo?.testcases?.every(item => item?.status_code === 15);
                    if (allStatus15) {
                        determinedStatus = 'Accepted';
                        status_color = 'green';
                    }
                    else {
                        someStatus15 = openLogInfo?.testcases?.some(item => item?.status_code === 15);
                        determinedStatus = someStatus15 ? 'Partially Accepted' : 'Wrong Answer';
                        status_color = 'orange';
                    }
                }
            }
            else {
                if (openLogInfo?.status_code === 11) {
                    determinedStatus = 'Compilation error';
                    status_color = 'red';
                }
                else if (openLogInfo?.status_code === 12) {
                    determinedStatus = 'Runtime error';
                    status_color = 'red';
                }
                else if (openLogInfo?.status_code === 17) {
                    determinedStatus = 'Limit exceeded';
                    status_color = 'red';
                }
                else if (openLogInfo?.status_code === 19) {
                    determinedStatus = 'Illegal system call';
                    status_color = 'red';
                }
                else if (openLogInfo?.status_code === 20) {
                    determinedStatus = 'Internal error';
                    status_color = 'red';
                }
                else {
                    determinedStatus = openLogInfo?.status_name;
                    status_color = '#959595';
                }
            }
            setStatusName(determinedStatus);
            setStatusColor(status_color);
        }

    }, [openLogInfo]);

    const formatData = (text) => {
        const lines = text.split('\n');
        const fragmentStyle = {
            whiteSpace: 'pre', // Preserve spaces and line breaks
        };
        return (
            <div style={{ whiteSpace: 'pre-wrap' }}>
                {lines.map((line, index) => (
                    <div key={index}>{line}</div>
                ))}
            </div>
        );
    };

    return (

            <Modal
                open={isLogOpen}
                onClose={handleCloseLog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <LogModalBox sx={{background: !isDarkMode ? paletteColors.common.white : paletteColors.backgroundPrimary}}>
                    <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '15px',
                        }}
                    >
                        <Typography variant='body5' color={!isDarkMode ? paletteColors.primaryLighter: paletteColors.textSecondary}>Output Log</Typography>
                        {/* <img src={`/images/close_x.svg`} alt="Close" onClick={handleCloseLog} style={{cursor: 'pointer'}}/> */}
                        <Box onClick={handleCloseLog} style={{cursor: 'pointer'}}>
                            <CloseX backgroundColor={paletteColors.textPrimary} />
                        </Box>
                    </Box>
                    {openLogInfo?.submission_type === 3 && statusName !== 'Partially Accepted' ?
                        <Box
                            sx={{
                                margin: '25px auto',
                                marginTop: '0px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                            }}
                        >
                            <img src={openLogInfo.status_code === 15 ? `/gifs/correct_tick_animation.gif` : '/images/error_cross_red.svg'} alt="Submit Status" style={{height: '60px', width: '60px'}} />
                            <Typography variant="h6" color={openLogInfo?.status_code === 15 ? paletteColors.easy : paletteColors.hard} sx={{margin: openLogInfo?.status_code === 15 ? '0px' : '10px 0px 5px 0px'}}>{openLogInfo?.status_code === 15 ? 'Well Done!' : openLogInfo?.status_code === 14 ? 'Wrong Answer' : 'Error'}</Typography>
                            <Typography variant="body1" sx={{color: !isDarkMode ? paletteColors.grey[600] : paletteColors.textPrimary}}>{openLogInfo?.status_code === 15 ? 'You have successfully submitted the question with zero errors' : 'The answer you have submitted is not correct'}</Typography>
                            {openLogInfo?.status_code === 15 ?
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        gap: '20px',
                                        marginTop: '20px',
                                    }}
                                >
                                    {/* <Button 
                                        variant='filled' 
                                        size='small' 
                                        sx={{background: !isDarkMode ? paletteColors.secondary : paletteColors.grey1, padding: '5px 15px', marginBottom: '10px', '&:hover' : {background: !isDarkMode ? paletteColors.secondary : paletteColors.grey1}}} 
                                        onClick={handleCloseLog}
                                    >
                                        <Typography variant="body1" color={!isDarkMode ? paletteColors.primary : paletteColors.textPrimary}>Continue</Typography>
                                    </Button> */}
                                    <Button 
                                        variant='filled' 
                                        size='small' 
                                        sx={{background: paletteColors.primary, padding: '5px 15px', marginBottom: '10px', '&:hover' : {background: paletteColors.primary, color: paletteColors.common.white}}} 
                                        onClick={() => goToNextQues()}
                                    >
                                        <Typography variant="body1" color={!isDarkMode ? paletteColors.secondary : paletteColors.common.white}>Next question</Typography>
                                    </Button>
                                </Box>
                            : null}
                        </Box>
                    : null}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            padding: '10px',
                            paddingLeft: '20px',
                            textAlign: 'left',
                            background: !isDarkMode ? paletteColors.secondary : paletteColors.grey1,
                            margin: '0px 15px 10px 15px', 
                            overflow: 'hidden',
                            borderRadius: '5px',
                        }}
                    >
                        <Typography variant='body2' color={!isDarkMode ? paletteColors.primary : paletteColors.textSecondary} width="30%">Status</Typography>
                        <Typography variant='body2' color={!isDarkMode ? paletteColors.primary : paletteColors.textSecondary} width="30%">Details</Typography>
                        <Typography variant='body2' color={!isDarkMode ? paletteColors.primary : paletteColors.textSecondary} width="25%">Type</Typography>
                        <Typography variant='body2' color={!isDarkMode ? paletteColors.primary : paletteColors.textSecondary} width="15%">Points</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            padding: '10px',
                            paddingLeft: '20px',
                            textAlign: 'left',
                            margin: '0px 15px',
                            overflow: 'hidden',
                            borderRadius: '5px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                // gap: '5px',
                                width: "30%"
                            }}
                        >
                            {/* <Typography variant='body3' color={openLogInfo?.status_code === 15 ? paletteColors.easy : openLogInfo?.status_code === 14 ? paletteColors.medium : openLogInfo?.status_code === 11 || openLogInfo?.status_code === 12 || openLogInfo?.status_code === 19 || openLogInfo?.status_code === 20 ? paletteColors.errorPrimary : (theme) => theme.palette.grey[700]} sx={{textTransform: 'capitalize'}}>{openLogInfo?.status_name}</Typography> */}
                            <Typography variant='body3' color={statusColor === 'green' ? paletteColors.easy : statusColor === 'orange' ? paletteColors.medium : statusColor === 'red' ? paletteColors.errorPrimary : paletteColors.grey[700]} sx={{textTransform: 'capitalize'}}>{statusName}</Typography>
                            <Typography variant='body1' color={paletteColors.textPrimary}>{handleTimeStampFormatConversion(openLogInfo?.submission_timestamp)}</Typography>
                        </Box>
                        <Typography variant='body2' color={paletteColors.textPrimary} width="30%">{openLogInfo?.execution_memory}kB / {openLogInfo?.execution_time}s / <Typography variant='body2' color={paletteColors.primary} sx={{display: 'inline-block'}}>{openLogInfo?.compiler_name}</Typography></Typography>
                        <Typography variant='body2' color={paletteColors.textPrimary} width="25%">{openLogInfo?.submission_type === 1 ? `Run Code` : openLogInfo?.submission_type === 2 ? `Run Test Cases` : 'Submit Code'}</Typography>
                        <Typography variant='body2' color={paletteColors.textPrimary} width="15%">{openLogInfo?.score !== null ? openLogInfo?.score : '-'}</Typography>
                    </Box>
                    <Box sx={{height: '1px', background: !isDarkMode ? paletteColors.grey[200] : paletteColors.grey1, margin: '20px',}}></Box>
                    {openLogInfo?.compilation_data !== null ? 
                        <Box sx={{color: paletteColors.textPrimary}}>
                            <Typography variant="body4" sx={{margin: '20px 30px'}}>Error Information</Typography>
                            <StreamBox sx={{background: !isDarkMode ? paletteColors.errorSecondary : paletteColors.backgroundSecondary, color: paletteColors.errorPrimary, border: 'none'}}>
                                <Typography variant='body1'>
                                    {openLogInfo?.compilation_data ? formatData(openLogInfo?.compilation_data) : null}
                                </Typography>
                            </StreamBox>
                        </Box>
                    : null
                    }
                    {openLogInfo?.testcases && openLogInfo.testcases.length > 0 ? 
                        <Box sx={{color: paletteColors.textPrimary}}>
                            <Typography variant="body4" sx={{margin: '20px 30px'}}>Test Cases</Typography>
                            <StreamBox sx={{border: !isDarkMode ? `1px solid ${paletteColors.grey[200]}` : `none`, color: !isDarkMode ? paletteColors.grey[600] : paletteColors.textSecondary, background: !isDarkMode ? paletteColors.common.white : paletteColors.grey1,}}>
                                {openLogInfo?.testcases.map((test_case) => (
                                <Typography variant='body1' sx={{margin: '15px 0px'}}>
                                    {`test ${test_case?.test_case_no} - ${test_case?.status_name} (time = ${test_case?.timetaken}, memory = ${test_case?.memory})`}
                                </Typography>
                                ))}
                            </StreamBox>
                        </Box>
                    : null
                    }
                    {openLogInfo?.input !== null ? 
                        <Box sx={{color: paletteColors.textPrimary}}>
                            <Typography variant="body4" sx={{margin: '20px 30px'}}>Input (stdin stream)</Typography>
                            <StreamBox sx={{border: !isDarkMode ? `1px solid ${paletteColors.grey[200]}` : `none`, color: !isDarkMode ? paletteColors.grey[600] : paletteColors.textSecondary, background: !isDarkMode ? paletteColors.common.white : paletteColors.grey1}}>
                                <Typography variant='body1'>
                                    {openLogInfo?.input ? formatData(openLogInfo?.input) : null}
                                </Typography>
                            </StreamBox>
                        </Box>
                    : null
                    }
                    {openLogInfo?.output !== null && openLogInfo?.submission_type === 1 ? 
                        <Box sx={{color: paletteColors.textPrimary}}>
                            <Typography variant="body4" sx={{margin: '20px 30px'}}>Output (stdout stream)</Typography>
                            <StreamBox sx={{border: !isDarkMode ? `1px solid ${paletteColors.grey[200]}` : `none`, color: !isDarkMode ? paletteColors.grey[600] : paletteColors.textSecondary, background: !isDarkMode ? paletteColors.common.white : paletteColors.grey1}}>
                                <Typography variant='body1'>
                                    {openLogInfo?.output ? formatData(openLogInfo?.output) : null}
                                </Typography>
                            </StreamBox>
                        </Box>
                    : null
                    }
                    {openLogInfo?.error !== null ? 
                        <Box sx={{color: paletteColors.textPrimary}}>
                            <Typography variant="body4" sx={{margin: '20px 30px'}}>Errors (stderr stream)</Typography>
                            <StreamBox sx={{border: !isDarkMode ? `1px solid ${paletteColors.grey[200]}` : `none`, color: !isDarkMode ? paletteColors.grey[600] : paletteColors.textSecondary, background: !isDarkMode ? paletteColors.common.white : paletteColors.grey1}}>
                                <Typography variant='body1'>
                                    {openLogInfo?.error ? formatData(openLogInfo?.error) : null}
                                </Typography>
                            </StreamBox>
                        </Box>
                    : null
                    }
                    {openLogInfo?.source !== null ? 
                        <Box sx={{color: paletteColors.textPrimary}}>
                            <Typography variant="body4" sx={{margin: '20px 30px'}}>Source Code</Typography>
                            <StreamBox sx={{border: !isDarkMode ? `1px solid ${paletteColors.grey[200]}` : `none`, color: !isDarkMode ? paletteColors.grey[600] : paletteColors.textSecondary, background: !isDarkMode ? paletteColors.common.white : paletteColors.grey1}}>
                                <Typography variant='body1'>
                                    {openLogInfo?.source ? formatData(openLogInfo?.source) : null}
                                </Typography>
                            </StreamBox>
                        </Box>
                    : null
                    }
                </LogModalBox>
            </Modal>
    )
}

export default LogDetails;


// {openLogInfo?.status_name}
// {handleTimeStampFormatConversion(openLogInfo?.submission_timestamp)}