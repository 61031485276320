import React, { Component } from 'react';
import styles from './SphereEngine.module.css';


class SphereEngine extends Component {


  render() {

    let widget = <div className="se-widget" data-widget={this.props.wcode} data-user-id={this.props.user_key}></div>

    return (<div className={styles.SphereEngine}>

      {widget}

    </div>)
  }
}

SphereEngine.propTypes = {};

SphereEngine.defaultProps = {};

export default SphereEngine;
