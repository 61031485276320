import axios from '../../interceptor';
import moment from 'moment';

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
let CORS = 'https://cors-anywhere.herokuapp.com/';

export function setGlobalToken() {
    try {
        let token = localStorage.getItem('token');
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    } catch (error) {
      console.log(error);
    }
};

export const GETSUBJECTIVEASSESSMENT = 'GET_SUBJECTIVE_ASSESSMENT';
export const SUBMITSUBJECTIVEQUESTION = 'SUBMIT_SUBJECTIVE_QUESTION';

export const getSubjectiveAssessmentA = (res, id, err, already_submitted) => {
  return {
    type: GETSUBJECTIVEASSESSMENT,
    id: id,
    res: res,
    err: err,
    already_submitted: already_submitted
  }
}

export const submitSubjectiveQuestionA = (index, err) => {
  return {
    type: SUBMITSUBJECTIVEQUESTION,
    index: index,
    err: err
  }
}

export const getSubjectiveAssessmentAC = (test_id) => {
  console.log('in action creator')
  setGlobalToken();
  return (dispatch, getState) =>{
      http.get(`${BASE_API_URL}/questionnaire/v2/getSubjectiveTest?test_id=${test_id}`)
      .then((res)=> {
          if (res.status ==200) {
              if (res.data.status == 200 || res.data.status == 409) {
                  if (res.data.status == 409) {
                    dispatch(getSubjectiveAssessmentA(res.data, test_id, false, true));
                  } else {
                    let date = new Date();
                    let difference = moment.duration(moment(res.data.date_of_expiry).diff(moment(date)));
                    let difference2 = parseInt(difference.asSeconds());
                    if (res.data.timelimit > difference2) {
                        res.data.expiryTime = difference2;
                    } else res.data.expiryTime = res.data.timelimit;
                    console.log('done test')
                    dispatch(getSubjectiveAssessmentA(res.data, test_id, false));
                  }
                  
              } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                  let refresh_token = localStorage.getItem('refresh_token');
                  http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                  http.get(`${BASE_API_URL}/auth/refresh`)
                  .then((refres)=> {
                      localStorage.setItem('token', refres.data.token);
                      setGlobalToken();
                      http.get(`${BASE_API_URL}/questionnaire/v2/getSubjectiveTest?test_id=${test_id}`)
                      .then((res)=> {
                          if (res.status == 200) {
                              if (res.data.status == 200 || res.data.status == 409) {
                                  if (res.data.status == 409) {
                                    dispatch(getSubjectiveAssessmentA(res.data, test_id, false, true));
                                  } else {
                                    let date = new Date();
                                    let difference = moment.duration(moment(res.data.date_of_expiry).diff(moment(date)));
                                    let difference2 = parseInt(difference.asSeconds());
                                    if (res.data.timelimit > difference2) {
                                        res.data.expiryTime = difference2;
                                    } else res.data.expiryTime = res.data.timelimit;
                                    console.log('done test')
                                    dispatch(getSubjectiveAssessmentA(res.data, test_id, false));
                                  }
                                  
                              } else {
                                  dispatch(getSubjectiveAssessmentA(null, test_id, true));
                              }
                          } else {
                            dispatch(getSubjectiveAssessmentA(null, test_id, true));
                          }
                      })
                      .catch((err)=>{
                        dispatch(getSubjectiveAssessmentA(null, test_id, true));
                      })
                  })
                  .catch((err)=> {
                      console.log(err);
                  });
              } else {
                dispatch(getSubjectiveAssessmentA(null, test_id, true));
              }
          } else {
            dispatch(getSubjectiveAssessmentA(null, test_id, true));
          }
      })
      .catch((err)=> {
          console.log('error in getting practice test')
          console.log(err)
          dispatch(getSubjectiveAssessmentA(null, test_id, true));
      });
  };
};


export const submitSubjectiveQuestionAC = (index) => {
  console.log('in action creator')
  setGlobalToken();
  return (dispatch, getState) =>{
    const formdata = new FormData();
    let answer = getState().sub.answers[index];
    formdata.append('subject_id', answer.subject_id);
    formdata.append('questionnaire_id', answer.questionnaire_id);
    formdata.append('question_id', answer.question_id);
    if (answer.answer_url && answer.answer_url.length != 0) {
      formdata.append('answer', '');
      Array.from(answer.answer_url).map((file)=> {
        console.log(file)
        formdata.append('answer_url', file);
      })
      console.log(answer.answer_url);
    }
    else {
      formdata.append('answer', answer.answer.toString('html'));
      formdata.append('answer_url', '');
    }
    http.post(`${BASE_API_URL}/questionnaire/v2/submitSubjectiveQuestionnaireQuestion`, formdata)
      .then((res)=> {
        if (res.status ==200) {
          if (res.data.status == 200) {
            dispatch(submitSubjectiveQuestionA(index, false));
          } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
            let refresh_token = localStorage.getItem('refresh_token');
            http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
            http.get(`${BASE_API_URL}/auth/refresh`)
            .then((refres)=> {
              localStorage.setItem('token', refres.data.token);
              setGlobalToken();
              http.post(`${BASE_API_URL}/questionnaire/v2/submitSubjectiveQuestionnaireQuestion`, formdata)
                .then((res)=> {
                  if (res.status == 200) {
                    if (res.data.status == 200 ) {
                      dispatch(submitSubjectiveQuestionA(index, false));
                    } else {
                      dispatch(submitSubjectiveQuestionA(index, true));
                    }
                  } else {
                    dispatch(submitSubjectiveQuestionA(index, true));
                }
              })
              .catch((err)=>{
                dispatch(submitSubjectiveQuestionA(index, true));
              })
            })
            .catch((err)=> {
                console.log(err);
            });
        } else {
          dispatch(submitSubjectiveQuestionA(index, true));
        }
    } else {
      dispatch(submitSubjectiveQuestionA(index, true));
    }
      })
      .catch((err)=> {
          console.log('error in getting practice test')
          console.log(err)
          dispatch(submitSubjectiveQuestionA(index, true));
      });
  };
};