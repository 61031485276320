import React from 'react';
import {Chip} from '@mui/material';

const MuiCustomChip1 = ({text}) => {
    // const modifiedLabel = <span style={{whiteSpace :'normal', fontSize: props?.fontSize, fontWeight: '500', color: colors2.common.white}}>{props.label}</span>;
    return (
        <Chip 
            size="small" 
            variant="filled" 
            label={text} 
            color='primary'
            sx={{
               width:'fit-content',
               p:1.2,
               borderRadius:'14px',
               background:(theme)=>theme.palette.primary[700],
               color:(theme)=>theme.palette.grey[1000],
               height:'28px',
               '& .MuiChip-label':{
                padding:'0px'
              }

            }} 
        />
    )
}

export default MuiCustomChip1;