export const timerHelper = (bannerData) => {
    let todayDateTime = ~~(new Date().getTime() / 1000)
    console.log("-----------------------------------------------------------")
    console.log(todayDateTime)
    console.log(bannerData)
    let releaseDateTime = Math.floor(new Date(bannerData?.results_release_time).getTime() / 1000);
    console.log(releaseDateTime)
    let noOfSecondsLeft = bannerData?.no_of_hours_to_end_remedial * 12 * 60 * 60;
    console.log(noOfSecondsLeft)
    let timeLeftForTest = releaseDateTime + noOfSecondsLeft;
    console.log(timeLeftForTest)
    let result = timeLeftForTest - todayDateTime;
    let TimerSecondsLeft = result > 0 ? result : 0;
    return TimerSecondsLeft
}


