import { Box } from '@mui/material'
import React from 'react'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const BorderLinearProgress = ({value , height = 8 , progressColor , barColor,threshold=false}) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
          <LinearProgress
    variant="determinate"
    value={value}
    sx={{
        height: height,
        borderRadius: 4,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: barColor,
            position: 'relative',
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 4,
            backgroundColor: progressColor,
        },
        ...(threshold && {
            '& .MuiLinearProgress-bar:before': {
                content: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'2\' height=\'14\' viewBox=\'0 0 2 14\' fill=\'none\'%3E%3Cpath d=\'M1 1.02188V13.0219\' stroke=\'%23919EAB\' stroke-linecap=\'round\'/%3E%3C/svg%3E")',
                position: 'absolute',
                height: '120%', /* Increase height as needed */
                width: 2,
                backgroundColor: '#888888', /* Change color as needed */
                left: '50%',
                top: '-10%', /* Adjust position as needed */
            }
        })
    }}
/>

        </Box>
    )
}

export default BorderLinearProgress