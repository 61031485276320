import React, { useEffect, useState } from 'react'
import StudyContentCarousel from '../../StudyContentCarousel/StudyContentCarousel'
import { readingCardRenderer } from '../../StudyContentCarousel/renderer';
import { useRemedialContext } from '../../../hooks/useRemedial';

const dummyArray = [1, 2, 3];

const ReadingLeasons = ({index , data}) => {
  const [noOfReading, setNoOfReading] = useState(0);
  const { studyPageData, setStudyPageData } = useRemedialContext();

  const handleOnChange = (num) => {
    setNoOfReading(num)
  }


  useEffect(() => {
    if (data) {
      const array = studyPageData;
      const newArray = [];
      array.forEach((item, idx) => {
        let value = idx === index ? { ...item, noOfReading: noOfReading + 1 } : item
        newArray.push(value)
      })
      setStudyPageData(newArray)
    }
  }, [noOfReading])


  console.log(studyPageData, index , 'ReadingLeasons')

  // data.noOfReading + 1 > data.snippet_current_read ? data.noOfReading + 1 : data.snippet_current_read

  return (
    <StudyContentCarousel data={data} renderer={readingCardRenderer} onChange={handleOnChange} currentSlideIndex={studyPageData[index].noOfReading > studyPageData[index].snippet_current_read  ? studyPageData[index].noOfReading : studyPageData[index].snippet_current_read } />
  )
}

export default ReadingLeasons