import { GETSUBJECTIVEASSESSMENT, SUBMITSUBJECTIVEQUESTION } from "../actions/subjectiveActions"
import RichTextEditor from 'react-rte';

let initial_state = {
    loading: true
}

const reducer = (state = initial_state, action)=> {
    if (action.type == GETSUBJECTIVEASSESSMENT) {
        if (action.err) {
            return {
                ...state,
                test_id: action.id,
                error_loading_sub: true,
                loading: false
            }
        }
        console.log(action)
        if (action.already_submitted) {
            return {
                ...state,
                test_id: action.id,
                error_loading_sub: false,
                loading: false,
                test_already_submitted: true
            }
        }
        let remaining_questions = [];
        let answers = action.res.questions.map(({ id }) => ({
            question_id: id,
            subject_id: action.res.subject_id,
            questionnaire_id: action.id,
            answer: RichTextEditor.createEmptyValue(),
            answer_url: null,
            answer_type: 'text',
            saved: 0
        }));
        answers.map((ans, index)=> {
            remaining_questions.push(index)
        })
        return {
            ...state,
            ...action.res,
            test_started: false,
            loading: false,
            error_loading_sub: false,
            test_id: action.id,
            answers: answers,
            skipped: [],
            remaining_questions: remaining_questions,
            answered: [],
            test_already_submitted: false
        }
    }
    if (action.type == 'SUB_TEST_START_FROM_INSTRUCTIONS') {
        return {
            ...state,
            test_started: true
        }
    }
    if ( action.type == 'ADD_QUESTION_IMAGE') {
        console.log(action.files)
        let answers = [...state.answers];
        answers[action.index]['answer_url'] = action.files;
        answers[action.index]['just_saved'] = false;
        return {
            ...state,
            answers: answers
        }
    }
    if (action.type == 'CHANGE_ANSWER_TYPE') {
        let answers = [...state.answers];
        answers[action.index]['answer_type'] = action.answer_type;
        answers[action.index]['just_saved'] = false;
        return {
            ...state,
            answers: answers
        }
    }
    if (action.type == 'UPDATE_ANSWER') {
        let answers = [...state.answers];
        answers[action.index]['answer'] = action.text;
        answers[action.index]['just_saved'] = false;
        return {
            ...state,
            answers: answers
        }
    }
    if (action.type == SUBMITSUBJECTIVEQUESTION) {
        if (!action.err) {
            let answers = [...state.answers];
            answers[action.index]['saved'] = 1;
            let answered = state.answered;
            let remaining_questions = state.remaining_questions;
            if (answered.indexOf(action.index) == -1) {
                answered.push(action.index);
                remaining_questions.splice(remaining_questions.indexOf(action.index), 1)
            }
            answers[action.index]['just_saved'] = true;
            console.log(state);

            return {
                ...state,
                answers: answers,
                remaining_questions: remaining_questions,
                answered: answered
            } 
        }
    }
    if (action.type == 'DELETE_UPLOADED_IMAGE') {
        console.log('deleting')
        let answers = [...state.answers];
        console.log(answers);
        let answer = {...answers[action.index]};
        let answer_url = Array.from(answer.answer_url);
        answer_url.splice(action.file_index, 1);
        answer.answer_url = answer_url;
        answers[action.index] = answer;
        answers[action.index]['just_saved'] = false;
        console.log(answers)
        return {
            ...state,
            answers: answers
        }
    }

    return {...state}
}

export default reducer