import React from 'react'
import RemedialCard from '../RemedialCard/RemedialCard'
import { Skeleton, Stack, Typography } from '@mui/material'
import Leaf from './../../../../assets/remedial/svg/clover-leaf.svg'
import { AnimatedNumber } from '../../../../utils'
import BorderLinearProgress from '../../../../components/common/BorderLinearProgress/BorderLinearProgress'


const FlukeCard = ({ value = 0, width = '100%', height = '100%' , points = 0 }) => {
  return (
    <RemedialCard width={width} height={height}>
      <Stack gap={'20px'}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Stack direction={'row'} gap={'8px'}>
            <img src={Leaf} width={20} height={20} alt='clover-leaf' />
            <Typography
              component={'h4'}
              sx={{
                fontFamily: 'Poppins-SemiBold',
                fontSize: '16px',
                color: 'grey.900'
              }}>
              Fluke
            </Typography>
          </Stack>
          <Typography
            component={'h4'}
            sx={{
              fontFamily: 'Poppins-SemiBold',
              fontSize: '19px',
              color: value > 50 ? theme => theme.palette.extraredpalette[700] :  'success.900'
            }}>
            {value >= 0 ? <AnimatedNumber n={Number(value)} showPercentage /> : <Skeleton variant='text' width={30} height={30} />}
          </Typography>
        </Stack>
        <BorderLinearProgress value={value} barColor={value > 50 ? theme => theme.palette.extraredpalette[100] : 'success.100'} progressColor={ value > 50 ? theme => theme.palette.extraredpalette[700] : 'success.main'} />
        <Typography
          component={'h4'}
          sx={{
            fontFamily: 'Poppins-Medium',
            fontSize: '12px',
            color: 'grey.600',
            textAlign: 'start',
            span: {
              fontFamily: 'Poppins-SemiBold',
              fontSize: '12px',
              color: 'grey.800'
            }
          }}>
          <span>{'>'} 65% fluke disqualifies</span> you from taking  <span>{points} marks/points</span> remedial path
        </Typography>
      </Stack>
    </RemedialCard>
  )
}

export default FlukeCard