import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom";
import styled, { keyframes, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import "./joinchallange.css";
import Studentspanel from "./otherteams";
import Table from "react-bootstrap/Table";
import ReactCountdownClock from "react-countdown-clock";
import { AiFillWechat } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import { AiOutlineSend } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import Chatwidget from "./chatwindow/chatroom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as joinchallenge from "../../store/actions/joinchallengeactions";
import * as Ably from "ably/promises";
import { channel, realtime } from "./joinchallange";
import { MdOutlinePendingActions } from "react-icons/md";
import { GiConfirmed } from "react-icons/gi";
export let timeInterval;
function Singleteamdetails(props) {
  // console.log("fewdfgdsvguhijohugyftdresdtfgyuijoihugyfcxdghj");
   console.log(props.activemembers);
  // console.log(props.captainConfirmed);
  let captainconfirmed = props.captainConfirmed;
  // console.log("Captainconfirmed" + captainconfirmed);
  const [showResults, setShowResults] = React.useState(false);
  // console.log(props.otherteams);
  const [showmodal, setShow] = useState(false);
  const [showTimer, setTimer] = useState(true);
  const [teamname, Setteamname] = useState(props.data.team_name);
  const [newmsgrecieved, Setnewmsgrecieved] = useState("");
  const [teamid, SetTeamid] = useState("");
  const [teamedit, SetTeamedit] = useState(true);
  const [vicecaptainid, SetVicecaptain] = useState("");
  
  const closemodal = () => setShow(false);
  const openmodal = () => setShow(true);
  const onFinished = () => setTimer(false);
  const onTrigger = (teamid) => {
    props.editteamname(teamname, teamid,vicecaptainid);
    SetTeamedit(false);
    if (props.CaptainConfirmedStatus == "Success") {
      setShow(false);
    }
  };
  const openchatwidget = () => {
    setShowResults(true);
    Setnewmsgrecieved(false);
    setTimeout(() => {
      document
        .getElementById("scrollintoview")
        .scrollIntoView({ behavior: "smooth" });
    }, 10);
  };
  const closechatwidget = () => {
    Setnewmsgrecieved(false);
    setShowResults(false);
  };
  const msgrecieved = () => {
    if (!showResults) {
      Setnewmsgrecieved(true);
    }
  };
  const onTimerFinished = () => setTimer(false);
  // SetTeamid(props.data.id);
  const data = "sdvs";
  let showeditoption;
  let getlocalstorage = localStorage.getItem("user");
  getlocalstorage = JSON.parse(getlocalstorage);
  // console.log(getlocalstorage["name"]);

  const sendpresenceagain = () => {
    let time = props.challengedetails.captain_conmfirmation_timer;

    //  this.props.getteamdetails(this.state.challengeid);
    timeInterval = setInterval(() => {
      //  console.log(time);
      // console.log("dvdv" + props.captainConfirmed);
      if (captainconfirmed) {
        // console.log("cleared");
        clearInterval(timeInterval);
      }
      if (time <= 10) {
        clearInterval(timeInterval);
        channel.publish(
          "team_member_status",
          `${props.data.id}_${getlocalstorage["user_id"]}`
        );
        props.getteamdetails(props.challengedetails.id);

        return;
      }
      time--;
    }, 1000);
  };
  useEffect(() => {
    sendpresenceagain();
  }, []);
  const onChangeVicecaptain= (event) => {
    SetVicecaptain(event.target.value);
  }
  return (
  !props.viewerscreen ? (
    <Maindiv>
      {showTimer ? (
        <div
          style={{
            float: "right",
            display: "flex",
            alignItems: "center",
            margin: "-27px 14px 0px 0px",
          }}
        >
          <span
            style={{ opacity: "0.5", fontWeight: "700", marginRight: "-24px" }}
          >
            Time Left:-
          </span>
          <ReactCountdownClock
            seconds={props.challengedetails.captain_conmfirmation_timer}
            alpha={0.9}
            color="red"
            size={70}
            weight={5}
            showMilliseconds={false}
            onComplete={onTimerFinished}
          />
        </div>
      ) : null}
      <h2 style={{ color: "red", marginBottom: "40px" }}>
        {props.challengedetails.title}
      </h2>
     
      <div style={{ position: "relative", top: "30px" }}>
        <div className="teamname">
          {teamname ? teamname : props.data.team_name}

          {props.data.members.map((val, key) => {
            if (
              val.student_id == getlocalstorage["user_id"] &&
              val.is_captain &&
              !props.showedit &&
              props.CaptainConfirmedStatus != "Success"
            ) {
              showeditoption = (
                <>
                  <FiEdit
                    style={{
                      color: "black",
                      cursor: "pointer",
                      padding: "2px",
                    }}
                    onClick={openmodal}
                  />
                  <span className="teamconfirmation_pending">
                    Captain Confirmation pending
                  </span>
                </>
              );
            }
          })}

          {showeditoption}
        </div>
        <Modal show={showmodal} onHide={closemodal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit your team name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="form-control"
              value={teamname}
              onChange={(e) => Setteamname(e.target.value)}
            >
              {teamname ? teamname : props.data.team_name}
            </textarea>
            <span className="teamconfirmation_pending" style={{ left: "0px" }}>
              {props.CaptainConfirmedStatus}
            </span>
            {props.data.members.map((val, key) => {
              return (
                <>{val.is_captain ? 
                 <div style={{fontWeight:"500",marginTop:"10px"}}>Select the vice captain from your team</div>:null}</> 
              );
            })}
            {/* <div>Select the vice captain from your team</div> */}
            <div onChange={onChangeVicecaptain}>
            {props.data.members.map((val, key) => {
            // console.log("-------------------"+typeof props.activemembers)
              return (
                <div> {val.student_id != getlocalstorage["user_id"] &&
                !val.is_captain && props.activemembers.includes(val.student_id) ? <>
                <input type="radio" value={val.student_id} name="vicecaptain" />{val.student_name}</>:null}</div>
              );
            })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closemodal}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={(teamid) => onTrigger(props.data.id)}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Table
          className="bordered"
          hover
          size="sm"
          style={{ width: "50%", margin: "0px auto" }}
        >
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Role</th>
              <th scope="col">Position</th>
              {/* <th scope="col">Status</th> */}
            </tr>
          </thead>
          <tbody>
            {props.data.members.map((val, key) => {
              return (
                <tr key={key}>
                  <td>
                    {val.faculty_name ? (
                      `${val.faculty_name}`
                    ) : val.student_id == getlocalstorage["user_id"] &&
                      val.is_captain &&
                      props.CaptainConfirmedStatus != "Success" ? (
                      <>
                        {val.student_name}
                        <MdOutlinePendingActions
                          style={{ color: "red", fontSize: "25px" }}
                          onClick={(teamid) => onTrigger(props.data.id)}
                        />
                      </>
                    ) : val.student_id == getlocalstorage["user_id"] &&
                      val.is_captain &&
                      props.CaptainConfirmedStatus == "Success" ? (
                      <>
                        {val.student_name}
                        <GiConfirmed
                          style={{ color: "green", fontSize: "25px" }}
                        />
                      </>
                    ) : (
                      val.student_name
                    )}
                  </td>
                  <td>{val.is_captain ? <b>Captain</b> : "Member"}</td>
                  <td>{val.faculty_name ? "Faculty" : "Student"}</td>
                  {/* <td style={{ color: "green" }}>Active</td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div
        // className="jump"
        style={{
          cursor: "pointer",
          position: "fixed",
          bottom: "20px",
          right: "25px",
        }}
        onClick={openchatwidget}
        title={`Chat with ${teamname ? teamname : props.data.team_name}`}
      >
        {newmsgrecieved ? (
          <img
            src="/gifs/new-message-recieved.gif"
            style={{
              position: "absolute",
              top: "-15px",
              left: "-39px",
              width: "41px",
              borderRadius: "20px",
            }}
          ></img>
        ) : null}
        <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
        <div>{teamname ? teamname : props.data.team_name}</div>
      </div>
      <div className={showResults ? "fadeIn" : "fadeOut"} id="supportmonboxid">
        <div
          className="supportmonbox_head col-md-12 col-sm-12"
          id="supbox"
          style={{ display: "block", height: "41px" }}
        >
          <div className="col-xs-1" style={{ float: "right" }}>
            <AiFillCloseCircle
              onClick={closechatwidget}
              style={{ fontSize: "26px", cursor: "pointer" }}
            />
          </div>
        </div>

        <Chatwidget
          roomid={`${props.challengedetails.id}_${props.data.id}`}
          foundnewmsg={msgrecieved}
        />
      </div>
      <Studentspanel data={props.otherteams} />
    </Maindiv>
    ) : (
<>
<h3 style={{marginTop:'4rem'}}>All teams</h3>
<div className="col-md-12 col-lg-12 row" style={{ height: "560px" }}>
  {props.otherteams.map((teamdetails, index) => {
    return (
      <div
        className="col-md-4 col-lg-4"
        style={{ padding: "20px" }}
        key={index}
      >
        <Table  className="bordered"
          hover
          size="sm"
          style={{ width: "80%", margin: "0px auto" }}
          >
          <thead>
            <tr>
              <th scope="col" style={{background:'red',color:'white'}}>{teamdetails.team_name}</th>
            </tr>
          </thead>
          <tbody>
            {teamdetails.members.map((eacheteamdetails, index) => {
              let teammembername;
              if (eacheteamdetails.faculty_name) {
                if (eacheteamdetails.is_captain) {
                  teammembername = `${eacheteamdetails.faculty_name}(C)`;
                } else {
                  teammembername = `${eacheteamdetails.faculty_name}`;
                }
              } else {
                if (eacheteamdetails.is_captain) {
                  teammembername = `${eacheteamdetails.student_name}(C)`;
                } else {
                  teammembername = `${eacheteamdetails.student_name}`;
                }
              }
              return (
                <tr key={index}>
                  <td style={{ fontWeight: "500" }}>
                    {teammembername}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  })}
</div>
</> )
  );
}
const Maindiv = styled.div`
  margin: 30px;
  height: 588px;
  border-radius: 50px;
  margin: 0px 30px 0px 30px;
  padding: 35px;
  position: relative;
  background-color: white;
  @media (max-width: 768px) {
    height: 530px;
  }
  @media (max-width: 1024px) {
    height: 530px;
  }
`;
function mapStateToProps(state) {
  return {
    challengedetails: state.joinchallenge.challangedetails,
    teamdetails: state.joinchallenge.teamdetails,
    allteams: state.joinchallenge.allteams,
    CaptainConfirmedStatus: state.joinchallenge.captainconfirmed,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    getteamdetails: (challengeid) =>
      dispatch(joinchallenge.getTeamMembers(challengeid)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Singleteamdetails)
);
// export default Singleteamdetails;