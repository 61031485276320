import * as liveActions from '../actions/liveActions';

const initial_state = {
    testStarted: false,
    testSubmitted: false,
    feedbackSubmitted: false,
    testSubmitting: false,
    error_submitting_answer: null,
    error_msg: null,
    error_loading_live_results: false,
    questions: []
};

const reducer = (state = initial_state, action) =>{
    if (action.type === liveActions.REFRESH_TOKEN) {
        if (action.error) {
            return {
                ...state,
                error_loading_live : true
            }
        }
        return{
            ...state
        }
    }
    else if (action.type === liveActions.CHECK_CODE) {
        if (action.error) {
            return {
                ...state,
                error_loading_live : true,
                error_msg: action.response.message
            }
        }
        return{
            ...state,
            error_loading_live : false,
            error_msg: null,
            channel_id: action.response.channel_id,
            questions: action.response.questions,
            testId: action.response.test_id,
            title: action.response.title
        }
    }
    else if (action.type === liveActions.ON_LIVE_OPTION_SELECTED) {
        if(action.error){
            return{
                ...state,
                error_submitting_answer:true
            }
        }
        return {
            ...state,
            error_submitting_answer:false,

        }
    }
    else if (action.type === liveActions.GET_LIVE_TEST_RESULTS) {
        if(action.error){
            return{
                ...state,
                error_loading_live_results:true
            }
        }
        return {
            ...state,
            error_loading_live_results:false,
            test_name: action.response.data.name,
            description: action.response.data.description,
            end_time: action.response.data.end_time,
            timetaken: action.response.data.questions.map(q => q.timelimit).reduce((a,b) => a+b),
            student_analytics: action.response.data.student_analytics[0],
            ...action.response.data
        }
    }
    else if (action.type === liveActions.GET_LIVE_SURVEY_RESULTS) {
        if(action.error){
            return{
                ...state,
                error_loading_live_results:true
            }
        }
        return {
            ...state,
            error_loading_live_results:false,
            test_name: action.response.data.name,
            description: action.response.data.description,
            end_time: action.response.data.end_time,
            // timetaken: action.response.data.questions.map(q => q.timelimit).reduce((a,b) => a+b),
            // student_analytics: action.response.data.student_analytics[0],
            ...action.response.data
        }
    }
    return state;
}



export default reducer;