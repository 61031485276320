import axios from '../../interceptor';

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;


export function setGlobalToken() {
    try {
        let token = localStorage.getItem('token');
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    } catch (error) {
      console.log(error);
    }
}

export const GETSUBJECTIVETESTRESULTS = 'GET_SUBJECTIVE_TEST_RESULTS';

export const getSubjectiveResultsA = (id, results, err) => {
    return {
        type: GETSUBJECTIVETESTRESULTS,
        results: results,
        id: id,
        error: err
    }
}

export const getSubjectiveResultsAC = (test_id) => {
    console.log('in action creator 1')
    console.log(test_id)
    setGlobalToken();
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/questionnaire/v2/subjectiveQuestionnaireResults?test_id=${test_id}`)
        .then((res)=> {
            if (res.status == 200 ) {
                console.log(res);
                if (res.data.status == 200 ) {
                    dispatch(getSubjectiveResultsA(test_id, res.data, false));
                        
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/questionnaire/v2/subjectiveQuestionnaireResults?test_id=${test_id}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    dispatch(getSubjectiveResultsA(test_id, res.data, false)); 
                                } else {
                                    dispatch(getSubjectiveResultsA(test_id, null, true));
                                }
                            } else {
                                dispatch(getSubjectiveResultsA(test_id, null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(getSubjectiveResultsA(test_id, null, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    });
                } else {
                    dispatch(getSubjectiveResultsA(test_id, null, true));
                }
                
            } else {
                console.log('in error dispatch 3');
                dispatch(getSubjectiveResultsA(test_id, null, true));

            }
            
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(getSubjectiveResultsA(test_id, null, true));
        });
    };
};
