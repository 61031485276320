import { CLOSE_NOTIFICATION, PERSONALITY_QUESTION_NOTIFICATION } from "../actions/commonActions";


const initial_state = {
    openNotificationPopover: false,
    notificationPopoverType: null,
    trigger_id: 0,
}

const reducer = (state=initial_state, action) => {
    if (action.type === PERSONALITY_QUESTION_NOTIFICATION) {
        return {
            openNotificationPopover: true,
            notificationPopoverType: "Personality",
            trigger_id: action.trigger_id,
        }
    }

    if (action.type === CLOSE_NOTIFICATION) {
        return {
            openNotificationPopover: false,
            trigger_id: 0,
        }
    }

    return state;
}

export default reducer;