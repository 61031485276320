import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './AssessmentInfo.module.css';

class AssessmentInfo extends Component {

  render() {
    return (
      <div className={styles.AssessmentInfo}>
        <h2 className={styles.h2class} style={{ textTransform: 'capitalize' }}>{this.props.title}</h2>
        <h4 className={styles.h4class}>{this.props.portion ? 'Subject: ' + this.props.portion + ' | ' : ''} Questions: {this.props.qcount}</h4>
      </div>

    )
  }
}

AssessmentInfo.propTypes = {
  title: PropTypes.string,
  portion: PropTypes.string
};

AssessmentInfo.defaultProps = {
//   title: 'Title of the Assessment',
//   time: 3600,
//   qcount: 100
};

export default AssessmentInfo;
