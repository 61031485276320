import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components';
import { updateSummaryAC } from '../../store/actions/profileActions';

const SaveButton = styled.button`
    border-radius: 32px;
    background:  ${({ active }) => (active ? "linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%)" : "grey")};
    margin: auto;
    text-align: center;
    width: 98px;
    padding: 8px;
    color: white;
    margin-right: 0;
    border: none;
`

class UpdateSummary extends Component {

    state = {
        openContent : false,
        contentUrl: '',
        contentType: null,
        summary: this.props.summary
    }

    onSummaryChange = (event)=> {
        event.stopPropagation();
        event.preventDefault();
        this.setState({summary: event.target.value});
    }

    closeSummaryEditor = () => {
        this.setState({
            summary: this.props.summary
        });
        this.props.clearUpdateSummaryMessages();
        this.props.close();
    }

    render () {
        console.log(this.state)
        return (
            <div style={{}}>                    
                <div  style={{ textAlign: 'left', margin: '20px 0'}}>
                    <div style={{ margin: '0 auto 20px auto', fontWeight: 'bold',textAlign: 'left', fontSize: '20px'}}>Update your Career Objective : </div>
                    <div style={{margin: '10px auto'}}>
                        <textarea onChange={this.onSummaryChange} type='text' value = {this.state.summary} style={{width: '98%', height: '150px',border: '1px solid #bbb',borderRadius: '4px', overflowY: 'auto'}}></textarea>
                    </div>
                    {this.props.error_updating_summary? <div style={{color: 'red', textAlign: 'left'}}>Error Updating Summary. Please try again after some time!!!!</div> : null}
                    {this.props.summary_updated_successfully? <div style={{color: 'green', textAlign: 'left'}}>Summary Updated Successfully.</div> : null}

                    <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeSummaryEditor}>Cancel</div>
                        <div>
                            <SaveButton active={this.state.summary != this.props.summary} onClick={()=>this.props.updateSummary(this.state.summary)}>Save</SaveButton>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.prof.loading,
        college: state.prof.college,
        college_university_degree_department_id: state.prof.college_university_degree_department_id,
        department_details: state.prof.department_details,

        id: state.prof.id,
        roll_number: state.prof.roll_number,
        semester_id: state.prof.semester_id,
        university_degree_department_id: state.prof.university_degree_department_id,
        
        summary: state.prof.summary,
        error_updating_summary: state.prof.error_updating_summary,
        summary_updated_successfully: state.prof.summary_updated_successfully

    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateSummary:(summary)=> dispatch(updateSummaryAC(summary)),
        clearUpdateSummaryMessages: ()=>dispatch({type: 'CLEAR_UPDATE_SUMMARY_STATUS'})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateSummary));