import React, { Component } from 'react';
import styles from './PreviewCardItems.module.css';

class PreviewCardItems extends Component {
  render() {
    return (
      <div className={styles.PreviewCardItems}>
          <div className={styles.innerCard} style={{ background: this.props.gradient, boxShadow: this.props.width ? 'none' : '0px 2px 26px rgb(0 0 0 / 20%)' }}>
          </div>
          <div className={styles.viewCard} style={{width: this.props.width ? this.props.width : '450px'}}>
            <div className={styles.viewContent}>
              {this.props.children}
              {/* <Equation bold={true} math_type={1} equation={this.props.children}></Equation> */}
              {/* <div dangerouslySetInnerHTML={{ __html: this.props.children }} /> */}
            </div>
          </div>
      </div>
    );
  }
}

export default PreviewCardItems;