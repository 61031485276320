import React from 'react'
import styled from 'styled-components';
import { keyframes } from '@mui/system';
import './RemedialStudy.css'
import Gif from '../../../../../assets/remedial/gif/RemedialStudy.gif'


const RemedialStudyAnimation = () => {

    const CircleSvg = styled('div')(({ theme }) => ({
        img:{
            minWidth:'150px',
            minHeight: '150px',
            objectFit:'contain',
            bottom: '-16px',
            position: 'relative'
        },
        svg: {
            minWidth: '150px',
            minHeight: '150px',
            maxWidth: '150px',
            maxHeight: '150px',
            position: 'relative',
            top: 19,
        }
    }));


    return (
        <>
            <CircleSvg className='enabled-animation'>
            <img src={Gif} alt="testResultGif" />
            </CircleSvg>
            <CircleSvg className='disabled-animation'>
                <svg width="259" height="233" viewBox="0 0 259 233" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Property 1=Variant3" clip-path="url(#clip0_44_7317)">
                        <g id="CHARACTER&#38;apos;S LEGS">
                            <g id="CHAIR">
                                <path id="Vector" d="M171.192 222.062C171.142 222.062 171.099 222.048 171.049 222.02L156.458 213.042C151.333 209.885 147.071 205.446 144.137 200.195C141.204 194.943 139.655 188.994 139.655 182.987V143.498C139.655 143.348 139.776 143.234 139.919 143.234C140.061 143.234 140.183 143.356 140.183 143.498V182.987C140.183 188.908 141.71 194.765 144.601 199.938C147.492 205.104 151.69 209.479 156.737 212.586L171.327 221.564C171.456 221.642 171.491 221.806 171.413 221.927C171.363 222.005 171.277 222.055 171.185 222.055L171.192 222.062Z" fill="#6FAEDF" />
                                <g id="Group">
                                    <path id="Vector_2" d="M126.713 143.498H144.501C146.072 143.498 147.35 142.229 147.35 140.655V136.173H123.872V140.655C123.872 142.222 125.142 143.498 126.72 143.498H126.713Z" fill="white" />
                                    <path id="Vector_3" d="M144.501 143.761H126.713C124.999 143.761 123.6 142.365 123.6 140.655V135.909H147.614V140.655C147.614 142.365 146.215 143.761 144.501 143.761ZM124.136 136.436V140.655C124.136 142.073 125.292 143.227 126.713 143.227H144.501C145.922 143.227 147.078 142.073 147.078 140.655V136.436H124.129H124.136Z" fill="#6FAEDF" />
                                </g>
                            </g>
                            <g id="RIGHT LEG">
                                <path id="Vector_4" d="M141.929 132.113L129.388 215.101L154.559 216.42L179.187 142.442C183.635 130.396 176.097 117.507 163.339 116.81L161.564 116.717C152.12 116.193 143.741 122.768 141.929 132.113Z" fill="#4B4B4B" />
                                <path id="Vector_5" d="M134.306 215.359L147.573 216.054L145.127 222.761C142.647 220.02 139.096 217.95 135.116 217.499C134.747 217.451 134.384 217.425 134.02 217.406L134.306 215.359Z" fill="#DAD9D9" />
                                <g id="Group_2">
                                    <path id="Vector_6" d="M148.665 232.586C149.693 226.204 143.303 218.427 135.117 217.492C126.931 216.556 121.579 221.705 120.843 228.117C120.728 229.089 120.58 230.272 120.748 231.152L148.672 232.587L148.665 232.586Z" fill="white" />
                                    <path id="Vector_7" d="M120.513 231.403L120.474 231.202C120.305 230.322 120.436 229.202 120.552 228.216L120.558 228.088C120.947 224.769 122.523 221.841 125.008 219.845C127.694 217.681 131.201 216.78 135.138 217.229C139.387 217.716 143.535 220.131 146.237 223.683C148.396 226.529 149.376 229.791 148.92 232.628L148.879 232.862L120.506 231.403L120.513 231.403ZM134.036 217.663C130.664 217.487 127.68 218.372 125.344 220.247C122.971 222.157 121.466 224.96 121.099 228.138L121.092 228.266C120.982 229.138 120.867 230.109 120.969 230.885L148.437 232.296C148.783 229.653 147.84 226.642 145.82 223.989C143.206 220.549 139.189 218.212 135.082 217.74C134.734 217.7 134.386 217.667 134.044 217.65L134.036 217.663Z" fill="#828282" />
                                </g>
                                <path id="Vector_8" d="M121.066 228.544L121.032 229.077L148.757 230.86L148.791 230.326L121.066 228.544Z" fill="#828282" />
                            </g>
                            <g id="LEFT LEG">
                                <path id="Vector_9" d="M146.886 216.17L167.915 203.394L120.902 124.772C114.956 115.36 99.8582 112.445 90.3357 118.231C79.3569 124.908 77.2011 139.914 85.8671 149.39L146.893 216.17H146.886Z" fill="#4B4B4B" />
                                <path id="Vector_10" d="M146.921 216.155L85.7529 149.568C81.5698 144.994 79.6781 138.852 80.5562 132.724C81.4342 126.596 84.9819 121.231 90.2786 118.01C94.9185 115.196 101.043 114.291 107.096 115.545C113.214 116.813 118.361 120.126 121.209 124.637L168.45 203.308L146.921 216.155ZM101.743 115.523C97.6453 115.523 93.7407 116.528 90.557 118.459C85.3888 121.601 81.941 126.824 81.0844 132.795C80.2278 138.766 82.0695 144.752 86.1455 149.205L147.021 215.471L167.722 203.116L120.759 124.9C117.99 120.518 112.971 117.29 106.989 116.051C105.233 115.687 103.47 115.509 101.743 115.509V115.523Z" fill="white" />
                                <path id="Vector_11" d="M167.58 202.831C166.644 201.284 161.177 202.859 155.366 206.351C149.548 209.835 145.615 214.046 146.55 215.592C147.485 217.138 152.932 215.443 158.749 211.951C164.553 208.467 168.515 204.384 167.587 202.831H167.58Z" fill="#4B4B4B" />
                                <path id="Vector_12" d="M166.302 212.828L156.486 218.984L153.71 215.878L152.867 214.93L149.348 210.99C150.861 209.443 152.932 207.812 155.373 206.358C157.343 205.175 159.292 204.235 161.055 203.601L163.803 208.417L164.41 209.472L166.302 212.828Z" fill="#DAD9D9" />
                                <g id="Group_3">
                                    <path id="Vector_13" d="M181.785 219.504L157.336 232.665C156.722 232.009 156.201 231.233 155.78 230.356C155.423 229.622 155.152 228.845 154.938 228.069C153.496 222.675 155.53 216.49 162.033 213.398C167.765 210.676 174.904 212.236 179.03 215.82C180.25 216.896 181.207 218.143 181.785 219.504Z" fill="white" />
                                    <path id="Vector_14" d="M157.286 233L157.143 232.85C156.508 232.166 155.966 231.368 155.544 230.478C155.202 229.772 154.916 228.988 154.681 228.141C153.924 225.319 154.131 222.34 155.266 219.761C156.508 216.918 158.814 214.638 161.919 213.156C164.681 211.845 167.887 211.439 171.177 211.98C174.233 212.486 177.088 213.776 179.208 215.621C180.486 216.747 181.464 218.051 182.035 219.398L182.128 219.619L157.286 232.993V233ZM168.372 212.279C166.166 212.279 164.053 212.735 162.155 213.64C159.164 215.058 156.951 217.253 155.759 219.975C154.681 222.447 154.481 225.298 155.202 227.998C155.423 228.81 155.701 229.558 156.03 230.243C156.401 231.019 156.858 231.725 157.4 232.33L181.449 219.383C180.893 218.186 180.007 217.025 178.858 216.02C176.817 214.246 174.054 212.992 171.099 212.507C170.178 212.358 169.271 212.279 168.372 212.279Z" fill="#828282" />
                                </g>
                                <path id="Vector_15" d="M156.023 230.94C155.93 230.94 155.837 230.89 155.787 230.798C155.716 230.67 155.766 230.506 155.894 230.434L180.35 217.274C180.479 217.203 180.643 217.252 180.714 217.381C180.786 217.509 180.736 217.673 180.607 217.744L156.151 230.905C156.108 230.926 156.065 230.933 156.023 230.933V230.94Z" fill="#828282" />
                                <path id="Vector_16" d="M163.782 208.403C162.376 209.572 160.655 210.797 158.721 211.952C156.658 213.191 154.631 214.203 152.846 214.923L149.148 210.983C150.662 209.436 152.91 207.805 155.352 206.351C157.322 205.168 159.271 204.142 161.034 203.501L163.782 208.396V208.403Z" fill="#C1C1C0" />
                                <path id="Vector_17" d="M145.329 214.402C145.137 214.082 145.101 213.683 145.215 213.205C145.743 211.089 149.412 207.619 154.152 204.776C156.958 203.094 159.984 201.74 162.461 201.064C163.953 200.657 166.559 200.151 167.265 201.32L166.808 201.598C166.437 200.978 164.824 200.971 162.597 201.577C160.163 202.239 157.186 203.572 154.423 205.232C150.519 207.576 146.286 211.11 145.729 213.326C145.643 213.661 145.665 213.925 145.779 214.117L145.322 214.395L145.329 214.402Z" fill="white" />
                            </g>
                        </g>
                        <g id="TABLE">
                            <path id="Vector_18" d="M238.338 136C240.359 136 242 134.154 242 131.881V116.119C242 113.846 240.359 112 238.338 112H34.6618C32.6413 112 31 113.846 31 116.119V131.881C31 134.154 32.6413 136 34.6618 136H238.338Z" fill="#4B4B4B" />
                            <g id="Group_4">
                                <path id="Vector_19" d="M238.336 108H34.6639C32.6422 108 31 109.923 31 112.291V128.71C31 131.077 32.6422 133 34.6639 133H238.336C240.358 133 242 131.077 242 128.71V112.291C242 109.923 240.358 108 238.336 108Z" fill="white" />
                                <path id="Vector_20" d="M238.114 133H34.8856C32.7442 133 31 130.996 31 128.536V112.464C31 110.004 32.7442 108 34.8856 108H238.114C240.256 108 242 110.004 242 112.464V128.536C242 130.996 240.256 133 238.114 133ZM34.8856 108.528C32.9986 108.528 31.4593 110.296 31.4593 112.464V128.536C31.4593 130.704 32.9986 132.472 34.8856 132.472H238.114C240.001 132.472 241.541 130.704 241.541 128.536V112.464C241.541 110.296 240.001 108.528 238.114 108.528H34.8856Z" fill="#4B4B4B" />
                            </g>
                            <path id="Vector_21" d="M222.144 238.28C221.71 238.28 221.379 238.152 221.379 238.003V132.876C221.379 132.719 221.73 132.6 222.144 132.6C222.557 132.6 222.909 132.727 222.909 132.876V238.003C222.909 238.16 222.557 238.28 222.144 238.28Z" fill="#4B4B4B" />
                            <path id="Vector_22" d="M70.765 238.28C70.3308 238.28 70 238.152 70 238.003V132.876C70 132.719 70.3515 132.6 70.765 132.6C71.1785 132.6 71.53 132.727 71.53 132.876V238.003C71.53 238.16 71.1785 238.28 70.765 238.28Z" fill="#4B4B4B" />
                        </g>
                        <g id="CHARACTER">
                            <g id="HOODY">
                                <path id="Vector_23" d="M193.256 89.6161L178.287 63.3661C174.711 57.0887 168.036 53.2196 160.805 53.2196H104.734L106.697 48.9871C106.54 49.3363 105.69 49.4218 104.405 49.2436C102.435 48.98 99.4298 48.1178 96.2247 46.6999C94.3901 45.8947 92.7341 45.0468 91.3492 44.2701C88.7437 42.788 87.1733 41.4912 87.4303 40.9141L58.8056 87.7777C52.5167 96.9267 59.084 109.36 70.1912 109.36C71.6831 109.36 73.1607 109.118 74.567 108.648C75.9733 108.178 77.2938 107.487 78.4859 106.589L100.472 90.0935C101.8 89.103 103.691 89.8726 103.956 91.4972L106.825 109.36H181.735C191.9 109.368 198.274 98.4159 193.249 89.6161H193.256ZM159.934 91.6682L162.333 86.2172C162.74 85.2838 164.06 85.2696 164.481 86.1887L167.087 91.6753H159.927L159.934 91.6682Z" fill="#828282" />
                                <path id="Vector_24" d="M155.908 53.2051H155.373C155.373 52.1007 155.102 48.2458 151.611 45.1677C149.962 43.7141 147.842 42.6738 145.301 42.0681C142.317 41.3556 138.748 41.2558 134.686 41.7617C129.254 42.4458 124.028 44.0918 119.974 46.4004C116.133 48.5879 113.928 51.0675 113.928 53.198H113.392C113.392 50.868 115.691 48.2245 119.71 45.9372C123.821 43.593 129.118 41.9185 134.615 41.2273C138.741 40.7143 142.381 40.8141 145.422 41.5409C148.042 42.1679 150.24 43.251 151.961 44.7615C155.623 47.9893 155.908 52.0366 155.908 53.198V53.2051Z" fill="#828282" />
                                <path id="Vector_25" d="M153.645 53.2838C153.645 57.3951 134.643 65.8031 134.643 65.8031C134.643 65.8031 115.641 57.388 115.641 53.2838C115.641 49.1795 124.671 44.4198 135.086 43.1515C150.069 41.3202 153.638 49.1653 153.638 53.2838H153.645Z" fill="#828282" />
                                <path id="Vector_26" d="M153.645 53.2833C153.645 57.3946 134.643 65.8026 134.643 65.8026C134.643 65.8026 115.641 57.3875 115.641 53.2833V53.2334H153.638V53.2833H153.645Z" fill="white" />
                                <path id="Vector_27" d="M134.65 68.3397L134.529 68.2827C134.479 68.2542 129.211 65.6677 124.021 62.5539C116.976 58.3285 113.399 55.1862 113.399 53.1982H113.935C113.935 54.4238 115.734 56.9676 124.293 62.0978C129.039 64.9409 133.844 67.3493 134.65 67.7483C135.457 67.3493 140.261 64.9409 145.008 62.0907C153.567 56.9604 155.366 54.4238 155.366 53.1982H155.901C155.901 55.1791 152.325 58.3285 145.279 62.5539C140.082 65.6677 134.822 68.2613 134.772 68.2898L134.657 68.3468L134.65 68.3397Z" fill="white" />
                                <path id="Vector_28" d="M150.84 109.389C150.719 109.389 150.619 109.289 150.619 109.168C150.619 109.047 150.719 108.947 150.84 108.947C151.183 108.947 151.697 108.327 152.125 106.581C152.525 104.964 152.739 102.812 152.739 100.518C152.739 98.2232 152.517 96.0713 152.125 94.4539C151.697 92.7082 151.183 92.0883 150.84 92.0883C150.719 92.0883 150.619 91.9885 150.619 91.8674C150.619 91.7462 150.719 91.6465 150.84 91.6465C152.368 91.6465 153.188 96.221 153.188 100.518C153.188 104.814 152.368 109.389 150.84 109.389Z" fill="white" />
                                <path id="Vector_29" d="M106.074 50.6328L89.6177 83.4785L90.0135 83.6761L106.47 50.8304L106.074 50.6328Z" fill="white" />
                                <path id="Vector_30" d="M105.412 100.91C105.305 100.91 105.212 100.832 105.191 100.725L102.471 82.8542C102.449 82.7331 102.535 82.6191 102.656 82.6048C102.778 82.5835 102.892 82.669 102.906 82.7901L105.626 100.661C105.647 100.782 105.562 100.896 105.44 100.91C105.426 100.91 105.419 100.91 105.405 100.91H105.412Z" fill="white" />
                                <g id="Group_5">
                                    <g id="Group_6">
                                        <path id="Vector_31" d="M135.535 70.3627H133.758C133.637 70.3627 133.537 70.2629 133.537 70.1418C133.537 70.0207 133.637 69.9209 133.758 69.9209H135.535C135.657 69.9209 135.757 70.0207 135.757 70.1418C135.757 70.2629 135.657 70.3627 135.535 70.3627Z" fill="white" />
                                        <path id="Vector_32" d="M135.535 72.4789H133.758C133.637 72.4789 133.537 72.3791 133.537 72.258C133.537 72.1369 133.637 72.0371 133.758 72.0371H135.535C135.657 72.0371 135.757 72.1369 135.757 72.258C135.757 72.3791 135.657 72.4789 135.535 72.4789Z" fill="white" />
                                        <path id="Vector_33" d="M135.535 74.5804H133.758C133.637 74.5804 133.537 74.4807 133.537 74.3596C133.537 74.2384 133.637 74.1387 133.758 74.1387H135.535C135.657 74.1387 135.757 74.2384 135.757 74.3596C135.757 74.4807 135.657 74.5804 135.535 74.5804Z" fill="white" />
                                        <path id="Vector_34" d="M135.535 76.683H133.758C133.637 76.683 133.537 76.5832 133.537 76.4621C133.537 76.341 133.637 76.2412 133.758 76.2412H135.535C135.657 76.2412 135.757 76.341 135.757 76.4621C135.757 76.5832 135.657 76.683 135.535 76.683Z" fill="white" />
                                        <path id="Vector_35" d="M135.535 78.7845H133.758C133.637 78.7845 133.537 78.6848 133.537 78.5637C133.537 78.4425 133.637 78.3428 133.758 78.3428H135.535C135.657 78.3428 135.757 78.4425 135.757 78.5637C135.757 78.6848 135.657 78.7845 135.535 78.7845Z" fill="white" />
                                        <path id="Vector_36" d="M135.535 80.9008H133.758C133.637 80.9008 133.537 80.801 133.537 80.6799C133.537 80.5587 133.637 80.459 133.758 80.459H135.535C135.657 80.459 135.757 80.5587 135.757 80.6799C135.757 80.801 135.657 80.9008 135.535 80.9008Z" fill="white" />
                                        <path id="Vector_37" d="M135.535 80.9935H133.758C133.637 80.9935 133.537 80.8938 133.537 80.7726C133.537 80.6515 133.637 80.5518 133.758 80.5518H135.535C135.657 80.5518 135.757 80.6515 135.757 80.7726C135.757 80.8938 135.657 80.9935 135.535 80.9935Z" fill="white" />
                                        <path id="Vector_38" d="M135.535 83.0961H133.758C133.637 83.0961 133.537 82.9963 133.537 82.8752C133.537 82.7541 133.637 82.6543 133.758 82.6543H135.535C135.657 82.6543 135.757 82.7541 135.757 82.8752C135.757 82.9963 135.657 83.0961 135.535 83.0961Z" fill="white" />
                                        <path id="Vector_39" d="M135.535 85.2045H133.758C133.637 85.2045 133.537 85.1047 133.537 84.9836C133.537 84.8624 133.637 84.7627 133.758 84.7627H135.535C135.657 84.7627 135.757 84.8624 135.757 84.9836C135.757 85.1047 135.657 85.2045 135.535 85.2045Z" fill="white" />
                                        <path id="Vector_40" d="M135.535 87.2074H133.758C133.637 87.2074 133.537 87.1076 133.537 86.9865C133.537 86.8654 133.637 86.7656 133.758 86.7656H135.535C135.657 86.7656 135.757 86.8654 135.757 86.9865C135.757 87.1076 135.657 87.2074 135.535 87.2074Z" fill="white" />
                                        <path id="Vector_41" d="M135.535 89.3236H133.758C133.637 89.3236 133.537 89.2239 133.537 89.1027C133.537 88.9816 133.637 88.8818 133.758 88.8818H135.535C135.657 88.8818 135.757 88.9816 135.757 89.1027C135.757 89.2239 135.657 89.3236 135.535 89.3236Z" fill="white" />
                                        <path id="Vector_42" d="M135.535 91.4252H133.758C133.637 91.4252 133.537 91.3254 133.537 91.2043C133.537 91.0832 133.637 90.9834 133.758 90.9834H135.535C135.657 90.9834 135.757 91.0832 135.757 91.2043C135.757 91.3254 135.657 91.4252 135.535 91.4252Z" fill="white" />
                                        <path id="Vector_43" d="M135.535 93.5277H133.758C133.637 93.5277 133.537 93.428 133.537 93.3068C133.537 93.1857 133.637 93.0859 133.758 93.0859H135.535C135.657 93.0859 135.757 93.1857 135.757 93.3068C135.757 93.428 135.657 93.5277 135.535 93.5277Z" fill="white" />
                                        <path id="Vector_44" d="M135.535 95.6293H133.758C133.637 95.6293 133.537 95.5295 133.537 95.4084C133.537 95.2873 133.637 95.1875 133.758 95.1875H135.535C135.657 95.1875 135.757 95.2873 135.757 95.4084C135.757 95.5295 135.657 95.6293 135.535 95.6293Z" fill="white" />
                                        <path id="Vector_45" d="M135.535 97.7386H133.758C133.637 97.7386 133.537 97.6389 133.537 97.5178C133.537 97.3966 133.637 97.2969 133.758 97.2969H135.535C135.657 97.2969 135.757 97.3966 135.757 97.5178C135.757 97.6389 135.657 97.7386 135.535 97.7386Z" fill="white" />
                                        <path id="Vector_46" d="M135.535 99.8471H133.758C133.637 99.8471 133.537 99.7473 133.537 99.6262C133.537 99.505 133.637 99.4053 133.758 99.4053H135.535C135.657 99.4053 135.757 99.505 135.757 99.6262C135.757 99.7473 135.657 99.8471 135.535 99.8471Z" fill="white" />
                                        <path id="Vector_47" d="M135.535 101.95H133.758C133.637 101.95 133.537 101.85 133.537 101.729C133.537 101.608 133.637 101.508 133.758 101.508H135.535C135.657 101.508 135.757 101.608 135.757 101.729C135.757 101.85 135.657 101.95 135.535 101.95Z" fill="white" />
                                        <path id="Vector_48" d="M135.535 104.051H133.758C133.637 104.051 133.537 103.951 133.537 103.83C133.537 103.709 133.637 103.609 133.758 103.609H135.535C135.657 103.609 135.757 103.709 135.757 103.83C135.757 103.951 135.657 104.051 135.535 104.051Z" fill="white" />
                                        <path id="Vector_49" d="M135.535 104.159H133.758C133.637 104.159 133.537 104.059 133.537 103.938C133.537 103.817 133.637 103.717 133.758 103.717H135.535C135.657 103.717 135.757 103.817 135.757 103.938C135.757 104.059 135.657 104.159 135.535 104.159Z" fill="white" />
                                        <path id="Vector_50" d="M135.535 106.26H133.758C133.637 106.26 133.537 106.16 133.537 106.039C133.537 105.918 133.637 105.818 133.758 105.818H135.535C135.657 105.818 135.757 105.918 135.757 106.039C135.757 106.16 135.657 106.26 135.535 106.26Z" fill="white" />
                                        <path id="Vector_51" d="M135.535 108.363H133.758C133.637 108.363 133.537 108.263 133.537 108.142C133.537 108.021 133.637 107.921 133.758 107.921H135.535C135.657 107.921 135.757 108.021 135.757 108.142C135.757 108.263 135.657 108.363 135.535 108.363Z" fill="white" />
                                    </g>
                                    <path id="Vector_52" d="M133.794 67.2773H133.351V100.688H133.794V67.2773Z" fill="white" />
                                </g>
                                <path id="Vector_53" d="M171.191 92.0599H149.812C149.691 92.0599 149.591 91.9602 149.591 91.839C149.591 91.7179 149.691 91.6182 149.812 91.6182H171.191C171.313 91.6182 171.413 91.7179 171.413 91.839C171.413 91.9602 171.313 92.0599 171.191 92.0599Z" fill="white" />
                            </g>
                            <g id="BOOK">
                                <g id="Group_7">
                                    <path id="Vector_54" d="M90.3142 126.761H158.585L152.161 109.432H97.7095L90.3142 126.761Z" fill="white" />
                                    <path id="Vector_55" d="M158.963 127.024H89.9072L97.531 109.161H152.346L152.41 109.332L158.963 127.024ZM90.7139 126.49H158.2L151.975 109.688H97.8807L90.7139 126.49Z" fill="#C8C7C7" />
                                </g>
                                <path id="Vector_56" d="M90.3142 126.761C90.3142 124.31 96.8244 119.885 107.396 119.279C118.71 118.638 124.642 125.742 124.478 126.761H90.3213H90.3142Z" fill="#C8C7C7" />
                                <g id="Group_8">
                                    <path id="Vector_57" d="M124.844 126.76C124.844 126.76 126.278 112.737 138.868 115.595L136.149 101.757C136.149 101.757 125.839 97.8383 124.84 109.424V126.753L124.844 126.76Z" fill="white" />
                                    <path id="Vector_58" d="M124.844 127.024C124.902 127.024 124.956 126.939 124.97 126.811C124.976 126.761 125.351 123.326 127.232 120.284C128.339 118.488 129.747 117.177 131.413 116.379C133.502 115.381 136.006 115.203 138.858 115.852C138.902 115.859 138.946 115.823 138.974 115.745C139.001 115.666 139.008 115.574 138.991 115.481L136.272 101.644C136.255 101.558 136.221 101.501 136.176 101.48C136.159 101.48 134.541 100.867 132.551 100.839C130.704 100.817 128.152 101.295 126.472 103.731C125.511 105.128 124.922 107.023 124.721 109.361C124.721 109.375 124.721 109.389 124.721 109.41V126.739C124.724 126.875 124.775 126.989 124.84 127.003C124.844 127.003 124.85 127.003 124.854 127.003L124.844 127.024ZM135.658 114.947C134.074 114.947 132.629 115.253 131.338 115.873C129.634 116.693 128.193 118.039 127.058 119.892C125.869 121.83 125.266 123.889 124.973 125.229V109.446C125.16 107.259 125.712 105.484 126.615 104.18C128.241 101.822 130.735 101.352 132.544 101.38C134.285 101.401 135.737 101.879 136.064 101.993L138.677 115.282C137.624 115.054 136.619 114.947 135.665 114.947H135.658Z" fill="#C8C7C7" />
                                </g>
                                <g id="Group_9">
                                    <path id="Vector_59" d="M158.185 125.671C156.615 123.498 150.547 121.225 141.517 120.712C130.774 120.099 124.892 125.194 124.464 126.576V109.432C124.464 109.432 132.13 100.697 152.168 109.432L158.185 125.671Z" fill="white" />
                                    <path id="Vector_60" d="M124.464 126.839C124.464 126.839 124.435 126.839 124.421 126.839C124.293 126.818 124.193 126.711 124.193 126.576V109.432C124.193 109.368 124.214 109.304 124.257 109.254C124.335 109.161 126.277 106.995 130.731 105.898C133.33 105.257 136.249 105.114 139.412 105.485C143.352 105.941 147.678 107.188 152.268 109.19C152.332 109.218 152.389 109.275 152.41 109.339L158.428 125.578C158.471 125.706 158.428 125.842 158.307 125.906C158.192 125.97 158.043 125.934 157.964 125.828C156.544 123.861 150.783 121.502 141.496 120.975C135.778 120.647 131.674 121.966 129.232 123.134C126.22 124.573 124.871 126.155 124.714 126.654C124.678 126.768 124.571 126.839 124.457 126.839H124.464ZM124.728 109.532V125.649C125.485 124.759 126.984 123.612 129.004 122.65C131.495 121.46 135.692 120.106 141.524 120.441C149.712 120.904 155.159 122.749 157.5 124.609L151.946 109.624C147.449 107.679 143.223 106.461 139.383 106.012C136.292 105.648 133.437 105.784 130.903 106.397C128.654 106.945 127.077 107.786 126.149 108.399C125.356 108.919 124.892 109.368 124.728 109.532Z" fill="#C8C7C7" />
                                </g>
                                <path id="Vector_61" d="M128.968 120.077C128.875 120.077 128.783 120.027 128.74 119.941C128.668 119.813 128.711 119.649 128.84 119.578C130.382 118.694 132.98 117.561 136.606 117.155C138.005 117.005 139.461 116.97 140.939 117.055C143.894 117.226 146.614 117.618 149.034 118.231C149.177 118.267 149.262 118.409 149.227 118.552C149.191 118.694 149.048 118.78 148.905 118.744C146.521 118.138 143.83 117.747 140.911 117.583C139.461 117.497 138.034 117.533 136.67 117.682C133.137 118.074 130.61 119.179 129.111 120.034C129.068 120.055 129.025 120.069 128.975 120.069L128.968 120.077Z" fill="#C8C7C7" />
                                <path id="Vector_62" d="M128.647 117.241C128.554 117.241 128.461 117.191 128.418 117.105C128.347 116.977 128.39 116.813 128.518 116.742C130.06 115.858 132.659 114.725 136.285 114.319C137.684 114.17 139.14 114.134 140.618 114.219C143.373 114.383 145.936 114.74 148.242 115.281C148.384 115.317 148.477 115.459 148.441 115.602C148.406 115.744 148.263 115.837 148.12 115.801C145.843 115.267 143.316 114.911 140.589 114.754C139.14 114.668 137.713 114.704 136.349 114.854C132.816 115.245 130.289 116.35 128.79 117.205C128.747 117.226 128.704 117.241 128.654 117.241H128.647Z" fill="#C8C7C7" />
                                <path id="Vector_63" d="M128.411 114.398C128.319 114.398 128.226 114.348 128.183 114.262C128.112 114.134 128.155 113.97 128.283 113.899C129.825 113.015 132.423 111.883 136.05 111.476C137.449 111.327 138.905 111.291 140.382 111.377C142.938 111.526 145.336 111.854 147.514 112.331C147.656 112.36 147.749 112.502 147.714 112.652C147.685 112.795 147.542 112.887 147.392 112.852C145.244 112.374 142.874 112.061 140.347 111.911C138.898 111.826 137.47 111.861 136.107 112.011C132.573 112.403 130.046 113.507 128.547 114.362C128.504 114.384 128.461 114.398 128.411 114.398Z" fill="#C8C7C7" />
                                <path id="Vector_64" d="M150.631 108.572L150.129 108.756L155.782 124.127L156.285 123.943L150.631 108.572Z" fill="#C8C7C7" />
                                <path id="Vector_65" d="M158.592 126.761H124.464V126.575C124.892 125.193 130.774 120.098 141.517 120.711C150.547 121.224 156.615 123.497 158.185 125.67L158.592 126.761Z" fill="#C8C7C7" />
                            </g>
                            <g id="HEAD">
                                <g id="NECK">
                                    <path id="Vector_66" d="M145.543 53.2194C145.543 53.2194 139.176 56.5327 130.339 55.2003C128.14 54.8725 125.785 54.2526 123.35 53.2194V42.5313L134.8 35.4131L136.513 38.2632L145.536 53.2194H145.543Z" fill="#DAD9D9" />
                                    <path id="Vector_67" d="M135.436 36.2615C136.121 37.5298 136.235 39.0404 135.735 40.3942L130.339 55.1936C128.14 54.8659 125.785 54.2459 123.35 53.2128V42.5247L134.857 35.1855L135.436 36.2472V36.2615Z" fill="#C1C1C0" />
                                </g>
                                <g id="HAIR">
                                    <path id="Vector_68" d="M134.443 31.4802C134.443 31.4802 135.785 19.5594 131.01 18.4692C127.362 17.6355 125.549 20.3788 125.549 20.3788C125.549 20.3788 123.579 17.5002 120.517 18.854C117.611 20.1294 117.811 24.1196 119.117 23.799C120.424 23.4784 117.854 20.9204 115.106 22.8157C112.065 24.9034 115.106 28.345 115.106 28.345C115.106 28.345 112.857 27.2619 111.815 29.4708C110.523 32.2141 117.89 38.121 117.89 38.121L134.436 31.4802H134.443Z" fill="#252424" />
                                </g>
                                <g id="HEAD_2">
                                    <g id="HEAD_3">
                                        <path id="Vector_69" d="M127.169 25.7161L119.531 28.78C117.69 29.5139 116.79 31.623 117.533 33.4471L120.866 41.7482C122.094 44.8121 125.613 46.3013 128.676 45.0757L131.852 43.8074C134.922 42.5819 136.414 39.069 135.186 36.0123L131.852 27.7112C131.117 25.8728 129.004 24.975 127.177 25.7161H127.169Z" fill="#DAD9D9" />
                                    </g>
                                    <g id="EARS">
                                        <path id="Vector_70" d="M136.72 35.3927L135.186 36.0126L133.515 31.8585L135.05 31.2386C135.964 30.8752 136.999 31.317 137.363 32.2219L137.713 33.0841C138.084 33.989 137.641 35.0222 136.728 35.3927H136.72Z" fill="#DAD9D9" />
                                        <path id="Vector_71" d="M119.217 42.0619L120.752 41.442L119.196 37.5801L117.661 38.2C116.747 38.5634 116.312 39.6037 116.676 40.5086L116.904 41.0786C117.269 41.9836 118.304 42.4253 119.217 42.0619Z" fill="#C1C1C0" />
                                        <path id="Vector_72" d="M134.843 34.2815C134.757 34.2815 134.672 34.2316 134.636 34.139C134.308 33.3124 134.715 32.3719 135.543 32.0299C135.657 31.98 135.785 32.037 135.835 32.151C135.878 32.265 135.828 32.3933 135.714 32.4431C135.1 32.6925 134.814 33.3623 135.057 33.9751C135.1 34.0891 135.043 34.2174 134.929 34.2601C134.9 34.2672 134.872 34.2744 134.85 34.2744L134.843 34.2815Z" fill="#4B4B4B" />
                                    </g>
                                    <g id="FACE">
                                        <path id="Vector_73" d="M128.663 29.007C127.764 29.007 127.186 29.2136 127.143 29.2278C127.029 29.2706 126.971 29.3989 127.014 29.5129C127.057 29.6269 127.186 29.6839 127.3 29.6411C127.321 29.634 129.056 29.0141 131.226 30.0401C131.34 30.09 131.469 30.0401 131.519 29.9333C131.568 29.8193 131.519 29.691 131.411 29.6411C130.355 29.1423 129.398 28.9998 128.656 28.9998L128.663 29.007Z" fill="#4B4B4B" />
                                        <path id="Vector_74" d="M127.226 37.7222C127.126 37.7222 127.034 37.651 127.012 37.5441C126.084 33.2617 123.607 33.4612 123.579 33.4684C123.457 33.4826 123.35 33.39 123.336 33.2689C123.322 33.1477 123.414 33.0408 123.536 33.0266C123.564 33.0266 124.292 32.9553 125.149 33.4897C126.256 34.1809 127.034 35.5134 127.448 37.4515C127.476 37.5726 127.398 37.6866 127.276 37.7151C127.262 37.7151 127.248 37.7151 127.226 37.7151V37.7222Z" fill="#4B4B4B" />
                                        <path id="Vector_75" d="M127.171 40.6248C127.084 40.6989 127.068 40.8343 127.137 40.9256C127.411 41.2912 127.755 41.4752 128.155 41.4713C129.478 41.4491 130.984 39.2762 131.047 39.1851C131.12 39.0847 131.086 38.9454 130.99 38.8772C130.895 38.8089 130.756 38.8334 130.682 38.9338C130.282 39.5093 129.058 41.0113 128.145 41.0304C127.887 41.0354 127.674 40.9174 127.487 40.6649C127.414 40.5681 127.274 40.5469 127.177 40.6202C127.177 40.6202 127.171 40.6248 127.166 40.6295L127.171 40.6248Z" fill="#4B4B4B" />
                                        <path id="Vector_76" d="M130.646 34.7655C130.835 34.6984 130.854 34.2652 130.689 33.7979C130.523 33.3305 130.235 33.0059 130.045 33.073C129.856 33.14 129.836 33.5732 130.002 34.0406C130.168 34.508 130.456 34.8325 130.646 34.7655Z" fill="#4B4B4B" />
                                        <path id="Vector_77" d="M123.316 37.8241C123.506 37.757 123.525 37.3238 123.359 36.8565C123.193 36.3891 122.905 36.0645 122.716 36.1316C122.526 36.1986 122.507 36.6318 122.673 37.0992C122.839 37.5665 123.127 37.8911 123.316 37.8241Z" fill="#4B4B4B" />
                                    </g>
                                    <g id="GLASSES">
                                        <path id="Vector_78" d="M130.56 36.7039C129.018 36.7039 127.769 35.4569 127.769 33.9179C127.769 32.3788 129.018 31.1318 130.56 31.1318C132.102 31.1318 133.351 32.3788 133.351 33.9179C133.351 35.4569 132.102 36.7039 130.56 36.7039ZM130.56 31.5736C129.268 31.5736 128.212 32.621 128.212 33.9179C128.212 35.2147 129.261 36.2621 130.56 36.2621C131.859 36.2621 132.909 35.2147 132.909 33.9179C132.909 32.621 131.859 31.5736 130.56 31.5736Z" fill="white" />
                                        <path id="Vector_79" d="M123.001 39.6326C121.459 39.6326 120.209 38.3857 120.209 36.8466C120.209 35.3075 121.459 34.0605 123.001 34.0605C124.542 34.0605 125.792 35.3075 125.792 36.8466C125.792 38.3857 124.542 39.6326 123.001 39.6326ZM123.001 34.5023C121.709 34.5023 120.652 35.5498 120.652 36.8466C120.652 38.1434 121.701 39.1908 123.001 39.1908C124.3 39.1908 125.349 38.1434 125.349 36.8466C125.349 35.5498 124.3 34.5023 123.001 34.5023Z" fill="white" />
                                        <path id="Vector_80" d="M125.406 36.1484C125.32 36.1484 125.242 36.0985 125.206 36.0201C125.156 35.9061 125.206 35.7779 125.313 35.728L128.026 34.4882C128.14 34.4383 128.269 34.4882 128.319 34.5951C128.369 34.7091 128.319 34.8373 128.211 34.8872L125.499 36.127C125.499 36.127 125.435 36.1484 125.406 36.1484Z" fill="white" />
                                    </g>
                                </g>
                            </g>
                            <g id="ARMS">
                                <g id="LEFT">
                                    <g id="Group_10">
                                        <path id="Vector_81" d="M107.232 48.0108C107.082 48.3599 106.226 48.4454 104.941 48.2673C102.971 48.0036 99.9653 47.1415 96.7602 45.7235C94.9256 44.9041 93.2695 44.0704 91.899 43.2937C89.2935 41.8117 87.723 40.5149 87.98 39.9377C88.237 39.3677 90.2357 39.6456 93.0625 40.5576C94.583 41.0493 96.3319 41.719 98.1807 42.5456C101.343 43.9493 104.091 45.3174 105.712 46.3719C106.811 47.0845 107.389 47.6616 107.232 48.0108Z" fill="white" />
                                        <path id="Vector_82" d="M106.076 48.6236C105.74 48.6236 105.348 48.5951 104.905 48.531C102.685 48.2317 99.6012 47.2698 96.6531 45.9658C94.8471 45.1607 93.1553 44.3127 91.7633 43.5218C88.7581 41.8117 87.4018 40.5719 87.7302 39.8309C87.9158 39.4176 88.5082 39.2965 89.5861 39.4319C90.4927 39.5459 91.7205 39.8523 93.141 40.3083C94.7043 40.8071 96.4818 41.4982 98.2878 42.3034C101.7 43.814 104.32 45.1464 105.854 46.1511C107.196 47.0133 107.696 47.626 107.475 48.1248C107.325 48.4597 106.854 48.6307 106.076 48.6307V48.6236ZM88.8223 39.9021C88.444 39.9021 88.2584 39.9663 88.2227 40.0446C88.1228 40.2655 88.9294 41.2916 92.0346 43.0587C93.4123 43.8425 95.0826 44.6833 96.8744 45.4813C99.7797 46.771 102.813 47.7115 104.984 48.0108C106.525 48.2246 106.961 47.9894 106.997 47.9111C106.997 47.9111 107.089 47.5762 105.576 46.6C104.063 45.6096 101.471 44.2985 98.0807 42.795C96.2962 41.997 94.533 41.313 92.984 40.8142C90.8211 40.1159 89.5076 39.9093 88.8295 39.9093L88.8223 39.9021Z" fill="#828282" />
                                    </g>
                                    <path id="Vector_83" d="M122.315 30.0124L119.895 39.3395L117.718 33.9242C116.883 31.8507 117.89 29.4709 119.938 28.6016L122.315 30.0124Z" fill="#C1C1C0" />
                                    <path id="Vector_84" d="M122.408 29.8413L117.604 38.1495C117.054 39.1114 116.019 39.7028 114.913 39.7028H109.952C109.224 39.7028 108.574 40.1446 108.296 40.8143L105.719 46.3793L104.962 48.0039C102.785 47.7117 99.751 46.7712 96.8528 45.4815C95.7678 45.0041 94.7113 44.4982 93.7334 43.9923L95.0397 41.2704L97.3811 36.3966C98.5018 33.7602 100.429 31.5442 102.892 30.0693L107.732 27.1692C108.374 26.7845 109.109 26.585 109.859 26.585H118.075C118.532 26.585 118.975 26.6847 119.374 26.8771L121.844 28.0457C122.529 28.3734 122.786 29.2 122.415 29.8484L122.408 29.8413Z" fill="#DAD9D9" />
                                    <path id="Vector_85" d="M120.831 33.4825C120.795 33.4825 120.752 33.4754 120.724 33.454C120.616 33.3899 120.581 33.2545 120.638 33.1547C120.781 32.9053 120.809 32.6203 120.724 32.3496C120.638 32.0788 120.452 31.8579 120.188 31.7368L117.811 30.6039C117.447 30.4328 117.054 30.3402 116.655 30.3402H110.787C110.666 30.3402 110.566 30.2405 110.566 30.1193C110.566 29.9982 110.666 29.8984 110.787 29.8984H116.655C117.126 29.8984 117.576 29.9982 118.004 30.2048L120.381 31.3378C120.745 31.5088 121.023 31.8365 121.145 32.2213C121.266 32.6061 121.223 33.0265 121.016 33.3756C120.973 33.4469 120.902 33.4896 120.823 33.4896L120.831 33.4825Z" fill="#4B4B4B" />
                                    <path id="Vector_86" d="M119.374 36.005C119.339 36.005 119.296 35.9978 119.267 35.9765C119.16 35.9123 119.124 35.777 119.182 35.6772C119.324 35.4278 119.353 35.1428 119.267 34.872C119.182 34.6013 118.996 34.3804 118.732 34.2592L116.355 33.1263C115.991 32.9553 115.598 32.8627 115.198 32.8627H109.331C109.209 32.8627 109.109 32.7629 109.109 32.6418C109.109 32.5207 109.209 32.4209 109.331 32.4209H115.198C115.669 32.4209 116.119 32.5207 116.548 32.7273L118.925 33.8602C119.289 34.0312 119.567 34.359 119.688 34.7438C119.81 35.1285 119.767 35.5489 119.567 35.8981C119.524 35.9693 119.453 36.0121 119.374 36.0121V36.005Z" fill="#4B4B4B" />
                                    <path id="Vector_87" d="M117.661 39.297C117.625 39.297 117.583 39.2898 117.554 39.2685C117.447 39.2043 117.411 39.0689 117.468 38.9692C117.611 38.7198 117.64 38.4348 117.554 38.164C117.468 37.8933 117.283 37.6724 117.019 37.5512L114.642 36.4183C114.278 36.2473 113.885 36.1547 113.485 36.1547H107.618C107.496 36.1547 107.396 36.0549 107.396 35.9338C107.396 35.8126 107.496 35.7129 107.618 35.7129H113.485C113.956 35.7129 114.406 35.8126 114.834 36.0193L117.211 37.1522C117.575 37.3232 117.854 37.651 117.975 38.0358C118.097 38.4205 118.054 38.8409 117.847 39.1901C117.804 39.2613 117.733 39.3041 117.654 39.3041L117.661 39.297Z" fill="#4B4B4B" />
                                    <path id="Vector_88" d="M104.976 48.0039C102.799 47.7117 99.7654 46.7712 96.8672 45.4815C95.7822 45.0041 94.7258 44.4982 93.7478 43.9923L95.2254 40.9854C96.2105 41.3559 97.1813 41.7834 98.2664 42.275C101.429 43.6787 104.198 45.1395 105.812 46.194L104.976 48.0039Z" fill="#C1C1C0" />
                                    <path id="Vector_89" d="M110.052 39.6888H105.89C105.769 39.6888 105.669 39.5891 105.669 39.468C105.669 39.3468 105.769 39.2471 105.89 39.2471H110.052C110.173 39.2471 110.273 39.3468 110.273 39.468C110.273 39.5891 110.173 39.6888 110.052 39.6888Z" fill="#4B4B4B" />
                                </g>
                                <g id="RIGHT">
                                    <path id="Vector_90" d="M149.769 109.361C150.944 109.361 151.897 105.398 151.897 100.511C151.897 95.6233 150.944 91.6611 149.769 91.6611C148.594 91.6611 147.642 95.6233 147.642 100.511C147.642 105.398 148.594 109.361 149.769 109.361Z" fill="white" />
                                    <path id="Vector_91" d="M151.928 100.634C151.928 102.152 151.843 103.577 151.671 104.831C151.122 106.234 149.994 107.339 148.566 107.866C147.988 108.08 147.36 108.201 146.696 108.201H138.63C137.616 108.201 136.788 109.028 136.788 110.039V110.289C136.788 111.771 135.203 112.733 133.89 112.035L130.099 110.054C129.421 109.697 129 108.999 129 108.237V105.28C129 104.168 129.521 103.135 130.406 102.465L136.188 98.1258C137.166 97.3919 138.358 97 139.579 97H151.736C151.871 98.1116 151.928 99.3371 151.928 100.627V100.634Z" fill="#DAD9D9" />
                                    <path id="Vector_92" d="M132.038 110.914C132.038 110.914 131.966 110.906 131.938 110.892C131.238 110.536 130.803 109.816 130.803 109.025V106.311C130.803 105.235 131.317 104.201 132.18 103.553L137.491 99.5699C137.591 99.4987 137.727 99.5129 137.805 99.6127C137.877 99.7124 137.863 99.8478 137.763 99.9262L132.452 103.909C131.702 104.479 131.252 105.377 131.252 106.318V109.032C131.252 109.645 131.602 110.222 132.145 110.5C132.252 110.557 132.295 110.693 132.238 110.8C132.195 110.878 132.123 110.921 132.038 110.921V110.914Z" fill="#4B4B4B" />
                                    <path id="Vector_93" d="M134.736 112.104C134.736 112.104 134.665 112.097 134.636 112.083C133.937 111.726 133.501 111.007 133.501 110.216V107.501C133.501 106.425 134.015 105.392 134.879 104.743L140.19 100.76C140.29 100.689 140.425 100.703 140.504 100.803C140.575 100.903 140.561 101.038 140.461 101.117L135.15 105.1C134.401 105.67 133.951 106.568 133.951 107.508V110.223C133.951 110.836 134.301 111.413 134.843 111.691C134.95 111.748 134.993 111.883 134.936 111.99C134.893 112.068 134.822 112.111 134.736 112.111V112.104Z" fill="#4B4B4B" />
                                    <path id="Vector_94" d="M151.897 100.511C151.897 102.028 151.811 103.453 151.64 104.707C151.09 106.111 149.962 107.216 148.534 107.743C147.992 106.14 147.635 103.503 147.635 100.511C147.635 99.2138 147.706 97.9882 147.821 96.8838H151.704C151.825 97.9953 151.889 99.2209 151.889 100.511H151.897Z" fill="#C1C1C0" />
                                </g>
                            </g>
                        </g>
                        <g id="CUP">
                            <path id="Vector_95" d="M181.193 124.214C186.515 124.214 190.83 123.853 190.83 123.409C190.83 122.964 186.515 122.604 181.193 122.604C175.871 122.604 171.556 122.964 171.556 123.409C171.556 123.853 175.871 124.214 181.193 124.214Z" fill="#828282" />
                            <g id="Group_11">
                                <path id="Vector_96" d="M189.216 124.021H185.233C181.536 124.021 178.545 121.028 178.545 117.345V111.673H195.905V117.338C195.905 121.028 192.907 124.021 189.209 124.021H189.216Z" fill="white" />
                                <path id="Vector_97" d="M189.216 124.292H185.233C181.4 124.292 178.281 121.178 178.281 117.351V111.416H196.176V117.344C196.176 121.178 193.057 124.292 189.216 124.292ZM178.816 111.95V117.351C178.816 120.886 181.693 123.757 185.233 123.757H189.216C192.764 123.757 195.641 120.879 195.641 117.344V111.95H178.809H178.816Z" fill="#828282" />
                            </g>
                            <path id="Vector_98" d="M198.339 116.804H195.634V111.951H198.339C199.531 111.951 200.502 112.92 200.502 114.11V114.659C200.502 115.842 199.531 116.804 198.339 116.804ZM196.169 116.269H198.339C199.239 116.269 199.967 115.542 199.967 114.652V114.103C199.967 113.205 199.239 112.478 198.339 112.478H196.169V116.269Z" fill="#828282" />
                            <g id="Group_12">
                                <path id="Vector_99" d="M187.225 113.362C192.062 113.362 195.984 112.58 195.984 111.616C195.984 110.652 192.062 109.87 187.225 109.87C182.387 109.87 178.466 110.652 178.466 111.616C178.466 112.58 182.387 113.362 187.225 113.362Z" fill="#828282" />
                                <path id="Vector_100" d="M187.225 113.589C183.613 113.589 178.245 113.062 178.245 111.623C178.245 110.184 183.613 109.656 187.225 109.656C190.837 109.656 196.205 110.184 196.205 111.623C196.205 113.062 190.837 113.589 187.225 113.589ZM187.225 110.091C184.898 110.091 182.713 110.269 181.079 110.597C179.194 110.974 178.695 111.416 178.695 111.616C178.695 111.815 179.194 112.257 181.079 112.635C182.721 112.962 184.905 113.141 187.225 113.141C189.545 113.141 191.736 112.962 193.371 112.635C195.256 112.257 195.755 111.815 195.755 111.616C195.755 111.416 195.256 110.974 193.371 110.597C191.729 110.269 189.545 110.091 187.225 110.091Z" fill="white" />
                            </g>
                            <g id="Group_13">
                                <path id="Vector_101" d="M187.225 115.051C184.891 115.051 182.699 114.865 181.043 114.531C179.187 114.16 178.288 113.668 178.288 113.034H178.823C178.823 113.212 179.308 113.64 181.15 114.01C182.77 114.338 184.926 114.516 187.225 114.516C189.523 114.516 191.679 114.338 193.3 114.01C195.141 113.64 195.627 113.212 195.627 113.034H196.162C196.162 113.668 195.263 114.16 193.407 114.531C191.75 114.865 189.559 115.051 187.225 115.051Z" fill="#828282" />
                                <path id="Vector_102" d="M187.225 116.903C184.891 116.903 182.699 116.718 181.043 116.383C179.187 116.013 178.288 115.521 178.288 114.887H178.823C178.823 115.065 179.308 115.492 181.15 115.863C182.77 116.191 184.926 116.369 187.225 116.369C189.523 116.369 191.679 116.191 193.3 115.863C195.141 115.492 195.627 115.065 195.627 114.887H196.162C196.162 115.521 195.263 116.013 193.407 116.383C191.75 116.718 189.559 116.903 187.225 116.903Z" fill="#828282" />
                                <path id="Vector_103" d="M187.225 118.763C184.891 118.763 182.699 118.577 181.043 118.242C179.187 117.872 178.288 117.38 178.288 116.746H178.823C178.823 116.924 179.308 117.352 181.15 117.722C182.77 118.05 184.926 118.228 187.225 118.228C189.523 118.228 191.679 118.05 193.3 117.722C195.141 117.352 195.627 116.924 195.627 116.746H196.162C196.162 117.38 195.263 117.872 193.407 118.242C191.75 118.577 189.559 118.763 187.225 118.763Z" fill="#828282" />
                                <path id="Vector_104" d="M187.225 120.615C184.926 120.615 182.763 120.43 181.136 120.095C179.308 119.724 178.423 119.233 178.423 118.599H178.958C178.958 118.777 179.437 119.204 181.243 119.575C182.835 119.903 184.962 120.081 187.225 120.081C189.488 120.081 191.615 119.903 193.207 119.575C195.013 119.204 195.491 118.777 195.491 118.599H196.026C196.026 119.233 195.141 119.724 193.314 120.095C191.686 120.43 189.523 120.615 187.225 120.615Z" fill="#828282" />
                                <path id="Vector_105" d="M187.225 122.469C183.955 122.469 179.087 121.934 179.087 120.452H179.623C179.623 120.63 180.058 121.058 181.714 121.428C183.177 121.756 185.133 121.934 187.218 121.934C189.302 121.934 191.258 121.756 192.721 121.428C194.377 121.058 194.813 120.63 194.813 120.452H195.348C195.348 121.927 190.48 122.469 187.211 122.469H187.225Z" fill="#828282" />
                            </g>
                            <path id="Vector_106" d="M183.875 108.73C183.832 108.73 183.789 108.716 183.746 108.694C183.09 108.246 181.833 105.723 181.826 103.457C181.826 101.89 182.412 100.65 183.525 99.8804C183.596 99.8305 183.661 99.7877 183.732 99.745C183.504 98.6334 183.468 97.3722 183.704 96.2678C184.168 94.0946 185.538 93.0186 187.551 93.2253C188.865 93.3606 189.428 94.3724 189.4 95.313C189.371 96.2678 188.722 97.2511 187.537 97.3081C187.415 97.3081 187.308 97.2155 187.308 97.0943C187.308 96.9732 187.401 96.8663 187.523 96.8663C188.479 96.8236 188.936 96.0255 188.957 95.3059C188.979 94.6218 188.608 93.7882 187.508 93.6742C186.259 93.5459 184.667 93.9093 184.139 96.3676C183.918 97.3936 183.953 98.5408 184.139 99.5526C184.881 99.2747 185.374 99.5668 185.631 99.802C186.316 100.436 186.502 101.804 186.145 102.41C185.988 102.681 185.717 102.788 185.431 102.695C184.739 102.467 184.16 101.476 183.832 100.215C183.811 100.23 183.796 100.244 183.775 100.251C182.768 100.949 182.262 102.025 182.269 103.464C182.269 105.709 183.554 108.032 183.996 108.338C184.096 108.409 184.125 108.545 184.053 108.645C184.011 108.709 183.939 108.744 183.868 108.744L183.875 108.73ZM184.239 99.9872C184.532 101.17 185.031 102.089 185.574 102.267C185.638 102.289 185.695 102.296 185.767 102.182C186.002 101.783 185.881 100.636 185.331 100.123C185.038 99.8519 184.674 99.8091 184.239 99.9872Z" fill="white" />
                            <path id="Vector 1" d="M191.875 104.73C191.832 104.73 191.789 104.716 191.746 104.694C191.09 104.246 189.833 101.723 189.826 99.4573C189.826 97.8897 190.412 96.6499 191.525 95.8804C191.596 95.8305 191.661 95.7877 191.732 95.745C191.504 94.6334 191.468 93.3722 191.704 92.2678C192.168 90.0946 193.538 89.0186 195.551 89.2253C196.865 89.3606 197.428 90.3724 197.4 91.313C197.371 92.2678 196.722 93.2511 195.537 93.3081C195.415 93.3081 195.308 93.2155 195.308 93.0943C195.308 92.9732 195.401 92.8663 195.523 92.8663C196.479 92.8236 196.936 92.0255 196.957 91.3059C196.979 90.6218 196.608 89.7882 195.508 89.6742C194.259 89.5459 192.667 89.9093 192.139 92.3676C191.918 93.3936 191.953 94.5408 192.139 95.5526C192.881 95.2747 193.374 95.5668 193.631 95.802C194.316 96.4361 194.502 97.8042 194.145 98.4099C193.988 98.6806 193.717 98.7875 193.431 98.6949C192.739 98.4669 192.16 97.4765 191.832 96.2153C191.811 96.2295 191.796 96.2438 191.775 96.2509C190.768 96.9492 190.262 98.0251 190.269 99.4644C190.269 101.709 191.554 104.032 191.996 104.338C192.096 104.409 192.125 104.545 192.053 104.645C192.011 104.709 191.939 104.744 191.868 104.744L191.875 104.73ZM192.239 95.9872C192.532 97.1701 193.031 98.0892 193.574 98.2674C193.638 98.2887 193.695 98.2959 193.767 98.1819C194.002 97.7828 193.881 96.6357 193.331 96.1226C193.038 95.8519 192.674 95.8091 192.239 95.9872Z" fill="white" />
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_44_7317">
                            <rect width="259" height="233" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </CircleSvg>
        </>
    )
}

export default RemedialStudyAnimation