import { Box, Stack } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import RemedialPathBox from '../RemedialPathBox/RemedialPathBox'
import styled from 'styled-components'
import { useRemedialContext } from '../../hooks/useRemedial'
import useFetch from '../../../../hooks/useFetch'

const RemedialPathWrapper = ({ stages, activeBannerIndex}) => {
  const [lightIndex, setLightIndex] = useState(0);

  const {directBonusPoints} = useRemedialContext();

  // const { updateBannerIndex } = useRemedialContext();
  

  // const handleLightIndex = (stages) => {
  //   let lightIndex = 0;
  //   stages.forEach((item, index) => {
  //     if (item.is_position === 1) {
  //       lightIndex = index
  //     }
  //   })
  //   return lightIndex
  // }


  // stages && handleLightIndex(stages)


  // useEffect(() => {
  //   if(stages){
  //     setLightIndex(handleLightIndex(stages))
  //   }
  // }, [stages])




  return (
    <Stack flexDirection={'row'} sx={{ paddingTop: '40px' }}>
      <Box className='move'
        sx={{
          position: 'absolute',
          top: 0,
          left: 160, // change the highlight position
          width: '210px',
          height: '100%',
          opacity: 0.7,
          transition: 'all 0.5s linear',
          '&.move': {
            left: directBonusPoints !== '1' ? 160 + (264 * activeBannerIndex) : activeBannerIndex === 4 ? 160 + (264 * 3) : 160 + (264 * activeBannerIndex),

          }
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="211" height="254" viewBox="0 0 211 254" fill="none">
          <path d="M211 253.5C60 253.5 0.5 253.5 0.5 253.5L55 0H152.5L211 253.5Z" fill="url(#paint0_linear_390_38186)" />
          <defs>
            <linearGradient id="paint0_linear_390_38186" x1="117.855" y1="54.7108" x2="112.598" y2="309.047" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FFDB61" />
              <stop offset="1" stop-color="#FFE795" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </Box>
      {stages && stages.map((item, index) => (
        <RemedialPathBox
          key={index} data={item}
          index={index}
          isActive={item.is_completed}
        />
      ))}
    </Stack>
  )
}

export default RemedialPathWrapper