import moment from 'moment';
// import { GETTESTRESULTSDATA, GETRESULTSANALYTICS } from "../actions/questionnaireResultsActions"
const initial_state = {
    loading: true,
    loading_result_analytics:true,
    error_loading_results_analytics:false,
};

const Reducer = (state = initial_state, action)=> {
    if (action.type == 'GET_TEST_RESULTS_DATA') {
        let results = action.results;
        let analytics = action.analytics;
        let error = action.error;
        console.log(action)
        if (error) {
            return {
                ...state,
                loading: false,
                errorLoadingTestDetails: error
            }
        } else {
            let summary = action.summary.summary[0];
            let topic_wise_analysis_data = [];
            summary['topicWise_data'].map((topic)=> {
                let topic_data = {};
                if (topic['name'].length > 20 ) {
                    topic_data['name'] = topic['name'].slice(0,20) + '...';
                } else {
                    topic_data['name'] = topic['name'];
                }
                topic_data['correct questions count'] = topic['correct_questions_count'];
                topic_data['total questions count'] = topic['total_questions_count'];
                topic_wise_analysis_data.push(topic_data);
            });
            let time_analysis_data = [];
            summary['time_per_question'].map((que, index)=> {
                let ques_data = {};
                ques_data['index'] = index + 1;
                ques_data['student_time'] = que.timetaken;
                ques_data['average_time'] = que.time_spent_per_question;
                time_analysis_data.push(ques_data);
            })
            console.log(time_analysis_data);
            return {
                ...state,
                loading: false,
                summary: summary,
                objective_analytics:analytics,
                results: results,
                errorLoadingTestDetails: error,
                topic_wise_analysis_data: topic_wise_analysis_data,
                time_analysis_data: time_analysis_data
            }
        }
        
    }
    if (action.type == 'GET_RESULTS_ANALYTICS') {
        let results_analytics = action.analytics;
        console.log("efenfjenfjenfjndjsnjen")
        console.log(results_analytics)
        let error = action.error;
        console.log(results_analytics)
        if (error) {
            return {
                ...state,
                loading_result_analytics: false,
                error_loading_results_analytics: error
            }
        } else {
           
            return {
                ...state,
                loading_result_analytics: false,
                objectivetest_analytics: results_analytics,
                error_loading_results_analytics: error,
                
            }
        }
        
    }
    return {...state}
}

export default Reducer;