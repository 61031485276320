import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#F9FAFB',
  100: '#F4F6F8',
  200: '#DFE3E8',
  300: '#C4CDD5',
  400: '#919EAB',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  1000: '#FFFFFF'//212b36
};

const PRIMARY = {
  main: '#0B58F5',
  contrastText: '#fff',
  gradient800: '#0946C4',
  0: '#FFFFFF',
  100: '#E7EEFE',
  200: '#CEDEFD',
  300: '#B6CDFC',
  400: '#9DBCFB',
  500: '#6D9BF9',
  600: '#3C79F7',
  700: '#0B58F5',
  800: '#0946C4',
  900: '#073593',
  1000: '#042363',
  1100: '#031A4A',
  1200: '#021232',
  1300: '#010919',
  1400: '#000001',
};

const SECONDARY = {
  lighter: '#ECF2FA',
  light: '#ECF2FA',
  main: '#ECF2FA',
  dark: '#ECF2FA',
  darker: '#ECF2FA',
  contrastText: '#000000',
};

const INFO = {
  main: '#1076D4',
  contrastText: '#000000',
  0: '#E6F2FD',
  100: '#D1E6F9',
  200: '#BBD9F5',
  300: '#A6CDF1',
  400: '#90C0ED',
  500: '#66A8E4',
  600: '#3B8FDC',
  700: '#1076D4',
  800: '#0D5EAA',
  900: '#0A477F',
  1000: '#062F55',
  1100: '#052340',
  1200: '#03182A',
  1300: '#020C15',
  1400: '#000000',
};

const SUCCESS = {
  main: '#4ECD56',
  contrastText: GREY[800],
  0: '#FFFFFF',
  100: '#EDFAEE',
  200: '#DCF5DD',
  300: '#CAF0CC',
  400: '#B8EBBB',
  500: '#95E19A',
  600: '#71D778',
  700: '#4ECD56',
  800: '#40A846',
  900: '#318436',
  1000: '#235F27',
  1100: '#1C4D1F',
  1200: '#143B17',
  1300: '#0D280F',
  1400: '#061607',
  gradient1: 'linear-gradient(180deg, #FAD964 0%, #FFF 100%);'
};

const WARNING = {
  main: '#FFB63E',
  contrastText: GREY[800],
  0: '#FFFFFF',
  100: '#FFF8EC',
  200: '#FFF0D8',
  300: '#FFE9C5',
  400: '#FFE2B2',
  450: '#FFDB61',
  500: '#FFD38B',
  600: '#FFC565',
  700: '#FFB63E',
  800: '#D89932',
  900: '#B27D25',
  1000: '#8B6019',
  1100: '#785213',
  1200: '#65440C',
  1300: '#513506',
  1400: '#3E2700',
};

const ERROR = {
  main: '#F44336',
  contrastText: '#fff',
  0: '#FFFFFF',
  100: '#FEECEB',
  200: '#FDD9D7',
  300: '#FCC7C3',
  400: '#FBB4AF',
  500: '#F88E86',
  600: '#F6695E',
  700: '#F44336',
  800: '#CB372C',
  900: '#A32A21',
  1000: '#7A1E17',
  1100: '#661812',
  1200: '#52110C',
  1300: '#3D0B07',
  1400: '#290502',
};

const EXTERNALINFO = {
  0: '#E8EDF9',
  100: '#8799BF',
  200: '#497DAE',
  300: '#3D80D2',
};
const EXTERNALERROR = {
  0: '#FFE7E7',
  100: '#E9D1D3',
  200: '#F24A53',
};
const EXTERNALWARNING = {
  0: '#FFC727',
};

const EXTRAREDPALETTE = {
  0: '#FFFFFF',
  100: '#FFECED',
  200: '#FFD9DA',
  300: '#FFC5C8',
  400: '#FFB2B6',
  500: '#FF8C91',
  600: '#FF656D',
  700: '#FF3F48',
  800: '#D9323A',
  900: '#B2262C',
  1000: '#8C191F',
  1100: '#791318',
  1200: '#650D11',
  1300: '#52060A',
  1400: '#3F0003',
};



const palette = {
  mode: 'light',
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  extraredpalette: EXTRAREDPALETTE,
  externalInfo: EXTERNALINFO,
  externalerror: EXTERNALERROR,
  externalwarning: EXTERNALWARNING,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const GREYDARK = {
  0: 'linear-gradient(80deg, #030A0D -22.58%, #010601 9.59%, #000812 41.76%, #000E0B 73.93%, #001713 106.1%)', 
  100: '#454F5B',// 212B36
  200: '#454F5B',// 212B36, Background Gradient
  300: '#C4CDD5',// C4CDD5
  400: '#919EAB',// 919EAB
  500: '#919EAB',// 919EAB
  600: '#C4CDD5',// C4CDD5
  700: '#F4F6F8',// F4F6F8
  800: '#DFE3E8',// DFE3E8
  900: '#DFE3E8',// F4F6F8
  1000: '#212B36'// 212B36, Circle Gradient
  // 1000: 'rgba(37, 37, 37, 0.3)'// 212B36, Circle Gradient

};


const PRIMARYDARK = {
  main: '#0B58F5',
  contrastText: '#fff',
  gradient800: 'linear-gradient(180deg, #0053FF 0%, #00F0FF 100%);',
  0: '#B6CDFC',// #B6CDFC
  100: '#A5C1FB',// #A5C1FB
  200: '#94B6FB',// #94B6FB
  300: '#83AAFA',// #83AAFA
  400: '#729EF9',// #729EF9
  500: '#4F87F8',// #4F87F8
  600: '#2D6FF6',// #2D6FF6
  700: '#0B58F5',// #0B58F5
  800: '#2D6FF6',// #094CD3,
  900: '#0840B1',// #0840B1
  1000: '#0840B1',// #0840B1
  1100: '#052D7D',// #052D7D
  1200: '#05276C',// #05276C
  1300: '#04215B',// #04215B
  1400: '#031B4A',// #031B4A
};



const SECONDARYDARK = {
  lighter: '#ECF2FA',
  light: '#ECF2FA',
  main: '#ECF2FA',
  dark: '#ECF2FA',
  darker: '#ECF2FA',
  contrastText: '#000000',
};


const INFODARK = {
  main: '#1076D4',
  contrastText: '#000000',
  0: '#9FCDF8', // #9FCDF8
  100: '#91C4F4',// #91C4F4
  200: '#82BCF1',// #82BCF1
  300: '#74B3ED',// #74B3ED
  400: '#66AAEA',// #66AAEA
  500: '#4999E2',// #4999E2
  600: '#2D87DB',// #2D87DB
  700: '#1076D4',// #1076D4
  800: '#0D64B3',// #0D64B3
  900: '#0B5192',// #0B5192
  1000: '#083F70',// #083F70
  1100: '#073660',// #073660
  1200: '#062C4F',// #062C4F
  1300: '#04233F',// #04233F
  1400: '#031A2E',// #031A2E
};


const SUCCESSDARK = {
  main: '#4ECD56',
  contrastText: GREY[800],
  0: '#D9F4DB',// #D9F4DB
  100: '#CBF0CE', // #CBF0CE
  200: '#BDECC0',// #BDECC0
  300: '#AFE8B3',// #AFE8B3
  400: '#A1E4A6',// #A1E4A6
  500: '#86DD8B',// #86DD8B
  600: '#6AD571',// #6AD571, Progress Bar Gradient
  700: '#4ECD56',// #4ECD56
  800: '#43B44A',// #43B44A
  900: '#389B3E',// #389B3E
  1000: '#2D8333',// #2D8333
  1100: '#28762D',// #28762D
  1200: '#226A27',// #226A27
  1300: '#1D5D21',// #1D5D21
  1400: '#17511B',// #17511B
  gradient1: 'linear-gradient(180deg, #FAD964 0%, #FFF 100%);'
};


const WARNINGDARK = {
  main: '#FFB63E',
  contrastText: GREY[800],
  0: '#FFFAF1',// #FFFAF1
  100: '#FFF3DF',// #FFF3DF
  200: '#FFECCD',// #FFECCD
  300: '#FFE6BB',// #FFE6BB
  400: '#FFDFA9',// #FFDFA9
  450: '#FFD186',// #FFD186, Top Banner Gradient
  500: '#FFD186',// #FFD186
  600: '#FFC462',// #FFC462
  700: '#FFB63E',// #FFB63E
  800: '#E8A332',// #E8A332
  900: '#D19025',// #D19025
  1000: '#B97C19',// #B97C19
  1100: '#AE7313',// #AE7313
  1200: '#A2690C',// #A2690C
  1300: '#976006',// #976006
  1400: '#8B5600',// #8B5600
};

const ERRORDARK = {
  main: '#F44336',
  contrastText: '#fff',
  0: '#FDE1DF',// #FDE1DF
  100: '#FCD1CE',// #FCD1CE
  200: '#FBC1BD',// #FBC1BD
  300: '#FAB2AC',// #FAB2AC
  400: '#F9A29B',// #F9A29B
  500: '#F8827A',//#F8827A
  600: '#F66358',// #F66358
  700: '#F44336',// #F44336
  800: '#DA382C',// #DA382C
  900: '#C02E23',// #C02E23
  1000: '#A52319',// #A52319
  1100: '#981E14',// #981E14
  1200: '#8B1910',// #8B1910
  1300: '#7E130B',// #7E130B
  1400: '#710E06',// #710E06
};


const EXTERNALINFODARK = {
  0: '#E8EDF9',
  100: '#8799BF',
  200: 'linear-gradient(180deg, #101621 0%, rgba(34, 40, 52, 0.00) 212.8%)',
  300: '#3D80D2',
};

const EXTERNALERRORDARK = {
  0: '#FFE7E7',
  100: '#E9D1D3',
  200: '#F24A53',
};
const EXTERNALWARNINGDARK = {
  0: '#FFC727',
};

const EXTRAREDPALETTEDARK = {
  0: '#FFFFFF', 
  100: '#FFECED',
  200: '#FFD9DA',
  300: '#FFC5C8',
  400: '#FFB2B6',
  500: '#FF8C91',
  600: '#FF656D',
  700: '#FF3F48',
  800: '#D9323A',
  900: '#B2262C',
  1000: '#8C191F',
  1100: '#791318',
  1200: '#650D11',
  1300: '#52060A',
  1400: '#3F0003',
};


export default palette;



// Light Palettle

export const lightPalette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  extraredpalette: EXTRAREDPALETTE,
  externalInfo: EXTERNALINFO,
  externalerror: EXTERNALERROR,
  externalwarning: EXTERNALWARNING,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}




export const darkPalette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARYDARK,
  secondary: SECONDARYDARK,//
  info: INFODARK, //
  success: SUCCESSDARK,
  warning: WARNINGDARK,
  error: ERRORDARK,
  grey: GREYDARK,
  extraredpalette: EXTRAREDPALETTEDARK,
  externalInfo: EXTERNALINFODARK,
  externalerror: EXTERNALERRORDARK,
  externalwarning: EXTERNALWARNINGDARK,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}