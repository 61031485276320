import React, { Component } from "react";
import {connect} from 'react-redux';
import Flex from 'styled-flex-component';
import Circle from './UI/QuestionnaireCircle';
import Equation from './Equation';
import styled from 'styled-components';
import {getTestAC, submitQuestionAC, submitTestAC} from '../store/actions/questionnaireActions';
import moment from 'moment';
import MonogramTimer from './MonogramTimer/MonogramTimer';

const Rectangle = () => (
    <svg height="560" width="360">
        <polygon points="190,10 360,80 360,560 10,560 10,80 180,10">
        </polygon>
        Sorry, your browser does not support inline SVG.
    </svg>
);

const RectangleWrapper = styled.div`
    color: #FFFFFF;
    position: absolute;
    top: 10px;
    right: 8px;
    svg {
        // position: absolute;?
        display: block;
        fill: #FFFFFF;
        width: 100%;
        height: 100%;
        polygon {
            stroke: #FFFFFF;
            fill: #FFFFFF;
            stroke-width: 1px;
            transition: all 0.3s esae;
        }
        transition: all 0.3s esae;
    }
`
const Rectangle2 = () => (
    <svg height="960" width="360">
        <polygon points="180,10 360,80 360,960 10,960 10,80 180,10">
        </polygon>
        Sorry, your browser does not support inline SVG.
    </svg>
);

const HintDataDiv = styled.div`
    // &::before{
        background-image: url("/images/Combined Shape Copy.svg");
        background-position: 0 0;
    background-repeat: no-repeat;
    width: 151px;
    height: 200px;
    box-sizing: border-box;
    // }
`

const QuestionsGrid = styled.div`
    display: grid;
    display: grid;
    grid-template-columns: repeat(5,60px);
    grid-gap: 0.7em 0;

`

const OptionFlex = styled.div`
    margin: 10px;
    padding: 10px;
    margin-left: 0;
    align-items:center;
    cursor: pointer;
    &:hover{
        background-color: #F6E4E4;
    }
`

class AssessmentMarkedReview extends Component {
    state = {
        currentTime: 0
    }

    componentDidMount = () => {
        // document.body.style.overflow = 'hidden';
    }

    openFullQuestionsView = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState((prevState, props)=>({showFullQuestionsView: !prevState.showFullQuestionsView}));
    }
    setCurrentTime = (time, timer) => {
        // console.log(time);
        if (time == 0) {
            this.confirmSubmitTest();
        }
        this.setState({ currentTime: time, timer: timer });
    }

    closeMarked = ()=> {
        clearInterval(this.state.timer);
        this.props.closeDialog();
    }

    onQuestionChange = async (qid, type, doubt) => {
        console.log("qid sent " + qid);
         if(!qid && qid!=0) qid = this.props.marked[0] - 1;
        // console.log("qid sent " + qid);
        await this.props.onQuestionClick(qid, null, type, doubt);
        console.log("question submitted");
        await this.props.submitQuestion();
    };
    
    onOptionSelected = async (qid, option_id) => {
        await this.props.onOptionSelected(qid, option_id);
        // let qid_next = qid;
        // const marked_qid_index = this.props.marked.indexOf(qid + 1);;
        // console.log("qid " + qid);
        // console.log("marked_qid_index " + marked_qid_index);
        // console.log("marked " + JSON.stringify(this.props.marked));
        // console.log("this.props.marked[marked_qid_index + 1] " + this.props.marked[marked_qid_index + 1]);
        // if(!this.props.marked[marked_qid_index + 1]) qid_next = this.props.marked[0] - 1;
        // else qid_next = this.props.marked[marked_qid_index + 1] - 1;
        // console.log("qid sent " + qid_next);
        
        // await this.onQuestionChange(qid_next,1);
    }

    viewSkipped = async (reverse) => {
        console.log("clicked view marked");
        document.body.style.overflow = "hidden";
        // await this.props.submitQuestion();
        this.props.openSkippedDialogAction(reverse);
    };

    render () {
        if (this.props.error_loading_practice_test) {
            return <div>No Questions</div>
        }
        if (!this.props.questions) {
            return <div>Loading</div>
        }
        let question_ids = this.props.marked;
        let qid = this.props.qid;
        let answers = this.props.answers;
        let option_numbers = {0: 'a', 1: 'b', 2: 'c', 3: 'd', 4: 'e'};
        let questions = this.props.questions;
        // console.log(this.props);
        let questions_numbers_indexes = question_ids.slice(this.props.currentQuestionMarkedNumberIndex *10, this.props.currentQuestionMarkedNumberIndex *10 + 10).map((que, index)=> {
            return que;
        });
        // console.log(questions_numbers_indexes);
        return (
            <div style={{}}>
                <Flex row style = {{ width: '100%', paddingBottom:'50px'}}>
                    <div style={{width: '72%',marginTop: '30px',backgroundColor: '#fff', border:'1px solid #fff',overflow: 'auto',maxHeight: '600px', boxSizing: 'border-box', borderRadius: '10px', padding: '10px', padding: '10px'}}>
                        <Flex row style = {{margin: '8px', marginTop: '20px', marginBottom: '30px'}}>
                            <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                                Marked Questions
                            </div>
                            <div  onClick={() => this.closeMarked()} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',  margin: 'auto', marginRight: '10px'}}>
                                <div style={{color: '#000', cursor: 'pointer', fontSize: '16px'}}>Back to Original View</div>
                                <div>
                                    <div className = "close1" >&times;</div>
                                </div>
                            </div>
                        </Flex>
                        {question_ids.length > 10 ? 
                        <div>
                            <Flex row>
                                {this.props.question_marked_numbers.map((number, index)=> {
                                    return <Flex row style={{marginTop: '15px'}} key={index}>
                                        {this.props.currentQuestionMarkedNumberIndex == index ? 
                                            questions_numbers_indexes.map((que, qNoIndex)=> {
                                                return <Flex column key={qNoIndex}>
                                                    {que -1 == qid ? <img style={{marginTop: '-15px', marginBottom: '-10px'}} src= '/images/Small_monkey.svg' alt="monkey"></img>: null}
                                                    <Circle onQuestionClick = {this.onQuestionChange} current={que -1 == qid ? true: false} number={que} key={que -1} answered={answers[que - 1].question_option_id ? true : false}></Circle>
                                                </Flex>
                                            }) :
                                        <div onClick={()=>this.props.changeCurrentQuestionMarkedNumberIndex( index)} key={index} style={{margin: '8px', width: '42px'}}>
                                            <img width='100%' src='/images/Buffer.svg'></img>
                                        </div>
                                    }
                                    </Flex>
                                })}
                            </Flex>
                        </div> 
                        : 
                        <div>
                            <Flex row style={{marginTop: '15px'}}>
                                {question_ids.map((number, index)=> (
                                    <Flex column key={index}>
                                        {number -1 == qid ? <img style={{marginTop: '-15px', marginBottom: '-10px'}} src= '/images/Small_monkey.svg'></img>: null}
                                        <Circle current={number -1 == qid ? true: false} onQuestionClick = {this.onQuestionChange} number={number} key={number -1} answered={answers[number - 1].question_option_id ? true : false}></Circle>

                                    </Flex>
                                ))}
                            </Flex>
                        </div>}
                        <div style={{marginLeft: '8px',marginTop: '20px'}}>
                            <Flex row style={{ height: "325px", overflow: "auto" }}>
                                <Flex row style={{width: '85%'}}>
                                    <div style={{fontWeight: 'bold', fontSize: '16px', marginRight: '8px', width: '2%'}}>
                                        {qid + 1}.
                                    </div>
                                    <Flex column style={{marginLeft: '10px',width: '98%'}}>

                                        <Flex column style={{marginBottom:'20px', textAlign: 'left'}}>
                                            <Equation blooms={questions[qid].blooms_level} math_type={questions[qid].math_type} bold equation={questions[qid].name}></Equation>
                                            {questions[qid].question_img && questions[qid].question_img != ' ' ? 
                                                <div style={{width: '324px', height: '216px', margin: '10px'}}>
                                                    <img width='324px' height='216px' src={questions[qid].question_img} alt="question"></img>
                                                </div>: null}
                                        </Flex>
                                        
                                        <div>
                                        {questions[qid].test_questions_options.map(
                                            ({ id, name, media, option_img, is_answer }, i) => {
                                            let option_selected = answers[qid].question_option_id;
                                            let is_selected = true ? option_selected === id : false;
                                            return (
                                                <OptionFlex onClick={() => this.onOptionSelected(qid, id)} style ={{ color: is_selected ? '#4E54C8' : null, fontWeight: is_selected ? '600' : '400', alignItems: 'center', backgroundColor: is_selected ? '#DDDDDD' : null}} key={id}>
                                                <Flex row key={i} >
                                                    <div style={{margin: 'auto',marginRight: '8px', width: '2%', marginLeft: '10px'}}>{option_numbers[i]})</div>
                                                    <div>
                                                        {option_img ? <div style={{margin: '10px',marginTop: '0px', width: '150px', height: '100px'}}>
                                                            <img  width=  '150px' height = '100px' src={option_img} alt="" />
                                                            </div> : null}
                                                    </div>
                                                    <div style = {{margin: 'auto',marginLeft: '10px'}}>
                                                        <Equation bold={is_selected} answered={is_selected}  math_type={questions[qid].math_type} equation={name}></Equation>
                                                    </div>
                                                </Flex>
                                            </OptionFlex>)
                                            }
                                            )}
                                        </div>
                                    </Flex>
                                </Flex>
                                
                                <Flex column style={{width: '15%'}}>
                                    {/* <div onClick={this.onHintToggle} >
                                        <img className='hint' src='/images/Tips.svg'></img>
                                    </div>
                                    {this.state.showHint ? <HintDataDiv>
                                        <div style={{marginTop: '20px', color: 'white', padding: '10px'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                                    </HintDataDiv> : null} */}
                                    {questions[qid].section_name ? 
                                    <div style={{fontWeight: 'bold'}}>{questions[qid].section_name }</div> : null}
                                </Flex>
                            </Flex>
                            {this.props.marked.indexOf(qid +1) != 0 ? <div className = 'submitButton' onClick={() =>this.onQuestionChange(question_ids[question_ids.indexOf(qid+1)-1]-1,4)} style={{float: 'left', marginRight: '40px'}}>
                                <div style={{marginRight: '10px'}}>
                                    <img src ='/images/left_arrow.svg' alt="left arrow"/>
                                </div>
                                <div>{answers[qid].question_option_id ? "Confirm & " : ""} Previous</div>
                            </div>:  null}
                            {this.props.skipped.length > 0 ? 
                                <Flex row style={{float: 'left', margin: 'auto', marginRight: '40px', color: '#6568D9', fontSize: '15px', marginTop: '8px'}}>
                                    <div onClick={this.viewSkipped} className="row no-gutters" style={{cursor: 'pointer'}}>
                                        <div className="col-1 pr-2">
                                            <img src ='/images/left_arrow.svg' alt="left arrow"/>
                                        </div>
                                        <div className="col-11">Skipped questions</div>
                                    </div>
                                </Flex>
                            : null }                            
                            {this.props.marked.length -1 != this.props.marked.indexOf(qid+1) || answers[qid].question_option_id ? <div className='nextButton' onClick={() =>this.onQuestionChange(question_ids[question_ids.indexOf(qid+1)+1]-1,1)} style={{float: 'right', marginRight: '40px'}}>
                                {/* <img src='/images/Group 15.svg'></img> */}
                                <div style={{margin: 'auto', marginTop: '5px'}}>{answers[qid].question_option_id ? "Confirm " : ""} Next</div>
                                <div style={{margin: 'auto', marginLeft: '10px'}}>
                                    <img src ='/images/Right_arrow.svg' alt="right arrow"/>
                                </div>
                            </div>: null}
                        </div>
                        
                    </div>
                    <div style={{width: '380px', maxHeight: '580px', position: 'absolute', left: '71%'}}>
                        {this.state.showFullQuestionsView ? 
                        <>
                            <RectangleWrapper>
                                <Rectangle2>
                                    
                                </Rectangle2>
                            </RectangleWrapper>
                        </>
                        :
                        <>
                            <RectangleWrapper>
                                <Rectangle>
                                    
                                </Rectangle>
                            </RectangleWrapper>
                        </> 
                        }
                        
                        <div style={{position: 'absolute', top: '0', right: '30px', justifyContent: 'center', alignItems: 'center'}}>
                            <div>
                                <div style={{widht: '275px', height: '160px'}}>
                                    <MonogramTimer seconds={this.props.timeremaining} onTimerUpdate={this.setCurrentTime} />

                                </div>
                                <div style={{marginTop: '20px', borderBottom: '2px solid #eee', width: '290px', marginLeft: '10px'}}></div>
                                <Flex style={{width: '312px', height: '53px', backgroundColor: 'white', boxShadow: '5px 10px 20px #eee', padding: '17px 5px', boxSizing: 'border-box',borderRadius: '11px', marginTop: '20px'}}>
                                    <Flex row style={{width: '50%', margin: 'auto'}}>
                                        <div style={{width: '70%', margin: 'auto'}}>answered</div>
                                        <div style={{width: '30%', fontSize: '24px', fontWeight:'500', color:'#4E54C8'}}>{this.props.answered}</div>
                                    </Flex>
                                    <Flex row style={{width: '50%', margin: 'auto'}}>
                                        <div style={{width: '70%', margin: 'auto'}}>remaining</div>
                                        <div style={{width: '30%', fontSize: '24px', fontWeight:'500'}}>{this.props.remaining_questions}</div>
                                    </Flex>
                                </Flex>
                                <Flex style={{width: '312px', height: 'auto', backgroundColor: 'white', boxShadow: '5px 10px 20px #eee', padding: '12px 5px', boxSizing: 'border-box',borderRadius: '11px', marginTop: '20px'}}>
                                    <Flex column style={{width: '50%', margin: 'auto'}}>
                                        <Flex row>
                                            <div style={{width: '70%', margin: 'auto'}}>skipped</div>
                                            <div style={{width: '30%', fontSize: '24px', fontWeight:'500', color: 'black'}}>{this.props.skipped.length}</div>
                                        </Flex>
                                        <Flex row style={{fontSize: '14px', color: '#4E54C8'}}>
                                            {/* <div style={{width: '70%'}}>See All
                                                <img style={{marginLeft: '10px'}} src='/images/Right_arrow_2.svg'></img>
                                            </div> */}
                                        </Flex>
                                    </Flex>
                                    <Flex column style={{width: '50%', margin: 'auto'}}>
                                        <Flex row>
                                            <div style={{width: '70%', margin: 'auto'}}>marked</div>
                                            <div style={{width: '30%', fontSize: '24px', fontWeight:'500', color: 'red'}}>{this.props.marked.length}</div>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                {/* <div onClick={this.submitTest} style={{display: 'flex', flexDirection: 'row',marginTop: '20px', color: '#6568d9'}}>
                                    <div>Submit exam</div>
                                    <div style={{marginLeft:'10px'}}>
                                        <img src='/images/Right_arrow_blue.svg'></img>
                                    </div>

                                </div> */}
                                {/* <div>
                                    <div onClick={this.openFullQuestionsView} style={{marginTop: '50px', marginLeft: '96%'}}>
                                        <img src='/images/BlueGradientCircle.svg'></img>
                                    </div>
                                    <div onClick={this.openFullQuestionsView} style={{marginTop: '-50px', marginLeft: '101%'}}>
                                        <img src='/images/viewAllQuestions.svg'></img>
                                    </div>
                                </div> */}
                                {this.state.showFullQuestionsView ? 
                                <div style={{marginTop: '-70px'}}>
                                    <QuestionsGrid>
                                        {question_ids.map((que, index)=> {
                                            return <Flex column key={index}>
                                            {/* {index == qid ? <img style={{marginTop: '-15px', marginBottom: '-10px'}} src= '/images/Small_monkey.svg'></img>: null} */}
                                            <Circle current={que -1 == qid ? true: false} answered={answers[que-1].question_option_id ? true : false} marked={ true } skipped = {this.props.skipped.indexOf(questions[que-1].id) == -1 ? false : true} onQuestionClick = {this.onQuestionChange} number={que} key={que -1}></Circle>
    
                                        </Flex>
                                        })}
                                    </QuestionsGrid>
                                </div> : null}
                            </div>
                        </div>
                        
                    </div>
                </Flex>
                <style  jsx scoped='true'>{`
                    .submitButton{
                        border: 1px solid #6568D9;
                        // border-image: linear-gradient(to right bottom, #6568D9, #9055CA) 1;
                        margin: auto;
                        // margin-left: 20px;
                        float: right;
                        width: max-content !important;
                        max-width: 200px !important;
                        height: 23px; 
                        padding: 0.3em 1em;
                        padding-top: 8px;
                        padding-left: 20px;
                        border-radius: 20px; 
                        font-size: 14px;
                        color: #6568D9;
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        &:focus{
                            outline:0;
                        }
                          
                      }
                    .nextButton {
                        background:
                            linear-gradient(to right bottom, #7175E0 0%, #4D50CB 32.912%, #E25BC8 100%) padding-box, 
                            linear-gradient(to right bottom, #7175E0 0%, #4D50CB 32.912%, #E25BC8 100%) border-box;
                        margin: auto;
                        float: right;
                        padding: 0.3em 1em;
                        border-radius: 20px; 
                        font-size: 14px;
                        width: max-content !important;
                        max-width: 200px !important;
                        display: flex;
                        flex-direction: row;
                        height: min-content;
                        box-sizing: border-box;
                        font-weight: 500;
                        color: white;
                        border: none;
                        cursor: pointer;
                    }
                    .close1 {
                        color: #000000;
                        float: right;
                        font-size: 28px;
                        font-weight: bold;
                        margin-top: -10px;
                        margin-left: 10px;
                      }
                      
                      .close1:hover,
                      .close1:focus {
                        color: #000000;
                        text-decoration: none;
                        cursor: pointer;
                      }
                `}

                </style>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        questions: state.ques.questions,
        answers: state.ques.answers,
        test_id: state.ques.practice_id,
        qid: state.ques.qid,
        timelimit: state.ques.timelimit,
        practice_name: state.ques.practice_name,
        error_loading_practice_test: state.ques.error_loading_practice_test,
        loading: state.ques.loading_practice_test,
        remaining_questions: state.ques.remaining_questions,
        answered: state.ques.answered,
        skipped: state.ques.skipped,
        marked: state.ques.marked,
        question_numbers: state.ques.question_numbers,
        currentQuestionNumberIndex: state.ques.currentQuestionNumberIndex,
        question_marked_numbers: state.ques.question_marked_numbers,
        currentQuestionMarkedNumberIndex: state.ques.currentQuestionMarkedNumberIndex
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onQuestionClick: (qid, question_option_id,type)=>dispatch({type: 'ON_TEST_QUESTION_CLICK', qid: qid, question_option_id: question_option_id, marked: true,action_type: type  }),
        getQuestions: ()=>dispatch(getTestAC()),
        onOptionSelected: (qid,option_id)=>dispatch({type: 'ON_OPTION_SELECTED', qid: qid, option_id: option_id}),
        onMarkedAsDoubt: (qid)=>dispatch({type: 'ON_MARKED_AS_DOUBT', qid: qid}),
        submitTest: ()=>dispatch(submitTestAC()),
        changeCurrentQuestionNumberIndex: (index)=> dispatch({type: 'ON_CURRENT_QUESTION_NUMBER_INDEX_CHANGED', index: index}),
        changeCurrentQuestionMarkedNumberIndex: (index)=> dispatch({type: 'ON_CURRENT_QUESTION_MARKED_NUMBER_INDEX_CHANGED', index: index}),
        submitQuestion: () => dispatch(submitQuestionAC()),
        openSkippedDialogAction: () => dispatch({ type: "OPEN_SKIPPED_DIALOG_ACTION" }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentMarkedReview);