import { styled } from '@mui/material';
import React, { memo } from 'react'

const VideoLesseonsCard = ({ data }) => {

    console.log(data, 'data for videos')

    const VideoWrapper = styled('div')(({ theme }) => ({
        '&.video-responsive': {
            overflow: 'hidden',
            paddingBottom: '56.25%',
            position: 'relative',
            width: '100%',
            height: '100%',
            iframe: {
                left: 0,
                top: 0,
                height: '100%',
                width: '100%',
                position: 'absolute'
            }
        }
    }));

    // Extract the video ID from the YouTube link
    const videoId = data.file_url.split('v=')[1];

    // Construct the embedded YouTube link
    const embedLink = `https://www.youtube.com/embed/${videoId}`;

    return (
        <>
            <VideoWrapper className='video-responsive'>
                <iframe
                    width="100%"
                    height="100%"
                    src={embedLink}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                    
                />
            </VideoWrapper>
        </>
    )
}

export default memo(VideoLesseonsCard)