import {Typography, styled } from '@mui/material';
import React from 'react'
import Equation from '../../../../../utils';

const QuestionType1Choice = ({index, questionData, selectedIndex, title, id , newSelection , handleClicked }) => {

    const ChoiceWrapper = styled('div')(({ theme }) => ({
        borderRadius: '4px',
        background: newSelection === id ?  theme.palette.primary[100] : selectedIndex === id ? theme.palette.primary[100] : theme.palette.grey[100],
        color: newSelection === id ? theme.palette.primary[800] : selectedIndex === id ? theme.palette.primary[800] : theme.palette.grey[800],
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        gap: '10px'

    }));

    return (
        <ChoiceWrapper onClick={() => handleClicked(id,questionData,index)}>
            <Typography component={'p'} sx={{
                fontFamily: 'Poppins-Medium',
                fontSize: '14px',
                lineHeight:'20px'
            }}>
            <Equation equation={title}  math_type={title?.includes('$') ? 1 : 0} />
            </Typography>
        </ChoiceWrapper>
    )
}

export default QuestionType1Choice




