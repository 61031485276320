import React from 'react'
import styled from 'styled-components';
import Gif from '../../../../../assets/remedial/gif/RemedialTest.gif'


const RemedialTestAnimation = () => {

    const CircleSvg = styled('div')(({ theme }) => ({
        img: {
            minWidth: '100px',
            minHeight: '100px',
            objectFit: 'contain',
            bottom: '-16px',
            position: 'relative'
        },
        svg: {
            minWidth: '150px',
            minHeight: '150px',
            maxWidth: '150px',
            maxHeight: '150px',
            position: 'relative',
            top: 16,
            left:'-8px'
        }
    }));


    return (
        <>
            <CircleSvg className='enabled-animation'>
                <img src={Gif} alt="testResultGif" />
            </CircleSvg>
            <CircleSvg className='disabled-animation'>
                <svg width="185" height="246" viewBox="0 0 185 246" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M103.913 188.121L102.432 192.156L95.6031 210.776C93.0428 212.591 89.8046 213.753 86.1758 213.753C85.7472 213.753 85.2319 213.65 84.7926 213.623C80.9956 213.362 77.356 211.694 74.8391 209.576L76.8786 187.116L77.0251 185.486C88.0363 189.761 100.881 189.044 103.913 188.115V188.121Z" fill="#C1BDBD" />
                    <path d="M103.913 188.123L101.017 196.005C92.1806 197.205 79.2384 189.97 76.8843 187.124L77.0307 185.494C88.0365 189.769 100.876 189.052 103.913 188.123Z" fill="#4B4B4B" />
                    <path d="M95.7442 210.755L93.6559 232.677C93.2817 238.456 88.8555 242.883 83.6048 242.883C83.3662 242.883 83.1329 242.872 82.8888 242.856C77.3236 242.438 73.1361 237.191 73.532 231.145L74.8013 211.77L74.9315 209.695C76.0489 210.749 77.3561 211.651 78.799 212.351C80.6106 213.226 82.6285 213.796 84.7927 213.965C85.2321 213.997 85.6606 214.019 86.0891 214.019C87.3313 214.019 88.5463 213.878 89.7017 213.617C89.7342 213.606 89.783 213.601 89.8156 213.59C91.9744 213.079 93.9543 212.145 95.5978 210.874C95.6575 210.841 95.7117 210.803 95.7551 210.76L95.7442 210.755Z" fill="white" />
                    <path d="M83.6051 243.105C83.3665 243.105 83.1224 243.094 82.8729 243.078C77.1991 242.648 72.9086 237.293 73.31 231.133L74.5792 211.758L74.742 209.222L75.0783 209.542C76.2011 210.596 77.4812 211.476 78.8915 212.16C80.7412 213.056 82.7318 213.589 84.8039 213.752C85.2378 213.784 85.6609 213.806 86.084 213.806C87.2882 213.806 88.487 213.67 89.6477 213.41C89.664 213.41 89.6857 213.399 89.7074 213.399C89.7237 213.399 89.7454 213.393 89.7562 213.388C91.8608 212.894 93.8298 211.97 95.4571 210.71L95.4842 210.694C95.5385 210.661 95.571 210.639 95.5981 210.618L96.0266 210.189L93.8786 232.719C93.6942 235.555 92.5389 238.184 90.6187 240.123C88.7039 242.056 86.2142 243.121 83.6106 243.121L83.6051 243.105ZM75.1162 210.167L75.0132 211.785L73.7439 231.16C73.3588 237.081 77.4649 242.23 82.9 242.638C83.1387 242.654 83.3719 242.665 83.5997 242.665C86.0894 242.665 88.4653 241.644 90.2987 239.797C92.1429 237.934 93.2549 235.403 93.4339 232.665L95.4788 211.226C93.8515 212.426 91.9151 213.317 89.8539 213.801C89.8376 213.801 89.8159 213.811 89.7942 213.817C89.7779 213.817 89.7562 213.822 89.7454 213.828C88.552 214.099 87.3208 214.235 86.0786 214.235C85.6447 214.235 85.2161 214.219 84.7659 214.181C82.6396 214.018 80.6001 213.469 78.6962 212.546C77.389 211.91 76.1848 211.112 75.1162 210.161V210.167Z" fill="#CDCDCD" />
                    <path d="M83.7621 238.476C83.556 238.476 83.3444 238.465 83.1383 238.449C78.327 238.08 74.6819 233.533 75.0237 228.308L76.1844 210.851L76.6184 210.878L75.4576 228.335C75.1321 233.322 78.5928 237.662 83.1709 238.009C83.3661 238.025 83.5614 238.036 83.7567 238.036C88.0961 238.036 91.7357 234.337 92.0395 229.612L93.5203 212.062L93.9542 212.1L92.4734 229.644C92.3161 232.051 91.3343 234.283 89.707 235.929C88.0798 237.569 85.9643 238.476 83.7512 238.476H83.7621Z" fill="#CDCDCD" />
                    <path d="M89.7888 213.394C88.6335 213.633 87.3317 213.676 86.0895 213.676C85.661 213.676 85.2325 213.66 84.7931 213.633C82.6343 213.486 80.6056 212.959 78.7994 212.15L78.2244 209.314C77.8555 208.103 78.7777 206.886 80.0415 206.93L88.4382 207.174C89.5068 207.201 90.3204 208.13 90.2228 209.189L89.7834 213.394H89.7888Z" fill="#828282" />
                    <path d="M89.7021 213.308L88.6444 226.632C88.4599 229.217 86.0136 231.124 83.7029 230.706L83.5185 230.689C80.2314 230.086 77.7091 226.654 78.1376 222.971L78.8048 212.146C80.6165 212.949 82.6343 213.476 84.7986 213.628C85.238 213.661 85.6665 213.672 86.095 213.672C87.3371 213.683 88.5522 213.547 89.7075 213.313L89.7021 213.308Z" fill="#828282" />
                    <path d="M88.4163 227.956C88.4163 227.956 88.3837 227.956 88.3675 227.951L78.7883 225.762C78.1645 225.621 77.9041 225.089 77.9258 224.627C77.9475 224.16 78.2621 223.655 78.9022 223.578L88.0637 222.503C88.541 222.443 88.7417 222.074 88.7743 221.775C88.8014 221.476 88.6712 221.08 88.2156 220.933L79.4446 218.147C78.734 217.919 78.3055 217.294 78.3489 216.555C78.3923 215.811 78.8859 215.235 79.6182 215.089L86.5286 213.888C86.2086 213.888 85.8723 213.883 85.5306 213.872C82.4442 213.78 80.1497 213.329 78.7069 212.536C78.6038 212.476 78.5604 212.346 78.6201 212.237C78.6798 212.134 78.81 212.09 78.9184 212.15C82.2164 213.97 89.6204 213.345 89.691 213.34C89.8049 213.329 89.9134 213.416 89.9242 213.53C89.9405 213.644 89.8591 213.753 89.7452 213.774L79.6941 215.518C79.0595 215.648 78.8045 216.17 78.7828 216.577C78.7611 216.984 78.9564 217.528 79.5748 217.729L88.3458 220.515C89.0021 220.721 89.2516 221.319 89.2028 221.813C89.154 222.313 88.8014 222.856 88.1125 222.938L78.9564 224.013C78.5387 224.062 78.376 224.388 78.3652 224.649C78.3543 224.909 78.4791 225.241 78.8859 225.333L88.4651 227.522C88.5844 227.549 88.6549 227.669 88.6278 227.783C88.6061 227.886 88.5139 227.951 88.4163 227.951V227.956Z" fill="white" />
                    <path d="M121.987 138.849L103.913 188.12C100.876 189.049 86.561 190.277 72.5828 183.786L83.5777 132.853C84.6029 125.145 91.1608 119.382 98.9391 119.382H107.021C117.175 119.371 124.596 129.007 121.987 138.855V138.849Z" fill="#828282" />
                    <path d="M98.153 183.85C96.824 183.85 95.3703 183.807 93.8244 183.698C88.6551 183.345 80.9039 182.188 73.348 178.679C73.2178 178.619 73.1581 178.462 73.2178 178.331C73.2774 178.201 73.4348 178.141 73.5649 178.201C87.4998 184.67 103.013 183.502 105.937 182.612C106.078 182.568 106.224 182.65 106.262 182.785C106.306 182.927 106.224 183.073 106.089 183.111C104.988 183.448 102.118 183.845 98.1476 183.845L98.153 183.85Z" fill="white" />
                    <path d="M128.013 189.09L126.196 192.321L114.555 212.723C110.069 214.499 104.775 215.156 100.034 214.157L99.4378 213.989C97.0946 213.5 94.6971 212.185 92.9993 210.713L101.494 187.981H101.51C104.439 188.954 114.517 191.686 128.013 189.084V189.09Z" fill="#C1BDBD" />
                    <path d="M128.013 189.09L124.172 195.864C109.391 198.227 101.011 189.319 101.011 189.319L101.499 187.993C104.439 188.955 114.517 191.692 128.013 189.09Z" fill="#4B4B4B" />
                    <path d="M152.921 146.663L132.759 188.145C115.694 192.42 104.412 188.96 101.499 187.999L122.6 131.492C124.042 128.065 126.44 125.241 129.407 123.269C132.374 121.297 135.905 120.184 139.615 120.184C153.664 120.184 159.718 134.339 152.926 146.663H152.921Z" fill="#828282" />
                    <path d="M118.737 186.031C117.104 186.031 115.488 185.966 113.893 185.83C110.08 185.509 106.429 184.803 103.332 183.777C103.197 183.733 103.121 183.581 103.164 183.445C103.207 183.309 103.359 183.233 103.495 183.277C106.549 184.287 110.161 184.988 113.931 185.303C120.657 185.862 127.73 185.232 134.961 183.418C135.102 183.38 135.243 183.467 135.281 183.608C135.314 183.749 135.232 183.891 135.091 183.929C129.52 185.325 124.042 186.025 118.737 186.025V186.031Z" fill="white" />
                    <path d="M122.337 131.385L91.3479 215.094L91.8412 215.277L122.83 131.568L122.337 131.385Z" fill="white" />
                    <path d="M114.377 212.785L113.693 214.583L105.958 234.844C103.745 241.791 96.466 245.913 90.0763 244.327L89.7237 244.213C85.2379 243.1 82.3847 237.69 84.1422 232.904L92.9782 211.286L93.1627 210.841C94.2746 211.84 95.5656 212.66 97.0139 213.258C97.7841 213.573 98.5977 213.828 99.4439 214.013L100.041 214.181C102.552 214.708 105.139 214.801 107.694 214.507C109.983 214.225 112.245 213.649 114.377 212.791V212.785Z" fill="white" />
                    <path d="M92.8971 244.89C91.9262 244.89 90.9607 244.776 90.0223 244.542H90.006L89.6643 244.428C87.5109 243.891 85.6178 242.353 84.4841 240.202C83.2637 237.899 83.063 235.211 83.9417 232.832L92.7778 211.208L93.0815 210.475L93.3094 210.681C94.4213 211.68 95.696 212.484 97.0955 213.06C97.8603 213.375 98.6685 213.625 99.4876 213.804H99.4984L100.084 213.973C102.536 214.483 105.085 214.592 107.662 214.293C109.945 214.016 112.175 213.44 114.29 212.593L114.751 212.408L113.889 214.673L106.159 234.928C105.08 238.307 102.786 241.169 99.6883 242.989C97.5348 244.254 95.197 244.906 92.8917 244.906L92.8971 244.89ZM90.1362 244.119C93.2009 244.874 96.5151 244.336 99.4767 242.598C102.482 240.832 104.711 238.057 105.753 234.776V234.765L113.488 214.505L113.992 213.169C111.98 213.935 109.87 214.456 107.716 214.717C105.091 215.021 102.493 214.912 99.992 214.391H99.9758L99.3845 214.222C98.5383 214.038 97.7084 213.783 96.9219 213.457C95.5713 212.897 94.3345 212.142 93.2443 211.202L93.1792 211.365L84.3431 232.984C83.5132 235.238 83.7031 237.796 84.8638 239.991C85.9433 242.033 87.7333 243.494 89.7728 243.999H89.789L90.1308 244.113L90.1362 244.119Z" fill="#CDCDCD" />
                    <path d="M94.1551 240.118C93.3361 240.118 92.5278 240.02 91.7359 239.825H91.7196L91.4376 239.727C89.6204 239.271 88.0312 237.973 87.0711 236.169C86.0459 234.23 85.8777 231.971 86.61 229.966L94.1009 211.759C94.1443 211.645 94.2744 211.596 94.3883 211.64C94.5023 211.683 94.5511 211.814 94.5077 211.928L87.0222 230.129C86.3334 232.009 86.4907 234.138 87.4616 235.968C88.362 237.669 89.8537 238.885 91.5515 239.309H91.5677L91.8498 239.407C94.4046 240.042 97.171 239.586 99.6444 238.136C102.15 236.664 104.016 234.344 104.884 231.607V231.596L111.665 213.655C111.708 213.541 111.833 213.487 111.947 213.53C112.06 213.573 112.115 213.698 112.071 213.812L105.296 231.748C104.391 234.583 102.46 236.99 99.8614 238.516C98.0551 239.58 96.0861 240.124 94.1551 240.124V240.118Z" fill="#CDCDCD" />
                    <path d="M109.278 211.792L107.688 214.491C105.144 214.79 102.552 214.692 100.035 214.166L99.4381 213.997C98.5919 213.823 97.7783 213.568 97.0081 213.242L97.6373 208.864C97.7837 207.81 98.7981 207.104 99.8395 207.322L108.063 209.049C109.305 209.304 109.923 210.706 109.278 211.792Z" fill="#828282" />
                    <path d="M107.683 214.486L107.64 214.557L103.528 225.436C102.4 228.967 98.7059 231.058 95.4568 230.254L95.2832 230.195C92.9996 229.624 91.5459 226.876 92.4518 224.448L96.9918 213.411L97.019 213.242C97.7892 213.557 98.6028 213.813 99.449 213.997L100.046 214.166C102.557 214.698 105.144 214.785 107.688 214.491L107.683 214.486Z" fill="#828282" />
                    <path d="M101.76 227.632C101.711 227.632 101.657 227.632 101.608 227.626L92.1588 226.6C92.0395 226.589 91.9527 226.48 91.9636 226.361C91.9744 226.241 92.0829 226.154 92.2022 226.165L101.651 227.192C102.167 227.246 102.459 226.904 102.552 226.589C102.644 226.279 102.595 225.834 102.145 225.595L94.7896 221.662C94.1658 221.331 94.0194 220.684 94.155 220.19C94.2906 219.696 94.7462 219.212 95.4568 219.245L104.748 219.663C105.313 219.69 105.616 219.305 105.709 218.968C105.801 218.626 105.736 218.137 105.237 217.871L96.8996 213.422C96.7966 213.368 96.7532 213.238 96.8074 213.129C96.8616 213.02 96.9864 212.977 97.0949 213.031C97.1057 213.031 98.1797 213.536 100.008 213.933C101.695 214.297 104.385 214.65 107.629 214.248C107.748 214.232 107.857 214.319 107.873 214.438C107.889 214.558 107.802 214.666 107.683 214.682C104.369 215.095 101.619 214.731 99.8992 214.357C99.7039 214.313 99.5141 214.27 99.3351 214.226L105.443 217.485C106.121 217.849 106.278 218.55 106.132 219.088C105.985 219.62 105.492 220.141 104.727 220.103L95.4405 219.685C94.936 219.663 94.6648 220.006 94.5835 220.31C94.5021 220.608 94.5618 221.043 95.0011 221.282L102.356 225.215C102.996 225.557 103.132 226.219 102.975 226.725C102.828 227.197 102.405 227.643 101.76 227.643V227.632Z" fill="white" />
                    <path d="M169.727 245.158H170.227V244.658V131.528V131.028H169.727H57.177H56.677V131.528V244.658V245.158H57.177H57.7545H58.2545V244.658V132.555H168.65V244.658V245.158H169.15H169.727Z" fill="#A0A0A0" stroke="#A0A0A0" />
                    <path d="M178.873 135.569H42.0205C38.9288 135.569 36.4214 133.564 36.4214 131.093V114.037C36.4214 111.566 38.9288 109.562 42.0205 109.562H178.873C181.965 109.562 184.472 111.566 184.472 114.037V131.093C184.472 133.564 181.965 135.569 178.873 135.569Z" fill="white" />
                    <path d="M178.873 135.829H42.02C38.7516 135.829 36.0947 133.705 36.0947 131.093V114.037C36.0947 111.425 38.7516 109.301 42.02 109.301H178.873C182.141 109.301 184.798 111.425 184.798 114.037V131.093C184.798 133.705 182.141 135.829 178.873 135.829ZM42.02 109.822C39.1117 109.822 36.7471 111.712 36.7471 114.037V131.093C36.7471 133.418 39.1117 135.308 42.02 135.308H178.873C181.781 135.308 184.146 133.418 184.146 131.093V114.037C184.146 111.712 181.781 109.822 178.873 109.822H42.02Z" fill="#6FAEDF" />
                    <path d="M179.206 132.499H41.6939C38.6021 132.499 36.0947 130.495 36.0947 128.024V110.968C36.0947 108.496 38.6021 106.492 41.6939 106.492H179.206C182.297 106.492 184.805 108.496 184.805 110.968V128.024C184.805 130.495 182.297 132.499 179.206 132.499Z" fill="#A0A0A0" />
                    <path d="M137.271 106.483H98.1301V127.216H137.271V106.483Z" fill="white" />
                    <path d="M132.689 122.794C133.139 122.794 133.503 122.429 133.503 121.979C133.503 121.529 133.139 121.164 132.689 121.164C132.24 121.164 131.876 121.529 131.876 121.979C131.876 122.429 132.24 122.794 132.689 122.794Z" fill="#828282" />
                    <path d="M132.689 123.011C132.12 123.011 131.658 122.55 131.658 121.979C131.658 121.409 132.12 120.947 132.689 120.947C133.259 120.947 133.72 121.409 133.72 121.979C133.72 122.55 133.259 123.011 132.689 123.011ZM132.689 121.382C132.364 121.382 132.092 121.648 132.092 121.979C132.092 122.311 132.358 122.577 132.689 122.577C133.02 122.577 133.286 122.311 133.286 121.979C133.286 121.648 133.02 121.382 132.689 121.382Z" fill="#828282" />
                    <path d="M129.684 122.794C130.134 122.794 130.498 122.429 130.498 121.979C130.498 121.529 130.134 121.164 129.684 121.164C129.235 121.164 128.871 121.529 128.871 121.979C128.871 122.429 129.235 122.794 129.684 122.794Z" fill="white" />
                    <path d="M129.684 123.011C129.114 123.011 128.653 122.55 128.653 121.979C128.653 121.409 129.114 120.947 129.684 120.947C130.253 120.947 130.714 121.409 130.714 121.979C130.714 122.55 130.253 123.011 129.684 123.011ZM129.684 121.382C129.358 121.382 129.087 121.648 129.087 121.979C129.087 122.311 129.353 122.577 129.684 122.577C130.015 122.577 130.28 122.311 130.28 121.979C130.28 121.648 130.015 121.382 129.684 121.382Z" fill="#828282" />
                    <path d="M126.685 122.794C127.134 122.794 127.498 122.429 127.498 121.979C127.498 121.529 127.134 121.164 126.685 121.164C126.235 121.164 125.871 121.529 125.871 121.979C125.871 122.429 126.235 122.794 126.685 122.794Z" fill="white" />
                    <path d="M126.684 123.011C126.115 123.011 125.654 122.55 125.654 121.979C125.654 121.409 126.115 120.947 126.684 120.947C127.254 120.947 127.715 121.409 127.715 121.979C127.715 122.55 127.254 123.011 126.684 123.011ZM126.684 121.382C126.359 121.382 126.087 121.648 126.087 121.979C126.087 122.311 126.353 122.577 126.684 122.577C127.015 122.577 127.281 122.311 127.281 121.979C127.281 121.648 127.015 121.382 126.684 121.382Z" fill="#828282" />
                    <path d="M132.689 119.755C133.139 119.755 133.503 119.391 133.503 118.941C133.503 118.491 133.139 118.126 132.689 118.126C132.24 118.126 131.876 118.491 131.876 118.941C131.876 119.391 132.24 119.755 132.689 119.755Z" fill="#828282" />
                    <path d="M132.689 119.973C132.119 119.973 131.658 119.512 131.658 118.941C131.658 118.371 132.119 117.909 132.689 117.909C133.258 117.909 133.719 118.371 133.719 118.941C133.719 119.512 133.258 119.973 132.689 119.973ZM132.689 118.344C132.363 118.344 132.092 118.61 132.092 118.941C132.092 119.273 132.358 119.539 132.689 119.539C133.02 119.539 133.285 119.273 133.285 118.941C133.285 118.61 133.02 118.344 132.689 118.344Z" fill="#828282" />
                    <path d="M129.684 119.755C130.133 119.755 130.498 119.391 130.498 118.941C130.498 118.491 130.133 118.126 129.684 118.126C129.235 118.126 128.87 118.491 128.87 118.941C128.87 119.391 129.235 119.755 129.684 119.755Z" fill="white" />
                    <path d="M129.683 119.973C129.114 119.973 128.653 119.512 128.653 118.941C128.653 118.371 129.114 117.909 129.683 117.909C130.253 117.909 130.714 118.371 130.714 118.941C130.714 119.512 130.253 119.973 129.683 119.973ZM129.683 118.344C129.358 118.344 129.087 118.61 129.087 118.941C129.087 119.273 129.353 119.539 129.683 119.539C130.014 119.539 130.28 119.273 130.28 118.941C130.28 118.61 130.014 118.344 129.683 118.344Z" fill="#828282" />
                    <path d="M126.685 119.755C127.134 119.755 127.498 119.391 127.498 118.941C127.498 118.491 127.134 118.126 126.685 118.126C126.235 118.126 125.871 118.491 125.871 118.941C125.871 119.391 126.235 119.755 126.685 119.755Z" fill="white" />
                    <path d="M126.684 119.973C126.115 119.973 125.654 119.512 125.654 118.941C125.654 118.371 126.115 117.909 126.684 117.909C127.254 117.909 127.715 118.371 127.715 118.941C127.715 119.512 127.254 119.973 126.684 119.973ZM126.684 118.344C126.359 118.344 126.087 118.61 126.087 118.941C126.087 119.273 126.353 119.539 126.684 119.539C127.015 119.539 127.281 119.273 127.281 118.941C127.281 118.61 127.015 118.344 126.684 118.344Z" fill="#828282" />
                    <path d="M132.689 113.699C133.139 113.699 133.503 113.334 133.503 112.884C133.503 112.434 133.139 112.069 132.689 112.069C132.24 112.069 131.876 112.434 131.876 112.884C131.876 113.334 132.24 113.699 132.689 113.699Z" fill="white" />
                    <path d="M132.689 113.918C132.12 113.918 131.658 113.456 131.658 112.886C131.658 112.315 132.12 111.854 132.689 111.854C133.259 111.854 133.72 112.315 133.72 112.886C133.72 113.456 133.259 113.918 132.689 113.918ZM132.689 112.288C132.364 112.288 132.092 112.554 132.092 112.886C132.092 113.217 132.358 113.483 132.689 113.483C133.02 113.483 133.286 113.217 133.286 112.886C133.286 112.554 133.02 112.288 132.689 112.288Z" fill="#828282" />
                    <path d="M129.684 113.699C130.134 113.699 130.498 113.334 130.498 112.884C130.498 112.434 130.134 112.069 129.684 112.069C129.235 112.069 128.871 112.434 128.871 112.884C128.871 113.334 129.235 113.699 129.684 113.699Z" fill="#828282" />
                    <path d="M129.684 113.918C129.114 113.918 128.653 113.456 128.653 112.886C128.653 112.315 129.114 111.854 129.684 111.854C130.253 111.854 130.715 112.315 130.715 112.886C130.715 113.456 130.253 113.918 129.684 113.918ZM129.684 112.288C129.358 112.288 129.087 112.554 129.087 112.886C129.087 113.217 129.353 113.483 129.684 113.483C130.015 113.483 130.281 113.217 130.281 112.886C130.281 112.554 130.015 112.288 129.684 112.288Z" fill="#828282" />
                    <path d="M126.685 113.699C127.134 113.699 127.499 113.334 127.499 112.884C127.499 112.434 127.134 112.069 126.685 112.069C126.236 112.069 125.871 112.434 125.871 112.884C125.871 113.334 126.236 113.699 126.685 113.699Z" fill="white" />
                    <path d="M126.684 113.918C126.115 113.918 125.654 113.456 125.654 112.886C125.654 112.315 126.115 111.854 126.684 111.854C127.254 111.854 127.715 112.315 127.715 112.886C127.715 113.456 127.254 113.918 126.684 113.918ZM126.684 112.288C126.359 112.288 126.088 112.554 126.088 112.886C126.088 113.217 126.354 113.483 126.684 113.483C127.015 113.483 127.281 113.217 127.281 112.886C127.281 112.554 127.015 112.288 126.684 112.288Z" fill="#828282" />
                    <path d="M132.689 116.725C133.138 116.725 133.502 116.36 133.502 115.91C133.502 115.46 133.138 115.096 132.689 115.096C132.239 115.096 131.875 115.46 131.875 115.91C131.875 116.36 132.239 116.725 132.689 116.725Z" fill="white" />
                    <path d="M132.688 116.944C132.119 116.944 131.658 116.482 131.658 115.912C131.658 115.342 132.119 114.88 132.688 114.88C133.258 114.88 133.719 115.342 133.719 115.912C133.719 116.482 133.258 116.944 132.688 116.944ZM132.688 115.314C132.363 115.314 132.092 115.581 132.092 115.912C132.092 116.243 132.357 116.509 132.688 116.509C133.019 116.509 133.285 116.243 133.285 115.912C133.285 115.581 133.019 115.314 132.688 115.314Z" fill="#828282" />
                    <path d="M129.683 116.725C130.133 116.725 130.497 116.36 130.497 115.91C130.497 115.46 130.133 115.096 129.683 115.096C129.234 115.096 128.87 115.46 128.87 115.91C128.87 116.36 129.234 116.725 129.683 116.725Z" fill="white" />
                    <path d="M129.683 116.944C129.113 116.944 128.652 116.482 128.652 115.912C128.652 115.342 129.113 114.88 129.683 114.88C130.252 114.88 130.713 115.342 130.713 115.912C130.713 116.482 130.252 116.944 129.683 116.944ZM129.683 115.314C129.357 115.314 129.086 115.581 129.086 115.912C129.086 116.243 129.352 116.509 129.683 116.509C130.014 116.509 130.279 116.243 130.279 115.912C130.279 115.581 130.014 115.314 129.683 115.314Z" fill="#828282" />
                    <path d="M126.684 116.725C127.133 116.725 127.497 116.36 127.497 115.91C127.497 115.46 127.133 115.096 126.684 115.096C126.234 115.096 125.87 115.46 125.87 115.91C125.87 116.36 126.234 116.725 126.684 116.725Z" fill="#828282" />
                    <path d="M126.683 116.944C126.114 116.944 125.653 116.482 125.653 115.912C125.653 115.342 126.114 114.88 126.683 114.88C127.253 114.88 127.714 115.342 127.714 115.912C127.714 116.482 127.253 116.944 126.683 116.944ZM126.683 115.314C126.358 115.314 126.087 115.581 126.087 115.912C126.087 116.243 126.352 116.509 126.683 116.509C127.014 116.509 127.28 116.243 127.28 115.912C127.28 115.581 127.014 115.314 126.683 115.314Z" fill="#828282" />
                    <path d="M108.984 116.721C109.433 116.721 109.797 116.357 109.797 115.907C109.797 115.457 109.433 115.092 108.984 115.092C108.534 115.092 108.17 115.457 108.17 115.907C108.17 116.357 108.534 116.721 108.984 116.721Z" fill="#828282" />
                    <path d="M108.984 116.94C108.414 116.94 107.953 116.478 107.953 115.908C107.953 115.338 108.414 114.876 108.984 114.876C109.554 114.876 110.015 115.338 110.015 115.908C110.015 116.478 109.554 116.94 108.984 116.94ZM108.984 115.311C108.659 115.311 108.387 115.577 108.387 115.908C108.387 116.239 108.653 116.505 108.984 116.505C109.315 116.505 109.581 116.239 109.581 115.908C109.581 115.577 109.315 115.311 108.984 115.311Z" fill="#828282" />
                    <path d="M105.979 116.721C106.429 116.721 106.793 116.357 106.793 115.907C106.793 115.457 106.429 115.092 105.979 115.092C105.53 115.092 105.166 115.457 105.166 115.907C105.166 116.357 105.53 116.721 105.979 116.721Z" fill="white" />
                    <path d="M105.979 116.94C105.41 116.94 104.949 116.478 104.949 115.908C104.949 115.338 105.41 114.876 105.979 114.876C106.549 114.876 107.01 115.338 107.01 115.908C107.01 116.478 106.549 116.94 105.979 116.94ZM105.979 115.311C105.654 115.311 105.383 115.577 105.383 115.908C105.383 116.239 105.648 116.505 105.979 116.505C106.31 116.505 106.576 116.239 106.576 115.908C106.576 115.577 106.31 115.311 105.979 115.311Z" fill="#828282" />
                    <path d="M102.975 110.659C103.424 110.659 103.788 110.294 103.788 109.844C103.788 109.394 103.424 109.029 102.975 109.029C102.525 109.029 102.161 109.394 102.161 109.844C102.161 110.294 102.525 110.659 102.975 110.659Z" fill="white" />
                    <path d="M102.974 110.878C102.404 110.878 101.943 110.416 101.943 109.846C101.943 109.275 102.404 108.813 102.974 108.813C103.544 108.813 104.005 109.275 104.005 109.846C104.005 110.416 103.544 110.878 102.974 110.878ZM102.974 109.248C102.649 109.248 102.377 109.514 102.377 109.846C102.377 110.177 102.643 110.443 102.974 110.443C103.305 110.443 103.571 110.177 103.571 109.846C103.571 109.514 103.305 109.248 102.974 109.248Z" fill="#828282" />
                    <path d="M108.985 113.695C109.434 113.695 109.798 113.33 109.798 112.88C109.798 112.43 109.434 112.065 108.985 112.065C108.535 112.065 108.171 112.43 108.171 112.88C108.171 113.33 108.535 113.695 108.985 113.695Z" fill="white" />
                    <path d="M108.984 113.914C108.415 113.914 107.954 113.452 107.954 112.882C107.954 112.311 108.415 111.85 108.984 111.85C109.554 111.85 110.015 112.311 110.015 112.882C110.015 113.452 109.554 113.914 108.984 113.914ZM108.984 112.284C108.659 112.284 108.388 112.55 108.388 112.882C108.388 113.213 108.653 113.479 108.984 113.479C109.315 113.479 109.581 113.213 109.581 112.882C109.581 112.55 109.315 112.284 108.984 112.284Z" fill="#828282" />
                    <path d="M105.98 113.695C106.429 113.695 106.794 113.33 106.794 112.88C106.794 112.43 106.429 112.065 105.98 112.065C105.531 112.065 105.166 112.43 105.166 112.88C105.166 113.33 105.531 113.695 105.98 113.695Z" fill="white" />
                    <path d="M105.98 113.914C105.41 113.914 104.949 113.452 104.949 112.882C104.949 112.311 105.41 111.85 105.98 111.85C106.549 111.85 107.01 112.311 107.01 112.882C107.01 113.452 106.549 113.914 105.98 113.914ZM105.98 112.284C105.654 112.284 105.383 112.55 105.383 112.882C105.383 113.213 105.649 113.479 105.98 113.479C106.31 113.479 106.576 113.213 106.576 112.882C106.576 112.55 106.31 112.284 105.98 112.284Z" fill="#828282" />
                    <path d="M102.975 113.695C103.424 113.695 103.788 113.33 103.788 112.88C103.788 112.43 103.424 112.065 102.975 112.065C102.525 112.065 102.161 112.43 102.161 112.88C102.161 113.33 102.525 113.695 102.975 113.695Z" fill="#828282" />
                    <path d="M102.974 113.914C102.404 113.914 101.943 113.452 101.943 112.882C101.943 112.311 102.404 111.85 102.974 111.85C103.544 111.85 104.005 112.311 104.005 112.882C104.005 113.452 103.544 113.914 102.974 113.914ZM102.974 112.284C102.649 112.284 102.377 112.55 102.377 112.882C102.377 113.213 102.643 113.479 102.974 113.479C103.305 113.479 103.571 113.213 103.571 112.882C103.571 112.55 103.305 112.284 102.974 112.284Z" fill="#828282" />
                    <path d="M120.359 119.755C120.808 119.755 121.172 119.391 121.172 118.941C121.172 118.491 120.808 118.126 120.359 118.126C119.909 118.126 119.545 118.491 119.545 118.941C119.545 119.391 119.909 119.755 120.359 119.755Z" fill="white" />
                    <path d="M120.358 119.973C119.788 119.973 119.327 119.512 119.327 118.941C119.327 118.371 119.788 117.909 120.358 117.909C120.928 117.909 121.389 118.371 121.389 118.941C121.389 119.512 120.928 119.973 120.358 119.973ZM120.358 118.344C120.033 118.344 119.761 118.61 119.761 118.941C119.761 119.273 120.027 119.539 120.358 119.539C120.689 119.539 120.955 119.273 120.955 118.941C120.955 118.61 120.689 118.344 120.358 118.344Z" fill="#828282" />
                    <path d="M117.348 119.755C117.797 119.755 118.161 119.391 118.161 118.941C118.161 118.491 117.797 118.126 117.348 118.126C116.898 118.126 116.534 118.491 116.534 118.941C116.534 119.391 116.898 119.755 117.348 119.755Z" fill="white" />
                    <path d="M117.348 119.973C116.778 119.973 116.317 119.512 116.317 118.941C116.317 118.371 116.778 117.909 117.348 117.909C117.917 117.909 118.378 118.371 118.378 118.941C118.378 119.512 117.917 119.973 117.348 119.973ZM117.348 118.344C117.022 118.344 116.751 118.61 116.751 118.941C116.751 119.273 117.017 119.539 117.348 119.539C117.678 119.539 117.944 119.273 117.944 118.941C117.944 118.61 117.678 118.344 117.348 118.344Z" fill="#828282" />
                    <path d="M114.349 119.755C114.798 119.755 115.162 119.391 115.162 118.941C115.162 118.491 114.798 118.126 114.349 118.126C113.899 118.126 113.535 118.491 113.535 118.941C113.535 119.391 113.899 119.755 114.349 119.755Z" fill="#828282" />
                    <path d="M114.348 119.973C113.778 119.973 113.317 119.512 113.317 118.941C113.317 118.371 113.778 117.909 114.348 117.909C114.918 117.909 115.379 118.371 115.379 118.941C115.379 119.512 114.918 119.973 114.348 119.973ZM114.348 118.344C114.023 118.344 113.751 118.61 113.751 118.941C113.751 119.273 114.017 119.539 114.348 119.539C114.679 119.539 114.945 119.273 114.945 118.941C114.945 118.61 114.679 118.344 114.348 118.344Z" fill="#828282" />
                    <path d="M120.359 113.699C120.809 113.699 121.173 113.334 121.173 112.884C121.173 112.434 120.809 112.069 120.359 112.069C119.91 112.069 119.546 112.434 119.546 112.884C119.546 113.334 119.91 113.699 120.359 113.699Z" fill="#828282" />
                    <path d="M120.359 113.918C119.789 113.918 119.328 113.456 119.328 112.886C119.328 112.315 119.789 111.854 120.359 111.854C120.928 111.854 121.389 112.315 121.389 112.886C121.389 113.456 120.928 113.918 120.359 113.918ZM120.359 112.288C120.033 112.288 119.762 112.554 119.762 112.886C119.762 113.217 120.028 113.483 120.359 113.483C120.69 113.483 120.955 113.217 120.955 112.886C120.955 112.554 120.69 112.288 120.359 112.288Z" fill="#828282" />
                    <path d="M117.348 113.699C117.797 113.699 118.161 113.334 118.161 112.884C118.161 112.434 117.797 112.069 117.348 112.069C116.898 112.069 116.534 112.434 116.534 112.884C116.534 113.334 116.898 113.699 117.348 113.699Z" fill="white" />
                    <path d="M117.347 113.918C116.778 113.918 116.317 113.456 116.317 112.886C116.317 112.315 116.778 111.854 117.347 111.854C117.917 111.854 118.378 112.315 118.378 112.886C118.378 113.456 117.917 113.918 117.347 113.918ZM117.347 112.288C117.022 112.288 116.751 112.554 116.751 112.886C116.751 113.217 117.016 113.483 117.347 113.483C117.678 113.483 117.944 113.217 117.944 112.886C117.944 112.554 117.678 112.288 117.347 112.288Z" fill="#828282" />
                    <path d="M114.348 113.699C114.798 113.699 115.162 113.334 115.162 112.884C115.162 112.434 114.798 112.069 114.348 112.069C113.899 112.069 113.535 112.434 113.535 112.884C113.535 113.334 113.899 113.699 114.348 113.699Z" fill="white" />
                    <path d="M114.348 113.918C113.778 113.918 113.317 113.456 113.317 112.886C113.317 112.315 113.778 111.854 114.348 111.854C114.917 111.854 115.378 112.315 115.378 112.886C115.378 113.456 114.917 113.918 114.348 113.918ZM114.348 112.288C114.022 112.288 113.751 112.554 113.751 112.886C113.751 113.217 114.017 113.483 114.348 113.483C114.679 113.483 114.944 113.217 114.944 112.886C114.944 112.554 114.679 112.288 114.348 112.288Z" fill="#828282" />
                    <path d="M120.358 116.725C120.807 116.725 121.172 116.36 121.172 115.91C121.172 115.46 120.807 115.096 120.358 115.096C119.909 115.096 119.544 115.46 119.544 115.91C119.544 116.36 119.909 116.725 120.358 116.725Z" fill="white" />
                    <path d="M120.358 116.944C119.788 116.944 119.327 116.482 119.327 115.912C119.327 115.342 119.788 114.88 120.358 114.88C120.928 114.88 121.389 115.342 121.389 115.912C121.389 116.482 120.928 116.944 120.358 116.944ZM120.358 115.314C120.033 115.314 119.761 115.581 119.761 115.912C119.761 116.243 120.027 116.509 120.358 116.509C120.689 116.509 120.955 116.243 120.955 115.912C120.955 115.581 120.689 115.314 120.358 115.314Z" fill="#828282" />
                    <path d="M117.348 116.725C117.797 116.725 118.161 116.36 118.161 115.91C118.161 115.46 117.797 115.096 117.348 115.096C116.898 115.096 116.534 115.46 116.534 115.91C116.534 116.36 116.898 116.725 117.348 116.725Z" fill="#828282" />
                    <path d="M117.348 116.944C116.778 116.944 116.317 116.482 116.317 115.912C116.317 115.342 116.778 114.88 117.348 114.88C117.918 114.88 118.379 115.342 118.379 115.912C118.379 116.482 117.918 116.944 117.348 116.944ZM117.348 115.314C117.023 115.314 116.751 115.581 116.751 115.912C116.751 116.243 117.017 116.509 117.348 116.509C117.679 116.509 117.945 116.243 117.945 115.912C117.945 115.581 117.679 115.314 117.348 115.314Z" fill="#828282" />
                    <path d="M114.348 116.725C114.797 116.725 115.161 116.36 115.161 115.91C115.161 115.46 114.797 115.096 114.348 115.096C113.898 115.096 113.534 115.46 113.534 115.91C113.534 116.36 113.898 116.725 114.348 116.725Z" fill="white" />
                    <path d="M114.348 116.944C113.779 116.944 113.318 116.482 113.318 115.912C113.318 115.342 113.779 114.88 114.348 114.88C114.918 114.88 115.379 115.342 115.379 115.912C115.379 116.482 114.918 116.944 114.348 116.944ZM114.348 115.314C114.023 115.314 113.752 115.581 113.752 115.912C113.752 116.243 114.018 116.509 114.348 116.509C114.679 116.509 114.945 116.243 114.945 115.912C114.945 115.581 114.679 115.314 114.348 115.314Z" fill="#828282" />
                    <path d="M163.655 111.198H123.288L123.32 106.483H99.0034L91.9248 85.723L84.3471 99.6934L105.735 117.966L95.5102 131.838L70.2495 115.625C65.6769 112.637 62.9214 107.548 62.9214 102.083C62.9214 99.3512 63.6103 96.6517 64.9446 94.2617L76.7369 72.9042C81.6892 63.9418 91.1003 58.3906 101.314 58.3906H139.181C145.478 58.3906 151.385 61.4541 155.014 66.6088L173.375 92.2031C179.087 100.128 173.418 111.192 163.655 111.192V111.198Z" fill="white" />
                    <path d="M95.5805 132.197L70.1138 115.853C65.4489 112.806 62.6609 107.662 62.6609 102.089C62.6609 99.3027 63.3715 96.5543 64.7167 94.1372L76.509 72.7851C81.4992 63.7467 91.0079 58.1357 101.319 58.1357H139.186C145.559 58.1357 151.559 61.2481 155.231 66.4626L173.592 92.0568C174.975 93.9797 175.778 96.2067 175.903 98.4988C176.022 100.655 175.561 102.828 174.557 104.783C173.554 106.744 172.062 108.395 170.245 109.563C168.309 110.802 166.03 111.459 163.66 111.459H123.027L123.06 106.744H98.8188L91.8758 86.3752L84.6887 99.6341L106.098 117.928L95.5805 132.197ZM101.314 58.6626C91.1923 58.6626 81.8627 64.1704 76.9646 73.0404L65.1723 94.3979C63.8705 96.739 63.187 99.3951 63.187 102.095C63.187 107.488 65.8829 112.469 70.3958 115.419L95.4504 131.497L105.382 118.015L84.0215 99.7644L91.9843 85.0771L99.1931 106.228H123.586L123.553 110.943H163.655C165.927 110.943 168.103 110.318 169.958 109.129C171.699 108.015 173.131 106.429 174.085 104.555C175.04 102.681 175.49 100.601 175.371 98.5369C175.246 96.3424 174.481 94.2078 173.158 92.3719L154.797 66.7776C151.217 61.6989 145.38 58.6735 139.175 58.6735H101.309L101.314 58.6626Z" fill="#828282" />
                    <path d="M103.809 127.058C100.815 131.088 97.1376 133.402 95.57 132.235C94.0024 131.067 95.1577 126.863 98.1411 122.838C101.135 118.807 104.466 116.673 106.033 117.835C107.601 118.997 106.793 123.039 103.809 127.058Z" fill="#828282" />
                    <path d="M116.703 75.7016L104.064 58.168H128.36L116.697 75.7016H116.703ZM105.095 58.6948L116.687 74.7782L127.383 58.6948H105.095Z" fill="#828282" />
                    <path d="M116.822 81.6221C115.976 81.6221 115.293 80.9323 115.293 80.0903C115.293 79.2484 115.982 78.5586 116.822 78.5586C117.663 78.5586 118.352 79.2484 118.352 80.0903C118.352 80.9323 117.663 81.6221 116.822 81.6221ZM116.822 79.08C116.269 79.08 115.819 79.5309 115.819 80.0849C115.819 80.6389 116.269 81.0898 116.822 81.0898C117.376 81.0898 117.826 80.6389 117.826 80.0849C117.826 79.5309 117.376 79.08 116.822 79.08Z" fill="#828282" />
                    <path d="M116.822 89.6836C115.976 89.6836 115.293 88.9938 115.293 88.1519C115.293 87.3099 115.982 86.6201 116.822 86.6201C117.663 86.6201 118.352 87.3099 118.352 88.1519C118.352 88.9938 117.663 89.6836 116.822 89.6836ZM116.822 87.1416C116.269 87.1416 115.819 87.5924 115.819 88.1464C115.819 88.7005 116.269 89.1513 116.822 89.1513C117.376 89.1513 117.826 88.7005 117.826 88.1464C117.826 87.5924 117.376 87.1416 116.822 87.1416Z" fill="#828282" />
                    <path d="M123.89 111.503H123.364V93.3718H147.464L141.942 84.8006L142.387 84.5127L148.429 93.8987H123.89V111.503Z" fill="#828282" />
                    <path d="M154.325 93.3701H147.946V93.897H154.325V93.3701Z" fill="#828282" />
                    <path d="M123.63 111.502C125.58 111.502 127.161 107.435 127.161 102.42C127.161 97.404 125.58 93.3379 123.63 93.3379C121.679 93.3379 120.098 97.404 120.098 102.42C120.098 107.435 121.679 111.502 123.63 111.502Z" fill="#828282" />
                    <path d="M127.128 111.443V110.916C128.902 110.916 130.399 107.027 130.399 102.421C130.399 97.8144 128.902 93.9253 127.128 93.9253V93.3984C129.254 93.3984 130.925 97.3636 130.925 102.421C130.925 107.477 129.26 111.443 127.128 111.443Z" fill="#828282" />
                    <path d="M92.6901 130.352C90.9869 129.086 92.0935 124.795 95.207 120.585C96.7095 118.565 98.25 117.001 99.6657 116.072C101.179 115.078 102.432 114.888 103.289 115.529L102.974 115.952C101.58 114.915 98.5591 116.952 95.6246 120.901C92.7823 124.741 91.5782 128.874 92.9993 129.933L92.6847 130.357L92.6901 130.352Z" fill="#828282" />
                    <path d="M104.666 51.1661C104.726 42.5297 110.459 40.1669 119.87 40.9165C130.019 41.7312 125.864 55.7125 131.494 56.1742C133.854 56.3643 136.55 53.6864 140.135 51.4812C143.091 49.4823 146.65 48.3199 150.49 48.3199C160.731 48.3199 169.041 56.6413 169.041 66.8964C169.041 71.6165 166.844 76.1792 163.948 79.4545C163.948 79.4545 153.007 64.0664 148.765 62.6216C140.423 59.7863 137.77 67.1245 131.977 68.2652C131.218 68.4118 130.404 68.4553 129.509 68.3521C121.27 67.407 122.843 53.8005 122.843 53.8005C120.711 52.926 120.245 50.7099 120.169 49.3682C120.212 48.7218 120.185 48.2927 120.185 48.2927C120.185 48.2927 120.131 48.7327 120.169 49.3682C119.995 51.8885 118.455 57.9286 109.873 55.7613V58.6945C109.873 76.4671 110.161 79.9162 106.565 84.3865C106.033 85.0546 102.464 90.4212 108.859 93.3217C117.099 97.0533 111.284 106.874 111.284 106.874H93.8503C93.8503 106.874 83.5009 105.032 82.6004 95.6247C81.3149 82.1812 98.1517 78.58 100.349 78.1672C96.0797 77.5099 91.9084 73.2949 91.9084 68.1891C91.9084 63.6591 95.3799 59.8351 98.9491 58.5695C98.9491 58.5695 98.9545 58.5659 98.9654 58.5587C99.0684 58.5261 99.1769 58.4881 99.28 58.4555C102.068 57.4941 104.124 55.3703 104.579 52.8065L104.661 51.1607L104.666 51.1661Z" fill="#252424" />
                    <path d="M120.217 51.2648C120.093 51.2648 120 51.1616 120 51.0475L120.071 46.5718C120.071 46.4468 120.169 46.3545 120.288 46.3545C120.413 46.3545 120.505 46.4577 120.505 46.5718L120.434 51.0475C120.424 51.1724 120.331 51.2648 120.217 51.2648Z" fill="#C1BDBD" />
                    <path d="M110.264 56.3647C110.232 56.3647 110.194 56.3538 110.161 56.3375C108.046 55.224 107.601 54.0888 107.574 54.0399C107.531 53.9258 107.59 53.8009 107.704 53.7574C107.818 53.714 107.943 53.7737 107.986 53.8824C107.997 53.8987 108.426 54.9253 110.367 55.9519C110.471 56.0062 110.514 56.142 110.465 56.2506C110.433 56.3212 110.351 56.3647 110.275 56.3647H110.264Z" fill="#C1BDBD" />
                    <path d="M122.035 119.968L122.024 121.559C122.024 121.7 121.991 121.842 121.937 121.961L118.108 130.511C117.538 131.744 116.307 132.537 114.951 132.537H106.755C104.71 132.537 102.724 131.847 101.113 130.581L100.826 130.353L95.9441 126.507C96.4865 125.334 97.2296 124.079 98.1463 122.846C99.8984 120.484 101.9 118.713 103.56 117.882H115.325C116.263 119.973 113.215 121.657 113.215 121.657H118.531L120.456 119.566C120.955 119.023 121.856 119.261 122.029 119.968H122.035Z" fill="#C1BDBD" />
                    <path d="M115.851 130.15H108.952C108.832 130.15 108.735 130.052 108.735 129.933C108.735 129.813 108.832 129.716 108.952 129.716H115.851C117.12 129.716 118.281 128.972 118.813 127.82C118.862 127.711 118.992 127.662 119.1 127.711C119.209 127.76 119.258 127.891 119.209 127.999C118.607 129.308 117.289 130.15 115.851 130.15Z" fill="#4B4B4B" />
                    <path d="M117.06 127.448H110.16C110.041 127.448 109.943 127.35 109.943 127.231C109.943 127.111 110.041 127.014 110.16 127.014H117.06C118.329 127.014 119.49 126.269 120.022 125.118C120.07 125.009 120.201 124.96 120.309 125.009C120.418 125.058 120.466 125.188 120.418 125.297C119.815 126.606 118.497 127.448 117.06 127.448Z" fill="#4B4B4B" />
                    <path d="M118.362 124.433H111.462C111.343 124.433 111.245 124.336 111.245 124.216C111.245 124.097 111.343 123.999 111.462 123.999H118.362C119.631 123.999 120.792 123.255 121.323 122.103C121.372 121.995 121.502 121.946 121.611 121.995C121.719 122.043 121.768 122.174 121.719 122.282C121.117 123.591 119.799 124.433 118.362 124.433Z" fill="#4B4B4B" />
                    <path d="M103.722 126.887C102.941 128.158 101.796 129.478 100.826 130.358L100.815 130.347L95.9329 126.502C96.4753 125.328 97.2184 124.074 98.1351 122.841C99.8871 120.478 101.889 118.707 103.548 117.876H106.076C106.304 119.864 105.973 123.21 103.717 126.887H103.722Z" fill="#4B4B4B" />
                    <path d="M112.108 99.8601C112.108 99.8601 116.361 88.4969 118.004 88.7251C119.642 88.9423 114.338 99.0182 114.338 99.0182L112.108 99.8601Z" fill="#828282" />
                    <path d="M127.16 102.425C127.16 107.286 125.674 111.246 123.808 111.49C123.748 111.501 123.683 111.501 123.624 111.501H106.304C105.778 111.501 105.36 111.083 105.36 110.556V105.021C105.36 103.451 106.32 102.05 107.785 101.501L115.037 98.7527C115.694 98.5083 116.393 98.3779 117.093 98.3779H126.786C127.025 99.6055 127.16 100.98 127.16 102.435V102.425Z" fill="#C1BDBD" />
                    <path d="M105.67 107.666C105.55 107.666 105.453 107.569 105.453 107.449C105.453 106.189 106.245 105.038 107.422 104.592L112.9 102.517C113.014 102.474 113.139 102.533 113.182 102.642C113.226 102.756 113.166 102.881 113.057 102.925L107.584 105C106.575 105.385 105.897 106.368 105.897 107.449C105.897 107.569 105.8 107.666 105.68 107.666H105.67Z" fill="#4B4B4B" />
                    <path d="M105.67 110.979C105.55 110.979 105.453 110.881 105.453 110.762C105.453 109.502 106.245 108.35 107.422 107.905L112.9 105.83C113.014 105.786 113.139 105.846 113.182 105.955C113.226 106.069 113.166 106.194 113.057 106.237L107.584 108.312C106.575 108.698 105.897 109.681 105.897 110.762C105.897 110.881 105.8 110.979 105.68 110.979H105.67Z" fill="#4B4B4B" />
                    <path d="M107.199 111.419C107.113 111.419 107.026 111.365 106.993 111.278C106.95 111.164 107.01 111.039 107.118 110.995L112.591 108.921C112.705 108.877 112.83 108.937 112.873 109.045C112.917 109.16 112.857 109.284 112.748 109.328L107.275 111.403C107.275 111.403 107.221 111.419 107.199 111.419Z" fill="#4B4B4B" />
                    <path d="M127.16 102.422C127.16 107.284 125.674 111.243 123.808 111.488C123.748 111.499 123.683 111.499 123.623 111.499C121.671 111.499 120.092 107.436 120.092 102.417C120.092 101.075 120.293 99.5544 120.505 98.3594H126.742C126.959 99.5598 127.16 101.086 127.16 102.422Z" fill="#4B4B4B" />
                    <path d="M125.712 55.7545C125.712 56.265 125.538 56.743 125.251 57.1233C124.952 57.5089 124.54 57.7914 124.041 57.9217L122.832 58.2368V59.5838C122.832 62.8429 120.201 65.4772 116.946 65.4772H115.785C112.542 65.4772 109.916 62.8483 109.9 59.611V55.7545C118.481 57.9272 120.022 51.8816 120.195 49.3613C120.266 50.6378 120.694 52.7073 122.582 53.6632L122.913 53.5763C124.329 53.2233 125.712 54.2933 125.712 55.7599V55.7545Z" fill="#C1BDBD" />
                    <path d="M121.231 57.7696C121.182 57.7696 121.128 57.7533 121.09 57.7153C120.141 56.9005 118.454 57.0581 118.438 57.0581C118.318 57.0689 118.21 56.982 118.199 56.8625C118.188 56.743 118.275 56.6344 118.394 56.6235C118.47 56.6181 120.282 56.4497 121.372 57.384C121.465 57.46 121.475 57.6012 121.394 57.6936C121.351 57.7425 121.291 57.7696 121.226 57.7696H121.231Z" fill="#391A2E" />
                    <path d="M115.736 62.6862C115.617 62.6862 115.519 62.5884 115.519 62.4689C115.503 59.8726 114.95 57.8248 114.18 57.4826C112.824 56.8851 111.603 57.5315 111.592 57.5369C111.484 57.5967 111.354 57.5532 111.294 57.45C111.234 57.3414 111.278 57.211 111.381 57.1513C111.44 57.1187 112.807 56.3963 114.353 57.0807C115.324 57.5098 115.937 59.5684 115.953 62.4635C115.953 62.583 115.856 62.6808 115.736 62.6862Z" fill="#391A2E" />
                    <path d="M113.165 60.0156C113.354 60.0156 113.507 59.7214 113.507 59.3584C113.507 58.9954 113.354 58.7012 113.165 58.7012C112.977 58.7012 112.824 58.9954 112.824 59.3584C112.824 59.7214 112.977 60.0156 113.165 60.0156Z" fill="#391A2E" />
                    <path d="M119.517 60.0156C119.706 60.0156 119.859 59.7214 119.859 59.3584C119.859 58.9954 119.706 58.7012 119.517 58.7012C119.329 58.7012 119.176 58.9954 119.176 59.3584C119.176 59.7214 119.329 60.0156 119.517 60.0156Z" fill="#391A2E" />
                    <path d="M123.162 56.8268C123.043 56.8268 122.945 56.729 122.945 56.6095C122.945 55.7513 123.553 54.9963 124.394 54.817C124.513 54.7899 124.627 54.8659 124.654 54.9854C124.681 55.1049 124.605 55.219 124.486 55.2461C123.846 55.3819 123.385 55.9523 123.385 56.6095C123.385 56.729 123.287 56.8268 123.168 56.8268H123.162Z" fill="#391A2E" />
                </svg>



            </CircleSvg>
        </>
    )
}

export default RemedialTestAnimation