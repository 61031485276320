import { Box, Stack } from '@mui/material'
import Grid from '@mui/system/Unstable_Grid';
import React, { useState } from 'react'
import QuestionType1Choice from './QuestionType1Choice';
import QuestionHeader from '../../QuestionHeader/QuestionHeader';
import ImagePreviewModal from '../../ImagePreviewModal/ImagePreviewModal';
import QuestionType2Choice from '../QuestionType2/QuestionType2Choice';
import { useRemedialContext } from '../../../hooks/useRemedial';

const QuestionType1 = ({ questionId, data, index, newSelection }) => {
    const [selected, setSelected] = useState();

    const { questions, setQuestions } = useRemedialContext();

    function handleClicked(id, questionData) {

        setSelected(id);
        if (index >= 0 && index < questions.length) {
            const newArray = [...questions];
            newArray[index] = { ...questions[index], newSelectedOption: id };
            setQuestions(newArray)
        }
    }


    return (
        <Stack gap={'32px'}>
            <QuestionHeader number={index + 1} question={data.name} />
            <Grid className='mcq-grid' container spacing={'20px'} padding={0}>
                {data.question_img && <QuestionImageBox img={data.question_img} />}
                {data.options.map((item, index) => (
                    item.option_img === "" ? <Grid xs={6}>
                        <QuestionType1Choice
                            questionData={item}
                            title={item.name}
                            id={item.id}
                            index={index}
                            selected={selected}
                            handleClicked={handleClicked}
                            selectedIndex={selected}
                            newSelection={newSelection}
                        />
                    </Grid> :
                        <Grid xs={3}>
                            <QuestionType2Choice
                                questionData={item}
                                title={item.name}
                                id={item.id}
                                index={index}
                                selected={selected}
                                handleClicked={handleClicked}
                                selectedIndex={selected}
                                newSelection={newSelection}
                                img={item.option_img}
                            />
                        </Grid>
                )
                )}
            </Grid>
        </Stack>
    )
}

export default QuestionType1


const QuestionImageBox = ({ img }) => {
    const [imageModal, setImageModal] = useState(false);
    return (
        <>

            <Box onClick={() => setImageModal(true)} sx={{
                width: '100%',
                height: '97px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: (theme) => `1px solid ${theme.palette.grey[100]}`,
                img: {
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%'
                },
                cursor: 'pointer'
            }}>

                <img src={img} alt="question-img" />

            </Box>

            {imageModal && <ImagePreviewModal img={img} show={imageModal} onImageClose={() => { setImageModal(false) }} />}

        </>
    )
}

