import React, { useEffect, useState } from 'react';
import {styled} from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Chip, Grid, Tooltip, Typography } from '@mui/material';
import Editor from "@monaco-editor/react";
import Equation5 from '../../components/Equation5';
import CustomChip from '../../components/common/CustomChip';
import { useHistory } from 'react-router-dom';
import colors2 from '../../pages/codingscreen/colors2';
import { darkModeColors } from '../../pages/codingscreen/colors2';
import { TickMark } from '../../assets/codingModule/TickMark';
import { CrossMark } from '../../assets/codingModule/CrossMark';
import { FaLock, FaUnlock } from "react-icons/fa";

const QuestionPanelBox = styled(Box)(({ theme }) => ({
    borderRight: `0.5px solid ${colors2.grey[300]}`,
    padding: '0px 5px',
    height: 'calc(100vh - 65px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
        width: '4px', 
    },
    '&::-webkit-scrollbar-thumb': {
        background: 'transparent', 
        borderRadius: '4px', 
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: colors2.grey[600], 
    },
    '&::-webkit-scrollbar-track': {
        background: 'transparent',
    },
    // '&:hover': {
    //     overflowY: 'scroll', 
    //     '&::-webkit-scrollbar': {
    //         width: '4px', 
    //     },
    //     '&::-webkit-scrollbar-thumb': {
    //         background: theme.palette.grey[600], 
    //         borderRadius: '4px', 
    //     },
    //     '&::-webkit-scrollbar-thumb:hover': {
    //         background: theme.palette.grey[800], 
    //     },
    // },
}));

const BackButton = styled(Button)(({ theme }) => ({
    minWidth: '20px',
    height: '30px',
    background: colors2.common.white,
    borderRadius: '5px',
}));

const QuestionInfoGrid = styled(Grid)(({ theme }) => ({
    margin: '20px 15px',
    marginLeft: '20px',
    minHeight: '400px',

    [theme.breakpoints.up('xl')]: {
        minHeight: '475px',
    },
}));

const CodingQuestionPanel = (props) => {
    const history = useHistory();
    const [questionData, setQuestionData] = useState(null);
    const [topics, setTopics] = useState(null);
    const [placementCompanies, setPlacementCompanies] = useState(null);
    const [activeView, setActiveView] = useState('Question');
    const [solutionLanguage, setSolutionLanguage] = useState('');
    const [solutionCode, setSolutionCode] = useState('');
    const [questionSwitched, setQuestionSwitched] = useState(false);
    const [paletteColors, setPaletteColors] = useState({});

    useEffect(() => {
        const handleKeyDown = (e) => {
          // Prevent Ctrl+C (or Cmd+C on Mac) for copying
          if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            e.preventDefault();
          }
        };
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
    
    useEffect(() => {
        setQuestionData(props.question);
        console.log("question : " + JSON.stringify(props.question));
        
        if (props.question?.placement_companies) {
            const comp = props.question?.placement_companies.split(',');
            setPlacementCompanies(comp);
        }
        
        if (props.question?.topics_tagged) {
            const topicsTagged = props.question?.topics_tagged.filter(tag => tag.id !== null);
            if (topicsTagged.length > 0) {
                setTopics(topicsTagged);
            }
        }
        setQuestionSwitched(true);
    }, [props.question]);

    useEffect(() => {
        if (questionSwitched) {
            const language = questionData.languages.find(language => language.language_id === props.compilerId);
            if (language.name !== 'C++14 [GCC]' && language.name !== 'Python 3.x') {
                setSolutionLanguage(language.name.toLowerCase());
            }
            else {
                if (language.name === 'C++14 [GCC]') {
                    setSolutionLanguage('cpp');
                }
                else {
                    setSolutionLanguage('python');
                }
            }
            setSolutionCode(language.solution);
        }
    }, [questionSwitched]);

    useEffect(() => {
        if (props.isDarkMode) {
            setPaletteColors(darkModeColors);
        }
        else {
            setPaletteColors(colors2);
        }
    }, [props.isDarkMode]);
    
    useEffect(() => {
        if (questionData) {
            if (props.questionSolutions.length === 0) {
                const language = questionData.languages.find(language => language.language_id === props.compilerId);
                if (language.name !== 'C++14 [GCC]' && language.name !== 'Python 3.x') {
                    setSolutionLanguage(language.name.toLowerCase());
                }
                else {
                    if (language.name === 'C++14 [GCC]') {
                        setSolutionLanguage('cpp');
                    }
                    else {
                        setSolutionLanguage('python');
                    }
                }
                setSolutionCode(language.solution);
            }
            else {
                const language = props.questionSolutions.find(language => language.language_id === props.compilerId);
                if (language.id === 9) {
                    setSolutionLanguage('c');
                }
                else if (language.id === 14) {
                    setSolutionLanguage('cpp');
                }
                else if (language.id === 33) {
                    setSolutionLanguage('java');
                }
                else if (language.id === 56) {
                    setSolutionLanguage('python');
                }
                setSolutionCode(language.solution);
            }
        }
    }, [props.compilerId, props.questionSolutions]);

    const handleBackButton = (track_id) => {
        history.push('/codingTrack', {"track_id": track_id});
    }

    const handleTabChange = (tab) => {
        if (tab === 'Question') {
            setActiveView('Question');
        }
        else if (tab === 'Solution') {
            setActiveView('Solution');
            handleSolutionChange();
        }
    }

    const handleSolutionChange = () => {
        if (questionData) {
            if (props.questionSolutions.length === 0) {
                const language = questionData.languages.find(language => language.language_id === props.compilerId);
                if (language.name !== 'C++14 [GCC]' && language.name !== 'Python 3.x') {
                    setSolutionLanguage(language.name.toLowerCase());
                }
                else {
                    if (language.name === 'C++14 [GCC]') {
                        setSolutionLanguage('cpp');
                    }
                    else {
                        setSolutionLanguage('python');
                    }
                }
                setSolutionCode(language.solution);
            }
            else {
                const language = props.questionSolutions.find(language => language.language_id === props.compilerId);
                if (language.id === 9) {
                    setSolutionLanguage('c');
                }
                else if (language.id === 14) {
                    setSolutionLanguage('cpp');
                }
                else if (language.id === 33) {
                    setSolutionLanguage('java');
                }
                else if (language.id === 56) {
                    setSolutionLanguage('python');
                }
                setSolutionCode(language.solution);
            }
        }
    }
    
    //For formatting the text into separate lines - input and output constraints, samples, formats, etc.
    const formatData = (text) => {
        const lines = text.split('\n');
        // console.log(lines);
        const fragmentStyle = {
            whiteSpace: 'pre', // Preserve spaces and line breaks
        };
        return (
            <div style={{ whiteSpace: 'pre-wrap' }}>
                {lines.map((line, index) => (
                    <div key={index}>{line}</div>
                ))}
            </div>
        );
    };

    const formatDataForSolution = () => {
        const language = questionData.languages.find(language => language.language_id === props.compilerId);
        if (language.solution !== "") {
        const lines = language.solution.split('\n');
        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {index+1}&nbsp; &nbsp; &nbsp; &nbsp;{line}
                <br />
            </React.Fragment>
        ));
        }
        else {
            return (
                <React.Fragment>Solution not available</React.Fragment>
            )
        }
    };


    // const handleQuestionsButton = (track_id) => {
    //     history.push('/codingTrack', {"track_id": track_id});
    // }

    // const goToPreviousQuestion = (id) => {
    //     console.log("Prev --> "+id);
    //     history.push('/codingTrackConsole', {"question_id": id})
    // }

    // const goToNextQuestion = (id) => {
    //     history.push('/codingTrackConsole', {"question_id": id})
    // }

    return (
        <>
            <QuestionPanelBox sx={{background: paletteColors.backgroundSecondary}}>
                {questionData && (
                <Grid container direction='column' sx={{p:0, marginTop: '20px',}}>
                    {/* <Grid item sx={{overflow: 'hidden'}}>
                        <Grid container justifyContent='flex-start' alignItems='center'>
                            <Grid item sx={{marginLeft: '15px', marginBottom: '5px', marginRight: '15px'}}>
                                <BackButton onClick={() => handleBackButton(questionData.coding_track_id)} sx={{background: !props.isDarkMode ? paletteColors.secondary : paletteColors.primary}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M7.64767 4.73889L2.79688 9.58968L7.64767 14.4405M16.3823 9.58968H2.93273" stroke={!props.isDarkMode ? paletteColors.primary : "white"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </BackButton>
                            </Grid>
                            <Grid item sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '15px', marginRight: '10px', flexGrow: 1, borderBottom: props.isDarkMode ? paletteColors.grey2 : (theme) => `1px solid ${theme.palette.grey[200]}`}}>
                                <Typography variant='body1' onClick={() => setActiveView('Question')} sx={{cursor: 'pointer', color: activeView === 'Question' ? paletteColors.textPrimary : (theme) => theme.palette.grey[500], borderBottom: activeView === 'Question' ? `2px solid ${paletteColors.primary}` : 'none'}}>Question</Typography>
                                <Typography variant='body1' onClick={() => setActiveView('Solution')} sx={{cursor: 'pointer', color: activeView === 'Solution' ? paletteColors.textPrimary : (theme) => theme.palette.grey[500], borderBottom: activeView === 'Solution' ? `2px solid ${paletteColors.primary}` : 'none'}}>Solution</Typography>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '65px',
                            gap: '10px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-end',
                            background: `linear-gradient(180deg, ${paletteColors.backgroundSecondary} 66%, rgba(255, 255, 255, 0.00) 100%)`,
                            paddingBottom: '20px',
                            height: '75px',
                            width: '24.5%',
                        }}
                    >
                        <Grid item sx={{overflow: 'hidden'}}>
                            <Grid container justifyContent='flex-start' alignItems='center'>
                                <Grid item sx={{marginLeft: '15px', marginRight: '15px'}}>
                                    <BackButton onClick={() => handleBackButton(questionData.coding_track_id)} sx={{background: !props.isDarkMode ? paletteColors.secondary : paletteColors.grey1, '&:hover' : {background: !props.isDarkMode ? paletteColors.secondary : paletteColors.grey1}}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M7.64767 4.73889L2.79688 9.58968L7.64767 14.4405M16.3823 9.58968H2.93273" stroke={!props.isDarkMode ? paletteColors.primary : "white"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </BackButton>
                                </Grid>
                                <Grid item sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '15px', marginRight: '10px', flexGrow: 1, borderBottom: props.isDarkMode ? paletteColors.grey2 : (theme) => `1px solid ${paletteColors.grey[200]}`}}>
                                    <Typography variant='body1' onClick={() => handleTabChange('Question')} sx={{cursor: 'pointer', color: activeView === 'Question' ? paletteColors.textPrimary : paletteColors.grey[500], borderBottom: activeView === 'Question' ? `2px solid ${paletteColors.primary}` : 'none'}}>Question</Typography>
                                    <Tooltip 
                                        title={'Click to check solution'} 
                                        placement='top' 
                                        arrow
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    bgcolor: paletteColors.grey[800], 
                                                    color: !props.isDarkMode ? paletteColors.backgroundSecondary : paletteColors.common.white
                                                }
                                            },
                                            arrow: {
                                                sx: {
                                                    color: paletteColors.grey[800]
                                                }
                                            }
                                        }}
                                    >
                                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', borderBottom: activeView === 'Solution' ? `2px solid ${paletteColors.primary}` : 'none'}}>
                                            <Typography variant='body1' onClick={() => handleTabChange('Solution')} sx={{cursor: 'pointer', color: activeView === 'Solution' ? paletteColors.textPrimary : paletteColors.grey[500]}}>
                                                Solution
                                            </Typography>
                                            {/* <Typography variant='body1' onClick={() => handleTabChange('Solution')} sx={{marginTop: '-1px', cursor: 'pointer', color: activeView === 'Solution' ? paletteColors.textPrimary : paletteColors.grey[500]}}>
                                                {props.enableSolutionButton ? null : <FaLock /> }
                                            </Typography> */}
                                        </Box>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    {activeView === 'Question' ? 
                    <QuestionInfoGrid item>
                        <Typography variant='h5' sx={{marginTop: '45px', marginBottom: '5px', textAlign: 'left', color: paletteColors.textPrimary}}>{questionData.title}</Typography>
                        <Equation5 fontSize={'12px'} math_type={1} equation={questionData.description} color={paletteColors.textPrimary}></Equation5>
                        
                        <Box sx={{margin: '10px 0px', textAlign: 'left', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', color: paletteColors.textPrimary}}>
                            {questionData.input_format ? 
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', margin: '20px 0px'}}>
                                    <Typography variant='body5' sx={{margin: '5px 0px'}}>Input Format:</Typography>
                                    <Typography variant='body1'>{formatData(questionData.input_format)}</Typography>
                                </Box>
                            : null}
                            {questionData.output_format ? 
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', margin: '20px 0px'}}>
                                <Typography variant='body5' sx={{margin: '5px 0px'}}>Output Format:</Typography>
                                <Typography variant='body1'>{formatData(questionData.output_format)}</Typography>
                            </Box>
                            : null}
                            {questionData.input_constraints ? 
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', margin: '20px 0px'}}>
                                    <Typography variant='body5' sx={{margin: '5px 0px'}}>Input Constraints:</Typography>
                                    <Typography variant='body1'>{formatData(questionData.input_constraints)}</Typography>
                                </Box>
                            : null}
                            {questionData.output_constraints ? 
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', margin: '20px 0px'}}>
                                    <Typography variant='body5' sx={{margin: '5px 0px'}}>Output Constraints:</Typography>
                                    <Typography variant='body1'>{formatData(questionData.output_constraints)}</Typography>
                                </Box>
                            : null}
                            {questionData.sample_input ? 
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', margin: '20px 0px'}}>
                                    <Typography variant='body5' sx={{margin: '5px 0px'}}>Sample Input:</Typography>
                                    <Typography variant='body1'>{formatData(questionData.sample_input)}</Typography>
                                </Box>
                            : null}
                            {questionData.sample_output ? 
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', margin: '20px 0px', marginBottom: placementCompanies || topics || questionData?.examples ? '20px' : '70px'}}>
                                <Typography variant='body5' sx={{margin: '5px 0px'}}>Sample Output:</Typography>
                                <Typography variant='body1'>{formatData(questionData.sample_output)}</Typography>
                            </Box>
                            : null}
                            {questionData.examples ? 
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', margin: '20px 0px', marginBottom: placementCompanies || topics ? '20px' : '70px'}}>
                                <Typography variant='body5' sx={{margin: '5px 0px'}}>Examples:</Typography>
                                <Typography variant='body1'>{formatData(questionData.examples)}</Typography>
                            </Box>
                            : null}
                            {topics && topics.length > 0 ?
                            <>
                                <Typography variant='body5' sx={{marginTop: '15px', marginBottom: '10px'}}>Tags</Typography>
                                <Grid container spacing={1} justifyContent="flex-start" sx={{marginBottom: placementCompanies ? '0px' : '70px'}}>
                                    {topics.map((tag, i) => {
                                        if (tag.name !== null) {
                                        return (
                                            <Grid item key={i}>
                                                {/* <Chip label={tag} variant='filled' color='primary' size='small' sx={{borderRadius: '4px', fontSize: '12px', marginTop: '5px', fontWeight: '600', }}/> */}
                                                <CustomChip label={tag.name} fontSize={'10px'} borderRadius={'4px'} bgColor={paletteColors.secondary} textColor={!props.isDarkMode ? paletteColors.primary : paletteColors.secondaryLighter}/>
                                            </Grid>
                                        )
                                        }
                                        return null;
                                    })}
                                </Grid>
                            </>
                            : null}
                            {placementCompanies ? 
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', marginTop: '20px'}}>
                                <Typography variant='body5' sx={{margin: '10px 0px'}}>Companies:</Typography>
                                <Grid container spacing={1.5} justifyContent="flex-start" sx={{marginBottom: '70px'}}>
                                    {placementCompanies.map((company, i) => {
                                        return (
                                            <Grid item key={i}>
                                                {/* <Chip label={tag} variant='filled' color='primary' size='small' sx={{borderRadius: '4px', fontSize: '12px', marginTop: '5px', fontWeight: '600', }}/> */}
                                                <CustomChip label={company} fontSize={'10px'} borderRadius={'25px'} bgColor={paletteColors.backgroundSecondary} textColor={paletteColors.companyTagPrimary} chipBorder={`1px solid ${paletteColors.companyTagPrimary}`} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Box>
                            : null}
                        </Box>
                    </QuestionInfoGrid>
                    : null }
                    
                    {activeView === 'Solution' ? 
                    // <Grid item sx={{textAlign: 'left', color: paletteColors.textPrimary, background: paletteColors.backgroundPrimary, border: `1px solid ${paletteColors.secondary}`, margin: '10px', borderRadius: '10px', padding: '20px 10px', overflow: 'scroll', whiteSpace: 'nowrap', width: '98%', height: 'auto', maxHeight: '80vh', '&::-webkit-scrollbar': {height: '3px', width: '2px'}, '&::-webkit-scrollbar-thumb': {background: (theme) => theme.palette.grey[300], borderRadius: '4px',}, '&::-webkit-scrollbar-thumb:hover': {background: (theme) => theme.palette.grey[700],}}}>
                    //     {formatDataForSolution()}
                    // </Grid>
                    <Grid item sx={{width: '90%', margin: '50px 5%'}}>
                        {questionData?.solution_unlocked === 1 || props.questionSolutions.length > 0 ? 
                            <>
                                <Box sx={{background: !props.isDarkMode ? paletteColors.common.white : paletteColors.backgroundPrimary, padding: '10px', borderRadius: '5px', margin: '0px auto', marginBottom: '20px'}}>
                                    <Typography variant='caption1' color={paletteColors.textPrimary}>Disclaimer: The solutions being displayed are for learning purposes only. Please do not share with others.</Typography>
                                </Box>
                                <Editor
                                    height="60vh"
                                    // width="100%"
                                    language={solutionLanguage}
                                    defaultValue={`Solution Not Available`}
                                    value={solutionCode}
                                    theme={props.isDarkMode ? "vs-dark":"va--light"}
                                    options={{ readOnly: true, contextmenu: false }}
                                    // readOnly={true}
                                    // onValidate={handleEditorValidation}
                                />
                            </>
                        :
                            <Box sx={{height: '70vh', position: 'relative'}}>
                                <Box sx={{width: '75%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '15px'}}>
                                    <Typography variant='body5' sx={{color: paletteColors.textPrimary}}>{props.solutionMessage === 'max_reached' ? `You have reached the maximum limit for solution unlocking in this level. Sorry, cannot unlock the solution!` : props.hasPerfectTestCasesScore ? `Submit your code correctly to unlock the solution automatically` : `Evaluate your code against the test cases ${props.attemptCountToEnableSolutionButton} more times in order to unlock the solution`}</Typography>
                                    {props.solutionMessage !== 'max_reached' ? 
                                    <Button 
                                        variant='filled' 
                                        size='small' 
                                        sx={{background: props.enableSolutionButton ? paletteColors.primary : (!props.isDarkMode ? (theme) => paletteColors.grey[500] : paletteColors.backgroundPrimary), padding: '5px 15px', '&:hover' : {background: props.enableSolutionButton ? paletteColors.primary : (!props.isDarkMode ? (theme) => paletteColors.grey[500] : paletteColors.backgroundPrimary), color: '#fff'}}} 
                                        onClick={props.handleUnlockSolution}
                                    >
                                        <Typography variant="caption2" color={paletteColors.common.white}>Unlock solution</Typography>
                                    </Button>
                                    : null}
                                </Box>
                            </Box>
                        }
                    </Grid>
                    : null }
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '0px',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'flex-end',
                            gap: '10px',
                            background: `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, ${paletteColors.backgroundSecondary} 56.77%)`,
                            paddingBottom: '20px',
                            height: '100px',
                            width: '24.5%',
                        }}
                    >
                        {questionData?.previous_question_id !== 0 ?
                            <Button 
                                variant='outlined' 
                                size='small' 
                                sx={{background: !props.isDarkMode ? paletteColors.common.white : paletteColors.grey1, border: `1px solid ${!props.isDarkMode ? paletteColors.primary : paletteColors.common.white}`, padding: '5px 15px', '&:hover': {background: !props.isDarkMode ? paletteColors.secondary : paletteColors.grey1, border: `1px solid ${!props.isDarkMode ? paletteColors.primary : paletteColors.common.white}`}}} 
                                onClick={() => props.handleMoveToAnotherQuestion(questionData.previous_question_id, 'previous')}
                            >
                                <Typography variant="caption2" color={!props.isDarkMode ? paletteColors.primary: paletteColors.textPrimary}>Previous question</Typography>
                            </Button>
                        : null}
                        {questionData?.next_question_id !== 0 ?
                        <Button 
                            variant='filled' 
                            size='small' 
                            sx={{background: props.canMoveToNextQuestion ? paletteColors.primary : (!props.isDarkMode ? (theme) => paletteColors.grey[500] : paletteColors.backgroundPrimary), padding: '5px 15px', '&:hover' : {background: props.canMoveToNextQuestion ? paletteColors.primary : (!props.isDarkMode ? (theme) => paletteColors.grey[500] : paletteColors.backgroundPrimary), color: '#fff'}}} 
                            onClick={() => props.handleMoveToAnotherQuestion(questionData.next_question_id, 'next')}
                        >
                            <Typography variant="caption2" color={paletteColors.common.white}>Next question</Typography>
                        </Button>
                        : null}
                    </Box>
                </Grid>)}
            </QuestionPanelBox>
        </>
    )
}

export default CodingQuestionPanel;