import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom";
import styled, { keyframes, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import Layout1 from "../../components/Layout/Layout1";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import "./dashboard.css";
import Analyticskeletonloader from "./analyticsloadingskeleton"
import ActivitywallSkeleton from "./activitywallskeleton";
import "./dashboard.css";
const SubjectDiv = styled.div`
 border-radius: 8px;
    padding: 3px 12px;
    margin-bottom: 8px;
    text-align: left;
    font-size: 12px;
`;
const Button = styled.div`
//   border-radius: 25px;
  margin: auto;
  text-align: center;
 
//   width: 130px;
//     padding: 6px;
//     color: black;
    margin-top: 8px;
    font-size: 13px;
    font-weight: 600;
    // box-shadow: 0px 0px 2px 0px #7e7777;
`;
function Dashboardskeletonloader(props) {

    return (
        <>
            <div style={{ overflow: "hidden", width: "100%", justifyContent: "space-between", margin: "0px" }} className="row">
                <Layout1 active_page="dashboard"></Layout1>
                <div className="maindiv" style={{ marginLeft: "214px", width: "61%", paddingRight: "10px", paddingLeft: "0px" }}>
                    <div
                        style={{
                            textAlign: "left",
                            fontSize: "32px",
                            fontWeight: "600",
                            color: "black",
                        }}
                    >
                        <div
                            style={{
                                color: "#black",
                                fontSize: "25px",
                                fontWeight: "400",
                            }}
                        >
                            <Skeleton width={340} height={40} />
                        </div>

                    </div>
                    <div className="row" style={{ marginLeft: "5px" }}>
                        <div style={{ border: '1px solid rgb(238, 238, 238)', marginTop: '25px', borderRadius: '8px', padding: '8px', width: '60%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div>
                                    <Skeleton circle={true} width={38} height={33} />

                                </div>
                                <div style={{ fontWeight: 600, fontSize: '15px', color: 'rgb(25, 22, 72)', paddingLeft: '10px' }}>
                                    <Skeleton width={190} height={20} />
                                </div>
                                <div style={{ margin: '0px 10px' }}>
                                    <Skeleton circle={true} width={20} height={20} />
                                </div>
                                <div style={{ margin: '0px 10px' }}>
                                    <Skeleton circle={true} width={20} height={20} />
                                </div>
                            </div>
                            <div style={{ margin: '-5px 0px 0px 5px', textAlign: 'left' }}>
                                <div className="row" style={{ marginTop: '10px' }}>
                                    <div className="col-12">
                                        <Skeleton width="90%" height={70} />
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ width: '100%', marginTop: "20px" }}>
                                <div className="col-6 my-1">
                                    <div className="">
                                        <div>
                                            <Skeleton width="70%" height={30} borderRadius={10} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 my-1">
                                    <div className="">
                                        <div>
                                            <Skeleton width="70%" height={30} borderRadius={10} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 my-1">
                                    <div className="">
                                        <div>
                                            <Skeleton width="70%" height={30} borderRadius={10} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 my-1">
                                    <div className="">
                                        <div>
                                            <Skeleton width="70%" height={30} borderRadius={10} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ borderRadius: '8px', color: 'rgb(25, 22, 72)', width: '39%', marginTop: "18px" }}>
                            <div style={{ fontWeight: 600, fontSize: '16px', textAlign: 'left', color: 'rgb(25, 22, 72)' }}>
                                Your Subjects
                            </div>
                            <SubjectDiv>
                                <Skeleton width="60%" height={12} />
                                <div className="">
                                    <Skeleton width="90%" height={16} borderRadius={10} />
                                </div>
                            </SubjectDiv>
                            <SubjectDiv>
                                <Skeleton width="60%" height={12} />
                                <div className="">
                                    <Skeleton width="90%" height={16} borderRadius={10} />
                                </div>
                            </SubjectDiv> <SubjectDiv>
                                <Skeleton width="60%" height={12} />
                                <div className="">
                                    <Skeleton width="90%" height={16} borderRadius={10} />
                                </div>
                            </SubjectDiv> <SubjectDiv>
                                <Skeleton width="60%" height={12} />
                                <div className="">
                                    <Skeleton width="90%" height={16} borderRadius={10} />
                                </div>
                            </SubjectDiv> <SubjectDiv>
                                <Skeleton width="60%" height={12} />
                                <div className="">
                                    <Skeleton width="90%" height={16} borderRadius={10} />
                                </div>
                            </SubjectDiv>
                        </div>
                    </div>
                    <div className="col-lg-12 row" style={{ padding: '0px', margin: '25px 0px 0px 0px' }}>
                        <div style={{ fontSize: '16px', textAlign: 'left', fontWeight: 600}}>
                           Activity wall
                        </div>
                        {/* <div className="mb-0 w-100">
                        <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1" style={{ background: "#ffdfd6" }}><i class="fas fa-search"></i></span>
              </div>
                            <div className="w-90">
                            <Skeleton width="100%" height={20} />
                            </div>
                           
                        </div> */}
                        <ActivitywallSkeleton />

                    </div>
                </div>
                <div
                    className="rightpane_loader col-lg-2"
                    style={{
                        background: "#f7f7f7",
                        width: "23%",
                        paddingLeft: "5px",
                    }}
                >
             <ul className="nav nav-tabs" style={{ marginTop: "15px" }}>
            <li className="nav-item nav-li-item" >
              <a className="nav-link active addbgcolor navstyling" href="#" style={{ color: "black" }}>Assessment</a>
            </li>
            <li className="nav-item" >
              <a className="nav-link navstyling" aria-current="page" href="#" style={{ color: "black" }}>Result</a>
            </li>
            {/* <li className="nav-item" >
              <a className="nav-link navstyling" aria-current="page" href="#" style={{ color: "black" }}>Bookmarks</a>
            </li> */}

          </ul>
          <div className="assessmenttabcontent">
    <div>
        <div style={{marginTop: '20px' }} />
        <div style={{marginTop: '20px' , marginBottom: '10px' }}>
            <div>
                <div style={{textAlign: 'left' , marginTop: '20px' }}>
                    <div style={{display: 'flex' , flexDirection: 'row' , justifyContent: 'space-between' }}>
                        <div>
                            <div style={{fontSize: '12px' , fontWeight: 600}}>
                            <Skeleton width={190} height={15} />
                                </div>
                            <div style={{fontSize: '12px' }}>
                            <Skeleton width={108} height={10} />
                                </div>
                        </div>
                        <div style={{textAlign: 'right' , margin: '10px 10px 0px 4px' }}>
                        <Skeleton width={30} height={30} circle={true} />
                        </div>
                    </div>
                </div>
                <div style={{textAlign: 'left' , marginTop: '20px' }}>
                    <div style={{display: 'flex' , flexDirection: 'row' , justifyContent: 'space-between' }}>
                        <div>
                            <div style={{fontSize: '12px' , fontWeight: 600}}>
                            <Skeleton width={190} height={15} />
                                </div>
                            <div style={{fontSize: '12px' }}>
                            <Skeleton width={108} height={10} />
                                </div>
                        </div>
                        <div style={{textAlign: 'right' , margin: '10px 10px 0px 4px' }}>
                        <Skeleton width={30} height={30} circle={true} />
                        </div>
                    </div>
                </div>
                <div style={{textAlign: 'left' , marginTop: '20px' }}>
                    <div style={{display: 'flex' , flexDirection: 'row' , justifyContent: 'space-between' }}>
                        <div>
                            <div style={{fontSize: '12px' , fontWeight: 600}}>
                            <Skeleton width={190} height={15}  />
                                </div>
                            <div style={{fontSize: '12px' }}>
                            <Skeleton width={108} height={10}  />
                                </div>
                        </div>
                        <div style={{textAlign: 'right' , margin: '10px 10px 0px 4px' }}>
                        <Skeleton width={30} height={30} circle={true} />
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
    <Analyticskeletonloader />
</div>
                </div>
            </div>
        </>
    );
}


export default Dashboardskeletonloader;
// export default Singleteamdetails;
