import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Grid, Typography, Button, Box, List, ListItem, Hidden } from '@mui/material';
import { MdLockOutline } from 'react-icons/md';
import colors2 from '../../pages/codingscreen/colors2';
import { BeginnerIcon } from '../../assets/codingModule/BeginnerIcon';
import { IntermediateIcon } from '../../assets/codingModule/IntermediateIcon';
import { CompetitiveIcon } from '../../assets/codingModule/CompetitveIcon';

// Styled components
const SingleCardContainer = styled(Grid)(({ theme, inactive }) => ({
  height: 333,
  width: 300,
  borderRadius: 12,
  marginBottom: 5,
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'space-around',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  position: 'relative',
  transition: 'none',
  zIndex: 10,

  [theme.breakpoints.up('xl')]: {
    height: 400,
    width: 340,
  },

  '&:hover': {
    boxShadow: '4px 4px 15px 0px rgba(0, 0, 0, 0.15)',
    transition: '0.2s ease-in-out',
  },

  '& > *': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  ...(inactive && {
    transform: 'scale(0.9)',
    opacity: 0.5,
    pointerEvents: 'none',
  }),
}));

const TagContainer = styled(Box)(({ theme }) => ({
  width: 200,
  height: 30,
  position: 'absolute',
  top: -30,
  background: colors2.secondary,
  borderRadius: '5px 5px 0px 0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const LockIconContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  svg: {
    fontSize: '10rem',
    color: colors2.grey,
    opacity: 0.5,
  },
}));


// Main SingleCard component
const LevelCard = ({ card }) => {

  const history = useHistory();
  const [locked, setLocked] = useState(false);

  useEffect(() => {
    console.log("Level - " + card.track);
    console.log("Locked - "+ card.locked);
    if (card.locked) {
      setLocked(true);
    }
  }, []);

  const handleTrackSelection = (track_id, track) => {
    history.push('/codingTrack', {'track_id': track_id, 'track' : track});
  }
  return (
    <SingleCardContainer inactive={locked} container sx={{border: `1px solid ${colors2.grey[100]}`, boxShadow: '4px 4px 15px 0px rgba(0, 0, 0, 0.05)'}}>
        {locked && (
            <LockIconContainer className='lock'>
            <MdLockOutline />
            </LockIconContainer>
        )}
        <TagContainer>
            <Typography variant='caption3' color={colors2.primaryLighter}>
              {card.tag}
            </Typography>
        </TagContainer>
        
        <Grid item sx={{marginTop: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '5px'}}>
            {card.id === 1 ? <BeginnerIcon /> : card.id === 2 ? <IntermediateIcon /> : <CompetitiveIcon />}
            <Typography variant='h3'>{card.track}</Typography>
        </Grid>
        <Grid item height="15%" sx={{margin: '0px 10px'}}>
            <Typography variant='caption2' color={colors2.common.black}>{card.description}</Typography>
        </Grid>
        {/* <List 
          sx={{
            width: '90%',
            height: '40%',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '0 1.5rem',
            margin: 0,
            padding: 0,
          }}
        >
            {card.stats_keys.map((stat, i) => (
              <ListItem
                key={i}
                sx={{
                  width: '20%',
                  height: '30%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant='body6' sx={{textAlign: 'center'}}>{card.stats_values[i]}</Typography>
                <Typography variant='caption1' sx={{fontSize: '8px', textAlign: 'center'}}>{stat}</Typography>
              </ListItem>
            ))}
        </List> */}
        <Grid item height="45%">
          <Grid container justifyContent="space-evenly" alignItems="center" sx={{margin: '0px 25px'}}>
            {card.stats_keys.map((stat, i) => (
                <Grid item xs={4} key={i}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            margin: '5px 7.5px',
                            height: 'auto',
                            // height: '65px',
                            padding: '5px 10px',
                            borderRadius: '5px',
                            background: colors2.grey[100],
                            overflowX: 'hidden',
                        }}
                    >
                        <Typography variant='body5' textAlign="center">{card.stats_values[i]}</Typography>
                        <Typography variant='caption2' textAlign="center">{stat}</Typography>
                    </Box>
                </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item height="15%" width="85%">
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleTrackSelection(card.id, card.track)}
              sx={{width: '100%', borderRadius: '10px', background: colors2.primary, '&:hover' : {background: colors2.primary}}}
            >
              <Typography variant="body4">Start coding</Typography>
            </Button>
        </Grid>
    </SingleCardContainer>
  );
};

export default LevelCard;