import React from "react";
import { Component } from "react";
import styled from "styled-components";
import Flex from "styled-flex-component";
import Modal3 from './Modal3';

const ModalWrapper = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.1);
`;

const ModalTextWrapper = styled(Flex)`
  width: 80%;
  padding: 2em;
  border: 1px solid #ccc;
  margin: auto;
  background: #fff;
  margin-top: 120px;
  height: auto;
  border-radius: 8px;

`;

class Modal extends Component {
    state = {
        open_content: false
    }
    openDataItem = (item)=> {
        this.setState({open_content: true, content_type: item.type, url: item.file_url});
    }
    closeDataItem = () => {
        this.setState({open_content: false, content_type: null, url: null})
    }
    render () {
        return (
            <ModalWrapper center open={this.props.open}>
                    <ModalTextWrapper onClick={()=>{}} column>
                        <div onClick={this.props.close} style={{textAlign: 'right', cursor: 'pointer', color: 'blue', textDecoration: 'underline'}}>close</div>
                        {this.props.topic && <div>
                            <div style={{textAlign: 'center', fontSize: '18px', fontWeight: 'bold'}}>
                                {this.props.topic.name}
                            </div>
                            <div style={{textAlign: 'left', margin: '20px 0'}}>
                                {this.props.data.map((item)=> {
                                    return <div style={{margin: '20px 0'}}>
                                            <div onClick={()=>this.openDataItem(item)} style={{margin: '10px', display: 'flex', flexDirection: 'row'}}>
                                                <div style={{margin: 'auto 15px'}}>
                                                {this.props.type == 'DOCS'  ? <img src='/images/doc_content.svg'></img> : 
                                                this.props.type == 'VIDEO' ? <img src='/images/video_content.svg'></img> : null}
                                                </div>
                                                <div>
                                                    {item.title}
                                                </div>
                                            </div>
                                            <div style={{borderBottom: '1px solid #eee'}}></div>
                                        </div>
                                })}
                            </div>
                        </div>}
                    </ModalTextWrapper>
                    <Modal3 open={this.state.open_content} 
                        type={this.state.content_type} 
                        url={this.state.url}
                        close={this.closeDataItem}></Modal3>

                </ModalWrapper>

        )
    }
}

export default Modal;