import React, { Component } from 'react';
import cl from './AssessmentSummary.module.css';
import upward from '../../assets/coding/up_arrow.svg'
import AnalysisMeter from '../../components/CodingComponents/components/AnalysisMeter/AnalysisMeter';
import { v4 } from 'uuid';
import axios from "axios";
import AceEditor from "react-ace";

import AppLayout from '../../components/Layout/Layout';

import Barchart from '../../components/CodingComponents/components/Barchart/Barchart'
import { withRouter } from 'react-router-dom';
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;

class AssessmentSummary extends Component {
    // let messages =https://stagingstudentpython.edwisely.com/ 

    constructor() {
        super()
        this.state = {
            name: '',
            test_type: '',
            start_time: '',
            subject_name: '',
            showAnalysis: true,
            problems: [],
            resData: {},
            test_cases: [true, false, true, false, true],
            run_times: [],
            student_marks: 0,
            total_marks: 0,
            loading:true
        }
    }


    componentDidMount() {
        this.getCodingResults()
    }

    handleAnalysisVisibility = () => {
        this.setState({ showAnalysis: !this.state.showAnalysis })
    }

    getCodingResults = () => {
        let test_id = this.props.location.state.test_id;
        let token;
        if (!test_id) {
            if (window.location.search) {
                let search = window.location.search.slice(1);
                let queryParams = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,
                  '":"') + '"}');
                test_id = queryParams.test_id;
                token = queryParams.token;
                localStorage.setItem('token', token);
            }
        }
        console.log(test_id)
        let headers = { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } }
       
        axios.get(`${BASE_API_URL}/codeEditor/v2/getCodingResults?test_id=${test_id}`, headers)
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    if (res.data.status === 200 ) {
                        let test_data = res.data.data;
                        let { name, test_type,
                            start_time,
                            timelimit,
                            subject_name, problems, total_marks, student_marks, run_times } = test_data ;
                        this.setState({
                            name,
                            test_type,
                            start_time,
                            subject_name, problems, total_marks, student_marks, run_times, timelimit: timelimit,
                            loading:false
                        })
                    } else if (res.data.status === 401 && res.data.message === 'Signature expired. Please log in again.') {
                        const refresh_token = localStorage.getItem('refresh_token');
                        headers = { headers: { "Authorization": `Bearer ${refresh_token}` } }
                        axios.get(`${BASE_API_URL}/auth/refresh`, headers)
                        .then((refres)=> {
                            localStorage.setItem('token', refres.data.token);
                            headers = { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } }
                            axios.get(`${BASE_API_URL}/codeEditor/v2/getCodingResults?test_id=${test_id}`, headers)
                            .then((res)=> {
                                if (res.status === 200) {
                                    if (res.data.status === 200) {
                                        let test_data = res.data.data;
                                        let { name, test_type,
                                            start_time,
                                            timelimit,
                                            subject_name, problems, total_marks, student_marks, run_times } = test_data ;
                                        this.setState({
                                            name,
                                            test_type,
                                            start_time,
                                            timelimit,
                                            subject_name, problems, total_marks, student_marks, run_times
                                        })
                                    }
                                }
                            })
                        })

                    } else {
                        this.setState({error_fetching: true})
                    }
                } else {
                    this.setState({error_fetching: true})
                }
                
            })
        
    }

    render() {

        if (this.state.error_fetching) {
            return <div>Please try again after some time </div>
        }
        if (this.state.loading) {
            return <div>Loading... </div>
        }

        let timelimit = Number(this.state.timelimit);
        console.log(timelimit)
        let hours = Math.floor(timelimit / (60 * 60));
        let minutes = (timelimit - hours * 60 * 60);
        if (minutes > 60) {
            minutes = Math.floor(minutes/60);
        }
        let timelimit_modified = minutes && hours ? hours + 'hrs ' + minutes + 'mins' : minutes && !hours ? minutes + 'mins' :hours + 'hrs';

        let tbody = this.state.problems.map(p => {
            let time_comp = {
                min: p.min_time,
                max: p.max_time,
                students: Number(p.student_summary_time)
            }
            let comp_comp = {
                min: p.min_compilations,
                max: p.max_compilations,
                students: p.student_summary_compilations
            }
            console.log(time_comp);
            console.log(comp_comp)
            return (
                <div className={cl.row} key={v4()}>
                    <div className={cl.item}>{p.title}</div>
                    <div className={`${cl.item} ${cl.dFlex} ${cl.test_cases}`}>
                        {p.test_cases.map(t => { return (<div key={v4()} className={t.status === 'accepted' ? cl.passed : cl.failed}></div>) })}
                    </div >
                    <div className={cl.item}><AnalysisMeter comp={time_comp} /></div>
                    <div className={cl.item}><AnalysisMeter comp={comp_comp} /></div>
                    <div className={`${cl.item} ${cl.numeric}`}> <div className={cl.chip}>{p.your_score ? p.your_score : 0} / {p.max_score}</div>  </div>
                </div>
            )
        })

        let detailedAnalysis = this.state.showAnalysis ? <div className={cl.detailedAnalysis}>
            <h3 className={cl.h3class}>Results Summary</h3>
            <div className={cl.card}>
                <div className={cl.tableclass}>
                    <div className={cl.theadclass}>
                        <div>Problem</div>
                        <div className={`${cl.dFlex} ${cl.test_cases}`}>Test Cases</div>
                        <div style={{ marginLeft: '30px' }}>Time Comparision</div>
                        <div>Compilations Comparision</div>
                        <div className={cl.numeric}>Score</div>
                    </div>
                    <div className={cl.tbodyclass}>
                        {tbody}
                    </div>
                </div>
            </div>

        </div> : null

        let assessmentAnalysis = this.state.problems.map((p, index) => {

            return (
                <div style={{ display: 'flex' }} key={v4()}>
                    <div style={{ width: '2%', margin: '20px', padding: '20px' }}><h2>{index + 1}. </h2></div>
                    <div className={cl.assessmentAnalysis} >
                        <h3 className={cl.h3class}>{p.title}</h3>
                        {p.body.includes("</") ? 
                            <p className={cl.pclass} dangerouslySetInnerHTML={{__html:p.body}}></p>
                        :   <p className={cl.pclass}>{p.body}</p>
                        }
                        <div className={cl.codeDisp}>
                            <AceEditor
                                placeholder="Placeholder Text"
                                name="blah2"
                                width='100%'
                                onLoad={this.onLoad}
                                onChange={this.onChange}
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={p.source_code}
                                readOnly={true}
                            />

                        </div>
                    </div>
                    <div className={cl.ExecutionGraph}>
                        <div style={{ height: '300px' }}>
                            <Barchart chartData={p.coding_time} />
                        </div>

                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', borderTop: '1px solid #ddd' }}>
                            <div style={{ flexGrow: 1, marginTop: '20px' }}>
                                <div style={{ fontSize: '12px', textAlign: 'center' }}>Memory</div>
                                <div style={{ fontSize: '24px', textAlign: 'center' }}>{p.memory_consumption} KB</div>
                                <div style={{ fontSize: '12px', textAlign: 'center' }}>Best: {p.best_memory} KB</div>
                            </div>
                            
                            <div style={{ flexGrow: 1, marginTop: '20px' }}>
                                <div style={{ fontSize: '12px', textAlign: 'center' }}>Runtime</div>
                                <div style={{ fontSize: '24px', textAlign: 'center' }}>{p.run_time}s</div>
                                <div style={{ fontSize: '12px', textAlign: 'center' }}>Best {p.best_run_time}s</div>
                            </div>
                        </div>


                    </div>
                </div>
            )
        })

        return (<div>
            {/* <AppLayout></AppLayout> */}
            <div className={cl.AssessmentSummary}>
                <div className={cl.card}>
                    <div>
                        <h2 className = {cl.h2class} style={{ marginBottom: 5, textTransform: 'capitalize' }}>{this.state.name}</h2>
                        <h4 className={cl.h4class}><span style={{ marginLeft: 0 }}>{this.state.test_type}</span>  | <span> {this.state.subject_name}</span>  |  <span>{this.state.start_time}</span>  |  <span>Time: {timelimit_modified}</span> | <span>Marks: {this.state.student_marks} / {this.state.total_marks} </span></h4>
                    </div>

                    <div style={{ borderBottom: '1px solid rgb(0,0,0,.3)', margin: '30px 0' }}></div>
                    <div>
                        {detailedAnalysis}
                        <div style={{ margin: "20px 0px", display: 'flex', alignItems: 'center' }}>
                            {/* <button onClick={this.handleAnalysisVisibility} className={cl.button}>{this.state.showAnalysis ? 'Hide Analysis' : 'Show Analysis'} <img alt="icon" className={this.state.showAnalysis ? cl.upward : cl.downward} src={upward}></img></button> */}
                            {/* <div style={{ borderBottom: '1px solid #4e54c8', margin: '30px 0', flexGrow: 1 }}></div> */}
                        </div>
                        <h3 className={cl.h3class}>Your Answers</h3>
                        {assessmentAnalysis}
                    </div>
                </div>

            </div>
            </div>
        )
    }

}


export default withRouter(AssessmentSummary);