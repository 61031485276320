import React, { Component } from "react";
import classes from "./login.module.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserDetailsAC,
  getLoginOtpAC,
  OPENENTERROLLNUMBERAGAIN,
  getUserPasswordDetailsAC,
  updatePasswordAC,
} from "../../store/actions/loginActions";
import { Redirect } from "react-router-dom";
import LoginRollNumber from "./LoginRollNumber";
import LoginOtp from "./LoginOtp";
import LoginPassword from "./LoginPassword";
import ResetPassword from "./resetPassword";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpSent: false,
      roll_number: null,
      otp: null,
      resend: false,
      otpList: ["", "", "", ""],
    };
  }

  componentDidMount = () => {
    // localStorage.clear();
    if (localStorage.getItem("token") && !this.props.forgot_password) {
      this.props.history.push("/home");
    }
  };

  onRollNumberChange = (event) => {
    this.setState({ roll_number: event.target.value });
  };

  onSubmitMobileNo = () => {
    console.log(this.state.roll_number);
    this.props.getPasswordDetails(this.state.roll_number, false);
  };

  onRollNumberEnter = (event) => {
    if (event.key == "Enter") {
      this.onSubmitMobileNo();
    }
  };

  otpEnteredHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (isNaN(event.target.value)) {
      return;
    }
    let otp = this.state.otpList;
    otp[Number(event.target.name) - 1] = event.target.value;

    if (String(event.target.name) !== "4" && event.target.value) {
      event.target.nextElementSibling.focus();
      this.setState({ otpList: otp });
    } else if (!event.target.value && String(event.target.name) !== "1") {
      event.target.previousElementSibling.focus();
      this.setState({ otpList: otp });
    } else {
      this.setState({ otpList: otp });
    }
  };

  onEnterOtpClickHandler = (event) => {
    event.target.nextSibling.children[0].focus();
  };
  onSubmitOTP = () => {
    let otp = "";
    // console.log(this.state.otpList);
    this.state.otpList.forEach((el) => (otp += el));
    // for (var el in this.state.otpList) {
    //     otp = otp + el;
    //     console.log((otp));
    // }
    // console.log(otp);
    this.props.getUserDetails(otp);
  };

  openEnterRollnumber = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.openEnterRollnumberAgain();
  };

  onOtpEnter = (event) => {
    if (event.key == "Enter") {
      if (
        !(
          this.state.login_otp_message ||
          this.state.otpList[0] == "" ||
          this.state.otpList[1] == "" ||
          this.state.otpList[2] == "" ||
          this.state.otpList[3] == ""
        )
      ) {
        this.onSubmitOTP();
      }
    }
  };

  render() {
    if (this.props.user_logged_in_successfully && !this.props.forgot_password) {
      return <Redirect to="/home" exact />;
    }
    console.log(this.props);

    return (
      <>
        <div
          className={classes.dFlex}
          style={{
            backgroundImage: "url('/images/login/login-bg.png')",
            backgroundSize: "cover",
            height: "100%",
          }}
        >
          <div className={classes.imagesSection}>
            <img
              src="/images/login/girl.png"
              alt=""
              className={classes.mainImg}
            />
            <img
              src="/images/login/mask1.png"
              alt=""
              className={classes.mask1}
            />
            <img
              src="/images/login/mask2.png"
              alt=""
              className={classes.mask2}
            />
          </div>
          {this.props.got_user_password_details &&
          this.props.login_through_password &&
          this.props.is_user ? (
            <LoginPassword></LoginPassword>
          ) : this.props.got_user_password_details &&
            (this.props.login_through_otp || this.props.forgot_password) &&
            !this.props.got_user_details &&
            this.props.is_user &&
            !this.props.password_disabled ? (
            <LoginOtp></LoginOtp>
          ) : this.props.got_user_password_details &&
            this.props.user_logged_in_successfully &&
            (this.props.login_through_otp || this.props.forgot_password) &&
            this.props.is_user &&
            !this.props.password_disabled ? (
            <ResetPassword></ResetPassword>
          ) : (
            // : this.props.got_user_password_details && !this.props.has_password && !this.props.is_user ? <div>{this.props.user_password_details_message}</div>
            <LoginRollNumber />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roll_number: state.login.roll_number,
    login_otp_message: state.login.login_otp_message,
    user_logged_in_successfully: state.login.user_logged_in_successfully,
    error_in_roll_number: state.login.error_in_roll_number,
    error_getting_details: state.login.error_getting_details,
    error_message: state.login.error_message,
    resent_otp: state.login.resent_otp,
    has_password: state.login.has_password,
    is_user: state.login.is_user,
    password_updated_successfully: state.login.password_updated_successfully,
    error_updating_password: state.login.error_updating_password,
    user_password_details_message: state.login.user_password_details_message,
    user_password_status: state.login.user_password_status,
    got_user_password_details: state.login.got_user_password_details,
    got_user_details: state.login.got_user_details,
    error_loading_user_password_details:
      state.login.error_loading_user_password_details,
    login_through_otp: state.login.login_through_otp,
    login_through_password: state.login.login_through_password,
    forgot_password: state.login.forgot_password,
    password_disabled: state.login.password_disabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetails: (otp) => dispatch(getUserDetailsAC(otp)),
    openEnterRollnumberAgain: () =>
      dispatch({ type: OPENENTERROLLNUMBERAGAIN }),
    getPasswordDetails: (roll_number) =>
      dispatch(getUserPasswordDetailsAC(roll_number)),
    updatePassword: (roll_number, password) =>
      dispatch(updatePasswordAC(roll_number)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
