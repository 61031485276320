import React, { useReducer, useState } from "react";
import { connect } from "react-redux";
import Flex from "styled-flex-component";
import Equation from "./Equation";
import styled from "styled-components";
import moment from "moment";
import {
  getTestAC,
  submitTestAC,
  TESTSUBMITLOAD,
  submitQuestionsFeedbackAC,
  submitQuestionAC,
} from "../store/actions/questionnaireActions";
const OptionFlex = styled.div`
  margin: 10px;
  padding: 10px;
  margin-left: 0;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f6e4e4;
  }
`;
function Questionpanel(props) {
  let questions = props.questions;
  let qid = props.qid;
  let answers = props.answers;
  const option_numbers = {
    0: "a",
    1: "b",
    2: "c",
    3: "d",
    4: "e",
    5: "f",
    6: "g",
    7: "h",
    8: "i",
    9: "j",
  };

  console.log("new compoennt");
  return (
    <>
      <Flex
        row
        style={{ height: "360px", overflowY: "scroll", marginRight: "21px" }}
      >
        <Flex row style={{ width: "75%" }}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              marginRight: "8px",
              width: "2%",
            }}
          >
            {qid + 1}.
          </div>
          <Flex column style={{ marginLeft: "10px", width: "98%" }}>
            <Flex column style={{ marginBottom: "20px", textAlign: "left" }}>
              <Equation
                blooms={questions[qid].blooms_level}
                math_type={questions[qid].math_type}
                bold
                equation={questions[qid].name}
              ></Equation>
              {questions[qid].question_img &&
              questions[qid].question_img != " " ? (
                <div
                  style={{
                    width: "324px",
                    height: "216px",
                    margin: "10px",
                  }}
                >
                  <img
                    width="324px"
                    height="216px"
                    src={questions[qid].question_img}
                    alt="question"
                  ></img>
                </div>
              ) : null}
            </Flex>

            <div>
              {questions[qid].test_questions_options.map(
                ({ id, name, media, option_img, is_answer }, i) => {
                  let option_selected = answers[qid].question_option_id;
                  let is_selected = true ? option_selected === id : false;
                  let is_correct_selected = is_selected && is_answer;
                  let is_incorrect = is_selected && !is_answer;
                  let is_correct = option_selected && is_answer;
                  // console.log(option_selected);
                  // console.log(is_selected);
                  // console.log(is_correct_selected);
                  // console.log(is_incorrect);
                  return (
                    <OptionFlex
                      onClick={() => props.onOptionSelected(qid, id)}
                      style={{
                        color: is_selected ? "#4E54C8" : null,
                        fontWeight: is_selected ? "600" : "400",
                        alignItems: "center",
                        backgroundColor: is_selected ? "#DDDDDD" : null,
                      }}
                    >
                      <Flex row key={i}>
                        <div
                          onClick={() => props.onOptionSelected(qid, id)}
                          style={{
                            margin: "auto",
                            marginRight: "8px",
                            width: "2%",
                            marginLeft: "10px",
                          }}
                        >
                          {option_numbers[i]}
                          {")"}
                        </div>
                        <div>
                          {option_img ? (
                            <div
                              style={{
                                margin: "10px",
                                marginTop: "0px",
                                width: "150px",
                                height: "100px",
                              }}
                            >
                              <img
                                onClick={() => props.onOptionSelected(qid, id)}
                                width="150px"
                                height="100px"
                                src={option_img}
                                alt=""
                              />
                            </div>
                          ) : null}
                        </div>
                        <div
                          style={{ margin: "auto", marginLeft: "10px" }}
                          onClick={() => props.onOptionSelected(qid, id)}
                        >
                          <Equation
                            bold={is_selected}
                            answered={is_selected}
                            math_type={questions[qid].math_type}
                            equation={name}
                          ></Equation>
                        </div>
                      </Flex>
                    </OptionFlex>
                  );
                }
              )}
            </div>
          </Flex>
        </Flex>

        <Flex column style={{ width: "18%" }}>
          {/* <div onClick={this.onHintToggle} >
                                        <img className='hint' src='/images/Tips.svg'></img>
                                    </div> */}
          {questions[qid].section_name ? (
            <div style={{ fontWeight: "bold", textAlign: "end" }}>
              {questions[qid].section_name}
            </div>
          ) : null}
          {/* {this.state.showHint ? <HintDataDiv>
                                        <div style={{marginTop: '20px', color: 'white', padding: '10px'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                                    </HintDataDiv> : null} */}
        </Flex>
        <div style={{ width: "7%" }}>
          <div style={{ width: "100%", cursor: "pointer" }}>
            <img
              style={{ marginLeft: "0" }}
              onClick={() =>
                props.reportItem("objective_questions", questions[qid].id)
              }
              alt="report"
              src="/images/report.svg"
            ></img>
          </div>
        </div>
      </Flex>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    // questions: state.ques.questions,
    // answers: state.ques.answers,
    // test_id: state.ques.practice_id,
    // qid: state.ques.qid,
    // timelimit: state.ques.timelimit,
    // name: state.ques.name,
    // attempted: state.ques.attempted,
    // close_test: state.ques.close_test,
    // resume_test: state.ques.resume_test,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOptionSelected: (qid, option_id) =>
      dispatch({ type: "ON_OPTION_SELECTED", qid: qid, option_id: option_id }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Questionpanel);
