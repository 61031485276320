import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
//import correct from 'public/../correct-icon.png';
import React from "react";
import ReactDOM from "react-dom";
import "./joinchallange.css";
import styled, { keyframes, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import ReactCountdownClock from "react-countdown-clock";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdOutlinePending } from "react-icons/md";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as joinchallenge from "../../store/actions/joinchallengeactions";
class Challengeresults extends Component {
  state = {
    showdetails: null,
    showResults: false,
    leaderboarddata: false,
  };

  viewdetails = (index) => {
    //   this.setState({count:this.state.count+1})
    this.setState({ showdetails: null });
    setTimeout(() => {
      this.setState({ showdetails: index });
    }, 100);
  };
  componentDidMount = async () => {
    console.log("api call");
    this.props.getleaderboarddata(localStorage.getItem("challengeid"));
    this.props.getchallengedetails(localStorage.getItem("challengeid"));
    setTimeout(() => {
      this.setState({ leaderboarddata: this.props.leaderboarddata });
    }, 1000);
  };
  goBack = () => {
    window.history.back();
  };
  // const avatars=["https://www.w3schools.com/howto/img_avatar2.png","https://www.w3schools.com/w3images/avatar2.png","https://www.w3schools.com/w3images/avatar6.png","https://www.w3schools.com/w3images/avatar5.png","https://www.w3schools.com/howto/img_avatar.png","https://www.w3schools.com/howto/img_avatar.png"]
  render() {
    // console.log(this.props.leaderboarddata);
    const colors = [
      "rgb(225 54 58 / 85%)",
      "rgb(72 103 246 / 83%)",
      "rgb(102 175 110 / 86%)",
      "rgb(221 165 57 / 86%)",
      "rgb(235 133 187)",
      "rgb(225 54 58 / 85%)",
      "rgb(72 103 246 / 83%)",
      "rgb(102 175 110 / 86%)",
      "rgb(221 165 57 / 86%)",
      "rgb(235 133 187)",
    ];
    const avatars = [
      "https://cdn-icons-png.flaticon.com/512/476/476863.png",
      "https://cdn-icons-png.flaticon.com/512/1165/1165674.png",
      "https://cdn-icons-png.flaticon.com/512/1256/1256650.png",
      "https://cdn-icons-png.flaticon.com/512/3280/3280979.png",
      "https://cdn-icons-png.flaticon.com/512/7829/7829198.png",
      "https://cdn-icons-png.flaticon.com/512/3791/3791146.png",
      "https://cdn-icons-png.flaticon.com/512/3369/3369157.png",
      "https://cdn-icons-png.flaticon.com/512/2967/2967475.png",
      "https://cdn-icons-png.flaticon.com/512/476/476863.png",
      "https://cdn-icons-png.flaticon.com/512/1165/1165674.png",
      "https://cdn-icons-png.flaticon.com/512/1256/1256650.png",
      "https://cdn-icons-png.flaticon.com/512/3280/3280979.png",
      "https://cdn-icons-png.flaticon.com/512/7829/7829198.png",
      "https://cdn-icons-png.flaticon.com/512/3791/3791146.png",
      "https://cdn-icons-png.flaticon.com/512/3369/3369157.png",
      "https://cdn-icons-png.flaticon.com/512/2967/2967475.png",
    ];
    const lineargradient = [
      "rgb(242 111 116 / 71%)",
      "#a7b5f6",
      "#9ce1a4",
      "#fbd793",
      "#fdcee8",
      "rgb(242 111 116 / 71%)",
      "#a7b5f6",
      "#9ce1a4",
      "#fbd793",
      "#fdcee8",
    ];
    return (
      <>
        {this.props.leaderboarddata ? (
           <div style={{ background: "linear-gradient(45deg,#FDF3C2, #FFC2D5)" }}>
          <Maindiv>
            <div style={{ float: "left" }}>
              <button className="btn btn-primary" onClick={this.goBack}>
                Back
              </button>
            </div>
            <h2 style={{ color: "red" }}>
              {this.props.challengedetails.title}
            </h2>
            {/* <h3 style={{fontWeight:"700"}}>01:00</h3>*/}
            <h3>Leaderboard</h3>
            <div
              className="row"
              style={{ padding: "18px", justifyContent: "center" }}
            >
              <div
                className="col-lg-10 col-md-12"
                style={{ height: "464px", overflow: "auto" }}
              >
                {this.props.leaderboarddata.map((students, index) => {
                  let height = 0;
                  let fontsize = 0;
                  let questionsfontsize = 0;
                  let animationprop = "";
                  let rankfontsize,
                    avatarheight,
                    avatarwidth,
                    student_score_font_size;
                  let total_time_taken = 0;
                  if (index == 0) {
                    height = 65;
                    fontsize = 20;
                    rankfontsize = 40;
                    animationprop = true;
                    questionsfontsize = 10;
                    avatarwidth = 60;
                    avatarheight = 63;
                    student_score_font_size = 35;
                  } else {
                    height = 55;
                    fontsize = 15;
                    rankfontsize = 30;
                    questionsfontsize = 9;
                    animationprop = false;
                    avatarwidth = 54;
                    avatarheight = 47;
                    student_score_font_size = 30;
                  }
                  let top;
                  if (index == 1) {
                    top = 325;
                  } else if (index == 6) {
                    top = -325;
                  } else if (index == 2) {
                    top = 130;
                  } else if (index == 4) {
                    top = -65;
                  } else if (index == 3) {
                    top = -65;
                  } else {
                    top = "";
                  }
                  return (
                    <>
                      <Rotate
                        className="col-lg-12 leaderboardrow"
                        primary={index + 1}
                        top={null}
                        style={{
                          marginBottom: "10px",
                          background:
                            "linear-gradient(45deg, #ebc8af, #f1b2c5)",
                          height: `${height}px`,
                          color: "black",
                        }}
                      >
                        <div
                          className="col-lg-1 col-md-1 col-sm-2"
                          style={{ fontSize: `${rankfontsize}px` }}
                        >
                          {index + 1}
                        </div>
                        <div
                          className="col-lg-8 col-md-6 col-sm-6"
                          style={{ fontSize: `${fontsize}px` }}
                        >
                          <div>
                            {this.props.challengedetails.participation_type
                              ? students.team_name
                              : students.student_name}
                          </div>
                          <div
                            className="questiondetails"
                            style={{ fontSize: `${questionsfontsize}px` }}
                          >
                            {students.questions.map(
                              (questiondetails, index) => {
                                let questionstatus = "";
                                if (questiondetails.status == "correct") {
                                  questionstatus =
                                    process.env.PUBLIC_URL +
                                    "/correct-icon.png";
                                } else if (questiondetails.status == "wrong") {
                                } else {
                                  questionstatus = "";
                                }
                                total_time_taken =
                                  total_time_taken + questiondetails.time_taken;
                                return (
                                  <span>
                                    <div style={{ color: "black" }}>
                                      Q{index + 1}
                                    </div>
                                    <div>
                                      {questiondetails.is_correct === 0 ? (
                                        <AiOutlineCloseCircle
                                          title={`Time taken - ${questiondetails.time_taken} seconds`}
                                          style={{
                                            color: "red",
                                            fontSize: "20px",
                                          }}
                                        />
                                      ) : questiondetails.is_correct == 1 ? (
                                        <AiOutlineCheckCircle
                                          title={`Time taken - ${questiondetails.time_taken} seconds`}
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                          }}
                                        />
                                      ) : (
                                        <MdOutlinePending
                                          style={{
                                            color: "grey",
                                            fontSize: "20px",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </span>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <div
                          className="col-lg-1 col-md-3 col-sm-2"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span
                            style={{ fontSize: `${student_score_font_size}px` }}
                          >
                            {students.total_correct_answers}/
                            {students.questions.length}
                          </span>
                          {/* <button
                        className="btn btn-success"
                        style={{
                          padding: "-11px",
                          height: "21px",
                          width: "50%",
                          fontSize: "13px",
                          padding: "0px",
                        }}
                        onClick={() => this.viewdetails(index)}
                      >
                        View details
                      </button> */}
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2">
                          <span style={{ fontSize: "14px" }}>
                            Time taken:- {`${students.total_time_taken} Sec`}
                          </span>
                          {/* <img
                            src={avatars[index]}
                            style={{
                              width: `${avatarwidth}px`,
                              height: `${avatarheight}px`,
                              borderRadius: "10px",
                              marginTop: "0px",
                            }} 
                          />*/}
                        </div>
                      </Rotate>
                    </>
                  );
                })}
              </div>
            </div>
          </Maindiv>
          </div>
        ) : <div>Loading...</div>}{" "}
      
      </>
    );
  }
}
const Maindiv = styled.div`
  height: 615px;
  border-radius: 50px;
  margin: 0px 30px 0px 30px;
  padding: 12px;
  @media (max-width: 768px) {
    height: 310px;
    overflow: scroll;
  }
  @media (max-width: 1024px) {
    height: 593px;
  }
`;
const Button = styled.button`
  background: transparent;
  border-radius: 3px;
  border: 2px solid palevioletred;
  color: palevioletred;
  margin: 0.5em 1em;
  padding: 0.25em 1em;
`;
const movediv = (top) => keyframes`
  from {
   
   top:0px;
  }

  to {
  
   top:${top}px;
   margin:5px;
`;
const movediv1 = keyframes`
  from {
   
   top:0px;
  }

  to {
  
   top:-200px;
  }
`;
const Rotate = styled.div`
  ${(props) =>
    props.top &&
    css`
      animation-delay: 5s;
      animation-fill-mode: forwards;
      animation: ${(props) => movediv(props.top)} 5s forwards;
    `}
`;
function mapStateToProps(state) {
  return {
    challengedetails: state.joinchallenge.challangedetails,
    leaderboarddata: state.joinchallenge.leaderboarddetails,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getchallengedetails: (challengeid) =>
      dispatch(joinchallenge.getchallengedetails(challengeid)),
    getleaderboarddata: (challengeid) =>
      dispatch(joinchallenge.getLeaderboardDetails(challengeid)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Challengeresults)
);
//export default Leaderboard2;
