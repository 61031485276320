import React from 'react';

const NoMatch = () => (
  <div>
    <h1>The page doesn't exist</h1>
    <p>Please contact us if you think that is a bug.</p>
  </div>
)

export default NoMatch;
