import { Stack, Typography } from '@mui/material'
import React from 'react'
import Equation from '../../../../utils'

const QuestionHeader = ({ number , question }) => {
    return (
        <Stack direction={'row'} gap={'10px'}>
            <Typography component={'p'} sx={{
                fontFamily: 'Poppins-Medium',
                fontSize: '14px',
                color: 'grey.900'
            }}>Q{number}
            </Typography>
            <Typography component={'p'} sx={{
                fontFamily: 'Poppins-Medium',
                fontSize: '14px',
                color: 'grey.900'
            }}>
            <Equation equation={question}  math_type={question?.includes('$') ? 1 : 0} />
            </Typography>
        </Stack>
    )
}

export default QuestionHeader