import axios from '../../interceptor';

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;


export function setGlobalToken() {
    try {
        let token = localStorage.getItem('token');
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    } catch (error) {
      console.log(error);
    }
};

export const GETASSESSMENTS = 'GET_ASSESSMENTS';
export const GETPRACTICEASSESSMENTS = 'GET_PRACTICE_ASSESSMENTS';
export const GETPRACTICESERIESRESULTS = 'GET_PRACTICE_SERIES_RESULTS';

export const getAssessmentsA = (res,subject_id, student_name, err) => {
    return {
        type: GETASSESSMENTS,
        response: res,
        student_name: student_name,
        subject_id: subject_id,
        error: err
    }
}


export const getAssessmentsAC = (subject_id) => {
    console.log('in action creator')
    setGlobalToken();
    let user = JSON.parse(localStorage.getItem('user'));
    let student_name = user.name;
    console.log(subject_id);
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/v2/getSubjectAssessments?subject_id=${subject_id}`)
        .then((res)=> {
            if (res.status == 200) {
                if (res.data.status == 200) {                    
                    dispatch(getAssessmentsA(res.data,subject_id,student_name, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/v2/getSubjectAssessments?subject_id=${subject_id}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    dispatch(getAssessmentsA(res.data,subject_id, student_name, false));
                                } else {
                                    dispatch(getAssessmentsA(null, subject_id,student_name, true));
                                }
                            } else {
                                dispatch(getAssessmentsA(null,subject_id, student_name,true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(getAssessmentsA(null, subject_id,student_name ,true))
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    })
                } else {
                    dispatch(getAssessmentsA(null, subject_id, student_name, true))
                }
            } else {
                dispatch(getAssessmentsA(null, subject_id, student_name, true))
            }
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(getAssessmentsA(null,subject_id, student_name,  true))
        });
    };
};

export const getPracticeAssessmentsA = (res,subject_id, student_name, err) => {
    return {
        type: GETPRACTICEASSESSMENTS,
        response: res,
        student_name: student_name,
        subject_id: subject_id,
        error: err
    }
}

export const getPracticeAssessmentsAC = (subject_id) => {
    console.log('in action creator')
    setGlobalToken();
    let user = JSON.parse(localStorage.getItem('user'));
    let student_name = user.name;
    // console.log(subject_id);
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/codeEditor/getPracticeSeries?subject_id=${subject_id}`)
        // http.get(`${BASE_API_URL}/codeEditor/getPracticeSeries?subject_id=${"140"}`)
        .then((res)=> {
            if (res.status == 200) {
                if (res.data.status == 200) {                    
                    dispatch(getPracticeAssessmentsA(res.data,subject_id,student_name, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/codeEditor/getPracticeSeries?subject_id=${subject_id}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    dispatch(getPracticeAssessmentsA(res.data,subject_id, student_name, false));
                                } else {
                                    dispatch(getPracticeAssessmentsA(null, subject_id,student_name, true));
                                }
                            } else {
                                dispatch(getPracticeAssessmentsA(null,subject_id, student_name,true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(getPracticeAssessmentsA(null, subject_id,student_name ,true))
                        })
                    })
                    .catch((err)=> {
                        dispatch(getPracticeAssessmentsA(null, subject_id,student_name ,true))
                        console.log(err);
                    })
                } else {
                    dispatch(getPracticeAssessmentsA(null, subject_id, student_name, true))
                }
            } else {
                dispatch(getPracticeAssessmentsA(null, subject_id, student_name, true))
            }
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(getPracticeAssessmentsA(null,subject_id, student_name,  true))
        });
    };
}

export const getPracticeSerisResultsA = (res, err) => {
    return {
        type: GETPRACTICESERIESRESULTS,
        response: res,
        error: err
    }
}

export const getPracticeSeriesResultsAC = (test_id) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/codeEditor/getPracticeCodingStudentResults?test_id=${test_id}`)
        // http.get(`${BASE_API_URL}/codeEditor/getPracticeSeries?subject_id=${"140"}`)
        .then((res)=> {
            if (res.status == 200) {
                if (res.data.status == 200) {                    
                    dispatch(getPracticeSerisResultsA(res.data,false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/codeEditor/getPracticeCodingStudentResults?test_id=${test_id}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    dispatch(getPracticeSerisResultsA(res.data,false));
                                } else {
                                    dispatch(getPracticeSerisResultsA(null, true));
                                }
                            } else {
                                dispatch(getPracticeSerisResultsA(null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(getPracticeSerisResultsA(null, true))
                        })
                    })
                    .catch((err)=> {
                        dispatch(getPracticeSerisResultsA(null, true))
                        console.log(err);
                    })
                } else {
                    dispatch(getPracticeSerisResultsA(null, true))
                }
            } else {
                dispatch(getPracticeSerisResultsA(null, true))
            }
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(getPracticeSerisResultsA(null, true))
        });
    };
};