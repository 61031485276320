import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import { itemUpdateRemoveAddAC, REMOVECERTIFICATE, UPDATECERTIFICATE} from '../../store/actions/profileActions';
import styled from 'styled-components';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 25%);

    @media(max-width: 1180px) {
        grid-template-columns: repeat(2, 50%);
    }

`;

const SaveButton = styled.button`
    border-radius: 32px;
    background:  ${({ active }) => (active ? "linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%)" : "grey")};
    margin: auto;
    text-align: center;
    width: 98px;
    padding: 8px;
    color: white;
    margin-right: 0;
    border: none;
`

const FlexBoxForDate = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px 10px;
    align-items: flex-end;
    @media (max-width: 1024px) {
        diplay: flex;
        flex-direction: column;
    }
`;

class UpdateCertificate extends Component {

    state = {
        openContent : false,
        contentUrl: '',
        contentType: null,
        title: this.props.certificate[this.props.certificate_index].title,
        company: this.props.certificate[this.props.certificate_index].company,
        location: this.props.certificate[this.props.certificate_index].location,
        start_year: this.props.certificate[this.props.certificate_index].from_year,
        end_year: this.props.certificate[this.props.certificate_index].to_year,
        description: this.props.certificate[this.props.certificate_index].description,
        files: this.props.certificate[this.props.certificate_index].files,
        new_files: [],
        start_time: moment(this.props.certificate[this.props.certificate_index].from_year,'MMM yyyy').toDate(),
        end_time: moment(this.props.certificate[this.props.certificate_index].to_year, 'MMM yyyy').toDate(),
    }

    onChangeHandler = (event, type)=> {
        event.stopPropagation();
        event.preventDefault();
        
        this.setState({[type] : event.target.value});
    }

    onFileChangeHandler = (event) => {
        this.setState({new_files: event.target.files})
    }

    onEndDateChange = (date) => {
        try {
            let dateFormat = moment(date).format('MMM yyyy').toString();
            
            if (this.state.start_time) {
                if (date < this.state.start_time) {
                    this.setState({ endTimeError: true, endTimeErrorMessage: 'end time cannot be lesser than start year' })
                } else {
                    this.setState({ end_time: date, end_year: dateFormat, endTimeError: false, endTimeErrorMessage: '' });

                }
            } else {
                if (dateFormat) {
                    this.setState({  endTimeError: true, endTimeErrorMessage: 'please select start year first' });
                }
            }
        } catch (error) {
            console.log(error);
        }
        this.forceUpdate()
    }

    onStartDateChange = (date) => {
        try {
            let dateFormat = moment(date).format('MMM yyyy').toString();
            
            if (this.state.end_time) {
                if (date > this.state.end_time) {
                    this.setState({ startTimeError: true, startTimeErrorMessage: 'start year cannot be greater than end year' })
                } else {
                    this.setState({ start_time: date, start_year: dateFormat, startTimeError: false, startTimeErrorMessage: '' });

                }
            } else {
                this.setState({ start_time: date, start_year: dateFormat, startTimeError: false, startTimeErrorMessage: '' });
            }
            
        } catch (error) {
            console.log(error);
        }
        this.forceUpdate()
    }

    closeCertificateEditor = () => {
        this.setState({
            title: null,
            company: null,
            location: null,
            start_year: null,
            end_year: null,
            description: null,
            files: null,
            new_files: []
        });
        this.props.clearStatus();
        this.props.close();
    }

    updateCertificateHandler = () => {
        const formdata = new FormData();
        formdata.append('title', this.state.title);
        formdata.append('certificate_id',this.props.certificate[this.props.certificate_index].id);
        formdata.append('company', this.state.company);
        formdata.append('location', this.state.location);
        formdata.append('description', this.state.description);
        formdata.append('from_date', this.state.start_year);
        formdata.append('to', this.state.end_year);
        let old_attachment_id = this.state.files.map((file)=> {
            return file.id;
        })
        formdata.append('old_attachment_id', JSON.stringify(old_attachment_id));
        formdata.append('new_attachments', '');
        if (this.state.new_files && this.state.new_files.length != 0) {
            Array.from(this.state.new_files).map((file)=> {
              console.log(file)
              formdata.append('new_attachments', file);
            })
        }

        let item = {};
        item.type = UPDATECERTIFICATE;
        item.index = this.props.certificate_index;
        this.props.updateCertificate(item, formdata);
    }

    deleteCertificate = () => {
        const formdata = new FormData();
        formdata.append('certificate_id',this.props.certificate[this.props.certificate_index].id);

        let item = {};
        item.type = REMOVECERTIFICATE;
        item.id = this.props.certificate[this.props.certificate_index].id;
        this.props.updateCertificate(item, formdata);
    }

    removeFileFromList = (event, index) => {
        let files = this.state.files;
        files.splice(index, 1);
        this.setState({files: files});
    }
    

    render () {
        console.log(this.state);
        console.log(this.props);
        return (
            <div style={{}}>
                <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '20px'}}>Edit Your Certificate</div>

                <div style={{border: '1px solid #eee', borderRadius: '4px',  textAlign: 'left', padding: '10px', margin: '20px 0'}}>
                    <div style={{width: '10%', margin: 'auto', marginRight: '20px'}} onClick={this.deleteCertificate}>
                        <img src='/images/profile/delete_icon.svg'></img>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Title : </div>
                        <div style={{width: '80%'}}>
                            <input onChange={(event)=>this.onChangeHandler(event, 'title')} value={this.state.title} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Company : </div>
                        <div style={{width: '80%'}}>
                            <input onChange={(event)=>this.onChangeHandler(event, 'company')} value={this.state.company} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Location : </div>
                        <div style={{width: '80%'}}>
                            <input onChange={(event)=>this.onChangeHandler(event, 'location')} value={this.state.location} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input>
                        </div>
                    </div>
                    <FlexBoxForDate>
                        <div style={{marginRight: '20px', width: '50%', display: 'flex', flexDirection: 'row',}}>
                            <div style={{width: '40%', marginRight: '20px',textAlign: 'right'}}>Start Year : </div>
                            <div style={{width: '60%'}}>
                                <DatePicker
                                    selected={this.state.start_time}
                                    onChange={this.onStartDateChange}
                                    showMonthYearPicker
                                    dateFormat="MMM yyyy"
                                />
                                {/* <input onChange={(event)=>this.onChangeHandler(event, 'start_year')} value={this.state.start_year} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input> */}
                            </div>
                        </div>
                        <div style={{width: '50%',display: 'flex', flexDirection: 'row',}}>
                            <div style={{width: '40%', marginRight: '20px',textAlign: 'right'}}>End Year : </div>
                            <div style={{width: '60%'}}>
                                <DatePicker
                                    selected={this.state.end_time}
                                    onChange={this.onEndDateChange}
                                    showMonthYearPicker
                                    dateFormat="MMM yyyy"
                                />
                                {/* <input onChange={(event)=>this.onChangeHandler(event, 'end_year')} value={this.state.end_year} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input> */}
                            </div>
                        </div>
                    </FlexBoxForDate>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Description : </div>
                        <div style={{margin: 'auto 0 auto auto', width: '80%'}}>
                            <textarea onChange={(event)=>this.onChangeHandler(event, 'description')} value={this.state.description} style={{width: '80%', height: '70px',border: '1px solid #bbb',borderRadius: '4px'}} required></textarea>
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '25%', marginRight: '20px',textAlign: 'right'}}>Upload PDFs, Images : </div>
                        <div style={{margin: 'auto', width: '80%'}}>
                            <input multiple type='file' onChange={(event)=>this.onFileChangeHandler(event)} style={{width: '100%', height: '22px'}} required></input>
                        </div>
                    </div>
                    {this.state.files && this.state.files.length != 0 ? <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 33%)', margin: '20px 10px'}}>
                        {this.state.files.map((file, file_index)=> {
                            return <div style={{display: 'flex', flexDirection: 'row', margin: '10px'}}>
                                <div style={{}}>
                                    <img width='150px' height='114' style={{}} src={file.thumb_url} />
                                </div>
                                <div style={{position: 'relative', top: '0', left: '-20px'}}>
                                    <img onClick={(event)=>this.removeFileFromList(event,file_index)} src='/images/profile/delete_icon.svg' />
                                </div>
                            </div>
                        })}
                    </div> : null}

                    {this.state.new_files && this.state.new_files.length != 0 ? <div style={{margin: '20px 10px'}}>
                        <div>New Files Added:</div>
                        <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 33%)', margin: '20px 0'}}>
                            {Array.from(this.state.new_files).map((file)=> {
                                return <iframe width='90%' style={{margin: '10px'}} src={URL.createObjectURL(file)} />
                            })}
                        </div>
                    </div> : null}

                    {this.props.updated_certificate_successfully? <div style={{color: 'green', textAlign: 'left'}}>Certificate Updated Successfully.</div> : null}
                    {this.props.deleted_certificate_successfully? <div style={{color: 'green', textAlign: 'left'}}>Certificate Deleted Successfully.</div> : null}
                    {this.props.error_deleting_certificate? <div style={{color: 'green', textAlign: 'left'}}>Error deleting certificate. Please try again after some time!!</div> : null}
                    
                    {this.props.updated_certificate_successfully || this.props.deleted_certificate_successfully?  
                    <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto', marginTop: '25px'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeCertificateEditor}>OK</div>
                    </div>
                    :<div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeCertificateEditor}>Cancel</div>
                        <div>
                            <SaveButton active={this.state.title && this.state.title != '' && this.state.company && this.state.company != '' && this.state.start_year && this.state.end_year && moment(this.state.end_year) > moment(this.state.start_year) }  onClick={this.updateCertificateHandler}>Save</SaveButton>
                        </div>
                    </div>}
                </div>
                <style jsx scoped="true">{`
                    .react-datepicker-wrapper,
                    .react-datepicker__input-container,
                    .datePicker input {
                        width: 100%;
                        // border: 0;
                        height: 22px;
                        border-radius: 4px;
                    }
                
                
                `}</style>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.prof.loading,
        name: state.prof.name,

        error_updating_certificate: state.prof.error_updating_certificate,
        updated_certificate_successfully: state.prof.updated_certificate_successfully,
        error_deleting_certificate: state.prof.error_deleting_certificate,
        deleted_certificate_successfully: state.prof.deleted_certificate_successfully,

        certificate: state.prof.certificate
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateCertificate: (item, formdata) => dispatch(itemUpdateRemoveAddAC(item, formdata)),
        clearStatus: () => dispatch({type:'CLEAR_UPDATE_CERTIFICATE_STATUS'})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateCertificate));