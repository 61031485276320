import React, { useEffect, useState } from 'react'
import RemedialCard from '../RemedialCard/RemedialCard'
import SpinWheel from '../SpinWheel/SpinWheel'
import { Box, Stack } from '@mui/material'
import { useRemedialContext } from '../../hooks/useRemedial'

const SpinWheelResultCard = ({prizeNo , spinWheelCategory}) => {

    const [wheelSpin , setWheelSpin] = useState(false);

console.log(prizeNo , 'prizeNo')
    // const data = spinWheelData;
    useEffect(() =>{
        setTimeout(() => setWheelSpin(true), 500);
    }, [])

    return (
        <RemedialCard>
            <Stack alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100%'}>
            <SpinWheel mustSpin={wheelSpin} prizeNumber={prizeNo} data={spinWheelCategory} setSpinDuration={0.3} />
            </Stack>
        </RemedialCard>
    )
}

export default SpinWheelResultCard