import axios from 'axios';

const axiosInterceptor = axios.create();

axiosInterceptor.interceptors.response.use(response => {
  if(response.config.url.includes("/auth/refresh") && response.status === 401 && response.data.msg && response.data.msg === "Token has expired"){
    localStorage.clear();
    window.location.replace("/");
  }
  // else if(response.data?.status && response.data.status != 200){  
  //   const form_data = new FormData();
  //   const url_split = response.config.url.split("?");
  //   const payload = response.config.method === "get" && url_split.length === 2 ? 
  //   url_split[1].split("&").reduce((acc, param) => { const [key, value] = param.split("="); return { ...acc, [key]: value }; }, {}) : {};
  //   form_data.append("payload",JSON.stringify(payload));
  //   const user = getLoggedInUser();
  //   form_data.append("user_id",user.user_id);
  //   form_data.append("api_name",url_split[0]);
  //   axios.post(`${process.env.REACT_APP_API_URL_TAKE_TEST}/auth/addLog`,form_data)
  //     .then(res => console.log(`${response.config.url} - Log addedd`))
  //     .catch(err => console.log(err));
  // }
  // else if(response.data && response.data.status === 401 && response.data.message === "Signature expired. Please log in again."){
  //   const refresh_token = localStorage.getItem('refresh_token');
  //   axios.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
  //   axios.get(`${process.env.REACT_APP_API_URL_TAKE_TEST}/auth/refresh`)
  //   .then((refres)=> localStorage.setItem('token', refres.data.token))
  //   .catch((err)=> console.log(err));
  //   return response;
  // }
  return response;
}, err => {
  if(err.config.url.includes("/auth/refresh")){
    localStorage.clear();
    window.location.replace("/");
  }
  // else{
  //   const form_data = new FormData();
  //   const url_split = err.config.url.split("?");
  //   const payload = err.config.method === "get" && url_split.length === 2 ? 
  //   url_split[1].split("&").reduce((acc, param) => { const [key, value] = param.split("="); return { ...acc, [key]: value }; }, {}) : {};
  //   form_data.append("payload",JSON.stringify(payload));
  //   const user = getLoggedInUser();
  //   form_data.append("user_id",user.user_id);
  //   form_data.append("api_name",url_split[0]);
  //   axios.post(`${process.env.REACT_APP_API_URL_TAKE_TEST}/auth/addLog`,form_data)
  //     .then(res => console.log(`${err.config.url} - Log addedd`))
  //     .catch(err => console.log(err));
  // }
  return Promise.reject(err);
});

export default axiosInterceptor;