import React, { useState, useEffect } from 'react';
import {styled} from '@mui/material/styles';
import CodingScreenHeader from '../../features/codingModule/codingScreenHeader';
import { Box, Button, FormControl, Grid, MenuItem, Select, Skeleton, Typography, TextField } from '@mui/material';
import CodingQuestionPanel from '../../features/codingModule/codingQuestionPanel';
import CodingEditor from '../../features/codingModule/codingEditor';
import CodingTestScreen from '../../features/codingModule/codingTestScreen';
import { useLocation, useHistory, withRouter } from 'react-router-dom';
import { getCodingQuestionAC, getCodingQuestionSubmissionHistoryAC, getCodingQuestionsListAC, submitCodingQuestionAC, unlockSolutionAC } from '../../store/actions/codingTrackActions';
import { connect } from 'react-redux';
import useTimeSpentTimer from '../../hooks/useTimeSpentTimer';
import ErrorPage from '../../components/common/ErrorPage';
import colors2, { darkModeColors } from './colors2';
import { CiLight } from 'react-icons/ci';
import { BsMoon } from 'react-icons/bs';

const tags = ['Math Fundamentals', 'Recursion', 'Anchor tag']

const BackButton = styled(Button)(({ theme }) => ({
    minWidth: '20px',
    height: '30px',
    borderRadius: '5px',
}));

const CodingTrackConsole = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [question, setQuestion] = useState(null);
    const [submissionHistory, setSubmissionHistory] = useState(null);
    const [isCodeBeingSubmitted, setIsCodeBeingSubmitted] = useState(false);
    const [compilerId, setCompilerId] = useState(0);
    const [isRunCode, setIsRunCode] = useState(false);
    const [sourceCode, setSourceCode] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [timeSpent, setTimeSpent] = useState(0);
    const [submissionType, setSubmissionType] = useState(0);
    const [runCount, setRunCount] = useState(0);
    const [allTrackQuestions, setAllTrackQuestions] = useState(null);
    const [openSubmissionPopup, setOpenSubmissionPopup] = useState(false);
    const [isCodeSwitchingSub, setIsCodeSwitchingSub] = useState(false);
    const [olderCompilerId, setOlderCompilerId] = useState(0); //To keep track of the language we switched from (through dropdown)
    const [alternateSubType, setAlternateSubType] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [canMoveToNextQuestion, setCanMoveToNextQuestion] = useState(false);
    const [enableSolutionButton, setEnableSolutionButton] = useState(false);
    const [attemptCountToEnableSolutionButton, setAttemptCountToEnableSolutionButton] = useState(5);
    const [askToShowHint, setAskToShowHint] = useState(false);
    const [paletteColors, setPaletteColors] = useState({});

    const { startTimer, stopTimer } = useTimeSpentTimer();

    useEffect(() => {
        setIsDarkMode(true);
        startTimer();
    }, []);
    
    useEffect(() => {
        if (isDarkMode) {
            setPaletteColors(darkModeColors);
        }
        else {
            setPaletteColors(colors2);
        }
    }, [isDarkMode]);

    useEffect(() => {
        setIsCodeSwitchingSub(false);
        setIsRunCode(false);
        setAlternateSubType('');
        setEnableSolutionButton(false);
        setAskToShowHint(false);
        
        const getCodingQuestionA = async (id) => await props.getCodingQuestion(id);
        const getCodingQuestionSubmissionHistoryA = async (id) => await props.getCodingQuestionSubmissionHistory(id);
        
        getCodingQuestionA(location.state.question_id);
        getCodingQuestionSubmissionHistoryA(location.state.question_id);
    }, [location.state.question_id]);

    useEffect(() => {
        if (props.question.length !== 0) {
                // console.log("Inside props.question useEffect");
                setQuestion(props.question);
                const getCodingQuestionsListA = async (track_id) => await props.getCodingQuestionsList(track_id);
                getCodingQuestionsListA(props.question.coding_track_id);
            }
    }, [props.question]);
    
    useEffect(() => {
        if (props.question_submission_history.length !== 0) {
            let sub_occurred = 0;
            setSubmissionHistory(props.question_submission_history);
            if (isCodeBeingSubmitted) {
                sub_occurred = 1;
                setIsCodeBeingSubmitted(false);
            }

            const hasHighScoreForRunTestCases = props.question_submission_history.some(
                submission => submission.submission_type === 2 && submission.score >= 100
            );
            const hasPerfectSubmissions = props.question_submission_history.some(
                submission => submission.submission_type === 3 && submission.score === 100
            );
            const attemptedSubmissionsCountWithoutFullScore = props.question_submission_history.filter(
                submission => (submission.submission_type === 2) && submission.score < 100
            ).length;
            
            if (hasPerfectSubmissions) {
                setCanMoveToNextQuestion(true);
            }

            if (hasHighScoreForRunTestCases) {
                setDisableSubmit(false);
            }
            else {
                setDisableSubmit(true);
            }

            if (attemptedSubmissionsCountWithoutFullScore >= 3 && !hasHighScoreForRunTestCases && sub_occurred === 1) {
                setAskToShowHint(true);
            }
            else {
                setAskToShowHint(false);
            }
            if (attemptedSubmissionsCountWithoutFullScore >= 5) {
                setEnableSolutionButton(true);
                setAttemptCountToEnableSolutionButton(0);
            }
            else {
                setEnableSolutionButton(false);
                let count = 5 - attemptedSubmissionsCountWithoutFullScore;
                setAttemptCountToEnableSolutionButton(count);
            }
        }
        else {
            setDisableSubmit(true);
            setSubmissionHistory([]);
            setEnableSolutionButton(false);
            setAttemptCountToEnableSolutionButton(5);
            setAskToShowHint(false);
        }
    }, [props.question_submission_history]);
    
    useEffect(() => {
        if (props.trackQuestions.length > 0) {
            setAllTrackQuestions(props.trackQuestions);
            const incompleteQuestions = props.trackQuestions.filter(ques => !ques.completed);
            const firstThreeIncompleteQuestionIds = incompleteQuestions.slice(0, 3).map(ques => ques.question_id);
            
            const isNextQuestionCompleted = props.trackQuestions.some(q => q.question_id === question?.next_question_id && q.completed);
            console.log(isNextQuestionCompleted)
            if (firstThreeIncompleteQuestionIds.includes(question?.next_question_id) || isNextQuestionCompleted) {
                setCanMoveToNextQuestion(true);
            }
            else {
                setCanMoveToNextQuestion(false);
            }
        }
    }, [props.trackQuestions]);
    
    useEffect(() => {
        if (sourceCode !== '' && sourceCode !== '//Start typing your code here' && sourceCode !== '#Start typing your code here') {
            let allowSub = true;
            if (submissionType === 1 && submissionHistory.length > 0) {
                let sub_history_length = submissionHistory.length;
                if (sourceCode === submissionHistory[sub_history_length-1].source && submissionHistory[sub_history_length-1].submission_type === 1 && submissionHistory[sub_history_length-1].input && inputValue === submissionHistory[sub_history_length-1].input) {
                    allowSub = false;
                }
            }

            if (isCodeSwitchingSub && submissionHistory.length > 0) {
                const sub = submissionHistory.slice().reverse();
                const language_latest_submission = sub.find(submission => submission.compiler_id === olderCompilerId);
                if (language_latest_submission && Object.keys(language_latest_submission).length > 0) {
                    if (sourceCode === language_latest_submission?.source) {
                        allowSub = false;
                    }
                }
            }

            if (isRunCode && allowSub) {
                setIsCodeBeingSubmitted(true);
                const submitCodingQuestionA = async (track_id, question_id, submission_type, time_spent, source_code, input_data, compiler_id, problem_id) => await props.submitCodingQuestion(track_id, question_id, submission_type, time_spent, source_code, input_data, compiler_id, problem_id); 
                submitCodingQuestionA(question.coding_track_id, question.id, submissionType, timeSpent, sourceCode, inputValue, isCodeSwitchingSub ? olderCompilerId : compilerId, question.sphere_engine_problem_id);
            }
        }
        setIsRunCode(false);
    }, [runCount]);

    useEffect(() => {
        if (props.question_submission_data && Object.keys(props.question_submission_data).length > 0) {
            const getCodingQuestionSubmissionHistoryA = async (id) => await props.getCodingQuestionSubmissionHistory(id);
            getCodingQuestionSubmissionHistoryA(location.state.question_id);
            if (props.question_submission_data.submission_type === "3" && !isCodeSwitchingSub) {
                setOpenSubmissionPopup(true);
            }
            setIsCodeSwitchingSub(false);
        }
    }, [props.question_submission_data]);
    
    useEffect(() => {
        const rootElement = window.document.documentElement;
        if (isDarkMode) {
            rootElement.classList.add('dark');
        } else {
            rootElement.classList.remove('dark');
        }
    }, [isDarkMode]);

    const handleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    const handleMoveToAnotherQuestion = (id, type) => {
        console.log("Clicked on question with id: "+id);
        if (id !==0) {
            const ques = allTrackQuestions.find(q => q.question_id === id); // To find the question to go to from here
            // if (ques && (ques.completed === 1 || ques.attempted === 1 || ques.id === location.state.display_question)) {
            //     history.push('/codingTrackConsole', {'question_id' : id, "display_question": location.state.display_question});
            // }
            if (ques) {
                if (type === 'previous') {
                    history.push('/codingTrackConsole', {'question_id' : id, "display_question": location.state.display_question});
                }
                else {
                    if (canMoveToNextQuestion) {
                        history.push('/codingTrackConsole', {'question_id' : id, "display_question": location.state.display_question});
                    }
                }
            }
        }
        // if (id !== 0) {
        //     history.push('/codingTrackConsole', {'question_id' : id, "display_question": location.state.display_question});
        // }
    };

    const handleCompilerChange = (compiler_id) => {
        console.log("comp id : "+compiler_id);
        setCompilerId(compiler_id);
    };

    const handleCodeChange = (source_code) => {
        setSourceCode(source_code);
        setRunCount(runCount + 1);
    };

    const handleTimeSpentAndSubmissionTypeAndInputParameters = (submission_type, input_value) => {
        const elapsedTimeInSeconds = stopTimer();
        setTimeSpent(elapsedTimeInSeconds);
        setSubmissionType(submission_type);
        setInputValue(input_value);
        setIsCodeSwitchingSub(false);
        setIsRunCode(true);
        startTimer();
    }

    const handleSubmissionPopup = () => {
        setOpenSubmissionPopup(false);
    }

    //to perform submission on behalf of user when they switch to another language
    const handleAlternateSubmissions = (type, source_code, older_compiler_id) => {  
        const elapsedTimeInSeconds = stopTimer();
        setTimeSpent(elapsedTimeInSeconds);
        setAlternateSubType(type);
        setSubmissionType(2);
        setInputValue('');
        setIsCodeSwitchingSub(true);
        setSourceCode(source_code);
        setOlderCompilerId(older_compiler_id);
        setIsRunCode(true);
        setRunCount(runCount + 1);

        startTimer();
        // console.log("In alternate submissions");
    }

    const handleUnlockSolution = () => {
        if (question && attemptCountToEnableSolutionButton === 0) {
            const unlockSolutionA = async (id) => await props.unlockSolution(id);
            unlockSolutionA(location.state.question_id);
        }
    }

    if (props.error_loading_question) {
        return (
            <>
                <CodingScreenHeader logo={'/rmklogo_transparent.png'} background={!isDarkMode ? '#F4F6F8' : '#252525'}/>
                <Box sx={{paddingTop: '50px', textAlign: 'center', background: !isDarkMode ? '#F4F6F8' : '#252525'}}>
                    <Box sx={{height: '95vh'}}>
                        <ErrorPage backgroundColor={!isDarkMode ? '#F4F6F8' : '#252525'} />
                    </Box>
                </Box>
            </>
        );
    }

    return (
        <>
                <CodingScreenHeader logo={'/rmklogo_transparent.png'} background={!isDarkMode ? '#F4F6F8' : '#252525'}/>
                {!props.loading_coding_question ? 
                    <Grid container direction="row">
                        <Grid item xs={3}>
                            {question && (
                                <CodingQuestionPanel 
                                    tags={tags}
                                    question={question}
                                    compilerId={compilerId}
                                    enableSolutionButton={enableSolutionButton}
                                    attemptCountToEnableSolutionButton={attemptCountToEnableSolutionButton}
                                    hasPerfectTestCasesScore={!disableSubmit} //Since disable submit is false when student gets 100% in test cases, and true if not
                                    solutionMessage={props.solution_message}
                                    questionSolutions={props.solutions}
                                    canMoveToNextQuestion={canMoveToNextQuestion}
                                    handleMoveToAnotherQuestion={handleMoveToAnotherQuestion}
                                    handleUnlockSolution={handleUnlockSolution}
                                    isDarkMode={isDarkMode}
                                    loading={props.loading_coding_question}
                                />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {question && (
                                <CodingEditor
                                    isRunCode={isRunCode}
                                    languages={question.languages}
                                    submissionHistory={submissionHistory}
                                    isCodeSwitchingSub={isCodeSwitchingSub}
                                    showHint={askToShowHint}
                                    hint={question?.hint}
                                    handleCompilerChange={handleCompilerChange}
                                    handleCodeChange={handleCodeChange}
                                    handleAlternateSubmissions={handleAlternateSubmissions}
                                    handleDarkMode={handleDarkMode} 
                                    isDarkMode={isDarkMode}
                                    loading={props.loading_coding_question}
                                />
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            {submissionHistory !== null && (
                                <CodingTestScreen
                                    submissionHistory={submissionHistory}
                                    disableSubmit={disableSubmit}
                                    isCodeBeingSubmitted={isCodeBeingSubmitted}
                                    nextQuestionId={question?.next_question_id}
                                    openSubmissionPopup={openSubmissionPopup}
                                    errorSubmittingQuestion={props.error_submitting_question}
                                    isDarkMode={isDarkMode}
                                    loading={props.loading_coding_question}
                                    handleSubmissionPopup={handleSubmissionPopup}
                                    moveToNextQuestion={handleMoveToAnotherQuestion}
                                    handleTimeSpentAndSubmissionTypeAndInputParameters={handleTimeSpentAndSubmissionTypeAndInputParameters}
                                />
                            )}
                        </Grid>
                    </Grid>
                :
                    <Box>
                        <Box sx={{height: '100vh', background: !isDarkMode ? '#F4F6F8' : '#252525'}}>
                            {/* <Typography variant="body1" sx={{color: isDarkMode ? '#F4F6F8' : '#252525'}}>Loading</Typography> */}
                            <Grid container direction="row">
                                <Grid item xs={3}>
                                    <Grid container direction='column' sx={{padding: '20px', marginTop: '5px', marginLeft: '1px'}}>
                                        <Grid item sx={{overflow: 'hidden'}}>
                                            <Grid container justifyContent='flex-start' alignItems='center'>
                                                <Grid item>
                                                    <Button sx={{height: '30px', minWidth: '20px', borderRadius: '5px', background: !isDarkMode ? paletteColors.secondary : paletteColors.grey1, '&:hover' : {background: isDarkMode ? paletteColors.secondary : paletteColors.grey1}}}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path d="M7.64767 4.73889L2.79688 9.58968L7.64767 14.4405M16.3823 9.58968H2.93273" stroke={!isDarkMode ? paletteColors.primary : "white"} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </Button>
                                                </Grid>
                                                <Grid item sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '15px', marginRight: '10px', marginLeft: '10px', flexGrow: 1, borderBottom: isDarkMode ? paletteColors.grey2 : `1px solid ${paletteColors.grey[200]}`}}>
                                                    <Typography variant='body1'  sx={{cursor: 'pointer', color: paletteColors.textPrimary, borderBottom: `2px solid ${paletteColors.primary}`}}>Question</Typography>
                                                    <Typography variant='body1'  sx={{cursor: 'pointer', color: paletteColors.textPrimary, borderBottom: 'none'}}>Solution</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Skeleton variant='rounded' height={'25px'} sx={{marginTop: '35px', background: paletteColors.skeletonColor }}/>
                                        <Box sx={{marginTop: '15px', display: 'flex', flexDirection: 'column', gap: '7px'}}>
                                            <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>
                                            <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>
                                            <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>
                                            <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>
                                            <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>
                                            <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>
                                            <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>   
                                        </Box>
                                        <Box sx={{marginTop: '30px'}}>
                                            <Skeleton variant='rounded' height={'20px'} sx={{background: paletteColors.skeletonColor}}/>
                                            <Box sx={{marginTop: '15px', display: 'flex', flexDirection: 'column', gap: '7px'}}>
                                                <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>
                                                <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>
                                                <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>  
                                            </Box>
                                        </Box>
                                        <Box sx={{marginTop: '30px'}}>
                                            <Skeleton variant='rounded' height={'20px'} sx={{background: paletteColors.skeletonColor}}/>
                                            <Box sx={{marginTop: '15px', display: 'flex', flexDirection: 'column', gap: '7px'}}>
                                                <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>
                                                <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>
                                                <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>  
                                            </Box>
                                        </Box>
                                        <Box sx={{marginTop: '30px'}}>
                                            <Skeleton variant='rounded' height={'20px'} sx={{background: paletteColors.skeletonColor}}/>
                                            <Box sx={{marginTop: '15px', display: 'flex', flexDirection: 'column', gap: '7px'}}>
                                                <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>
                                                <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>
                                                <Skeleton variant='rounded' sx={{background: paletteColors.skeletonColor}}/>  
                                            </Box>
                                        </Box> 
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: '0px',
                                                display: 'flex',
                                                justifyContent: 'space-evenly',
                                                alignItems: 'flex-end',
                                                gap: '10px',
                                                paddingBottom: '20px',
                                                height: '100px',
                                                width: '21%',
                                            }}
                                        >
                                                <Button 
                                                    variant='outlined' 
                                                    size='small' 
                                                    sx={{background: !isDarkMode ? '#fff' : paletteColors.grey1, border: `1px solid ${!isDarkMode ? paletteColors.primary : '#fff'}`, padding: '5px 15px', '&:hover': {background: !isDarkMode ? paletteColors.secondary : paletteColors.primary}}} 
                                                >
                                                    <Typography variant="caption2" color={!isDarkMode ? paletteColors.primary: paletteColors.textPrimary}>Previous question</Typography>
                                                </Button>
                                                <Button 
                                                    variant='filled' 
                                                    size='small' 
                                                    sx={{background: paletteColors.primary, padding: '5px 15px', '&:hover' : {background: paletteColors.primary, color: '#fff'}}} 
                                                >
                                                    <Typography variant="caption2" color={'#fff'}>Next question</Typography>
                                                </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container direction='column' sx={{p:0, paddingTop: '20px', background: !isDarkMode ? '#fff' : paletteColors.backgroundPrimary}}>
                                        <Grid item>
                                            <Grid container justifyContent='space-between' alignItems='center'>
                                                <Grid item>
                                                    <Box width="120px">
                                                        <FormControl fullWidth sx={{ '& .MuiOutlinedInput-notchedOutline': { border: 'none', } }}>
                                                            <Select
                                                                labelId="skeleton-select"
                                                                id="skeleton-select"
                                                                value={'C2'}
                                                                inputProps={{ 'aria-label': 'Without label', style: { border: 'none' } }}
                                                                onChange={() => {}}
                                                                sx={{
                                                                    height: '30px', 
                                                                    fontSize: '12px', 
                                                                    fontWeight: '500', 
                                                                    marginLeft: '10px', 
                                                                    background: !isDarkMode ? paletteColors.secondary : paletteColors.grey1, 
                                                                    color: !isDarkMode ? paletteColors.primary : paletteColors.textPrimary,
                                                                }}
                                                            >
                                                                <MenuItem value={"C2"}>C</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item >
                                                    <Grid container justifyContent='space-evenly' alignItems='center' wrap='nowrap'>
                                                        <Grid item sx={{marginLeft: '10px'}}>
                                                            {/* <StyledButton onClick={handleResetCode}><Box component="img" src={`/coding/reset_code.svg`}/>&nbsp;Reset code</StyledButton> */}
                                                            <Button 
                                                                variant='filled' 
                                                                size='small' 
                                                                sx={{background: !isDarkMode ? paletteColors.grey[100] : paletteColors.grey1 , padding: '5px 15px', '&:hover': {background: !isDarkMode ? paletteColors.secondary : paletteColors.grey1}}} 
                                                            >
                                                                <Typography variant={!isDarkMode ? "caption2" : "caption1"} color={paletteColors.textPrimary} sx={{display: 'flex', justifyContent: "center", flexWrap: 'nowrap'}}>
                                                                    <Box component="img" src={!isDarkMode ? `/images/reset_code.svg`: `/images/reset_code_white.svg`}/>&nbsp;Reset code
                                                                </Typography>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item sx={{marginLeft: '10px', marginRight: '10px'}}>
                                                            <Button 
                                                                variant='filled' 
                                                                size='small' 
                                                                sx={{background: !isDarkMode ? paletteColors.secondary : paletteColors.grey1, padding: '5px 15px', '&:hover': {background: !isDarkMode ? paletteColors.secondary : paletteColors.grey1}}}
                                                                >
                                                                <Typography variant={!isDarkMode ? "caption2" : "caption1"} color={!isDarkMode ? paletteColors.primary : paletteColors.textPrimary}>{isDarkMode ? <CiLight sx={{height: '25px', width: '25px'}}/> : <BsMoon sx={{height: '25px', width: '25px'}}/>}&nbsp;{isDarkMode ? 'Light' : 'Dark'}</Typography>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={{marginTop: '20px', padding: '10px'}}>
                                            <Skeleton variant='rounded' height={'90vh'} sx={{background: paletteColors.skeletonColor}}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container direction='column' sx={{marginTop: '15px'}}>
                                        <Grid item>
                                            <Grid container justifyContent='space-between' alignItems='center'>
                                                <Grid item sx={{marginLeft: '15px', marginTop: '5px'}}>
                                                    <Button 
                                                        variant= {!isDarkMode ? 'filled' : 'outlined'} 
                                                        size='small' 
                                                        sx={{background: !isDarkMode ? paletteColors.secondary : paletteColors.grey1, padding: '5px 15px', marginRight: '7.5px', border: !isDarkMode ? 'none' : paletteColors.companyTagPrimary, '&:hover': {background: !isDarkMode ? paletteColors.secondary : paletteColors.grey1, border: !isDarkMode ? 'none' : paletteColors.companyTagPrimary,}}} 
                                                    >
                                                        <Typography variant="caption2" sx={{display: 'flex', justifyContent: "center", flexWrap: 'nowrap', color: !isDarkMode ? paletteColors.primary : paletteColors.textPrimary}}>
                                                            Run Code&nbsp;
                                                            <Box component="img" src={!isDarkMode ? "/images/run.svg" : "/images/run_white.svg"}/>
                                                        </Typography>
                                                    </Button>
                                                    <Button
                                                        variant= {!isDarkMode ? 'filled' : 'outlined'} 
                                                        size='small' 
                                                        sx={{background: !isDarkMode ? paletteColors.secondary : paletteColors.grey1, padding: '5px 15px', border: !isDarkMode ? 'none' : paletteColors.companyTagPrimary, '&:hover': {background: !isDarkMode ? paletteColors.secondary : paletteColors.grey1, border: !isDarkMode ? 'none' : paletteColors.companyTagPrimary,}}}
                                                    >
                                                        <Typography variant="caption2" sx={{display: 'flex', justifyContent: "center", flexWrap: 'nowrap',color: !isDarkMode ? paletteColors.primary : paletteColors.textPrimary}}>
                                                            Run Test Cases&nbsp;
                                                            <Box component="img" src={!isDarkMode ? "/images/test_tube.svg" : "/images/test_tube_white.svg"}/>
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                                <Grid item sx={{margin: '5px 15px 0px 15px'}}>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        sx={{marginRight: '5px', background: paletteColors.primary, padding: '5px 15px'}}
                                                    >
                                                        <Typography variant="caption2" color='#fff'>Submit</Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={{margin: '20px 15px', marginBottom: '5px'}}>
                                            <TextField multiline label="Input parameters" value={''} variant="outlined" size='small' sx={{width: '100%', border: !isDarkMode ? `1px solid ${paletteColors.grey[300]}` : `1px solid ${paletteColors.grey1}`, background: !isDarkMode ? paletteColors.grey[100] : paletteColors.backgroundPrimary, color: !isDarkMode ? paletteColors.textPrimary : paletteColors.easy}} onChange={() => {}}/>
                                            <Grid container justifyContent="flex-end" alignItems="center">
                                                <Grid item>
                                                    <Typography variant="caption2" color={paletteColors.textPrimary} sx={{margin: '5px 10px', cursor: 'pointer'}}>Auto-clear</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="caption2" color={paletteColors.textPrimary} sx={{margin: '5px 10px', cursor: 'pointer'}}>Clear log</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Typography variant='body2' sx={{textAlign: 'left', margin: '5px 15px', color: !isDarkMode ? paletteColors.primaryLighter : paletteColors.textPrimary}}>Output Logs</Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                padding: '5px 10px',
                                                textAlign: 'left',
                                                background: !isDarkMode ? paletteColors.secondary : paletteColors.grey1,
                                                margin: '0px 15px', 
                                                overflow: 'hidden',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            <Typography variant='caption2' width="35%" sx={{color: !isDarkMode ? paletteColors.primary : paletteColors.textSecondary}}>Status</Typography>
                                            <Typography variant='caption2' width="45%" sx={{color: !isDarkMode ? paletteColors.primary : paletteColors.textSecondary}}>Details</Typography>
                                            <Typography variant='caption2' width="20%" sx={{color: !isDarkMode ? paletteColors.primary : paletteColors.textSecondary}}>Type</Typography>
                                        </Box>
                                        <Grid container direction='column' gap='10px' sx={{padding: '10px 15px'}}>
                                            <Skeleton variant='rounded' height='50px' sx={{background: paletteColors.skeletonColor}}/>
                                            <Skeleton variant='rounded' height='50px' sx={{background: paletteColors.skeletonColor}}/>
                                            <Skeleton variant='rounded' height='50px' sx={{background: paletteColors.skeletonColor}}/>
                                            <Skeleton variant='rounded' height='50px' sx={{background: paletteColors.skeletonColor}}/>
                                            <Skeleton variant='rounded' height='50px' sx={{background: paletteColors.skeletonColor}}/>
                                            <Skeleton variant='rounded' height='50px' sx={{background: paletteColors.skeletonColor}}/>
                                            <Skeleton variant='rounded' height='50px' sx={{background: paletteColors.skeletonColor}}/>
                                            <Skeleton variant='rounded' height='50px' sx={{background: paletteColors.skeletonColor}}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                }
        </>
    )
} 

const mapStateToProps = (state) => {
    return {
      question: state.codingTrack.codingQuestionData,
      question_submission_history: state.codingTrack.questionSubmissionHistory, //For all submissions
      question_submission_data: state.codingTrack.questionSubmissionData, //For the particular submission that has been done at the last 
      error_loading_question: state.codingTrack.error_loading_question,
      error_submitting_question: state.codingTrack.error_submitting_question,
      trackQuestions: state.codingTrack.trackQuestions,
      error_loading_questionsList: state.codingTrack.error_loading_questionsList,
      loading_coding_question: state.codingTrack.loading_coding_question,
      solutions: state.codingTrack.solutions,
      solution_message: state.codingTrack.solution_message,
      loading_solution: state.codingTrack.loading_solution,
      error_loading_solution: state.codingTrack.error_loading_solution,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getCodingQuestion: (question_id) => dispatch(getCodingQuestionAC(question_id)),
      getCodingQuestionSubmissionHistory: (question_id) => dispatch(getCodingQuestionSubmissionHistoryAC(question_id)),
      getCodingQuestionsList: (track_id) => dispatch(getCodingQuestionsListAC(track_id)),
      submitCodingQuestion: (track_id, question_id, submission_type, time_spent, source_code, input_data, compiler_id, problem_id) => dispatch(submitCodingQuestionAC(track_id, question_id, submission_type, time_spent, source_code, input_data, compiler_id, problem_id)), 
      unlockSolution: (question_id) => dispatch(unlockSolutionAC(question_id)),
    }
  }
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CodingTrackConsole)
  );