import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components';
import MeterSvg from './../../../../assets/remedial/svg/testmeter.svg'
import Pill from '../../../../components/common/Pill/Pill';
import DocumentTextSvg from '../../../../assets/remedial/svg/DocumentTextSvg';
import OpenBookSvg from '../../../../assets/remedial/svg/OpenBookSvg';


// variant -> result-header or page-header



const RemedialHeader = ({
    variant = 'result-header',
    heading = 'placeholder',
    testName = 'placeholder',
    subject = 'placeholder',
    pill,
    total,
    percentage
}) => {

    const calculateMarks = (data) => (data?.length > 0) ? data.reduce((acc, section) => acc + (section.correct_answered_count * section.marks), 0) : 0;
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
                justifyContent: variant === 'result-header' ? 'flex-start' : 'space-between',
            }}
        >
            <Stack direction={'row'} alignItems={'center'} gap={'20px'}>
                <Typography component='h2' sx={{
                    fontFamily: 'Poppins-SemiBold',
                    color: 'primary.800',
                    fontSize: '20px'
                }}>{heading}</Typography>
                {pill && <Box sx={{
                    padding: '6px 12px',
                    borderRadius: '122px',
                    backgroundColor: theme => theme.palette.primary[100],
                }}>
                    <Typography component='h2' sx={{
                        fontFamily: 'Poppins-SemiBold',
                        color: 'primary.800',
                        fontSize: '12px'
                    }}>{pill}</Typography>
                </Box>}
            </Stack>

            {
                variant === 'result-header' && <Box display={'flex'} alignItems={'center'} gap={'24px'}>
                    <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                        <Box display={'flex'} alignItems={'center'} gap={'4px'}>
                            <Box sx={{
                                width: 20,
                                height: 20,
                                svg: {
                                    path: {
                                        stroke: 'primary.200'
                                    }
                                }
                            }}>
                                <DocumentTextSvg />
                            </Box>
                            <Typography component='p' sx={{
                                fontFamily: 'Poppins-Medium',
                                color: 'grey.600',
                                fontSize: '14px'
                            }}>Test Name:</Typography>
                        </Box>
                        <Typography component='p' sx={{
                            fontFamily: 'Poppins-SemiBold',
                            color: 'grey.900',
                            fontSize: '14px'
                        }}>{testName}</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                        <Box display={'flex'} alignItems={'center'} gap={'4px'}>
                            <Box sx={{
                                width: 20,
                                height: 20,
                                svg: {
                                    path: {
                                        stroke: 'primary.200'
                                    }
                                }
                            }}>
                                <OpenBookSvg />
                            </Box>
                            <Typography component='p' sx={{
                                fontFamily: 'Poppins-Medium',
                                color: 'grey.600',
                                fontSize: '14px'
                            }}>Subject:</Typography>
                        </Box>
                        <Typography component='p' sx={{
                            fontFamily: 'Poppins-SemiBold',
                            color: 'grey.900',
                            fontSize: '14px'
                        }}>{subject}</Typography>
                    </Box>
                </Box>
            }

            {
                variant === 'page-header' && <Stack direction={'row'} alignItems={'center'} gap={'14px'}>

                    <Stack direction={'row'} alignItems={'center'} gap={'4px'}>
                        <Box>
                            <img src={MeterSvg} width={30} height={30} alt="meter" />
                        </Box>
                        <Typography component='p' sx={{
                            fontFamily: 'Poppins-SemiBold',
                            color: 'grey.900',
                            fontSize: '14px'
                        }}>Test Score :</Typography>
                    </Stack>

                    <Typography component='h3' sx={{
                        fontFamily: 'Poppins-SemiBold',
                        color: 'primary.800',
                        fontSize: '16px'
                    }}>{calculateMarks(percentage)}/{total}</Typography>
                </Stack>
            }
        </Box>
    )
}

export default RemedialHeader