import React from "react";
import { Component } from "react";
import styled from "styled-components";
import Flex from "styled-flex-component";
import {connect} from 'react-redux';
import Circle from '../../components/UI/QuestionCircle';
import {submitFeedbackAC} from "../../store/actions/classroomActions";


const ModalWrapper = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.1);
`;

const ModalTextWrapper = styled(Flex)`
  width: 80%;
//   padding: 2em;
  border: 1px solid #ccc;
  margin: auto;
  background: #fff;
  margin-top: 120px;
  height: 70%;
  border-radius: 8px;
  padding: 20px;
  overflow: auto;

`;


const OptionFlex = styled.div`
    margin: 10px;
    padding: 10px;
    margin-left: 0;
    align-items:center;
    &:hover{
        background-color: #F6E4E4;
    }
`

class FeedbackModal extends Component {
    state = {
        
    }
    render () {
        if (!this.props.feedback_data) {
            return <div></div>;
        }
        let feedback_questions = this.props.feedback_data.feedback_questions;
        let answers = this.props.feedback_answers;
        let qid = this.props.qid;
        let qindex = this.props.qindex;
        let option_numbers = {0: 'a', 1: 'b', 2: 'c', 3: 'd', 4: 'e', 5 : 'f', 6: 'g', 7: 'h', 8: 'i', 9: 'j'};
        return (
            <ModalWrapper center open={this.props.open}>
                <ModalTextWrapper onClick={()=>{}} column>
                    <div onClick={this.props.closeFeedback} style={{textAlign: 'right', cursor: 'pointer', color: 'blue', textDecoration: 'underline'}}>Close</div>

                    <div>
                        <Flex row style = {{margin: '8px', marginTop: '50px'}}>
                            <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                                {this.props.feedback_data.name} 
                            </div>
                            <Flex row style={{fontSize: '14px', margin: 'auto', marginLeft: '5px'}}>
                                <div style={{margin: 'auto', marginBottom: '4px'}}>
                                    &nbsp;Description: {this.props.feedback_data.description} | Questions: {feedback_questions.length}

                                </div>
                                {/* <div onClick={this.openInstructionsHandler} style={{marginLeft:'8px'}}>
                                    <img src='/images/Info.svg'></img>
                                </div> */}
                            </Flex>
                        </Flex>
                        <div>
                            <Flex row style={{marginTop: '20px'}}>
                                {feedback_questions.map((question, index)=> (
                                    <Flex column>
                                        {index === qindex ? <img style={{marginTop: '-15px', marginBottom: '-10px', width: '30px', marginLeft: '8px'}} src= '/images/Small_monkey.svg' alt="monkey"></img>: null}
                                        <Circle current={index === qindex} answered={answers[index]?.question_option_id} onQuestionClick = {()=>this.props.onQuestionClick(index)} number={index + 1} key={index}></Circle>
                                    </Flex>
                                ))}
                            </Flex>
                        </div>
                        <div style={{marginLeft: '8px',marginTop: '20px'}}>
                            <Flex row >
                                <Flex row>
                                    <div style={{fontWeight: 'bold', fontSize: '16px', marginRight: '8px', width: '2%'}}>
                                        {qindex + 1}.
                                    </div>
                                    <Flex column style={{marginLeft: '10px', width: '98%'}}>
                                        <Flex column style={{marginBottom:'20px', textAlign: 'left'}}>
                                            <div style={{fontSize: '14px', fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: feedback_questions[qindex].name.replace(/(?:\\r\\n|\\r|\\n)/g, '<br/>')}}></div>
                                        </Flex>
                                        
                                        <div>
                                        {feedback_questions[qindex].feedback_questions_options.map(({ id, name }, i) => {
                                            
                                            const is_selected = answers[qid] === id;
                                            
                                            return (
                                                <OptionFlex onClick={() => this.props.onOptionSelected(qid, id)} style ={{ color: is_selected ? '#4E54C8' : null, fontWeight: is_selected ? '600' : '400', alignItems: 'center', backgroundColor: is_selected ? '#DDDDDD' : null}}> 
                                                    <Flex row key={i} >
                                                        <div onClick={() => this.props.onOptionSelected(qid, id)} style={{margin: 'auto',marginRight: '8px', width: '2%', marginLeft: '10px'}}>{option_numbers[i]})</div>
                                                        <div style = {{margin: 'auto',marginLeft: '10px'}} onClick={() => this.props.onOptionSelected(qid, id)}>
                                                            <div style={{fontSize: '14px', fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: name.replace(/(?:\\r\\n|\\r|\\n)/g, '<br>')}}></div>
                                                        </div>
                                                    </Flex>
                                                </OptionFlex>)
                                            }
                                            )}
                                        </div>
                                    </Flex>
                                </Flex>
                                
                            </Flex>
                            {qindex !== 0 ? <div className = 'submitButton' onClick={() =>this.props.onQuestionClick(qindex-1)} style={{float: 'left', marginRight: '40px', paddingLeft: '12px', marginLeft: '20px'}}>
                                <div style={{marginRight: '10px'}}>
                                    <img src ='/images/left_arrow.svg' alt="left"></img>
                                </div>
                                <div>Previous</div>
                            </div>:  null}
                            
                            
                            {qindex !== feedback_questions.length -1 ? <div className='nextButton' onClick={() =>this.props.onQuestionClick(qindex+1)} style={{float: 'right', marginRight: '40px'}}>
                                {/* <img src='/images/Group 15.svg'></img> */}
                                
                                <div style={{margin: 'auto', marginTop: '5px'}}>Next</div>
                                <div style={{margin: 'auto', marginLeft: '10px'}}>
                                    <img src ='/images/Right_arrow.svg' alt="right"></img>
                                </div>
                            </div>: null}
                            {qindex === feedback_questions.length -1 ? <div className='submitButton' onClick={() =>this.props.submitFeedback()} style={{float: 'right', marginRight: '40px'}}>                                
                                <div style={{margin: 'auto', fontSize: '14px', fontWeight: '600'}}>Submit</div>
                            </div>: null}
                        </div>
                    </div>
                </ModalTextWrapper>
                <style jsx scoped>{`
                    .submitButton{
                        border: 1px solid #6568D9;
                        height: 40px;
                        // border-image: linear-gradient(to right bottom, #6568D9, #9055CA) 1;
                        margin: auto;
                        // margin-left: 20px;
                        float: right;
                        width: 100px;
                        padding: 0.3em 1em;
                        padding-top: 8px;
                        padding-left: 20px;
                        border-radius: 20px; 
                        font-size: 14px;
                        color: #6568D9;
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;

                        &:focus{
                            outline:0;
                        }
                          
                      }
                    .nextButton {
                        background:
                            linear-gradient(to right bottom, #7175E0 0%, #4D50CB 32.912%, #E25BC8 100%) padding-box, 
                            linear-gradient(to right bottom, #7175E0 0%, #4D50CB 32.912%, #E25BC8 100%) border-box;
                        margin: auto;
                        float: right;
                        padding: 0.3em 1em;
                        border-radius: 20px; 
                        font-size: 14px;
                        width: 100px;
                        height: min-content;
                        display: flex;
                        flex-direction: row;
                        height: min-content;
                        box-sizing: border-box;
                        font-weight: 500;
                        color: white;
                        border: none;
                        cursor: pointer;
                    }
                
                `}

                </style>
            </ModalWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        college_notifications: state.class.college_notifications,
        error_posting_comment: state.class.error_posting_comment,
        start_feedback: state.class.start_feedback,
        feedback_data: state.class.feedback_data,
        feedback_answers: state.class.feedback_answers,
        qid: state.class.qid,
        qindex: state.class.qindex
    }
}

const mapDispatchToProps = dispatch => {
    return {        
        onQuestionClick: (qindex, question_option_id)=>dispatch({type: 'ON_FEEDBACK_QUESTION_CLICK', qindex: qindex, question_option_id: question_option_id }),
        onOptionSelected: (qid,option_id)=>dispatch({type: 'ON_FEEDBACK_OPTION_SELECTED', qid: qid, option_id: option_id}),
        submitFeedback: ()=>dispatch(submitFeedbackAC()),
        closeFeedback: ()=>dispatch({type: 'CLOSE_FEEDBACK'})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackModal);