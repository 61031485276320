import axios from "../../interceptor";
import { getLoggedInUser } from "../../helpers/Auth";

const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
let http = axios;
// const user = JSON.parse(localStorage.getItem("user"));
export function setGlobalToken() {
    try {
      let token = localStorage.getItem("token");
      http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      console.log(error);
    }
}

export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const refreshTokenA = (err) => {
  return {
    type: REFRESH_TOKEN,
    error: err,
  };
};

export const refreshTokenAC = (code) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const refresh_token = localStorage.getItem("refresh_token");
    http.defaults.headers.common["Authorization"] = `Bearer ${refresh_token}`;
    http
      .get(`${BASE_API_URL}/auth/refresh`)
      .then((refres) => {
        localStorage.setItem("token", refres.data.token);
        setGlobalToken();
        dispatch(refreshTokenA(false));
      })
      .catch((err) => {
        dispatch(refreshTokenA(true));
        console.log(err);
      });
  };
};

export const GET_CODING_STATUS = "GET_CODING_STATUS";
export const GET_CODING_TRACKS = "GET_CODING_TRACKS";
export const GET_CODING_QUESTIONS_LIST = "GET_CODING_QUESTIONS_LIST";
export const GET_CODING_STATS = "GET_CODING_STATS";
export const GET_CODING_QUESTION = "GET_CODING_QUESTION";
export const SUBMIT_CODING_QUESTION = "SUBMIT_CODING_QUESTION";
export const GET_CODING_QUESTION_SUBMISSION_HISTORY = "GET_CODING_QUESTION_SUBMISSION_HISTORY";
export const UNLOCK_SOLUTION = "UNLOCK_SOLUTION";
export const FETCHING_DATA = "FETCHING_DATA";

export const getCodingStatusA = (res, err) => {
    return {
        type: GET_CODING_STATUS,
        response: res,
        error: err,
    }
}

export const getCodingTracksA = (res, err) => {
    return {
        type: GET_CODING_TRACKS,
        response: res,
        error: err,
    }
}

export const getCodingQuestionsListA = (res, err) => {
    return {
        type: GET_CODING_QUESTIONS_LIST,
        response: res,
        error: err,
    }
}

export const getCodingStatsA = (res, err) => {
    return {
        type: GET_CODING_STATS,
        response: res,
        error: err,
    }
}

export const getCodingQuestionA = (res, err) => {
    return {
        type: GET_CODING_QUESTION,
        response: res,
        error: err,
    }
}

export const submitCodingQuestionA = (res, err) => {
    return {
        type: SUBMIT_CODING_QUESTION,
        response: res,
        error: err,
    }
}

export const getCodingQuestionSubmissionHistoryA = (res, err) => {
    return {
        type: GET_CODING_QUESTION_SUBMISSION_HISTORY,
        response: res,
        error: err,
    }
}

export const unlockSolutionA = (message, res, err) => {
    return {
        type: UNLOCK_SOLUTION,
        response: res,
        message: message,
        error: err,
    }
}

export const fetchingData = (res) => {
    return {
        type: FETCHING_DATA,
        response: res, 
    }
}

export const getCodingStatusAC = () => {
    console.log("in action creator");
    setGlobalToken();
    return async (dispatch, getState) => {
        dispatch(fetchingData('status'));
        http
            .get(`${BASE_API_URL}/codingTrack/v2/getCodingStatus`)
            .then((res) => {
            console.log("res");
            console.log(res);
            if (res.data.status === 200) {
                dispatch(getCodingStatusA(res.data.data, false));
            } else if (
                res.data.message === "Signature expired. Please log in again."
            ) {
                const refresh_token = localStorage.getItem("refresh_token");
                http.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${refresh_token}`;
                http
                    .get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres) => {
                    localStorage.setItem("token", refres.data.token);
                    setGlobalToken();
                    http
                    .get(`${BASE_API_URL}/codingTrack/v2/getCodingStatus`)
                    .then((res) => {
                        console.log("data= ", res.data);
                        if (res.data.status === 200) {
                            dispatch(getCodingStatusA(res.data.data, false));
                        // return res.data;
                        } else if (
                            res.data.message === "Signature expired. Please log in again."
                        ) {
                            dispatch(getCodingStatusA(null, true));
                        // return res;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            })
            .catch((err) => {
            console.log(err);
            dispatch(getCodingStatusA(null, true));
            });
    };
};

export const getCodingTracksAC = () => {
    console.log("in action creator");
    setGlobalToken();
    return async (dispatch, getState) => {
        dispatch(fetchingData('tracks'));
        http
            .get(`${BASE_API_URL}/codingTrack/getCodingTracks`)
            .then((res) => {
            console.log("res");
            console.log(res);
            if (res.data.status === 200) {
                dispatch(getCodingTracksA(res.data.data, false));
            } 
            else if (res.data.status === 500) {
                dispatch(getCodingTracksA(null, true));
            }
            else if (
                res.data.message === "Signature expired. Please log in again."
            ) {
                const refresh_token = localStorage.getItem("refresh_token");
                http.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${refresh_token}`;
                http
                    .get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres) => {
                    localStorage.setItem("token", refres.data.token);
                    setGlobalToken();
                    http
                    .get(`${BASE_API_URL}/codingTrack/getCodingTracks`)
                    .then((res) => {
                        console.log("data= ", res.data);
                        if (res.data.status === 200) {
                            dispatch(getCodingTracksA(res.data.data, false));
                        // return res.data;
                        } 
                        else if (res.data.status === 500) {
                            dispatch(getCodingTracksA(null, true));
                        }
                        else if (
                            res.data.message === "Signature expired. Please log in again."
                        ) {
                            dispatch(getCodingTracksA(null, true));
                        // return res;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            })
            .catch((err) => {
            console.log(err);
            dispatch(getCodingTracksA(null, true));
            });
    };
};

export const getCodingQuestionsListAC = (track_id) => {
    console.log("in action creator");
    setGlobalToken();
    return async (dispatch, getState) => {
        dispatch(fetchingData('questions_list'));
        http
            .get(`${BASE_API_URL}/codingTrack/v2/getCodingQuestionsList?track_id=${track_id}`)
            .then((res) => {
            console.log("res");
            console.log(res);
            if (res.data.status === 200) {
                dispatch(getCodingQuestionsListA(res.data.data, false));
            } 
            else if (res.data.status === 500) {
                dispatch(getCodingQuestionsListA(null, true));
            }
            else if (
                res.data.message === "Signature expired. Please log in again."
            ) {
                const refresh_token = localStorage.getItem("refresh_token");
                http.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${refresh_token}`;
                http
                    .get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres) => {
                    localStorage.setItem("token", refres.data.token);
                    setGlobalToken();
                    http
                    .get(`${BASE_API_URL}/codingTrack/getCodingQuestionsList?track_id=${track_id}`)
                    .then((res) => {
                        console.log("data= ", res.data);
                        if (res.data.status === 200) {
                            dispatch(getCodingQuestionsListA(res.data.data, false));
                        // return res.data;
                        } 
                        else if (res.data.status === 500) {
                            dispatch(getCodingQuestionsListA(null, true));
                        }
                        else if (
                            res.data.message === "Signature expired. Please log in again."
                        ) {
                            dispatch(getCodingQuestionsListA(null, true));
                        // return res;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            })
            .catch((err) => {
            console.log(err);
            dispatch(getCodingQuestionsListA(null, true));
            });
    };
};

export const getCodingStatsAC = (track_id) => {
    console.log("in action creator");
    setGlobalToken();
    return async (dispatch, getState) => {
        http
            .get(`${BASE_API_URL}/codingTrack/getCodingStats?track_id=${track_id}`)
            .then((res) => {
            console.log("res");
            console.log(res);
            if (res.data.status === 200) {
                dispatch(getCodingStatsA(res.data.data, false));
            } else if (
                res.data.message === "Signature expired. Please log in again."
            ) {
                const refresh_token = localStorage.getItem("refresh_token");
                http.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${refresh_token}`;
                http
                    .get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres) => {
                    localStorage.setItem("token", refres.data.token);
                    setGlobalToken();
                    http
                    .get(`${BASE_API_URL}/codingTrack/getCodingStats?track_id=${track_id}`)
                    .then((res) => {
                        console.log("data= ", res.data);
                        if (res.data.status === 200) {
                            dispatch(getCodingStatsA(res.data.data, false));
                        // return res.data;
                        } else if (
                            res.data.message === "Signature expired. Please log in again."
                        ) {
                            dispatch(getCodingStatsA(null, true));
                        // return res;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            })
            .catch((err) => {
            console.log(err);
            dispatch(getCodingStatsA(null, true));
            });
    };
};

export const getCodingQuestionAC = (master_question_id) => {
    console.log("in action creator");
    setGlobalToken();
    return async (dispatch, getState) => {
        dispatch(fetchingData('coding_question'));
        http
            .get(`${BASE_API_URL}/codingTrack/v2/getCodingQuestion?master_question_id=${master_question_id}`)
            .then((res) => {
            console.log("res");
            console.log(res);
            if (res.data.status === 200) {
                dispatch(getCodingQuestionA(res.data.data, false));
            } 
            else if (res.data.status === 500) {
                dispatch(getCodingQuestionA(null, true));
            }
            else if (
                res.data.message === "Signature expired. Please log in again."
            ) {
                const refresh_token = localStorage.getItem("refresh_token");
                http.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${refresh_token}`;
                http
                    .get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres) => {
                    localStorage.setItem("token", refres.data.token);
                    setGlobalToken();
                    http
                    .get(`${BASE_API_URL}/codingTrack/v2/getCodingQuestion?master_question_id=${master_question_id}`)
                    .then((res) => {
                        console.log("data= ", res.data);
                        if (res.data.status === 200) {
                        dispatch(getCodingQuestionA(res.data.data, false));
                        // return res.data;
                        } 
                        else if (res.data.status === 500) {
                            dispatch(getCodingQuestionA(null, true));
                        }
                        else if (
                            res.data.message === "Signature expired. Please log in again."
                        ) {
                        dispatch(getCodingQuestionA(null, true));
                        // return res;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            })
            .catch((err) => {
            console.log(err);
            dispatch(getCodingQuestionA(null, true));
            });
    };
};

export const submitCodingQuestionAC = (track_id, question_id, submission_type, time_spent, source_code, input_data, compiler_id, problem_id) => {
    console.log("in action creator");
    setGlobalToken();
    const form = new FormData();
    form.append("track_id", track_id);
    form.append("question_id", question_id);
    form.append("submission_type", submission_type);
    form.append("time_spent", time_spent);
    form.append("source_code", source_code);
    if (input_data !== '') {
        form.append("input_data", input_data);
    }
    form.append("compiler_id", compiler_id);
    form.append("problem_id", problem_id);

    return async (dispatch, getState) => {
        http
            .post(`${BASE_API_URL}/codingTrack/submitCodingQuestion`, form)
            .then((res) => {
            console.log("res");
            console.log(res);
            if (res.data.status === 200) {
                dispatch(submitCodingQuestionA(res.data.data, false));
            } 
            else if (res.data.status === 500) {
                dispatch(submitCodingQuestionA(null, true));
            }
            else if (
                res.data.message === "Signature expired. Please log in again."
            ) {
                const refresh_token = localStorage.getItem("refresh_token");
                http.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${refresh_token}`;
                http
                    .get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres) => {
                    localStorage.setItem("token", refres.data.token);
                    setGlobalToken();
                    http
                    .post(`${BASE_API_URL}/codingTrack/submitCodingQuestion`, form)
                    .then((res) => {
                        console.log("data= ", res.data);
                        if (res.data.status === 200) {
                            dispatch(submitCodingQuestionA(res.data.data, false));
                        // return res.data;
                        } else if (
                            res.data.message === "Signature expired. Please log in again."
                        ) {
                            dispatch(submitCodingQuestionA(null, true));
                        // return res;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            })
            .catch((err) => {
            console.log(err);
                dispatch(submitCodingQuestionA(null, true));
            });
    };
};

export const getCodingQuestionSubmissionHistoryAC = (master_question_id) => {
    console.log("in action creator");
    setGlobalToken();
    return async (dispatch, getState) => {
        http
            .get(`${BASE_API_URL}/codingTrack/v2/getCodingQuestionSubmissionHistory?master_question_id=${master_question_id}`)
            .then((res) => {
            console.log("res");
            console.log(res);
            if (res.data.status === 200) {
                dispatch(getCodingQuestionSubmissionHistoryA(res.data.data, false));
            } else if (
                res.data.message === "Signature expired. Please log in again."
            ) {
                const refresh_token = localStorage.getItem("refresh_token");
                http.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${refresh_token}`;
                http
                    .get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres) => {
                    localStorage.setItem("token", refres.data.token);
                    setGlobalToken();
                    http
                    .get(`${BASE_API_URL}/codingTrack/v2/getCodingQuestionSubmissionHistory?master_question_id=${master_question_id}`)
                    .then((res) => {
                        console.log("data= ", res.data);
                        if (res.data.status === 200) {
                            dispatch(getCodingQuestionSubmissionHistoryA(res.data.data, false));
                        // return res.data;
                        } else if (
                            res.data.message === "Signature expired. Please log in again."
                        ) {
                            dispatch(getCodingQuestionSubmissionHistoryA(null, true));
                        // return res;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            })
            .catch((err) => {
            console.log(err);
            dispatch(getCodingQuestionSubmissionHistoryA(null, true));
            });
    };
};

export const unlockSolutionAC = (master_question_id) => {
    console.log("in action creator");
    setGlobalToken();
    return async (dispatch, getState) => {
        dispatch(fetchingData('solution'));
        http
            .get(`${BASE_API_URL}/codingTrack/v2/unlockSolution?master_question_id=${master_question_id}`)
            .then((res) => {
            console.log("res");
            console.log(res);
            if (res.data.status === 200) {
                dispatch(unlockSolutionA("success", res.data.data, false));
            } 
            else if (res.data.status === 409 && res.data.message === "Unlocked maximum allowed solutions! Sorry the solution cannot be unlocked!") {
                dispatch(unlockSolutionA("max reached", [], false));
            }
            else if (
                res.data.message === "Signature expired. Please log in again."
            ) {
                const refresh_token = localStorage.getItem("refresh_token");
                http.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${refresh_token}`;
                http
                    .get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres) => {
                    localStorage.setItem("token", refres.data.token);
                    setGlobalToken();
                    http
                    .get(`${BASE_API_URL}/codingTrack/v2/unlockSolution?master_question_id=${master_question_id}`)
                    .then((res) => {
                        console.log("data= ", res.data);
                        if (res.data.status === 200) {
                            dispatch(unlockSolutionA("success", res.data.data, false));
                        // return res.data;
                        } 
                        else if (res.data.status === 409 && res.data.message === "Unlocked maximum allowed solutions! Sorry the solution cannot be unlocked!") {
                            dispatch(unlockSolutionA("max reached", [], false));
                        }
                        else if (
                            res.data.message === "Signature expired. Please log in again."
                        ) {
                            dispatch(unlockSolutionA("signature expired", null, true));
                        // return res;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            })
            .catch((err) => {
            console.log(err);
            dispatch(getCodingQuestionSubmissionHistoryA(null, true));
            });
    };
};