import React, { Component } from 'react';

class Dialog extends Component {
  state = {
    open: false
  }

  //to close dialog box
  closeDialog = () => {
    try {
      document.body.style.overflow = 'auto';
      this.props.closeDialog();
    } catch (error) {
      console.log(error);
    }
  }

  render() {

    return <>
      <div>
        <div id="myModal" className="modal" style={{ display: this.props.open == true ? 'block' : 'none' }} >
          <div className="modal-content">
            <span className="close" onClick={() => this.closeDialog()} >&times;</span>
            <div>
              {this.props.children}
            </div>
          </div>
        </div>
        <style jsx scoped='true'>{`
                    .modal {
                    // position: fixed; /* Stay in place */
                    // z-index: 5; /* Sit on top */
                    // left: 0;
                    // top: 0;
                    // width: 100%; /* Full width */
                    // height: 100%; /* Full height */
                    // overflow: auto; /* Enable scroll if needed */
                    // background-color: rgb(0,0,0); /* Fallback color */
                    // background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

                    --tw-bg-opacity: 1;
                    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
                    --tw-bg-opacity: 0.5;
                    height: 100%;
                    position: fixed;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    left: 0px;
                    width: 100%;
                    z-index: 10;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  
                  /* Modal Content */
                  .modal-content {
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    margin: auto;
                    margin-top: ${ 'marginTop' in this.props ? this.props.marginTop : 'auto'};
                    width: ${ 'width' in this.props ? this.props.width : '600px'};
                    padding: 30px;
                    background: #ffffff;
                    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.199437);
                    border-radius: 8px;
                    box-sizing: border-box;
                    height: ${ 'height' in this.props ? this.props.height : 'auto'};
                    overflow: auto;
                  }
                  
                  /* The Close Button */
                  .close {
                    color: #aaaaaa;
                    float: right;
                    font-size: 28px;
                    font-weight: bold;
                  }
                  
                  .close:hover,
                  .close:focus {
                    color: #000;
                    text-decoration: none;
                    cursor: pointer;
                  }
                `}</style>
      </div>
    </>
  }
}

export default Dialog;