import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom";
import styled, { keyframes, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import AppLayout from "../../components/Layout/Layout";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import "./dashboard.css";
import Analyticskeletonloader from "./analyticsloadingskeleton"
const SubjectDiv = styled.div`
 border-radius: 8px;
    padding: 3px 12px;
    margin-bottom: 8px;
    text-align: left;
    font-size: 12px;
`;
const Button = styled.div`
//   border-radius: 25px;
  margin: auto;
  text-align: center;
 
//   width: 130px;
//     padding: 6px;
//     color: black;
    margin-top: 8px;
    font-size: 13px;
    font-weight: 600;
    // box-shadow: 0px 0px 2px 0px #7e7777;
`;
function ActivitywallSkeleton(props) {

    return (
        <>
            <div className="col-lg-6">
                <div style={{
                    backgroundColor: 'white', boxShadow: 'lightgreen 1px 1px 4px', padding: '10px', margin: '20px auto'
                    , borderRadius: '8px'
                }}>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
                        <div style={{ margin: 'auto 10px' }}>
                            <Skeleton width={60} height={60} circle={true} />
                        </div>
                        <div style={{ textAlign: 'left', margin: 'auto 0px' }}>
                            <div style={{
                                fontSize: '16px', fontWeight: 600, lineHeight: '23px', color: 'rgb(25, 22, 72)',
                                marginBottom: '0px'
                            }}>
                                <Skeleton width={200} height={20} />
                            </div>
                            <div style={{ fontSize: '10px' }}>
                                <Skeleton width={100} height={10} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ textAlign: 'left', fontSize: '12px' }}><span>
                            <Skeleton width="40%" height={10} />
                        </span></div>
                        <div style={{ textAlign: 'left', margin: '10px 20px' }}>
                            <div style={{ fontSize: '11px', lineHeight: '1.8' }}>
                                <Skeleton count={4} />
                            </div>
                            <Button>
                                <Skeleton width={110} height={30} borderRadius={10} />
                            </Button>


                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div style={{
                    backgroundColor: 'white', boxShadow: 'lightgreen 1px 1px 4px', padding: '10px', margin: '20px auto'
                    , borderRadius: '8px'
                }}>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
                        <div style={{ margin: 'auto 10px' }}>
                            <Skeleton width={60} height={60} circle={true} />
                        </div>
                        <div style={{ textAlign: 'left', margin: 'auto 0px' }}>
                            <div style={{
                                fontSize: '16px', fontWeight: 600, lineHeight: '23px', color: 'rgb(25, 22, 72)',
                                marginBottom: '0px'
                            }}>
                                <Skeleton width={200} height={20} />
                            </div>
                            <div style={{ fontSize: '10px' }}>
                                <Skeleton width={100} height={10} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ textAlign: 'left', fontSize: '12px' }}><span>
                            <Skeleton width="40%" height={10} />
                        </span></div>
                        <div style={{ textAlign: 'left', margin: '10px 20px' }}>
                            <div style={{ fontSize: '11px', lineHeight: '1.8' }}>
                                <Skeleton count={4} />
                            </div>
                            <Button>
                                <Skeleton width={110} height={30} borderRadius={10} />
                            </Button>


                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div style={{
                    backgroundColor: 'white', boxShadow: 'lightgreen 1px 1px 4px', padding: '10px', margin: '20px auto'
                    , borderRadius: '8px'
                }}>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
                        <div style={{ margin: 'auto 10px' }}>
                            <Skeleton width={60} height={60} circle={true} />
                        </div>
                        <div style={{ textAlign: 'left', margin: 'auto 0px' }}>
                            <div style={{
                                fontSize: '16px', fontWeight: 600, lineHeight: '23px', color: 'rgb(25, 22, 72)',
                                marginBottom: '0px'
                            }}>
                                <Skeleton width={200} height={20} />
                            </div>
                            <div style={{ fontSize: '10px' }}>
                                <Skeleton width={100} height={10} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ textAlign: 'left', fontSize: '12px' }}><span>
                            <Skeleton width="40%" height={10} />
                        </span></div>
                        <div style={{ textAlign: 'left', margin: '10px 20px' }}>
                            <div style={{ fontSize: '11px', lineHeight: '1.8' }}>
                                <Skeleton count={4} />
                            </div>
                            <Button>
                                <Skeleton width={110} height={30} borderRadius={10} />
                            </Button>


                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div style={{
                    backgroundColor: 'white', boxShadow: 'lightgreen 1px 1px 4px', padding: '10px', margin: '20px auto'
                    , borderRadius: '8px'
                }}>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
                        <div style={{ margin: 'auto 10px' }}>
                            <Skeleton width={60} height={60} circle={true} />
                        </div>
                        <div style={{ textAlign: 'left', margin: 'auto 0px' }}>
                            <div style={{
                                fontSize: '16px', fontWeight: 600, lineHeight: '23px', color: 'rgb(25, 22, 72)',
                                marginBottom: '0px'
                            }}>
                                <Skeleton width={200} height={20} />
                            </div>
                            <div style={{ fontSize: '10px' }}>
                                <Skeleton width={100} height={10} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ textAlign: 'left', fontSize: '12px' }}><span>
                            <Skeleton width="40%" height={10} />
                        </span></div>
                        <div style={{ textAlign: 'left', margin: '10px 20px' }}>
                            <div style={{ fontSize: '11px', lineHeight: '1.8' }}>
                                <Skeleton count={4} />
                            </div>
                            <Button>
                                <Skeleton width={110} height={30} borderRadius={10} />
                            </Button>


                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}


export default ActivitywallSkeleton;
