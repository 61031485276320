import { ADDCERTIFICATE, ADDEDUCATION, ADDEXPERIENCE, ADDLANGUAGE, ADDPROJECT, ADDSKILLS, ADDVIDEORESUME, GETCLASSMATESLIST, GETPROFILEDATA, LISTLANGUAGES, LISTSKILLS, REMOVECERTIFICATE, REMOVEEDUCATION, REMOVEEXPERIENCE, REMOVELANGUAGE, REMOVEPROJECT, REMOVESKILLS, UPDATECERTIFICATE, UPDATEEDUCATION, UPDATEEMAIL, UPDATEEXPERIENCE, UPDATELANGUAGE, UPDATEMOBILE, UPDATEPROFILE, UPDATEPROFILEPIC, UPDATEPROJECT, UPDATESKILLS, UPDATESUMMARY } from "../actions/profileActions";

const initial_state = {
    loading: true,
    loading_languages: true,
    loading_skills: true,
    loading_classmates: true
};

const reducer = (state=initial_state, action) => {
    if (action.type == GETPROFILEDATA) {
        if (action.error) {
            return {
                ...state,
                loading: false,
                error_loading_data: true
            }
        }
        console.log(action);
        let data = action.res.student_profile;
        console.log(data);
        let skills = data.skills;
        let working_skills = '';
        let learning_skills = '';
        let advance_skills = '';
        let other_skills = '';

        skills.forEach((skill)=> {
            if (skill.level == 'Working') {
                working_skills = working_skills ? working_skills + ' | ' + skill.name : working_skills + skill.name;
            } else if (skill.level == 'Advanced') {
                advance_skills = advance_skills ? advance_skills + ' | ' + skill.name : advance_skills + skill.name;
            } else if (skill.level == 'Learning') {
                learning_skills = learning_skills ? learning_skills + ' | ' + skill.name : learning_skills + skill.name;
            } else {
                other_skills = other_skills + skill.name + ' | '
            }
        })
        return {
            ...state,
            name: data.name,
            certificate: data.certificate,
            college: data.college,
            college_university_degree_department_id: data.college_university_degree_department_id,
            course_id: data.course_id,
            course_title: data.course_title,
            department_details: data.department_details,
            education: data.education,
            email: data.email,
            email_verified: data.email_verified,
            experience: data.experience,
            gender: data.gender,
            id: data.id,
            is_ambassador: data.is_ambassador,
            languages: data.languages,
            mobile_number: data.mobile_number,
            mobile_verified: data.mobile_verified,
            profile_pic: data.profile_pic,
            projects: data.project,
            roll_number: data.roll_number,
            section_details: data.section_details,
            semester_id: data.semester_id,
            skills: data.skills,
            splash: data.splash,
            summary: data.summary,
            university_degree_department_id: data.university_degree_department_id,
            video_resume: data.video_resume.resume_video,
            video_resume_thumb: data.video_resume.video_thumb,
            error_loading_data: false,
            loading: false,
            working_skills: working_skills,
            advance_skills: advance_skills,
            learning_skills: learning_skills,
            other_skills: other_skills,
            nameGenderUpdate:false,
            update_mobile_number_message:'',
            update_email_message: ''
        }
    }
    if (action.type === UPDATEPROFILE) {
        if (action.error) {
            return {
                ...state,
                loading: false,
                error_updating_basic_profile_data: true
            }
        } else {
            return {
                ...state,
                name: action.name,
                gender: action.gender,
                nameGenderUpdate: true
            }
        }
    }
    if (action.type === UPDATEPROFILEPIC) {
        if (action.error) {
            return {
                ...state,
                loading: false,
                error_updating_profile_pic: true
            }
        } else {
            return {
                ...state,
                profile_pic : action.res.profile_pic
            }
        }
    }
    if (action.type === UPDATEMOBILE) {
        if (action.error) {
            return {
                ...state,
                loading: false,
                error_updating_mobile_number: true,
                update_mobile_number_message: action.res.message
            }
        } else {
            return {
                ...state,
                mobile_number: action.mobile,
                update_mobile_number_message: ''
            }
        }
    }
    if (action.type === UPDATEEMAIL) {
        if (action.error) {
            return {
                ...state,
                loading: false,
                error_updating_email: true,
            }
        } else {
            return {
                ...state,
                email: action.email,
                update_email_message: action.res.message
            }
        }
    }
    if (action.type == UPDATESUMMARY) {
        if (action.error) {
            return {
                ...state,
                loading: false,
                error_updating_summary: true
            }
        } else {
            return {
                ...state,
                summary: action.summary,
                summary_updated_successfully: true
            }
        }
    }
    if (action.type == 'CLEAR_UPDATE_SUMMARY_STATUS') {
        return {
            ...state,
            summary_updated_successfully: null,
            error_updating_summary: null
        }
    }
    if (action.type === ADDVIDEORESUME) {
        if (action.error) {
            return {
                ...state,
                loading: false,
                error_updating_video: true
            }
        } else {
            console.log(action);
            return {
                ...state,
                // video_resume: action.video,
                video_updated_successfully: true
            }
        }
    }
    if (action.type == 'CLEAR_UPDATE_VIDEO_RESUME_STATUS') {
        return {
            ...state,
            video_updated_successfully: null,
            error_updating_video: null
        }
    }
    if (action.type == LISTLANGUAGES) {
        if (action.error) {
            return {
                ...state,
                error_loading_list_of_languages: true,
                loading_languages: false
            }
        } else {
            console.log(action)
            return {
                ...state,
                languages_list: action.res.data,
                loading_languages: false
            }
        }
    }
    if (action.type == LISTSKILLS) {
        if (action.error) {
            return {
                ...state,
                error_loading_list_of_skills: true,
                loading_skills: false
            }
        } else {
            console.log(action)
            return {
                ...state,
                skills_list: action.res.data,
                loading_skills: false
            }
        }
    }
    if (action.type == ADDLANGUAGE) {
        if (action.error) {
            return {
                ...state,
                error_adding_language: true
            }
        } else {
            let languages = state.languages.map((lang)=> {
                return {...lang}
            });
            languages.push(action.item);
            return {
                ...state,
                languages: languages,
                added_language_successfully: true
            }


        }
    }
    if (action.type == 'CLEAR_ADD_LANGUAGE_STATUS') {
        return {
            ...state,
            error_adding_language: null,
            added_language_successfully: null
        }
    }
    
    if (action.type == UPDATELANGUAGE) {
        if (action.error) {
            return {
                ...state,
                error_updating_language: true
            }
        } else {
            let languages = state.languages.map((lang)=> {
                return {...lang}
            });
            let index = languages.findIndex((lang)=> {
                return lang.id == action.item.id
            });
            languages[index].read = action.item.read;
            languages[index].write = action.item.write;
            languages[index].listening = action.item.listening;
            languages[index].speaking = action.item.speaking;
            return {
                ...state,
                languages: languages,
                updated_language_successfully: true
            }
        }
    }
    if (action.type == REMOVELANGUAGE) {
        if (action.error) {
            return {
                ...state,
                error_deleting_language: true
            }
        } else {
            let languages = state.languages.map((lang)=> {
                return {...lang}
            });
            let index = languages.findIndex((lang)=> {
                return lang.id == action.item.id
            });
            languages.splice(index, 1);
            return {
                ...state,
                languages: languages,
                deleted_language_successfully: true
            }
        }
    }
    if (action.type == 'CLEAR_UPDATE_LANGUAGE_STATUS') {
        return {
            ...state,
            error_deleting_language: null,
            deleted_language_successfully: null,
            updated_language_successfully: null,
            error_updating_language: null
        }
    }

    if (action.type == UPDATESKILLS) {
        if (action.error) {
            return {
                ...state,
                error_updating_skills: true
            }
        } else {
            let skills = action.item.skills.map((skill)=> {
                return {...skill}
            });

            let working_skills = '';
            let learning_skills = '';
            let advance_skills = '';
            let other_skills = '';

            skills.forEach((skill)=> {
                if (skill.level == 'Working') {
                    working_skills = working_skills ? working_skills + ' | ' + skill.name : working_skills + skill.name;
                } else if (skill.level == 'Advanced') {
                    advance_skills = advance_skills ? advance_skills + ' | ' + skill.name : advance_skills + skill.name;
                } else if (skill.level == 'Learning') {
                    learning_skills = learning_skills ? learning_skills + ' | ' + skill.name : learning_skills + skill.name;
                } else {
                    other_skills = other_skills + skill.name + ' | '
                }
            })
            
            return {
                ...state,
                skills: skills,
                updated_skills_successfully: true,
                working_skills: working_skills,
                advance_skills: advance_skills,
                learning_skills: learning_skills,
                other_skills: other_skills
            }
        }
    }
    if (action.type == REMOVESKILLS) {
        if (action.error) {
            return {
                ...state,
                error_deleting_skills: true
            }
        } else {
            let skills = state.skills.map((skill)=> {
                return {...skill}
            });
            let index = skills.findIndex((skill)=> {
                return skill.id == action.item.id
            });
            skills.splice(index, 1);
            return {
                ...state,
                skills: skills,
                deleted_skills_successfully: true
            }
        }
    }
    if (action.type == 'CLEAR_UPDATE_SKILLS_STATUS') {
        return {
            ...state,
            error_deleting_skills: null,
            deleted_skills_successfully: null,
            updated_skills_successfully: null,
            error_updating_skills: null
        }
    }

    if (action.type == ADDSKILLS) {
        if (action.error) {
            return {
                ...state,
                error_adding_skills: true
            }
        } else {
            let skills = state.skills.map((skill)=> {
                return {...skill}
            });
            skills.concat(action.item.skills);

            let working_skills = '';
            let learning_skills = '';
            let advance_skills = '';
            let other_skills = '';

            skills.forEach((skill)=> {
                if (skill.level == 'Working') {
                    working_skills = working_skills ? working_skills + ' | ' + skill.name : working_skills + skill.name;
                } else if (skill.level == 'Advanced') {
                    advance_skills = advance_skills ? advance_skills + ' | ' + skill.name : advance_skills + skill.name;
                } else if (skill.level == 'Learning') {
                    learning_skills = learning_skills ? learning_skills + ' | ' + skill.name : learning_skills + skill.name;
                } else {
                    other_skills = other_skills + skill.name + ' | '
                }
            })
            return {
                ...state,
                skills: skills,
                added_skills_successfully: true,
                working_skills: working_skills,
                advance_skills: advance_skills,
                learning_skills: learning_skills,
                other_skills: other_skills
            }
        }
    }
    if (action.type == 'CLEAR_ADD_SKILLS_STATUS') {
        return {
            ...state,
            error_adding_skills: null,
            added_skills_successfully: null,
            error_loading_list_of_skills: null,
            loading_skills: true
        }
    }

    if (action.type == ADDEDUCATION) {
        if (action.error) {
            return {
                ...state,
                error_adding_education: true
            }
        } else {
            let education = state.education.map((edu)=> {
                return {...edu}
            });
            education.push(action.item)
            return {
                ...state,
                // education: education,
                added_education_successfully: true,
                
            }
        }
    }
    if (action.type == 'CLEAR_ADD_EDUCATION_STATUS') {
        return {
            ...state,
            error_adding_education: null,
            added_education_successfully: null
        }
    }

    if (action.type == ADDEXPERIENCE) {
        if (action.error) {
            return {
                ...state,
                error_adding_experience: true
            }
        } else {
            // let experience = state.experience.map((edu)=> {
            //     return {...edu}
            // });
            // experience.push(action.item)
            return {
                ...state,
                // experience: experience,
                added_experience_successfully: true,
                
            }
        }
    }
    if (action.type == 'CLEAR_ADD_EXPERIENCE_STATUS') {
        return {
            ...state,
            error_adding_experience: null,
            added_experience_successfully: null
        }
    }

    if (action.type == ADDCERTIFICATE) {
        if (action.error) {
            return {
                ...state,
                error_adding_certificate: true
            }
        } else {
            // let certificate = state.certificate.map((edu)=> {
            //     return {...edu}
            // });
            // certificate.push(action.item)
            return {
                ...state,
                // certificate: certificate,
                added_certificate_successfully: true,
                
            }
        }
    }
    if (action.type == 'CLEAR_ADD_CERTIFICATE_STATUS') {
        return {
            ...state,
            error_adding_certificate: null,
            added_certificate_successfully: null
        }
    }

    if (action.type == ADDPROJECT) {
        if (action.error) {
            return {
                ...state,
                error_adding_project: true
            }
        } else {
            // let projects = state.projects.map((edu)=> {
            //     return {...edu}
            // });
            // projects.push(action.item)
            return {
                ...state,
                // projects: projects,
                added_project_successfully: true,
                
            }
        }
    }
    if (action.type == 'CLEAR_ADD_PROJECT_STATUS') {
        return {
            ...state,
            error_adding_project: null,
            added_project_successfully: null
        }
    }

    if (action.type == GETCLASSMATESLIST) {
        if(action.error) {
            return {
                ...state,
                error_loading_classmates: true,
                loading_classmates: false
            } 
            
        } else {
            return {
                ...state,
                classmates: action.res.data,
                loading_classmates: false
            }
        }
    }
    
    if (action.type == REMOVEEDUCATION) {
        if (action.error) {
            return {
                ...state,
                error_deleting_education: true,

            }
        } else {
            return {
                ...state, 
                deleted_education_successfully: true,
                error_deleting_education: false
            }
        }
    }
    if (action.type == UPDATEEDUCATION) {
        if (action.error) {
            return {
                ...state,
                error_updating_education: true,
            }
        } else {
            return {
                ...state, 
                updated_education_successfully: true,
                error_updating_education: false
            }
        }
    }

    if (action.type == 'CLEAR_UPDATE_EDUCATION_STATUS') {
        return {
            ...state,
            deleted_education_successfully: null,
            error_deleting_education: null,
            updated_education_successfully: null,
            error_updating_education: null
        }
    }
    
    if (action.type == REMOVEEXPERIENCE) {
        if (action.error) {
            return {
                ...state,
                error_deleting_experience: true,

            }
        } else {
            return {
                ...state, 
                deleted_experience_successfully: true,
                error_deleting_experience: false
            }
        }
    }
    if (action.type == UPDATEEXPERIENCE) {
        if (action.error) {
            return {
                ...state,
                error_updating_experience: true,
            }
        } else {
            return {
                ...state, 
                updated_experience_successfully: true,
                error_updating_experience: false
            }
        }
    }

    if (action.type == 'CLEAR_UPDATE_EXPERIENCE_STATUS') {
        return {
            ...state,
            deleted_experience_successfully: null,
            error_deleting_experience: null,
            updated_experience_successfully: null,
            error_updating_experience: null
        }
    }

    if (action.type == REMOVECERTIFICATE) {
        if (action.error) {
            return {
                ...state,
                error_deleting_certificate: true,

            }
        } else {
            return {
                ...state, 
                deleted_certificate_successfully: true,
                error_deleting_certificate: false
            }
        }
    }
    if (action.type == UPDATECERTIFICATE) {
        if (action.error) {
            return {
                ...state,
                error_updating_certificate: true,
            }
        } else {
            return {
                ...state, 
                updated_certificate_successfully: true,
                error_updating_certificate: false
            }
        }
    }

    if (action.type == 'CLEAR_UPDATE_CERTIFICATE_STATUS') {
        return {
            ...state,
            deleted_certificate_successfully: null,
            error_deleting_certificate: null,
            updated_certificate_successfully: null,
            error_updating_certificate: null
        }
    }
    if (action.type == REMOVEPROJECT) {
        if (action.error) {
            return {
                ...state,
                error_deleting_project: true,

            }
        } else {
            return {
                ...state, 
                deleted_project_successfully: true,
                error_deleting_project: false
            }
        }
    }
    if (action.type == UPDATEPROJECT) {
        if (action.error) {
            return {
                ...state,
                error_updating_project: true,
            }
        } else {
            return {
                ...state, 
                updated_project_successfully: true,
                error_updating_project: false
            }
        }
    }

    if (action.type == 'CLEAR_UPDATE_PROJECT_STATUS') {
        return {
            ...state,
            deleted_project_successfully: null,
            error_deleting_project: null,
            updated_project_successfully: null,
            error_updating_project: null
        }
    }

    return {
        ...state
    }
}

export default reducer;