import moment from "moment";
import { ONTESTSUBMIT, TESTSUBMITLOAD } from "../actions/questionnaireActions";

const initial_state = {
  loading_test: true,
  testSubmitted: false,
  feedbackSubmitted: false,
  testSubmitting: false,
  openFeedback: false,
  close_test: false,
};

const reducer = (state = initial_state, action) => {
  // console.log(action);
  if (action.type === "GET_TEST") {
    if (action.error) {
      return {
        ...state,
        error_loading_test: true,
        loading_test: false,
        attempted: action.attempted,
      };
    }
    console.log(action.response);
    let answers = action.response.questions.map((que) => ({
      question_id: que.id,
      question_option_id: null,
      timetaken: {
        start: null,
        end: null,
        duration: null
      },
      screenshot: 0,
      isBookMarked: false,
      action_type: 0,
      question_section_id: que.section_id,
      question_section_marks: action.response.sections.find(
        (sec) => que.section_id === sec.id
      )?.marks,
    }));
    let answered = 0;
    let skipped = [];
    if(action.response.resume_test){
      action.response.questions.forEach((que,i) => {
        if(que.test_questions_options.some(op => op.selected_option)){
          answers[i].question_option_id = que.test_questions_options.find(op => op.selected_option)?.id
          answered++;
        }
        else skipped.push(i+1);
      })
    }

    // let datetime = moment().format("YYYY-MM-DD HH:mm:ss").toString();
    // answers[0]["started_time"] = moment();
    // answers[0].timetaken.start = datetime;
    console.log(answers);
    let questions_by_10 = Math.ceil(action.response.questions.length / 10);
    let question_numbers = [];
    for (var i = 0; i < questions_by_10; i++) {
      question_numbers.push(i);
    }
    let date = new Date();
    let difference = moment.duration(
      moment(action.response.date_of_expiry).diff(moment(action.response.currentTime))
    );
    console.log(difference)
    let difference2 = parseInt(difference.asSeconds());
    console.log(difference2)
    let remainingtime;
    if (action.response.remaining_test_duration > difference2) {
      remainingtime=difference2
    }else{
      remainingtime=action.response.remaining_test_duration
    }
    console.log(remainingtime)
    return {
      ...state,
      ...action.response,
      qid: 0,
      prev_qid: 0,
      answers: answers,
      error_loading_test: false,
      loading_test: false,
      remaining_questions: action.response.questions.length - answered,
      doe: action.response.date_of_expiry,
      answered: answered,
      skipped: skipped,
      marked: [],
      question_numbers: question_numbers,
      currentQuestionNumberIndex: 0,
      testStarted: false,
      testSubmitted: false,
      collect_feedback: action.response.collect_feedback,
      attempted: false,
      timeduration: remainingtime,
    };
  } else if (action.type === "TEST_START_FROM_INSTRUCTIONS") {
    const answers = state.answers;
    const answer = answers[0];
    answer["started_time"] = moment();
    const datetime = moment().format("YYYY-MM-DD HH:mm:ss").toString();
    answer.timetaken.start = datetime;
    answers[0] = answer;
    return {
      ...state,
      answers: answers,
      testStarted: true,
    };
  } else if (action.type === "ON_TEST_QUESTION_CLICK") {
    let answers = state.answers;
    let qid = state.qid;
    let answer = answers[qid];
    let action_qid = action.qid;
    if(action_qid > answers.length - 1) action_qid = answers.length - 1;
    let skipped = [...state.skipped];
    let marked = [...state.marked];
    // console.log("answer");
    // console.log(JSON.stringify(answer));
    // answer.question_option_id = action.question_option_id ? action.question_option_id : null;
    answer.action_type = action.action_type;
    let datetime = moment().format("YYYY-MM-DD HH:mm:ss").toString();
    let timetaken = answer.timetaken;
    timetaken.end = datetime;
    // console.log(timetaken);
    let duration = moment().diff(answer.started_time, "seconds");
    timetaken.duration = duration;
    // console.log(timetaken);
    answer.timetaken = timetaken;
    answers[qid] = answer;

    const skipped_index_qid_next = skipped.indexOf(action_qid + 1);
    const marked_index_qid_next = marked.indexOf(action_qid + 1);
    let qid_next = action_qid;
    let openSkippedDialog = action.skipped;
    let openMarkedDialog = action.marked;
    
    console.log("qid " + qid);
    console.log("action_qid " + action_qid);
    console.log("skipped_index_qid_next " + skipped_index_qid_next);
    console.log("marked_index_qid_next " + marked_index_qid_next);
    if (action.action_type !== 2) {
      if(action.skipped && skipped.length > 0)
        qid_next = skipped.length > skipped_index_qid_next ? skipped[skipped_index_qid_next] - 1 : skipped[0] - 1;
      if(action.marked && marked.length > 0){
        qid_next = marked.length > marked_index_qid_next ? marked[marked_index_qid_next] - 1 : marked[0] - 1;
      }

      console.log("qid_next " + qid_next);
      answers[qid_next].started_time = moment();
      answers[qid_next].timetaken.start = datetime;
      
    }
    // console.log(answers);
    // if((answer.question_option_id || action.action_type === 1))
    //   action_qid = qid_next;

    if (!answer.question_option_id && skipped.indexOf(qid + 1) === -1) {
      skipped.push(qid + 1);
    } else if (answer.question_option_id && skipped.indexOf(qid + 1) !== -1){
      skipped.splice(skipped.indexOf(qid + 1),1);
    }
      
    if (action.doubt && marked.indexOf(qid + 1) === -1) {
      marked.push(qid + 1);
    }
    console.log(marked)
    if(!action.doubt && answer.question_option_id && marked.indexOf(qid + 1) !== -1 && action.action_type !== 3) {
      marked.splice(marked.indexOf(qid + 1), 1);
    }
    if(action.skipped && skipped.length === 0)
      openSkippedDialog = false;
    if(action.marked && marked.length === 0)
      openMarkedDialog = false;

    let currentQuestionMarkedNumberIndex =
      state.currentQuestionMarkedNumberIndex;
    let currentQuestionSkippedNumberIndex =
      state.currentQuestionSkippedNumberIndex;
    let question_marked_numbers = [];
    let question_skipped_numbers = [];
    if (state.openMarkedDialog) {
      currentQuestionMarkedNumberIndex = Math.floor(
        marked.indexOf(action_qid + 1) / 10
      );
      let questions_by_10 = Math.ceil(marked.length / 10);
      for (let i = 0; i < questions_by_10; i++) {
        question_marked_numbers.push(i);
      }
    }
    if (state.openSkippedDialog) {
      currentQuestionSkippedNumberIndex = Math.floor(
        skipped.indexOf(action_qid + 1) / 10
      );
      let questions_by_10 = Math.ceil(skipped.length / 10);
      for (let i = 0; i < questions_by_10; i++) {
        question_skipped_numbers.push(i);
      }
    }
    // if (!answer.question_option_id) {
    //   answered = answered + 1;
    //   remaining_questions = remaining_questions - 1;
    // }
    if(!answers[action_qid]) action_qid = qid;
    return {
      ...state,
      qid: action_qid,
      prev_qid: qid,
      answers: answers,
      skipped: skipped,
      marked: marked,
      currentQuestionNumberIndex: Math.floor(action_qid / 10),
      currentQuestionMarkedNumberIndex: currentQuestionMarkedNumberIndex,
      currentQuestionSkippedNumberIndex: currentQuestionSkippedNumberIndex,
      question_marked_numbers: question_marked_numbers,
      question_skipped_numbers: question_skipped_numbers,
      openSkippedDialog: openSkippedDialog,
      openMarkedDialog: openMarkedDialog
    };
  } else if (action.type == "ON_CURRENT_QUESTION_NUMBER_INDEX_CHANGED") {
    console.log("inside question click");
    let answers = state.answers;
    let answer = answers[state.qid];
    let qid = state.qid;
    // answer.question_option_id = action.question_option_id ? action.question_option_id : null;
    let datetime = moment().format("YYYY-MM-DD HH:mm:ss").toString();
    let timetaken = answer.timetaken;
    timetaken.end = datetime;
    let duration = moment().diff(answer.started_time, "seconds");
    timetaken.duration = duration;
    answer.timetaken = timetaken;
    answers[qid] = answer;
    let currentQuestionNumberIndex = action.index;
    let qid_next = currentQuestionNumberIndex * 10 + 0;

    answers[qid_next].started_time = moment();
    answers[qid_next].timetaken.start = datetime;
    console.log(answers);
    let skipped = [...state.skipped];

    if (!answer.question_option_id) {
      if (skipped.indexOf(qid + 1) == -1) {
        skipped.push(qid + 1);
      }
    } else {
      if (skipped.indexOf(qid + 1) != -1) {
        skipped.splice(skipped.indexOf(qid + 1));
      }
    }
    return {
      ...state,
      qid: currentQuestionNumberIndex * 10 + 0,
      answers: answers,
      skipped: skipped,
      currentQuestionNumberIndex: currentQuestionNumberIndex,
    };
  } else if (action.type == "ON_CURRENT_QUESTION_MARKED_NUMBER_INDEX_CHANGED") {
    console.log("inside question click");
    let answers = state.answers;
    let answer = answers[state.qid];
    let qid = state.qid;
    // answer.question_option_id = action.question_option_id ? action.question_option_id : null;
    let datetime = moment().format("YYYY-MM-DD HH:mm:ss").toString();
    let timetaken = answer.timetaken;
    timetaken.end = datetime;
    let duration = moment().diff(answer.started_time, "seconds");
    timetaken.duration = duration;
    answer.timetaken = timetaken;
    answers[qid] = answer;
    let currentQuestionMarkedNumberIndex = action.index;
    let qid_next = state.marked[currentQuestionMarkedNumberIndex * 10 + 0] - 1;

    answers[qid_next].started_time = moment();
    answers[qid_next].timetaken.start = datetime;
    console.log(answers);
    let skipped = [...state.skipped];
    // let question_id = state.questions[qid].id;
    if (!answer.question_option_id) {
      if (skipped.indexOf(qid + 1) == -1) {
        skipped.push(qid + 1);
      }
    } else {
      if (skipped.indexOf(qid + 1) != -1) {
        skipped.splice(skipped.indexOf(qid + 1));
      }
    }
    return {
      ...state,
      qid: qid_next,
      answers: answers,
      skipped: skipped,
      currentQuestionMarkedNumberIndex: currentQuestionMarkedNumberIndex,
    };
  } else if (
    action.type == "ON_CURRENT_QUESTION_SKIPPED_NUMBER_INDEX_CHANGED"
  ) {
    console.log("inside question click");
    let answers = state.answers;
    let answer = answers[state.qid];
    let qid = state.qid;
    // answer.question_option_id = action.question_option_id ? action.question_option_id : null;
    let datetime = moment().format("YYYY-MM-DD HH:mm:ss").toString();
    let timetaken = answer.timetaken;
    timetaken.end = datetime;
    let duration = moment().diff(answer.started_time, "seconds");
    timetaken.duration = duration;
    answer.timetaken = timetaken;
    answers[qid] = answer;
    let currentQuestionSkippedNumberIndex = action.index;
    let qid_next =
      state.skipped[currentQuestionSkippedNumberIndex * 10 + 0] - 1;

    answers[qid_next].started_time = moment();
    answers[qid_next].timetaken.start = datetime;
    console.log(answers);
    let skipped = [...state.skipped];

    if (!answer.question_option_id) {
      if (skipped.indexOf(qid + 1) === -1) {
        skipped.push(qid + 1);
      }
    } else {
      if (skipped.indexOf(qid + 1) != -1) {
        skipped.splice(skipped.indexOf(qid + 1));
      }
    }
    return {
      ...state,
      qid: qid_next,
      answers: answers,
      skipped: skipped,
      currentQuestionSkippedNumberIndex: currentQuestionSkippedNumberIndex,
    };
  } else if (action.type == "ON_OPTION_SELECTED") {
    console.log("in on option selected");
    let answers = state.answers.map((answer) => {
      return { ...answer };
    });
    // let questions = state.questions;
    // let options = questions[action.qid].test_questions_options;
    // let is_answer = 0;
    let qid = state.qid;
    // options.forEach((option) => {
    //   if (option.id == action.option_id) {
    //     is_answer = option.is_answer;
    //   }
    // });
    let answer = { ...answers[action.qid] };
    let prev_option_id = answer.question_option_id;
    answer.question_option_id = action.option_id ? action.option_id : null;
    let remaining_questions = state.remaining_questions;
    let answered = state.answered;
    if (!prev_option_id) {
      answered = answered + 1;
      remaining_questions = remaining_questions - 1;
    }

    // let marked = [...state.marked];
    // let question_marked_numbers = [];
    // let question_skipped_numbers = [];
    // let openMarkedDialog = state.openMarkedDialog;
    // let openSkippedDialog = state.openSkippedDialog;
    // let currentQuestionSkippedNumberIndex = state.currentQuestionSkippedNumberIndex;

    // if (marked.indexOf(qid + 1) != -1) {
    //   marked.splice(marked.indexOf(qid + 1), 1);
    // }
    
    // let currentQuestionMarkedNumberIndex =
    //   state.currentQuestionMarkedNumberIndex;
    // if (state.openMarkedDialog) {
    //   currentQuestionMarkedNumberIndex = Math.floor(
    //     marked.indexOf(qid + 1) / 10
    //   );
    // }

    // if (!answer.question_option_id) {
    //   if (skipped.indexOf(qid + 1) == -1) {
    //     skipped.push(qid + 1);
    //   }
    // } else {
    //   if (skipped.indexOf(qid + 1) != -1) {
    //     skipped.splice(skipped.indexOf(qid + 1), 1);
    //   }
    // }
    // if (action.marked) {
    //   console.log(marked);
    //   console.log(marked_index_qid_next);
    //   if (marked.length == 0) {
    //     openMarkedDialog = false;
    //   } else if (marked.length > marked_index_qid_next) {
    //     qid_next = marked[marked_index_qid_next] - 1;
    //     answers[qid_next].started_time = moment();
    //     answers[qid_next].timetaken.push([datetime]);
    //   } else {
    //     qid_next = marked[marked_index_qid_next - 1] - 1;
    //     answers[qid_next].started_time = moment();
    //     answers[qid_next].timetaken.push([datetime]);
    //   }
    //   let timetaken = answer.timetaken[answer.timetaken.length - 1];
    //   timetaken.push(datetime);
    //   let duration = moment().diff(answer.started_time, "seconds");
    //   timetaken.push(duration);
    //   answer.timetaken[answer.timetaken.length - 1] = timetaken;
    //   let questions_by_10 = Math.ceil(marked.length / 10);
    //   for (var i = 0; i < questions_by_10; i++) {
    //     question_marked_numbers.push(i);
    //   }
    // }
    // if (state.openSkippedDialog) {
    //   console.log(skipped);
    //   console.log(skipped_index_qid_next);
    //   if (skipped.length == 0) {
    //     openSkippedDialog = false;
    //   } else if (skipped.length > skipped_index_qid_next) {
    //     qid_next = skipped[skipped_index_qid_next] - 1;
    //     answers[qid_next].started_time = moment();
    //     answers[qid_next].timetaken.push([datetime]);
    //   } else {
    //     qid_next = skipped[skipped_index_qid_next - 1] - 1;
    //     answers[qid_next].started_time = moment();
    //     answers[qid_next].timetaken.push([datetime]);
    //   }
    //   currentQuestionSkippedNumberIndex = Math.floor(
    //     skipped.indexOf(qid_next + 1) / 10
    //   );
    //   let timetaken = answer.timetaken[answer.timetaken.length - 1];
    //   timetaken.push(datetime);
    //   let duration = moment().diff(answer.started_time, "seconds");
    //   timetaken.push(duration);
    //   answer.timetaken[answer.timetaken.length - 1] = timetaken;
    //   let questions_by_10 = Math.ceil(skipped.length / 10);
    //   for (var i = 0; i < questions_by_10; i++) {
    //     question_skipped_numbers.push(i);
    //   }
    // }
    answers[action.qid] = answer;
    return {
      ...state,
      answers: answers,
      remaining_questions: remaining_questions,
      answered: answered,
      currentQuestionNumberIndex: Math.floor(qid / 10),
    };
  } else if (action.type == "ON_MARKED_AS_DOUBT") {
    let answers = state.answers;
    let answer = answers[state.qid];
    let qid = state.qid;
    // answer.question_option_id = action.question_option_id ? action.question_option_id : null;
    let datetime = moment().format("YYYY-MM-DD HH:mm:ss").toString();
    let timetaken = answer.timetaken;
    timetaken.end = datetime;
    let duration = moment().diff(answer.started_time, "seconds");
    timetaken.duration = duration;
    answer.timetaken = timetaken;
    answer.action_type = action.action_type;
    answers[qid] = answer;

    console.log(answers);
    // let question_id = state.questions[qid].id;
    let marked = [...state.marked];
    let openMarkedDialog = state.openMarkedDialog;
    let marked_index_qid_next = marked.indexOf(qid + 1);
    let currentQuestionMarkedNumberIndex =
      state.currentQuestionMarkedNumberIndex;

    if (marked.indexOf(qid + 1) == -1) {
      marked.push(qid + 1);
    }
    let skipped = [...state.skipped];
    let skipped_index_qid_next = skipped.indexOf(qid + 1);
    let openSkippedDialog = state.openSkippedDialog;
    let currentQuestionSkippedNumberIndex =
      state.currentQuestionSkippedNumberIndex;
    if (skipped.indexOf(qid + 1) != -1) {
      skipped.splice(skipped.indexOf(qid + 1), 1);
    }

    console.log(qid);
    let qid_next = qid;
    let question_marked_numbers = [];
    let question_skipped_numbers = [];
    if (action.skipped) {
      if (skipped.length == 0) {
        openSkippedDialog = false;
      } else if (skipped.length > skipped_index_qid_next) {
        qid_next = skipped[skipped_index_qid_next] - 1;
        answers[qid_next].started_time = moment();
        answers[qid_next].timetaken.start = datetime;
      } else {
        qid_next = skipped[skipped_index_qid_next - 1] - 1;
        answers[qid_next].started_time = moment();
        answers[qid_next].timetaken.start = datetime;
      }
      currentQuestionSkippedNumberIndex = Math.floor(
        skipped.indexOf(qid_next + 1) / 10
      );
      let questions_by_10 = Math.ceil(skipped.length / 10);
      for (let i = 0; i < questions_by_10; i++) {
        question_skipped_numbers.push(i);
      }
    } else if (action.marked) {
      if (marked.length == 0) {
        openMarkedDialog = false;
      } else if (marked.length > marked_index_qid_next) {
        qid_next = marked[marked_index_qid_next] - 1;
        answers[qid_next].started_time = moment();
        if (typeof answers[qid_next].timetaken === "string")
          answers[qid_next].timetaken = JSON.parse(answers[qid_next].timetaken);
        answers[qid_next].timetaken.push([datetime]);
      } else {
        qid_next = marked[marked_index_qid_next - 1] - 1;
        answers[qid_next].started_time = moment();
        if (typeof answers[qid_next].timetaken === "string")
          answers[qid_next].timetaken = JSON.parse(answers[qid_next].timetaken);
        answers[qid_next].timetaken.push([datetime]);
      }
      currentQuestionMarkedNumberIndex = Math.floor(
        marked.indexOf(qid_next + 1) / 10
      );
      let questions_by_10 = Math.ceil(marked.length / 10);
      for (var j = 0; j < questions_by_10; j++) {
        question_marked_numbers.push(j);
      }
    } else if (qid + 1 === answers.length) {
      qid_next = qid;
    } else {
      qid_next = qid + 1;
      answers[qid_next].started_time = moment();
      answers[qid_next].timetaken.start = datetime;
    }

    return {
      ...state,
      marked: marked,
      answers: answers,
      qid: qid_next,
      currentQuestionNumberIndex: Math.floor(qid_next / 10),
      skipped: skipped,
      openSkippedDialog: openSkippedDialog,
      currentQuestionMarkedNumberIndex: currentQuestionMarkedNumberIndex,
      currentQuestionSkippedNumberIndex: currentQuestionSkippedNumberIndex,
      question_marked_numbers: question_marked_numbers,
      question_skipped_numbers: question_skipped_numbers,
    };
  } else if (action.type == "OPEN_MARKED_DIALOG_ACTION") {
    console.log("inside open marked duakog action");
    let answers = state.answers;
    let answer = answers[state.qid];
    let qid = state.qid;
    // answer.question_option_id = action.question_option_id ? action.question_option_id : null;
    let datetime = moment().format("YYYY-MM-DD HH:mm:ss").toString();
    let timetaken = answer.timetaken;
    timetaken.end = datetime;
    let duration = moment().diff(answer.started_time, "seconds");
    timetaken.duration = duration;
    answer.timetaken = timetaken;
    answer.action_type = 1;
    answers[qid] = answer;

    console.log(answers);
    let skipped = [...state.skipped];
    
    let marked = [...state.marked];
    if (!answer.question_option_id) {
      if (skipped.indexOf(qid + 1) == -1) {
        skipped.push(qid + 1);
      }
    } else {
      if (skipped.indexOf(qid + 1) != -1) {
        skipped.splice(skipped.indexOf(qid + 1));
      }
    }
    let questions_by_10 = Math.ceil(marked.length / 10);
    let question_marked_numbers = [];
    for (var i = 0; i < questions_by_10; i++) {
      question_marked_numbers.push(i);
    }
    answers[marked[0] - 1].started_time = moment();
    answers[marked[0] - 1].timetaken.start = datetime;
    return {
      ...state,
      qid: marked[0] - 1,
      answers: answers,
      skipped: skipped,
      currentQuestionNumberIndex: Math.floor(qid / 10),
      currentQuestionMarkedNumberIndex: 0,
      question_marked_numbers: question_marked_numbers,
      openMarkedDialog: true,
      openSkippedDialog: false,
    };
  } else if (action.type == "CLOSE_MARKED_DIALOG_ACTION") {
    console.log("inside question click");
    console.log("inside open marked duakog action");
    let qid = state.qid;
    // answer.question_option_id = action.question_option_id ? action.question_option_id : null;

    return {
      ...state,
      qid: qid,
      currentQuestionNumberIndex: Math.floor(qid / 10),
      currentQuestionMarkedNumberIndex: 0,
      openMarkedDialog: false,
    };
  } else if (action.type === "OPEN_SKIPPED_DIALOG_ACTION") {
    console.log("inside question click");
    console.log("inside open skipped duakog action");
    let answers = state.answers;
    let qid = state.qid;
    let answer = answers[qid];
    // answer.question_option_id = action.question_option_id ? action.question_option_id : null;
    let datetime = moment().format("YYYY-MM-DD HH:mm:ss").toString();
    let timetaken = answer.timetaken;
    timetaken.end = datetime;
    let duration = moment().diff(answer.started_time, "seconds");
    timetaken.duration = duration;
    answer.timetaken = timetaken;
    answer.action_type = 1;
    answers[qid] = answer;

    console.log(answers);
    let skipped = [...state.skipped];
    
    if (!answer.question_option_id) {
      if (skipped.indexOf(qid + 1) == -1) {
        skipped.push(qid + 1);
      }
    } else {
      if (skipped.indexOf(qid + 1) != -1) {
        skipped.splice(skipped.indexOf(qid + 1),1);
      }
    }
    let questions_by_10 = Math.ceil(skipped.length / 10);
    let question_skipped_numbers = [];
    for (var i = 0; i < questions_by_10; i++) {
      question_skipped_numbers.push(i);
    }
    if(skipped.length > 0){
      answers[skipped[0] - 1].started_time = moment();
      answers[skipped[0] - 1].timetaken.start = datetime;
      qid = skipped[0] - 1;
    }
    return {
      ...state,
      qid: qid,
      answers: answers,
      skipped: skipped,
      currentQuestionNumberIndex: Math.floor(qid / 10),
      currentQuestionMarkedNumberIndex: 0,
      question_skipped_numbers: question_skipped_numbers,
      currentQuestionSkippedNumberIndex: 0,
      openSkippedDialog: true,
      openMarkedDialog: false,
    };
  } else if (action.type == "CLOSE_SKIPPED_DIALOG_ACTION") {
    console.log("inside question click");
    console.log("inside open marked duakog action");
    let answers = state.answers;
    let answer = answers[state.qid];
    let qid = state.qid;
    // answer.question_option_id = action.question_option_id ? action.question_option_id : null;

    return {
      ...state,
      qid: qid,
      currentQuestionNumberIndex: Math.floor(qid / 10),
      currentQuestionSkippedNumberIndex: 0,
      openSkippedDialog: false,
    };
  } else if (action.type === TESTSUBMITLOAD) {
    return {
      ...state,
      testSubmitting: true,
    };
  } else if (action.type === ONTESTSUBMIT) {
    if (action.error) {
      return {
        ...state,
        testSubmitted: false,
        testSubmitting: false,
        errorSubmittingTest: true,
      };
    }
    let feedback_questions = action.feedback_response
      ? action.feedback_response.data.questions
      : null;
    let feedback_answers = [];
    if (feedback_questions) {
      feedback_answers = feedback_questions.map((que) => {
        let answer = {};
        answer["question_id"] = que.id;
        answer["question_option_id"] = null;
        return answer;
      });
    }
    return {
      ...state,
      testSubmitted: true,
      testSubmitting: false,
      feedback_questions: feedback_questions,
      feedback_answers: feedback_answers,
      errorSubmittingTest: false,
    };
  } else if (action.type == "OPEN_COLLECT_FEEDBACK") {
    return {
      ...state,
      openFeedback: true,
      feedbackSubmitted: false,
    };
  } else if (action.type == "ON_QUES_FEEDBACK_OPTION_SELECTED") {
    let feedback_questions = state.feedback_questions;
    let question_id = action.question_id;
    let option_id = action.option_id;

    let answers = state.feedback_answers.map((ans) => {
      return { ...ans };
    });
    let feedback_answers = [...answers];
    let index = feedback_answers.findIndex((ans) => {
      return ans.question_id == question_id;
    });
    feedback_answers[index].question_option_id = option_id;
    return {
      ...state,
      feedback_answers: feedback_answers.map((ans) => {
        return { ...ans };
      }),
    };
  } else if (action.type == "SUBMIT_QUESTIONS_FEEDBACK") {
    return {
      ...state,
      feedbackSubmitted: true,
      openFeedback: false,
    };
  } else if (action.type === "ON_TEST_QUESTION_SUBMIT") {
    const answers = state.answers;
    console.log("ON_TEST_QUESTION_SUBMIT");
    answers[action.qid].timetaken = {
      start: null,
      end: null,
      duration: null
    };
    return {
      ...state,
      answers: answers,
      close_test: action.close,
    };
  } else if (action.type === "CLEAR_TEST_STATE") {
    return initial_state;
  }
  return state;
};

export default reducer;
