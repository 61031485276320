import React from 'react';
import styles from './MonogramTimer.module.css';
import monogramGroup from '../../../../assets/coding/monogram_group.svg'
import Timer from '../Timer/Timer';

const MonogramTimer = (props) => (
  <div className={styles.MonogramTimer}>
    <img src={monogramGroup} alt="monogram" />
    <div className={styles.timer} >
      <Timer seconds={props.seconds} onStartTimer={props.onStartTimer} onTimerUpdate={props.onTimerUpdate} />
    </div>
  </div>
);


export default MonogramTimer;
