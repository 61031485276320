import React, { Component } from 'react';
import { config } from "react-spring";
import axios from '../../../interceptor';

import styles from './PreviewCard.module.css';
import Carousel from "../Carousel/Carousel";

import PreviewCardItems from '../PreviewCardItems/PreviewCardItems';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


class PreviewCard extends Component {

  state = {
    goToSlide: 0,
    offsetRadius: 4,
    showNavigation: true,
    config: config.stiff,
    slides: []
  };

  componentDidMount = async ()=> {
    
        let data = this.props.deck_data;
        console.log(data);

        var slides = []
        let gradients = ['linear-gradient(140.58deg, #A0F4FF -7.25%, #75DAE9 13.83%, #8459F8 96.87%)', 'linear-gradient(324.97deg, #ff723b 17.69%, #ffdf57 140.91%, #ffed5b 163.41%)', 'linear-gradient(160.86deg, #DCFF73 -207.23%, #8ECA6B 93.39%)']
        const CORS = 'https://cors-anywhere.herokuapp.com/';
        if (data) {
          for (let i = 0; i < data.length; i++) {

            await axios.get(`${data[i]}`).then(res => {
              console.log(res);
              let randomGrad = Math.floor(Math.random() * gradients.length);
              let item = {
                key: i,
                content: <PreviewCardItems gradient={gradients[randomGrad]}>{res.data}</PreviewCardItems>,
                onClick: () => this.setState({ goToSlide: i })
              }
              console.log(item)
              slides.push(item);
              console.log(slides)
            })
  
          }
        }
        console.log(slides);
        this.setState({slides: slides});
      
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.deck_data != this.props.deck_data) {
      let data = this.props.deck_data;
        console.log(data);

        var slides = []
        let gradients = ['linear-gradient(140.58deg, #A0F4FF -7.25%, #75DAE9 13.83%, #8459F8 96.87%)', 'linear-gradient(324.97deg, #ff723b 17.69%, #ffdf57 140.91%, #ffed5b 163.41%)', 'linear-gradient(160.86deg, #DCFF73 -207.23%, #8ECA6B 93.39%)']
        const CORS = 'https://cors-anywhere.herokuapp.com/';
        if (data) {
          for (let i = 0; i < data.length; i++) {

            await axios.get(`${data[i]}`).then(res => {
              console.log(res);
              let randomGrad = Math.floor(Math.random() * gradients.length);
              let item = {
                key: i,
                content: <PreviewCardItems gradient={gradients[randomGrad]}>{res.data}</PreviewCardItems>,
                onClick: () => this.setState({ goToSlide: i })
              }
              console.log(item)
              slides.push(item);
              console.log(slides)
            })
  
          }
        }
        console.log(slides);
        this.setState({slides: slides});
    }
  }

  render() {
    console.log(this.state.slides);
    console.log(this.props)
    let dialog = null;

    if (this.state.slides.length != 0) {
      dialog = (
        <div className={styles.overlay}>
          <div className={styles.PreviewCard}>
            <div className={styles.CardTitleContainer}>
              <h3 className={styles.CardTitleText}>{this.props.deck_name}</h3>
              {/* {!this.props.hideBookmark && this.props.deck.bookmarked == 1 ? <img style={{ marginLeft: 30, marginTop: '5px' }} onClick = {(event)=>this.props.addOrDeleteBookmark(event,this.props.deck.unit_id, this.props.deck.id, 'decks')} src='/images/bookmarked.svg'></img>
              : !this.props.hideBookmark ?  <img style={{ marginLeft: 30, marginTop: '5px' }} onClick = {(event)=>this.props.addOrDeleteBookmark(event, this.props.deck.unit_id, this.props.deck.id, 'decks')} src='/images/bookmark.svg'></img> : null} */}
              {/* <img  src='/images/decks/share_comp.svg' alt="share icons" /> */}
              <span className={styles.Spacer}></span>
              <img src='/images/decks/close.svg' onClick={this.props.onClose} alt="close" />
            </div>
            <div style={{ width: "100%", height: "500px", margin: "0 auto" }}>
              <Carousel
                slides={this.state.slides}
                goToSlide={this.state.goToSlide}
                offsetRadius={this.state.offsetRadius}
                showNavigation={this.state.showNavigation}
                animationConfig={this.state.config}
              />
            </div>
          </div>
        </div>
      );
    }

    if (this.state.slides.length == 0) {
      dialog = (<div className={styles.overlay}>
        <Loader
        type="ThreeDots"
        color="#eee"
        height={100}
        width={100}//3 secs
      />
      </div>)
    }

    if (!this.props.isOpen) {
      dialog = null;
    }
    return (
      <div>
        {dialog}
      </div>
    );
  }
}

export default PreviewCard;