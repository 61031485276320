import React from 'react'

const CrossSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M19 5.00098L5.00094 19" stroke="#0946C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.00094 4.99996L19 18.999" stroke="#0946C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default CrossSvg