import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Wheel } from 'react-custom-roulette'
import WheelPointerSvg from './../../../../assets/remedial/svg/WheelPointer.svg'

const SpinWheel = ({mustSpin, prizeNumber,startingOptionIndex = 0 , data , onStopSpinHandle , setSpinDuration = 1}) => {
    const spinWrapper = {
        transform: 'rotate(-45deg)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const theme = useTheme();
 console.log(mustSpin)
 console.log(prizeNumber)
 console.log(startingOptionIndex)
 console.log(data)
 
    return (
        <Box sx={{
            ...spinWrapper,
            position: 'relative',
            '> div':{
                maxWidth:'320px',
                maxHeight: '320px',
            },
            '.inner-spin-bg': {
                position: 'absolute',
                width: '45px',
                height: '45px',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                // backgroundColor: '#FF3F48',
                zIndex: '2',
                borderRadius: '50%'
            },
        }}>
            <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                data={data}
                outerBorderColor={theme.palette.extraredpalette[800]}
                outerBorderWidth={10}
                innerRadius={16}
                innerBorderColor={theme.palette.extraredpalette[700]}
                innerBorderWidth={4}
                startingOptionIndex={startingOptionIndex}
                radiusLineColor={theme.palette.extraredpalette[800]}
                radiusLineWidth={2}
                backgroundColors={[theme.palette.grey[1000], theme.palette.extraredpalette[700]]}
                textColors={[theme.palette.extraredpalette[700] , theme.palette.grey[1000]]}
                fontSize={[14]}
                fontWeight={600}
                fontFamily={'Poppins-SemiBold'}
                pointerProps={{
                    src: WheelPointerSvg,
                    style: {
                        transform: 'rotate(48deg)',
                        right: '26px',
                        top: '26px',
                        width: '12%'
                    }
                }}
                spinDuration={setSpinDuration}
                onStopSpinning={onStopSpinHandle}
            />
            <span className='inner-spin-bg' />
        </Box>
    )
}

export default SpinWheel