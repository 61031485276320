import React, { useEffect, useState } from 'react';
import {useLocation, useHistory, withRouter} from 'react-router-dom';
import CodingScreenHeader from '../../features/codingModule/codingScreenHeader';
import TrackInfo from '../../features/codingModule/trackInfo';
import { Grid, Button, Container, Box, Typography, Hidden, Skeleton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CodingStats from '../../features/codingModule/codingStats';
import CodingLeaderboard from '../../features/codingModule/codingLeaderboard';
import { connect } from 'react-redux';
import {BackButton} from './styles.js'
import { getCodingQuestionsListAC, getCodingStatsAC, getCodingStatusAC } from '../../store/actions/codingTrackActions';
import colors2 from './colors2';
import SearchField2 from '../../components/common/searchField2';
import TagsPopup from '../../features/codingModule/tagsPopup.jsx';
import ErrorPage from '../../components/common/ErrorPage.jsx';
import { BeginnerIcon } from '../../assets/codingModule/BeginnerIcon.jsx';
import { IntermediateIcon } from '../../assets/codingModule/IntermediateIcon.jsx';
import { CompetitiveIcon } from '../../assets/codingModule/CompetitveIcon.jsx';

const CodingTrack = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [questionsList, setQuestionsList] = useState([]);
    const [questionsDisplayed, setQuestionsDisplayed] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [statsKeys, setStatsKeys] = useState([]);
    const [statsValues, setStatsValues] = useState([]);
    const [leaderBoard, setLeaderBoard] = useState(null);
    const [codingDisplayQuestion, setCodingDisplayQuestion] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [openPopupInfo, setOpenPopupInfo] = useState([]);
    const [questionsToBeOpened, setQuestionsToBeOpened] = useState([]); //Only stores the 3 questions that will be opened, not the completed ones (which will anyways be opened by default)
    // const [openPopupId, setOpenPopupId] = useState(-1);

    useEffect(() => {
        const getCodingQuestionsListA = async () => await props.getCodingQuestionsList(location.state.track_id);
        const getCodingStatsA = async () => await props.getCodingStats(location.state.track_id);
        const getCodingStatusA = async () => await props.getCodingStatus();
        getCodingStatusA();
        getCodingQuestionsListA();
        getCodingStatsA();
    }, []);

    useEffect(() => {
        if (props.codingTrackStatus && Object.keys(props.codingTrackStatus).length > 0 && !props.loading_questions_list) {
            setCodingDisplayQuestion(props.codingTrackStatus.display_question);
        }
    }, [props.codingTrackStatus, props.loading_questions_list]);

    useEffect(() => {
        if (props.trackQuestions.length > 0) {
            const listByDisplayOrder = props.trackQuestions;
            listByDisplayOrder.sort((a,b) => a.order - b.order);
            setQuestionsList(listByDisplayOrder);
            setQuestionsDisplayed(listByDisplayOrder);
        }
    }, [props.trackQuestions]);

    useEffect(() => {
        if (questionsList.length > 0) {
            const incompleteQuestions = questionsList.filter(question => !question.completed);
            const firstThreeIncompleteQuestionIds = incompleteQuestions.slice(0, 3).map(question => question.question_id);
            setQuestionsToBeOpened(firstThreeIncompleteQuestionIds);
            console.log("incomplete --> ");
            console.log(incompleteQuestions);
            console.log("three incomplete ids --> ");
            console.log(firstThreeIncompleteQuestionIds);
        }
    }, [questionsList]);

    useEffect(() => {
        setStatsKeys(props.stats_keys);
        setStatsValues(props.stats_values);
        setLeaderBoard(props.leaderboard);
    }, [props.stats_keys, props.stats_values, props.leaderboard]);

    const handleBackButton = () => {
        history.push('/codingTrackLevel');
    }

    const handleQuestionSearch = (event) => {
        setSearchText(event.target.value);
        const searched_text = event.target.value.toLowerCase();
        const matching_questions = questionsList.filter(ques => ques.title.toLowerCase().includes(searched_text));
        setQuestionsDisplayed(matching_questions);
    };

    const handleOpenPopup = (id, tags) => {
        setIsPopupOpen(true);
        setOpenPopupInfo(tags);
        // setOpenPopupId(id);
    }

    const handleClosePopup = () => {
        console.log("close");
        setIsPopupOpen(false);
        setOpenPopupInfo([]);
        // setOpenPopupId(-1);
    };

    if (props.error_loading_questionsList) {
        return (
            <>
                <CodingScreenHeader logo={'/rmklogo_transparent.png'} background={'#F4F6F8'}/>
                <Box sx={{marginTop: '50px', textAlign: 'center'}}>
                    <ErrorPage />
                </Box>
            </>
        );
    }

    return (
        <>
            <CodingScreenHeader logo={'/rmklogo_transparent.png'}/> 
            <Box sx={{position: 'relative', background: '#fff'}}>
                <Grid container>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11} md={8} sx={{textAlign: 'left', marginTop: '25px'}}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: '15px'
                            }}
                        >
                            <BackButton variant="contained" size="small" onClick={handleBackButton} sx={{background: colors2.secondary, marginBottom: '2.5px', '&:hover' : {background: colors2.secondary}}}>
                                <img src={"/images/back_arrow.svg"} alt="back"/>
                            </BackButton>
                            {props.loading_questions_list ? 
                                <Skeleton variant='rounded' height={'27px'} width={'200px'}/> 
                            :
                                <Grid container justifyContent={'flex-start'} alignItems={'center'} gap={'12px'}>
                                    {location.state.track_id === 1 ? <BeginnerIcon backgroundColor={colors2.primaryLighter} /> : location.state.track_id === 2 ? <IntermediateIcon backgroundColor={colors2.primaryLighter} /> : <CompetitiveIcon backgroundColor={colors2.primaryLighter} />}
                                    <Typography variant="h3" sx={{color: colors2.primaryLighter}}>
                                        {location.state.track_id === 1 ? 'Beginner' : location.state.track_id === 2 ? 'Intermediate' : 'Competitive'}
                                    </Typography>
                                </Grid>
                            }
                        </Box>
                        <Typography variant="body2" sx={{marginRight: '20%', marginTop: '10px', marginBottom: '30px'}}>Improve problem-solving skills in your college Foster programming culture with the help of our Data Structure & Algorithms focused learning program</Typography>
                        <SearchField2
                            onChange={handleQuestionSearch}
                            placeholder="Search questions"
                            width="98%"
                            height="35px"
                        />
                        {props.loading_questions_list ?
                        <Grid container direction="column" spacing={2} justifyContent="center" sx={{padding: '30px 2%', gap: '17px'}}>
                            <Skeleton variant='rounded' height={'95px'} />
                            <Skeleton variant='rounded' height={'95px'} />
                            <Skeleton variant='rounded' height={'95px'} />
                            <Skeleton variant='rounded' height={'95px'} />
                            <Skeleton variant='rounded' height={'95px'} />
                            <Skeleton variant='rounded' height={'95px'} />
                        </Grid>
                        :
                        <Grid container direction="column" spacing={2} justifyContent="center" sx={{paddingRight: '2%', marginTop: '-5px'}}>
                            {questionsList.length > 0 && questionsDisplayed.length > 0 && questionsDisplayed.map((question, i) => {
                                const difficulty = (question.difficulty_level === 1 ? 'Easy' : question.difficulty_level === 2 ? 'Medium' : 'Hard');
                                return (
                                <Grid item key={i}>
                                    <TrackInfo 
                                        key = {question.question_id}
                                        id = {question.master_question_id}
                                        track = {location.state.track}
                                        number = {i+1}
                                        heading = {question.title}
                                        tags = {question.topics_tagged}
                                        order= {question.order}
                                        difficulty = {difficulty}
                                        percentageDone = {question.highest_score}
                                        attempted={question.attempted}
                                        completed = {question.completed}
                                        open={questionsToBeOpened.includes(question.question_id) ? true : false}
                                        topics_tagged={question.topics_tagged}
                                        display_question = {codingDisplayQuestion}
                                        handleOpenPopup = {handleOpenPopup}
                                    />
                                </Grid>
                                )
                            })}
                        </Grid>
                        }

                        {questionsList.length > 0 && questionsDisplayed.length === 0 ?
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '50vh',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '10px',
                                }}
                            >
                                <img src={`/gifs/coding_track_element_not_found_animation.gif`} alt="Not available" style={{height: '100px', width: '100px'}}/>
                                <Typography variant="body4">We could not find what you are looking for</Typography>
                            </Box>
                        : null}

                        {questionsList.length === 0 ? 
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '50vh',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '10px',
                                }}
                            >
                                <img src={`/gifs/coding_track_element_not_found_animation.gif`} alt="Not available" style={{height: '100px', width: '100px'}}/>
                                <Typography variant="body4">The questions for this level are not available at the moment. Please try again later.</Typography>
                            </Box>
                        : null}
                    </Grid>
                    <Hidden mdDown>
                        <Grid item xs={0} md={3} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'}}>
                            <Grid item sx={{marginTop: '50px', marginBottom: '20px', width: '100%'}}>
                                <CodingStats 
                                    stats_keys = {statsKeys}
                                    stats_values = {statsValues}
                                    loading={props.loading_questions_list}
                                />
                            </Grid>
                            {leaderBoard && (
                            <Grid item width="100%">
                                <CodingLeaderboard leaderboard = {leaderBoard} loading={props.loading_questions_list}/>
                            </Grid>
                            )}
                        </Grid>
                    </Hidden>
                </Grid>
                <TagsPopup 
                    isPopupOpen={isPopupOpen}
                    // id={openPopupId}
                    tags={openPopupInfo}
                    handleClosePopup={handleClosePopup}
                />
            </Box>
        </>
    )
} 

const mapStateToProps = (state) => {
    console.log(state.codingTrack);
    return {
        trackQuestions: state.codingTrack.trackQuestions,
        stats_keys: state.codingTrack.codingStats_keys,
        stats_values: state.codingTrack.codingStats_values,
        leaderboard: state.codingTrack.leaderboard,
        error_loading_questionsList: state.codingTrack.error_loading_questionsList,
        codingTrackStatus: state.codingTrack.codingTrackStatus,
        error_loading_codingTracks: state.codingTrack.error_loading_codingTracks,
        loading_questions_list: state.codingTrack.loading_questions_list,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCodingStatus: () => dispatch(getCodingStatusAC()),
        getCodingQuestionsList: (track_id) => dispatch(getCodingQuestionsListAC(track_id)),
        getCodingStats: (track_id) => dispatch(getCodingStatsAC(track_id)),
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CodingTrack)
);