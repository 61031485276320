import React, { Component } from "react";
import styled from 'styled-components';

const QuestionNumbersDiv = styled.div`
    border: ${({ current, marked, answered, skipped }) => (current ? "1px solid #4E54C8" : answered ? '1px solid lightgreen' : marked ? '1px solid #FF7F3F' : skipped ?  "1px solid #CCCCCC" : "1px solid #4E54C8")};
    background-color: ${({ current, marked, answered, skipped  }) => (current ? "#4E54C8" : marked ? '#FF7F3F' : answered ? 'lightgreen' : skipped ? '#CCCCCC' : null)};
    color: ${({ current, marked, answered, skipped  }) => (current ? "#FFFFFF" : answered ? '#DDDDDD' : marked ? '#FFFFFF' :  "black")};
    display: flex;
    align-items: center;
    justfy-content: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
`

const QuestionNumbers = styled.p`
    margin-top: 6px;
    margin-bottom: 8px;
    width: 100%;
    font-size: 14px;
    color: ${({ current, marked, answered, skipped  }) => (current ? "#FFFFFF" : answered ? 'black' : marked ? '#FFFFFF' :  "black")};
    font-weight: ${({ current, marked, answered, skipped  }) => (answered ? 'bold' : "500")};
`

class Circle extends Component {
    render () {
        // console.log(this.props);
        return (
            <QuestionNumbersDiv answered={this.props.answered} marked = {this.props.marked} skipped={this.props.skipped} current = {this.props.current} onClick={this.props.current ? () => false : () => this.props.onQuestionClick(this.props.number - 1,4)} key = {this.props.index}>
                <QuestionNumbers answered={this.props.answered} marked = {this.props.marked} skipped={this.props.skipped} current = {this.props.current} >{this.props.number}</QuestionNumbers>
            </QuestionNumbersDiv>
        )
    }
}

export default Circle;