import React, { useEffect, useState } from 'react'
import RemedialCard from '../RemedialCard/RemedialCard'
import { Box, Stack, Typography } from '@mui/material'
import WheelSvg from './../../../../assets/remedial/svg/fortune-wheel.svg'
import RedmedialButton from '../RemedialButtons/RedmedialButton'
import ConfettiSvg from './../../../../assets/remedial/gif/Confetti.gif'
import ConfettiConeSvg from './../../../../assets/remedial/gif/cone-transparent.gif'
import { useRemedialContext } from '../../hooks/useRemedial'

//TODO:anurag -> remove hard color

const SpinResultCard = ({ data, updateBanner, jackpot = false }) => {

    const [showConfetti, setShowConfetti] = useState(false);

    const { updateBannerIndex,bannerData } = useRemedialContext();


    useEffect(() => {
        setTimeout(() => setShowConfetti(true), 3300);
    }, [data])

    return (
        <RemedialCard>
            <Stack sx={{ position: 'relative' }} gap={'16px'}>
                <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
                    <img src={WheelSvg} alt='timer' width={20} height={20} />
                    <Typography component={'p'} sx={{
                        fontFamily: 'Poppins-SemiBold',
                        fontSize: '16px',
                        color: 'grey.900'
                    }}>Spin Result</Typography>
                </Stack>
                {!jackpot && <Stack alignItems={'center'} justifyContent={'center'} sx={{ width: '100%', height: '160px' }}>
                    <Typography
                        component={'h5'}
                        sx={{
                            fontFamily: 'Poppins-SemiBold',
                            fontSize: '18px',
                            color: 'grey.900',
                            textAlign: 'start',
                            zIndex: '2',
                            span: {
                                fontFamily: 'Poppins-SemiBold',
                                fontSize: '18px',
                                color: theme => theme.palette.extraredpalette[700]
                            }
                        }}>
                        You Got “ <span>{data?.selected_category_name}</span> ”
                    </Typography>
                    {showConfetti && <Box sx={{ zIndex: '0', position: 'absolute', }}>
                        <img src={ConfettiSvg} alt="ConfettiSvg" />
                    </Box>}
                </Stack>}
                {!jackpot && <Typography
                    component={'h5'}
                    sx={{
                        fontFamily: 'Poppins-Medium',
                        fontSize: '14px',
                        color: 'grey.600',
                        textAlign: 'start',
                        span: {
                            fontFamily: 'Poppins-SemiBold',
                            fontSize: '14px',
                            color: 'grey.800'
                        }
                    }}>
                    Now, you just need to <span> answer {data?.selected_category_name} correctly</span> in remedial test to get all the bonus points to increase your score.
                </Typography>}


                {jackpot &&
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '163px' }}>
                        <Box>
                            <img src={ConfettiConeSvg} alt="ConfettiSvg" />
                        </Box>
                        <Typography
                            component={'h5'}
                            sx={{
                                fontFamily: 'Poppins-SemiBold',
                                fontSize: '18px',
                                color: 'grey.900',
                                textAlign: 'start',
                                zIndex: '2',
                                span: {
                                    fontFamily: 'Poppins-SemiBold',
                                    fontSize: '18px',
                                    color: theme => theme.palette.extraredpalette[700]
                                }
                            }}>
                            <span>JACKPOT</span>
                        </Typography>
                        <Box sx={{ transform: 'rotate(280deg)' }}>
                            <img src={ConfettiConeSvg} alt="ConfettiSvg" />
                        </Box>
                    </Box>}

                {jackpot && <Typography
                    component={'h5'}
                    sx={{
                        fontFamily: 'Poppins-Medium',
                        fontSize: '14px',
                        color: 'grey.600',
                        textAlign: 'start',
                        span: {
                            fontFamily: 'Poppins-SemiBold',
                            fontSize: '14px',
                            color: 'grey.800'
                        }
                    }}>
                    <span>Congratulations to our lucky student</span> who not only hit the jackpot but also earned an impressive 3 bonus points – <span>you're on a winning streak!</span>
                </Typography>}



                {/* <RedmedialButton color="externalerror.200" onClick={() => updateBanner(bannerData.test_completed>0 ?'4':'3')}>{bannerData.test_completed>0 ?'View revised result':'Start Remedial Process'}</RedmedialButton> */}
                <RedmedialButton color="externalerror.200" onClick={() => updateBanner(jackpot ? '3' : bannerData.test_completed>0 ? '4' : '3', jackpot ? 'direct bonus points' : 'not direct')}>{jackpot ? 'View Revised Result' : bannerData.test_completed>0 ?'View revised result':'Start Remedial Process'}</RedmedialButton>
            </Stack>
        </RemedialCard>
    )
}

export default SpinResultCard