import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import { itemUpdateRemoveAddAC, REMOVEPROJECT, UPDATEPROJECT, getClassmatesDataAC} from '../../store/actions/profileActions';
import styled from 'styled-components';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import 'react-datepicker/dist/react-datepicker.css';

const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 25%);

    @media(max-width: 1180px) {
        grid-template-columns: repeat(2, 50%);
    }

`;

const SaveButton = styled.button`
    border-radius: 32px;
    background:  ${({ active }) => (active ? "linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%)" : "grey")};
    margin: auto;
    text-align: center;
    width: 98px;
    padding: 8px;
    color: white;
    margin-right: 0;
    border: none;
`

const FlexBoxForDate = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px 10px;
    align-items: flex-end;
    @media (max-width: 1024px) {
        diplay: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`;

const StartDateBox = styled.div`
    margin-right: 20px;
    width: 50%;
    display: flex;
    flex-direction: row;
    @media (max-width: 1024px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;
const EndDateBox = styled.div`
    margin-right: 20px;
    width: 50%;
    display: flex;
    flex-direction: row;
    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const DateTextDiv =  styled.div`
    width: 40%;
    margin-right: 20px;
    text-align: right;
    @media (max-width: 1024px) {
        width: 20%;
    }
`;

class UpdateProject extends Component {

    state = {
        openContent : false,
        contentUrl: '',
        contentType: null,
        title: this.props.projects[this.props.project_index].title,
        company: this.props.projects[this.props.project_index].company,
        location: this.props.projects[this.props.project_index].location,
        start_year: this.props.projects[this.props.project_index].from_year,
        end_year: this.props.projects[this.props.project_index].to_year,
        description: this.props.projects[this.props.project_index].description,
        selected_students: this.props.projects[this.props.project_index].team.map((stu)=> {
            return stu.id;
        }),
        new_files: [],
        start_time: moment(this.props.projects[this.props.project_index].from_year,'MMM yyyy').toDate(),
        end_time: moment(this.props.projects[this.props.project_index].to_year, 'MMM yyyy').toDate(),
    }

    componentDidMount = () => {
        this.props.getClassmatesData();
    }

    onChangeHandler = (event, type)=> {
        event.stopPropagation();
        event.preventDefault();
        
        this.setState({[type] : event.target.value});
    }

    onFileChangeHandler = (event) => {
        this.setState({new_files: event.target.files})
    }

    handleChange = (e)=> {
        let students = this.state.selected_students;
        let new_list = [];
        let deleted_list = [];
        if (Array.isArray(e)) {
            e.map((x)=> {
                if (students.indexOf(x.value)==-1) {
                    students.push(x.value);
                    new_list.push(x.value);
                }
            });
            students = students.filter((skill)=> {
                let index = e.findIndex((x)=> {
                    return x.value == skill
                })
                if (index == -1) {
                    deleted_list.push(skill);
                    return false
                } else {
                    return true
                }
            })
        }

        this.setState({selected_students: students});

    }

    onEndDateChange = (date) => {
        try {
            let dateFormat = moment(date).format('MMM yyyy').toString();
            
            if (this.state.start_time) {
                if (date < this.state.start_time) {
                    this.setState({ endTimeError: true, endTimeErrorMessage: 'end time cannot be lesser than start year' })
                } else {
                    this.setState({ end_time: date, end_year: dateFormat, endTimeError: false, endTimeErrorMessage: '' });

                }
            } else {
                if (dateFormat) {
                    this.setState({  endTimeError: true, endTimeErrorMessage: 'please select start year first' });
                }
            }
        } catch (error) {
            console.log(error);
        }
        this.forceUpdate()
    }

    onStartDateChange = (date) => {
        try {
            let dateFormat = moment(date).format('MMM yyyy').toString();
            
            if (this.state.end_time) {
                if (date > this.state.end_time) {
                    this.setState({ startTimeError: true, startTimeErrorMessage: 'start year cannot be greater than end year' })
                } else {
                    this.setState({ start_time: date, start_year: dateFormat, startTimeError: false, startTimeErrorMessage: '' });

                }
            } else {
                this.setState({ start_time: date, start_year: dateFormat, startTimeError: false, startTimeErrorMessage: '' });
            }
            
        } catch (error) {
            console.log(error);
        }
        this.forceUpdate()
    }

    closeProjectEditor = () => {
        this.setState({
            title: null,
            company: null,
            location: null,
            start_year: null,
            end_year: null,
            description: null,
            files: null,
            new_files: [],
            selected_students: []
        });
        this.props.clearStatus();
        this.props.close();
    }

    updateProjectHandler = () => {
        const formdata = new FormData();
        formdata.append('title', this.state.title);
        formdata.append('project_id',this.props.projects[this.props.project_index].id);
        formdata.append('company', this.state.company);
        formdata.append('location', this.state.location);
        formdata.append('description', this.state.description);
        formdata.append('from_date', this.state.start_year);
        formdata.append('team', JSON.stringify(this.state.selected_students));
        formdata.append('to', this.state.end_year);
        if (this.state.new_files && this.state.new_files.length != 0) {
            Array.from(this.state.new_files).map((file)=> {
              console.log(file)
              formdata.append('new_attachments', file);
            })
        }

        let item = {};
        item.type = UPDATEPROJECT;
        item.index = this.props.project_index;
        this.props.updateProject(item, formdata);
    }

    deleteProject = () => {
        const formdata = new FormData();
        formdata.append('Project_id',this.props.projects[this.props.project_index].id);

        let item = {};
        item.type = REMOVEPROJECT;
        item.id = this.props.projects[this.props.project_index].id;
        this.props.updateProject(item, formdata);
    }

    removeFileFromList = (event, index) => {
        let files = this.state.files;
        files.splice(index, 1);
        this.setState({files: files});
    }
    

    render () {
        console.log(this.state);
        console.log(this.props);
        if (this.props.loading_classmates) {
            return <div>Loading...</div>
        } else if (!this.props.loading_classmates && this.props.error_loading_classmates) {
            return <div>Error loading classemates. Please try after some time!!!!</div>;
        }
        let options = this.props.classmates.map((mate)=> {
            let opt = {};
            opt['value'] = mate.id;
            opt['label'] = mate.name;
            return opt

        })

        let selected_options = [];
        if (this.state.selected_students && this.state.selected_students.length != 0) {
            selected_options = []
            selected_options = options.filter((opt)=> {
                return this.state.selected_students.includes(opt.value);
            })
        }
        return (
            <div style={{}}>
                <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '20px'}}>Edit Your Project</div>

                <div style={{border: '1px solid #eee', borderRadius: '4px',  textAlign: 'left', padding: '10px', margin: '20px 0'}}>
                    <div style={{width: '10%', margin: 'auto', marginRight: '20px'}} onClick={this.deleteProject}>
                        <img src='/images/profile/delete_icon.svg'></img>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Title : </div>
                        <div style={{width: '80%'}}>
                            <input onChange={(event)=>this.onChangeHandler(event, 'title')} value={this.state.title} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Company : </div>
                        <div style={{width: '80%'}}>
                            <input onChange={(event)=>this.onChangeHandler(event, 'company')} value={this.state.company} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Location : </div>
                        <div style={{width: '80%'}}>
                            <input onChange={(event)=>this.onChangeHandler(event, 'location')} value={this.state.location} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input>
                        </div>
                    </div>
                    <FlexBoxForDate>
                        <StartDateBox>
                            <DateTextDiv >Start Year : </DateTextDiv>
                            <div style={{width: '60%'}}>
                                <DatePicker
                                    selected={this.state.start_time}
                                    onChange={this.onStartDateChange}
                                    showMonthYearPicker
                                    dateFormat="MMM yyyy"
                                />
                                {/* <input onChange={(event)=>this.onChangeHandler(event, 'start_year')} value={this.state.start_year} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input> */}
                            </div>
                        </StartDateBox>
                        <EndDateBox>
                            <DateTextDiv>End Year : </DateTextDiv>
                            <div style={{width: '60%'}}>
                                <DatePicker
                                    selected={this.state.end_time}
                                    onChange={this.onEndDateChange}
                                    showMonthYearPicker
                                    dateFormat="MMM yyyy"
                                />
                                {/* <input onChange={(event)=>this.onChangeHandler(event, 'end_year')} value={this.state.end_year} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input> */}
                            </div>
                        </EndDateBox>
                    </FlexBoxForDate>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Description : </div>
                        <div style={{margin: 'auto 0 auto auto', width: '80%'}}>
                            <textarea onChange={(event)=>this.onChangeHandler(event, 'description')} value={this.state.description} style={{width: '80%', height: '70px',border: '1px solid #bbb',borderRadius: '4px'}} required></textarea>
                        </div>
                    </div>

                    
                    <div style={{margin: '20px 10px', display: 'flex'}}>
                        <div style={{width: '20%', textAlign: 'right', margin: 'auto '}}>Select your team mates</div>
                        <div style={{width: '80%', margin: 'auto 20px'}}>
                            <Select style={{width: '80%'}}
                                value={selected_options}
                                onChange={this.handleChange}
                                options={options}
                                isMulti
                                isClearable
                            />
                        </div>
                        
                    </div>

                    {this.props.updated_project_successfully? <div style={{color: 'green', textAlign: 'left'}}>Project Updated Successfully.</div> : null}
                    {this.props.deleted_project_successfully? <div style={{color: 'green', textAlign: 'left'}}>Project Deleted Successfully.</div> : null}
                    {this.props.error_deleting_project? <div style={{color: 'green', textAlign: 'left'}}>Error deleting project. Please try again after some time!!</div> : null}
                    
                    {this.props.updated_project_successfully || this.props.deleted_project_successfully?  
                    <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto', marginTop: '25px'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeProjectEditor}>OK</div>
                    </div>
                    :<div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeProjectEditor}>Cancel</div>
                        <div>
                            <SaveButton active={this.state.title && this.state.title != '' && this.state.company && this.state.company != '' && this.state.start_year && this.state.end_year && moment(this.state.end_year) > moment(this.state.start_year) }  onClick={this.updateProjectHandler}>Save</SaveButton>
                        </div>
                    </div>}
                </div>
                <style jsx scoped="true">{`
                    .react-datepicker-wrapper,
                    .react-datepicker__input-container,
                    .datePicker input {
                        width: 100%;
                        // border: 0;
                        height: 22px;
                        border-radius: 4px;
                    }
                
                
                `}</style>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.prof.loading,
        name: state.prof.name,

        error_updating_project: state.prof.error_updating_project,
        updated_project_successfully: state.prof.updated_project_successfully,
        error_deleting_project: state.prof.error_deleting_project,
        deleted_project_successfully: state.prof.deleted_project_successfully,
        classmates: state.prof.classmates,
        error_loading_classmates: state.prof.error_loading_classmates,
        loading_classmates: state.prof.loading_classmates,
        projects: state.prof.projects
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProject: (item, formdata) => dispatch(itemUpdateRemoveAddAC(item, formdata)),
        clearStatus: () => dispatch({type:'CLEAR_UPDATE_PROJECT_STATUS'}),
        getClassmatesData: () => dispatch(getClassmatesDataAC())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateProject));