import React, { Component } from "react";
import classes from "./login.module.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserDetailsAC,
  getLoginOtpAC,
  OPENENTERROLLNUMBERAGAIN,
  getUserPasswordDetailsAC,
  updatePasswordAC,
} from "../../store/actions/loginActions";
import { Redirect } from "react-router-dom";
import moment from "moment";

let timeInterval = null;

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpSent: false,
      roll_number: "",
      otp: null,
      resend: false,
      otpList: ["", "", "", ""],
      roll_number_error: false,
      password_disabled: false,
      time: 0
    };
  }

  componentDidMount = () => {
    // localStorage.clear();
    if (localStorage.getItem("token")) {
      this.props.history.push("/home");
    }
    if (
      this.props.history.location.state &&
      this.props.history.location.state.roll_number
    )
      this.setState({
        roll_number: this.props.history.location.state.roll_number,
      });
  };

  componentDidUpdate(){
    if (
      !timeInterval &&
      !this.state.password_disabled &&
      this.props.password_disabled &&
      this.props.last_incorrect_attempt
    ) {
      const last_incorrect_time = moment(this.props.last_incorrect_attempt);
      last_incorrect_time.add(1, 'minute');
      const current_date = moment();
      if(last_incorrect_time > current_date){
        let time = moment.duration(last_incorrect_time.diff(current_date)).asSeconds().toFixed(0);
        if(time > 0){
          timeInterval = setInterval(() => {
            if (time <= 0) {
              clearInterval(timeInterval);
              timeInterval = null;
              this.setState({ time: 0, password_disabled: false });
              return;
            }
            this.setState({ time: time--, password_disabled: true });
          }, 1000);
        }
      }
    }
  }

  onRollNumberChange = (event) => {
    if(/^[A-Z0-9]+$/i.test(event.target.value.trim()))
      this.setState({ roll_number: event.target.value.trim(), roll_number_error: false });
    else this.setState({ 
      roll_number: event.target.value.replace(/[^A-Z0-9]+/i, ''), 
      roll_number_error: !/^[A-Z0-9]+$/i.test(event.target.value) && event.target.value
    });
  };

  onSubmitMobileNo = () => {
    this.props.getPasswordDetails(this.state.roll_number, false);
  };

  onRollNumberEnter = (event) => {
    if (event.key === "Enter") {
      this.onSubmitMobileNo();
    }
  };

  render() {
    if (this.props.user_logged_in_successfully) {
      return <Redirect to="/home" exact />;
    }
    console.log(this.props);
    console.log(this.state);

    return (
      <>
        <div className={classes.dFlex}>
          <div className={classes.loginArea}>
            <fieldset className={classes.fieldset}>
              <legend style={{ width: "auto", float: "none" }}>
                <img
                  src="/rmklogo.png"
                  alt="logo"
                  width="200px"
                  style={{ padding: "0px 10px", borderRadius: "20px" }}
                  height="75px"
                />
              </legend>
              <h1 className={classes.title}>
                Welcome to RMK College Student Portal!
                <br />
                Login to get started
              </h1>
              <p className={classes.subtext}>Please enter your roll number</p>
              <input
                onKeyDown={(event) => this.onRollNumberEnter(event)}
                className={classes.username}
                type="text"
                placeholder="Enter roll number"
                value={this.state.roll_number}
                onChange={this.onRollNumberChange}
              />
              {this.props.error_in_roll_number || this.props.is_user === 0 ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "0",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    width: "420px",
                  }}
                >
                  Please enter a valid roll number
                </p>
              ) : null}
              {this.state.roll_number_error ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "0",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "18px",
                    width: "420px",
                  }}
                >
                  Special Characters are no allowed!
                </p>
              ) : null}
              {this.props.password_disabled && this.state.password_disabled ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "0",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    width: "390px",
                    wordBreak: "break-word",
                  }}
                >
                 {this.props.error_message}
                </p>
              ) : null}
              {this.props.password_disabled && this.state.time > 0 ? (
                <p
                  style={{
                    margin: "0",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Please wait for {this.state.time} seconds.
                </p>
              ) : null}
              <button
                className={classes.loginButton}
                onClick={this.onSubmitMobileNo}
                disabled={this.state.password_disabled}
              >
                Continue
              </button>
              <div style={{ clear: "both" }}></div>
              <div
                style={{
                  float: "right",
                  marginRight: "-140px",
                  position: "relative",
                  top: "-22px",
                }}
              >
                <img src="/images/login/monkey.svg" width="170px" alt="" />
              </div>
            </fieldset>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roll_number: state.login.roll_number,
    login_otp_message: state.login.login_otp_message,
    user_logged_in_successfully: state.login.user_logged_in_successfully,
    error_in_roll_number: state.login.error_in_roll_number,
    error_getting_details: state.login.error_getting_details,
    error_message: state.login.error_message,
    resent_otp: state.login.resent_otp,
    has_password: state.login.has_password,
    is_user: state.login.is_user,
    password_updated_successfully: state.login.password_updated_successfully,
    error_updating_password: state.login.error_updating_password,
    user_password_details_message: state.login.user_password_details_message,
    user_password_status: state.login.user_password_status,
    login_through_otp: state.login.login_through_otp,
    login_through_password: state.login.login_through_password,
    got_user_password_details: state.login.got_user_password_details,
    got_user_details: state.login.got_user_details,
    error_loading_user_password_details:
      state.login.error_loading_user_password_details,
    password_disabled: state.login.password_disabled,
    last_incorrect_attempt: state.login.last_incorrect_attempt,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginOtp: (roll_number, resend) =>
      dispatch(getLoginOtpAC(roll_number, resend)),
    getUserDetails: (otp) => dispatch(getUserDetailsAC(otp)),
    openEnterRollnumberAgain: () =>
      dispatch({ type: OPENENTERROLLNUMBERAGAIN }),
    getPasswordDetails: (roll_number) =>
      dispatch(getUserPasswordDetailsAC(roll_number)),
    updatePassword: (roll_number, password) =>
      dispatch(updatePasswordAC(roll_number)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
