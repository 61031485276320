import React from 'react';
import styled from "styled-components";
import Flex from 'styled-flex-component';

const vars = {
    gray_color: '#9a9a9a',
    brand_color: '#673AB7'
  }
  

const Hex = () => (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 300 300">
      <polygon points="300,150 225,280 75,280 0,150 75,20 225,20" />
    </svg>
  );
  
const Hexagon = styled(Flex)`
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 3em;
      height: 3em;
      cursor: pointer;
      text-decoration: none;
      span {
        color: ${({ green, red }) =>
      green ? "white" : red ? "white" : "#000"};
        z-index: 1;
      }
    }
  
    svg {
      position: absolute;
      fill: ${({ green, red }) =>
      green ? "#22A122" : red ? "#f32643" : "#ccc"};
      width: 100%;
      height: 100%;
      polygon {
        stroke: ${({ active }) => (active ? vars.brand_color : "blue")};
        stroke-width: 10px;
        transition: all 0.3s esae;
      }
      transition: all 0.3s esae;
    }
  `;
const HexagonProgress = (props) => {
    return <div>{props.value}</div>
    // <Hexagon>
    //     <Hex />
        // <span>{props.value}</span>
    // {/* </Hexagon> */}
};

export default HexagonProgress;