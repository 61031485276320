import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import AppLayout from '../../components/Layout/Layout';
import moment from 'moment';
import Flex from 'styled-flex-component';
import styled from 'styled-components';
import GaugeChart from 'react-gauge-chart';
import { getSubjectiveResultsAC } from '../../store/actions/subjectiveResultsActions';
import { BarChart, Bar, Cell, XAxis, YAxis, Scatter, ResponsiveContainer, Text, ComposedChart,Tooltip} from 'recharts';
import Modal1 from './Modal';
import Modal from "react-bootstrap/Modal";
import ImageModal from './ImageModal';
import ReportDialog from '../../components/reportModal';
import Equation from '../../components/Equation';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {MdArrowBackIos} from "react-icons/md"
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Rectangle = () => (
    <svg height="220" width="320" fill='#fdfdfd'>
        <polygon points="320,10 320,160 160,220 10,160 10,10 320,10">
        </polygon>
        Sorry, your browser does not support inline SVG.
    </svg>
);

const RectangleWrapper = styled.div`
    // color: #FFFFFF;
    position: absolute;
    top: 100px;
    right: 3%;
    svg {
        // position: absolute;?
        display: block;
        // fill: #FFFFFF;
        width: 100%;
        height: 100%;
        filter: drop-shadow(5px 10px 20px #eee);
        polygon {
            stroke: #FFFFFF;
            // fill: #FFFFFF;
            stroke-width: 1px;
            transition: all 0.3s esae;
        }
        transition: all 0.3s esae;
    }
`
const Button=styled.div`
    text-align: left;
    height: 24px;
    // background: pink;
    width: 57px;
    padding-left: 3px;
    border-radius: 10px;
    cursor:pointer;
    font-size: 16px;
`
const GaugeContainer = styled.div`
    // position: absolute;
    width: 286px;
    // height: 150px;
    // right: 20px;
    // top: 100px;
    // background: url("/images/timer/guage_container.svg");
    // background-size: cover;
    // padding: 10px;
    box-sizing: border-box;
    margin: auto;
    position: relative;
`

class Results extends Component {
    state = {
        openAnswerKey: false,
        reportItemId: null,
        openReportDialog: false,
        itemToBeReported: null,
        openImage: false,
        image_url: null
    }
    componentDidMount = async () => {
        let test_id;
        if (this.props.location.state) {
            test_id = this.props.location.state.test_id;
        }
        console.log(this.props.location)
        let token = localStorage.getItem('token')
        if (!test_id) {
            if (window.location.search) {
                let search = window.location.search.slice(1);
                let queryParams = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,
                  '":"') + '"}');
                test_id = queryParams.test_id;
                token = queryParams.token;
                localStorage.setItem('token', token);
            }
        }
        // let test_id = 79;
        await this.props.getTestDetails(test_id);
    }

    openAnswerKeyHandler = (event, data) => {
        event.preventDefault();
        event.stopPropagation();
        // document.body.style.overflow='hidden';
        this.setState({openAnswerKey: true, answerKeyData: data})
    }

    closeAnswerKey = () => {
        this.setState({openAnswerKey: false, answerKeyData: null});
        document.body.style.overflow = 'auto';

    }

    reportItem = (item, id)=> {
        // document.body.style.overflow = 'hidden';
        this.setState({openReportDialog: true, itemToBeReported: item, reportItemId: id});
    }

    closeReport = ()=> {
        document.body.style.overflow = 'auto';
        this.setState({openReportDialog: false, itemToBeReported: null, reportItemId: null});
    }

    onImageClick = (url)=> this.setState({openImage: true, image_url: url});

    closeImage = () => this.setState({openImage: false, image_url: null});
    handleClose = () => {
        this.setState({openAnswerKey:false})
      };
      goback= () => {
        this.props.history.push({
            pathname: '/home',
        });
    }
    render () {
        console.log(this.props.questions);
        if (this.props.loading) {
            return <div>Loading....</div>
        }
        if (this.props.error_loading_results) {
           return <div>Error loading Test Details Please try again after some time </div>
        }
        console.log(this.props);
        let timelimit = this.props.timelimit;
        let hours = Math.floor(timelimit / (60 * 60));
        let seconds = (timelimit - hours * 60 * 60);
        let minutes;
        if (seconds > 60) {
            minutes = Math.floor(seconds/60);
            seconds = seconds - (minutes * 60);
        }
        let timelimit_modified = minutes && hours && seconds ? hours + 'hrs ' + minutes + 'mins ' + seconds + 'secs' : minutes && !hours && seconds ? minutes + 'mins ' + seconds + 'secs' : minutes  && !hours && !seconds ? minutes + 'mins' : hours && seconds && !minutes ? hours + 'hrs ' + seconds + 'secs' : !minutes && hours && !seconds ? hours + 'hrs' : !minutes && !hours && seconds ? seconds + 'secs' : hours && minutes && !seconds ? hours + 'hrs ' + minutes + 'mins' : '0';
        let marks = 0;
        let total_marks = 0;
        this.props.questions.map((que)=> {
            marks =  que.final_marks ? marks + que.final_marks : marks;
            total_marks = que.marks + total_marks;
            console.log(que.final_marks)
            console.log(marks)
        })
        let sections = this.props.sections;
        let marks_percent = marks/total_marks;
        
        return (
            <div>
                {/* <AppLayout></AppLayout> */}
                    <div>
                    <div style={{paddingLeft: '20px', backgroundColor: 'white'}}>
                        <div style={{display: 'flex', flexDirection: 'row',borderBottom:"1px solid rgba(0, 0, 0, 0.25)"}}>
                            <div style={{width: '70%', margin: '10px 0px 0px 10px',display:"flex",flexDirection:"column"}}>
                                <Button onClick={this.goback}><MdArrowBackIos />Back</Button>
                                <div style={{textAlign: 'left', fontSize: '24px', fontWeight: '600'}}>{this.props.name}</div>
                                <div style={{display: 'flex', flexDirection: 'row', margin: '10px 0', fontSize: '15px'}}>
                                    <div style={{marginRight: '15px'}}>Subjective</div>
                                    <div style={{borderRight: '1px solid #000'}}></div>
                                    <div style={{margin: '0 15px'}}>{moment(this.props.created_at).format('Do MMMM')}</div>
                                    <div style={{borderRight: '1px solid #000'}}></div>
                                    {/* <div style={{margin: '0 15px'}}>Start Date : {moment(test_details.start_time).format('Do MMM hh:mm A')}</div>
                                    <div style={{borderRight: '1px solid #000'}}></div> */}
                                    <div style={{margin: '0 15px'}}>Expiry Date : {moment(this.props.date_of_expiry).format('Do MMM hh:mm A')}</div>
                                    <div style={{borderRight: '1px solid #000'}}></div>
                                    <div style={{marginLeft: '15px'}}>
                                        <img style={{margin: 'auto'}} src='/images/test_timer.png'></img>
                                    </div>
                                    <div style={{margin: '0 15px'}}>{timelimit_modified}</div>
                                </div>
                            </div>
                            <div style={{width: '30%',margin: 'auto'}}>
                                <GaugeContainer>
                                    <div style={{ height: 100, overflow: 'hidden' }}>

                                        <GaugeChart id="gauge-chart5"
                                            animate={false}
                                            nrOfLevels={100}
                                            arcsLength={[0.1, 0.25, 0.25, 0.25, .15]}
                                            colors={['#D76D6B', '#FFA287', '#FFC200', '#23DD75', '#00D0FF']}
                                            percent={marks_percent}
                                            textColor={'#6852CB'}
                                            arcPadding={0.01}
                                            cornerRadius={5}
                                            formatTextValue={r => Math.round(r * total_marks/100) + `/${total_marks}`}
                                            style={{ height: 125, color: '#000', fontWeight: 700 }}
                                        />
                                    </div>
                                    <p style={{ textAlign: 'center', margin: 0, fontSize: 14, position: 'absolute', top: '77%', left: '50%', transform: 'translate(-50%, -50%)',fontWeight:500}}>Your Marks</p>
                                    <p style={{ textAlign: 'center', margin: 0 , marginTop: '20px',fontSize:14}}> Your average percentage is {Math.floor(marks/total_marks * 100)}%</p>
                                </GaugeContainer>
                            </div>
                        </div>
    
                        <div style={{marginTop: '20px'}}>
                            {this.props.questions.map((que, qid)=> {
                                return <div style={{marginBottom: '20px', display: 'flex', flexDirection: 'row'}}>
                                    <Flex row style={{marginLeft: '15px', width: '70%'}}>
                                        <div style={{fontWeight: 'bold', fontSize: '16px', marginRight: '8px'}}>
                                            {qid + 1}.
                                        </div>
                                        <Flex column style={{marginLeft: '10px', width: '100%'}}>

                                            <Flex row style={{marginBottom:'10px', textAlign: 'left',marginRight: '20px'}}>
                                                {/* <div>{que.question}</div> */}
                                                <div style={{width: '93%'}}>
                                                    <Equation fontSize={'14px'} math_type={1} bold equation={que.question}></Equation>
                                                </div>
                                                <div style={{width: '7%', margin: 'auto'}}>
                                                    <div style={{width: '100%'}}>
                                                        <img style={{marginLeft: '0',cursor:"pointer"}} onClick={()=>this.reportItem('subjective_questions', que.id)} src='/images/report.svg'></img>
                                                    </div>
                                                </div>
                                            </Flex>
                                        
                                            <div style={{border: '1px solid #969696', width: '90%', height: 'min-content', padding: '20px', borderRadius: '8px'}}>
                                                {que.evaluation_schema ?  <div>
                                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                                        <div>Answer Key </div>
                                                        <div style={{marginLeft: '10px',cursor:"pointer"}} onClick={(event)=>this.openAnswerKeyHandler(event, que.evaluation_schema)}>
                                                            <img src='/images/sub_answer_key.png' />
                                                        </div>
                                                        
                                                    </div>
                                                    <div style={{borderBottom: '1px solid #DDDDDD', margin: '10px 0 14px 0', width: '100%'}}></div>

                                                </div> : null}
                                                {que.answer && que.answer[0] != '<' ? <div style={{wordBreak: 'break-word',textAlign: 'left', fontSize: '18px', color: '#1D1A4B', height: que.evaluation_schema ?  'auto' : 'auto', overflow: 'auto'}}>{que.answer}</div> : 
                                                que.answer && que.answer[0] == '<' ? <div style={{wordBreak: 'break-word', height: que.evaluation_schema ?  '130px' : '130px', overflow: 'auto',textAlign:'left'}}>
                                                    <Equation fontSize={'4px'} math_type={1} equation={que.answer}></Equation>
                                                </div> : 
                                                <div style={{height: que.evaluation_schema ? '230px' : '270px'}}>No Answer submitted </div>    }
                                                <div style={{borderBottom: '1px solid #DDDDDD', margin: '20px 0 14px 0', width: '100%'}}></div>
                                                {que.answer_url.length > 0 ? 
                                                    <div className='row'>
                                                        {que.answer_url.filter(url => url ? true : false).map((url,i) => 
                                                        <div className='col-2 my-2 p-2' style={{cursor: 'pointer'}} onClick={() => this.onImageClick(url)}>
                                                            <img src={url} width="100px" height="100px" alt='answer' />
                                                        </div>)}
                                                    </div>
                                                : null}
                                                <div>
                                                    <div style={{textAlign: 'left', fontSize:'16px', fontWeight: '400'}}>{que.section_id ? `Section : ${sections[sections.findIndex((sec, index)=>sec.id == Number(que.section_id))].name}  | ` : '' } &nbsp;&nbsp; Max Score : { 1 * sections[sections.findIndex((sec, index)=>sec.id == que.section_id)].marks} &nbsp;&nbsp;| &nbsp;&nbsp;<span style={{color: '#4F55C8', fontSize: '16px', fontWeight: '600'}}>Your Score : {que.final_marks ? que.final_marks : 0}</span> </div>
                                                </div>
                                            </div>
                                        
                                        </Flex>
                                        
                                    
                                    </Flex>
                                    <div style={{width: '30%', margin: 'auto'}}>
                                        <div style={{marginTop: '40px'}} >
                                            <div  style={{boxShadow: '5px 10px 20px #eee', borderRadius: '11px', padding: '10px'}}>
                                                <ResponsiveContainer width="100%" height={280}>
                                                    <ComposedChart
                                                        data={que.graph_data}
                                                        margin={{
                                                            top: 20,
                                                            right: 30,
                                                            left: 20,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                                    <XAxis dataKey="percentage_level" padding={{left: 50, right: 50}}/>
                                                    <YAxis 
                                                        label={
                                                        <Text
                                                        x={0}
                                                        y={0}
                                                        dx={30}
                                                        dy={220}
                                                        offset={0}
                                                        angle={-90}>No of Students</Text>}
                                                        />
                                                    <Tooltip />
                                                    {/* <Legend /> */}
                                                    <Bar dataKey="value" barSize={15} radius={[8,8,0,0]} stackId="a" fill="#6852CB" />
                                                    {/* <Bar dataKey="average_time" barSize={15} radius={[8,8,0,0]} stackId="a" fill="#4E54C8" /> */}
                                                    <Scatter dataKey='student_percentage' fill="#FF723B" shape="square" />
                                                    </ComposedChart>
                                                </ResponsiveContainer>
                                                <div style={{display: 'flex', flexDirection: 'row',justifyContent:"center",fontSize:14,marginTop:-30}}>
                                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                                        <div style={{width: '10px', height: '10px', borderRadius: '50%', background: '#6852CB', margin: '5px 20px'}}></div>
                                                        <div>Students Count</div>
                                                    </div>
                                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                                        <div  style={{width: '10px', height: '10px', borderRadius: '50%', background: '#FF723B',  margin: '5px 20px'}}></div>
                                                        <div>Student Percentage</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    
                    </div>
                    {/* {this.state.openAnswerKey ? <Modal1 marginTop='30px' width='500px' height='70%' padding='20px' open={this.state.openAnswerKey} closeDialog={this.closeAnswerKey}>
                        <div style={{marginTop:'25px'}}>
                            <Equation math_type={1} equation={this.state.answerKeyData}></Equation>
                            
                        </div>
                    </Modal1> : null} */}
                    <Dialog
        open={this.state.openAnswerKey}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Answer Key
          </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <div style={{marginTop:'25px'}}>
                            <Equation math_type={1} equation={this.state.answerKeyData}></Equation>
                            
                        </div>
          </DialogContentText>
          </DialogContent>
          </Dialog>
        <Modal show={this.state.openReportDialog} onHide={this.closeReport}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:"16px"}}>Please select issue to be reported</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <ReportDialog
            open={this.state.openReportDialog}
            close={this.closeReport}
            reportItem={this.state.itemToBeReported}
            reportItemId={this.state.reportItemId}
          ></ReportDialog></Modal.Body>
      </Modal> 
                    {this.state.openImage && <ImageModal width='500px' height='70%' padding='none' style={{zIndex:12}} 
                        closeDialog={this.closeImage} open={this.state.openImage} url={this.state.image_url}>
                    </ImageModal>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.subRes.loading,
        created_at: state.subRes.created_at,
        date_of_expiry: state.subRes.date_of_expiry,
        description: state.subRes.description,
        name: state.subRes.name,
        questions: state.subRes.questions,
        sections: state.subRes.sections,
        timelimit: state.subRes.timelimit,
        subject_id: state.subRes.subject_id,
        test_id: state.subRes.test_id,
        error_loading_results: state.subRes.error_loading_results
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTestDetails: (test_id)=> dispatch(getSubjectiveResultsAC(test_id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Results));