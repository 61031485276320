import moment from 'moment';

const initial_state = {
    loading: true,
};

const Reducer = (state = initial_state, action)=> {
    if (action.type == 'GET_PRACTICE_TEST_RESULTS_DATA') {
        let results = action.results;
        let error = action.error;
        console.log(action)
        if (error) {
            return {
                ...state,
                loading: false,
                errorLoadingTestDetails: error
            }
        } else {
            console.log('no error');
            let summary = action.summary;
            let results = action.results;
            console.log(summary);
            console.log(results);
            let topic_wise_analysis_data = [];
            summary.topicWise_data.map((topic)=> {
                let topic_data = {};
                topic_data['name'] = topic.name;
                topic_data['correct'] = topic.correct_questions_count;
                topic_data['total'] = topic.total_questions_count;
                topic_data['percentage'] = parseFloat((topic.correct_questions_count / topic.total_questions_count) * 100).toFixed(0);
                topic_wise_analysis_data.push(topic_data);
            });
            let questions = results.questions;
            let time_analysis_data = []
            let total_time_taken = 0;
            questions.map((que, index)=> {
                let question_data = {}
                question_data['index'] = index +1;

                let timeTaken = 0;
                let timeTakenArray;
                if (!Array.isArray(que['timetaken'])) {
                    timeTakenArray = JSON.parse(que['timetaken'])
                } else {
                    timeTakenArray = que['timetaken'];
                }
                timeTakenArray.map((item) => {
                    timeTaken += +item[2]
                })
                question_data['time_taken'] = timeTaken;
                time_analysis_data.push(question_data);
                total_time_taken = total_time_taken + timeTaken;
            })
            
            return {
                ...state,
                loading: false,
                summary: summary,
                results: results,
                topic_wise_analysis_data: topic_wise_analysis_data,
                time_analysis_data: time_analysis_data,
                questions: questions,
                total_time_taken: total_time_taken,
                errorLoadingTestDetails: false
            }
        }
        
    }
    return {...state}
}

export default Reducer;