import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom";
import styled, { keyframes, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import Analyticskeletonloader from "../../dashboard/analyticsloadingskeleton"
const Leaderboardrow = styled.div`
    display: flex;
    font-size: 10px;
   align-items: center;
    font-weight: 600;
    border-bottom: 1px solid #80808052;
    padding: 12px 0px 12px 0px;
    padding-left: 0px !important;
  `;
function Subjectscreenloader(props) {

    return (
        <>
        <div className="col-lg-12 row" style={{background:"white"}}>
        <div className="col-lg-9">
   <div className="mainBox">
    <div className="studentDiv" style={{padding: '13px' , fontSize: '14px' , fontWeight: 600}}>
    <Skeleton width="40%" height={25} />
    <div style={{display: 'flex', marginTop: '20px',alignItems:"center"}}>
    <div style={{width: '70%'}} >
        <Skeleton width="70%" height={150} />
    </div>
    <div style={{width: '30%'}}> 
        <Skeleton width="65%" height={40} />
    </div>
  </div>
  <div>
    <div style={{borderBottom: '1px solid rgb(238, 238, 238)' , margin: '10px 0px' }} />
    <div className="col-lg-12 row" style={{margin: '25px 0px 0px 0px'}}>
        <div className="col-lg-2">
        <Skeleton width="100%" height={130} inline={true} />
        </div>
        <div className="col-lg-2">
        <Skeleton width="100%" height={130} inline={true} />
        </div>
         <div className="col-lg-2">
        <Skeleton width="100%" height={130} inline={true} />
        </div>
        <div className="col-lg-2">
        <Skeleton width="100%" height={130} inline={true} />
        </div>
        <div className="col-lg-2">
        <Skeleton width="100%" height={130} inline={true} />
        </div>
        <div className="col-lg-2">
        <Skeleton width="100%" height={130} inline={true} />
        </div>

    
    </div>
    <div style={{marginTop:"20px"}}>
    <Skeleton width="100%" height={25} />
    <Skeleton width="70%" height={15} />
    <Skeleton width="70%" height={15} />
    <Skeleton width="70%" height={15} />
    <Skeleton width="70%" height={15} />
    <Skeleton width="70%" height={15} />
    </div>
    <div style={{marginTop:"20px"}}>
    <Skeleton width="100%" height={25} />
    <Skeleton width="70%" height={15} />
    <Skeleton width="70%" height={15} />
    <Skeleton width="70%" height={15} />
    <Skeleton width="70%" height={15} />
    <Skeleton width="70%" height={15} />
    </div>
</div>
  
    </div>
</div>
</div>
<div  className="rightpane"
          style={{
            background: "#f7f7f7",
            width: "25%",
            paddingLeft: "22px",
          }}> 
         
             <Analyticskeletonloader />:
             
      </div>
</div>

<style jsx="true" scoped>
          {`
            .unitBox {
              border: 1px solid #fff;
              box-shadow: 0 0 5px #ddd;
              padding: 5px 15px;
              margin: 10px;
              margin-bottom: 20px;
            }
            .mainBox {
              display: block;
              width: 100%;
              background-color: white;
              border-radius: 8px;
              margin-top: 20px;
              text-align: left;
              padding: 2px;
              box-sizing: border-box;
              box-shadow: 0 0 10px #ccc;
            }
            .circleGradient {
              width: 11px;
              height: 11px;
              background-image: linear-gradient(
                to right top,
                #f38fa2,
                #f28699,
                #f17c8f,
                #f07386,
                #ee697c,
                #ee6776,
                #ee666f,
                #ee6569,
                #f06c65,
                #f17362,
                #f17b5e,
                #f1825c
              );
              border-radius: 50%;
              margin-top: 5.5px;
              margin-right: 10px;
            }
            .recommended {
             border-radius: 5px;
             padding: 5px 5px 5px 10px;
             background: white;
             cursor:pointer;
            }
            .blueDeck {
              background:rgba(82, 195, 255, 0.58);
              border-radius: 5px;
              box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.163981);
              margin-right: 20px;
              padding: 0 0 4px 4px;
              height: 100%;
            }
            .orangeDeck {
              background: rgba(255, 203, 87, 0.68);
              border-radius: 5px;
              box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.163981);
              margin-right: 20px;
              padding: 0 0 4px 4px;
              height: 100%;
            }
            .greenDeck {
              background: rgba(252, 119, 160, 0.58);
              border-radius: 5px;
              box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.163981);
              margin-right: 20px;
              padding: 0 0 4px 4px;
              height: 100%;
            }
             .tabbtn {
              margin-right: 15px;
              font-size: 12px;
              background: transparent;
              border: none;
              outline: none;
              padding: 10px 20px;
              cursor: pointer;
              color: rgba(0, 0, 0, 0.4);
              transition: all ease-in-out 0.2s;
              border-bottom: 2px solid transparent;
            }
            .active {
              border-bottom: 2px solid rgba(252, 119, 160, 0.58);
              color: black;
            }
            .leaderboardrank {
    border-radius: 34px;
    width: 20px;
    height: 20px;
    padding: 3px;
    background: #fff;
    /* border: 1px solid #666; */
    color: black;
    text-align: center;
    font: 12px Arial, sans-serif;
}
.activetab_rightpanel{
    background: white;
    border-radius: 13px;
    font-size: 11px;
    padding: 3px 9px 2px 10px;
    font-weight: 500;
    margin: 3px;
    color: rgb(233, 151, 165);
}
.inactivetab_rightpanel{
    border-radius: 8px;
    font-size: 11px;
    padding: 5px 1px 0px 0px;
    font-weight: 500;
    color: white;
    cursor: pointer;
}
          `}
        </style>
        </>
        
    );
}


export default Subjectscreenloader;
// export default Singleteamdetails;
