import moment from 'moment';
import {ONTESTSUBMIT, TESTSUBMITLOAD} from '../actions/questionnaireActions';

const initial_state = {
    loading: true,
};

const reducer = (state = initial_state, action) =>{
    if (action.type == 'GET_TEST_DETAILS') {
        if (action.error) {
            return {
                ...state,
                error_loading_test : true,
                loading_test: false
            }
        }
        console.log(action.response);
        if (action.test_type == 'mcq') {
            return {
                ...state,
                ...action.response,
                error_loading_test : false,
                loading: false,
                doe: action.response.date_of_expiry,
                type: action.test_type
            }
        } else if (action.test_type == 'coding') {
            return {
                ...state,
                ...action.response.data,
                error_loading_test : false,
                loading: false,
                doe: action.response.date_of_expiry,
                type: action.test_type
            }
        } else {
            return {
                ...state,
                ...action.response,
                error_loading_test : false,
                loading: false,
                doe: action.response.date_of_expiry,
                type: action.test_type
            }
        }
        
    }
    return {...state}
}

export default reducer;