import React, { useEffect, useRef, useState } from 'react';
import '../fonts/axiforma.css';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';

const ThreeLineText = ({text, font_family, math_type}) => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(null);
//   const text = "The question of the day is brought to you by Edwisely. Please read through the question thoroughly before answering it. The options may be tricky, but we know that you can solve it! All the best and best of luck in your future endeavors. sodvsvi sidvnisnbdv sdbnisdbnv sdvibnsdivnj adbnvaiobdvioa afibf";

  useEffect(() => {
    // Get the initial container width
    const container = containerRef.current;
    const initialContainerWidth = container.clientWidth;

    // Update the container width state
    setContainerWidth(initialContainerWidth);

    // Handle window resize to dynamically adjust container width
    const handleResize = () => {
      const newContainerWidth = container.clientWidth;
      setContainerWidth(newContainerWidth);
    };

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const calculateCharsPerLine = () => {
    // Adjust as needed for font size and padding
    const padding = 10; // You can adjust this value based on your design
    const containerContentWidth = containerWidth;
    const minCharsPerLine = 20; // Minimum characters per line
    const charsPerLine = Math.max(
      minCharsPerLine,
      Math.floor(containerContentWidth / 6) // Adjust as needed
    );

    return charsPerLine;
  };

  const splitTextIntoLines = () => {
    const charsPerLine = calculateCharsPerLine();

    const words = text.split(' ');
    let currentLine = '';
    const lines = [];

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (currentLine.length + word.length + 1 <= charsPerLine) {
        currentLine += (currentLine === '' ? '' : ' ') + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }

    // Push the remaining line
    if (currentLine !== '') {
      lines.push(currentLine);
    }

    // Limit to 3 lines
    return lines.slice(0, 3);
  };

  return (
    <div
      style={{
        lineHeight: '1.2em',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        whiteSpace: 'nowrap', 
      }}
      ref={containerRef}
    >
      {splitTextIntoLines().map((line, index) => (
        <div key={index} style={{ color: index < 2 ? 'black' : '#d0d0d0', background: index < 2 ? '' : 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1) 40%, rgba(255,255,255,1)) 70%', textAlign: 'left', fontSize: '12px', fontFamily: font_family === 'Axiforma' ? 'Axiforma, sans-serif' : 'inherit', fontWeight: '300', lineHeight: '22.5px', border: 'none' }}>
          {math_type == 0 &&
          <span dangerouslySetInnerHTML={{ __html: line }} />
        }
        {math_type == 1 &&
          <Latex>{line}</Latex>
        }
        </div>
      ))}
    </div>
  );
};

export default ThreeLineText;