import { styled } from '@mui/material/styles';
import { Container, Typography, Box, Grid, Button } from '@mui/material';
import colors2 from './colors2';

//coding track level select styling
export const StyledGrid = styled(Grid)(
    ({ theme }) => ({
        marginTop: 60,
        // width: '80%',
        // height: 550,
        marginLeft: '214px',
        zIndex: '999',
        minHeight: '90vh',
    })
);

export const VerticalLine = styled(Box)(
    ({ theme }) => ({
        position: 'absolute',
        left: '-10px',
        width: '2px',
        height: '100%',
        background: '#d9d9d9',
    })
);

//coding track (individual track styling)
export const BackButton = styled(Button)(({ theme }) => ({
    minWidth: '20px',
    height: '30px',
    background: colors2.common.white,
    borderRadius: '5px',
}));
