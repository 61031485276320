import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import {ADDCERTIFICATE, itemUpdateRemoveAddAC} from '../../store/actions/profileActions';
import styled from 'styled-components';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 25%);

    @media(max-width: 1180px) {
        grid-template-columns: repeat(2, 50%);
    }

`;

const SaveButton = styled.button`
    border-radius: 32px;
    background:  ${({ active }) => (active ? "linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%)" : "grey")};
    margin: auto;
    text-align: center;
    width: 98px;
    padding: 8px;
    color: white;
    margin-right: 0;
    border: none;
`

class AddCertificate extends Component {

    state = {
        openContent : false,
        contentUrl: '',
        contentType: null,
        title: null,
        company: null,
        location: null,
        start_year: null,
        end_year: null,
        description: null,
        files: null
    }

    onChangeHandler = (event, type)=> {
        event.stopPropagation();
        event.preventDefault();
        
        this.setState({[type] : event.target.value});
    }

    onFileChangeHandler = (event) => {
        this.setState({files: event.target.files})
    }

    onEndDateChange = (date) => {
        try {
            let dateFormat = moment(date).format('MMM yyyy').toString();
            
            if (this.state.start_time) {
                if (date < this.state.start_time) {
                    this.setState({ endTimeError: true, endTimeErrorMessage: 'end time cannot be lesser than start year' })
                } else {
                    this.setState({ end_time: date, end_year: dateFormat, endTimeError: false, endTimeErrorMessage: '' });

                }
            } else {
                if (dateFormat) {
                    this.setState({  endTimeError: true, endTimeErrorMessage: 'please select start year first' });
                }
            }
        } catch (error) {
            console.log(error);
        }
        this.forceUpdate()
    }

    onStartDateChange = (date) => {
        try {
            let dateFormat = moment(date).format('MMM yyyy').toString();
            
            if (this.state.end_time) {
                if (date > this.state.end_time) {
                    this.setState({ startTimeError: true, startTimeErrorMessage: 'start year cannot be greater than end year' })
                } else {
                    this.setState({ start_time: date, start_year: dateFormat, startTimeError: false, startTimeErrorMessage: '' });

                }
            } else {
                this.setState({ start_time: date, start_year: dateFormat, startTimeError: false, startTimeErrorMessage: '' });
            }
            
        } catch (error) {
            console.log(error);
        }
        this.forceUpdate()
    }

    closeCertificateEditor = () => {
        this.setState({
            title: null,
            company: null,
            location: null,
            start_year: null,
            end_year: null,
            description: null,
            files: null
        });
        this.props.clearStatus();
        this.props.close();
    }

    addCertificateHandler = () => {
        const formdata = new FormData();
        formdata.append('title', this.state.title);
        formdata.append('company', this.state.company);
        formdata.append('location', this.state.location);
        formdata.append('description', this.state.description);
        formdata.append('from_date', this.state.start_year);
        formdata.append('to', this.state.end_year);
        formdata.append('is_current', 0);
        formdata.append('attachments', '');
        if (this.state.files && this.state.files.length != 0) {
            Array.from(this.state.files).map((file)=> {
              console.log(file)
              formdata.append('attachments', file);
            })
        }

        let item = {};
        item.type = ADDCERTIFICATE;
        item.title = this.state.title;
        item.company = this.state.company;
        item.location = this.state.location;
        item.description = this.state.description;
        item.from_year = this.state.start_year;
        item.to_year = this.state.end_year;
        item.files = this.state.files;


        this.props.addCertificate(item, formdata);
    }
    

    render () {
        console.log(this.state);
        console.log(this.props);
        return (
            <div style={{}}>
                <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '20px'}}>Add Certificate</div>

                <div style={{border: '1px solid #eee', borderRadius: '4px',  textAlign: 'left', padding: '10px', margin: '20px 0'}}>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Title : </div>
                        <div style={{width: '80%'}}>
                            <input onChange={(event)=>this.onChangeHandler(event, 'title')} value={this.state.title} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Company : </div>
                        <div style={{width: '80%'}}>
                            <input onChange={(event)=>this.onChangeHandler(event, 'company')} value={this.state.company} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Location : </div>
                        <div style={{width: '80%'}}>
                            <input onChange={(event)=>this.onChangeHandler(event, 'location')} value={this.state.location} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px', alignItems: 'flex-end'}}>
                        <div style={{marginRight: '20px', width: '50%', display: 'flex', flexDirection: 'row',}}>
                            <div style={{width: '40%', marginRight: '20px',textAlign: 'right'}}>Start Year : </div>
                            <div style={{width: '60%'}}>
                                <DatePicker
                                    selected={this.state.start_time}
                                    onChange={this.onStartDateChange}
                                    showMonthYearPicker
                                    dateFormat="MMM yyyy"
                                />
                                {/* <input onChange={(event)=>this.onChangeHandler(event, 'start_year')} value={this.state.start_year} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input> */}
                            </div>
                        </div>
                        <div style={{width: '50%',display: 'flex', flexDirection: 'row',}}>
                            <div style={{width: '40%', marginRight: '20px',textAlign: 'right'}}>End Year : </div>
                            <div style={{width: '60%'}}>
                                <DatePicker
                                    selected={this.state.end_time}
                                    onChange={this.onEndDateChange}
                                    showMonthYearPicker
                                    dateFormat="MMM yyyy"
                                />
                                {/* <input onChange={(event)=>this.onChangeHandler(event, 'end_year')} value={this.state.end_year} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input> */}
                            </div>
                        </div>
                        
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Description : </div>
                        <div style={{margin: '10px 0 20px auto', width: '80%'}}>
                            <textarea onChange={(event)=>this.onChangeHandler(event, 'description')} value={this.state.description} style={{width: '80%', height: '70px',border: '1px solid #bbb',borderRadius: '4px'}} required></textarea>
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '20%', marginRight: '20px',textAlign: 'right'}}>Upload PDFs, Images : </div>
                        <div style={{margin: 'auto', width: '80%'}}>
                            <input multiple type='file' onChange={(event)=>this.onFileChangeHandler(event)} style={{width: '100%', height: '22px'}} required></input>
                        </div>
                    </div>
                    {this.state.files && this.state.files.length != 0 ? <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 33%)', margin: '20px 10px'}}>
                        {Array.from(this.state.files).map((file)=> {
                            return <iframe width='90%' style={{margin: '10px'}} src={URL.createObjectURL(file)} />
                        })}
                    </div> : null}
                    {this.props.added_certificate_successfully? <div style={{color: 'green', textAlign: 'left'}}>Certificate Added Successfully.</div> : null}
                    
                    {this.props.added_certificate_successfully?  
                    <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto', marginTop: '25px'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeCertificateEditor}>OK</div>
                    </div>
                    :<div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeCertificateEditor}>Cancel</div>
                        <div>
                            <SaveButton active={this.state.title && this.state.title != ''  && this.state.company && this.state.company != '' && this.state.location && this.state.location != '' && this.state.start_year && this.state.end_year && moment(this.state.end_year) > moment(this.state.start_year) }  onClick={this.addCertificateHandler}>Save</SaveButton>
                        </div>
                    </div>}
                </div>
                <style jsx scoped="true">{`
                    .react-datepicker-wrapper,
                    .react-datepicker__input-container,
                    .datePicker input {
                        width: 100%;
                        // border: 0;
                        height: 22px;
                        border-radius: 4px;
                    }
                
                
                `}</style>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.prof.loading,
        name: state.prof.name,
        error_adding_certificate: state.prof.error_adding_certificate,
        added_certificate_successfully: state.prof.added_certificate_successfully,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addCertificate: (item, formdata) => dispatch(itemUpdateRemoveAddAC(item, formdata)),
        clearStatus: () => dispatch({type:'CLEAR_ADD_CERTIFICATE_STATUS'})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddCertificate));