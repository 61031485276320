import * as React from 'react'
import 'katex/dist/katex.min.css';
import '../fonts/axiforma.css';
import Latex from 'react-latex-next';

class Equation5 extends React.Component {
  render() {
    let equation = this.props.equation;
    let math_type = this.props.math_type;
    let font_size = this.props.font_size;
    if (!equation) return null;
    equation = equation.toString();
    const latexStyle = {
      fontSize: font_size ? font_size : '12px',
      fontFamily: this.props.font_family === 'Axiforma' ? 'Axiforma, sans-serif' : 'inherit',
      fontWeight: this.props.bold ? '600' : this.props.fontWeight ? this.props.fontWeight : '300',
      color: this.props.answered ? '#4E54C8' : this.props.color ? this.props.color : 'black',
      textAlign: this.props.alignment ? this.props.alignment : 'left',
    };

    return (
      <div className="equation" style={{display: this.props.display ? this.props.display : '', color: this.props.answered ? '#4E54C8' : this.props.color ? this.props.color : 'black', fontFamily: this.props.font_family === "Axiforma" ? 'Axiforma, sans-serif' : 'inherit', fontWeight: this.props.bold ? '600' : this.props.fontWeight ? this.props.fontWeight : '300', fontSize: this.props.fontSize ? this.props.fontSize : '12px', textAlign: this.props.alignment ? this.props.alignment : 'left', flexGrow: math_type === 1 ? 1 : 0}}>
        
        {math_type == 0 &&
          <span style={{fontSize:font_size ? font_size :'12px'}} dangerouslySetInnerHTML={{ __html: equation }} />
        }
        {math_type == 1 &&
          <Latex style={latexStyle}>{equation}</Latex>
        }
        <style jsx='true' scoped>{`
          .equation{
            text-align: ${this.props.alignment ? this.props.alignment : 'left'};
            width: ${this.props.width ? this.props.width : 'auto'};
            line-height: 22.5px;
            font-size: 12px;
            font-weight: 500;
            word-break: break-word;
            text-align: left;
            // font-weight: ${ 'bold' in this.props ? this.props.bold : '500'};
          }
          p{
            font-size: 12px;            
          }
        `}
        </style>
      </div>
    )
  }
}
export default Equation5;