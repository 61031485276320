import React, { Component } from 'react';
import { config } from "react-spring";

import styles from './PreviewCard.module.css';
import Carousel from "../Carousel/Carousel";

import PreviewCardItems from '../PreviewCardItems/PreviewCardItems';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import axios from 'axios';
import Equation from '../../Equation';


class PreviewCard extends Component {

  state = {
    goToSlide: 0,
    offsetRadius: 4,
    showNavigation: true,
    config: config.stiff,
    slides: [],
    selectedOptions:{},
    loadedCS: {}
  };

  componentDidMount = async ()=> {
    await this.getSlides();
    this.setState({selectedOptions: {}});
  }

  componentDidUpdate = async (prevProps,prevState) => {
    if (prevProps.deck_data !== this.props.deck_data || prevState.selectedOptions !== this.state.selectedOptions) {
      await this.getSlides();
    }
  }

  optionSelected = (id,question_id) => {
    const selectedOptions = {...this.state.selectedOptions};
    selectedOptions[question_id] = id;
    this.setState({selectedOptions: selectedOptions});
  }

  getSlides = async () =>{
    const data = this.props.deck_data;
    const slides = []
    let gradients = ['rgba(252, 119, 160, 0.58)', 'rgba(255, 203, 87, 0.68)', 'rgba(82, 195, 255, 0.58)']
    // const CORS = 'https://cors-anywhere.herokuapp.com/';
    const loadedCS = {...this.state.loadedCS};
    if (data) {
      for (let i = 0; i < data.length; i++) {
        const randomGrad = Math.floor(Math.random() * gradients.length);
        const deckItem = data[i];
        if(deckItem.type === "cs"){
          if(!loadedCS[deckItem.id]){
            await axios.get(`${deckItem.url}`).then(res => {
              // console.log(res);
              const item = {
                key: i,
                content: <PreviewCardItems gradient={gradients[randomGrad]}>
                  <Equation math_type={(res.data.includes("$") ? 1 : 0)} bold equation={res.data}></Equation>
                </PreviewCardItems>,
                onClick: () => this.setState({ goToSlide: i })
              }
              loadedCS[deckItem.id] = item;
              slides.push(item);
            });
          }
          else slides.push(loadedCS[deckItem.id]);
        }
        else if(deckItem.type === "question"){
          slides.push({
            key: i,
            content: <PreviewCardItems gradient={gradients[randomGrad]}>
              <div className='row mb-2'>
                <div className='col-12'>
                  {deckItem.question_image ? 
                    <div className='row'>
                      <div className='col-4'>
                          <img src={deckItem.question_image} alt="question" style={{width: "100%",cursor: 'pointer'}}/>
                      </div>
                      <div className='col-8'>
                          <Equation math_type={(deckItem.name.includes("$") ? 1 : 0)} bold equation={deckItem.name}></Equation>
                      </div>
                    </div>
                  : <Equation math_type={(deckItem.name.includes("$") ? 1 : 0)} bold equation={deckItem.name}></Equation>
                }
                </div>
                {deckItem.question_options.map(option => 
                  <div className='col-6 my-2' key={option.id}>
                    <div className={'qotdOption' + (this.state.selectedOptions[option.question_id] ? 
                        this.state.selectedOptions[option.question_id] === option.id ? option.is_answer ? ' selectedCorrect' : ' selectedWrong' : '' : '')}>
                      {option.option_img ? 
                        <div className='row'>
                          <div className='col-4'>
                              <img src={option.option_img} alt="option" style={{width: "100%",cursor: 'pointer'}}/>
                          </div>
                          <div className='col-8' onClick={() => this.optionSelected(option.id, option.question_id)}>
                              <Equation color="white" math_type={option.name.includes("$") ? 1 : 0} bold equation={option.name}></Equation>
                          </div>
                        </div>
                      : <div onClick={() => this.optionSelected(option.id, option.question_id)}>
                          <Equation color="white" math_type={option.name.includes("$") ? 1 : 0} bold equation={option.name}></Equation>
                        </div>}                      
                    </div>
                  </div>
                )}
              </div>
            </PreviewCardItems>,
            onClick: () => this.setState({ goToSlide: i })
          })
        }

      }
    }
    // console.log(slides);
    this.setState({slides: slides, loadedCS: loadedCS});
  }

  render() {
    // console.log(this.state.slides);
    // console.log(this.props)
    // console.log(this.state);
    let dialog = null;

    if (this.state.slides.length > 0) {
      dialog = (
        <div className={styles.overlay}>
          <div className={styles.PreviewCard}>
            <div className={styles.CardTitleContainer}>
              <h3 className={styles.CardTitleText}>{this.props.deck.name}</h3>
              {!this.props.hideBookmark && this.props.deck.bookmarked && this.props.addOrDeleteBookmark  ? <img alt='bookmark' style={{ marginLeft: 30, marginTop: '5px' }} onClick = {(event)=>this.props.addOrDeleteBookmark(event,this.props.deck.unit_id, this.props.deck.id, 'decks')} src='/images/bookmarked.svg'></img>
              : !this.props.hideBookmark && this.props.addOrDeleteBookmark  ? <img alt='unbookmark' style={{ marginLeft: 30, marginTop: '5px' }} onClick = {(event)=>this.props.addOrDeleteBookmark(event, this.props.deck.unit_id, this.props.deck.id, 'decks')} src='/images/bookmark.svg'></img> : null}
              {this.props.hideReport ?<img style={{margin: '5px 10px 0 10px'}} onClick={(event)=>this.props.reportItem(event,'learning_snippets', this.props.deck.id)}  src='/images/report.svg' alt="report icons" /> : null}
              <span className={styles.Spacer}></span>
              <img  src='/images/decks/close.svg' onClick={this.props.onClose} style={{cursor: 'pointer'}} alt="close" />
            </div>
            <div style={{ width: "100%", height: "475px", margin: "0 auto" }}>
              <Carousel
                slides={this.state.slides}
                goToSlide={this.state.goToSlide}
                offsetRadius={this.state.offsetRadius}
                showNavigation={this.state.showNavigation}
                animationConfig={this.state.config}
              />
            </div>
          </div>
          <style jsx scoped={true}>
            {`
              .qotdOption{
                background:
                    linear-gradient(131.37deg, #7175E0 -8.1%, #4D50CB 45.77%, #E25BC8 155.59%) padding-box, 
                    linear-gradient(131.37deg, #7175E0 -8.1%, #4D50CB 45.77%, #E25BC8 155.59%) border-box;
                width: auto;
                border-radius: 24px;
                padding: 8px 15px;
                font-size: 10px;
                height: min-content;
              }
              .selectedCorrect{
                background: #22A021 !important;
              }
              .selectedWrong{
                background: #F32642 !important;
              }
            `}
          </style>
        </div>
      );
    }

    if (this.state.slides.length === 0) {
      dialog = (<div className={styles.overlay}>
        <Loader
        type="ThreeDots"
        color="#eee"
        height={100}
        width={100}//3 secs
      />
      </div>)
    }

    if (!this.props.isOpen) {
      dialog = null;
    }
    return (
      <div>
        {dialog}
      </div>
    );
  }
}

export default PreviewCard;