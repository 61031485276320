import React from 'react';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import { Box,Popover } from '@mui/material';

const SearchResultList = ({ searchResults, handleRecordClick, handleInputChange, searchQuery,loading }) => {

    const handleclick = (id, value) => {
        console.log(id)
        handleRecordClick(id, value);
        // handleInputChange(value);
    };
    // console.log(searchResults)
    const renderValueWithBoldQuery = (value) => {
        console.log(value)
        const index = value.toLowerCase().indexOf(searchQuery.toLowerCase());
        if (index === -1) {
            return <Typography>{value}</Typography>;
        } else {
            return (
                <Typography>
                    {value.substring(0, index)}
                    <b>{value.substring(index, index + searchQuery.length)}</b>
                    {value.substring(index + searchQuery.length)}
                </Typography>
            );
        }
    };

    const renderSkills = (skills) => {
        if (!skills || skills.length === 0) {
            return null;
        }
        // console.log(skills)
        return (
            <Typography variant="body2" style={{ fontSize: '10px' }}>
                skills:{" "}
                {skills.map(({skill_id, skill_name}, index) => (
                    <span key={index}>
                        {index > 0 && ", "}
                        {skill_name.split('').map((char, charIndex) => (
                            char.toLowerCase() === searchQuery.toLowerCase()[charIndex] ? (
                                <b key={charIndex}>{char}</b>
                            ) : (char)
                        ))}
                    </span>
                ))}
            </Typography>
        );
    };
    

    return (
        <Box
            style={{
                width: '496px',
                padding: '10px',
                
            }}
        >
             {/* <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          width: '496px',
          borderRadius: '10px',
          padding: '10px',
          boxShadow: '4px 4px 15px 0px rgba(0, 0, 0, 0.05)',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      > */}
            {!loading && (searchResults== 'No results found' || searchResults?.length==0) ? (
                "No Results Found"
            ) :!loading ? (
                searchResults.map((result, index) => (
                    <ListItem
                        key={result.id}
                        onClick={() => handleclick(result.career_id, result.career_name)}
                        style={{
                            cursor: 'pointer',
                            padding: '10px',
                            margin: '6px 0px',
                            borderRadius: '5px',
                            width: '480px',
                            height: 'auto',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                        sx={{
                            '&:hover': { backgroundColor: '#F4F6F8' },
                        }}
                    >
                        {renderValueWithBoldQuery(result.career_name)}
                        {renderSkills(result.skills)}
                    </ListItem>
                ))
            ):null}
        </Box>
    );
};

export default SearchResultList;