import React, { Component } from 'react';

class Dialog extends Component {
  state = {
    open: false
  }

  //to close dialog box
  closeDialog = (e) => {
    try {
      if(e.target.nodeName !== "IMG"){
        document.body.style.overflow = 'auto';
        this.props.closeDialog();
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {

    return <>
      <div>
        <div id="myModal" className="modal" style={{ display: this.props.open ? 'block' : 'none' }} onClick={(e) => this.closeDialog(e)}>
          <div className="modal-content">
            <div style={{position: 'relative', width: '100%'}}>
                <img style={{width: '100%', height: '100%'}} src={this.props.url} alt="url"/>
                <div style={{position: 'absolute', top: '0', right: '10px'}}>
                    <span className="close" onClick={(e) => this.closeDialog(e)} >&times;</span>

                </div>
            </div>
          </div>
        </div>
        <style jsx scoped='true'>{`
                    .modal {
                    --tw-bg-opacity: 1;
                    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
                    --tw-bg-opacity: 0.5;
                    height: 100%;
                    position: fixed;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    left: 0px;
                    width: 100%;
                    z-index: 1001;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  
                  /* Modal Content */
                  .modal-content {
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    margin: auto;
                    margin-top: 50px;
                    width: 500px;
                    padding: none;
                    background: #ffffff;
                    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.199437);
                    border-radius: 8px;
                    box-sizing: border-box;
                    overflow: auto;
                  }
                  
                  /* The Close Button */
                  .close {
                    color: #aaaaaa;
                    float: right;
                    font-size: 28px;
                    font-weight: bold;
                  }
                  
                  .close:hover,
                  .close:focus {
                    color: #000;
                    text-decoration: none;
                    cursor: pointer;
                  }
                `}</style>
      </div>
    </>
  }
}

export default Dialog;