import React, { useEffect, useState } from 'react';
import { Box, IconButton, Paper, Slide } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Question from '../../features/PersonalityAssessment/question';
import { useDispatch } from 'react-redux';
import colors from '../../pages/PersonalityAssessment/colors';
import { useSelector } from 'react-redux';
import PersonalityPopover from '../../features/PersonalityAssessment/personalityPopover';
import { closeNotification } from '../../store/actions/commonActions';

const NotificationPrompt = (props) => {
  const [open, setOpen] = useState(true);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const openNotificationPopover = useSelector(
    (state) => state.common.openNotificationPopover
  );
  const notificationPopoverType = useSelector(
    (state) => state.common.notificationPopoverType
  );
  const trigger_id = useSelector(
    (state) => state.common.trigger_id
  );
  // const {openNotificationPopover,notificationPopoverType, triggerId} = useSelector(state=>state.Common)
  // const openNotification = true;

  useEffect(() => {
    return () => {
      dispatch(closeNotification());
    }
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const checkNotificationType = (notificationPopoverType) => {
    if (notificationPopoverType === "Personality") {
      return <PersonalityPopover trigger_id={trigger_id}/>;
    }
  }

  return (
    <>
      { openNotificationPopover && props.number === trigger_id ? 
      <Box
        sx={{
          position: 'fixed',
          bottom: '110px',
          right: '0px',
          width: '370px',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          padding: '0px',
          zIndex: 999,
        }}
      >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
              <IconButton
                sx={{
                  position: 'absolute',
                  right: open ? '360px' : '0px',
                  width: '10px',
                  margin: 0,
                  height: '80px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  borderRadius: '10px',
                  backgroundColor: colors.primary,
                  boxShadow: '2px 3px 4px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 15px 0px rgba(0, 0, 0, 0.10), 0px -2px 4px 0px rgba(0, 0, 0, 0.15) inset',
                  transition: open ? 'right 0.51s cubic-bezier(0, 0, 0.2, 1)' : 'right 0.55s ease-in-out',
                  '&:hover': {
                    backgroundColor: colors.primary, 
                  },
                }}
                onClick={toggleDrawer}
              >
                {open ? <ArrowForwardIosRoundedIcon sx={{color: colors.common.white, fontSize: '12px'}}/> : <ArrowBackIosRoundedIcon sx={{color: colors.common.white, fontSize: '12px'}}/>}
              </IconButton>
              <Slide direction="left" timeout={550} in={open} mountOnEnter unmountOnExit={false}>
                <Paper
                  elevation={3}
                  sx={{
                    borderRadius: '21px 0px 0px 21px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    border: '1px solid #F5F5F5',
                    boxShadow: '2px 3px 4px 0px rgba(0, 0, 0, 0.10) inset, 2px 2px 15px 0px rgba(0, 0, 0, 0.10), 0px -2px 4px 0px rgba(0, 0, 0, 0.15) inset',
                  }}
                >
                  {checkNotificationType(notificationPopoverType)}
                </Paper>
              </Slide>
          </Box>
      </Box>    
      : null}
    </>
  );
};

export default NotificationPrompt;