import { Box, Container } from '@mui/material'
import React from 'react'

const RemedialContainer = ({ children, width='100%',height='100%' }) => {
    return (
        <Box maxWidth={'100%'} sx={{
            padding: '40px 28px',
            width:width,
            height:height,
            background: theme => theme.palette.grey[0]
        }}>
            {children}
        </Box>
    )
}

export default RemedialContainer