import axios from '../../interceptor';

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;


export function setGlobalToken() {
    try {
        let token = localStorage.getItem('token');
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    } catch (error) {
      console.log(error);
    }
};

export const GETCODINGTEST = 'GET_CODING_TEST';
export const ONPROBLEMSUBMISSION = 'ON_PROBLEM_SUBMISSION';
export const ONPROBLEMFINALSUBMISSION = 'ON_PROBLEM_FINAL_SUBMISSION';

export const getCodingTestA = () => {
    return {
        type: GETCODINGTEST
        // response: res,
        // error: err
    }
}

export const getCodingTestAC = (res) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/codeEditor/getCodingTest?test_id=${getState().ct.test_id}`)
        .then((res)=> {
            dispatch(getCodingTestA(res.data, false));
        })
        .catch((err)=> {
            console.log('error in getting coding test')
            console.log(err)
            dispatch(getCodingTestA(null, true))
        });
    };
};

export const onProblemFinalSubmissionA = (res, err) => {
    return {
        type: ONPROBLEMFINALSUBMISSION,
        response: res,
        error: err
    }
}

export const onProblemFinalSubmissionAC = (problem_id) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        const formdata = new FormData();
        formdata.append('test_id', getState().ct.test_id);
        formdata.append('problem_id', problem_id);

        http.post(`${BASE_API_URL}/codeEditor/submitCodingProblem`, formdata)
        .then((res)=> {
            dispatch(onProblemFinalSubmissionA(res,false));
        })
        .catch((err)=> {
            console.log('error in getting coding test')
            console.log(err)
            dispatch(onProblemFinalSubmissionA(null, true))
        });
    };
};

export const onProblemSubmissionA = (res, err) => {
    return {
        type: ONPROBLEMSUBMISSION,
        response: res,
        error: err
    }
}

export const onProblemSubmissionAC = (submission_id) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        const formdata = new FormData();
        formdata.append('test_id', getState().ct.test_id);
        formdata.append('apiSubmissionId', submission_id);

        http.post(`${BASE_API_URL}/codeEditor/onProblemExecution`, formdata)
        .then((res)=> {
            dispatch(onProblemSubmissionA(res.data,false));
        })
        .catch((err)=> {
            console.log('error in getting coding test')
            console.log(err)
            dispatch(onProblemSubmissionA(null, true))
        });
    };
};
