import { FETCHING_DATA, GET_GRAND_TOPICS, GET_SNIPPET_READ_DATA ,GET_SPIN_CATEGERIOES,GET_TEST_DATA} from "../actions/remedialActions";

const initial_state = {
    grandTopics: [],
    nedel: {},
    alldata:[],
    loading: false,
    error_loading_grandTopics: false,
    loading_spin_data:true,
    error_loading_spin_data:false,
    loading_test_data:true,
    remedial_test_bonus_points:0,
    student_attempted:0,
    submitted_student_details:false,
    remedial_path_test_type:null,
    remedial_path_test_completed:null,
    submit_remedial_test:false,
};

const reducer = (state = initial_state, action) => {
    if (action.type === GET_GRAND_TOPICS) {
        if(action.error){
            return {
                ...state,
                error_loading_grandTopics: true,
                loading: false
            }
        }
        console.log(action.response);
        action.response?.grandTopics.forEach(grandTopic => {
            if (grandTopic.current_page=="None") {
                grandTopic.completed = 0;
                grandTopic.visited = false;
                grandTopic.percentageCovered = 0;
                grandTopic.current_page = 1;
                grandTopic.max_page = 1;
                grandTopic.total_pages = grandTopic.snippet_url.length;
            }else{
                grandTopic.completed = grandTopic.current_page.status;
                grandTopic.visited = true;
                grandTopic.percentageCovered = Math.floor((grandTopic.current_page.max_read*100)/grandTopic.current_page.total_pages);
                grandTopic.current_page = grandTopic.current_page.page;
                grandTopic.max_page = grandTopic.current_page.max_read;
                grandTopic.total_pages = grandTopic.current_page.total_pages;
            }
           
            // grandTopic.read_status = action.response?.current_page[grandTopic.code];
            grandTopic.topics = grandTopic.topics.map(topic => ({
                ...topic, 
                // visited: false,percentageCovered: Math.floor((action.response?.current_page[topic.code]?.max_read*100)/action.response?.current_page[topic.code]?.total_pages), 
                current_page: topic.current_page, 
                // max_page: 1, 
                // total_pages: 1, 
                gcode: grandTopic.code
            }));
        });
        let total_bonus_points=0;
        action.response.student_data.forEach(eachstudent => {
            total_bonus_points += eachstudent.remedial_test_bonus_points
        });
        let remedial_has_remedial_path =1;
        if(action.response.statusCode === 204 || action.response.message === "No Remedial Path"){
            remedial_has_remedial_path = 0;
        }
        

        console.log("in reducer")
            console.log(action.response.grandTopics)
            console.log(action.response.student_data);
            console.log(action.response.student_data.length)
            console.log(remedial_has_remedial_path)
        return {
            ...state,
            grandTopics: action.response.grandTopics,
            nedel: action.response.nedel,
            alldata: action.response,
            error_loading_grandTopics: false,
            loading: false,
            remedial_test_bonus_points:total_bonus_points,
            remedial_has_remedial_path : remedial_has_remedial_path,
            student_attempted:action.response.student_data.length,
            submitted_student_details:action.response.student_data,
            remedial_path_test_type:action.response.remedial_path_test_type,
            remedial_path_test_completed:action.response.remedial_path_test_completed,
        }
    }
    else if (action.type === GET_SNIPPET_READ_DATA) {
        if (action.error) {
            return {
                ...state
            }
        }
        console.log(action.response);
        return {
            ...state,
            snippetReadProgress: action.response,
            error_loading_grandTopics: false,
            loading: false
        }
    }
    else if (action.type === FETCHING_DATA) {
        return {
            ...state,
            loading: true
        }
    }else if (action.type === GET_SPIN_CATEGERIOES) {
        if (action.error) {
            return {
                ...state,
                loading_spin_data:false,
                error_loading_spin_data:true
            }
        }
        console.log(action.response);
        return {
            ...state,
            loading_spin_data:false,
            error_loading_spin_data:false,
            spin_wheel_data:action.response,
        }
    }
    if (action.type == GET_TEST_DATA) {
        if (action.error) {
            return {
                ...state,
                loading_test_data:false,
            }
        }else{
            console.log(action)
            // let remedial_questions = action.response.data.questions;
            // console.log(remedial_questions)
            // let remedial_answers = [];
           let remedial_answers =action?.response.data?.questions.map((question_id,index)=>{
             return {question_id:question_id.id,option_id:""}

           })
            console.log(action.response)
            return {
                ...state,
                loading_test_data:false,
                start_remedial_test: true,
                remedial_test_data: action?.response?.data?.questions,
                remedial_test_answers: remedial_answers,
                qid: action.response?.data?.questions[0]?.id,
                qindex : 0,
                remedial_test_id:action.response.data.questionnaire_remedial_path_id,
            }
        }

       
    }
    if (action.type == 'ON_REMEDIAL_QUESTION_CLICK') {
        const qid = state.remedial_test_data[action.qindex]?.id;
        return {
            ...state,
            qindex: action.qindex,
            qid: qid
        }
    }
    if (action.type === 'ON_REMEDIAL_OPTION_SELECTED') {
        const updatedAnswers = state.remedial_test_answers.map(answer => {
            if (answer.question_id === action.qid) {
                return { ...answer, option_id: action.option_id };
            }
            return answer;
        });
    
        return {
            ...state,
            remedial_test_answers: updatedAnswers,
        };
    }
    if (action.type == 'SUBMIT_REMEDIAL_TEST') {
        if (action.err) {
            return {
                ...state,
                submit_remedial_test:false,
            }
        }else{
            return{
                ...state,
                submit_remedial_test: true
            }
        }
       
    }
    
    return state;
};

export default reducer;