import React from 'react';
import styles from './MonogramTimer.module.css';
import Timer from '../Timer/Timer';

const MonogramTimer = (props) => (
  <div className={styles.MonogramTimer}>
    <img src='/images/Group 8.svg' alt="monogram" style={{display:'inline'}} />
    <div className={styles.timer} style={{left: props.increment ? '65px': '85px'}}>
      <Timer increment={props.increment} seconds={props.seconds} onStartTimer={props.onStartTimer} onTimerUpdate={props.onTimerUpdate} />
    </div>
  </div>
);


export default MonogramTimer;
