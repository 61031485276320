import axios from '../../interceptor';
import moment from 'moment';

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
let CORS = 'https://cors-anywhere.herokuapp.com/';

export function setGlobalToken() {
    try {
        let token = localStorage.getItem('token');
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    } catch (error) {
      console.log(error);
    }
};

const feedbacks_data = [
    // {
    //     unit_name: 'Unit I',
    //     unit_id: 1234,
    //     faculty: [
    //         {
    //             "feedback_id": 1904,
    //             "college_account_id": 2811,
    //             "faculty_name": "Dr.Selvakanmani S",
    //             "feedback_name": "Feedback for Computer Networks - Unit - Unit I taught by Dr.Selvakanmani S",
    //             "doe": "2024-03-30 12:00:00",
    //             "start_time": "2024-03-29 10:58:04",
    //             "questions": [
    //                 {
    //                     "id": 1,
    //                     "question": "Effectiveness in classroom communication",
    //                     "max_scale": 5,
    //                     "submission_count": 2,
    //                     "value": 4
    //                 },
    //                 {
    //                     "id": 2,
    //                     "question": "Effectiveness in managing classroom time and syllabus schedule",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 },
    //                 {
    //                     "id": 3,
    //                     "question": "Response to Questions in a timely and helpful manner",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 },
    //                 {
    //                     "id": 4,
    //                     "question": "Facilitating classroom discussion and student engagement",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 },
    //                 {
    //                     "id": 5,
    //                     "question": "Usage of innovative teaching methods",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 },
    //                 {
    //                     "id": 6,
    //                     "question": "Quality of Assignments and Mini Projects",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 },
    //                 {
    //                     "id": 7,
    //                     "question": "Availability and quality of the Digital course material",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 },
    //                 {
    //                     "id": 8,
    //                     "question": "Availability and quality of video lectures",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 },
    //                 {
    //                     "id": 9,
    //                     "question": "Discussion of MCQ tests and feedback",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 },
    //                 {
    //                     "id": 10,
    //                     "question": "Attainment of Course Outcome",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 }
    //             ],
    //             "submitted_feedback": ""
    //         },
    //         {
    //             "feedback_id": 1704,
    //             "college_account_id": 2615,
    //             "faculty_name": "Mr. Aravinda De Silva",
    //             "feedback_name": "Feedback for Computer Networks - Unit - Unit I taught by Mr. Aravinda De Silva",
    //             "doe": "2024-03-30 12:00:00",
    //             "start_time": "2024-03-29 10:58:04",
    //             "questions": [
    //                 {
    //                     "id": 11,
    //                     "question": "Effectiveness in classroom communication",
    //                     "max_scale": 5,
    //                     "submission_count": 2,
    //                     "value": 2
    //                 },
    //                 {
    //                     "id": 12,
    //                     "question": "Effectiveness in managing classroom time and syllabus schedule",
    //                     "max_scale": 5,
    //                     "submission_count": 1,
    //                     "value": 5
    //                 },
    //                 {
    //                     "id": 13,
    //                     "question": "Response to Questions in a timely and helpful manner",
    //                     "max_scale": 5,
    //                     "submission_count": 2,
    //                     "value": 1
    //                 },
    //                 {
    //                     "id": 14,
    //                     "question": "Facilitating classroom discussion and student engagement",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 },
    //                 {
    //                     "id": 15,
    //                     "question": "Usage of innovative teaching methods",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 },
    //                 {
    //                     "id": 16,
    //                     "question": "Quality of Assignments and Mini Projects",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 },
    //                 {
    //                     "id": 17,
    //                     "question": "Availability and quality of the Digital course material",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 },
    //                 {
    //                     "id": 18,
    //                     "question": "Availability and quality of video lectures",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 },
    //                 {
    //                     "id": 19,
    //                     "question": "Discussion of MCQ tests and feedback",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 },
    //                 {
    //                     "id": 20,
    //                     "question": "Attainment of Course Outcome",
    //                     "max_scale": 5,
    //                     "submission_count": 0,
    //                     "value": ""
    //                 }
    //             ],
    //             "submitted_feedback": ""
    //         }
    //     ]
    // },
    {
        unit_name: 'Unit I',
        unit_id: 1234,
        faculty: [
            {
                "feedback_id": 1904,
                "college_account_id": 2811,
                "faculty_name": "Dr.Selvakanmani S",
                "feedback_name": "Feedback for Computer Networks - Unit - Unit I taught by Dr.Selvakanmani S",
                "doe": "2024-03-30 12:00:00",
                "start_time": "2024-03-29 10:58:04",
                "questions": [
                    {
                        "id": 1,
                        "question": "Effectiveness in classroom communication",
                        "max_scale": 5,
                        "submission_count": 2,
                        "value": 4
                    },
                    {
                        "id": 2,
                        "question": "Effectiveness in managing classroom time and syllabus schedule",
                        "max_scale": 5,
                        "submission_count": 1,
                        "value": 3
                    },
                    {
                        "id": 3,
                        "question": "Response to Questions in a timely and helpful manner",
                        "max_scale": 5,
                        "submission_count": 1,
                        "value": 5
                    },
                    {
                        "id": 4,
                        "question": "Facilitating classroom discussion and student engagement",
                        "max_scale": 5,
                        "submission_count": 2,
                        "value": 1
                    },
                    {
                        "id": 5,
                        "question": "Usage of innovative teaching methods",
                        "max_scale": 5,
                        "submission_count": 2,
                        "value": 4
                    },
                    {
                        "id": 6,
                        "question": "Quality of Assignments and Mini Projects",
                        "max_scale": 5,
                        "submission_count": 1,
                        "value": 1
                    },
                    {
                        "id": 7,
                        "question": "Availability and quality of the Digital course material",
                        "max_scale": 5,
                        "submission_count": 1,
                        "value": 5
                    },
                    {
                        "id": 8,
                        "question": "Availability and quality of video lectures",
                        "max_scale": 5,
                        "submission_count": 2,
                        "value": 2
                    },
                    {
                        "id": 9,
                        "question": "Discussion of MCQ tests and feedback",
                        "max_scale": 5,
                        "submission_count": 2,
                        "value": 4
                    },
                    {
                        "id": 10,
                        "question": "Attainment of Course Outcome",
                        "max_scale": 5,
                        "submission_count": 1,
                        "value": 4
                    }
                ],
                "submitted_feedback": ""
            },
            {
                "feedback_id": 1704,
                "college_account_id": 2615,
                "faculty_name": "Mr. Aravinda De Silva",
                "feedback_name": "Feedback for Computer Networks - Unit - Unit I taught by Mr. Aravinda De Silva",
                "doe": "2024-03-30 12:00:00",
                "start_time": "2024-03-29 10:58:04",
                "questions": [
                    {
                        "id": 11,
                        "question": "Effectiveness in classroom communication",
                        "max_scale": 5,
                        "submission_count": 2,
                        "value": 2
                    },
                    {
                        "id": 12,
                        "question": "Effectiveness in managing classroom time and syllabus schedule",
                        "max_scale": 5,
                        "submission_count": 1,
                        "value": 5
                    },
                    {
                        "id": 13,
                        "question": "Response to Questions in a timely and helpful manner",
                        "max_scale": 5,
                        "submission_count": 2,
                        "value": 1
                    },
                    {
                        "id": 14,
                        "question": "Facilitating classroom discussion and student engagement",
                        "max_scale": 5,
                        "submission_count": 1,
                        "value": 3
                    },
                    {
                        "id": 15,
                        "question": "Usage of innovative teaching methods",
                        "max_scale": 5,
                        "submission_count": 1,
                        "value": 1
                    },
                    {
                        "id": 16,
                        "question": "Quality of Assignments and Mini Projects",
                        "max_scale": 5,
                        "submission_count": 2,
                        "value": 2
                    },
                    {
                        "id": 17,
                        "question": "Availability and quality of the Digital course material",
                        "max_scale": 5,
                        "submission_count": 1,
                        "value": 5
                    },
                    {
                        "id": 18,
                        "question": "Availability and quality of video lectures",
                        "max_scale": 5,
                        "submission_count": 2,
                        "value": 2
                    },
                    {
                        "id": 19,
                        "question": "Discussion of MCQ tests and feedback",
                        "max_scale": 5,
                        "submission_count": 1,
                        "value": 3
                    },
                    {
                        "id": 20,
                        "question": "Attainment of Course Outcome",
                        "max_scale": 5,
                        "submission_count": 1,
                        "value": 2
                    }
                ],
                "submitted_feedback": ""
            }
        ]
    },
    {
        unit_name: 'Unit II',
        unit_id: 1240,
        faculty: [
            {
                "feedback_id": 1906,
                "college_account_id": 2814,
                "faculty_name": "Dr. Rajnikanth",
                "feedback_name": "Feedback for Computer Networks - Unit - Unit II taught by Dr. Rajnikanth",
                "doe": "2024-03-30 12:00:00",
                "start_time": "2024-03-29 10:58:04",
                "questions": [
                    {
                        "id": 21,
                        "question": "Effectiveness in classroom communication",
                        "max_scale": 5,
                        "submission_count": 1,
                        "value": 2
                    },
                    {
                        "id": 22,
                        "question": "Effectiveness in managing classroom time and syllabus schedule",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 23,
                        "question": "Response to Questions in a timely and helpful manner",
                        "max_scale": 5,
                        "submission_count": 2,
                        "value": 4
                    },
                    {
                        "id": 24,
                        "question": "Facilitating classroom discussion and student engagement",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 25,
                        "question": "Usage of innovative teaching methods",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 26,
                        "question": "Quality of Assignments and Mini Projects",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 27,
                        "question": "Availability and quality of the Digital course material",
                        "max_scale": 5,
                        "submission_count": 1,
                        "value": 2
                    },
                    {
                        "id": 28,
                        "question": "Availability and quality of video lectures",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 29,
                        "question": "Discussion of MCQ tests and feedback",
                        "max_scale": 5,
                        "submission_count": 2,
                        "value": 1
                    },
                    {
                        "id": 30,
                        "question": "Attainment of Course Outcome",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    }
                ],
                "submitted_feedback": ""
            }
        ]
    },
    {
        unit_name: 'Unit III',
        unit_id: 1250,
        faculty: [
            {
                "feedback_id": 1914,
                "college_account_id": 2911,
                "faculty_name": "Dr.David Warner",
                "feedback_name": "Feedback for Computer Networks - Unit - Unit III taught by Dr.David Warner",
                "doe": "2024-03-30 12:00:00",
                "start_time": "2024-03-29 10:58:04",
                "questions": [
                    {
                        "id": 31,
                        "question": "Effectiveness in classroom communication",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 32,
                        "question": "Effectiveness in managing classroom time and syllabus schedule",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 33,
                        "question": "Response to Questions in a timely and helpful manner",
                        "max_scale": 5,
                        "submission_count": 2,
                        "value": 3
                    },
                    {
                        "id": 34,
                        "question": "Facilitating classroom discussion and student engagement",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 35,
                        "question": "Usage of innovative teaching methods",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 36,
                        "question": "Quality of Assignments and Mini Projects",
                        "max_scale": 5,
                        "submission_count": 1,
                        "value": 4
                    },
                    {
                        "id": 37,
                        "question": "Availability and quality of the Digital course material",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 38,
                        "question": "Availability and quality of video lectures",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 39,
                        "question": "Discussion of MCQ tests and feedback",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 40,
                        "question": "Attainment of Course Outcome",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    }
                ],
                "submitted_feedback": ""
            }
        ]
    },
    {
        unit_name: 'Unit IV',
        unit_id: 1260,
        // faculty: [
        //     {
        //         "feedback_id": 1924,
        //         "college_account_id": 2841,
        //         "faculty_name": "Mr. MS Dhoni",
        //         "feedback_name": "Feedback for Computer Networks - Unit - Unit IV taught by Dr.MS Dhoni",
        //         "doe": "2024-03-30 12:00:00",
        //         "start_time": "2024-03-29 10:58:04",
        //         "questions": [
        //             {
        //                 "id": 41,
        //                 "question": "Effectiveness in classroom communication",
        //                 "max_scale": 5,
        //                 "submission_count": 2,
        //                 "value": 5
        //             },
        //             {
        //                 "id": 42,
        //                 "question": "Effectiveness in managing classroom time and syllabus schedule",
        //                 "max_scale": 5,
        //                 "submission_count": 0,
        //                 "value": ""
        //             },
        //             {
        //                 "id": 43,
        //                 "question": "Response to Questions in a timely and helpful manner",
        //                 "max_scale": 5,
        //                 "submission_count": 2,
        //                 "value": 2
        //             },
        //             {
        //                 "id": 44,
        //                 "question": "Facilitating classroom discussion and student engagement",
        //                 "max_scale": 5,
        //                 "submission_count": 0,
        //                 "value": ""
        //             },
        //             {
        //                 "id": 45,
        //                 "question": "Usage of innovative teaching methods",
        //                 "max_scale": 5,
        //                 "submission_count": 0,
        //                 "value": ""
        //             },
        //             {
        //                 "id": 46,
        //                 "question": "Quality of Assignments and Mini Projects",
        //                 "max_scale": 5,
        //                 "submission_count": 0,
        //                 "value": ""
        //             },
        //             {
        //                 "id": 47,
        //                 "question": "Availability and quality of the Digital course material",
        //                 "max_scale": 5,
        //                 "submission_count": 0,
        //                 "value": ""
        //             },
        //             {
        //                 "id": 48,
        //                 "question": "Availability and quality of video lectures",
        //                 "max_scale": 5,
        //                 "submission_count": 0,
        //                 "value": ""
        //             },
        //             {
        //                 "id": 49,
        //                 "question": "Discussion of MCQ tests and feedback",
        //                 "max_scale": 5,
        //                 "submission_count": 0,
        //                 "value": ""
        //             },
        //             {
        //                 "id": 50,
        //                 "question": "Attainment of Course Outcome",
        //                 "max_scale": 5,
        //                 "submission_count": 0,
        //                 "value": ""
        //             }
        //         ],
        //         "submitted_feedback": ""
        //     }
        // ]
        faculty: []
    },
    {
        unit_name: 'Unit V',
        unit_id: 1274,
        faculty: [
            {
                "feedback_id": 1994,
                "college_account_id": 2781,
                "faculty_name": "Dr. Virat Kohli",
                "feedback_name": "Feedback for Computer Networks - Unit - Unit V taught by Dr.Virat Kohli",
                "doe": "2024-03-30 12:00:00",
                "start_time": "2024-03-29 10:58:04",
                "questions": [
                    {
                        "id": 51,
                        "question": "Effectiveness in classroom communication",
                        "max_scale": 5,
                        "submission_count": 2,
                        "value": 4
                    },
                    {
                        "id": 52,
                        "question": "Effectiveness in managing classroom time and syllabus schedule",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 53,
                        "question": "Response to Questions in a timely and helpful manner",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 54,
                        "question": "Facilitating classroom discussion and student engagement",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 55,
                        "question": "Usage of innovative teaching methods",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 56,
                        "question": "Quality of Assignments and Mini Projects",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 57,
                        "question": "Availability and quality of the Digital course material",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 58,
                        "question": "Availability and quality of video lectures",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 59,
                        "question": "Discussion of MCQ tests and feedback",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    },
                    {
                        "id": 60,
                        "question": "Attainment of Course Outcome",
                        "max_scale": 5,
                        "submission_count": 0,
                        "value": ""
                    }
                ],
                "submitted_feedback": ""
            }
        ]
    },
]

export const FETCHING_DATA = 'FETCHING_DATA';
export const GET_SUBJECT_DATA = 'GET_SUBJECT_DATA';
export const SUBMIT_QUESTION = 'SUBMIT_QUESTION';

export const fetchingData = () => {
    return {
        type: FETCHING_DATA,
    }
}

export const getSubjectDataA = (res, unit_id, err) => {
    return {
        type: GET_SUBJECT_DATA,
        res: res,
        unit_id: unit_id,
        err: err,
    }
}

export const submitUnitFeedbackQuestionA = (res, err) => {
    return {
        type: SUBMIT_QUESTION,
        res: res,
        err: err,
    }
}



export const getSubjectDataAC = (subject_semester_id, unit_id) => {
    // return (dispatch, getState) => {
    //     dispatch(fetchingData());
    //     dispatch(getSubjectDataA(feedbacks_data,false));
    // }
  console.log('in action creator')
  setGlobalToken();
  return (dispatch, getState) =>{
      dispatch(fetchingData());
      http.get(`${BASE_API_URL}/faculty_feedback/v2/getFacultyFeedbacks?subject_semester_id=${subject_semester_id}`)
      .then((res)=> {
          if (res.status ==200) {
              if (res.data.status == 200) {
                dispatch(getSubjectDataA(res.data.data, unit_id, false));
              } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                  let refresh_token = localStorage.getItem('refresh_token');
                  http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                  http.get(`${BASE_API_URL}/auth/refresh`)
                  .then((refres)=> {
                      localStorage.setItem('token', refres.data.token);
                      setGlobalToken();
                      http.get(`${BASE_API_URL}/faculty_feedback/v2/getFacultyFeedbacks?subject_semester_id=${subject_semester_id}`)
                      .then((res)=> {
                          if (res.status == 200) {
                              if (res.data.status == 200) {
                                dispatch(getSubjectDataA(res.data.data, unit_id, false));
                              } else {
                                dispatch(getSubjectDataA(null, unit_id, true));
                              }
                          } else {
                            dispatch(getSubjectDataA(null, unit_id, true));
                          }
                      })
                      .catch((err)=>{
                        dispatch(getSubjectDataA(null, unit_id, true));
                      })
                  })
                  .catch((err)=> {
                      console.log(err);
                  });
              } else {
                dispatch(getSubjectDataA(null, unit_id, true));
              }
          } else {
            dispatch(getSubjectDataA(null, unit_id, true));
          }
      })
      .catch((err)=> {
          console.log('error in getting subject data in unit feedback')
          console.log(err)
          dispatch(dispatch(getSubjectDataA(null, unit_id, true)));
      });
  };
};

export const submitUnitFeedbackQuestionAC = (feedback_id, question_id, answer) => {
    setGlobalToken();
    return (dispatch, getState) => {
      http
        .get(
        `${BASE_API_URL}/faculty_feedback/submitFacultyPerformanceFeedback?feedback_id=${feedback_id}&question_id=${question_id}&answer=${answer}`
        )
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            if (res.data.status == 200 || (res.data.status === 402 && res.data.message === 'Submissions Exceeded')) {
              console.log("inside data status ");
              dispatch(submitUnitFeedbackQuestionA(res.data, false));
            } else if (
              res.data.status == 401 &&
              res.data.message == "Signature expired. Please log in again."
            ) {
              let refresh_token = localStorage.getItem("refresh_token");
              http.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${refresh_token}`;
              http
                .get(`${BASE_API_URL}/auth/refresh`)
                .then((refres) => {
                  localStorage.setItem("token", refres.data.token);
                  setGlobalToken();
                  http
                    .get(
                    `${BASE_API_URL}/faculty_feedback/submitFacultyPerformanceFeedback?feedback_id=${feedback_id}&question_id=${question_id}&answer=${answer}`
                    )
                    .then((res) => {
                      if (res.status == 200) {
                        if (res.data.status == 200 && (res.data.status === 402 && res.data.message === 'Submissions Exceeded')) {
                          dispatch(submitUnitFeedbackQuestionA(res.data, false));
                        } else {
                          dispatch(submitUnitFeedbackQuestionA(null, true));
                        }
                      } else {
                        dispatch(submitUnitFeedbackQuestionA(null, true));
                      }
                    });
                })
                .catch((err) => {
                  dispatch(submitUnitFeedbackQuestionA(null, true));
                });
            } else {
              dispatch(submitUnitFeedbackQuestionA(null, true));
            }
          } else {
            dispatch(submitUnitFeedbackQuestionA(null, true));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(submitUnitFeedbackQuestionA(null, true));
        });
    };
  };


