import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom";
import styled, { keyframes, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import "./dashboard.css";
const Leaderboardrow = styled.div`
    display: flex;
    font-size: 10px;
   align-items: center;
    font-weight: 600;
    border-bottom: 1px solid #80808052;
    padding: 12px 0px 12px 0px;
    padding-left: 0px !important;
  `;
function Analyticskeletonloader(props) {

    return (
        <>
    <div className="" style={{fontSize: '12px' , marginTop: '53px' , padding: '0px' }}>
    <Skeleton baseColor="rgba(82, 195, 255, 0.58)" highlightColor="rgba(82, 195, 255, 0.58)"
     width={130} height={70} inline={true} />
      <Skeleton baseColor="rgba(255, 203, 87, 0.68)" highlightColor="rgba(255, 203, 87, 0.68)"
     width={130} height={70} />
      <Skeleton baseColor="rgb(255, 223, 214)" highlightColor="rgb(255, 223, 214)"
     width={130} height={70} inline={true} />
      <Skeleton baseColor="rgba(252, 119, 160, 0.58)" highlightColor="rgba(252, 119, 160, 0.58)"
     width={130} height={70} />
    
    </div>
    <div className="col-lg-12 row" style={{background: 'white', paddingLeft: '0px', marginTop: '36px', borderRadius: '20px', paddingRight: '0px',marginLeft:"3px"}}>
  {/* <div style={{fontSize: '11px', display: 'flex', justifyContent: 'flex-end', padding: '5px 19px 0px 0px'}}>Algebra and math
  </div> */}
  <div className="row col-lg-12" style={{position: 'relative', top: '30px', padding: '2px', background: 'white', borderRadius: '20px', marginLeft: '0px'}}>
    <div style={{display: 'flex', padding: '0px 5px'}}>
      <div style={{height: 'auto', boxShadow: 'rgba(128, 128, 128, 0.38) 1px 1px 4px', fontSize: '11px', padding: '6px', borderRadius: '10px', marginRight: '9px', position: 'relative', top: '15px'}}>
      <Skeleton circle={true} width={30} height={30} />
        <div style={{fontWeight: 600}}> <Skeleton width={60} height={10} /></div>
        <div className="leaderboardrank" style={{position: 'absolute', top: '-19px', right: '-10px', background: 'rgb(255, 220, 141)'}}>2</div>
      </div>
      <div style={{height: 'auto', boxShadow: 'rgba(128, 128, 128, 0.38) 1px 1px 4px', fontSize: '11px', padding: '6px', borderRadius: '10px', marginRight: '14px', position: 'relative'}}>
      <Skeleton circle={true} width={30} height={30} />
        <div style={{fontWeight: 600}}> <Skeleton width={60} height={10} /></div>
        <div className="leaderboardrank" style={{position: 'absolute', top: '-19px', right: '-10px', background: 'rgb(253, 176, 200)'}}>1</div>
      </div>
      <div style={{height: 'auto', boxShadow: 'rgba(128, 128, 128, 0.38) 1px 1px 4px', fontSize: '11px', padding: '6px', borderRadius: '10px', position: 'relative', top: '15px'}}>
      <Skeleton circle={true} width={30} height={30} />
        <div style={{fontWeight: 600}}> <Skeleton width={60} height={10} /></div>
        <div className="leaderboardrank" style={{position: 'absolute', top: '-19px', right: '-10px', background: 'rgb(155, 220, 255)'}}>3</div>
      </div>
    </div>
    <div className="row" style={{padding: '0px', borderRadius: '20px', margin: '30px 0px 0px', background: 'white'}}>
      <Leaderboardrow className="sc-licaXj dcbfDl col-lg-12">
        <div className="col-lg-1" style={{padding: '0px', fontSize: '12px'}}>4</div>
        <div className="col-lg-1" style={{padding: '0px'}}>
        <Skeleton circle={true} width={30} height={30} />
            </div>
        <div className="col-lg-10">
          <div><Skeleton width={160} height={10} /></div>
        </div>
      </Leaderboardrow>
      <Leaderboardrow className="sc-licaXj dcbfDl col-lg-12">
        <div className="col-lg-1" style={{padding: '0px', fontSize: '12px'}}>5</div>
        <div className="col-lg-1" style={{padding: '0px'}}>
        <Skeleton circle={true} width={30} height={30} />
            </div>
        <div className="col-lg-10">
          <div><Skeleton width={160} height={10} /></div>
        </div>
      </Leaderboardrow>
      <Leaderboardrow className="sc-licaXj dcbfDl col-lg-12">
        <div className="col-lg-1" style={{padding: '0px', fontSize: '12px'}}>6</div>
        <div className="col-lg-1" style={{padding: '0px'}}>
        <Skeleton circle={true} width={30} height={30} />
            </div>
        <div className="col-lg-10">
          <div><Skeleton width={160} height={10} /></div>
        </div>
      </Leaderboardrow>
     
    </div>
  </div>
</div>

        </>
    );
}


export default Analyticskeletonloader;
// export default Singleteamdetails;
