import React from 'react'

const StarSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M9.15327 2.33995L10.3266 4.68662C10.4866 5.01328 10.9133 5.32662 11.2733 5.38662L13.3999 5.73995C14.7599 5.96662 15.0799 6.95328 14.0999 7.92662L12.4466 9.57995C12.1666 9.85995 12.0133 10.3999 12.0999 10.7866L12.5733 12.8333C12.9466 14.4533 12.0866 15.0799 10.6533 14.2333L8.65994 13.0533C8.29994 12.8399 7.70661 12.8399 7.33994 13.0533L5.34661 14.2333C3.91994 15.0799 3.05327 14.4466 3.42661 12.8333L3.89994 10.7866C3.98661 10.3999 3.83327 9.85995 3.55327 9.57995L1.89994 7.92662C0.926606 6.95328 1.23994 5.96662 2.59994 5.73995L4.72661 5.38662C5.07994 5.32662 5.50661 5.01328 5.66661 4.68662L6.83994 2.33995C7.47994 1.06662 8.51994 1.06662 9.15327 2.33995Z" fill="#FFB63E" />
  </svg>
  )
}

export default StarSvg