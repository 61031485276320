import React, { useEffect, useState } from 'react'
import StudyContentCarousel from '../../StudyContentCarousel/StudyContentCarousel'
import {videoLessonCardRenderer } from '../../StudyContentCarousel/renderer';
import { useRemedialContext } from '../../../hooks/useRemedial';

const dummyArray = ['1as', '2as', '3as'];

const VideoLessons = ({index ,data}) => {
  const [noOfVideos, setNoOfVideos] = useState(0);
  const { studyPageData, setStudyPageData } = useRemedialContext();

  const handleOnChange = (num) => {
    setNoOfVideos(num)
  }


  useEffect(() => {
    if (data) {
      const array = studyPageData;
      const newArray = [];
      array.forEach((item, idx) => {
        let value = idx === index ? { ...item, noOfVideos: noOfVideos + 1 } : item
        newArray.push(value)
      })
      setStudyPageData(newArray)
    }
  }, [noOfVideos])

  return (
    <StudyContentCarousel data={data} renderer={videoLessonCardRenderer} onChange={handleOnChange} currentSlideIndex={studyPageData[index].noOfVideos > studyPageData[index].video_current_read ? studyPageData[index].noOfVideos : studyPageData[index].video_current_read} />
  )
}

export default VideoLessons