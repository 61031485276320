export const SET_TIMER  = 'SET_TIMER';
export const UPDATE_BANNER_INDEX  = 'UPDATE_BANNER_INDEX';
export const SET_BANNER_DATA  = 'SET_BANNER_DATA';
export const SET_TEST_RESULT_PAGE_DATA  = 'SET_TEST_RESULT_PAGE_DATA';
export const SET_STUDY_PAGE_DATA  = 'SET_STUDY_PAGE_DATA';
export const SET_STUDY_PROGRESS  = 'SET_STUDY_PROGRESS';
export const SET_SPIN_WHEEL_DATA  = 'SET_SPIN_WHEEL_DATA ';
export const SET_SPIN_PRIZE_NUMBER  = 'SET_SPIN_PRIZE_NUMBER ';
export const SET_DIRECT_BONUS_POINTS = 'SET_DIRECT_BONUS_POINTS';
export const SET_REVISED_RESULT_PAGE_DATA  = 'SET_REVISED_RESULT_PAGE_DATA';
export const SET_TEST_DATA  = 'SET_TEST_DATA';


// question reducers
export const SET_QUESTIONS  = 'SET_QUESTIONS';
export const SET_QUESTIONS_STATUS  = 'SET_QUESTIONS_STATUS';
