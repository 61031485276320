import { Box } from '@mui/material';
import React from 'react';
import QuestionNumber from './QuestionNumber';

// addQuestionMark -> 1 or Q1

const QuestionSheet = ({ data, columnWidth, columnMaxWidth = '1fr', addQuestionMark = false, boxPadding = 6 }) => {
    return (
        <Box sx={{
            flexGrow: '1',
            display: 'grid',
            gap: '12px',
            rowGap: '12px',
            gridTemplateColumns: `repeat(auto-fit, minmax(${columnWidth}px, ${columnWidth}px))`
        }}>

            {data.map((item, index) => {

                if (item.correctly_answered) {
                    return <QuestionNumber boxPadding={boxPadding} variant={'right'} qNumber={addQuestionMark ? `Q${index + 1}` : index + 1} />
                } else {
                    let notSelectedOptions = 0
                    item.test_questions_options.forEach((choice, index, arr) => {
                        if (choice.selected_option_id === '0') {
                            notSelectedOptions = notSelectedOptions + 1;
                        }
                    })
                    if (notSelectedOptions === item.test_questions_options.length) {
                        return <QuestionNumber boxPadding={boxPadding} variant={'notAttempt'} qNumber={addQuestionMark ? `Q${index + 1}` : index + 1} />
                    } else {
                        return <QuestionNumber boxPadding={boxPadding} variant={'wrong'} qNumber={addQuestionMark ? `Q${index + 1}` : index + 1} />
                    }
                }
            })}
        </Box>
    );
};

export default QuestionSheet;
