import { Avatar, AvatarGroup, Box, styled } from '@mui/material'
import React from 'react'


const GroupedAvatars = ({ data, removeBorder = true }) => {

    const AvatarGroupBox = styled('div')(({ theme }) => ({
        '.MuiAvatar-root': {
            width: '30px',
            height: '30px',
            fontSize: '12px',
            fontFamily: 'Poppins-Medium',
        },
        '.MuiAvatar-root.MuiAvatar-circular.MuiAvatarGroup-avatar': {
            border: 'none'
        },
        '.MuiAvatar-root.MuiAvatar-circular.MuiAvatarGroup-avatar:not(:last-child)': {
            marginLeft: '-16px',
        },

    }));

    return (
        <AvatarGroupBox>
            { data  ? <AvatarGroup max={4}>
                {data?.slice(0,4).map((item, index) => (
                    <Avatar sx={{ width: 25, height: 25 }}  alt={item.name} src={item.profile_pic} />
                ))}
            </AvatarGroup> :
                <AvatarGroup max={4}>
                    <Avatar sx={{ width: 25, height: 25 }}  alt="Remy Sharp" src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=2864&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                    <Avatar sx={{ width: 25, height: 25 }}  alt="Remy" src="" />
                    <Avatar sx={{ width: 25, height: 25 }}  alt="Remy Sharp" src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=2864&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                    <Avatar sx={{ width: 25, height: 25 }}  alt="Remy" src="" />
                </AvatarGroup>
            }
        </AvatarGroupBox>
    )
}

export default GroupedAvatars;