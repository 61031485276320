import { Avatar, AvatarGroup, Box, styled } from '@mui/material'
import React from 'react'


const GroupedAvatar = ({ data, removeBorder = true }) => {


    console.log(data, 'avatar group data')


    // function stringToColor(string) {
    //     let hash = 0;
    //     let i;

    //     /* eslint-disable no-bitwise */
    //     for (i = 0; i < string.length; i += 1) {
    //       hash = string.charCodeAt(i) + ((hash << 5) - hash);
    //     }

    //     let color = '#';

    //     for (i = 0; i < 3; i += 1) {
    //       const value = (hash >> (i * 8)) & 0xff;
    //       color += `00${value.toString(16)}`.slice(-2);
    //     }
    //     /* eslint-enable no-bitwise */

    //     return color;
    //   }

    // function stringAvatar(name) {
    //     return {
    //         //   sx: {
    //         //     bgcolor: stringToColor(name),
    //         //   },
    //         children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    //     };
    // }



    const AvatarGroupBox = styled('div')(({ theme }) => ({
        '.MuiAvatar-root': {
            width: '30px',
            height: '30px',
            fontSize: '12px',
            fontFamily: 'Poppins-Medium',
        },
        '.MuiAvatar-root.MuiAvatar-circular.MuiAvatarGroup-avatar': {
            border: 'none'
        }

    }));

    return (
        <AvatarGroupBox>
            { data  ? <AvatarGroup max={4}>
                {data?.slice(0,3).map((item, index) => (
                    <Avatar sx={{ width: 30, height: 30 }}  alt={item.name} src={item.url} />
                ))}
            </AvatarGroup> :
                <AvatarGroup max={4}>
                    <Avatar sx={{ width: 30, height: 30 }}  alt="Remy Sharp" src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=2864&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                    <Avatar sx={{ width: 30, height: 30 }}  alt="I K" src="has" />
                    <Avatar sx={{ width: 30, height: 30 }}  alt="Remy Sharp" src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=2864&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                </AvatarGroup>
            }
        </AvatarGroupBox>
    )
}

export default GroupedAvatar