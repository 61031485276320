import moment from 'moment';

const initial_state = {
    loading_practice_test: true,
    submittedTest: false
};

const reducer = (state = initial_state, action) =>{
    if (action.type == 'GET_PRACTICE_TEST') {
        if (action.error) {
            return {
                ...state,
                error_loading_practice_test : true,
                loading_practice_test: false,
                subject_id: action.subject_id,
                unit_id: action.unit_id,
                university_degree_department_id: action.university_degree_department_id
            }
        }
        console.log(action.response);
        let answers = action.response.data.questions.map(({ id, type, type_code, type_id }) => ({
            question_id: id,
            question_option_id: null,
            timetaken: [],
            screenshot: 0,
            isBookMarked: false,
            type: type,
            type_code: type_code,
            type_id: type_id,
            answered: 0
        }));

        let datetime = moment().format('YYYY-MM-DD hh:mm:ss').toString();
        
        answers[0]['started_time'] = moment();
        answers[0].timetaken.push([datetime])
        return {
            ...state,
            subject_id: action.subject_id,
            unit_id: action.unit_id,
            ...action.response.data,
            qid: 0,
            answers: answers,
            error_loading_practice_test : false,
            loading_practice_test: false,
            remaining_questions: action.response.data.questions.length,
            answered: 0,
            correct: 0,
            incorrect: 0,
            test_started: false,
            
            university_degree_department_id: action.university_degree_department_id
        }
    }
    else if (action.type == 'PRACTICE_TEST_START_FROM_INSTRUCTIONS') {
        return {
            ...state,
            test_started: true
        }
    }
    else if (action.type == 'ON_QUESTION_CLICK') {
        let answers = state.answers;
        let answer = answers[state.qid];
        let qid = state.qid;
        // answer.question_option_id = action.question_option_id ? action.question_option_id : null;
        let datetime = moment().format('YYYY-MM-DD hh:mm:ss').toString();
        let timetaken = answer.timetaken[answer.timetaken.length - 1];
        timetaken.push(datetime);
        let duration = moment().diff(answer.started_time, 'seconds');
        timetaken.push(duration);
        answer.timetaken[answer.timetaken.length - 1] = timetaken;
        answers[qid] = answer;

        answers[action.qid].started_time = moment();
        answers[action.qid].timetaken.push([datetime])
        return {
            ...state,
            qid: action.qid,
            answers : answers,

        }
    }
    else if (action.type == 'ON_PRACTICE_OPTION_SELECTED') {
        console.log('in on practice option selected')
        let answers = state.answers.map((answer)=> {
            return {...answer}
        });
        let questions = state.questions;
        let options = questions[action.qid].practice_questions_options;
        let is_answer = 0;
        options.forEach((option)=> {
            if(option.id == action.option_id) {
                is_answer = option.is_answer;
            }
        });
        let answer = {...answers[action.qid]};
        if (answer.question_option_id) {
            return {
                ...state
            }
        }
        answer.question_option_id = action.option_id ? action.option_id : null;
        answer['answered'] = 1
        
        let incorrect = state.incorrect;
        let correct = state.correct;
        let remaining_questions = state.remaining_questions;
        let answered = state.answered;
        if (is_answer == 0) {
            incorrect = incorrect + 1;
            answer['correct_answered'] = 0
            answer['is_answer'] = 0

        } else {
            correct = correct + 1;
            answer['correct_answered'] = 1
            answer['is_answer'] = 1

        }
        answers[action.qid] = answer;
        console.log(answers);
        answered = answered + 1;
        remaining_questions = remaining_questions - 1
        return {
            ...state,
            answers: answers,
            remaining_questions: remaining_questions,
            answered: answered,
            correct: correct,
            incorrect: incorrect
        }
    }
    else if (action.type == 'ON_PRACTICE_TEST_SUBMIT') {
        return {
            ...state,
            submittedTest: true
        }
    }
    else if (action.type == 'CLEAR_PRACTICE_TEST_STATE') {
        return {
            loading_practice_test: true,
            submittedTest: false
        }
    }
    return state;
}

export default reducer;