import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Equation5 from "../../components/Equation5";

const QodSolution = ({ url, type, template}) => {
  // console.log(url);
  // console.log(type);
  const [isS3, setIsS3] = useState(false);
  const [isImg, setIsImg] = useState(false);
  const [data, setData] = useState(null);

  const getData = async () => {
    // const res1 = axios.get(url).then(res => res);
    // console.log(res1);
    return "text"
  }

  useEffect(() => {
    if(url && url.includes(".s3.")){
      setIsS3(true);
      if (url.includes(".png") || url.includes(".jpeg") || url.includes(".jpg")) {
        setIsImg(true);
      }
      else {
      axios.get(`${url}`).then(res => {
        console.log(res.data.split("$"));
        let eq_data = "";
        const data_split = res.data.split("$");
        data_split.forEach((st,i) => {
          if(i === 0 || i === data_split.length - 1) eq_data += st;
          else eq_data += `<Equation5 font_size="10px" font_family="Axiforma" equation={'$${st}$'}></Equation5>`
        })
        console.log(eq_data);
        setData(res.data)
      });
      }
    }
  }, [url])
  

  if (type === 'MP4') {
    if (url.includes('youtube') || url.includes('youtu')) {
      var re = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;
      let text1 = url.replace(re,'$1');
      url = `https://www.youtube.com/embed/${text1}?autoplay=1`;
    }
  } else if(type === 'PPT' || type === 'DOCS' || type === 'PDF'){
      url = "https://drive.google.com/viewerng/viewer?embedded=true&url="+url.split('?')[0];
  }
  // console.log(url);
  // console.log(type);
  if(!isS3){
    return <iframe src={url} allow="autoplay" width="100%" height="auto" title="modal frame"></iframe>
  }
  else if (isImg) {
    return <img src={url} alt="Solution" width="100%"></img>
  }
  else{
    console.log('Data');
    return <Equation5 math_type={1} font_size="10px" font_family="Axiforma" equation={data}></Equation5>
  }
};

export default QodSolution;