import React, {Component} from 'react';
import Hexagon from './ProgressHexagon';

class ContentHexagonBox extends Component{
    render () {
        return (
            <div style={{width: '18%',margin: 'auto', border:'1px solid #fff', boxShadow: '0 0 10px #ddd', borderRadius: '10px', padding: '20px'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div>
                        <Hexagon progress={this.props.progress} value={this.props.value}></Hexagon>
                    </div>
                    <div>
                        <div>{this.props.title}</div>
                        <div>{this.props.description}</div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ContentHexagonBox;