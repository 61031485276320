import React, { useEffect } from 'react';
import RemedialAllStages from './RemedialAllStages';
import RemedialProvider from '../../features/RemedialPath/utils/context/Remedial/RemedialProvider';

const RemedialPath = () => {
	return (
		<RemedialProvider>
			<RemedialAllStages />
		</RemedialProvider>
	);
};

export default RemedialPath;
