import { createBrowserHistory } from "history";
import { useSpring, animated } from 'react-spring';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
export const history = createBrowserHistory()

export const serialize = function(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const getQueryVariable = (search, variable) => {
    var query = search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    console.log('Query variable %s not found', variable);
}



export const AnimatedNumber = ({ n , showPercentage = false,time=false }) => {
  const { number } = useSpring({
    from: { number: 0 },
    to: { number: n },
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10 },
  });

  return <span><animated.span>{number.interpolate((val) => val.toFixed(0))}</animated.span>{showPercentage && '%'}{time && ' Min'}</span>;
};



const Equation = ({ equation, math_type }) => {


    if (!equation) return null;
    equation = equation.toString();
    return (

        math_type === 0 ?
            <span dangerouslySetInnerHTML={{ __html: equation }} />
            :
            <Latex>{equation}</Latex>

    )

}
export default Equation;