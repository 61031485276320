import { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { getLoggedInUser } from "../../helpers/Auth";
import styled, { keyframes, css } from "styled-components";
import moment from "moment";
import ReactCountdownClock from "react-countdown-clock";
import { useEffect, useRef, useState } from "react";
import * as joinchallenge from "../../store/actions/joinchallengeactions";
import Joinedchallange from "./challangejoined";
import Singleteamdetails from "./allteams";
import Wheel from "./wheel";
import Leaderboard2 from "./leaderboard";
import "./joinchallange.css";
import * as Ably from "ably/promises";
import { AiFillLock } from "react-icons/ai";
import axios from "../../interceptor";
import { AiFillCloseCircle } from "react-icons/ai";
import { AiOutlineSend } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import Chatwidget from "./chatwindow/chatroom";
// import notification from process.env.PUBLIC_URL+"/Ringtones/ticking.mp3";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import RichTextEditor from "react-rte";
// import Toast from "react-bootstrap/Toast";
import ImageModal from "../subjective/ImageModal";
import Studentspanel from "./otherteams";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Equation from "../../components/Equation";
// import { ToastContainer, toast } from "react-toastify";
// import timeInterval from "./allteams";
let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
export let realtime = null;
export let channel = null;
var timeInterval;
let getlocalstorage = localStorage.getItem("user");
getlocalstorage = JSON.parse(getlocalstorage);
// const audio = new Audio(process.env.PUBLIC_URL + "/Ringtones/ticking.mp3");
let intervalStopper = true;
var timeInterval1=false;
const Animationdiv =styled.div`
font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
position: relative;
overflow: visible;
flex-direction: row;
-webkit-box-pack: start;
justify-content: flex-start;
-webkit-box-align: center;
align-items: center;
padding: 0.3125rem;
/*box-shadow: rgb(0 0 0 / 15%) 0px -0.25rem 0px 0px in;*/
height: auto;
width: calc(50% - 3rem);
display: flex;
/* flex: 1 0 auto; */
border-radius: 10px;
/*border: 1px dotted #808080b5;*/
box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
margin: 10px;
cursor: pointer;
margin-right: 460px;

${(props) =>
  props.shadowtype==2 &&
  css`
  opacity: 0.3;
  `}

  ${(props) =>
    props.shadowtype==1 &&
    css`
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border: 2px solid red;
    `}
&:before {
  ${(props) =>
    props.submission_percentage &&
    css`
    content: '';
    background-color: #efe3af;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    width: ${(props) => (props.submission_percentage)}%;
    transition: 2s;
    border-radius: 10px;
    `}
  }
`;
class Joinchallange extends Component {
  
  state = {
    challengeid: localStorage.getItem("challengeid"),
    joinchallenge: false,
    teamname: null,
    question: null,
    answer: "",
    answer_text: "",
    ablyConn: false,
    answers: [
      {
        id: 1,
        text: "France",
        img: "https://images-cdn.kahoot.it/ef482d16-1228-4f56-8bea-c2d21b1bfb23?auto=webp&width=1200",
      },
      { id: 2, text: "Egypt", img: "" },
      { id: 3, text: "Vatican city", img: "" },
      { id: 4, text: "Singapore", img: "" },
    ],
    finalleaderboard: false,
    team_regenerated: false,
    viewchallenge: false,
    offline: true,
    captainConfirmed: false,
    openImage: false,
    image_url: null,
    newmsgrecieved: false,
    current_position: false,
    current_questionid: false,
    timeralert: false,
    showtimealert: false,
    activeMembers:[],
    submittedQuestionOrderDataLen:false,
    lastSubmittedQuestionMembers:null,
    finalleaderboardAblyData: ""
  };
  openchatwidget = () => {
    this.setState({ showResults: true, newmsgrecieved: false });
    setTimeout(() => {
      document
        .getElementById("scrollintoview")
        .scrollIntoView({ behavior: "smooth" });
    }, 10);
  };

  closechatwidget = () =>
    this.setState({ showResults: false, newmsgrecieved: false });
  msgrecieved = () => {
    if (!this.state.showResults) {
      this.setState({ newmsgrecieved: true });
    }
  };
  hidetimeralert = () => {
    this.setState({ showtimealert: false });
  };
  handleteamedit = (teamdata, teamid,vicecaptainid) => {
    this.setState({ teamname: teamdata, captainConfirmed: true });
    this.props.UpdateTeamName(teamdata, teamid, this.state.challengeid,vicecaptainid);
    this.props.getteamdetails(this.state.challengeid);
    // if (this.props.CaptainConfirmedStatus == "Success") {
    channel.publish("captain_confirmed", `${teamid}_${teamdata}`);
    // }
  };
  
  generatingTeams = () => {
    // channel.unsubscribe();
    this.setState({
      ablyConn: false,
    });
    let time = 6;
    // let timeInterval;
    // console.log(this.state.ablyConn);
    this.setState({ loadingteams: true, viewchallenge: false });
  };
  captainConfirmed = (data) => {
    const teamid = data.split("_");
    // console.log(teamid[0]);
    // console.log(this.props.teamdetails.id);
    if (teamid[0] == this.props.teamdetails.id) {
      this.props.getteamdetails(this.state.challengeid);
      this.setState({ captainConfirmed: true });
      // console.log("confirmed" + this.state.captainConfirmed);
    }
    this.setState({ loadingteams: "Done", viewchallenge: false });
  };
  showTeams = () => {
    console.log("this.state.challengeid -> ",this.state.challengeid);
    this.props.getteamdetails(this.state.challengeid);
    this.setState({ loadingteams: "Done", viewchallenge: false });
  };
  regenerateteam = (msg) => {
    this.setState({
      ablyConn: false,
      team_regenerated: true,
    });
    this.props.getteamdetails(this.state.challengeid);
  };
  showspinwheelloader = (data) => {
    // console.log("before" + this.state.ablyConn);
    this.setState({
      ablyConn: false,
      viewchallenge: false,
    });
    // console.log("after" + this.state.ablyConn);
    this.setState({
      spinwheelloader: true,
      spinwheeldata: data,
      loadingteams: false,
      leaderboard: false,
    });
  };
  showSpinwheel = (msg) => {
    if (this.props.challengedetails.participation_type) {
      this.props.getteamdetails(this.state.challengeid);
      // clearInterval(timeInterval);
    }
    this.setState({
      ablyConn: false,
      viewchallenge: false,
      spinwheelloader: false,
      showspinwheel: true,
      selectedtopic: msg,
      loadingteams: false,
      teamid: this.props.teamdetails?.id ? this.props.teamdetails.id : null,
    });
  };

  updateQuestion = (msg) => {
    if (this.props.challengedetails.participation_type) {
      this.props.getteamdetails(this.state.challengeid);
    }
   
    this.setState({
      ablyConn: false,
    });
    let questiondata = JSON.parse(msg);
    if (this.props.challengedetails.is_viewer==1) {
      this.props.getQuestionSubmissionOrder(this.state.challengeid,questiondata.id)
    }
    this.setState({
      spinwheelloader: false,
      showspinwheel: false,
      question: JSON.parse(msg),
      timetaken: moment(),
      leaderboard: false,
      answer:
        questiondata.field_type === 1 ? "" : "",
      newmsgrecieved: false,
      timeralert: true,
    });
   
  };
  optionselected = (optionid) => {
    if (optionid == this.state.selectedoption) {
      this.setState({ selectedoption: false });
    } else {
      this.setState({ selectedoption: optionid });
    }
  };
  onAnswerChange = (event) => {
    console.log(event)
    console.log(event.target.value)
    let answer = "";
    answer = event;
    this.setState({ answer: event.target.value });
  };
  onSubmitAnswer = () => {
    if (channel && (this.state.selectedoption || this.state.answer) && this.props.challengedetails.is_viewer == 0) {
      const answer = this.state.answer.toString("html");
      if (this.state.question.field_type === 0) {
        this.props.onOptionSelected(
          this.state.challengeid,
          this.props.teamdetails?.id,
          this.state.question.id,
          this.state.selectedoption ? this.state.selectedoption : "",
          null,
          moment().diff(this.state.timetaken, "seconds"),
          0
        );
      } else if (this.state.question.field_type === 1) {
        this.props.onOptionSelected(
          this.state.challengeid,
          this.props.teamdetails?.id,
          this.state.question.id,
          null,
          answer.replace(/<\/?[^>]+(>|$)/g, "") ? answer : "",
          moment().diff(this.state.timetaken, "seconds"),
          1
        );
      } else {
        this.props.onOptionSelected(
          this.state.challengeid,
          this.props.teamdetails?.id,
          this.state.question.id,
          null,
          answer.replace(/<\/?[^>]+(>|$)/g, "") ? answer : "",
          moment().diff(this.state.timetaken, "seconds"),
          3
        );
      }
    }else if(channel && (this.state.selectedoption || this.state.answer) && this.props.challengedetails.is_viewer == 1){
      const answer = this.state.answer.toString("html");
      if (this.state.question.field_type === 0) {
        this.props.submitWeeklyChallengeViewerAnswer(
          this.state.challengeid,
          this.state.question.id,
          this.state.selectedoption ? this.state.selectedoption : "",
          null,
          moment().diff(this.state.timetaken, "seconds"),
          0
        );
      } else if (this.state.question.field_type === 1) {
        this.props.submitWeeklyChallengeViewerAnswer(
          this.state.challengeid,
          this.state.question.id,
          null,
          answer.replace(/<\/?[^>]+(>|$)/g, "") ? answer : "",
          moment().diff(this.state.timetaken, "seconds"),
          1
        );
      } else {
        this.props.submitWeeklyChallengeViewerAnswer(
          this.state.challengeid,
          this.state.question.id,
          null,
          answer.replace(/<\/?[^>]+(>|$)/g, "") ? answer : "",
          moment().diff(this.state.timetaken, "seconds"),
          3
        );
      }
      // clearInterval(timeInterval1);
      // timeInterval1 = false;
    }
    if(this.props.challengedetails.is_viewer == 1){
      if(this.props.challengedetails.no_of_questions - 1 === this.state.question.current_pos){
        this.finalleaderboard(this.state.finalleaderboardAblyData);
      }else{
        this.setState({
          question_submit: true,
          loading_leaderboard: true,
          time: 0,
          answer: "",
          question_time: 0,
          selectedoption: false,
          viewchallenge: false,
          showtimealert: false,
        });
      }
    }else{
      this.setState({
        question_submit: true,
        loading_leaderboard: true,
        time: 0,
        answer: "",
        question_time: 0,
        selectedoption: false,
        viewchallenge: false,
        showtimealert: false,
      });
    }
    
    this.props.Clearquestionsubmissionorder();
    // audio.pause();
    clearInterval(timeInterval);
    timeInterval = null;
  };
  timealert = async (time) => {
    let remainingtime = time;

    // console.log("remainning" + remainingtime);
    if (!timeInterval) {
      timeInterval = setInterval(() => {
        // console.log(remainingtime);
        // console.log("inside interval");
        if (remainingtime == 12 && remainingtime >= 1) {
          this.setState({ showtimealert: true });
          // let playPromise = audio.play();
          // if (playPromise !== undefined) {
          //   playPromise
          //     .then(function () {
          //       console.log("playing");
          //     })
          //     .catch(function (error) {
          //       console.log(error);
          //     });
          // }
        } else if (remainingtime == 8 && remainingtime >= 1) {
          // audio.play();
        } else if (remainingtime == 4 && remainingtime >= 1) {
          // audio.play();
        } else if (remainingtime == 0) {
          // audio.pause();
          clearInterval(timeInterval);
          timeInterval = null;
          return;
        }
        remainingtime--;
      }, 1000);
    }
  };
  showLeaderboard = (data) => {
    // audio.pause();
    clearInterval(timeInterval);
    timeInterval = null;
    let pos_questionid = data.split("_");
    // console.log(pos_questionid);
    if (this.props.challengedetails.participation_type) {
      this.props.getteamdetails(this.state.challengeid);
    }
    this.setState({
      ablyConn: false,
    });
    this.props.getleaderboarddata(this.state.challengeid, pos_questionid[2]);
    setTimeout(() => {
      this.setState({
        leaderboard: true,
        spinwheelloader: false,
        showspinwheel: false,
        loading_leaderboard: false,
        question_submit: false,
        viewchallenge: false,
        current_position: pos_questionid[1],
        current_questionid: pos_questionid[2],
        showtimealert: false,
        lastSubmittedQuestionMembers:false,
        submittedQuestionOrderDataLen:false,
      });
    }, 1000);
    clearInterval(timeInterval1);
    timeInterval1 = false;
    intervalStopper=true;
    this.props.Clearquestionsubmissionorder();
  };
  finalleaderboard = (data) => {
    // audio.pause();
    clearInterval(timeInterval1);
    timeInterval1 = false;
    let pos_questionid;
    if (data) {
      pos_questionid = data.split("_");
    } else {
      // console.log("position" + this.state.question.current_pos);
      // console.log("question id" + this.state.question.id);
      let questiondata = `1_${this.state.question.current_pos}_${this.state.question.id}`;
      pos_questionid = questiondata.split("_");
    }

    if (this.props.challengedetails.participation_type) {
      this.props.getteamdetails(this.state.challengeid);
    }
    this.setState({
      ablyConn: false,
    });
    this.props.getleaderboarddata(this.state.challengeid, pos_questionid[2]);
    setTimeout(() => {
      this.setState({
        finalleaderboard: true,
        leaderboard: true,
        spinwheelloader: false,
        showspinwheel: false,
        loading_leaderboard: false,
        question_submit: false,
        viewchallenge: false,
        current_position: pos_questionid[1],
        current_questionid: pos_questionid[2],
        showtimealert: false,
      });
    }, 1000);
    clearInterval(timeInterval);
        timeInterval = null;
  };

  updateStatus() {
    if (navigator.onLine) {
      // this.setState({ offline: "online" });
    } else {
      // this.setState({ offline: true });
    }
  }
  joinchallenge = () => {
    this.setState({ joinchallenge: false });
    this.props.checkCode(this.state.challengeid);
  };

  onFinished = () => {};
  //   spinwheel = () => {
  //     this.setState({ leaderboard: false });
  //     this.setState({ spinwheel: true });
  //   };
  //   questions = () => {
  //     this.setState({ questions: true });
  //   };
  removeTags(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }
  // removeTags(str) {
  //   if (str === null || str === "") return false;
  //   else str = str.toString();
  //   return str.replace(/(<([^>]+)>)/gi, "");
  // }
  resolveAfter2Seconds(x) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(x);
      }, 2000);
    });
  }

  componentDidMount = async () => {
    this.setState({ joinchallenge: false });
    const getchallengedetails = await this.props.getchallengedetails(
      this.state.challengeid
    );
   
    const returnpromise = await this.resolveAfter2Seconds(10);
    // console.log(returnpromise);
    // console.log("promise done");
    // console.log(this.props.challengedetails.is_member);
    // setTimeout(() => {
    // if (this.props.getchallengedetails) {
    // console.log("member" + this.props.challengedetails.is_member);
    if (this.props.challengedetails.is_member == 1) {
      // console.log("member in" + this.props.challengedetails.is_member);
      this.props.checkCode(this.state.challengeid);
      this.setState({ joinchallenge: true });
    } else if (this.props.challengedetails.is_viewer == 1) {
      // console.log("viewer");
      this.setState({ viewchallenge: true });
    }
    // }
    // }, 1500);
    const user = getLoggedInUser();
    realtime = new Ably.Realtime({
      authUrl: `${BASE_API_URL}/weekly_challenge/weeklyChallengeAuth?user_id=${user.user_id}&roll_number=${user.roll_number}`,
    });
    realtime.connection.once("connected", () => this.updateAblyConn(true));
    // console.log("fdefed");
    // console.log(getchallengedetails);
   
  };
  updateAblyConn = (ablyconn) => {
    // console.log("ablyconn" + ablyconn);
    this.setState({
      ablyConn: ablyconn,
    });
  };
  
  componentDidUpdate = (prevProps, prevState) => {
    console.log(prevProps)
    // console.log("------------------------"+this.props.loading_question_order)
    // console.log("presence");
    let channelid;
    if (this.props.challengedetails.is_member == 1) {
      channelid = this.props.challengedetails.participation_channel_id;
    } else if (this.props.challengedetails.is_viewer == 1) {
      channelid = this.props.challengedetails.viewer_channel_id;
    }
    if (this.state.ablyConn) {
      // console.log("1st in subscribe" + this.state.ablyConn);
      channel = realtime.channels.get(String(channelid));
      if (this.props.challengedetails.is_member == 1) {
        channel.presence.enter();
      }
      // console.log("done");
      channel.subscribe((msg) => {
        // console.log("in subscribe" + this.state.ablyConn);
        console.log(msg.name+" -> in subscripiton data -> ", msg.data);
        if (msg.name === "topic_selection_on")
          this.showspinwheelloader(msg.data);
        else if (msg.name === "topic_selected") this.showSpinwheel(msg.data);
        else if (msg.name === "captain_confirmed")
          this.captainConfirmed(msg.data);
        else if (msg.name === "question") this.updateQuestion(msg.data);
        else if (msg.name === "team_generated")
        {
          if(this.props.challengedetails.is_viewer === 1){
            this.generatingTeams();
            setTimeout(() => {
              this.showTeams();
            },1500)
          }else{
            this.generatingTeams();
          }
        }
        else if (msg.name === "show_team_timer") this.showTeams();
        else if (msg.name === "leader_board") this.showLeaderboard(msg.data);
        else if (msg.name === "team_regenerated") this.regenerateteam();
        else if (msg.name === "final_leader_board"){
          this.setState({
            finalleaderboardAblyData: msg.data,
          }) 
          this.finalleaderboard(msg.data);
        }

      });

      channel.presence.subscribe((msg) => {
         console.log(msg);
        const member_status = msg.clientId.split(/[-]/);
        const member_id = parseInt(member_status[0]);
        const action_status = msg.action;
        if (member_id !== getlocalstorage["user_id"]) {
            if (action_status === 'enter') {
                if (!this.state.activeMembers.includes(member_id)) {
                  this.setState({activeMembers:[...this.state.activeMembers, member_id]})
                  // console.log("_--------------------------------------")
                  // console.log(this.state.activeMembers)
                }
            } else if (action_status === 'update') {
                if (!this.state.activeMembers.includes(member_id)) {
                  this.setState({activeMembers:[...this.state.activeMembers, member_id]})
                }
            } else if (action_status === 'present') {
                if (!this.state.activeMembers.includes(member_id)) {
                  this.setState({activeMembers:[...this.state.activeMembers, member_id]})
                }
            } else if (action_status === 'leave') {
                const newMembers = this.state.activeMembers.filter((item) => item !== member_id);
                // console.log("_--------------------------------------leave")
                this.setState({activeMembers:newMembers})
            }
            // console.log(this.state.activeMembers)
        }
       
    });
   
      this.updateAblyConn(false);
    }
    if (
      this.state.question &&
      !this.state.showspinwheel &&
      !this.state.spinwheelloader &&
      !this.state.leaderboard &&
      !this.state.loading_leaderboard
    ) {
     
      if (
        this.props.challengedetails.is_member == 1 &&
        this.props.challengedetails.is_viewer == 0
      ) {
        this.timealert(this.props.challengedetails.time_per_question);
      }
    }
    // console.log("this.props.loading_question_order"+ this.props.loading_question_order)
    // console.log("this.props.error_loading_question_order"+ this.props.error_loading_question_order)
    // console.log("timeInterval1"+timeInterval1)
    if (!this.props.loading_question_order && !this.props.error_loading_question_order && !timeInterval1 && this.props.challengedetails.is_viewer==1) {
      console.log("inside 1st if did update");
      if (this.props.challengedetails.participation_type === 1) {
        console.log("inside second if in did update")
        this.setState({submittedQuestionOrderDataLen:this.props.question_order_details.data[0].teams.length})
    } else {
      this.setState({submittedQuestionOrderDataLen:this.props.question_order_details.data[0].participants.length})
    }
    console.log("calling data prepare-----------------------------")
    this.prepareresultsvisualizationdata(this.props.challengedetails.time_per_question, this.props.question_order_details.data[0]);
      
    }
  };
   prepareresultsvisualizationdata = async (time, question_data) => {
    console.log("----------------------insside prepare function")
    let end_timer = time;
    let start_timer = 0;
    let default_time;
    let timer = new Date().getTime();
    if (!timeInterval1) {
        timeInterval1 = setInterval(() => {
          console.log("----------------------inside set interval")
            let last_submitted_question = {};
            last_submitted_question["question_id"] = question_data.id;
            if (intervalStopper) {
                if (this.props.challengedetails.participation_type === 1) {
                    if (question_data.teams.length === 0) {
                        clearInterval(timeInterval);
                        timeInterval = null;
                        intervalStopper = false;
                        return;
                    }
                } else {
                    if (question_data.participants.length === 0) {
                        clearInterval(timeInterval);
                        timeInterval = null;
                        intervalStopper = false;
                        return;
                    }
                }
                if (start_timer === 2) {
                    let submitted_members = [];
                    if (this.props.challengedetails.participation_type === 1) {
                        default_time = question_data.teams[0].modified_at;
                        submitted_members.push(question_data.teams[0]);
                    } else {
                        default_time = question_data.participants[0].modified_at;
                        submitted_members.push(question_data.participants[0]);
                    }
                    console.log("s===", submitted_members);
                    last_submitted_question["submitted_members"] = submitted_members;
                    this.setState({lastSubmittedQuestionMembers:last_submitted_question})
                    //  console.log(this.state.lastSubmittedQuestionMembers);
                    // console.log("yes!1");
                }

                if ((start_timer % 3 === 0) && start_timer <= end_timer) {
                    let submitted_members = [];
                    if (this.props.challengedetails.participation_type === 1) {
                        for (let i = 0; i < question_data.teams.length; i++) {
                            if (Math.floor((new Date(question_data.teams[i].modified_at).getTime() - new Date(default_time).getTime()) / 1000) <= start_timer) {
                                submitted_members.push(question_data.teams[i]);
                            }
                        }
                    } else {
                        console.log("yes!2");
                        for (let i = 0; i < question_data.participants.length; i++) {
                            console.log("T = ", Math.floor((new Date(question_data.participants[i].modified_at).getTime() - new Date(default_time).getTime()) / 1000));
                            if (Math.floor((new Date(question_data.participants[i].modified_at).getTime() - new Date(default_time).getTime()) / 1000) <= start_timer) {
                                submitted_members.push(question_data.participants[i]);
                                console.log("yes 22");
                            }
                        }
                    }
                    last_submitted_question["submitted_members"] = submitted_members;
                    this.setState({lastSubmittedQuestionMembers:last_submitted_question})
                    // console.log("submitted members : ", last_submitted_question);
                }
                if (parseInt(start_timer) >= parseInt(end_timer)) {
                    clearInterval(timeInterval);
                    timeInterval = null;
                    intervalStopper = false;
                    return;
                }
                console.log("----------------------------")
                console.log(this.state.lastSubmittedQuestionMembers);
                console.log(this.state.question);
            }
            start_timer = start_timer + 1;
        }, 1000);
    }
};
  componentWillUnmount() {
    // this.setState({
    //   challengeid: false,
    //   joinchallenge: false,
    //   teamname: null,
    //   question: null,
    //   answer: "",
    //   answer_text: "",
    //   ablyConn: false,
    //   finalleaderboard: false,
    //   team_regenerated: false,
    //   viewchallenge: false,
    //   offline: true,
    //   captainConfirmed: false,
    //   openImage: false,
    //   image_url: null,
    // });

    // channel.close();
    if (this.props.challengedetails.is_member == 1) {
      channel.presence.leave();
    }
    // channel.detach();
    // channel.unsubscribe();
    // console.log("You are unsubscribed");
  }
  onImageClick = (url) => this.setState({ openImage: true, image_url: url });

  closeImage = () => this.setState({ openImage: false, image_url: null });
  
  render() {
    window.addEventListener("online", this.updateStatus);
    window.addEventListener("offline", this.updateStatus);
   
    let time;
    const img_extensions = [
      'png',
      'jpg',
      'jpeg',
      'gif',
      'tiff'
  ];
  const video_extensions = [
      'mp4',
      'mov',
      'wmv',
      'avi',
      'flv',
      'f4v',
      'swf',
      'mkv'
  ];
  const audio_extensions = [
      'mp3',
      'aac',
      'flac',
      'alac',
      'wac',
      'aiff',
      'dsd',
      'pcm'
  ];
    // console.log(
    //   "time remianing" +
    //     moment().diff(this.props.challengedetails.start_date, "seconds")
    // );
    // console.log("unix" + moment().unix());
    const date = new Date(this.props.challengedetails.start_date);
    const timestampInMs = date.getTime();

    const timestampInSeconds = Math.floor(date.getTime() / 1000);
    // console.log(timestampInSeconds);
    if (
      Math.abs(
        moment().diff(this.props.challengedetails.start_date, "seconds")
      ) < 900 &&
      timestampInSeconds > moment().unix()
    ) {
      time = Math.abs(
        moment().diff(this.props.challengedetails.start_date, "seconds")
      );
    } else {
      time = false;
    }

    const bordercol = [
      "green",
      "yellow",
      "blue",
      "red",
      "green",
      "yellow",
      "blue",
      "red",
    ];
    const options = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
    const optionbgcolors = [
      "#f7d5b2",
      "#fee091",
      "#f1c7db",
      "#bdcaff",
      "#f7d5b2",
      "#fee091",
      "#f1c7db",
      "#bdcaff",
    ];
    // console.log(this.props);

    if (
      !this.state.joinchallenge &&
      !this.props.joinchallenge &&
      !this.state.loadingteams &&
      this.state.loadingteams != "Done" &&
      !this.state.showspinwheel &&
      !this.state.spinwheelloader &&
      !this.state.question &&
      !this.state.leaderboard &&
      !this.state.viewchallenge
    ) {
      return (
        <div
          style={{
            // backgroundColor: "white",
            height: "700px",
            marginTop: "15%",
            marginLeft: '45%'
          }}
        >
          <div className="loader"></div>
        </div>
      );
    }
    if (
      this.state.joinchallenge &&
      this.props.joinchallenge &&
      !this.state.loadingteams &&
      this.state.loadingteams != "Done" &&
      !this.state.showspinwheel &&
      !this.state.spinwheelloader &&
      !this.state.question &&
      !this.state.leaderboard &&
      !this.state.viewchallenge
    ) {
      return (
        <div
          style={{
            backgroundColor: "white",
          }}
        >
          {/* {this.state.offline ? (
            
          ) : null} */}
          <Joinedchallange
            data={this.props.enrolled_data.data}
            time={time}
            onFinished={() => this.onFinished()}
          />
        </div>
      );
    }

    if (
      this.state.viewchallenge &&
      !this.props.joinchallenge &&
      !this.state.loadingteams &&
      this.state.loadingteams != "Done" &&
      !this.state.showspinwheel &&
      !this.state.spinwheelloader &&
      !this.state.question &&
      !this.state.leaderboard
    ) {
      return (
        <div
          style={{
            backgroundColor: "white",
            height: "700px",
          }}
        >
          <Maindiv>
            <img src="/gifs/animation_waiting_loader.gif" style={{marginLeft: '35%'}}></img>
            <div style={{ fontSize: "20px", fontWeight: "600" }}>
              Initializing the viewer environment!<br></br>Please wait..
              <br></br>The Arena is yet to initiate!
            </div>
          </Maindiv>
        </div>
      );
    }

    if (
      this.state.question_submit &&
      this.state.loading_leaderboard &&
      !this.state.showspinwheel &&
      !this.state.spinwheelloader &&
      !this.state.leaderboard
    ) {
      return (
        <div style={{ backgroundColor: "white" }}>
          <Maindiv>
            {/* <div className="loader"></div> */}
            <img src="/gifs/animation_waiting_loader.gif" style={{marginLeft: '35%'}}></img>
            <div style={{ fontSize: "20px", fontWeight: "600" }}>
              Generating the leaderboard for you...
            </div>
          </Maindiv>
        </div>
      );
    }
    if (this.state.spinwheelloader) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <div
            className="generatingteams"
            style={{ fontSize: "20px", marginTop: "21px" }}
          >
            Initiating Arena environment. Get ready
          </div>
          <div>
            <img
              src="/gifs/animation_initiating_challenge.gif"
              style={{ height: "400px", marginLeft: '35%' }}
            ></img>
          </div>
        </div>
      );
    }
    if (!this.state.spinwheelloader && this.state.showspinwheel) {
      return (
        <>
          {this.props.challengedetails.participation_type &&
          this.props.teamdetails ? (
            <div style={{ backgroundColor: "white" }}>
              <Wheel
                data={this.state.spinwheeldata}
                topicselected={this.state.selectedtopic}
                questionnumber={
                  this.state.question?.current_pos
                    ? this.state.question.current_pos + 1
                    : "1"
                }
              />
            </div>
          ) : !this.props.challengedetails.participation_type ? (
            <div style={{ backgroundColor: "white" }}>
              <Wheel
                data={this.state.spinwheeldata}
                topicselected={this.state.selectedtopic}
                questionnumber={
                  this.state.question?.current_pos
                    ? this.state.question.current_pos + 1
                    : "1"
                }
              />
            </div>
          ) : null}
        </>
      );
    }

    if (
      this.state.loadingteams &&
      !this.state.leaderboard &&
      !this.state.spinwheel
    ) {
      if (this.state.loadingteams == "Done" && (this.props.teamdetails || this.props.allteams)) {
        return (
          <div style={{ backgroundColor: "white" }}>
            <Singleteamdetails
              data={this.props.teamdetails}
              otherteams={this.props.allteams}
              editteamname={this.handleteamedit}
              showedit={this.state.team_regenerated}
              captainConfirmed={this.state.captainConfirmed}
              activemembers={this.state.activeMembers}
              viewerscreen={this.props.challengedetails.is_viewer==1 ?true:false}
            />
          </div>
        );
      } else {
        return (
          <div style={{ backgroundColor: "white" }}>
            <Maindiv>
              <h2 style={{ color: "red" }}>
                {this.props.challengedetails.title}
              </h2>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                  fontSize: "28px",
                  color: "#455a64"
                }}
                className="generatingteams"
              >
                Generating teams... Please wait
                {/* Generating teams<div className="dot1"> </div>
                <div className="dot2"></div>
                <div className="dot3"></div>Please wait */}
              </div>
              <img src="/gifs/animation_team_generation.gif" style={{marginLeft: '35%'}}></img>
            </Maindiv>
          </div>
        );
      }
    }

    if (
      this.state.question &&
      !this.state.showspinwheel &&
      !this.state.spinwheelloader &&
      !this.state.leaderboard
    ) {
      let showsubmitoption;
      let submit = "none";
      let autosubmitans = (
        <ReactCountdownClock
          seconds={this.props.challengedetails.time_per_question}
          alpha={0.9}
          color="red"
          size={70}
          weight={5}
          showMilliseconds={false}
          onComplete={this.onSubmitAnswer}
        />
      );
     
      if (
        this.props.challengedetails.participation_type &&
        this.props.challengedetails.is_member == 1 &&
        this.props.challengedetails.is_viewer == 0 &&
        this.props.teamdetails
      ) {
        {
          this.props.teamdetails.members.map((val, key) => {
            if (
              (val.student_id == getlocalstorage["user_id"] &&
              val.is_captain &&
              this.props.challengedetails.is_member) || (val.student_id == getlocalstorage["user_id"] &&
              val.is_vice_captain &&
              this.props.challengedetails.is_member)
            ) {
              submit = "cursor";
              showsubmitoption = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    onClick={this.onSubmitAnswer}
                    className="btn btn-danger"
                    style={{ marginTop: "7px" }}
                  >
                    Submit Answer
                  </button>
                </div>
              );

              // if (this.props.teamdetails) {
              //   console.log("call time alert");
              //   this.timealert(this.props.challengedetails.time_per_question);
              //   // this.setState({ timeralert: false });
              // }

              autosubmitans = (
                <ReactCountdownClock
                  seconds={this.props.challengedetails.time_per_question}
                  alpha={0.9}
                  color="red"
                  size={70}
                  weight={5}
                  onComplete={this.onSubmitAnswer}
                  showMilliseconds={false}
                />
              );
            }
            if (
              val.student_id == getlocalstorage["user_id"] &&
              !val.is_captain && !val.is_vice_captain &&
              this.props.challengedetails.is_member
            ) {
              submit = "none";
              showsubmitoption = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <span style={{ color: "red" }}>
                    Note:- Only your team captain & vice captain can answer!{" "}
                  </span>
                </div>
              );
            }
          });
        }
      } else if (
        this.props.challengedetails.participation_type == 0 &&
        this.props.challengedetails.is_member == 1 &&
        this.props.challengedetails.is_viewer == 0
      ) {
        submit = "cursor";
        showsubmitoption = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={this.onSubmitAnswer}
              className="btn btn-danger"
              style={{ marginTop: "7px" }}
            >
              Submit Answer
            </button>
          </div>
        );
        autosubmitans = (
          <ReactCountdownClock
            seconds={this.props.challengedetails.time_per_question}
            alpha={0.9}
            color="red"
            size={70}
            weight={5}
            showMilliseconds={false}
            onComplete={this.onSubmitAnswer}
          />
        );
      } else if (
        this.props.challengedetails.is_member == 0 &&
        this.props.challengedetails.is_viewer == 1 
      ) {
        // let finalleaderboard_id = `1_${this.state.question.current_pos}_${this.state.question.id}`;
        submit = "cursor";
        showsubmitoption = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={this.onSubmitAnswer}
              className="btn btn-danger"
              style={{ marginTop: "7px" }}
            >
              Submit Answer
            </button>
          </div>
        );
        autosubmitans = (
          <ReactCountdownClock
            seconds={this.props.challengedetails.time_per_question}
            alpha={0.9}
            color="red"
            size={70}
            weight={5}
            showMilliseconds={false}
            onComplete={this.onSubmitAnswer}
          />
        );
      }
      let hasimageoption = false;
      if (this.state.question.field_type == 0) {
        this.state.question.options.map(({ id, option, media }, index) => {
          if (media) {
            hasimageoption = true;
          }
        });
      }

      return (
        <div style={{ backgroundColor: "white" }}>
          <Maindiv_questionarrie>
            <div
              style={{
                float: "right",
                display: "flex",
                alignItems: "center",
                margin: "-25px 19px 0px 0px",
              }}
            >
              <span
                style={{
                  opacity: "0.5",
                  fontWeight: "700",
                  marginRight: "-24px",
                  position: "relative",
                }}
              >
                Time Left:-
              </span>

              {autosubmitans}
            </div>
            <h2 style={{ color: "red", paddingLeft: "107px" }}>
              {this.props.challengedetails.title}
            </h2>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  paddingRight: "10px",
                }}
              >
                Q{this.state.question.current_pos + 1}
              </div>
              <div className="questionsection" style={{ display: "flex", width: '90%' }}>
                <div className="classdiv" dir="auto">
                   <Equation
                          color="black"
                          bold
                          font_size="16px"
                          math_type={
                            this.state.question.question.includes("$")
                              ? 1
                              : 0
                          }
                          
                          equation={
                            this.state.question.question
                          }
                        ></Equation>
                  {/* <span role="heading" aria-level="1">
                    {this.removeTags(this.state.question.question)}
                  </span> */}
                  {this.state.question.question_img ? (
                    <div style={{ cursor: "pointer", textAlign: "center" }}>
                       {
                                                    img_extensions.includes(this.state.question.question_img.split('.').pop().toLowerCase())
                                                        ? (
                                                          <img
                                                          src={this.state.question.question_img}
                                                          alt="question"
                                                          onClick={() =>
                                                            this.onImageClick(this.state.question.question_img)
                                                          }
                                                          style={{ height: "70px", width: "max-content" }}
                                                        />
                                                        )
                                                        : video_extensions.includes(this.state.question.question_img.split('.').pop().toLowerCase())
                                                            ? (
                                                                <video alt="question video" controls style={{ width: "80%", marginLeft: "10%", marginTop: "2.5%" }}>
                                                                    <source src={this.state.question.question_img} type={`video/${this.state.question.question_img.split('.').pop().toLowerCase()}`} loop={true} />
                                                                </video>
                                                            )
                                                            : (
                                                                <audio controls style={{ marginTop: "2%" }}>
                                                                    {
                                                                        this.state.question.question_img.split('.').pop().toLowerCase() === "mp3"
                                                                            ? (<source src={this.state.question.question_img} type="audio/mpeg" />)
                                                                            : (<source src={this.state.question.question_img} type={`audio/${this.state.question.question_img.split('.').pop().toLowerCase()}`} />)
                                                                    }
                                                                    Your browser does not support the audio element.
                                                                </audio>
                                                            )
                                                }
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="verticalline"></div>
            <div>
              {
                !this.props.loading_question_order && this.state.lastSubmittedQuestionMembers
                && this.props.challengedetails.is_viewer==1 ? 
                  <>
                    {
                      this.state.question.field_type == 0 ?
                      <div className="answersdiv">
                      {this.state.question.options.map(
                        ({ id, option, media }, index) => {
                          let member_count = 0;
                          for (let i = 0; i < this.state.lastSubmittedQuestionMembers.submitted_members.length; i++) {
                            if (id === this.state.lastSubmittedQuestionMembers.submitted_members[i].option_id) {
                              member_count = member_count + 1;
                            }
                          }
                          const isselected = this.state.selectedoption === id;
                          const classname =
                            this.state.selectedoption && !isselected
                              ? "boxshadow answermaindiv"
                              : this.state.selectedoption && isselected
                              ? "boxshadow1 answermaindiv"
                              : "answermaindiv";
                          if (id == this.state.selectedoption) {
                            let isselected = true;
                          }
                          return (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                              key={index}
                            >
                               <div
                               style={{
                                 borderTop: `3px dashed ${optionbgcolors[index]}`,
                                 margin: "0px -10px 2px -38px",
                                 width: "9%",
                               }}
                             ></div>
                            
                             
                              <Animationdiv
                                onClick={() => this.optionselected(id)}
                                shadowtype={this.state.selectedoption && !isselected ? 2 : this.state.selectedoption && isselected ? 1: 0}
                                // style={{ pointerEvents: `${submit}`
                              // }}
                               submission_percentage={((member_count / this.state.submittedQuestionOrderDataLen) * 100)}
                              >
                                <div className="">
                                  <div
                                    className="card-icon__icon"
                                    style={{
                                      background: `${optionbgcolors[index]}`,
                                      opacity: "0.9",
                                    }}
                                  >
                                    {options[index]}
                                  </div>
                                </div>
                                
                                {media ? (
                                  <div
                                    role="image"
                                    data-functional-selector="image-answer"
                                    style={{
                                      display: "flex",
                                      position: "relative",
                                      backgroundSize: "contain",
                                      backgroundImage: `url('${media}')`,
                                      backgroundPosition: "left center",
                                      flex: "1 1 0%",
                                      height: "auto",
                                      alignSelf: "stretch",
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  ></div>
                                ) : null}
      
                                <span
                                  aria-live="off"
                                  dir="auto"
                                  className="answertext"
                                  style={{zIndex:'1'}}
                                >
                                  <span>{this.removeTags(option)}</span>
                                </span>
                                <span style={{fontWeight:"bold",zIndex:'1'}}> {
                                  member_count > 0
                                    ? `${parseFloat(((member_count / this.state.submittedQuestionOrderDataLen) * 100)).toFixed(2)}%`
                                    : "0%"
                                }
                                   </span>
                                {isselected ? (
                                  <AiFillLock
                                    style={{ color: "green", fontSize: "40px" }}
                                  />
                                ) : null}
                              </Animationdiv>
                            </div>
                          );
                        }
                      )}
                    </div>

                         : <div className="sub-answers">
                           <div><label><strong>Submitted Answers:</strong></label></div><br />
                           <div style={{height:"200px",overflow:"scroll"}}>
                              {
                                this.state.lastSubmittedQuestionMembers.submitted_members.map(subAnswer => (
                                   <span className="viewer-sub-answer-div">{this.removeTags(subAnswer.answer)}</span>
                              ))
                            }
                            </div>
                         </div>
                    }
                  </>
                
                :this.props.loading_question_order
                && this.props.challengedetails.is_viewer==1 ?
                <div>Loading......</div>
                :null
              }
            </div>
            {this.state.question.field_type == 0 && !hasimageoption && this.props.challengedetails.is_member == 1 ? (
              <div className="answersdiv">
                {this.state.question.options.map(
                  ({ id, option, media }, index) => {
                    const isselected = this.state.selectedoption === id;
                    const classname =
                      this.state.selectedoption && !isselected
                        ? "boxshadow answermaindiv"
                        : this.state.selectedoption && isselected
                        ? "boxshadow1 answermaindiv"
                        : "answermaindiv";
                    if (id == this.state.selectedoption) {
                      let isselected = true;
                    }
                    return (
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        key={index}
                      >
                        { this.props.challengedetails.is_member == 1 ?
                         <div
                         style={{
                           borderTop: `3px dashed ${optionbgcolors[index]}`,
                           margin: "0px -10px 2px -38px",
                           width: "9%",
                         }}
                       ></div>
                       :null
                      
                      }
                       
                        <div
                          color="#e21b3c"
                          onClick={() => this.optionselected(id)}
                          className={classname}
                         style={{ pointerEvents: `${submit}` }}
                        >
                          <div className="">
                            <div
                              className="card-icon__icon"
                              style={{
                                background: `${optionbgcolors[index]}`,
                                opacity: "0.9",
                              }}
                            >
                              {options[index]}
                            </div>
                          </div>
                          {media ? (
                            <div
                              role="image"
                              data-functional-selector="image-answer"
                              style={{
                                display: "flex",
                                position: "relative",
                                backgroundSize: "contain",
                                backgroundImage: `url('${media}')`,
                                backgroundPosition: "left center",
                                flex: "1 1 0%",
                                height: "auto",
                                alignSelf: "stretch",
                                backgroundRepeat: "no-repeat",
                              }}
                            ></div>
                          ) : null}

                          <span
                            aria-live="off"
                            dir="auto"
                            className="answertext"
                          >
                             <Equation
                          color="black"
                          bold
                          font_size="16px"
                          math_type={
                            option.includes("$")
                              ? 1
                              : 0
                          }
                          
                          equation={
                            option
                          }
                        ></Equation>
                            {/* <span>{this.removeTags(option)}</span> */}
                          </span>
                          {isselected ? (
                            <AiFillLock
                              style={{ color: "green", fontSize: "40px" }}
                            />
                          ) : null}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            ) : this.state.question.field_type == 0 && hasimageoption && this.props.challengedetails.is_member == 1 ? (
              <div className="answersdiv_withimage row">
                {this.state.question.options.map(
                  ({ id, option, media }, index) => {
                    const isselected = this.state.selectedoption === id;
                    const classname =
                      this.state.selectedoption && !isselected
                        ? "boxshadow answermaindiv"
                        : this.state.selectedoption && isselected
                        ? "boxshadow1 answermaindiv"
                        : "answermaindiv";
                    // console.log(classname);
                    if (id == this.state.selectedoption) {
                      let isselected = true;
                      // console.log(isselected);
                    }
                    // console.log(isselected);
                    return (
                      <>
                        <div
                        // onClick={this.changeStyle}
                        // className={
                        //   this.state.style == "boxshadow" && index != 2
                        //     ? "boxshadow col-lg-5 answerimagediv"
                        //     : this.state.style == "boxshadow" && index == 2
                        //     ? "boxshadow1 col-lg-5 answerimagediv"
                        //     : "col-lg-5 answerimagediv"
                        // }
                        >
                          <div className="row">
                            <div
                              className={`${classname} col-lg-2`}
                              style={{
                                paddingLeft: "7px",
                                paddingTop: "32px",
                                pointerEvents: `${submit}`,
                              }}
                              onClick={() => this.optionselected(id)}
                            >
                              <div
                                className="card-icon__icon"
                                style={{
                                  background: `${optionbgcolors[index]}`,
                                  opacity: "0.9",
                                }}
                              >
                                {options[index]}
                              </div>{" "}
                            </div>
                            <div
                              className="col-lg-5"
                              style={{ padding: "0px", textAlign: "left" }}
                            >
                              <img src={media} style={{ width: "95%" }} />
                            </div>
                            <div className="answertext col-lg-3">
                              <span>{option}</span>
                              {isselected ? (
                                <AiFillLock
                                  style={{ color: "green", fontSize: "40px" }}
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
              </div>
            ) :this.state.question.field_type == 1 ? (
              <div className="answersdiv">
                <textarea
                  className="RTE"
                  value={this.state.answer}
                  maxLength={32}
                  onChange={this.onAnswerChange}
                />
              </div>
            ):null}
            {showsubmitoption}
            <ToastContainer className="p-3" position="top-center">
              <Toast
                animation={true}
                show={this.state.showtimealert}
                onClose={this.hidetimeralert}
              >
                <Toast.Header
                  style={{ paddingLeft: "23px", background: "#f43e3e" }}
                >
                  <img src="/gifs/time_alert.gif" style={{ height: "70px" }} />
                  <strong className="me-auto" style={{ color: "white" }}>
                    Alert! Alert! <br /> Running out of time!
                  </strong>
                </Toast.Header>
              </Toast>
            </ToastContainer>

            {/* <button onClick={this.leaderboard}>Leaderboard</button> */}
            {this.props.challengedetails.participation_type &&
            this.props.challengedetails.is_member == 1 &&
            this.props.challengedetails.is_viewer == 0 &&
            this.props.teamdetails ? (
              <>
                <div
                  style={{
                    // float: "right",
                    cursor: "pointer",
                    position: "fixed",
                    bottom: "20px",
                    right: "86px",
                  }}
                  onClick={this.openchatwidget}
                  title={`Chat with team`}
                >
                  {this.state.newmsgrecieved ? (
                    <img
                      src="/gifs/new-message-recieved.gif"
                      style={{
                        position: "absolute",
                        top: "-15px",
                        left: "-39px",
                        width: "41px",
                        borderRadius: "20px",
                      }}
                    ></img>
                  ) : null}
                  <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
                  {/* <div>{`Team`}</div> */}
                </div>
                <div
                  className={this.state.showResults ? "fadeIn" : "fadeOut"}
                  id="supportmonboxid"
                >
                  <div
                    className="supportmonbox_head col-md-12 col-sm-12"
                    id="supbox"
                    style={{ display: "block", height: "41px" }}
                  >
                    <div className="col-xs-1" style={{ float: "right" }}>
                      <AiFillCloseCircle
                        onClick={this.closechatwidget}
                        style={{ fontSize: "26px", cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  <Chatwidget
                    roomid={`${this.props.challengedetails.id}_${this.props.teamdetails.id}`}
                    foundnewmsg={this.msgrecieved}
                  />
                </div>
                <Studentspanel data={this.props.allteams} />
              </>
            ) :this.props.challengedetails.is_member == 0 &&
            this.props.challengedetails.is_viewer == 1  ?
            (
              <>
                <div
                  style={{
                    float: "right",
                    cursor: "pointer",
                    position: "relative",
                    bottom: "50px",
                  }}
                  onClick={this.openchatwidget}
                  title={`Chat with team`}
                >
                  {this.state.newmsgrecieved ? (
                    <img
                      src="/gifs/new-message-recieved.gif"
                      style={{
                        position: "absolute",
                        top: "-15px",
                        left: "-39px",
                        width: "41px",
                        borderRadius: "20px",
                      }}
                    ></img>
                  ) : null}
                  <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
                  {/* <div>{`Team`}</div> */}
                </div>
  
                <div
                  className={this.state.showResults ? "fadeIn" : "fadeOut"}
                  id="supportmonboxid"
                >
                  <div
                    className="supportmonbox_head col-md-12 col-sm-12"
                    id="supbox"
                    style={{ display: "block", height: "41px" }}
                  >
                    <div className="col-xs-1" style={{ float: "right" }}>
                      <AiFillCloseCircle
                        onClick={this.closechatwidget}
                        style={{ fontSize: "26px", cursor: "pointer" }}
                      />
                    </div>
                  </div>
  
                  <Chatwidget
                    roomid={`${this.props.challengedetails.id}_Viewer`}
                    foundnewmsg={this.msgrecieved}
                  />
                </div>
                {this.props.challengedetails.participation_type==1 ?
                <Studentspanel data={this.props.allteams} />
              :null}
              </>
            )
            : null}
          </Maindiv_questionarrie>
          {this.state.openImage && (
            <ImageModal
              width="500px"
              height="70%"
              padding="none"
              style={{ zIndex: 12 }}
              closeDialog={this.closeImage}
              open={this.state.openImage}
              url={this.state.image_url}
            ></ImageModal>
          )}
        </div>
      );
    }

    if (this.state.leaderboard && this.props.leaderboarddata) {
      return (
        <>
          {this.props.challengedetails.participation_type &&
          this.props.teamdetails &&
          this.props.allteams ? (
            <div
              style={
                !this.state.finalleaderboard
                  ? {
                      background: "linear-gradient(45deg,#FDF3C2, #FFC2D5)",
                      height: "100%",
                    }
                  : {}
              }
            >
              <Leaderboard2
                data={this.props.leaderboarddata}
                data1={this.props.leaderboarddata}
                results={this.state.finalleaderboard}
                current_pos={this.state.current_position}
                otherteams={this.props.allteams}
                current_questionid={this.state.current_questionid}
              />
            </div>
          ) : !this.props.challengedetails.participation_type ? (
            <div
              style={
                !this.state.finalleaderboard
                  ? {
                      background: "linear-gradient(45deg,#FDF3C2, #FFC2D5)",
                      height: "100%",
                    }
                  : {}
              }
            >
              <Leaderboard2
                data={this.props.leaderboarddata}
                data1={this.props.leaderboarddata}
                results={this.state.finalleaderboard}
                current_pos={this.state.current_position}
                current_questionid={this.state.current_questionid}
              />
            </div>
          ) : null}
        </>
      );
    }

    return (
      <div>
        {/* <Maindiv>
  <h2 style={{color:"red"}}>{this.props.challengedetails.title}</h2>
  {this.state.joinchallenge ? 
    <div>
    <Customh4 style={{opacity:"0.5",fontWeight:"700",paddingTop:"62px"}}>Starts at</Customh4>
    <h3 style={{fontWeight:"700"}}>{this.props.challengedetails.start_date}</h3>
    <Joinaction onClick={this.joinchallenge}>Join the action</Joinaction>
    <p style={{padding:"10px"}}>Total registrations:127</p></div>
    : <div><div className="loader"></div></div>}
    </Maindiv>  */}
      </div>
    );
  }
}
const Maindiv = styled.div`
  height: auto;
  border-radius: 50px;
  margin: 0px 30px 0px 30px;
  padding: 35px;
  background-color: white;
  @media (max-width: 768px) {
    height: auto;
  }
  @media (max-width: 1024px) {
    height: auto;
  }
`;
const Joinaction = styled.button`
  border-radius: 9px;
  background-color: red;
  color: white;
  padding: 8px;
  font-size: 16px;
  border: navajowhite;
  margin-top: 45px;
`;
const Customh4 = styled.h4`
  opacity: 0.5;
  font-weight: 700;
  padding-top: 62px;
  @media (max-width: 768px) {
    padding-top: 17px;
  }
`;
const Maindiv_questionarrie = styled.div`
  height: auto;
  border-radius: 50px;
  margin: 0px 30px 0px 30px;
  padding: 35px;
  background-color: white;
  @media (max-width: 768px) {
    height: auto;
  }
  @media (max-width: 1024px) {
    height: auto;
  }
`;
const mapStateToProps = (state) => {
  // console.log(state);
  return {
    challengedetails: state.joinchallenge.challangedetails,
    joinchallenge: state.joinchallenge.joinedchallenge,
    enrolled_data: state.joinchallenge.enrolled_data,
    teamdetails: state.joinchallenge.teamdetails,
    allteams: state.joinchallenge.allteams,
    leaderboarddata: state.joinchallenge.leaderboarddetails,
    CaptainConfirmedStatus: state.joinchallenge.captainconfirmed,
    getchallengedetails: state.joinchallenge.getchallengedetails,
    error_loading_question_order:state.joinchallenge.error_loading_question_order,
    loading_question_order:state.joinchallenge.loading_question_order,
    question_order_details: state.joinchallenge.question_order_details,
  };
  // console.log(state)
};

const mapDispatchToProps = (dispatch) => {
  return {
    refreshToken: (challengeid) => dispatch(joinchallenge.refreshTokenAC()),
    getchallengedetails: (challengeid) =>
      dispatch(joinchallenge.getchallengedetails(challengeid)),
    checkCode: (challengeid) =>
      dispatch(joinchallenge.joinedchallenge(challengeid)),
    getteamdetails: (challengeid) =>
      dispatch(joinchallenge.getTeamMembers(challengeid)),
    UpdateTeamName: (teamdata, teamid, challengeid,vicecaptainid) =>
      dispatch(joinchallenge.UpdateTeamName(teamdata, teamid, challengeid,vicecaptainid)),
    onOptionSelected: (
      weekly_challenge_id,
      team_id,
      qid,
      option_id,
      answer,
      timetaken,
      field_type
    ) =>
      dispatch(
        joinchallenge.onOptionSelectedSurveyAC(
          weekly_challenge_id,
          team_id,
          qid,
          option_id,
          answer,
          timetaken,
          field_type
        )
      ),
    getleaderboarddata: (challengeid, questionid) =>
      dispatch(joinchallenge.getLeaderboardDetails(challengeid, questionid)),
      submitWeeklyChallengeViewerAnswer: (
        weekly_challenge_id,
        qid,
        option_id,
        answer,
        timetaken,
        field_type
      ) =>
        dispatch(
          joinchallenge.submitWeeklyChallengeViewerAnswer(
            weekly_challenge_id,
            qid,
            option_id,
            answer,
            timetaken,
            field_type
          )
        ),
        getQuestionSubmissionOrder: (challengeid,question_id) =>
        dispatch(joinchallenge.getQuestionSubmissionOrder(challengeid,question_id)),
        Clearquestionsubmissionorder: () =>dispatch({ type: "CLEAR_SUBMISSIONORDER" })
      
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Joinchallange)
);
// export default Joinchallange;
