import { Box, Modal, Stack } from '@mui/material'
import React from 'react'
import RedmedialButton from '../../../features/RemedialPath/components/RemedialButtons/RedmedialButton'

const ModalWithNoHeader = ({ children, show, onCloseHandle, size, headBodyGap = 10,
    closeButtonText = 'Click To Close',
    buttonColor = 'externalerror.200',
    buttonTextColor = 'primary.contrastText',
    buttonSize = 'large',
    buttonTextSize,
    buttonBoderRadius,
    isButtonDisable = false


}) => {
    return (
        <Modal open={show} onClose={onCloseHandle} disableAutoFocus aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 360,
                bgcolor: theme => theme.palette.grey[1000],
                padding: '32px',
                borderRadius: '8px'
            }}>
                <Stack gap={`${headBodyGap}px`}>
                    {children}
                    <RedmedialButton onClick={onCloseHandle}
                        color={buttonColor}
                        textColor={buttonTextColor}
                        size={buttonSize}
                        fullWidth
                        textSize={buttonTextSize}
                        borderRadius={buttonBoderRadius}
                        disable={isButtonDisable}

                    >{closeButtonText}</RedmedialButton>
                </Stack>
            </Box>
        </Modal>
    )

}

export default ModalWithNoHeader


