import React from 'react'
import { BannerAnimationWraper } from './BannerMovingAnimationStyled';


const BannerMovingAnimation = () => {
    return (
        <>
            <BannerAnimationWraper>
                <div className='banner-layer banner-layer-1'> <TreesSvg /></div>
                <div className='banner-layer banner-layer-2'> <TreesSvg /></div>
                <div className='banner-layer banner-layer-3'> <TreesSvg /></div>
                <div className='banner-layer cloud banner-layer-1'> <CloudAndStarSvg /></div>
                <div className='banner-layer cloud banner-layer-2'> <CloudAndStarSvg /></div>
                <div className='banner-layer cloud banner-layer-3'> <CloudAndStarSvg /></div>
            </BannerAnimationWraper>
        </>

    )
}

export default BannerMovingAnimation






const TreesSvg = () => {
    return (
        <svg width="950" height="166" viewBox="0 0 950 166" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5" d="M247.176 64.831C247.176 64.831 261.32 66.231 268.489 75.0007C268.489 75.0007 276.068 64.8391 289.673 65.0483C292.998 61.0369 295.114 56.1638 295.777 50.9961C296.439 45.8285 295.62 40.579 293.415 35.8589C291.209 31.1387 287.708 27.1423 283.319 24.3347C278.931 21.5271 273.835 20.024 268.625 20.0003C263.415 19.9766 258.306 21.4334 253.892 24.201C249.478 26.9686 245.941 30.9331 243.692 35.633C241.444 40.3328 240.577 45.5747 241.193 50.7481C241.808 55.9216 243.88 60.8138 247.168 64.8552L247.176 64.831Z" fill="white" fill-opacity="0.3" />
            <path d="M268.022 21.9648L267.547 165.499H269.928L268.022 21.9648Z" fill="white" fill-opacity="0.3" />
            <path d="M268.737 72.3528C268.737 72.3528 273.517 63.752 282.109 61.0889C282.109 61.0889 273.034 63.237 268.496 70.639L268.737 72.3528Z" fill="white" fill-opacity="0.3" />
            <path d="M268.215 48.2255C268.215 48.2255 273.517 39.3753 282.11 36.7686C282.11 36.7686 272.753 39.4639 268.215 46.8658V48.2255Z" fill="white" fill-opacity="0.3" />
            <path d="M268.401 60.8088C268.401 60.8088 273.518 51.5965 282.078 48.9736C282.078 48.9736 272.786 51.5482 268.248 58.9502L268.401 60.8088Z" fill="white" fill-opacity="0.3" />
            <path d="M268.029 54.2043C268.029 54.2043 263.419 45.6035 255.156 42.9404C255.156 42.9404 263.918 45.0886 268.295 52.4906L268.029 54.2043Z" fill="white" fill-opacity="0.3" />
            <path d="M268.029 66.1436C268.029 66.1436 263.419 57.5428 255.156 54.9199C255.156 54.9199 263.918 57.0681 268.295 64.4701L268.029 66.1436Z" fill="white" fill-opacity="0.3" />
            <path opacity="0.5" d="M712.14 51.1192C712.14 51.1192 728.268 52.7155 736.442 62.7153C736.442 62.7153 745.085 51.1284 760.598 51.3669C764.389 46.7929 766.802 41.2362 767.558 35.3437C768.313 29.4513 767.379 23.4654 764.864 18.0833C762.349 12.7011 758.357 8.14405 753.353 4.94267C748.349 1.74129 742.538 0.0273112 736.597 0.000323686C730.657 -0.0266638 724.831 1.63444 719.797 4.79022C714.764 7.94601 710.731 12.4666 708.168 17.8257C705.604 23.1848 704.615 29.1619 705.317 35.061C706.019 40.9601 708.382 46.5385 712.131 51.1467L712.14 51.1192Z" fill="white" fill-opacity="0.3" />
            <path d="M735.91 2.24023L735.369 165.907H738.085L735.91 2.24023Z" fill="white" fill-opacity="0.3" />
            <path d="M736.726 59.6963C736.726 59.6963 742.176 49.8892 751.974 46.8525C751.974 46.8525 741.625 49.302 736.451 57.7422L736.726 59.6963Z" fill="white" fill-opacity="0.3" />
            <path d="M736.129 32.1851C736.129 32.1851 742.175 22.0935 751.973 19.1211C751.973 19.1211 741.303 22.1944 736.129 30.6347V32.1851Z" fill="white" fill-opacity="0.3" />
            <path d="M736.342 46.5323C736.342 46.5323 742.177 36.0279 751.938 33.0371C751.938 33.0371 741.342 35.9728 736.168 44.413L736.342 46.5323Z" fill="white" fill-opacity="0.3" />
            <path d="M735.919 39.002C735.919 39.002 730.662 29.1948 721.24 26.1582C721.24 26.1582 731.231 28.6077 736.222 37.0479L735.919 39.002Z" fill="white" fill-opacity="0.3" />
            <path d="M735.919 52.6153C735.919 52.6153 730.662 42.8082 721.24 39.8174C721.24 39.8174 731.231 42.2669 736.222 50.7071L735.919 52.6153Z" fill="white" fill-opacity="0.3" />
            <path opacity="0.5" d="M641.863 85.6438C641.863 85.6438 652.999 86.7461 658.644 93.651C658.644 93.651 664.611 85.6501 675.323 85.8148C677.941 82.6565 679.608 78.8196 680.129 74.7508C680.651 70.682 680.006 66.5487 678.269 62.8323C676.533 59.1159 673.776 55.9692 670.321 53.7586C666.865 51.5481 662.853 50.3646 658.751 50.3459C654.649 50.3273 650.626 51.4743 647.15 53.6534C643.675 55.8325 640.89 58.954 639.12 62.6545C637.35 66.3549 636.667 70.4822 637.152 74.5555C637.636 78.6289 639.268 82.4808 641.857 85.6628L641.863 85.6438Z" fill="white" fill-opacity="0.3" />
            <path opacity="0.5" d="M287.358 124.932C287.358 124.932 292.759 125.492 295.496 129C295.496 129 298.39 124.935 303.584 125.019C304.854 123.415 305.662 121.465 305.915 119.398C306.168 117.331 305.855 115.231 305.013 113.343C304.171 111.455 302.834 109.857 301.158 108.734C299.483 107.611 297.537 107.01 295.548 107C293.558 106.991 291.608 107.573 289.922 108.68C288.237 109.787 286.886 111.373 286.028 113.253C285.17 115.133 284.839 117.23 285.074 119.299C285.308 121.368 286.1 123.325 287.355 124.942L287.358 124.932Z" fill="white" fill-opacity="0.3" />
            <path d="M658.276 51.8926L657.902 164.905H659.777L658.276 51.8926Z" fill="white" fill-opacity="0.3" />
            <path d="M295.199 108L295 165H296L295.199 108Z" fill="white" fill-opacity="0.3" />
            <path d="M658.84 91.566C658.84 91.566 662.603 84.7941 669.369 82.6973C669.369 82.6973 662.223 84.3887 658.65 90.2167L658.84 91.566Z" fill="white" fill-opacity="0.3" />
            <path d="M296.089 128C296.089 128 297.844 124.946 301 124C301 124 297.667 124.763 296 127.391L296.089 128Z" fill="white" fill-opacity="0.3" />
            <path d="M658.428 72.5696C658.428 72.5696 662.602 65.6013 669.368 63.5488C669.368 63.5488 662.001 65.671 658.428 71.499V72.5696Z" fill="white" fill-opacity="0.3" />
            <path d="M296 119C296 119 297.908 114.365 301 113C301 113 297.633 114.412 296 118.288V119Z" fill="white" fill-opacity="0.3" />
            <path d="M658.573 82.4767C658.573 82.4767 662.602 75.2233 669.343 73.1582C669.343 73.1582 662.026 75.1853 658.453 81.0134L658.573 82.4767Z" fill="white" fill-opacity="0.3" />
            <path d="M296.055 124C296.055 124 297.905 120.108 301 119C301 119 297.64 120.088 296 123.215L296.055 124Z" fill="white" fill-opacity="0.3" />
            <path d="M658.282 77.276C658.282 77.276 654.652 70.504 648.146 68.4072C648.146 68.4072 655.045 70.0986 658.491 75.9266L658.282 77.276Z" fill="white" fill-opacity="0.3" />
            <path d="M295.899 121C295.899 121 294.145 117.182 291 116C291 116 294.334 116.954 296 120.239L295.899 121Z" fill="white" fill-opacity="0.3" />
            <path d="M658.282 86.6779C658.282 86.6779 654.652 79.906 648.146 77.8408C648.146 77.8408 655.045 79.5322 658.491 85.3602L658.282 86.6779Z" fill="white" fill-opacity="0.3" />
            <path d="M295.899 125C295.899 125 294.145 121.935 291 121C291 121 294.334 121.766 296 124.404L295.899 125Z" fill="white" fill-opacity="0.3" />
            <path opacity="0.5" d="M4.8629 83.6438C4.8629 83.6438 15.9995 84.7461 21.6438 91.651C21.6438 91.651 27.6112 83.6501 38.3233 83.8148C40.941 80.6565 42.6075 76.8196 43.129 72.7508C43.6505 68.682 43.0055 64.5487 41.269 60.8323C39.5326 57.1159 36.7761 53.9692 33.3206 51.7586C29.8651 49.5481 25.8528 48.3646 21.7507 48.3459C17.6487 48.3273 13.6258 49.4743 10.1503 51.6534C6.67488 53.8325 3.88995 56.954 2.11979 60.6545C0.349625 64.3549 -0.332936 68.4822 0.15158 72.5555C0.636095 76.6289 2.26775 80.4808 4.85657 83.6628L4.8629 83.6438Z" fill="white" fill-opacity="0.3" />
            <path d="M21.2761 49.8926L20.9023 162.905H22.7774L21.2761 49.8926Z" fill="white" fill-opacity="0.3" />
            <path d="M21.8404 89.566C21.8404 89.566 25.6033 82.7941 32.3689 80.6973C32.3689 80.6973 25.2232 82.3887 21.6504 88.2167L21.8404 89.566Z" fill="white" fill-opacity="0.3" />
            <path d="M21.4277 70.5696C21.4277 70.5696 25.6024 63.6013 32.3679 61.5488C32.3679 61.5488 25.0006 63.671 21.4277 69.499V70.5696Z" fill="white" fill-opacity="0.3" />
            <path d="M21.5735 80.4767C21.5735 80.4767 25.6024 73.2233 32.3427 71.1582C32.3427 71.1582 25.026 73.1853 21.4531 79.0134L21.5735 80.4767Z" fill="white" fill-opacity="0.3" />
            <path d="M21.2822 75.276C21.2822 75.276 17.6523 68.504 11.1465 66.4072C11.1465 66.4072 18.0451 68.0986 21.4912 73.9266L21.2822 75.276Z" fill="white" fill-opacity="0.3" />
            <path d="M21.2822 84.6779C21.2822 84.6779 17.6523 77.906 11.1465 75.8408C11.1465 75.8408 18.0451 77.5322 21.4912 83.3602L21.2822 84.6779Z" fill="white" fill-opacity="0.3" />
        </svg>
    )
}

const CloudAndStarSvg = () => {
    return (
        <svg width="950" height="137" viewBox="0 0 950 137" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M761.358 20.8033C757.531 20.3088 757.18 19.9597 756.684 16.146C756.684 16.1031 756.667 16.062 756.637 16.0317C756.606 16.0014 756.565 15.9844 756.522 15.9844C756.479 15.9844 756.438 16.0014 756.407 16.0317C756.377 16.062 756.36 16.1031 756.36 16.146C755.863 19.9597 755.513 20.3088 751.686 20.8033C751.646 20.8087 751.61 20.828 751.584 20.8576C751.558 20.8873 751.543 20.9254 751.543 20.9649C751.543 21.004 751.558 21.0416 751.584 21.0708C751.611 21.0999 751.647 21.1185 751.686 21.1232C755.513 21.621 755.863 21.9668 756.36 25.7805C756.36 25.8234 756.377 25.8645 756.407 25.8948C756.438 25.9251 756.479 25.9421 756.522 25.9421C756.565 25.9421 756.606 25.9251 756.637 25.8948C756.667 25.8645 756.684 25.8234 756.684 25.7805C757.18 21.9668 757.531 21.621 761.358 21.1232C761.397 21.1185 761.433 21.0999 761.459 21.0708C761.485 21.0416 761.5 21.004 761.501 20.9649C761.5 20.9254 761.486 20.8873 761.46 20.8576C761.433 20.828 761.397 20.8087 761.358 20.8033ZM756.522 24.6299C756.09 22.0217 755.461 21.398 752.844 20.9649C755.461 20.535 756.087 19.908 756.522 17.2966C756.956 19.908 757.582 20.5286 760.203 20.9649C757.582 21.3947 756.956 22.0217 756.522 24.6299Z" fill="white" />
            <path d="M462.798 40.2796C459.415 39.8434 459.105 39.534 458.668 36.1527C458.659 36.1096 458.636 36.0709 458.602 36.043C458.568 36.0152 458.525 36 458.481 36C458.437 36 458.394 36.0152 458.36 36.043C458.326 36.0709 458.303 36.1096 458.294 36.1527C457.857 39.534 457.547 39.8434 454.164 40.2796C454.119 40.2858 454.078 40.3081 454.047 40.3423C454.017 40.3765 454 40.4204 454 40.466C454.001 40.5111 454.018 40.5545 454.048 40.5881C454.078 40.6217 454.119 40.6432 454.164 40.6487C457.547 41.0886 457.857 41.3943 458.294 44.7756C458.294 44.825 458.314 44.8724 458.349 44.9074C458.384 44.9423 458.431 44.962 458.481 44.962C458.531 44.962 458.578 44.9423 458.613 44.9074C458.648 44.8724 458.668 44.825 458.668 44.7756C459.105 41.398 459.415 41.0886 462.798 40.6487C462.843 40.6432 462.884 40.6217 462.914 40.5881C462.944 40.5545 462.961 40.5111 462.962 40.466C462.962 40.4204 462.945 40.3765 462.915 40.3423C462.884 40.3081 462.843 40.2858 462.798 40.2796ZM458.481 43.4931C458.108 41.4129 457.529 40.8388 455.449 40.466C457.529 40.0932 458.108 39.5153 458.481 37.4388C458.854 39.5153 459.433 40.0932 461.513 40.466C459.433 40.8388 458.854 41.4129 458.481 43.4931Z" fill="white" />
            <path d="M799.023 80.8489C794.206 80.2252 793.829 79.8409 793.228 75.0472C793.217 74.9607 793.175 74.8811 793.109 74.8236C793.044 74.7661 792.96 74.7347 792.873 74.7354C792.785 74.7345 792.7 74.7657 792.633 74.823C792.566 74.8804 792.523 74.9601 792.51 75.0472C791.887 79.8699 791.504 80.2542 786.715 80.8489C786.628 80.8611 786.547 80.9044 786.489 80.9709C786.43 81.0375 786.397 81.1229 786.396 81.2115C786.398 81.2993 786.431 81.3838 786.489 81.4491C786.548 81.5145 786.628 81.5563 786.715 81.5669C791.525 82.1978 791.909 82.5749 792.51 87.3686C792.523 87.4557 792.566 87.5354 792.633 87.5927C792.7 87.6501 792.785 87.6813 792.873 87.6804C792.96 87.6811 793.044 87.6497 793.109 87.5922C793.175 87.5347 793.217 87.4551 793.228 87.3686C793.858 82.5459 794.235 82.1688 799.023 81.5669C799.11 81.5563 799.19 81.5145 799.249 81.4491C799.308 81.3838 799.34 81.2993 799.342 81.2115C799.341 81.1229 799.308 81.0375 799.249 80.9709C799.191 80.9044 799.111 80.8611 799.023 80.8489ZM792.844 85.0624C792.315 82.5894 791.467 81.7337 788.99 81.2115C791.467 80.6821 792.315 79.8336 792.844 77.3534C793.365 79.8336 794.22 80.6821 796.69 81.2115C794.22 81.7337 793.365 82.5894 792.844 85.0624Z" fill="white" />
            <path d="M234.655 43.3056C230.206 43.8873 229.858 44.235 229.302 48.655C229.293 48.7354 229.254 48.8095 229.194 48.8637C229.134 48.9178 229.056 48.9482 228.975 48.9492C228.893 48.9484 228.814 48.9182 228.753 48.8642C228.691 48.8102 228.651 48.736 228.64 48.655C228.065 44.2083 227.71 43.8606 223.288 43.3056C223.208 43.2944 223.136 43.2552 223.083 43.195C223.03 43.1349 223.001 43.0579 223 42.9779C223.001 42.8971 223.03 42.8192 223.083 42.758C223.135 42.6968 223.208 42.6562 223.288 42.6436C227.737 42.0685 228.092 41.7141 228.64 37.2941C228.651 37.2132 228.691 37.1389 228.753 37.0849C228.814 37.0309 228.893 37.0007 228.975 36.9999C229.056 37.0009 229.134 37.0314 229.194 37.0855C229.254 37.1396 229.293 37.2137 229.302 37.2941C229.885 41.7342 230.232 42.0886 234.655 42.6436C234.736 42.6548 234.81 42.6947 234.864 42.7561C234.918 42.8174 234.948 42.8962 234.949 42.9779C234.948 43.0589 234.918 43.1367 234.864 43.197C234.81 43.2573 234.735 43.2959 234.655 43.3056ZM228.948 39.4205C228.459 41.7074 227.677 42.4898 225.389 42.9779C227.677 43.4593 228.459 44.2484 228.948 46.5286C229.43 44.2484 230.219 43.4593 232.501 42.9779C230.206 42.4831 229.43 41.7074 228.948 39.4205Z" fill="white" />
            <path d="M639.738 39.5265C634.774 38.6412 634.378 38.112 633.766 31.3858C633.766 31.2508 633.727 31.1214 633.657 31.026C633.587 30.9306 633.492 30.877 633.393 30.877C633.294 30.877 633.199 30.9306 633.129 31.026C633.059 31.1214 633.02 31.2508 633.02 31.3858C632.378 38.1527 631.982 38.6819 627.048 39.5265C626.959 39.5415 626.877 39.6006 626.817 39.6925C626.758 39.7844 626.726 39.9028 626.727 40.0251C626.726 40.1486 626.758 40.2683 626.817 40.3619C626.876 40.4555 626.958 40.5166 627.048 40.5339C632.012 41.4091 632.408 41.9484 633.02 48.6747C633.02 48.8096 633.059 48.939 633.129 49.0344C633.199 49.1299 633.294 49.1835 633.393 49.1835C633.492 49.1835 633.587 49.1299 633.657 49.0344C633.727 48.939 633.766 48.8096 633.766 48.6747C634.408 41.9077 634.804 41.3684 639.738 40.5339C639.828 40.5166 639.91 40.4555 639.969 40.3619C640.028 40.2683 640.06 40.1486 640.059 40.0251C640.06 39.9028 640.028 39.7844 639.968 39.6925C639.909 39.6006 639.827 39.5415 639.738 39.5265ZM633.371 45.4387C632.826 41.9687 631.945 40.768 629.399 40.0251C631.945 39.2925 632.826 38.0917 633.371 34.6217C633.908 38.0917 634.789 39.2925 637.334 40.0251C634.759 40.768 633.908 41.9687 633.371 45.4387Z" fill="white" />
            <path opacity="0.3" d="M387.052 25.6241H328.309L328.385 25.0655C328.698 22.7457 329.776 17.1972 332.969 15.8432C336.008 14.546 338.839 17.3676 339.986 18.75C340.209 16.1651 341.217 9.3951 345.724 6.9901C347.154 6.16709 348.728 5.92939 350.255 6.30611C351.782 6.68282 353.195 7.6576 354.321 9.11104C355.017 6.8386 357.799 0.00233469 365.136 0.00233469C366.795 -0.0358048 368.442 0.393707 369.973 1.26386C371.504 2.13401 372.886 3.42598 374.032 5.05852C376.607 8.79677 378.05 13.7203 378.073 18.8447C379.214 18.5038 382.246 17.8978 384.423 20.0188C385.687 21.2231 386.592 23.0032 386.976 25.0371L387.052 25.6241ZM329.15 24.6773H386.141C385.742 23.1052 384.99 21.7438 383.992 20.7857C381.599 18.4186 377.823 19.8957 377.788 19.9052L377.322 20.0945V19.4412C377.43 14.3615 376.063 9.42828 373.517 5.71185C372.432 4.17949 371.127 2.96819 369.682 2.15361C368.238 1.33904 366.686 0.938698 365.122 0.97759C356.901 0.97759 354.85 9.7549 354.766 10.1336L354.578 10.9763L354.168 10.2946C353.157 8.77457 351.821 7.72548 350.348 7.29561C348.875 6.86574 347.341 7.07684 345.961 7.89907C340.897 10.5976 340.612 19.7252 340.605 19.8199V20.9561L340.035 20.1229C340 20.0756 336.655 15.294 333.184 16.7711C330.527 17.8694 329.491 22.5658 329.15 24.6773Z" fill="white" />
            <path opacity="0.3" d="M917.744 65.6241H859L859.076 65.0655C859.389 62.7457 860.468 57.1972 863.66 55.8432C866.699 54.546 869.53 57.3676 870.678 58.75C870.9 56.1651 871.909 49.3951 876.416 46.9901C877.845 46.1671 879.419 45.9294 880.946 46.3061C882.473 46.6828 883.886 47.6576 885.012 49.111C885.708 46.8386 888.49 40.0023 895.828 40.0023C897.487 39.9642 899.133 40.3937 900.664 41.2639C902.195 42.134 903.577 43.426 904.723 45.0585C907.298 48.7968 908.741 53.7203 908.764 58.8447C909.905 58.5038 912.938 57.8978 915.114 60.0188C916.378 61.2231 917.283 63.0032 917.667 65.0371L917.744 65.6241ZM859.842 64.6773H916.832C916.434 63.1052 915.682 61.7438 914.683 60.7857C912.291 58.4186 908.514 59.8957 908.479 59.9052L908.013 60.0945V59.4412C908.122 54.3615 906.755 49.4283 904.209 45.7118C903.124 44.1795 901.818 42.9682 900.374 42.1536C898.929 41.339 897.377 40.9387 895.814 40.9776C887.593 40.9776 885.541 49.7549 885.458 50.1336L885.27 50.9763L884.859 50.2946C883.848 48.7746 882.512 47.7255 881.04 47.2956C879.567 46.8657 878.032 47.0768 876.652 47.8991C871.589 50.5976 871.304 59.7252 871.297 59.8199V60.9561L870.726 60.1229C870.692 60.0756 867.346 55.294 863.876 56.7711C861.219 57.8694 860.182 62.5658 859.842 64.6773Z" fill="white" />
            <path opacity="0.3" d="M75 137H0L0.0976664 136.477C0.497264 134.304 1.87365 129.107 5.94955 127.839C9.83008 126.624 13.4442 129.267 14.9094 130.562C15.1936 128.141 16.4812 121.8 22.2354 119.547C24.06 118.776 26.0702 118.554 28.0197 118.906C29.9691 119.259 31.7732 120.172 33.211 121.534C34.099 119.405 37.651 113.002 47.0193 113.002C49.1371 112.966 51.2394 113.369 53.194 114.184C55.1486 114.999 56.9131 116.209 58.3768 117.738C61.6639 121.239 63.5065 125.851 63.536 130.65C64.9923 130.331 68.864 129.763 71.6434 131.75C73.2567 132.878 74.4126 134.545 74.9023 136.45L75 137ZM1.07447 136.113H73.8367C73.3277 134.641 72.3678 133.366 71.0929 132.468C68.0382 130.251 63.2164 131.635 63.172 131.644L62.577 131.821V131.209C62.7156 126.451 60.9703 121.831 57.7197 118.35C56.3343 116.915 54.6675 115.78 52.8233 115.017C50.9792 114.254 48.9973 113.879 47.0016 113.916C36.5055 113.916 33.8859 122.137 33.7793 122.491L33.5396 123.281L33.0156 122.642C31.725 121.218 30.0189 120.236 28.1387 119.833C26.2584 119.431 24.2989 119.628 22.5373 120.398C16.0727 122.926 15.7086 131.475 15.6997 131.564V132.628L14.9716 131.847C14.9272 131.803 10.6559 127.325 6.22482 128.708C2.83268 129.737 1.50958 134.136 1.07447 136.113Z" fill="white" />
        </svg>

    )
}