import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DarkBg from '../../../assets/remedial/svg/switch-dark-bg.svg'
import LightBg from '../../../assets/remedial/svg/switch-light-bg.svg'
import { useDispatch } from 'react-redux'
import { changeThemes } from '../../../store/actions/themeChangeAction'

const ThemeSwitchButton = () => {
    const storedValue = JSON.parse(localStorage.getItem('darkMode'));
    const [darkMode, setDarkMode] = useState();
    const dispatch = useDispatch();


    const handleCheckboxChange = () => {
        setDarkMode(!darkMode);
        localStorage.setItem('darkMode', JSON.stringify(!darkMode));
        dispatch(changeThemes(!darkMode ? 'dark' : "light"));
    };

    useEffect(() => {
        if (storedValue === undefined) {
            setDarkMode(false);
            localStorage.setItem('darkMode', JSON.stringify(false));
            dispatch(changeThemes("light"));
        } else if (storedValue === true) {
            setDarkMode(true);
            localStorage.setItem('darkMode', JSON.stringify(true));
            dispatch(changeThemes("dark"));
        } else {
            setDarkMode(false);
            localStorage.setItem('darkMode', JSON.stringify(false));
            dispatch(changeThemes("light"));
        }
    }, [])




    return (
        <Box sx={{
            cursor: 'pointer',
            input: {
                width: 0,
                height: 0,
                visibility: 'hidden'
            },
            label: {
                width: '53px',
                height: '24px',
                background: '#fff',
                borderRadius: '16px',
                position: 'relative',
                // backgroundColor: 'red',
                backgroundImage: `url(${LightBg})`,
                transition: '0.3s',
                '&:after': {
                    content: '""',
                    width: '18px',
                    height: '18px',
                    position: 'absolute',
                    top: '3px',
                    right: '4px',
                    backgroundColor: theme => theme.palette.primary.contrastText,
                    borderRadius: '50%',
                    transition: '0.3s',
                }
            },
            'input:checked + label': {
                backgroundImage: `url(${DarkBg})`,
            },
            'input:checked + label:after': {
                right: '12px',
                transform: 'translate(-100%)',
            },
        }}>
            <input type="checkbox" id="dark-toggle"
                checked={darkMode}
                onChange={handleCheckboxChange}
            />
            <label for="dark-toggle"></label>
        </Box>
    )
}

export default ThemeSwitchButton