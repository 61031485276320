import { SET_BANNER_DATA, SET_QUESTIONS, SET_QUESTIONS_STATUS, SET_REVISED_RESULT_PAGE_DATA, SET_SPIN_PRIZE_NUMBER, SET_SPIN_WHEEL_DATA, SET_DIRECT_BONUS_POINTS, SET_STUDY_PAGE_DATA, SET_STUDY_PROGRESS, SET_TEST_RESULT_PAGE_DATA, SET_TIMER, UPDATE_BANNER_INDEX,SET_TEST_DATA } from "../../constant/remedialActionConstant";

export const remedialIntialState = {
  timer: null,
  activeBannerIndex: 0,
  bannerData: null,
  testResultPageData: null,
  studyProgress: 0,
  studyPageData: null,
  spinWheelData: null,
  spinPrizeNumber: null,
  directBonusPoints: 0,
  questions: null,
  flukePercentage:0,
  questionsStatus: {
    visitedQuestions: 0,
    skippedQuestions: 0,
    remainingQuestions: 0,
    totalQuestion: 0,
  },
  revisedResultPageData: null,
}

const remedialReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {

    case SET_TIMER:
      console.log(SET_TIMER, payload);
      return {
        ...state,
        timer: payload.timer
      };

    case SET_BANNER_DATA:
      console.log(SET_BANNER_DATA, payload);
      return {
        ...state,
        bannerData: payload.bannerData
      };

    case SET_TEST_RESULT_PAGE_DATA:
      console.log(SET_TEST_RESULT_PAGE_DATA, payload);
      return {
        ...state,
        testResultPageData: payload.testResultPageData
      };

    case SET_STUDY_PAGE_DATA:
      console.log(SET_STUDY_PAGE_DATA, payload);
      return {
        ...state,
        studyPageData: payload.studyPageData
      };

    case SET_STUDY_PROGRESS:
      console.log(SET_STUDY_PROGRESS, payload);
      return {
        ...state,
        studyProgress: payload.studyProgress
      };

    case UPDATE_BANNER_INDEX:
      console.log(UPDATE_BANNER_INDEX, payload);
      return {
        ...state,
        activeBannerIndex: payload.activeBannerIndex
      };

    case SET_SPIN_WHEEL_DATA:
      console.log(SET_SPIN_WHEEL_DATA, payload);
      return {
        ...state,
        spinWheelData: payload.spinWheelData
      };

    case SET_SPIN_PRIZE_NUMBER:
      console.log(SET_SPIN_PRIZE_NUMBER, payload);
      return {
        ...state,
        spinPrizeNumber: payload.spinPrizeNumber
      };

      case SET_DIRECT_BONUS_POINTS:
      console.log(SET_DIRECT_BONUS_POINTS, payload);
      return {
        ...state,
        directBonusPoints: payload.directBonusPoints
      };

    case SET_QUESTIONS:
      console.log(SET_QUESTIONS, payload);
      return {
        ...state,
        questions: payload.questions
      };

    case SET_QUESTIONS_STATUS:
      console.log(SET_QUESTIONS_STATUS, payload);
      return {
        ...state,
        questionsStatus: payload.questionsStatus
      };

      case SET_REVISED_RESULT_PAGE_DATA:
        console.log(SET_REVISED_RESULT_PAGE_DATA, payload);
        return {
          ...state,
          revisedResultPageData: payload.revisedResultPageData
        };
      case SET_TEST_DATA:
        console.log('SET_TEST_DATA')
        console.log(payload)
          return {
            ...state,
            flukePercentage: payload.fluke_percentage
          };

    default:
      return state
  }

}


export default remedialReducer