import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import {
  TextField,
  IconButton,
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Grid,
  Divider,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Stack,
  Chip,
  Collapse,
  Slide,
  Skeleton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { FaCircleCheck } from "react-icons/fa6";
import { FaHourglassStart } from "react-icons/fa6";
// import { useTheme } from '@mui/styles'

const CustomTab = styled(Tabs)(({ theme }) => ({
  display: 'flex !important',
  justifyContent: 'space-around !important',
  borderRight: 'none !important',
  height: '100% !important',
  '& .MuiTabs-indicator': {
    height: '40px !important',
    marginTop: '25px !important',
    marginRight: '0px',
  },
  // '& .MuiTabs-indicatorSpan': {
  //   width: '3px',
  //   backgroundColor: theme.palette.primary.main,
  // },
}))

// const subjects = [
//     {
//         id: 10,
//         subject_name: 'Mathematics',
//     },
//     {
//         id: 12,
//         subject_name: 'Science',
//     },
//     {
//         id: 22,
//         subject_name: 'History',
//     },
//     {
//         id: 31,
//         subject_name: 'Geography',
//     }
// ]

const SideSubNav = (props) => {
  const [selectedSub, setSelectedSub] = useState(null);

  useEffect(() => {
    const first_unsubmitted_sub = props.subjects.find(sub => sub.submitted === 0);
    if (first_unsubmitted_sub) {
      setSelectedSub(first_unsubmitted_sub.id);
    }
  }, [props.subjects, props.subChanged]);

  // const theme = useTheme()

  const handleChange = (event, newValue) => {
    console.log(newValue)
    setSelectedSub(newValue);
    props.handleSubjectChange(newValue);
    // if (newValue === 'selectedTopic') {
    //   setselectedUnitsArr(newValue)
    //   selectedTopicsHandler(true)
    // } else {
    //   setselectedUnitsArr(newValue)
    //   selectedTopicsHandler(false)
    //   dispatch(getUnitTopics(newValue, true, true))
    // }

  }
  function a11yProps(index) {
    return {
      id: `mui-tab-${index}`,
      'aria-controls': `mui-tabpanel-${index}`,
    }
  }

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      sx={{
        // maxWidth: '160px',
        // minWidth: '100px',
        // width: '10%',
        height: '100%',
        borderRight: (theme) => `1px solid ${theme.palette.grey[300]}`,
      }}
    >
        <CustomTab
          orientation='vertical'
          value={selectedSub}
          onChange={handleChange}
          // TabIndicatorProps={{
          //   children: <span className='MuiTabs-indicatorSpan' />,
          // }}
          aria-label='Vertical tabs'
        >
          {props.subjects.map((sub, index) => {
            return (
              <Tab
                key={sub.id}
                label={
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '13.5px'}}>
                    {sub.subject_name && sub.taught_by && sub.taught_by !== "" && sub.taught_by !== " " ?
                      <div>
                        <Typography variant='body2'>{sub.subject_name}</Typography>
                        <Typography variant='caption1'>{`(by ${sub.taught_by})`}</Typography>
                      </div>
                    : sub.subject_name ?
                      <div>
                        <Typography variant='body2'>{sub.subject_name}</Typography>
                      </div>
                    : 
                      <div>
                        <Typography variant='body2'>{props.name}</Typography>
                      </div>
                    }
                    <span style={{marginLeft: '6.5px'}}>{sub.submitted === 1 ? <FaCircleCheck color="green" /> : <img src='/images/feedback_pending.svg' alt='pending' style={{height: '15px', width: '15px'}}/>} </span>
                  </div>
                }
                sx={{
                  fontWeight: 300,
                  color: (theme) => theme.palette.info[1400],
                  fontSize: '15px',
                  padding: '25px 0px',
                  background: 'transparent',
                  borderTop: index === 0 ? 'none' : (theme) => `1px solid ${theme.palette.grey[300]}`,
                  // '&.Mui-selected': {
                  //   color: (theme) => theme.palette.info[1400],
                  //   background: (theme) => `${theme.palette.grey[100]}`,
                  // },
                  '&:hover': {
                    background: '#DFE3E8',
                  },
                }}
                value={sub.id}
                {...a11yProps(index)}
              />
            )
          })}
        </CustomTab>

    </Stack>
  )
}

export default SideSubNav;
