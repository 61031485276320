import { GETSUBJECTIVETESTRESULTS } from "../actions/subjectiveResultsActions";

let initial_state = {
    loading: true
}

const reducer = (state = initial_state, action) => {
    if (action.type == GETSUBJECTIVETESTRESULTS) {
        console.log(action);
        if (action.err) {
            return {
                loading: false,
                error_loading_results: true
            }
        }
        let results = action.results.subjective_results;
        let questions = results.questions;
        questions = questions.map((que)=> {
            let graph_data = [];
            let student_percentage = que.final_marks ? Math.round((que.final_marks/ que.marks) * 100) : 0;
            if (student_percentage < 40) {
                let data = {};
                data['percentage_level'] = '<40%';
                data['value'] = que.percentage_below_average;
                data['student_percentage'] = 1;
                graph_data.push(data);
            } else {
                let data = {};
                data['percentage_level'] = '<40%';
                data['value'] = que.percentage_below_average;
                data['student_percentage'] = null;
                graph_data.push(data);
            }

            if (student_percentage >= 40 && student_percentage < 60) {
                let data = {};
                data['percentage_level'] = '40-60%';
                data['value'] = que.percentage_average;
                data['student_percentage'] = 1;
                graph_data.push(data);
            } else {
                let data = {};
                data['percentage_level'] = '40-60%';
                data['value'] = que.percentage_average;
                data['student_percentage'] = null;
                graph_data.push(data);
            }

            if (student_percentage >= 60 && student_percentage < 80) {
                let data = {};
                data['percentage_level'] = '60-80%';
                data['value'] = que.percentage_good;
                data['student_percentage'] = 1;
                graph_data.push(data);
            } else {
                let data = {};
                data['percentage_level'] = '60-80%';
                data['value'] = que.percentage_good;
                data['student_percentage'] = null;
                graph_data.push(data);
            }

            if (student_percentage >= 80) {
                let data = {};
                data['percentage_level'] = '>80%';
                data['value'] = que.percentage_very_good;
                data['student_percentage'] = 1;
                graph_data.push(data);
            } else {
                let data = {};
                data['percentage_level'] = '>80%';
                data['value'] = que.percentage_very_good;
                data['student_percentage'] = null;
                graph_data.push(data);
            }

            que['graph_data'] = graph_data;
            return que;
        })
        return {
            ...state,
            created_at: results.created_at,
            date_of_expiry: results.date_of_expiry,
            description: results.description,
            name: results.name,
            questions: results.questions,
            sections: results.sections,
            timelimit: results.timelimit,
            subject_id: results.subject_id,
            test_id: action.id,
            loading: false,
            error_loading_results: false
        }
        console.log(action.results);
    }
    return {...state}
}

export default reducer;