import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Typography } from '@mui/material';
import colors2 from '../../pages/codingscreen/colors2';
import { CgProfile } from 'react-icons/cg';


export const LeaderBoardBox = styled(Box)(({ theme }) => ({
    width: '90%',
    height: '50vh',
    overflowY: 'scroll',
    boxShadow: '4px 4px 15px rgba(0, 0, 0, 0.05)',
    borderRadius: '10px',
    margin: 'auto',
    textAlign: 'left',
    
    '&::-webkit-scrollbar': {
        width: '2px', 
    },
    '&::-webkit-scrollbar-thumb': {
        background: colors2.grey[300], 
        borderRadius: '4px', 
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: colors2.grey[800], 
    },
}));

const CodingLeaderboard = (props) => {
    const [userData, setUserData] = useState(null);
    const [studentsData, setStudentsData] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        const student = props.leaderboard.find(student => student.id === user.user_id);
        setUserData(student);
        
        const otherStudents = props.leaderboard.filter(student => student.id !== user.user_id);
        setStudentsData(otherStudents);
    }, [props.leaderboard]);
    
    return (
        <>
            <LeaderBoardBox>
                <Box sx={{padding: '15px 20px', marginBottom: '15px', background: colors2.grey[0], borderRadius: '10px 10px 0px 0px'}}>
                    <Typography variant="body4">Leaderboard</Typography>
                </Box>
                {studentsData && studentsData.length !== 0 ? 
                <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                    <Grid item width="90%">
                        {userData && (
                            <Grid container 
                                sx={{
                                    // margin: '15px 0px 15px 25px',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexWrap: 'nowrap',
                                    background: colors2.grey[100],
                                    padding: '10px',
                                    margin: '10px auto',
                                    borderRadius: '10px'
                                }}
                            >
                                <Grid item>
                                    <Box 
                                        sx={{
                                            height: '40px',
                                            width: '40px',
                                            borderRadius: '50%',
                                            marginRight: '15px',
                                            position: 'relative'
                                        }}
                                    >
                                        <CgProfile style={{fontSize: '27.5px', color: '#919EAB', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
                                    </Box>
                                </Grid>
                                <Grid item width="100%">
                                    <Grid container justifyContent="space-between" alignItems="center" wrap='nowrap'>
                                        <Grid item>
                                            <Typography variant='body1'>{userData.name}</Typography>
                                        </Grid>
                                        <Grid item>
                                        <Box
                                            sx={{
                                                height: '25px',
                                                width: '25px',
                                                borderRadius: '50%',
                                                background: colors2.secondary,
                                                color: colors2.primary,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Typography>{userData.performance}</Typography>
                                        </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>                        
                        )}
                    </Grid>
                    {studentsData && studentsData.map((student, i) => {
                        return (
                        <Grid item key={i} width="90%">
                            <Grid container 
                                sx={{
                                    // margin: '15px 0px 15px 25px',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexWrap: 'nowrap',
                                    margin: '5px auto',
                                    padding: '10px',
                                }}
                            >
                                <Grid item>
                                    <Box 
                                        sx={{
                                            height: '40px',
                                            width: '40px',
                                            borderRadius: '50%',
                                            marginRight: '15px',
                                            position: 'relative'
                                        }}
                                    >
                                        <CgProfile style={{fontSize: '27.5px', color: '#919EAB', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
                                    </Box>
                                </Grid>
                                <Grid item width="100%">
                                    <Grid container justifyContent="space-between" alignItems="center" wrap='nowrap'>
                                        <Grid item>
                                            <Typography variant='body1'>{student.name}</Typography>
                                        </Grid>
                                        <Grid item>
                                        <Box
                                            sx={{
                                                height: '25px',
                                                width: '25px',
                                                borderRadius: '50%',
                                                background: student?.performance <=3 ? colors2.completedSecondary : colors2.secondary,
                                                color: student?.performance <=3 ? colors2.completedPrimary : colors2.primary,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Typography>{student.performance}</Typography>
                                        </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        )
                    })}
                </Grid>
                :
                <Grid container justifyContent="center" alignItems="center" sx={{marginTop: '50px'}}>
                    <Grid item>
                        <Typography variant="body4">Leaderboard is not available</Typography>
                    </Grid>
                </Grid>
                }
            </LeaderBoardBox>
        </>
    )
}

export default CodingLeaderboard;