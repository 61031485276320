import { Box, Modal, Stack, Typography } from '@mui/material';
import React from 'react';
import CrossSvg from '../../../assets/remedial/svg/CrossSvg';

const RemedialModal = ({ children, show, title, onCloseHandle, size, headBodyGap = 32 }) => {




    return (
        <Modal open={show} onClose={onCloseHandle} disableAutoFocus aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: size === 'lg' ? 1042 : size = 'sm' ? 680 : 680,
                bgcolor: theme => theme.palette.grey[1000],
                p: 3,
                borderRadius: '8px'
            }}>
                <Stack gap={`${headBodyGap}px`}>
                    {/* Heading */}
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography component={'h4'} sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '22px', color: 'grey.900' }}>
                            {title}
                        </Typography>

                        <Box onClick={onCloseHandle}
                            sx={{
                                width: 24,
                                height: 24,
                                cursor: 'pointer',
                                svg: {
                                    path: {
                                        stroke: (theme) => theme.palette.grey[900]
                                    }
                                }
                            }}>
                            <CrossSvg />
                        </Box>
                    </Box>

                    {/* body */}
                    <Box>{children}</Box>
                </Stack>

            </Box>
        </Modal>
    );
};

export default RemedialModal;
