import moment from "moment";
import {
  GETDECKITEMS,
  GETDECKDATA,
  OPENTOPICOFTHEDAYREADMORE,
  OPENQUESTIONOFTHEDAYREADMORE,
  OPENRECOMMENDEDDECK,
  CLOSETOPICOFTHEDAYREADMORE,
  CLOSEQUESTIONOFTHEDAYREADMORE,
  CLOSERECOMMENDEDDECK,
  GETRECOMMENDEDDECKS,
  GETSEARCHEDTOPICDOCS,
  GETSEARCHEDTOPICDECK,
  GETSEARCHEDTOPICVIDEO,
  GETIOSAPPLINK,
  GETWALLDATA,
  GETANALYTICSDATA,
  GETANALYTICSSEMESTERDATA,
  GETANSWEREDSTUDENTS,
  GETUNANSWEREDSTUDENTS,
  ALLSECTIONSGETPERMORMANCESEVENDAYSDATA,
  ALLSECTIONSGETPERMORMANCETHIRTYDAYSDATA,
  ALLSECTIONSGETPARTICIPATIONSEVENDAYSDATA,
  ALLSECTIONSGETPARTICIPATIONTHIRTYDAYSDATA
} from "../actions/dashboardActions";

const initial_state = {
  loading: true,
  error_loading_dashboardData: false,
  loading_walldata: true,
  error_loading_WallData: false,
  loading_analyticsdata: true,
  error_loading_analyticsdata: false,
  loading_answered_students: true,
  error_loading_answered_students: false,
  loading_unanswered_students: true,
  error_loading_unanswered_students: false,
  answered_students:[],
  unanswered_students:[],
  loading_seven_days_all_sections_performance: true,
  error_loading_seven_days_all_sections_performance: false,
  loading_thirty_days_all_sections_performance: true,
  error_loading_thirty_days_all_sections_performance: false,
  loading_seven_days_all_sections_participation: true,
  error_loading_seven_days_all_sections_participation: false,
  loading_thirty_days_all_sections_participation: true,
  error_loading_thirty_days_all_sections_participation: false,
  
};

const reducer = (state = initial_state, action) => {
  if (action.type == "GET_DASHBOARD_DATA") {
    console.log("dashboard data")
    if (action.error) {
      return {
        ...state,
        loading: false,
        error_loading_dashboardData: true,
      };
    } else {
      let response = action.res;
      let subjects = response.semesters.subjects;
      // let trending_topics = response.trending_topics;
      let question_of_the_day = response.question_of_the_day;
      // let topic_of_the_day = response.topic_of_the_day;
      // let recommended_decks = response.recommendedDecks;
      // console.log(recommended_decks);
      if (question_of_the_day) {
        if (
          question_of_the_day.previous_performance &&
          question_of_the_day.previous_performance.length > 0
        ) {
          question_of_the_day.previous_performance.sort((a, b) =>
            a.display_date > b.display_date ? 1 : -1
          );
        }
        question_of_the_day.previous_performance.forEach((pre) => {
          pre["display_date"] = moment(pre.display_date).format("DD MMM");
          if (pre.correct_answered == "correct") {
            pre["value"] = 0;
          } else {
            pre["value"] = 0;
          }
        });
      }
      // let bookmarks_dict = response.bookmarks;
      // let bookmarks = [];
      // if (bookmarks_dict) {
      //   bookmarks = Object.values(bookmarks_dict);
      // }
      let assessments = response.upcoming_tests;
      let assessment_today = 0;
      let coding_assessment_today = 0;
     
      let assessment_results = response.results_released;
      return {
        ...state,
        subjects: subjects,
        // trending_topics: trending_topics,
        question_of_the_day: question_of_the_day,
        // topic_of_the_day: topic_of_the_day,
        // recommended_decks: recommended_decks,
        loading: false,
        error_loading_dashboardData: false,
        // bookmarks: bookmarks,
        assessments: assessments,
        // weekly_challenge: weekly_challenge,
        // assessment_today: assessment_today,
        student_name: action.user.name,
        college_university_degree_department_id:
          action.user.college_university_degree_department_id,
        semester_id: action.user.semester_id,
        department_id: action.user.department_id,
        user_id: action.user.user_id,
        // coding_assessments: coding_assessments,
        // coding_assessment_today: coding_assessment_today,
        assessment_results: assessment_results,
        activity_wall:response.activity_wall,
        previous_semesters:response.previous_semesters,
        // coding_assessment_results: coding_assessment_results,
        // subjective_tests: subjective_tests,
        // subjective_test_results: subjective_test_results,
        college_name: action.user.college_name,
        query_date:response.activity_delta_date,
        // assignments: assignments,
        // assignment_results: assignment_results,
      };
    }
  }
  
  if (action.type == "GET_WALL_DATA") {
    if (action.error) {
      return {
        ...state,
        loading_walldata: false,
        error_loading_WallData: true,
      };
    } else {
      let response = action.res;
      return {
        ...state,
        loading_walldata: false,
        error_loading_WallData: false,
        activity_wall: [...state.activity_wall, ...response.activity_wall],
        query_date:response.activity_delta_date,
      };
    }
  }
  if (action.type == "GET_ANALYTICS_DATA") {
    if (action.error) {
      return {
        ...state,
        loading_analyticsdata: false,
        error_loading_analyticsdata: true,
      };
    } else {
      let response = action.res;
      return {
        ...state,
        loading_analyticsdata: false,
        error_loading_analyticsdata: false,
        analytics_data:response,
      };
    }
  }

  if (action.type == "GET_ANALYTICS_SEMESTER_DATA") {
    if (action.error) {
      return {
        ...state,
        loading_analyticsdata: false,
        error_loading_analyticsdata: true,
      };
    } else {
      let response = action.res;
      return {
        ...state,
        loading_analyticsdata: false,
        error_loading_analyticsdata: false,
        analytics_data_semester:response,
      };
    }
  }

  if (action.type == "GET_ANSWERED_STUDENTS") {
    if (action.error) {
      return {
        ...state,
        loading_answered_students: false,
        error_loading_answered_students: true,
      };
    } else {
      let response = action.res;
      return {
        ...state,
        loading_answered_students: false,
        error_loading_answered_students: false,
        answered_students:response,
      };
    }
  }

  if (action.type == "GET_UNANSWERED_STUDENTS") {
    if (action.error) {
      return {
        ...state,
        loading_unanswered_students: false,
        error_loading_unanswered_students: true,
      };
    } else {
      let response = action.res;
      return {
        ...state,
        loading_answered_unstudents: false,
        error_unloading_answered_students: false,
        unanswered_students:response,
      };
    }
  }
  if (action.type == "ALL_SECTIONS_GET_PERMORMANCE_SEVEN_DAYS_DATA") {
    if (action.error) {
      return {
        ...state,
        loading_seven_days_all_sections_performance: false,
        error_loading_seven_days_all_sections_performance: true,
      };
    } else {
      let response = action.res;
      console.log(response);
      console.log("++++++++++++++++++____________---------");
      return {
        ...state,
        loading_seven_days_all_sections_performance: false,
        error_loading_seven_days_all_sections_performance: false,
        allsections_sevendays_performance:response,
      };
    }
  }
  if (action.type == "ALL_SECTIONS_GET_PERMORMANCE_THIRTY_DAY_SDATA") {
    if (action.error) {
      return {
        ...state,
        loading_thirty_days_all_sections_performance: false,
        error_loading_thirty_days_all_sections_performance: true,
      };
    } else {
      let response = action.res;
      return {
        ...state,
        loading_thirty_days_all_sections_performance: false,
        error_loading_thirty_days_all_sections_performance: true,
        allsections_thirtydays_performance:response,
      };
    }
  }
  if (action.type == "ALL_SECTIONS_GET_PARTICIPATION_SEVEN_DAYS_DATA") {
    if (action.error) {
      return {
        ...state,
        loading_seven_days_all_sections_participation: false,
        error_loading_seven_days_all_sections_participation: true,
      };
    } else {
      let response = action.res;
      return {
        ...state,
        loading_seven_days_all_sections_participation: false,
        error_loading_seven_days_all_sections_participation: false,
        allsections_sevendays_participation:response,
      };
    }
  }
  if (action.type == "ALL_SECTIONS_GET_PARTICIPATION_THIRTY_DAYS_DATA") {
    if (action.error) {
      return {
        ...state,
        loading_thirty_days_all_sections_participation: false,
        error_loading_thirty_days_all_sections_participation: true,
      };
    } else {
      let response = action.res;
      return {
        ...state,
        loading_thirty_days_all_sections_participation: false,
        error_loading_thirty_days_all_sections_participation: false,
        allsections_thirtydays_participation:response,
      };
    }
  }
  if (action.type == "GET_SEARCH_DATA") {
    console.log(action);
    return {
      ...state,
      search_data: action.res.search_data,
    };
  }
  if (action.type == "SUBMIT_QUESTION_OF_THE_DAY") {
    let qotd = action.res;
    // qotd.is_answered = 1;
    // qotd.answer = action.res.data[0].answer;
    // qotd.selected_answer = action.res.data[0].submitted_answer;
    // if (action.res.data[0].submitted_answer == action.res.data[0].answer) {
    //   qotd.correctly_answered = 1;
    //   // qotd.previous_performance[6].correct_answered = "correct";
    // } else {
    //   qotd.correctly_answered = 0;
    //   // qotd.previous_performance[6].correct_answered = "wrong";
    // }
    return {
      ...state,
      question_of_the_day: { ...qotd },
    };
  }

  if (action.type === "ADD_QOD_INTEREST") {
    let qotd = state.question_of_the_day;
    qotd.show_more_of_this_kind = action.show_more_of_this_kind;
    return {
      ...state,
      question_of_the_day: { ...qotd },
    };
  }
  if (action.type == "ADD_OR_DELETE_BOOKMARK_QUESTION_OF_THE_DAY") {
    let qotd = state.question_of_the_day;
    qotd.is_bookmarked = action.bookmark;
    return {
      ...state,
      question_of_the_day: { ...qotd },
    };
  }
  if (action.type == "ADD_OR_DELETE_BOOKMARK_TOPIC_OF_THE_DAY") {
    let totd = state.topic_of_the_day;
    totd.is_bookmarked = action.bookmark;
    return {
      ...state,
      topic_of_the_day: { ...totd },
    };
  }
  if (action.type == GETDECKITEMS) {
    let response = action.res;
    let data = response.data.filter((i) => i.type === "cs");
    return {
      ...state,
      deck_data: data,
    };
  }

  if (action.type == GETDECKDATA) {
    let response = action.res;
    let data = response.data.filter((i) => i.type === "cs");
    console.log(data);
    return {
      ...state,
      deck_data: data,
    };
  }
  if (action.type == OPENTOPICOFTHEDAYREADMORE) {
    return {
      ...state,
      open_topic_of_the_day_readmore: true,
    };
  }
  if (action.type == OPENQUESTIONOFTHEDAYREADMORE) {
    return {
      ...state,
      open_question_of_the_day_readmore: true,
    };
  }
  if (action.type == OPENRECOMMENDEDDECK) {
    console.log("opening recom deck");
    return {
      ...state,
      deck: action.deck,
      open_recommended_deck: true,
    };
  }
  if (action.type == CLOSETOPICOFTHEDAYREADMORE) {
    return {
      ...state,
      open_topic_of_the_day_readmore: false,
      deck_data: null,
    };
  }
  if (action.type == CLOSEQUESTIONOFTHEDAYREADMORE) {
    return {
      ...state,
      open_question_of_the_day_readmore: false,
      deck_data: null,
    };
  }
  if (action.type == CLOSERECOMMENDEDDECK) {
    return {
      ...state,
      open_recommended_deck: false,
      deck_data: null,
    };
  }
  if (action.type == GETRECOMMENDEDDECKS) {
    console.log(action);
    return {
      ...state,
      deck_data: action.res.data,
    };
  }
  if (action.type == GETSEARCHEDTOPICDOCS) {
    let searched_docs = action.res.data;
    let searched_topic;
    let search_data = state.search_data;
    search_data.map((d, index) => {
      if (d.code == action.code) {
        searched_topic = d;
      }
    });
    return {
      ...state,
      searched_topic: searched_topic,
      searched_type: "DOCS",
      searched_docs: searched_docs,
      open_search_topic: true,
    };
  }
  if (action.type == GETSEARCHEDTOPICDECK) {
    let searched_deck = action.res.data;
    let searched_topic;
    let search_data = state.search_data;
    search_data.map((d, index) => {
      if (d.code == action.code) {
        searched_topic = d;
      }
    });
    return {
      ...state,
      searched_topic: searched_topic,
      searched_type: "DECK",
      searched_deck: searched_deck,
      open_searched_topic_deck: true,
    };
  }
  if (action.type == GETSEARCHEDTOPICVIDEO) {
    let searched_vid = action.res.data;
    let searched_topic;
    let search_data = state.search_data;
    search_data.map((d, index) => {
      if (d.code == action.code) {
        searched_topic = d;
      }
    });
    return {
      ...state,
      searched_topic: searched_topic,
      searched_type: "VIDEO",
      searched_vid: searched_vid,
      open_search_topic: true,
    };
  }
  if (action.type == "CLOSE_SEARCHED_TOPIC") {
    return {
      ...state,
      searched_topic: null,
      searched_type: null,
      searched_vid: null,
      searched_docs: null,
      searched_deck: null,
      open_search_topic: false,
      open_searched_topic_deck: false,
    };
  }
  if (action.type === GETIOSAPPLINK) {
    console.log(action);
    if (action.error)
      return {
        ...state,
        iosLink: null,
        iosLinkError: action.res,
      };
    return {
      ...state,
      iosLink: action.res,
      iosLinkError: false,
    };
  }
  if (action.type === "CLEAR_ANALYTICS") {
    return {
      ...state,
      loading_analyticsdata: true,
      error_loading_analyticsdata: false,
    }
  }

  return { ...state };
};

export default reducer;
