import React from 'react'
import styled from 'styled-components';
import Gif from '../../../../../assets/remedial/gif/Revised.gif'


const RevisedTestAnimation = () => {

    const CircleSvg = styled('div')(({ theme }) => ({
        img: {
            minWidth: '100px',
            minHeight: '100px',
            objectFit: 'contain',
            bottom: '-16px',
            position: 'relative'
        },
        svg: {
            minWidth: '120px',
            minHeight: '120px',
            maxWidth: '120px',
            maxHeight: '120px',
            position: 'relative',
            top: 16,
            // left:'-8px'
        }
    }));


    return (
        <>
            <CircleSvg className='enabled-animation'>
                <img src={Gif} alt="testResultGif" />
            </CircleSvg>
            <CircleSvg className='disabled-animation'>
                <svg width="237" height="271" viewBox="0 0 237 271" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M111.403 243.445C105.6 246.288 106.141 251.75 106.095 255.411C106.036 259.316 98.5918 261.354 98.5918 261.354C100.57 255.114 99.1654 248.683 99.1654 248.683L111.409 243.445H111.403Z" fill="#C1BDBD" />
                    <path d="M117.561 257.819L116.236 258.538L113.46 252.193C113.315 251.863 112.992 251.665 112.656 251.665C112.543 251.665 112.425 251.691 112.313 251.731C112.029 251.85 111.825 252.113 111.792 252.423L110.572 261.619L94.5173 270.365C94.036 269.224 97.0162 266.348 98.5986 261.355C98.5986 261.355 106.049 259.317 106.102 255.412C106.155 251.751 105.614 246.282 111.409 243.446L112.629 242.925C119.288 245.992 117.554 257.819 117.554 257.819H117.561Z" fill="#9A9A99" />
                    <path d="M112.623 197.865V242.925L99.3372 248.69L89.3813 203.452C93.8384 203.624 101.262 202.681 112.616 197.865H112.623Z" fill="white" />
                    <path d="M99.1857 248.993L89.1177 203.228L89.3946 203.241C96.9901 203.532 106.175 200.372 112.544 197.674L112.84 197.549V243.07L99.1923 248.993H99.1857ZM89.6517 203.677L99.489 248.393L112.405 242.786V198.189C106.089 200.847 97.1483 203.881 89.6517 203.677Z" fill="#A0A0A0" />
                    <path d="M103.082 201.807L102.655 201.832L105.278 246.015L105.706 245.99L103.082 201.807Z" fill="#A0A0A0" />
                    <path d="M112.623 197.866V209.66L90.0143 206.389L89.3813 203.453C93.8384 203.625 101.262 202.682 112.616 197.866H112.623Z" fill="#A0A0A0" />
                    <path d="M179.571 167.294L170.98 169.102C170.69 169.161 170.4 169.069 170.195 168.851C170.05 168.686 169.971 168.495 169.971 168.29C169.971 168.066 170.057 167.848 170.235 167.683L175.015 163.198L173.953 162.248C173.953 162.248 162.857 164.425 162.224 171.351L163.068 172.274C167.433 176.562 172.134 174.352 175.424 173.238C178.562 172.175 182.314 177.057 183.059 178.066H183.092C188.043 177.901 191.551 179.662 192.415 178.871L179.564 167.288L179.571 167.294Z" fill="#9A9A99" />
                    <path d="M163.088 172.28C167.452 176.568 172.153 174.358 175.444 173.243C178.945 172.063 183.118 178.072 183.118 178.072C176.92 178.27 171.639 181.555 171.639 181.555L163.094 172.28H163.088Z" fill="#C1BDBD" />
                    <path d="M110.058 198.25C110.058 198.25 112.22 212.848 128.789 212.571C145.358 212.3 171.455 181.463 171.455 181.463L162.277 171.403L110.058 198.244V198.25Z" fill="white" />
                    <path d="M128.334 212.795C124.708 212.795 121.49 212.069 118.774 210.644C116.506 209.451 114.574 207.768 113.038 205.644C110.427 202.03 109.853 198.323 109.846 198.29L109.827 198.138L162.329 171.152L171.744 181.476L171.626 181.621C171.56 181.7 164.993 189.431 156.488 197.089C151.49 201.594 146.802 205.196 142.569 207.795C137.255 211.053 132.62 212.742 128.802 212.801C128.651 212.801 128.499 212.801 128.347 212.801L128.334 212.795ZM110.295 198.375C110.427 199.094 111.132 202.287 113.394 205.414C114.89 207.485 116.769 209.121 118.978 210.275C121.747 211.726 125.044 212.432 128.782 212.366C132.521 212.307 137.083 210.644 142.332 207.425C146.545 204.84 151.206 201.258 156.191 196.772C164.044 189.701 170.235 182.571 171.171 181.482L162.23 171.68L110.295 198.375Z" fill="#A0A0A0" />
                    <path d="M130.2 201.844C126.739 201.844 124.062 201.092 122.229 199.615C120.673 198.362 119.723 196.581 119.4 194.318L119.822 194.259C120.514 199.114 124.134 201.515 130.596 201.409C135.679 201.33 142.22 198.329 150.033 192.491C156.797 187.438 163.186 181.231 167.353 176.917L167.663 177.214C158.861 186.33 142.351 201.647 130.609 201.831C130.477 201.831 130.338 201.831 130.207 201.831L130.2 201.844Z" fill="#A0A0A0" />
                    <path d="M182.435 141.807C182.456 141.814 182.477 141.821 182.498 141.829L182.491 141.836L182.435 141.807C180.891 141.309 177.358 143.267 174.684 146.638C171.77 150.299 170.597 154.197 171.876 155.213L130.872 133.399C129.026 132.421 104.23 116.564 100.474 115.567L100.007 115.697C99.9579 115.51 100.125 115.474 100.474 115.567L120.261 110.062L182.435 141.807Z" fill="#828282" />
                    <path d="M139.522 183.406L127.107 136.361L104.261 98.0625L84.0066 103.697L84 202.522C84 202.522 97.4767 210.701 139.522 183.406Z" fill="#828282" />
                    <path d="M84.0066 47.9565C84 47.9433 84 47.9367 84 47.9367C84 47.9367 84.0063 79.5 84.0066 103.696L104.261 98.0621C101.598 96.5713 99.9492 93.7613 99.9492 90.7006L99.9492 42H99.9426C99.7448 43.0092 96.6592 44.942 92.6109 46.5119C88.2131 48.2269 84.3626 48.8734 84.0066 47.9565Z" fill="#828282" />
                    <path d="M105.291 98.4922L99.8843 129.917L127.471 110.913L105.291 98.4922Z" fill="#9A9A99" />
                    <path d="M99.5347 130.472L105.04 98.453C105.066 98.3145 105.198 98.2221 105.337 98.2419C105.475 98.2683 105.568 98.4002 105.548 98.5388L100.247 129.363L127.326 110.702C127.444 110.623 127.602 110.65 127.682 110.768C127.761 110.887 127.734 111.045 127.616 111.125L99.5347 130.472Z" fill="white" />
                    <path d="M105.291 98.4922L99.8843 129.917L127.471 110.913L105.291 98.4922Z" fill="#9A9A99" />
                    <path d="M99.5347 130.472L105.04 98.453C105.066 98.3145 105.198 98.2221 105.337 98.2419C105.475 98.2683 105.568 98.4002 105.548 98.5388L100.247 129.363L127.326 110.702C127.444 110.623 127.602 110.65 127.682 110.768C127.761 110.887 127.734 111.045 127.616 111.125L99.5347 130.472Z" fill="white" />
                    <path d="M105.066 37.8478L69.4559 45.1499C68.1438 45.4203 66.7856 44.2528 66.4362 42.5377C66.0867 40.8227 66.8713 39.2132 68.1834 38.9427L103.794 31.6406L105.066 37.8478Z" fill="white" />
                    <path d="M69.0735 45.4068C68.6251 45.4068 68.1834 45.2683 67.7614 44.9912C66.9966 44.4899 66.4427 43.6126 66.2317 42.5836C65.8625 40.7564 66.7196 39.0348 68.1504 38.7379L103.965 31.3896L105.324 38.0189L69.5086 45.3672C69.3636 45.4002 69.2185 45.4134 69.0735 45.4134V45.4068ZM103.629 31.891L68.2295 39.1535C67.0295 39.4042 66.3175 40.9015 66.6471 42.4978C66.8317 43.4147 67.3262 44.1931 67.9922 44.635C68.4471 44.9385 68.9416 45.044 69.4163 44.9451L104.816 37.6825L103.629 31.891Z" fill="#3EB1B5" />
                    <path d="M105.056 37.8575C106.372 37.5877 107.155 35.9802 106.804 34.2672C106.453 32.5541 105.102 31.3841 103.785 31.654C102.469 31.9238 101.686 33.5313 102.037 35.2444C102.388 36.9574 103.74 38.1274 105.056 37.8575Z" fill="white" />
                    <path d="M104.677 38.1044C103.392 38.1044 102.165 36.9302 101.829 35.2812C101.453 33.454 102.31 31.7323 103.741 31.4355C105.172 31.1453 106.635 32.392 107.011 34.2191C107.189 35.0965 107.097 35.9738 106.748 36.6928C106.385 37.4316 105.805 37.9197 105.099 38.0648C104.961 38.0912 104.816 38.1044 104.677 38.1044ZM103.827 31.8511C102.627 32.0951 101.921 33.5925 102.244 35.1888C102.541 36.6334 103.589 37.669 104.671 37.669C104.783 37.669 104.895 37.6558 105.007 37.636C105.574 37.5173 106.055 37.1149 106.358 36.4949C106.668 35.8616 106.747 35.0767 106.589 34.2917C106.26 32.6954 105.02 31.5938 103.82 31.8445L103.827 31.8511Z" fill="#3EB1B5" />
                    <path d="M84.7525 40.9527C84.7525 40.9527 73.2339 53.9012 74.4933 56.7508C76.135 58.3801 77.4668 59.0991 77.269 59.706C77.0581 60.3656 74.2955 64.3366 74.2955 64.3366L74.0251 61.388L71.3878 62.2588C71.3878 62.2588 74.1702 58.1954 74.3482 56.9487C73.2076 56.078 70.8735 54.264 70.9526 53.0041C71.0384 51.6651 75.1196 46.8959 78.08 43.611L73.6163 45.0886L72.7394 40.9659L80.7701 39.4883L84.759 40.9593L84.7525 40.9527Z" fill="#828282" />
                    <path d="M83.1895 41.5209C84.5296 41.5209 85.6159 40.4341 85.6159 39.0935C85.6159 37.7528 84.5296 36.666 83.1895 36.666C81.8495 36.666 80.7632 37.7528 80.7632 39.0935C80.7632 40.4341 81.8495 41.5209 83.1895 41.5209Z" fill="white" />
                    <path d="M83.1896 41.7321C81.7324 41.7321 80.5522 40.5514 80.5522 39.0936C80.5522 37.6358 81.7324 36.4551 83.1896 36.4551C84.6467 36.4551 85.8269 37.6358 85.8269 39.0936C85.8269 40.5514 84.6467 41.7321 83.1896 41.7321ZM83.1896 36.8838C81.9698 36.8838 80.9808 37.8733 80.9808 39.0936C80.9808 40.3139 81.9698 41.3034 83.1896 41.3034C84.4093 41.3034 85.3983 40.3139 85.3983 39.0936C85.3983 37.8733 84.4093 36.8838 83.1896 36.8838Z" fill="#828282" />
                    <path d="M99.8246 41.8043C99.6268 42.8135 96.5411 44.7397 92.4928 46.3162C88.0951 48.0246 84.2446 48.6711 83.8885 47.7608C83.8885 47.7608 83.8863 47.7542 83.8819 47.741C83.572 46.8175 86.8357 44.7067 91.2071 43.0114C95.6049 41.303 99.4553 40.6565 99.8114 41.5668C99.8378 41.6328 99.8443 41.7119 99.8312 41.8043H99.8246Z" fill="#A0A0A0" />
                    <path d="M96.8642 34.6282L96.3961 41.4092L96.1719 44.6546C95.1038 45.2153 93.8511 45.7826 92.4929 46.3103C90.1852 47.2074 88.0358 47.8077 86.46 48.0517V41.8512L92.9412 40.5187C92.9412 40.5187 93.2972 38.2034 93.9829 36.2641C94.6555 34.3182 96.8708 34.6216 96.8708 34.6216L96.8642 34.6282Z" fill="#C1BDBD" />
                    <path d="M90.2051 43.7437C90.1128 43.7437 90.0337 43.6646 90.0337 43.5722C90.0337 42.0089 91.5238 41.1052 92.4073 40.5643C92.6315 40.4324 92.9018 40.2609 92.9479 40.1883C92.9743 40.1026 93.0666 40.0498 93.1523 40.0696C93.2446 40.0894 93.2974 40.1817 93.2776 40.2741C93.2381 40.4456 93.0337 40.5709 92.5853 40.8479C91.7611 41.3493 90.3765 42.1936 90.3765 43.5656C90.3765 43.658 90.2974 43.7371 90.2051 43.7371V43.7437Z" fill="#4B4B4B" />
                    <path d="M96.3961 41.4089L96.1719 44.6543C95.1038 45.215 93.8511 45.7823 92.4929 46.31C90.1852 47.2071 88.0358 47.8074 86.46 48.0514V45.248C87.7259 44.5026 89.3742 43.7176 91.2072 42.9987C93.1192 42.2467 94.9324 41.6992 96.3961 41.4023V41.4089Z" fill="#4B4B4B" />
                    <path d="M92.15 38.0182L84.9172 39.6738C84.5348 39.7596 84.1721 39.4495 84.1721 39.0274V34.6342C84.1721 34.0472 84.5282 33.5327 85.049 33.3612L92.2028 31.0327C92.6577 30.8875 93.1061 31.2437 93.1061 31.7517V36.7583C93.0995 37.3651 92.7039 37.8928 92.15 38.0247V38.0182Z" fill="#C1BDBD" />
                    <path d="M86.6052 39.1784C86.4206 39.1784 86.2491 39.1191 86.1041 39.0003C85.9129 38.8486 85.8008 38.6178 85.8008 38.3671V34.1587C85.8008 33.5122 86.2162 32.9581 86.8293 32.7734C86.9216 32.747 87.014 32.7998 87.0403 32.8856C87.0667 32.9779 87.014 33.0703 86.9282 33.0966C86.4601 33.2352 86.1436 33.6639 86.1436 34.1521V38.3605C86.1436 38.5056 86.2096 38.6376 86.3151 38.7233C86.4271 38.8091 86.5656 38.842 86.7041 38.8157C86.7964 38.7959 86.8887 38.8552 86.9085 38.9476C86.9282 39.0399 86.8689 39.1323 86.7766 39.1521C86.7173 39.1653 86.6645 39.1719 86.6052 39.1719V39.1784Z" fill="#4B4B4B" />
                    <path d="M88.6887 38.7237C88.5106 38.7237 88.3326 38.6644 88.1876 38.5456C87.9898 38.3873 87.8777 38.1499 87.8777 37.8992V33.5588C87.8777 32.9058 88.2997 32.3253 88.9062 32.1406C88.9985 32.1142 89.0908 32.167 89.1172 32.2528C89.1436 32.3451 89.0909 32.4375 89.0051 32.4638C88.5436 32.6024 88.2205 33.0509 88.2205 33.5588V37.8992C88.2205 38.0509 88.2865 38.1894 88.3986 38.2818C88.504 38.3675 88.6491 38.4005 88.781 38.3741C88.8733 38.3543 88.9656 38.4137 88.9854 38.5061C89.0051 38.5984 88.9458 38.6908 88.8535 38.7105C88.8007 38.7237 88.7414 38.7303 88.6821 38.7303L88.6887 38.7237Z" fill="#4B4B4B" />
                    <path d="M91.0226 38.2293C90.8446 38.2293 90.6732 38.1699 90.5281 38.0512C90.3303 37.8929 90.2117 37.6488 90.2117 37.385V32.8929C90.2117 32.2267 90.6336 31.6264 91.2336 31.4351C91.3259 31.4087 91.4183 31.4549 91.4512 31.5472C91.4776 31.6396 91.4314 31.7319 91.3391 31.7649C90.8776 31.91 90.5545 32.3784 90.5545 32.8995V37.3916C90.5545 37.5499 90.6205 37.695 90.7391 37.7939C90.8446 37.8797 90.9831 37.9127 91.115 37.8863C91.2073 37.8665 91.2996 37.9259 91.3194 38.0182C91.3391 38.1106 91.2798 38.2029 91.1875 38.2227C91.1347 38.2359 91.0754 38.2425 91.0226 38.2425V38.2293Z" fill="#4B4B4B" />
                    <path d="M179.202 150.122C176.288 153.796 173.031 156.026 171.751 155.01C170.472 153.994 171.646 150.109 174.56 146.435C177.277 143.018 180.863 141.052 182.373 141.626L182.67 141.798C183.962 142.82 182.116 146.461 179.202 150.122Z" fill="#A0A0A0" />
                    <path d="M192.375 156.296L190.437 157.319C189.666 157.728 188.71 157.437 188.301 156.672L186.263 152.879L183.171 151.817C183.514 151.943 184.846 152.629 184.424 155.115L177.165 152.411L174.672 151.481L172.305 149.97C172.859 148.842 173.617 147.629 174.567 146.441C175.358 145.445 176.222 144.568 177.086 143.855L179.551 145.584L182.077 145.854L186.184 146.29C186.844 146.355 187.477 146.58 188.03 146.929L191.307 149.258C191.789 149.601 192.099 150.109 192.178 150.696L192.844 155.359C192.903 155.742 192.705 156.118 192.362 156.296H192.375Z" fill="#C1BDBD" />
                    <path d="M191.367 156.725C191.307 156.725 191.248 156.693 191.215 156.633C191.169 156.547 191.202 156.448 191.287 156.402C191.518 156.284 191.65 156.033 191.617 155.762L191.037 152.003C190.964 151.541 190.714 151.132 190.345 150.868L187.503 148.843C187.424 148.79 187.411 148.685 187.463 148.605C187.516 148.526 187.622 148.513 187.701 148.566L190.542 150.591C190.984 150.908 191.287 151.402 191.373 151.95L191.953 155.716C192.006 156.125 191.802 156.521 191.446 156.706C191.419 156.719 191.393 156.725 191.367 156.725Z" fill="#4B4B4B" />
                    <path d="M189.613 157.398C189.553 157.398 189.494 157.365 189.461 157.306C189.415 157.22 189.448 157.121 189.534 157.075C189.764 156.956 189.896 156.706 189.863 156.435L189.283 152.675C189.21 152.214 188.96 151.805 188.591 151.541L185.749 149.516C185.67 149.463 185.657 149.357 185.709 149.278C185.762 149.199 185.868 149.186 185.947 149.239L188.788 151.264C189.23 151.58 189.534 152.075 189.619 152.623L190.199 156.389C190.252 156.798 190.048 157.194 189.692 157.379C189.665 157.392 189.639 157.398 189.613 157.398Z" fill="#4B4B4B" />
                    <path d="M183.171 151.99C183.171 151.99 183.131 151.99 183.118 151.983L181.055 151.284C180.962 151.251 180.916 151.159 180.949 151.066C180.982 150.974 181.074 150.928 181.167 150.961L183.23 151.66C183.323 151.693 183.369 151.785 183.336 151.878C183.31 151.95 183.244 151.996 183.171 151.996V151.99Z" fill="#4B4B4B" />
                    <path d="M182.09 145.854C181.398 147.16 180.389 148.645 179.215 150.135C178.543 150.98 177.857 151.751 177.178 152.418L174.686 151.488L172.319 149.977C172.872 148.849 173.631 147.635 174.58 146.448C175.371 145.452 176.235 144.575 177.099 143.862L179.565 145.591L182.09 145.861V145.854Z" fill="#4B4B4B" />
                    <path d="M107.546 75.6904L125.803 83.7973L132.317 102.861L119.084 93.3949L112.913 85.8553L107.546 75.6904Z" fill="#828282" />
                    <path d="M112.912 85.8557C112.912 85.8557 118.886 86.8781 121.292 93.5404L122.565 92.2607C122.565 92.2607 120.251 85.7765 114.139 84.1143L112.912 85.8557Z" fill="#A0A0A0" />
                    <path d="M148.53 94.1336C146.479 92.9726 144.587 92.3328 143.98 92.5637C144.02 92.4581 144.046 92.3394 144.039 92.2206C144.007 91.8447 143.697 91.561 143.334 91.561H143.275C142.879 91.594 142.589 91.937 142.615 92.3328C142.615 92.3592 142.622 92.3856 142.622 92.3988L140.453 93.7906C139.965 94.1072 139.371 94.1204 138.857 93.8433C138.343 93.5663 138.046 93.0518 138.046 92.4713V89.3513C138.046 87.9924 137.374 86.7391 136.24 85.9871C135.106 85.2352 133.681 85.1164 132.435 85.6441L128.301 87.4911L126.732 82.8869L108.376 74.747C108.264 74.6942 108.126 74.747 108.08 74.8658C108.027 74.9779 108.08 75.1164 108.198 75.1626L126.363 83.2233L132.844 102.194C132.877 102.287 132.963 102.346 133.055 102.346C133.081 102.346 133.108 102.339 133.134 102.339C133.253 102.3 133.312 102.168 133.279 102.049L128.453 87.9199L132.62 86.0597C133.728 85.5782 134.993 85.6903 135.989 86.3565C136.998 87.0228 137.591 88.1309 137.591 89.3447V92.4647C137.591 93.2167 137.987 93.8829 138.64 94.2391C138.949 94.4106 139.279 94.4964 139.615 94.4964C139.991 94.4964 140.367 94.3842 140.697 94.1732L142.846 92.8011C142.971 92.9199 143.143 92.9858 143.327 92.9858H143.387C143.538 92.9792 143.677 92.9199 143.782 92.8209C143.782 93.4278 145.338 94.7866 147.468 96.0003C147.93 96.2642 148.391 96.5017 148.826 96.7061C149.09 96.4819 149.314 96.218 149.492 95.9014C149.67 95.5914 149.782 95.2616 149.835 94.9185C149.426 94.6613 148.991 94.3908 148.53 94.127V94.1336ZM143.347 92.6296C143.149 92.6494 142.991 92.5043 142.971 92.313C142.952 92.1217 143.097 91.9568 143.288 91.937C143.472 91.9172 143.644 92.0623 143.664 92.2536C143.683 92.4383 143.538 92.6098 143.347 92.6296Z" fill="#9A9A99" />
                    <path d="M111.726 97.0883L108.568 114.661L119.335 106.363V95.4062L111.726 97.0883Z" fill="#C1BDBD" />
                    <path d="M111.726 97.0883L111.337 99.2915L116.097 108.856L119.335 106.363V95.4062L111.726 97.0883Z" fill="#4B4B4B" />
                    <path d="M113.091 86.0331C113.091 86.0331 107.704 84.694 106.023 87.8207C104.341 90.9473 109.26 91.8114 109.464 90.7165C109.557 90.2349 100.61 90.2481 102.113 97.0225C103.676 104.074 110.717 101.653 110.717 101.653L111.535 97.0951L113.091 86.0463V86.0331Z" fill="white" />
                    <path d="M108.067 102.227C107.071 102.227 105.93 102.049 104.882 101.462C103.398 100.63 102.416 99.1462 101.955 97.0552C101.447 94.7531 102.08 92.9061 103.794 91.7253C104.566 91.191 105.475 90.8414 106.339 90.637C106.174 90.5116 106.029 90.3665 105.904 90.2082C105.541 89.7333 105.238 88.9285 105.878 87.7478C106.596 86.4153 108.086 85.6699 110.19 85.5908C111.752 85.538 113.084 85.8612 113.143 85.8744L113.295 85.914L111.719 97.1211L110.882 101.785L110.79 101.818C110.71 101.844 109.55 102.233 108.08 102.233L108.067 102.227ZM106.741 90.8942C105.838 91.0789 104.842 91.4153 104.005 91.9958C102.377 93.1106 101.796 94.7926 102.284 96.9826C102.726 98.9747 103.656 100.38 105.047 101.165C107.269 102.411 110.031 101.679 110.572 101.521L111.376 97.0618L112.906 86.1713C112.517 86.0855 111.416 85.8876 110.19 85.9272C108.192 85.9997 106.84 86.666 106.174 87.8995C105.733 88.7174 105.733 89.4232 106.174 89.9905C106.339 90.2082 106.557 90.3863 106.801 90.5314C108.119 90.2808 109.253 90.3335 109.524 90.5116C109.609 90.571 109.649 90.6567 109.636 90.7491C109.59 91.0064 109.359 91.1976 108.996 91.2834C108.376 91.4351 107.473 91.29 106.741 90.8942ZM107.354 90.7887C107.928 90.9932 108.535 91.0393 108.917 90.9536C109.023 90.9272 109.187 90.8744 109.267 90.7689C109.009 90.6897 108.264 90.6633 107.354 90.7887Z" fill="#A0A0A0" />
                    <path d="M119.249 96.1449C119.249 96.1449 111.205 106.633 118.273 111.092C123.943 114.661 125.4 109.634 123.884 108.942C122.236 108.19 118.458 122.365 130.102 122.326C141.772 122.286 138.205 107.702 132.323 102.867C129.515 100.558 120.139 94.1133 120.139 94.1133L119.249 96.1449Z" fill="white" />
                    <path d="M130.049 122.498C127.392 122.498 123.93 121.719 122.453 118.058C121.741 116.297 121.675 114.252 121.926 112.531C120.541 112.557 119.071 111.798 118.181 111.238C116.585 110.228 115.616 108.837 115.306 107.095C115.056 105.684 115.121 103.256 116.882 99.7074C117.871 97.7153 118.965 96.2377 119.097 96.0596L120.06 93.8564L120.231 93.9752C120.324 94.0411 129.64 100.453 132.422 102.735C134.334 104.305 136.016 106.871 137.031 109.773C138.112 112.854 138.264 115.783 137.466 118.025C136.748 120.05 134.882 122.471 130.095 122.491C130.075 122.491 130.062 122.491 130.042 122.491L130.049 122.498ZM122.275 112.511C122.275 112.55 122.262 112.597 122.255 112.636C122.097 113.744 121.952 115.908 122.77 117.933C123.884 120.697 126.403 122.155 130.049 122.155C130.068 122.155 130.082 122.155 130.101 122.155C133.734 122.141 136.167 120.677 137.15 117.913C138.831 113.184 135.877 106.013 132.211 102.999C129.647 100.895 121.497 95.2549 120.211 94.371L119.401 96.2311L119.387 96.2509C119.341 96.3103 114.798 102.3 115.649 107.036C115.946 108.678 116.855 109.998 118.372 110.947C119.737 111.805 120.983 112.227 121.992 112.188C122.255 110.677 122.757 109.463 123.264 108.982C123.567 108.692 123.811 108.718 123.963 108.784C124.352 108.962 124.596 109.378 124.622 109.918C124.662 110.637 124.293 111.607 123.383 112.141C123.04 112.346 122.671 112.458 122.288 112.504L122.275 112.511ZM123.752 109.087C123.66 109.087 123.561 109.16 123.488 109.232C123.113 109.595 122.611 110.644 122.335 112.155C122.651 112.102 122.941 112.003 123.198 111.851C123.983 111.389 124.306 110.558 124.273 109.945C124.253 109.529 124.082 109.226 123.811 109.101C123.792 109.094 123.772 109.087 123.752 109.087Z" fill="#A0A0A0" />
                    <path d="M119.011 93.5199L119.618 93.9025C120.205 94.2785 120.376 95.0569 120.014 95.6439L119.895 95.822C119.519 96.4091 118.741 96.5938 118.154 96.2178L117.508 95.8089C116.789 96.3234 112.477 99.1994 109.814 96.3366C107.229 93.5595 113.203 85.4395 113.203 85.4395C118.273 87.0292 120.251 91.5344 120.251 91.5344L119.011 93.5133V93.5199Z" fill="#C1BDBD" />
                    <path d="M112.392 92.9854C112.392 92.9854 112.372 92.9854 112.365 92.9854C112.345 92.9854 111.904 92.9128 111.567 92.5501C111.35 92.3126 111.548 91.8311 111.95 90.9274C112.24 90.2809 112.563 89.5487 112.629 88.988C112.748 87.926 111.488 87.4445 111.475 87.4445C111.389 87.4115 111.343 87.3126 111.376 87.2268C111.409 87.1411 111.508 87.0949 111.594 87.1279C112.121 87.3258 113.09 87.9722 112.965 89.0342C112.893 89.6477 112.556 90.4063 112.26 91.0725C112.062 91.521 111.759 92.1939 111.825 92.3258C112.075 92.5896 112.418 92.649 112.418 92.649C112.51 92.6622 112.576 92.7479 112.563 92.8469C112.55 92.9326 112.477 92.992 112.392 92.992V92.9854Z" fill="#4B4B4B" />
                    <path d="M112.992 94.1207C111.891 94.1207 111.139 93.7118 111.106 93.692C111.027 93.6458 110.994 93.5403 111.04 93.4611C111.086 93.3819 111.192 93.349 111.271 93.3951C111.284 93.4017 112.286 93.9426 113.618 93.7315C113.71 93.7183 113.796 93.7777 113.816 93.8767C113.836 93.9756 113.77 94.0548 113.671 94.0746C113.434 94.1141 113.203 94.1273 112.992 94.1273V94.1207Z" fill="#4B4B4B" />
                    <path d="M115.657 91.8317C115.778 91.5151 115.738 91.2059 115.568 91.141C115.398 91.0761 115.162 91.2801 115.041 91.5967C114.921 91.9133 114.961 92.2225 115.131 92.2874C115.301 92.3523 115.537 92.1483 115.657 91.8317Z" fill="#4B4B4B" />
                    <path d="M111.719 90.0319C111.82 89.7664 111.784 89.5059 111.637 89.4501C111.491 89.3943 111.29 89.5643 111.189 89.8298C111.088 90.0953 111.125 90.3558 111.271 90.4116C111.417 90.4674 111.618 90.2974 111.719 90.0319Z" fill="#4B4B4B" />
                    <path d="M118.563 95.1551C118.563 95.1551 118.497 95.1485 118.471 95.1287C118.392 95.0759 118.365 94.9704 118.418 94.8912C118.708 94.4361 119.308 94.3041 119.763 94.5944C119.842 94.6471 119.869 94.7527 119.816 94.8318C119.763 94.911 119.658 94.9374 119.578 94.8846C119.282 94.6999 118.893 94.7857 118.702 95.0825C118.669 95.1353 118.616 95.1616 118.557 95.1616L118.563 95.1551Z" fill="#4B4B4B" />
                    <path d="M116.994 91.2712C116.928 91.2712 116.862 91.2317 116.836 91.1657C116.341 90.0245 115.405 89.7541 115.365 89.7409C115.273 89.7145 115.22 89.6222 115.247 89.5298C115.273 89.4375 115.365 89.3847 115.458 89.4111C115.504 89.4243 116.585 89.7277 117.152 91.0272C117.192 91.1129 117.152 91.2119 117.066 91.2515C117.047 91.2581 117.02 91.2647 117 91.2647L116.994 91.2712Z" fill="#4B4B4B" />
                    <path d="M116.968 87.5039C116.968 87.5039 115.834 91.2704 119.084 93.843C120.996 95.3535 121.108 93.5329 121.108 93.5329C121.108 93.5329 120.858 89.8324 116.968 87.5039Z" fill="white" />
                    <path d="M120.35 94.6152C119.974 94.6152 119.519 94.4041 118.978 93.9753C117.251 92.6099 116.75 90.908 116.638 89.7273C116.513 88.4476 116.796 87.4977 116.803 87.4582L116.869 87.2471L117.06 87.3592C119.11 88.5861 120.146 90.1956 120.66 91.3236C121.214 92.5505 121.28 93.4872 121.286 93.5202V93.5334V93.5466C121.286 93.5795 121.233 94.3051 120.699 94.5426C120.594 94.5888 120.475 94.6152 120.357 94.6152H120.35ZM117.08 87.7748C116.908 88.6059 116.513 91.594 119.189 93.7115C119.789 94.1864 120.258 94.3645 120.554 94.2326C120.871 94.094 120.93 93.6125 120.937 93.54C120.917 93.3157 120.554 89.9714 117.08 87.7814V87.7748Z" fill="#A0A0A0" />
                </svg>




            </CircleSvg>
        </>
    )
}

export default RevisedTestAnimation