import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import {
  Typography,
  Tabs,
  Tab,
  Stack,
} from '@mui/material'
import { FaCircleCheck } from "react-icons/fa6";
// import { useTheme } from '@mui/styles'

const CustomTab = styled(Tabs)(({ theme }) => ({
  display: 'flex !important',
  justifyContent: 'space-around !important',
  borderRight: 'none !important',
  height: '100% !important',
  '& .MuiTabs-indicator': {
    height: '40px !important',
    marginTop: '17px !important',
    marginRight: '0px',
  },
  // '& .MuiTabs-indicatorSpan': {
  //   width: '3px',
  //   backgroundColor: theme.palette.primary.main,
  // },
}))


const SideUnitNav = (props) => {
  const [selectedUnit, setSelectedUnit] = useState(null);

  useEffect(() => {
    if (props.unit) {
        setSelectedUnit(props.unit.unit_id);
    }
  }, [props.unit]);

  // const theme = useTheme()

  const handleChange = (event, newValue) => {
    console.log(newValue)
    setSelectedUnit(newValue);
    props.handleUnitChange(newValue);
    // if (newValue === 'selectedTopic') {
    //   setselectedUnitsArr(newValue)
    //   selectedTopicsHandler(true)
    // } else {
    //   setselectedUnitsArr(newValue)
    //   selectedTopicsHandler(false)
    //   dispatch(getUnitTopics(newValue, true, true))
    // }

  }
  function a11yProps(index) {
    return {
      id: `mui-tab-${index}`,
      'aria-controls': `mui-tabpanel-${index}`,
    }
  }

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      sx={{
        // maxWidth: '160px',
        // minWidth: '100px',
        // width: '10%',
        height: '100%',
        borderRight: (theme) => `1px solid ${theme.palette.grey[300]}`,
      }}
    >
        <CustomTab
          orientation='vertical'
          value={selectedUnit}
          onChange={handleChange}
          // TabIndicatorProps={{
          //   children: <span className='MuiTabs-indicatorSpan' />,
          // }}
          aria-label='Vertical tabs'
        >
          {props.units_names_and_ids.map((unit, index) => {
            return (
              <Tab
                key={unit.unit_id}
                label={
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '13.5px'}}>
                        {/* <Typography variant='body5'>{unit.is_empty === 0 ? `${unit.unit_name}`: `${unit.unit_name} - (Not assigned)`}</Typography> */}
                        <Typography variant='body5'>{unit.is_empty === 1 ? `${unit.unit_name} - (Not assigned)` : unit.is_active === 0 ? `${unit.unit_name} - (Yet to Start)` : unit.is_active === 2 ? `${unit.unit_name} - (Expired)` : unit.is_active === 1 ? `${unit.unit_name}` : `${unit.unit_name}`}</Typography>
                        {unit.is_completed === 1 && unit.is_active === 1 ? <span style={{marginLeft: '6.5px'}}><FaCircleCheck color="green" /></span> : null}
                    </div>
                }
                sx={{
                  fontWeight: 300,
                  color: (theme) => theme.palette.info[1400],
                  fontSize: '15px',
                  padding: '25px 0px',
                  background: 'transparent',
                  borderTop: index === 0 ? 'none' : (theme) => `1px solid ${theme.palette.grey[300]}`,
                  // '&.Mui-selected': {
                  //   color: (theme) => theme.palette.info[1400],
                  //   background: (theme) => `${theme.palette.grey[100]}`,
                  // },
                  '&:hover': {
                    background: '#DFE3E8',
                  },
                }}
                value={unit.unit_id}
                disabled={unit.is_empty === 1 || unit.is_active === 0 || unit.is_active === 2}
                {...a11yProps(index)}
              />
            )
          })}
        </CustomTab>

    </Stack>
  )
}

export default SideUnitNav;
