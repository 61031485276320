import React, {useState, useEffect} from 'react';
import { Box, Grid, Modal, Typography } from '@mui/material';
import colors2 from '../../pages/codingscreen/colors2';
import {styled, useTheme} from '@mui/material/styles';
import CustomChip from '../../components/common/CustomChip';

const LogModalBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    width: '50%',
    maxHeight: '70%',
    outline: 0,
    borderRadius: '10px',
    p: 4,
    // paddingLeft: '1%',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
        width: '4px', 
    },
    '&::-webkit-scrollbar-thumb': {
        background: colors2.grey[600], 
        borderRadius: '4px', 
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: colors2.grey[800], 
    },
    [theme.breakpoints.down('md')]: {
        width: '75%',
    },
}));


const TagsPopup = ({isPopupOpen, tags, handleClosePopup}) => {

    useEffect(() => {
        console.log("tags");
        console.log(tags);
    }, [tags]);

    return (

            <Modal
                open={isPopupOpen}
                onClose={handleClosePopup}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <LogModalBox sx={{background: colors2.common.white}}>
                    <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '15px',
                        }}
                    >
                        <Typography variant='body5'>Tags</Typography>
                        <img src={`/images/close_x.svg`} alt="Close" onClick={handleClosePopup} style={{cursor: 'pointer'}}/>
                    </Box>
                    <Grid container spacing={1} justifyContent="flex-start" alignItems="center" sx={{padding: '10px 0px 25px 12.5px'}}>
                        {tags && tags.map((tag, index) => {
                            return (
                                // <Chip label={tag} variant='filled' color='primary' size='small' sx={{borderRadius: '4px', fontSize: '12px', marginTop: '5px', fontWeight: '600', }}/>
                                <Grid item sx={{margin: '0px 3px'}}>
                                    <CustomChip label={tag.name} bgColor={colors2.secondary} textColor={colors2.primary} borderRadius={'5px'} height={'25px'}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </LogModalBox>
            </Modal>
    )
}

export default TagsPopup;