import axios from "../../interceptor";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { getLoggedInUser } from "../../helpers/Auth";

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
const ENVIRONEMNT_VAR = process.env.REACT_APP_ENVIRONEMNT;
// let CORS = 'https://cors-anywhere.herokuapp.com/';
let failed_questionsubmission=[];
const plain_error_obj = {
  api_name: null,
  status: null,
  api_status: null,
  user_id: getLoggedInUser()?.user_id,
  timestamp: null,
};

export function setGlobalToken() {
  try {
    let token = localStorage.getItem("token");
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } catch (error) {
    console.log(error);
  }
}

export const GETTEST = "GET_TEST";
export const ONQUESTIONCLICK = "ON_QUESTION_CLICK";
export const ONOPTIONSELECTED = "ON_OPTION_SELECTED";
export const ONQUESTIONSUBMIT = "ON_QUESTION_SUBMIT";
export const ONTESTSUBMIT = "ON_TEST_SUBMIT";
export const TESTSUBMITLOAD = "TEST_SUBMIT_LOADING";

export const getTestA = (res, err, attempted) => {
  return {
    type: GETTEST,
    response: res,
    error: err,
    attempted: attempted,
  };
};

export const loadTestSubmitA = () => {
  return {
    type: TESTSUBMITLOAD,
  };
};

export const getTestAC = (test_id) => {
  console.log("in action creator");
  setGlobalToken();
  const error_obj = { ...plain_error_obj };
  error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
  error_obj.api_name = `/v3/getTest`;
  error_obj.test_id = test_id;
  return async (dispatch, getState) => {
    dispatch(loadTestSubmitA);
    const IPADDRESS = await fetch(
      "https://api.ipify.org?format=json&callback=?"
    )
      .then((res) => res.json())
      .then((data) => (data?.ip ? data.ip : ""))
      .catch((err) => console.log(err));
    http
      .get(
        `https://qsdsbm4079.execute-api.ap-south-1.amazonaws.com/${ENVIRONEMNT_VAR}/questionnaire?test_id=${test_id}&device_type=2&device_details=${IPADDRESS}`
      )
      .then((res) => {
        error_obj.status = res.status;
        error_obj.api_status = res.data?.status;
        if (res.status === 200) {
          if (res.data.status === 200) {
            let date = new Date();
            let difference = moment.duration(
              moment(res.data.date_of_expiry).diff(moment(date))
            );
            let difference2 = parseInt(difference.asSeconds());
            if (res.data.timelimit > difference2) {
              res.data.expiryTime = difference2;
            } else res.data.expiryTime = res.data.timelimit;
            console.log("done test");
            dispatch(getTestA(res.data, false));
          } else if (res.data.status === 409) {
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(getTestA(null, true, res.data.message));
          } else if (
            res.data.status === 401 &&
            res.data.message === "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .get(
                    `https://qsdsbm4079.execute-api.ap-south-1.amazonaws.com/${ENVIRONEMNT_VAR}/questionnaire?test_id=${test_id}&device_type=2&device_details=${IPADDRESS}`
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data.status === 200) {
                        let date = new Date();
                        let difference = moment.duration(
                          moment(res.data.date_of_expiry).diff(moment(date))
                        );
                        let difference2 = parseInt(difference.asSeconds());
                        if (res.data.timelimit > difference2) {
                          res.data.expiryTime = difference2;
                        } else res.data.expiryTime = res.data.timelimit;
                        dispatch(getTestA(res.data, false));
                      } else {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        dispatch(getTestA(null, true));
                      }
                    } else if (res.data.status === 409) {
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                      dispatch(getTestA(null, true, true));
                    } else {
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                      dispatch(getTestA(null, true));
                    }
                  })
                  .catch((err) => {
                    error_obj.status = err?.request?.status;
                    Sentry.captureException(error_obj, (scope) => {
                      scope.setTransactionName(error_obj.api_name);
                      return scope;
                    });
                    dispatch(getTestA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
                dispatch(getTestA(null, true));
              });
          } else {
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(getTestA(null, true));
          }
        } else {
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
          dispatch(getTestA(null, true));
        }
      })
      .catch((err) => {
        console.log(err);
        error_obj.status = err?.request?.status;
        Sentry.captureException(error_obj, (scope) => {
          scope.setTransactionName(error_obj.api_name);
          return scope;
        });
        dispatch(getTestA(null, true));
      });
  };
};

export const onQuestionClick = (res) => {
  return {
    type: ONQUESTIONCLICK,
    question_option_id: res.question_option_id,
    qid: res.qid,
  };
};

export const onOptionSelected = (res) => {
  console.log("on practie option selected");
  return {
    type: ONOPTIONSELECTED,
    qid: res.qid,
    option_id: res.option_id,
  };
};

export const submitQuestionA = (res, err, qid, close) => {
  return {
    type: "ON_TEST_QUESTION_SUBMIT",
    response: res,
    error: err,
    qid: qid,
    close: close,
  };
};

export const submitTestA = (res, que_res, err) => {
  return {
    type: ONTESTSUBMIT,
    response: res,
    error: err,
    feedback_response: que_res,
  };
};

export const submitFeedbackA = (res, err) => {
  return {
    type: "SUBMIT_QUESTIONS_FEEDBACK",
    response: res,
    error: err,
  };
};

export const updateSeenStatus = (test_id, type) => {
  const error_obj = { ...plain_error_obj };
  error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
  error_obj.api_name = `/v3/updateSeenStatus`;
  error_obj.payload = {
    test_id: test_id,
    type: type,
    device: 2,
  };
  const formdata = new FormData();
  formdata.append("test_id", test_id);
  formdata.append("type", type === "mcq" ? 1 : type === "subjective" ? 2 : 3);
  formdata.append("device", 2);
  http
    .post(`${BASE_API_URL}/questionnaire/v3/updateSeenStatus`, formdata)
    .then((res) => console.log(res))
    .catch((err) => {
      error_obj.status = err?.request?.status;
      Sentry.captureException(error_obj, (scope) => {
        scope.setTransactionName(error_obj.api_name);
        return scope;
      });
    });
};

export const submitQuestionsFeedbackAC = (res) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    let formdata = new FormData();
    let feedback_answers = getState().ques.feedback_answers;
    formdata.append("test_id", getState().ques.test_id);
    formdata.append("feedback_answers", JSON.stringify(feedback_answers));

    http
      .post(`${BASE_API_URL}/questionnaire/v2/submitTestFeedback`, formdata)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(submitFeedbackA(res, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(
                    `${BASE_API_URL}/questionnaire/v2/submitTestFeedback`,
                    formdata
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(submitFeedbackA(res, false));
                      } else {
                        dispatch(submitFeedbackA(res, true));
                      }
                    } else {
                      dispatch(submitFeedbackA(res, true));
                    }
                  })
                  .catch((err) => {
                    dispatch(submitFeedbackA(res, true));
                  });
              })
              .catch((err) => {
                console.log(err);
                dispatch(submitFeedbackA(res, true));
              });
          } else {
            dispatch(submitFeedbackA(res, true));
          }
        } else {
          dispatch(submitFeedbackA(res, true));
        }
      })
      .catch((err) => {
        console.log("error in submittting test");
        console.log(err);
        dispatch(submitFeedbackA(res, true));
      });
  };
};

export const submitQuestionAC = (networkspeed) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const qid = getState().ques.prev_qid;
    const question_answer = {
      ...getState().ques.answers[qid],
      total_timetaken: 0,
    };
    // let qid = getState().ques.qid;
    // question_answers.timetaken = JSON.parse(question_answers.timetaken);
    // question_answers.timetaken = [1];
    const timetaken = question_answer.timetaken.duration;
    if (!question_answer.action_type) question_answer.action_type = 1;
    if(!question_answer.timetaken.start && question_answer.timetaken.end){
      question_answer.timetaken.start = moment(question_answer.timetaken.end).subtract(question_answer.timetaken.duration,'seconds').format("YYYY-MM-DD HH:mm:ss").toString();
    } 
    // if(question_answer.timetaken.start === question_answer.timetaken.end) question_answer.timetaken.duration = 0;
    question_answer.timetaken = JSON.stringify([[question_answer.timetaken.start,question_answer.timetaken.end,question_answer.timetaken.duration]]);
    question_answer.total_timetaken = timetaken ? timetaken : 0;
    question_answer.device = 2;
    question_answer.internet_speed=networkspeed;
    // console.log(question_answer);
    // question_answers.timetaken = JSON.stringify(question_answers);
    // question_answers.question_option_id = question_answers.question_option_id ? question_answers.question_option_id : 'null';
    const question_answers = [];
    question_answers.push(question_answer);

    // const formdata = new FormData();
    // formdata.append("questionnaire_id", getState().ques.test_id);
    // formdata.append("subject_id ", getState().ques.subject_id);
    // formdata.append("question_answers", JSON.stringify(question_answers));
    // formdata.append("total_timetaken", timetaken);
    const formdata = {
      questionnaire_id: getState().ques.test_id,
      subject_id: getState().ques.subject_id,
      question_answers: question_answers,
      total_timetaken: timetaken
    }
    console.log("question submitted for qid - " + qid);
    console.log(JSON.stringify(question_answer.timetaken));

    const error_obj = { ...plain_error_obj };
    error_obj.payload = {};
    // formdata.forEach((value, key) => (error_obj.payload[key] = value));
    error_obj.payload = formdata;
    error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
    error_obj.api_name = `/v2/mcqQuestionSubmission`;

    http
      .post(`https://5x9619yt5a.execute-api.ap-south-1.amazonaws.com/${ENVIRONEMNT_VAR}/questionsubmission`, formdata, {headers: { 'Content-Type': 'application/json' }})
      .then((res) => {
        error_obj.status = res.status;
        error_obj.api_status = res.data?.status;
        if (res.status === 200) {
          if (res.data.status === 200) {
            if (res.data.message === "This test is already submitted!") {
              Sentry.captureException(error_obj, (scope) => {
                scope.setTransactionName(error_obj.api_name);
                return scope;
              });
              dispatch(submitQuestionA(res, null, qid, true));
            } else dispatch(submitQuestionA(res, false, qid));
            // dispatch(submitQuestionA(null, false, qid));
          } else if (
            res.data.status === 401 &&
            res.data.message === "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(
                    `https://5x9619yt5a.execute-api.ap-south-1.amazonaws.com/${ENVIRONEMNT_VAR}/questionsubmission`,
                    formdata,
                    {headers: { 'Content-Type': 'application/json' }}
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      if (
                        res.data.message === "This test is already submitted!"
                      ) {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        dispatch(submitQuestionA(res, null, qid, true));
                      }
                      if (res.data.status === 200) {
                        dispatch(submitQuestionA(res, false, qid));
                      } else {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        dispatch(submitQuestionA(null, true, qid));
                      }
                    } else {
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                      dispatch(submitQuestionA(null, true, qid));
                    }
                  })
                  .catch((err) => {
                    error_obj.status = err?.request?.status;
                    Sentry.captureException(error_obj, (scope) => {
                      scope.setTransactionName(error_obj.api_name);
                      return scope;
                    });
                    dispatch(submitQuestionA(null, true, qid));
                  });
              })
              .catch((err) => {
                console.log(err);
                dispatch(submitQuestionA(null, true, qid));
              });
          } else {
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(submitQuestionA(null, true, qid));
          }
        } else {
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
          failed_questionsubmission.push(question_answer);
          dispatch(submitQuestionA(null, true, qid));
        }
      })
      .catch((err) => {
        console.log("error in submittting question");
        failed_questionsubmission.push(question_answer);
        console.log(err);
        error_obj.status = err?.request?.status;
        Sentry.captureException(error_obj, (scope) => {
          scope.setTransactionName(error_obj.api_name);
          return scope;
        });
        dispatch(submitQuestionA(null, true, qid));
      });
  };
};

export const submitTestAC = (type,networkspeed) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    console.log(getState().ques);
    const question_answer = getState().ques.answers[getState().ques.qid];

    // question_answers.forEach((que) => {
    //   if (!que.action_type) que.action_type = 1;
    //   if (typeof que.timetaken === "string")
    //     que.timetaken = JSON.parse(que.timetaken);
    //   const timetaken = que.timetaken
    //     .filter((time) => time.length === 3)
    //     .map((time) => time[2]);
    //   que.total_timetaken =
    //     timetaken.length > 0 ? timetaken.reduce((a, b) => a + b) : 0;
    //   if (typeof que.timetaken !== "string")
    //     que.timetaken = JSON.stringify(que.timetaken);
    // });
    if(!question_answer.timetaken.end){
      question_answer.timetaken.end = moment().format("YYYY-MM-DD HH:mm:ss").toString();
    } 
    if(!question_answer.timetaken.start && question_answer.timetaken.end){
      question_answer.timetaken.start = moment(question_answer.timetaken.end).subtract(question_answer.timetaken.duration,'seconds').format("YYYY-MM-DD HH:mm:ss").toString();
    } 
    let timetakendiff = moment.duration(
      moment(question_answer.timetaken.end).diff(moment(question_answer.timetaken.start))
    );

    if(question_answer.timetaken.start === question_answer.timetaken.end) question_answer.timetaken.duration = 0;
    if (!question_answer.action_type) question_answer.action_type = 2;
    question_answer.total_timetaken = parseInt(timetakendiff.asSeconds());
	     const timetaken = [question_answer.timetaken.start,question_answer.timetaken.end,parseInt(timetakendiff.asSeconds())];
    question_answer.timetaken = timetaken;
    question_answer.internet_speed=networkspeed;

    // console.log(question_answer);
    let question_answers = [];
    question_answers.push(question_answer);
    if (failed_questionsubmission.length>0) {
      question_answers = [...question_answers, ...failed_questionsubmission];
   }

    console.log("question submitted for qid - " + question_answer);
    console.log(JSON.stringify(question_answer.timetaken));

    // const formdata = new FormData();
    // formdata.append("questionnaire_id", getState().ques.test_id);
    // formdata.append("subject_id ", getState().ques.subject_id);
    // formdata.append("question_answers", typeof question_answer !== "string" ? JSON.stringify([question_answer]) : [question_answer]);
    // formdata.append("test_submission_type", type);
    const formdata = {
      questionnaire_id: getState().ques.test_id,
      subject_id: getState().ques.subject_id,
      question_answers: question_answers,
      test_submission_type: type
    }

    const error_obj = { ...plain_error_obj };
    error_obj.payload = {};
    // formdata.forEach((value, key) => (error_obj.payload[key] = value));
    error_obj.payload = formdata;
    error_obj.timestamp = moment().format("YYYY-MM-DD hh:mm:ss").toString();
    error_obj.api_name = `/prod/testsubmission`;

    const collect_feedback = getState().ques.collect_feedback;
    http
      .post(`https://mk2dp5bcoi.execute-api.ap-south-1.amazonaws.com/${ENVIRONEMNT_VAR}/testsubmission`, formdata, {headers: { 'Content-Type': 'application/json' }})
      .then((res) => {
        error_obj.status = res.status;
        error_obj.api_status = res.data?.status;
        if (res.status === 200) {
          if (res.data.status === 200) {
            if (collect_feedback) {
              http
                .get(
                  `${BASE_API_URL}/questionnaire/v2/getTestFeedback?test_id=${
                    getState().ques.test_id
                  }`
                )
                .then((que_res) => {
                  dispatch(submitTestA(res, que_res, false));
                })
                .catch((error) => {
                  console.log(error);
                  Sentry.captureException(error_obj, (scope) => {
                    scope.setTransactionName(error_obj.api_name);
                    return scope;
                  });
                  dispatch(submitTestA(res, null, true));
                });
            } else {
              dispatch(submitTestA(res, null, false));
                //  dispatch(submitTestA(null, null, false));
            }
          } else if (
            res.data.status === 401 &&
            res.data.message === "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(
                    `https://mk2dp5bcoi.execute-api.ap-south-1.amazonaws.com/${ENVIRONEMNT_VAR}/testsubmission`,
                    formdata,
                    {headers: { 'Content-Type': 'application/json' }}
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data.status === 200) {
                        if (collect_feedback) {
                          http
                            .get(
                              `${BASE_API_URL}/questionnaire/v2/getTestFeedback?test_id=${
                                getState().ques.test_id
                              }`
                            )
                            .then((que_res) => {
                              dispatch(submitTestA(res, que_res, false));
                            })
                            .catch((error) => {
                              console.log(error);
                              console.log("error getting feedback questions");
                              dispatch(submitTestA(res, null, false));
                            });
                        } else {
                          dispatch(submitTestA(res, null, false));
                        }
                      } else {
                        Sentry.captureException(error_obj, (scope) => {
                          scope.setTransactionName(error_obj.api_name);
                          return scope;
                        });
                        dispatch(submitTestA(null, false, true));
                      }
                    } else {
                      Sentry.captureException(error_obj, (scope) => {
                        scope.setTransactionName(error_obj.api_name);
                        return scope;
                      });
                      dispatch(submitTestA(null, false, true));
                    }
                  })
                  .catch((err) => {
                    error_obj.status = err?.request?.status;
                    Sentry.captureException(error_obj, (scope) => {
                      scope.setTransactionName(error_obj.api_name);
                      return scope;
                    });
                    dispatch(submitTestA(null, false, true));
                  });
              })
              .catch((err) => {
                console.log(err);
                dispatch(submitTestA(null, false, true));
              });
          } else {
            Sentry.captureException(error_obj, (scope) => {
              scope.setTransactionName(error_obj.api_name);
              return scope;
            });
            dispatch(submitTestA(null, false, true));
          }
        } else {
          Sentry.captureException(error_obj, (scope) => {
            scope.setTransactionName(error_obj.api_name);
            return scope;
          });
          dispatch(submitTestA(null, false, true));
        }
      })
      .catch((err) => {
        console.log("error in submittting test");
        console.log(err);
        error_obj.status = err?.request?.status;
        Sentry.captureException(error_obj, (scope) => {
          scope.setTransactionName(error_obj.api_name);
          return scope;
        });
        dispatch(submitTestA(null, false, true));
      });
  };
};