import React from 'react'
import RemedialCard from '../RemedialCard/RemedialCard'
import { Box, Stack, Typography, styled } from '@mui/material'
import ProgressSvg from './../../../../assets/remedial/svg/Progress-loading.svg'
import BorderLinearProgress from '../../../../components/common/BorderLinearProgress/BorderLinearProgress'


const TestProgressCard = ({ data }) => {

    const ProgressInfoWrapper = styled('div')(({ theme }) => ({
        flexGrow: '1',
        display: 'grid',
        gap: '10px',
        rowGap: '10px',
        gridTemplateColumns: `repeat(auto-fit, minmax(120px, 1fr))`,
    }));
    const InfoBox = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        borderRadius: '8px',
        padding: '20px',
        '&.skipped': {
            backgroundColor: theme.palette.warning[200]
        },
        '&.remaining': {
            backgroundColor: theme.palette.extraredpalette[200]
        }

    }));

    return (
        <RemedialCard>
            <Stack gap={'20px'}>
                <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
                    <img src={ProgressSvg} alt='timer' width={20} height={20} />
                    <Typography component={'p'} sx={{
                        fontFamily: 'Poppins-SemiBold',
                        fontSize: '16px',
                        color: 'grey.900'
                    }}>Progress</Typography>
                </Stack>

                <Stack gap={'6px'}>
                    <BorderLinearProgress value={data.answeredQuestion/data.totalQuestion * 100} height={8} progressColor={'primary.800'} barColor={'primary.100'} />
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>

                        <Typography component={'p'} sx={{
                            fontFamily: 'Poppins-Medium',
                            fontSize: '12px',
                            color: 'grey.700'
                        }}>Completed</Typography>

                        {data.totalQuestion && <Typography component={'p'} sx={{
                            fontFamily: 'Poppins-Medium',
                            fontSize: '12px',
                            color: 'grey.700'
                        }}>{`${data.answeredQuestion > 9 ? data.answeredQuestion : '0' + data.answeredQuestion}/${data.totalQuestion > 9 ? data.totalQuestion : '0' + data.totalQuestion}`}</Typography>}

                    </Stack>
                </Stack>

                <ProgressInfoWrapper>
                    <InfoBox className='skipped'>
                        <Typography component={'p'} sx={{
                            fontFamily: 'Poppins-Medium',
                            fontSize: '14px',
                            color: theme => theme.palette.secondary.contrastText
                        }}>Skipped</Typography>
                        <Typography component={'p'} sx={{
                            fontFamily: 'Poppins-SemiBold',
                            fontSize: '16px',
                            color: 'primary.800'
                        }}>{data.skippedQuestions > 9 ? data.skippedQuestions : '0' + data.skippedQuestions}</Typography>
                    </InfoBox>
                    <InfoBox className='remaining'>
                        <Typography component={'p'} sx={{
                            fontFamily: 'Poppins-Medium',
                            fontSize: '14px',
                            color: theme => theme.palette.secondary.contrastText
                        }}>Remaining</Typography>
                        <Typography component={'p'} sx={{
                            fontFamily: 'Poppins-SemiBold',
                            fontSize: '16px',
                            color: 'primary.800'
                        }}>{data.remainingQuestions > 9 ? data.remainingQuestions : '0' + data.remainingQuestions}</Typography>
                    </InfoBox>
                </ProgressInfoWrapper>

            </Stack>

        </RemedialCard>
    )
}

export default TestProgressCard