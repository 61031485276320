import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import {updateEmailAC, updateMobileAC, updateProfileDataAC, updateProfilePicAC, verifyOtpAC} from '../../store/actions/profileActions';
import styled from 'styled-components';

const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 25%);

    @media(max-width: 1180px) {
        grid-template-columns: repeat(2, 50%);
    }

`;

const SaveButton = styled.button`
    border-radius: 32px;
    background:  ${({ active }) => (active ? "linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%)" : "grey")};
    margin: auto;
    text-align: center;
    width: 98px;
    padding: 8px;
    color: white;
    margin-right: 0;
    border: none;
`

class EditProfile extends Component {

    state = {
        openContent : false,
        contentUrl: '',
        contentType: null,
        name: this.props.name,
        gender: this.props.gender,
        email: this.props.email,
        mobile_number: this.props.mobile_number,
        mobile_number_less_than_10_digits: false,
        profilePicUrl: this.props.profile_pic,
    }

    onNameChange = (event)=> {
        event.stopPropagation();
        event.preventDefault();
        this.setState({name: event.target.value});
    }

    onGenderChange = async (event) => {
        event.stopPropagation();
        let gender = event.target.id;
        // document.getElementById(this.state.gender.toLowerCase()).checked = false;
        this.setState({gender: gender});
        // document.getElementById(event.target.id).checked= true;
    }

    onMobileNumberChange = (event)=> {
        event.stopPropagation();
        event.preventDefault();
        this.setState({mobile_number: event.target.value});
    }

    onEmailChange = (event)=> {
        event.stopPropagation();
        event.preventDefault();
        this.setState({email: event.target.value});
    }

    closeEditBasicProfile = () => {
        this.setState({
            name: this.props.name,
            gender: this.props.gender,
            email: this.props.email,
            mobile_number: this.props.mobile_number,
            openVerifyOtpInputBox: false,
            otp: null,
            file: null
        });
        this.props.close();
    }

    saveBasicProfile = () => {
        this.props.updateProfile(this.state.name, this.state.gender.toUpperCase());
    }

    saveMobileNumber = () => {
        if (this.state.mobile_number.length !== 10) {
            this.setState({mobile_number_less_than_10_digits: true});
        } else {
            this.setState({mobile_number_less_than_10_digits: false});
            this.props.updateMobile(this.state.mobile_number);
        }
    }

    saveEmail = () => {
        this.props.updateEmail(this.state.email);
    }

    openVerifyOtpInputBoxHandler = () => {
        this.setState({openVerifyOtpInputBox: true});
    }

    onOtpChange = (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.setState({otp: event.target.value});
    }

    submitVerifyOtp = () => {
        this.props.verifyOtp(this.state.otp);
    }

    onFileChangeHandler = (event) => {
        const file = event.target.files[0];
        if (file) {
            const profilePicUrl = URL.createObjectURL(file);
            this.setState({ file, profilePicUrl });
        }
    }

    saveProfilePic = () => {
        if (this.state.file) {
            this.props.updateProfilePic(this.state.file);
        }
    }

    render () {
        // console.log(this.props.update_email_message)
        return (
            <div style={{}}>
                <div style={{border: '1px solid #eee', borderRadius: '4px', padding: '10px', margin: '20px 0'}}>
                    <div style={{margin: '10px 0',}}>
                        <img style={{borderRadius: '50% 50%', width: '100px', height: '100px'}} src={this.state.profilePicUrl} alt='profile'></img>
                    </div>
                    <div style={{ margin: '10px'}}>
                        <input type='file' onChange={this.onFileChangeHandler} accept='image/*' style={{height: '22px'}}></input>
                    </div>
                    {this.props.error_updating_basic_profile_data ? <div style={{color: 'red',margin:'auto'}}>Error Updating Profile Details. Please try again after some time !!!</div> : this.props.nameGenderUpdate ? <div style={{color: 'green',margin:'auto',textAlign:'center'}}>Saved!</div> : null}
                    <div style={{display: 'flex'}}>
                        <SaveButton active={this.state.file} onClick={this.saveProfilePic}>Save</SaveButton><br></br>
                    </div>
                </div>
                <div style={{border: '1px solid #eee', borderRadius: '4px',  textAlign: 'left', padding: '10px', margin: '20px 0'}}>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '15%', marginRight: '20px',textAlign: 'right'}}>Name : </div>
                        <div style={{width: '85%'}}>
                            <input onChange={this.onNameChange} value={this.state.name} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}} required></input>
                            {!this.state.name || this.state.name === '' ? <div style={{color: 'red'}}>Name cannot be Empty!!</div>: null}
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '15%', marginRight: '20px',textAlign: 'right'}}>Gender : </div>
                        <div style={{width: '85%', display: 'flex', flexDirection: 'row'}}>
                            <div style={{margin: 'auto 10px'}}>
                                <input onChange={this.onGenderChange} type="radio" id="male" name="gender" value="male"
                                        checked={this.state.gender.toLowerCase() == 'male' ? true : false} />
                                <label htmlFor="male">Male</label>
                            </div>

                            <div style={{margin: 'auto 10px'}}>
                                <input onChange={this.onGenderChange} type="radio" id="female" name="gender" value="female"  checked={this.state.gender.toLowerCase() == 'female' ? true : false} />
                                <label htmlFor="female">Female</label>
                            </div>

                            <div style={{margin: 'auto 10px'}}>
                                <input onChange={this.onGenderChange} type="radio" id="other" name="gender" value="other" checked={this.state.gender.toLowerCase() !== 'male' && this.state.gender.toLowerCase() !== 'female' ? true : false}  />
                                <label htmlFor="other">Other</label>
                            </div>
                        </div>
                    </div>
                    {this.props.error_updating_basic_profile_data ? <div style={{color: 'red',margin:'auto'}}>Error Updating Profile Details. Please try again after some time !!!</div> : this.props.nameGenderUpdate ? <div style={{color: 'green',margin:'auto',textAlign:'center'}}>Saved!</div> : null}
                    <div style={{display: 'flex'}}>
                        <SaveButton active={this.state.name && this.state.name !== '' && (this.state.name!==this.props.name || this.state.gender.toLowerCase() !== this.props.gender.toLowerCase())}  onClick={this.saveBasicProfile}>Save</SaveButton><br></br>
                    </div>
                </div>
                <div  style={{border: '1px solid #eee',  borderRadius: '4px', textAlign: 'left', padding: '10px', margin: '20px 0'}}>
                    <div>
                        <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                            <div style={{width: '15%',  marginRight: '20px',textAlign: 'right'}}>Mobile : </div>
                            <div style={{width: '85%'}}>
                                <input onChange={this.onMobileNumberChange} value = {this.state.mobile_number} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}}></input>
                                {!this.state.mobile_number.trim() ? <div style={{color: 'red'}}>Mobile Number cannot be Empty!!</div>: null}
                                {this.state.mobile_number_less_than_10_digits ? <div style={{color: 'red'}}>Mobile Number must be of 10 digits!!</div>: null}
                                {this.props.update_mobile_number_message ? <div style={{color: 'red'}}>{this.props.update_mobile_number_message}</div>: null}
                            </div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <SaveButton active={this.state.mobile_number !== this.props.mobile_number} onClick={this.saveMobileNumber}>Save</SaveButton>
                        </div> 
                    </div>
                    {/* // this.props.update_mobile_number_message == 'Enter the otp to validate the mobile number' && !this.state.openVerifyOtpInputBox ? <div style={{display: 'flex',flexDirection: 'column', padding: '20px'}}>
                    //     <div style={{margin: '10px auto 10px 20px'}}>Mobile : {this.props.mobile_number}</div>
                    //     <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    //         <div>
                    //             <SaveButton style={{margin: 'auto'}} active={true} onClick={this.openVerifyOtpInputBoxHandler}>Verify Otp</SaveButton>
                    //         </div>
                    //         <div></div>
                    //     </div>
                    // </div> : 
                    // this.props.update_mobile_number_message && !this.state.openVerifyOtpInputBox ? <div style={{textAlign: 'center', padding: '20px'}}>{this.props.update_mobile_number_message}</div> : 
                    // : <div style={{textAlign: 'center', padding: '20px'}}>{this.props.update_mobile_number_message}</div>

                    // this.state.openVerifyOtpInputBox ? <div>
                    //     <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                    //         <div style={{width: '65%',  marginRight: '20px',textAlign: 'right'}}>{this.props.update_mobile_number_message} : </div>
                    //         <div style={{width: '35%'}}>
                    //             <input onChange={this.onOtpChange} placeholder='Enter Otp' style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}}></input>

                    //         </div>
                    //     </div>
                    //     <div style={{display: 'flex', alignItems: 'flex-end', justifyContent:'flex-end'}}>
                    //         <div style={{textAlign: 'right',margin: 'auto', marginRight: '20px'}}>Cancel</div>
                    //         <div>
                    //             <SaveButton active={this.state.otp && this.state.otp!= ''} onClick={this.submitVerifyOtp}>Submit</SaveButton>
                    //         </div>
                    //     </div> 
                    // </div> : null}
                    // } */}

                </div>
                
                <div  style={{border: '1px solid #eee', borderRadius: '4px', textAlign: 'left', padding: '10px', margin: '20px 0'}}>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 10px'}}>
                        <div style={{width: '15%',  marginRight: '20px',textAlign: 'right'}}>Email : </div>
                        <div style={{width: '85%'}}>
                            <input onChange={this.onEmailChange} type='email' value = {this.state.email} style={{width: '70%', height: '22px',border: '1px solid #bbb',borderRadius: '4px'}}></input>
                            {this.props.update_email_message ? <div style={{color: 'red'}}>{this.props.update_email_message}</div>: null}
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <SaveButton active={this.state.email !== this.props.email} onClick={this.saveEmail}>Save</SaveButton>
                    </div>
                </div>
                <div style={{color: 'blue'}} onClick={this.closeEditBasicProfile}>Cancel</div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.prof.loading,
        name: state.prof.name,
        college: state.prof.college,
        college_university_degree_department_id: state.prof.college_university_degree_department_id,
        department_details: state.prof.department_details,
        email: state.prof.email,
        email_verified: state.prof.email_verified,
        gender: state.prof.gender,
        id: state.prof.id,
        is_ambassador: state.prof.is_ambassador,
        mobile_number: state.prof.mobile_number,
        mobile_verified: state.prof.mobile_verified,
        profile_pic: state.prof.profile_pic,
        roll_number: state.prof.roll_number,
        section_details: state.prof.section_details,
        semester_id: state.prof.semester_id,
        university_degree_department_id: state.prof.university_degree_department_id,
        error_updating_basic_profile_data: state.prof.error_updating_basic_profile_data,
        update_mobile_number_message: state.prof.update_mobile_number_message,
        error_updating_email: state.prof.email,
        update_email_message: state.prof.update_email_message,
        nameGenderUpdate : state.prof.nameGenderUpdate
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfile:(name, gender)=> dispatch(updateProfileDataAC(name, gender)),
        updateProfilePic:(file)=> dispatch(updateProfilePicAC(file)),
        updateEmail:(email)=> dispatch(updateEmailAC(email)),
        updateMobile:(mobile_number)=> dispatch(updateMobileAC(mobile_number)),
        verifyOtp:(otp)=> dispatch(verifyOtpAC(otp)),        
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditProfile));