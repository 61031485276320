import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import styled, { keyframes, css } from "styled-components";
import Countdowntimer from "./timer";
import AppLayout from "../../components/Layout/Layout";
import Modal1 from "./Modal";
import Modal2 from "./Modal2";
import Modal from "react-bootstrap/Modal";
import {
  getClassroomActivityAC,
  postCommentAC,
  loadMoreCommentsAC,
  getFeedbackAC,
  EnrollweeklyChallenge,
} from "../../store/actions/classroomActions";

const Button = styled.div`
  background: linear-gradient(
    139.5deg,
    #7175e0 -50.69%,
    #3235c0 60.2%,
    #e25bc8 189.26%
  );
  border-radius: 32px;
  margin: auto;
  text-align: center;
  border: 1px solid
    linear-gradient(139.5deg, #7175e0 -50.69%, #3235c0 60.2%, #e25bc8 189.26%);
  width: 150px;
  padding: 12px;
  color: white;
  margin-top: 20px;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const ExpiredButton = styled.div`
  // background: linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%);
  bacgroudn: white;
  border-radius: 32px;
  margin: auto;
  text-align: center;
  // border: 1px solid linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%);
  border: 1px solid #bbb;
  width: 180px;
  padding: 12px;
  color: black;
  margin-top: 20px;
`;

const MaterialImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 300px);

  @media (max-width: 1180px) {
    grid-template-columns: repeat(3, 300px);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 300px);
  }
`;

class Classroom extends Component {
  state = {
    openMaterial: false,
    material_url: null,
    comments: [],
    enrolledweeklychallenge: false,
    disablejoinbtn: true,
  };

  componentDidMount = () => {
    document.body.style.backgroundColor = "#f2f2f2";
    let date = moment().format("YYYY-MM-DD HH:mm:ss");
    if (this.props.from_date) {
      date = this.props.from_date;
    }
    console.log(date);
    this.props.getClassroomDetails(date);
  };

  openMaterialHandler = (url) => {
    this.setState({ openMaterial: true, material_url: url });
  };

  closeMaterial = () => {
    this.setState({ openMaterial: false, material_url: null });
  };
  closeSuccess = () => {
    this.setState({ enrolledweeklychallenge: false, material_url: null });
    window.location.reload();
  };

  openVC = (event, url) => {
    window.open(url, "_blank").focus();
  };

  openTestResults = (id) => {
    this.props.history.push({
      pathname: "/assessmentResults",
      state: { test_id: id },
    });
  };

  openLiveSurveyResults = (id) => {
    this.props.history.push({
      pathname: "/liveSurveyResults",
      state: { testId: id },
    });
  };

  openLiveTestResults = (id) => {
    this.props.history.push({
      pathname: "/liveAssessmentResults",
      state: { testId: id },
    });
  };

  openSubjectiveTestResults = (id) => {
    this.props.history.push({
      pathname: "/subjectiveResults",
      state: { test_id: id },
    });
  };

  openCodingTestResults = (id) => {
    this.props.history.push({
      pathname: "/codingAssessmentSummary",
      state: { test_id: id },
    });
  };

  startAssessment = (id) => {
    this.props.history.push({
      pathname: "/assessment",
      state: { test_id: id, prev_page: "classroom" },
    });
  };

  startCodingAssessment = (id) => {
    this.props.history.push({
      pathname: "/startCoding",
      state: {
        test_id: id,
      },
    });
  };

  startSubjectiveAssessment = (id) => {
    this.props.history.push({
      pathname: "/subjectiveAssessment",
      state: {
        test_id: id,
      },
    });
  };
  enrollweeklychallenge = (id) => {
    localStorage.setItem("challengeid", id);
    this.props.history.push({
      pathname: "/joinchallange",
      state: {
        test_id: id,
      },
    });
    window.location.reload();
  };
  enrolltoweeklychallenge = async (id) => {
    localStorage.setItem("challengeid", id);
    // this.props.enrollweeklychallenge(id)
    const Enrolling_challenge = await this.props.enrollweeklychallenge(id);
    this.setState({
      enrolledweeklychallenge: true,
    });
  };

  viewchallengeresults = (id) => {
    localStorage.setItem("challengeid", id);
    this.props.history.push({
      pathname: "/challengeResults",
      state: {
        test_id: id,
      },
    });
  };
  onCommentInputChange = (event, id, type) => {
    console.log(event.target.value)
    event.preventDefault();
    event.stopPropagation();
    let comments = this.state.comments;
    let item_index = -1;
    comments.findIndex((item, index) => {
      if (item.id == id && item.type == type) {
        item_index = index;
      }
    });
    if (item_index == -1) {
      let comment_dict = {
        id: id,
        type: type,
        comment: event.target.value,
      };
      comments.push(comment_dict);
    } else {
      let comment_dict = comments[item_index];
      comment_dict["comment"] = event.target.value;
      comments[item_index] = comment_dict;
    }
    this.setState({ comments: comments });
    console.log(this.state.comments);
  };

  postCommentHandler = (event, id, type) => {
    event.preventDefault();
    event.stopPropagation();
    let comments = [...this.state.comments];
    let item_index = -1;
    comments.findIndex((item, index) => {
      if (item.id == id && item.type == type) {
        item_index = index;
      }
    });
    if (item_index == -1) {
      return;
    } else {
      let comment = { ...comments[item_index] };
      this.props.postComment(id, comment.comment, type);
      comments.splice(item_index, 1);
      this.setState({ comments: comments });
    }
  };

  goToHomePage = () => {
    this.props.history.push("/home");
  };

  loadMoreNotifications = () => {
    this.props.getClassroomDetails(this.props.from_date);
  };

  componentWillUnmount = () => {
    this.props.clearState();
  };
  disablejoinbtn = () => {
    this.setState({ disablejoinbtn: false });
  };
  render() {
    const current = moment();
    console.log(this.props);
    console.log(this.state);
    document.body.style.overflow = this.props.start_feedback
      ? "hidden"
      : "auto";
    return (
      <div>
        <AppLayout></AppLayout>
        <div
          onClick={this.goToHomePage}
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "116px",
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div>
            <img
              style={{ width: "20px", marginTop: "10px" }}
              src="/images/left_arrow.svg"
              alt="left arrow"
            ></img>
          </div>
          <div style={{ margin: "auto 0", marginLeft: "20px" }}>
            My classroom activity
          </div>
        </div>
        <div style={{}}>
          {this.props.college_notifications.map((item) => {
            let material_urls_string =
              item.file_url && item.type == "Material" ? item.file_url : "";
            let material_urls_a =
              material_urls_string.length > 0
                ? material_urls_string.replace(/'/g, '"')
                : "";
            let material_urls =
              material_urls_string.length > 0
                ? JSON.parse(material_urls_a)
                : [];
            let item_index = -1;
            if (item.id === 844) console.log(item);
            this.state.comments.findIndex((item_c, index) => {
              if (item_c.id == item.id && item_c.type == item.type) {
                item_index = index;
                console.log("index of"+ item_index)
              }
            });
            return (
              <div
                style={{
                  backgroundColor: "white",
                  boxShadow: "5px 10px 20px #f1ecec",
                  padding: "20px",
                  width: "90%",
                  margin: "20px auto",
                  borderRadius: "8px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {item?.college_account_details?.profile_pic ? (
                    <div style={{ margin: "auto 10px auto 10px" }}>
                      <img
                        style={{ borderRadius: "50%" }}
                        width="60px"
                        height="60px"
                        src={item.college_account_details.profile_pic}
                        alt=""
                      ></img>
                    </div>
                  ) : null}
                  <div style={{ textAlign: "left", margin: "auto 0" }}>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        lineHeight: "23px",
                        color: "#191648",
                        marginBottom: "8px",
                      }}
                    >
                      {item.title ? item.title : item.name}
                    </div>
                    <div style={{ fontSize: "16px" }}>
                      Posted by {item.college_account_details.faculty_name}{" "}
                      &nbsp;&nbsp;| &nbsp;&nbsp;
                      {moment(item.created_at).format("Do MMMM YYYY")}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #D8D8D8",
                    borderRadius: "8px",
                    width: "95%",
                    margin: "10px auto",
                    padding: "20px",
                  }}
                >
                  {item.description ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <span style={{ fontWeight: "600" }}>Description: </span>
                      <span
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></span>
                    </div>
                  ) : null}
                  <MaterialImageGrid>
                    {item.type == "Material"
                      ? material_urls.map((url) => {
                          return (
                            <div
                              style={{
                                border: "1px solid #D8D8D8",
                                borderRadius: "8px",
                                width: `300px`,
                                boxSizing: "border-box",
                                padding: "10px",
                                margin: "10px",
                              }}
                              onClick={() => this.openMaterialHandler(url)}
                            >
                              <div>
                                <img
                                  src="/images/material_file.svg"
                                  alt="material"
                                ></img>
                              </div>
                              {/* <div style={{margin: '10px', marginRight: '10px', textAlign: 'end'}}>
                                                    <img src='/images/download.svg'></img>
                                                </div> */}
                            </div>
                          );
                        })
                      : null}
                  </MaterialImageGrid>

                  {item.type == "Notification" ? (
                    item.file_url ? (
                      <div
                        style={{
                          border: "1px solid #D8D8D8",
                          borderRadius: "8px",
                          width: "300px",
                          boxSizing: "border-box",
                          padding: "10px",
                          margin: "10px",
                        }}
                        onClick={() => this.openMaterialHandler(item.file_url)}
                      >
                        <div>
                          <img
                            src="/images/material_file.svg"
                            alt="notification"
                          ></img>
                        </div>
                        {/* <div style={{margin: '10px', marginRight: '10px', textAlign: 'end'}}>
                                            <img src='/images/download.svg'></img>
                                        </div> */}
                      </div>
                    ) : null
                  ) : null}

                  {item.type == "Test" &&
                  moment(item.doe) > current &&
                  item.submitted == 0 &&
                  item.starttime &&
                  moment(item.starttime) <= current ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Your test has started on{" "}
                        {moment(item.starttime).format("DD MMM")} at{" "}
                        {moment(item.starttime).format("HH:mm A")} and the
                        timelimit for the test is {item.timelimit / 60} mins.
                        You have only{" "}
                        {(moment(item.doe).diff(current) / (1000 * 60)).toFixed(
                          0
                        )}{" "}
                        mins
                      </div>
                      <Button onClick={() => this.startAssessment(item.id)}>
                        Start Test
                      </Button>
                    </div>
                  ) : item.type == "Test" &&
                    moment(item.doe) > current &&
                    item.submitted == 0 &&
                    item.starttime &&
                    moment(item.starttime) > current ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Your test will start on{" "}
                        {moment(item.starttime).format("DD MMM")} at{" "}
                        {moment(item.starttime).format("HH:mm A")} and the
                        timelimit for the test is {item.timelimit / 60} mins.
                      </div>
                      {/* <Button onClick={()=>this.startAssessment(item.id)}>
                                        Start Test
                                    </Button>     */}
                    </div>
                  ) : item.type == "Test" &&
                    ((item.results_release_time &&
                      moment(item.results_release_time) > current) ||
                      moment(item.doe) > current) &&
                    item.submitted == 1 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div>
                        You have successfully completed the test. Please wait
                        for the results
                      </div>
                    </div>
                  ) : item.type == "Test" &&
                    moment(item.doe) < current &&
                    item.submitted == 0 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Your test has expired on{" "}
                        {moment(item.doe).format("DD MMM YYYY HH:mm A")}
                      </div>
                      <ExpiredButton>Test Expired </ExpiredButton>
                    </div>
                  ) : item.type == "Test" &&
                    ((item.results_release_time &&
                      moment(item.results_release_time) < current) ||
                      moment(item.doe) < current) &&
                    item.submitted == 1 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        You have successfully submitted the test
                      </div>
                      <Button onClick={() => this.openTestResults(item.id)}>
                        View Results
                      </Button>
                    </div>
                  ) : item.type === "Live Survey" &&
                    moment(item.survey_completed_time).isBefore(current) ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Live survey has ended
                      </div>
                      <Button
                        onClick={() => this.openLiveSurveyResults(item.id)}
                      >
                        View Results
                      </Button>
                    </div>
                  ) : item.type === "Live Test" &&
                    moment(item.test_completed_time).isBefore(current) ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Live assessment has ended
                      </div>
                      <Button onClick={() => this.openLiveTestResults(item.id)}>
                        View Results
                      </Button>
                    </div>
                  ) : null}

                  {item.type == "Subjective" &&
                  moment(item.doe) > current &&
                  !item.submitted &&
                  item.starttime &&
                  moment(item.starttime) < current &&
                  !item.results_release_time ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Your test has started on{" "}
                        {moment(item.starttime).format("DD MMM")} at{" "}
                        {moment(item.starttime).format("HH:mm A")} and the
                        timelimit for the test is {item.timelimit / 60} mins.
                        You have only{" "}
                        {(moment(item.doe).diff(current) / (1000 * 60)).toFixed(
                          0
                        )}{" "}
                        mins
                      </div>
                      <Button
                        onClick={() => this.startSubjectiveAssessment(item.id)}
                      >
                        Start Test
                      </Button>
                    </div>
                  ) : item.type == "Subjective" &&
                    moment(item.doe) > current &&
                    !item.submitted &&
                    item.starttime &&
                    moment(item.starttime) >= current ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Your test will start on{" "}
                        {moment(item.starttime).format("DD MMM")} at{" "}
                        {moment(item.starttime).format("HH:mm A")} and the
                        timelimit for the test is {item.timelimit / 60} mins.
                      </div>
                    </div>
                  ) : item.type == "Subjective" &&
                    moment(item.doe) > current &&
                    item.submitted == 1 &&
                    (!item.results_release_time ||
                      moment(item.results_release_time) > current) ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div>
                        You have successfully completed the test. Please wait
                        for the results
                      </div>
                    </div>
                  ) : item.type == "Subjective" &&
                    moment(item.doe) < current &&
                    item.submitted == 0 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Your test has expired on{" "}
                        {moment(item.doe).format("DD MMM YYYY HH:mm A")}
                      </div>
                      <ExpiredButton>Test Expired </ExpiredButton>
                    </div>
                  ) : item.type == "Subjective" &&
                    moment(item.doe) < current &&
                    item.submitted == 1 &&
                    moment(item.results_release_time) < current ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        You have successfully submitted the test
                      </div>
                      <Button
                        onClick={() => this.openSubjectiveTestResults(item.id)}
                      >
                        View Results
                      </Button>
                    </div>
                  ) : null}
                  {item.type == "Weekly_Challenge" &&
                  item.enrollment_end_time &&
                  moment(item.enrollment_end_time) < current &&
                  item.is_enrolled === 0 &&
                  item.is_completed != 1 &&
                  item.is_viewer == 1 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Your challenge enrollment closed on{" "}
                        {moment(item.enrollment_end_time).format("DD MMM")} at{" "}
                        {moment(item.enrollment_end_time).format("HH:mm A")}.
                        But still you can follow.
                      </div>

                      <Button
                        onClick={() => this.enrollweeklychallenge(item.id)}
                      >
                        Follow Challenge
                      </Button>
                    </div>
                  ) : item.type == "Weekly_Challenge" &&
                    item.enrollment_end_time &&
                    moment(item.enrollment_end_time) >= current &&
                    !item.is_participant &&
                    !item.is_enrolled &&
                    item.is_completed != 1 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        The challenge will start on{" "}
                        {moment(item.start_date).format("DD MMM")}{" "}
                        {moment(item.start_date).format("HH:mm A")} and
                        enrollement will be close on{" "}
                        {moment(item.enrollment_end_time).format("DD MMM")}{" "}
                        {moment(item.enrollment_end_time).format("HH:mm A")}.
                        Please enroll before enrollement time.
                      </div>
                      <Button
                        onClick={() => this.enrolltoweeklychallenge(item.id)}
                      >
                        Enroll Challenge
                      </Button>
                    </div>
                  ) : item.type == "Weekly_Challenge" &&
                    item.start_date &&
                    moment(item.start_date) > current &&
                    moment(item.enrollment_end_time) < current &&
                    item.is_member === 1 &&
                    item.is_enrolled &&
                    item.is_completed === 0 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0px" }}>
                        You have enrolled to this challenge. The challenge will
                        start at {moment(item.start_date).format("DD MMM")}{" "}
                        {moment(item.start_date).format("HH:mm A")}
                      </div>
                      <Button
                        onClick={() => this.enrollweeklychallenge(item.id)}
                      >
                        Join Arena
                      </Button>
                    </div>
                  ) : item.type == "Weekly_Challenge" &&
                    item.start_date &&
                    moment(item.start_date) > current &&
                    moment(item.enrollment_end_time) > current &&
                    item.is_member === 1 &&
                    item.is_enrolled &&
                    item.is_completed === 0 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0px" }}>
                        The challenge will start at{" "}
                        {moment(item.start_date).format("DD MMM")}{" "}
                        {moment(item.start_date).format("HH:mm A")}
                      </div>
                      <div style={{ margin: "10px 0px", fontWeight: "600" }}>
                        You have enrolled already! Please wait to start the
                        challenge!
                      </div>
                      <div>
                        You can join after{" "}
                        <Countdowntimer
                          time={Math.abs(
                            moment().diff(item.enrollment_end_time, "seconds")
                          )}
                          timercompleted={this.disablejoinbtn}
                        />
                      </div>
                      <Button
                        disabled={this.state.disablejoinbtn}
                        onClick={() => this.enrollweeklychallenge(item.id)}
                      >
                        Join Arena
                      </Button>
                    </div>
                  ) : item.type == "Weekly_Challenge" &&
                    item.start_date &&
                    item.is_viewer === 1 &&
                    item.is_enrolled &&
                    item.is_completed === 0 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Your have missed the challenge. But still you can
                        follow.
                      </div>
                      <Button
                        onClick={() => this.enrollweeklychallenge(item.id)}
                      >
                        Follow Challenge
                      </Button>
                    </div>
                  ) : item.type == "Weekly_Challenge" &&
                    item.is_enrolled &&
                    item.is_completed == 1 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Your have completed challenge.
                      </div>
                      <Button
                        onClick={() => this.viewchallengeresults(item.id)}
                      >
                        View Results
                      </Button>
                    </div>
                  ) : item.type == "Weekly_Challenge" &&
                    item.is_enrolled &&
                    item.is_member == 1 &&
                    item.is_completed == 0 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        You have enrolled to this challenge. Join using below
                        button
                      </div>
                      <Button
                        onClick={() => this.enrollweeklychallenge(item.id)}
                      >
                        Join Arena
                      </Button>
                    </div>
                  ) : null}
                  {item.type == "assignment" &&
                  moment(item.due_date) > current &&
                  item.offline_submission == 0 &&
                  item.starttime &&
                  moment(item.starttime) < current &&
                  !item.results_release_time ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Assignment has started on{" "}
                        {moment(item.starttime).format("DD MMM")} at{" "}
                        {moment(item.starttime).format("HH:mm A")} and the due
                        date for the submission is{" "}
                        {moment(item.due_date).format("DD MMM")} . You have only{" "}
                        {moment(item.due_date).diff(current, "days")} days
                      </div>
                      <Button onClick={() => this.startAssignment(item.id)}>
                        Start Assignment
                      </Button>
                    </div>
                  ) : item.type == "assignment" &&
                    moment(item.due_date) > current &&
                    !item.offline_submission &&
                    item.starttime &&
                    moment(item.starttime) >= current ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Your assignment will start on{" "}
                        {moment(item.starttime).format("DD MMM")} at{" "}
                        {moment(item.starttime).format("HH:mm A")} and the due
                        date for the submission is{" "}
                        {moment(item.due_date).format("DD MMM")}
                      </div>
                    </div>
                  ) : item.type == "assignment" &&
                    moment(item.due_date) > current &&
                    item.offline_submission == 1 &&
                    (!item.results_release_time ||
                      item.results_release_time > current) ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div>
                        You have successfully submitted the assignment. Please
                        wait for the results
                      </div>
                    </div>
                  ) : item.type == "assignment" &&
                    moment(item.doe) < current &&
                    item.offline_submission == 0 &&
                    moment(item.late_submission_date) > current ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Assignment late submission date is{" "}
                        {moment(item.late_submission_date).format("DD MMM")} at{" "}
                        {moment(item.late_submission_date).format("HH:mm A")}.
                        You have{" "}
                        {moment(item.late_submission_date).diff(
                          current,
                          "hours"
                        )}{" "}
                        hours.
                      </div>
                      <Button onClick={() => this.startAssignment(item.id)}>
                        Start Assignment
                      </Button>
                    </div>
                  ) : item.type == "assignment" &&
                    moment(item.due_date) < current &&
                    moment(item.late_submission_date) < current &&
                    item.offline_submission == 0 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Your assignment submission has closed on{" "}
                        {moment(item.late_submission_date).format(
                          "DD MMM YYYY HH:mm A"
                        )}
                      </div>
                      <ExpiredButton>
                        Assignment Submission Closed
                      </ExpiredButton>
                    </div>
                  ) : item.type == "assignment" &&
                    moment(item.due_date) < current &&
                    item.offline_submission == 1 &&
                    moment(item.results_release_time) < current ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        You have successfully submitted the assignment
                      </div>
                      <Button
                        onClick={() => this.openAssignmentResults(item.id)}
                      >
                        View Results
                      </Button>
                    </div>
                  ) : null}

                  {item.type == "Coding test" &&
                  moment(item.doe) > current &&
                  item.submitted == 0 &&
                  item.starttime &&
                  moment(item.starttime) <= current ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Your test has started on{" "}
                        {moment(item.starttime).format("DD MMM")} at{" "}
                        {moment(item.starttime).format("HH:mm A")} and the
                        timelimit for the test is {item.timelimit / 60} mins.
                        You have only{" "}
                        {(moment(item.doe).diff(current) / (1000 * 60)).toFixed(
                          0
                        )}{" "}
                        mins.
                      </div>
                      <Button
                        onClick={() => this.startCodingAssessment(item.id)}
                      >
                        Start Test
                      </Button>
                    </div>
                  ) : item.type == "Coding test" &&
                    moment(item.doe) > current &&
                    item.submitted == 0 &&
                    moment(item.starttime) &&
                    moment(item.starttime) > current ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Your test will start on{" "}
                        {moment(item.starttime).format("DD MMM")} at{" "}
                        {moment(item.starttime).format("HH:mm A")} and the
                        timelimit for the test is {item.timelimit / 60} mins.
                      </div>
                    </div>
                  ) : item.type == "Coding test" &&
                    moment(item.doe) > current &&
                    item.submitted == 1 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div>
                        You have successfully completed the test. Please wait
                        for the results
                      </div>
                    </div>
                  ) : item.type == "Coding test" &&
                    moment(item.doe) < current &&
                    item.submitted == 0 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Your test has expired on{" "}
                        {moment(item.doe).format("DD MMM YYYY HH:mm A")}
                      </div>
                      <ExpiredButton>Test Expired </ExpiredButton>
                    </div>
                  ) : item.type == "Coding test" &&
                    moment(item.doe) < current &&
                    item.submitted == 1 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        You have successfully submitted the test
                      </div>
                      <Button
                        onClick={() => this.openCodingTestResults(item.id)}
                      >
                        View Results
                      </Button>
                    </div>
                  ) : null}

                  {item.type === "Feedback" &&
                  moment(item.start_time) < current &&
                  moment(item.doe) > current &&
                  item.submitted == 0 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        A Feedback named {item.title} created and set to be
                        expired on{" "}
                        {moment(item.doe).format("DD MMM YYYY HH:mm")}
                      </div>
                      <Button onClick={() => this.props.getFeedback(item.id)}>
                        Give Feedback
                      </Button>
                      {this.props.feedback_error ? (
                        <p className="text-center text-danger m-0 mt-2">
                          Request failed
                        </p>
                      ) : null}
                    </div>
                  ) : item.type === "Feedback" &&
                    moment(item.start_time) > current &&
                    item.submitted == 0 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        A Feedback named {item.title} created and will start on{" "}
                        {moment(item.start_time).format("DD MMM YYYY HH:mm")}{" "}
                        and set to be expired on{" "}
                        {moment(item.doe).format("DD MMM YYYY HH:mm")}
                      </div>
                      <ExpiredButton>Yet to Start</ExpiredButton>
                    </div>
                  ) : item.type == "Feedback" &&
                    moment(item.doe) < current &&
                    item.submitted == 0 ? (
                    <div>
                      {/* <div style={{margin: '10px 0'}}>Feedback got expired </div> */}
                      <ExpiredButton>Feedback Expired </ExpiredButton>
                    </div>
                  ) : item.type == "Feedback" && item.submitted == 1 ? (
                    <div style={{ textAlign: "center", margin: "10px 20px" }}>
                      Feedback was submitted successfully
                    </div>
                  ) : null}

                  {item.type == "VideoConference" &&
                  moment(item.end_time) >= current &&
                  moment(item.start_time).subtract(10, "minutes") >= current ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Meeting will start by{" "}
                        {moment(item.start_time).format("DD MMM YYYY HH:mm")}
                      </div>
                    </div>
                  ) : item.type == "VideoConference" &&
                    moment(item.end_time) >= current &&
                    moment(item.start_time).subtract(10, "minutes") <=
                      current ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Meeting has already started and it is going to end by{" "}
                        {moment(item.end_time).format("DD MMM YYYY HH:mm")}
                      </div>
                      <Button onClick={(event) => this.openVC(event, item.url)}>
                        Start Meeting
                      </Button>
                    </div>
                  ) : item.type == "VideoConference" &&
                    moment(item.end_time) <= current &&
                    moment(item.start_time) <= current ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" }}>
                      <div style={{ margin: "10px 0" }}>
                        Meeting has completed
                      </div>
                      <ExpiredButton>Metting Completed</ExpiredButton>
                    </div>
                  ) : null}
                </div>
                {item.type == "Material" ||
                item.type == "Notification" ||
                item.type == "Feedback" ? (
                  <div style={{ margin: "10px" }}>
                    <div style={{ width: "100%" }}>
                      <input
                        onChange={(event) =>
                          this.onCommentInputChange(event, item.id, item.type)
                        }
                        value={
                          item_index != -1
                            ? this.state.comments[item_index].comment
                            : ""
                        }
                        type="test"
                        placeholder="Write a comment here"
                        style={{
                          width: "99.5%",
                          border: "0.5px solid #595CD2",
                          height: "55px",
                          borderRadius: "8px",
                        }}
                      ></input>
                    </div>
                    <div>
                      <Button
                        onClick={(event) =>
                          this.postCommentHandler(event, item.id, item.type)
                        }
                        style={{ marginRight: "10px" }}
                      >
                        Post Comment
                      </Button>
                    </div>
                  </div>
                ) : null}
                {(item.type == "Material" ||
                  item.type == "Notification" ||
                  item.type == "Feedback") &&
                item.comments &&
                item.comments.length > 0 ? (
                  <div>
                    <div
                      style={{
                        borderBottom: "1px solid #DDDDDD",
                        margin: "10px",
                      }}
                    ></div>
                    <div style={{ margin: "10px" }}>
                      <div
                        style={{
                          fontWeight: "600",
                          fontSize: "20px",
                          lineHeight: "23px",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                      >
                        Other Comments
                      </div>
                      {item.comments.map((com) => {
                        return (
                          <div>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              {com.student && com.student.profile_pic ? (
                                <div>
                                  <img
                                    width="36px"
                                    height="36px"
                                    style={{
                                      border: "1px solid #979797",
                                      borderRadius: "50%",
                                    }}
                                    src={com.student.profile_pic}
                                    alt="profile"
                                  ></img>
                                </div>
                              ) : (
                                <div>
                                  <img
                                    width="36px"
                                    height="36px"
                                    style={{
                                      border: "1px solid #979797",
                                      borderRadius: "50%",
                                    }}
                                    src={com.college_account?.profile_pic}
                                    alt="college profile"
                                  ></img>
                                </div>
                              )}
                              <div
                                style={{
                                  margin: "auto 10px",
                                  color: "#191648",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  lineHeight: "23px",
                                }}
                              >
                                {com.student
                                  ? com.student.name
                                  : `${com.college_account.first_name}  ${com.college_account.last_name}`}
                              </div>
                              <div style={{ margin: "auto 10px auto 0" }}>
                                {" "}
                                |{" "}
                              </div>
                              <div style={{ margin: "auto 10px auto 0" }}>
                                {com.created_at}
                              </div>
                            </div>
                            <div
                              style={{
                                color: "#191648",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textAlign: "left",
                                marginLeft: "30px",
                              }}
                            >
                              {com.comment}
                            </div>
                            <div
                              style={{
                                borderBottom: "1px solid #DDDDDD",
                                margin: "10px 0",
                              }}
                            ></div>
                          </div>
                        );
                      })}
                      {item.comments.length < item.comments_count ? (
                        <div
                          onClick={() =>
                            this.props.loadMoreComments(item.id, item.type)
                          }
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#4F55C8",
                            marginLeft: "20px",
                            textAlign: "left",
                            lineHeight: "26px",
                          }}
                        >
                          View more comments
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
        <div style={{ margin: "20px auto" }}>
          <Button onClick={this.loadMoreNotifications}>Load More</Button>
        </div>
        <Modal1
          open={this.state.openMaterial}
          url={this.state.material_url}
          close={this.closeMaterial}
        ></Modal1>
        <Modal2 open={this.props.start_feedback}></Modal2>
        <Modal
          show={this.state.enrolledweeklychallenge}
          onHide={this.closeSuccess}
        >
          <Modal.Header closeButton>
            <Modal.Title>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>Successfully enrolled to challenge</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.closeSuccess}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    college_notifications: state.class.college_notifications,
    error_posting_comment: state.class.error_posting_comment,
    start_feedback: state.class.start_feedback,
    feedback_data: state.class.feedback_data,
    feedback_answers: state.class.feedback_answers,
    from_date: state.class.from_date,
    feedback_error: state.class.feedback_error,
    enrolledweeklychallenge: state.class.enrolledweeklychallenge,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClassroomDetails: (date) => dispatch(getClassroomActivityAC(date)),
    postComment: (id, comment, type) =>
      dispatch(postCommentAC(id, comment, type)),
    loadMoreComments: (id, type) => dispatch(loadMoreCommentsAC(id, type)),
    getFeedback: (id) => dispatch(getFeedbackAC(id)),
    clearState: () => dispatch({ type: "CLEAR_STATE" }),
    enrollweeklychallenge: (id) => dispatch(EnrollweeklyChallenge(id)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Classroom)
);
