import React, { useEffect, useState } from 'react';
import { Stepper, Step, StepLabel, Box, Typography, Grid, styled, StepConnector } from '@mui/material';

// Styled component for the circle icon
const CircleIcon = styled('div')(({ theme, isActive }) => ({
    width: 15,
    height: 15,
    borderRadius: '50%',
    marginLeft: '5px',
    background: theme.palette.grey[200],
    border: isActive ? `3px solid ${theme.palette.grey[600]}` : `1px solid ${theme.palette.grey[500]}`,
}));

const DottedConnector = styled(StepConnector)(({ theme }) => ({
    zIndex: '20',
    '& .MuiStepConnector-line': {
        border: '0px solid #fff',
        borderLeft: '2.3px',
        borderColor: theme.palette.grey[500],
        borderStyle: 'dotted',
        marginBottom: '-21px',
        marginTop: '-4px',
    },
    // '&.MuiStepConnector-vertical': {
    //     marginTop: '-20px',
    //     marginBottom: '-20px',
    //   },
}));

const CustomVerticalStepper = ({ steps }) => {
    const [activeStep, setActiveStep] = useState(1);
    const [stepCount, setStepCount] = useState(0);

    useEffect(() => {
    if (steps.length > 0) {
        setStepCount(steps.length);
    }
    else {
        setStepCount(0);
    }
    }, [steps]);

    return (
        <Stepper activeStep={activeStep} orientation="vertical" connector={<DottedConnector />}>
            {steps.map((step, index) => (
            <Step key={index}>
                <StepLabel
                StepIconComponent={({ active }) => (
                <Box sx={{position: 'relative'}}>
                    <CircleIcon isActive={active} />
                    {index !== stepCount-1 ? <Box sx={{position: 'absolute', top: 15, width: '5px', height: '15px', background: 'transparent', marginLeft: '12px', border: '0px', borderLeft: '2.3px', borderColor: (theme) => theme.palette.grey[500], borderStyle: 'dotted'}} /> : null}
                </Box>
                )}
                >
                <Grid container alignItems="center" spacing={0.5}>
                    <Grid item>
                    {/* Text Box */}
                    <Typography variant={index === activeStep ? "body2" : "body1"} sx={{color: (theme) => index === activeStep ? theme.palette.grey[600] : theme.palette.grey[500]}}>{step.package}</Typography>
                    <Typography variant={index === activeStep ? "body2" : "body1"} sx={{color: (theme) => index === activeStep ? theme.palette.grey[600] : theme.palette.grey[500]}}>{step.role}</Typography>
                    </Grid>
                </Grid>
                </StepLabel>
            </Step>
            ))}
        </Stepper>
    );
};

export default CustomVerticalStepper;
