import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppLayout from '../../components/Layout/Layout';
import './results.css';
import * as liveActions from '../../store/actions/liveActions';
import Latex from "react-latex-next";
import { Bar, BarChart, Cell, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import moment from "moment";
import styled from "styled-components";
import Flex from 'styled-flex-component';
import Equation from "../../components/Equation";
import ImageModal from "../subjective/ImageModal";
import WordCloud from "react-d3-cloud";
import { getLoggedInUser } from "../../helpers/Auth";

const COLORS = ['#295be0', '#a52a2a', '#fb3075', '#f8c152', '#6234d9'];
const RADIAN = Math.PI / 180;

const OptionFlex = styled.div`
    margin: 10px;
    padding: 10px;
    margin-left: 0;
    align-items:center;
`        

class LiveAssessmentResults extends Component{

    state = {
        question: null,
        current_ques_id: null,
        selected_option: null,
        question_count: 0,
        openImage: false,
        image_url: null
    }

    async componentDidMount(){
        if(this.props.history.location.state?.testId)
            await this.props.getLiveSurveyResults(this.props.history.location.state.testId);
        // await this.props.getLiveSurveyResults(43);       
    }

    componentDidUpdate(){
        if(this.props.questions && this.props.questions.length > 0 && !this.state.question){
            const ques = {...this.props.questions[0]};
            ques.options.forEach((op,i) => op.option_name = "Option " + (i+1));
            this.setState({question: ques});
        }
    }
        wordCloudFunction=(data)=>{ 
        if(!data || data?.length===0){
            return [{text:'',value:10}]
        }
        const finalData=[]       
        const checkingData=[]
        data.forEach((value)=>{            
            const count=data.filter(item=>item.answer_text==value.answer_text).length        
            if(!checkingData.includes(value.answer_text)){                                                
                checkingData.push(value.answer_text)
                finalData.push({text:value.answer_text,value:count*20})
            }
        })
        return finalData        
    }

    onQuestionClick = id => {
        const ques = {...this.props.questions.find(q => q.id === id)};
        if(ques){
            ques.options.forEach((op,i) => op.option_name = "Option " + (i+1));
            this.setState({question: ques});
        }
    };

    onImageClick = (url)=> this.setState({openImage: true, image_url: url});

    closeImage = () => this.setState({openImage: false, image_url: null});

    render(){
        console.log(this.props);
        console.log(this.state);
        const rotations = [-90,-45,0,45,90];
        const user = getLoggedInUser();
        
        // const student_analytics = this.props.student_analytics ? this.props.student_analytics["0"] : null;
        if(!this.props.error_loading_live_results && this.props.questions.length <= 0)
            return <div>Error Loading Survey Results</div>;
        if(this.props.error_loading_live_results || this.props.questions.length <= 0)
            return <div>Error Loading Survey Results</div>;
        else if(this.props.questions.length > 0 && this.state.question){
            return <div>
                <AppLayout></AppLayout>
                <div style={{borderBottom: '1px solid black', paddingBottom: '30px', marginTop:'110px', paddingLeft:'20px'}}>
                    <div style={{textAlign: 'left', fontSize: '24px', fontWeight: '600'}}>{this.props.test_name}</div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '10px 0', fontSize: '15px'}}>
                        <div style={{marginRight: '15px'}}>{this.props.description}</div>
                        <div style={{borderRight: '1px solid #000'}}></div>
                        <div style={{margin: '0 15px'}}>{moment(this.props.end_time).format('Do MMMM')}</div>
                        <div style={{borderRight: '1px solid #000'}}></div>
                        <div style={{margin: '0 15px'}}>Date : {moment(this.props.end_time).format('Do MMM hh:mm A')}</div>
                    </div>
                </div>
                <div className="mainDiv">
                    <div className='slideList'>
                        {this.props.questions.map((question,i) => 
                            <div key={question.id} className={this.state.question.id === question.id ? " active" : ''}
                            onClick={() => this.onQuestionClick(question.id)} style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:'15%'}}>
                                    Q {i+1})&nbsp;
                                </div>
                                <div style={{width:'85%'}}>
                                    {question.math_type === 0 ? <span dangerouslySetInnerHTML={{__html:question.name.length > 35 ? question.name.substring(0,32)+"..." : question.name}}></span>
                                    : <Latex>{question.name.length > 100 ? question.name.substring(0,97)+"..." : question.name}</Latex>}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="chartDiv">
                        <div style={{width:(this.state.question.options?.length > 0 ? '70%' : '100%')}}>
                            <div style={{display: 'flex',width: '100%',flexDirection: 'row'}}>
                                {this.state.question.media && ['mp3','wav','aac'].includes(this.state.question.question_image.split(/[#?]/)[0].split('.').pop().trim()) ? 
                                    <div style={{margin: '10px',width: '20%'}}>
                                        <audio controls style={{width:'100%'}} src={encodeURI(this.state.question.question_image)}></audio>
                                    </div>
                                : this.state.question.media ? <div style={{margin: '10px',width: '20%'}}>
                                    <img width='50px' height='50px' src={this.state.question.question_image} alt="question" style={{cursor: 'pointer'}}
                                        onClick={() => this.onImageClick(this.state.question.question_image)} />
                                    </div> : null}
                                <div style={{textAlign:'center',fontSize:'24px',width:(this.state.question.media ? '80%' : '100%'),margin: 'auto'}}>
                                    {this.state.question?.name.includes("$") ? <span dangerouslySetInnerHTML={{__html:this.state.question.name}}></span>
                                    : <Latex>{this.state.question.name}</Latex>}
                                </div>
                            </div>
                            {this.state.question.field_type === 1 && this.state.question.results_layout === 1 ? 
                                <ResponsiveContainer width="100%" height={500}>
                                    <BarChart width={150} height={500} data={this.state.question.options} 
                                        style={{marginTop:'20px'}} margin={{top: 20,right: 30,left: 20,bottom: 5}}>
                                    <XAxis dataKey="option_name"  stroke="#8884d8" />
                                    <YAxis interval={1}/>
                                    <Tooltip barSize={10} label="Selected Count"/>
                                    <Bar dataKey="selected_users" fill="#8884d8" barSize={100} />
                                    </BarChart>
                                </ResponsiveContainer> 
                                : this.state.question.field_type === 1 && this.state.question.results_layout === 2 ?                                
                                    <ResponsiveContainer width="100%" height={500}>
                                        <PieChart width={150} height={500} style={{marginTop:'20px'}} 
                                            margin={{top: 20,right: 30,left: 20,bottom: 5}}>
                                        <Pie data={this.state.question.options} dataKey="selected_users" cx="50%" cy="50%" labelLine={false}
                                             label={renderCustomizedLabel} outerRadius={150} fill="#8884d8">
                                                {this.state.question.options.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                        </Pie>
                                        <Tooltip barSize={10} label="Selected Count"/>
                                        {/* <Bar dataKey="selected_users" fill="#8884d8" barSize={100} /> */}
                                        </PieChart>
                                    </ResponsiveContainer>
                                : this.state.question.field_type === 1 && this.state.question.results_layout === 3 ?                                
                                    <ResponsiveContainer width="100%" height={500}>
                                        <PieChart width={150} height={500} style={{marginTop:'20px'}} 
                                            margin={{top: 20,right: 30,left: 20,bottom: 5}}>
                                        <Pie data={this.state.question.options} dataKey="selected_users" cx="50%" cy="50%" labelLine={false}
                                             label={renderCustomizedLabel} innerRadius={60} outerRadius={150} fill="#8884d8">
                                                {this.state.question.options.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                        </Pie>
                                        <Tooltip barSize={10}   />
                                        </PieChart>
                                    </ResponsiveContainer>
                                : this.state.question.field_type === 2 ?
                                <WordCloud                                    
                                    data={this.wordCloudFunction(this.state.question.submissions)}
                                    width={300}
                                    height={200}
                                    font="Times"
                                    fontStyle="italic"
                                    fontWeight="bold"
                                    fontSize={(word) => Math.log2(word.value)*2}
                                    rotate={() => rotations[Math.floor(Math.random() * rotations.length)]}
                                    padding={2} />
                                : <ul>
                                    {this.state.question.submissions.map(sub => 
                                        sub.answer_text.includes("</") ? <li key={sub.student_id} dangerouslySetInnerHTML={{ __html: sub.answer_text}}></li>
                                        : <li key={sub.student_id}>{sub.answer_text}</li>)}
                                </ul>
                            }
                            {this.state.question.presentation_notes ? <div style={{width: '100%',textAlign:'left'}}>
                                <h5>Presentation Notes - </h5>
                                <Equation math_type={this.state.question?.presentation_notes.includes("$") ? 1 : 0 } equation={this.state.question.presentation_notes} style={{display:'inline'}}></Equation>
                            </div> : null}
                        </div>
                        {this.state.question.options?.length > 0 ?
                            <div style={{width:'30%'}}>
                                <ul className="optionList">
                                    {this.state.question.options.map((op,i) => <li key={op.id}>
                                        <OptionFlex style ={{ alignItems: 'center', backgroundColor: this.state.question.submissions.some(sub => sub.student_id === user.user_id && sub.option_id === op.id) ? 'lightgreen' : 'white'}}> 
                                            <Flex row key={i} >
                                                <div style={{margin: 'auto',marginRight: '8px', width: '2%', marginLeft: '10px'}}>{i+1})</div>
                                                <div>
                                                    {op.media === 1 && ['mp3','wav','aac'].includes(op.option_image.split(/[#?]/)[0].split('.').pop().trim()) ? 
                                                        <div style={{margin: '10px',marginTop: '0px', width: '50px', height: '50px'}}>
                                                            <audio controls style={{width:'150px'}} src={encodeURI(op.option_image)}></audio>
                                                        </div>
                                                    : op.media === 1 ?  <div style={{margin: '10px',marginTop: '0px', width: '50px', height: '50px'}}>
                                                        <img width='50px' height='100%' src={encodeURI(op.option_image)} alt="option" style={{cursor: 'pointer'}}
                                                            onClick={() => this.onImageClick(op.option_image)}/>
                                                        </div> 
                                                    : null}
                                                </div>
                                                <div style = {{margin: 'auto',marginLeft: '10px'}}>
                                                    <Equation math_type={op?.name.includes("$") ? 1 : 0 } equation={op.name} style={{display:'inline'}}></Equation>
                                                </div>
                                            </Flex>
                                        </OptionFlex>
                                        </li>)}
                                </ul>
                            </div>
                            : null
                        }
                    </div>
                </div>
                {this.state.openImage && <ImageModal width='500px' height='70%' padding='none' style={{zIndex:12}} 
                    closeDialog={this.closeImage} open={this.state.openImage} url={this.state.image_url}>
                </ImageModal>}
            </div>
        }
        return <div></div>;
       
    };
}

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {(percent * 100) > 0 ? `${(percent * 100).toFixed(0)}%` : ''}
    </text>
  );
};

const mapStateToProps = state => {
    return {
        error_loading_live_results: state.live.error_loading_live_results,
        testId: state.live.testId,
        questions: state.live.questions,
        title: state.live.title,
        error_msg: state.live.error_msg,
        test_name: state.live.test_name,
        description: state.live.description,
        end_time: state.live.end_time,
        timetaken: state.live.timetaken,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getLiveSurveyResults : (test_id) => dispatch(liveActions.getLiveSurveyResultsAC(test_id))
    }
}

export default withRouter(
    connect(mapStateToProps,mapDispatchToProps)(LiveAssessmentResults)
);