import React,{useEffect, useState} from 'react';
import { Box, Typography, Button, Stack,Fade } from '@mui/material';
import { setIntrestedCareer } from '../../../store/actions/careerPathActions';
import { setNewNode } from '../../../store/reducers/careerPathReducer';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';

//type is used to indicate the origin - it can be from search (when student searches) or through path (when they click on a node) - this is useful for deciding whether to add the node to path or not

const IntrestedCareer = ({careerData,fit,accordian, nodes, type}) => {
const[showpopOver,setShowPopover]=useState(false);
  const dispatch=useDispatch()

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopover(true);
    }, 3000); // Set the delay to 2000 milliseconds (2 seconds)
  
    return () => clearTimeout(timer);
  }, []);

  const handleYesClick = () =>{
    if (type === 'search') {
      dispatch(setNewNode({data:careerData,fit:fit}))
    }
    // else if (type === 'path') {
    //   const isNodeInPath = nodes.find(node => node.careerId === careerData.id);
    //   if (!isNodeInPath) {
    //     dispatch(setNewNode({data:careerData,fit:fit}))
    //   }
    // }
    dispatch(setIntrestedCareer(careerData.id,1))
    setShowPopover(false)
  }
  const handleNoClick = () =>{
    dispatch(setIntrestedCareer(careerData.id,-1))
    setShowPopover(false)
  }
  return (
    <Fade in={showpopOver} timeout={1000}>
    <Box
      sx={{
        width: '370px',
        height: '75px',
        bgcolor: '#F4F6F8',
        borderRadius: '10px',
        border: '1px solid #F4F6F8',
        p: '20px',
        m:'0px 15px',
        position:'fixed',
        bottom:'15px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Typography>Tell us if you are interested in this career?</Typography>
        <Stack direction={'row'} spacing={2} sx={{ml:'15px'}}>
            <Button variant="contained" sx={{background: '#637381', minWidth:'20px', width: '45px', height:'35px', p:'10px', borderRadius:'5px', textTransform: 'none', '&:hover': {
                    background: '#454F5B',
                }}}
            onClick={handleYesClick}
            >Yes</Button>
            <Button variant="outlined" sx={{ minWidth:'20px',  width: '45px', borderRadius:'5px', textTransform: 'none',height:'35px',  p:'10px',color:'#637381', border: '1px solid #637381', '&:hover': {
                    background: 'none',
                    border: '1px solid #454F5B',
                    color:'#454F5B',
                    boxShadow:'none'
                }}}
            onClick={handleNoClick}
            >No</Button>
        </Stack>
      </Box>
    </Box>
    </Fade>
  );
};

export default IntrestedCareer;