import React from 'react'
import styled from '@emotion/styled';
import CircularProgress, {
    circularProgressClasses
} from "@mui/material/CircularProgress";
import { Typography } from '@mui/material';


const GradeWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    flexFlow: '1'
}));


const CircularProgressGrade = ({ size, value = 0 , grade }) => {
    return (
        <GradeWrapper>
            <FixedCircularProgress />
            <CircularProgress
                variant="determinate"
                disableShrink
                sx={{
                    color: (theme) =>
                        theme.palette.primary[800],
                    animationDuration: "550ms",
                    position: "absolute",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round"
                    }
                }}
                size={100}
                thickness={4}
                value={value}
            />
            <Typography component={'h6'} sx={{
                fontFamily: 'Poppins-SemiBold',
                fontSize: '20px',
                color: 'primary.800',
                position: 'absolute',
                top: '47%',
                left: '50%',
                transform: 'translate(-50%,-50%)'
            }} >{grade}</Typography>
        </GradeWrapper>

    )
}




const FixedCircularProgress = () => {
    return (
        <>
         <CircularProgress
                variant="determinate"
                sx={{
                    color: (theme) =>
                        theme.palette.primary[100]
                }}
                size={100}
                thickness={4}
                value={100}
            />
        </>
    )
}


export default CircularProgressGrade


