import * as React from 'react'
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';

class Equation3 extends React.Component {
  render() {
    let equation = this.props.equation;
    // console.log(this.props)
    let math_type = this.props.math_type;
    let font_size = this.props.font_size;
    if (!equation) return null;
    equation = equation.toString();
    return (
      <div className="equation" style={{color: this.props.answered ? '#4E54C8' : this.props.color ? this.props.color : 'black', fontWeight: this.props.bold ? '600' : 'normal', fontSize: this.props.fontSize ? this.props.fontSize : '18px'}}>
        
        {math_type == 0 &&
          <span style={{fontSize:font_size ? font_size :'18px'}} dangerouslySetInnerHTML={{ __html: equation }} />
        }
        {math_type == 1 &&
          <Latex>{equation}</Latex>
        }
        <style jsx='true' scoped>{`
          .equation{
            text-align: center !important;
            line-height:1.2;
            font-size: 18px;
            word-break: break-word;
            text-align: left;
            // font-weight: ${ 'bold' in this.props ? this.props.bold : '500'};
          }
          p{
            font-size: 18px;            
          }
        `}
        </style>
      </div>
    )
  }
}
export default Equation3;