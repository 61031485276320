import React from 'react'

const DocumentTextSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
    <path d="M18.3334 8.83317V12.9998C18.3334 17.1665 16.6667 18.8332 12.5001 18.8332H7.50008C3.33341 18.8332 1.66675 17.1665 1.66675 12.9998V7.99984C1.66675 3.83317 3.33341 2.1665 7.50008 2.1665H11.6667" stroke="#0946C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.3334 8.83317H15.0001C12.5001 8.83317 11.6667 7.99984 11.6667 5.49984V2.1665L18.3334 8.83317Z" stroke="#0946C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.83325 11.3335H10.8333" stroke="#0946C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.83325 14.6665H9.16659" stroke="#0946C4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
  )
}

export default DocumentTextSvg