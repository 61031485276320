import React from 'react';
import Drawer from '@mui/material/Drawer';

const MuiDrawer = ({ open, onClose, drawerWidth, drawerContent }) => {

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      transitionDuration={500}
      sx={{
        // width: '40%',
        '& .MuiDrawer-paper': {
          width: drawerWidth ? drawerWidth : '30%',
          backgroundColor: (theme) => theme.palette.grey[1000],
          opacity: 1,
          overflowX:'hidden',
        },
      }}
    >
      {drawerContent}
    </Drawer>
  );
};

export default MuiDrawer;
