import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import AppLayout from '../../components/Layout/Layout';
import moment from 'moment';
import Flex from 'styled-flex-component';
import styled from 'styled-components';
import Equation from '../../components/Equation';
import GaugeChart from 'react-gauge-chart';
import {getCodingTestDetailsAC, getQuestionnaireTestDetailsAC, getSubjectiveTestDetailsAC} from '../../store/actions/QuestionnaireExamDetails';

const Rectangle = () => (
    <svg height="220" width="320" fill='#fdfdfd'>
        <polygon points="320,10 320,160 160,220 10,160 10,10 320,10">
        </polygon>
        Sorry, your browser does not support inline SVG.
    </svg>
);

const RectangleWrapper = styled.div`
    // color: #FFFFFF;
    position: absolute;
    top: 100px;
    right: 3%;
    svg {
        // position: absolute;?
        display: block;
        // fill: #FFFFFF;
        width: 100%;
        height: 100%;
        filter: drop-shadow(5px 10px 20px #eee);
        polygon {
            stroke: #FFFFFF;
            // fill: #FFFFFF;
            stroke-width: 1px;
            transition: all 0.3s esae;
        }
        transition: all 0.3s esae;
    }
`

const GaugeContainer = styled.div`
    position: absolute;
    width: 286px;
    height: 213px;
    right: 20px;
    top: 100px;
    background: url("/images/timer/guage_container.svg");
    background-size: cover;
    padding: 20px;
    box-sizing: border-box;
`

class ExamDetails extends Component {
    componentDidMount = async () => {
        let test_id;
        let type;
        if (this.props.location.state) {
            test_id = this.props.location.state.test_id;
            type = this.props.location.state.type;
        }
        console.log(this.props.location)
        if (type == 'mcq') {
            await this.props.getQuestionnaireTestDetails(test_id, type);
        } else if (type == 'coding') {
            await this.props.getCodingTestDetails(test_id, type);
        } else {
            await this.props.getSubjectiveTestDetails(test_id, type);

        }
    }

    render () {
        console.log(this.props);
        if (this.props.loading) {
            return <div>Loading....</div>
        }
        if (this.props.errorLoadingTestDetails) {
           return <div>Error loading Test Details Please try again after some time </div>
        }
        console.log(this.props);
        let option_numbers = {0: 'a', 1: 'b', 2: 'c', 3: 'd', 4: 'e'};
        let timelimit = this.props.timelimit;
        let hours = Math.floor(timelimit / (60 * 60));
        let seconds = (timelimit - hours * 60 * 60);
        let minutes;
        if (seconds > 60) {
            minutes = Math.floor(seconds/60);
            seconds = seconds - (minutes * 60);
        }
        let timelimit_modified = minutes && hours && seconds ? hours + 'hrs ' + minutes + 'mins ' + seconds + 'secs' : minutes && !hours && seconds ? minutes + 'mins ' + seconds + 'secs' : minutes  && !hours && !seconds ? minutes + 'mins' : hours && seconds && !minutes ? hours + 'hrs ' + seconds + 'secs' : !minutes && hours && !seconds ? hours + 'hrs' : !minutes && !hours && seconds ? seconds + 'secs' : hours && minutes && !seconds ? hours + 'hrs ' + minutes + 'mins' : '0';
        let marks = 0;
        let sections = this.props.sections;
        
        return (
            <div>
                <AppLayout></AppLayout>
                    <div style={{boxShadow: '5px 10px 20px #eee', borderRadius: '11px', margin: 'auto', marginTop: '116px', width: '90%', padding: '20px', backgroundColor: 'white'}}>

                        <div style={{borderBottom: '1px solid black', paddingBottom: '30px'}}>
                            <div style={{textAlign: 'left', fontSize: '24px', fontWeight: '600'}}>{this.props.name}</div>
                            <div style={{display: 'flex', flexDirection: 'row', margin: '10px 0', fontSize: '15px'}}>
                                <div style={{marginRight: '15px'}}>{this.props.type == 'mcq' ? 'Objective' : this.props.type == 'coding' ? 'Coding Challenge' : 'Subjective'}</div>
                                <div style={{borderRight: '1px solid #000'}}></div>
                                <div style={{margin: '0 15px'}}>{moment(this.props.created_at).format('Do MMMM')}</div>
                                <div style={{borderRight: '1px solid #000'}}></div>
                                {/* <div style={{margin: '0 15px'}}>Start Date : {moment(test_details.start_time).format('Do MMM hh:mm A')}</div>
                                <div style={{borderRight: '1px solid #000'}}></div> */}
                                <div style={{margin: '0 15px'}}>Expiry Date : {moment(this.props.doe).format('Do MMM hh:mm A')}</div>
                                <div style={{borderRight: '1px solid #000'}}></div>
                                <div style={{marginLeft: '15px'}}>
                                    <img style={{margin: 'auto'}} src='/images/test_timer.png'></img>
                                </div>
                                <div style={{margin: '0 15px'}}>{timelimit_modified}</div>
                            </div>
                            <div>
                                <GaugeContainer>
                                    <div style={{ height: 100, overflow: 'hidden' }}>

                                        <GaugeChart id="gauge-chart5"
                                            nrOfLevels={100}
                                            arcsLength={[0.1, 0.25, 0.25, 0.25, .15]}
                                            colors={['#D76D6B', '#FFA287', '#FFC200', '#23DD75', '#00D0FF']}
                                            percent={marks}
                                            textColor={'#6852CB'}
                                            arcPadding={0.01}
                                            cornerRadius={5}
                                            formatTextValue={r => Math.round(r * this.props.questions.length/100) + `/${this.props.questions.length}`}
                                            style={{ height: 150, color: '#000', fontWeight: 700 }}
                                        />
                                    </div>
                                    <p style={{ textAlign: 'center', margin: 0, fontSize: 14, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Your Marks</p>
                                    <p style={{ textAlign: 'center', margin: 0 }}>You haven’t  attempted the test</p>
                                </GaugeContainer>
                            </div>
                        </div>
                        {sections && sections.length != 0 ? <div style={{marginTop: '70px'}}>
                            <div style={{textAlign: 'left', fontSize: '18px', fontWeight: '600', margin: '20px 0'}}>Sections</div>
                            <div  style={{boxShadow: '5px 10px 20px #eee', borderRadius: '11px', padding: '10px', marginTop: '20px'}}>
                                {sections.map((sec)=> {
                                    return <div style={{textAlign: 'left', display: 'flex', flexDirection: 'row', margin: '15px'}}>
                                        <div style={{width: '2px',height: '2px',marginTop: '10px', marginRight: '20px', backgroundColor: 'black', borderRadius: '50%', border: '1px solid black'}}></div>
                                        <div>
                                            <div><span style={{fontWeight: '600'}}>Name </span> : {sec.name}</div>
                                            <div><span style={{fontWeight: '600'}}>Marks</span> : {sec.marks}</div>
                                        </div>
                                    </div>
                                })}
                            </div>   
                        </div> : null}
                        <div style={{marginTop: '40px'}}>
                            <div style={{textAlign: 'left', fontSize: '16px', fontWeight: '600', margin: '20px 0'}}>Exam Questions</div>
                            {this.props.type == 'mcq' && this.props.questions.map((que, qid)=> {
                                return <div>
                                    <Flex row style={{marginLeft: '15px'}}>
                                        <div style={{fontWeight: 'bold', fontSize: '16px', marginRight: '8px'}}>
                                            {qid + 1}.
                                        </div>
                                        <Flex column style={{marginLeft: '10px'}}>

                                            <Flex column style={{marginBottom:'20px', textAlign: 'left'}}>
                                                <Equation blooms={que.blooms_level} math_type={que.math_type} bold equation={que.name}></Equation>
                                                {que.question_img && que.question_img != ' ' ? 
                                                    <div style={{width: '324px', height: '216px', margin: '10px'}}>
                                                        <img width='324px' height='216px' src={que.question_img}></img>
                                                    </div>: null}
                                            </Flex>
                                        
                                            <div>
                                            {que.test_questions_options.map(
                                                ({ id, name, media, option_img, is_answer, selected_option_id }, i) => {
                                                let is_selected = true ? selected_option_id == 1 : false;
                                                let is_correct_selected = is_selected && is_answer == 1;
                                                let is_incorrect = is_selected && is_answer == 0;
                                                let is_correct = true ? is_answer == 1 : false;
                                                console.log(que.section_id);
                                                console.log(sections.findIndex((sec, index)=>sec.id == Number(que.section_id)))
                                                console.log(sections);
                                                return (
                                                    <Flex row style ={{margin: '10px',marginBottom: '30px', alignItems:'center', color: is_selected ? '#4E54C8' : null, fontWeight: is_selected ? '600' : '500'}}> 
                                                        {is_correct_selected || is_correct ? <div style={{width: '24px', height: '24px', marginRight: '10px', marginLeft: '-34px'}}>
                                                            <img src='/images/Correct_answer.svg'></img>
                                                        </div>: null}
                                                        {is_incorrect ? <div style={{width: '24px', height: '24px', marginRight: '10px', marginLeft: '-34px'}}>
                                                            <img src='/images/Wrong_answer.svg'></img>
                                                        </div>: null}

                                                        <Flex row key={i} >
                                                            <div>{option_numbers[i]})</div>
                                                            <div>
                                                                {media === 1 ? <div style={{margin: '10px',marginTop: '0px', width: '150px', height: '100px'}}>
                                                                    <img width=  '150px' height = '100px' src={option_img} alt="" />
                                                                    </div> : null}
                                                            </div>
                                                            <div style = {{margin: 'auto',marginLeft: '10px'}}>
                                                                <Equation bold={is_selected} answered={is_selected} math_type={que.math_type} equation={name}></Equation>
                                                            </div>
                                                        </Flex>
                                                    </Flex>)
                                                }
                                                )}
                                            </div>
                                        
                                    </Flex>
                                    
                                </Flex>
                                <div style={{marginLeft: '30px'}}>
                                    <div style={{borderBottom: '1px solid #DDDDDD', margin: '10px 0', width: '100%'}}></div>
                                    <div style={{textAlign: 'left', fontSize:'16px', fontWeight: '400'}}>{que.section_id ? `Section : ${sections[sections.findIndex((sec, index)=>sec.id == Number(que.section_id))].name}   | ` : '' } &nbsp;&nbsp; Max Score : { sections && sections.length > 0 ?  1 * sections[sections.findIndex((sec, index)=>sec.id == que.section_id)].marks : 1} &nbsp;&nbsp;</div>
                                </div>
                                <div style={{borderBottom: '1px solid #000000', margin: '20px 0', width: '100%'}}></div>
                            </div>
                            })}

                            {this.props.type == 'coding' && this.props.problems.length != 0 ?  this.props.problems.map((prob, qid)=> {
                                return <div><Flex row style={{marginLeft: '15px'}}>
                                        <div style={{fontWeight: 'bold', fontSize: '16px', marginRight: '8px'}}>
                                            {qid + 1}.
                                        </div>
                                        <Flex column style={{marginLeft: '10px'}}>
                                            <Flex column style={{marginBottom:'20px', textAlign: 'left'}}>
                                                {/* <Equation blooms={que.blooms_level} math_type={que.math_type} bold equation={que.name}></Equation>
                                                {que.question_img && que.question_img != ' ' ? 
                                                    <div style={{width: '324px', height: '216px', margin: '10px'}}>
                                                        <img width='324px' height='216px' src={que.question_img}></img>
                                                    </div>: null} */}
                                                    <div>{prob.question}</div>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <div style={{marginLeft: '30px'}}>
                                        <div style={{borderBottom: '1px solid #DDDDDD', margin: '10px 0', width: '100%'}}></div>
                                        <div style={{textAlign: 'left', fontSize:'16px', fontWeight: '400'}}> Max Score : 1 &nbsp;&nbsp;</div>
                                    </div>
                                    <div style={{borderBottom: '1px solid #000000', margin: '20px 0', width: '100%'}}></div>
                                </div>}) : this.props.type == 'coding' && this.props.problems.length == 0 ? 
                                    <div>No Problems in this coding test</div>
                                : null}
                            {this.props.type === 'subjective' && this.props.questions.map((que, qid)=> {
                                return <div>
                                    <Flex row style={{marginLeft: '15px'}}>
                                        <div style={{fontWeight: 'bold', fontSize: '16px', marginRight: '8px'}}>
                                            {qid + 1}.
                                        </div>
                                        <Flex column style={{marginLeft: '10px'}}>

                                            <Flex column style={{marginBottom:'20px', textAlign: 'left'}}>
                                                {que.question ? <Equation blooms={que.blooms_level} bold equation={que.question} math_type="0"></Equation> : null}
                                                {que.question_img && que.question_img != ' ' ? 
                                                    <div style={{width: '324px', height: '216px', margin: '10px'}}>
                                                        <img width='324px' height='216px' src={que.question_img}></img>
                                                    </div>: null}
                                            </Flex>
                                        
                                            <div>
                                            </div>
                                        
                                    </Flex>
                                    
                                </Flex>
                                <div style={{marginLeft: '30px'}}>
                                    <div style={{borderBottom: '1px solid #DDDDDD', margin: '10px 0', width: '100%'}}></div>
                                    <div style={{textAlign: 'left', fontSize:'16px', fontWeight: '400'}}>{que.section_id ? `Section : ${sections[sections.findIndex((sec, index)=>sec.id == Number(que.section_id))].name}   | ` : '' } &nbsp;&nbsp; Max Score : {que.marks ? que.marks : 1} &nbsp;&nbsp;</div>
                                </div>
                                <div style={{borderBottom: '1px solid #000000', margin: '20px 0', width: '100%'}}></div>
                            </div>
                            })}
                        </div>
                    </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.queDetails.loading,
        errorLoadingTestDetails: state.queDetails.error_loading_test,
        questions: state.queDetails.questions,
        test_id: state.queDetails.practice_id,
        timelimit: state.queDetails.timelimit,
        name: state.queDetails.name,
        description: state.queDetails.description,
        sections: state.queDetails.sections,
        doe: state.queDetails.doe,
        created_at: state.queDetails.created_at,
        type: state.queDetails.type,
        problems: state.queDetails.problems
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getQuestionnaireTestDetails: (test_id, type)=> dispatch(getQuestionnaireTestDetailsAC(test_id, type)),
        getCodingTestDetails: (test_id, type)=> dispatch(getCodingTestDetailsAC(test_id, type)),
        getSubjectiveTestDetails: (test_id, type)=> dispatch(getSubjectiveTestDetailsAC(test_id, type)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExamDetails));