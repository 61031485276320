import { Box, Stack, Typography } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import TimerSvg from './../../../../assets/remedial/svg/timer.svg';
import WarningSvg from './../../../../assets/remedial/svg/warning.svg';
import RemedialCard from '../RemedialCard/RemedialCard';

const TimerCard = ({ timeLeft, width = '100%', height = '100%' }) => {
    const Ref = useRef(null);
    const [timer, setTimer] = useState('00 : 00 : 00');

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return { total, hours, minutes, seconds };
    };

    const startTimer = (e) => {
        let { total, hours, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ' : ' +
                (minutes > 9 ? minutes : '0' + minutes) + ' : ' +
                (seconds > 9 ? seconds : '0' + seconds)
            );
        }
    };

    const clearTimer = (e) => {
        setTimer('00 : 00 : 00');

        if (Ref.current) clearInterval(Ref.current);

        const id = setInterval(() => {
            startTimer(e);
        }, 1000);

        Ref.current = id;
    };

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + timeLeft);
        return deadline;
    };

    useEffect(() => {
        // Clear the interval when the component unmounts
        return () => {
            if (Ref.current) {
                clearInterval(Ref.current);
            }
        };
    }, []);

    useEffect(() => {
        clearTimer(getDeadTime());
    }, [timeLeft]); // Update the timer when timeLeft changes

    const onClickReset = () => {
        clearTimer(getDeadTime());
    };

    return (
        <RemedialCard width={width} height={height}>
            <Stack gap={'16px'}>
                <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
                    <img src={timeLeft ? TimerSvg : WarningSvg} alt='timer' width={20} height={20} />
                    <Typography component={'p'} sx={{
                        fontFamily: 'Poppins-SemiBold',
                        fontSize: '16px',
                        color: 'grey.900'
                    }}>Remedial Timer</Typography>
                </Stack>
                <Box sx={{
                    padding: '16px 10px',
                    backgroundColor: 'grey.100',
                    borderRadius: '4px'
                }}>
                    <Typography component={'p'} sx={{
                        fontFamily: 'Poppins-SemiBold',
                        fontSize: '22px',
                        color: timeLeft ? 'grey.900' : theme => theme.palette.externalerror[200]
                    }}>{timer}</Typography>
                </Box>
            </Stack>
        </RemedialCard>
    );
};

export default TimerCard;
