import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";

const Circle = ({color, size, isFilled, hoverColor}) => {

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Box 
                    sx={{
                        borderRadius: '50%',
                        border: isFilled ? `3px solid ${hoverColor}` : `2px solid ${color}`,
                        height: size,
                        width: size,
                        background: isFilled ? color : '#FFF',
                        cursor: 'pointer',
                        transition: 'background 0.2s ease',
                        '&:hover': {
                            background: isFilled ? '' : hoverColor,
                        }
                    }}
                />
            </Box>
        </>
    )
}

export default Circle;