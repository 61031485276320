import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import { Handle, Position } from "reactflow";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { alpha, styled } from '@mui/material/styles';
import { useSpring, animated } from '@react-spring/web';
import { Collapse,Grow } from '@mui/material';
import CustomCircularProgressBar from '../../../components/common/CustomCircularProgressBar';
import MuiCustomLinearProgress from '../../../components/common/MuiCustomLinearProgress';
import TreeLabel from '../TreeLabel';
import ExpandedCustomTriangleIcon from '../../../assets/common/ExpandedCustomTriangleIcon';

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: props.in ? 1 : 0 },
    // to: {
    //   opacity: props.in ? 1 : 0,
    //   transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    // },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const CustomTreeItem = React.forwardRef((props, ref) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
));

const TreeOuterItem = styled(CustomTreeItem)(({ theme }) => ({
    // marginBottom: '16px',
    margin: '0px 25px 16px 25px',
    padding: '6.5px 11px',
    borderRadius: '6.25px',
    color: theme.palette.grey[900],
    background: theme.palette.grey[100],
    '& .customTreeOuterItem-content': {
        backgroundColor: `${theme.palette.grey[100]} !important`,
        padding: '0px 6px',
    },
    '& .customTreeOuterItem-content .Mui-TreeItem-label': {
      paddingLeft: '0px',
  },
}));

const TreeInnerItem = styled(TreeItem)(({ theme }) => ({
    pointerEvents:'none',
    color: theme.palette.grey[900],
    margin: '7.5px 0px',
    // background: theme.palette.grey[200],
    '& .customTreeInnerItem-content': {
      backgroundColor: `${theme.palette.grey[100]} !important`,
      padding: '0px',
  },
}));

let count = 0;


export default function CustomizedTreeView({skills}) {
    return (
    
    <Box sx={{ minHeight: 180, flexGrow: 1, maxWidth: 400,mb:'6rem' }}>
    <TreeView
        aria-label="customized"
        defaultCollapseIcon={<img src={'/images/down_arrow.svg'} alt='close' />}
        defaultExpandIcon={<ArrowRightIcon sx={{color: (theme) => theme.palette.grey[500],width:'24px',height:'21px'}} />}
        // sx={{
        //   '& .MuiTreeItem-group': {
        //     marginTop: '-4px'
        //   },
        // }}
    >
      
        {skills.map((skill, index) => {
          const nodeId = `node-${index}`;
            count += 1;
            return (
              <>
                <TreeOuterItem key={nodeId} nodeId={nodeId} label={<TreeLabel title={skill.name} percent={(skill.fit)} />} classes={{content: 'customTreeOuterItem-content'}}  >
                    {skill?.sub_skill && skill.sub_skill.map((sub_s, innerIndex) => {
                       const innerNodeId = `inner-node-${index}-${innerIndex}`;
                        count += 1
                        return (
                        <TreeInnerItem key={innerNodeId} nodeId={innerNodeId} label={sub_s} classes={{content: 'customTreeInnerItem-content'}} />
                        )
                    })}
                </TreeOuterItem>
                </>
            )
        })}
      </TreeView>
    </Box>
   
  );
}




// import * as React from 'react';
// import Box from '@mui/material/Box';
// import { useSpring, animated } from '@react-spring/web';
// import SvgIcon from '@mui/material/SvgIcon';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import Collapse from '@mui/material/Collapse';
// import { alpha, styled } from '@mui/material/styles';
// import { TreeView } from '@mui/x-tree-view/TreeView';
// import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';

// function TransitionComponent(props) {
//   const style = useSpring({
//     to: {
//       opacity: props.in ? 1 : 0,
//       transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
//     },
//   });

//   return (
//     <animated.div style={style}>
//       <Collapse {...props} />
//     </animated.div>
//   );
// }

// const CustomTreeItem = React.forwardRef((props, ref) => (
//   <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
// ));

// const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({

//     // margin: '10px',
//     // padding: '10px',
//     // background: 'red',
//     // borderRadius: '6.25px',
//     [`& .${treeItemClasses.iconContainer}`]: {
//         '& .close': {
//         opacity: 0.3,
//         },
//     },

//     '&. MuiCollapse-wrapperInner': {
//         display: 'flex',
//         flexDirection: 'column',
//         gap: '5px',
//         margin: '8px 0px',
//     }
//     // [`& .${treeItemClasses.group}`]: {
//     //     marginLeft: 15,
//     //     paddingLeft: 18,
//     // },
// }));

// export default function CustomizedTreeView() {
//   return (
//     <Box sx={{ minHeight: 270, flexGrow: 1, maxWidth: 300 }}>
//       <TreeView
//         aria-label="customized"
//         defaultExpanded={['1']}
//         defaultCollapseIcon={<ExpandMoreIcon />}
//         defaultExpandIcon={<ChevronRightIcon />}
//         sx={{ 
//             overflowX: 'hidden',
//             display: 'flex',
//             flexDirection: 'column',
//             gap: '16px',
//         }}
//       >
//         <StyledTreeItem nodeId="1" label="Natural Language Processing">
//           <StyledTreeItem nodeId="2" label="Tokenization" />
//           {/* <StyledTreeItem nodeId="3" label="Subtree with children">
//             <StyledTreeItem nodeId="6" label="Hello" />
//             <StyledTreeItem nodeId="7" label="Sub-subtree with children">
//               <StyledTreeItem nodeId="9" label="Child 1" />
//               <StyledTreeItem nodeId="10" label="Child 2" />
//               <StyledTreeItem nodeId="11" label="Child 3" />
//             </StyledTreeItem>
//             <StyledTreeItem nodeId="8" label="Hello" />
//           </StyledTreeItem> */}
//           <StyledTreeItem nodeId="3" label="Stopword Removal" />
//           <StyledTreeItem nodeId="4" label="Sequence Labeling" />
//           <StyledTreeItem nodeId="5" label="Information Retrieval" />
//         </StyledTreeItem>
//         <StyledTreeItem nodeId="6" label="Applied Mathematics">
//             <StyledTreeItem nodeId="7" label="Calculus" />
//             <StyledTreeItem nodeId="8" label="Differentiation" />
//             <StyledTreeItem nodeId="9" label="Integration" />
//         </StyledTreeItem>
//       </TreeView>
//     </Box>
//   );
// }