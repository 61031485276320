import axios from "../../interceptor";
import { triggerNotification } from "./commonActions";

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
const environment = process.env.REACT_APP_ENVIRONEMNT;
export function setGlobalToken() {
  try {
    let token = localStorage.getItem("token");
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } catch (error) {
    console.log(error);
  }
}

const getAccessToken = () => {
  let token = localStorage.getItem("token");
  return token;
};

export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const refreshTokenA = (err) => {
  return {
    type: REFRESH_TOKEN,
    error: err,
  };
};

export const refreshTokenAC = (code) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const refresh_token = localStorage.getItem("refresh_token");
    http.defaults.headers.common["Authorization"] = `Bearer ${refresh_token}`;
    http
      .get(`${BASE_API_URL}/auth/refresh`)
      .then((refres) => {
        localStorage.setItem("token", refres.data.token);
        setGlobalToken();
        dispatch(refreshTokenA(false));
      })
      .catch((err) => {
        dispatch(refreshTokenA(true));
        console.log(err);
      });
  };
};

export const LOADING_QUESTIONS = "LOADING_QUESTIONS";
export const GET_ALL_PERSONALITY_QUESTIONS = "GET_ALL_PERSONALITY_QUESTIONS";
export const GET_PERSONALITY_QUESTION_AT_TRIGGER = "GET_PERSONALITY_QUESTION_AT_TRIGGER";
export const SUBMIT_PERSONALITY_QUESTION = "SUBMIT_PERSONALITY_QUESTION";
export const LEAVING_PERSONALITY_PAGE = "LEAVING_PERSONALITY_PAGE";

export const loadingQuestions = () => {
  return {
      type: LOADING_QUESTIONS,
  };
};

export const getAllPersonalityQuestionsA = (res, err) => {
    return {
        type: GET_ALL_PERSONALITY_QUESTIONS,
        response: res,
        error: err,
    };
};

export const getPersonalityQuestionAtTriggerA = (res, err) => {
  return {
      type: GET_PERSONALITY_QUESTION_AT_TRIGGER,
      response: res,
      error: err,
  };
};

export const submitPersonalityQuestionA = (res, question_id, option_id, err) => {
  return {
      type: SUBMIT_PERSONALITY_QUESTION,
      response: res,
      question_id: question_id,
      option_id: option_id,
      error: err,
  };
};

export const leavingPersonalityPage = () => {
  return {
    type: LEAVING_PERSONALITY_PAGE,
  }
}

export const getAllPersonalityQuestionsAC = () => {
  setGlobalToken();
  return async (dispatch, getState) =>{
  dispatch(loadingQuestions());
  http.get(
    `https://z0c0fzo9mj.execute-api.ap-south-1.amazonaws.com/${environment}/personality_assessment/?send_all=1`
  ).then((res) => {
    console.log(res);
    if (res.data.statusCode === 200) {
      dispatch(getAllPersonalityQuestionsA(res.data.body, false));
      // return res.data;
    } else if (
      res.data === "Signature expired. Please log in again."
    ) {
      const refresh_token = localStorage.getItem("refresh_token");
      http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${refresh_token}`;
      http
        .get(`${BASE_API_URL}/auth/refresh`)
        .then((refres) => {
          localStorage.setItem("token", refres.data.token);
          setGlobalToken();
          http.get(
            `https://z0c0fzo9mj.execute-api.ap-south-1.amazonaws.com/${environment}/personality_assessment/?send_all=1`
          ).then((res) => {
            console.log("data= ", res.data);
            if (res.data.statusCode === 200) {
              dispatch(getAllPersonalityQuestionsA(res.data.body, false));
              // return res.data;
            } else if (
              res.data === "Signature expired. Please log in again."
            ) {
              dispatch(getAllPersonalityQuestionsA(null, true));
              // return res;
            }
          })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  })
    .catch((err) => {
      console.log(err);
    });
  }
};

export const getPersonalityQuestionAtTriggerAC = (trigger_id) => {
  setGlobalToken();
  return async (dispatch, getState) =>{
  http.get(
    `https://z0c0fzo9mj.execute-api.ap-south-1.amazonaws.com/${environment}/personality_assessment/?trigger_id=${trigger_id}`
  ).then((res) => {
    console.log(res);
    if (res.data.statusCode === 200) {
      if (res.data.body) {
        dispatch(getPersonalityQuestionAtTriggerA(res.data.body, false));
        setTimeout(() => {
          dispatch(triggerNotification("Personality", trigger_id));
        }, 3000);
      }
      // return res.data;
    } else if (
      res.data === "Signature expired. Please log in again."
    ) {
      const refresh_token = localStorage.getItem("refresh_token");
      http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${refresh_token}`;
      http
        .get(`${BASE_API_URL}/auth/refresh`)
        .then((refres) => {
          localStorage.setItem("token", refres.data.token);
          setGlobalToken();
          http.get(
            `https://z0c0fzo9mj.execute-api.ap-south-1.amazonaws.com/${environment}/personality_assessment/?trigger_id=${trigger_id}`
          ).then((res) => {
            console.log("data= ", res.data);
            if (res.data.statusCode === 200) {
              if (res.data.body) {
                dispatch(getPersonalityQuestionAtTriggerA(res.data.body, false));
                setTimeout(() => {
                  dispatch(triggerNotification("Personality", trigger_id));
                }, 3000);
              }
              // return res.data;
            } else if (
              res.data === "Signature expired. Please log in again."
            ) {
              dispatch(getPersonalityQuestionAtTriggerA(null, true));
              // return res;
            }
          })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  })
    .catch((err) => {
      console.log(err);
    });
  }
};

export const submitPersonalityQuestionAC = (trigger_id, question_id, option_id) => {
  setGlobalToken();
  return async (dispatch, getState) =>{
  http.get(
    `https://z0c0fzo9mj.execute-api.ap-south-1.amazonaws.com/${environment}/personality_assessment/?trigger_id=${trigger_id}&question_id=${question_id}&option_id=${option_id}`
  ).then((res) => {
    console.log(res);
    if (res.data.statusCode === 200) {
      dispatch(submitPersonalityQuestionA(res.data.body, question_id, option_id, false));
      // return res.data;
    } else if (
      res.data === "Signature expired. Please log in again."
    ) {
      const refresh_token = localStorage.getItem("refresh_token");
      http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${refresh_token}`;
      http
        .get(`${BASE_API_URL}/auth/refresh`)
        .then((refres) => {
          localStorage.setItem("token", refres.data.token);
          setGlobalToken();
          http.get(
            `https://z0c0fzo9mj.execute-api.ap-south-1.amazonaws.com/${environment}/personality_assessment/?trigger_id=${trigger_id}&question_id=${question_id}&option_id=${option_id}`
          ).then((res) => {
            console.log("data= ", res.data);
            if (res.data.statusCode === 200) {
              dispatch(submitPersonalityQuestionA(res.data.body, question_id, option_id, false));
              // return res.data;
            } else if (
              res.data === "Signature expired. Please log in again."
            ) {
              dispatch(submitPersonalityQuestionA(null, question_id, option_id, true));
              // return res;
            }
          })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  })
    .catch((err) => {
      console.log(err);
    });
  }
};


// export const submitPersonalityQuestionAC = (trigger_id, question_id, option_id) => {
//   setGlobalToken();
//   let form = {
//     "question_id" : question_id,
//     "trigger_id": trigger_id,
//     "option_id": option_id,
//   }
//   return async (dispatch, getState) =>{
//   http.post(
//     `https://z0c0fzo9mj.execute-api.ap-south-1.amazonaws.com/test/personality_assessment`, form)
//     .then((res) => {
//       console.log("data= ", res.data);
//       if (res.data.statusCode === 200) {
//           dispatch(submitPersonalityQuestionA(res.data.body, false))
//         return res.data;
//       } else if (
//         res.data === "Signature expired. Please log in again."
//       ) {
//         const refresh_token = localStorage.getItem("refresh_token");
//         http.defaults.headers.common[
//           "Authorization"
//         ] = `Bearer ${refresh_token}`;
//         http
//           .get(`${BASE_API_URL}/auth/refresh`)
//           .then((refres) => {
//             localStorage.setItem("token", refres.data.token);
//             setGlobalToken();
//             http.post(
//               `https://z0c0fzo9mj.execute-api.ap-south-1.amazonaws.com/test/personality_assessment`, form)
//               .then((res) => {
//                 console.log("data= ", res.data);
//                 if (res.data.statusCode === 200) {
//                     dispatch(submitPersonalityQuestionA(res.data.body, false))
//                 } else if (
//                   res.data === "Signature expired. Please log in again."
//                 ) {
//                   dispatch(submitPersonalityQuestionA(null,true))
//                 }
//               })
//               .catch((err) => {
//                 console.log(err);
//               });
//           })
//           .catch((err) => {
//             console.log(err);
//           });
//       }
//     })
//     .catch((err) => {
//       console.log(err);
//     });
//   }
// };