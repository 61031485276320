import * as React from 'react';
import { Error } from '../../assets/codingModule/Error';
import { Box,Button } from '@mui/material';
export default function ErrorPage(props) {
    console.log(props)
  return (
    <>
    <Box justifyContent={'center'} sx={{display:'flex'}}>
   <Error backgroundColor={props?.backgroundColor ? props.backgroundColor : '#F4F6F8'}/>
   </Box>
    {props.resetErrorBoundary && (
        <Box justifyContent={'center'} sx={{display:'flex'}}>
            <Button variant='contained' color='primary' onClick={props.resetErrorBoundary}>
              Try Again!
            </Button>
        </Box>
    )}
    </>
  );
}
