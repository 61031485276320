import React, { useEffect, useState } from 'react'
import RemedialCard from '../RemedialCard/RemedialCard'
import TestSheet from '../TestSheet/TestSheet'
import { Box, Stack } from '@mui/material'
import RedmedialButton from '../RemedialButtons/RedmedialButton'
import QuestionType1 from '../RemedialQuestion/QuestionType1/QuestionType1'
import { useRemedialContext } from '../../hooks/useRemedial'
import { PostDataToApi, fetchDataFromApi } from '../../../../helpers/Api'
import { updateBannerApi } from '../../../../helpers/updateBanner'
import { useParams } from 'react-router-dom'
import BonusPointsModal1 from '../modals/BonusPointsModal1'





const RemedialTestSection = ({ data, questionnaireId }) => {
    const [activeQuestion, setActiveQuestion] = useState(0);
    const [showRewardModal, setshowRewardModal] = useState(false);
    const [formAnswers, setformAnswers] = useState([]);
    const { testId } = useParams();

    const {setQuestions, questions, setQuestionsStatus, setBannerData, bannerData, setRevisedResultPageData, revisedResultPageData, studyProgress } = useRemedialContext();


    const handleUpdateBanner = async () => {
        // updateBannerIndex(4);
        const res = await updateBannerApi(testId, bannerData.stages[4].id);
        const { status } = await res.data
        if (status === 200) {
            const { data } = await fetchDataFromApi(`/questionnaire/v2/getRemedialPathStages?test_id=${testId}`);

            setBannerData(data)
        }
    }

    const postData = async () => {
        const formData = new FormData()
        formData.append('questionnaire_remedial_path_id', questionnaireId)
        formData.append('answers', JSON.stringify(formAnswers))
        // const data = await PostDataToApi('/questionnaire/submitRemedialPathTestMock', formData)
        const data = await PostDataToApi('/questionnaire/submitRemedialPathTest', formData)
        
        if (data?.status !== 200) {
            throw data;
        } else {
            const revisedResult = await fetchDataFromApi(`/questionnaire/remedialRevisedResult?questionnaire_id=${testId}`)
            setRevisedResultPageData(revisedResult.data[0])
            if (revisedResult.status === 200) {
                setshowRewardModal(false)
                handleUpdateBanner();
            }

        }
        return data;
    };



    //handles the question sheet clicks
    const handleQuestionClick = (index) => {
        setActiveQuestion(index)
    }

    // handles the next button
    const handleNextClick = () => {
        if (activeQuestion < data.length - 1) {
            if (data[activeQuestion].newSelectedOption) {
                setActiveQuestion(prev => prev + 1)
            }
        } else {
            postData()

        }
    }

    //handles the skip button
    const handleSkipClick = () => {
        if (activeQuestion < data.length - 1) {
            if (data) {
                const array = questions;
                const newArray = [];
                array.forEach((item, index) => {
                    let value = index === activeQuestion ? { ...item, newSelectedOption: '' } : item
                    newArray.push(value)
                })
                setQuestions(newArray)
            }
            setActiveQuestion(prev => prev + 1)
        } else {
            setshowRewardModal(true)
        }

    }

    //recording the QuestionsStatus
    useEffect(() => {
        if (data && activeQuestion < data.length) {
            let visitedQuestions = 0;
            let skippedQuestions = 0;
            let remainingQuestions = 0;
            let answeredQuestion = 0;
            data.forEach((item, index) => {
                if (item.isVisited) {
                    visitedQuestions = visitedQuestions + 1;
                    if (activeQuestion !== index) {
                        if (!item.newSelectedOption) {
                            skippedQuestions = skippedQuestions + 1;
                        }
                    }
                    if (item.newSelectedOption) {
                        answeredQuestion = answeredQuestion + 1
                    }
                }
                // if (!item.isVisited) {
                //     remainingQuestions = remainingQuestions + 1;
                // }
            })
            remainingQuestions = data.length-answeredQuestion-skippedQuestions;
            // remainingQuestions = data.length-answeredQuestion;
            setQuestionsStatus({
                visitedQuestions,
                skippedQuestions,
                remainingQuestions,
                answeredQuestion,
                totalQuestion: data.length
            })
        }

    }, [activeQuestion, data])


    // recording the question visit
    useEffect(() => {
        if (data) {
            const array = questions;
            const newArray = [];
            array.forEach((item, index) => {
                let value = index === activeQuestion ? { ...item, isVisited: true } : item
                newArray.push(value)
            })
            setQuestions(newArray)
        }

    }, [activeQuestion])

    //records the answer
    useEffect(() => {
        if (data) {
            const array = questions;
            const answerArray = [];

            array.forEach((item, index) => {
                let value = index === activeQuestion ? { question_id: data[activeQuestion].id, option_id: data[activeQuestion].newSelectedOption } : { question_id: data[index].id, option_id: data[index].newSelectedOption }
                answerArray.push(value)
            })

            setformAnswers(answerArray)
        }

    }, [data, activeQuestion])

    // console.log(data, activeQuestion, 'adgffgdfgfgds')

    return (
        <>
            <RemedialCard>
                <Stack gap={'32px'}>
                    <TestSheet onQuestionClick={handleQuestionClick} data={data} columnWidth={46} boxPadding={12} addQuestionMark onQuestion={activeQuestion} />

                    {data?.map((item, index) => {
                        if (index === activeQuestion) {
                            return (
                                <QuestionType1
                                    key={item.id}
                                    data={item}
                                    questionId={item.id}
                                    index={index}
                                    newSelection={item.newSelectedOption}
                                />
                            )
                        }
                    })}

                    {data && <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box sx={{ width: '200px', visibility: activeQuestion === data.length - 1 ? 'hidden' : 'visible' }}>
                            <RedmedialButton fullWidth variant='outlined' color='primary.contrastText' onClick={() => { handleSkipClick() }} textColor='primary.800'>Skip</RedmedialButton>
                        </Box>
                        {activeQuestion === data.length - 1 ?
                            <Box sx={{ width: '200px' }}>
                                <RedmedialButton fullWidth color='primary.800' textColor='primary.contrastText' onClick={() => { handleNextClick() }}>Submit</RedmedialButton>
                            </Box> :
                            <Box sx={{ width: '200px' }}>
                                <RedmedialButton disable={!data[activeQuestion].newSelectedOption} fullWidth color='primary.800' textColor='primary.contrastText' onClick={() => { handleNextClick() }}>Next</RedmedialButton>
                            </Box>
                        }
                    </Stack>}
                </Stack>
            </RemedialCard>
            {/* modal */}
            {showRewardModal && <BonusPointsModal1 show={showRewardModal} bonusPoints={revisedResultPageData.test_details.remedial_bonus_points} studyProgress={studyProgress} onClose={() => { setshowRewardModal(false); handleUpdateBanner() }} />}
        </>
    )
}

export default RemedialTestSection