import React, { Component } from "react";
import { connect } from "react-redux";
import AppLayout from "../../components/Layout/Layout";
import {
  getTestAC,
  submitTestAC,
  TESTSUBMITLOAD,
  submitQuestionsFeedbackAC,
  submitQuestionAC,
} from "../../store/actions/questionnaireActions";
import Flex from "styled-flex-component";
import Circle from "../../components/UI/QuestionnaireCircle";
import Equation from "../../components/Equation";
import styled from "styled-components";
import Modal1 from "./Modal";
import ModalMarked from "./ModalMarked";
import MonogramTimer from "../../components/MonogramTimer/MonogramTimer";
import Instructions from "../../components/QuestionnaireInstructions/QuestionnaireInstructions";
import Questionpanel from "../../components/Questionscreen";
import Assessmentstatus from "../../components/AssessmentstatusMetrics";
import moment from "moment";
import ReportDialog from "../../components/reportModal";
// import NetworkSpeed from "network-speed";
import ReactTooltip from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import { init, identify, Identify, track } from '@amplitude/analytics-browser';
const AMPLITUDE_API_KEY=process.env.REACT_APP_AMPLITUDE_KEY;
// const testNetworkSpeed = new NetworkSpeed();
const baseUrl = "https://eu.httpbin.org/stream-bytes/50000";
const fileSizeInBytes = 50000;
let networkTimer = null;

const Rectangle = () => (
  <svg height="560" width="360">
    <polygon points="180,10 360,80 360,560 10,560 10,80 180,10"></polygon>
    Sorry, your browser does not support inline SVG.
  </svg>
);

const RectangleBig = () => (
  <svg height="580" width="380">
    <polygon points="190,10 380,80 380,580 10,580 10,80 190,10"></polygon>
    Sorry, your browser does not support inline SVG.
  </svg>
);

const Rectangle2 = () => (
  <svg height="960" width="360">
    <polygon points="180,10 360,80 360,960 10,960 10,80 180,10"></polygon>
    Sorry, your browser does not support inline SVG.
  </svg>
);

const RectangleBig2 = () => (
  <svg height="980" width="380">
    <polygon points="190,10 380,80 380,980 10,980 10,80 190,10"></polygon>
    Sorry, your browser does not support inline SVG.
  </svg>
);

const RectangleWrapper = styled.div`
  color: #ffffff;
  position: absolute;
  top: 10px;
  right: 8px;
  svg {
    // position: absolute;?
    display: block;
    fill: #ffffff;
    width: 100%;
    height: 100%;
    polygon {
      stroke: #ffffff;
      fill: #ffffff;
      stroke-width: 1px;
      transition: all 0.3s esae;
    }
    transition: all 0.3s esae;
  }
`;

const RectangleWrapperBig = styled.div`
  // color: #000000;
  svg {
    display: block;
    width: 100%;
    fill: #eeeeee;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    height: 100%;
    // border: 2px solid rgba(#000, .2);
    polygon {
      // stroke-width: 1px;
      fill: #eeeeee;

      transition: all 0.3s esae;
    }
    transition: all 0.3s esae;
  }
`;

const HintDataDiv = styled.div`
  // &::before{
  background-image: url("/images/Combined Shape Copy.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 151px;
  height: 200px;
  box-sizing: border-box;
  margin-left: 15px;
  // }
`;

const QuestionsGrid = styled.div`
  display: grid;
  display: grid;
  grid-template-columns: repeat(5, 60px);
  grid-gap: 0.7em 0;
`;

const OptionFlex = styled.div`
  margin: 10px;
  padding: 10px;
  margin-left: 0;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f6e4e4;
  }
`;

class Assessment extends Component {
  state = {
    questionsNumbersView: 0,
    loading: true,
    testSubmittedFromMulitpleFraud: false,
    pageMounted: false,
    event: null,
    noOfWindowSwitches: 0,
    testSubmittedModalOpen: false,
    windowChangeWarningModalOpen: false,
    windowFullScreenWarningModalOpen: true,
    reportDialogOpened: false,
    dialogTitle: "Are you sure you want to submit the test ?",
    showHint: false,
    showFullQuestionsView: false,
    openMarkedDialog: false,
    currentTime: 0,
    openInstructions: false,
    warningWindow: false,
    showCalc: false,
    reportItemId: null,
    openReportDialog: false,
    itemToBeReported: null,
    close_test: false,
    testCompleted: false,
    showNetworkModal: false,
    networkSpeed: 0,
    networkCheck: true,
    resubmitCount: 0,
    reSubmitFailure: false,
    regetquestionscount:0,
  };

  componentDidMount = async () => {
    // document.body.style.overflow = 'hidden';
    // this.closeFullscreen();
    if(!this.props.location.state.test_id){
      this.props.history.push({pathname: "/home"});
      return;
    }
    let test_id = this.props.location.state.test_id;
    await this.props.getQuestions(test_id);
    this.setState({ loading: false });
    console.log(this.props);
    document.onkeydown = function (e) {
      return false;
    };
    window.addEventListener("online", () =>
      this.setState({ networkDisconnect: false })
    );
    window.addEventListener("offline", () =>
      this.setState({ networkDisconnect: true })
    );
    this.startNetworkSpeedCheckTimer();
  };

  componentDidUpdate = () => {
    let test_id = this.props.location.state.test_id;
    if (this.props.close_test && !this.state.close_test)
      this.confirmSubmitTest(2);
    if (this.props.errorSubmittingTest && this.state.close_test) {
      this.setState({
        openSubmitTestDialog: true,
        dialogTitle: "Are you sure you want to submit the test?",
        close_test: false,
      });
    }
    if(this.props.error_loading_test && this.state.regetquestionscount<2 && !this.props.attempted){
      this.props.getQuestions(test_id);
     this.setState(prevState => ({
       regetquestionscount:prevState.regetquestionscount+1,
     }));  
     }
    if(this.props.error_loading_test && this.props.attempted) clearInterval(networkTimer);
    if(this.props.errorSubmittingTest && this.state.resubmitCount < 3 && !this.state.reSubmitFailure) this.confirmSubmitTest(2);
    else if(this.props.errorSubmittingTest && this.state.resubmitCount >= 3 && this.state.reSubmitFailure){
      this.setState({testCompleted: true,reSubmitFailure: false});
    }
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      const identifyObj = new Identify();
      identifyObj.setOnce('User ID', `${user.user_id}`);
      identify(identifyObj);
      init(AMPLITUDE_API_KEY,`${user.user_id}`);
    }
  };

  componentWillUnmount(){
    this.props.clearTestState();
  }

  startNetworkSpeedCheckTimer = () => {
    this.getNetworkSpeed();
    networkTimer = setInterval(async () => {
      await this.getNetworkSpeed();
    }, 10000);
  };

  getNetworkSpeed = async () => {
    if (window?.navigator?.onLine && this.state.networkCheck) {
      let speed = 0;
      try {
        this.setState((prevState) => ({
          networkCheck: prevState.networkCheck ? false : true,
        }));
        // const speedRes = await testNetworkSpeed.checkDownloadSpeed(
        //   baseUrl,
        //   fileSizeInBytes
        // );
        const speedRes = 0
        speed =
          speedRes?.mbps && speedRes.mbps !== "Infinity"
            ? speedRes.mbps * 8
            : 0;
        this.setState((prevState) => ({
          showNetworkModal: parseFloat(speed) < 2,
          networkCheck: true,
          networkSpeed: speed,
        }));
      } catch (error) {
        this.setState((prevState) => ({
          showNetworkModal: false,
          networkDisconnect: true,
          networkSpeed: 0,
          networkCheck: true,
        }));
      }
    }
  };

  closeNetworkModal = () => this.setState({ showNetworkModal: false });

  // closeNetworkDisconnectModal = () => this.setState({networkDisconnect: false});

  openFullscreen = () => {
    try {
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
      //   this.props.onTestStart();
    } catch (error) {
      console.log(error);
    }
  };

  askBeforeUserLeaving() {
    try {
      //   let pathname,array = [];
      this.onVisibilityChange(this.onVisibilityChangeCallBack);
      document.addEventListener("fullscreenchange", () => {
        const isFullScreen =
          document.fullScreen ||
          document.mozFullScreen ||
          document.webkitIsFullScreen;
        if (!isFullScreen)
          this.setState({ windowFullScreenWarningModalOpen: !isFullScreen });
      });
    } catch (error) {
      console.log(error);
    }
    // window.addEventListener("beforeunload",(event)=> this.beforeUnloadFunction(event));
  }

  closeFullscreen = () => {
    try {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE11 */
          document.msExitFullscreen();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  onCloseWargningWindow = () => {
    this.setState({ windowChangeWarningModalOpen: false });
    this.openFullscreen();
  };

  onCloseFullScreenWarningWindow = () => {
    this.setState({ windowFullScreenWarningModalOpen: false });
    this.openFullscreen();
  };

  onVisibilityChangeCallBack = (visible) => {
    const noOfWindowSwitches = this.state.noOfWindowSwitches + 1;
    //  console.log(noOfWindowSwitches);
    if (
      noOfWindowSwitches <= 1 &&
      this.props.testStarted &&
      !this.props.testSubmitted
    ) {
      const eventProperties = {
        count: noOfWindowSwitches,
        testid:`${this.props.location.state.test_id}`
      };
       track('test_tab_switch', eventProperties);
      this.setState({
        windowChangeWarningModalOpen: true,
        noOfWindowSwitches: noOfWindowSwitches,
        dialogTitle:
          "Warning: You are NOT allowed to switch apps during the test! ",
      });
    } else if (
      noOfWindowSwitches >= 2 &&
      this.props.testStarted &&
      !this.props.testSubmitted
    ) {
      const eventProperties = {
        count: noOfWindowSwitches,
        testid:`${this.props.location.state.test_id}`
      };
       track('test_tab_switch', eventProperties);
      this.confirmSubmitTest(4);
      this.setState({
        testSubmittedFromMulitpleFraud: true,
        testSubmittedModalOpen: true,
        dialogTitle: "Your Test has been submitted due to tab switches!!",
        windowChangeWarningModalOpen: false,
      });
    }
  };

  focused = (callback) => {
    if (!this.state.visible) {
      this.setState({ visible: true });
      callback(true);
    }
  };

  unfocused = (callback) => {
    if (this.state.visible) {
      this.setState({ visible: false });
      callback(false);
    }
  };

  mozVisiblityChangeFunction = (callBack) => {
    (document.mozHidden ? this.unfocused : this.focused)(callBack);
  };
  visiblityChangeFunction = (callBack) => {
    (document.hidden ? this.unfocused : this.focused)(callBack);
  };
  webkitVisiblityChangeFunction = (callBack) => {
    (document.webkitHidden ? this.unfocused : this.focused)(callBack);
  };
  msVisiblityChangeFunction = (callBack) => {
    (document.msHidden ? this.unfocused : this.focused)(callBack);
  };

  onVisibilityChange(callback) {
    if (!callback) {
      throw new Error("no callback given");
    }
    // Standards:
    if ("hidden" in document) {
      document.addEventListener("visibilitychange", () =>
        this.visiblityChangeFunction(callback)
      );
    }
    if ("mozHidden" in document) {
      document.addEventListener("mozvisibilitychange", () =>
        this.mozVisiblityChangeFunction(callback)
      );
    }
    if ("webkitHidden" in document) {
      document.addEventListener("webkitvisibilitychange", () =>
        this.webkitVisiblityChangeFunction(callback)
      );
    }
    if ("msHidden" in document) {
      document.addEventListener("msvisibilitychange", () =>
        this.msVisiblityChangeFunction(callback)
      );
    }
    // IE 9 and lower:
    if ("onfocusin" in document) {
      document.onfocusin = () => this.focused(callback);
      document.onfocusout = () => this.unfocused(callback);
    }
    // All others:
    window.onpageshow = window.onfocus = () => this.focused(callback);
    window.onpagehide = window.onblur = () => this.unfocused(callback);
  }

  onHintToggle = () => {
    this.setState({
      showHint: !this.state.showHint,
    });
  };

  openFullQuestionsView = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState((prevState, props) => ({
      showFullQuestionsView: !prevState.showFullQuestionsView,
    }));
  };

  submitTest = () => {
    const eventProperties = {
      type: "submit_confirmation",
      testid:`${this.props.location.state.test_id}`
    };
     track('test_submission', eventProperties);
    this.setState({
      openSubmitTestDialog: true,
      dialogTitle: "Are you sure you want to submit the test?",
    });
  };

  closeSubmitTestDialog = (event) => {
    event.stopPropagation();
    if (!this.props.errorSubmittingTest)
      this.setState({ openSubmitTestDialog: false, dialogTitle: "" });
  };

  viewMarked = async (reverse,qid) => {
    console.log("clicked view marked");
    document.body.style.overflow = "hidden";
    // this.onQuestionChange(qid, 1) time recording commented due to issue
    // await this.props.submitQuestion();
    this.props.opneMarkedDialogAction(reverse);
  };

  closeMarked = () => {
    document.body.style.overflow = "auto";
    this.props.closeMarkedDialogAction();
  };
  viewSkipped = async (reverse,qid) => {
    console.log(reverse)
    console.log("clicked view marked");
    //  this.onQuestionChange(qid, 1) time recording commented due to issue
    // await this.props.submitQuestion();
    document.body.style.overflow = "hidden";
    // await this.props.submitQuestion();
    this.props.openSkippedDialogAction(reverse);
  };

  closeSkipped = () => {
    document.body.style.overflow = "auto";
    this.props.closeSkippedDialogAction();
  };
  submitqueswhileindexchange= async(index,qid) => {
    this.onQuestionChange(qid, 1)
    this.props.changeCurrentQuestionNumberIndex(index)
  };

  setCurrentTime = (time, timer) => {
    // console.log(time);
    if (time <= 0) {
      this.confirmSubmitTest(1);
    }
    this.setState({ currentTime: time, timer: timer });
  };

  closeTestSubmitted = () => {
    this.removeEventListeners();
    this.setState({
      questionsNumbersView: 0,
      loading: true,
      testSubmittedFromMulitpleFraud: false,
      pageMounted: false,
      event: null,
      noOfWindowSwitches: 0,
      testSubmittedModalOpen: false,
      windowChangeWarningModalOpen: false,
      reportDialogOpened: false,
      dialogTitle: "Are you sure you want to submit the test ?",
      showHint: false,
      showFullQuestionsView: false,
      openMarkedDialog: false,
      currentTime: 0,
      testCompleted: true,
    });
    // this.props.history.goBack();
  };

  removeEventListeners = () => {
    try {
      window.removeEventListener("beforeunload", (event) =>
        this.beforeUnloadFunction(event)
      );
      document.removeEventListener("beforeunload", this.beforeUnloadFunction);
      window.removeEventListener("beforeunload", this.beforeUnloadFunction);
      window.removeEventListener("beforeunload", this.beforeUnloadFunction);
      window.removeEventListener("beforeunload", this.beforeUnloadFunction);
      window.onbeforeunload = null;
      window.removeEventListener("pagehide", this.unfocused);
      window.removeEventListener("pageshow", this.focused);
      window.removeEventListener("focus", this.focused);
      window.removeEventListener("blur", this.unfocused);
      window.onblur = null;
      window.onfocus = null;
      window.onpagehide = null;
      window.onpageshow = null;
      if ("onfocusin" in document) {
        document.onfocusin = null;
        document.onfocusout = null;
      }
      document.removeEventListener(
        "visibilitychange",
        this.visiblityChangeFunction
      );
      document.removeEventListener(
        "mozvisibilitychange",
        this.mozVisiblityChangeFunction
      );
      document.removeEventListener("focusin", this.focused);
      document.removeEventListener("focusout", this.unfocused);

      document.removeEventListener(
        "webkitvisibilitychange",
        this.webkitVisiblityChangeFunction
      );
      document.removeEventListener(
        "msvisibilitychange",
        this.msVisiblityChangeFunction
      );
    } catch (error) {}
  };

  openInstructionsHandler = () => {
    this.setState({ openInstructions: true });
  };

  closeInstructions = () => {
    this.setState({ openInstructions: false });
  };

  confirmSubmitTest = async (type) => {
    if (type==3) {
      const eventProperties = {
        type: "submit_confirmed",
        testid:`${this.props.location.state.test_id}`
      };
       track('test_submission', eventProperties);
    }
    this.removeEventListeners();
    clearInterval(this.state.timer);
    if (!this.props.testSubmitting && !this.state.close_test) {
      this.setState(prevState => ({
        openSubmitTestDialog: false,
        dialogTitle:
          type === 3
            ? "You have submitted the test!"
            : type === 1
            ? "Your test has been submitted due to time lapse!"
            : "",
        openMarkedDialog: false,
        openSkippedDialog: false,
        close_test: true,
        resubmitCount: prevState.resubmitCount + 1,
        reSubmitFailure: prevState.resubmitCount + 1 >= 3
      }));
      await this.props.onTestSubmitting();
      if (!this.props.errorSubmittingTest && this.state.resubmitCount === 0)
        await this.onQuestionChange(this.props.qid, 2, false, true);
      await this.props.submitTest(type,this.state.networkSpeed);
      // console.log("test submitted");
      clearInterval(networkTimer);
      document.onkeydown = function (e) {
        return true;
      };
    }
  };

  showCalcHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showCalc: true });
  };

  closeCalc = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showCalc: false });
  };

  reportItem = (item, id) => {
    document.body.style.overflow = "hidden";
    this.setState({
      openReportDialog: true,
      itemToBeReported: item,
      reportItemId: id,
    });
  };

  closeReport = () => {
    document.body.style.overflow = "auto";
    this.setState({
      openReportDialog: false,
      itemToBeReported: null,
      reportItemId: null,
    });
  };

  closeTestAfterSubmission = () => {
    if (this.props.collect_feedback) {
      this.props.openCollectFeedback();
    } else {
      this.setState({ testCompleted: true });
      this.closeFullscreen();
    }
  };

  closeTestAfterFeedback = () => {
    this.props.clearState();
    this.setState({ testCompleted: true });
  };

  onFeedbackOptionSelectHandler = (event, ques_id, opt_id) => {
    event.stopPropagation();
    this.props.onFeedbackOptionSelected(ques_id, opt_id);
  };

  onQuestionChange = async (qid, type, doubt, notSubmit) => {
    if (type==1 && !doubt) {
      const eventProperties = {
        type: "buttons",
        testid:`${this.props.location.state.test_id}`
      };
       track('question_submit', eventProperties);
    }else if(type==4){
      const eventProperties = {
        type: "circle",
        testid:`${this.props.location.state.test_id}`
      };
      track('question_submit', eventProperties);
    }else if(type==1 && doubt){
      const eventProperties = {
        testid:`${this.props.location.state.test_id}`
      };
       track('mark_as_doubt', eventProperties);
    }
    console.log("---------"+qid+"_________"+doubt)
    if(doubt && qid==this.props.questions.length) qid = qid-2;
    // if (doubt) await this.props.onMarkedAsDoubt(qid, type);
    await this.props.onQuestionClick(qid, null, type, doubt);
    // console.log("question submitted");
    if (!notSubmit) await this.props.submitQuestion(this.state.networkSpeed);
  };

  render() {
    // console.log(this.props);
    if (this.props.error_loading_practice_test) {
      return <div>No Questions</div>;
    }
    if (this.props.error_loading_test && this.props.attempted) {
      return <div>{this.props.attempted}</div>;
    }
    if (!this.props.questions) {
      return <div>Loading</div>;
    }
    let questions = this.props.questions;
    let qid = this.props.qid;
    let answers = this.props.answers;

    // let collect_feedback = this.props.collect_feedback;
    const option_numbers = {
      0: "a",
      1: "b",
      2: "c",
      3: "d",
      4: "e",
      5: "f",
      6: "g",
      7: "h",
      8: "i",
      9: "j",
    };
    // let questionIndexesAtEnd = this.props.questions - this.state.currentQuestionNumberIndex * 10 >= 10 ? 10 : this.props.questions % 10;
    let questions_numbers_indexes = this.props.questions
      .slice(
        this.props.currentQuestionNumberIndex * 10,
        this.props.currentQuestionNumberIndex * 10 + 10
      )
      .map((que, index) => {
        return index;
      });
    // console.log(questions_numbers_indexes);
    if (this.props.openMarkedDialog) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    if (this.state.testCompleted || this.props.testSubmitted) {
      return (
        <div className="pt-5">
          <h5>
            {this.props.errorSubmittingTest ? "There was error submitting your test. Please contact test coordinator." : "Your test has been submitted successfully."}
          </h5>
          <img
            className="img-responsive"
            width={250}
            height="250"
            style={{display:'inline'}}
            src={this.props.errorSubmittingTest ? "images/gif/test_submit_fail.gif" : "images/gif/test_submitted.gif"}
            alt="full screen"
          />
          <p>
            <button className="btn">
              <u>
                <b
                  style={{ cursor: "pointer", fontSize: "large" }}
                  onClick={() =>
                    this.props.history.push({
                      pathname: 'home',
                      state: { subject_id: this.props.location.state.subject_id },
                    })
                  }
                  >
                  Go to Dashboard <img src="/images/Right_arrow_blue.svg" alt="right arrow" style={{display:'inline'}} />
                </b>
              </u>
            </button>
          </p>
        </div>
      );
    }
    if (!this.props.testStarted) {
      return (
        <Instructions
          askBefore={() => this.askBeforeUserLeaving()}
          showStart={true}
          testId={this.props.location.state.test_id}
          networkSpeed={this.state.networkSpeed}
          resume={this.props.resume_test}
        ></Instructions>
      );
    }
    let timelimit = this.props.timeduration;
    if (moment(this.props.doe).diff(moment(), "seconds") < timelimit) {
      timelimit = moment(this.props.doe).diff(moment(), "seconds");
    }

    // console.log(timelimit);
    return (
      <div onCopy={() => false} onCut={() => false} onPaste={() => false}>
        {/* <AppLayout logoutDisable={true}></AppLayout> */}
        <Flex
          row
          style={{
            marginTop: "12px",
            marginLeft: "30px",
            overflow: "auto",
            paddingBottom: "50px",
          }}
        >
          <div style={{ width: "72%" }}>
            <Flex row style={{ margin: "8px", marginTop: "50px" }}>
              <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                {this.props.name}
              </div>
              <Flex
                row
                style={{ fontSize: "14px", margin: "auto", marginLeft: "5px" }}
              >
                <div style={{ margin: "auto", marginBottom: "4px" }}>
                  &nbsp;Description: {this.props.description} | Questions:{" "}
                  {this.props.questions.length}
                </div>
                <div
                  onClick={this.openInstructionsHandler}
                  style={{ marginLeft: "8px", cursor: 'pointer' }}
                >
                  <img src="/images/Info.svg" alt="info" />
                </div>
              </Flex>
            </Flex>
            {/* <div className="float-right mr-3">
              Network Speed :{" "}
              <span
                style={{
                  color:
                    this.state.networkSpeed > 0 && this.state.networkSpeed <= 1
                      ? "red"
                      : this.state.networkSpeed > 1 &&
                        this.state.networkSpeed <= 3
                      ? "gold"
                      : this.state.networkSpeed > 3 &&
                        this.state.networkSpeed <= 5
                      ? "GoldenRod"
                      : this.state.networkSpeed > 5 &&
                        this.state.networkSpeed <= 10
                      ? "green"
                      : this.state.networkSpeed > 10
                      ? "green"
                      : "black",
                }}
              >
                {this.state.networkSpeed > 0 && this.state.networkSpeed <= 1
                  ? "Critically Low"
                  : this.state.networkSpeed > 1 && this.state.networkSpeed <= 3
                  ? "Low"
                  : this.state.networkSpeed > 3 && this.state.networkSpeed <= 5
                  ? "Stable"
                  : this.state.networkSpeed > 5 && this.state.networkSpeed <= 10
                  ? "High"
                  : this.state.networkSpeed > 10
                  ? "Very High"
                  : null}
              </span>
              <span data-tip data-for="registerTip">
                <img src="/images/Info.svg" alt="info" style={{display:'inline'}} />
              </span>
              <ReactTooltip id="registerTip" place="bottom" effect="solid">
                <li>0 to 1MBPS : Critically low</li>
                <li>1 to 3 MBPS : Low</li>
                <li> 3 to 5 MBPS : Stable</li>
                <li>5 to 10 MBPS : High</li>
                <li>Above 10 MBPS: Very High</li>
              </ReactTooltip>
              {this.state.showNetworkModal ? (
                <p className="slowinternetdetected" style={{ color: "red" }}>
                  Slow Network Detected!
                </p>
              ) : null}
            </div> */}
            {this.props.questions.length > 10 ? (
              <div style={{ margin: "20px 0px 20px 0px" }}>
                <Flex row>
                  {this.props.question_numbers.map((number, index) => {
                    return (
                      <Flex row style={{ marginTop: "15px" }}>
                        {this.props.currentQuestionNumberIndex == index ? (
                          questions_numbers_indexes.map((que, qNoIndex) => {
                            // console.log(qNoIndex)
                            return (
                              <Flex column>
                                {qNoIndex +
                                  this.props.currentQuestionNumberIndex * 10 ==
                                qid ? (
                                  <img
                                    style={{
                                      marginTop: "-15px",
                                      marginBottom: "-10px",
                                      width: "30px",
                                      marginLeft: "8px",
                                    }}
                                    alt="monkey"
                                    src="/images/Small_monkey.svg"
                                  ></img>
                                ) : null}
                                <Circle
                                  onQuestionClick={this.onQuestionChange}
                                  current={
                                    qNoIndex +
                                      this.props.currentQuestionNumberIndex *
                                        10 ==
                                    qid
                                      ? true
                                      : false
                                  }
                                  number={
                                    qNoIndex +
                                    this.props.currentQuestionNumberIndex * 10 +
                                    1
                                  }
                                  answered={
                                    answers[
                                      qNoIndex +
                                        this.props.currentQuestionNumberIndex *
                                          10
                                    ].question_option_id
                                      ? true
                                      : false
                                  }
                                  marked={
                                    this.props.marked.indexOf(
                                      qNoIndex +
                                        this.props.currentQuestionNumberIndex *
                                          10 +
                                        1
                                    ) == -1
                                      ? false
                                      : true
                                  }
                                  skipped={
                                    this.props.skipped.indexOf(
                                      qNoIndex +
                                        this.props.currentQuestionNumberIndex *
                                          10 +
                                        1
                                    ) == -1
                                      ? false
                                      : true
                                  }
                                ></Circle>
                              </Flex>
                            );
                          })
                        ) : (
                          <div
                          onClick={() =>
                            this.submitqueswhileindexchange(index,qid+1)
                          }
                            key={index}
                            style={{ margin: "8px", width: "42px", cursor: 'pointer' }}
                          >
                            <img width="100%" src="/images/Buffer.svg" alt="question nav"/>
                          </div>
                        )}
                      </Flex>
                    );
                  })}
                </Flex>
              </div>
            ) : (
              <div>
                <Flex row style={{ marginTop: "15px" }}>
                  {this.props.questions.map((question, index) => (
                    <Flex column>
                      {index == qid ? (
                        <img
                          style={{
                            marginTop: "-15px",
                            marginBottom: "-10px",
                            width: "30px",
                            marginLeft: "8px",
                          }}
                          alt="monkey"
                          src="/images/Small_monkey.svg"
                        ></img>
                      ) : null}
                      <Circle
                        current={index == qid ? true : false}
                        answered={
                          answers[index].question_option_id ? true : false
                        }
                        marked={
                          this.props.marked.indexOf(index + 1) == -1
                            ? false
                            : true
                        }
                        skipped={
                          this.props.skipped.indexOf(index + 1) == -1
                            ? false
                            : true
                        }
                        onQuestionClick={this.onQuestionChange}
                        number={index + 1}
                        key={index}
                      ></Circle>
                    </Flex>
                    // <Circle onQuestionClick = {this.props.onQuestionClick} number={index+1} key={index}></Circle>
                  ))}
                </Flex>
              </div>
            )}
             <Questionpanel qid={qid} answers={answers} questions={questions} reportItem={this.reportItem} />
           <div>
              {qid != 0 ? (
                <div
                  className="submitButton"
                  onClick={() => this.onQuestionChange(qid - 1, 4)}
                  style={{ float: "left", marginRight: "40px" }}
                >
                  <div style={{ marginRight: "10px" }}>
                    <img src="/images/left_arrow.svg"></img>
                  </div>
                  <div>Previous</div>
                </div>
              ) : null}
              {this.props.skipped.length != 0 ? (
                <div
                onClick={(reverse) => { this.viewSkipped(reverse,qid+1) }}
                  // onClick={this.viewSkipped}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    float: "left",
                    margin: "auto",
                    marginRight: "40px",
                    color: "#6568D9",
                    fontSize: "15px",
                    marginTop: "8px",
                    cursor: 'pointer'
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    <img src="/images/left_arrow.svg" alt="left arrow" />
                  </div>
                  <div>Skipped questions</div>
                </div>
              ) : null}

              {qid != questions.length - 1 ? (
                <div
                  className="nextButton"
                  onClick={() => this.onQuestionChange(qid + 1, 1)}
                  style={{ float: "right", marginRight: "40px" }}
                >
                  {/* <img src='/images/Group 15.svg'></img> */}

                  <div style={{ margin: "auto", marginTop: "5px" }}>Next</div>
                  <div style={{ margin: "auto", marginLeft: "10px" }}>
                    <img src="/images/Right_arrow.svg"></img>
                  </div>
                </div>
              ) : null}
              <div
                onClick={() => this.onQuestionChange(qid + 1, 1, true)}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  float: "right",
                  margin: "auto",
                  color: "#6568D9",
                  fontSize: "15px",
                  marginTop: "5px",
                  marginRight: "50px",
                  cursor: 'pointer'
                }}
              >
                <div style={{ margin: "auto", marginTop: "5px" }}>
                  Mark as Doubt {qid !== questions.length - 1 ? " and Next" : ""}
                </div>
                {qid !== questions.length - 1 ?
                  <div
                    style={{
                      margin: "auto",
                      marginLeft: "10px",
                      marginTop: "6px",
                    }}
                  >
                    <img src="/images/Right_arrow_blue.svg" alt="right arrow" />
                  </div>
                : null}
              </div>
            </div>
          </div>
       
          <div
            style={{
              width: "380px",
              height: "580px",
              marginTop: "50px",
              position: "absolute",
              top: "96px",
              left: "71%",
            }}
          >
            {this.state.showFullQuestionsView ? (
              <>
                <RectangleWrapperBig>
                  <RectangleBig2></RectangleBig2>
                </RectangleWrapperBig>
                <RectangleWrapper>
                  <Rectangle2></Rectangle2>
                </RectangleWrapper>
              </>
            ) : (
              <>
                <RectangleWrapperBig>
                  <RectangleBig></RectangleBig>
                </RectangleWrapperBig>
                <RectangleWrapper>
                  <Rectangle></Rectangle>
                </RectangleWrapper>
              </>
            )}

            <div
              style={{
                position: "absolute",
                top: "-40px",
                right: "30px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <div style={{ widht: "275px", height: "160px" }}>
                  {/* <img width='275px' height='160px' src='/images/Group 8.svg'></img> */}
                  <MonogramTimer
                    seconds={timelimit}
                    onTimerUpdate={this.setCurrentTime}
                  />
                </div>
                {/* <div style={{marginTop: '20px', borderBottom: '2px solid #eee', width: '290px', marginLeft: '10px'}}></div>
                                <Flex row style={{marginTop: '20px', width: '300px'}}>
                                    <div style={{width: '106px', height: '57px'}}>
                                        <img width = '106px' height = '57px' src='/images/Group 19.svg'></img>
                                    </div>
                                    <div style={{widht: '184px', color: '#ff7676'}}>
                                        <p style={{margin: '0'}}>You are taking more than the average time, move to the next question!</p>
                                    </div>
                                </Flex> */}
                <div
                  style={{
                    marginTop: "20px",
                    borderBottom: "2px solid #eee",
                    width: "290px",
                    marginLeft: "10px",
                  }}
                ></div>
                 <Assessmentstatus
                  answered={this.props.answered}
                  remaining_questions={this.props.remaining_questions}
                  skipped={this.props.skipped}
                  marked={this.props.marked}
                  viewMarked={this.viewMarked}
                  viewSkipped={this.viewSkipped}
                  qid={qid}
                />
                {/* <Flex
                  style={{
                    width: "312px",
                    height: "53px",
                    backgroundColor: "white",
                    boxShadow: "5px 10px 20px #eee",
                    padding: "17px 5px",
                    boxSizing: "border-box",
                    borderRadius: "11px",
                    marginTop: "20px",
                  }}
                >
                  <Flex row style={{ width: "50%", margin: "auto" }}>
                    <div style={{ width: "70%", margin: "auto" }}>answered</div>
                    <div
                      style={{
                        width: "30%",
                        fontSize: "24px",
                        fontWeight: "500",
                        color: "lightgreen",
                      }}
                    >
                      {this.props.answered}
                    </div>
                  </Flex>
                  <Flex row style={{ width: "50%", margin: "auto" }}>
                    <div style={{ width: "70%", margin: "auto" }}>
                      remaining
                    </div>
                    <div
                      style={{
                        width: "30%",
                        fontSize: "24px",
                        fontWeight: "500",
                      }}
                    >
                      {this.props.remaining_questions}
                    </div>
                  </Flex>
                </Flex>
                <Flex
                  style={{
                    width: "312px",
                    height: "auto",
                    backgroundColor: "white",
                    boxShadow: "5px 10px 20px #eee",
                    padding: "12px 5px",
                    boxSizing: "border-box",
                    borderRadius: "11px",
                    marginTop: "20px",
                  }}
                >
                  <Flex column style={{ width: "50%", margin: "auto" }}>
                    <Flex row>
                      <div style={{ width: "70%", margin: "auto" }}>
                        skipped
                      </div>
                      <div
                        style={{
                          width: "30%",
                          fontSize: "24px",
                          fontWeight: "500",
                          color: "#A5A4A4",
                        }}
                      >
                        {this.props.skipped.length}
                      </div>
                    </Flex>
                    {this.props.skipped.length != 0 ? (
                      <Flex row style={{ fontSize: "14px", color: "#4E54C8" }}>
                        <div
                          style={{ width: "70%", cursor: 'pointer' }}
                          onClick={this.viewSkipped}
                        >
                          See All
                          <img
                            style={{ marginLeft: "10px" }}
                            src="/images/Right_arrow_2.svg"
                            alt="arrow"
                          ></img>
                        </div>
                      </Flex>
                    ) : null}
                  </Flex>
                  <Flex column style={{ width: "50%", margin: "auto" }}>
                    <Flex row>
                      <div style={{ width: "70%", margin: "auto" }}>marked</div>
                      <div
                        style={{
                          width: "30%",
                          fontSize: "24px",
                          fontWeight: "500",
                          color: "red",
                        }}
                      >
                        {this.props.marked.length}
                      </div>
                    </Flex>
                    {this.props.marked.length != 0 ? (
                      <Flex
                        row
                        style={{
                          fontSize: "14px",
                          color: "#4E54C8",
                          marginLeft: "5px",
                          cursor: 'pointer' 
                        }}
                      >
                        <div onClick={this.viewMarked} style={{ width: "70%" }}>
                          See All
                          <img
                            style={{ marginLeft: "10px" }}
                            src="/images/Right_arrow_2.svg"
                            alt="arrow"
                          ></img>
                        </div>
                      </Flex>
                    ) : null}
                  </Flex>
                </Flex> */}
                <div
                  onClick={this.submitTest}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                    color: "#6568d9",
                    cursor: 'pointer'
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      margin: "auto 0",
                    }}
                  >
                    Submit exam
                  </div>
                  <div
                    style={{
                      margin: "auto",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <img
                      width="18px"
                      height="18px"
                      src="/images/Right_arrow_blue.svg"
                      alt="arrow"
                    ></img>
                  </div>
                </div>
                {/* <div
                  onMouseEnter={this.showCalcHandler}
                  onMouseLeave={this.closeCalc}
                >
                  <div style={{ marginTop: "50px", marginLeft: "96%" }}>
                    <img src="/images/BlueGradientCircle.svg"></img>
                  </div>
                  <div style={{ marginTop: "-50px", marginLeft: "101%" }}>
                    <img src="/images/viewAllQuestions.svg"></img>
                  </div>
                </div> */}
                {this.state.showCalc ? (
                  <div style={{ marginTop: "-35px" }}>
                    <span>Calculator is diabled for this test </span>
                  </div>
                ) : null}
                {this.state.showFullQuestionsView ? (
                  <div style={{ marginTop: "-70px" }}>
                    <QuestionsGrid>
                      {this.props.questions.map((que, index) => {
                        return (
                          <Flex column>
                            {/* {index == qid ? <img style={{marginTop: '-15px', marginBottom: '-10px'}} src= '/images/Small_monkey.svg'></img>: null} */}
                            <Circle
                              current={index == qid ? true : false}
                              answered={
                                answers[index].question_option_id ? true : false
                              }
                              marked={
                                this.props.marked.indexOf(index + 1) == -1
                                  ? false
                                  : true
                              }
                              skipped={
                                this.props.skipped.indexOf(index + 1) == -1
                                  ? false
                                  : true
                              }
                              onQuestionClick={this.onQuestionChange}
                              number={index + 1}
                              key={index}
                            ></Circle>
                          </Flex>
                        );
                      })}
                    </QuestionsGrid>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Flex>
        {this.state.openSubmitTestDialog ? (
          <Modal1 open={this.state.openSubmitTestDialog}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {!this.props.errorSubmittingTest ? (
                <img
                  className="img-responsive"
                  style={{display:'inline'}}
                  width={200}
                  height="200"
                  src="images/gif/test_submit_confirm.gif"
                  alt="test submit confirmation"
                />
              ) : (
                <img
                  className="img-responsive"
                  style={{display:'inline'}}
                  width={100}
                  height="100"
                  src="images/gif/test_submit_fail.gif"
                  alt="test submission fail"
                />
              )}
              {this.props.errorSubmittingTest ? (
                <div
                  style={{
                    margin: "0 30px",
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "red",
                  }}
                >
                  Test submission failed! Please try again!
                </div>
              ) : (
                <p>{this.state.dialogTitle}</p>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "40px 20px",
              }}
            >
              {!this.props.errorSubmittingTest ? (
                <div
                  onClick={this.closeSubmitTestDialog}
                  style={{ margin: "auto", marginLeft: "0", color: "red" }}
                >
                  Cancel
                </div>
              ) : null}
              <button
                className="btn btn-primary"
                onClick={() => this.confirmSubmitTest(3)}
                style={{
                  margin: "auto",
                  marginRight: "10px",
                }}
              >
                Submit
              </button>
            </div>
          </Modal1>
        ) : null}
        <ModalMarked
          timelimit={this.state.currentTime}
          open={this.props.openMarkedDialog}
          closeDialog={this.closeMarked}
          marked={true}
        ></ModalMarked>
        <ModalMarked
          timelimit={this.state.currentTime}
          open={this.props.openSkippedDialog}
          closeDialog={this.closeSkipped}
          skipped={true}
        ></ModalMarked>
        {this.state.windowChangeWarningModalOpen ? (
          <Modal1
            style={{ zIndex: 12 }}
            open={this.state.windowChangeWarningModalOpen}
          >
            <div className="text-center">
              <img
                className="img-responsive"
                width={250}
                style={{display:'inline'}}
                height="250"
                src="images/gif/tab_switch.gif"
                alt="tab switch"
              />
              <p>
                <b>Warning</b>: You are NOT allowed to switch apps during the
                test!
                <br />
                If you switch <b>one more time</b> your test{" "}
                <b>will be submitted</b>.
              </p>
            </div>
            <button
              className="btn btn-warning font-weight-bold m-auto"
              onClick={this.onCloseWargningWindow}
            >
              I Understand
            </button>
          </Modal1>
        ) : null}
        {this.state.windowFullScreenWarningModalOpen ? (
          <Modal1
            style={{ zIndex: 12 }}
            open={this.state.windowFullScreenWarningModalOpen}
          >
            <div className="text-center">
              <img
                className="img-responsive"
                width={250}
                style={{display:'inline'}}
                height="250"
                src="images/gif/full_screen.gif"
                alt="full screen"
              />
              <p>
                <b>Warning</b>: You are <b>NOT allowed</b> to turn off full
                screen mode.
              </p>
            </div>
            <button
              className="btn btn-warning font-weight-bold m-auto"
              onClick={this.onCloseFullScreenWarningWindow}
            >
              Understood
            </button>
          </Modal1>
        ) : null}
        {this.props.testSubmitted ? (
          <Modal1 open={this.props.testSubmitted}>
            <div className="text-center" style={{ marginTop: "20px" }}>
              <img
                className="img-responsive"
                width={100}
                style={{display:'inline'}}
                height="100"
                src="images/gif/tick.gif"
                alt="completed"
              />
              <p className="my-2">{this.state.dialogTitle}</p>
            </div>
            <button
              className="btn btn-primary"
              onClick={this.closeTestAfterSubmission}
              style={{ margin: "20px auto", minWidth: "100px" }}
            >
              OK
            </button>
          </Modal1>
        ) : null}
        {this.props.openFeedback ? (
          <Modal1
            width={"70%"}
            height={"70%"}
            open={this.props.openFeedback}
            close={this.closeTestAfterFeedback}
          >
            <div
              style={{
                fontSize: "24px",
                fontWeight: "600",
                marginTop: "20px",
                textAlign: "left",
              }}
            >
              Exam Experience Survey
            </div>
            {/* <div style={{borderBottom: '1px solid #ccc', margin: '10px 0'}}></div> */}
            {this.props.feedback_questions.map((f_ques) => {
              let answer_index = this.props.feedback_answers.findIndex(
                (ans) => {
                  return f_ques.id == ans.question_id;
                }
              );
              return (
                <div
                  key={f_ques.id}
                  style={{ color: "#787676", margin: "20px 10px 20px 0" }}
                >
                  <div style={{ textAlign: "left", marginBottom: "14px" }}>
                    {f_ques.question_name}
                  </div>
                  {f_ques.questions_options[0].option_img ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {f_ques.questions_options.map((opt) => {
                        return (
                          <div
                            onClick={(event) =>
                              this.onFeedbackOptionSelectHandler(
                                event,
                                f_ques.id,
                                opt.id
                              )
                            }
                            style={{
                              color: "#302e2e",
                              margin: "10px",
                              padding: "10px",
                              width: "20%",
                              backgroundColor:
                                this.props.feedback_answers[answer_index]
                                  .question_option_id == opt.id
                                  ? "#eee"
                                  : "#fff",
                            }}
                          >
                            <img
                              width={"50px"}
                              src={opt.option_img}
                              alt="option"
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 50%)",
                        textAlign: "left",
                      }}
                    >
                      {f_ques.questions_options.map((opt, index) => {
                        return (
                          <div style={{ color: "#302e2e", margin: "10px" }}>
                            <input
                              onClick={(event) =>
                                this.onFeedbackOptionSelectHandler(
                                  event,
                                  f_ques.id,
                                  opt.id
                                )
                              }
                              type="radio"
                              id={opt.id}
                              key={index}
                              checked={
                                this.props.feedback_answers[answer_index]
                                  .question_option_id == opt.id
                              }
                            />
                            <label for={opt.id}>{opt.option_name}</label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
            <div style={{ margin: "20px auto" }}>
              <div
                onClick={this.props.submitFeedback}
                style={{
                  width: "100px",
                  height: "30px",
                  margin: "auto",
                  borderRadius: "8px",
                  textAlign: "center",
                  display: "block",
                  marginBottom: "20px",
                  color: "white",
                  backgroundColor: "#6568D9",
                  border: "1px solid #6568D9",
                  fontWeight: "600",
                  paddingTop: "5px",
                }}
              >
                Submit
              </div>
            </div>
          </Modal1>
        ) : null}

        {this.props.feedbackSubmitted ? (
          <Modal1 open={this.props.feedbackSubmitted}>
            <div style={{ marginTop: "20px" }}>Feedback is Submitted</div>
            <div
              onClick={this.closeTestAfterFeedback}
              style={{ margin: "20px auto", color: "#6568d9" }}
            >
              OK
            </div>
          </Modal1>
        ) : null}
        {this.state.networkDisconnect ? (
          <Modal1 open={this.state.networkDisconnect}>
            <div className="text-center">
              <img
                className="img-responsive"
                width={200}
                style={{display:'inline'}}
                height="200"
                src="images/gif/no_network.gif"
                alt="Network Disconnected"
              />
              <p>
                Network is disconneted. Please connect to a stable network
                immediately
              </p>
            </div>
          </Modal1>
        ) : null}
        {/* {this.state.showNetworkModal ? (
          <Modal style={{ zIndex: 12 }} open={this.state.showNetworkModal}>
            <div>
              <img className="img-responsive" width={100} height="100" src="images/gif/no_network.gif" alt="slow network" />
              <p>
                Your network speed is slow that can cause issues while attempting the test. 
                Please consider switching to better network.
              </p>
            </div>
            <div
              onClick={this.closeNetworkModal}
              style={{
                margin: "auto",
                marginTop: "10px",
                color: "#6568d9",
                cursor: "pointer",
              }}
            >
              <b>Sure</b>
            </div>
          </Modal>
        ) : null} */}
        {this.props.testSubmitting ? (
          <Modal1 open={this.props.testSubmitting}>
            <div className="text-center">
              <img
                className="img-responsive"
                style={{display:'inline'}}
                width={200}
                height="200"
                src="images/gif/test_submitting.gif"
                alt="Network Disconnected"
              />
              <p>
                Test is Submitting
              </p>
            </div>
          </Modal1>
        ) : null}

        {this.state.openInstructions ? (
          <Modal1
            open={this.state.openInstructions}
            closeDialog={this.closeInstructions}
            width="750px"
            height="80%"
            marginTop="50px"
          >
            <Instructions
              new_time={this.state.currentTime}
              showStart={false}
            ></Instructions>
          </Modal1>
        ) : null}
           <Modal show={this.state.openReportDialog} onHide={this.closeReport}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:"16px"}}>Please select issue to be reported</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <ReportDialog
            open={this.state.openReportDialog}
            close={this.closeReport}
            reportItem={this.state.itemToBeReported}
            reportItemId={this.state.reportItemId}
          ></ReportDialog></Modal.Body>
      </Modal> 
        {/* {this.state.openReportDialog ? (
          <ReportDialog
            open={this.state.openReportDialog}
            close={this.closeReport}
            reportItem={this.state.itemToBeReported}
            reportItemId={this.state.reportItemId}
          ></ReportDialog>
        ) : null} */}
        <style jsx scoped>
          {`
            .submitButton {
              border: 1px solid #6568d9;
              // border-image: linear-gradient(to right bottom, #6568D9, #9055CA) 1;
              margin: auto;
              // margin-left: 20px;
              float: right;
              width: 100px;
              height: min-content;
              padding: 0.3em 1em;
              padding-top: 8px;
              border-radius: 20px;
              font-size: 14px;
              color: #6568d9;
              cursor: pointer;
              display: flex;
              flex-direction: row;
              &:focus {
                outline: 0;
              }
            }
            .nextButton {
              background: linear-gradient(
                    to right bottom,
                    #7175e0 0%,
                    #4d50cb 32.912%,
                    #e25bc8 100%
                  )
                  padding-box,
                linear-gradient(
                    to right bottom,
                    #7175e0 0%,
                    #4d50cb 32.912%,
                    #e25bc8 100%
                  )
                  border-box;
              margin: auto;
              float: right;
              padding: 0.3em 1em;
              border-radius: 20px;
              font-size: 14px;
              width: 100px;
              display: flex;
              flex-direction: row;
              height: min-content;
              box-sizing: border-box;
              font-weight: 500;
              color: white;
              border: none;
              cursor: pointer;
            }
            .btn {
              border-radius: 20px;
            }
            .slowinternetdetected {
              animation: blinker 1s linear infinite;
              color: "red";
            }
            @keyframes blinker {
              50% {
                opacity: 0;
              }
            }
            .tooltiptext {
              visibility: hidden;
              width: 120px;
              background-color: #555;
              color: #fff;
              text-align: center;
              border-radius: 6px;
              padding: 5px 0;
              position: absolute;
              z-index: 1;
              bottom: 125%;
              left: 50%;
              margin-left: -60px;
              opacity: 0;
              transition: opacity 0.3s;
            }

            .tooltiptext::after {
              content: "";
              position: absolute;
              top: 100%;
              left: 50%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: #555 transparent transparent transparent;
            }

            .tooltip:hover .tooltiptext {
              visibility: visible;
              opacity: 1;
            }
          `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    questions: state.ques.questions,
    answers: state.ques.answers,
    test_id: state.ques.practice_id,
    qid: state.ques.qid,
    timeduration: state.ques.timeduration,
    name: state.ques.name,
    description: state.ques.description,
    sections: state.ques.sections,
    error_loading_practice_test: state.ques.error_loading_practice_test,
    loading: state.ques.loading_practice_test,
    remaining_questions: state.ques.remaining_questions,
    answered: state.ques.answered,
    skipped: state.ques.skipped,
    marked: state.ques.marked,
    question_numbers: state.ques.question_numbers,
    currentQuestionNumberIndex: state.ques.currentQuestionNumberIndex,
    openMarkedDialog: state.ques.openMarkedDialog,
    openSkippedDialog: state.ques.openSkippedDialog,
    testSubmitted: state.ques.testSubmitted,
    testSubmitting: state.ques.testSubmitting,
    errorSubmittingTest: state.ques.errorSubmittingTest,
    testStarted: state.ques.testStarted,
    doe: state.ques.doe,
    collect_feedback: state.ques.collect_feedback,
    feedback_questions: state.ques.feedback_questions,
    openFeedback: state.ques.openFeedback,
    feedbackSubmitted: state.ques.feedbackSubmitted,
    feedback_answers: state.ques.feedback_answers,
    loading_test: state.ques.loading_test,
    error_loading_test: state.ques.error_loading_test,
    attempted: state.ques.attempted,
    close_test: state.ques.close_test,
    resume_test: state.ques.resume_test,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onQuestionClick: (qid, question_option_id, action_type, doubt) =>
      dispatch({
        type: "ON_TEST_QUESTION_CLICK",
        qid: qid,
        question_option_id: question_option_id,
        action_type: action_type,
        doubt: doubt,
      }),
    getQuestions: (test_id) => dispatch(getTestAC(test_id)),
    onOptionSelected: (qid, option_id) =>
      dispatch({ type: "ON_OPTION_SELECTED", qid: qid, option_id: option_id }),
    onMarkedAsDoubt: (qid, action_type) =>
      dispatch({ type: "ON_MARKED_AS_DOUBT", qid: qid }),
    submitTest: (type,networkspeed) => dispatch(submitTestAC(type,networkspeed)),
    changeCurrentQuestionNumberIndex: (index) =>
      dispatch({
        type: "ON_CURRENT_QUESTION_NUMBER_INDEX_CHANGED",
        index: index,
      }),
    opneMarkedDialogAction: (reverse) =>
      dispatch({ type: "OPEN_MARKED_DIALOG_ACTION", reverse: reverse  }),
    closeMarkedDialogAction: () =>
      dispatch({ type: "CLOSE_MARKED_DIALOG_ACTION" }),
    openSkippedDialogAction: (reverse) =>
      dispatch({ type: "OPEN_SKIPPED_DIALOG_ACTION", reverse: reverse }),
    closeSkippedDialogAction: () =>
      dispatch({ type: "CLOSE_SKIPPED_DIALOG_ACTION" }),
    onTestSubmitting: () => dispatch({ type: TESTSUBMITLOAD }),
    openCollectFeedback: () => dispatch({ type: "OPEN_COLLECT_FEEDBACK" }),
    onFeedbackOptionSelected: (question_id, option_id) =>
      dispatch({
        type: "ON_QUES_FEEDBACK_OPTION_SELECTED",
        question_id: question_id,
        option_id: option_id,
      }),
    submitFeedback: () => dispatch(submitQuestionsFeedbackAC()),
    submitQuestion: (networkspeed) => dispatch(submitQuestionAC(networkspeed)),
    clearTestState: () => dispatch({ type: "CLEAR_TEST_STATE" })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assessment);
