import { Box } from '@mui/material';
import React, { useState } from 'react';
// import { Document, Page } from 'react-pdf';
// import { pdfjs } from 'react-pdf';
import ControlPanel from '../PdfComponents/ControlPanel';


// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.js',
//     import.meta.url,
// ).toString();
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;




const StudySnippetsSection = ({ data }) => {
    const [scale, setScale] = useState(1.0);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);



    console.log(data.file_url, 'data.file_url')


    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };




    return (
        <Box sx={{
            width: '100%',
            maxHeight: '300px',
            overflowY: 'scroll'
        }}>

            <div style={{ width: '100%', height: '100%' }}>
                <ControlPanel
                    scale={scale}
                    setScale={setScale}
                    file={data.file_url}
                />
                {/* <Document file={data.file_url} onLoadSuccess={onDocumentLoadSuccess}>
                    {[...Array(numPages).keys()].map((page) => (
                        <Page key={page + 1} pageNumber={page + 1} scale={scale} />
                    ))}
                </Document> */}
            </div>

        </Box>
    )
}

export default StudySnippetsSection



