import React, { Component } from "react";
import styled from "styled-components";
import classes from "./Layout.module.css";
import Flex from "styled-flex-component";
import { withRouter } from "react-router-dom";
const LogoDiv = styled.div`
    width: 200px;
    height: 120px;
    margin-left: 40px;
    // margin-top: 10px;
    text-align: left;
    border-radius: 8px;
    // background-color: white;
    
`

const FixedHeaderDiv = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  height: 96px;
  width: 100%;
  overflow: hidden;
  opacity: 1;
`;

const IconsDivRight = styled.div`
  width: 32px;
  height: 32px;
  margin-top: 32px;
  text-align: right;
  margin-right: 30px;
`;

class Layout extends Component {
  onLogout = () => {
    if (!this.props.logoutDisable) {
      localStorage.clear();
      const allCookies = document.cookie.split(";");
      for (let i = 0; i < allCookies.length; i++)
        document.cookie =
          allCookies[i] + "=;expires=" + new Date(0).toUTCString();
      this.props.history.go("/");
    }
  };

  goToHome = () => {
    if (!this.props.logoutDisable) {
      this.props.history.push("/home");
    }
  };

  goToClassroom = () => {
    console.log("in classroom");
    if (!this.props.logoutDisable) {
      this.props.history.push("/classroom");
    }
  };
  goToProfile = () => {
    console.log("in classroom");
    if (!this.props.logoutDisable) {
      this.props.history.push("/profile");
    }
  };

  startLiveAssessment = () => {
    if (!this.props.logoutDisable) this.props.history.push("/liveAssessment");
  }

  startLiveSurvey = () => {
    if (!this.props.logoutDisable) this.props.history.push("/liveSurvey");
  }
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }
  render() {
    return (
      <FixedHeaderDiv className={classes.layoutdiv} style={{opacity: '1'}}>
        <Flex style={{overflow: 'hidden'}} row>
          <Flex style={{overflow: 'hidden'}}  row>
            <LogoDiv onClick={this.goToHome}>
                <img style={{borderRadius: '50px', marginTop: '-10px'}} width="200" height='120' src='/rmklogo.png' alt="logo"></img>
                <div style={{position: 'absolute', top: '60px', left: '100px', fontSize: '14px', fontWeight: 'bold', color: 'rgb(53, 41, 41)', fontStyle: 'italic'}}>Powered by Edwisely</div>
            </LogoDiv>
            {/* <IconsDiv>
                <img src='/images/Points.svg'></img>
            </IconsDiv>
            <TextDiv>
                <Text>20 points</Text>
            </TextDiv>
            <IconsDiv>
                <img src='/images/Level.svg'></img>
            </IconsDiv>
            <TextDiv>
                <Text>Level 1</Text>
            </TextDiv> */}
          </Flex>
          {!this.props.dontShowIcons ? (
            <Flex row style={{ margin: "0 10px 0 auto" }}>
              <IconsDivRight
                onClick={this.goToHome}
                style={{ marginTop: "34px", width: "120px" }}
              >
                <img
                  width="120"
                  height="36"
                  src="/images/dashboard_icon.svg"
                  alt="dashboard"
                ></img>
              </IconsDivRight>
              <IconsDivRight
                onClick={this.startLiveAssessment}
                style={{
                  marginTop: "40px",
                  cursor: this.props.logoutDisable ? "auto" : "pointer",
                  textAlign: "center",
                  width: "60px",
                }}
              >
                <img
                  src="/images/live_test.png"
                  width="25"
                  alt="live assessment"
                ></img>
                <p
                  style={{
                    fontSize: "10px",
                    lineHeight: "0.7rem",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Live Assessment
                </p>
              </IconsDivRight>
              <IconsDivRight
                onClick={this.startLiveSurvey}
                style={{
                  marginTop: "40px",
                  cursor: this.props.logoutDisable ? "auto" : "pointer",
                  textAlign: "center",
                  width: "30px",
                }}
              >
                <img
                  src="/images/survey_icon.png"
                  width="25"
                  alt="live assessment"
                ></img>
                <p
                  style={{
                    fontSize: "10px",
                    lineHeight: "0.7rem",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Live Survey
                </p>
              </IconsDivRight>
              {/* <IconsDivRight style={{marginTop: '38px'}}>
                            <img src='/images/Live_class.svg'></img>
                        </IconsDivRight> */}
              <IconsDivRight
                onClick={this.goToClassroom}
                style={{ marginTop: "40px" }}
              >
                <img src="/images/Live_class.svg" alt="classroom"></img>
              </IconsDivRight>
              <IconsDivRight
                onClick={this.goToProfile}
                style={{ marginTop: "34px" }}
              >
                <img
                  width="32"
                  src="/images/profile/profile_icon.png"
                  alt="profile"
                ></img>
              </IconsDivRight>
              <IconsDivRight
                style={{ width: "50px", height: "36px", marginTop: "38px" }}
              >
                {/* <img width = '96px' height = '36px' src='/images/YLP_cta.svg'></img> */}
                <div
                  onClick={this.onLogout}
                  style={{
                    color: "#ffffff",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Logout{" "}
                </div>
              </IconsDivRight>

              {/* <IconsDivRight style={{marginTop: '38px', marginRight: '0px'}}>
                            <img src='/images/Menu.svg'></img>
                        </IconsDivRight> */}
            </Flex>
          ) : null}
        </Flex>
      </FixedHeaderDiv>
    );
  }
}

export default withRouter(Layout);
