import React, { Component } from "react";
import styles from "./StartPage.module.css";
import AssessmentInfo from "../../components/CodingComponents/components/AssessmentInfo/AssessmentInfo";
import AppLayout from "../../components/Layout/Layout";

import { withRouter } from "react-router-dom";
import axios from "axios";

class StartPage extends Component {
  state = {
    assessment_title: "",
    portion: "",
    time: 0,
    problem: "",
    isDataLoaded: false,
    testStatus: "Loading...",
    total_assessments: 0,
  };

  componentDidMount() {
    let test_id = this.props.location.state.test_id;
    // let CORS = "https://cors-anywhere.herokuapp.com/";
    const practice = this.props.location.state.practice;

    const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
    const url = practice
      ? `getPracticeCodingTest?test_id=${test_id}`
      : `v2/getCodingTest?test_id=${test_id}`;
    let headers = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios.get(`${BASE_API_URL}/codeEditor/${url}`, headers).then((res) => {
      console.log(res.data);
      if (
        res.data.status === 200 &&
        ((res.data?.data?.doe && new Date(res.data.data.doe) > new Date()) ||
          practice)
      ) {
        const data = { ...res.data.data };
        data.practice = practice;
        const problems1 = [...data.problems.map((p) => p)].filter(
          (p) => !p.completion_status
        );
        // const problems1 = [...data.problems.map(p => p)];
        data.problems = [...problems1];
        console.log(data);
        localStorage.setItem("coding_data", JSON.stringify(data));
        localStorage.setItem(
          "coding_test_id",
          JSON.stringify(res.data.data.id)
        );
        let { problems, name, subject_name, timelimit, doe } = res.data.data;

        // let doe_data = moment(doe);
        // let current = moment();
        // if (doe_data.diff(current, "seconds") < timelimit) {
        //   timelimit = doe_data.diff(current, "seconds");
        // }
        this.setState({
          isDataLoaded: true,
          assessment_title: name,
          total_assessments: problems.length,
          time: parseInt(timelimit),
          portion: subject_name,
          doe: doe,
        });
      } else if (
        res.data.status === 401 &&
        res.data.message === "Signature expired. Please log in again."
      ) {
        headers = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
          },
        };
        axios.get(`${BASE_API_URL}/auth/refresh`, headers).then((refres) => {
          localStorage.setItem("token", refres.data.token);
          headers = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          axios
            .get(`${BASE_API_URL}/codeEditor/${url}`, headers)
            .then((res) => {
              if (res.status === 200) {
                if (
                  res.data.status === 200 &&
                  ((res.data?.data?.doe &&
                    new Date(res.data.data.doe) > new Date()) ||
                    practice)
                ) {
                  localStorage.setItem(
                    "coding_data",
                    JSON.stringify(res.data.data)
                  );
                  localStorage.setItem(
                    "coding_test_id",
                    JSON.stringify(res.data.data.id)
                  );
                  const { problems, name, subject_name, timelimit, doe } =
                    res.data.data;
                  this.setState({
                    isDataLoaded: true,
                    assessment_title: name,
                    total_assessments: problems.length,
                    time: parseInt(timelimit),
                    portion: subject_name,
                    doe: doe,
                  });
                } else {
                  this.setState({ testStatus: "Completed!" });
                }
              }
            });
        });
      } else {
        this.setState({ testStatus: "Completed!" });
      }
    });
  }

  openFullscreen = () => {
    try {
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    } catch (error) {
      console.log(error);
    }
  };

  startAssessment = () => {
    this.openFullscreen();
    this.props.history.push("/codingAssessment");
  };

  render() {
    let assessmentBtn = this.state.isDataLoaded ? (
      <a href={"/codingAssessment"} className={styles.button}>
        Start Assessment
      </a>
    ) : (
      <h3>{this.state.testStatus}</h3>
    );
    console.log(this.state.time);

    return (
      <div>
        <AppLayout></AppLayout>

        <div className={styles.StartPage}>
          <div className={styles.card}>
            <div>
              <AssessmentInfo
                title={this.state.assessment_title}
                portion={this.state.portion}
                time={this.state.time}
                doe={this.state.doe}
                qcount={this.state.total_assessments}
              />
              <h4 className={styles.h4class}>Points to Remember</h4>
              <ul>
                <li>
                  {!this.props.location.state.practice
                    ? "This is a timed test. "
                    : null}
                  Please make sure you are not interrupted during the test, as
                  the timer cannot be paused once started.
                </li>
                <li>Please ensure you have a stable internet connection.</li>
                <li>Different problems may carry different marks.</li>
              </ul>
            </div>
          </div>
          {assessmentBtn}
        </div>
      </div>
    );
  }
}
export default withRouter(StartPage);
