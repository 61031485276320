import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'

const ActiveStudentListItem = ({data}) => {
    return (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
                    <Avatar alt="Avatar" sx={{ width: 30, height: 30 }} src={data.profile_pic} />
                    <Typography component={'p'} sx={{
                        fontFamily: 'Poppins-Medium',
                        fontSize: '14px',
                        textAlign:'start',
                        color: (theme) => theme.palette.grey[900]
                    }}>{data.name}</Typography>
                    {/* .split(' ').slice(0, 2).join(' ') */}
                </Stack>

            {/* marks */}
            
            <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
                <Typography component={'p'} sx={{
                    fontFamily: 'Poppins-SemiBold',
                    fontSize: '14px',
                    color: (theme) => theme.palette.primary[800]
                }}>77/100</Typography>
            </Stack>

        </Stack>
    )
}

export default ActiveStudentListItem