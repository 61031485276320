import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import RemedialContainer from '../../features/RemedialPath/components/RemedialContainer/RemedialContainer'
import RemedialHeader from '../../features/RemedialPath/components/RemedialHeader/RemedialHeader';
import TimerCard from '../../features/RemedialPath/components/TimerCard/TimerCard';
import ActiveStudentsCard from '../../features/RemedialPath/components/ActiveStudentsCard';
import StudyProgressCard from '../../features/RemedialPath/components/StudyProgressCard/StudyProgressCard';
import SpinWheelCard from '../../features/RemedialPath/components/SpinWheelCard/SpinWheelCard';
import RemedialStudyContent from '../../features/RemedialPath/components/RemedialStudyContent/RemedialSudyContent';
import { useRemedialContext } from '../../features/RemedialPath/hooks/useRemedial';
import useFetch from '../../hooks/useFetch';
import { fetchDataFromApi } from '../../helpers/Api';
import { useParams } from 'react-router-dom';
import WheelUnlockedModal from '../../features/RemedialPath/components/WheelUnlockedModal/WheelUnlockedModal';
import { updateBannerApi } from '../../helpers/updateBanner';

const RemedialStudyPage = ({ endpoint }) => {
    const { timer, bannerData, testResultPageData, studyPageData, setStudyPageData, setStudyProgress, studyProgress, setBannerData, updateBannerIndex,setTestData } = useRemedialContext();
    const { data } = useFetch(endpoint)
    const [spinWheelCompleted, setSpinWheelCompleted] = useState(0);
    const [pageData, setPageData] = useState(false);
    // console.log(bannerData.test_completed,"++++++++++++++++++++++++++++++++++++++" )
    const { testId } = useParams();
    let { user_id } = JSON.parse(localStorage.getItem("user"))


    const handleRemedialStudyData = (studyData) => {
        let studyDataArray = [];
        // first Map 
        studyData.forEach((item1) => {
            let firstMapData = { ...item1 };
            delete firstMapData.topics
            studyDataArray.push({ ...firstMapData });
            // Second Map 
            item1.topics.forEach((item2) => {
                let secondMapData = { ...item2 };
                delete secondMapData.subTopics
                studyDataArray.push({ ...secondMapData });
                // Third Map 
                item2.subTopics.forEach((item3) => {
                    studyDataArray.push(item3);
                })
            })
        })
        return studyDataArray.filter((item) => item.code)
    }


    useEffect(() => {
        if (data) {
            setPageData(data)
            // console.log(data)
            // console.log('ueifhiewhewiuguig___-----------------')
            setTestData(data.fluke_percentage_test)
            const array = data?.topics;
            const newArray = []
            array.forEach((item, index) => {
                let value = { ...item, noOfReading: 0, noOfVideos: 0, noOfSnippets: 0 }
                newArray.push(value)
            })
            setStudyPageData(newArray)
            
            if (data?.spin_wheel_completed === 'true') {
                setSpinWheelCompleted(1);
            }
            else {
                setSpinWheelCompleted(0);
            }
        }

    }, [data])


    useEffect(() => {
        fetchDataFromApi(`/questionnaire/v2/getRemedialStudyProgress?test_id=${testId}&student_id=${user_id}`).then(
            ({ data }) => {
                setStudyProgress(data.progress_percentage)
            }
        )

    }, [])


    // const BannerUpdateCall = () => {
    //     updateBannerApi(testId ,bannerData.stages[2].id)
    // }


    const handleUpdateBanner = async () => {
        updateBannerIndex(2);
        const res = await updateBannerApi(testId, bannerData.stages[2].id);
        const { status } = await res.data
        if (status === 200) {
            const { data } = await fetchDataFromApi(`/questionnaire/v2/getRemedialPathStages?test_id=${testId}`);

            setBannerData(data)
        }
    }



    return (
        <RemedialContainer>
            <Stack sx={{ gap: '12px' }}>
                {pageData ?
                <RemedialHeader heading={'Remedial Study '} variant='page-header' percentage={pageData.sections} total={pageData.total_questions} />:null}
                <Stack sx={{ gap: '24px' }}>
                    <Grid container padding={0} spacing={'20px'}>
                        <Grid xs={2}><TimerCard timeLeft={timer} /></Grid>
                        <Grid xs={2.5}><ActiveStudentsCard bannerData={bannerData} /></Grid>
                        <Grid xs><StudyProgressCard studyProgress={studyProgress} /></Grid>
                        <Grid xs={3}>
                            <SpinWheelCard
                                isActive={studyProgress > 75 ? true : false}
                                updateBanner={() => { handleUpdateBanner() }} 
                                getCategoryEndPoint={`/questionnaire/v2/getRemedialPatCategories?questionnaire_id=${testId}`}
                                isCompleted={bannerData.test_completed || (spinWheelCompleted)}
                                />
                        </Grid>
                    </Grid>
                    <RemedialStudyContent studyData={studyPageData} />
                </Stack>
            </Stack>
        </RemedialContainer>
    )
}

export default RemedialStudyPage