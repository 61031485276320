import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const RedmedialButton = (
    { children,
        variant = 'contained',
        size = 'large',
        fullWidth = false,
        disable = false,
        color = 'externalerror.200',
        textColor = 'primary.contrastText',
        borderRadius = 10,
        textSize,
        onClick,
        href,

        ...props
    }
) => {
    return (
        <>
            {
                href ?
                    <Link to={href} style={{display: 'inline-block' , width:'100%'}}>
                        <Button
                            onClick={onClick}
                            variant={variant}
                            size={size}
                            fullWidth={fullWidth}
                            disabled={disable}
                            sx={{
                                backgroundColor: color,
                                color: textColor,
                                borderRadius: `${borderRadius}px`,
                                cursor: 'pointer',
                                fontSize: textSize ? `${textSize}px` : '',
                                '&:hover': {
                                    backgroundColor: color,
                                    color: textColor
                                }
                            }}
                        >{children}</Button>
                    </Link>
                    :
                    <Button
                        href={href}
                        onClick={onClick}
                        variant={variant}
                        size={size}
                        fullWidth={fullWidth}
                        disabled={disable}
                        sx={{
                            backgroundColor: color,
                            color: textColor,
                            borderRadius: `${borderRadius}px`,
                            cursor: 'pointer',
                            fontSize: textSize ? `${textSize}px` : '',
                            '&:hover': {
                                backgroundColor: color,
                                color: textColor
                            }
                        }}
                    >{children}</Button>
            }
        </>
    )
}

export default RedmedialButton