import { useEffect, useState, React } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { getCodingStatusAC} from "../../store/actions/codingTrackActions";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom/";

const CodingScreen = (props) => {
  const history = useHistory();
  const [codingStatusData, setCodingStatusData] = useState([]);

  useEffect(() => {
    const getCodingStatusA = async () => await props.getCodingStatus();
    getCodingStatusA();
  }, []);

  useEffect(() => {
    if (props.codingTrackStatus && Object.keys(props.codingTrackStatus).length > 0 && !props.loading_coding_status) {
      setCodingStatusData(props.codingTrackStatus);
      if (props.codingTrackStatus.current_track === 0 || props.codingTrackStatus.display_question === 0) {
        history.push("/codingTrackLevel");
      }
      else {
        history.push("/codingTrackConsole", {'question_id': props.codingTrackStatus.display_question, "display_question": props.codingTrackStatus.display_question});
      }
    }
  }, [props.codingTrackStatus, props.loading_coding_status]);

  return (
    <>
      {props.loading_coding_status 
      ?
        <Typography>Loading</Typography>
      :
        null
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    codingTrackStatus: state.codingTrack.codingTrackStatus,
    error_loading_codingTracks: state.codingTrack.error_loading_codingTracks,
    loading_coding_status: state.codingTrack.loading_coding_status,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCodingStatus: () => dispatch(getCodingStatusAC()),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CodingScreen)
);
