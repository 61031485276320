import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import {getTestResultsDataAC} from '../../store/actions/questionnaireResultsActions';
import AppLayout from '../../components/Layout/Layout';
import moment from 'moment';
import Flex from 'styled-flex-component';
import styled from 'styled-components';
import Equation from '../../components/Equation';
import { BarChart, Bar, Cell, XAxis, YAxis, Scatter, ResponsiveContainer, Text, ComposedChart, Tooltip,
    Legend} from 'recharts';
import GaugeChart from 'react-gauge-chart';
import ReportDialog from '../../components/reportModal';
import Modal1 from './Modal';
import Modal from "react-bootstrap/Modal";
import axios from '../../interceptor';
import Layout1 from "../../components/Layout/Layout1";
import {MdArrowBackIos} from "react-icons/md"
import Resultsrightpanel from "../../components/ResultsRightPane";
import NotificationPrompt from '../../components/common/NotificationPrompt';
const Rectangle = () => (
    <svg height="220" width="320" fill='#fdfdfd'>
        <polygon points="320,10 320,160 160,220 10,160 10,10 320,10">
        </polygon>
        Sorry, your browser does not support inline SVG.
    </svg>
);

const RectangleWrapper = styled.div`
    // color: #FFFFFF;
    position: absolute;
    top: 100px;
    right: 3%;
    svg {
        // position: absolute;?
        display: block;
        // fill: #FFFFFF;
        width: 100%;
        height: 100%;
        filter: drop-shadow(5px 10px 20px #eee);
        polygon {
            stroke: #FFFFFF;
            // fill: #FFFFFF;
            stroke-width: 1px;
            transition: all 0.3s esae;
        }
        transition: all 0.3s esae;
    }
`

const GaugeContainer = styled.div`
    // position: absolute;
    // width: 286px;
    // height: 213px;
    right: 8px;
    top: 0px;
    // background: url("/images/timer/guage_container.svg");
    background-size: cover;
    padding: 20px;
    box-sizing: border-box;
`
const Button=styled.div`
    text-align: left;
    height: 24px;
    // background: pink;
    // width: 57px;
    padding-left: 3px;
    border-radius: 10px;
    cursor:pointer;
    font-size: 16px;
`
class Results extends Component {
    state = {
        reportItemId: null,
        openReportDialog: false,
        itemToBeReported: null,
        errorLoadingTestDetails: false
    }
    componentDidMount = async () => {
        let test_id;
        if (this.props.location?.state?.test_id) {
            test_id = this.props.location.state.test_id;
        }
        // console.log(this.props.location)
        let token = localStorage.getItem('token')
        if (!test_id) {
            if (window.location.search) {
                let search = window.location.search.slice(1);
                let queryParams = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,
                  '":"') + '"}');
                test_id = queryParams.test_id;
                token = queryParams.token;
                localStorage.setItem('token', token);
            }
        }
        // if(test_id) await this.props.getTestResultsAnalyticsDetails(test_id);
        if(test_id) await this.props.getTestDetails(test_id);
        else if(!test_id && !this.state.errorLoadingTestDetails) this.setState({errorLoadingTestDetails: true});
    }

    reportItem = (item, id)=> {
        // document.body.style.overflow = 'hidden';
        this.setState({openReportDialog: true, itemToBeReported: item, reportItemId: id});
    }

    closeReport = ()=> {
        document.body.style.overflow = 'auto';
        this.setState({openReportDialog: false, itemToBeReported: null, reportItemId: null});
    }
    openSolution = async (event, qid) => {
        document.body.style.overflow = 'hidden';
        let image_url = this.props.results.questions[qid].solution_image;
        let solution = this.props.results.questions[qid].solution;
        let response, template;
        if (image_url) {
            response = await axios.get(`${image_url}`);
            template = response.data
        }
        console.log(solution)
        console.log(template)
        this.setState({openSolutionDialog: true, question_solution_index: qid, solution_data: solution, solution_template: template});
    }
    closeSolution = () => {
        document.body.style.overflow = 'auto';

        this.setState({openSolutionDialog: false, question_solution_index: null, solution_template:null, solution_data: null});
    }

    goToRemedial = () => {
        this.props.history.push({
            pathname: "/remedial",
            state: { test_id: this.props.location?.state?.test_id },
        });
    }
    goback= () => {
        this.props.history.push({
            pathname: '/home',
        });
    }
    render () {
        console.log("------------results");
         console.log(this.props.summary);
         console.log(this.props.topic_wise_analysis_data);
        if (this.props.loading && !this.state.errorLoadingTestDetails) {
            return <div>Loading....</div>
        }
        if (this.props.errorLoadingTestDetails || this.state.errorLoadingTestDetails) {
           return <div>Error loading Test Details Please try again after some time </div>
        }
        let option_numbers = {0: 'a', 1: 'b', 2: 'c', 3: 'd', 4: 'e'};
        let results = this.props.results;
        let timelimit = results.timelimit;
        let hours = Math.floor(timelimit / (60 * 60));
        let seconds = (timelimit - hours * 60 * 60);
        let minutes;
        if (seconds > 60) {
            minutes = Math.floor(seconds/60);
            seconds = seconds - (minutes * 60);
        }
        let timelimit_modified = minutes && hours && seconds ? hours + 'hrs ' + minutes + 'mins ' + seconds + 'secs' : minutes && !hours && seconds ? minutes + 'mins ' + seconds + 'secs' : minutes  && !hours && !seconds ? minutes + 'mins' : hours && seconds && !minutes ? hours + 'hrs ' + seconds + 'secs' : !minutes && hours && !seconds ? hours + 'hrs' : !minutes && !hours && seconds ? seconds + 'secs' : hours && minutes && !seconds ? hours + 'hrs ' + minutes + 'mins' : '0';
        let marks = (this.props.summary.correct_answered_count ? this.props.summary.correct_answered_count : 0 )/ this.props.summary.total_questions_count;
        let sections = this.props.results.sections;
        let test_details = this.props.summary.test_details;
        
        return (
            <div>
                  <Modal show={this.state.openReportDialog} onHide={this.closeReport}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:"16px"}}>Please select issue to be reported</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <ReportDialog
            open={this.state.openReportDialog}
            close={this.closeReport}
            reportItem={this.state.itemToBeReported}
            reportItemId={this.state.reportItemId}
          ></ReportDialog></Modal.Body>
      </Modal> 
                {/* {this.state.openReportDialog ? <ReportDialog 
                    open={this.state.openReportDialog} 
                    close={this.closeReport} 
                    reportItem={this.state.itemToBeReported}
                    reportItemId={this.state.reportItemId}>
                </ReportDialog> : null} */}
                {this.state.openSolutionDialog ? <Modal1 marginTop={'50px'} height={'75%'} width={'70%'} open={this.state.openSolutionDialog} closeDialog={this.closeSolution}>
                    <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '20px', marginTop: '20px'}}>Solution</div>
                    <div style={{borderBottom: '1px solid #ccc', margin: '10px 0'}}></div>
                    <div style={{margin: '10px', textAlign: 'left', fontSize: '18px', display: 'flex', flexDirection: 'row'}}>
                        <Equation fontSize={'14px'} math_type={1} equation={this.state.solution_data}></Equation>
                    </div>
                    <div style={{margin: '10px', textAlign: 'left'}}>
                        <Equation fontSize={'14px'} math_type={1} equation={this.state.solution_template}></Equation>
                    </div>
                </Modal1> : null}
                <div style={{ overflow: "hidden", width: "100%", justifyContent: "space-between", margin: "0px" }} className="row">
                {/* <Layout1></Layout1> */}
                    <div className='resultsmaindiv' style={{paddingLeft: "40px", width: "77%",boxShadow: '5px 10px 20px #eee', borderRadius: '11px',padding: '20px', backgroundColor: 'white'}}>

                        <div style={{borderBottom: '1px solid #00000040', paddingBottom: '15px',display:"flex",flexDirection:"column"}}>
                        <Button onClick={this.goback}><MdArrowBackIos />Back</Button>
                            <div style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>{results.name}</div>
                            <div style={{display: 'flex', flexDirection: 'row', margin: '10px 0', fontSize: '12px'}}>
                                <div style={{marginRight: '15px'}}>Objective</div>
                                <div style={{borderRight: '1px solid #000'}}></div>
                                <div style={{margin: '0 15px'}}>{moment(results.created_at).format('Do MMMM')}</div>
                                <div style={{borderRight: '1px solid #000'}}></div>
                                <div style={{margin: '0 15px'}}>Start Date : {moment(test_details.start_time).format('Do MMM hh:mm A')}</div>
                                <div style={{borderRight: '1px solid #000'}}></div>
                                <div style={{margin: '0 15px'}}>Expiry Date : {moment(test_details.doe).format('Do MMM hh:mm A')}</div>
                                <div style={{borderRight: '1px solid #000'}}></div>
                                <div style={{marginLeft: '15px'}}>
                                    <img style={{margin: 'auto'}} src='/images/test_timer.png'></img>
                                </div>
                                <div style={{margin: '0 15px'}}>{timelimit_modified}</div>
                            </div>
                            <div>
                                {/* <RectangleWrapper>
                                    <Rectangle />

                                </RectangleWrapper>
                                <div style={{position: 'absolute', right: '4.5%', top: '120px', width: '260px'}}>
                                    <img width='160px' src='/images/Group 19.svg'></img>
                                    <div style={{marginTop: '-50px', fontSize: '36px', color:'#4E54C8'}}>{this.props.summary.correct_answered_count ? this.props.summary.correct_answered_count : 0} / {this.props.summary.total_questions_count}</div>
                                    <div style={{fontSize: '14px'}}>Your marks</div>
                                    <div style={{whiteSpace: 'nowrap', marginLeft: '10px', marginTop: '10px'}}>Your average percentage is {this.props.summary.percentage}%</div>
                                </div> */}
                                {/* <GaugeContainer>
                                    <div style={{ height: 115, overflow: 'hidden' }}>

                                        <GaugeChart id="gauge-chart5"
                                            animate={false}
                                            nrOfLevels={100}
                                            arcsLength={[0.1, 0.25, 0.25, 0.25, .15]}
                                            colors={['#D76D6B', '#FFA287', '#FFC200', '#23DD75', '#00D0FF']}
                                            percent={marks}
                                            textColor={'#6852CB'}
                                            arcPadding={0.01}
                                            cornerRadius={5}
                                            formatTextValue={r => Math.round(r * this.props.summary.total_questions_count/100) + `/${this.props.summary.total_questions_count}`}
                                            style={{ height: 150, color: '#000', fontWeight: 700 }}
                                        />
                                    </div>
                                    <p style={{ textAlign: 'center', margin: 0, fontSize: 14, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Correct Answers</p>
                                    <p style={{ textAlign: 'center', margin: 0, fontSize: 12 }}> Your average percentage is {this.props.summary.percentage}%</p>
                                </GaugeContainer> */}
                            </div>
                            {sections && sections.length > 1 ? <div style={{}}>
                            <div style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>Sections</div>
                            <div  style={{boxShadow: 'rgb(238 238 238 / 28%) 0px -1px 0px 2px', borderRadius: '11px', padding: '10px'}}>
                                {sections.map((sec)=> {
                                    return <div style={{textAlign: 'left', display: 'flex', flexDirection: 'row'}}>
                                        <div style={{width: '2px',height: '2px', backgroundColor: 'black', borderRadius: '50%', border: '1px solid black'}}></div>
                                        <div>
                                            <div style={{fontSize:"14px"}}><span style={{}}>Name </span> : {sec.name}</div>
                                            <div style={{fontSize:"14px"}}><span style={{}}>Marks</span> : {sec.marks}</div>
                                        </div>
                                    </div>
                                })}
                            </div>   
                        </div> : null}
                        </div>
                        {/* <div className='w-100 text-left mt-2' >
                            <button className='btn btn-primary' onClick={this.goToRemedial}>Get Personalized Remedial Path</button>
                        </div> */}
                      <div className='row'>
                        <div style={{marginTop: '10px',width:"50%"}} >
                            <div style={{textAlign: 'center', fontSize: '14px', fontWeight: '600', margin: '10px 0'}}>Time analysis</div>
                            <div  style={{ borderRadius: '11px', padding: '10px', marginTop: '20px'}}>
                                <ResponsiveContainer width="100%" height={300}>
                                    <ComposedChart
                                        data={this.props.time_analysis_data}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                    <XAxis dataKey="index" tick={false} allowDataOverflow={true}/>
                                    <YAxis 
                                        label={
                                        <Text
                                           x={0}
                                           y={0}
                                           dx={40}
                                           dy={220}
                                           offset={0}
                                           angle={-90}>Time spent ( seconds )</Text>}
                                        />
                                    <Tooltip />
                                    {/* <Legend /> */}
                                    <Bar dataKey="student_time" barSize={8} radius={[8,8,8,8]} stackId="a" fill="#6852CB" />
                                    {/* <Bar dataKey="average_time" barSize={15} radius={[8,8,0,0]} stackId="a" fill="#4E54C8" /> */}
                                    <Scatter dataKey='average_time' fill="#FF723B" shape="circle" />
                                    </ComposedChart>
                                </ResponsiveContainer>
                                <div style={{display: 'flex', flexDirection: 'row',margin: '-32px 0',justifyContent:"center"}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div style={{width: '10px', height: '10px', borderRadius: '50%', background: '#6852CB', margin: '5px 20px'}}></div>
                                        <div>Time Taken</div>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div  style={{width: '10px', height: '10px', borderRadius: '50%', background: '#FF723B',  margin: '5px 20px'}}></div>
                                        <div>Average Time</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div  style={{marginTop: '10px',width:"50%"}}>
                            <div style={{textAlign: 'center', fontSize: '14px', fontWeight: '600', margin: '10px 0'}}>Topic wise analysis</div>
                            <div  style={{ borderRadius: '11px', padding: '10px', marginTop: '20px'}}>
                                <ResponsiveContainer width="100%" height={400}>
                                    <BarChart
                                        data={this.props.topic_wise_analysis_data}
                                        margin={{
                                            top: 20,
                                            right: 70,
                                            left: 20,
                                            bottom: 100,
                                        }}
                                    >
                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                  <XAxis dataKey="name" tick={false} angle={35} textAnchor="start" interval={0}
                                    /> 
                                    <YAxis 
                                        label={
                                        <Text
                                           x={0}
                                           y={0}
                                           dx={30}
                                           dy={180}
                                           offset={0}
                                           angle={-90}>No of Questions</Text>}
                                        />
                                    <Tooltip />
                                    {/* <Legend /> */}
                                    <Bar dataKey="total questions count" barSize={8} radius={[8,8,8,8]}  stackId="a" fill="#4E54C8" />
                                    <Bar dataKey="correct questions count" barSize={8} radius={[8,8,8,8]}  stackId="a" fill="#A0F4FF" />
                                    </BarChart>
                                </ResponsiveContainer>
                                <div style={{display: 'flex', flexDirection: 'row',margin: '-131px 0px',justifyContent:"center"}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div style={{width: '10px', height: '10px', borderRadius: '50%', background: '#A0F4FF', margin: '5px 20px'}}></div>
                                        <div>Correct answers</div>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div  style={{width: '10px', height: '10px', borderRadius: '50%', background: '#4E54C8',  margin: '5px 20px'}}></div>
                                        <div>Total Questions</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        </div>
                        <div style={{marginTop: '55px'}}>
                            <div style={{textAlign: 'left', fontSize: '16px', fontWeight: '600', margin: '20px 0'}}>Your answer analysis</div>
                            {results.questions.map((que, qid)=> {
                                return <div>
                                    <Flex row style={{marginLeft: '15px'}}>
                                        <div style={{fontWeight: 'bold', fontSize: '16px', marginRight: '8px', width: '2%'}}>
                                            {qid + 1}.
                                        </div>
                                        <Flex column style={{marginLeft: '10px', width: '98%'}}>

                                            <Flex column style={{marginBottom:'20px', marginRight: '10px',textAlign: 'left'}}>
                                                <Flex row>
                                                    <Flex column style={{width: que.solution || que.solution_image ? '85%' : '95%'}}>
                                                        <Equation fontSize={'14px'} blooms={que.blooms_level} math_type={que.math_type} bold equation={que.name}></Equation>
                                                        {que.question_img && que.question_img != ' ' ? 
                                                            <div style={{width: '324px', height: '216px', margin: '10px'}}>
                                                                <img width='324px' height='216px' src={que.question_img}></img>
                                                            </div>: null}
                                                    </Flex>
                                                    <div style={{width: '5%', alignContent: 'flex-end'}}>
                                                        <div style={{width: '100%'}}>
                                                            <img style={{marginLeft: '0',cursor:"pointer"}} onClick={()=>this.reportItem('objective_questions', que.id)} src='/images/report.svg'></img>
                                                        </div>
                                                    </div>
                                                    {/* <div style={{width: '10%'}}>
                                                        <div style={{width: '100%'}}>
                                                            {que.solution || que.solution_image ? <div style={{color: '#4e54c8'}} onClick={(event)=>this.openSolution(event, qid)}>Solution</div> : null}
                                                        </div>
                                                    </div> */}
                                                </Flex>
                                                
                                            </Flex>
                                        
                                            <div>
                                            {que.test_questions_options.map(
                                                ({ id, name, media, option_img, is_answer, selected_option_id }, i) => {
                                                let is_selected = true ? selected_option_id == 1 : false;
                                                let is_correct_selected = is_selected && is_answer == 1;
                                                let is_incorrect = is_selected && is_answer == 0;
                                                let is_correct = true ? is_answer == 1 : false;
                                                
                                                return (
                                                    <Flex row style ={{margin: '10px',marginBottom: '10px', alignItems:'center', color: is_selected ? '#4E54C8' : null, fontWeight: is_selected ? '600' : '500'}}> 
                                                        {is_correct_selected || is_correct ? <div style={{width: '24px', height: '24px', marginRight: '10px', marginLeft: '-34px'}}>
                                                            <img src='/images/Correct_answer.svg' width="20"></img>
                                                        </div>: null}
                                                        {is_incorrect ? <div style={{width: '24px', height: '24px', marginRight: '10px', marginLeft: '-34px'}}>
                                                            <img src='/images/Wrong_answer.svg' width="20"></img>
                                                        </div>: null}

                                                        <Flex row key={i} >
                                                            <div>{option_numbers[i]})</div>
                                                            <div>
                                                                {media === 1 ? <div style={{margin: '10px',marginTop: '0px', width: '150px', height: '100px'}}>
                                                                    <img width=  '150px' height = '100px' src={option_img} alt="" />
                                                                    </div> : null}
                                                            </div>
                                                            <div style = {{margin: 'auto',marginLeft: '10px'}}>
                                                                <Equation fontSize={'14px'} bold={is_selected} answered={is_selected} math_type={que.math_type} equation={name}></Equation>
                                                            </div>
                                                        </Flex>
                                                    </Flex>)
                                                }
                                                )}
                                            </div>
                                        
                                        </Flex>
                                    
                                    </Flex>
                                <div style={{marginLeft: '30px'}}>
                                    {/* <div style={{borderBottom: '1px solid #DDDDDD', margin: '10px 0', width: '100%'}}></div> */}
                                    <div style={{textAlign: 'center', fontSize:'14px', fontWeight: '400'}}>{que.section_id && sections && sections.length > 0 ? `Section : ${sections[sections.findIndex((sec, index)=>sec.id == Number(que.section_id))].name} ` : '' } &nbsp;&nbsp;| &nbsp;&nbsp; Max Score : { sections && sections.length > 0 ? 1 * sections[sections.findIndex((sec, index)=>sec.id == que.section_id)].marks : 1} &nbsp;&nbsp;| &nbsp;&nbsp;<span style={{color: '#4F55C8', fontSize: '16px', fontWeight: '600'}}>Your Score</span> : {que.correctly_answered * (sections && sections.length > 0 ? sections[sections.findIndex((sec, index)=>sec.id == que.section_id)].marks : 1)}</div>
                                </div>
                                <div style={{borderBottom: '1px solid rgb(0 0 0 / 27%)', margin: '13px', width: '100%'}}></div>
                            </div>
                            })}
                        </div>
                    </div>
                    <div  className="rightpane"
          style={{
            background: "#f7f7f7",
            width: "23%",
            paddingLeft: "5px",
          }}> 
          <GaugeContainer>
          <div className='gaugechartdiv' style={{overflow: 'hidden' }}>

              <GaugeChart id="gauge-chart5"
                  animate={false}
                  nrOfLevels={100}
                  arcsLength={[0.1, 0.25, 0.25, 0.25, .15]}
                  colors={['#D76D6B', '#FFA287', '#FFC200', '#23DD75', '#00D0FF']}
                  percent={marks}
                  textColor={'#6852CB'}
                  arcPadding={0.01}
                  cornerRadius={5}
                  formatTextValue={r => Math.round(r * this.props.summary.total_questions_count/100) + `/${this.props.summary.total_questions_count}`}
                  style={{ height: 150, color: '#000', fontWeight: 700 }}
              />
          </div>
          <p style={{ textAlign: 'center', margin: "-15px 0px 0px", fontSize: 12}}>Correct Answers</p>
          <p style={{ textAlign: 'center', margin: 0, fontSize: 12,fontWeight:500}}> Your average percentage is {this.props.summary.percentage}%</p>
      </GaugeContainer>
      <Resultsrightpanel data={this.props.objective_analytics} rank={this.props.objective_analytics.performance_leaderboard}
       timespent={this.props.summary.total_time} 
       percentage={this.props.summary.percentage} 
       unanswered={this.props.summary.unanswered_count} 
       test_id={this.props.location?.state?.test_id}
       bonuspoints={`${this.props.results.bonus_points_data.bonus_points_obtained}/${this.props.results.bonus_points_data.out_of}`}
       results={true}
       remedial_present={this.props.results.remedial_path}
       style={{marginLeft:"7px"}} />
      </div>
            </div>
            <NotificationPrompt number={2}/>
            <style jsx scoped>
          {`
          @media only screen and (max-width: 1225px) {
            .resultsmaindiv {
              width:75% !important;
            }
        }
        .gaugechartdiv{
            height:115px;
        }
        @media only screen and (min-width: 1900px) {
            .gaugechartdiv{
                height:245px;
            }
        }
            `}
            </style>
            
            </div>
        )
    }
}

const mapStateToProps = state => {
    console.log("hybvjfwe------------------")
    console.log(state)
    return {
        loading: state.queRes.loading,
        summary: state.queRes.summary,
        results: state.queRes.results,
        errorLoadingTestDetails: state.queRes.errorLoadingTestDetails,
        time_analysis_data: state.queRes.time_analysis_data,
        topic_wise_analysis_data: state.queRes.topic_wise_analysis_data,
        objective_analytics: state.queRes.objective_analytics,
        // loading_result_analytics:state.ques.loading_result_analytics,
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTestDetails: (test_id)=> dispatch(getTestResultsDataAC(test_id)),
        // getTestResultsAnalyticsDetails: (test_id)=> dispatch(getTestResultsAnalyticsAC(test_id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Results));