import React, { useState,useEffect } from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import MuiDrawer from '../../../components/common/MuiDrawer1';
import CareerContent from './CareerContent';
import IntrestedCareer from './IntrestedCareer';
import { motion } from 'framer-motion';

const steps = [
    {
        package: '7,00,000 L.P.A',
        role: 'Entry Level',
    },
    {
        package: '10,00,000 L.P.A',
        role: 'Junior Level',
    },
    {
        package: '13,00,000 L.P.A',
        role: 'Associate',
    },
    {
        package: '15,00,000  L.P.A',
        role: 'Senior',
    },
]

const CareerDrawer = ({open,careerId,nodes,type,onClose}) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isAccordianOpen, setIsAccordianOpen] = useState(false);

    const { careerData,loadingCareerData } = useSelector((state) => state.careerpath);
    useEffect(() => {
      if(open){
        setIsDrawerOpen(true)
      }
      }, [open]);
    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
        onClose(!isDrawerOpen)
    };

    const slides = [
        <Typography>84+ people have shown interest</Typography>,
        <Typography>35,00,000/yr - Average Salary In Amazon</Typography>,
        <Typography>67% - This career fits you</Typography>,
    ]
   const onAccordianhandle=(open)=>{
    if(!open){
        setIsAccordianOpen(open)
    }else{
        setTimeout(() => {
            setIsAccordianOpen(open)
        }, 300);
    }
    
    
   }
    return (
        <>
            <MuiDrawer 
                open={isDrawerOpen} 
                onClose={toggleDrawer} 
                drawerWidth={'400px'}
                drawerContent={
                    <>
                       <CareerContent careerId={careerId} onAccordianhandle={onAccordianhandle} />
                       {careerData?.student_interested==0  ?
                        // <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{delay:2,duration:0.8}}>
                            <IntrestedCareer careerData={careerData.job} fit={careerData.fit} accordian={isAccordianOpen} nodes={nodes} type={type} />
                        // </motion.div>
                    :null}
                    </>
                }
            />
        </>
    )
}

export default CareerDrawer;