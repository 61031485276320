import { useState, useEffect } from 'react';

const useTimeSpentTimer = () => {
  const [startTime, setStartTime] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isRunning, setIsRunning] = useState(true);

  const startTimer = () => {
    if (isRunning) {
      setStartTime(Date.now() - elapsedTime * 1000);
    }
  };

  const stopTimer = () => {
    if (isRunning) {
      const now = Date.now();
      const elapsedTimeInSeconds = Math.floor((now - startTime) / 1000);

      // Reset timer
      setStartTime(now);
      setElapsedTime(0);

      return elapsedTimeInSeconds;
    }
    return 0;
  };

  useEffect(() => {
    if (isRunning) {
      const interval = setInterval(() => {
        setElapsedTime(prevElapsedTime => prevElapsedTime + 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isRunning]);

  return { startTimer, stopTimer };
};

export default useTimeSpentTimer;