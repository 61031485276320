import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import { getLanguagesListAC, itemUpdateRemoveAddAC } from '../../store/actions/profileActions';

const SaveButton = styled.button`
    border-radius: 32px;
    background:  ${({ active }) => (active ? "linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%)" : "grey")};
    margin: auto;
    text-align: center;
    width: 98px;
    padding: 8px;
    color: white;
    margin-right: 0;
    border: none;
`

class AddLanguage extends Component {

    state = {
        openContent : false,
        contentUrl: '',
        contentType: null,
    }

    componentDidMount = () => {
        this.props.getLanguagesList();
    }

    changeLanguage = (event)=> {
        event.preventDefault();
        console.log(event.target.value);
        console.log(event.target.id);
        console.log(event.target.key);
        let name = event.target.value;
        let index = [...this.props.languages_list].findIndex((lang)=> {
            return lang.name == name;
        })
        let id = this.props.languages_list[index].id

        this.setState({new_language: name, new_language_id:id, Reading: 0, Writing: 0, Listening: 0, Speaking: 0});
    }

    increaseProficiency = (type, num) => {
        console.log('increase', num)
        let orig_num = this.state[type];
        let new_num = orig_num + num;
        if (type == 'Reading') {
            this.setState({Reading: new_num})
        }
        if (type == 'Writing') {
            this.setState({Writing: new_num})
        }
        if (type == 'Listening') {
            this.setState({Listening: new_num})
        }
        if (type == 'Speaking') {
            this.setState({Speaking: new_num})
        }
    }

    decreaseProficiency = (type, num) => {
        console.log('decrease', num)
       
        if (type == 'Reading') {
            this.setState({Reading: num})
        }
        if (type == 'Writing') {
            this.setState({Writing: num})
        }
        if (type == 'Listening') {
            this.setState({Listening: num})
        }
        if (type == 'Speaking') {
            this.setState({Speaking: num})
        }
    }

    closeLanguageEditor = () => {
        this.setState({
            new_language: null,
            new_language_id: null,
            Reading: null, Writing: null, Listening: null, Speaking: null
        });
        this.props.clearStatus();
        this.props.close();
    }

    addLanguageHandler = () => {
        const formdata = new FormData();
        formdata.append('language_id', this.state.new_language_id);
        formdata.append('read', this.state.Reading);
        formdata.append('write', this.state.Writing);
        formdata.append('listening', this.state.Listening);
        formdata.append('speaking', this.state.Speaking);

        let item = {};
        item.type = 'ADD_LANGUAGE';
        item.name = this.state.new_language;
        item.id = this.state.new_language_id;
        item.read = this.state.Reading;
        item.write = this.state.Writing;
        item.listening = this.state.Listening;
        item.speaking = this.state.Speaking;

        this.props.addLanguage(item, formdata);
    }

    render () {
        console.log(this.state);
        if (this.props.loading_languages) {
            return <div>Loading...</div>
        } else if (!this.props.loading_languages && this.props.error_loading_list_of_languages) {
            return <div>Error loading languages. Please try after some time!!!!</div>;
        }
        return (
            <div style={{}}>                    
                <div  style={{ textAlign: 'left', margin: '20px 0'}}>
                    <div style={{ margin: '0 auto 20px auto', fontWeight: 'bold',textAlign: 'left', fontSize: '20px'}}>Add Language: </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: '25%', margin: 'auto', marginRight:'10px', textAlign: 'right'}}>
                            Select Language : 
                        </div>
                        <div style={{margin: 'auto auto', width: '75%'}}>
                            <select onChange={this.changeLanguage} style={{width: '70%', height: '25px', border: '1px solid black', borderRadius: '4px'}}>
                                <option selected disabled>Select Language</option>
                                {this.props.languages_list.map((lang, index)=> {
                                    return <option onChange={(event)=>this.changeLanguage(event)} value={lang.name} id={index} key={index}>{lang.name}</option>
                                })}
                            </select>
                        </div>
                    </div>

                    {this.state.new_language? <div style={{marginTop:'25px'}}>
                        {['Reading', 'Writing', 'Listening', 'Speaking'].map((type)=> {
                            return <div style={{margin: '12px auto 12px auto', display: 'flex', flexDirection:'row'}} key={type}>
                                <div style={{width: '23%',marginRight: '24px', textAlign: 'right'}}>{type}</div>
                                {this.state[type] != 0 && [...Array(this.state[type])].map((num, index)=> {
                                    return <div onClick={()=>this.decreaseProficiency(type, index + 1)} style={{marginRight: '20px'}}>
                                        <img src='/images/profile/star_one.png' />
                                    </div>
                                })}
                                {5-this.state[type] != 0 && [...Array(5-this.state[type])].map((num, index)=> {
                                    return <div onClick={()=>this.increaseProficiency(type, index + 1)} style={{marginRight: '20px'}}>
                                        <img src='/images/profile/star_zero.png' />
                                    </div>
                                })}
                            </div>
                            
                        })}
                    </div> : null}
                    {this.props.error_adding_language? <div style={{color: 'red', textAlign: 'left'}}>Error Adding New Language. Please try again after some time!!!!</div> : null}
                    {this.props.added_language_successfully? <div style={{color: 'green', textAlign: 'left'}}>Language Added Successfully.</div> : null}
                    
                    <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeLanguageEditor}>Cancel</div>
                        <div>
                            <SaveButton active={this.state.new_language} onClick={()=>this.addLanguageHandler()}>Save</SaveButton>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.prof.loading,
        college: state.prof.college,
        college_university_degree_department_id: state.prof.college_university_degree_department_id,
        department_details: state.prof.department_details,

        id: state.prof.id,
        roll_number: state.prof.roll_number,
        semester_id: state.prof.semester_id,
        university_degree_department_id: state.prof.university_degree_department_id,
        
        languages_list: state.prof.languages_list,
        error_loading_list_of_languages: state.prof.error_loading_list_of_languages,
        loading_languages:  state.prof.loading_languages,

        added_language_successfully: state.prof.added_language_successfully,
        error_adding_language: state.prof.error_adding_language

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getLanguagesList:()=> dispatch(getLanguagesListAC()),
        addLanguage: (item, formdata) => dispatch(itemUpdateRemoveAddAC(item, formdata)),
        clearStatus: () => dispatch({type:'CLEAR_ADD_LANGUAGE_STATUS'})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddLanguage));