import React, { Component } from "react";
import classes from "./login.module.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserDetailsAC,
  getLoginOtpAC,
  OPENENTERROLLNUMBERAGAIN,
  getUserPasswordDetailsAC,
  updatePasswordAC,
  CHANGELOGINMETHODTOOTP,
  OPENFORGOTPASSWORD,
} from "../../store/actions/loginActions";
import { Redirect } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA;
let recaptchaRef = null;
let timeInterval = null;

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roll_number: null,
      isVerified: false,
      password: "",
      captchaError: false,
      time: 0,
      timer: false,
      showPassword: false,
      password_disabled: false
    };
  }

  componentDidMount = () => {
    if (localStorage.getItem("token")) {
      this.props.history.push("/home");
    }
    recaptchaRef = React.createRef();
  };

  componentDidUpdate = () => {
    if (
      !timeInterval &&
      !this.state.password_disabled &&
      this.props.password_disabled &&
      this.props.last_incorrect_time
    ) {
      const last_incorrect_time = moment(this.props.last_incorrect_time);
      last_incorrect_time.add(1, 'minute');
      const current_date = moment();
      if(last_incorrect_time > current_date){
        let time = moment.duration(last_incorrect_time.diff(current_date)).asSeconds().toFixed(0);
        if(time > 0){
          timeInterval = setInterval(() => {
            if (time <= 0) {
              clearInterval(timeInterval);
              timeInterval = null;
              this.setState({ isVerified: false, password: "", timer: false, time: 0, password_disabled: false });
              recaptchaRef.current.reset();
              return;
            }
            this.setState({ time: time--, password_disabled: true });
          }, 1000);
        }
      }
    }
  };

  onPasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  onPasswordEnter = (event) => {
    if (event.key === "Enter") {
      this.onSubmitPassword();
    }
  };

  onSubmitPassword = () => {
    if (this.state.isVerified) {
      this.props.getUserDetails(null, this.state.password);
      recaptchaRef.current.reset();
      this.setState({ isVerified: false, captchaError: false, timer: true });
    } else if (!this.state.isVerified) this.setState({ captchaError: true });
  };

  onChangeCaptcha = (value) =>
    this.setState({ isVerified: value !== "" && value !== null });

  openEnterRollnumber = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.openEnterRollnumberAgain();
  };

  onForgotPasswordClick = async () => {
    await this.props.getLoginOtp();
    await this.props.openForgotPassword();
  };

  onShowPasswordClick = () =>
    this.setState({ showPassword: !this.state.showPassword });

  render() {
    if (this.props.user_logged_in_successfully) {
      return <Redirect to="/home" exact />;
    }
    console.log(this.props);

    return (
      <>
        <div className={classes.dFlex}>
          <div className={classes.loginArea}>
            <fieldset className={classes.otpFieldSet}>
              <legend style={{ width: "auto", float: "none" }}>
                <img
                  src="/rmklogo.png"
                  alt="logo"
                  width="200px"
                  style={{ padding: "0px 10px", borderRadius: "20px" }}
                  height="75px"
                />
              </legend>
              <div style={{ margin: "0 30px"}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "20px 0",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    Roll Number - {this.props.roll_number}
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={this.openEnterRollnumber}
                  >
                    <img src="/images/login/edit_button.png" alt="edit"></img>
                  </div>
                </div>
                <div style={{ margin: "20px 0 0 0", justifyContent: "center" }}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "320px",
                        margin: "10px auto",
                      }}
                    >
                      <div style={{ textAlign: "left", width: "50%" }}>
                        <div className={classes.subtext}>Password</div>
                      </div>
                      <div style={{ textAlign: "right", width: "50%" }}>
                        <div
                          onClick={this.onForgotPasswordClick}
                          className={classes.subtext}
                          style={{ color: "blue" }}
                        >
                          Forgot Password
                        </div>
                      </div>
                    </div>
                    <div className="row no-gutters mt-3">
                      <input
                        onKeyDown={(event) => this.onPasswordEnter(event)}
                        className={
                          classes.username + " form-control px-2 col-11"
                        }
                        type={this.state.showPassword ? "text" : "password"}
                        placeholder="Enter password"
                        value={this.state.password}
                        onChange={this.onPasswordChange}
                        disabled={this.state.password_disabled}
                        style={{ width: "91.66666667%" }}
                      />
                      <div
                        className="col-1 d-flex align-items-center justify-content-center"
                        style={{
                          marginBottom: "20px",
                          color: "blue",
                          cursor: "pointer",
                          backgroundColor: "white",
                        }}
                      >
                        <span
                          style={{ backgroundColor: "white" }}
                          onClick={this.onShowPasswordClick}
                        >
                          <i
                            className={
                              this.state.showPassword
                                ? "far fa-eye"
                                : "far fa-eye-slash"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <small style={{width: '100%',textAlign: 'center'}}>Please use CapsLock to type capital wprds</small> */}
                {this.props.error_user_message || this.props.is_user === 0  ? (
                  <div className="row no-gutters">
                    <img className="col-3 mx-0 img-reponsive" src="/images/gif/login_otp.gif" alt="gif" width={100}/>
                    <p
                      className="col-9 d-flex align-items-center"
                      style={{
                        color: "red",
                        margin: "0",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        textAlign: "center",
                      }}
                    >
                      {this.props.error_user_message}
                    </p>
                  </div>
                ) : null}
                {this.props.password_disabled && this.state.time > 0 ? (
                  <small
                    style={{
                      margin: "0",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "28px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Please wait for {this.state.time} seconds.
                  </small>
                ) : null}
                <ReCAPTCHA
                  style={{
                    border: this.state.captchaError ? "1px solid red" : "none",
                    margin: "10px 0",
                  }}
                  sitekey={RECAPTCHA_KEY}
                  ref={recaptchaRef}
                  onChange={this.onChangeCaptcha}
                />
                <button
                  className={classes.loginButton}
                  disabled={
                    !this.state.password ||
                    !this.state.isVerified ||
                    this.state.password_disabled
                  }
                  style={{ width: "100%", height: "56px", margin: "20px 0" }}
                  onClick={this.onSubmitPassword}
                >
                  Submit
                </button>
                <div
                  style={{ color: "blue", fontWeight: "600", fontSize: "16px" }}
                  onClick={() => this.props.getLoginOtp(false)}
                >
                  Login Using Otp
                </div>
                {this.props.error_sending_otp ? (
                  <p
                    style={{
                      color: "red",
                      margin: "0",
                      fontSize: "14px",
                      fontWeight: "400",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Request Failed
                  </p>
                ) : null}
                <div style={{ clear: "both" }}></div>
                <div
                  style={{
                    float: "right",
                    marginRight: "-170px",
                    position: "relative",
                    top: "-130px",
                  }}
                >
                  <img src="/images/login/monkey.svg" width="170px" alt="" />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roll_number: state.login.roll_number,
    login_otp_message: state.login.login_otp_message,
    user_logged_in_successfully: state.login.user_logged_in_successfully,
    error_in_roll_number: state.login.error_in_roll_number,
    error_sending_otp: state.login.error_sending_otp,
    error_user_message: state.login.error_user_message,
    has_password: state.login.has_password,
    is_user: state.login.is_user,
    password_updated_successfully: state.login.password_updated_successfully,
    error_updating_password: state.login.error_updating_password,
    user_password_details_message: state.login.user_password_details_message,
    user_password_status: state.login.user_password_status,
    login_through_otp: state.login.login_through_otp,
    login_through_password: state.login.login_through_password,
    got_user_password_details: state.login.got_user_password_details,
    got_user_details: state.login.got_user_details,
    error_loading_user_password_details: state.login.error_loading_user_password_details,
    last_incorrect_time: state.login.last_incorrect_time,
    incorrect_attempts: state.login.incorrect_attempts,
    password_disabled: state.login.password_disabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginOtp: () => dispatch(getLoginOtpAC(false)),
    getUserDetails: (otp, password) =>
      dispatch(getUserDetailsAC(otp, password)),
    openEnterRollnumberAgain: () =>
      dispatch({ type: OPENENTERROLLNUMBERAGAIN }),
    getPasswordDetails: (roll_number) =>
      dispatch(getUserPasswordDetailsAC(roll_number)),
    updatePassword: (roll_number, password) =>
      dispatch(updatePasswordAC(roll_number)),
    changeLoginMethodToOtp: () => dispatch({ type: CHANGELOGINMETHODTOOTP }),
    openForgotPassword: () => dispatch({ type: OPENFORGOTPASSWORD }),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
