import React from 'react'
import styled from 'styled-components';
import Gif from '../../../../../assets/remedial/gif/spinWheel.gif'


const SpinWheelAnimation = () => {

    const CircleSvg = styled('div')(({ theme }) => ({
        img: {
            minWidth: '100px',
            minHeight: '100px',
            objectFit: 'contain',
            bottom: '-16px',
            position: 'relative'
        },
        svg: {
            minWidth: '100px',
            minHeight: '100px',
            maxWidth: '100px',
            maxHeight: '100px',
            position: 'relative',
            top: 19,
        }
    }));


    return (
        <>
            <CircleSvg className='enabled-animation'>
                <img src={Gif} alt="testResultGif" />
            </CircleSvg>
            <CircleSvg className='disabled-animation'>
                <svg width="223" height="217" viewBox="0 0 223 217" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_44_7072)">
                        <path d="M77.6888 37.9258L70.8996 39.2051L67.7409 52.0575L58.8879 41.5281L51.29 43.161L72.121 71.4917L78.2027 39.1715C78.0258 38.759 77.8573 38.3382 77.6888 37.9258ZM71.8346 70.246L52.1661 43.4977L58.6942 42.1005L68.002 53.16L71.3208 39.6512L77.8236 38.4224L71.8346 70.246Z" fill="white" />
                        <path d="M41.46 131.966C40.4071 131.587 39.5648 131.15 39.0678 130.653C38.7393 130.325 38.5708 129.971 38.5708 129.618C38.5708 129.247 38.7477 128.877 39.1015 128.515C40.4155 127.194 44.2734 125.965 49.9676 125.056C55.7797 124.122 63.0407 123.608 70.4027 123.6C78.3206 123.591 85.3457 123.903 90.7282 124.517C96.5403 125.174 100.095 126.116 101.308 127.328C101.628 127.648 101.788 127.993 101.788 128.347C101.788 128.742 101.594 129.138 101.207 129.534C98.5956 132.151 86.3649 134.878 70.4279 134.895C62.8301 134.903 55.5439 134.499 49.9086 133.759C47.1121 133.388 43.7849 132.816 41.4684 131.974L41.46 131.966ZM99.0589 126.562C94.6282 124.963 84.2086 124.012 70.4027 124.029C55.6786 124.046 42.0665 126.15 39.4047 128.818C39.1352 129.087 38.992 129.357 38.992 129.618C38.992 129.862 39.1183 130.106 39.3626 130.358C40.5419 131.528 44.2987 132.589 49.9508 133.338C55.5691 134.079 62.8301 134.483 70.4111 134.474C77.5541 134.466 84.6718 133.877 90.4418 132.824C95.7822 131.848 99.5896 130.544 100.887 129.239C101.19 128.936 101.35 128.633 101.35 128.355C101.35 128.111 101.232 127.875 100.996 127.64C100.617 127.261 99.9602 126.899 99.0505 126.571L99.0589 126.562Z" fill="#4B4B4B" />
                        <path d="M29.5745 116.731C29.0691 118.12 25.1691 118.002 20.8731 116.445C16.5772 114.888 13.4943 112.498 14.0081 111.1L14.0249 111.041C14.6146 109.712 18.4641 109.863 22.701 111.395C26.938 112.927 29.9956 115.275 29.5913 116.681L29.5745 116.74V116.731Z" fill="white" />
                        <path d="M20.7972 116.639C18.6914 115.881 16.813 114.897 15.499 113.87C14.1259 112.801 13.5195 111.782 13.7974 111.016L13.8227 110.949C14.4797 109.476 18.3208 109.577 22.7683 111.193C27.2158 112.809 30.2314 115.183 29.7934 116.74L29.7681 116.807C29.4901 117.573 28.3783 117.969 26.6346 117.91C24.9668 117.851 22.8947 117.405 20.7972 116.647V116.639ZM14.2186 111.117L14.2018 111.168C13.7974 112.296 16.4087 114.602 20.9404 116.243C25.4722 117.884 28.9595 117.783 29.3722 116.664L29.3891 116.614C29.7007 115.486 27.0642 113.196 22.6251 111.597C20.5951 110.865 18.5903 110.419 16.9815 110.36C15.4737 110.301 14.4629 110.579 14.2186 111.126V111.117Z" fill="#4B4B4B" />
                        <path d="M27.1148 113.71L24.731 120.284C23.9561 122.413 22.0777 123.928 19.8371 124.256L16.0297 124.795C15.1031 124.93 14.1513 124.829 13.2668 124.509C12.8878 124.374 12.7446 123.97 12.8625 123.642C12.9131 123.507 13.0141 123.381 13.1489 123.297L14.8673 122.287C15.1116 122.144 15.0694 121.799 14.8167 121.706L7.79166 119.164L5.92167 120.317C4.92772 120.923 3.70633 121.058 2.61972 120.663L2.24909 120.528C1.79423 120.36 1.69315 119.77 2.05535 119.459L2.29121 119.249C1.7858 119.274 1.26356 119.206 0.775001 119.03L0.404373 118.895C-0.0504882 118.727 -0.151568 118.138 0.210636 117.826L4.237 115.697L6.51973 115.638L17.8407 115.343L19.5338 110.672C20.5109 110.891 21.5554 111.185 22.6336 111.572C24.3773 112.195 25.9103 112.953 27.1148 113.719V113.71Z" fill="#DAD9D9" />
                        <path d="M2.19019 119.282C2.19019 119.282 2.13965 119.256 2.11438 119.231C2.05541 119.155 2.06384 119.054 2.13965 118.996L6.03966 115.747C6.11547 115.688 6.21655 115.696 6.27552 115.772C6.33448 115.848 6.32606 115.949 6.25025 116.008L2.35023 119.256C2.29969 119.299 2.24073 119.307 2.18177 119.282H2.19019Z" fill="#4B4B4B" />
                        <path d="M13.9407 122.943C13.9407 122.943 13.8733 122.909 13.8565 122.867C13.806 122.783 13.8312 122.682 13.9155 122.631L16.0466 121.36C16.1813 121.276 16.2572 121.133 16.2403 120.981C16.2235 120.822 16.1224 120.695 15.9708 120.645C15.8781 120.611 15.836 120.519 15.8697 120.426C15.9034 120.333 15.996 120.291 16.0887 120.325C16.3667 120.426 16.552 120.662 16.5772 120.948C16.6025 121.234 16.4677 121.503 16.215 121.655L14.0924 122.926C14.0502 122.951 13.9913 122.959 13.9492 122.943H13.9407Z" fill="#4B4B4B" />
                        <path d="M27.1148 113.71L25.6239 117.818C24.2088 117.641 22.5409 117.237 20.8057 116.606C19.736 116.218 18.742 115.781 17.8491 115.318L19.5422 110.646C20.5193 110.865 21.5638 111.16 22.642 111.547C24.3688 112.178 25.9187 112.944 27.1148 113.71Z" fill="#4B4B4B" />
                        <path d="M43.5488 203.602V214.627C43.5488 215.772 44.4754 216.698 45.621 216.698H73.2159C73.2159 216.698 73.3759 211.581 69.1811 210.15C62.0465 207.726 58.7193 203.602 58.7193 203.602H43.5488Z" fill="white" />
                        <path d="M73.5107 217.001H45.621C44.3154 217.001 43.2456 215.94 43.2456 214.627V203.29H58.8625L58.9552 203.399C58.9889 203.442 62.3245 207.49 69.2738 209.855C71.0848 210.47 72.3904 211.858 73.0559 213.861C73.5528 215.351 73.5191 216.647 73.5191 216.706V217.001H73.5107ZM43.8521 203.904V214.627C43.8521 215.604 44.6439 216.395 45.621 216.395H72.9042C72.8537 215.318 72.4494 211.581 69.08 210.436C62.5182 208.205 59.1742 204.603 58.5761 203.904H43.8521Z" fill="#4B4B4B" />
                        <path d="M58.9804 209.536L58.8203 208.946C59.789 208.677 62.375 207.196 62.6529 206.11C62.7119 205.874 62.6529 205.689 62.476 205.504C62.0549 205.083 60.9262 205.42 60.5639 205.571L60.3281 205.007C60.5134 204.932 62.1223 204.292 62.9056 205.075C63.2426 205.411 63.3521 205.815 63.2342 206.261C62.8467 207.734 59.9153 209.266 58.9719 209.527L58.9804 209.536Z" fill="#4B4B4B" />
                        <path d="M63.1415 211.059L62.9814 210.47C63.8743 210.217 66.3676 208.601 66.5866 207.532C66.6372 207.296 66.5698 207.12 66.3592 206.96C65.9886 206.665 65.4748 206.699 64.8346 207.069C64.2955 207.381 63.8827 207.801 63.8827 207.81L63.4447 207.389C63.4447 207.389 63.9164 206.901 64.5229 206.547C65.6264 205.908 66.3424 206.16 66.7467 206.488C67.1257 206.791 67.2774 207.195 67.1847 207.658C66.8815 209.123 64.0596 210.806 63.1499 211.067L63.1415 211.059Z" fill="#4B4B4B" />
                        <path d="M59.5701 204.494L43.5488 209.468V203.602H58.7277C58.7277 203.602 59.0057 203.947 59.5701 204.494Z" fill="#4B4B4B" />
                        <path d="M78.1436 203.602V214.627C78.1436 215.772 79.0701 216.698 80.2157 216.698H107.811C107.811 216.698 107.971 211.581 103.776 210.15C96.6413 207.726 93.314 203.602 93.314 203.602H78.1436Z" fill="white" />
                        <path d="M108.105 217.001H80.2157C78.9101 217.001 77.8403 215.94 77.8403 214.627V203.29H93.4572L93.5499 203.399C93.5836 203.442 96.9192 207.49 103.868 209.855C105.68 210.47 106.985 211.858 107.651 213.861C108.148 215.351 108.114 216.647 108.114 216.706V217.001H108.105ZM78.4468 203.904V214.627C78.4468 215.604 79.2386 216.395 80.2157 216.395H107.499C107.448 215.318 107.044 211.581 103.675 210.436C97.113 208.205 93.7689 204.603 93.1708 203.904H78.4468Z" fill="#4B4B4B" />
                        <path d="M93.5751 209.536L93.415 208.946C94.3837 208.677 96.9697 207.196 97.2477 206.11C97.3066 205.874 97.2477 205.689 97.0708 205.504C96.6496 205.083 95.5209 205.42 95.1587 205.571L94.9228 205.007C95.1081 204.932 96.717 204.292 97.5004 205.075C97.8373 205.411 97.9468 205.815 97.8289 206.261C97.4414 207.734 94.5101 209.266 93.5667 209.527L93.5751 209.536Z" fill="#4B4B4B" />
                        <path d="M97.7362 211.059L97.5762 210.47C98.469 210.217 100.962 208.601 101.181 207.532C101.232 207.296 101.165 207.12 100.954 206.96C100.583 206.665 100.069 206.699 99.4293 207.069C98.8902 207.381 98.4775 207.801 98.4775 207.81L98.0395 207.389C98.0395 207.389 98.5112 206.901 99.1176 206.547C100.221 205.908 100.937 206.16 101.341 206.488C101.72 206.791 101.872 207.195 101.779 207.658C101.476 209.123 98.6544 210.806 97.7446 211.067L97.7362 211.059Z" fill="#4B4B4B" />
                        <path d="M94.1648 204.494L78.1436 209.468V203.602H93.3225C93.3225 203.602 93.6004 203.947 94.1648 204.494Z" fill="#4B4B4B" />
                        <path d="M51.0035 94.6465L41.0134 199.191H61.9118L72.4157 148.53L76.7032 159.329V199.191H98.4692V154.489L86.8618 94.6465H51.0035Z" fill="#4B4B4B" />
                        <path d="M61.5806 119.711L51.8628 199.138L52.4648 199.211L62.1826 119.785L61.5806 119.711Z" fill="#F9F9F9" />
                        <path d="M87.5104 199.191H86.9039V154.532L73.2749 99.7896L73.8645 99.6465L87.5104 154.456V199.191Z" fill="#F9F9F9" />
                        <path d="M76.1136 159.43L63.0827 121.622C62.6784 120.443 61.5665 119.661 60.3198 119.661V119.055C61.8276 119.055 63.1753 120.006 63.6639 121.428L76.6948 159.228L76.122 159.43H76.1136Z" fill="white" />
                        <path d="M98.5113 204.442H76.6274C75.8019 204.442 75.1365 203.778 75.1365 202.953V200.68C75.1365 199.855 75.8019 199.19 76.6274 199.19H98.5113C99.3368 199.19 100.002 199.855 100.002 200.68V202.953C99.9938 203.778 99.3368 204.442 98.5113 204.442Z" fill="#828282" />
                        <path d="M62.2908 204.442H40.407C39.5815 204.442 38.916 203.778 38.916 202.953V200.68C38.916 199.855 39.5815 199.19 40.407 199.19H62.2908C63.1163 199.19 63.7818 199.855 63.7818 200.68V202.953C63.7733 203.778 63.1163 204.442 62.2908 204.442Z" fill="#828282" />
                        <path d="M88.1083 60.2887C88.1083 60.2887 88.0831 60.2129 88.0746 60.1709C87.3587 58.7148 86.6764 57.2418 86.0278 55.7521C85.0844 53.5806 84.1915 51.367 83.2818 49.1534C81.3275 45.5426 79.2722 41.9739 77.7813 38.1611L51.7026 43.3206L45.284 44.5915C40.3395 45.5763 36.271 49.0608 34.5526 53.7994L14.1934 109.939C14.7914 108.609 18.7167 108.786 23.0547 110.36C27.3928 111.934 30.5347 114.307 30.1304 115.713L48.249 65.7512C48.4175 65.2882 48.7544 64.9347 49.1671 64.7411C49.5799 64.5476 50.0685 64.5139 50.5317 64.6822C51.3656 64.9852 51.8458 65.8437 51.6689 66.7191L38.4779 128.127C39.6572 125.283 54.1538 122.413 70.7141 122.387C88.0325 122.362 102.959 124.517 102.689 127.892C102.714 127.816 102.748 127.74 102.765 127.665C104.652 119.812 92.1094 76.4488 88.2431 61.7532C88.1168 61.265 88.0747 60.7769 88.0915 60.3055L88.1083 60.2887Z" fill="#828282" />
                        <path d="M59.1655 41.849L59.612 33.0451L66.2917 32.3213L67.9932 34.9473L70.9077 39.4503L67.7321 52.0164L59.1655 41.849Z" fill="#DAD9D9" />
                        <path d="M67.9932 34.9473L60.1595 43.0358L59.1655 41.849L59.612 33.0451L66.2917 32.3213L67.9932 34.9473Z" fill="#C1C1C0" />
                        <path d="M68.4233 24.1455C68.4233 24.1455 74.7127 21.113 70.813 19.4882C66.9134 17.8634 65.4522 20.4107 65.4522 20.4107C65.4522 20.4107 64.6398 15.6922 61.2646 17.0057C57.8894 18.3192 58.2911 20.9395 58.2911 20.9395C58.2911 20.9395 52.1037 19.5595 52.0964 21.8745C52.0892 24.1895 57.4533 27.964 57.4533 27.964L68.4148 24.1457L68.4233 24.1455Z" fill="#252424" />
                        <path d="M62.7613 34.719C62.3385 34.6159 61.654 33.9108 60.9157 32.9959L59.4997 33.5055C58.687 33.8039 57.7794 33.3883 57.4891 32.5761C57.3575 32.2246 57.3602 31.8457 57.4732 31.5158C57.6184 31.0927 57.9499 30.7257 58.419 30.5503L58.9971 30.3396C58.1184 29.0481 57.4534 27.9638 57.4534 27.9638C57.4534 27.9638 61.6339 22.5051 68.4149 24.1454C68.4149 24.1454 69.4089 32.5313 69.4035 33.2974C69.3984 34.0719 65.1456 35.2892 62.7445 34.7193L62.7613 34.719Z" fill="#DAD9D9" />
                        <path d="M58.9969 30.3399C58.1182 29.0484 57.4532 27.9641 57.4532 27.9641C57.4532 27.9641 58.9981 25.9459 61.7431 24.7345C58.8243 26.1505 61.2279 29.6834 58.9969 30.3399Z" fill="#252424" />
                        <path d="M61.5203 26.7412C61.5203 26.7412 61.4864 26.7249 61.4693 26.7083C61.3926 26.6506 61.3825 26.5413 61.4489 26.473C61.4736 26.4389 62.1688 25.6119 63.2611 25.4187C63.3535 25.4005 63.4387 25.4665 63.4569 25.5589C63.4752 25.6512 63.4091 25.7364 63.3167 25.7546C62.3504 25.9207 61.7131 26.6794 61.7048 26.688C61.6552 26.7476 61.5797 26.7656 61.5203 26.7412Z" fill="#4B4B4B" />
                        <path d="M65.8871 29.7312C65.8363 29.7151 65.8021 29.682 65.7845 29.6317C65.3714 28.4932 65.613 27.1932 66.0066 26.4802C66.2199 26.0982 66.4774 25.8586 66.746 25.7956C67.5269 25.6239 68.6983 26.2124 68.7492 26.2369C68.8341 26.2777 68.8693 26.3782 68.82 26.4631C68.7792 26.5479 68.6786 26.583 68.5937 26.5338C68.5852 26.5339 67.4817 25.9782 66.8184 26.1228C66.6504 26.159 66.4595 26.3471 66.3038 26.6356C65.9429 27.2892 65.7247 28.4626 66.1027 29.5091C66.1378 29.6012 66.0887 29.6945 65.9965 29.7296C65.9547 29.747 65.9209 29.7391 65.8871 29.7312Z" fill="#4B4B4B" />
                        <path d="M64.8211 31.1029C64.5416 31.0061 64.2359 30.8424 63.9202 30.5778C63.8519 30.5199 63.8418 30.4106 63.8996 30.334C63.9576 30.2657 64.067 30.2557 64.1436 30.3134C65.1504 31.1569 66.0131 30.824 66.055 30.8066C66.1387 30.7716 66.2404 30.8122 66.2753 30.8958C66.3103 30.9795 66.2781 31.081 66.186 31.116C66.1526 31.1334 65.5916 31.3607 64.8294 31.0944L64.8211 31.1029Z" fill="#4B4B4B" />
                        <path d="M63.6907 31.0097C63.606 30.9773 63.5541 30.8855 63.5865 30.8009C63.7309 30.3189 64.1559 30.0094 64.1809 29.9922C64.2559 29.9406 64.3657 29.9558 64.4173 30.0308C64.469 30.1057 64.4538 30.2154 64.3788 30.267C64.3788 30.267 64.029 30.5332 63.9165 30.8969C63.8926 30.9899 63.7923 31.0419 63.7076 31.0095L63.6907 31.0097Z" fill="#4B4B4B" />
                        <path d="M63.3692 27.9673C63.3889 27.689 63.2919 27.4556 63.1527 27.4458C63.0135 27.436 62.8847 27.6536 62.8651 27.9318C62.8455 28.21 62.9425 28.4435 63.0817 28.4533C63.2209 28.4631 63.3496 28.2455 63.3692 27.9673Z" fill="#4B4B4B" />
                        <path d="M67.4932 27.6711C67.5115 27.4114 67.4248 27.1938 67.2995 27.185C67.1742 27.1762 67.0578 27.3795 67.0395 27.6392C67.0212 27.8989 67.1079 28.1165 67.2332 28.1253C67.3585 28.1341 67.4749 27.9308 67.4932 27.6711Z" fill="#4B4B4B" />
                        <path d="M61.104 33.7762C61.104 33.7762 61.0362 33.7436 61.0188 33.7101L59.6563 31.6178C59.5528 31.4594 59.3412 31.3953 59.1655 31.4737L59.0148 31.5349C58.9227 31.5784 58.8481 31.6552 58.8159 31.7567C58.7837 31.8582 58.7937 31.9591 58.8373 32.0595C58.8807 32.143 58.8401 32.2446 58.7649 32.2878C58.6814 32.3312 58.5796 32.2906 58.5364 32.2155C58.4411 32.0402 58.4296 31.8383 58.4942 31.6522C58.5588 31.466 58.6913 31.3125 58.8754 31.234L59.026 31.1643C59.3608 31.0162 59.7417 31.1367 59.94 31.4368L61.3025 33.5291C61.3542 33.6041 61.3306 33.7139 61.2555 33.7655C61.2138 33.7914 61.155 33.8007 61.1042 33.7846L61.104 33.7762Z" fill="#4B4B4B" />
                        <path d="M85.876 39.7353L96.481 65.2212C97.0369 66.2059 98.3847 66.3743 99.168 65.5578L121.92 41.7469L127.437 57.1832L107.659 83.7043C104.862 87.4581 99.9767 89.0152 95.5207 87.5844L78.1518 81.9873L77.7896 38.1614C77.7896 38.1614 84.604 37.7658 85.8844 39.7353H85.876Z" fill="#828282" />
                        <path d="M96.8853 94.1077L88.0576 61.7959L88.4619 61.6865L96.3294 91.0777L96.8853 94.1077Z" fill="white" />
                        <path d="M150.19 111L133 210H203L185.682 111H150.19Z" fill="#4B4B4B" />
                        <path d="M144.681 111L127 210H199L181.187 111H144.681Z" fill="#EEEEEE" />
                        <path d="M199 209.992H127L144.652 111H181.217L181.244 111.169L199 210V209.992ZM127.427 209.569H198.573L180.941 111.423H144.927L127.427 209.569Z" fill="#4B4B4B" />
                        <path d="M180.942 0H154.695C149.498 0 145.286 4.20919 145.286 9.40148V35.628C145.286 40.8203 149.498 45.0295 154.695 45.0295H180.942C186.138 45.0295 190.351 40.8203 190.351 35.628V9.40148C190.351 4.20919 186.138 0 180.942 0Z" fill="#4B4B4B" />
                        <path d="M177.817 3.12305H151.57C146.373 3.12305 142.161 7.33223 142.161 12.5245V38.751C142.161 43.9433 146.373 48.1525 151.57 48.1525H177.817C183.013 48.1525 187.226 43.9433 187.226 38.751V12.5245C187.226 7.33223 183.013 3.12305 177.817 3.12305Z" fill="#EEEEEE" />
                        <path d="M177.817 48.5645H151.561C146.145 48.5645 141.731 44.1626 141.731 38.7422V12.5157C141.731 7.10372 146.137 2.69336 151.561 2.69336H177.817C183.233 2.69336 187.647 7.09531 187.647 12.5157V38.7506C187.647 44.1626 183.242 48.5729 177.817 48.5729V48.5645ZM151.561 3.53503C146.608 3.53503 142.574 7.55823 142.574 12.5157V38.7506C142.574 43.6996 146.608 47.7313 151.561 47.7313H177.817C182.77 47.7313 186.805 43.7081 186.805 38.7506V12.5157C186.805 7.56664 182.77 3.53503 177.817 3.53503H151.561Z" fill="#4B4B4B" />
                        <path d="M125.154 48.1104C123.015 47.3361 121.069 46.4355 119.671 45.5854C118.138 44.6512 117.472 43.8936 117.674 43.3381C117.876 42.7826 118.879 42.6227 120.656 42.8752C122.29 43.1109 124.362 43.658 126.494 44.4323C128.625 45.2067 130.57 46.1072 131.969 46.9742C133.493 47.9168 134.167 48.6828 133.965 49.2383C133.763 49.7938 132.76 49.9453 130.992 49.6844C129.374 49.4487 127.302 48.8848 125.163 48.1104H125.154ZM126.342 44.8279C124.236 44.062 122.198 43.5233 120.589 43.2961C118.752 43.0351 118.138 43.2961 118.07 43.4812C117.919 43.8852 119.148 44.769 119.89 45.2235C121.263 46.0652 123.183 46.9405 125.297 47.7064C127.412 48.4723 129.45 49.0194 131.042 49.2551C131.91 49.3814 133.409 49.4908 133.552 49.0868C133.62 48.8932 133.316 48.3124 131.733 47.3361C130.351 46.4776 128.431 45.5938 126.333 44.8279H126.342Z" fill="#3EB1B5" />
                        <path d="M165.628 76.7275L218.62 78.5119C218.283 87.1979 215.503 95.6483 210.567 103.005C205.631 110.361 198.699 116.387 190.469 120.461L165.628 76.7275Z" fill="#E2E3E3" />
                        <path d="M190.3 121.016L164.887 76.2812L219.049 78.1077L219.033 78.5285C218.696 87.2819 215.882 95.8249 210.904 103.24C205.926 110.655 198.926 116.74 190.646 120.839L190.292 121.016H190.3ZM166.361 77.1734L190.637 119.905C198.631 115.874 205.395 109.957 210.213 102.769C215.023 95.5976 217.777 87.3577 218.182 78.9157L166.37 77.1734H166.361Z" fill="white" />
                        <path d="M165.628 76.7263L193.779 34.7773C201.663 39.3897 208.116 45.8622 212.479 53.5382C216.842 61.2143 218.956 69.8246 218.62 78.5106L165.628 76.7263Z" fill="#828282" />
                        <path d="M219.024 78.9488L164.853 77.1223L193.644 34.2139L193.99 34.4159C201.933 39.0619 208.453 45.6017 212.841 53.3282C217.238 61.0632 219.378 69.7745 219.041 78.5279L219.024 78.9488ZM166.403 76.3396L218.215 78.0818C218.46 69.6314 216.354 61.2231 212.108 53.7491C207.854 46.2582 201.562 39.9036 193.905 35.3501L166.403 76.3396Z" fill="white" />
                        <path d="M165.629 75.8862L140.788 32.1529C149.01 28.0792 158.242 26.1013 167.541 26.4127C176.84 26.7241 185.895 29.3165 193.78 33.9288L165.629 75.8778V75.8862Z" fill="#E2E3E3" />
                        <path d="M165.595 76.6854L140.198 31.9758L140.594 31.7822C148.866 27.6832 158.199 25.6801 167.549 25.9999C176.907 26.3113 186.047 28.9373 193.99 33.575L194.378 33.8022L165.603 76.6854H165.595ZM141.369 32.3377L165.654 75.0862L193.173 34.0715C185.449 29.6359 176.587 27.1362 167.524 26.8332C158.443 26.5302 149.422 28.4323 141.378 32.3293L141.369 32.3377Z" fill="white" />
                        <path d="M165.628 76.7265L112.637 74.9421C112.974 66.2561 115.754 57.8057 120.69 50.4494C125.626 43.0932 132.558 37.0669 140.788 32.9932L165.628 76.7265Z" fill="#828282" />
                        <path d="M166.369 77.1728L112.207 75.3547L112.224 74.9339C112.561 66.1805 115.374 57.6375 120.353 50.2224C125.331 42.8073 132.331 36.722 140.611 32.623L140.965 32.4463L166.378 77.1812L166.369 77.1728ZM113.083 74.5383L164.895 76.2806L140.619 33.5489C132.625 37.5805 125.862 43.4974 121.043 50.6853C116.234 57.8564 113.488 66.0964 113.075 74.5383H113.083Z" fill="white" />
                        <path d="M165.628 76.7267L137.478 118.676C129.593 114.063 123.141 107.591 118.778 99.9148C114.414 92.2387 112.3 83.6284 112.637 74.9424L165.628 76.7267Z" fill="#E2E3E3" />
                        <path d="M137.612 119.24L137.267 119.038C129.324 114.392 122.804 107.852 118.415 100.125C114.018 92.3904 111.879 83.6791 112.216 74.9257L112.233 74.5049L166.403 76.3313L137.612 119.24ZM113.05 75.3802C112.805 83.8306 114.911 92.2389 119.157 99.713C123.41 107.204 129.703 113.558 137.36 118.112L164.862 77.1225L113.05 75.3802Z" fill="white" />
                        <path d="M165.628 76.7275L190.469 120.461C182.248 124.535 173.016 126.512 163.716 126.201C154.417 125.89 145.362 123.297 137.478 118.685L165.628 76.736V76.7275Z" fill="#828282" />
                        <path d="M165.62 126.655C164.98 126.655 164.34 126.647 163.708 126.622C154.349 126.31 145.21 123.684 137.267 119.047L136.879 118.819L165.654 75.9277L191.05 120.637L190.654 120.831C182.947 124.644 174.33 126.647 165.62 126.647V126.655ZM138.084 118.542C145.808 122.977 154.67 125.477 163.733 125.78C172.813 126.083 181.835 124.181 189.879 120.284L165.595 77.5353L138.076 118.55L138.084 118.542Z" fill="white" />
                        <g opacity="0.2">
                            <path d="M218.831 87.6019C218.569 88.7971 218.275 89.9922 217.938 91.1706C213.878 105.395 204.519 117.187 191.589 124.383C178.66 131.571 163.708 133.305 149.481 129.248C135.877 125.368 124.388 116.547 117.135 104.402C109.917 92.3153 107.575 78.0994 110.54 64.3802C110.801 63.185 111.096 61.9898 111.433 60.8115C115.493 46.5872 124.851 34.7954 137.781 27.5991C150.711 20.4112 165.662 18.6773 179.889 22.7342C193.493 26.6143 204.983 35.435 212.235 47.5804C219.454 59.6668 221.796 73.8826 218.831 87.6019ZM118.702 66.1224C113.496 90.2447 128.018 114.443 151.772 121.218C176.705 128.33 202.793 113.82 209.91 88.8812C210.197 87.8796 210.449 86.8612 210.668 85.8512C215.874 61.7289 201.352 37.5308 177.598 30.7553C152.665 23.6432 126.578 38.1536 119.46 63.0924C119.174 64.094 118.921 65.1124 118.702 66.1224Z" fill="#010101" />
                        </g>
                        <path d="M221.686 88.2158C221.416 89.4699 221.096 90.7324 220.742 91.9696C216.472 106.943 206.616 119.358 193.013 126.933C179.401 134.499 163.666 136.317 148.689 132.05C134.369 127.968 122.273 118.676 114.642 105.899C107.044 93.1732 104.576 78.2083 107.701 63.7652C107.97 62.5111 108.291 61.2486 108.644 60.0113C112.915 45.038 122.77 32.6233 136.374 25.0483C149.986 17.4816 165.721 15.6636 180.698 19.9309C195.018 24.013 207.113 33.3051 214.745 46.0817C222.343 58.8078 224.811 73.7727 221.686 88.2158ZM116.293 65.6085C110.809 91.0017 126.106 116.471 151.107 123.6C177.354 131.082 204.805 115.814 212.302 89.5625C212.605 88.5104 212.875 87.433 213.102 86.3725C218.586 60.9793 203.289 35.5103 178.289 28.3813C152.042 20.8988 124.59 36.1668 117.093 62.4185C116.79 63.4706 116.52 64.548 116.293 65.6085Z" fill="#E2E3E3" />
                        <path d="M220.978 88.0648C220.709 89.302 220.397 90.5477 220.052 91.7766C215.831 106.565 206.102 118.828 192.667 126.302C179.224 133.776 163.682 135.569 148.899 131.352C134.757 127.32 122.812 118.146 115.282 105.521C107.777 92.9549 105.342 78.1751 108.425 63.9172C108.695 62.6799 109.006 61.4343 109.352 60.2054C113.572 45.4172 123.301 33.1541 136.736 25.68C150.18 18.206 165.721 16.4132 180.504 20.63C194.647 24.6616 206.591 33.8358 214.122 46.4609C221.627 59.0271 224.061 73.8068 220.978 88.0648ZM115.585 65.4575C110.026 91.221 125.541 117.069 150.904 124.299C177.539 131.891 205.395 116.404 213.001 89.765C213.304 88.696 213.582 87.6103 213.81 86.5245C219.369 60.7609 203.853 34.9132 178.491 27.6832C151.856 20.0913 124 35.5865 116.394 62.217C116.09 63.2859 115.812 64.3717 115.585 65.4575Z" fill="white" />
                        <path d="M110.388 60.5077C101.821 90.5049 119.199 121.756 149.194 130.308C179.19 138.859 210.449 121.478 219.007 91.4728C227.574 61.4756 210.196 30.2243 180.201 21.6729C150.205 13.1215 118.955 30.502 110.388 60.5077ZM214.046 90.0672C206.263 117.329 177.859 133.127 150.61 125.35C123.352 117.581 107.566 89.1834 115.349 61.9217C123.133 34.6599 151.536 18.8617 178.786 26.6387C206.044 34.4074 221.829 62.8054 214.046 90.0672Z" fill="#828282" />
                        <path d="M166.867 68.1425C162.621 66.9978 158.249 69.5144 157.104 73.7648C155.958 78.0153 158.477 82.3836 162.722 83.5198C166.968 84.6645 171.339 82.1479 172.485 77.8975C173.631 73.647 171.112 69.2787 166.867 68.1425Z" fill="#828282" />
                        <path d="M172.039 79.8918C174.279 75.8855 172.856 70.827 168.855 68.5882C164.854 66.3493 159.791 67.7718 157.55 71.7781C155.31 75.7845 156.733 80.8429 160.735 83.0818C164.736 85.3206 169.798 83.8982 172.039 79.8918ZM158.342 72.2915C160.406 68.6134 164.795 67.2247 168.467 69.2868C172.14 71.3489 173.361 75.7003 171.297 79.3784C169.234 83.0565 164.71 84.218 161.341 82.4169C157.626 80.4305 156.279 75.9612 158.342 72.2915Z" fill="white" />
                        <path d="M167.018 67.5528C162.453 66.3239 157.744 69.0341 156.514 73.6044C155.284 78.1747 157.988 82.8712 162.562 84.1001C167.128 85.3289 171.836 82.6187 173.066 78.0484C174.296 73.4782 171.592 68.7816 167.018 67.5528ZM162.672 83.6961C158.325 82.5261 155.748 78.0568 156.919 73.7054C158.089 69.3624 162.562 66.7784 166.909 67.9484C171.255 69.1183 173.833 73.5876 172.662 77.939C171.491 82.282 167.018 84.866 162.672 83.6961Z" fill="#4B4B4B" />
                        <path d="M160.078 10.9757C160.078 10.9757 159.387 11.4302 159.37 11.4386C156.557 13.3071 154.796 16.657 155.083 20.3098C155.495 25.5955 165.822 36.8319 165.822 36.8319L166.589 36.3269C166.589 36.3269 175.029 23.4577 174.616 18.1636C174.204 12.8779 169.663 8.95568 164.483 9.40176C162.857 9.54485 161.358 10.1004 160.078 10.9589V10.9757Z" fill="#4B4B4B" />
                        <path d="M173.841 18.6769C174.254 23.9626 165.814 36.8402 165.814 36.8402C165.814 36.8402 155.487 25.6039 155.074 20.3182C154.661 15.0325 158.527 10.378 163.708 9.92353C168.888 9.46902 173.428 13.3912 173.841 18.6853V18.6769Z" fill="#828282" />
                        <path d="M157.879 20.2596C158.089 22.9024 162.217 28.5753 165.468 32.4301C168.08 28.0619 171.272 21.7409 171.061 19.1065C170.767 15.2937 167.566 12.4488 163.927 12.7687C160.288 13.0885 157.576 16.4468 157.879 20.2596Z" fill="#E2E3E3" />
                        <path d="M156.363 20.3856C156.725 25.0653 165.679 35.0138 165.679 35.0138C165.679 35.0138 172.957 23.6513 172.594 18.9716C172.232 14.2919 168.299 10.8158 163.817 11.2113C159.336 11.5985 156 15.7143 156.363 20.3856ZM171.07 19.1062C171.28 21.7491 168.088 28.0616 165.477 32.4299C162.217 28.575 158.089 22.8938 157.887 20.2593C157.592 16.4465 160.305 13.0883 163.935 12.7684C167.574 12.4486 170.775 15.2935 171.07 19.1062Z" fill="white" />
                        <path d="M164.727 25.4606C167.818 25.3007 170.202 22.6157 170.067 19.4679C169.932 16.32 167.313 13.8876 164.23 14.0475C161.139 14.2074 158.755 16.8923 158.889 20.0402C159.024 23.1881 161.644 25.6205 164.727 25.4606Z" fill="#828282" />
                        <path d="M203.991 215H125.008C123.351 215 122 213.337 122 211.299V205.701C122 203.663 123.351 202 125.008 202H199.557H203.991C205.648 202 207 203.663 207 205.701V211.299C207 213.337 205.648 215 203.991 215Z" fill="#4B4B4B" />
                        <path d="M201.482 201L198 204H202.282L204 201.682L201.482 201Z" fill="white" />
                        <path d="M202.027 217H123.973C122.335 217 121 215.337 121 213.299V207.701C121 205.663 122.335 204 123.973 204H197.645H202.027C203.664 204 205 205.663 205 207.701V213.299C205 215.337 203.664 217 202.027 217Z" fill="#EEEEEE" />
                    </g>
                    <defs>
                        <clipPath id="clip0_44_7072">
                            <rect width="223" height="217" fill="white" />
                        </clipPath>
                    </defs>
                </svg>


            </CircleSvg>
        </>
    )
}

export default SpinWheelAnimation