const colors = {
    primary: '#3C79F7',
    secondary: '#E7EEFE',
    common: {
        white: '#FFF',
        black: '#000',
    },
    questionBorderColor: '#DFE3E8',

    disagree: '#F8A31B',
    disagree_secondary: '#FDE3BB',

    agree: '#3C79F7',
    agree_secondary: '#C4D7FD',

    neutral: '#919EAB',
    neutral_secondary: '#DFE3E8',
}

export default colors;