// import moment from "moment";
import { GETLOGINOTP, GETUSERDETAILS, GETUSERPASSWORDDETAILS, OPENENTERROLLNUMBERAGAIN, UPDATEPASSWORD, CHANGELOGINMETHODTOOTP, OPENFORGOTPASSWORD } from "../actions/loginActions";
import { init, identify, Identify, track } from '@amplitude/analytics-browser';
const AMPLITUDE_API_KEY=process.env.REACT_APP_AMPLITUDE_KEY;
const initial_state = {
    got_user_password_details:false,
    got_user_details: false,
    login_through_otp: false,
    login_through_password: true,
    error_sending_otp: false,
    forgot_password: false,
    resent_otp: false,
    password_disabled: false,
    user_details:false
};

const reducer = (state=initial_state, action) => {
    if (action.type === GETLOGINOTP) {
        let response = action.res;
        if (action.error) {
            return {
                ...state,
                roll_number: action.roll_number,
                error_in_roll_number: true,
                login_otp_message: null,
                error_getting_details: false,
                error_sending_otp: true,
                resent_otp: false
            }
        }
        // let login_through_otp = state.login_through_otp;
        // let login_through_password = state.login_through_password;
        return {
            ...state,
            roll_number: action.roll_number,
            login_otp_message: response.otp_send_to,
            error_in_roll_number: false,
            error_getting_details: false,
            error_sending_otp: false,
            resent_otp: action.resent,
            login_through_otp: true,
            login_through_password: false,
        }
    }
    if (action.type === GETUSERDETAILS) {
        if (action.error) {
            let message = action.message;
            if(action.incorrect_attempts === 1) message = "Oops ..! it seems you Entered a wrong password. Please try again!";
            else if(action.incorrect_attempts === 2) message = "You went wrong with your password. Please try again!";
            else if(action.incorrect_attempts === 3) message = "You went wrong with your password again! Last attempt left !! ";
            else if(action.incorrect_attempts === 4) message = "Sorry..! Please wait for 1 minute before you reattmpt or try logggin in using OTP";
            return {
                ...state,
                error_user_message: message,
                error_getting_details: true,
                resent_otp: false,
                password_disabled: action.res ? action.res.password_disabled : action.incorrect_attempts === 4 ? true : false,
                incorrect_attempts: action.incorrect_attempts,
                last_incorrect_time: action.last_incorrect_time
            }
        }else{
            let user = action.res.data.user_id;
            const identifyObj = new Identify();
            identifyObj.setOnce('User ID', `${user.user_id}`);
            identify(identifyObj);
            init(AMPLITUDE_API_KEY,`${user.user_id}`);
              const eventProperties = {
                Logged_in: `Yes`,
              };
               track('logged_in', eventProperties);
        }
        return {
            ...state,
            ...action.res,
            user_details:action.res.data,
            user_logged_in_successfully: true,
            error_getting_details: false,
            resent_otp: false,
            got_user_details: true
        }
    }
    if (action.type == OPENENTERROLLNUMBERAGAIN) {
        window.location.reload();
        // return {
        //     ...state,
        //     roll_number: null,
        //     login_otp_message: null,
        //     has_password: null,
        //     is_user: null,
        //     login_through_otp: null,
        //     login_through_password: null,
        //     got_user_password_details: null,
        //     error_loading_user_password_details: null
        // }        
    }

    if (action.type === GETUSERPASSWORDDETAILS) {
        if (action.error) {
            return {
                ...state,
                got_user_password_details: true,
                error_loading_user_password_details: true,
                password_disabled: action.res && action.res.password_disabled,
                roll_number: action.roll_number,
                error_in_roll_number: !action.res?.password_disabled,
                error_message: action.message,
                last_incorrect_attempt: action.last_incorrect_attempt
            }
        } else {
            let login_through_password = state.login_through_password;
            let login_through_otp = state.login_through_otp;
            let forgot_password = state.forgot_password;
            if (action.res.has_password == 1 && !state.forgot_password) {
                login_through_otp = false;
                login_through_password = true;
            } else if (action.res.has_password == 0 && !state.forgot_password) {
                login_through_otp = true;
                login_through_password = false;
                forgot_password=true
            }
            return {
                ...state,
                roll_number: action.roll_number,
                has_password: action.res.has_password,
                // has_password: 1,
                is_user: action.res.is_user,
                // is_user: 1,
                login_through_otp: login_through_otp,
                login_through_password: login_through_password,
                user_password_details_message: action.res.message,
                user_password_status: action.res.status,
                got_user_password_details: true,
                error_loading_user_password_details: false,
                forgot_password: forgot_password,
                password_disabled: false,
                error_in_roll_number: false
            }
        }
        
    }

    if (action.type == UPDATEPASSWORD) {
        console.log(action)
        if (action.error) {
            return {
                ...state,
                error_updating_password: true,
                error_message: action.res ? action.res.message : null
            }
        } else {
            return {
                ...state,
                error_message: null,
                password_updated_successfully: true,
                error_updating_password: false,
                forgot_password: false
            }
        }
        
    }

    if (action.type == CHANGELOGINMETHODTOOTP) {
        return {
            ...state,
            login_through_otp: true,
            login_through_password: false,
        }

    }
    if (action.type == OPENFORGOTPASSWORD) {
        localStorage.setItem("forgot_password", true)
        return{
            ...state,
            login_through_password: false,
            forgot_password: true,
            login_otp_message: null
        }
    }
    return {...state}
}
export default reducer;