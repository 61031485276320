import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import './StudyContentCarousel.css'
import { useRemedialContext } from '../../hooks/useRemedial';

const StudyContentCarousel = ({ data, onChange, renderer, spaceBetween, currentSlideIndex, slidesPerView = 1.1, }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef()

    // const{ studyPageData } = useRemedialContext();

    console.log(data, 'video data')

    const SwiperButtons = styled('div')(({ theme }) => ({
        width: '100%',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '16px'
    }));

    const handleNext = () => {
        swiperRef?.current?.swiper.slideNext();
    }

    const handlePrev = () => {
        swiperRef?.current.swiper.slidePrev()
    }

    useEffect(() => {
        if (currentSlideIndex > 1) {
            swiperRef?.current.swiper.slideTo(currentSlideIndex - 1);
        }
        // console.log(currentSlideIndex , 'test currentSlideIndex')
    }, [])



    useEffect(() => {
        onChange(activeIndex)
    }, [activeIndex])



    return (
        <>
            <div className='study-content-swiper' >
                <Swiper
                    ref={swiperRef}
                    spaceBetween={16}
                    slidesPerView={slidesPerView}
                    onActiveIndexChange={(swiperCore) => {
                        setActiveIndex(swiperCore.activeIndex)
                    }}
                    // onSlideChange={(swiperCore) => onChange(swiperCore.activeIndex) }
                    pagination={{
                        type: 'progressbar',
                    }}
                    navigation={true}
                >
                    {data.map((item, index) => {
                        return (
                            <SwiperSlide>{renderer(item)}</SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
            {/* Swiper Buttons */}
            <SwiperButtons>
                {activeIndex  === 0 ? <span style={{width:'72px'}}></span> : <Box onClick={() => handlePrev()} sx={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }}>
                    <Box sx={{
                        transform: 'rotate(-180deg)',
                        svg: {
                            path: {
                                stroke: theme => theme.palette.primary[800]
                            }
                        }
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M9.61984 12.0465L13.6665 7.99979L9.61984 3.95312" stroke="#0946C4" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.33322 8L13.5532 8" stroke="#0946C4" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Box>
                    <Typography component='p' sx={{
                        fontFamily: 'Poppins-Medium',
                        color: (theme) => theme.palette.primary[800],
                        fontSize: '12px'
                    }}>Previous</Typography>
                </Box>}
                <Typography component='p' sx={{
                    fontFamily: 'Poppins-Medium',
                    color: 'grey.800',
                    fontSize: '14px'
                }}>{activeIndex < 9 ? `0${activeIndex + 1}` : activeIndex+1} of {data.length < 9 ? `0${data.length}` : data.length} Pages</Typography>
                <Box onClick={() => handleNext()} sx={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }}>
                    <Typography component='p' sx={{
                        fontFamily: 'Poppins-Medium',
                        color: (theme) => theme.palette.primary[800],
                        fontSize: '12px'
                    }}>
                        {activeIndex + 1 !== data.length ? 'Next' : ''}
                    </Typography>
                    {activeIndex + 1 !== data.length ? <Box sx={{
                        svg: {
                            path: {
                                stroke: theme => theme.palette.primary[800]
                            }
                        }
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M9.61984 12.0465L13.6665 7.99979L9.61984 3.95312" stroke="#0946C4" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.33322 8L13.5532 8" stroke="#0946C4" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Box> : <span style={{width:'48px'}}></span>}
                </Box>
            </SwiperButtons>

        </>
    )
}

export default StudyContentCarousel
