import React from "react";
import styled from "styled-components";
import Flex from "styled-flex-component";
import Equation from '../../../components/Equation';

const ModalWrapper = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.1);
`;

const ModalTextWrapper = styled(Flex)`
  width: 80%;
//   padding: 2em;
  border: 1px solid #ccc;
  margin: auto;
  background: #fff;
  // margin-top: 120px;
  overflow: auto;
  // height: 80%;

`;

const Modal = ({ open, url, close , type, template}) => {

    if (type && type == 'MP4') {
      if (url.includes('youtube') || url.includes('youtu')) {
        var re = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;
        let text1 = url.replace(re,'$1');
        url = `https://www.youtube.com/embed/${text1}?autoplay=1`;
      }
    } else if(type && (type.toUpperCase() == 'PPT')){
        url = "https://view.officeapps.live.com/op/embed.aspx?src="+url.split('?')[0];
    }else if(type && (type.toUpperCase() == 'PDF' || type.toUpperCase() == 'DOCS')){
        url = url.split('?')[0];
    }
    console.log(url);
    console.log(type);
    const data = (type === "mp4" && (!url.includes('youtube') || !url.includes('youtu'))) ?
     <video height="550px" controls><source src={url} type="video/mp4" /></video> :
     (type === "jpg" || type === "jpeg" || type === "png") ?
     <img width="100%" height="550px" src={url} alt={type} style={{margin: '10px'}} /> :
     <iframe allow="autoplay" src={url} title={type} frameBorder="0" width="100%" height="550px" onLoad={() => console.log('Iframe loaded')}
     onError={(e) => console.error('Iframe error', e)}></iframe>;

    return <ModalWrapper center open={open} onClick={close}>
        <ModalTextWrapper onClick={()=>{}} column>
            {data}
        </ModalTextWrapper>
    </ModalWrapper>
};

export default Modal;