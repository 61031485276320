import React, { useEffect} from 'react';
import RemedialProvider from '../../features/RemedialPath/utils/context/Remedial/RemedialProvider';
import { calculateRemedialApi } from '../../helpers/CalculateRemedial';
import { useParams } from 'react-router-dom';
const RemedialCalculation = (props) => {
    const { testId } = useParams();            
    // const[remedialData,setRemedialData]=useState(false)

     useEffect(() => {
        const fetchRemedialData=async ()=>{
            try{
                if (testId) {                
                    const response = await calculateRemedialApi(testId)                                        
                    if (response.data.statusCode === 200) {
                        console.log('in first if cond')
                        if(response.data.grandTopics?.length>0){
                            console.log('in if cond')
                            props.history.push({
                                pathname: `/remedial/testresult/${testId}`,
                            });
                        }else{
                            props.history.push({
                                pathname: `/testresult/${testId}`,
                            });
                        }
                    }else if(response.data.statusCode === 204){
                        props.history.push({
                            pathname: `/testresult/${testId}`,
                        });
                    }
        
                }
            }catch(err){
                console.log(err)
            }            
        }
        fetchRemedialData()
    }, [testId,props.history])


	return (
		<RemedialProvider>
			<h2  style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                  fontSize: "28px",
                  color: "#455a64",
                }}
                className="generatingteams">Remedial is calculating for you... Please wait.</h2>
		</RemedialProvider>
	);
};

export default RemedialCalculation;
