import React, { Component } from "react";
import {connect} from 'react-redux';
import AppLayout from '../../components/Layout/Layout';
import {getPracticeTestAC, onPracticeSubmitAC} from '../../store/actions/practiceActions';
import Flex from 'styled-flex-component';
import Circle from '../../components/UI/QuestionCircle';
import Equation from '../../components/Equation';
import styled from 'styled-components';
import { Redirect } from "react-router-dom";
import Modal from '../assessment/Modal';
// import Rectangle from '../../components/UI/Rectangle';
import MonogramTimer from '../../components/MonogramTimer/MonogramTimer';
import Instructions from '../../components/practiceInstructions';
import ReportDialog from '../../components/reportModal';


const Rectangle = () => (
    <svg height="560" width="360">
        <polygon points="180,10 360,80 360,560 10,560 10,80 180,10">
        </polygon>
        Sorry, your browser does not support inline SVG.
    </svg>
);

const RectangleBig = () => (
    <svg height="580" width="380">
        <polygon points="190,10 380,80 380,580 10,580 10,80 190,10">
        </polygon>
        Sorry, your browser does not support inline SVG.
    </svg>
);

const RectangleWrapper = styled.div`
    color: #FFFFFF;
    position: absolute;
    top: 10px;
    right: 8px;
    svg {
        // position: absolute;?
        display: block;
        fill: #FFFFFF;
        width: 100%;
        height: 100%;
        polygon {
            stroke: #FFFFFF;
            fill: #FFFFFF;
            stroke-width: 1px;
            transition: all 0.3s esae;
        }
        transition: all 0.3s esae;
    }
`

const RectangleWrapperBig = styled.div`
    // color: #000000;
    svg {
        display: block;
        width: 100%;
        fill: #EEEEEE;
        filter: blur(5px);
            -webkit-filter: blur(5px);
        height: 100%;
        // border: 2px solid rgba(#000, .2);
        polygon {
            // stroke-width: 1px;
            fill: #EEEEEE;
            
            transition: all 0.3s esae;
        }
        transition: all 0.3s esae;
    }
`

const Rectangle2 = () => (
    <svg height="960" width="360">
        <polygon points="180,10 360,80 360,960 10,960 10,80 180,10">
        </polygon>
        Sorry, your browser does not support inline SVG.
    </svg>
);

const RectangleBig2 = () => (
    <svg height="980" width="380">
        <polygon points="190,10 380,80 380,980 10,980 10,80 190,10">
        </polygon>
        Sorry, your browser does not support inline SVG.
    </svg>
);

const HintDataDiv = styled.div`
    // &::before{
        background-image: url("/images/Combined Shape Copy.svg");
        background-position: 0 0;
    background-repeat: no-repeat;
    width: 151px;
    height: 200px;
    box-sizing: border-box;
    // }
`

const QuestionsGrid = styled.div`
    display: grid;
    display: grid;
    grid-template-columns: repeat(5,60px);
    grid-gap: 0.7em 0;

`

class Assessment extends Component{

    state = {
        questionsNumbersView : 0,
        questionsNumbers: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
        loading: true,
        testSubmittedFromMulitpleFraud:false,
        pageMounted:false,
        event:null,
        noOfWindowSwitches:0,
        testSubmittedModalOpen: false,
        windowChangeWarningModalOpen: false,
        reportDialogOpened: false,
        dialogTitle: 'Are you sure you want to submit the test ?',
        showHint: false,
        showFullQuestionsView: false,
        currentTime: 0,
        showCalc: false,
        reportItemId: null,
        openReportDialog: false,
        itemToBeReported: null
    }

    componentDidMount = async () => {
        // document.body.style.overflow = 'hidden';
        let subject_id = this.props.history.location.state.subject_id;
        let unit_id = this.props.history.location.state.unit_id;
        let udd = this.props.history.location.state.university_degree_department_id;
        let cudd = this.props.history.location.state.college_university_degree_department_id;
        let semester_id = this.props.history.location.state.semester_id;
        let topic_code = this.props.history.location.state.code;
        let topic_name = this.props.history.location.state.name;

        await this.props.getQuestions(subject_id, unit_id, udd, cudd, semester_id, topic_code, topic_name);
        this.setState({loading: false});
        this.askBeforeUserLeaving();

    }

    askBeforeUserLeaving(){
        try {
          let pathname,array = [];
          this.onVisibilityChange(this.onVisibilityChangeCallBack);
        } catch (error) {
          console.log(error);
        }
        // window.addEventListener("beforeunload",(event)=> this.beforeUnloadFunction(event));
    }

    onCloseWargningWindow= ()=> {
        this.setState({windowChangeWarningModalOpen:false});
        this.openFullscreen();
    }

    onVisibilityChangeCallBack = (visible) => {   
        if (this.state.noOfWindowSwitches === 1) {            
          this.setState({
            windowChangeWarningModalOpen: true,
            noOfWindowSwitches: this.state.noOfWindowSwitches + 1,
            dialogTitle:
              "Warning: You are NOT allowed to switch apps during the test! ",
          });
        }
        if (!visible && this.state.noOfWindowSwitches == 2) {
          this.setState({ testSubmittedFromMulitpleFraud: true });
          this.onSubmitPracticeTest();
          this.setState({
            testSubmittedModalOpen: true,
            dialogTitle: "Your Test has been submitted!!",
            windowChangeWarningModalOpen: false,
          });
        }
      };

    focused = (callback) => {
        if (!this.state.visible) {
            this.setState({visible : true})
            callback( true);
        }
    }
    
    unfocused = (callback) => {
        if (this.state.visible) {
            this.setState({visible: false})
            callback( false);

        }
    }
    
    mozVisiblityChangeFunction = (callBack) => {
        (document.mozHidden ? this.unfocused : this.focused)(callBack)
    }
    visiblityChangeFunction = (callBack) => {
        (document.hidden ? this.unfocused : this.focused)(callBack)
    }
    webkitVisiblityChangeFunction = (callBack) => {
        (document.webkitHidden ? this.unfocused : this.focused)(callBack)
    }
    msVisiblityChangeFunction = (callBack) => {
        (document.msHidden ? this.unfocused : this.focused)(callBack)
    }
    
    onVisibilityChange(callback) {
        var visible = true;

        if (!callback) {
            throw new Error('no callback given');
        }

        // Standards:
        if ('hidden' in document) {
            document.addEventListener('visibilitychange',() => this.visiblityChangeFunction(callback));
        }
        if ('mozHidden' in document) {
            document.addEventListener('mozvisibilitychange',()=> this.mozVisiblityChangeFunction(callback));
        }
        if ('webkitHidden' in document) {
            document.addEventListener('webkitvisibilitychange',()=>this.webkitVisiblityChangeFunction(callback));
        }
        if ('msHidden' in document) {
            document.addEventListener('msvisibilitychange',()=>this.msVisiblityChangeFunction(callback));
        }
        // IE 9 and lower:
        if ('onfocusin' in document) {
            document.onfocusin = () =>this.focused(callback);
            document.onfocusout = () =>this.unfocused(callback);
        }
        // All others:
        window.onpageshow = window.onfocus = ()=>this.focused(callback);
        window.onpagehide = window.onblur = ()=>this.unfocused(callback);
    };

    onHintToggle = () => {
        this.setState({
            showHint: !this.state.showHint
        })
    }

    openFullQuestionsView = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState((prevState, props)=>({showFullQuestionsView: !prevState.showFullQuestionsView}));
    }

    submitTest= ()=>{
        this.setState({openSubmitTestDialog: true, dialogTitle: 'Are you sure you want to submit the test?'})
    }

    closeSubmitTestDialog = (event) => {
        event.stopPropagation();
        this.setState({openSubmitTestDialog: false, dialogTitle:''})
    }

    onSubmitPracticeTest = ()=>{
        this.props.onSubmitPracticeTest();
    }

    setCurrentTime = (time) => {
        console.log(time);
        if (time == 0) {
            this.props.submitTest();
        }
        this.setState({ currentTime: time });
    }

    showCalcHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({showCalc: true});
    } 

    closeCalc = (event)=> {
        event.preventDefault();
        event.stopPropagation();
        this.setState({showCalc: false});
    }

    componentWillUnmount = () => {
        this.closeFullscreen();
        this.removeEventListeners();
        this.props.clearState();
    }

    openInstructionsHandler = () => {
        this.setState({openInstructions: true});
    }

    closeInstructions = () => {
        this.setState({openInstructions: false});
    }

    removeEventListeners = () => {
        try {
          window.removeEventListener("beforeunload", (event) =>
            this.beforeUnloadFunction(event)
          );
          document.removeEventListener("beforeunload", this.beforeUnloadFunction);
          window.removeEventListener("beforeunload", this.beforeUnloadFunction);
          window.removeEventListener("beforeunload", this.beforeUnloadFunction);
          window.removeEventListener("beforeunload", this.beforeUnloadFunction);
          window.onbeforeunload = null;
          window.removeEventListener("pagehide", this.unfocused);
          window.removeEventListener("pageshow", this.focused);
          window.removeEventListener("focus", this.focused);
          window.removeEventListener("blur", this.unfocused);
          window.onblur = null;
          window.onfocus = null;
          window.onpagehide = null;
          window.onpageshow = null;
          if ("onfocusin" in document) {
            document.onfocusin = null;
            document.onfocusout = null;
          }
          document.removeEventListener(
            "visibilitychange",
            this.visiblityChangeFunction
          );
          document.removeEventListener(
            "mozvisibilitychange",
            this.mozVisiblityChangeFunction
          );
          document.removeEventListener("focusin", this.focused);
          document.removeEventListener("focusout", this.unfocused);
    
          document.removeEventListener(
            "webkitvisibilitychange",
            this.webkitVisiblityChangeFunction
          );
          document.removeEventListener(
            "msvisibilitychange",
            this.msVisiblityChangeFunction
          );
        } catch (error) {}
      };
    
    closeFullscreen = () => {
        try {
            if (document.fullscreenElement) {
                if (document.exitFullscreen) {
                document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                /* Safari */
                document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                /* IE11 */
                document.msExitFullscreen();
                }
            }
        } catch (error) {
        console.log(error);
        }
    };

    reportItem = (item, id)=> {
        document.body.style.overflow = 'hidden';
        this.setState({openReportDialog: true, itemToBeReported: item, reportItemId: id});
    }

    closeReport = ()=> {
        document.body.style.overflow = 'auto';
        this.setState({openReportDialog: false, itemToBeReported: null, reportItemId: null});
    }


    render () {
        if (this.props.error_loading_practice_test) {
            return <div>No Questions</div>
        }
        if (!this.props.questions) {
            return <div>Loading</div>
        }
        let questions = this.props.questions;
        let qid = this.props.qid;
        let answers = this.props.answers;
        let option_numbers = {0: 'a', 1: 'b', 2: 'c', 3: 'd', 4: 'e'};
        // console.log(this.props);
        if (this.props.submittedTest) {
            return <Redirect to={{
                pathname: '/practiceTestResults',
                state: {test_id: this.props.practice_id}
            }} exact></Redirect>
        }

        if (!this.props.test_started) {
            return <Instructions showStart={true}></Instructions>
        }

        return (
            <div style={{overflow: 'auto'}}>
                <AppLayout logoutDisable={true}></AppLayout>
                <Flex row style = {{marginTop: '96px', marginLeft: '30px', width: '98%',overflow: 'auto', paddingBottom:'50px'}}>
                    <div style={{width: '72%'}}>
                        <Flex row style = {{margin: '8px', marginTop: '50px'}}>
                            <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                                {this.props.practice_name} (Practice)
                            </div>
                            <Flex row style={{fontSize: '14px'}}>
                                <div>
                                    &nbsp; Questions: {this.props.questions.length}

                                </div>
                                <div onClick={this.openInstructionsHandler}  style={{marginLeft:'8px'}}>
                                    <img src='/images/Info.svg'></img>
                                </div>
                            </Flex>
                        </Flex>
                        {this.props.questions.length > 20 ? 
                        <div style={{marginTop: '20px'}}>
                            <Flex row>
                                {this.state.questionsNumbers.map((number, index)=> (
                                    <Flex column>
                                        {number -1 == qid ? <img style={{marginTop: '-15px', marginBottom: '-10px', width: '24px', marginLeft: '10px'}} src= '/images/Small_monkey.svg'></img>: null}
                                        <Circle current={number-1 == qid ? true: false} onQuestionClick = {this.props.onQuestionClick} number={number} key={index}></Circle>

                                    </Flex>
                                ))}
                                {[1,2,3,4,5].map((number)=> (
                                    <div key={number} style={{margin: '8px', width: '42px'}}>
                                        <img width='100%' src='/images/Buffer.svg'></img>
                                    </div>
                                ))}
                            </Flex>
                        </div> 
                        : 
                        <div>
                            <Flex row style={{marginTop: '15px'}}>
                                {this.props.questions.map((question, index)=> (
                                    <Flex column>
                                        {index == qid ? <img style={{marginTop: '-15px', marginBottom: '-10px'}} src= '/images/Small_monkey.svg'></img>: null}
                                        <Circle current={index == qid ? true: false} answered={answers[index].answered == 1 ? true : false } correct =  {answers[index].correct_answered == 1 ? true : false} onQuestionClick = {this.props.onQuestionClick} number={index + 1} key={index}></Circle>

                                    </Flex>
                                // <Circle onQuestionClick = {this.props.onQuestionClick} number={index+1} key={index}></Circle>
                                ))}
                            </Flex>
                        </div>}
                        <div style={{marginLeft: '8px',marginTop: '20px'}}>
                            <Flex row >
                                <Flex row style={{width: '85%'}}>
                                    <div style={{width: '93%', display: 'flex', flexDirection: 'row'}}>
                                    <div style={{fontWeight: 'bold', fontSize: '16px', marginRight: '8px'}}>
                                        {qid + 1}.
                                    </div>
                                    <Flex column style={{marginLeft: '10px'}}>
                                            <Flex column style={{marginBottom:'20px', textAlign: 'left'}}>
                                                <Equation blooms={questions[qid].blooms_level} math_type={questions[qid].math_type} bold equation={questions[qid].name}></Equation>
                                                {questions[qid].question_img && questions[qid].question_img != ' ' ? 
                                                    <div style={{width: '324px', height: '216px', margin: '10px'}}>
                                                        <img width='324px' height='216px' src={questions[qid].question_img}></img>
                                                    </div>: null}
                                            </Flex>
                                        
                                        <div>
                                        {questions[qid].practice_questions_options.map(
                                            ({ id, name, media, option_img, is_answer }, i) => {
                                            let option_selected = answers[qid].question_option_id;
                                            let is_selected = true ? option_selected === id : false;
                                            let is_correct_selected = is_selected && is_answer;
                                            let is_incorrect = is_selected && !is_answer;
                                            let is_correct = option_selected && is_answer;
                                            console.log(option_selected);
                                            console.log(is_selected);
                                            console.log(is_correct_selected);
                                            console.log(is_incorrect);
                                            return (
                                                <Flex row style ={{margin: '10px',marginBottom: '30px', alignItems:'center', color: is_selected ? '#4E54C8' : null}}> 
                                                    {is_correct_selected || is_correct ? <div style={{width: '24px', height: '24px', marginRight: '10px', marginLeft: '-34px'}}>
                                                        <img src='/images/Correct_answer.svg'></img>
                                                    </div>: null}
                                                    {is_incorrect ? <div style={{width: '24px', height: '24px', marginRight: '10px', marginLeft: '-34px'}}>
                                                        <img src='/images/Wrong_answer.svg'></img>
                                                    </div>: null}

                                                    <Flex row key={i} >
                                                        <div onClick={() => this.props.onPracticeOptionSelected(qid, id)} style={{margin: 'auto',marginRight: '8px'}}>{option_numbers[i]})</div>
                                                        <div>
                                                            {media === 1 ? <div style={{margin: '10px',marginTop: '0px', width: '150px', height: '100px'}}>
                                                                <img onClick={() => this.props.onPracticeOptionSelected(qid, id)} width=  '150px' height = '100px' src={option_img} alt="" />
                                                                </div> : null}
                                                        </div>
                                                        <div style = {{margin: 'auto',marginLeft: '10px'}} onClick={() => this.props.onPracticeOptionSelected(qid, id)}>
                                                            <Equation answered={is_selected} math_type={questions[qid].math_type} equation={name}></Equation>
                                                        </div>
                                                    </Flex>
                                                </Flex>)
                                            }
                                            )}
                                        </div>
                                    </Flex>
                                    </div>
                                    <div style={{width: '7%'}}>
                                        <div style={{width: '100%'}}>
                                            <img style={{marginLeft: '0'}} onClick={()=>this.reportItem('objective_questions', questions[qid].id)} src='/images/report.svg'></img>
                                        </div>
                                    </div>
                                </Flex>
                                
                                {questions[qid].hint ? <Flex column style={{width: '15%'}}>
                                    <div onClick={this.onHintToggle} >
                                        <img className='hint' src='/images/Tips.svg'></img>
                                    </div>
                                    {this.state.showHint ? <HintDataDiv>
                                        <div style={{marginTop: '20px', color: 'white', padding: '10px'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                                    </HintDataDiv> : null}
                                </Flex> : null}
                            </Flex>
                            {qid != 0 ? <div className = 'submitButton' onClick={() =>this.props.onQuestionClick(qid-1)} style={{float: 'left', marginRight: '40px'}}>
                                <div style={{marginRight: '10px'}}>
                                    <img src ='/images/left_arrow.svg'></img>
                                </div>
                                <div>Previous</div>
                            </div>:  null}
                            {qid != questions.length -1 ? <div className='nextButton' onClick={() =>this.props.onQuestionClick(qid+1)} style={{float: 'right', marginRight: '40px'}}>
                                {/* <img src='/images/Group 15.svg'></img> */}
                                
                                <div style={{margin: 'auto', marginTop: '5px'}}>Next</div>
                                <div style={{margin: 'auto', marginLeft: '10px'}}>
                                    <img src ='/images/Right_arrow.svg'></img>
                                </div>
                            </div>: null}
                        </div>
                        
                    </div>
                    <div style={{width: '380px', height: '580px', marginTop: '50px', position: 'absolute', top: '96px', left: '71%'}}>
                    {this.state.showFullQuestionsView ? 
                        <>
                            <RectangleWrapperBig>
                                <RectangleBig2>

                                </RectangleBig2>
                                
                            </RectangleWrapperBig>
                            <RectangleWrapper>
                                <Rectangle2>
                                    
                                </Rectangle2>
                            </RectangleWrapper>
                        </>
                        :
                        <>
                            <RectangleWrapperBig>
                                <RectangleBig>

                                </RectangleBig>
                                
                            </RectangleWrapperBig>
                            <RectangleWrapper>
                                <Rectangle>
                                    
                                </Rectangle>
                            </RectangleWrapper>
                        </> 
                        }
                        <div style={{position: 'absolute', top: '-40px', right: '30px', justifyContent: 'center', alignItems: 'center'}}>
                            <div>
                                <div style={{width: '275px', height: '160px'}}>
                                    <MonogramTimer seconds={0} onTimerUpdate={this.setCurrentTime} increment={true} />
                                </div>
                                <div style={{marginTop: '20px', borderBottom: '2px solid #eee', width: '290px', marginLeft: '10px'}}></div>
                                {/* <Flex row style={{marginTop: '20px', width: '300px'}}>
                                    <div style={{width: '106px', height: '57px'}}>
                                        <img width = '106px' height = '57px' src='/images/Group 19.svg'></img>
                                    </div>
                                    <div style={{widht: '184px', color: '#ff7676'}}>
                                        <p style={{margin: '0'}}>You are taking more than the average time, move to the next question!</p>
                                    </div>
                                </Flex> */}
                                {/* <div style={{marginTop: '20px', borderBottom: '2px solid #eee', width: '290px', marginLeft: '10px'}}></div> */}
                                <Flex style={{width: '312px', height: '53px', backgroundColor: 'white', boxShadow: '5px 10px 20px #eee', padding: '17px 5px', boxSizing: 'border-box',borderRadius: '11px', marginTop: '20px'}}>
                                    <Flex row style={{width: '50%', margin: 'auto'}}>
                                        <div style={{width: '70%', margin: 'auto'}}>answered</div>
                                        <div style={{width: '30%', fontSize: '24px', fontWeight:'500', color:'#4E54C8'}}>{this.props.answered}</div>
                                    </Flex>
                                    <Flex row style={{width: '50%', margin: 'auto'}}>
                                        <div style={{width: '70%', margin: 'auto'}}>remaining</div>
                                        <div style={{width: '30%', fontSize: '24px', fontWeight:'500'}}>{this.props.remaining_questions}</div>
                                    </Flex>
                                </Flex>
                                <Flex style={{width: '312px', height: '53px', backgroundColor: 'white', boxShadow: '5px 10px 20px #eee', padding: '17px 5px', boxSizing: 'border-box',borderRadius: '11px', marginTop: '20px'}}>
                                    <Flex row style={{width: '50%', margin: 'auto'}}>
                                        <div style={{width: '70%', margin: 'auto'}}>correct</div>
                                        <div style={{width: '30%', fontSize: '24px', fontWeight:'500', color: 'green'}}>{this.props.correct}</div>
                                    </Flex>
                                    <Flex row style={{width: '50%', margin: 'auto'}}>
                                        <div style={{width: '70%', margin: 'auto'}}>incorrect</div>
                                        <div style={{width: '30%', fontSize: '24px', fontWeight:'500', color: 'red'}}>{this.props.incorrect}</div>
                                    </Flex>
                                </Flex>
                            </div>
                            <div onClick={this.submitTest} style={{display: 'flex', flexDirection: 'row',marginTop: '20px', color: '#6568d9'}}>
                                <div>Submit exam</div>
                                <div style={{marginLeft:'10px'}}>
                                    <img src='/images/Right_arrow_blue.svg'></img>
                                </div>

                            </div>
                            <div onMouseEnter={this.showCalcHandler} onMouseLeave={this.closeCalc}>
                                <div style={{marginTop: '50px', marginLeft: '96%'}}>
                                    <img src='/images/BlueGradientCircle.svg'></img>
                                </div>
                                <div style={{marginTop: '-50px', marginLeft: '101%'}}>
                                    <img src='/images/viewAllQuestions.svg'></img>
                                </div>
                            </div>
                            {this.state.showCalc ? <div style={{marginTop: '-35px'}}>
                                    <span>Calculator is diabled for this test </span>
                                </div> : null}
                            {this.state.showFullQuestionsView ? 
                            <div style={{marginTop: '-70px'}}>
                                <QuestionsGrid>
                                    {this.props.questions.map((que, index)=> {
                                        return <Flex column>
                                        {/* {index == qid ? <img style={{marginTop: '-15px', marginBottom: '-10px'}} src= '/images/Small_monkey.svg'></img>: null} */}
                                        <Circle current={index == qid ? true: false} answered={answers[index].question_option_id ? true : false} onQuestionClick = {this.props.onQuestionClick} number={index + 1} key={index}></Circle>

                                    </Flex>
                                    })}
                                </QuestionsGrid>
                            </div> : null}
                        </div>
                        
                    </div>
                </Flex>
                <Modal open={this.state.openSubmitTestDialog} closeDialog = {this.closeSubmitTestDialog} submit={this.props.submitTest}>
                    <div style={{marginTop: '30px'}}>{this.state.dialogTitle}</div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '40px 20px'}}>
                        <div onClick={this.closeSubmitTestDialog} style={{margin:'auto', marginLeft: '0', color: 'red'}}>Cancel</div>
                        <div onClick={this.onSubmitPracticeTest} style={{margin: 'auto', marginRight: '10px', color: '#6568d9'}}>Submit</div>
                    </div>
                </Modal>
                <Modal style={{zIndex:12}} closeDialog={this.onCloseWargningWindow} open={this.state.windowChangeWarningModalOpen}>
                    <div>
                        Warning: You are NOT allowed to switch apps during the test! If you
                        do one more time your test will be submitted.
                    </div>
                    <div
                        onClick={this.onCloseWargningWindow}
                        style={{ margin: "auto", marginRight: "10px", color: "#6568d9" }}
                    >
                        OK
                    </div>
                </Modal>
                {this.state.openInstructions ? <Modal open={this.state.openInstructions} closeDialog={this.closeInstructions} width='750px' height='80%' marginTop='50px'>
                    <Instructions showStart={false}></Instructions>
                </Modal> : null}
                {this.state.openReportDialog ? <ReportDialog 
                    open={this.state.openReportDialog} 
                    close={this.closeReport} 
                    reportItem={this.state.itemToBeReported}
                    reportItemId={this.state.reportItemId}>
                </ReportDialog> : null}
                <style  jsx scoped>{`
                    .submitButton{
                        border: 1px solid #6568D9;
                        // border-image: linear-gradient(to right bottom, #6568D9, #9055CA) 1;
                        margin: auto;
                        // margin-left: 20px;
                        float: right;
                        width: 100px;
                        height: 23px; 
                        padding: 0.3em 1em;
                        padding-top: 8px;
                        padding-left: 20px;
                        border-radius: 20px; 
                        font-size: 14px;
                        color: #6568D9;
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        &:focus{
                            outline:0;
                        }
                          
                      }
                    .nextButton {
                        background:
                            linear-gradient(to right bottom, #7175E0 0%, #4D50CB 32.912%, #E25BC8 100%) padding-box, 
                            linear-gradient(to right bottom, #7175E0 0%, #4D50CB 32.912%, #E25BC8 100%) border-box;
                        margin: auto;
                        float: right;
                        padding: 0.3em 1em;
                        border-radius: 20px; 
                        font-size: 14px;
                        width: 100px;
                        display: flex;
                        flex-direction: row;
                        height: min-content;
                        box-sizing: border-box;
                        font-weight: 500;
                        color: white;
                        border: none;
                        cursor: pointer;
                    }
                `}

                </style>
            </div>
        )
    };
};

const mapStateToProps = state => {
    return {
        questions: state.prc.questions,
        answers: state.prc.answers,
        practice_id: state.prc.practice_id,
        qid: state.prc.qid,
        timelimit: state.prc.timelimit,
        practice_name: state.prc.practice_name,
        error_loading_practice_test: state.prc.error_loading_practice_test,
        loading: state.prc.loading_practice_test,
        remaining_questions: state.prc.remaining_questions,
        answered: state.prc.answered,
        correct: state.prc.correct,
        incorrect: state.prc.incorrect,
        submittedTest: state.prc.submittedTest,
        subject_id: state.prc.subject_id,
        test_started: state.prc.test_started,
        description: state.prc.description
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onQuestionClick: (qid, question_option_id)=>dispatch({type: 'ON_QUESTION_CLICK', qid: qid, question_option_id: question_option_id }),
        getQuestions: (subject_id, unit_id, university_degree_department_id, cudd, sem_id, topic_code, topic_name)=>dispatch(getPracticeTestAC(subject_id, unit_id, university_degree_department_id, cudd, sem_id, topic_code, topic_name)),
        onPracticeOptionSelected: (qid,option_id)=>dispatch({type: 'ON_PRACTICE_OPTION_SELECTED', qid: qid, option_id: option_id}),
        onSubmitPracticeTest:()=> dispatch(onPracticeSubmitAC()),
        clearState: ()=> dispatch({type: 'CLEAR_PRACTICE_TEST_STATE'})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Assessment);