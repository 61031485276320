import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './AssessmentInfo.module.css';

class AssessmentInfo extends Component {

  secondsToTime = (secs) => {
    let timelimit = secs;
    // let hours = Math.floor(timelimit / (60 * 60));
    // let minutes = (timelimit - hours * 60 * 60);
    // if (minutes > 60) {
    //     minutes = Math.floor(minutes/60);
    // }
    // let timelimit_modified = minutes && hours ? hours + 'hrs ' + minutes + 'mins' : minutes && !hours ? minutes + 'mins' : hours === 1 ? hours + 'hr' : hours + 'hrs';

    let hours = Math.floor(timelimit / (60 * 60));
        let seconds = (timelimit - hours * 60 * 60);
        let minutes;
        if (seconds > 60) {
            minutes = Math.floor(seconds/60);
            seconds = seconds - (minutes * 60);
        }
        let timelimit_modified = minutes && hours && seconds ? hours + 'hrs ' + minutes + 'mins ' + seconds + 'secs' : minutes && !hours && seconds ? minutes + 'mins ' + seconds + 'secs' : minutes  && !hours && !seconds ? minutes + 'mins' : hours && seconds && !minutes ? hours + 'hrs ' + seconds + 'secs' : !minutes && hours && !seconds ? hours + 'hrs' : !minutes && !hours && seconds ? seconds + 'secs' : hours && minutes && !seconds ? hours + 'hrs ' + minutes + 'mins' : '0';
    // let hours = Math.floor(secs / (60 * 60));

    // let divisor_for_minutes = secs % (60 * 60);
    // let minutes = Math.floor(divisor_for_minutes / 60);

    // let str = hours ? `${hours} hour${hours > 1 ? 's' : ''}` : null + minutes ? `${minutes} minute${minutes > 1 ? 's' : ''}` : null;
    return timelimit_modified;
  }
  render() {
    return (
      <div className={styles.AssessmentInfo}>
        <h2 className={styles.h2class} style={{ textTransform: 'capitalize' }}>{this.props.title}</h2>
        <h4 className={styles.h4class}>{this.props.portion ? 'Subject: ' + this.props.portion + ' | ' : ''} {this.props.time || this.props.totalTime ? "Time:" : null} {this.props.totalTime ? this.secondsToTime(this.props.totalTime) : this.props.time ? this.secondsToTime(this.props.time) : null} | Questions: {this.props.qcount}</h4>
      </div>

    )
  }
}

AssessmentInfo.propTypes = {
  title: PropTypes.string,
  portion: PropTypes.string,
  time: PropTypes.number,
  qcount: PropTypes.number
};

AssessmentInfo.defaultProps = {
  title: 'Title of the Assessment',
  time: 3600,
  qcount: 100
};

export default AssessmentInfo;
