import { GET_SUBJECT_DATA, SUBMIT_QUESTION, FETCHING_DATA} from "../actions/unitFeedbacksActions"

let initial_state = {
    loading: false,
    error_loading_subject_data:false,
    subject_data: [],
    units_names_and_ids: [],
    unit_data: {},
    faculty_switch: 0,
    all_units_submitted: false,
    submitted_response : false,
    error_submitted_response:false,
}

const reducer = (state = initial_state, action)=> {
    if (action.type === 'FETCHING_DATA') {
        return {
            ...state,
            loading: true,
        }
    }

    if (action.type === GET_SUBJECT_DATA) {
        if (action.err) {
            return {
                ...state,
                error_loading_sem_feedbacks: true,
                loading: false
            }
        }
        console.log("in reducer of getSubjectData");
        console.log(action.res);
        let current_unit_data = {};
        let subject_data = action.res;
        let initial_unit_id = parseInt(action.unit_id);

        subject_data.forEach(unit => {
            const isUnitCompleted = unit.faculty
                .filter(faculty => faculty.is_active === 1)
                .every(faculty => {
                    return faculty.questions.every(question => question.submission_count > 0);
                });

            unit.faculty.forEach(faculty => {
                const isFacultyCompleted = faculty.questions.every(question => question.submission_count > 0);
                faculty.faculty_completed_first_time = isFacultyCompleted ? 1 : 0;
            });

            unit.is_completed = isUnitCompleted ? 1 : 0;
            unit.completed_first_time = isUnitCompleted ? 1 : 0;

            if (Object.keys(current_unit_data).length === 0 && !isUnitCompleted) {
                current_unit_data = unit;
            }
        });
        if (Object.keys(current_unit_data).length === 0) {
            current_unit_data = subject_data[0];
        }
        const initial_unit = subject_data.find(unit => unit.unit_id === initial_unit_id);
        if (initial_unit) {
            current_unit_data = initial_unit;
        }

        let allUnitsCompleted = true;
        for (const unit of subject_data) {
            if (unit.is_active === 1) {
                if (unit.is_completed !== 1) {
                    allUnitsCompleted = false;
                    break;
                }
            }
        }

        // const current_unit_data = action.res.length > 0 ? action.res[0] : {};
        const units_details = subject_data.map(unit => ({
            unit_id: unit.unit_id,
            unit_name: unit.unit_name,
            is_active: unit.is_active,
            is_empty: unit.faculty.length === 0 ? 1 : 0, 
            is_completed: unit.is_completed,
        }));
        console.log(subject_data);
        return {
            ...state,
            loading: false,
            subject_data: subject_data,
            unit_data: current_unit_data,
            units_names_and_ids: units_details,
            all_units_submitted: allUnitsCompleted,
            error_loading_subject_data: false,
            submitted_response: false,
            error_submitted_response: false,
        }
        console.log(state)
    }
    if (action.type === SUBMIT_QUESTION) {
        // if (action.err) {
        //     return {
        //         ...state,
        //         submitted_response : false,
        //         error_submitted_response:true,
        //     }
        // }
        // return {
        //     ...state,
        //     submitted_response : true,
        //     error_submitted_response:action.err,
        // }
      return {
        ...state,
      }
    }

    if (action.type === 'SWITCH_FEEDBACK_UNIT') {
        if (action.prev_unit_id) {
            const updatedUnitValue = action.unit_data;
            const indexToUpdate = state.subject_data.findIndex(unit => unit.unit_id === action.prev_unit_id);
            if (indexToUpdate !== -1) {
                const updatedSubjectData = [...state.subject_data]
                updatedSubjectData[indexToUpdate] = updatedUnitValue;

                let allUnitsCompleted = true;
                for (const unit of updatedSubjectData) {
                    if (unit.is_active === 1) {
                        if (unit.is_completed !== 1) {
                            allUnitsCompleted = false;
                            break;
                        }
                    }
                }

                return {
                    ...state,
                    all_units_submitted: allUnitsCompleted,
                    unit_data: updatedUnitValue,
                    subject_data: updatedSubjectData,
                }
            }
            return {
                ...state
            }
        }
        return {
            ...state
        }
    }

    if (action.type === 'GET_UNIT_FEEDBACK_DATA') {
        if (action.unit_id) {
            const unit = state.subject_data.find(u => u.unit_id === action.unit_id);
            if (unit) {
                return {
                    ...state,
                    unit_data : unit,
                }
            }
        }
        return {
            ...state,
        }
    }

    if (action.type === 'FEEDBACK_OF_A_UNIT_COMPLETED') {
        if (action.unit_id) {
            const updatedUnitValue = action.unit_data;
            const indexToUpdate = state.subject_data.findIndex(unit => unit.unit_id === action.unit_id);
            if (indexToUpdate !== -1) {
                const updatedSubjectData = [...state.subject_data]
                updatedSubjectData[indexToUpdate] = updatedUnitValue;

                let current_unit_data = {};
        
                updatedSubjectData.forEach(unit => {
                    const isUnitCompleted = unit.completed_first_time;

                    unit.faculty.forEach(faculty => {
                        const isFacultyCompleted = faculty.questions.every(question => question.submission_count > 0);
                        faculty.faculty_completed_first_time = isFacultyCompleted ? 1 : 0;
                    });
        
                    if (Object.keys(current_unit_data).length === 0 && !isUnitCompleted && unit.is_active === 1) {
                        current_unit_data = unit;
                    }
                });
                if (Object.keys(current_unit_data).length === 0) {
                    const activeUnit = updatedSubjectData.find(unit => unit.is_active === 1);
                    if (activeUnit) {
                        current_unit_data = activeUnit;
                    }
                    else {
                        current_unit_data = state.unit_data;
                    }
                }
                const units_details = updatedSubjectData.map(unit => ({
                    unit_id: unit.unit_id,
                    unit_name: unit.unit_name,
                    is_active: unit.is_active,
                    is_empty: unit.faculty.length === 0 ? 1 : 0, 
                    is_completed: unit.is_completed,
                }));

                let allUnitsCompleted = true;
                for (const unit of updatedSubjectData) {
                    if (unit.is_active === 1) {
                        if (unit.is_completed !== 1) {
                            allUnitsCompleted = false;
                            break;
                        }
                    }
                }

                return {
                    ...state,
                    all_units_submitted: allUnitsCompleted,
                    units_names_and_ids: units_details,
                    unit_data: current_unit_data,
                    subject_data: updatedSubjectData,
                }
            }
            return {
                ...state
            }
        }
        return {
            ...state,
        }
    }

    if (action.type === 'FEEDBACK_OF_A_FACULTY_IN_A_UNIT_COMPLETED') {
        if (action.unit_id) {
            const updatedUnitValue = action.unit_data;
            const indexToUpdate = state.subject_data.findIndex(unit => unit.unit_id === action.unit_id);
            if (indexToUpdate !== -1) {
                const updatedSubjectData = [...state.subject_data]
                updatedSubjectData[indexToUpdate] = updatedUnitValue;

                let allUnitsCompleted = true;
                for (const unit of updatedSubjectData) {
                    if (unit.is_active === 1) {
                        if (unit.is_completed !== 1) {
                            allUnitsCompleted = false;
                            break;
                        }
                    }
                }

                return {
                    ...state,
                    all_units_submitted: allUnitsCompleted,
                    faculty_switch: state.faculty_switch + 1, 
                    unit_data: updatedUnitValue,
                    subject_data: updatedSubjectData,
                }
            }
            return {
                ...state
            }
        }
        return {
            ...state
        }
    }

    return {...state}
}

export default reducer