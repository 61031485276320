import React, { Component } from "react";
import classes from "./login.module.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserDetailsAC,
  getLoginOtpAC,
  OPENENTERROLLNUMBERAGAIN,
  getUserPasswordDetailsAC,
  updatePasswordAC,
} from "../../store/actions/loginActions";
import { Redirect } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA;
let timeInterval = null;
let recaptchaRef = null;

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpSent: false,
      roll_number: null,
      otp: null,
      resend: false,
      otpList: ["", "", "", ""],
      isVerified: false,
      time: 0,
      captchaError: false,
      otp_disable: false,
      resend_disable: false,
    };
  }

  componentDidMount() {
    recaptchaRef = React.createRef();
    if (!this.props.login_otp_message) this.props.getLoginOtp(false);
  }

  onRollNumberChange = (event) => {
    this.setState({ roll_number: event.target.value });
  };

  otpEnteredHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (isNaN(event.target.value)) {
      return;
    }
    let otp = this.state.otpList;
    otp[Number(event.target.name) - 1] = event.target.value;

    if (String(event.target.name) !== "4" && event.target.value) {
      event.target.nextElementSibling.focus();
      this.setState({ otpList: otp });
    } else if (!event.target.value && String(event.target.name) !== "1") {
      event.target.previousElementSibling.focus();
      this.setState({ otpList: otp });
    } else {
      this.setState({ otpList: otp });
    }
  };

  onEnterOtpClickHandler = (event) => {
    event.target.nextSibling.children[0].focus();
  };

  onSubmitOTP = async () => {
    let otp = "";
    this.state.otpList.forEach((el) => (otp += el));
    if (this.state.isVerified) {
      await this.props.getUserDetails(otp);
      recaptchaRef.current.reset();
      this.setState({ isVerified: false, otp: "", otpList: ["", "", "", ""] });
    }
  };

  openEnterRollnumber = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.openEnterRollnumberAgain();
  };

  resendOtp = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!timeInterval && this.state.isVerified && !this.state.time) {
      this.setState({
        otp: "",
        captchaError: false,
        resend_disable: true,
        otp_disable: false,
      });
      this.props.getLoginOtp(true);
      recaptchaRef.current.reset();
      let time = 30;
      timeInterval = setInterval(() => {
        if (time <= 0) {
          clearInterval(timeInterval);
          timeInterval = null;
          this.setState({ time: 0, resend_disable: false });
          return;
        }
        this.setState({ time: time-- });
      }, 1000);
    } else if (!this.state.isVerified) this.setState({ captchaError: true });
  };

  onOtpEnter = (event) => {
    if (event.key === "Enter") {
      if (
        !(
          this.state.login_otp_message ||
          !this.state.otpList[0] ||
          !this.state.otpList[1] ||
          !this.state.otpList[2] ||
          !this.state.otpList[3] ||
          this.state.isVerified
        )
      ) {
        this.onSubmitOTP();
        recaptchaRef.current.reset();
        this.setState({ captchaError: false, otp_disable: false });
      } else if (!this.state.isVerified) this.setState({ captchaError: true });
    }
  };

  onChangeCaptcha = (value) =>
    this.setState({ isVerified: value !== "" && value !== null });

  render() {
    if (this.props.user_logged_in_successfully && !this.props.forgot_password) {
      return <Redirect to="/home" exact />;
    }
    // console.log(this.props);
    console.log(this.state);
    let otp = this.state.otpList;

    // console.log(otp);
    return (
      <>
        <div className={classes.dFlex}>
          <div className={classes.loginArea}>
            <fieldset className={classes.otpFieldSet}>
              <legend style={{ width: "auto", float: "none" }}>
                <img
                  src="/rmklogo.png"
                  alt="logo"
                  width="200px"
                  style={{ padding: "0px 10px", borderRadius: "20px" }}
                  height="75px"
                />
              </legend>
              <div style={{ margin: "30px", padding: "20px", marginTop: '10px' }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "20px 0",
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ marginRight: "10px", textAlign: "center" }}>
                    {!this.props.error_sending_otp &&
                    this.props.login_otp_message ? (
                      this.props.login_otp_message
                    ) : this.props.error_sending_otp ? (
                      <p
                        style={{
                          color: "red",
                          margin: "0",
                          fontSize: "16px",
                          fontWeight: "400",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        Request Failed! Try again after some time
                      </p>
                    ) : (
                      "Sending Otp"
                    )}
                  </div>
                  {this.props.login_otp_message ? (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={this.openEnterRollnumber}
                    >
                      <img src="/images/login/edit_button.png" alt="edit"></img>
                    </div>
                  ) : null}
                </div>
                <div style={{ margin: "20px 0" }}>
                  You will receive a 4 digit code{" "}
                </div>
                <div
                  onClick={this.onEnterOtpClickHandler}
                  style={{
                    color: "#7b7b7b",
                    textAlign: "left",
                    marginTop: "40px",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginBottom: "15px",
                  }}
                >
                  Enter OTP
                </div>
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  id="otp-screen"
                >
                  <input
                    onChange={this.otpEnteredHandler}
                    value={otp[0] !== "" ? Number(otp[0]) : ""}
                    name={1}
                    autoComplete="off"
                    className={classes.otpText}
                    type="text"
                    placeholder=""
                    maxLength="1"
                    disabled={this.state.otp_disable}
                  />
                  <input
                    onChange={this.otpEnteredHandler}
                    value={otp[1] !== "" ? Number(otp[1]) : ""}
                    name={2}
                    autoComplete="off"
                    className={classes.otpText}
                    type="text"
                    placeholder=""
                    maxLength="1"
                    disabled={this.state.otp_disable}
                  />
                  <input
                    onChange={this.otpEnteredHandler}
                    value={otp[2] !== "" ? Number(otp[2]) : ""}
                    name={3}
                    autoComplete="off"
                    className={classes.otpText}
                    type="text"
                    placeholder=""
                    maxLength="1"
                    disabled={this.state.otp_disable}
                  />
                  <input
                    onKeyDown={(event) => this.onOtpEnter(event)}
                    onChange={this.otpEnteredHandler}
                    value={otp[3] !== "" ? Number(otp[3]) : ""}
                    name={4}
                    autoComplete="off"
                    className={classes.otpText}
                    type="text"
                    placeholder=""
                    maxLength="1"
                    disabled={this.state.otp_disable}
                  />
                </div>
                {this.props.error_getting_details &&
                this.props.error_user_message ? (
                  <div style={{ color: "red", margin: "10px" }}>
                    Invalid Otp ! Please click on <b>resend</b> to receive new OTP!
                  </div>
                ) : null}
                {this.props.resent_otp &&
                this.state.time > 0 &&
                this.state.resend_disable ? (
                  <div
                    style={{
                      color: "blue",
                      fontWeight: "bold",
                      margin: "10px",
                    }}
                  >
                    Otp has been resent.
                    <br />{" "}
                    <small>
                      Please wait {this.state.time}s to resend again.
                    </small>
                  </div>
                ) : null}
                {this.state.time === 0 && !this.state.resend_disable ? (
                  <div style={{ textAlign: "right", margin: "20px" }}>
                    Didn't receive otp ?{" "}
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={this.resendOtp}
                    >
                      Resend
                    </span>
                  </div>
                ) : null}
                <ReCAPTCHA
                  style={{
                    border: this.state.captchaError ? "1px solid red" : "",
                  }}
                  sitekey={RECAPTCHA_KEY}
                  ref={recaptchaRef}
                  onChange={this.onChangeCaptcha}
                />
                <button
                  className={classes.loginButton}
                  disabled={
                    this.state.login_otp_message ||
                    !this.state.otpList[0] ||
                    !this.state.otpList[1] ||
                    !this.state.otpList[2] ||
                    !this.state.otpList[3] ||
                    !this.state.isVerified ||
                    this.state.otp_disable
                  }
                  style={{ width: "320px", height: "56px" }}
                  onClick={this.onSubmitOTP}
                >
                  Submit
                </button>
                <div style={{ clear: "both" }}></div>
                <div
                  style={{
                    float: "right",
                    marginRight: "-160px",
                    position: "relative",
                    top: "-140px",
                  }}
                >
                  <img src="/images/login/monkey.svg" width="170px" alt="" />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roll_number: state.login.roll_number,
    login_otp_message: state.login.login_otp_message,
    user_logged_in_successfully: state.login.user_logged_in_successfully,
    error_in_roll_number: state.login.error_in_roll_number,
    error_getting_details: state.login.error_getting_details,
    error_sending_otp: state.login.error_sending_otp,
    error_user_message: state.login.error_user_message,
    resent_otp: state.login.resent_otp,
    has_password: state.login.has_password,
    is_user: state.login.is_user,
    password_updated_successfully: state.login.password_updated_successfully,
    error_updating_password: state.login.error_updating_password,
    user_password_details_message: state.login.user_password_details_message,
    user_password_status: state.login.user_password_status,
    login_through_otp: state.login.login_through_otp,
    login_through_password: state.login.login_through_password,
    got_user_password_details: state.login.got_user_password_details,
    got_user_details: state.login.got_user_details,
    error_loading_user_password_details:
      state.login.error_loading_user_password_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginOtp: (resend) => dispatch(getLoginOtpAC(resend)),
    getUserDetails: (otp) => dispatch(getUserDetailsAC(otp)),
    openEnterRollnumberAgain: () =>
      dispatch({ type: OPENENTERROLLNUMBERAGAIN }),
    getPasswordDetails: (roll_number) =>
      dispatch(getUserPasswordDetailsAC(roll_number)),
    updatePassword: (roll_number, password) =>
      dispatch(updatePasswordAC(roll_number)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
