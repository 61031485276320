import { Tooltip, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

const CustomCircularProgressBar = ({ percentage = 0, radius = 20, animation = true }) => {
  const [sectors, setSectors] = useState([]); 
  const sectorCount = 10;
  const sectorRadius = radius;        //For 50X50 whdth X height take radius 25px
  const strokeWidth = 5;             //Width of the sector
  const sectorSpacing = 0.07;       //Adjust the spacing between sectors
  const blueGradient = ['#E7EEFE', '#CEDEFD', '#B6CDFC', '#9DBCFB', '#6D9BF9', '#3C79F7', '#0B58F5', '#0946C4', '#073593', '#042363'];

  useEffect(() => {
    setSectors([]);

    const renderSectors = async () => {
      for (let i = 0; i < sectorCount; i++) {
        const startAngle = ((5 / 2 + i + sectorSpacing) / sectorCount) * Math.PI * 2;
        const endAngle = ((5 / 2 + i + 1 - sectorSpacing) / sectorCount) * Math.PI * 2;
        const largeArcFlag = endAngle - startAngle <= Math.PI ? '0' : '1';
        const fillColor = i < Math.ceil((percentage / 100) * sectorCount) ? blueGradient[i] : '#DFE3E8';

        const newSector = (
          <path
            key={i}
            d={`M ${sectorRadius + sectorRadius * Math.cos(startAngle)} ${sectorRadius + sectorRadius * Math.sin(startAngle)}
                A ${sectorRadius} ${sectorRadius} 0 ${largeArcFlag} 1
                ${sectorRadius + sectorRadius * Math.cos(endAngle)} ${sectorRadius + sectorRadius * Math.sin(endAngle)}
                L ${sectorRadius + (sectorRadius - strokeWidth) * Math.cos(endAngle)} ${sectorRadius + (sectorRadius - strokeWidth) * Math.sin(endAngle)}
                A ${sectorRadius - strokeWidth} ${sectorRadius - strokeWidth} 0 ${largeArcFlag} 0
                ${sectorRadius + (sectorRadius - strokeWidth) * Math.cos(startAngle)} ${sectorRadius + (sectorRadius - strokeWidth) * Math.sin(startAngle)}
                Z`}
            fill={fillColor}
          />
        );

        setSectors(prevSectors => [...prevSectors, newSector]);

        if (animation) {
          await new Promise(resolve => setTimeout(resolve, 100));    //Delay for better UX experience
        }
      }
    };

    renderSectors();
  }, [percentage, animation, sectorRadius]);

  return (
    <Box style={{ position: 'relative' }}>
      <Tooltip title={percentage} placement="top" arrow >
        <svg width={sectorRadius * 2} height={sectorRadius * 2} viewBox={`0 0 ${sectorRadius * 2} ${sectorRadius * 2}`}>
          {sectors}
        </svg>
      </Tooltip>
    </Box>
  );
};

export default CustomCircularProgressBar;