import "bootstrap/dist/css/bootstrap.min.css";
import { Component } from "react";
// import 'bootstrap/dist/js/bootstrap.bundle.min';
import Table from "react-bootstrap/Table";
import React from "react";
import ReactDOM from "react-dom";
import styled, { keyframes, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import ReactCountdownClock from "react-countdown-clock";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
class Joinedchallange extends Component {
  render() {
    let startslabel;
    let startsstyle;

    if (this.props.time) {
      startslabel = "Starts in";
      startsstyle = "row";
    } else {
      startslabel = "Starts at";
      startsstyle = "column";
    }
    // console.log(this.props.data);
    return (
      <div style={{ backgroundColor: "white" }}>
        <Maindiv>
          <div
            style={{
              float: "right",
              display: "flex",
              alignItems: "center",
              margin: "-26px 12px 0px 0px",
              flexDirection: `${startsstyle}`,
            }}
          >
            <span
              style={{
                opacity: "0.5",
                fontWeight: "700",
                marginRight: "-24px",
              }}
            >
              {startslabel}:-
            </span>
            {this.props.time ? (
              <>
                {/* <div class="half-circle"></div>
                <div class="hours"></div>
                <div class="hours1"></div> */}
                <ReactCountdownClock
                  seconds={this.props.time}
                  alpha={0.9}
                  color="red"
                  size={70}
                  weight={5}
                  onComplete={this.props.onFinished}
                  showMilliseconds={false}
                />
              </>
            ) : (
              <b>{this.props.challengedetails.start_date}</b>
            )}
          </div>
          <h2 style={{ color: "red", marginLeft: "120px" }}>
            {this.props.challengedetails.title}
          </h2>
          {/*<span style={{opacity:"0.5",fontWeight:"700",paddingTop:"62px"}}>Starts in</span>
		<h3 style={{fontWeight:"700"}}>05:00</h3>*/}
          {/*<p style={{padding:"10px"}}>Total registrations:127</p>*/}
          <br />
          <br />
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <span style={{}}>Total registrations:{this.props.data.length}</span>
            {/* <span>Total joined:130</span> */}
          </div>
          <div
            style={{
              display: "block",
              overflow: "auto",
              height: "332px",
              position: "relative",
            }}
          >
            <Table
              bordered
              hover
              size="sm"
              style={{ width: "50%", margin: "0px auto" }}
            >
              <thead style={{ color: "white", backgroundColor: "red" }}>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Position</th>
                  <th scope="col">Roll ID</th>
                  <th scope="col">Department</th>
                  {/* <th scope="col">Status</th> */}
                </tr>
              </thead>
              <tbody>
                {this.props.data.map((students, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {students.faculty_first_name &&
                        students.faculty_last_name
                          ? `${students.faculty_first_name} ${students.faculty_last_name}`
                          : students.student_name}
                      </td>
                      <td>
                        {students.faculty_first_name &&
                        students.faculty_last_name
                          ? "Faculty"
                          : "Student"}
                      </td>
                      <td>
                        {students.faculty_first_name &&
                        students.faculty_last_name
                          ? "-"
                          : students.student_roll_number}
                      </td>
                      <td>{students.department_name}</td>
                      {/* <td style={{ color: "green" }}>Active</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Maindiv>
      </div>
    );
  }
}
const Maindiv = styled.div`
  height: 595px;
  border-radius: 50px;
  margin: 0px 30px 0px 30px;
  background-color: white;
  padding: 35px;
  @media (max-width: 768px) {
    height: 530px;
  }
  @media (max-width: 1024px) {
    height: 530px;
  }
`;
const Responsivetable = styled.table`
   width:40%;
    position: absolute;
    right: 73px;
    bottom: 100px;
    border: 1px solid grey;
    thead{
    	background-color:red;
    }
     @media (max-width: 1440px) {
    }
    bottom:118px;
  }
   @media (max-width: 1024px) {
    bottom:98px;
  }
   @media (max-width: 768px) {
    bottom:98px;
    width: 40%;
  }

`;
const mapStateToProps = (state) => {
  return {
    challengedetails: state.joinchallenge.challangedetails,
  };
};
export default withRouter(connect(mapStateToProps, null)(Joinedchallange));
