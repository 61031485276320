import * as joinactions from "../actions/joinchallengeactions";

const initial_state = {
  challangedetails: 17,
  loading_question_order:true,
  error_loading_question_order:false,
};
const reducer = (state = initial_state, action) => {
  if (action.type === joinactions.REFRESH_TOKEN) {
    if (action.error) {
      return {
        ...state,
        error_loading_live: true,
        enrolled_data: null,
        teamdetails: null,
        captainconfirmed: false,
        getchallengedetails: false,
      };
    }
    return {
      ...state,
      enrolled_data: null,
      teamdetails: null,
      captainconfirmed: false,
    };
  } else if (action.type === joinactions.JOINED_CHALLENGE) {
    if (action.error) {
      return {
        ...state,
        joinedchallenge: true,
        enrolled_data: action.response,
        teamdetails: null,
      };
    }
    return {
      ...state,
      joinedchallenge: true,
      error_loading_live: false,
      enrolled_data: null,
      teamdetails: null,
      captainconfirmed: false,
    };
  } else if (action.type === joinactions.GET_TEAMDETAILS) {
    if (action.error) {
      //console.log(action.response)
      console.log({
        ...state,
        teamdetails: action.response,
        allteams: action.allteams,
      });
      return {
        ...state,
        teamdetails: action.response,
        allteams: action.allteams,
      };
    }
    return {
      ...state,
      teamdetails: "Unable to get details",
      allteams: "Unable to get details",
    };
  } else if (action.type === joinactions.CHALLENGE_DETAILS) {
    if (action.error) {
      return {
        ...state,
        challangedetails: action.response,
        getchallengedetails: action.error,
      };
    }
    return {
      ...state,
      challangedetails: action.response,
      getchallengedetails: action.error,
    };
  } else if (action.type === joinactions.LEADERBOARD_DETAILS) {
    // console.log(action);
    if (action.error) {
      return {
        ...state,
        leaderboarddetails: action.response,
      };
    }
    return {
      ...state,
      leaderboarddetails: "Unable to get details",
    };
  } else if (action.type === joinactions.UPDATE_TEAMS) {
    // console.log(action);
    if (action.error) {
      return {
        ...state,
        captainconfirmed: action.response,
      };
    }
    return {
      ...state,
      captainconfirmed: action.response,
    };
  } else if (action.type === joinactions.QUESTION_ORDER_DETAILS) {
    // console.log(action);
    if (action.error) {
      return {
        ...state,
        loading_question_order:false,
        error_loading_question_order:true,
      };
    }
    return {
      ...state,
      loading_question_order:false,
      error_loading_question_order:false,
      question_order_details:action.response,
    };
  }else if(action.type === "CLEAR_SUBMISSIONORDER"){
    return {
      ...state,
      loading_question_order:true,
        error_loading_question_order:true,
    }
  }
  return state;
  console.log(state);
};

export default reducer;
