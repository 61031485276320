import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import {  itemUpdateRemoveAddAC } from '../../store/actions/profileActions';

const SaveButton = styled.button`
    border-radius: 32px;
    background:  ${({ active }) => (active ? "linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%)" : "grey")};
    margin: auto;
    text-align: center;
    width: 98px;
    padding: 8px;
    color: white;
    margin-right: 0;
    border: none;
`

class UpdateSkills extends Component {

    state = {
        openContent : false,
        contentUrl: '',
        contentType: null,
        skills: this.props.skills.map((skill)=> {
            return {...skill}
        })
        
    }

    componentDidMount = () => {
    }

    onLevelChangeHandler = (event, level, id, index) => {
        let skills = [...this.state.skills];
        skills[index].level = level;
        this.setState({skills: skills})
    }

    closeSkillsEditor = () => {
        this.setState({
            skills: this.props.skills
        });
        this.props.clearStatus();
        this.props.close();
    }

    updateSkillsHandler = () => {
        const formdata = new FormData();
        let skills = [];
        let skills_dict = this.state.skills;
        let old_skills_dict = this.props.skills;
        skills_dict.map((skill, index)=> {
            if (skill.level != old_skills_dict[index].level) {
                let dict = {}
                dict[skill.master_id] = skill.level;
                skills.push(dict);
            }
        });
        // skills.forEach((skill)=> {
        //     skill = JSON.stringify(skill)
        // })
        formdata.append('skills', JSON.stringify(skills));

        let item = {};
        item.type = 'UPDATE_SKILLS';
        item.skills = this.state.skills;
        this.props.updateSkills(item, formdata);
    }

    deleteSkillHandler = (event, id) => {
        const formdata = new FormData();
        formdata.append('skills', id);
        let item = {};
        item.type = 'REMOVE_SKILLS';
        item.id = id;
        this.props.updateSkills(item, formdata);
        let skills = this.state.skills.map((skill)=> {
            return {...skill}
        });
        let index = skills.findIndex((skill)=> {
            return skill.id == id
        });
        skills.splice(index, 1);
        this.setState({skills: skills});
    }

    render () {
        console.log(this.props);
        let saveButtonActive = false;
        let skills_dict = this.state.skills;
        let old_skills_dict = this.props.skills;
        skills_dict.map((skill, index)=> {
            if (skill.level != old_skills_dict[index].level) {
                saveButtonActive = true;
            }
        })
        return (
            <div style={{}}>                    
                <div  style={{ textAlign: 'left', margin: '20px 0'}}>
                    <div style={{ margin: '0 auto 20px auto', fontWeight: 'bold',textAlign: 'left', fontSize: '20px'}}>
                        <div style={{margin: 'auto 20px'}}>Rate Your Skills</div>
                    </div>
                    <div style={{marginTop:'25px'}}>
                        <div style={{display: 'flex'}}>
                            <div style={{width: '30%'}}>Skills</div>
                            <div style={{width: '20%'}}>Learning</div>
                            <div style={{width: '20%'}}>Working</div>
                            <div style={{width: '20%'}}>Advanced</div>
                            <div style={{width: '10%'}}></div>
                        </div>
                        {this.state.skills.map((skill, index)=> { return <div style={{margin: '15px auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{width: '30%', textAlign: 'left'}}>{skill.name}</div>
                            {skill.level == 'Learning' || skill.level =='Working' || skill.level == 'Advanced' ? <div onClick={(event)=> this.onLevelChangeHandler(event,'Learning', skill.id, index)} style={{width: '20%'}}>
                                <img src='/images/profile/star_one.png' />
                            </div> : <div onClick={(event)=> this.onLevelChangeHandler(event,'Learning', skill.id, index)}  style={{width: '20%'}}>
                                <img src='/images/profile/star_zero.png' />
                            </div> }
                            {skill.level =='Working' || skill.level == 'Advanced' ? <div onClick={(event)=> this.onLevelChangeHandler(event,'Working', skill.id, index)}  style={{width: '20%'}}>
                                <img src='/images/profile/star_one.png' />
                            </div> : <div onClick={(event)=> this.onLevelChangeHandler(event,'Working', skill.id, index)}  style={{width: '20%'}}>
                                <img src='/images/profile/star_zero.png' />
                            </div> }
                            {skill.level == 'Advanced' ? <div onClick={(event)=> this.onLevelChangeHandler(event,'Advanced', skill.id, index)} style={{width: '20%'}}>
                                <img src='/images/profile/star_one.png' />
                            </div> : <div onClick={(event)=> this.onLevelChangeHandler(event,'Advanced', skill.id, index)}  style={{width: '20%'}}>
                                <img src='/images/profile/star_zero.png' />
                            </div> }
                            <div style={{width: '10%'}} onClick={(event)=>this.deleteSkillHandler(event, skill.id)}>
                                <img src='/images/profile/delete_icon.svg'></img>
                            </div>
                        </div>

                        })}
                    </div>
                    {this.props.error_deleting_skills? <div style={{color: 'red', textAlign: 'left'}}>Error Deleting  Skills. Please try again after some time!!!!</div> : null}
                    {this.props.deleted_skills_successfully? <div style={{color: 'green', textAlign: 'left'}}>Skills Deleted Successfully.</div> : null}
                    
                    {this.props.updated_skills_successfully? <div style={{color: 'green', textAlign: 'left'}}>Skills Proficiency Details Updated Successfully.</div> : null}
                    {this.props.error_updating_skills? <div style={{color: 'red', textAlign: 'left'}}>Error Updating Skills Details. Please try again after some time!!!!</div> : null}
                    
                    {this.props.updated_skills_successfully?  
                    <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto', marginTop: '25px'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeSkillsEditor}>OK</div>
                    </div>
                    :<div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto', marginTop: '25px'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeSkillsEditor}>Cancel</div>
                        <div>
                            <SaveButton disabled={!saveButtonActive} active={saveButtonActive} onClick={()=>this.updateSkillsHandler()}>Save</SaveButton>
                        </div>
                    </div>}
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.prof.loading,
        college: state.prof.college,
        college_university_degree_department_id: state.prof.college_university_degree_department_id,
        department_details: state.prof.department_details,

        id: state.prof.id,
        roll_number: state.prof.roll_number,
        semester_id: state.prof.semester_id,
        university_degree_department_id: state.prof.university_degree_department_id,
        
        working_skills: state.prof.working_skills,
        advance_skills: state.prof.advance_skills,
        other_skills: state.prof.other_skills,
        learning_skills: state.prof.learning_skills,
        skills: state.prof.skills,

        updated_skills_successfully: state.prof.updated_skills_successfully,
        error_updating_skills: state.prof.error_updating_skills,

        deleted_skills_successfully: state.prof.deleted_skills_successfully,
        error_deleting_skills: state.prof.error_deleting_skills

    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateSkills: (item, formdata) => dispatch(itemUpdateRemoveAddAC(item, formdata)),
        clearStatus: () => dispatch({type:'CLEAR_UPDATE_SKILLS_STATUS'})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateSkills));