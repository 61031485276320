import React, { Component } from "react";
import AppLayout from "../../../components/Layout/Layout";
import Flex from "styled-flex-component";
import { connect } from "react-redux";
import StudentCourseDetails from "../../../components/studentCourseDetails";
import CourseMenu from "../../../components/CourseMenu";
import Carousel from "react-grid-carousel";
import Analyticscreen from "../../dashboard/analyticsscreen";
import Subjectscreenloader from "./loadingskeleton";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import {FiRefreshCcw} from "react-icons/fi";
import {MdArrowBackIos} from "react-icons/md";
import {FcFeedback} from "react-icons/fc";
import Rating from '@mui/material/Rating';
import {
  getCourseContentAC,
  getCourseDetailsAC,
  addOrDeleteBookmarkAC,
  onUnitChangeAC,
  CLOSEDECK,
  getDeckDataAC,
  OPENDECKDATA,
  getAnalysisConent,
  getAnalysisSemesterConentAc,
  getreemapDataAC,
  getAllsectionsubjectLeaderboard,
  getfeedbackquestions,
  submitfeedbackresponse,
} from "../../../store/actions/courseContentActions";
import Modal1 from "./Modal";
import Modal from "react-bootstrap/Modal";
import ContentHexagonBox from "../../../components/ContentHexagonBox";
import { withRouter } from "react-router-dom";
import PreviewCard from "../../../components/decks/PreviewCard/PreviewCard";
import styled from "styled-components";
import ReportDialog from "../../../components/reportModal";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {AiOutlineArrowRight} from "react-icons/ai";
import {CgFileDocument} from "react-icons/cg";
import Analyticskeletonloader from "../../dashboard/analyticsloadingskeleton";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import ReactApexChart from "react-apexcharts";
import { init, identify, Identify, track } from '@amplitude/analytics-browser';
const AMPLITUDE_API_KEY=process.env.REACT_APP_AMPLITUDE_KEY;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // const classes = useStyles();
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
// const useStyles = makeStyles({
//   customLabelColor: {
//     color: "#fff"
//   }
// });
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  fontSize:`14px`
//   border: `1px solid grey`,
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
//   '&:before': {
//     display: 'none',
//   },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
   fontSize:`12px`,
  backgroundColor:'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '10px',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '5px',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  fontSize:`14px`
}));

const TopicDiv = styled.div`
  margin: 10px 10px 10px 25px;

  @media (max-width: 1100px) {
    margin: 10px 10px 10px 50px;
  }

  @media (max-width: 768px) {
    margin: 10px 10px 10px 30px;
  }
`;

const TopicNameDiv = styled.div`
  width: 60%;
  @media (max-width: 1100px) {
    width: 70%;
  }

  @media (max-width: 768px) {
    width: 50%;
  }
`;
const AcadTopicNameDiv = styled.div`
  width: 80%;
  @media (max-width: 1100px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 65%;
  }
`;

const BookmarkDiv = styled.div`
  width: 10%;
  @media (max-width: 1100px) {
    width: 10%;
  }

  @media (max-width: 768px) {
    width: 15%;
  }
`;
const ReportDiv = styled.div`
  width: 10%;
  @media (max-width: 1100px) {
    width: 10%;
  }

  @media (max-width: 768px) {
    width: 10%;
  }
`;
const StartDiv = styled.div`
  width: 10%;
  cursor:pointer;
  @media (max-width: 1100px) {
    width: 10%;
  }

  @media (max-width: 768px) {
    width: 25%;
  }
`;

const AcadStartDiv = styled.div`
  width: 10%;
  @media (max-width: 1100px) {
    width: 10%;
  }

  @media (max-width: 768px) {
    width: 25%;
  }
`;

const UnitNameDiv = styled.div`
  width: 20%;
  @media (max-width: 1100px) {
    width: 20%;
  }

  @media (max-width: 768px) {
    width: 30%;
  }
`;

const NoOfTopicsDiv = styled.div`
  width: 20%;
  @media (max-width: 1100px) {
    width: 20%;
  }

  @media (max-width: 768px) {
    width: 30%;
  }
`;

const CollapseDiv = styled.div`
  width: 60%;
  @media (max-width: 1100px) {
    width: 60%;
  }

  @media (max-width: 768px) {
    width: 40%;
  }
`;

const DeckGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);

  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, 33.333%);
  }
  @media (max-width: 930px) {
    grid-template-columns: repeat(2, 50%);
  }
`;

const CourseContentDiv = styled.div`
  @media (max-width: 930px) {
    margin-left: 40px;
  }
`;
const Leaderboardrow=styled.div`
    display: flex;
    font-size: 10px;
   align-items: center;
    font-weight: 600;
    border-bottom: 1px solid #80808052;
    padding: 12px 0px 12px 0px;
    padding-left: 0px !important;
  `;
  const Button=styled.div`
    text-align: left;
    height: 24px;
    // background: pink;
    width: 57px;
    padding-left: 3px;
    border-radius: 10px;
    cursor:pointer;
    font-size: 16px;
`
class CourseContent extends Component {
  state = {
    openContent: false,
    contentUrl: "",
    contentType: null,
    reportItemId: null,
    openReportDialog: false,
    itemToBeReported: null,
    expanded:'panel1',
    seven_days_data:true,
    activetab_unitid:false,
    series: [],
    coursecontent:false,
    filteredcontent:false,
    options: {
      legend: {
        show: false
      },
      chart: {
        height: 350,
        type: 'treemap'
      },
      title: {
        text: 'Topic wise analysis'
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '14px',
        },
        formatter: function(text, op) {
          return [text, op.value]
        },
        offsetY: -4
      },
      plotOptions: {
        treemap: {
          enableShades: true,
          shadeIntensity: 0.1,
          reverseNegativeShade: true,
          colorScale: {
            ranges: [
              {
                from: 1,
                to: 30,
                color: '#FF6961',
               
              },
              {
                from: 31,
                to: 50,
                color: '#FFB54C',
                foreColor:'#0D140C'
              },
              {
                from: 51,
                to: 70,
                color: '#F8D66D',
                foreColor:'#0D140C'
              },
              {
                from: 71,
                to: 85,
                color: '#8CD47E',
                foreColor:'#0D140C'
              },
              {
                from: 86,
                to: 100,
                color: '#7ABD7E',
                foreColor:'#0D140C'
              },
            ]
          }
        }
      }
    },
    showfeedmackpopup: false,
    feedbacktabvalue: 0,
    pagecount: 1,
  };
  
  componentDidMount = async () => {
    document.body.style.backgroundColor = "#f5f5f5";
    // //console.log(this.props);
    if (!this.props.location?.state?.subject_id) this.props.history.push("/");
    else {
      let subject_id = this.props.location.state.subject_id;
      let subject_semester_id = this.props.location.state.subject_semester_id;
      let csm_Id = this.props.location.state.csm_Id
      // //console.log("calling action dispatcher");
      await this.props.getCourseDetails(subject_id,csm_Id);
      await this.props.getAnalyticsdata(subject_semester_id);
      await this.props.getAnalyticssemesterdata(subject_semester_id);
      await this.props.getreemapDatadetails(subject_semester_id);
      await this.props.getAllsectionsubjectLeaderboardData("7","Performance",subject_semester_id)
      await this.props.getAllsectionsubjectLeaderboardData("30","Performance",subject_semester_id)
      await this.props.getAllsectionsubjectLeaderboardData("7","Participation",subject_semester_id)
      await this.props.getAllsectionsubjectLeaderboardData("30","Participation",subject_semester_id)
      localStorage.subjectpageapicalltime= new Date();
    }
  

   
  };
  getFormattedTime(d){
    let start = new Date(localStorage.getItem("subjectpageapicalltime"));
     return start.getFullYear() + "-"+(start.getMonth()+1) +"-"+start.getDate() + ' '+start.toString().split(' ')[4];
  
  }
  latestloadanalytics= async()=>{
    let subject_semester_id = this.props.location.state.subject_semester_id;
    await this.props.getAnalyticssemesterdata(subject_semester_id);
    await this.props.getreemapDatadetails(subject_semester_id);
    // this.setState({loading_refreshed_data:true});
    localStorage.subjectpageapicalltime=new Date();
    
  }
  componentDidUpdate = async () => {
  if (!this.props.loading_course_details && !this.props.loading_course_content && !this.state.activetab_unitid) {
    // //console.log("-----------------"+this.props.unit_id)
    this.setState({
      activetab_unitid: this.props.unit_id,
    });
    
  }
  if (this.props.course_content && !this.state.coursecontent) {
    this.setState({
      coursecontent:this.props.course_content,
      filteredcontent:this.props.course_content,
    })
    
  }
  if (localStorage.getItem("user")) {
    let user = JSON.parse(localStorage.getItem("user"));
    const identifyObj = new Identify();
    identifyObj.setOnce('User ID', `${user.user_id}`);
    identify(identifyObj);
    init(AMPLITUDE_API_KEY,`${user.user_id}`);
  }
};
 searchresults = (text) => {
  let filteredActivities = [];
  if (text && text!="") {
           filteredActivities = this.state.coursecontent.filter((item) => {
              return Object.values(item).join('').toLowerCase().includes(text.toLowerCase())
           });
    
   } else {
    filteredActivities=this.state.coursecontent;
  }
  this.setState({filteredcontent:filteredActivities})
  //  //console.log(state.classroomdata)
  // //console.log("search")
}
  onContentOpen = async (url, type) => {
    let template;
    // if (type != 'MP4') {
    //     template = await getTemplateData(url);
    // }
    const eventProperties = {
      type: `${type}`,
      url:`${url}`
    };
     track('subject_content_open', eventProperties);
    document.body.style.overflow = "hidden";
    this.setState({
      openContent: true,
      contentUrl: url,
      contentType: type,
      template: template,
    });
  };

  closeContent = () => {
    const eventProperties = {
      content:"close"
     };
     track('subject_content_close', eventProperties);
    this.setState({
      openContent: false,
      contentUrl: "",
      contentType: null,
      template: null,
    });
    document.body.style.overflow = "auto";
  };

  onMenuBarClicked = (event, page) => {
    event.preventDefault();
    event.stopPropagation();
    if (page == "content") {
      this.props.history.push({
        pathname: "/course/content",
        state: { subject_id: this.props.subject_id },
      });
    }
    if (page == "assessment") {
      this.props.history.push({
        pathname: "/course/assessment",
        state: { subject_id: this.props.subject_id },
      });
    }
    if (page == "practice") {
      this.props.history.push({
        pathname: "/course/practice",
        state: { subject_id: this.props.subject_id },
      });
    }
  };

  openDeck = (deck_code, deck) => {
    const eventProperties = {
      deck_code: `${deck_code}`,
    };
     track('open_deck', eventProperties);
    this.props.getDeckData(deck_code, deck);
    this.props.openDeckData();
  };

  closeDeck = () => {
    this.props.closeDeck();
  };

  startUnitPracticeTest = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let user = JSON.parse(localStorage.getItem("user"));
    let udd = user.university_degree_department_id;
    const eventProperties = {
      subject_id: `${this.props.subject_id}`,
      university_degree_department_id: udd,
      unit_id: this.props.unit_id,
    };
     track('start_practice_test', eventProperties);
    this.props.history.push({
      pathname: "/practice",
      state: {
        subject_id: this.props.subject_id,
        university_degree_department_id: udd,
        unit_id: this.props.unit_id,
      },
    });
  };

  addOrDeleteBookmark = (event, unit_id, id, type) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.addOrDeleteBookmark(unit_id, id, type);
  };

  componentWillUnmount = () => {
    this.props.removeState();
      this.props.clearTestState();
  };

  reportItem = (event, item, id) => {
    event.preventDefault();
    event.stopPropagation();
    // document.body.style.overflow = "hidden";
    this.setState({
      openReportDialog: true,
      itemToBeReported: item,
      reportItemId: id,
    });
  };

  closeReport = () => {
    document.body.style.overflow = "auto";
    this.setState({
      openReportDialog: false,
      itemToBeReported: null,
      reportItemId: null,
    });
  };
   changetabcontent = (unitid) => {
    this.props.clearUnitTestState()
    const eventProperties = {
      unitid: `${unitid}`,
    };
     track('unit_change', eventProperties);
     this.props.clearFeedbackState();
    this.props.onUnitChange(unitid,this.props.csm_Id);
    this.props.getfeedbackquestiondetails(unitid);
    this.setState({
      activetab_unitid: unitid,
      coursecontent:false,
    });
  };
   handleChange = (panel) => (event, newExpanded) => {
    newExpanded ? this.setState({expanded:panel})     : this.setState({expanded:false})
  };
  sevendaysdata=()=>{
    this.setState({ seven_days_data: true })
  }
  semesterdata=()=>{
    this.setState({ seven_days_data: false })
  }
  goback= () => {
    this.props.history.push({
        pathname: '/home',
    });
}
showfeedbackmodal = (unit_id) => {

  this.setState({ showfeedmackpopup: true })
}


facultyrating = (rating, feedback_id, question_id) => {
  this.props.submitfeedbackresp(feedback_id, question_id, rating);
  // console.log(rating+"---"+feedback_id+"---"+question_id);
}
handleTabChange = (event, newValue) => {
  this.setState({ feedbacktabvalue: newValue })
};

changefeedbackquestion = (event, value) => {
  this.setState({ pagecount: value });
}

 closeshowfeedmackpopup = () => {
  this.setState({ showfeedmackpopup: false })
}
  render() {
    let user = JSON.parse(localStorage.getItem("user"));
    //console.log("_________________++++++++++");
    //console.log(this.props.course_content);
    if (
      this.props.loading_course_details ||
      this.props.loading_course_content
    ) {
      return <Subjectscreenloader />;
    } else if (
      this.props.error_loading_content ||
      this.props.error_loading_details
    ) {
      return <div>Error Loading Content</div>;
    }
    return (
      <div
        style={{ overflow: "hidden", background: "white", width: "100%",height:"inherit",overflowY:"scroll"}}
        className="row"
      >
        {/* <AppLayout></AppLayout> */}
        <div className="col-lg-9" style={{paddingLeft:"30px"}}>
          {/* <StudentCourseDetails
            name={this.props.student_name}
            subject_name={this.props.subject_name}
            subject_id={this.props.subject_id}
          ></StudentCourseDetails> */}
          <div className="mainBox">
          <Button onClick={this.goback}><MdArrowBackIos />Back</Button>
            <div
              className="studentDiv"
              style={{ padding: 13, fontSize: 14, fontWeight: 600 }}
            >
              Hi {this.props.student_name}!<br /> You are viewing {this.props.subject_name}
            </div>

            <Flex column>
              <div style={{ width: "100%" }}>
                {/* <CourseMenu
                  onMenuBarClicked={this.onMenuBarClicked}
                  active_page="content"
                ></CourseMenu> */}
              </div>
              <CourseContentDiv style={{}}>
               
            <div  style={{
              listStyleType: "none",
              paddingLeft: 20,
              display: "flex",
              justifyContent: "center"
            }}>
               {this.props.units.map((unit,index) => {
                 return (
                <>
              <button key={index}  className={this.state.activetab_unitid === unit.id ? 'tabbtn active' : 'tabbtn'}
               onClick={() => this.changetabcontent(unit.id)}>{unit.topic_name}</button>
              </>
                 )
               })}
              </div>
                {this.props.units.map((unit) => {
                  // //console.log(this.props.tree_map_data)
                  let series=[];
                  let treemapdata;
                  if (this.props.tree_map_data) {
                     treemapdata = this.props.tree_map_data.filter(unitdata => unitdata.unit_id ==unit.id);
                
                  //  //console.log(treemapdata)
                    series.push({data:treemapdata[0].data})
                    // //console.log("----------------"+treemapdata[0].data.length)
                  }
                 
                
                  return (
                 
                     <div className="">
                     
                      {unit.id == this.props.unit_id ? (
                        <div>
                               <div style={{display:"flex",margin:"20px 0px 0px 25px",alignItems:"center"}}>
                        <div style={{width:"65%"}}>
                          {this.props.tree_map_data && treemapdata[0].data.length>0 ? 
            
                        <ReactApexChart options={this.state.options} series={series} type="treemap" height={250} />
                         :this.props.tree_map_data && treemapdata[0].data.length==0 ?
                           <div style={{fontSize:"14px"}}></div>
                          :<Skeleton width="70%" height={150} />}
                        </div>
                        {unit.has_test == 1 && (
                          <div style={{width:"23%",margin:"0px 0px 0px 25px"}}> <button className="btn" 
                          style={{background:"#fdb0c8"}}
                          onClick={this.startUnitPracticeTest}>
                           Start practice test<AiOutlineArrowRight style={{fontSize:"20px"}} /></button></div>
                         
                              // <div
                              //   style={{
                              //     width: "10%",
                              //     margin: "auto",
                              //     display: "flex",
                              //     flexDirection: "row",
                              //     textAlign: "end",
                              //     justifyContent: "flex-end",
                              //     marginRight: "10px",
                              //   }}
                              //   onClick={this.startUnitPracticeTest}
                              // >
                              //   <div
                              //     style={{
                              //       marginRight: "5px",
                              //       fontSize: "14px",
                              //     }}
                              //    >
                              //     Start
                              //     <AiOutlineArrowRight style={{fontSize:"20px"}} />
                              //   </div>
                              
                              // </div>
                          )}
                          {this.props.feedback_questions_data ?
                            <div style={{ cursor: "pointer",width:"12%"}} onClick={() => this.showfeedbackmodal(unit.id)} >
                              <div style={{fontSize:"14px",fontWeight:"500"}}>Faculty feedback</div>
                              {/* <button className="btn" onClick={() => this.showfeedbackmodal(unit.id)} style={{fontWeight:500,background:"rgb(253 176 200 / 76%)",padding:4}}>
                                <FcFeedback /> Feedback
                                </button> */}
                              <div>
                              <Rating sx={{fontSize: '1.5rem',opacity:"0.5"}} precision={0.5} onClick={() => this.showfeedbackmodal(unit.id)} name="read-only" value={this.props.feedback_average} size="small" readOnly />
                              </div>
                              </div>
                            : null}
                      </div>
                          {this.props.decks && this.props.decks.length > 0 ? (
                            <div>
                              <div
                                style={{
                                  borderBottom: "1px solid #eee",
                                  margin: "10px 0",
                                }}
                              ></div>

                              {/* <DeckGrid
                                style={{
                                  gridGap: "20px 10px",
                                  margin: "20px 10px 30px 10px",
                                }}
                              > */}
                              <div style={{margin:25}}>
                              <Carousel cols={5} rows={1} gap={10} loop >
                                {this.props.decks.map((deck, index) => {
                                  return deck.type != "pqp" ? (
                                     <Carousel.Item>
                                    <div
                                      style={{}}
                                      onClick={() =>
                                        this.openDeck(deck.code, deck)
                                      }
                                    >
                                      <div
                                        className={
                                          index % 3 == 0
                                            ? "greenDeck"
                                            : index % 3 == 1
                                            ? "blueDeck"
                                            : "orangeDeck"
                                        }
                                      >
                                        <div className="recommended">
                                          <div
                                            style={{
                                              textAlign: "left",
                                              height: "50px",
                                              fontSize: "11px",
                                              fontWeight:"600"
                                            }}
                                          >
                                            {deck.name}
                                          </div>
                                          <div style={{ margin: "5px 0 0 0" }}>
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <div style={{ margin: "0 15px" }}>
                                                {deck.bookmarked == 1 ? (
                                                  <img
                                                    onClick={(event) =>
                                                      this.addOrDeleteBookmark(
                                                        event,
                                                        deck.unit_id,
                                                        deck.id,
                                                        "decks"
                                                      )
                                                    }
                                                    src="/images/bookmarked.svg" style={{height:"15px"}}
                                                  ></img>
                                                ) : (
                                                  <img
                                                    onClick={(event) =>
                                                      this.addOrDeleteBookmark(
                                                        event,
                                                        deck.unit_id,
                                                        deck.id,
                                                        "decks"
                                                      )
                                                    }
                                                    src="/images/bookmark.svg"
                                                    style={{height:"15px"}}
                                                  ></img>
                                                )}
                                              </div>
                                              <div style={{ margin: "0 10px" }}>
                                                <img
                                                  onClick={(event) =>
                                                    this.reportItem(
                                                      event,
                                                      "learning_snippets",
                                                      deck.id
                                                    )
                                                  }
                                                  src="/images/report.svg"
                                                  style={{height:"15px"}}
                                                ></img>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    </Carousel.Item>
                                  ) : null;
                                })}
                              </Carousel>
                              </div>
                            </div>
                          ) : null}
                          {/* <div
                            style={{
                              borderBottom: "1px solid #eee",
                              margin: "10px 0",
                            }}
                          ></div> */}
                           <div className="input-group ml-3 mb-0 w-75">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1" style={{ background: "#ffdfd6" }}><i class="fas fa-search"></i></span>
                    </div>
                    <input type="text" className="form-control" placeholder="Search using Name"
                       onChange={(e) => this.searchresults(e.target.value)}    style={{ fontSize: "11px" }} />
                </div>
                          {this.state.filteredcontent &&
                            this.state.filteredcontent.map((topic,index) => {
                              //  //console.log(topic.academic_materials.length)
                              //  //console.log(topic.academic_materials)
                              return (
                          <Accordion sx={{'& .MuiTypography-root' :{   
                            fontSize:'14px'            
                    }}} expanded={this.state.expanded == `panel${index+1}`} onChange={this.handleChange(`panel${index+1}`)}>
                                {/* <div> */}
                                <AccordionSummary aria-controls={`"panel${index+1}d-content"`} id={`"panel${index+1}d-header"`}>
                                <Typography>{topic.name}</Typography>
                                </AccordionSummary>
                                  {/* <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div style={{ marginRight: "20px" }}>
                                      <img src="/images/teacher.svg"></img>
                                    </div>
                                    <div>{topic.name}</div>
                                  </div> */}
                                  {/* <div
                                    style={{
                                      borderBottom: "1px solid #eee",
                                      margin: "10px 0",
                                    }}
                                  ></div> */}
                                  {topic.learning_content.length>0 ?
                                    topic.academic_materials.map(
                                      (acad_item) => {
                                        // //console.log(acad_item)
                                        return (
                                            <AccordionDetails key={acad_item.id}>
                                            <Typography>
                                          {/* <div > */}
                                            {acad_item.title && acad_item.type && (
                                              <div>
                                                <TopicDiv
                                                  style={{
                                                    fontSize: "14px",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                  }}
                                                >
                                                  <AcadTopicNameDiv
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      margin: "auto",
                                                    }}
                                                    onClick={() =>
                                                      this.onContentOpen(
                                                        acad_item.file_url,
                                                        acad_item.type
                                                      )
                                                    }
                                                  >
                                                    <div
                                                      style={{
                                                        marginRight: "20px",
                                                      }}
                                                    >
                                                      {acad_item.type ==
                                                        "DOCS" ||
                                                      acad_item.type == "PPT" ||
                                                      acad_item.type ==
                                                        "PPTS" ||
                                                      acad_item.type == "PDF" ||
                                                      acad_item.type ==
                                                        "url" ? (
                                                            <CgFileDocument style={{fontSize:"18px"}} />
                                                        // <img src="/images/doc_content.svg" width={15}></img>
                                                      ) : acad_item.type ==
                                                        "MP4" ? (
                                                        <img src="/images/video_content.svg" width={15}></img>
                                                      ) : null}
                                                    </div>
                                                    <div
                                                      style={{
                                                        marginRight: "20px",
                                                      }}
                                                    >
                                                      {acad_item.title}
                                                    </div>
                                                    <div>
                                                      <img src="/images/acad_materials_verified_tag.png" />
                                                    </div>
                                                  </AcadTopicNameDiv>
                                                  {/* <BookmarkDiv style={{ margin: 'auto'}}>
                                                                    {item.bookmarked == 1 ? <img onClick = {()=>this.props.addOrDeleteBookmark(topic.id, item.material_id, 'learning_content')} src='/images/bookmarked.svg'></img>
                                                                    : <img onClick = {()=>this.props.addOrDeleteBookmark(topic.id, item.material_id, 'learning_content')} src='/images/bookmark.svg'></img>}
                                                                </BookmarkDiv> */}
                                                  <ReportDiv
                                                    style={{ margin: "auto" }}
                                                  >
                                                    <img
                                                      onClick={(event) =>
                                                        this.reportItem(
                                                          event,
                                                          "academic_materials",
                                                          acad_item.material_id
                                                        )
                                                      }
                                                      src="/images/report.svg"
                                                      width={15}
                                                    ></img>
                                                  </ReportDiv>
                                                  <AcadStartDiv
                                                    style={{
                                                      margin: "auto",
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      textAlign: "end",
                                                      justifyContent:
                                                        "flex-end",
                                                    }}
                                                    onClick={() =>
                                                      this.onContentOpen(
                                                        acad_item.file_url,
                                                        acad_item.type
                                                      )
                                                    }
                                                  >
                                                    <div
                                                      style={{
                                                        marginRight: "5px",
                                                      }}
                                                    >
                                                      Start
                                                    </div>
                                                    <div>
                                                        <AiOutlineArrowRight style={{fontSize:"20px"}} />
                                                      {/* <img src="/images/Right_arrow_blue.svg"></img> */}
                                                    </div>
                                                  </AcadStartDiv>
                                                </TopicDiv>
                                                <div
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #eee",
                                                    margin: "10px 0",
                                                  }}
                                                ></div>
                                              </div>
                                            )}
                                            </Typography>
                                          </AccordionDetails>
                                        );
                                      }
                                    )
                                    :<>
                                    <AccordionDetails><Typography>No content found for this topic </Typography>
                                    </AccordionDetails>
                                    </> }
                                  {topic.learning_content &&
                                    topic.learning_content.map((item) => {
                                      return (
                                        <div>
                                          {item.title && item.type && (
                                            <div>
                                              <TopicDiv
                                                style={{
                                                  fontSize: "14px",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <TopicNameDiv
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    margin: "auto",
                                                  }}
                                                  onClick={() =>
                                                    this.onContentOpen(
                                                      item.file_url,
                                                      item.type
                                                    )
                                                  }
                                                >
                                                  <div
                                                    style={{
                                                      marginRight: "20px",
                                                    }}
                                                  >
                                                    {item.type == "DOCS" ||
                                                    item.type == "PPT" ||
                                                    item.type == "url" ? (
                                                     <CgFileDocument style={{fontSize:"18px"}} />
                                                    //   <img src="/images/doc_content.svg" width={15}></img>
                                                    ) : item.type == "MP4" ? (
                                                      <img src="/images/video_content.svg" width={15}></img>
                                                    ) : null}
                                                  </div>
                                                  <div>{item.title}</div>
                                                </TopicNameDiv>
                                                <BookmarkDiv
                                                  style={{ margin: "auto" }}
                                                >
                                                  {item.bookmarked == 1 ? (
                                                    <img
                                                      onClick={() =>
                                                        this.props.addOrDeleteBookmark(
                                                          topic.id,
                                                          item.material_id,
                                                          "learning_content"
                                                        )
                                                      }
                                                      src="/images/bookmarked.svg"
                                                    ></img>
                                                  ) : (
                                                    <img
                                                      onClick={() =>
                                                        this.props.addOrDeleteBookmark(
                                                          topic.id,
                                                          item.material_id,
                                                          "learning_content"
                                                        )
                                                      }
                                                      src="/images/bookmark.svg"
                                                      width={15}
                                                    ></img>
                                                  )}
                                                </BookmarkDiv>
                                                <ReportDiv
                                                  style={{ margin: "auto" }}
                                                >
                                                  <img
                                                    onClick={(event) =>
                                                      this.reportItem(
                                                        event,
                                                        "learning_content",
                                                        item.material_id
                                                      )
                                                    }
                                                    src="/images/report.svg"
                                                    width={15}
                                                  ></img>
                                                </ReportDiv>
                                                <StartDiv
                                                  style={{
                                                    margin: "auto",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    textAlign: "end",
                                                    justifyContent: "flex-end",
                                                  }}
                                                  onClick={() =>
                                                    this.onContentOpen(
                                                      item.file_url,
                                                      item.type
                                                    )
                                                  }
                                                >
                                                  <div
                                                    style={{
                                                      marginRight: "5px",
                                                    }}
                                                  >
                                                    Start
                                                  </div>
                                                  <div>
                                                  <AiOutlineArrowRight style={{fontSize:"20px"}} />
                                                    {/* <img src="/images/Right_arrow_blue.svg"></img> */}
                                                  </div>
                                                </StartDiv>
                                              </TopicDiv>
                                              <div
                                                style={{
                                                  borderBottom:
                                                    "1px solid #eee",
                                                  margin: "10px 0",
                                                }}
                                              ></div>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}
                                </Accordion>
                              );
                            })}
                          {!this.props.decks && !this.state.filteredcontent && (
                            <div
                              style={{ margin: "20px 0", textAlign: "center" }}
                            >
                              No Topics
                            </div>
                          )}
                         
                        </div>
                       
                      ) : null}
                     </div>
                  );
                })}
              </CourseContentDiv>
            </Flex>
          </div>
        </div>
        <div  className="rightpane"
          style={{
            background: "#f7f7f7",
            width: "25%",
            paddingLeft: "22px",
          }}> 
         
         <div className="col-lg-12" style={{
                display: 'flex', border: '1px', justifyContent: 'space-around', marginTop: '50px',
                borderRadius: '13px', padding: '0px', background: 'rgb(253, 176, 200)', marginLeft: '36px', width: '70%',
                textAlign: 'center'
              }}>
                <div className={this.state.seven_days_data? "activetab_rightpanel":"inactivetab_rightpanel"}  onClick={this.sevendaysdata}>Last 7 days</div>
                <div  className={!this.state.seven_days_data? "activetab_rightpanel":"inactivetab_rightpanel"} onClick={this.semesterdata}>
                  Last 30 days</div>
              </div>
              {this.props.analytics_data ||this.props.semester_analytics_data?
              <div><span style={{fontSize:"11px"}}>Last updated on:- {this.getFormattedTime(localStorage.getItem('apicalltime'))}</span> <span><FiRefreshCcw
               style={{fontSize:"20px",cursor:"pointer"}} onClick={this.latestloadanalytics} /></span></div>:null}
             {this.props.loading_analytics_data?
             <Analyticskeletonloader />:
             <>
               {this.state.seven_days_data && this.props.analytics_data?
                <Analyticscreen data={this.props.analytics_data}
                allsections_participation={this.props.allsections_sevendays_sub_participation}
                allsections_performance={this.props.allsections_sevendays_sub_performance}
                 sevedays={true} coursepage={true} 
                 user_id={user.user_id} 
                 />  
                 :!this.state.seven_days_data && this.props.semester_analytics_data?
                 <Analyticscreen data={this.props.semester_analytics_data} 
               allsections_participation={this.props.allsections_thirtydays_sub_participation}
                allsections_performance={this.props.allsections_thirtydays_sub_performance}
               sevedays={false} coursepage={true} 
               user_id={user.user_id} 
               />:
               <div style={{fontSize:"14px"}}>Error loading data. Please try gain after some time</div>}
               </>
              } 
      </div>
      <Modal show={this.state.showfeedmackpopup} onHide={this.closeshowfeedmackpopup} size='lg'>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "16px" }}>Submit your faculty feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!this.props.loading_feedback_questions && this.props.feedback_questions_data ?
              <>
                <Tabs value={this.state.feedbacktabvalue} onChange={this.handleTabChange} style={{ fontSize: 10 }} >
                  {this.props.feedback_questions_data.map((item, index) => {
                    return (
                      <Tab label={item.faculty_name} {...a11yProps(index)} sx={{ color: "grey" }} />
                    )
                  })}
                </Tabs>
                {/* <Carousel cols={1} rows={1} gap={10} loop > */}
                {this.props.feedback_questions_data.map((item, index) => {
                  let i = 1;
                  return (
                    <>
                      {/* <Carousel.Item> */}
                      <TabPanel value={this.state.feedbacktabvalue} index={index}>
                        <Carousel cols={1} rows={1} gap={10} showDots={true} hideArrow={false} scrollSnap={true} dotColorActive="#795548" >
                          {item.questions.map((questions, index) => {
                            return (
                              <Carousel.Item>
                                <div style={{ marginLeft: "53px" }} >
                                  <div><b>{index + 1}.</b> {questions.question}</div>
                                  {questions.submission_count != 2 ?
                                    <Rating name="half-rating" defaultValue={questions.value}
                                      precision={0.5} size="large"
                                      sx={{fontSize: '2.5rem'}} 
                                      onChange={(event, newHover) => {
                                        this.facultyrating(newHover, this.props.feedback_questions_data[0].feedback_id, questions.id)
                                      }}
                                    />
                                    : <Rating name="half-rating" defaultValue={questions.value}
                                      precision={0.5} size="large"
                                      sx={{fontSize: '2.5rem'}} 
                                      disabled
                                    />}
                                </div>
                              </Carousel.Item>
                            )
                            i++;
                          })}
                        </Carousel>
                        {/* <Pagination count={item.questions.length} page={this.state.pagecount} onChange={this.changefeedbackquestion} /> */}
                      </TabPanel>
                      {/* </Carousel.Item> */}
                    </>
                  )
                })}
                {/* </Carousel> */}
              </>
              : <div>Loading....</div>}
          </Modal.Body>
        </Modal>
        <Modal1
          open={this.state.openContent}
          url={this.state.contentUrl}
          close={this.closeContent}
          type={this.state.contentType}
          template={this.state.template}
        ></Modal1>
        {this.props.open_deck && this.props.deck_data ? (
          <PreviewCard
            isOpen={this.props.open_deck && this.props.deck_data}
            deck={this.props.deck}
            onClose={this.closeDeck}
            deck_data={this.props.deck_data}
            addOrDeleteBookmark={this.addOrDeleteBookmark}
            reportItem={this.reportItem}
          ></PreviewCard>
        ) : null}
        <Modal show={this.state.openReportDialog} onHide={this.closeReport}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:"16px"}}>Please select issue to be reported</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <ReportDialog
            open={this.state.openReportDialog}
            close={this.closeReport}
            reportItem={this.state.itemToBeReported}
            reportItemId={this.state.reportItemId}
          ></ReportDialog></Modal.Body>
      </Modal> 
        <style jsx="true" scoped>
          {`
          // .apexcharts-datalabel {
          //   font-size: 10px !important;
          //   overflow:hiddeen;
          // }
            .unitBox {
              border: 1px solid #fff;
              box-shadow: 0 0 5px #ddd;
              padding: 5px 15px;
              margin: 10px;
              margin-bottom: 20px;
            }
            .mainBox {
              display: block;
              width: 100%;
              background-color: white;
              border-radius: 8px;
              margin-top: 20px;
              text-align: left;
              padding: 2px;
              box-sizing: border-box;
            }
            .circleGradient {
              width: 11px;
              height: 11px;
              background-image: linear-gradient(
                to right top,
                #f38fa2,
                #f28699,
                #f17c8f,
                #f07386,
                #ee697c,
                #ee6776,
                #ee666f,
                #ee6569,
                #f06c65,
                #f17362,
                #f17b5e,
                #f1825c
              );
              border-radius: 50%;
              margin-top: 5.5px;
              margin-right: 10px;
            }
            .recommended {
             border-radius: 5px;
             padding: 5px 5px 5px 10px;
             background: white;
             cursor:pointer;
            }
            .blueDeck {
              background:rgba(82, 195, 255, 0.58);
              border-radius: 5px;
              box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.163981);
              margin-right: 20px;
              padding: 0 0 4px 4px;
              height: 100%;
            }
            .orangeDeck {
              background: rgba(255, 203, 87, 0.68);
              border-radius: 5px;
              box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.163981);
              margin-right: 20px;
              padding: 0 0 4px 4px;
              height: 100%;
            }
            .greenDeck {
              background: rgba(252, 119, 160, 0.58);
              border-radius: 5px;
              box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.163981);
              margin-right: 20px;
              padding: 0 0 4px 4px;
              height: 100%;
            }
             .tabbtn {
              margin-right: 15px;
              font-size: 12px;
              background: transparent;
              border: none;
              outline: none;
              padding: 10px 20px;
              cursor: pointer;
              color: rgba(0, 0, 0, 0.4);
              transition: all ease-in-out 0.2s;
              border-bottom: 2px solid transparent;
            }
            .active {
              border-bottom: 2px solid rgba(252, 119, 160, 0.58);
              color: black;
              pointer-events: none;
            }
            .leaderboardrank {
    border-radius: 34px;
    width: 20px;
    height: 20px;
    padding: 3px;
    background: #fff;
    /* border: 1px solid #666; */
    color: black;
    text-align: center;
    font: 12px Arial, sans-serif;
}
.activetab_rightpanel{
    background: white;
    border-radius: 13px;
    font-size: 11px;
    padding: 3px 9px 2px 10px;
    font-weight: 500;
    margin: 3px;
    color: rgb(233, 151, 165);
}
.inactivetab_rightpanel{
    border-radius: 8px;
    font-size: 11px;
    padding: 5px 1px 0px 0px;
    font-weight: 500;
    color: white;
    cursor: pointer;
}
.apexcharts-menu-icon{
  display:none;
}
          `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    subject_id: state.cc.subject_id,
    student_name: state.cc.student_name,
    units: state.cc.units,
    csm_Id:state.cc.csm_Id,
    course_content: state.cc.content,
    unit_id: state.cc.unit_id,
    subject_name: state.cc.subject_name,
    subject_image: state.cc.subject_image,
    subject_semester_id: state.cc.subject_semester_id,
    loading_course_details: state.cc.loading_course_details,
    loading_course_content: state.cc.loading_course_content,
    error_loading_details: state.cc.error_loading_details,
    error_loading_content: state.cc.error_loading_content,
    decks: state.cc.decks,
    open_deck: state.cc.open_deck,
    deck_data: state.cc.deck_data,
    deck: state.cc.deck,
    analytics_data: state.cc.analytics_data,
    semester_analytics_data:state.cc.semester_analytics_data,
    loading_analytics_data:state.cc.loading_analytics_data,
    tree_map_data:state.cc.tree_map_data,
    loading_seven_days_all_sections_sub_performance:state.cc.loading_seven_days_all_sections_sub_performance,
    loading_thirty_days_all_sections_sub_performance:state.cc.loading_thirty_days_all_sections_sub_performance,
    loading_seven_days_all_sections_sub_participation:state.cc.loading_seven_days_all_sections_sub_participation,
    loading_thirty_days_all_sections_sub_participation:state.cc.loading_thirty_days_all_sections_sub_participation,
    allsections_sevendays_sub_performance:state.cc.allsections_sevendays_sub_performance,
    allsections_thirtydays_sub_performance:state.cc.allsections_thirtydays_sub_performance,
    allsections_sevendays_sub_participation:state.cc.allsections_sevendays_sub_participation,
    allsections_thirtydays_sub_participation:state.cc.allsections_thirtydays_sub_participation,
    loading_feedback_questions: state.cc.loading_feedback_questions,
    error_loading_feedback_questions: state.cc.error_loading_feedback_questions,
    feedback_questions_data: state.cc.feedback_questions_data,
    feedback_average: state.cc.feedback_average
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCourseDetails: (subject_id,csm_Id) => dispatch(getCourseDetailsAC(subject_id,csm_Id)),
    getCourseContent: () => dispatch(getCourseContentAC()),
    addOrDeleteBookmark: (topic_id, learning_content_id, type) =>
      dispatch(addOrDeleteBookmarkAC(topic_id, learning_content_id, type)),
    onUnitChange: (unit_id,csm_Id) => dispatch(onUnitChangeAC(unit_id,csm_Id)),
    getDeckData: (deck_code, deck) => dispatch(getDeckDataAC(deck_code, deck)),
    openDeckData: () => dispatch({ type: OPENDECKDATA }),
    closeDeck: () => dispatch({ type: CLOSEDECK }),
    removeState: () => dispatch({ type: "REMOVE_STATE" }),
    getAnalyticsdata: (subject_id) => dispatch(getAnalysisConent(subject_id)),
    getAnalyticssemesterdata: (subject_id) => dispatch(getAnalysisSemesterConentAc(subject_id)),
    getreemapDatadetails: (subject_id) => dispatch(getreemapDataAC(subject_id)),
    getAllsectionsubjectLeaderboardData: (activity_days,type,subject_id) => dispatch(getAllsectionsubjectLeaderboard(activity_days,type,subject_id)),
    clearTestState: () => dispatch({ type: "CLEAR_TEST_STATE" }),
    clearUnitTestState: () => dispatch({ type: "CLEAR_UNIT_STATE" }),
    getfeedbackquestiondetails: (unit_id) => dispatch(getfeedbackquestions(unit_id)),
    submitfeedbackresp: (feedback_id, question_id, answer) => dispatch(submitfeedbackresponse(feedback_id, question_id, answer)),
    clearFeedbackState: () => dispatch({ type: "CLEAR_FEEDBACK_STATE" }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CourseContent)
);
