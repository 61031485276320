import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import styled, { keyframes, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import AppLayout from "../../components/Layout/Layout";
import "./dashboard.css";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import moment from "moment";
import FeedbackModal from "./feedbackmodal"
import { BsArrowDownCircleFill } from "react-icons/bs";
import ActivitywallSkeleton from "./activitywallskeleton";
import {AiOutlineSend} from "react-icons/ai";
import {AiOutlineFileDone} from "react-icons/ai";
import {MdOutlinePending} from "react-icons/md";
import {GrInProgress} from "react-icons/gr";
import Modal from "react-bootstrap/Modal";
import Countdowntimer from "./timer";
import {GoComment} from "react-icons/go";
import { init, identify, Identify, track } from '@amplitude/analytics-browser';
import {
    getWallData,
    getAnsweredStudents,
    getUnAnsweredStudents,
} from "../../store/actions/dashboardActions";
import {
    getClassroomActivityAC,
    postCommentAC,
    loadMoreCommentsAC,
    getFeedbackAC,
    EnrollweeklyChallenge,
  } from "../../store/actions/classroomActions";
  import Table from 'react-bootstrap/Table';
  import Dialog from '@mui/material/Dialog';
  import DialogActions from '@mui/material/DialogActions';
  import DialogContent from '@mui/material/DialogContent';
  import DialogContentText from '@mui/material/DialogContentText';
  import DialogTitle from '@mui/material/DialogTitle';
  import Slide from '@mui/material/Slide';
  import Snackbar from '@mui/material/Snackbar';
  import MuiAlert from '@mui/material/Alert';
  import Fade from '@mui/material/Fade';
  import AlertTitle from '@mui/material/AlertTitle';
  import { FaCircleCheck } from "react-icons/fa6";
  import { FaHourglassStart } from "react-icons/fa6";
  const AMPLITUDE_API_KEY=process.env.REACT_APP_AMPLITUDE_KEY;
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const Button = styled.div`
  background:#FFDFD6;
  border-radius: 25px;
  margin: auto;
  text-align: center;
  // border: 1px solid
  //   linear-gradient(139.5deg, #7175e0 -50.69%, #3235c0 60.2%, #e25bc8 189.26%);
  width: 130px;
    padding: 4px;
    color: black;
    margin-top: 8px;
    font-size: 13px;
    font-weight: 600;
    box-shadow: 0px 0px 2px 0px #7e7777;
    cursor:pointer;
    margin-right:0px;
`;
const ViewComments = styled.div`
text-align: left;
font-size: 11px;
font-weight: 500;
`;
const ExpiredButton = styled.div`
background: white;
border-radius: 30px;
margin: auto;
text-align: center;
border: 1px solid #bbb;
width: 155px;
padding: 4px;
color: black;
margin-top: 20px;
font-size: 14px;
font-weight: 600;
margin-right:0px;
`;
const MaterialImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 300px);

  @media (max-width: 1180px) {
    grid-template-columns: repeat(3, 300px);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 300px);
  }
`;
const Loadmore = styled.div`
    margin: auto;
    background: pink;
    margin-bottom: 20px;
    height: 30px;
    font-size: 16px;
    font-weight: 600;
    padding: 4px;
    border-radius: 13px;
    width: 150px !important;
    cursor: pointer;
    `;
    function createMarkup(htmlString) {
        return { __html: htmlString };
      }
const current = moment();
function ActivityWall(props) {
    let user = JSON.parse(localStorage.getItem("user"));
    const [activity_loading_status, setactivityloadingstatus] = useState(false)
    const [classroomdata, setclassroomdata] = useState(false)
    const [filteredclassroomdata, setfilteredclassroomdata] = useState(false)
    const [openMaterial, setopenmaterial] = useState(false)
    const [material_url, setmaterial_url] = useState(null)
    const [comments, setcomments] = useState([])
    const [showansweredstudents, setshowansweredstudents] = useState(false)
    const [showunansweredstudents, setshowunansweredstudents] = useState(false)
    const [enrolledweeklychallenge, setenrolledweeklychallenge] = useState(false)
    const [disablejoinbtn, setdisablejoinbtn] = useState(true)
    const [showcommentsmodal, setshowcommentsmodal] = useState(false)
    const [showsuccess, setshowsuccess] = useState(false)
    const [searchtext, setsearchtext] = useState('')
    const [semFeedbackMaster, setSemFeedbackMaster] = useState({});
    // const [unitFeedbackMaster, setUnitFeedbackMaster] = useState({});
    
    useEffect(() => {
        if (!props.loading_walldata) {
            setactivityloadingstatus(false);

        }
        setclassroomdata(props.activity_wall);
        setfilteredclassroomdata(props.activity_wall)
    //  if (classroomdata.length>0) {
    //     let filteredActivities=[]
    //     console.log(searchtext)
        const feedbackMasterList = {};
        // const unitFeedbackMasterList = {};
        const a_list = props.activity_wall.map((act) => {
            if (act.type === 'semester_feedback' && act?.master_survey_id) {
                if (!(act.master_survey_id in feedbackMasterList)) {
                    feedbackMasterList[act.master_survey_id] = {};
                    feedbackMasterList[act.master_survey_id]['name'] = act?.master_survey_name;
                    feedbackMasterList[act.master_survey_id]['subjects'] = [];
                    feedbackMasterList[act.master_survey_id]['first'] = act?.id;
                    feedbackMasterList[act.master_survey_id]['all_submitted'] = 1;
                }
                const arr = {id: act?.id, subject_name: act?.subject_name, submitted: act?.submitted, taught_by: act?.taught_by}
                feedbackMasterList[act.master_survey_id]['subjects'].push(arr)
                if (act?.submitted === 0) {
                    feedbackMasterList[act.master_survey_id]['all_submitted'] = 0;
                }
                return act?.master_survey_id;
            }
            // else if (act.type === 'unit_feedback' && act?.sub_sem_id) {
            //     if (!(act.sub_sem_id in unitFeedbackMasterList)){
            //         unitFeedbackMasterList[act.sub_sem_id] = {};
            //         unitFeedbackMasterList[act.sub_sem_id]['name'] = act?.subject_name;
            //         // unitFeedbackMasterList[act.sub_sem_id]['units'] = [];
            //         unitFeedbackMasterList[act.sub_sem_id]['first'] = act?.id;
            //         // unitFeedbackMasterList[act.sub_sem_id]['all_submitted'] = 1;
            //     }
            //     // const arr = {id: act?.id, unit_name: act?.unit_name, submitted: act?.submitted}
            //     // unitFeedbackMasterList[act.sub_sem_id]['units'].push('arr');
            //     // if (act?.submitted === 0) {
            //     //     unitFeedbackMasterList[act.sub_sem_id]['all_submitted'] = 0;
            //     // }
            //     unitFeedbackMasterList[act.sub_sem_id]['all_submitted'] = 0; //remove this line later
            //     return act?.sub_sem_id;
            // }
        })
        console.log("sem feedback master list");
        console.log(feedbackMasterList);
        setSemFeedbackMaster(feedbackMasterList);
        // setUnitFeedbackMaster(unitFeedbackMasterList);
      
    // console.log(filteredActivities)
    // setclassroomdata(filteredActivities)
    // // if (classroomdata.length>0) {
    // //     const  filteredActivities = classroomdata.filter((item) => {
    // //         console.log( Object.values(item.title).join(''))
    // //         })
    // // }
    //  }
   
    }, [props.loading_walldata, props.activity_wall]);

    useEffect(() =>{
        // console.log("in search")
        console.log(searchtext)
        let filteredActivities=[]
        if (searchtext=="") {
            filteredActivities=props.activity_wall;
       }else{
        if(searchtext.includes(":=")){
            const values = searchtext.split(":=");
            // console.log(values[0]+"------"+values[1])
            if(!values[0] && values[1]){
                console.log(values[0]+"------"+values[1])
                filteredActivities = classroomdata.filter(card => 
                    (card.type === "Weekly_Challenge" && values[1] === "weekly") ||
                    (card.type === "Test" && values[1] === "objective") ||
                    (card.type === "Subjective" && searchtext === "subjective") ||
                    (card.type == "Notification" && values[1] == "notification") ||
                    (card.type === "Coding" && values[1] === "coding") ||
                    (card.type === "Feedback" && values[1] === "feedback"))
            }
           
        }else{
            console.log(searchtext)
            filteredActivities = classroomdata.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchtext.toLowerCase())
                })
        }
           
       }
       setfilteredclassroomdata(filteredActivities)
    }, [searchtext]);
    const loadmoredata = () => {
        props.getWallDatares(props.query_date)
        setactivityloadingstatus(true)
    }
    
    const searchresults = (event) => {
        // const text = event.target.value.toLowerCase();
        console.log(event)
        setsearchtext(event.toLowerCase())
        if (event=="") {
            setsearchtext('')
        }
        console.log(searchtext)
        let filteredActivities = [];
        if (searchtext && searchtext!="") {
            console.log("not empty")
            if(searchtext.includes(":=")){
                const values = searchtext.split(":=");
                // console.log(values[0]+"------"+values[1])
                if(!values[0] && values[1]){
                    console.log(values[0]+"------"+values[1])
                    filteredActivities = classroomdata.filter(card => 
                        (card.type === "Weekly_Challenge" && values[1] === "weekly") ||
                        (card.type === "Test" && values[1] === "objective") ||
                        (card.type === "Subjective" && searchtext === "subjective") ||
                        (card.type == "Notification" && values[1] == "notification") ||
                        (card.type === "Coding" && values[1] === "coding") ||
                        (card.type === "Feedback" && values[1] === "feedback"))
                }
               
            }else{
                 filteredActivities = classroomdata.filter((item) => {
                    return Object.values(item).join('').toLowerCase().includes(searchtext.toLowerCase())
                    })
                // filteredActivities = classroomdata.filter(card => card.title && card.title.toLowerCase().indexOf(searchtext) > -1);
             }
            //  filteredActivities = state.classroomdata.filter(card => 
            //                         (card.type === "Test" && text === "test") ||
            //                         (card.type === "Subjective" && text === "subjective") ||
            //                         (card.type === "Notification" && text === "notification") ||
            //                         (card.type === "Coding" && text === "coding") ||
            //                         (card.type === "Feedback" && text === "feedback"))
            console.log(filteredActivities)
            setclassroomdata(filteredActivities)
        } else {
            console.log("empty")
            setclassroomdata(props.activity_wall)
        }
        //  console.log(state.classroomdata)
        // console.log("search")
    }
    const openMaterialHandler = (url) => {
        setopenmaterial(true)
        setmaterial_url(url)
    };

    const closeMaterial = () => {
        setopenmaterial(false)
        setmaterial_url(null)
    };

    const openVC = (event, url) => {
        window.open(url, "_blank").focus();
    };
    const enrollweeklychallenge = (id) => {
        const eventProperties = {
            testid: `${id}`,
            userid:`${user.user_id}`
          };
           track('join_challenge', eventProperties);
        localStorage.setItem("challengeid", id);
        props.history.push({
            pathname: "/joinchallange",
            state: {
                test_id: id,
            },
        });
    };
    const enrolltoweeklychallenge = async (id) => {
        const eventProperties = {
            testid: `${id}`,
            userid:`${user.user_id}`
          };
           track('enroll_challenge', eventProperties);
        localStorage.setItem("challengeid", id);
        // this.props.enrollweeklychallenge(id)
        await props.enrollweeklychallenge(id);
        setenrolledweeklychallenge(true)
       
      };
      const disablejoinbtnfunc = () => {
        setdisablejoinbtn(false)
      };
    const viewchallengeresults = (id) => {
        const eventProperties = {
            testid: `${id}`,
            userid:`${user.user_id}`
          };
           track('challenge_results', eventProperties);
        localStorage.setItem("challengeid", id);
        props.history.push({
            pathname: "/challengeResults",
            state: {
                test_id: id,
            },
        });
    };
    const closemodal = (id) => {
        // this.setState({ openchallange: false });
    };

    const onCommentInputChange = (event, id, type) => {
        //  console.log(event.target.value)
        event.preventDefault();
        event.stopPropagation();
        // let comments = this.state.comments;
        let item_index = -1;
        comments.findIndex((item, index) => {
        //    console.log()
            if (item.id == id && item.type == type) {
                // console.log("in if condition")
                item_index = index;
            }
        });
        console.log(item_index)
        if (item_index == -1) {
            let comment_dict = {
                id: id,
                type: type,
                comment: event.target.value,
            };
            comments.push(comment_dict);
            // console.log(comments)
        } else {
            console.log("in esle")
            let comment_dict = comments[item_index];
            console.log(comment_dict)
            comment_dict["comment"] = event.target.value;
            comments[item_index] = comment_dict;
        }
        setcomments(comments)
         console.log(comments);
    };
    const openAssignmentResults = (testid,remedialpath) => {
        if(remedialpath){
            const eventProperties = {
                testid: `testid_${testid}`,
                userid:`${user.user_id}`
              };
               track('remedial', eventProperties);
            props.history.push({
                pathname: "/remedial",
                state: {
                    remedial_test_id: testid,
                },
            });
        }else{
            console.log("results clicked")
            const eventProperties = {
                testid: `testid_${testid}`,
                userid:`${user.user_id}`
              };
               track('objective_results', eventProperties);
            props.history.push({
                pathname: "/assessmentResults",
                state: {
                    test_id: testid,
                },
            });
        }
    }
    const postCommentHandler = (event, id, type) => {
        event.preventDefault();
        event.stopPropagation();
        const eventProperties = {
            testid: `${id}`,
            userid:`${user.user_id}`
          };
           track('post_comment', eventProperties);
        setshowsuccess(true)     
        // let comments = [...this.state.comments];
        let item_index = -1;
        comments.findIndex((item, index) => {
            if (item.id == id && item.type == type) {
                item_index = index;
            }
        });
        if (item_index == -1) {
            return;
        } else {
            let comment = { ...comments[item_index] };
            props.postComment(id, comment.comment, type);
            comments.splice(item_index, 1);
            setcomments(comments)
        }
    };

    const openTestResults = (id, remedial, item) => {
        const eventProperties = {
          testid: `testid_${id}`,
          userid: `${user.user_id}`,
        };
      
        if (remedial === 1 && item.remedial_calculated === 1) {
          if (item.remedial_path_id > 0) {
            track('remedial', eventProperties);
            props.history.push({
              pathname: `/remedial/testresult/${id}`,
            });
          } else {
            track('objective_results', eventProperties);
            props.history.push({
              pathname: `/testresult/${id}`,
            });
          }
        } else if (remedial === 1 && item.remedial_calculated === 0) {
          track('remedial_calculation', eventProperties);
          props.history.push({
            pathname: `/remedial/${id}`,
          });
        } else {
          track('objective_results', eventProperties);
          props.history.push({
            pathname: `/testresult/${id}`,
          });
        }
      };

    const openLiveSurveyResults = (id) => {
        const eventProperties = {
            testid: `${id}`,
            userid:`${user.user_id}`
          };
           track('livesurvey_results', eventProperties);
        props.history.push({
            pathname: "/liveSurveyResults",
            state: { testId: id },
        });
    };

    const openLiveTestResults = (id) => {
        const eventProperties = {
            testid: `${id}`,
            userid:`${user.user_id}`
          };
           track('livetest_results', eventProperties);
        props.history.push({
            pathname: "/liveAssessmentResults",
            state: { testId: id },
        });
    };

    const openSubjectiveTestResults = (id) => {
        const eventProperties = {
            testid: `${id}`,
            userid:`${user.user_id}`
          };
           track('subjective_results', eventProperties);
        props.history.push({
            pathname: "/subjectiveResults",
            state: { test_id: id },
        });
    };

    const openCodingTestResults = (id) => {
        const eventProperties = {
            testid: `${id}`,
            userid:`${user.user_id}`
          };
           track('coding_results', eventProperties);
        props.history.push({
            pathname: "/codingAssessmentSummary",
            state: { test_id: id },
        });
    };

    const startAssessment = (id) => {
        const eventProperties = {
            testid: `${id}`,
            userid:`${user.user_id}`
          };
           track('objective_assesment', eventProperties);
        props.history.push({
            pathname: "/assessment",
            state: { test_id: id, prev_page: "classroom" },
        });
    };

    const startCodingAssessment = (id) => {
        const eventProperties = {
            testid: `${id}`,
            userid:`${user.user_id}`
          };
           track('coding_assesment', eventProperties);
        props.history.push({
            pathname: "/startCoding",
            state: {
                test_id: id,
            },
        });
    };

    const startSubjectiveAssessment = (id) => {
        const eventProperties = {
            testid: `${id}`,
            userid:`${user.user_id}`
          };
           track('subjective_assesment', eventProperties);
        props.history.push({
            pathname: "/subjectiveAssessment",
            state: {
                test_id: id,
            },
        });
    };
    const getAnsweredStudentsdetails =(id,type,count)=>{
        const eventProperties = {
            testid: `${id}`,
            userid:`${user.user_id}`
          };
           track('get_answered_students', eventProperties);
        if (count>0) {
            props.getAnsweredStudentsdata(id,type)
            setshowansweredstudents(true);
        }
      
    }
    const getUnAnsweredStudentsdetails =(id,type,count)=>{
        const eventProperties = {
            testid: `testid_${id}`,
            userid:`${user.user_id}`
          };
           track('get_unanswered_students', eventProperties);
        if (count>0) {
          props.getUnAnsweredStudentsdata(id,type)
          setshowunansweredstudents(true)
        }
    }
    const closeunansweredmodal =()=>{
        setshowunansweredstudents(false);
    }
    const closeansweredmodal =()=>{
        setshowansweredstudents(false);
    }
   const closeSuccess = () => {
    setenrolledweeklychallenge(false) 
        window.location.reload();
      };
      const loadcomments = (id,type) => {
        props.loadMoreComments(id, type)
        setshowcommentsmodal(true) 
          };
          const closecommentmodal=()=>{
            setshowcommentsmodal(false) 
          }
          const handsnackbarleClose=()=>{
            setshowsuccess(false)     
          }
          const secondsToHms = (second) => {
            let dateObj = new Date(second * 1000);
            let hours = dateObj.getUTCHours();
            let minutes = dateObj.getUTCMinutes();
            let seconds = dateObj.getSeconds();
    
            let timeString = hours.toString().padStart(2, '0')
                + ':' + minutes.toString().padStart(2, '0')
                + ':' + seconds.toString().padStart(2, '0');
            return timeString;
        }
        const give_sem_feedback=(master_survey_id, id)=>{
            console.log(`msi - ${master_survey_id}`);
            console.log(semFeedbackMaster);
            const subjects_list = semFeedbackMaster[master_survey_id]['subjects'];
            const firstUnsubmittedSub = subjects_list.find(sub => sub.submitted === 0);
            // const unsubmittedSubs = subjects_list.filter(sub => sub.submitted === 0);
            if (firstUnsubmittedSub) {
                props.history.push({
                    pathname: "/semesterfeedback",
                    state: {
                        sem_feedback_id: firstUnsubmittedSub.id,
                        subjects: subjects_list,
                    },
                });
            }
        }
        const give_unit_feedback = (sub_sem_id, unit_id) => {
            console.log(`subject_semester_id - ${sub_sem_id}`);
            props.history.push({
                pathname: `/unitFeedbacks/${sub_sem_id}/${unit_id}`
            });
        }

    return (
        <>
            <div className="col-lg-12 row dashboardactivitywall" style={{ padding: "0px 0px 0px 0px", margin: "0px" }}>
                <div style={{ fontSize: "16px", textAlign: "left", fontWeight: "600", marginBottom: "15px" }}>Activity wall</div>
                <div className="input-group mb-0 w-100">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1" style={{ background: "#ffdfd6" }}><i class="fas fa-search"></i></span>
                    </div>
                    <input type="text" className="form-control" placeholder="Search using Name"
                         onChange={(e) => setsearchtext(e.target.value)} style={{ fontSize: "11px" }} />
                </div>
                {filteredclassroomdata.length > 0 ?
                    <>
                        {filteredclassroomdata.map((item) => {
                            let material_urls_string =
                                item.file_url && item.type == "Material" ? item.file_url : "";
                            let material_urls_a =
                                material_urls_string.length > 0
                                    ? material_urls_string.replace(/'/g, '"')
                                    : "";
                            let material_urls =
                                material_urls_string.length > 0
                                    ? JSON.parse(material_urls_a)
                                    : [];
                            let item_index = -1;
                            if (item.id === 844) console.log(item);
                            comments.findIndex((item_c, index) => {
                              if (item_c.id == item.id && item_c.type == item.type) {
                                item_index = index;
                              }
                            });
                            if (item.type !== 'semester_feedback' || semFeedbackMaster[item?.master_survey_id]['first'] === item?.id) {
                            return (
                                <div className="col-lg-6">
                                    <div

                                        style={{
                                            backgroundColor: "white",
                                            boxShadow: "5px 10px 20px #f1ecec",
                                            padding: "10px",
                                            margin: "20px auto",
                                            borderRadius: "8px",
                                            boxShadow: "1px 1px 4px lightgreen",
                                            // height:"268px"
                                        }}
                                    >
                                        <div style={{ display: "flex", flexDirection: "row", flexDirection: "row-reverse", justifyContent: "space-between" }}>
                                            {item?.college_account_details?.profile_pic ? (
                                                <div style={{ margin: "auto 10px auto 10px" }}>
                                                    <img
                                                        style={{ borderRadius: "50%", width: "35px", height: "35px" }}
                                                        width="60px"
                                                        height="60px"
                                                        src={item.college_account_details.profile_pic}
                                                        alt=""
                                                    ></img>
                                                </div>
                                            ) : null}
                                            <div style={{ textAlign: "left", margin: "auto 0" }}>
                                                <div
                                                    style={{
                                                        fontSize: "16px",
                                                        fontWeight: "600",
                                                        lineHeight: "23px",
                                                        color: "#191648",
                                                        marginBottom: "0px",
                                                    }}
                                                >
                                                    {item.type !== 'semester_feedback' ? (item.title ? item.title : item.name) : item?.master_survey_name}
                                                </div>
                                                <div style={{ fontSize: "10px" }}>
                                                    Posted by {item.college_account_details.faculty_name}{" "}
                                                    &nbsp;&nbsp;| &nbsp;&nbsp;
                                                    {moment(item.created_at).format("Do MMMM YYYY")}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                            }}
                                        >
                                            {item.description ? (
                                                <div style={{ textAlign: "left", fontSize: "12px" }}>
                                                    {/* <span style={{ fontWeight: "600" }}>Description: </span> */}
                                                    <span
                                                        dangerouslySetInnerHTML={{ __html: item.description }}
                                                    ></span>
                                                </div>
                                            ) : null}
                                            {item.type == "Material" ?
                                                <MaterialImageGrid>
                                                    {item.type == "Material"
                                                        ? material_urls.map((url) => {
                                                            return (
                                                                <div
                                                                    style={{
                                                                        border: "1px solid #D8D8D8",
                                                                        borderRadius: "8px",
                                                                        width: `300px`,
                                                                        boxSizing: "border-box",
                                                                        padding: "10px",
                                                                        margin: "10px",
                                                                    }}
                                                                    onClick={() => openMaterialHandler(url)}
                                                                >
                                                                    <div>
                                                                        <img
                                                                            src="/images/material_file.svg"
                                                                            alt="material"
                                                                        ></img>
                                                                    </div>
                                                                    {/* <div style={{margin: '10px', marginRight: '10px', textAlign: 'end'}}>
                                                    <img src='/images/download.svg'></img>
                                                </div> */}
                                                                </div>
                                                            );
                                                        })
                                                        : null}
                                                </MaterialImageGrid>
                                                : null}

                                            {item.type == "Notification" ? (
                                                item.file_url ? (
                                                    <div
                                                        style={{
                                                            border: "1px solid #D8D8D8",
                                                            borderRadius: "8px",
                                                            width: "300px",
                                                            boxSizing: "border-box",
                                                            padding: "10px",
                                                            margin: "10px",
                                                        }}
                                                        onClick={() => openMaterialHandler(item.file_url)}
                                                    >
                                                        <div>
                                                            <img
                                                                src="/images/material_file.svg"
                                                                alt="notification"
                                                            ></img>
                                                        </div>
                                                        {/* <div style={{margin: '10px', marginRight: '10px', textAlign: 'end'}}>
                                            <img src='/images/download.svg'></img>
                                        </div> */}
                                                    </div>
                                                ) : null
                                            ) : null}

                                            {item.type == "Test" &&
                                                moment(item.doe) > current &&
                                                item.submitted == 0 &&
                                                item.start_time &&
                                                moment(item.start_time) <= current ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        Created on : <b>{moment(item.created_at).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        Results Release Time : <b>{moment(item.results_release_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        Exam duration :<b>{secondsToHms(item.timelimit)}</b>
                                                    </div>
                                                    <Button onClick={() => startAssessment(item.id)}>
                                                        Start Test
                                                    </Button>
                                                    <div className="row mt-3" style={{fontSize:"9px"}}>
                                                        <div className="col-lg-3" style={{padding:"0px"}}>
                                                          <AiOutlineSend /> {item.sent_to} Send to
                                                        </div>
                                                        <div className="col-lg-3" style={{padding:"0px",cursor:"pointer"}} 
                                                        onClick={() => getAnsweredStudentsdetails(item.id,item.type,item.submitted_count)}>
                                                        <AiOutlineFileDone /><span style={{paddingLeft:"3px"}}>{item.submitted_count} Attempted</span>
                                                            </div>
                                                            <div className="col-lg-3" style={{padding:"0px"}}
                                                            onClick={() => getUnAnsweredStudentsdetails(item.id,item.type,item.unattempted_count)}>
                                                           <MdOutlinePending /><span style={{paddingLeft:"3px"}}>{item.unattempted_count} Unattempted</span>
                                                            </div>
                                                            <div className="col-lg-3 pl-2" style={{padding:"0px"}}>
                                                           <GrInProgress /><span style={{paddingLeft:"3px"}}>{item.ongoing_count} In Progress</span>
                                                            </div>
                                                    </div>
                                                </div>
                                            ) : item.type == "Test" &&
                                                moment(item.doe) > current &&
                                                item.submitted == 0 &&
                                                item.start_time &&
                                                moment(item.start_time) > current ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    {/* <div style={{ margin: "10px 0" }}>
                        Your test will start on{" "}
                        {moment(item.start_time).format("DD MMM")} at{" "}
                        {moment(item.start_time).format("HH:mm A")} and the
                        timelimit for the test is {item.timelimit / 60} mins.
                      </div> */}
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        Created on : <b>{moment(item.created_at).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        Results Release Time : <b>{moment(item.results_release_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        Exam duration :<b>{secondsToHms(item.timelimit)}</b>
                                                    </div>
                                                    {/* <Button onClick={()=>startAssessment(item.id)}>
                                        Start Test
                                    </Button>     */}
                                     <div className="row mt-3" style={{fontSize:"9px"}}>
                                                        <div className="col-lg-3" style={{padding:"0px"}}>
                                                          <AiOutlineSend /> {item.sent_to} Send to
                                                        </div>
                                                        <div className="col-lg-3" style={{padding:"0px",cursor:"pointer"}} 
                                                        onClick={() => getAnsweredStudentsdetails(item.id,item.type,item.submitted_count)} >
                                                        <AiOutlineFileDone /><span style={{paddingLeft:"3px"}}>{item.submitted_count} Attempted</span>
                                                            </div>
                                                            <div className="col-lg-3" style={{padding:"0px"}}
                                                            onClick={() => getUnAnsweredStudentsdetails(item.id,item.type,item.unattempted_count)}>
                                                           <MdOutlinePending /><span style={{paddingLeft:"3px"}}>{item.unattempted_count} Unattempted</span>
                                                            </div>
                                                            <div className="col-lg-3 pl-2" style={{padding:"0px"}}>
                                                           <GrInProgress /><span style={{paddingLeft:"3px"}}>{item.ongoing_count} In Progress</span>
                                                            </div>
                                                    </div>
                                                </div>
                                            ) : item.type == "Test" &&
                                                ((item.results_release_time &&
                                                    moment(item.results_release_time) > current) ||
                                                    moment(item.doe) > current) &&
                                                item.submitted == 1 ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ fontSize: "12px" }}>
                                                        You have successfully completed the test. Please wait
                                                        for the results
                                                    </div>
                                                    <div className="row mt-3" style={{fontSize:"9px"}}>
                                                        <div className="col-lg-3" style={{padding:"0px"}}>
                                                          <AiOutlineSend /> {item.sent_to} Send to
                                                        </div>
                                                        <div className="col-lg-3" style={{padding:"0px",cursor:"pointer"}} 
                                                        onClick={() => getAnsweredStudentsdetails(item.id,item.type,item.submitted_count)}>
                                                        <AiOutlineFileDone /><span style={{paddingLeft:"3px"}}>{item.submitted_count} Attempted</span>
                                                            </div>
                                                            <div className="col-lg-3" style={{padding:"0px"}}
                                                            onClick={() => getUnAnsweredStudentsdetails(item.id,item.type,item.unattempted_count)}>
                                                           <MdOutlinePending /><span style={{paddingLeft:"3px"}}>{item.unattempted_count} Unattempted</span>
                                                            </div>
                                                            <div className="col-lg-3 pl-2" style={{padding:"0px"}}>
                                                           <GrInProgress /><span style={{paddingLeft:"3px"}}>{item.ongoing_count} In Progress</span>
                                                            </div>
                                                    </div>
                                                </div>
                                            ) : item.type == "Test" &&
                                                moment(item.doe) < current &&
                                                item.submitted == 0 ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        Created on : <b>{moment(item.created_at).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        Results Release Time : <b>{moment(item.results_release_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        Exam duration :<b>{secondsToHms(item.timelimit)}</b>
                                                    </div>
                                                    <ExpiredButton>Test Expired </ExpiredButton>
                                                    <div className="row mt-3" style={{fontSize:"9px"}}>
                                                        <div className="col-lg-3" style={{padding:"0px"}}>
                                                          <AiOutlineSend /> {item.sent_to} Send to
                                                        </div>
                                                        <div className="col-lg-3" style={{padding:"0px",cursor:"pointer"}} 
                                                        onClick={() => getAnsweredStudentsdetails(item.id,item.type,item.submitted_count)}>
                                                        <AiOutlineFileDone /><span style={{paddingLeft:"3px"}}>{item.submitted_count} Attempted</span>
                                                            </div>
                                                            <div className="col-lg-3" style={{padding:"0px"}}
                                                            onClick={() => getUnAnsweredStudentsdetails(item.id,item.type,item.unattempted_count)}>
                                                           <MdOutlinePending /><span style={{paddingLeft:"3px"}}>{item.unattempted_count} Unattempted</span>
                                                            </div>
                                                            <div className="col-lg-3 pl-2" style={{padding:"0px"}}>
                                                           <GrInProgress /><span style={{paddingLeft:"3px"}}>{item.ongoing_count} In Progress</span>
                                                            </div>
                                                    </div>
                                                </div>
                                            ) : item.type == "Test" &&
                                                ((item.results_release_time &&
                                                    moment(item.results_release_time) < current) ||
                                                    moment(item.doe) < current) &&
                                                item.submitted == 1 ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    {/* <div style={{ margin: "10px 0" }}>
                        You have successfully submitted the test
                      </div> */}
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        Created on : <b>{moment(item.created_at).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        Results Release Time : <b>{moment(item.results_release_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        {item.remedial_path === 1 && item.remedial_path_test_completed === 0 && item?.remedial_path_end_time ? (
                                                        <div
                                                        dangerouslySetInnerHTML={createMarkup(
                                                            `Remedial test end time : <b>${moment(item.remedial_path_end_time).format(
                                                            "ddd, MMM Do YYYY, h:mm a"
                                                            )}</b><br />`
                                                        )}
                                                        />
                                                    ) : null}
                                                        Exam duration :<b>{secondsToHms(item.timelimit)}</b>
                                                    </div>
                                                    <Button onClick={() => openTestResults(item.id,item.remedial_path,item)}>
                                                    {item.remedial_path && item.remedial_path_id > 0 ? "Remedial Actions" : "View Results"}
                                                    </Button>
                                                    <div className="row mt-3" style={{fontSize:"9px"}}>
                                                        <div className="col-lg-3" style={{padding:"0px"}}>
                                                          <AiOutlineSend /> {item.sent_to} Send to
                                                        </div>
                                                        <div className="col-lg-3" style={{padding:"0px",cursor:"pointer"}} 
                                                        onClick={() => getAnsweredStudentsdetails(item.id,item.type,item.submitted_count)}>
                                                        <AiOutlineFileDone /><span style={{paddingLeft:"3px"}}>{item.submitted_count} Attempted</span>
                                                            </div>
                                                            <div className="col-lg-3" style={{padding:"0px"}}
                                                            onClick={() => getUnAnsweredStudentsdetails(item.id,item.type,item.unattempted_count)}>
                                                           <MdOutlinePending /><span style={{paddingLeft:"3px"}}>{item.unattempted_count} Unattempted</span>
                                                            </div>
                                                            <div className="col-lg-3 pl-2" style={{padding:"0px"}}>
                                                           <GrInProgress /><span style={{paddingLeft:"3px"}}>{item.ongoing_count} In Progress</span>
                                                            </div>
                                                    </div>
                                                </div>
                                            ) : item.type === "Live Survey" &&
                                                moment(item.survey_completed_time).isBefore(current) ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0" }}>
                                                        Live survey has ended
                                                    </div>
                                                    <Button
                                                        onClick={() => openLiveSurveyResults(item.id)}
                                                    >
                                                        View Results
                                                    </Button>
                                                </div>
                                            ) : item.type === "Live Test" &&
                                                moment(item.test_completed_time).isBefore(current) ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0", fontSize: "12px" }}>
                                                        Live assessment has ended
                                                    </div>
                                                    <Button onClick={() => openLiveTestResults(item.id)}>
                                                        View Results
                                                    </Button>
                                                </div>
                                            ) : null}

                                            {(item.type == "Subjective" || item.type == "Assignment") &&
                                                moment(item.doe) > current &&
                                                !item.submitted &&
                                                item.start_time &&
                                                moment(item.start_time) < current
                                                ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    {/* <div style={{ margin: "10px 0", fontSize: "12px" }}>
                                                        Your test has started on{" "}
                                                        {moment(item.start_time).format("DD MMM")} at{" "}
                                                        {moment(item.start_time).format("HH:mm A")} and the
                                                        timelimit for the test is {item.timelimit / 60} mins.
                                                        You have only{" "}
                                                        {(moment(item.doe).diff(current) / (1000 * 60)).toFixed(
                                                            0
                                                        )}{" "}
                                                        mins
                                                    </div> */}
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        Exam duration :<b>{secondsToHms(item.timelimit)}</b>
                                                    </div>
                                                    <Button
                                                        onClick={() => startSubjectiveAssessment(item.id)}
                                                    >
                                                        {item.type === "Subjective" ? `Start Test` : `Start Assignment`}
                                                    </Button>
                                                    <div className="row mt-3" style={{fontSize:"9px"}}>
                                                        <div className="col-lg-3" style={{padding:"0px"}}>
                                                          <AiOutlineSend /> {item.sent_to} Send to
                                                        </div>
                                                        <div className="col-lg-3" style={{padding:"0px",cursor:"pointer"}} 
                                                        onClick={() => getAnsweredStudentsdetails(item.id,item.type,item.submitted_count)}>
                                                        <AiOutlineFileDone /><span style={{paddingLeft:"3px"}}>{item.submitted_count} Attempted</span>
                                                            </div>
                                                            <div className="col-lg-3" style={{padding:"0px"}}
                                                            onClick={() => getUnAnsweredStudentsdetails(item.id,item.type,item.unattempted_count)}>
                                                           <MdOutlinePending /><span style={{paddingLeft:"3px"}}>{item.unattempted_count} Unattempted</span>
                                                            </div>
                                                            <div className="col-lg-3 pl-2" style={{padding:"0px"}}>
                                                           <GrInProgress /><span style={{paddingLeft:"3px"}}>{item.ongoing_count} In Progress</span>
                                                            </div>
                                                    </div>
                                                </div>
                                            ) : (item.type == "Subjective" || item.type == "Assignment") &&
                                                moment(item.doe) > current &&
                                                !item.submitted &&
                                                item.start_time &&
                                                moment(item.start_time) >= current ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        Exam duration :<b>{secondsToHms(item.timelimit)}</b>
                                                    </div>
                                                    <ExpiredButton>Yet to Start </ExpiredButton>
                                                </div>
                                            ) : (item.type == "Subjective" || item.type == "Assignment") &&
                                                moment(item.doe) > current &&
                                                item.submitted == 1 &&
                                                (!item.results_release_time || item.results_release_time === 'None' ||
                                                    moment(item.results_release_time) > current) ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px", fontSize: "12px" }}>
                                                    <div>
                                                        {item.type === 'Subjective' 
                                                        ? `You have successfully completed the test. Please wait for the results`
                                                        : `You have successfully completed the assignment. Please wait for the results`
                                                        }
                                                    </div>
                                                    <div className="row mt-3" style={{fontSize:"9px"}}>
                                                        <div className="col-lg-3" style={{padding:"0px"}}>
                                                          <AiOutlineSend /> {item.sent_to} Send to
                                                        </div>
                                                        <div className="col-lg-3" style={{padding:"0px",cursor:"pointer"}} 
                                                        onClick={() => getAnsweredStudentsdetails(item.id,item.type,item.submitted_count)}>
                                                        <AiOutlineFileDone /><span style={{paddingLeft:"3px"}}>{item.submitted_count} Attempted</span>
                                                            </div>
                                                            <div className="col-lg-3" style={{padding:"0px"}}
                                                            onClick={() => getUnAnsweredStudentsdetails(item.id,item.type,item.unattempted_count)}>
                                                           <MdOutlinePending /><span style={{paddingLeft:"3px"}}>{item.unattempted_count} Unattempted</span>
                                                            </div>
                                                            <div className="col-lg-3 pl-2" style={{padding:"0px"}}>
                                                           <GrInProgress /><span style={{paddingLeft:"3px"}}>{item.ongoing_count} In Progress</span>
                                                            </div>
                                                    </div>
                                                </div>
                                            ) : (item.type == "Subjective" || item.type == "Assignment") &&
                                                moment(item.doe) < current &&
                                                item.submitted == 0 ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                    </div>
                                                    <ExpiredButton>{item.type === 'Subjective' ? `Test Expired` : `Assignment Expired`} </ExpiredButton>
                                                </div>
                                            ) : (item.type == "Subjective" || item.type == "Assignment") &&
                                                moment(item.doe) < current &&
                                                item.submitted == 1 &&
                                                moment(item.results_release_time) < current ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0", fontSize: "12px" }}>
                                                        {item.type === 'Subjective' ? `You have successfully submitted the test` : `You have successfully submitted the assignment`}
                                                    </div>
                                                    <Button
                                                        onClick={() => openSubjectiveTestResults(item.id)}
                                                    >
                                                        View Results
                                                    </Button>
                                                    <div className="row mt-3" style={{fontSize:"9px"}}>
                                                        <div className="col-lg-3" style={{padding:"0px"}}>
                                                          <AiOutlineSend /> {item.sent_to} Send to
                                                        </div>
                                                        <div className="col-lg-3" style={{padding:"0px",cursor:"pointer"}} 
                                                        onClick={() => getAnsweredStudentsdetails(item.id,item.type,item.submitted_count)}>
                                                        <AiOutlineFileDone /><span style={{paddingLeft:"3px"}}>{item.submitted_count} Attempted</span>
                                                            </div>
                                                            <div className="col-lg-3" style={{padding:"0px"}}
                                                            onClick={() => getUnAnsweredStudentsdetails(item.id,item.type,item.unattempted_count)}>
                                                           <MdOutlinePending /><span style={{paddingLeft:"3px"}}>{item.unattempted_count} Unattempted</span>
                                                            </div>
                                                            <div className="col-lg-3 pl-2" style={{padding:"0px"}}>
                                                           <GrInProgress /><span style={{paddingLeft:"3px"}}>{item.ongoing_count} In Progress</span>
                                                            </div>
                                                    </div>
                                                </div>
                                            ) : (item.type == "Subjective" || item.type == "Assignment") &&
                                                moment(item.doe) < current &&
                                                item.submitted == 1 &&
                                                moment(item.results_release_time) > current ? (
                                                    <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                        <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                            Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                            End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                            Results Release Time : <b>{moment(item.results_release_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        </div>
                                                        <ExpiredButton>Wait for Results </ExpiredButton>
                                                    </div>
                                            ) : (item.type == "Subjective" || item.type == "Assignment") &&
                                                moment(item.doe) < current &&
                                                item.submitted == 1 &&
                                                (!item.results_release_time || item.results_release_time === 'None') ? (
                                                    <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                        <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                            Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                            End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                            Results are yet to be released
                                                        </div>
                                                        <ExpiredButton>Wait for Results </ExpiredButton>
                                                    </div>
                                            ) 
                                            : null
                                            }

{item.type == "Weekly_Challenge" &&
                  item.enrollment_end_time &&
                //   moment(item.enrollment_end_time) < current &&
                  item.is_enrolled === 0 &&
                  item.is_completed != 1 &&
                  item.is_viewer == 1 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                      <div style={{ margin: "10px 0" }}>
                        Your arena enrollment closed on{" "}
                        {moment(item.enrollment_end_time).format("DD MMM")} at{" "}
                        {moment(item.enrollment_end_time).format("HH:mm A")}.
                        But still you can follow.
                      </div>

                      <Button
                        onClick={() => enrollweeklychallenge(item.id)}
                      >
                        Follow Arena
                      </Button>
                    </div>
                  ) : item.type == "Weekly_Challenge" &&
                    item.enrollment_end_time &&
                    moment(item.enrollment_end_time) >= current &&
                    !item.is_participant &&
                    !item.is_enrolled &&
                    item.is_completed != 1 && item.is_member == 1 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                      <div style={{ margin: "10px 0" }}>
                        The Arena will start on{" "}
                        {moment(item.start_time).format("DD MMM")}{" "}
                        {moment(item.start_time).format("HH:mm A")} and
                        enrollement will be close on{" "}
                        {moment(item.enrollment_end_time).format("DD MMM")}{" "}
                        {moment(item.enrollment_end_time).format("HH:mm A")}.
                        {item.enrolled_count < item.no_of_participants ?
                        "Please enroll before enrollement time.":"Enrollment slot are full. You can follow challenge"}
                      </div>
                      {item.enrolled_count < item.no_of_participants ?
                      <Button
                        onClick={() => enrolltoweeklychallenge(item.id)}
                      >
                        Enroll Arena
                      </Button>:<Button
                        onClick={() => enrollweeklychallenge(item.id)}
                      >
                        Follow Arena
                      </Button>}
                      <div style={{marginTop:"-20px"}}>Total Enrollments: {item.enrolled_count}</div>
                    </div>
                  ) : item.type == "Weekly_Challenge" &&
                    item.start_time &&
                    moment(item.start_time) > current &&
                    moment(item.enrollment_end_time) < current &&
                    item.is_member === 1 &&
                    item.is_enrolled &&
                    item.is_completed === 0 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                      <div style={{ margin: "10px 0px" }}>
                        You have enrolled to this challenge. The challenge will
                        start at {moment(item.start_time).format("DD MMM")}{" "}
                        {moment(item.start_time).format("HH:mm A")}
                      </div>
                      <Button
                        onClick={() => enrollweeklychallenge(item.id)}
                      >
                        Join Arena
                      </Button>
                    </div>
                  ) : item.type == "Weekly_Challenge" &&
                    item.start_time &&
                    moment(item.start_time) > current &&
                    moment(item.enrollment_end_time) > current &&
                    item.is_member === 1 &&
                    item.is_enrolled &&
                    item.is_completed === 0 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                      <div style={{ margin: "10px 0px" }}>
                        The Arena will start at{" "}
                        {moment(item.start_time).format("DD MMM")}{" "}
                        {moment(item.start_time).format("HH:mm A")}
                      </div>
                      <div style={{ margin: "10px 0px", fontWeight: "600" }}>
                        You have enrolled already! Please wait to start the
                        Arena!
                      </div>
                      <div>
                        You can join after{" "}
                        <Countdowntimer
                          time={Math.abs(
                            moment().diff(item.enrollment_end_time, "seconds")
                          )}
                          timercompleted={disablejoinbtnfunc}
                        />
                      </div>
                      <Button
                        disabled={disablejoinbtn}
                        onClick={() => enrollweeklychallenge(item.id)}
                      >
                        Join Arena
                      </Button>
                    </div>
                  ) : item.type == "Weekly_Challenge" &&
                    item.start_time &&
                    item.is_viewer === 1 &&
                    item.is_enrolled &&
                    item.is_completed === 0 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                      <div style={{ margin: "10px 0" }}>
                        Your have missed the Arena. But still you can
                        follow.
                      </div>
                      <Button
                        onClick={() => enrollweeklychallenge(item.id)}
                      >
                        Follow Challenge
                      </Button>
                    </div>
                  ) : item.type == "Weekly_Challenge" &&
                    // item.is_enrolled &&
                    item.is_completed == 1 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                      <div style={{ margin: "10px 0" }}>
                        Your have completed Arena.
                      </div>
                      <Button
                        onClick={() => viewchallengeresults(item.id)}
                      >
                        View Results
                      </Button>
                    </div>
                  ) : item.type == "Weekly_Challenge" &&
                    item.is_enrolled &&
                    item.is_member == 1 &&
                    item.is_completed == 0 ? (
                    <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                      <div style={{ margin: "10px 0" }}>
                        You have enrolled to this Arena. Join using below
                        button
                      </div>
                      <Button
                        onClick={() => enrollweeklychallenge(item.id)}
                      >
                        Join Arena
                      </Button>
                    </div>
                  ) : null}

                                            {item.type == "assignment" &&
                                                moment(item.due_date) > current &&
                                                item.offline_submission == 0 &&
                                                item.start_time &&
                                                moment(item.start_time) < current &&
                                                !item.results_release_time ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0" }}>
                                                        Assignment has started on{" "}
                                                        {moment(item.start_time).format("DD MMM")} at{" "}
                                                        {moment(item.start_time).format("HH:mm A")} and the due
                                                        date for the submission is{" "}
                                                        {moment(item.due_date).format("DD MMM")} . You have only{" "}
                                                        {moment(item.due_date).diff(current, "days")} days
                                                    </div>
                                                    <Button onClick={startAssessment(item.id)}>
                                                        Start Assignment
                                                    </Button>
                                                </div>
                                            ) : item.type == "assignment" &&
                                                moment(item.due_date) > current &&
                                                !item.offline_submission &&
                                                item.start_time &&
                                                moment(item.start_time) >= current ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0" }}>
                                                        Your assignment will start on{" "}
                                                        {moment(item.start_time).format("DD MMM")} at{" "}
                                                        {moment(item.start_time).format("HH:mm A")} and the due
                                                        date for the submission is{" "}
                                                        {moment(item.due_date).format("DD MMM")}
                                                    </div>
                                                </div>
                                            ) : item.type == "assignment" &&
                                                moment(item.due_date) > current &&
                                                item.offline_submission == 1 &&
                                                (!item.results_release_time ||
                                                    item.results_release_time > current) ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div>
                                                        You have successfully submitted the assignment. Please
                                                        wait for the results
                                                    </div>
                                                </div>
                                            ) : item.type == "assignment" &&
                                                moment(item.doe) < current &&
                                                item.offline_submission == 0 &&
                                                moment(item.late_submission_date) > current ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0" ,fontSize:"12px"}}>
                                                        Assignment late submission date is{" "}
                                                        {moment(item.late_submission_date).format("DD MMM")} at{" "}
                                                        {moment(item.late_submission_date).format("HH:mm A")}.
                                                        You have{" "}
                                                        {moment(item.late_submission_date).diff(
                                                            current,
                                                            "hours"
                                                        )}{" "}
                                                        hours.
                                                    </div>
                                                    <Button onClick={startAssessment(item.id)}>
                                                        Start Assignment
                                                    </Button>
                                                </div>
                                            ) : item.type == "assignment" &&
                                                moment(item.due_date) < current &&
                                                moment(item.late_submission_date) < current &&
                                                item.offline_submission == 0 ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0" }}>
                                                        Your assignment submission has closed on{" "}
                                                        {moment(item.late_submission_date).format(
                                                            "DD MMM YYYY HH:mm A"
                                                        )}
                                                    </div>
                                                    <ExpiredButton>
                                                        Assignment Submission Closed
                                                    </ExpiredButton>
                                                </div>
                                            ) : item.type == "assignment" &&
                                                moment(item.due_date) < current &&
                                                item.offline_submission == 1 &&
                                                moment(item.results_release_time) < current ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0",fontSize:"12px" }}>
                                                        You have successfully submitted the assignment
                                                    </div>
                                                    <Button
                                                        onClick={openAssignmentResults(item.id,item.remedial_path)}
                                                    >
                                                        View Results
                                                    </Button>
                                                </div>
                                            ) : null}

                                            {item.type == "Coding test" &&
                                                moment(item.doe) > current &&
                                                item.submitted == 0 &&
                                                item.start_time &&
                                                moment(item.start_time) <= current ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0" }}>
                                                        Your test has started on{" "}
                                                        {moment(item.start_time).format("DD MMM")} at{" "}
                                                        {moment(item.start_time).format("HH:mm A")} and the
                                                        timelimit for the test is {item.timelimit / 60} mins.
                                                        You have only{" "}
                                                        {(moment(item.doe).diff(current) / (1000 * 60)).toFixed(
                                                            0
                                                        )}{" "}
                                                        mins.
                                                    </div>
                                                    <Button
                                                        onClick={() => startCodingAssessment(item.id)}
                                                    >
                                                        Start Test
                                                    </Button>
                                                    <div className="row mt-3" style={{fontSize:"9px"}}>
                                                        <div className="col-lg-3" style={{padding:"0px"}}>
                                                          <AiOutlineSend /> {item.sent_to} Send to
                                                        </div>
                                                        <div className="col-lg-3" style={{padding:"0px",cursor:"pointer"}} 
                                                        onClick={() => getAnsweredStudentsdetails(item.id,item.type,item.submitted_count)}>
                                                        <AiOutlineFileDone /><span style={{paddingLeft:"3px"}}>{item.submitted_count} Attempted</span>
                                                            </div>
                                                            <div className="col-lg-3" style={{padding:"0px"}}
                                                            onClick={() => getUnAnsweredStudentsdetails(item.id,item.type,item.unattempted_count)}>
                                                           <MdOutlinePending /><span style={{paddingLeft:"3px"}}>{item.unattempted_count} Unattempted</span>
                                                            </div>
                                                            <div className="col-lg-3 pl-2" style={{padding:"0px"}}>
                                                           <GrInProgress /><span style={{paddingLeft:"3px"}}>{item.ongoing_count} In Progress</span>
                                                            </div>
                                                    </div>
                                                </div>
                                            ) : item.type == "Coding test" &&
                                                moment(item.doe) > current &&
                                                item.submitted == 0 &&
                                                moment(item.start_time) &&
                                                moment(item.start_time) > current ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12,fontSize:"12px"}}>
                                                    <div style={{ margin: "10px 0" }}>
                                                        Your test will start on{" "}
                                                        {moment(item.start_time).format("DD MMM")} at{" "}
                                                        {moment(item.start_time).format("HH:mm A")} and the
                                                        timelimit for the test is {item.timelimit / 60} mins.
                                                    </div>
                                                </div>
                                            ) : item.type == "Coding test" &&
                                                moment(item.doe) > current &&
                                                item.submitted == 1 ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12,fontSize:"12px"}}>
                                                    <div>
                                                        You have successfully completed the test. Please wait
                                                        for the results
                                                    </div>
                                                </div>
                                            ) : item.type == "Coding test" &&
                                                moment(item.doe) < current &&
                                                item.submitted == 0 ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0" ,fontSize:"12px"}}>
                                                        Your test has expired on{" "}
                                                        {moment(item.doe).format("DD MMM YYYY HH:mm A")}
                                                    </div>
                                                    <ExpiredButton>Test Expired </ExpiredButton>
                                                    <div className="row mt-3" style={{fontSize:"9px"}}>
                                                        <div className="col-lg-3" style={{padding:"0px"}}>
                                                          <AiOutlineSend /> {item.sent_to} Send to
                                                        </div>
                                                        <div className="col-lg-3" style={{padding:"0px",cursor:"pointer"}} 
                                                        onClick={() => getAnsweredStudentsdetails(item.id,item.type,item.submitted_count)}>
                                                        <AiOutlineFileDone /><span style={{paddingLeft:"3px"}}>{item.submitted_count} Attempted</span>
                                                            </div>
                                                            <div className="col-lg-3" style={{padding:"0px"}}
                                                            onClick={() => getUnAnsweredStudentsdetails(item.id,item.type,item.unattempted_count)}>
                                                           <MdOutlinePending /><span style={{paddingLeft:"3px"}}>{item.unattempted_count} Unattempted</span>
                                                            </div>
                                                            <div className="col-lg-3 pl-2" style={{padding:"0px"}}>
                                                           <GrInProgress /><span style={{paddingLeft:"3px"}}>{item.ongoing_count} In Progress</span>
                                                            </div>
                                                    </div>
                                                </div>
                                            ) : item.type == "Coding test" &&
                                                moment(item.doe) < current &&
                                                item.submitted == 1 ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0" ,fontSize:"12px"}}>
                                                        You have successfully submitted the test
                                                    </div>
                                                    <Button
                                                        onClick={() => openCodingTestResults(item.id)}
                                                    >
                                                        View Results
                                                    </Button>
                                                    <div className="row mt-3" style={{fontSize:"9px"}}>
                                                        <div className="col-lg-3" style={{padding:"0px"}}>
                                                          <AiOutlineSend /> {item.sent_to} Send to
                                                        </div>
                                                        <div className="col-lg-3" style={{padding:"0px",cursor:"pointer"}} 
                                                        onClick={() => getAnsweredStudentsdetails(item.id,item.type,item.submitted_count)}>
                                                        <AiOutlineFileDone /><span style={{paddingLeft:"3px"}}>{item.submitted_count} Attempted</span>
                                                            </div>
                                                            <div className="col-lg-3" style={{padding:"0px"}}
                                                            onClick={() => getUnAnsweredStudentsdetails(item.id,item.type,item.unattempted_count)}>
                                                           <MdOutlinePending /><span style={{paddingLeft:"3px"}}>{item.unattempted_count} Unattempted</span>
                                                            </div>
                                                            <div className="col-lg-3 pl-2" style={{padding:"0px"}}>
                                                           <GrInProgress /><span style={{paddingLeft:"3px"}}>{item.ongoing_count} In Progress</span>
                                                            </div>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {item.type === "Feedback" &&
                                                moment(item.start_time) < current &&
                                                moment(item.doe) > current &&
                                                item.submitted == 0 ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0",fontSize:"12px"}}>
                                                        A Feedback named {item.title} created and set to be
                                                        expired on{" "}
                                                        {moment(item.doe).format("DD MMM YYYY HH:mm")}
                                                    </div>
                                                    <Button onClick={() => props.getFeedback(item.id)}>
                                                        Give Feedback
                                                    </Button>
                                                    {props.feedback_error ? (
                                                        <p className="text-center text-danger m-0 mt-2">
                                                            Request failed
                                                        </p>
                                                    ) : null}
                                                </div>
                                            ) : item.type === "Feedback" &&
                                                moment(item.start_time) > current &&
                                                item.submitted == 0 ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0",fontSize:"12px" }}>
                                                        A Feedback named {item.title} created and will start on{" "}
                                                        {moment(item.start_time).format("DD MMM YYYY HH:mm")}{" "}
                                                        and set to be expired on{" "}
                                                        {moment(item.doe).format("DD MMM YYYY HH:mm")}
                                                    </div>
                                                    <ExpiredButton>Yet to Start</ExpiredButton>
                                                </div>
                                            ) : item.type == "Feedback" &&
                                                moment(item.doe) < current &&
                                                item.submitted == 0 ? (
                                                <div>
                                                    {/* <div style={{margin: '10px 0'}}>Feedback got expired </div> */}
                                                    <ExpiredButton>Feedback Expired </ExpiredButton>
                                                </div>
                                            ) : item.type == "Feedback" && item.submitted == 1 ? (
                                                <div style={{ textAlign: "center", margin: "10px 20px",fontSize:"12px",fontWeight:"bold"}}>
                                                    Feedback was submitted successfully
                                                </div>
                                            ) : null}

                                            {item.type === 'semester_feedback' ? 
                                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', gap: '2.5px'}}>
                                                    {semFeedbackMaster[item?.master_survey_id]['subjects'].map((sub) => (
                                                        <div style={{fontSize: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: '5px'}}>
                                                            {sub.subject_name ? `${sub.subject_name}` : null}
                                                            {/* <span>{sub.submitted ? 'Completed' : 'Not Completed'}</span> */}
                                                            {sub.subject_name ? 
                                                            <span style={{marginLeft: '5px'}}>{sub.submitted ? <FaCircleCheck color="green" /> : <img src='/images/feedback_pending.svg' alt='pending' style={{height: '10px', width: '10px'}}/>}</span>
                                                            :null}
                                                        </div>
                                                    ))}
                                                </div>
                                            : null}
                                            
                                            {item.type === "semester_feedback" &&
                                                moment(item.start_time) < current &&
                                                moment(item.doe) > current &&
                                                semFeedbackMaster[item?.master_survey_id]['all_submitted'] === 0 ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    {/* <div style={{ margin: "10px 0",fontSize:"12px"}}>
                                                        A Feedback named {item.title} created and set to be
                                                        expired on{" "}
                                                        {moment(item.doe).format("DD MMM YYYY HH:mm")}
                                                    </div> */}
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        {/* Created on : <b>{moment(item.created_at).format("ddd, MMM Do YYYY, h:mm a")}</b><br /> */}
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
	                                                </div>
                                                    <Button onClick={() => give_sem_feedback(item.master_survey_id, item.id)}>
                                                        Give Feedback
                                                    </Button>
                                                   
                                                </div>
                                            ) : item.type === "semester_feedback" &&
                                                moment(item.start_time) > current &&
                                                semFeedbackMaster[item?.master_survey_id]['all_submitted'] === 0 ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    {/* <div style={{ margin: "10px 0",fontSize:"12px" }}>
                                                        A Feedback named {item.title} created and will start on{" "}
                                                        {moment(item.start_time).format("DD MMM YYYY HH:mm")}{" "}
                                                        and set to be expired on{" "}
                                                        {moment(item.doe).format("DD MMM YYYY HH:mm")}
                                                    </div> */}
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        {/* Created on : <b>{moment(item.created_at).format("ddd, MMM Do YYYY, h:mm a")}</b><br /> */}
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                    </div>
                                                    <ExpiredButton>Yet to Start</ExpiredButton>
                                                </div>
                                            ) : item.type == "semester_feedback" &&
                                                moment(item.doe) < current &&
                                                semFeedbackMaster[item?.master_survey_id]['all_submitted'] === 0 ? (
                                                 <div  style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        {/* Created on : <b>{moment(item.created_at).format("ddd, MMM Do YYYY, h:mm a")}</b><br /> */}
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                    </div>
                                                    {/* <div style={{margin: '10px 0'}}>Feedback got expired </div> */}
                                                    <ExpiredButton>Feedback Expired </ExpiredButton>
                                                </div>
                                            ) : item.type == "semester_feedback" && semFeedbackMaster[item?.master_survey_id]['all_submitted'] === 1 ? (
                                                <div  style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        {/* Created on : <b>{moment(item.created_at).format("ddd, MMM Do YYYY, h:mm a")}</b><br /> */}
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                    </div>
                                                    <div style={{ textAlign: "center", margin: "10px 20px",fontSize:"12px",fontWeight:"bold"}}>
                                                        Feedback was submitted successfully
                                                    </div>
                                                </div>
                                            ) : null}

                                            {/* {item.type === 'unit_feedback' ? 
                                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', gap: '2.5px'}}>
                                                {unitFeedbackMaster[item?.sub_sem_id]['units'].map((unit) => (
                                                    <div style={{fontSize: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: '5px'}}>
                                                        {unit.unit_name ? `${unit.unit_name}` : null}
                                                        {unit.unit_name ? 
                                                        <span style={{marginLeft: '5px'}}>{unit.submitted ? <FaCircleCheck color="green" /> : <img src='/images/feedback_pending.svg' alt='pending' style={{height: '10px', width: '10px'}}/>}</span>
                                                        :null}
                                                    </div>
                                                ))}
                                                </div>
                                            :null} */}

                                            {item.type === "unit_feedback" &&
                                                moment(item.start_time) < current &&
                                                moment(item.doe) > current  ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    {/* <div style={{ margin: "10px 0",fontSize:"12px"}}>
                                                        A Feedback named {item.title} created and set to be
                                                        expired on{" "}
                                                        {moment(item.doe).format("DD MMM YYYY HH:mm")}
                                                    </div> */}
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        {/* Created on : <b>{moment(item.created_at).format("ddd, MMM Do YYYY, h:mm a")}</b><br /> */}
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
	                                                </div>
                                                    <Button onClick={() => give_unit_feedback(item.sub_sem_id, item.unit_id)}>
                                                        {item.submitted === 1 ? 'View Feedback' : 'Give Feedback'}
                                                    </Button>
                                                   
                                                </div>
                                            ) : item.type === "unit_feedback" &&
                                                moment(item.start_time) > current ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    {/* <div style={{ margin: "10px 0",fontSize:"12px" }}>
                                                        A Feedback named {item.title} created and will start on{" "}
                                                        {moment(item.start_time).format("DD MMM YYYY HH:mm")}{" "}
                                                        and set to be expired on{" "}
                                                        {moment(item.doe).format("DD MMM YYYY HH:mm")}
                                                    </div> */}
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        {/* Created on : <b>{moment(item.created_at).format("ddd, MMM Do YYYY, h:mm a")}</b><br /> */}
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                    </div>
                                                    <ExpiredButton>Yet to Start</ExpiredButton>
                                                </div>
                                            ) : item.type == "unit_feedback" &&
                                                moment(item.doe) < current ? (
                                                 <div  style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        {/* Created on : <b>{moment(item.created_at).format("ddd, MMM Do YYYY, h:mm a")}</b><br /> */}
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                    </div>
                                                    {/* <div style={{margin: '10px 0'}}>Feedback got expired </div> */}
                                                    <ExpiredButton>Feedback Expired </ExpiredButton>
                                                </div>
                                            ) : item.type == "unit_feedback" ? (
                                                <div  style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ fontSize: "11px", lineHeight: "1.8" }}>
                                                        {/* Created on : <b>{moment(item.created_at).format("ddd, MMM Do YYYY, h:mm a")}</b><br /> */}
                                                        Start Time : <b>{moment(item.start_time).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                        End Time : <b>{moment(item.doe).format("ddd, MMM Do YYYY, h:mm a")}</b><br />
                                                    </div>
                                                    <div style={{ textAlign: "center", margin: "10px 20px",fontSize:"12px",fontWeight:"bold"}}>
                                                        View Feedback
                                                    </div>
                                                </div>
                                            ) : null}

                                            {item.type == "VideoConference" &&
                                                moment(item.end_time) >= current &&
                                                moment(item.start_time).subtract(10, "minutes") >= current ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0",fontSize:"12px"}}>
                                                        Meeting will start by{" "}
                                                        {moment(item.start_time).format("DD MMM YYYY HH:mm")}
                                                    </div>
                                                </div>
                                            ) : item.type == "VideoConference" &&
                                                moment(item.end_time) >= current &&
                                                moment(item.start_time).subtract(10, "minutes") <=
                                                current ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0" ,fontSize:"12px"}}>
                                                        Meeting has already started and it is going to end by{" "}
                                                        {moment(item.end_time).format("DD MMM YYYY HH:mm")}
                                                    </div>
                                                    <Button onClick={(event) => openVC(event, item.url)}>
                                                        Start Meeting
                                                    </Button>
                                                </div>
                                            ) : item.type == "VideoConference" &&
                                                moment(item.end_time) <= current &&
                                                moment(item.start_time) <= current ? (
                                                <div style={{ textAlign: "left", margin: "10px 20px" ,fontSize:12}}>
                                                    <div style={{ margin: "10px 0" }}>
                                                        Meeting has completed
                                                    </div>
                                                    <ExpiredButton>Metting Completed</ExpiredButton>
                                                </div>
                                            ) : null}
                                        </div>
                                        {item.type == "Material" ||
                                            item.type == "Notification" ||
                                            item.type == "Feedback" ? (
                                            <div style={{ margin: "10px" }}>
                                                <div style={{ width: "100%" }}>
                                                    <input
                                                        onChange={(event) =>
                                                            onCommentInputChange(event, item.id, item.type)
                                                        }
                                                        value={
                                                           comments[item_index]?.comment
                                                               
                                                        }
                                                        type="test"
                                                        placeholder="Write a comment here"
                                                        className="form-control"
                                                        style={{
                                                            width: "100%",
                                                            height: "35px",
                                                            borderRadius: "15px",
                                                            fontSize:12
                                                        }}
                                                    ></input>
                                                </div>
                                                <div>
                                                    <Button
                                                        onClick={(event) =>
                                                            postCommentHandler(event, item.id, item.type)
                                                        }
                                                        style={{ marginRight: "10px" }}
                                                    >
                                                        Post Comment
                                                    </Button>
                                                </div>
                                                <ViewComments  onClick={() =>
                            loadcomments(item.id, item.type)
                          }><GoComment style={{fontSize:"20px"}} />View Comments</ViewComments>
                                            </div>
                                        ) : null}
                                        {(item.type == "Material" ||
                                            item.type == "Notification" ||
                                            item.type == "Feedback") &&
                                            item.comments &&
                                            item.comments.length > 0 ? (
                                            <div>
                                                <div
                                                    style={{
                                                        borderBottom: "1px solid #DDDDDD",
                                                        margin: "10px",
                                                    }}
                                                ></div>
                                                <div style={{ margin: "10px" }}>
                                                    <div
                                                        style={{
                                                            fontWeight: "600",
                                                            fontSize: "20px",
                                                            lineHeight: "23px",
                                                            textAlign: "left",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        Other Comments
                                                    </div>
                                                    {item.comments.map((com) => {
                                                        return (
                                                            <div>
                                                                <div
                                                                    style={{ display: "flex", flexDirection: "row" }}
                                                                >
                                                                    {com.student && com.student.profile_pic ? (
                                                                        <div>
                                                                            <img
                                                                                width="36px"
                                                                                height="36px"
                                                                                style={{
                                                                                    border: "1px solid #979797",
                                                                                    borderRadius: "50%",
                                                                                }}
                                                                                src={com.student.profile_pic}
                                                                                alt="profile"
                                                                            ></img>
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            <img
                                                                                width="36px"
                                                                                height="36px"
                                                                                style={{
                                                                                    border: "1px solid #979797",
                                                                                    borderRadius: "50%",
                                                                                }}
                                                                                src={com.college_account?.profile_pic}
                                                                                alt="college profile"
                                                                            ></img>
                                                                        </div>
                                                                    )}
                                                                    <div
                                                                        style={{
                                                                            margin: "auto 10px",
                                                                            color: "#191648",
                                                                            fontWeight: "600",
                                                                            fontSize: "16px",
                                                                            lineHeight: "23px",
                                                                        }}
                                                                    >
                                                                        {com.student
                                                                            ? com.student.name
                                                                            : `${com.college_account.first_name}  ${com.college_account.last_name}`}
                                                                    </div>
                                                                    <div style={{ margin: "auto 10px auto 0" }}>
                                                                        {" "}
                                                                        |{" "}
                                                                    </div>
                                                                    <div style={{ margin: "auto 10px auto 0" }}>
                                                                        {com.created_at}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        color: "#191648",
                                                                        fontSize: "16px",
                                                                        lineHeight: "24px",
                                                                        textAlign: "left",
                                                                        marginLeft: "30px",
                                                                    }}
                                                                >
                                                                    {com.comment}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        borderBottom: "1px solid #DDDDDD",
                                                                        margin: "10px 0",
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        );
                                                    })}
                                                    {item.comments.length < item.comments_count ? (
                                                        <div
                                                            onClick={() =>
                                                                props.loadMoreComments(item.id, item.type)
                                                            }
                                                            style={{
                                                                fontSize: "16px",
                                                                fontWeight: "600",
                                                                color: "#4F55C8",
                                                                marginLeft: "20px",
                                                                textAlign: "left",
                                                                lineHeight: "26px",
                                                            }}
                                                        >
                                                            View more comments
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            );
                            }
                        })}
                        {activity_loading_status ? <ActivitywallSkeleton />

                            : null}

                    </>
                    : <div>No results found for your search</div>}
            </div>
            {!activity_loading_status && !searchtext ? <Loadmore onClick={() => loadmoredata(props.query_date)} >Load More <BsArrowDownCircleFill /></Loadmore> : null}
            <FeedbackModal open={props.start_feedback}></FeedbackModal>
            <Modal show={showunansweredstudents} onHide={closeunansweredmodal}>
          <Modal.Header closeButton>
            <Modal.Title>UnAttempted students</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{height:"400px",overflowY:"scroll"}}>
          <Table hover style={{margin:"0px"}}>
      <thead>
        <tr>
          <th>Roll No.</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
      {props.unanswered_students.length>0 ?
            <>
          {props.unanswered_students.map((data,index) => (
            <tr
              key={index}
             
            >
              <td component="th" scope="row">
                {data.roll_number}
              </td>
              <td>{data.name}</td>

            </tr>
          ))}
          </>
          :null}
      </tbody>
    </Table>
        
          </Modal.Body>
       
        </Modal>

        <Modal show={showansweredstudents} onHide={closeansweredmodal}>
          <Modal.Header closeButton>
            <Modal.Title>Attempted students</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{height:"400px",overflowY:"scroll"}}>
          <Table hover style={{margin:"0px"}}>
      <thead>
        <tr>
          <th>Roll No.</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
      {props.answered_students.length>0 ?
            <>
          {props.answered_students.map((data,index) => (
            <tr
              key={index}
             
            >
              <td component="th" scope="row">
                {data.roll_number}
              </td>
              <td>{data.name}</td>

            </tr>
          ))}
          </>
          :null}
      </tbody>
    </Table>
        
          </Modal.Body>
       
        </Modal>
        <Modal
          show={enrolledweeklychallenge}
          onHide={closeSuccess}
        >
          <Modal.Header closeButton>
            <Modal.Title>Success</Modal.Title>
          </Modal.Header>
          <Modal.Body>Successfully enrolled to Arena</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={closeSuccess}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
        <Dialog
        open={showcommentsmodal}
        TransitionComponent={Transition}
        keepMounted
         onClose={closecommentmodal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Comments
          </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.comments_data ?
            <>
          {props.comments_data.map((com) => {
                        return (
                          <div>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              {com.student && com.student.profile_pic ? (
                                <div>
                                  <img
                                    width="36px"
                                    height="36px"
                                    style={{
                                      border: "1px solid #979797",
                                      borderRadius: "50%",
                                    }}
                                    src={com.student.profile_pic}
                                    alt="profile"
                                  ></img>
                                </div>
                              ) : (
                                <div>
                                  <img
                                    width="36px"
                                    height="36px"
                                    style={{
                                      border: "1px solid #979797",
                                      borderRadius: "50%",
                                    }}
                                    src={com.college_account?.profile_pic}
                                    alt="college profile"
                                  ></img>
                                </div>
                              )}
                              <div
                                style={{
                                  margin: "auto 10px",
                                  color: "#191648",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  lineHeight: "23px",
                                }}
                              >
                                {com.student
                                  ? com.student.name
                                  : `${com.college_account.first_name}  ${com.college_account.last_name}`}
                              </div>
                              <div style={{ margin: "auto 10px auto 0" }}>
                                {" "}
                                |{" "}
                              </div>
                              <div style={{ margin: "auto 10px auto 0" }}>
                                {com.created_at}
                              </div>
                            </div>
                            <div
                              style={{
                                color: "#191648",
                                fontSize: "16px",
                                lineHeight: "24px",
                                textAlign: "left",
                                marginLeft: "30px",
                              }}
                            >
                              {com.comment}
                            </div>
                            <div
                              style={{
                                borderBottom: "1px solid #DDDDDD",
                                margin: "10px 0",
                              }}
                            ></div>
                          </div>
                        );
                      })}
                      </>
                      :null}
          </DialogContentText>
          </DialogContent>
          </Dialog>
            <Snackbar
                open={showsuccess && !props.error_posting_comment}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                autoHideDuration={4000}
                TransitionComponent={Transition}
                message="I love snacks"
                onClose={handsnackbarleClose}
            > 
             <Alert onClose={handsnackbarleClose} sx={{ width: '100%' }}  severity="success">
             <AlertTitle>Success</AlertTitle>
               Comment posted successfully
                </Alert>
                </Snackbar>
        </>
    );
}
function mapStateToProps(state) {
    return {
        query_date: state.dash.query_date,
        loading_walldata: state.dash.loading_walldata,
        activity_wall: state.dash.activity_wall,
        start_feedback: state.class.start_feedback,
        feedback_data: state.class.feedback_data,
        feedback_answers: state.class.feedback_answers,
        feedback_error: state.class.feedback_error,
        loading_answered_students:state.dash.loading_answered_students,
        error_loading_answered_students:state.dash.error_loading_answered_students,
        answered_students:state.dash.answered_students,
        loading_answered_unstudents:state.dash.loading_answered_unstudents,
        error_unloading_answered_students:state.dash.error_unloading_answered_students,
        unanswered_students:state.dash.unanswered_students,
        comments_data: state.class.comments_data,

    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getWallDatares: (query_date) => dispatch(getWallData(query_date)),
        postComment: (id, comment, type) =>
        dispatch(postCommentAC(id, comment, type)),
        getFeedback: (id) => dispatch(getFeedbackAC(id)),
        getAnsweredStudentsdata:(id,type)=>dispatch(getAnsweredStudents(id,type)),
        getUnAnsweredStudentsdata:(id,type)=>dispatch(getUnAnsweredStudents(id,type)),
        enrollweeklychallenge: (id) => dispatch(EnrollweeklyChallenge(id)),
        loadMoreComments: (id, type) => dispatch(loadMoreCommentsAC(id, type)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ActivityWall)
);