import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import Flex from "styled-flex-component";
import Equation from "../../components/Equation";

const ModalWrapper = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.1);
`;

const ModalTextWrapper = styled(Flex)`
  width: 85%;
  padding: 1em 2em;
  border: 1px solid #ccc;
  margin: auto;
  background: #fff;
  margin-top: auto;
  // height: auto;
  overflow: auto;
  border-radius: 8px;
`;

const Modal = ({ open, url, close , type, template}) => {
  // console.log(url);
  // console.log(type);
  const [isS3, setIsS3] = useState(false);
  const [data, setData] = useState(null);

  const getData = async () => {
    // const res1 = axios.get(url).then(res => res);
    // console.log(res1);
    return "text"
  }

  useEffect(() => {
    if(url && url.includes(".s3.")){
      setIsS3(true);
      axios.get(`${url}`).then(res => {
        console.log(res.data.split("$"));
        let eq_data = "";
        const data_split = res.data.split("$");
        data_split.forEach((st,i) => {
          if(i === 0 || i === data_split.length - 1) eq_data += st;
          else eq_data += `<Equation bold equation={'$${st}$'}></Equation>`
        })
        console.log(eq_data);
        setData(res.data)
      });
    }
  }, [url])
  

  if (type === 'MP4') {
    if (url.includes('youtube') || url.includes('youtu')) {
      var re = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;
      let text1 = url.replace(re,'$1');
      url = `https://www.youtube.com/embed/${text1}?autoplay=1`;
    }
  } else if(type === 'PPT' || type === 'DOCS' || type === 'PDF'){
      url = "https://drive.google.com/viewerng/viewer?embedded=true&url="+url.split('?')[0];
  }
  // console.log(url);
  // console.log(type);
  if(!isS3){
    return <ModalWrapper center open={open} onClick={close}>
        <ModalTextWrapper onClick={()=>{}} column>
            <iframe src={url} allow="autoplay" frameBorder="0" width="100%" height="550px" title="modal frame"></iframe>
        </ModalTextWrapper>
    </ModalWrapper>
  }
  else{
    console.log("data");
    console.log(data);
    return <ModalWrapper center open={open} onClick={close}>
        <ModalTextWrapper onClick={()=>{}} column>
            <div style={{width: "100%", height: "550px"}}>
              <Equation math_type={1} equation={data}></Equation>
            </div>
        </ModalTextWrapper>
    </ModalWrapper>
  }
};

export default Modal;