export const PERSONALITY_QUESTION_NOTIFICATION = "PERSONALITY_QUESTION_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

export const triggerNotification = (trigger_type, trigger_id) => {
    let notification_type;
    if (trigger_type === 'Personality') {
        notification_type = PERSONALITY_QUESTION_NOTIFICATION;
    }
    return {
        type: notification_type,
        trigger_id: trigger_id,
    };
};

export const closeNotification = () => {
    return {
        type: CLOSE_NOTIFICATION,
        trigger_id: 0,
    }
}