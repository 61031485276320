import React, { useEffect, useState } from 'react'
import StudyContentCarousel from '../../StudyContentCarousel/StudyContentCarousel';
import { StudySnippetsSectionRenderer } from '../../StudyContentCarousel/renderer';
import { useRemedialContext } from '../../../hooks/useRemedial';


const dummyArray = [
  {snippet: ["Lorem ipsum dolor sit amet consectetur. Id sed vitae egestas vitae sem malesuada. Amet ultricies rhoncus vestibulum sed maecenas nulla elementum. Scelerisque felis eget morbi vitae nulla viverra. Pharetra sed aliquam nascetur mauris velit non eget. Lorem ipsum dolor sit amet consectetur. Id sed vitae egestas vitae sem malesuada. Amet ultricies rhoncus vestibulum sed maecenas nulla elementum. Scelerisque felis eget morbi vitae nulla viverra. Pharetra sed aliquam nascetur mauris velit non eget.","Lorem ipsum dolor sit amet consectetur. Id sed vitae egestas vitae sem malesuada. Amet ultricies rhoncus vestibulum sed maecenas nulla elementum. Scelerisque felis eget morbi vitae nulla viverra. Pharetra sed aliquam nascetur mauris velit non eget.","Lorem ipsum dolor sit amet consectetur. Id sed vitae egestas vitae sem malesuada. Amet ultricies rhoncus vestibulum sed maecenas nulla elementum. Scelerisque felis eget morbi vitae nulla viverra. Pharetra sed aliquam nascetur mauris velit non eget.","Lorem ipsum dolor sit amet consectetur adipisicing elit.Lorem ipsum dolor sit amet consectetur adipisicing elit.",]},
  {snippet: ["Lorem ipsum dolor sit amet consectetur. Id sed vitae egestas vitae sem malesuada. Amet ultricies rhoncus vestibulum sed maecenas nulla elementum. Scelerisque felis eget morbi vitae nulla viverra. Pharetra sed aliquam nascetur mauris velit non eget. Lorem ipsum dolor sit amet consectetur. Id sed vitae egestas vitae sem malesuada. Amet ultricies rhoncus vestibulum sed maecenas nulla elementum. Scelerisque felis eget morbi vitae nulla viverra. Pharetra sed aliquam nascetur mauris velit non eget.","Lorem ipsum dolor sit amet consectetur. Id sed vitae egestas vitae sem malesuada. Amet ultricies rhoncus vestibulum sed maecenas nulla elementum. Scelerisque felis eget morbi vitae nulla viverra. Pharetra sed aliquam nascetur mauris velit non eget.","Lorem ipsum dolor sit amet consectetur. Id sed vitae egestas vitae sem malesuada. Amet ultricies rhoncus vestibulum sed maecenas nulla elementum. Scelerisque felis eget morbi vitae nulla viverra. Pharetra sed aliquam nascetur mauris velit non eget.","Lorem ipsum dolor sit amet consectetur adipisicing elit.Lorem ipsum dolor sit amet consectetur adipisicing elit.","Lorem ipsum dolor sit amet consectetur adipisicing elit.Lorem ipsum dolor sit amet consectetur adipisicing elit.","Lorem ipsum dolor sit amet consectetur adipisicing elit.Lorem ipsum dolor sit amet consectetur adipisicing elit."]},
  {snippet: ["Lorem ipsum dolor sit amet consectetur. Id sed vitae egestas vitae sem malesuada. Amet ultricies rhoncus vestibulum sed maecenas nulla elementum. Scelerisque felis eget morbi vitae nulla viverra. Pharetra sed aliquam nascetur mauris velit non eget. Lorem ipsum dolor sit amet consectetur. Id sed vitae egestas vitae sem malesuada. Amet ultricies rhoncus vestibulum sed maecenas nulla elementum. Scelerisque felis eget morbi vitae nulla viverra. Pharetra sed aliquam nascetur mauris velit non eget.","Lorem ipsum dolor sit amet consectetur. Id sed vitae egestas vitae sem malesuada. Amet ultricies rhoncus vestibulum sed maecenas nulla elementum. Scelerisque felis eget morbi vitae nulla viverra. Pharetra sed aliquam nascetur mauris velit non eget.","Lorem ipsum dolor sit amet consectetur. Id sed vitae egestas vitae sem malesuada. Amet ultricies rhoncus vestibulum sed maecenas nulla elementum. Scelerisque felis eget morbi vitae nulla viverra. Pharetra sed aliquam nascetur mauris velit non eget.","Lorem ipsum dolor sit amet consectetur adipisicing elit.Lorem ipsum dolor sit amet consectetur adipisicing elit.",]},
];

const StudySnippets = ({index , data}) => {
  const [noOfSnippets, setNoOfSnippets] = useState(0);
  const { studyPageData, setStudyPageData } = useRemedialContext();

  const handleOnChange = (num) => {
    setNoOfSnippets(num)
  }





  useEffect(() => {
    if (data) {
      const array = studyPageData;
      const newArray = [];
      array.forEach((item, idx) => {
        let value = idx === index ? { ...item, noOfSnippets: noOfSnippets + 1 } : item
        newArray.push(value)
      })
      setStudyPageData(newArray)
    }
  }, [noOfSnippets])

  console.log(index , studyPageData[index].noOfSnippets ,'vgygvvghgvh')

  return (
    <StudyContentCarousel data={data} slidesPerView={1} renderer={StudySnippetsSectionRenderer} onChange={handleOnChange} currentSlideIndex={studyPageData[index].noOfSnippets > studyPageData[index].document_current_read ? studyPageData[index].noOfSnippets : studyPageData[index].document_current_read} />
  )
}

export default StudySnippets