import React from 'react'
import { Box } from '@mui/material'
import RemedialModal from '../../../../components/common/RemedialModal/RemedialModal'

const ImagePreviewModal = ({show , img , onImageClose}) => {
    return (
        <RemedialModal show={show} title={'Image Preview'} headBodyGap={16} onCloseHandle={onImageClose} >
            <Box  sx={{
                width: '100%',
                height: '193px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: (theme) => theme.palette.grey[100],
                padding: '0 24px',
                img: {
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%'
                }
            }}>

                <img src={img} alt="question-img" />

            </Box>
        </RemedialModal>
    )
}

export default ImagePreviewModal