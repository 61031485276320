import React, { useEffect } from 'react'
import RemedialContainer from '../../features/RemedialPath/components/RemedialContainer/RemedialContainer'
import Grid from '@mui/system/Unstable_Grid';
import { Stack } from '@mui/material';
import RemedialHeader from '../../features/RemedialPath/components/RemedialHeader/RemedialHeader';
import RecordCard from '../../features/RemedialPath/components/RecordCard/RecordCard';
import RevisedScoreCard from '../../features/RemedialPath/components/RevisedScoreCard/RevisedScoreCard';
import Questionnaire from '../../features/RemedialPath/components/Questionnaire/Questionnaire';
import TimeAnalysisGraph from '../../features/RemedialPath/components/TimeAnalysisGraph/TimeAnalysisGraph';
import { useRemedialContext } from '../../features/RemedialPath/hooks/useRemedial';
import { useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';

const RevisedTestResultPage = ({ endpoint }) => {
    const { revisedResultPageData, testResultPageData, setRevisedResultPageData,bannerData } = useRemedialContext();
    const { testId } = useParams();
    const { data, loading, error } = useFetch(endpoint);
    let {user_id} = JSON.parse(localStorage.getItem("user"))

    useEffect(() => {
        if (data) {
            setRevisedResultPageData(data)
        }
    }, [data, loading, error])


    console.log(revisedResultPageData, 'revisedResultPageData')

    return (
        <RemedialContainer>
            <Stack sx={{ gap: '24px' }}>
                <RemedialHeader heading={'Test Result'} testName={revisedResultPageData?.test_details?.name} subject={revisedResultPageData?.test_details?.subject} />
                <Grid container spacing={3}>
                    <Grid xs={3.5} container spacing={2}>
                        <Grid xs={6}><RecordCard variant='time' value={revisedResultPageData?.total_time} text='Total time spent by you' /></Grid>
                        <Grid xs={6}><RecordCard variant='rank' value={revisedResultPageData?.rank} text='Your Class rank' /></Grid>
                        <Grid xs={6}><RecordCard variant='performance' value={revisedResultPageData?.percentage} text='average performance' /></Grid>
                        <Grid xs={6}><RecordCard variant='answered' value={revisedResultPageData?.unanswered_count} text='unanswered count' /></Grid>
                    </Grid>
                    <Grid xs={4}>
                        <RevisedScoreCard testData={testResultPageData} revisedData={revisedResultPageData} />
                    </Grid>
                    <Grid xs={4.5}>
                        <Stack sx={{ gap: '24px' }}>
                            {bannerData.test_completed>0 ?
                            <Questionnaire title='Remedial Answer Sheet' endpoint={`/questionnaire/v2/getRemedialPathTestDetails?remedial_questionnaire_id=${bannerData.remedial_questionnaire_id}&student_id=${user_id}&stage_id=1`} />
                            :null}
                            <Questionnaire endpoint={`/questionnaire/v2/getTestQuestions?test_id=${testId}`} />
                        </Stack>
                    </Grid>
                </Grid>
                {revisedResultPageData?.time_per_question && <TimeAnalysisGraph data={revisedResultPageData?.time_per_question} />}
            </Stack>
        </RemedialContainer>
    )
}

export default RevisedTestResultPage