import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Layout1 from "../../components/Layout/Layout1";
import moment from "moment";
// import Slider from "react-slick";
import Carousel from "react-grid-carousel";
import "./dashboard.css";
import { AiOutlineFieldTime } from "react-icons/ai";
import { VscGraph } from "react-icons/vsc";
import { AiOutlineFileDone } from "react-icons/ai";
import { GrDocumentPerformance } from "react-icons/gr";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import {FiArrowDown} from "react-icons/fi";
import {FiArrowUp} from "react-icons/fi";
import {FiRefreshCcw} from "react-icons/fi";
import Tooltip from '@mui/material/Tooltip';
import {MdNotificationsNone} from "react-icons/md";
import Analyticskeletonloader from "./analyticsloadingskeleton";
import {AiOutlineCloseCircle} from "react-icons/ai";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import '../../fonts/axiforma.css';
import party from "party-js";
import {
  getDashboardDataAC,
  getSearchDataAC,
  submitQuestionOfTheDayAC,
  addBookmarkAC,
  deleteBookmarkAC,
  getDeckItemsAC,
  getDeckDataAC,
  OPENRECOMMENDEDDECK,
  OPENQUESTIONOFTHEDAYREADMORE,
  OPENTOPICOFTHEDAYREADMORE,
  CLOSERECOMMENDEDDECK,
  CLOSEQUESTIONOFTHEDAYREADMORE,
  CLOSETOPICOFTHEDAYREADMORE,
  getRecommendedDecksAC,
  getSearchedTopicDeckAC,
  getSearchedTopicVideoAC,
  getSearchedTopicDocsAC,
  getIosAppLinkAC,
  getWallData,
  getStatsData,
  getStatsDataSemester,
  getAnsweredStudents,
  addQodInterestAC,
} from "../../store/actions/dashboardActions";
import styled from "styled-components";
import Equation3 from "../../components/Equation3";
import QotdGraph from "../../components/QotdGraph";
import Modal1 from "./Modal";
import Modal2 from "./Modal2";
import Modal3 from "./Modal3";
import Modal4 from "./Modal4";
import Modal from "react-bootstrap/Modal";
import PreviewCard from "../../components/decks/PreviewCard/PreviewCard";
import PreviewCard2 from "../../components/decks/PreviewCard/PreviewCardSearchDeck";
import ReportDialog from "../../components/reportModal";
import OneSignal from "react-onesignal";
import ImageModal from "../subjective/ImageModal";
import "./dashboard.css";
import { BsArrowRightCircleFill } from "react-icons/bs";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Dashboardskeletonloader from './loadingskeleton';
import ActivityWall from './activitywall';
import Analyticscreen from "./analyticsscreen";
import { init, identify, Identify, track } from '@amplitude/analytics-browser';
import {
  getClassroomActivityAC,
  postCommentAC,
  loadMoreCommentsAC,
  getFeedbackAC,
} from "../../store/actions/classroomActions";
import ThreeLineText from "../../components/ThreeLineText";
import Equation5 from "../../components/Equation5";
import QodSolution from "./qodSolution";
import Collapse from 'react-bootstrap/Collapse';
import NotificationPrompt from "../../components/common/NotificationPrompt";
var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
const AMPLITUDE_API_KEY=process.env.REACT_APP_AMPLITUDE_KEY;
const SearchInput = styled.input`
  border: none;
  width: 95%;
  font-size: 15px;
  &:focus {
    outline: none !important;
  }
`;
// const SubjectDiv = styled.div`
//   // display: flex;
//   // flex-direction: column;
//   // flex: 0 0 calc(33% - 32px);
//   // width: calc(50% - 32px);
//   // margin: 32px 16px 0;
//   width: 98%;
//   color: #172239;
//   transition: color 0.2s;
//   cursor: pointer;
//   :hover {
//     // box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
//     // border-radius: 35px 32px 29px 31px !important;
//   }
// `;
const SubjectDiv = styled.div`
 border-radius: 8px;
    padding: 3px 12px;
    margin-bottom: 8px;
    text-align: left;
    font-size: 12px;
    cursor:pointer;
  // width: 60%;
  &:hover {
    background-color: #d6d8ff;
  }
  @media (max-width: 768px) {
    padding: 0px 0px;
    font-size: 10px;
  }
`;
const Leaderboardrow = styled.div`
    display: flex;
    font-size: 10px;
   align-items: center;
    font-weight: 600;
    border-bottom: 1px solid #80808052;
    padding: 12px 0px 12px 0px;
    padding-left: 0px !important;
  `;
const MaterialImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 300px);

  @media (max-width: 1180px) {
    grid-template-columns: repeat(3, 300px);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 300px);
  }
`;
const SurpriseText = styled.div`
  margin-top: 15px;
  color: blue;
  cursor: pointer;

  @media (max-width: 1200px) {
    // margin-top: 10px;
  }

  @media (max-width: 1100px) {
    margin-top: 0px;
  }

  @media (max-width: 768px) {
    margin-top: -30px;
  }
`;

const SearchInputDiv = styled.div`
  width: 90%;
  @media (max-width: 1100px) {
    width: 92%;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const SearchImage = styled.div`
  width: 10%;
  @media (max-width: 1100px) {
    width: 8%;
  }
  @media (max-width: 768px) {
    width: 10%;
  }
`;

const MonkeyDiv = styled.div`
  margin: auto;
  margin-top: -80px;

  @media (max-width: 1200px) {
    margin-top: -30px;
  }
  @media (max-width: 960px) {
    margin-top: -10px;
  }
`;
const Button = styled.div`
  background:#FFDFD6;
  border-radius: 25px;
  margin: auto;
  text-align: center;
  // border: 1px solid
  //   linear-gradient(139.5deg, #7175e0 -50.69%, #3235c0 60.2%, #e25bc8 189.26%);
  width: 130px;
    padding: 4px;
    color: black;
    margin-top: 8px;
    font-size: 13px;
    font-weight: 600;
    box-shadow: 0px 0px 2px 0px #7e7777;
`;
const ExpiredButton = styled.div`
  // background: linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%);
  bacgroudn: white;
  border-radius: 30px;
  margin: auto;
  text-align: center;
  // border: 1px solid linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%);
  border: 1px solid #bbb;
  width: 150px;
  padding: 4px;
  color: black;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600;
  margin-right:0px;
`;
class Dashboard extends Component {
  state = {
    openTOTD: false,
    search_data: "",
    showSearchResults: false,
    reportItemId: null,
    openReportDialog: false,
    itemToBeReported: null,
    iosModelShow: false,
    openImage: false,
    image_url: null,
    get_search_data: false,
    assessmenttabcontent: true,
    objectivetabcontent: true,
    resultstabcontent: false,
    openMaterial: false,
    material_url: null,
    comments: [],
    classroomdata: [],
    show_more_assesments: false,
    seven_days_data:true,
    loading_refreshed_data:false,
    opennotification:false,
    semester:4,
    open_qod: false,
    selected_qod_option: 0,
    submitting_qod: false,
    show_qod_answered_view: false,
    viewQODExplanation: false,
    qod_notification: '',
    total_for_personalization: 0,
    qod_completed_percentage_before_personalization: '0%',
    show_qod_notification: false,
    show_qod_up_arrow: false,
    show_qod_down_arrow: false,
    show_qod_menu: false, 
    qod_options_to_letters_mapping: {1:'a', 2:'b', 3:'c', 4:'d', 5:'e'},
    image_aspect_ratio: 'not wide',
    hoveredOption: 0,
    qodExplanationButtonClicked: false,
    validOptionsWithoutImagesList: [],
    qodOverflow: false,
    startTime: null,
    endTime: null, 
    elapsedTime: null,
    refreshPage: true
  };
  componentDidMount = async () => {
    console.log(process.env);
    document.body.style.backgroundColor = "#fff";

    OneSignal.init({
      appId: "62835524-709a-408f-9220-c1878d149754",
    });

    // OneSignal.push(function() {
    //     OneSignal.on('subscriptionChange', function(isSubscribed) {
    //         if (isSubscribed === true) {
    //             // console.log('The user subscription state is now:', isSubscribed);
    //             // var pathArray = window.location.pathname.split('/');
    //             // OneSignal.sendTags({
    //             //     "subscription_page": pathArray[1],
    //             //     "subscription_page_topic": page_topic,
    //             // }).then(function(tagsSent) {
    //             //     // Callback called when tags have finished sending
    //             //     console.log(tagsSent);
    //             // });
    //             let user = JSON.parse(localStorage.getItem('user'));

    //             OneSignal.setExternalUserId(user.user_id);
    //         }
    //     });
    // });
    const user = JSON.parse(localStorage.getItem("user"));
    
    OneSignal.setExternalUserId(String(user.user_id));
    let checkdiff;
    if (localStorage.getItem("apicalltime")) {
      checkdiff=Math.abs(
        moment().diff(localStorage.getItem("apicalltime"), "seconds")
      )
    }
    if (!this.props.activity_wall || checkdiff>900 || this.state.refreshPage) {
      await this.props.getDashboardData();
    // await this.props.getStatsDatares();
    // await this.props.getStatsDataSemesterres();
    localStorage.apicalltime= new Date();
    }
    document.addEventListener("mousedown", this.handleClickOutsideQodMenu);
    if (this.props?.question_of_the_day) {
      if (this.props.question_of_the_day.is_answered) {
        this.setState({ show_qod_answered_view: true});

        const options = this.props.question_of_the_day.options[0];
        // const options = {option1_image: 'https://images.pexels.com/photos/1400249/pexels-photo-1400249.jpeg?cs=srgb&dl=pexels-mihai-vlasceanu-1400249.jpg&fm=jpg', option2_image: 'https://images.pexels.com/photos/255419/pexels-photo-255419.jpeg?cs=srgb&dl=pexels-pixabay-255419.jpg&fm=jpg', option3_image: 'https://images.pexels.com/photos/1400249/pexels-photo-1400249.jpeg?cs=srgb&dl=pexels-mihai-vlasceanu-1400249.jpg&fm=jpg', option4_image: 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3', option5_image: ''};
        let options_list = [];
        if (this.props.question_of_the_day.correctly_answered) {
          options_list.push(this.props.question_of_the_day.answer);
        }
        else {
          options_list.push(this.props.question_of_the_day.selected_answer);
          options_list.push(this.props.question_of_the_day.answer);
        }
        options_list.forEach((option) => {
          const key = `option${option}_image`;
          const imgUrl = options[key];

          if (imgUrl) {
            const img = new Image();
            img.src = imgUrl;
            img.onload = () => {
              const naturalWidth = img.naturalWidth;
              const naturalHeight = img.naturalHeight;
              const aspectRatio = naturalWidth / naturalHeight;
              if (aspectRatio > 10/3) {
                this.setState({ image_aspect_ratio: 'wide' });
              }
            };
          }
        });
      }
      else {
        let completed_percentage_before_personalization = (this.props.question_of_the_day.total_attempted/(this.props.question_of_the_day.total_attempted + this.props.question_of_the_day.pending_for_personlization))*100;
        this.setState({qod_completed_percentage_before_personalization: `${completed_percentage_before_personalization}%`});
        const options = this.props.question_of_the_day.options[0];
        // const options = {option1_image: 'https://images.pexels.com/photos/1400249/pexels-photo-1400249.jpeg?cs=srgb&dl=pexels-mihai-vlasceanu-1400249.jpg&fm=jpg', option2_image: 'https://images.pexels.com/photos/255419/pexels-photo-255419.jpeg?cs=srgb&dl=pexels-pixabay-255419.jpg&fm=jpg', option3_image: 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3', option4_image: 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3', option5_image: ''};
        let actually_present_options = [];
        for (let i=1; i<=5; i++) {
          if (options[`option${i}`] !== ""){
            actually_present_options.push(i);
          }
        }
        this.setState({validOptionsWithoutImagesList: actually_present_options});
        [1, 2, 3, 4, 5].forEach((option) => {
          const key = `option${option}_image`;
          const imgUrl = options[key];

          if (imgUrl) {
            const img = new Image();
            img.src = imgUrl;
            img.onload = () => {
              const naturalWidth = img.naturalWidth;
              const naturalHeight = img.naturalHeight;
              const aspectRatio = naturalWidth / naturalHeight;
              console.log("In image checker");
              if (aspectRatio > 10/3) {
                this.setState({ image_aspect_ratio: 'wide' });
                console.log("Is wide: " + JSON.stringify(option));
              }
            };
          }
        });
      }
      this.setState({show_qod_notification: true, qod_notification: this.props.question_of_the_day?.notification, total_for_personalization: this.props.question_of_the_day.total_attempted + this.props.question_of_the_day?.pending_for_personlization});
      setTimeout(() => {
        this.setState({show_qod_notification: false, show_qod_down_arrow: true});
      }, 3000);
    }    
    
    // let date = moment().format("YYYY-MM-DD HH:mm:ss");

    // this.props.getClassroomDetails(date);
    // setTimeout(() => {
    // this.setState({classroomdata:this.props.college_notifications})
    // console.log(this.state.college_notifications);
    // console.log("classroom dataloaded")
    // }, 4000);

  };
  loadanalyticsdata= async () => {
    await this.props.getStatsDatares();
    // await this.props.getStatsDataSemesterres();
    }
  componentDidUpdate(prevProps) {
    if (this.state.iosModelShow && this.props.iosLinkError) {
      document.body.style.overflow = "auto";
    }
    // console.log(this.state.classroomdata.length)
    if (this.props.college_notifications.length > 0 && this.state.classroomdata.length === 0) {
      // console.log("college data update");
      this.setState({ classroomdata: this.props.college_notifications })
      // console.log(this.state.classroomdata)
    }
    if (!this.props.loading_analyticsdata && this.state.loading_refreshed_data) {
      this.setState({loading_refreshed_data:false});
    }
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      const identifyObj = new Identify();
      identifyObj.setOnce('User ID', `${user.user_id}`);
      identify(identifyObj);
      init(AMPLITUDE_API_KEY,`${user.user_id}`);
      if (!this.state.logged_in_event) {
        const eventProperties = {
          dashboard_loaded: `Yes`,
        };
         track('dashboard_loaded', eventProperties);
         this.setState({logged_in_event:true})
      }
    }
    if ((this.props.question_of_the_day && !prevProps.question_of_the_day)) {
      if (this.props.question_of_the_day.is_answered === 1 ){
        this.setState({ show_qod_answered_view: true });
      }
      else {
        let completed_percentage_before_personalization = (this.props.question_of_the_day.total_attempted/(this.props.question_of_the_day.total_attempted + this.props.question_of_the_day.pending_for_personlization))*100;
        this.setState({qod_completed_percentage_before_personalization: `${completed_percentage_before_personalization}%`});
        this.setState({total_for_personalization: this.props.question_of_the_day.total_attempted + this.props.question_of_the_day?.pending_for_personlization});
      }
      this.setState({show_qod_notification: true, qod_notification: this.props.question_of_the_day?.notification, show_qod_up_arrow: false});
      setTimeout(() => {
        this.setState({show_qod_notification: false, show_qod_down_arrow: true});
      }, 3000);

      const options = this.props.question_of_the_day.options[0];
      // const options = {option1_image: 'https://images.pexels.com/photos/1400249/pexels-photo-1400249.jpeg?cs=srgb&dl=pexels-mihai-vlasceanu-1400249.jpg&fm=jpg', option2_image: 'https://images.pexels.com/photos/255419/pexels-photo-255419.jpeg?cs=srgb&dl=pexels-pixabay-255419.jpg&fm=jpg', option3_image: '', option4_image: 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3', option5_image: ''};
      let actually_present_options = [];
        for (let i=1; i<=5; i++) {
          if (options[`option${i}`] !== ""){
            actually_present_options.push(i);
          }
        }
      this.setState({validOptionsWithoutImagesList: actually_present_options});
      [1, 2, 3, 4, 5].forEach((option) => {
        const key = `option${option}_image`;
        const imgUrl = options[key];

        if (imgUrl) {
          const img = new Image();
          img.src = imgUrl;
          img.onload = () => {
            const naturalWidth = img.naturalWidth;
            const naturalHeight = img.naturalHeight;
            const aspectRatio = naturalWidth / naturalHeight;
            console.log("In image checker");
            if (aspectRatio > 10/3) {
              this.setState({ image_aspect_ratio: 'wide' });
              console.log("Is wide: " + JSON.stringify(option));
            }
          };

          
        }
      });
    }

    if (this.props?.question_of_the_day?.is_answered && !prevProps?.question_of_the_day?.is_answered) {
      if (this.props.question_of_the_day.correctly_answered === 1 && this.state.submitting_qod === true) {
        party.confetti(document.getElementById("qod-main-content-box"), {
          count: party.variation.range(60, 60),
          spread: party.variation.range(10, 20),
          origin: {y:1.5},
          // zIndex: -1,
        });
        this.playQODSoundEffect();
      }
      setTimeout(() => {
        this.setState({show_qod_answered_view: true, submitting_qod: false, show_qod_notification: true, show_qod_down_arrow: false, show_qod_up_arrow: false});
      }, 2000);
      setTimeout(() => {
        this.setState({show_qod_notification: false, show_qod_down_arrow: true});
      }, 4000);
    }    
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutsideQodMenu);
  }

  playQODSoundEffect = () => {
    const audioElement = document.getElementById('qod-sound-effect');
    audioElement.play();
  }

  handleClickOutsideQodMenu = (event) => {
    if (this.menuRef && !this.menuRef.contains(event.target)) {
      this.setState({ show_qod_menu: false });
    }
  };

  onQuestionOfTheDayOptionSelected = async (event, index) => {
    if (!this.state.submitting_qod && !this.props.question_of_the_day.is_answered) {
      this.setState({selected_qod_option: index+1});
    }
  };

  onQODSubmit = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (this.state.selected_qod_option !== 0) {
      const endTime = new Date();
      const elapsedTime = (endTime - this.state.startTime) / 1000; // Convert to seconds
      this.setState({submitting_qod: true});
      this.setState({ endTime: endTime, elapsedTime: elapsedTime });
      await this.props.submitQuestionOfTheDay(this.state.selected_qod_option, Math.floor(elapsedTime));
      const eventProperties = {
        answered:'yes'
      };
      track('qod_answered', eventProperties);
    }
  }

  openQodNotification = () => {
    this.setState({show_qod_notification: !this.state.show_qod_notification, show_qod_down_arrow: !this.state.show_qod_down_arrow, show_qod_up_arrow: !this.state.show_qod_up_arrow});
  }

  handleQodInterest = async (interest) => {
      console.log("interest: "+interest)
      await this.props.addQodInterest(interest);
  }

  onTotdClickHandler = () => {
    this.setState({ openTOTD: true });
  };
  closeTotd = () => {
    this.setState({ openTOTD: false });
  };
  onTTClickHandler = (index) => {
    if (this.props.trending_topics[index].summary) {
      this.setState({ openTT: true, trending_topic: index });
    } else {
      return;
    }
  };
  closeTT = () => {
    this.setState({ openTT: false, trending_topic: null });
  };

  openRecondedDecks = (topic_code, deck) => {
    this.props.getRecommendedDecks(topic_code);
    this.props.openRecommendedDeck(deck);
  };

  openSubjectContent = (subject_id,subject_semester_id,csm_Id) => {
    // let subject_id = this.props.subjects[index].id;
    // let subjectsemester_id = this.props.subjects[index].subject_semester_id;
    const eventProperties = {
      subject_id: `${subject_id}`,
      subjectsemester_id: `${subject_semester_id}`,
      previous_screen_name:'dashboard'
    };
     track('subject_opened', eventProperties);
    this.props.history.push({
      pathname: "/course/content",
      state: { subject_id: subject_id,subject_semester_id:subject_semester_id,csm_Id:csm_Id },
    });
  };

  startAssessment = (id) => {
    this.props.history.push({
      pathname: "/assessment",
      state: { test_id: id, prev_page: "home" },
    });
  };
  startAssessmentright = (id,type) => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (type=="Test") {
      const eventProperties = {
        testid: `${id}`,
        userid:`${user.user_id}`
      };
       track('objective_test_rightpane', eventProperties);
      this.props.history.push({
        pathname: "/assessment",
        state: { test_id: id, prev_page: "home" },
      });
    } else if(type=="Subjective" || type==="Assignment") {
      const eventProperties = {
        testid: `${id}`,
        userid:`${user.user_id}`
      };
       track('subjective_test_rightpane', eventProperties);
      this.props.history.push({
        pathname: "/subjectiveAssessment",
        state: {
          test_id: id,
        },
      });
    }else if(type=="Coding") {
      const eventProperties = {
        testid: `${id}`,
        userid:`${user.user_id}`
      };
       track('coding_test_rightpane', eventProperties);
      this.props.history.push({
        pathname: "/startCoding",
        state: {
          test_id: id,
        },
      });
    }else if(type=="Weekly_Challenge"){
      const eventProperties = {
        testid: `${id}`,
        userid:`${user.user_id}`
      };
       track('challenge_test_rightpane', eventProperties);
      localStorage.setItem("challengeid", id);
      this.props.history.push({
        pathname: "/joinchallange",
        state: {
          test_id: id,
        },
      });
      window.location.reload();
    }
  
  };
  startweeklychallenge = (id) => {
    localStorage.setItem("challengeid", id);
    this.props.history.push({
      pathname: "/joinchallange",
      state: { test_id: id, prev_page: "home" },
    });
  };
  startCodingAssessment = (test_id) => {
    this.props.history.push({
      pathname: "/startCoding",
      state: {
        test_id: test_id,
      },
    });
  };

  startSubjectiveAssessment = (test_id) => {
    this.props.history.push({
      pathname: "/subjectiveAssessment",
      state: {
        test_id: test_id,
      },
    });
  };

  onOpenTopicOfTheDay = () => {
    if (this.props.topic_of_the_day.readmore_type == "deck") {
      this.props.getDeckData(this.props.topic_of_the_day.readmore_deck_code);
      this.props.openTopicOfTheDayReadmore();
    } else if (this.props.topic_of_the_day.recommended_type == "video") {
      this.setState({
        openTopicOfTheDayVideo: true,
        totdVideoUrl: this.props.topic_of_the_day.readmore_url,
      });
    } else {
      return;
    }
  };

  onOpenQuestionOfTheDay = () => {
    if (this.props.question_of_the_day.deck_code) {
      this.props.getDeckData(this.props.question_of_the_day.deck_code);
      this.props.openQuestionOfTheDayReadmore();
    }
  };

  closeDeck = () => {
    if (this.props.open_topic_of_the_day_readmore) {
      this.props.closeTopicOfTheDayReadmore();
    } else if (this.props.open_question_of_the_day_readmore) {
      this.props.closeQuestionOfTheDayReadmore();
    } else if (this.props.open_recommended_deck) {
      this.props.closeRecommendedDeck();
    }
  };
  searchresults = (event) => {
    const text = event.target.value.toLowerCase();
    let filteredActivities = [];
    if (text) {
      filteredActivities = this.state.classroomdata.filter(card => card.title && card.title.toLowerCase().indexOf(text) != -1);
      //  filteredActivities = this.state.classroomdata.filter(card => 
      //                         (card.type === "Test" && text === "test") ||
      //                         (card.type === "Subjective" && text === "subjective") ||
      //                         (card.type === "Notification" && text === "notification") ||
      //                         (card.type === "Coding" && text === "coding") ||
      //                         (card.type === "Feedback" && text === "feedback"))
      console.log(filteredActivities)
      this.setState({ classroomdata: filteredActivities })
    } else {
      this.setState({ classroomdata: this.props.college_notifications })
    }
    //  console.log(this.state.classroomdata)
    // console.log("search")
  }
  onSearchChangeHandler = (event) => {
    this.setState({ search_data: event.target.value });
  };

  onSearchFocus = async () => {
    console.log("on focus");
    if (!this.state.get_search_data && !this.state.search_data.length) {
      await this.props.getSearchData();
      this.setState({ showSearchResults: true, get_search_data: true });
    } else this.setState({ showSearchResults: true });
  };

  onSearchBlur = (event) => {
    // if (!document.activeElement.id == 'search-icon') {

    // }
    setTimeout(() => {
      this.setState({ showSearchResults: false, search_data: "" });
    }, 100);
    // this.setState({showSearchResults: false, search_data: ''});
    // console.log(event)
  };

  openAssessmentResults = (test_id,type) => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (type=="Test") {
      const eventProperties = {
        testid: `${test_id}`,
        userid:`${user.user_id}`
      };
       track('objective_results_rightpane', eventProperties);
      this.props.history.push({
        pathname: "/assessmentResults",
        state: { test_id: test_id },
      });
    } else if(type=="Subjective Test" || type === "Assignment") {
      const eventProperties = {
        testid: `${test_id}`,
        userid:`${user.user_id}`
      };
       track('subjective_results_rightpane', eventProperties);
      this.props.history.push({
        pathname: "/subjectiveResults",
        state: { test_id: test_id },
      });
    }else if(type=="Coding Test") {
      const eventProperties = {
        testid: `${test_id}`,
        userid:`${user.user_id}`
      };
       track('coding_results_rightpane', eventProperties);
      this.props.history.push({
        pathname: "/codingAssessmentSummary",
        state: { test_id: test_id },
      });
    }
  
   
  };

  openCodingAssessmentResults = (test_id) => {
    this.props.history.push({
      pathname: "/codingAssessmentSummary",
      state: { test_id: test_id },
    });
  };

  openSubjectiveAssessmentResults = (test_id) => {
    this.props.history.push({
      pathname: "/subjectiveResults",
      state: { test_id: test_id },
    });
  };

  openSearchedTopicPracticeTest = (item) => {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log(item);
    this.props.history.push({
      pathname: "practice",
      state: {
        code: item.code,
        name: item.name,
        college_university_degree_department_id:
          user.college_university_degree_department_id,
        semester_id: user.semester_id,
      },
    });
  };

  handleOpenQOD = () => {
    this.setState({open_qod: true});
    // Start the timer
    const startTime = new Date();
    this.setState({ startTime });
  }

  handleQodMenu = () => {
    this.setState({show_qod_menu: !this.state.show_qod_menu});
  };

  qodbookmarkItem = () => {
    if (this.props.question_of_the_day.is_bookmarked === 1) {
      this.props.deleteBookmark("questionoftheday");
    }
    else {
      this.props.addBookmark("questionoftheday");
    }
  };

  handleViewQODExplanation = () => {
    this.setState({qodExplanationButtonClicked: true, qodOverflow: true});
    // setTimeout(() => {
      this.setState({viewQODExplanation: true});
    // }, 400);
    // setTimeout(() => {
      this.setState({qodOverflow: false});
    // }, 800);
  }

  handleCloseQODExplanation = () => {
    this.setState({qodExplanationButtonClicked: false, qodOverflow: true});
    // setTimeout(() => {
      this.setState({viewQODExplanation: false});
    // }, 400);
    // setTimeout(() => {
      this.setState({qodOverflow: false});
    // }, 800);
  }  

  openQotdExplanation = () => {
    document.body.style.overflow = "hidden";
    this.setState({
      openQotdExplanationData: true,
      qotdExplainUrl: this.props.question_of_the_day.solution,
    });
  };

  closeQotdExplanation = () => {
    document.body.style.overflow = "auto";
    this.setState({ openQotdExplanationData: false, qotdExplainUrl: null });
  };

  reportItem = (item, id) => {
    // document.body.style.overflow = "hidden";
    this.setState({
      openReportDialog: true,
      itemToBeReported: item,
      reportItemId: id,
    });
  };

  closeReport = () => {
    // document.body.style.overflow = "auto";
    this.setState({
      openReportDialog: false,
      itemToBeReported: null,
      reportItemId: null,
    });
    document.body.style.overflow = "auto";
  };

  iosLinkModalOpen = async () => {
    await this.props.getIosAppLink();
    this.setState({ iosModelShow: true });
    if (!this.props.iosLinkError) document.body.style.overflow = "hidden";
  };

  iosLinkModalClose = () => {
    document.body.style.overflow = "auto";
    this.setState({ iosModelShow: false });
  };

  onGameClick = (type) => {
    const BASE_API_URL = process?.env?.REACT_APP_API_URL_TAKE_TEST;
    const token = localStorage.getItem("token");
    if (BASE_API_URL && token) {
      if (type === 1)
        document.location.href = `https://dev.depe7r5p5ejvl.amplifyapp.com/?id=1&url=${BASE_API_URL}&token=${token}`;
      else if (type === 2)
        document.location.href = `https://origin.d3i0yf453va4p3.amplifyapp.com/?id=2&url=${BASE_API_URL}&token=${token}`;
    }
  };

  onImageClick = (url) => this.setState({ openImage: true, image_url: url });

  closeImage = () => this.setState({ openImage: false, image_url: null });
  showassesmenttab = () => {
    this.setState({
      assessmenttabcontent: true,
      resultstabcontent: false,
      objectivetabcontent: true,
      bookmarkstabcontent: false,
      objectivetabcontent: true,
      subjectivetabcontent: false,
      codingtabcontent: false,
    })
  };
  showresultstab = () => {
    this.setState({
      assessmenttabcontent: false,
      resultstabcontent: true,
      objective_result_tab_content: true,
      bookmarkstabcontent: false,
      objective_result_tab_content: true,
      subjective_result_tab_content: false,
      coding_result_tab_content: false,
    })
  };
  showbookmarktab = () => {
    this.setState({ bookmarkstabcontent: true, assessmenttabcontent: false, resultstabcontent: false })
  };
  showobjectiveassessment = () => {
    this.setState({ objectivetabcontent: true, subjectivetabcontent: false, codingtabcontent: false })
  }
  showsubjectiveassessment = () => {
    this.setState({ objectivetabcontent: false, subjectivetabcontent: true, codingtabcontent: false })
  }
  showcodingassessment = () => {
    this.setState({ objectivetabcontent: false, subjectivetabcontent: false, codingtabcontent: true })
  }
  ShowObjectiveResults = () => {
    this.setState({ objective_result_tab_content: true, subjective_result_tab_content: false, coding_result_tab_content: false })
  }
  ShowSubjectiveResults = () => {
    this.setState({ objective_result_tab_content: false, subjective_result_tab_content: true, coding_result_tab_content: false })
  }
  ShowCodingAssessment = () => {
    this.setState({ objective_result_tab_content: false, subjective_result_tab_content: false, coding_result_tab_content: true })
  }

  showmoreassessments = () => {
    console.log("show more")
    this.setState({ show_more_assesments: true })
  }
  showlessassessments = () => {
    this.setState({ show_more_assesments: false })
  }
  showmoreresults = () => {
    this.setState({ show_more_results: true })
  }
  showlessresults = () => {
    this.setState({ show_more_results: false })
  }
  sevendaysdata=()=>{
    const eventProperties = {
      page: "dashboard",
    };
     track('analytics_seven_days_data', eventProperties);
    this.setState({ seven_days_data: true })
  }
  semesterdata=async()=>{
    const eventProperties = {
      page: "dashboard",
    };
     track('analytics_thirty_days_data', eventProperties);
    this.setState({ seven_days_data: false })
    if(!this.props.analytics_data_semester){
      await this.props.getStatsDataSemesterres();
    }
   
  }
  latestloadanalytics= async()=>{
    await this.props.clearAnalyticsState();
    await this.props.getStatsDatares();
    await this.props.getStatsDataSemesterres();
    this.setState({loading_refreshed_data:true});
    localStorage.apicalltime=new Date();
    
  }
   getFormattedTime(d){
    let start = new Date(localStorage.getItem("apicalltime"));
     return start.getFullYear() + "-"+(start.getMonth()+1) +"-"+start.getDate() + ' '+start.toString().split(' ')[4];
  
  }
  opennotificationpanel= async()=>{
   this.setState({opennotification:true})
  }
  closenofificationpanel= async()=>{
    this.setState({opennotification:false})
   }
   handlesemesterChange= (event) => {
   this.setState({semester:event.target.value})
  };
  render() {
   
   
    // const employee = this.props.college_notifications;
    // const filterNames = employee.filter(x => x.name === "check with the input value" );
    // console.log("render")
    // console.log(this.props.college_notifications)
    // console.log(this.state.classroomdata)
    // console.log(this.props);
    // console.log(this.state);
    let user = JSON.parse(localStorage.getItem("user"));
    const current = moment();
    if (this.props.loading) {
      return <Dashboardskeletonloader />;
    }
    if (this.props.error_loading_dashboardData) {
      return (
        <div>
          Error loading dashboard data. Please try again after some time!!!
        </div>
      );
    }
    let recommended_decks = [];
    if (
      this.props.recommended_decks &&
      this.props.recommended_decks.length > 0
    ) {
      if (this.props.topic_of_the_day) {
        recommended_decks = [...this.props.recommended_decks].splice(0, 2);
      } else {
        recommended_decks = [...this.props.recommended_decks].splice(0, 3);
      }
    }
    let search_results;
    if (
      this.state.search_data.length > 2 &&
      this.state.showSearchResults &&
      this.props.search_data
    ) {
      search_results = this.props.search_data.filter((item, index) => {
        return item.name.includes(this.state.search_data);
      });
    }
    let has_bookmarks = false;
    if (this.props.bookmarks && this.props.bookmarks.length > 0) {
      this.props.bookmarks.map((b) => {
        if (
          b.bookmarked &&
          b.subject_name &&
          Object.keys(b.bookmarked).length != 0
        ) {
          has_bookmarks = true;
        }
      });
    }
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        paritialVisibilityGutter: 60,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        paritialVisibilityGutter: 50,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        paritialVisibilityGutter: 30,
      },
    };
    return (
      <div style={{ overflow: "hidden", width: "100%", justifyContent: "space-between", margin: "0px" }} className="row">
         {/* <div className={this.state.opennotification ? "fadeIn1 notificationpanel":"fadeOut1 notificationpanel" }>
          <AiOutlineCloseCircle onClick={this.closenofificationpanel} />
         </div> */}
        <Layout1 active_page="dashboard"></Layout1>
        <div className="maindiv" style={{ marginLeft: "214px", width: "60%", paddingRight: "10px", paddingLeft: "0px" }}>
          <div style={{ marginLeft: "20px", marginTop: "13px" }} className="row">
            <div
              style={{
                textAlign: "left",
                fontSize: "32px",
                fontWeight: "600",
                color: "black",
              }}
            >
              <div
                style={{
                  color: "#black",
                  fontSize: "25px",
                  fontWeight: "400",
                }}
              >
                Hey,  <span style={{ fontSize: "25px" }}>
                {this.props.student_name || <Skeleton />}
                  <span>
                     👋
                    {/* <img
                    src="/gifs/hello-gif.gif"
                    alt=""
                    style={{
                      height: "41px",
                      width: "60px",
                      marginBottom: "10px",
                    }}
                  /> */}
                  </span>
                </span>
              </div>

            </div>
           

            {this.props.trending_topics &&
              this.props.trending_topics.length != 0 ? (
              <div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    textAlign: "left",
                    color: "#191648",
                  }}
                >
                  Trending Topics
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "30% 30% 30%",
                    gridGap: "4%",
                    margin: "20px 0",
                  }}
                >
                  {this.props.trending_topics.map((topic, index) => {
                    return (
                      <div
                        onClick={() => this.onTTClickHandler(index)}
                        key={index}
                      >
                        <div
                          className={
                            index == 0
                              ? "ttBackgroundImage1"
                              : index == 1
                                ? "ttBackgroundImage2"
                                : "ttBackgroundImage3"
                          }
                        >
                          {/* <img src={`/images/trending_topics_image${index+1}.png`}></img> */}
                        </div>

                        <div
                          className={
                            index == 0
                              ? "orangeTT"
                              : index == 1
                                ? "blueTT"
                                : "redTT"
                          }
                        >
                          {topic.title.length > 40
                            ? topic.title.substr(0, 40) + "..."
                            : topic.title}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "30% 30% 30%",
                    gridGap: "4%",
                    margin: "20px 0 0 0",
                  }}
                >
                  {this.props.trending_topics.map((topic,index) => {
                    return (
                      <div key={index}>
                        <div style={{ textAlign: "left", color: "#191648" }}>
                          {topic.title}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "30% 30% 30%",
                    gridGap: "4%",
                    margin: "0 0 20px 0",
                  }}
                >
                  {this.props.trending_topics.map((topic,index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                        key={index}
                      >
                        <div style={{ margin: "0 10px" }}>
                          <img
                            width="20px"
                            src="/images/bookmark_white_back.png"
                          ></img>
                        </div>
                        <div style={{ margin: "0 10px" }}>
                          <img src="/images/Info.svg"></img>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {this.props.question_of_the_day ? (
              <div className="qod-section">
                <div className="qod-section-inner-container">
                  <div
                    style={{
                      display:"flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: '15px',
                    }}
                  >
                    <div
                      style={{
                        fontFamily: 'Axiforma, sans-serif',
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      Question of the day
                    </div>
                  </div>

                    <div
                      id="qod-main-content-box"
                      className="qod-box-2 qod-modal-size-animation"
                      style={{
                        padding: "0px",
                        border: this.props.question_of_the_day.personalized === 1 ? `double 2px transparent` : 'none',
                        borderRadius: "10px",
                        backgroundImage: this.props.question_of_the_day.personalized === 1 ? `linear-gradient(white, white), 
                                          linear-gradient(108.24deg, #3CBBD6 1.15%, #4035B8 52.76%, #38AB26 99.27%)` : 'none',
                        backgroundOrigin: 'border-box',
                        backgroundClip: 'padding-box, border-box',
                        boxShadow: "1px 2px 10px 0px #0000001A",
                        position: "relative",
                        overflowY: this.state.qodOverflow ? 'hidden' : '',
                        // border: "2px solid transparent",
                        // borderImage: this.props.question_of_the_day.personalized === 0 ? "linear-gradient(108.24deg, #3CBBD6 1.15%, #4035B8 52.76%, #38AB26 99.27%)" : "none",
                        // borderImageSlice: this.props.question_of_the_day.personalized === 0 ? 1 : 0,
                      }}
                    >
                      {this.props.question_of_the_day?.personalized === 1 ? 
                        <div
                          style={{
                            position: "absolute",
                            top : "-10px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            height: "20px",
                            width: "100px",
                            fontFamily: 'Axiforma, sans-serif',
                            fontSize: "12px",
                            fontWeight: '300',
                            lineHeight: "20px",
                            border: "none",
                            borderRadius: "3px",
                            color: "#fff",
                            textAlign: "center",
                            zIndex: '999',
                            background: 'linear-gradient(108.24deg, #3CBBD6 1.15%, #4035B8 52.76%, #38AB26 99.27%), linear-gradient(0deg, #D9D9D9, #D9D9D9)',
                          }}
                        >
                          Personalized
                        </div>
                      : null}
                      {this.state.show_qod_menu ? 
                        <div className="qod-more-menu" ref={(ref) => (this.menuRef = ref)}>
                          <div
                            className="qod-menu-item"
                            style={{fontFamily: 'Axiforma, sans-serif'}}
                            onClick={this.qodbookmarkItem}
                          >
                            {this.props.question_of_the_day.is_bookmarked ? 'Bookmarked' : 'Bookmark'}
                          </div>
                          <div
                            className="qod-menu-item"
                            style={{fontFamily: 'Axiforma, sans-serif'}}
                            onClick={() =>
                              this.reportItem(
                                "question_of_the_day",
                                this.props.question_of_the_day.id
                              )
                            }
                          >
                            Report
                          </div>
                        </div>
                      :null}
                      {/* {this.state.show_qod_notification ?  */}
                        <div
                          // id = "qod-notification"
                          // className={` qod-notification ${this.state.qod_notification ? `notif-visible` : `notif-invisible`}`}
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "50%",
                            // transform: `translateX(-50%) scaleY(${this.state.show_qod_notification ? 1 : 0}) translateY(0)`,
                            transform: `translateX(-50%) scaleY(${this.state.show_qod_notification ? 1 : 0})`,
                            // display: this.state.show_qod_notification ? '' : 'none',
                            transformOrigin: 'top',
                            padding: "10px 15px",
                            height: "auto",
                            width: "75%",
                            fontFamily: 'Axiforma, sans-serif',
                            zIndex: "-1",
                            fontSize: "12px",
                            fontWeight: '300',
                            lineHeight: "20px",
                            border: "none",
                            borderRadius: "0px 0px 10px 10px",
                            textAlign: "center",
                            background: "#fff",
                            boxShadow: '1px 2px 10px 0px rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.3s cubic-bezier(0.65, 0, 0.35, 1)',
                          }}
                        >
                          {!this.props.question_of_the_day.is_answered && !this.props.question_of_the_day.personalized ?
                            <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-evenly'}}>
                              <div style={{fontSize: '10px', marginTop: '5px'}}>
                                Attempt {this.props.question_of_the_day?.pending_for_personlization} more QODs to get personalized 
                                <div style={{width: '100%', height: '5px', background: '#D9D9D9', borderRadius: '53px', overflow: 'hidden'}}>
                                  <div style={{width: this.state.qod_completed_percentage_before_personalization, background: 'linear-gradient(90deg, #F96140 1.23%, #F4B73C 34.12%, #F3D83B 83.96%, #38AB26 99.89%)', borderRadius: '53px', height: '5px', color: 'red'}}>0</div>
                                </div>
                              </div>
                              <div style={{fontSize: '10px', marginTop: '5px'}}>
                                {this.props.question_of_the_day?.total_attempted}/{this.state.total_for_personalization}
                              </div>
                            </div>
                          : 
                          this.props.question_of_the_day.is_answered && !this.props.question_of_the_day.personalized ?
                            <div>
                              <div style={{fontSize: '12px', fontFamily: 'Axiforma, sans-serif', fontWeight: '300'}}>
                                <span style={{color: 'rgb(95,79,202)', fontSize: '12px', fontWeight: '500', fontFamily: 'Axiforma'}}>Good job!</span>
                                  &nbsp;you have completed&nbsp;
                                <span style={{fontSize: '12px', fontWeight: '500', fontFamily: 'Axiforma'}}>{this.props.question_of_the_day.total_attempted} QODs</span>
                              </div> 
                              <div style={{fontSize: '12px', fontFamily: 'Axiforma, sans-serif', fontWeight: '300'}}>
                                just do&nbsp;
                                <span style={{fontSize: '12px', fontWeight: '500', fontFamily: 'Axiforma'}}>{this.props.question_of_the_day.pending_for_personlization} QODs</span>
                                &nbsp;to get personalized questions
                              </div>
                            </div>
                          : 
                          this.props.question_of_the_day.personalized ?
                            <span style={{fontSize: '12px', fontWeight: '300', fontFamily: 'Axiforma, sans-serif'}}>
                              Attempted &nbsp;
                              <span style={{fontSize: '12px', fontWeight: '600', fontFamily: 'Axiforma, sans-serif'}}>{this.props.question_of_the_day.total_correct}</span>
                              &nbsp; correct answer QODs out of &nbsp; 
                              <span style={{fontSize: '12px', fontWeight: '600', fontFamily: 'Axiforma, sans-serif'}}>{this.props.question_of_the_day.total_attempted}</span>
                            </span>
                          : null}
                        </div>
                      {/* : null} */}
                      <div
                        onClick={this.handleQodMenu}
                        style={{
                          position: 'absolute',
                          top: '6px',
                          right: '2px',
                          cursor: 'pointer',
                        }}
                      >
                        <img 
                          width="20px"
                          src="/images/three_vertical_dots.svg"
                          alt="menu"
                        ></img>
                      </div>
                      {!this.props.question_of_the_day.is_answered && !this.state.open_qod ?
                        <div style={{padding: '0px 15px', paddingTop: '15px', overflowX: 'hidden', height: 'auto', zIndex: '30'}} className="qod-inside-box">
                        <ThreeLineText text={this.props.question_of_the_day?.name} math_type={this.props.question_of_the_day?.name.includes("$") ? 1 : 0} font_family="Axiforma"/>
                        <button
                          style={{
                            margin: "20px 0px 10px 0px",
                            width: '100%',
                            height: '40px',
                            border: 'none',
                            borderRadius: '6px',
                            fontSize: '13px',
                            fontFamily: 'Axiforma, sans-serif',
                            fontWeight: '300',
                            alignItems: 'center',
                            padding: 'auto',
                            cursor: 'pointer',
                            color: '#fff',
                            background: 'linear-gradient(90deg, rgba(56,165,159,1) 0%, rgba(59,166,166,1) 26%, rgba(70,159,201,1) 48%, rgba(83,151,211,1) 64%, rgba(90,163,188,1) 85%, rgba(107,134,229,1) 100%)',
                          }}
                          onClick={this.handleOpenQOD}
                        >
                          Show options
                        </button>
                        {this.state.show_qod_up_arrow ?
                        <div onClick={this.openQodNotification} style={{marginTop: '3px', marginBottom: '6px'}}>
                          <img 
                            width="15px"
                            src="/images/up_arrow_head.svg"
                            alt="up arrow"
                            style={{margin: 'auto', cursor: 'pointer'}}
                          ></img>
                        </div> 
                        : null}
                        {this.state.show_qod_down_arrow ?
                        <div onClick={this.openQodNotification} style={{marginTop: '3px', marginBottom: '6px'}}>
                          <img 
                            width="15px"
                            src="/images/down_arrow_head.svg"
                            alt="down arrow"
                            style={{margin: 'auto', cursor: 'pointer'}}
                          ></img>
                        </div> 
                        : !this.state.show_qod_up_arrow ? <div style={{height: '21px'}}></div> : null}
                        </div>
                      : this.state.open_qod && !this.state.show_qod_answered_view
                      ?
                      <div style={{padding: "0px 15px", paddingTop: '15px', overflowX: 'hidden', height: 'auto', zIndex: '30'}} className="qod-inside-box">
                        {this.props.question_of_the_day?.question_image ? 
                          <div className="row">
                            <div className="col-12">
                              <Equation5
                                math_type={this.props.question_of_the_day.name.includes("$") ? 1 : 0}
                                equation={this.props.question_of_the_day.name}
                                font_family="Axiforma"
                              />
                            </div>
                            <div onMouseEnter={() => this.setState({hoveredOption: -1})} onMouseLeave={() => this.setState({hoveredOption: 0})} style={{width: '200px', height: '100px', margin: '5px auto', background: '#DFE3E8', borderRadius: '10px', padding: '0px', position: 'relative'}}>
                              <img
                                src={
                                  this.props.question_of_the_day.question_image
                                }
                                alt="question"
                                style={{ width: "auto", height: "100%", cursor: "pointer", objectFit: 'contain', margin: 'auto' }}
                              />
                              <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === -1 ? 'block' : 'none'}} onClick={() => this.onImageClick(this.props.question_of_the_day.question_image)}>
                                <img src={`/images/expand_icon.svg`} width="20px" alt="Expand"/>
                              </div>
                            </div>
                          </div>
                        : 
                          <div style={{marginBottom: '15px'}}>
                          <Equation5
                            math_type={this.props.question_of_the_day.name.includes("$") ? 1 : 0}
                            equation={this.props.question_of_the_day.name}
                            font_family="Axiforma"
                          />
                          </div>
                        }
                        {this.props.question_of_the_day.options[0].option1_image 
                          || this.props.question_of_the_day.options[0].option2_image 
                          || this.props.question_of_the_day.options[0].option3_image 
                          || this.props.question_of_the_day.options[0].option4_image 
                          || this.props.question_of_the_day.options[0].option5_image ? (
                          <div>
                            {this.state.image_aspect_ratio === 'wide' ?
                              <div className="row" style={{ width: "100%", margin: '0px', marginBottom: '5px' }}>
                                {this.props.question_of_the_day.options[0].option1_image ? (
                                <div className="col-12" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                      <div 
                                        onClick={(event) => this.onQuestionOfTheDayOptionSelected(event, 0)} 
                                        onMouseEnter={() => this.setState({hoveredOption: 1})} 
                                        onMouseLeave={() => this.setState({hoveredOption: 0})}
                                        style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '100px', width: '470px', position: "relative", border: this.state.selected_qod_option === 1 ? '2px solid #919eab' : this.state.hoveredOption === 1 ? '2px solid #C4CDD5' : 'none', borderRadius: '5px'}}
                                      >
                                        <div 
                                          style={{
                                            width: '30px',
                                            height: '100%', 
                                            display: 'flex', 
                                            justifyContent: 'center', 
                                            alignItems: 'center', 
                                            background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 1 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 1 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                            fontSize: '13px',
                                            fontFamily: "Axiforma, sans-serif", 
                                            fontWeight: '300', 
                                            color: this.props.question_of_the_day.answer === 1 || this.props.question_of_the_day.selected_answer === 1 ? '#fff' : '#919eab', 
                                            // marginRight: '5px', 
                                            borderRadius: '5px 0px 0px 5px',
                                          }}>
                                          a
                                        </div>
                                        <div style={{height: '100%', width: '440px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                          <img
                                            src={
                                              this.props.question_of_the_day.options[0].option1_image
                                              // 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3'
                                            }
                                            alt="option1"
                                            style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                          />
                                          <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === 1 ? 'block' : 'none'}} onClick={() => this.onImageClick(this.props.question_of_the_day.options[0].option1_image)}>
                                            <img src={`/images/expand_icon.svg`} width="20px" alt="Expand"/>
                                          </div>
                                          
                                        </div>
                                      </div>
                                  </div>
                                ) : null}
                                {this.props.question_of_the_day.options[0].option2_image ? (
                                  <div className="col-12" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                        <div 
                                        onClick={(event) => this.onQuestionOfTheDayOptionSelected(event, 1)} 
                                        onMouseEnter={() => this.setState({hoveredOption: 2})} 
                                        onMouseLeave={() => this.setState({hoveredOption: 0})}
                                        style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '100px', width: '470px', position: "relative", border: this.state.selected_qod_option === 2 ? '2px solid #919eab': this.state.hoveredOption === 2 ? '2px solid #C4CDD5' : 'none', borderRadius: '5px'}}
                                        >
                                          <div 
                                            style={{
                                              width: '30px',
                                              height: '100%', 
                                              display: 'flex', 
                                              justifyContent: 'center', 
                                              alignItems: 'center', 
                                              background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 2 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 2 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                              fontSize: '13px',
                                              fontFamily: "Axiforma, sans-serif", 
                                              fontWeight: '300', 
                                              color: this.props.question_of_the_day.answer === 2 || this.props.question_of_the_day.selected_answer === 2 ? '#fff' : '#919eab', 
                                              // marginRight: '5px', 
                                              borderRadius: '5px 0px 0px 5px'
                                            }}>
                                            b
                                          </div>
                                          <div style={{height: '100%', width: '440px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                            <img
                                              src={
                                                this.props.question_of_the_day.options[0].option2_image
                                                // 'https://images.pexels.com/photos/255419/pexels-photo-255419.jpeg?cs=srgb&dl=pexels-pixabay-255419.jpg&fm=jpg'
                                              }
                                              alt="option2"
                                              style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                            />
                                            <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === 2 ? 'block' : 'none'}} onClick={() => this.onImageClick(this.props.question_of_the_day.options[0].option2_image)}>
                                            <img src={`/images/expand_icon.svg`} width="20px" alt="Expand"/>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                ) : null}
                                {this.props.question_of_the_day.options[0].option3_image ? (
                                <div className="col-12" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                      <div 
                                      onClick={(event) => this.onQuestionOfTheDayOptionSelected(event, 2)} 
                                      onMouseEnter={() => this.setState({hoveredOption: 3})} 
                                      onMouseLeave={() => this.setState({hoveredOption: 0})}
                                      style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '100px', width: '470px', position: "relative", border: this.state.selected_qod_option === 3 ? '2px solid #919eab': this.state.hoveredOption === 3 ? '2px solid #C4CDD5' : 'none', borderRadius: '5px'}}
                                      >
                                        <div 
                                          style={{
                                            width: '30px',
                                            height: '100%', 
                                            display: 'flex', 
                                            justifyContent: 'center', 
                                            alignItems: 'center', 
                                            background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 3 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 3 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                            fontSize: '13px',
                                            fontFamily: "Axiforma, sans-serif", 
                                            fontWeight: '300', 
                                            color: this.props.question_of_the_day.answer === 3 || this.props.question_of_the_day.selected_answer === 3 ? '#fff' : '#919eab', 
                                            // marginRight: '5px', 
                                            borderRadius: '5px 0px 0px 5px'
                                          }}>
                                          c
                                        </div>
                                        <div style={{height: '100%', width: '440px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                          <img
                                            src={
                                              this.props.question_of_the_day.options[0].option3_image
                                              // 'https://images.pexels.com/photos/255419/pexels-photo-255419.jpeg?cs=srgb&dl=pexels-pixabay-255419.jpg&fm=jpg'
                                            }
                                            alt="option3"
                                            style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                          />
                                          <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === 3 ? 'block' : 'none'}} onClick={() => this.onImageClick(this.props.question_of_the_day.options[0].option3_image)}>
                                            <img src={`/images/expand_icon.svg`} width="20px" alt="Expand"/>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                ) : null}
                                {this.props.question_of_the_day.options[0].option4_image ? (
                                <div className="col-12" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                      <div
                                        onClick={(event) => this.onQuestionOfTheDayOptionSelected(event, 3)} 
                                        onMouseEnter={() => this.setState({hoveredOption: 4})} 
                                        onMouseLeave={() => this.setState({hoveredOption: 0})}
                                        style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '100px', width: '470px', position: "relative", border: this.state.selected_qod_option === 4 ? '2px solid #919eab': this.state.hoveredOption === 4 ? '2px solid #C4CDD5' : 'none', borderRadius: '5px'}}
                                      >
                                        <div 
                                          style={{
                                            width: '30px',
                                            height: '100%', 
                                            display: 'flex', 
                                            justifyContent: 'center', 
                                            alignItems: 'center', 
                                            background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 4 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 4 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                            fontSize: '13px',
                                            fontFamily: "Axiforma, sans-serif", 
                                            fontWeight: '300', 
                                            color: this.props.question_of_the_day.answer === 4 || this.props.question_of_the_day.selected_answer === 4 ? '#fff' : '#919eab', 
                                            // marginRight: '5px', 
                                            borderRadius: '5px 0px 0px 5px'
                                          }}>
                                          d
                                        </div>
                                        <div style={{height: '100%', width: '440px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                          <img
                                            src={
                                              this.props.question_of_the_day.options[0].option4_image
                                              // 'https://images.pexels.com/photos/1400249/pexels-photo-1400249.jpeg?cs=srgb&dl=pexels-mihai-vlasceanu-1400249.jpg&fm=jpg'
                                            }
                                            alt="option4"
                                            style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                          />
                                          <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption ? 'block' : 'none'}} onClick={() => this.onImageClick(this.props.question_of_the_day.options[0].option4_image)}>
                                            <img src={`/images/expand_icon.svg`} width="20px" alt="Expand"/>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                ) : null}
                                {this.props.question_of_the_day.options[0].option5_image ? (
                                <div className="col-12" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                      <div 
                                      onClick={(event) => this.onQuestionOfTheDayOptionSelected(event, 4)} 
                                      onMouseEnter={() => this.setState({hoveredOption: 5})} 
                                      onMouseLeave={() => this.setState({hoveredOption: 0})}
                                        style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '100px', width: '470px', position: "relative", border: this.state.selected_qod_option === 5 ? '2px solid #919eab': this.state.hoveredOption === 5 ? '2px solid #C4CDD5' : 'none', borderRadius: '5px'}}
                                      >
                                        <div 
                                          style={{
                                            width: '30px',
                                            height: '100%', 
                                            display: 'flex', 
                                            justifyContent: 'center', 
                                            alignItems: 'center', 
                                            background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 5 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 5 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                            fontSize: '13px',
                                            fontFamily: "Axiforma, sans-serif", 
                                            fontWeight: '300', 
                                            color: this.props.question_of_the_day.answer === 5 || this.props.question_of_the_day.selected_answer === 5 ? '#fff' : '#919eab', 
                                            // marginRight: '5px', 
                                            borderRadius: '5px 0px 0px 5px'
                                          }}>
                                          e
                                        </div>
                                        <div style={{height: '100%', width: '440px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                          <img
                                            src={this.props.question_of_the_day.options[0].option5_image}
                                            alt="option5"
                                            style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                          />
                                          <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === 5 ? 'block' : 'none'}} onClick={() => this.onImageClick(this.props.question_of_the_day.options[0].option5_image)}>
                                            <img src={`/images/expand_icon.svg`} width="20px" alt="Expand"/>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                ) : null}
                              </div>
                            : 
                              <div className="row" style={{ width: "100%", margin: '0px', marginBottom: '5px' }}>
                              {this.props.question_of_the_day.options[0].option1_image ? (
                              <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                <div 
                                  onClick={(event) => this.onQuestionOfTheDayOptionSelected(event, 0)} 
                                  onMouseEnter={() => this.setState({hoveredOption: 1})} 
                                  onMouseLeave={() => this.setState({hoveredOption: 0})}
                                  style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '150px', width: '230px', position: "relative", border: this.state.selected_qod_option === 1 ? '2px solid #919eab': this.state.hoveredOption === 1 ? '2px solid #C4CDD5' : 'none', borderRadius: '5px', marginRight: '5px'}}
                                >
                                      <div 
                                        style={{
                                          width: '30px',
                                          height: '100%', 
                                          display: 'flex', 
                                          justifyContent: 'center', 
                                          alignItems: 'center', 
                                          background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 1 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 1 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                          fontSize: '13px',
                                          fontFamily: "Axiforma, sans-serif", 
                                          fontWeight: '300', 
                                          color: this.props.question_of_the_day.answer === 1 || this.props.question_of_the_day.selected_answer === 1 ? '#fff' : '#919eab', 
                                          // marginRight: '5px', 
                                          borderRadius: '5px'
                                        }}>
                                        a
                                      </div>
                                      <div style={{height: '100%', width: '200px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                        <img
                                          src={
                                            this.props.question_of_the_day.options[0].option1_image
                                            // 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3'
                                          }
                                          alt="option1"
                                          style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                        />
                                        <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === 1 ? 'block' : 'none'}} onClick={() => this.onImageClick(this.props.question_of_the_day.options[0].option1_image)}>
                                            <img src={`/images/expand_icon.svg`} width="20px" alt="Expand"/>
                                            </div>
                                      </div>
                                    </div>
                              </div>
                              ) : null}
                              {this.props.question_of_the_day.options[0].option2_image ? (
                                <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                      <div 
                                        onClick={(event) => this.onQuestionOfTheDayOptionSelected(event, 1)} 
                                        onMouseEnter={() => this.setState({hoveredOption: 2})} 
                                        onMouseLeave={() => this.setState({hoveredOption: 0})}
                                        style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '150px', width: '230px', position: "relative", border: this.state.selected_qod_option === 2 ? '2px solid #919eab': this.state.hoveredOption === 2 ? '2px solid #C4CDD5' : 'none', borderRadius: '5px', marginLeft: '5px'}}
                                      >
                                        <div 
                                          style={{
                                            width: '30px',
                                            height: '100%', 
                                            display: 'flex', 
                                            justifyContent: 'center', 
                                            alignItems: 'center', 
                                            background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 2 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 2 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                            fontSize: '13px',
                                            fontFamily: "Axiforma, sans-serif", 
                                            fontWeight: '300', 
                                            color: this.props.question_of_the_day.answer === 2 || this.props.question_of_the_day.selected_answer === 2 ? '#fff' : '#919eab', 
                                            // marginRight: '5px', 
                                            borderRadius: '5px'
                                          }}>
                                          b
                                        </div>
                                        <div style={{height: '100%', width: '200px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                          <img
                                            src={
                                              this.props.question_of_the_day.options[0].option2_image
                                              // 'https://images.pexels.com/photos/255419/pexels-photo-255419.jpeg?cs=srgb&dl=pexels-pixabay-255419.jpg&fm=jpg'
                                            }
                                            alt="option2"
                                            style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                          />
                                          <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === 2 ? 'block' : 'none'}} onClick={() => this.onImageClick(this.props.question_of_the_day.options[0].option2_image)}>
                                            <img src={`/images/expand_icon.svg`} width="20px" alt="Expand"/>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                              ) : null}
                              {this.props.question_of_the_day.options[0].option3_image ? (
                              <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                    <div 
                                      onClick={(event) => this.onQuestionOfTheDayOptionSelected(event, 2)} 
                                      onMouseEnter={() => this.setState({hoveredOption: 3})} 
                                      onMouseLeave={() => this.setState({hoveredOption: 0})}
                                      style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '150px', width: '230px', position: "relative", border: this.state.selected_qod_option === 3 ? '2px solid #919eab': this.state.hoveredOption === 3 ? '2px solid #C4CDD5' : 'none', borderRadius: '5px', marginRight: '5px'}}
                                    >
                                      <div 
                                        style={{
                                          width: '30px',
                                          height: '100%', 
                                          display: 'flex', 
                                          justifyContent: 'center', 
                                          alignItems: 'center', 
                                          background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 3 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 3 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                          fontSize: '13px',
                                          fontFamily: "Axiforma, sans-serif", 
                                          fontWeight: '300', 
                                          color: this.props.question_of_the_day.answer === 3 || this.props.question_of_the_day.selected_answer === 3 ? '#fff' : '#919eab', 
                                          // marginRight: '5px', 
                                          borderRadius: '5px'
                                        }}>
                                        c
                                      </div>
                                      <div style={{height: '100%', width: '200px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                        <img
                                          src={
                                            this.props.question_of_the_day.options[0].option3_image
                                            // 'https://images.pexels.com/photos/255419/pexels-photo-255419.jpeg?cs=srgb&dl=pexels-pixabay-255419.jpg&fm=jpg'
                                          }
                                          alt="option3"
                                          style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                        />
                                        <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === 3 ? 'block' : 'none'}} onClick={() => this.onImageClick(this.props.question_of_the_day.options[0].option3_image)}>
                                            <img src={`/images/expand_icon.svg`} width="20px" alt="Expand"/>
                                            </div>
                                      </div>
                                    </div>
                                </div>
                              ) : null}
                              {this.props.question_of_the_day.options[0].option4_image ? (
                              <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                    <div 
                                      onClick={(event) => this.onQuestionOfTheDayOptionSelected(event, 3)} 
                                      onMouseEnter={() => this.setState({hoveredOption: 4})} 
                                      onMouseLeave={() => this.setState({hoveredOption: 0})}
                                      style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '150px', width: '230px', position: "relative", border: this.state.selected_qod_option === 4 ? '2px solid #919eab': this.state.hoveredOption === 4 ? '2px solid #C4CDD5' : 'none', borderRadius: '5px', marginLeft: '5px'}}
                                    >
                                      <div 
                                        style={{
                                          width: '30px',
                                          height: '100%', 
                                          display: 'flex', 
                                          justifyContent: 'center', 
                                          alignItems: 'center', 
                                          background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 4 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 4 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                          fontSize: '13px',
                                          fontFamily: "Axiforma, sans-serif", 
                                          fontWeight: '300', 
                                          color: this.props.question_of_the_day.answer === 4 || this.props.question_of_the_day.selected_answer === 4 ? '#fff' : '#919eab', 
                                          // marginRight: '5px', 
                                          borderRadius: '5px'
                                        }}>
                                        d
                                      </div>
                                      <div style={{height: '100%', width: '200px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                        <img
                                          src={
                                            this.props.question_of_the_day.options[0].option4_image
                                            // 'https://images.pexels.com/photos/1400249/pexels-photo-1400249.jpeg?cs=srgb&dl=pexels-mihai-vlasceanu-1400249.jpg&fm=jpg'
                                          }
                                          alt="option4"
                                          style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                        />
                                        <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === 4 ? 'block' : 'none'}} onClick={() => this.onImageClick(this.props.question_of_the_day.options[0].option4_image)}>
                                            <img src={`/images/expand_icon.svg`} width="20px" alt="Expand"/>
                                            </div>
                                      </div>
                                    </div>
                                </div>
                              ) : null}
                              {this.props.question_of_the_day.options[0].option5_image ? (
                              <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                    <div 
                                      onClick={(event) => this.onQuestionOfTheDayOptionSelected(event, 4)} 
                                      onMouseEnter={() => this.setState({hoveredOption: 5})} 
                                      onMouseLeave={() => this.setState({hoveredOption: 0})}
                                      style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '150px', width: '230px', position: "relative", border: this.state.selected_qod_option === 5 ? '2px solid #919eab': this.state.hoveredOption === 5 ? '2px solid #C4CDD5' : 'none', borderRadius: '5px', marginRight: '5px'}}
                                    >
                                      <div 
                                        style={{
                                          width: '30px',
                                          height: '100%', 
                                          display: 'flex', 
                                          justifyContent: 'center', 
                                          alignItems: 'center', 
                                          background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 5 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 5 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                          fontSize: '13px',
                                          fontFamily: "Axiforma, sans-serif", 
                                          fontWeight: '300', 
                                          color: this.props.question_of_the_day.answer === 5 || this.props.question_of_the_day.selected_answer === 5 ? '#fff' : '#919eab', 
                                          // marginRight: '5px', 
                                          borderRadius: '5px'
                                        }}>
                                        e
                                      </div>
                                      <div style={{height: '100%', width: '200px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                        <img
                                          src={this.props.question_of_the_day.options[0].option5_image}
                                          alt="option5"
                                          style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                        />
                                        <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === 5 ? 'block' : 'none'}} onClick={() => this.onImageClick(this.props.question_of_the_day.options[0].option5_image)}>
                                            <img src={`/images/expand_icon.svg`} width="20px" alt="Expand"/>
                                            </div>
                                      </div>
                                    </div>
                                </div>
                              ) : null}
                              </div>
                            }
                          </div>
                          )  : (
                        <div className="row" style={{ width: "100%", margin: '0px', marginBottom: '5px' }}>
                        {this.state.validOptionsWithoutImagesList.map((option_num, index) => {
                            return (
                            <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                              <div 
                                onClick={(event) => this.onQuestionOfTheDayOptionSelected(event, index)} 
                                onMouseEnter={() => this.setState({hoveredOption: option_num})} 
                                onMouseLeave={() => this.setState({hoveredOption: 0})} 
                                style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: '100%', minHeight: '40px', position: "relative", border: this.state.selected_qod_option === option_num ? '2px solid #919eab' : this.state.hoveredOption === option_num ? '2px solid #C4CDD5' : '1px solid #f4f6f8', borderRadius: '5px', marginRight: option_num%2 === 1 ? '5px' : '0px', marginLeft: option_num%2 === 0 ? '5px' : '0px', cursor: 'pointer'}}
                                >
                                  <div 
                                  style={{
                                    width: '30px',
                                    height: '100%', 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === option_num ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === option_num && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                    fontSize: '13px',
                                    fontFamily: "Axiforma, sans-serif", 
                                    fontWeight: '300', 
                                    color: this.props.question_of_the_day.answer === option_num || this.props.question_of_the_day.selected_answer === option_num ? '#fff' : '#919eab', 
                                    // marginRight: '5px',
                                    borderRadius: '5px 0px 0px 5px'
                                  }}>
                                  {this.state.qod_options_to_letters_mapping[option_num]}
                                  </div>
                                  <Equation5
                                  color="black"
                                  font_size="12px"
                                  font_family="Axiforma"
                                  width="100%"
                                  alignment="center"
                                  math_type={
                                    this.props.question_of_the_day.options[0][`option${option_num}`].includes(
                                      "$"
                                    )
                                      ? 1
                                      : 0
                                  }
                                  equation={
                                    this.props.question_of_the_day.options[0]
                                      [`option${option_num}`]
                                  }
                                  ></Equation5>
                                </div>
                                {this.props.question_of_the_day.is_answered === 1 && this.props.question_of_the_day.correctly_answered !== 1 && this.props.question_of_the_day.selected_answer === option_num ?
                                  <div style={{position: 'absolute', top: '50%', right: '10px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                    <img 
                                      src="/images/wrong_answer_red_square.svg"
                                      alt="wrong"
                                      width="15px"
                                    ></img>
                                  </div>
                                : null}
                                {this.props.question_of_the_day.is_answered === 1 && this.props.question_of_the_day.answer === option_num ?
                                  <div style={{position: 'absolute', top: '50%', right: '10px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                    <img 
                                      src="/images/correct_answer_green_square.svg"
                                      alt="correct"
                                      width="15px"
                                    ></img>
                                  </div>
                                : null}
                            </div>
                            )
                        })}
                        {/* {this.props.question_of_the_day.options[0].option1 ? (
                          <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                              <div 
                                onClick={(event) => this.onQuestionOfTheDayOptionSelected(event, 0)} 
                                onMouseEnter={() => this.setState({hoveredOption: 1})} 
                                onMouseLeave={() => this.setState({hoveredOption: 0})} 
                                style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: '100%', minHeight: '40px', position: "relative", border: this.state.selected_qod_option === 1 ? '2px solid #919eab' : this.state.hoveredOption === 1 ? '2px solid #C4CDD5' : '1px solid #f4f6f8', borderRadius: '5px', marginRight: '5px', cursor: 'pointer'}}
                                >
                                <div 
                                  style={{
                                    width: '30px',
                                    height: '100%', 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 1 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 1 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                    fontSize: '13px',
                                    fontFamily: "Axiforma, sans-serif", 
                                    fontWeight: '300', 
                                    color: this.props.question_of_the_day.answer === 1 || this.props.question_of_the_day.selected_answer === 1 ? '#fff' : '#919eab', 
                                    // marginRight: '5px',
                                    borderRadius: '5px 0px 0px 5px'
                                  }}>
                                  a
                                </div>
                                <Equation5
                                  color="black"
                                  font_size="12px"
                                  font_family="Axiforma"
                                  width="100%"
                                  alignment="center"
                                  math_type={
                                    this.props.question_of_the_day.options[0].option1.includes(
                                      "$"
                                    )
                                      ? 1
                                      : 0
                                  }
                                  equation={
                                    this.props.question_of_the_day.options[0]
                                      .option1
                                  }
                                ></Equation5>
                                {this.props.question_of_the_day.is_answered === 1 && this.props.question_of_the_day.correctly_answered !== 1 && this.props.question_of_the_day.selected_answer === 1 ?
                                  <div style={{position: 'absolute', top: '50%', right: '5px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                    <img 
                                      src="/images/wrong_answer_red_square.svg"
                                      alt="wrong"
                                      width="15px"
                                    ></img>
                                  </div>
                                : null}
                                {this.props.question_of_the_day.is_answered === 1 && this.props.question_of_the_day.answer === 1 ?
                                  <div style={{position: 'absolute', top: '50%', right: '5px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                    <img 
                                      src="/images/correct_answer_green_square.svg"
                                      alt="correct"
                                      width="15px"
                                    ></img>
                                  </div>
                                : null}
                              </div>
                            
                          </div>
                        ) : null}
                        {this.props.question_of_the_day.options[0].option2 ? (
                          <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                <div
                                  onClick={(event) =>
                                    this.onQuestionOfTheDayOptionSelected(event, 1)
                                  }
                                  style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: '100%', minHeight: '40px', border: this.state.selected_qod_option === 2 ? '2px solid #919eab' : '1px solid #f4f6f8', borderRadius: '5px', marginLeft: '5px', cursor: 'pointer'}}
                                >
                                  <div 
                                    style={{
                                      width: '30px',
                                      height: '100%',
                                      display: 'flex', 
                                      justifyContent: 'center', 
                                      alignItems: 'center', 
                                      background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 2 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 2 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                      fontSize: '13px', 
                                      fontFamily: "Axiforma, sans-serif",
                                      fontWeight: '300', 
                                      color: this.props.question_of_the_day.answer === 2 || this.props.question_of_the_day.selected_answer === 2 ? '#fff' : '#919eab', 
                                      // marginRight: '5px', 
                                      borderRadius: '5px 0px 0px 5px'
                                    }}>
                                    b
                                  </div>
                                  <Equation5
                                    color="black"
                                    font_family="Axiforma"
                                    font_size="12px"
                                    alignment="center"
                                    width="100%"
                                    math_type={
                                      this.props.question_of_the_day.options[0].option2.includes(
                                        "$"
                                      )
                                        ? 1
                                        : 0
                                    }
                                    equation={
                                      this.props.question_of_the_day.options[0]
                                        .option2
                                    }
                                  ></Equation5>
                                  {this.props.question_of_the_day.is_answered === 1 && this.props.question_of_the_day.correctly_answered !== 1 && this.props.question_of_the_day.selected_answer === 2 ?
                                    <div style={{position: 'absolute', top: '50%', right: '5px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                      <img 
                                        src="/images/wrong_answer_red_square.svg"
                                        alt="wrong"
                                        width="15px"
                                      ></img>
                                    </div>
                                  : null}
                                  {this.props.question_of_the_day.is_answered === 1 && this.props.question_of_the_day.answer === 2 ?
                                    <div style={{position: 'absolute', top: '50%', right: '5px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                      <img 
                                        src="/images/correct_answer_green_square.svg"
                                        alt="correct"
                                        width="15px"
                                      ></img>
                                    </div>
                                  : null}
                                </div>
                            
                          </div>
                        ) : null}
                        {this.props.question_of_the_day.options[0].option3 ? (
                          <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                <div
                                  onClick={(event) =>
                                    this.onQuestionOfTheDayOptionSelected(event, 2)
                                  }
                                  style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: '100%', minheight: '40px', border: this.state.selected_qod_option === 3 ? '2px solid #919eab' : '1px solid #f4f6f8', borderRadius: '5px', marginRight: '5px', cursor: 'pointer'}}
                                >
                                  <div 
                                    style={{
                                      width: '30px',
                                      height: '100%', 
                                      display: 'flex', 
                                      justifyContent: 'center', 
                                      alignItems: 'center', 
                                      background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 3 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 3 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                      fontSize: '13px',
                                      fontFamily: "Axiforma, sans-serif", 
                                      fontWeight: '300', 
                                      color: this.props.question_of_the_day.answer === 3 || this.props.question_of_the_day.selected_answer === 3 ? '#fff': '#919eab', 
                                      // marginRight: '5px', 
                                      border: '1px solid #f4f6f8',
                                      borderRadius: '5px 0px 0px 5px'
                                    }}>
                                    c
                                  </div>
                                  <Equation5
                                    color="black"
                                    font_family="Axiforma"
                                    font_size="12px"
                                    alignment="center"
                                    width="100%"
                                    math_type={
                                      this.props.question_of_the_day.options[0].option3.includes(
                                        "$"
                                      )
                                        ? 1
                                        : 0
                                    }
                                    equation={
                                      this.props.question_of_the_day.options[0]
                                        .option3
                                    }
                                  ></Equation5>
                                  {this.props.question_of_the_day.is_answered === 1 && this.props.question_of_the_day.correctly_answered !== 1 && this.props.question_of_the_day.selected_answer === 3 ?
                                    <div style={{position: 'absolute', top: '50%', right: '5px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                      <img 
                                        src="/images/wrong_answer_red_square.svg"
                                        alt="wrong"
                                        width="15px"
                                      ></img>
                                    </div>
                                  : null}
                                  {this.props.question_of_the_day.is_answered === 1 && this.props.question_of_the_day.answer === 3 ?
                                    <div style={{position: 'absolute', top: '50%', right: '5px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                      <img 
                                        src="/images/correct_answer_green_square.svg"
                                        alt="correct"
                                        width="15px"
                                      ></img>
                                    </div>
                                  : null}
                                </div>
                            
                          </div>
                        ) : null}
                        {this.props.question_of_the_day.options[0].option4 ? (
                          <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                <div
                                  onClick={(event) =>
                                    this.onQuestionOfTheDayOptionSelected(event, 3)
                                  }
                                  style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: '100%', minHeight: '40px', border: this.state.selected_qod_option === 4 ? '2px solid #919eab' : '1px solid #f4f6f8', borderRadius: '5px', marginLeft: '5px', cursor: 'pointer'}}
                                >
                                  <div 
                                    style={{
                                      width: '30px',
                                      height: '100%', 
                                      display: 'flex', 
                                      justifyContent: 'center', 
                                      alignItems: 'center', 
                                      background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 4 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 4 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                      fontSize: '13px',
                                      fontFamily: "Axiforma, sans-serif", 
                                      fontWeight: '300', 
                                      color: this.props.question_of_the_day.answer === 4 || this.props.question_of_the_day.selected_answer === 4 ? '#fff' : '#919eab', 
                                      // marginRight: '5px', 
                                      border: '1px solid #f4f6f8',
                                      borderRadius: '5px 0px 0px 5px'
                                    }}>
                                    d
                                  </div>
                                  <Equation5
                                    color="black"
                                    font_family="Axiforma"
                                    font_size="12px"
                                    alignment="center"
                                    width="100%"
                                    math_type={
                                      this.props.question_of_the_day.options[0].option4.includes(
                                        "$"
                                      )
                                        ? 1
                                        : 0
                                    }
                                    equation={
                                      this.props.question_of_the_day.options[0]
                                        .option4
                                    }
                                  ></Equation5>
                                  {this.props.question_of_the_day.is_answered === 1 && this.props.question_of_the_day.correctly_answered !== 1 && this.props.question_of_the_day.selected_answer === 4 ?
                                    <div style={{position: 'absolute', top: '50%', right: '5px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                      <img 
                                        src="/images/wrong_answer_red_square.svg"
                                        alt="wrong"
                                        width="15px"
                                      ></img>
                                    </div>
                                  : null}
                                  {this.props.question_of_the_day.is_answered === 1 && this.props.question_of_the_day.answer === 4 ?
                                    <div style={{position: 'absolute', top: '50%', right: '5px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                      <img 
                                        src="/images/correct_answer_green_square.svg"
                                        alt="correct"
                                        width="15px"
                                      ></img>
                                    </div>
                                  : null}
                                </div>
                            
                          </div>
                        ) : null}
                        {this.props.question_of_the_day.options[0].option5 ? (
                          <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                <div
                                  onClick={(event) =>
                                    this.onQuestionOfTheDayOptionSelected(event, 4)
                                  }
                                  style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: '100%', minHeight: '40px', border: this.state.selected_qod_option === 5 ? '2px solid #919eab' : '1px solid #f4f6f8', borderRadius: '5px', cursor: 'pointer'}}
                                >
                                  <div 
                                    style={{
                                      width: '30px',
                                      height: '100%', 
                                      display: 'flex', 
                                      justifyContent: 'center', 
                                      alignItems: 'center', 
                                      background: this.props.question_of_the_day.is_answered !== 1 ? 'rgb(244,246,248)' : this.props.question_of_the_day.answer === 5 ? 'rgb(99, 193, 105)' : this.props.question_of_the_day.selected_answer === 5 && this.props.question_of_the_day.correctly_answered === 0 ? 'rgb(232, 70, 61)' : 'rgb(244,246,248)', 
                                      fontSize: '13px', 
                                      fontFamily: "Axiforma, sans-serif",
                                      fontWeight: '300', 
                                      color: this.props.question_of_the_day.answer === 5 || this.props.question_of_the_day.selected_answer === 5 ? '#fff' : '#919eab', 
                                      // marginRight: '5px', 
                                      border: '1px solid #f4f6f8',
                                      borderRadius: '5px 0px 0px 5px'
                                    }}>
                                    e
                                  </div>
                                  <Equation5
                                    color="black"
                                    font_family="Axiforma"
                                    font_size="12px"
                                    alignment="center"
                                    width="100%"
                                    math_type={
                                      this.props.question_of_the_day.options[0].option5.includes(
                                        "$"
                                      )
                                        ? 1
                                        : 0
                                    }
                                    equation={
                                      this.props.question_of_the_day.options[0]
                                        .option5
                                    }
                                  ></Equation5>
                                  {this.props.question_of_the_day.is_answered === 1 && this.props.question_of_the_day.correctly_answered !== 1 && this.props.question_of_the_day.selected_answer === 5 ?
                                    <div style={{position: 'absolute', top: '50%', right: '5px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                      <img 
                                        src="/images/wrong_answer_red_square.svg"
                                        alt="wrong"
                                        width="15px"
                                      ></img>
                                    </div>
                                  : null}
                                  {this.props.question_of_the_day.is_answered === 1 && this.props.question_of_the_day.answer === 5 ?
                                    <div style={{position: 'absolute', top: '50%', right: '5px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                      <img 
                                        src="/images/correct_answer_green_square.svg"
                                        alt="correct"
                                        width="15px"
                                      ></img>
                                    </div>
                                  : null}
                                </div>
                            
                          </div>
                        ) : null} */}
                      </div> 
                      )}
                      <button
                        style={{
                          margin: "5px 0px",
                          width: '100%',
                          height: '40px',
                          border: 'none',
                          borderRadius: '6px',
                          fontSize: '13px',
                          fontFamily: 'Axiforma, sans-serif',
                          fontWeight: '300',
                          alignItems: 'center',
                          padding: 'auto',
                          cursor: 'pointer',
                          color: '#fff',
                          background: 'linear-gradient(90deg, rgba(56,165,159,1) 0%, rgba(59,166,166,1) 26%, rgba(70,159,201,1) 48%, rgba(83,151,211,1) 64%, rgba(90,163,188,1) 85%, rgba(107,134,229,1) 100%)',
                          pointerEvents: this.state.selected_qod_option === 0 || this.state.submitting_qod || this.props.question_of_the_day.is_answered ? 'none' : 'auto',
                        }}
                        onClick={this.onQODSubmit}
                      >
                        {this.state.submitting_qod ?
                        <div className="spinner-border" style={{color: '#fff', height: '20px', width: '20px'}} role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        : 
                        <span>Submit</span>
                        }
                      </button>
                      {this.state.show_qod_up_arrow ?
                      <div onClick={this.openQodNotification} style={{marginTop: '10px', marginBottom: '15px',}}>
                        <img 
                          width="15px"
                          src="/images/up_arrow_head.svg"
                          alt="up arrow"
                          style={{margin: 'auto', cursor: 'pointer'}}
                        ></img>
                      </div> 
                      : null}
                      {this.state.show_qod_down_arrow ?
                      <div onClick={this.openQodNotification} style={{marginTop: '10px', marginBottom: '15px',}}>
                        <img 
                          width="15px"
                          src="/images/down_arrow_head.svg"
                          alt="down arrow"
                          style={{margin: 'auto', cursor: 'pointer'}}
                        ></img>
                      </div> 
                      : null}
                      </div>
                      : null}
                      <div>
                      <Collapse timeout={300} in={this.props.question_of_the_day.is_answered && this.state.show_qod_answered_view && !this.state.viewQODExplanation}>
                      <div>
                      {this.props.question_of_the_day.is_answered ?
                        <div style={{padding: '0px 15px', paddingTop: '15px', overflowX: 'hidden', height: 'auto', zIndex: '30'}} 
                          // className={`qod-inside-box`}
                          className={`qod-inside-box ${this.state.qodExplanationButtonClicked ? 'view-to-top-animation' : 'top-to-view-animation'}`}
                          >
                            {this.props.question_of_the_day.correctly_answered !== 1 
                            ?
                              <div>
                                <img 
                                  src="/images/wrong_answer_parallelogram.svg"
                                  alt="Wrong Answer"
                                  width="70px"
                                  style={{margin: "auto"}}
                                />
                                <p style={{
                                  textAlign: 'center',
                                  marginBottom: '12px',
                                  fontSize: '12px',
                                  fontFamily: "Axiforma, sans-serif",
                                  fontWeight: '300',
                                  lineHeight: '16px',
                                  color: '#FF1439',
                                }}>
                                  Your answer is wrong!
                                </p>
                                {this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.selected_answer}_image`] || this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`] ? 
                                  <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: "5px 0px"
                                  }}>
                                    {this.state.image_aspect_ratio === 'wide' ? 
                                      <div className="row" style={{ width: "100%", margin: '0px', marginBottom: '5px' }}>
                                        <div className="col-12" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                              <div onMouseEnter={() => this.setState({hoveredOption: this.props.question_of_the_day.answer})} onMouseLeave={() => this.setState({hoveredOption: 0})} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '100px', width: '470px', position: "relative",borderRadius: '5px', border: this.state.hoveredOption === this.props.question_of_the_day.answer ? '2px solid #C4CDD5' : 'none'}}>
                                                <div 
                                                  style={{
                                                    width: '30px',
                                                    height: '100%', 
                                                    display: 'flex', 
                                                    justifyContent: 'center', 
                                                    alignItems: 'center', 
                                                    background: 'rgb(99, 193, 105, 0.9)', 
                                                    fontSize: '13px',
                                                    fontFamily: "Axiforma, sans-serif", 
                                                    fontWeight: '300', 
                                                    color: '#fff', 
                                                    // marginRight: '5px', 
                                                    borderRadius: '5px 0px 0px 5px'
                                                  }}>
                                                  {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.answer]}
                                                </div>
                                                <div style={{height: '100%', width: '440px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                                  <img
                                                    src={
                                                      this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`]
                                                      // 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3'
                                                    }
                                                    alt="correct option"
                                                    style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                                  />
                                                  <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === this.props.question_of_the_day.answer ? 'block' : 'none'}} >
                                                  <img src={`/images/expand_icon.svg`} width="20px" alt="Expand" onClick={() => this.onImageClick(this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`])}/>
                                                  </div>
                                                </div>
                                          </div>
                                        </div>
                                        <div className="col-12" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                              <div onMouseEnter={() => this.setState({hoveredOption: this.props.question_of_the_day.selected_answer})} onMouseLeave={() => this.setState({hoveredOption: 0})} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '100px', width: '470px', position: "relative",borderRadius: '5px', border: this.state.hoveredOption === this.props.question_of_the_day.selected_answer ? '2px solid #C4CDD5' : 'none'}}>
                                                <div 
                                                  style={{
                                                    width: '30px',
                                                    height: '100%', 
                                                    display: 'flex', 
                                                    justifyContent: 'center', 
                                                    alignItems: 'center', 
                                                    background: 'rgba(232, 70, 61, 0.9)', 
                                                    fontSize: '13px',
                                                    fontFamily: "Axiforma, sans-serif", 
                                                    fontWeight: '300', 
                                                    color: '#fff', 
                                                    // marginRight: '5px', 
                                                    borderRadius: '5px 0px 0px 5px'
                                                  }}>
                                                  {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.selected_answer]}
                                                </div>
                                                <div style={{height: '100%', width: '440px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                                  <img
                                                    src={
                                                      this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.selected_answer}_image`]
                                                      // 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3'
                                                    }
                                                    alt="wrong option"
                                                    style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                                  />
                                                  <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === this.props.question_of_the_day.selected_answer ? 'block' : 'none'}}>
                                                  <img src={`/images/expand_icon.svg`} width="20px" alt="Expand" onClick={() => this.onImageClick(this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.selected_answer}_image`])}/>
                                                  </div>
                                                </div>
                                              </div>
                                          
                                        </div>
                                      </div>
                                    :
                                    <div className="row" style={{ width: "100%", margin: '0px', marginBottom: '5px' }}>
                                      <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                            <div onMouseEnter={() => this.setState({hoveredOption: this.props.question_of_the_day.answer})} onMouseLeave={() => this.setState({hoveredOption: 0})} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '150px', width: '230px', position: "relative", borderRadius: '5px', marginRight: '5px', border: this.state.hoveredOption === this.props.question_of_the_day.answer ? '2px solid #C4CDD5' : 'none'}}>
                                              <div 
                                                style={{
                                                  width: '30px',
                                                  height: '100%', 
                                                  display: 'flex', 
                                                  justifyContent: 'center', 
                                                  alignItems: 'center', 
                                                  background: 'rgb(99, 193, 105, 0.9)' , 
                                                  fontSize: '13px',
                                                  fontFamily: "Axiforma, sans-serif", 
                                                  fontWeight: '300', 
                                                  color: '#fff', 
                                                  // marginRight: '5px', 
                                                  borderRadius: '5px 0px 0px 5px'
                                                }}>
                                                {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.answer]}
                                              </div>
                                              <div style={{height: '100%', width: '200px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                                <img
                                                  src={
                                                    this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`]
                                                    // 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3'
                                                  }
                                                  alt="correct option"
                                                  style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                                />
                                                <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === this.props.question_of_the_day.answer ? 'block' : 'none'}}>
                                                  <img src={`/images/expand_icon.svg`} width="20px" alt="Expand" onClick={() => this.onImageClick(this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`])}/>
                                                  </div>
                                              </div>
                                            </div>
                                        
                                      </div>
                                      <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                            <div onMouseEnter={() => this.setState({hoveredOption: this.props.question_of_the_day.selected_answer})} onMouseLeave={() => this.setState({hoveredOption: 0})} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '150px', width: '230px', position: "relative", borderRadius: '5px', marginLeft: '5px', border: this.state.hoveredOption === this.props.question_of_the_day.selected_answer ? '2px solid #C4CDD5' : 'none'}}>
                                              <div 
                                                style={{
                                                  width: '30px',
                                                  height: '100%', 
                                                  display: 'flex', 
                                                  justifyContent: 'center', 
                                                  alignItems: 'center', 
                                                  background: 'rgba(232, 70, 61, 0.9)' , 
                                                  fontSize: '13px',
                                                  fontFamily: "Axiforma, sans-serif", 
                                                  fontWeight: '300', 
                                                  color: '#fff', 
                                                  // marginRight: '5px', 
                                                  borderRadius: '5px 0px 0px 5px'
                                                }}>
                                                {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.selected_answer]}
                                              </div>
                                              <div style={{height: '100%', width: '200px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                                <img
                                                  src={
                                                    this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.selected_answer}_image`]
                                                    // 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3'
                                                  }
                                                  alt="wrong option"
                                                  style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                                />
                                                <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === this.props.question_of_the_day.selected_answer ? 'block' : 'none'}}>
                                                  <img src={`/images/expand_icon.svg`} width="20px" alt="Expand" onClick={() => this.onImageClick(this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.selected_answer}_image`])}/>
                                                  </div>
                                              </div>
                                            </div>
                                        
                                      </div>
                                    </div>
                                    }
                                  </div>
                                :
                                  <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: "5px 0px"
                                  }}
                                  >
                                    <div style={{margin: '0px 5px', width: '50%', textAlign: 'right'}}>
                                      <span style={{color:'#A62B38', fontSize: '15px', fontFamily: "Axiforma, sans-serif", margin: '10px 0px'}}>option&nbsp;{this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.selected_answer].toUpperCase()}&nbsp;-&nbsp;
                                        <Equation5
                                          color='#A62B38'
                                          display="inline"
                                          font_size="15px"
                                          font_family="Axiforma"
                                          math_type={
                                            this.props.question_of_the_day.options[0][
                                              `option${this.props.question_of_the_day.selected_answer}`
                                            ].includes("$")
                                              ? 1
                                              : 0
                                          }
                                          equation={
                                            this.props.question_of_the_day.options[0][
                                            `option${this.props.question_of_the_day.selected_answer}`
                                            ]
                                          }
                                        ></Equation5>
                                      </span>
                                    </div>
                                    <div style={{margin: '0px 5px', width: '50%', textAlign: 'left'}}>
                                      <span style={{color:'#448B5C', fontSize: '15px', fontFamily: 'Axiforma, sans-serif', margin: '10px 0px'}}>option&nbsp;{this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.answer].toUpperCase()}&nbsp;-&nbsp;
                                          <Equation5
                                            color='#448B5C'
                                            display="inline"
                                            font_size="15px"
                                            font_family="Axiforma"
                                            math_type={
                                              this.props.question_of_the_day.options[0][
                                                `option${this.props.question_of_the_day.answer}`
                                              ].includes("$")
                                                ? 1
                                                : 0
                                            }
                                            equation={
                                              this.props.question_of_the_day.options[0][
                                              `option${this.props.question_of_the_day.answer}`
                                              ]
                                            }
                                          ></Equation5>
                                        </span>
                                    </div>
                                  </div>
                                }
                              </div>
                            : 
                              <div>
                                <img 
                                  src="/images/correct_answer_parallelogram.svg"
                                  alt="Correct Answer"
                                  width="70px"
                                  style={{margin: 'auto'}}
                                />
                                <p style={{
                                  textAlign: 'center',
                                  marginBottom: '10px',
                                  fontSize: '10px',
                                  fontFamily: 'Axiforma, sans-serif',
                                  fontWeight: '500',
                                  lineHeight: '16px',
                                  color: '#61C200',
                                }}>
                                  Your answer is right!
                                </p>
                                {this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.selected_answer}_image`] ? 
                                  <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: "5px 0px"
                                  }}>
                                    {this.state.image_aspect_ratio === 'wide' ? 
                                      <div className="row" style={{ width: "100%", margin: '0px', marginBottom: '5px' }}>
                                        <div className="col-12" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                              <div onMouseEnter={() => this.setState({hoveredOption: this.props.question_of_the_day.answer})} onMouseLeave={() => this.setState({hoveredOption: 0})} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '100px', width: '470px', position: "relative",borderRadius: '5px', border: this.state.hoveredOption === this.props.question_of_the_day.answer ? '2px solid #C4CDD5' : 'none'}}>
                                                <div 
                                                  style={{
                                                    width: '30px',
                                                    height: '100%', 
                                                    display: 'flex', 
                                                    justifyContent: 'center', 
                                                    alignItems: 'center', 
                                                    background: 'rgb(99, 193, 105, 0.9)', 
                                                    fontSize: '13px',
                                                    fontFamily: "Axiforma, sans-serif", 
                                                    fontWeight: '300', 
                                                    color: '#fff', 
                                                    // marginRight: '5px', 
                                                    borderRadius: '5px 0px 0px 5px'
                                                  }}>
                                                  {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.answer]}
                                                </div>
                                                <div style={{height: '100%', width: '440px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                                  <img
                                                    src={
                                                      this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`]
                                                      // 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3'
                                                    }
                                                    alt="correct option"
                                                    style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                                  />
                                                  <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === this.props.question_of_the_day.answer ? 'block' : 'none'}} >
                                                  <img src={`/images/expand_icon.svg`} width="20px" alt="Expand" onClick={() => this.onImageClick(this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`])}/>
                                                  </div>
                                                </div>
                                              </div>
                                          
                                        </div>
                                      </div>
                                    :
                                    <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '10px', }}>
                                            <div onMouseEnter={() => this.setState({hoveredOption: this.props.question_of_the_day.answer})} onMouseLeave={() => this.setState({hoveredOption: 0})} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '150px', width: '230px', position: "relative", borderRadius: '5px', border: this.state.hoveredOption === this.props.question_of_the_day.answer ? '2px solid #C4CDD5' : 'none'}}>
                                              <div 
                                                style={{
                                                  width: '30px',
                                                  height: '100%', 
                                                  display: 'flex', 
                                                  justifyContent: 'center', 
                                                  alignItems: 'center', 
                                                  background: 'rgb(99, 193, 105, 0.9)' , 
                                                  fontSize: '13px',
                                                  fontFamily: "Axiforma, sans-serif", 
                                                  fontWeight: '300', 
                                                  color: '#fff', 
                                                  // marginRight: '5px', 
                                                  borderRadius: '5px 0px 0px 5px'
                                                }}>
                                                {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.answer]}
                                              </div>
                                              <div style={{height: '100%', width: '200px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                                <img
                                                  src={
                                                    this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`]
                                                    // 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3'
                                                  }
                                                  alt="correct option"
                                                  style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                                />
                                                <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === this.props.question_of_the_day.answer ? 'block' : 'none'}}>
                                                  <img src={`/images/expand_icon.svg`} width="20px" alt="Expand" onClick={() => this.onImageClick(this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`])}/>
                                                </div>
                                              </div>
                                            </div>
                                        
                                      
                                    </div>
                                    }
                                  </div>
                                :
                                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                      <span style={{color:'#448B5C', margin: '0px', fontSize: '15px', fontFamily: "Axiforma, sans-serif", fontWeight: '300', textAlign: 'center'}}>option&nbsp;{this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.answer].toUpperCase()}&nbsp;-&nbsp;
                                        <Equation5
                                          color='#448B5C'
                                          display="inline"
                                          font_size="15px"
                                          font_family="Axiforma"
                                          math_type={
                                            this.props.question_of_the_day.options[0][
                                              `option${this.props.question_of_the_day.answer}`
                                            ].includes("$")
                                              ? 1
                                              : 0
                                          }
                                          equation={
                                            this.props.question_of_the_day.options[0][
                                            `option${this.props.question_of_the_day.answer}`
                                            ]
                                          }
                                        ></Equation5>
                                      </span>
                                  </div>
                                }
                              </div>
                            }
                            <div onClick={this.handleViewQODExplanation} style={{display: 'block', cursor: 'pointer', fontSize: '10px', fontFamily: 'Axiforma, sans-serif', fontWeight: '300', textAlign: 'center', marginBottom: this.props.question_of_the_day.correctly_answered === 1 ? "0.87rem" : "0.75rem", color: '#0075FF',textDecoration: 'underline', textDecorationOffset: '3px',}}>
                              View explanation
                            </div>
                            <div style={{
                              fontSize: '12px',
                              marginBottom: '0.94rem',
                              textAlign: 'center',
                              fontFamily: 'Axiforma, sans-serif',
                              fontWeight: '300',
                              position: 'relative'
                            }}>
                              Congrats, you are on <span style={{color: 'rgb(0,117,255)', fontFamily: 'Axiforma, sans-serif', fontWeight: '500', textDecoration: 'underline', cursor: 'pointer'}} onMouseEnter={() => this.setState({hoveredOption: 10})} onMouseLeave={() => this.setState({hoveredOption: 0})}>{this.props.question_of_the_day.leaderboard_rank} position</span>
                              <span style={{background: '#DFE3E8', padding: '5px 10px', borderRadius: '5px', position: 'absolute', top: '-35px', right: '25px', display: this.state.hoveredOption === 10 ? 'block': 'none'}}>Check the mobile app to view full leaderboard</span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <span 
                                style={{
                                  fontSize: '12px',
                                  fontWeight: '300',
                                  fontFamily: 'Axiforma, sans-serif',
                                  lineHeight: 'normal',
                                }}
                              >
                                Show more questions of this kind?
                              </span>
                              <div 
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-evenly'
                                }}
                              >
                                {this.props.question_of_the_day?.show_more_of_this_kind === 1 ?
                                <img 
                                  src="/images/thumbs_up_black.svg"
                                  alt="Interested"
                                  width="20px"
                                  style={{margin: "0px 10px"}}
                                ></img>
                                :
                                <img 
                                  src="/images/thumbs_up.svg"
                                  alt="Interested"
                                  width="20px"
                                  style={{margin: "0px 10px"}}
                                  onClick={() => this.handleQodInterest(1)}
                                ></img>
                                }
                                {this.props.question_of_the_day?.show_more_of_this_kind === -1 ?
                                <img 
                                  src="/images/thumbs_down_black.svg"
                                  alt="Interested"
                                  width="20px"
                                  style={{margin: "0px 10px"}}
                                ></img>
                                :
                                <img 
                                  src="/images/thumbs_down.svg"
                                  alt="Interested"
                                  width="20px"
                                  style={{margin: "0px 10px"}}
                                  onClick={() => this.handleQodInterest(-1)}
                                ></img>
                                }
                              </div>
                            </div>
                            {this.state.show_qod_up_arrow ?
                              <div onClick={this.openQodNotification} style={{marginTop: '15px', marginBottom: '15px',}}>
                                <img 
                                  width="15px"
                                  src="/images/up_arrow_head.svg"
                                  alt="up arrow"
                                  style={{margin: 'auto', cursor: 'pointer'}}
                                ></img>
                              </div> 
                              : null}
                              {this.state.show_qod_down_arrow ?
                              <div onClick={this.openQodNotification} style={{marginTop: '15px', marginBottom: '15px', }}>
                                <img 
                                  width="15px"
                                  src="/images/down_arrow_head.svg"
                                  alt="down arrow"
                                  style={{margin: 'auto', cursor: 'pointer'}}
                                ></img>
                              </div> 
                              : !this.state.show_qod_up_arrow ? <div style={{height: '40px'}}></div> : null}
                        </div>
                      :null}
                      </div>
                      </Collapse>  
                      </div>
                      <div>         
                      <Collapse timeout={300} in={this.props.question_of_the_day.is_answered && this.state.viewQODExplanation}> 
                      <div>     
                      {this.props.question_of_the_day.is_answered ?
                        <div style={{padding: '0px 15px', paddingTop: '15px', overflowX: 'hidden', height: 'auto', zIndex: '30'}} 
                          // className={`qod-inside-box`}
                          className={`qod-inside-box ${this.state.qodExplanationButtonClicked ? 'bottom-to-view-animation' : 'view-to-bottom-animation'}`}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              marginBottom: '10px',
                            }}
                          >
                            <img
                            src="/images/up_arrow.svg"
                            alt="Back"
                              width="20px"
                              onClick={this.handleCloseQODExplanation}
                              style={{
                                cursor: 'pointer'
                              }}
                            ></img>
                            <div
                              style={{
                                fontSize: '15px',
                                fontFamily: 'Axiforma, sans-serif',
                                fontWeight: '500',
                                marginLeft: '10px',
                              }}
                            >
                              Explanation
                            </div>
                          </div>
                          {this.props.question_of_the_day?.question_image ? 
                            <div className="row">
                            <div className="col-12">
                              <Equation5
                                math_type={this.props.question_of_the_day.name.includes("$") ? 1 : 0}
                                equation={this.props.question_of_the_day.name}
                                font_family="Axiforma"
                              />
                            </div>
                            <div onMouseEnter={() => this.setState({hoveredOption: -1})} onMouseLeave={() => this.setState({hoveredOption: 0})} style={{width: '200px', height: '100px', margin: '5px auto', background: '#DFE3E8', borderRadius: '10px', padding: '0px', position: 'relative'}}>
                              <img
                                src={this.props.question_of_the_day.question_image}
                                alt="question"
                                style={{ width: "auto", height: "100%", cursor: "pointer", objectFit: 'contain', margin: 'auto' }}
                              />
                              <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === -1 ? 'block' : 'none'}} onClick={() => this.onImageClick(this.props.question_of_the_day.question_image)}>
                                <img src={`/images/expand_icon.svg`} width="20px" alt="Expand"/>
                              </div>
                            </div>
                            </div>
                            : 
                              <Equation5
                                math_type={this.props.question_of_the_day.name.includes("$") ? 1 : 0}
                                equation={this.props.question_of_the_day.name}
                                font_family="Axiforma"
                              />
                            }
                            {this.props.question_of_the_day.correctly_answered === 1 ?
                              <div>
                                {this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.selected_answer}_image`] ? 
                                  <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: "5px 0px"
                                  }}>
                                    {this.state.image_aspect_ratio === 'wide' ? 
                                      <div className="row" style={{ width: "100%", margin: '0px', marginBottom: '5px' }}>
                                        <div className="col-12" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                              <div onMouseEnter={() => this.setState({hoveredOption: this.props.question_of_the_day.selected_answer})} onMouseLeave={() => this.setState({hoveredOption: 0})} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '100px', width: '470px', position: "relative",borderRadius: '5px', border: this.state.hoveredOption === this.props.question_of_the_day.answer ? '2px solid #C4CDD5' : 'none'}}>
                                                <div 
                                                  style={{
                                                    width: '30px',
                                                    height: '100%', 
                                                    display: 'flex', 
                                                    justifyContent: 'center', 
                                                    alignItems: 'center', 
                                                    background: 'rgb(99, 193, 105, 0.9)', 
                                                    fontSize: '13px',
                                                    fontFamily: "Axiforma, sans-serif", 
                                                    fontWeight: '300', 
                                                    color: '#fff', 
                                                    // marginRight: '5px', 
                                                    borderRadius: '5px 0px 0px 5px'
                                                  }}>
                                                  {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.answer]}
                                                </div>
                                                <div style={{height: '100%', width: '440px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                                  <img
                                                    src={
                                                      this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`]
                                                      // 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3'
                                                    }
                                                    alt="correct option"
                                                    style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                                  />
                                                  <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === this.props.question_of_the_day.selected_answer ? 'block' : 'none'}} >
                                                    <img src={`/images/expand_icon.svg`} width="20px" alt="Expand" onClick={() => this.onImageClick(this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`])}/>
                                                  </div>
                                                </div>
                                              </div>
                                          
                                        </div>
                                      </div>
                                    :
                                    <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '10px', }}>
                                            <div onMouseEnter={() => this.setState({hoveredOption: this.props.question_of_the_day.answer})} onMouseLeave={() => this.setState({hoveredOption: 0})} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '150px', width: '230px', position: "relative", borderRadius: '5px', border: this.state.hoveredOption === this.props.question_of_the_day.answer ? '2px solid #C4CDD5' : 'none'}}>
                                              <div 
                                                style={{
                                                  width: '30px',
                                                  height: '100%', 
                                                  display: 'flex', 
                                                  justifyContent: 'center', 
                                                  alignItems: 'center', 
                                                  background: 'rgb(99, 193, 105, 0.9)' , 
                                                  fontSize: '13px',
                                                  fontFamily: "Axiforma, sans-serif", 
                                                  fontWeight: '300', 
                                                  color: '#fff', 
                                                  // marginRight: '5px', 
                                                  borderRadius: '5px 0px 0px 5px'
                                                }}>
                                                {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.answer]}
                                              </div>
                                              <div style={{height: '100%', width: '200px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                                <img
                                                  src={
                                                    this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`]
                                                    // 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3'
                                                  }
                                                  alt="correct option"
                                                  style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer"}}
                                                />
                                                <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === this.props.question_of_the_day.answer ? 'block' : 'none'}}>
                                                  <img src={`/images/expand_icon.svg`} width="20px" alt="Expand" onClick={() => this.onImageClick(this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`])}/>
                                                  </div>
                                              </div>
                                            </div>
                                        
                                      
                                    </div>
                                    }
                                  </div>
                                :
                                <div className="row" style={{ width: "100%", margin: '0px', marginBottom: '5px' }}>
                                  <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: '100%', minHeight: '40px', position: "relative", borderRadius: '5px', border: '1px solid #f4f6f8'}}>
                                        <div 
                                          style={{
                                            width: '30px',
                                            height: '100%', 
                                            display: 'flex', 
                                            justifyContent: 'center', 
                                            alignItems: 'center', 
                                            background: 'rgb(99, 193, 105)', 
                                            fontSize: '13px',
                                            fontFamily: "Axiforma, sans-serif", 
                                            fontWeight: '300', 
                                            color: '#fff', 
                                            // marginRight: '5px', 
                                            borderRadius: '5px 0px 0px 5px'
                                          }}>
                                          {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.answer]}
                                        </div>
                                        <Equation5
                                          color="black"
                                          font_size="12px"
                                          font_family="Axiforma"
                                          width="100%"
                                          alignment="center"
                                          math_type={
                                            this.props.question_of_the_day.options[0][
                                              `option${this.props.question_of_the_day.answer}`
                                              ].includes(
                                              "$"
                                            )
                                              ? 1
                                              : 0
                                          }
                                          equation={
                                            this.props.question_of_the_day.options[0][
                                              `option${this.props.question_of_the_day.answer}`
                                              ]
                                          }
                                        ></Equation5>
                                        <div style={{position: 'absolute', top: '50%', right: '5px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                            <img 
                                              src="/images/correct_answer_green_square.svg"
                                              alt="correct"
                                              width="15px"
                                            ></img>
                                        </div>
                                      </div>
                                    
                                  </div>
                                </div>
                                }
                              </div>
                            :
                                <div>
                                  {this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.selected_answer}_image`] || this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`] ? 
                                    <div style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      margin: "5px 0px"
                                    }}>
                                      {this.state.image_aspect_ratio === 'wide' ? 
                                      <div className="row" style={{ width: "100%", margin: '0px', marginBottom: '5px' }}>
                                        <div className="col-12" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                              <div onMouseEnter={() => this.setState({hoveredOption: this.props.question_of_the_day.answer})} onMouseLeave={() => this.setState({hoveredOption: 0})} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '100px', width: '470px', position: "relative",borderRadius: '5px', border: this.state.hoveredOption === this.props.question_of_the_day.answer ? '2px solid #C4CDD5' : 'none'}}>
                                                <div 
                                                  style={{
                                                    width: '30px',
                                                    height: '100%', 
                                                    display: 'flex', 
                                                    justifyContent: 'center', 
                                                    alignItems: 'center', 
                                                    background: 'rgb(99, 193, 105, 0.9)', 
                                                    fontSize: '13px',
                                                    fontFamily: "Axiforma, sans-serif", 
                                                    fontWeight: '300', 
                                                    color: '#fff', 
                                                    // marginRight: '5px', 
                                                    borderRadius: '5px 0px 0px 5px'
                                                  }}>
                                                  {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.answer]}
                                                </div>
                                                <div style={{height: '100%', width: '440px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                                  <img
                                                    src={
                                                      this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`]
                                                      // 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3'
                                                    }
                                                    alt="correct option"
                                                    style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                                  />
                                                  <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === this.props.question_of_the_day.answer ? 'block' : 'none'}}>
                                                  <img src={`/images/expand_icon.svg`} width="20px" alt="Expand" onClick={() => this.onImageClick(this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`])}/>
                                                  </div>
                                                </div>
                                          </div>
                                        </div>
                                        <div className="col-12" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                              <div onMouseEnter={() => this.setState({hoveredOption: this.props.question_of_the_day.selected_answer})} onMouseLeave={() => this.setState({hoveredOption: 0})} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '100px', width: '470px', position: "relative",borderRadius: '5px', border: this.state.hoveredOption === this.props.question_of_the_day.selected_answer ? '2px solid #C4CDD5' : 'none'}}>
                                                <div 
                                                  style={{
                                                    width: '30px',
                                                    height: '100%', 
                                                    display: 'flex', 
                                                    justifyContent: 'center', 
                                                    alignItems: 'center', 
                                                    background: 'rgba(232, 70, 61, 0.9)', 
                                                    fontSize: '13px',
                                                    fontFamily: "Axiforma, sans-serif", 
                                                    fontWeight: '300', 
                                                    color: '#fff', 
                                                    // marginRight: '5px', 
                                                    borderRadius: '5px 0px 0px 5px'
                                                  }}>
                                                  {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.selected_answer]}
                                                </div>
                                                <div style={{height: '100%', width: '440px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                                  <img
                                                    src={
                                                      this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.selected_answer}_image`]
                                                      // 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3'
                                                    }
                                                    alt="wrong option"
                                                    style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                                  />
                                                  <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === this.props.question_of_the_day.selected_answer ? 'block' : 'none'}} >
                                                  <img src={`/images/expand_icon.svg`} width="20px" alt="Expand" onClick={() => this.onImageClick(this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.selected_answer}_image`])}/>
                                                  </div>
                                                </div>
                                              </div>
                                          
                                        </div>
                                      </div>
                                    :
                                    <div className="row" style={{ width: "100%", margin: '0px', marginBottom: '5px' }}>
                                      <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                            <div onMouseEnter={() => this.setState({hoveredOption: this.props.question_of_the_day.answer})} onMouseLeave={() => this.setState({hoveredOption: 0})} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '150px', width: '230px', position: "relative", borderRadius: '5px', marginRight: '5px', border: this.state.hoveredOption === this.props.question_of_the_day.answer ? '2px solid #C4CDD5' : 'none'}}>
                                              <div
                                                style={{
                                                  width: '30px',
                                                  height: '100%', 
                                                  display: 'flex', 
                                                  justifyContent: 'center', 
                                                  alignItems: 'center', 
                                                  background: 'rgb(99, 193, 105, 0.9)' , 
                                                  fontSize: '13px',
                                                  fontFamily: "Axiforma, sans-serif", 
                                                  fontWeight: '300', 
                                                  color: '#fff', 
                                                  // marginRight: '5px', 
                                                  borderRadius: '5px 0px 0px 5px'
                                                }}>
                                                {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.answer]}
                                              </div>
                                              <div style={{height: '100%', width: '200px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                                <img
                                                  src={
                                                    this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`]
                                                    // 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3'
                                                  }
                                                  alt="correct option"
                                                  style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                                />
                                                <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === this.props.question_of_the_day.answer ? 'block' : 'none'}}>
                                                  <img src={`/images/expand_icon.svg`} width="20px" alt="Expand" onClick={() => this.onImageClick(this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.answer}_image`])}/>
                                                  </div>
                                              </div>
                                            </div>
                                        
                                      </div>
                                      <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                            <div onMouseEnter={() => this.setState({hoveredOption: this.props.question_of_the_day.selected_answer})} onMouseLeave={() => this.setState({hoveredOption: 0})} style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', height: '150px', width: '230px', position: "relative", borderRadius: '5px', marginLeft: '5px', border: this.state.hoveredOption === this.props.question_of_the_day.selected_answer ? '2px solid #C4CDD5' : 'none'}}>
                                              <div 
                                                style={{
                                                  width: '30px',
                                                  height: '100%', 
                                                  display: 'flex', 
                                                  justifyContent: 'center', 
                                                  alignItems: 'center', 
                                                  background: 'rgba(232, 70, 61, 0.9)' , 
                                                  fontSize: '13px',
                                                  fontFamily: "Axiforma, sans-serif", 
                                                  fontWeight: '300', 
                                                  color: '#fff', 
                                                  // marginRight: '5px', 
                                                  borderRadius: '5px 0px 0px 5px'
                                                }}>
                                                {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.selected_answer]}
                                              </div>
                                              <div style={{height: '100%', width: '200px', background: '#DFE3E8', borderRadius: '0px 5px 5px 0px', position: 'relative'}}>
                                                <img
                                                  src={
                                                    this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.selected_answer}_image`]
                                                    // 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3'
                                                  }
                                                  alt="wrong option"
                                                  style={{ width: "auto", height: "100%", margin: "auto", objectFit: 'contain', cursor: "pointer", }}
                                                />
                                                <div style={{position: 'absolute', top: '3px', right: '3px', height: '25px', cursor: 'pointer', display: this.state.hoveredOption === this.props.question_of_the_day.selected_answer ? 'block' : 'none'}}>
                                                  <img src={`/images/expand_icon.svg`} width="20px" alt="Expand" onClick={() => this.onImageClick(this.props.question_of_the_day.options[0][`option${this.props.question_of_the_day.selected_answer}_image`])}/>
                                                  </div>
                                              </div>
                                            </div>
                                        
                                      </div>
                                    </div>
                                    }
                                    </div>
                                  :
                                    <div className="row" style={{ width: "100%", margin: '0px', marginBottom: '5px' }}>
                                      <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: '100%', minHeight: '40px', position: "relative", borderRadius: '5px', border: '1px solid #f4f6f8', marginRight: '5px'}}>
                                            <div 
                                              style={{
                                                width: '30px',
                                                height: '100%', 
                                                display: 'flex', 
                                                justifyContent: 'center', 
                                                alignItems: 'center', 
                                                background: 'rgb(99, 193, 105)', 
                                                fontSize: '13px',
                                                fontFamily: "Axiforma, sans-serif", 
                                                fontWeight: '300', 
                                                color: '#fff', 
                                                // marginRight: '5px', 
                                                borderRadius: '5px 0px 0px 5px'
                                              }}>
                                              {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.answer]}
                                            </div>
                                            <Equation5
                                              color="black"
                                              font_size="12px"
                                              font_family="Axiforma"
                                              width="100%"
                                              alignment="center"
                                              math_type={
                                                this.props.question_of_the_day.options[0][
                                                  `option${this.props.question_of_the_day.answer}`
                                                  ].includes(
                                                  "$"
                                                )
                                                  ? 1
                                                  : 0
                                              }
                                              equation={
                                                this.props.question_of_the_day.options[0][
                                                  `option${this.props.question_of_the_day.answer}`
                                                  ]
                                              }
                                            ></Equation5>
                                            <div style={{position: 'absolute', top: '50%', right: '5px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                                <img 
                                                  src="/images/correct_answer_green_square.svg"
                                                  alt="correct"
                                                  width="15px"
                                                ></img>
                                            </div>
                                          </div>
                                        
                                      </div>
                                      <div className="col-6" style={{padding: '0px', marginTop: '5px', marginBottom: '5px'}}>
                                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: '100%', minHeight: '40px', position: "relative", borderRadius: '5px', border: '1px solid #f4f6f8', marginLeft: '5px'}}>
                                            <div 
                                              style={{
                                                width: '30px',
                                                height: '100%', 
                                                display: 'flex', 
                                                justifyContent: 'center', 
                                                alignItems: 'center', 
                                                background: 'rgba(232, 70, 61, 0.90)', 
                                                fontSize: '13px',
                                                fontFamily: "Axiforma, sans-serif", 
                                                fontWeight: '300', 
                                                color: '#fff', 
                                                // marginRight: '5px', 
                                                borderRadius: '5px 0px 0px 5px'
                                              }}>
                                              {this.state.qod_options_to_letters_mapping[this.props.question_of_the_day.selected_answer]}
                                            </div>
                                            <Equation5
                                              color="black"
                                              font_size="12px"
                                              font_family="Axiforma"
                                              width="100%"
                                              alignment="center"
                                              math_type={
                                                this.props.question_of_the_day.options[0][
                                                  `option${this.props.question_of_the_day.selected_answer}`
                                                  ].includes(
                                                  "$"
                                                )
                                                  ? 1
                                                  : 0
                                              }
                                              equation={
                                                this.props.question_of_the_day.options[0][
                                                  `option${this.props.question_of_the_day.selected_answer}`
                                                  ]
                                              }
                                            ></Equation5>
                                            <div style={{position: 'absolute', top: '50%', right: '5px',transform: 'translateY(-50%)', alignItems: 'center', display: 'inherit'}}>
                                                <img 
                                                  src="/images/wrong_answer_red_square.svg"
                                                  alt="wrong"
                                                  width="15px"
                                                ></img>
                                            </div>
                                          </div>
                                        
                                      </div>
                                    </div>
                                  }
                                </div>
                            }
                            {this.props.question_of_the_day?.solution ?
                              <div>
                                <div
                                  style={{
                                    textAlign: 'left',
                                    marginTop: '10px',
                                    fontSize: '13px',
                                    fontFamily: 'Axiforma, sans-serif',
                                    fontWeight: '500',
                                  }}
                                >
                                  Solution
                                </div>
                                {/* <img src={`${this.props.question_of_the_day?.solution}`} alt="Solution" width="90%"></img> */}
                                <div style={{width: '90%'}}> 
                                  <QodSolution url={this.props.question_of_the_day?.solution}/>
                                </div>
                              </div>
                            : null}
                            {this.state.show_qod_up_arrow ?
                          <div onClick={this.openQodNotification} style={{marginTop: '15px', marginBottom: '15px'}}>
                            <img 
                                width="15px"
                                src="/images/up_arrow_head.svg"
                                alt="up arrow"
                                style={{margin: 'auto', cursor: 'pointer'}}
                              ></img>
                            </div> 
                            : null}
                            {this.state.show_qod_down_arrow ?
                          <div onClick={this.openQodNotification} style={{marginTop: '15px', marginBottom: '15px'}}>
                              <img 
                                width="15px"
                                src="/images/down_arrow_head.svg"
                                alt="down arrow"
                                style={{margin: 'auto', cursor: 'pointer'}}
                              ></img>
                            </div> 
                            : null}
                        </div>
                      :null}
                      </div>
                      </Collapse>  
                      </div>
                    </div>
                </div>
              </div>
            ):null}
              {this.props.subjects.length > 0 ? (
                  <div
                    // style={{
                    //   borderRadius: "8px",
                    //   // padding: "10px 20px",
                    //   color: "#191648",
                    //   width: "40%",
                    // }}
                    className="subjects-block"
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        textAlign: "left",
                        color: "#191648",
                      }}                    
                    >
                      Your Subjects
                      {/* {this.props.previous_semesters.subjects.length>0 ?
                    <FormControl sx={{minWidth: 100,minHeight: 40,mt:0,ml:1}} size="small">
      <InputLabel id="demo-select-small">Semester</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={this.state.semester}
        label="Semester"
        onChange={this.handlesemesterChange}
       
      >
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
      </Select>
    </FormControl>
    :null} */}
                    </div>
                   
                        {/* <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: "-32px -16px 0",
                      }}
                    > */}
                   {this.props.subjects.map((sub, index) => {
                      return (
                        <>
                          {/* {index < 5 ? */}
                            <SubjectDiv onClick={() => this.openSubjectContent(sub.id,sub.subject_semester_id,sub.college_subject_mapping_id )} key={index}>
                              {sub.name}
                              <div className="subjectProgressBar">
                                <div
                                  style={{ backgroundColor: `${sub.colour_code}` }}
                                  className="blueSub"
                                ></div>
                              </div>
                            </SubjectDiv>
                            {/* : null} */}
                        </>
                      );
                    })}
                  
                  </div>
                ) : null}
            <div
              style={{
                display: this.props.topic_of_the_day ? "grid" : "block",
                gridTemplateColumns: "33% 67%",
              }}
            >
              {this.props.topic_of_the_day ? (
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    textAlign: "left",
                    margin: "10px 0",
                    color: "#191648",
                  }}
                >
                  Topic of the day{" "}
                </div>
              ) : null}
            </div>
            <div
              style={{
                display: this.props.topic_of_the_day ? "grid" : "block",
                gridTemplateColumns: this.props.topic_of_the_day
                  ? "33% 67%"
                  : "none",
                width: this.props.topic_of_the_day ? "initial" : "100%",
              }}
            >
              {this.props.topic_of_the_day ? (
                <div style={{ marginBottom: "20px" }}>
                  <div style={{ boxShadow: "5px 10px 20px #eee" }}>
                    <div onClick={this.onOpenTopicOfTheDay} className="totd">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{ textAlign: "left", margin: "10px 0.03em" }}
                        >
                          <img
                            width="150px"
                            src="/images/totd_rectangle.svg"
                          ></img>
                          <p
                            style={{
                              marginTop: "-28px",
                              marginLeft: "5px",
                              fontWeight: "500",
                            }}
                          >
                            {this.props.topic_of_the_day.subject_name}
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          margin: "20px 10px",
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        {this.props.topic_of_the_day.title}
                      </div>
                    </div>
                    <div
                      style={{
                        borderRadius: "0 0 5px 5px",
                        margin: "0",
                        borderBottom: "1px solid #eee",
                        display: "flex",
                        flexDirection: "row",
                        padding: "10px",
                        justifyContent: "flex-end",
                      }}
                    >
                      {this.props.topic_of_the_day.is_bookmarked == 0 ? (
                        <div
                          onClick={() =>
                            this.props.addBookmark("topicoftheday")
                          }
                          style={{ margin: "0 10px" }}
                        >
                          <img
                            width="20px"
                            src="/images/bookmark_white_back.png"
                          ></img>
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            this.props.deleteBookmark("topicoftheday")
                          }
                          style={{ margin: "0 10px" }}
                        >
                          <img width="20px" src="/images/bookmarked.png"></img>
                        </div>
                      )}
                      <div style={{ margin: "0 10px" }}>
                        <img src="/images/Info.svg"></img>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>



          </div>
         
          
            <ActivityWall activity_wall={this.props.activity_wall} query_date={this.props.query_date} />
          
        </div>
        <style jsx scoped="true">
          {`
          .addbgcolor{
            background-color: #FFDFD6;
        }
          `}
        </style>
        <div
          className="rightpane col-lg-2"
          style={{
            // marginTop: "30px",
            // color: "#191648",
            background: "#f7f7f7",
            width: "23%",
            paddingLeft: "5px",
          }}
        >
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} >

          <ul className="nav nav-tabs" style={{ marginTop: "15px" }}>
            <li className="nav-item nav-li-item" onClick={this.showassesmenttab} >
              <a className={
                this.state.assessmenttabcontent ? 'nav-link active addbgcolor navstyling' : 'nav-link navstyling'
              } href="#" style={{ color: "black" }}>Assessment</a>
            </li>
            <li className="nav-item" onClick={this.showresultstab} >
              <a className={
                this.state.resultstabcontent ? 'nav-link active addbgcolor navstyling' : 'nav-link navstyling'
              } aria-current="page" href="#" style={{ color: "black" }}>Result</a>
            </li>
            {/* <li className="nav-item" onClick={this.showbookmarktab} >
              <a className={
                this.state.bookmarkstabcontent ? 'nav-link active addbgcolor navstyling' : 'nav-link navstyling'
              } aria-current="page" href="#" style={{ color: "black" }}>Bookmarks</a>
            </li> */}

          </ul>
          {/* <MdNotificationsNone style={{fontSize:"30px",margin:"5px 10px 0px 0px"}} onClick={this.opennotificationpanel} /> */}
         
        </div>
          {/* {this.state.assessmenttabcontent && !this.state.resultstabcontent && !this.state.bookmarkstabcontent ?
       <ul className="nav nav-tabs" style={{marginTop:"15px"}}>
        <li className="nav-item nav-li-item" onClick={this.showobjectiveassessment} >
          <a className={
        this.state.objectivetabcontent ? 'nav-link active addbgcolor mininavstyling' : 'nav-link mininavstyling'
      } href="#" style={{color:"black"}}>Objective</a>
        </li>
        <li className="nav-item" onClick={this.showsubjectiveassessment} >
          <a className={
        this.state.subjectivetabcontent ? 'nav-link active addbgcolor mininavstyling' : 'nav-link mininavstyling'
      } aria-current="page" href="#" style={{color:"black"}}>Subjective</a>
        </li>
         <li className="nav-item" onClick={this.showcodingassessment} >
          <a className={
        this.state.codingtabcontent ? 'nav-link active addbgcolor mininavstyling' : 'nav-link mininavstyling'
      } aria-current="page" href="#" style={{color:"black"}}>Coding</a>
        </li>
        
      </ul>
      :!this.state.assessmenttabcontent && this.state.resultstabcontent && !this.state.bookmarkstabcontent ?  
      <ul className="nav nav-tabs" style={{marginTop:"15px"}}>
        <li className="nav-item nav-li-item" onClick={this.ShowObjectiveResults} >
          <a className={
        this.state.objective_result_tab_content ? 'nav-link active addbgcolor mininavstyling' : 'nav-link mininavstyling'
      } href="#" style={{color:"black"}}>Objective</a>
        </li>
        <li className="nav-item" onClick={this.ShowSubjectiveResults} >
          <a className={
        this.state.subjective_result_tab_content ? 'nav-link active addbgcolor mininavstyling' : 'nav-link mininavstyling'
      } aria-current="page" href="#" style={{color:"black"}}>Subjective</a>
        </li>
         <li className="nav-item" onClick={this.ShowCodingAssessment} >
          <a className={
        this.state.coding_result_tab_content ? 'nav-link active addbgcolor mininavstyling' : 'nav-link mininavstyling'
      } aria-current="page" href="#" style={{color:"black"}}>Coding</a>
        </li>
        
      </ul>
      : null} */}


          {/* <div
            style={{ position: "relative" }}
            onFocus={this.onSearchFocus}
            onBlur={this.onSearchBlur}
           >
            <div
              style={{
                margin: "20px 0 0 0",
                marginBottom:
                  this.state.showSearchResults &&
                  this.state.search_data.length > 2
                    ? "0"
                    : "20px",
                fontSize: "16px",
                height: "50px",
                boxShadow: "0 0 10px #f2f2f2",
                borderRadius:
                  this.state.showSearchResults &&
                  this.state.search_data.length > 2
                    ? "8px 8px 0 0"
                    : "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  border: "1px solid rgb(234, 234, 234)",
                }}
              >
                <SearchImage>
                  <img
                    style={{
                      float: "left",
                      margin: "17px 17px 5px 10px",
                    }}
                    src="/images/search_icon.svg"
                    alt="search"
                  />
                </SearchImage>
                <SearchInputDiv
                  style={{
                    height: "100%",
                    margin: "15px 0",
                    // textAlign: "left",
                  }}
                >
                  <SearchInput
                    value={this.state.search_data}
                    onChange={this.onSearchChangeHandler}
                    placeholder="Anything we can help you with"
                  ></SearchInput>
                </SearchInputDiv>
              </div>
            </div>
            {this.state.showSearchResults &&
            this.state.search_data.length > 2 ? (
              <div
                id="search-icon"
                style={{
                  margin: "0",
                  fontSize: "16px",
                  border: "1px solid #f2f2f2",
                  borderRadius: "0 0 8px 8px",
                  position: "absoolute",
                  backgroundColor: "white",
                  zIndex: "100",
                  padding: "20px",
                  position: "absolute",
                  width: "-webkit-fill-available",
                }}
              >
                {search_results && search_results.length > 0 ? (
                  search_results.map((item, index) => {
                    return (
                      <div id="search-icon" key={index}>
                        <div
                          id="search-icon"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <div
                            id="search-icon"
                            style={{ textAlign: "left", width: "70%" }}
                          >
                            {item.name}
                          </div>
                          <div
                            id="search-icon"
                            style={{
                              width: "30%",
                              justifyContent: "flex-end",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            {item.has_video == 1 && (
                              <div style={{ margin: "auto 20px" }}>
                                <img
                                  id="search-icon"
                                  onClick={() =>
                                    this.props.getSearchedTopicVideo(item.code)
                                  }
                                  src="/images/search/video.svg"
                                ></img>
                              </div>
                            )}
                            {item.has_deck == 1 && (
                              <div style={{ margin: "auto 20px" }}>
                                <img
                                  id="search-icon"
                                  onClick={() =>
                                    this.props.getSearchedTopicDeck(item.code)
                                  }
                                  src="/images/search/deck.svg"
                                ></img>
                              </div>
                            )}
                            {item.has_docs == 1 && (
                              <div style={{ margin: "auto 20px" }}>
                                <img
                                  id="search-icon"
                                  onClick={() =>
                                    this.props.getSearchedTopicDocs(item.code)
                                  }
                                  src="/images/search/doc.svg"
                                ></img>
                              </div>
                            )}
                            {item.has_test == 1 && (
                              <div style={{ margin: "auto 20px" }}>
                                <img
                                  id="search-icon"
                                  onClick={() =>
                                    this.openSearchedTopicPracticeTest(item)
                                  }
                                  src="/images/search/test.svg"
                                ></img>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          id="search-icon"
                          style={{
                            borderBottom: "1px solid #eee",
                            margin: "10px 0",
                          }}
                        ></div>
                      </div>
                    );
                  })
                ) : (
                  <div>No topics found</div>
                )}
              </div>
            ) : null}
          </div> */}
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              margin: "auto",
              paddingLeft: "15px",
              boxSizing: "border-box",
            }}
          >
            <div style={{ margin: "auto" }}>
              {/* <MonkeyDiv
                style={{ width: "60%", height: "200px", marginTop: "-30px" }}
              >
                {(this.props.assessments &&
                  this.props.assessments.length > 0) ||
                (this.props.coding_assessments &&
                  this.props.coding_assessments.length) ||
                (this.props.weekly_challenge &&
                  this.props.weekly_challenge.length) ? (
                  <img
                    style={{ width: "100%" }}
                    height="200px"
                    src="/images/monkey_has_test.svg"
                  ></img>
                ) : (
                  <img
                    style={{ width: "100%" }}
                    height="200px"
                    src="/images/dashboard_monkey.svg"
                  ></img>
                )}
              </MonkeyDiv> */}

              {has_bookmarks && (
                <div
                  style={{
                    marginTop: "20px",
                    // borderBottom: "2px solid #eee",
                    width: "100%",
                  }}
                ></div>
              )}
              {this.state.bookmarkstabcontent ?
                <>
                  {has_bookmarks ? (
                    <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                      <div className="bookmarkTitle">My Bookmarks</div>
                      {/* <div style={{ textAlign: "left", fontSize: "14px" }}>
                    Hey, you have some new bookmarks
                  </div> */}
                      <div>
                        {this.props.bookmarks.map((sub,index) => {
                          return sub.bookmarked &&
                            sub.subject_name &&
                            Object.keys(sub.bookmarked).length != 0 ? (
                            <div style={{ textAlign: "left", marginTop: "20px" }} key={index}>
                              <div style={{ fontSize: "12px", fontWeight: "600" }}>
                                {sub.subject_name}
                              </div>
                              <div style={{ fontSize: "10px" }}>
                                {sub.bookmarked.bookmarked_type == "question"
                                  ? "Question is bookmarked"
                                  : sub.bookmarked.bookmarked_type == "deck"
                                    ? sub.bookmarked.name + " deck is bookmarked"
                                    : sub.bookmarked.title + " is bookmarked"}
                              </div>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </div>
                  ) : <div style={{ paddingTop: "15px",fontSize:"12px" }}>No Upcoming tests</div>}
                </>
                : null}
              {/* {this.props.weekly_challenge &&
                this.props.weekly_challenge.length > 0 && (
                  <div>
                    <div
                      style={{
                        marginTop: "20px",
                        borderBottom: "2px solid #eee",
                      }}
                    ></div>
                    <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                      <div className="bookmarkTitle">Weekly Challenge</div>
                      <div style={{ textAlign: "left", fontSize: "14px" }}>
                        'Hey, here are the coming up assessments!'
                      </div>
                      <div>
                        {this.props.weekly_challenge.map((assess) => {
                          return (
                            <div
                              style={{ textAlign: "left", marginTop: "20px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div>
                                  <div
                                    style={{
                                      fontSize: "17px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {assess.title}
                                  </div>
                                  {/* {assess.today == 0 ? <div style={{fontSize: '14px'}}>Exam ends on {assess.expires_at}, Start now</div>
                                                            : <div style={{fontSize: '14px'}}>Exam ends today start now</div>} */}

              {/* <div style={{ fontSize: "14px" }}>
                                    Exam starts at {assess.start_time}, Start
                                    now
                                  </div>
                                </div>
                                <div
                                  onClick={() =>
                                    this.startweeklychallenge(assess.id)
                                  }
                                  style={{
                                    textAlign: "right",
                                    margin: "auto",
                                    marginRight: "20px",
                                  }}
                                >
                                  <BsArrowRightCircleFill
                                    style={{
                                      fontSize: "30px",
                                    }}
                                  />
                                  {/* <img
                                    style={{ width: "30px" }}
                                    src="/images/arrow_with_circle.svg"
                                  ></img> */}
              {/* </div>
                              </div>
                            </div> */}
              {/* );
                        })}
                      </div>
                    </div>
                  </div>
                )} */}

              {/* <Carousel cols={1} rows={1} gap={10} loop hideArrow={true} showDots={true}> */}
              {this.state.assessmenttabcontent ?
                <div className="assessmenttabcontent">
                  {this.state.objectivetabcontent ?
                    <>
                      {this.props.assessments && this.props.assessments.length > 0 ? (
                        //  <Carousel.Item>
                        <div>
                          <div
                            style={{
                              marginTop: "20px",
                              // borderBottom: "2px solid #eee",
                            }}
                          ></div>
                          {this.state.show_more_assesments ?
                            <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                              {/* <div className="bookmarkTitle">Objective Assessment</div> */}
                              {/* <div style={{ textAlign: "left", fontSize: "14px" }}>
                      {this.props.assessment_today == 1
                        ? "Hey, there is an assessment today!"
                        : "Hey, here are the coming up assessments!"}
                    </div> */}
                              <div>
                                {this.props.assessments.map((assess,index) => {
                                  return (
                                    <div style={{ textAlign: "left", marginTop: "20px" }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                        key={index}
                                      >
                                        <div>
                                          <div
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {assess.title} ({assess.type})
                                          </div>
                                          {/* {assess.today == 0 ? <div style={{fontSize: '14px'}}>Exam ends on {assess.expires_at}, Start now</div>
                                                            : <div style={{fontSize: '14px'}}>Exam ends today start now</div>} */}
                                          {assess.starts_today == 1 ? (
                                            <div style={{ fontSize: "12px" }}>
                                              Exam starts at {assess.start_time}
                                            </div>
                                          ) : assess.expires_today == 1 ? (
                                            <div style={{ fontSize: "12px" }}>
                                              Exam ends at {assess.doe}, Start now
                                            </div>
                                          ) : (
                                            <div style={{ fontSize: "12px" }}>
                                              Exam ends at {assess.doe}, start now
                                            </div>
                                          )}
                                        </div>
                                        {moment(assess.start_time) <= current ? (
                                          <div
                                            onClick={() =>
                                              this.startAssessmentright(assess.id,assess.type)
                                            }
                                            style={{
                                              textAlign: "right",
                                              margin: "10px 10px 0px 4px",
                                            }}
                                          >
                                            <BsArrowRightCircleFill
                                              style={{
                                                fontSize: "24px",
                                              }}
                                            />
                                           
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              <button className="btn" onClick={this.showlessassessments} style={{padding:"2px",fontSize:"10px",background:"rgb(253, 176, 200)"}}>Show Less <FiArrowUp /></button>
                            </div>
                            : <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                              <div>
                                {this.props.assessments.slice(0, 2).map((assess,index) => {
                                  return (
                                    <div style={{ textAlign: "left", marginTop: "20px" }} key={index}>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>
                                          <div
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {assess.title} ({assess.type})
                                          </div>
                                          {/* {assess.today == 0 ? <div style={{fontSize: '14px'}}>Exam ends on {assess.expires_at}, Start now</div>
                                                          : <div style={{fontSize: '14px'}}>Exam ends today start now</div>} */}
                                          {assess.starts_today == 1 ? (
                                            <div style={{ fontSize: "12px" }}>
                                              Exam starts at {assess.start_time}
                                            </div>
                                          ) : assess.expires_today == 1 ? (
                                            <div style={{ fontSize: "12px" }}>
                                              Exam ends at {assess.doe}, Start now
                                            </div>
                                          ) : (
                                            <div style={{ fontSize: "12px" }}>
                                              Exam ends at {assess.doe}, start now
                                            </div>
                                          )}
                                        </div>
                                        {moment(assess.start_time) <= current ? (
                                          <div
                                          onClick={() =>
                                            this.startAssessmentright(assess.id,assess.type)
                                          }
                                            style={{
                                              textAlign: "right",
                                              margin: "10px 10px 0px 4px",
                                              cursor:"pointer",
                                            }}
                                          >
                                            <BsArrowRightCircleFill
                                              style={{
                                                fontSize: "24px",
                                              }}
                                            />
                                           
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              {this.props.assessments.length>2 ?
                                <button className="btn" onClick={this.showmoreassessments} 
                              style={{padding:"2px",fontSize:"10px",background:"rgb(253, 176, 200)"}}>Show More <FiArrowDown /></button>
                               :null}
                             
                            </div>}
                        </div>
                        // </Carousel.Item>
                      ) : <div style={{fontSize:"12px"}}>No upcoming tests</div>}
                    </>
                    : null}


                </div>
                : null}
            

          
            {this.state.resultstabcontent ?
                <div>
                  {(this.props.assessment_results &&
                    this.props.assessment_results.length > 0) && (
                     
                      <div>
                        <div
                          style={{
                            marginTop: "20px",
                          }}
                        ></div>
                         {this.state.show_more_results ?
                        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                         
                          <div>
                            {this.props.assessment_results.map((result,index) => {
                                return (
                                  <div
                                    style={{
                                      textAlign: "left",
                                      marginTop: "10px",
                                    }}
                                    key={index}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {result.title} ({result.type})
                                        </div>
                                        <div style={{ fontSize: "10px" }}>
                                          {result.subject_name}{" "}
                                        </div>
                                      </div>
                                      <div
                                        onClick={() =>
                                          this.openAssessmentResults(result.id,result.type)
                                        }
                                        style={{
                                          textAlign: "right",
                                          margin: "10px 10px 0px 4px",
                                          cursor:"pointer",
                                        }}
                                      >
                                        <BsArrowRightCircleFill
                                          style={{
                                            fontSize: "24px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                              <button className="btn" onClick={this.showlessresults} 
                              style={{padding:"2px",fontSize:"10px",background:"rgb(253, 176, 200)"}}>Show Less <FiArrowUp /></button>
                          </div>
                        </div>
                        : 
                         <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                         
                        <div>
                          {this.props.assessment_results.slice(0, 2).map((result,index) => {
                              return (
                                <div
                                  style={{
                                    textAlign: "left",
                                    marginTop: "10px",
                                  }}
                                  key={index}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {result.title} ({result.type})
                                      </div>
                                      <div style={{ fontSize: "10px" }}>
                                        {result.subject_name}{" "}
                                      </div>
                                    </div>
                                    <div
                                       onClick={() =>
                                        this.openAssessmentResults(result.id,result.type)
                                      }
                                      style={{
                                        textAlign: "right",
                                        margin: "10px 10px 0px 4px",
                                      }}
                                    >
                                      <BsArrowRightCircleFill
                                        style={{
                                          fontSize: "24px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                             <button className="btn" onClick={this.showmoreresults} 
                              style={{padding:"2px",fontSize:"10px",background:"rgb(253, 176, 200)"}}>Show More <FiArrowDown /></button>
                        </div>
                      </div>}
                      </div>
                    )}


                </div> 
                : null}
                 <div style={{marginTop: '45px', fontSize: '14px'}} onClick={this.loadanalyticsdata}><a href="#">Click here to view analytics</a></div>
              <div className="col-lg-12" style={{
                display: 'flex', border: '1px', justifyContent: 'space-around', marginTop: '5px',
                borderRadius: '13px', padding: '0px', background: 'rgb(253, 176, 200)', marginLeft: '16px', width: '80%',
                textAlign: 'center'
              }}>
                <div className={this.state.seven_days_data? "activetab_rightpanel":"inactivetab_rightpanel"}  onClick={this.sevendaysdata}>Last 7 days</div>
                <div  className={!this.state.seven_days_data? "activetab_rightpanel":"inactivetab_rightpanel"} onClick={this.semesterdata}>
                  Last 30 days</div>
              </div>
              {!this.props.loading_analyticsdata ?
              <div><span style={{fontSize:"11px"}}>Last updated on:- {this.getFormattedTime(localStorage.getItem('apicalltime'))}</span>
               <span>
                <Tooltip title="Click here to get latest data">
                  <span>
                  <FiRefreshCcw className={this.props.loading_analyticsdata ? "rotate":""}
               style={{fontSize:"20px",cursor:"pointer",marginLeft:"10px"}} onClick={this.latestloadanalytics} /></span></Tooltip></span></div>:null}
               {this.props.loading_analyticsdata ?
               <Analyticskeletonloader />
              : <> {this.state.seven_days_data && this.props.analytics_data ?
                // <Analyticskeletonloader />
                  <Analyticscreen data={this.props.analytics_data} sevedays={true} user_id={user.user_id} sevendays_all_section={true} />  
               : this.props.loading_semester_analyticsdata ?
               <Analyticskeletonloader />
               :!this.state.seven_days_data && this.props.analytics_data_semester ?
             <Analyticscreen data={this.props.analytics_data_semester} sevedays={false} user_id={user.user_id} thirty_all_section={true} />
             : <Analyticskeletonloader />
             }</>} 
           
           {/* <Analyticscreen data={this.props.analytics_data_semester} sevedays={false} user_id={user.user_id} /> */}
              {/* {this.props.assignment_results && this.props.assignment_results.length > 0 && <div>
                                    <div style={{marginTop: '20px', borderBottom: '2px solid #eee'}}></div>
                                    <div style={{marginTop: '20px', marginBottom: '10px'}}>
                                        <div className='bookmarkTitle'>Assignments Results</div>
                                        <div style={{ textAlign: 'left', fontSize: '14px'}}>Hey, here are the your assignments results </div>
                                        <div>
                                            {this.props.assignment_results.map((assign_result)=>{
                                                return <div style={{textAlign: 'left', marginTop: '20px'}}>
                                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                                        <div>
                                                            <div style={{fontSize: '17px', fontWeight: '500'}}>{assign_result.title}</div>
                                                            <div style={{fontSize: '14px'}}>{assign_result.subject_name} </div> 
                                                        </div>
                                                        {assign_result.results_released == 1 ? <div onClick={()=>this.openAssignmentResults(assign_result.id)} style={{textAlign: 'right', margin: 'auto', marginRight: '20px'}}>
                                                            <img style={{width: '30px'}} src='/images/arrow_with_circle.svg'></img>
                                                        </div> : null}
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>} */}

              {!this.props.assessments &&
                this.props.assessments.length == 0 &&
                !this.props.coding_assessments &&
                this.props.coding_assessments.length == 0 &&
                !this.props.assessment_results &&
                this.props.assessment_results.length == 0 &&
                !this.props.coding_assessment_results &&
                this.props.coding_assessment_results.length == 0 && (
                  <div>
                    <div
                      style={{
                        marginTop: "20px",
                        // borderBottom: "2px solid #eee",
                        width: "310px",
                      }}
                    ></div>
                    <div
                      style={{
                        marginTop: "20px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      You can check Your latest updates here{" "}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <Modal show={this.state.openReportDialog} onHide={this.closeReport}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:"16px"}}>Please select issue to be reported</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <ReportDialog
            open={this.state.openReportDialog}
            close={this.closeReport}
            reportItem={this.state.itemToBeReported}
            reportItemId={this.state.reportItemId}
          ></ReportDialog></Modal.Body>
      </Modal> 
        <Modal4
          open={
            this.props.iosLink &&
            !this.props.iosLinkError &&
            this.state.iosModelShow
          }
          url={this.props.iosLink ? this.props.iosLink : "Fetching link..."}
          close={this.iosLinkModalClose}
        ></Modal4>
        <Modal1
          open={this.state.openTOTD}
          url={
            this.props.topic_of_the_day
              ? this.props.topic_of_the_day.description
              : ""
          }
          close={this.closeTotd}
        ></Modal1>
        <Modal1
          open={this.state.openTT}
          url={
            this.state.trending_topic
              ? this.props.trending_topics[this.state.trending_topic].summary
              : ""
          }
          close={this.closeTT}
        ></Modal1>
        {this.props.open_search_topic &&
          (this.props.searched_type == "DOCS" ||
            this.props.searched_type == "VIDEO") ? (
          <Modal2
            open={
              this.props.open_search_topic &&
              (this.props.searched_type == "DOCS" ||
                this.props.searched_type == "VIDEO")
            }
            type={this.props.searched_type}
            data={
              this.props.searched_type == "DOCS"
                ? this.props.searched_docs
                : this.props.searched_vid
            }
            topic={this.props.searched_topic}
            close={this.props.closeSearchedTopic}
          ></Modal2>
        ) : null}
        {this.state.openImage && (
          <ImageModal
            width="500px"
            height="70%"
            padding="none"
            style={{ zIndex: 12 }}
            closeDialog={this.closeImage}
            open={this.state.openImage}
            url={this.state.image_url}
          ></ImageModal>
        )}
        <Modal3
          open={this.state.openQotdExplanationData}
          url={this.state.qotdExplainUrl}
          close={this.closeQotdExplanation}
        ></Modal3>
        {(this.props.open_recommended_deck ||
          this.props.open_question_of_the_day_readmore ||
          this.props.open_topic_of_the_day_readmore) &&
          this.props.deck_data ? (
          <PreviewCard
            isOpen={
              (this.props.open_recommended_deck ||
                this.props.open_question_of_the_day_readmore ||
                this.props.open_topic_of_the_day_readmore) &&
              this.props.deck_data
            }
            onClose={this.closeDeck}
            deck_data={this.props.deck_data}
            deck={this.props.deck}
            reportItem={this.reportItem}
            hideBookmark={true}
            hideReport={true}
          ></PreviewCard>
        ) : null}
        {this.props.open_searched_topic_deck && this.props.searched_deck ? (
          <PreviewCard2
            isOpen={
              this.props.open_searched_topic_deck && this.props.searched_deck
            }
            onClose={this.props.closeSearchedTopic}
            deck_data={this.props.searched_deck}
            deck_name={this.props.searched_topic.name}
            hideBookmark={true}
          ></PreviewCard2>
        ) : null}
        <audio id="qod-sound-effect" src='https://web-edwisely.s3.ap-south-1.amazonaws.com/college_logos/QOD_correct_sound.wav'></audio>
        <NotificationPrompt number={1}/>
        <style jsx scoped="true">
          {`
          .addbgcolor{
            background-color: #FFDFD6;
        }
            .rightpane {
              // background: url("/images/dashboard_right_pane.png");
              height: auto;
              // background-size: 100% 100%;
              // background-repeat-x: no-repeat;
              // background-position-x: center;
              // padding-bottom: 50px;
              padding:"0px"
              background: #f7f7f7;
              
            }
            .bookmarkTitle {
              font-size: 16px;
              text-align: start;
              font-weight: 600;
            }
            .qotdOption {
              width: auto;
              border-radius: 24px;
              padding: 4px 14px;
              height: 100%;
              cursor: pointer;
              border: 1px solid rgb(234, 234, 234);
              background: #8080800f;
            }
            .confettiDiv {
              // width: 95%;
              background-image: url("/images/qotd_confetti.png");
              margin: auto;
              margin-top:55px;
            }
            .subjectProgressBar {
             width: 100%;
    height: 8px;
    border: 1px solid #eee;
    border-radius: 8px;
    margin: 5px 0 5px 0;
    background: #e7e7e7;
            }
            .orangeSub {
              background: linear-gradient(
                    325.97deg,
                    #ff723b 17.54%,
                    #ffdf57 109.16%,
                    #ffed5b 125.89%
                  )
                  padding-box,
                linear-gradient(
                    325.97deg,
                    #ff723b 17.54%,
                    #ffdf57 109.16%,
                    #ffed5b 125.89%
                  )
                  border-box;
              width: 50%;
              height: 8px;
              border-radius: 8px;
            }
            .redSub {
              background: linear-gradient(
                    144.48deg,
                    #f38fa2 9.88%,
                    #eb5c6f 54.05%,
                    #f1825c 116.8%
                  )
                  padding-box,
                linear-gradient(
                    144.48deg,
                    #f38fa2 9.88%,
                    #eb5c6f 54.05%,
                    #f1825c 116.8%
                  )
                  border-box;
              width: 50%;
              height: 8px;

              border-radius: 8px;
            }
            .blueSub {
              // background: linear-gradient(
              //       139.49deg,
              //       #a0f4ff -33.67%,
              //       #75dae9 -6.6%,
              //       #8459f8 100%
              //     )
              //     padding-box,
              //   linear-gradient(
              //       139.49deg,
              //       #a0f4ff -33.67%,
              //       #75dae9 -6.6%,
              //       #8459f8 100%
              //     )
              //     border-box;
              width: 50%;
              height: 8px;
              border-radius: 8px;
            }
            .orangeTT {
              background: linear-gradient(
                    325.97deg,
                    #ff723b 17.54%,
                    #ffdf57 109.16%,
                    #ffed5b 125.89%
                  )
                  padding-box,
                linear-gradient(
                    325.97deg,
                    #ff723b 17.54%,
                    #ffdf57 109.16%,
                    #ffed5b 125.89%
                  )
                  border-box;
              width: 80%;
              border-radius: 8px;
              margin-top: -60px !important;
              height: 60px;
              z-index: 10;
              margin-left: 80px;
              color: white;
              padding: 10px;
              box-sizing: border-box;
            }
            .blueTT {
              background: linear-gradient(
                    139.49deg,
                    #a0f4ff -33.67%,
                    #75dae9 -6.6%,
                    #8459f8 100%
                  )
                  padding-box,
                linear-gradient(
                    139.49deg,
                    #a0f4ff -33.67%,
                    #75dae9 -6.6%,
                    #8459f8 100%
                  )
                  border-box;
              width: 80%;
              border-radius: 8px;
              margin-top: -60px !important;
              z-index: 10;
              height: 60px;
              color: white;
              margin-left: 80px;
              padding: 10px;
              box-sizing: border-box;
            }
            .redTT {
              background: linear-gradient(
                    144.48deg,
                    #f38fa2 9.88%,
                    #eb5c6f 54.05%,
                    #f1825c 116.8%
                  )
                  padding-box,
                linear-gradient(
                    144.48deg,
                    #f38fa2 9.88%,
                    #eb5c6f 54.05%,
                    #f1825c 116.8%
                  )
                  border-box;
              width: 80%;
              border-radius: 8px;
              margin-top: -60px !important;
              z-index: 10;
              height: 60px;
              margin-left: 80px;
              color: white;
              padding: 10px;
              box-sizing: border-box;
            }
            .totd {
              // background:
              //     linear-gradient(to right bottom, #6ACEF4 0%, #6EAFF9 32.912%, #7F64FF 100%) padding-box,
              //     linear-gradient(to right bottom, #6ACEF4 0%, #6EAFF9 32.912%, #7F64FF 100%) border-box;
              background-image: url("/images/totd_image.png");
              border: 1px solid #eee;
              // box-shadow:5px 10px 20px #eee;
              border-radius: 5px 5px 0 0;
              background-repeat: no-repeat;
              background-size: cover;
            }
            .ttBackgroundImage1 {
              background-image: url("/images/trending_topics_image1.png");
              background-repeat: no-repeat;
              background-size: cover;
              height: 181px;
            }
            .ttBackgroundImage2 {
              background-image: url("/images/trending_topics_image2.png");
              background-repeat: no-repeat;
              background-size: cover;
              height: 181px;
            }
            .ttBackgroundImage3 {
              background-image: url("/images/trending_topics_image3.png");

              background-repeat: no-repeat;
              background-size: cover;
              height: 181px;
            }
            .recommended {
              border-radius: 5px;
              padding: 20px;
              background: white;
              height: 100%;
              box-sizing: border-box;
              display: grid;
              grid-template-rows: 80% 20%;
            }
            .recommendedBlue {
              background: linear-gradient(
                    140.58deg,
                    #a0f4ff -7.25%,
                    #75dae9 13.83%,
                    #8459f8 96.87%
                  )
                  padding-box,
                linear-gradient(
                    140.58deg,
                    #a0f4ff -7.25%,
                    #75dae9 13.83%,
                    #8459f8 96.87%
                  )
                  border-box;
              border-radius: 5px;
              box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.163981);
              margin-right: 20px;
              padding: 0 0 4px 4px;
              height: 100%;
            }
            .recommendedOrange {
              background: linear-gradient(
                    324.97deg,
                    #ff723b 17.69%,
                    #ffdf57 140.91%,
                    #ffed5b 163.41%
                  )
                  padding-box,
                linear-gradient(
                    324.97deg,
                    #ff723b 17.69%,
                    #ffdf57 140.91%,
                    #ffed5b 163.41%
                  )
                  border-box;
              border-radius: 5px;
              box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.163981);
              margin-right: 20px;
              padding: 0 0 4px 4px;
              height: 100%;
            }
          `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state.dash);
  return {
    loading: state.dash.loading,
    student_name: state.dash.student_name,
    error_loading_dashboardData: state.dash.error_loading_dashboardData,
    subjects: state.dash.subjects,
    trending_topics: state.dash.trending_topics,
    recommended_decks: state.dash.recommended_decks,
    question_of_the_day: state.dash.question_of_the_day,
    topic_of_the_day: state.dash.topic_of_the_day,
    search_data: state.dash.search_data,
    bookmarks: state.dash.bookmarks,
    weekly_challenge: state.dash.weekly_challenge,
    assessments: state.dash.assessments,
    assessment_today: state.dash.assessment_today,
    deck_data: state.dash.deck_data,
    open_recommended_deck: state.dash.open_recommended_deck,
    open_question_of_the_day_readmore:
      state.dash.open_question_of_the_day_readmore,
    open_topic_of_the_day_readmore: state.dash.open_topic_of_the_day_readmore,
    open_searched_topic_deck: state.dash.open_searched_topic_deck,
    open_searched_topic_video: state.dash.open_searched_topic_video,
    open_searched_topic_docs: state.dash.open_searched_topic_docs,
    searched_content_data: state.dash.searched_content_data,
    coding_assessments: state.dash.coding_assessments,
    coding_assessment_today: state.dash.coding_assessment_today,
    assessment_results: state.dash.assessment_results,
    coding_assessment_results: state.dash.coding_assessment_results,
    searched_topic: state.dash.searched_topic,
    searched_type: state.dash.searched_type,
    searched_docs: state.dash.searched_docs,
    searched_vid: state.dash.searched_vid,
    searched_deck: state.dash.searched_deck,
    open_search_topic: state.dash.open_search_topic,
    deck: state.dash.deck,
    subjective_tests: state.dash.subjective_tests,
    subjective_test_results: state.dash.subjective_test_results,
    college_name: state.dash.college_name,
    assignments: state.dash.assignments,
    assignment_results: state.dash.assignment_results,
    iosLink: state.dash.iosLink,
    iosLinkError: state.dash.iosLinkError,
    college_notifications: state.class.college_notifications,
    activity_wall: state.dash.activity_wall,
    previous_semesters: state.dash.previous_semesters,
    query_date:state.dash.query_date,
    loading_analyticsdata:state.dash.loading_analyticsdata,
    error_loading_analyticsdata:state.dash.error_loading_analyticsdata,
    loading_semester_analyticsdata: state.dash.loading_semester_analyticsdata,
    error_semester_loading_analyticsdata: state.dash.error_semester_loading_analyticsdata,
    analytics_data:state.dash.analytics_data,
    analytics_data_semester:state.dash.analytics_data_semester,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardData: () => dispatch(getDashboardDataAC()),
    getSearchData: () => dispatch(getSearchDataAC()),
    submitQuestionOfTheDay: (option_index, time_spent) =>
      dispatch(submitQuestionOfTheDayAC(option_index, time_spent)),
    addBookmark: (type) => dispatch(addBookmarkAC(type)),
    deleteBookmark: (type) => dispatch(deleteBookmarkAC(type)),
    addQodInterest: (interest) => dispatch(addQodInterestAC(interest)),
    getDeckItem: (deck_id) => dispatch(getDeckItemsAC(deck_id)),
    getDeckData: (deck_code) => dispatch(getDeckDataAC(deck_code)),
    getRecommendedDecks: (topic_code) =>
      dispatch(getRecommendedDecksAC(topic_code)),
    openRecommendedDeck: (deck) =>
      dispatch({ type: OPENRECOMMENDEDDECK, deck: deck }),
    openTopicOfTheDayReadmore: () =>
      dispatch({ type: OPENTOPICOFTHEDAYREADMORE }),
    openQuestionOfTheDayReadmore: () =>
      dispatch({ type: OPENQUESTIONOFTHEDAYREADMORE }),
    closeRecommendedDeck: () => dispatch({ type: CLOSERECOMMENDEDDECK }),
    closeTopicOfTheDayReadmore: () =>
      dispatch({ type: CLOSETOPICOFTHEDAYREADMORE }),
    closeQuestionOfTheDayReadmore: () =>
      dispatch({ type: CLOSEQUESTIONOFTHEDAYREADMORE }),
    getSearchedTopicDeck: (code) => dispatch(getSearchedTopicDeckAC(code)),
    getSearchedTopicVideo: (code) => dispatch(getSearchedTopicVideoAC(code)),
    getSearchedTopicDocs: (code) => dispatch(getSearchedTopicDocsAC(code)),
    closeSearchedTopic: () => dispatch({ type: "CLOSE_SEARCHED_TOPIC" }),
    getIosAppLink: () => dispatch(getIosAppLinkAC()),
    getClassroomDetails: (date) => dispatch(getClassroomActivityAC(date)),
    postComment: (id, comment, type) =>
      dispatch(postCommentAC(id, comment, type)),
    loadMoreComments: (id, type) => dispatch(loadMoreCommentsAC(id, type)),
    getFeedback: (id) => dispatch(getFeedbackAC(id)),
    getWallDatares:(query_date)=>dispatch(getWallData(query_date)),
    getStatsDatares:()=>dispatch(getStatsData()),
    getStatsDataSemesterres:()=>dispatch(getStatsDataSemester()),
    clearAnalyticsState: () => dispatch({ type: "CLEAR_ANALYTICS" })
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);