import axios from 'axios'
import {
  setCareerData,
  setPathData,
  setRecentSearchesData,
  setSearchData
} from '../reducers/careerPathReducer'

import { getLoggedInUser } from '../../helpers/Auth'

const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST
const getAccessToken = () => localStorage.getItem('token')
const category_id = 1;
const career_data = {
  "recommended_careers": {
    "skills": [
      {
        "id": 1,
        "name": "Mathematics",
        "sub_skill": [
          "Calculus",
          "Linear Algebra"
        ],
        "fit": 6
      },
      {
        "id": 1,
        "name": "Statistics",
        "sub_skill": [
          "Probability",
          "Statistical Inference"
        ],
        "fit": 8
      },
      {
        "id": 2,
        "name": "Programming",
        "sub_skill": [
          "Python",
          "Java"
        ],
        "fit": 5
      },
      {
        "id": 3,
        "name": "Machine Learning",
        "sub_skill": [
          "Supervised Learning",
          "Unsupervised Learning",
          "Regression",
          "Classification",
          "Clustering"
        ],
        "fit": 4
      },
      {
        "id": 4,
        "name": "Deep Learning",
        "sub_skill": [
          "Neural Networks",
          "Convolutional Neural Networks",
          "Recurrent Neural Networks"
        ],
        "fit": 1
      },
      {
        "id": 5,
        "name": "Data Processing",
        "sub_skill": [
          "Data Cleaning",
          "Data Transformation"
        ],
        "fit": 2
      },
      {
        "id": 6,
        "name": "Model Evaluation",
        "sub_skill": [
          "Cross-Validation",
          "Confusion Matrix",
          "Precision and Recall"
        ],
        "fit": 0
      },
      {
        "id": 7,
        "name": "Feature Engineering",
        "sub_skill": [
          "Feature Selection",
          "Feature Scaling"
        ],
        "fit": 1
      },
      {
        "id": 8,
        "name": "Model Deployment",
        "sub_skill": [
          "Model Serialization",
          "API Development"
        ],
        "fit": 3
      }
    ],
    "job": {
      "id": 1,
      "name": "Machine Learning Engineer"
    },
    "fit": 7,
    "salary_range": [
      1000000,
      1500000,
      2000000,
      3000000
    ],
    "scroll": [
      {
        "interested": 32
      },
      {
        "aspirational_salary": {
          "company": "Amazon",
          "salary": 4000000
        }
      },
      {
        "fit": 32
      }
    ],
    "chips": [
      "Python",
      "CNN",
      "RNN",
      "Pytorch",
      "ChatGPT",
      "TensorFlow",
      "Neural Networks"
    ]
  }
}

const careerPathData = {
    "recommended_careers": [
      {"id": 1, "name": "Application Analyst"},
      {"id": 2, "name": "Business Intelligence Analyst"},
      {"id": 3, "name": "Data Architect"},
      {"id": 4, "name": "Database Manager"},
      {"id": 5, "name": "Data Warehouse Developer"},
      {"id": 6, "name": "Graphic Designer"},
      {"id": 7, "name": "Information Security Analyst"},
      {"id": 8, "name": "Mobile App Developer"},
      {"id": 9, "name": "Network Security Engineer"},
      {"id": 10, "name": "Programmer Analyst"},
      // {"id": 11, "name": "Software Developer"},
      // {"id": 12, "name": "Web Developer"},
      // {"id": 13, "name": "Data Scientist"},
      // {"id": 14, "name": "Data Engineer"},
      // {"id": 15, "name": "Full Stack Developer"}
    ]
  };
// export const getPathData = (career_id) => {
//     return async (dispatch) => {
//       dispatch(setPathData({reportsData: careerPathData}));
//     }
//   }
  
export const getPathData = (testId) => {
  return async (dispatch) => {
    dispatch(setPathData({ loadingCareerpath:true,careerPathData:[],errorLoadingCareerPath:false }))
    const fetchData = async () => {
      let endpoint = `https://3e4r3nopbi.execute-api.ap-south-1.amazonaws.com/prod/studentCareerRecommendations?career_id=None&student_id=${getLoggedInUser()?.user_id}`
      let options = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        redirect: 'follow',
      }

      const response = await axios.get(endpoint,options)

      if (response?.data?.statusCode !== 200) {
        dispatch(setPathData({ loadingCareerpath:false,careerPathData:[],errorLoadingCareerPath:true }))
        throw response
      }
      console.log(response?.data?.body)
      dispatch(setPathData({ loadingCareerpath:false,careerPathData:response?.data?.body,errorLoadingCareerPath:false }))
      return response
    }

    try {
   
      const fetchedData = await fetchData()
    
      return fetchedData
    } catch (error) {
      console.log(error)
      return error
    }
  }
}

export const getCareerData = (career_id) => {
  return async (dispatch) => {
    const fetchData = async () => {
      dispatch(setCareerData({careerData: [],loadingCareerData:true,errorLoadingCareerData:false}));
      let endpoint = `https://3e4r3nopbi.execute-api.ap-south-1.amazonaws.com/prod/studentCareerRecommendations?career_id=${career_id}&student_id=22801`
      let options = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        redirect: 'follow',
      }

      const response = await axios.get(endpoint,options)

      if (response?.data?.statusCode !== 200) {
        dispatch(setCareerData({careerData: [],loadingCareerData:false,errorLoadingCareerData:true}));
        throw response
      }
     console.log( response?.data)
      dispatch(setCareerData({careerData: response?.data?.body,loadingCareerData:false,errorLoadingCareerData:false}));

      return response
    }

    try {
   
      const fetchedData = await fetchData()
    
      return fetchedData
    } catch (error) {
      console.log(error)
      dispatch(setSearchData({ reportsData: 'error' }))
   
      return error
    }
   
  }
}


export const getSearchData = (text) => {
  return async (dispatch) => {
    const fetchData = async () => {
      // dispatch(setPathData({ searchData:[],loading:true,error:false }))
      dispatch(setSearchData({ searchData: [],loading:true,error:false }))
      let endpoint = `${BASE_API_URL}/careerPath/searchCareersAndSkills?search_text=${text}`
      let options = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        redirect: 'follow',
      }

      const response = await axios.get(endpoint,options)

      if (response?.data?.status !== 200) {
        dispatch(setSearchData({ searchData:[],loading:false,error:true }))
        throw response
      }

      dispatch(setSearchData({ searchData: response?.data?.data,loading:false,error:false }))

      return response
    }

    try {
   
      const fetchedData = await fetchData()
    
      return fetchedData
    } catch (error) {
      console.log(error)
      dispatch(setSearchData({ reportsData: 'error' }))
   
      return error
    }
  }
}


export const addSearchQuery = (text, career_id) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const endpoint = `${BASE_API_URL}/careerPath/addSearchQuery`
      const formData = new FormData();
      formData.append('keyword', text);
      formData.append('career_id', career_id);
      formData.append('category_id', category_id);

      const options = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${getAccessToken()}`,
        },
        body: formData,
        redirect: 'follow',
      }

      try {
        const response = await axios.post(endpoint, formData, options);

        return response;
      } catch (error) {
        console.log(error);
        return error;
      }
    };

    try {
      const fetchedData = await fetchData();
      return fetchedData;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}


export const getRecentSearchesData = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      dispatch(setRecentSearchesData({ recentSearchesData: [],loading:true,error:false }))
      let endpoint = `${BASE_API_URL}/careerPath/getRecentSearches?category_id=${category_id}`
      let options = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        redirect: 'follow',
      }

      const response = await axios.get(endpoint,options)

      if (response?.data?.status !== 200) {
        dispatch(setRecentSearchesData({ recentSearchesData:[],loading:false,error:true }))
        throw response
      }

      dispatch(setRecentSearchesData({ recentSearchesData: response?.data?.data,loading:false,error:false }))

      return response
    }

    try {
   
      const fetchedData = await fetchData()
    
      return fetchedData
    } catch (error) {
      console.log(error)
      dispatch(setSearchData({ reportsData: 'error' }))
   
      return error
    }
  }
  }
export const setIntrestedCareer = (career_id,intrested) => {
  return async (dispatch) => {
    const fetchData = async () => {
      // dispatch(setPathData({ searchData:[],loading:true,error:false }))
      // dispatch(setSearchData({ searchData: [],loading:true,error:false }))
      let endpoint = `${BASE_API_URL}/careerPath/careerIntrested?career_id=${career_id}&intrested=${intrested}`
      let options = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        redirect: 'follow',
      }

      const response = await axios.get(endpoint,options)

      if (response?.data?.status !== 200) {
        // dispatch(setSearchData({ searchData:[],loading:false,error:true }))
        throw response
      }

      // dispatch(setSearchData({ searchData: response?.data?.data,loading:false,error:false }))

      return response
    }

    try {
   
      const fetchedData = await fetchData()
    
      return fetchedData
    } catch (error) {
      console.log(error)
      dispatch(setSearchData({ reportsData: 'error' }))
   
      return error
    }
  }
}

