import React, { Component } from "react";
import AppLayout from "../../../components/Layout/Layout";
import Flex from "styled-flex-component";
import { connect } from "react-redux";
import StudentCourseDetails from "../../../components/studentCourseDetails";
import CourseMenu from "../../../components/CourseMenu";
import { getPracticeAssessmentsAC } from "../../../store/actions/courseAssessmentActions";
import moment from "moment";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const AssessmentDataDiv = styled.div`
  width: 75%;
  margin-left: 80px;

  @media (max-width: 930px) {
    margin-left: 40px;
  }
`;

const ListAssessDiv = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1100px) {
    display: block;
    margin-bottom: 20px;
  }
`;

const DateDiv = styled.div`
  width: 10%;
  margin: 10px 2.5%;

  @media (max-width: 930px) {
    width: 12%;
    margin: auto 2.5%;
  }
`;

const AssessNameDiv = styled.div`
  width: 15%;
  margin: 10px 2.5%;

  @media (max-width: 930px) {
    width: 18%;
    margin: auto 2.5%;
  }
`;

const CorrectAnswerDiv = styled.div`
  width: 10%;
  height: 30px;
  margin: auto 10px;
  text-align: center;
  border-radius: 50px;
  background-color: lightgreen;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
`;

const WrongAnswerDiv = styled.div`
  width: 10%;
  height: 30px;
  margin: 5px 10px;
  display: inline;
  text-align: center;
  border-radius: 50px;
  background-color: lightcoral;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
`;

const AssessmentStatusDiv = styled.div`
  width: 10%;
  margin: 10px 2.5%;

  @media (max-width: 930px) {
    width: 10%;
    margin: auto 2.5%;
  }
`;

const AssessActionDiv = styled.div`
  width: 15%;
  margin: 10px 2.5%;

  @media (max-width: 930px) {
    width: 20%;
    margin: auto 2.5%;
  }
`;

const LeftArrowDiv = styled.div`
  width: 30%;
  margin-top: -10px;

  @media (max-width: 930px) {
    width: 50%;
    margin: auto;
  }
`;

const AssessActionDataDiv = styled.div`
  width: 70%;
  @media (max-width: 930px) {
    width: 50%;
    margin: auto;
  }
`;

class AssessmentDashboard extends Component {
  componentDidMount = async () => {
    document.body.style.backgroundColor = "#f5f5f5";
    console.log("calling action dispatcher");
    let subject_id = this.props.location.state.subject_id;
    // await this.props.getPracticeAssessments(subject_id);
  };

  onMenuBarClicked = (event, page) => {
    event.preventDefault();
    event.stopPropagation();
    if (page === "content") {
      this.props.history.push({
        pathname: "/course/content",
        state: { subject_id: this.props.subject_id },
      });
    } else if (page === "assessment") {
      this.props.history.push({
        pathname: "/course/assessment",
        state: { subject_id: this.props.subject_id },
      });
    } else if (page === "practice") {
      this.props.history.push({
        pathname: "/course/practice",
        state: { subject_id: this.props.subject_id },
      });
    }
  };
  startAssessment = (id) => {
    this.props.history.push({
      pathname: "/startCoding",
      state: {
        test_id: id,
        practice: true,
      },
    });
  };

  onQuestionClick = (tid) => {
    const assessment = this.props.assessments.find((a) => a.id === tid);
    if (
      assessment &&
      moment() >= moment(assessment.start_time) &&
      assessment.active_status
    )
      this.startAssessment(tid);
  };

  goToResults = (tid) => {
    const assessment = this.props.assessments.find((a) => a.id === tid);
    if (assessment && moment() >= moment(assessment.doe))
      this.props.history.push({
        pathname: "practice/results",
        state: { test_id: tid },
      });
  };

  goToResults = (tid) => {
    const assessment = this.props.assessments.find((a) => a.id === tid);
    if (
      assessment &&
      ((assessment.end_time && moment().isAfter(moment(assessment.end_time))) ||
        assessment.problems.some((p) => p.completion_status))
    )
      this.props.history.push({
        pathname: "practice/results",
        state: { test_id: tid },
      });
  };

  render() {
    console.log(this.props);
    // if (this.props.loading_assessments ) {
    //     return <div>Loading...</div>
    // } else if (this.props.error_loading_assessments && !this.props.loading_assessments) {
    //     return <div>Error Loading Assessments</div>
    // } else if (this.props.no_assessments && !this.props.loading_assessments) {
    return (
      <div style={{ overflow: "hidden", background: "white" }} className="row">
        <AppLayout></AppLayout>
        <div className="col-lg-9">
          <StudentCourseDetails
            name={this.props.student_name}
          ></StudentCourseDetails>
          <div className="mainBox">
            <Flex column>
              <div style={{ width: "100%" }}>
                <CourseMenu
                  onMenuBarClicked={this.onMenuBarClicked}
                  active_page="practice"
                ></CourseMenu>
              </div>
              <div
                style={{
                  width: "75%",
                  margin: "auto",
                  marginLeft: "80px",
                  marginRight: "20px",
                }}
              >
                <p style={{ textAlign: "center" }}>
                  We are currently upgrading this module. Will get back to you
                  shortly
                </p>
                {/* <div style={{textAlign: 'center'}}> There are no Practice Series in this subject </div> */}
              </div>
            </Flex>
          </div>
        </div>
        <style jsx="true" scoped>
          {`
            .mainBox {
              display: block;
              width: 100%;
              background-color: white;
              border-radius: 8px;
              margin-top: 20px;
              text-align: left;
              padding: 15px;
              box-sizing: border-box;
              box-shadow: 0 0 10px #ccc;
            }
          `}
        </style>
      </div>
    );
    // }
    const current_time = moment();
    // console.log(current_time);
    return (
      <div style={{ overflow: "hidden" }}>
        <AppLayout></AppLayout>
        <div
          style={{
            margin: "auto",
            marginLeft: "2%",
            position: "absolute",
            top: "96px",
            width: "96%",
          }}
        >
          <StudentCourseDetails
            name={this.props.student_name}
          ></StudentCourseDetails>
          <div className="mainBox">
            <Flex row>
              <div style={{ width: "20%" }}>
                <CourseMenu
                  onMenuBarClicked={this.onMenuBarClicked}
                  active_page="practice"
                ></CourseMenu>
              </div>
              <AssessmentDataDiv
                style={{ marginRight: "20px", marginTop: "20px" }}
              >
                <ListAssessDiv>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "left",
                      fontWeight: "500",
                    }}
                  >
                    List of Practice Series
                  </div>
                </ListAssessDiv>
                <div>
                  {this.props.assessments.map((assessment) => {
                    let hours = Math.floor(assessment.timelimit / (60 * 60));
                    let seconds = assessment.timelimit - hours * 60 * 60;
                    let minutes;
                    if (seconds > 60) {
                      minutes = Math.floor(seconds / 60);
                      seconds = seconds - minutes * 60;
                    }
                    // let timelimit = minutes && hours ? hours + 'hrs ' + minutes + 'mins' : minutes && !hours ? minutes + 'mins' :hours + 'hrs';
                    return (
                      <div
                        key={assessment.id}
                        className="assessmentBox codAssess"
                      >
                        <AssessNameDiv
                          style={{ padding: "15px 0", wordBreak: "break-all" }}
                        >
                          {assessment.name}
                        </AssessNameDiv>
                        {/* <AssessNameDiv style={{padding: '15px 0'}}>{assessment.description}</AssessNameDiv> */}
                        <DateDiv style={{ padding: "15px 0" }}>
                          {moment(assessment.start_time).format("Do MMMM YYYY")}
                        </DateDiv>
                        <DateDiv style={{ padding: "15px 0" }}>
                          {assessment.end_time
                            ? moment(assessment.end_time).format("Do MMMM YYYY")
                            : null}
                        </DateDiv>
                        {assessment.problems.some(
                          (p) => !p.completion_status
                        ) ? (
                          <AssessmentStatusDiv
                            style={{ color: "red", padding: "15px 0" }}
                          >
                            Incomplete
                          </AssessmentStatusDiv>
                        ) : (
                          <AssessmentStatusDiv
                            style={{ color: "green", padding: "15px 0" }}
                          >
                            Completed
                          </AssessmentStatusDiv>
                        )}
                        <div className="row w-50 d-flex align-items-center">
                          {assessment.problems.map((pr, i) => {
                            if (pr.completion_status)
                              return (
                                <CorrectAnswerDiv key={pr.id}>
                                  Q {i + 1}
                                </CorrectAnswerDiv>
                              );
                            else
                              return (
                                <WrongAnswerDiv
                                  key={pr.id}
                                  onClick={() =>
                                    this.onQuestionClick(assessment.id)
                                  }
                                >
                                  Q {i + 1}
                                </WrongAnswerDiv>
                              );
                          })}
                        </div>
                        {(assessment.end_time &&
                          moment(assessment.end_time) < current_time) ||
                        assessment.problems.some((p) => p.completion_status) ? (
                          <AssessActionDiv
                            style={{
                              padding: "15px 0",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <AssessActionDataDiv>
                              View Results
                            </AssessActionDataDiv>
                            <LeftArrowDiv
                              onClick={() => this.goToResults(assessment.id)}
                              style={{ height: "38px" }}
                            >
                              <img
                                width="38px"
                                height="38px"
                                src="/images/arrow_with_circle.svg"
                                alt="go to results"
                              ></img>
                            </LeftArrowDiv>
                          </AssessActionDiv>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </AssessmentDataDiv>
            </Flex>
          </div>
        </div>
        <style jsx="true" scoped>
          {`
            .assessmentBox {
              display: flex;
              flex-direction: row;
              width: 100%;
              background-color: white;
              border-radius: 8px;
              margin-top: 20px;
              text-align: left;
              box-sizing: border-box;
              box-shadow: 0 0 10px #ccc;
              font-size: 14px;
              border-left: 5px solid transparent;
              background-image: linear-gradient(#fff, #fff),
                linear-gradient(
                  144.48deg,
                  #f38fa2 9.88%,
                  #eb5c6f 54.05%,
                  #f1825c 116.8%
                );
              background-origin: border-box;
              background-clip: content-box, border-box;
            }
            .objAssess {
              background-image: linear-gradient(#fff, #fff),
                linear-gradient(
                  144.48deg,
                  #f38fa2 9.88%,
                  #eb5c6f 54.05%,
                  #f1825c 116.8%
                );
            }
            .subAssess {
              background-image: linear-gradient(#fff, #fff),
                linear-gradient(
                  149.32deg,
                  #a0f4ff -46.18%,
                  #75dae9 -17.1%,
                  #8459f8 97.41%
                );
            }
            .codAssess {
              background-image: linear-gradient(#fff, #fff),
                linear-gradient(
                  243.37deg,
                  #dcff73 -38.07%,
                  #a9d868 37.1%,
                  #8eca6b 101.61%
                );
            }
            .mainBox {
              display: block;
              width: 100%;
              background-color: white;
              border-radius: 8px;
              margin-top: 20px;
              text-align: left;
              padding: 15px;
              box-sizing: border-box;
              box-shadow: 0 0 10px #ccc;
            }
            .objGradient {
              width: 11px;
              height: 11px;
              background: linear-gradient(
                144.48deg,
                #f38fa2 9.88%,
                #eb5c6f 54.05%,
                #f1825c 116.8%
              );
              border-radius: 50%;
              margin-top: 5.5px;
              margin-right: 10px;
            }
            .subGradient {
              width: 11px;
              height: 11px;
              background: linear-gradient(
                149.32deg,
                #a0f4ff -46.18%,
                #75dae9 -17.1%,
                #8459f8 97.41%
              );
              border-radius: 50%;
              margin-top: 5.5px;
              margin-right: 10px;
            }
            .codGradient {
              width: 11px;
              height: 11px;
              background: linear-gradient(
                243.37deg,
                #dcff73 -38.07%,
                #a9d868 37.1%,
                #8eca6b 101.61%
              );
              border-radius: 50%;
              margin-top: 5.5px;
              margin-right: 10px;
            }
            .assignGradient {
              width: 11px;
              height: 11px;
              background: linear-gradient(
                243.37deg,
                #c207ff -38.07%,
                #bf58e1c9 37.1%,
                #dbb8e1 101.61%
              );
              border-radius: 50%;
              margin-top: 5.5px;
              margin-right: 10px;
            }
          `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    subject_id: state.cad.subject_id,
    student_name: state.cad.student_name,
    assessments: state.cad.assessments,
    graphData: state.cad.graphData,
    loading_assessments: state.cad.loading_assessments,
    error_loading_assessments: state.cad.error_loading_assessments,
    total_correct_answered_questions:
      state.cad.total_correct_answered_questions,
    total_questions: state.cad.total_questions,
    total_assessments: state.cad.total_assessments,
    taken_assessments: state.cad.taken_assessments,
    no_assessments: state.cad.no_assessments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPracticeAssessments: (subject_id) =>
      dispatch(getPracticeAssessmentsAC(subject_id)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssessmentDashboard)
);
