import { useContext } from "react";
import RemedialContext from "../utils/context/Remedial/RemedialContext";
import { remedialIntialState } from "../utils/context/Remedial/RemedialReducer";


export const useRemedialContext = () => {
    const context = useContext(RemedialContext);
    if (context === undefined) {
        return { ...remedialIntialState }
    }
    return context
}