import { useEffect, useRef, useState } from "react";
import "./chatroom.css";
// import notification from "./Notification.mp3";
import { AiOutlineSend } from "react-icons/ai";
import firebase from "firebase";
import React, { Component } from "react";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGESENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
};

firebase.initializeApp(firebaseConfig);
export var db = firebase.database();
let user_id;
let username;
let getlocalstorage = localStorage.getItem("user");
// const audio = new Audio(process.env.PUBLIC_URL + "/Ringtones/Notification.mp3");
export let messages = [];
if (localStorage.getItem("user")) {
  getlocalstorage = JSON.parse(getlocalstorage);
  username = getlocalstorage["name"];
  user_id = getlocalstorage["user_id"];
} else {
  username = "";
  user_id = "";
}
function Chatwidget(props) {
  const bottomRef = useRef(null);
  const [msgrecieved, setmsgrecieved] = useState("");
  const firstUpdate = useRef(true);
  const deletechat = useRef(false);
   if (props.deletechat) {
      db.ref(props.roomid).remove();
      deletechat.current = true;
    }
   console.log(props)
  useEffect(() => {
    db.ref(props.roomid).on("value", (snapshot) => {
      messages = [];
      snapshot.forEach((snap) => {
        messages.push(snap.val());
      });

      // console.log(messages);
      setmsgrecieved(messages);
      setTimeout(() => {
        // document
        //   .getElementById("scrollintoview")
        //   .scrollIntoView({ behavior: "smooth" });
        const scrollToView = document.getElementById("scrollintoview");
        if (scrollToView) {
          scrollToView.scrollIntoView({ behavior: "smooth" });
        }
      }, 1000);
      if (firstUpdate.current) {
        firstUpdate.current = false;
        // console.log("first update chat");
      } else if (!props.deletechat) {
        // console.log("New message found");
        props.foundnewmsg();
        // audio.play();
      }
    });
  }, []);

  // setmsgrecieved(true);
  const [msg, setFormValue] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();
    const timestamp = Date.now();
    db.ref(props.roomid).push({
      msg: msg,
      ts: `${timestamp}`,
      name: username,
      id: `${user_id}`,
    });

    setFormValue("");
    // bottomRef.current.scrollIntoView({ behavior: "smooth" });

    setTimeout(() => {
      // document
      //   .getElementById("scrollintoview")
      //   .scrollIntoView({ behavior: "smooth" });
      const scrollToView = document.getElementById("scrollintoview");
      if (scrollToView) {
        scrollToView.scrollIntoView({ behavior: "smooth" });
      }
    }, 10);
  };

  return (
    <>
      <div id="conversation" class="conversationclassName">
        {messages &&
          messages.map((msg) => (
            <ChatMessage key={msg.timestamp} message={msg} />
          ))}
        <div ref={bottomRef} id="scrollintoview" />
      </div>

      <form onSubmit={sendMessage}>
        <div style={{ display: "flex", background: "white" }}>
          <input
            className="sendmessage form-control"
            value={msg}
            onChange={(e) => setFormValue(e.target.value)}
            placeholder="Chat with your team"
          />
          <button type="submit" disabled={!msg}>
            <AiOutlineSend style={{ fontSize: "40px" }} />
          </button>
        </div>
      </form>
    </>
  );
}

function ChatMessage(props) {
  const { msg, id, ts, name } = props.message;
  let date = new Date(parseInt(ts));

  let hours = date.getHours();
  let minutes = date.getMinutes();
  const messageClass = parseInt(id) == user_id ? "sent" : "received";

  return (
    <>
      <div class={`msg ${messageClass}`}>
        <div class="msg-bubble">
          <div class="msg-info">
            <div class="msg-info-name">{name}</div>
            <div class="msg-info-time">{`${hours}:${minutes}`}</div>
          </div>

          <div class="msg-text">{msg}</div>
        </div>
      </div>

      {/* <p>{content}</p>*/}
    </>
  );
}
export default Chatwidget;
