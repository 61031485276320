import React, { useState, useEffect,useRef } from 'react';
import { Box, Fade, Grid, Typography, Stack, Slide } from '@mui/material';
import CustomVerticalStepper from '../../../components/common/CustomVerticalStepper';
import AutomaticSwiper from '../../../components/common/AutomaticSwiper';
import { useDispatch, useSelector } from 'react-redux';
import { getCareerData } from '../../../store/actions/careerPathActions';
import MuiCustomChip from '../../../components/common/Elements/MuiCustomChip';
import SalaryAccordion from '../SalaryAccordion';
import CustomizedTreeView from './CustomizedTreeView';
import SkillsAccordion from './SkillsAccordian';
import CustomCircularProgressBar from '../../../components/common/CustomCircularProgressBar';
import { CareerDeveloper } from '../../../assets/careerPath/CareerDeveloper';
import MuiCustomLinearProgress from '../../../components/common/MuiCustomLinearProgress';
import { AnimatedNumber } from '../../../lib/AnimatedNumber';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { motion, AnimatePresence } from 'framer-motion';
import { Puzzle } from '../../../assets/careerPath/Puzzle';
import { Puzzletwo } from '../../../assets/careerPath/Puzzletwo';
import ScrollingNumber from '../../../lib/ScrollingNumber';
const styles = Object.freeze({
    opacity: "0",
    transform: "translateY(-50%)",
    transition: "all 0.5s",
  });
const CareerContent = ({ careerId, onAccordianhandle }) => {
    const puzzeleSvg=useRef()
    const [data, setData] = useState({});
    const [salaryRanges, setSalaryRanges] = useState([]);
    const [medianSalary, setMedianSalary] = useState(false);
    const [showcontent, setShowContent] = useState(false);
    const [hidepuzzele, setHidePuzzele] = useState(false);
    const [stylingChange, setStylingChange] = useState(false);
    const [animationRunning, setAnimationRunning] = useState(true);
    const [slidesData, setSlidesData] = useState([
        { id: 1, type: 'avatars', secondaryText: 'people have shown interest' },
        { id: 2, type: 'text', primaryText: '', secondaryText: 'Average Salary In Amazon' },
        { id: 3, type: 'text', primaryText: '', secondaryText: 'This career fits you' }
    ]);
    const [skills, setSkills] = useState([]);
    const [progress, setProgress] = React.useState(0);
    const [progressIndex, setProgressIndex] = useState(0);
    const progressArray=[10,100,20,90,30,100,40,80,10,65,25,80]
    const dispatch = useDispatch();
    const { careerData,loadingCareerData} = useSelector((state) => state.careerpath);
    // const loadingCareerData=true
useEffect(() => {
    if (!loadingCareerData) {
        const firstTimer = setTimeout(() => {
            setHidePuzzele(true);
        }, 1000);
        
        const secondTimer = setTimeout(() => {
            setShowContent(true);
        }, 2300);

        // Clear both timeouts if the component unmounts or if loadingCareerData changes
        return () => {
            clearTimeout(firstTimer);
            clearTimeout(secondTimer);
        };
    }
}, [loadingCareerData]);

useEffect(() => {
    if (hidepuzzele) {
        const firstTimer = setTimeout(() => {
            console.log('hello-----------------------')
            setStylingChange(true);
        }, 800);
        return () => {
            clearTimeout(firstTimer);
        };
    }
}, [hidepuzzele]);


   

    useEffect(() => {
        if (careerId) {
            dispatch(getCareerData(careerId))
        }
    }, [careerId]);

    useEffect(() => {
        if (careerData && Object.keys(careerData).length > 0) {
            setData(careerData);
            setSkills(careerData?.skills);
            setMedianSalary(careerData?.salary_range[1])
            setSalaryRanges([
                {
                    package: `${careerData?.salary_range[0]} L.P.A`,
                    role: 'Entry Level',
                },
                {
                    package: `${careerData?.salary_range[1]} L.P.A`,
                    role: 'Junior Level',
                },
                {
                    package: `${careerData?.salary_range[2]} L.P.A`,
                    role: 'Associate',
                },
                {
                    package: `${careerData?.salary_range[3]} L.P.A`,
                    role: 'Senior',
                },
            ])
            if (careerData?.scroll && careerData?.scroll.length <= 3) {
                const slides_matter = careerData?.scroll;
                let temp_slides_data = [];
                if (slides_matter[0]?.interested) {
                    temp_slides_data.push({ id: 1, type: 'avatars', secondaryText: `${slides_matter[0]?.interested}+ people have shown interest` });
                }
                if (slides_matter[1]?.aspirational_salary) {
                    temp_slides_data.push({ id: 2, type: 'text', primaryText: `${slides_matter[1]?.aspirational_salary.salary}/yr`, secondaryText: `Average salary in ${slides_matter[1]?.aspirational_salary.company}` });
                }
                if (slides_matter[2]?.fit) {
                    temp_slides_data.push({ id: 3, type: 'text', primaryText: `${slides_matter[2]?.fit}%`, secondaryText: `This career fits you` });
                }
                setSlidesData(temp_slides_data);
            }
        }
    }, [careerData]);

    const onAccordiaHandle = (open) => {
        onAccordianhandle(open)
    }

   

    useEffect(() => {
        const timer = setInterval(() => {
          setProgressIndex((prevIndex) => (prevIndex + 1) % progressArray.length);
        }, 300);
    
        return () => {
          clearInterval(timer);
        };
      }, []);
    
      useEffect(() => {
        setProgress(progressArray[progressIndex]);
      }, [progressIndex]);

    return (
        <>
          
            <Grid container direction='column' justifyContent={'flex-start'} alignItems='flex-start' flexWrap='nowrap' gap='25px' sx={{ padding: '15px', width: '400px' }}>



                <Grid container direction={'row'} justifyContent='space-between' alignItems='flex-start'>
                    {showcontent && (
                        
                        <motion.div initial={{ y: window.innerHeight }} animate={{ y: 0 }} transition={{ duration: 0.9,delay:0.7 }}>
                        <Grid item>
                            <Typography variant='h5' sx={{ color: (theme) => theme.palette.grey[900] }} >{data?.job?.name}</Typography>
                        </Grid>
                        </motion.div>
                        )}

<Box sx={!showcontent ?{margin:'auto',display: 'flex', alignItems: 'center', flexDirection: 'column',mt:'9rem' }:{display: 'flex'}}>
                        {/* {!showcontent &&( */}
                        <Puzzletwo show={hidepuzzele} showcontent={showcontent} />
                        {/* )} */}
                       
                     <motion.div layout={'position'}   initial={{opacity: 0}} animate={{opacity:1}} transition={{ duration: 0.8 }}>
                     <Grid item>
                            <MuiCustomLinearProgress sx={{ mt: 2 }} value={loadingCareerData ? progress : data?.fit} tooltip={!showcontent ? false : true} type='career' width={!showcontent ?140:53} />
                            </Grid>
                        </motion.div>
                      <AnimatePresence>
                        {!hidepuzzele && (
                            <motion.div exit={{ opacity: 0 }} initial={{opacity: 0}} animate={{opacity:1}} >
                                <Box sx={{ mt: 2.5 }}>
                                    <Typography variant='h1' component={'span'} sx={{ color: (theme) => theme.palette.primary.main }}>Career fits {loadingCareerData ? progress : data?.fit} %</Typography>
                                </Box>
                            </motion.div>
                        )}
                        </AnimatePresence>
                    </Box>


                </Grid>


                {showcontent && data && Object.keys(data).length > 0 ?
                    <motion.div initial={{ y: window.innerHeight }} animate={{ y: 0 }} transition={{ duration: 0.9,delay:0.7 }}>
                        <Grid item >
                            <Stack direction={'row'} gap={1.2} flexWrap={'wrap'}>
                                {data?.chips.map((chip, index) => (
                                    <MuiCustomChip key={index} label={chip} variant="filled" />
                                ))}
                            </Stack>
                        </Grid>
                    </motion.div>
                    : null}
                {showcontent && (
                    <motion.div initial={{ y: window.innerHeight }} animate={{ y: 0 }} transition={{ duration: 0.9,delay:0.7 }}>
                        <Grid item>
                            <SalaryAccordion
                                title={`${medianSalary}/yr`}
                                secondary={'Median Salary'}
                            >
                                <CustomVerticalStepper activeStep={1} steps={salaryRanges} />
                            </SalaryAccordion>
                        </Grid>
                    </motion.div>
                )}
                {showcontent && (
                    <motion.div initial={{ y: window.innerHeight }} animate={{ y: 0 }} transition={{ duration: 0.9,delay:0.7 }}>
                        <Grid item>
                            <Box sx={{ height: '32px', width: '300px', overflow: 'hidden', background: '#fff' }}>
                                <AutomaticSwiper
                                    slides_data={slidesData}
                                    type={'text-box'}
                                />
                            </Box>
                        </Grid>
                    </motion.div>
                )}
                {showcontent && (
                    <motion.div style={{width:'100%'}} initial={{ y: window.innerHeight }} animate={{ y: 0 }} transition={{ duration: 0.9,delay:0.7 }}>
                        <Grid item >
                            <SkillsAccordion title={'Required Skills'} onHandle={onAccordiaHandle}>
                                <CustomizedTreeView id={"panel2a-header"} skills={skills} />
                            </SkillsAccordion>
                        </Grid>
                    </motion.div>
                )}

            </Grid>


        </>
    );
}

export default CareerContent;

