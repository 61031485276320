import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from './Timer.module.css';

class Timer extends Component {

  constructor(props) {
    super(props);
    this.state = { time: {}, seconds: this.props.seconds };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });

    this.startTimer()
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
    }

    this.props.onTimerUpdate(seconds)
  }

  render() {
    let hour = this.state.time.h > 0 ? String(this.state.time.h).padStart(2, '0') + ":" : null
    let min = String(this.state.time.m).padStart(2, '0')
    let sec = String(this.state.time.s).padStart(2, '0')
    return (
      <div className={styles.Timer}>
        {/* <button onClick={this.startTimer}>Start</button> */}
        {hour}{min}:{sec}
      </div>
    );
  }

}

Timer.propTypes = {};

Timer.defaultProps = {};

export default Timer;
