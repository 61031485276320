export const CompetitiveIcon = ({backgroundColor}) => {
    return (
        <svg width="36" height="10" viewBox="0 0 36 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 5H22" stroke={backgroundColor ? backgroundColor : "black"} stroke-width="3"/>
        <path d="M10 0L10 10" stroke={backgroundColor ? backgroundColor : "black"} stroke-width="3"/>
        <path d="M6 1L6 9" stroke={backgroundColor ? backgroundColor : "black"} stroke-width="3"/>
        <path d="M2 2L2 8" stroke={backgroundColor ? backgroundColor : "black"} stroke-width="3"/>
        <path d="M26 0L26 10" stroke={backgroundColor ? backgroundColor : "black"} stroke-width="3"/>
        <path d="M30 1L30 9" stroke={backgroundColor ? backgroundColor : "black"} stroke-width="3"/>
        <path d="M34 2L34 8" stroke={backgroundColor ? backgroundColor : "black"} stroke-width="3"/>
        </svg>
    )
}