import axios from "../../interceptor";

const http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
// const BASE_API_URL = "https://studentpython.edwisely.com";
const CORS = "https://cors-anywhere.herokuapp.com/";

export function setGlobalToken() {
  try {
    let token = localStorage.getItem("token");
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } catch (error) {
    console.log(error);
  }
}

export const GETCLASSROOMACTIVITY = "GET_CLASSROOM_ACTIVITY";
export const ONCOMMENTADDITION = "ON_COMMENT_ADDITION";
export const POSTCOMMENT = "POST_COMMENT";
export const LOADMORECOMMENTS = "LOAD_MORE_COMMENTS";
export const GETFEEDBACK = "GET_FEEDBACK";
export const SUBMITFEEDBACK = "SUBMIT_FEEDBACK";
export const JOINED_CHALLENGE = "JOINED_CHALLENGE";

export const getClassroomActivityA = (res, err) => {
  return {
    type: GETCLASSROOMACTIVITY,
    res: res,
    error: err,
  };
};

export const getFeedbackA = (res, id, err) => {
  return {
    type: GETFEEDBACK,
    res: res,
    id: id,
    error: err,
  };
};

export const submitFeedbackA = (res, err) => {
  return {
    type: SUBMITFEEDBACK,
    res: res,
    error: err,
  };
};

export const postCommentA = (id, comment, type, err) => {
  console.log("inside post comment a ");
  return {
    type: POSTCOMMENT,
    id: id,
    comment: comment,
    item_type: type,
    err: err,
  };
};

export const loadMoreCommentsA = (id, res, type, err) => {
  return {
    type: LOADMORECOMMENTS,
    id: id,
    res: res,
    item_type: type,
    err: err,
  };
};
export const joinChallenge = (res, err) => {
  console.log(res);
  return {
    type: JOINED_CHALLENGE,
    response: res,
    error: err,
  };
};
export const postCommentAC = (id, comment, type) => {
  setGlobalToken();
  return (dispatch, getState) => {
    let formdata = new FormData();
    let endpoint;
    if (type == "Notification") {
      formdata.append("notification_id", id);
      formdata.append("comment", comment);
      endpoint = "commentNotification";
    } else if (type == "Material") {
      formdata.append("material_id", id);
      formdata.append("comment", comment);
      endpoint = "commentMaterial";
    } else if (type == "Feedback") {
      formdata.append("survey_id", id);
      formdata.append("comment", comment);
      endpoint = "commentSurvey";
    }
    http
      .post(`${BASE_API_URL}/college/v2/${endpoint}`, formdata)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.status == 200) {
            console.log("inside data status ");
            dispatch(postCommentA(id, comment, type, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(`${BASE_API_URL}/college/v2/${endpoint}`, formdata)
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(postCommentA(id, comment, type, false));
                      } else {
                        dispatch(postCommentA(id, comment, type, true));
                      }
                    } else {
                      dispatch(postCommentA(id, comment, type, true));
                    }
                  });
              })
              .catch((err) => {
                dispatch(postCommentA(id, comment, type, true));
              });
          } else {
            dispatch(postCommentA(id, comment, type, true));
          }
        } else {
          dispatch(postCommentA(id, comment, type, true));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(postCommentA(id, comment, type, true));
      });
  };
};

export const getClassroomActivityAC = (from_date) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    let formdata = new FormData();
    formdata.append("from_date", from_date);
    formdata.append("delta_days", 100);
    http
      .post(`${BASE_API_URL}/college/v4/getAllList`, formdata)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            dispatch(getClassroomActivityA(res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(`${BASE_API_URL}/college/v4/getAllList`, formdata)
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getClassroomActivityA(res.data, false));
                      } else {
                        dispatch(getClassroomActivityA(null, true));
                      }
                    } else {
                      dispatch(getClassroomActivityA(null, true));
                    }
                  })
                  .catch((err) => {
                    dispatch(getClassroomActivityA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(getClassroomActivityA(null, true));
          }
        } else {
          dispatch(getClassroomActivityA(null, true));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(getClassroomActivityA(null, true));
      });
  };
};

export const loadMoreCommentsAC = (id, type) => {
  setGlobalToken();
  return (dispatch, getState) => {
    const formdata = new FormData();
    let endpoint;
    if (type == "Notification") {
      formdata.append("notifications_id", id);
      endpoint = "getNotificationComments";
    } else if (type == "Material") {
      formdata.append("materials_id", id);
      endpoint = "getMaterialComments";
    } else if (type == "Feedback") {
      formdata.append("survey_id", id);
      endpoint = "getSurveyComments";
    }
    http
      .post(`${BASE_API_URL}/college/v2/${endpoint}`, formdata)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.status == 200) {
            console.log("inside data status ");
            dispatch(loadMoreCommentsA(id, res.data, type, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(`${BASE_API_URL}/college/v2/${endpoint}`, formdata)
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(loadMoreCommentsA(id, res.data, type, false));
                      } else {
                        dispatch(loadMoreCommentsA(id, null, type, true));
                      }
                    } else {
                      dispatch(loadMoreCommentsA(id, null, type, true));
                    }
                  });
              })
              .catch((err) => {
                dispatch(loadMoreCommentsA(id, null, type, true));
              });
          } else {
            dispatch(loadMoreCommentsA(id, null, type, true));
          }
        } else {
          dispatch(loadMoreCommentsA(id, null, type, true));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(loadMoreCommentsA(id, null, type, true));
      });
  };
};

export const getFeedbackAC = (id) => {
  setGlobalToken();
  return (dispatch, getState) => {
    const formdata = new FormData();
    formdata.append("feedback_id", id);
    http
      .post(`${BASE_API_URL}/feedback/v2/getFeedback`, formdata)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.status == 200) {
            console.log("inside data status ");
            dispatch(getFeedbackA(res.data, id, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            const refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(`${BASE_API_URL}/feedback/v2/getFeedback`, formdata)
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(getFeedbackA(res.data, id, false));
                      } else {
                        dispatch(getFeedbackA(null, id, true));
                      }
                    } else {
                      dispatch(getFeedbackA(null, id, true));
                    }
                  });
              })
              .catch((err) => {
                dispatch(getFeedbackA(null, id, true));
              });
          } else {
            dispatch(getFeedbackA(null, id, true));
          }
        } else {
          dispatch(getFeedbackA(null, id, true));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getFeedbackA(null, id, true));
      });
  };
};

export const submitFeedbackAC = () => {
  setGlobalToken();
  return (dispatch, getState) => {
    const formdata = new FormData();
    const feedback_id = getState().class.feedback_data?.feedback_id;
    const feedback_answers = getState().class.feedback_answers;
    // const answers = {};
    // feedback_answers.forEach((ans, index)=> answers[feedback_data.feedback_questions[index].id] = ans);
    // feedback_answers.forEach((ans, index)=> answers[feedback_data.feedback_questions[index].id] = ans[feedback_data.feedback_questions[index].id]);
    formdata.append("survey_id", feedback_id);
    formdata.append("question_answers", JSON.stringify(feedback_answers));
    http
      .post(`${BASE_API_URL}/college/v2/submitSurvey`, formdata)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.status == 200) {
            console.log("inside data status ");
            dispatch(submitFeedbackA(res.data, false));
          } else if (
            res.data.status == 401 &&
            res.data.message == "Signature expired. Please log in again."
          ) {
            let refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(
                    `${CORS}${BASE_API_URL}/college/v2/submitSurvey`,
                    formdata
                  )
                  .then((res) => {
                    if (res.status == 200) {
                      if (res.data.status == 200) {
                        dispatch(submitFeedbackA(res.data, false));
                      } else {
                        dispatch(submitFeedbackA(null, true));
                      }
                    } else {
                      dispatch(submitFeedbackA(null, true));
                    }
                  });
              })
              .catch((err) => {
                dispatch(submitFeedbackA(null, true));
              });
          } else {
            dispatch(submitFeedbackA(null, true));
          }
        } else {
          dispatch(submitFeedbackA(null, true));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(submitFeedbackA(null, true));
      });
  };
};

export const EnrollweeklyChallenge = (challengeid) => {
  console.log(challengeid);
  setGlobalToken();
  return (dispatch, getState) => {
    http
      .get(
        `${BASE_API_URL}/weekly_challenge/enrollWeeklyChallenge?weekly_challenge_id=${challengeid}&enrollment_type=0`
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            dispatch(joinChallenge(res.data.message, true));
          } else if (
            res.data.status === 401 &&
            res.data.message === "Signature expired. Please log in again."
          ) {
            const refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .get(
                    `${BASE_API_URL}/weekly_challenge/enrollWeeklyChallenge?weekly_challenge_id=${challengeid}`
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data.status === 200) {
                      } else {
                        dispatch(joinChallenge(res.data, false));
                      }
                    } else {
                      dispatch(joinChallenge(null, false));
                    }
                  })
                  .catch((err) => {
                    dispatch(joinChallenge(null, false));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            // dispatch(joinChallenge(res.data, true))
          }
        } else {
          dispatch(joinChallenge(null, false));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(joinChallenge(null, false));
      });
  };
};
