import React from 'react';
import styles from './AnalysisMeter.module.css';
const AnalysisMeter = (props) => {
console.log(props)
  let min = ((props.comp.students / props.comp.max) * 100 - 30);
  let max = 100 - min - 16
  // let min = props.comp.min;
  // let max = props.comp.max;

  return (<div className={styles.AnalysisMeter} >
    <div className={styles.likart} style={{ width: '100%' }}>
      <div style={{ width: `${min}%` }}>
        <div className={styles.ticker}>{props.comp.min}</div>
      </div>
      <div style={{  }}>
        <div className={styles.likartTicker}>{props.comp.students}</div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', width: `${max}%` }}>
        <div className={styles.ticker}>{props.comp.max}</div>
      </div>
    </div>
    <div className={styles.likartBar}>

    </div>
  </div>)
};


AnalysisMeter.defaultProps = {};

export default AnalysisMeter;