import React, {useState} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Fade, Grid } from '@mui/material';

const SalaryAccordion = ({ secondary, title, children }) => {
  const [isExpanded2, setIsExpanded2] = useState(false);
  
  return (
    <>
      <Accordion
        expanded={isExpanded2}
        onChange={() => setIsExpanded2(!isExpanded2)}
        sx={{
          border: 'none',
          boxShadow: 'none',
          backgroundColor: 'transparent',
          m: '0px !important',
          '& .MuiAccordionSummary-root': {
            padding: '0px',
            transition: 'none',
            gap: '7.5px',
            height: '51px',
          },
          '& .MuiAccordionSummary-content': {
            transition: 'none',
            margin: '0px',
          },
          '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
            height: '51px',
            minHeight: '51px',
          }
        }}
        >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container direction='column' justifyContent='space-between' alignItems='flex-start' gap='0px'>
            <Grid item>
              <Typography variant='caption2' sx={{ color: (theme) => theme.palette.grey[600] }}>
                {secondary}
              </Typography>
            </Grid>
            <Grid item>
            <Typography variant='h6' sx={{ color: (theme) => theme.palette.primary.main }}>
                ₹{title}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <Fade in={isExpanded2} timeout={1000}>
          <AccordionDetails sx={{padding: 0}}>
            {children}
          </AccordionDetails>
        </Fade>
      </Accordion>
    </>
  );
}

export default SalaryAccordion;