import axios from '../interceptor';
const baseURL = ''
//Set the logged in user data in local session 
const setLoggeedInUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
}

// Gets the logged in user data from local session 
const getLoggedInUser = () => {
  const user = localStorage.getItem('user');
  if (user)
    return JSON.parse(user);
  return null;
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
}



// Login Method
const postLogin = (url, data) => {
  return axios.post(url, data).then(response => {
    if (response.status === 400 || response.status === 500)
      throw response.data;
    return response.data;
  }).catch(err => {
    throw err[1];
  });
}

export { setLoggeedInUser, getLoggedInUser, isUserAuthenticated, postLogin }