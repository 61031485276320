import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Collapse } from '@mui/material';
import { getPersonalityQuestionAtTriggerAC, submitPersonalityQuestionAC } from '../../store/actions/personalityAssessmentActions';
import { withRouter, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Question from './question';
import colors from '../../pages/PersonalityAssessment/colors';
import './personalityPopoverStyles.css';
import { closeNotification } from '../../store/actions/commonActions';

const slideInStyle = {
    transform: 'translateY(0)',
    transition: 'transform 0.3s ease-in-out',
  };
  
  const slideOutStyle = {
    transform: 'translateY(-100%)',
    transition: 'transform 0.3s ease-in-out',
  };

const PersonalityPopover = (props) => {
    const [contextView, setContextView] = useState(true);
    const [username, setUsername] = useState('');
    const [answered, setAnswered] = useState(false);
    const [question, setQuestion] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setUsername(user.name);
    }, []);

    // useEffect(() => {
    //     const getPersonalityQuestionAtTriggerA = async(trigger_id) => await props.getPersonalityQuestionAtTrigger(trigger_id);
    //     getPersonalityQuestionAtTriggerA(props?.triggerId);
    // }, []);

    useEffect(() => {
        if (props.popover_question) {
            setQuestion(props.popover_question);
        }
        // else {
        //     const getPersonalityQuestionAtTriggerA = async(trigger_id) => await props.getPersonalityQuestionAtTrigger(trigger_id);
        //     getPersonalityQuestionAtTriggerA(props?.triggerId);
        // }
    }, [props.popover_question]);

    useEffect(() => {
        if (props.question_submitted === true) {
            setAnswered(true);
        }
    }, [props.question_submitted]);

    const handleAnswerSelection = (id, option_id) => {
        const submitPersonalityQuestionA = async(trigger_id, question_id, option_id) => await props.submitPersonalityQuestion(trigger_id, question_id, option_id);
        submitPersonalityQuestionA(props.trigger_id, id, option_id);
    }

    const goToPersonalityPage = () => {
        dispatch(closeNotification());
        history.push('/personalityAssessment');
    }

    return (
        <>
            <Collapse timeout={400} in={contextView} className={contextView ? '': 'view-to-top-animation'}>
                <Box 
                    sx={{
                        margin: 'auto',
                        padding: '30px 38px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly'
                    }}
                >
                        {/* <Typography variant='h5' color={colors.primary} sx={{marginBottom: '10px'}}>Hey, {username}!</Typography> */}
                        <Typography variant='h3' color={colors.primary} sx={{margin: '0px 0px 15px 0px', textAlign: 'center'}}>Join us on a journey of self discovery</Typography>
                        <Button 
                            variant="contained" 
                            size='small' 
                            onClick={() => setContextView(false)} 
                            sx={{
                                margin: 'auto', 
                                background: colors.secondary, 
                                color: colors.primary,
                                borderRadius: '5px', 
                                width: "160px",
                                '&:hover': {
                                    background: colors.primary,
                                    color: colors.secondary,
                                },
                            }}
                        >
                            <Typography variant='body4'>Lets get started</Typography>
                        </Button>
                </Box>
            </Collapse>

            <Collapse timeout={400} in={!contextView && question && !answered} className={!contextView && !answered ? 'bottom-to-view-animation': 'view-to-top-animation'}>
            {question ? 
                <Box
                    sx={{
                        margin: 'auto',
                        paddingTop: '20px',
                        textAlign: 'left'
                    }}
                >
                    <Typography variant="body4" color={colors.primary} sx={{margin: '0px 40px'}}>I See Myself as Someone Who...</Typography>
                    <Question 
                    id={question.question_id}
                    name={question.name}
                    option={question.option_id}
                    handleAnswerSelection={handleAnswerSelection}
                    type={'inPopover'}
                    />
                </Box>
                :null}
            </Collapse>
            <Collapse timeout={400} in={!contextView && question && answered} className={!contextView && answered ? 'bottom-to-view-animation': 'view-to-bottom-animation'}>
                {question ? 
                <Box 
                    sx={{
                        margin: 'auto',
                        padding: '20px 0px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly'
                    }}
                >
                        <Box sx={{margin: 'auto'}}>
                            <img src={`/gifs/personality_question_success.gif`} width="75px" alt="Success"/>
                        </Box>
                        <Typography variant="h3" color={colors.primary} textAlign="center">Awesome!</Typography>
                        <Button 
                            variant="contained" 
                            onClick={goToPersonalityPage} 
                            sx={{
                                background: colors.secondary, 
                                color: colors.primary,
                                borderRadius: '5px', 
                                width: '120px', 
                                margin: 'auto',
                                '&:hover': {
                                    background: colors.primary,
                                    color: colors.secondary,
                                },
                            }}
                        >
                            <Typography variant='body1'>Keep going</Typography>
                        </Button>
                </Box>
                :null}
            </Collapse>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        popover_question: state.personalityAssessment.popover_question,
        question_submitted: state.personalityAssessment.question_submitted,
        loading_popover_question: state.personalityAssessment.loading_popover_question,
        error_loading_popover_question: state.personalityAssessment.error_loading_popover_question,
        error_submitting_question: state.personalityAssessment.error_submitting_question,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // getPersonalityQuestionAtTrigger: (trigger_id) => dispatch(getPersonalityQuestionAtTriggerAC(trigger_id)), 
        submitPersonalityQuestion: (trigger_id, question_id, option_id) => dispatch(submitPersonalityQuestionAC(trigger_id, question_id, option_id)),
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PersonalityPopover)
);