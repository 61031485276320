import { Stack, Typography } from '@mui/material';
import React, {useState} from 'react';
import CustomCircularProgressBar from '../../components/common/CustomCircularProgressBar';
import MuiCustomLinearProgress from '../../components/common/MuiCustomLinearProgress';

const TreeLabel = ({title, percent}) => {
    return (
        <>
            <Stack direction={'row'} spacing={0.5} justifyContent='space-between' alignItems='center' sx={{marginLeft: '-5px'}}>
                <Typography variant='body1' sx={{color: (theme) => theme.palette.grey[900]}}>{title}</Typography>
                <MuiCustomLinearProgress value={Math.round(percent)} width={25} tooltip={true} />
                {/* <CustomCircularProgressBar radius={12.5} percentage={percent} /> */}
            </Stack>
        </>
    )
}

export default TreeLabel;