import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { useEffect, useRef, useState } from "react";
import Rating from '@mui/material/Rating';
import { GiConfirmed } from "react-icons/gi";
import LoadingButton from '@mui/lab/LoadingButton';
import { getSubjectDataAC, submitUnitFeedbackQuestionAC } from "../../store/actions/unitFeedbacksActions";
import SideUnitNav from "./SideUnitNav";
import { Link } from "react-scroll";
import { Box, Stack, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import './unitfeedbacks.css'
import { IoMdInformationCircleOutline } from "react-icons/io";

const UnitFeedbacks = (props) => {
    const [unitChanged, setUnitChanged] = useState(0);
    const [currentUnit, setCurrentUnit] = useState({});
    const [facultyTab, setFacultyTab] = useState(1);
    const [feedbackData, setFeedbackData] = useState({});

    const {subjectId, unitId} = useParams();
    
    useEffect(() => {
        if (subjectId) {
            props.getSubjectData(subjectId, unitId);
        }
    }, []);

    useEffect(() => {
        if (Object.keys(props.unit_data).length > 0) {
            if (Object.keys(currentUnit).length === 0 || currentUnit?.unit_id !== props.unit_data?.unit_id) {
                let currentFacultyData = {};

                for (const faculty of props.unit_data.faculty) {
                    const hasUnansweredQuestion = faculty.questions.some(question => question.submission_count === 0);
                
                    if (hasUnansweredQuestion && faculty.is_active === 1) {
                        currentFacultyData = faculty;
                        break;
                    }
                }
                if (Object.keys(currentFacultyData).length > 0) {
                    setFacultyTab(currentFacultyData.feedback_id);
                    setFeedbackData(currentFacultyData);
                }
                else {
                    const activeFaculty = props.unit_data?.faculty.find(fac => fac.is_active === 1);
                    if (activeFaculty) {
                        setFacultyTab(activeFaculty.feedback_id);
                        setFeedbackData(activeFaculty);
                    }
                    else {
                        setFacultyTab(props.unit_data?.faculty[0]?.feedback_id);
                        setFeedbackData(props.unit_data?.faculty[0]);
                    }
                }
            }
        }
        setCurrentUnit(props.unit_data);
        console.log(props.unit_data);
        window.scrollTo(0, 0);
        // setUnitChanged(unitChanged + 1);
    }, [props.unit_data]);

    useEffect(() => {
        if (props.faculty_switch) {
            let currentFacultyData = {};
            for (const faculty of props.unit_data.faculty) {
                const completed = faculty.faculty_completed_first_time;
            
                if (completed === 0 && faculty.is_active === 1) {
                    currentFacultyData = faculty;
                    break;
                }
            }
            if (Object.keys(currentFacultyData).length > 0) {
                setFacultyTab(currentFacultyData.feedback_id);
                setFeedbackData(currentFacultyData);
            }
            else {
                const activeFaculty = props.unit_data?.faculty.find(fac => fac.is_active === 1);
                if (activeFaculty) {
                    setFacultyTab(activeFaculty.feedback_id);
                    setFeedbackData(activeFaculty);
                }
                else {
                    setFacultyTab(props.unit_data?.faculty[0]?.feedback_id);
                    setFeedbackData(props.unit_data?.faculty[0]);
                }
            }
            setCurrentUnit(props.unit_data);
            console.log(props.unit_data);
            window.scrollTo(0, 0);
        }
    }, [props.faculty_switch]);

    const handleBack = () => {
        window.location.href='/home';
    }

    const handleUnitChange = (unit_id) => {
        console.log(`unit id - ${unit_id}`);
        props.unitSwitch(currentUnit.unit_id, currentUnit);
        props.getUnitData(unit_id);
    }

    const handleTabChange = (feedback_id) => {
        if (feedback_id !== facultyTab) {
            setFacultyTab(feedback_id);
            const feedback = props.unit_data.faculty.find(fac => fac.feedback_id === feedback_id);
            if (feedback) {
                setFeedbackData(feedback);
                props.unitSwitch(currentUnit.unit_id, currentUnit);
            }
        }
    }

    const facultyRating = (rating, feedback_id, question_id) => {
        if (rating !== null) {
            props.submitQuestion(feedback_id, question_id, rating);
            console.log(`feedback - ${feedback_id}, question - ${question_id}, rating - ${rating}`);
            const updatedQuestionId = question_id; 
            const updatedValue = rating; 
            const questionIndex = feedbackData.questions.findIndex(question => question.id === updatedQuestionId);
            
            if (questionIndex !== -1) {
                const updatedFeedbackData = {
                    ...feedbackData,
                    questions: [...feedbackData.questions] 
                };
            
                updatedFeedbackData.questions[questionIndex] = {
                    ...updatedFeedbackData.questions[questionIndex],
                    value: updatedValue,
                    submission_count: updatedFeedbackData.questions[questionIndex].submission_count + 1
                };
                setFeedbackData(updatedFeedbackData);

                //updating current unit
                const facultyIndexToUpdate = currentUnit.faculty.findIndex(faculty => faculty.feedback_id === feedback_id);
                if (facultyIndexToUpdate !== -1) {
                    const updatedCurrentUnit = {
                        ...currentUnit,
                        faculty: [...currentUnit.faculty],
                    };
                    updatedCurrentUnit.faculty[facultyIndexToUpdate] = updatedFeedbackData;
                    setCurrentUnit(updatedCurrentUnit);
                    if (updatedCurrentUnit.completed_first_time === 0) {
                        const isUnitCompleted = updatedCurrentUnit.faculty
                        .filter(faculty => faculty.is_active === 1)
                        .every(faculty => {
                            return faculty.questions.every(question => question.submission_count > 0);
                        });
                        if (isUnitCompleted) {
                            updatedCurrentUnit.is_completed = 1;
                            updatedCurrentUnit.completed_first_time = 1;
                            props.unitCompleted(updatedCurrentUnit.unit_id, updatedCurrentUnit);                    
                        }
                        else {
                            if (updatedCurrentUnit.faculty[facultyIndexToUpdate].faculty_completed_first_time === 0) {
                                const isFacultyCompleted = updatedFeedbackData.questions.every(question => question.submission_count > 0);
                                if (isFacultyCompleted) {
                                    updatedCurrentUnit.faculty[facultyIndexToUpdate].faculty_completed_first_time = 1;
                                    setCurrentUnit(updatedCurrentUnit);
                                    props.facultyCompleted(updatedCurrentUnit.unit_id, updatedCurrentUnit);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return (
        <>
            <div style={{overflowX: 'hidden'}}>
                {!props.loading && Object.keys(currentUnit).length > 0 ?
                    <div className="row">
                        <div className="col-2 p-0">
                            <SideUnitNav unit={currentUnit} units_names_and_ids={props.units_names_and_ids} unitChanged={unitChanged} handleUnitChange={handleUnitChange}/>
                        </div>
                        <div className="col-10 p-0">
                            <div className="row col-lg-12 col-md-12 p-0 m-0">
                                <header className="unit-feedback-nav1">
                                    <div
                                        style={{ padding: 13, fontSize: 16, fontWeight: 600, textAlign: "left", display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '12.5px'}}
                                    >
                                        <img src={'/images/back_arrow_black.svg'} alt='Back' onClick={handleBack} style={{cursor: 'pointer', paddingBottom: '1.5px'}} />
                                        <span>{currentUnit.subject_name}</span>
                                        {props.all_units_submitted ? 
                                            <button style={{background: '#fff', border: '1px solid #000', borderRadius: '10px', fontSize: 12}}>
                                                All feedbacks submitted
                                            </button> 
                                        : null}
                                    </div>
                                    <Stack direction={'row'} spacing={0} sx={{width: '100%', height: '75px'}}>
                                        {currentUnit.faculty.map(item => (
                                            <Box 
                                                onClick={() => handleTabChange(item.feedback_id)} 
                                                sx={{
                                                    p: 3, 
                                                    cursor: 'pointer', 
                                                    border: 'none', 
                                                    borderBottom: facultyTab === item.feedback_id ? '1px solid blue' : 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    pointerEvents: item.is_active === 1 ? 'auto' : 'none'
                                                }}>
                                                    <Typography 
                                                        variant='body2'
                                                        sx={{
                                                            color: facultyTab === item.feedback_id ? (theme) => theme.palette.primary.main : item.is_active === 1 ? '#000' : '#919eab'
                                                        }}
                                                    >
                                                        {item.is_active === 1 ? `${item.faculty_name} - ${currentUnit.unit_name}` : item.is_active === 0 ? `${item.faculty_name} - ${currentUnit.unit_name} (Yet to Start)` : item.is_active === 2 ? `${item.faculty_name} - ${currentUnit.unit_name} (Expired)` : `${item.faculty_name} - ${currentUnit.unit_name}`} 
                                                    </Typography>
                                            </Box>
                                        ))}
                                    </Stack>
                                </header>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div style={{border: '1px solid #ccc', borderRadius: '10px', display: 'inline-flex', marginTop: '10px', marginBottom: '0px', padding: '10px 20px', gap: '5px'}}>
                                            <IoMdInformationCircleOutline style={{fontSize: '14px'}}/>
                                            <Typography variant="caption1" color='#000'>Each question is auto-submitted once the rating is given, and rating can only be given twice for a question</Typography>
                                        </div>
                                        {Object.keys(feedbackData).length > 0 && feedbackData.questions.map((question,index) => (
                                            <div className="row eachquestion" key={question.id}>
                                                <div className="col-lg-7" style={{ marginTop: "19px", textAlign: 'left', fontSize: '13px'  }}>{index+1}) &nbsp;{question.question}</div>
                                                <Tooltip
                                                    title={question.submission_count >= 2 ? 'Question cannot be submitted more than twice' : ''} 
                                                    placement='right' 
                                                    arrow
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                bgcolor: '#212B36', 
                                                                color: '#F4F6F8',
                                                            }
                                                        },
                                                        arrow: {
                                                            sx: {
                                                                color: '#212B36'
                                                            }
                                                        }
                                                    }}
                                                >
                                                <div className="col-lg-5" style={{ marginTop: "19px",textAlign:"left"}}>
                                                    {/* {question.submission_count < 2 ? */}
                                                        <Rating name="half-rating" defaultValue={question.value}
                                                        precision={0.5} size="large"
                                                        sx={{fontSize: '2.5rem'}} 
                                                        value={question.value}
                                                        disabled={question.submission_count > 1}
                                                        onChange={(event, newHover) => {
                                                            facultyRating(newHover, facultyTab, question.id)
                                                        }}
                                                        />
                                                        {/* :
                                                        <Rating name="half-rating" defaultValue={question.value}
                                                        precision={0.5} size="large"
                                                        value={question.value}
                                                        sx={{fontSize: '2.5rem'}} 
                                                        disabled
                                                        />
                                                    } */}
                                                </div>
                                                </Tooltip>
                                            </div>
                                        ))}
                                    </div>
                                    {props.all_units_submitted ?
                                        <button id='go_to_dashboard_btn' onClick={handleBack} style={{position: 'fixed', width: '300px', padding: '10px 20px', bottom: '20px', left: '50%', transform: 'translateX(-50%)'}}>
                                            All Feedbacks Submitted, Go To Dashboard
                                        </button>
                                    : null}
                                </div>
                            </div>
                        </div>
                    </div>
                : 
                    <div>Loading</div>
                }
            </div>
        </>
    )
}
function mapStateToProps(state) {
    return {
        loading: state.unitFeedback.loading,
        feedback_data: state.unitFeedback.feedback_data,
        unit_data: state.unitFeedback.unit_data,
        units_names_and_ids: state.unitFeedback.units_names_and_ids,
        faculty_switch: state.unitFeedback.faculty_switch,
        all_units_submitted: state.unitFeedback.all_units_submitted,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getSubjectData: (subject_semester_id, unit_id) => dispatch(getSubjectDataAC(subject_semester_id, unit_id)),
        unitSwitch: (prev_unit_id, unit_data) => dispatch({type: "SWITCH_FEEDBACK_UNIT", prev_unit_id: prev_unit_id, unit_data: unit_data}),
        getUnitData: (unit_id) => dispatch({type: "GET_UNIT_FEEDBACK_DATA", unit_id: unit_id}),
        unitCompleted: (unit_id, unit_data) => dispatch({type: "FEEDBACK_OF_A_UNIT_COMPLETED", unit_id: unit_id, unit_data: unit_data}),
        facultyCompleted: (unit_id, unit_data) => dispatch({type: "FEEDBACK_OF_A_FACULTY_IN_A_UNIT_COMPLETED", unit_id: unit_id, unit_data: unit_data}),
        submitQuestion: (feedback_id, question_id, rating) => dispatch(submitUnitFeedbackQuestionAC(feedback_id, question_id, rating)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UnitFeedbacks)
);