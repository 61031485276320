import { keyframes } from "@emotion/core";
import { Box } from "@mui/material";

const moveSideways = keyframes`
  0% {
    transform: translateY(-2px); // Start position
  }
  50% {
    transform: translateY(2px); // Middle position (move to the right)
  }
  100% {
    transform: translateY(-2px); // End position (back to start)
  }
`;

export const HintBulb = ({backgroundColor}) => {
    return (
        <Box sx={{animation: `${moveSideways} 0.8s ease-in-out infinite`,}}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.875 18.125H13.125" stroke={backgroundColor ? backgroundColor : "#212B36"} stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.14873 13.0477C5.40556 12.4673 4.80363 11.726 4.38818 10.8794C3.97273 10.0329 3.75457 9.10321 3.7501 8.16025C3.73138 4.77257 6.4625 1.95646 9.8493 1.87675C11.1617 1.84515 12.4507 2.22758 13.5335 2.96981C14.6164 3.71203 15.438 4.77635 15.8819 6.01181C16.3257 7.24726 16.3694 8.59112 16.0065 9.85275C15.6437 11.1144 14.8928 12.2297 13.8604 13.0406C13.633 13.2168 13.4486 13.4424 13.3212 13.7003C13.1938 13.9582 13.1267 14.2417 13.125 14.5293L13.125 15C13.125 15.1657 13.0592 15.3247 12.9419 15.4419C12.8247 15.5591 12.6658 15.625 12.5 15.625H7.49999C7.33423 15.625 7.17526 15.5591 7.05805 15.4419C6.94084 15.3247 6.87499 15.1657 6.87499 15L6.87498 14.5288C6.87427 14.243 6.80851 13.9611 6.68268 13.7045C6.55685 13.4479 6.37425 13.2233 6.14873 13.0477V13.0477Z" stroke={backgroundColor ? backgroundColor : "#212B36"} stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.6348 4.43625C11.3992 4.56619 12.1045 4.93029 12.6531 5.47826C13.2018 6.02624 13.5667 6.73104 13.6976 7.49534" stroke={backgroundColor ? backgroundColor : "#212B36"} stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </Box>
    )
}