import React from "react";
import styled from "styled-components";
import Flex from "styled-flex-component";

const ModalWrapper = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.1);
`;

const ModalTextWrapper = styled(Flex)`
  width: 60%;
//   padding: 2em;
  border: 1px solid #ccc;
  margin: auto;
  background: #fff;
  margin-top: 120px;
  height: 80%;

`;

const Modal1 = ({ open, url, close , template, size}) => {
    
    // console.log(url);
    return <ModalWrapper center open={open} onClick={close} style={{size}}>
        <ModalTextWrapper onClick={()=>{}} column>
            <div style={{fontSize: '16px', margin: 'auto'}}>{url}</div>
        </ModalTextWrapper>
    </ModalWrapper>
};

export default Modal1;