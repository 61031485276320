import React, { useEffect, useState } from 'react'
import RemedialCard from '../RemedialCard/RemedialCard'
import { Stack, Typography, useTheme } from '@mui/material'
import wheelSvg from './../../../../assets/remedial/svg/fortune-wheel.svg'
import './spinWheelCard.css'
import RedmedialButton from '../RemedialButtons/RedmedialButton'
import SpinWheelModal from '../SpinWheelModal/SpinWheelModal'
import useFetch from '../../../../hooks/useFetch'
import { useRemedialContext } from '../../hooks/useRemedial'

const SpinWheelCard = ({ width = '100%', height = '100%', isActive, updateBanner, getCategoryEndPoint, setCategoryEndPoint, isCompleted }) => {
  const { timer,setSpinWheelData,flukePercentage } = useRemedialContext();
  console.log(flukePercentage+"-------------------------------------------------------------")
  const [showSpinModal, setShowSpinModal] = useState(false);
  const theme = useTheme()


  const { data } = useFetch(getCategoryEndPoint);


  const handleDataForSpinWheel = (data) => {
    let arrayData = [];
    data.forEach((item) => {
      arrayData.push({ option: item.category_name, id: item.category_id })
    })
    return arrayData
  }

  useEffect(() => {
    if (data) {
      setSpinWheelData(handleDataForSpinWheel(data))
    }
  }, [data])

  return <>
    <RemedialCard width={width} height={height}>
      <Stack gap={'20px'}>
        <Stack direction={'row'} gap={'8px'}>
          <img src={wheelSvg} width={20} height={20} alt='clover-leaf' />
          <Typography
            component={'h4'}
            sx={{
              fontFamily: 'Poppins-SemiBold',
              fontSize: '16px',
              color: 'grey.900'
            }}>
            Spin Wheel
          </Typography>
        </Stack>
        <Stack gap={'24px'} direction={'row'}>
          {isActive ? <EnabledWheel /> : <DisabledWheel />}

          <Stack gap={'8px'} width={'100%'}>
            <Typography
              component={'h4'}
              sx={{
                fontFamily: 'Poppins-Medium',
                fontSize: '12px',
                color: 'grey.600',
                textAlign: 'start',
                span: {
                  fontFamily: 'Poppins-SemiBold',
                  fontSize: '12px',
                  color: 'grey.800'
                }
              }}>
               
              {isCompleted ? <>
                You have completed the remedial process,<span> see your spin result </span> again.
              </>
                  //Change it back to original (before Vinay's change)
                  :(isActive || !isActive) && timer==0  ? <><span>You have not unlocked the Wheel</span>, Time is up!</> 
                
              
                : flukePercentage<65 ?
                <>
                {
                  isActive ? <><span>You have unlocked the Wheel</span>, Spin it  NOW!</> : 
                  <>To Unlock the Spin Wheel you must learn <span>75% of the study</span> material below.</> 
                }
                </>:flukePercentage>65 ?<><span>Your Fluke is greater than 65%</span>, Remedial test is not activated</>:null }
            </Typography>
            {isCompleted ? <Stack direction={'row'} display={'flex'} alignItems={'center'} justifyContent={'center'} gap={'5px'} >
              <Typography
                component={'h4'}
                sx={{
                  fontFamily: 'Poppins-Medium',
                  fontSize: '12px',
                  cursor: 'pointer',
                  color: theme => theme.palette.primary[800]
                }}
                onClick={() => updateBanner()}
                >
                View Spin Result
              </Typography>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                <path d="M9.99093 12.0679L14.0376 8.02128L9.99093 3.97461" stroke={theme.palette.primary[800]} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2.70432 8.02148L13.9243 8.02148" stroke={theme.palette.primary[800]} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </Stack>
              :
              <RedmedialButton onClick={() => setShowSpinModal(true)} color="externalerror.200" size='medium' borderRadius={5} disable={!isActive || timer==0 ||flukePercentage>65} >Spin</RedmedialButton>
            }
          </Stack>

        </Stack>
      </Stack>
    </RemedialCard>

    {showSpinModal && <SpinWheelModal updateBanner={updateBanner} show={showSpinModal} onCloseHandle={() => { setShowSpinModal(false) }} />}
  </>
}

export default SpinWheelCard


const DisabledWheel = () => {
  return <>

    <svg width="74" height="79" viewBox="0 0 74 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.87 24.4971C10.5172 24.0075 9.2869 23.438 8.40278 22.9005C7.43344 22.3098 7.01268 21.8308 7.1405 21.4796C7.26833 21.1284 7.90213 21.0272 9.02592 21.1869C10.0592 21.3359 11.3694 21.6818 12.7169 22.1714C14.0643 22.661 15.2947 23.2305 16.1788 23.7786C17.1428 24.3747 17.5689 24.859 17.441 25.2102C17.3132 25.5614 16.6794 25.6572 15.561 25.4923C14.5384 25.3433 13.2282 24.9867 11.8753 24.4971H11.87ZM12.621 22.4216C11.2895 21.9373 10.0006 21.5967 8.98331 21.453C7.82224 21.288 7.43343 21.453 7.39083 21.5701C7.29496 21.8255 8.07256 22.3843 8.54125 22.6717C9.40939 23.2039 10.6237 23.7573 11.9606 24.2416C13.2974 24.7259 14.5863 25.0718 15.5929 25.2209C16.1415 25.3007 17.0895 25.3699 17.1801 25.1144C17.2227 24.992 17.0309 24.6248 16.0296 24.0075C15.1562 23.4646 13.9418 22.9059 12.6157 22.4216H12.621Z" fill="#3EB1B5" />
      <path d="M37.4614 42.5918L70.9675 43.72C70.7544 49.2122 68.9968 54.5553 65.8758 59.2066C62.7547 63.8578 58.3714 67.6683 53.1679 70.244L37.4614 42.5918Z" fill="#DFE3E8" />
      <path d="M53.0618 70.5941L36.9932 42.3086L71.2395 43.4634L71.2289 43.7295C71.0158 49.2642 69.2369 54.6659 66.0893 59.3544C62.9416 64.043 58.5156 67.8907 53.2802 70.4824L53.0565 70.5941H53.0618ZM37.9252 42.8727L53.2748 69.8917C58.3292 67.3425 62.606 63.6012 65.6525 59.0564C68.6937 54.5222 70.4353 49.3121 70.6909 43.9743L37.9305 42.8727H37.9252Z" fill="white" />
      <path d="M37.4614 42.5904L55.261 16.0664C60.2462 18.9828 64.3259 23.0753 67.0848 27.9288C69.8437 32.7823 71.1805 38.2265 70.9675 43.7186L37.4614 42.5904Z" fill="#919EAB" />
      <path d="M71.2233 43.9955L36.9717 42.8407L55.176 15.71L55.3944 15.8377C60.4168 18.7753 64.5392 22.9104 67.314 27.7958C70.0942 32.6866 71.447 38.1947 71.234 43.7294L71.2233 43.9955ZM37.9517 42.3457L70.712 43.4474C70.8665 38.1042 69.535 32.7877 66.8507 28.0619C64.161 23.3255 60.1825 19.3075 55.3411 16.4284L37.9517 42.3457Z" fill="white" />
      <path d="M37.4618 42.0592L21.7554 14.4069C26.9536 11.8312 32.7909 10.5805 38.6708 10.7775C44.5508 10.9744 50.2762 12.6135 55.2614 15.5298L37.4618 42.0539V42.0592Z" fill="#DFE3E8" />
      <path d="M37.4403 42.5641L21.3823 14.2946L21.6326 14.1721C26.8628 11.5804 32.764 10.3138 38.6759 10.516C44.5932 10.713 50.3719 12.3734 55.3943 15.3057L55.6393 15.4494L37.4456 42.5641H37.4403ZM22.1226 14.5234L37.4776 41.553L54.8777 15.6197C49.9937 12.8151 44.3908 11.2345 38.66 11.0429C32.9185 10.8513 27.2143 12.0541 22.128 14.5181L22.1226 14.5234Z" fill="white" />
      <path d="M37.4611 42.5902L3.95508 41.462C4.16812 35.9699 5.92571 30.6267 9.04676 25.9755C12.1678 21.3242 16.5511 17.5138 21.7547 14.938L37.4611 42.5902Z" fill="#919EAB" />
      <path d="M37.9304 42.873L3.68408 41.7235L3.69473 41.4574C3.90777 35.9227 5.68667 30.521 8.83435 25.8325C11.982 21.1439 16.408 17.2963 21.6434 14.7045L21.8671 14.5928L37.9358 42.8783L37.9304 42.873ZM4.23799 41.2073L36.9984 42.3089L21.6488 15.2899C16.5944 17.8391 12.3176 21.5803 9.27108 26.1252C6.22992 30.6594 4.49364 35.8695 4.23266 41.2073H4.23799Z" fill="white" />
      <path d="M37.4616 42.5911L19.6621 69.1151C14.6769 66.1988 10.5972 62.1063 7.83827 57.2528C5.07939 52.3993 3.74256 46.955 3.9556 41.4629L37.4616 42.5911Z" fill="#DFE3E8" />
      <path d="M19.7477 69.4716L19.5294 69.3439C14.5069 66.4062 10.3846 62.2711 7.60971 57.3857C4.82952 52.4949 3.47671 46.9868 3.68975 41.4521L3.70041 41.186L37.9521 42.3409L19.7477 69.4716ZM4.21703 41.7395C4.06258 47.0826 5.39408 52.3991 8.0784 57.1249C10.768 61.8614 14.7466 65.8793 19.5879 68.7585L36.9774 42.8411L4.21703 41.7395Z" fill="white" />
      <path d="M37.4617 42.5918L53.1681 70.244C47.9699 72.8198 42.1326 74.0704 36.2527 73.8735C30.3727 73.6766 24.6473 72.0375 19.6621 69.1211L37.4617 42.5971V42.5918Z" fill="#919EAB" />
      <path d="M37.4561 74.1606C37.0513 74.1606 36.6465 74.1553 36.2471 74.1393C30.3299 73.9424 24.5511 72.282 19.5287 69.3497L19.2837 69.206L37.4774 42.0859L53.5354 70.3555L53.2851 70.4779C48.4117 72.8887 42.9632 74.1553 37.4561 74.1553V74.1606ZM20.0453 69.0304C24.9293 71.835 30.5323 73.4156 36.2631 73.6072C42.0045 73.7987 47.7087 72.596 52.7951 70.132L37.4401 43.1024L20.04 69.0357L20.0453 69.0304Z" fill="white" />
      <g opacity="0.2">
        <path d="M71.1005 49.4672C70.9354 50.2229 70.749 50.9786 70.5359 51.7236C67.9688 60.7175 62.0515 68.1734 53.8761 72.7236C45.7006 77.2684 36.2469 78.3647 27.2513 75.7996C18.6497 73.3463 11.385 67.769 6.79932 60.0896C2.23492 52.4474 0.75428 43.4588 2.62904 34.7843C2.79415 34.0286 2.98056 33.2729 3.1936 32.5278C5.76075 23.5339 11.678 16.078 19.8534 11.5278C28.0289 6.983 37.4826 5.8867 46.4782 8.45183C55.0798 10.9052 62.3445 16.4825 66.9302 24.1619C71.4946 31.804 72.9752 40.7926 71.1005 49.4672ZM7.78996 35.8859C4.49848 51.1382 13.6805 66.4385 28.6999 70.7226C44.465 75.2195 60.9597 66.0447 65.4602 50.2761C65.6413 49.6428 65.8011 48.9989 65.9395 48.3602C69.231 33.1079 60.049 17.8076 45.0296 13.5235C29.2645 9.02659 12.7698 18.2014 8.26931 33.97C8.08823 34.6033 7.92844 35.2473 7.78996 35.8859Z" fill="#010101" />
      </g>
      <path d="M72.9064 49.8554C72.7359 50.6483 72.5335 51.4466 72.3098 52.2289C69.6095 61.6965 63.3781 69.5462 54.7766 74.3358C46.1697 79.1201 36.2207 80.2697 26.751 77.5715C17.6967 74.9904 10.0486 69.1151 5.22318 61.0365C0.419099 52.9899 -1.14143 43.5277 0.834527 34.3955C1.00496 33.6025 1.20735 32.8042 1.43104 32.0219C4.13134 22.5544 10.3628 14.7047 18.9643 9.91502C27.5712 5.13069 37.5202 3.98118 46.9899 6.67935C56.0442 9.26044 63.6923 15.1357 68.5177 23.2143C73.3218 31.2609 74.8823 40.7231 72.9064 49.8554ZM6.26708 35.5609C2.79983 51.6169 12.4719 67.7208 28.2796 72.2284C44.8755 76.9595 62.233 67.3057 66.9732 50.7069C67.1649 50.0416 67.3353 49.3604 67.4791 48.6899C70.9464 32.6339 61.2743 16.5301 45.4667 12.0225C28.8708 7.29136 11.5132 16.9452 6.77305 33.544C6.58131 34.2092 6.41088 34.8904 6.26708 35.5609Z" fill="#DFE3E8" />
      <path d="M72.4588 49.7597C72.2884 50.542 72.0913 51.3297 71.8729 52.1067C69.2046 61.4571 63.0531 69.211 54.558 73.9368C46.0577 78.6626 36.2312 79.7961 26.884 77.1299C17.9416 74.5807 10.3893 68.7799 5.62782 60.7972C0.882328 52.8517 -0.656894 43.5066 1.29243 34.4914C1.46287 33.7091 1.65993 32.9215 1.8783 32.1445C4.54664 22.794 10.6982 15.0401 19.1932 10.3143C27.6936 5.58853 37.5201 4.45498 46.8672 7.12121C55.8097 9.67037 63.362 15.4712 68.1234 23.4539C72.8689 31.3994 74.4081 40.7445 72.4588 49.7597ZM5.81956 35.4653C2.30438 51.7554 12.1149 68.0988 28.1516 72.6702C44.9925 77.4705 62.6057 67.6783 67.4151 50.8347C67.6068 50.1589 67.7826 49.4723 67.9264 48.7858C71.4415 32.4957 61.631 16.1524 45.5943 11.5809C28.7534 6.78062 11.1403 16.5781 6.33085 33.4164C6.13911 34.0923 5.96336 34.7788 5.81956 35.4653Z" fill="white" />
      <path d="M2.5338 32.3359C-2.88277 51.3029 8.10483 71.0628 27.0708 76.4698C46.0368 81.8768 65.8017 70.8872 71.2129 51.9149C76.6295 32.9479 65.6419 13.1879 46.6759 7.78092C27.7099 2.37394 7.95038 13.3635 2.5338 32.3359ZM68.0759 51.0261C63.1547 68.2636 45.1953 78.2526 27.9656 73.3353C10.7306 68.4232 0.749584 50.4673 5.67083 33.2299C10.5921 15.9925 28.5515 6.00343 45.7812 10.9208C63.0162 15.8329 72.9972 33.7887 68.0759 51.0261Z" fill="#919EAB" />
      <path d="M38.2447 37.1634C35.5604 36.4397 32.7962 38.0309 32.0718 40.7184C31.3475 43.406 32.94 46.168 35.6243 46.8864C38.3086 47.6102 41.0728 46.019 41.7971 43.3315C42.5215 40.6439 40.929 37.8819 38.2447 37.1634Z" fill="#919EAB" />
      <path d="M41.5147 44.5923C42.9314 42.0591 42.0313 38.8607 39.5015 37.445C36.9716 36.0294 33.7707 36.9288 32.354 39.462C30.9372 41.9952 31.8373 45.1936 34.3672 46.6092C36.8971 48.0248 40.098 47.1255 41.5147 44.5923ZM32.8546 39.7867C34.1595 37.461 36.9343 36.5829 39.2565 37.8868C41.5786 39.1906 42.3509 41.942 41.046 44.2676C39.7412 46.5933 36.8811 47.3277 34.7507 46.1888C32.4019 44.9329 31.5497 42.107 32.8546 39.7867Z" fill="white" />
      <path d="M38.3404 36.7901C35.4537 36.0132 32.4765 37.7268 31.6989 40.6165C30.9213 43.5063 32.6309 46.4759 35.523 47.2529C38.4097 48.0299 41.3869 46.3162 42.1645 43.4265C42.9421 40.5367 41.2325 37.5671 38.3404 36.7901ZM35.5922 46.9974C32.844 46.2577 31.2142 43.4318 31.9545 40.6804C32.6948 37.9343 35.523 36.3005 38.2712 37.0403C41.0194 37.78 42.6492 40.6059 41.9089 43.3573C41.1686 46.1034 38.3404 47.7372 35.5922 46.9974Z" fill="#919EAB" />
      <path d="M33.9515 1.01705C33.9515 1.01705 33.5147 1.30442 33.5041 1.30975C31.7252 2.49119 30.6121 4.60928 30.7931 6.91896C31.0541 10.2611 37.5838 17.3657 37.5838 17.3657L38.0685 17.0464C38.0685 17.0464 43.4052 8.90932 43.1442 5.56189C42.8832 2.21978 40.0125 -0.260195 36.737 0.0218625C35.7091 0.112334 34.761 0.463575 33.9515 1.0064V1.01705Z" fill="#DFE3E8" />
      <path d="M42.6545 5.88646C42.9155 9.22857 37.5788 17.371 37.5788 17.371C37.5788 17.371 31.0491 10.2663 30.7881 6.92422C30.5272 3.58211 32.9718 0.639136 36.2473 0.351757C39.5228 0.0643784 42.3936 2.54435 42.6545 5.89178V5.88646Z" fill="#919EAB" />
      <path d="M32.5615 6.88671C32.6947 8.55776 35.3044 12.1447 37.3603 14.5821C39.0113 11.82 41.0299 7.82335 40.8968 6.15762C40.7103 3.74683 38.6864 1.94805 36.3856 2.15028C34.0848 2.35251 32.3698 4.47592 32.5615 6.88671Z" fill="#DFE3E8" />
      <path d="M31.6025 6.96668C31.8315 9.92562 37.4931 16.216 37.4931 16.216C37.4931 16.216 42.0947 9.03155 41.8657 6.07261C41.6367 3.11367 39.1494 0.915757 36.316 1.16588C33.4826 1.41069 31.3734 4.01306 31.6025 6.96668ZM40.9017 6.15776C41.0349 7.82882 39.0163 11.8202 37.3652 14.5822C35.3041 12.1448 32.6943 8.55259 32.5665 6.88685C32.3801 4.47606 34.095 2.35265 36.3906 2.15042C38.6914 1.94819 40.7153 3.74697 40.9017 6.15776Z" fill="white" />
      <path d="M36.8916 10.1757C38.8463 10.0746 40.3536 8.37693 40.2683 6.38656C40.1831 4.3962 38.5267 2.85819 36.5774 2.9593C34.6228 3.06042 33.1155 4.75808 33.2007 6.74845C33.2859 8.73881 34.9423 10.2768 36.8916 10.1757Z" fill="#919EAB" />
    </svg>


  </>
}

const EnabledWheel = () => {
  return <>

    <svg width="74" height="79" viewBox="0 0 74 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 10197">
        <g id="wheel">
          <path id="Vector" d="M11.87 24.4971C10.5172 24.0075 9.2869 23.438 8.40278 22.9005C7.43344 22.3098 7.01268 21.8308 7.1405 21.4796C7.26833 21.1284 7.90213 21.0272 9.02592 21.1869C10.0592 21.3359 11.3694 21.6818 12.7169 22.1714C14.0643 22.661 15.2947 23.2305 16.1788 23.7786C17.1428 24.3747 17.5689 24.859 17.441 25.2102C17.3132 25.5614 16.6794 25.6572 15.561 25.4923C14.5384 25.3433 13.2282 24.9867 11.8753 24.4971H11.87ZM12.621 22.4216C11.2895 21.9373 10.0006 21.5967 8.98331 21.453C7.82224 21.288 7.43343 21.453 7.39083 21.5701C7.29496 21.8255 8.07256 22.3843 8.54125 22.6717C9.40939 23.2039 10.6237 23.7573 11.9606 24.2416C13.2974 24.7259 14.5863 25.0718 15.5929 25.2209C16.1415 25.3007 17.0895 25.3699 17.1801 25.1144C17.2227 24.992 17.0309 24.6248 16.0296 24.0075C15.1562 23.4646 13.9418 22.9059 12.6157 22.4216H12.621Z" fill="#3EB1B5" />
          <g id="Group">
            <path id="Vector_2" d="M37.4619 42.5918L70.968 43.72C70.7549 49.2122 68.9973 54.5553 65.8763 59.2066C62.7552 63.8578 58.3719 67.6683 53.1684 70.244L37.4619 42.5918Z" fill="#EAF6FD" />
            <path id="Vector_3" d="M53.0618 70.5941L36.9932 42.3086L71.2395 43.4634L71.2289 43.7295C71.0158 49.2642 69.2369 54.6659 66.0893 59.3544C62.9416 64.043 58.5156 67.8907 53.2802 70.4824L53.0565 70.5941H53.0618ZM37.9252 42.8727L53.2748 69.8917C58.3292 67.3425 62.606 63.6012 65.6525 59.0564C68.6937 54.5222 70.4353 49.3121 70.6909 43.9743L37.9305 42.8727H37.9252Z" fill="white" />
          </g>
          <g id="Group_2">
            <path id="Vector_4" d="M37.4619 42.5904L55.2615 16.0664C60.2467 18.9828 64.3264 23.0753 67.0853 27.9288C69.8441 32.7823 71.181 38.2265 70.9679 43.7186L37.4619 42.5904Z" fill="#FF3F48" />
            <path id="Vector_5" d="M71.2233 43.9955L36.9717 42.8407L55.176 15.71L55.3944 15.8377C60.4168 18.7753 64.5392 22.9104 67.314 27.7958C70.0942 32.6866 71.447 38.1947 71.234 43.7294L71.2233 43.9955ZM37.9517 42.3457L70.712 43.4474C70.8665 38.1042 69.535 32.7877 66.8507 28.0619C64.161 23.3255 60.1825 19.3075 55.3411 16.4284L37.9517 42.3457Z" fill="white" />
          </g>
          <g id="Group_3">
            <path id="Vector_6" d="M37.4623 42.0592L21.7559 14.4069C26.9541 11.8312 32.7914 10.5805 38.6713 10.7775C44.5513 10.9744 50.2767 12.6135 55.2619 15.5298L37.4623 42.0539V42.0592Z" fill="#EAF6FD" />
            <path id="Vector_7" d="M37.4408 42.5641L21.3828 14.2946L21.6331 14.1721C26.8633 11.5804 32.7645 10.3138 38.6764 10.516C44.5936 10.713 50.3724 12.3734 55.3948 15.3057L55.6398 15.4494L37.4461 42.5641H37.4408ZM22.1231 14.5234L37.4781 41.553L54.8782 15.6197C49.9942 12.8151 44.3913 11.2345 38.6604 11.0429C32.919 10.8513 27.2148 12.0541 22.1285 14.5181L22.1231 14.5234Z" fill="white" />
          </g>
          <g id="Group_4">
            <path id="Vector_8" d="M37.4601 42.5902L3.9541 41.462C4.16714 35.9699 5.92473 30.6267 9.04578 25.9755C12.1668 21.3242 16.5502 17.5138 21.7537 14.938L37.4601 42.5902Z" fill="#FF3F48" />
            <path id="Vector_9" d="M37.93 42.873L3.68359 41.7235L3.69424 41.4574C3.90728 35.9227 5.68618 30.521 8.83386 25.8325C11.9815 21.1439 16.4075 17.2963 21.643 14.7045L21.8667 14.5928L37.9353 42.8783L37.93 42.873ZM4.2375 41.2073L36.9979 42.3089L21.6483 15.2899C16.5939 17.8391 12.3171 21.5803 9.27059 26.1252C6.22943 30.6594 4.49315 35.8695 4.23217 41.2073H4.2375Z" fill="white" />
          </g>
          <g id="Group_5">
            <path id="Vector_10" d="M37.4616 42.5911L19.6621 69.1151C14.6769 66.1988 10.5972 62.1063 7.83827 57.2528C5.07939 52.3993 3.74256 46.955 3.9556 41.4629L37.4616 42.5911Z" fill="#EAF6FD" />
            <path id="Vector_11" d="M19.7477 69.4716L19.5294 69.3439C14.5069 66.4062 10.3846 62.2711 7.60971 57.3857C4.82952 52.4949 3.47671 46.9868 3.68975 41.4521L3.70041 41.186L37.9521 42.3409L19.7477 69.4716ZM4.21703 41.7395C4.06258 47.0826 5.39408 52.3991 8.0784 57.1249C10.768 61.8614 14.7466 65.8793 19.5879 68.7585L36.9774 42.8411L4.21703 41.7395Z" fill="white" />
          </g>
          <g id="Group_6">
            <path id="Vector_12" d="M37.4617 42.5918L53.1681 70.244C47.9699 72.8198 42.1326 74.0704 36.2527 73.8735C30.3727 73.6766 24.6473 72.0375 19.6621 69.1211L37.4617 42.5971V42.5918Z" fill="#FF3F48" />
            <path id="Vector_13" d="M37.4566 74.1606C37.0518 74.1606 36.647 74.1553 36.2476 74.1393C30.3304 73.9424 24.5516 72.282 19.5292 69.3497L19.2842 69.206L37.4779 42.0859L53.5359 70.3555L53.2855 70.4779C48.4122 72.8887 42.9637 74.1553 37.4566 74.1553V74.1606ZM20.0458 69.0304C24.9298 71.835 30.5328 73.4156 36.2636 73.6072C42.005 73.7987 47.7092 72.596 52.7955 70.132L37.4406 43.1024L20.0405 69.0357L20.0458 69.0304Z" fill="white" />
          </g>
          <g id="Group_7" opacity="0.2">
            <path id="Vector_14" d="M71.101 49.4672C70.9359 50.2229 70.7494 50.9786 70.5364 51.7236C67.9693 60.7175 62.052 68.1734 53.8766 72.7236C45.7011 77.2684 36.2474 78.3647 27.2518 75.7996C18.6502 73.3463 11.3855 67.769 6.79981 60.0896C2.2354 52.4474 0.754768 43.4588 2.62953 34.7843C2.79464 34.0286 2.98105 33.2729 3.19409 32.5278C5.76123 23.5339 11.6785 16.078 19.8539 11.5278C28.0294 6.983 37.4831 5.8867 46.4787 8.45183C55.0803 10.9052 62.345 16.4825 66.9307 24.1619C71.4951 31.804 72.9757 40.7926 71.101 49.4672ZM7.79045 35.8859C4.49897 51.1382 13.681 66.4385 28.7004 70.7226C44.4655 75.2195 60.9602 66.0447 65.4607 50.2761C65.6418 49.6428 65.8016 48.9989 65.94 48.3602C69.2315 33.1079 60.0494 17.8076 45.03 13.5235C29.265 9.02659 12.7703 18.2014 8.2698 33.97C8.08872 34.6033 7.92893 35.2473 7.79045 35.8859Z" fill="#010101" />
          </g>
          <path id="Vector_15" d="M72.9064 49.8554C72.7359 50.6483 72.5335 51.4466 72.3098 52.2289C69.6095 61.6965 63.3781 69.5462 54.7766 74.3358C46.1697 79.1201 36.2207 80.2697 26.751 77.5715C17.6967 74.9904 10.0486 69.1151 5.22318 61.0365C0.419099 52.9899 -1.14143 43.5277 0.834527 34.3955C1.00496 33.6025 1.20735 32.8042 1.43104 32.0219C4.13134 22.5544 10.3628 14.7047 18.9643 9.91502C27.5712 5.13069 37.5202 3.98118 46.9899 6.67935C56.0442 9.26044 63.6923 15.1357 68.5177 23.2143C73.3218 31.2609 74.8823 40.7231 72.9064 49.8554ZM6.26708 35.5609C2.79983 51.6169 12.4719 67.7208 28.2796 72.2284C44.8755 76.9595 62.233 67.3057 66.9732 50.7069C67.1649 50.0416 67.3353 49.3604 67.4791 48.6899C70.9464 32.6339 61.2743 16.5301 45.4667 12.0225C28.8708 7.29136 11.5132 16.9452 6.77305 33.544C6.58131 34.2092 6.41088 34.8904 6.26708 35.5609Z" fill="#EAF6FD" />
          <path id="Vector_16" d="M72.4593 49.7597C72.2889 50.542 72.0918 51.3297 71.8734 52.1067C69.2051 61.4571 63.0535 69.211 54.5585 73.9368C46.0582 78.6626 36.2317 79.7961 26.8845 77.1299C17.9421 74.5807 10.3898 68.7799 5.62831 60.7972C0.882817 52.8517 -0.656405 43.5066 1.29292 34.4914C1.46335 33.7091 1.66042 32.9215 1.87879 32.1445C4.54713 22.794 10.6987 15.0401 19.1937 10.3143C27.694 5.58853 37.5206 4.45498 46.8677 7.12121C55.8101 9.67037 63.3624 15.4712 68.1239 23.4539C72.8694 31.3994 74.4086 40.7445 72.4593 49.7597ZM5.82004 35.4653C2.30487 51.7554 12.1154 68.0988 28.1521 72.6702C44.993 77.4705 62.6062 67.6783 67.4156 50.8347C67.6073 50.1589 67.7831 49.4723 67.9269 48.7858C71.442 32.4957 61.6315 16.1524 45.5948 11.5809C28.7539 6.78062 11.1407 16.5781 6.33134 33.4164C6.1396 34.0923 5.96385 34.7788 5.82004 35.4653Z" fill="white" />
          <path id="Vector_17" d="M2.53332 32.3359C-2.88326 51.3029 8.10434 71.0628 27.0703 76.4698C46.0363 81.8768 65.8012 70.8872 71.2125 51.9149C76.629 32.9479 65.6414 13.1879 46.6754 7.78092C27.7094 2.37394 7.94989 13.3635 2.53332 32.3359ZM68.0754 51.0261C63.1542 68.2636 45.1948 78.2526 27.9651 73.3353C10.7301 68.4232 0.749096 50.4673 5.67035 33.2299C10.5916 15.9925 28.551 6.00343 45.7807 10.9208C63.0157 15.8329 72.9967 33.7887 68.0754 51.0261Z" fill="#FF3F48" />
          <path id="Vector_18" d="M38.2442 37.1634C35.5599 36.4397 32.7957 38.0309 32.0713 40.7184C31.347 43.406 32.9395 46.168 35.6238 46.8864C38.3081 47.6102 41.0723 46.019 41.7966 43.3315C42.521 40.6439 40.9285 37.8819 38.2442 37.1634Z" fill="#FF3F48" />
          <path id="Vector_19" d="M41.5142 44.5923C42.931 42.0591 42.0309 38.8607 39.501 37.445C36.9711 36.0294 33.7702 36.9288 32.3535 39.462C30.9367 41.9952 31.8368 45.1936 34.3667 46.6092C36.8966 48.0248 40.0975 47.1255 41.5142 44.5923ZM32.8541 39.7867C34.159 37.461 36.9338 36.5829 39.256 37.8868C41.5781 39.1906 42.3504 41.942 41.0455 44.2676C39.7407 46.5933 36.8806 47.3277 34.7502 46.1888C32.4014 44.9329 31.5492 42.107 32.8541 39.7867Z" fill="white" />
          <path id="Vector_20" d="M38.3409 36.7901C35.4542 36.0132 32.477 37.7268 31.6994 40.6165C30.9218 43.5063 32.6314 46.4759 35.5235 47.2529C38.4102 48.0299 41.3874 46.3162 42.165 43.4265C42.9426 40.5367 41.233 37.5671 38.3409 36.7901ZM35.5927 46.9974C32.8445 46.2577 31.2147 43.4318 31.955 40.6804C32.6953 37.9343 35.5235 36.3005 38.2717 37.0403C41.0199 37.78 42.6497 40.6059 41.9094 43.3573C41.169 46.1034 38.3409 47.7372 35.5927 46.9974Z" fill="#9B3D83" />
        </g>
        <g id="navigator">
          <path id="Vector_21" d="M33.952 1.01705C33.952 1.01705 33.5152 1.30442 33.5046 1.30975C31.7257 2.49119 30.6126 4.60928 30.7936 6.91896C31.0546 10.2611 37.5843 17.3657 37.5843 17.3657L38.069 17.0464C38.069 17.0464 43.4057 8.90932 43.1447 5.56189C42.8837 2.21978 40.013 -0.260195 36.7375 0.0218625C35.7096 0.112334 34.7615 0.463575 33.952 1.0064V1.01705Z" fill="#9B3D83" />
          <path id="Vector_22" d="M42.655 5.88646C42.916 9.22857 37.5793 17.371 37.5793 17.371C37.5793 17.371 31.0496 10.2663 30.7886 6.92422C30.5277 3.58211 32.9723 0.639136 36.2478 0.351757C39.5233 0.0643784 42.394 2.54435 42.655 5.89178V5.88646Z" fill="#FF3F48" />
          <path id="Vector_23" d="M32.5625 6.88671C32.6956 8.55776 35.3054 12.1447 37.3612 14.5821C39.0123 11.82 41.0309 7.82335 40.8977 6.15762C40.7113 3.74683 38.6874 1.94805 36.3866 2.15028C34.0857 2.35251 32.3707 4.47592 32.5625 6.88671Z" fill="#EAF6FD" />
          <path id="Vector_24" d="M31.603 6.96668C31.832 9.92562 37.4935 16.216 37.4935 16.216C37.4935 16.216 42.0952 9.03155 41.8662 6.07261C41.6372 3.11367 39.1499 0.915757 36.3165 1.16588C33.483 1.41069 31.3739 4.01306 31.603 6.96668ZM40.9022 6.15776C41.0353 7.82882 39.0168 11.8202 37.3657 14.5822C35.3045 12.1448 32.6948 8.55259 32.567 6.88685C32.3806 4.47606 34.0955 2.35265 36.3911 2.15042C38.6919 1.94819 40.7158 3.74697 40.9022 6.15776Z" fill="white" />
          <path id="Vector_25" d="M36.8926 10.1757C38.8473 10.0746 40.3545 8.37693 40.2693 6.38656C40.1841 4.3962 38.5277 2.85819 36.5784 2.9593C34.6237 3.06042 33.1165 4.75808 33.2017 6.74845C33.2869 8.73881 34.9433 10.2768 36.8926 10.1757Z" fill="#FF3F48" />
        </g>
      </g>
    </svg>



  </>
}