import axios from '../../interceptor';
import moment from 'moment';
let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
const CORS = 'https://cors-anywhere.herokuapp.com/';


export function setGlobalToken() {
    try {
        let token = localStorage.getItem('token');
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    } catch (error) {
      console.log(error);
    }
}

export const GETPRACTICETEST = 'GET_PRACTICE_TEST';
export const ONQUESTIONCLICK = 'ON_QUESTION_CLICK';
export const ONPRACTICEOPTIONSELECTED = 'ON_PRACTICE_OPTION_SELECTED';
export const ONPRACTICETESTSUBMIT = 'ON_PRACTICE_TEST_SUBMIT';

export const onPracticeSubmitA = (res, err) => {
    return {
        type: ONPRACTICETESTSUBMIT,
        response: res,
        error: err
    }
}

export const onPracticeSubmitAC = (res) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        const formdata = new FormData();
        formdata.append('practice_id', getState().prc.practice_id);
        formdata.append('subject_id', getState().prc.subject_id);
        // console.log(getState().prc.subject_id);
        formdata.append('unit_id', getState().prc.unit_id);
        let question_answers = getState().prc.answers;
        console.log(question_answers)
        let qid = getState().prc.qid;
        let datetime = moment().format('YYYY-MM-DD hh:mm:ss').toString();
        question_answers[qid].timetaken[question_answers[qid].timetaken.length - 1].push(datetime);
        let duration = moment().diff(question_answers[qid].started_time, 'seconds');
        question_answers[qid].timetaken[question_answers[qid].timetaken.length - 1].push(duration);
        question_answers.forEach((que)=> {
            let tt = que.timetaken;
            que.timetaken = JSON.stringify(tt);
            let q_opt_id = que.question_option_id;
            if (!q_opt_id) {
                que.question_option_id = 'null';
            }
        })
        formdata.append('answers', JSON.stringify(question_answers));
        // console.log(formdata.values())

        http.post(`${BASE_API_URL}/units/v2/submitUnitPracticeTest`, formdata)
        .then((res)=> {
            if (res.status == 200) {
                if (res.data.status == 200) {
                    dispatch(onPracticeSubmitA(res, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.post(`${BASE_API_URL}/units/v2/submitUnitPracticeTest`, formdata)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    dispatch(onPracticeSubmitA(res, false));
                                } else {
                                    dispatch(onPracticeSubmitA(null, true));
                                }
                            } else {
                                dispatch(onPracticeSubmitA(null, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(onPracticeSubmitA(null, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    })
                } else {
                    dispatch(onPracticeSubmitA(null, true));
                }
            } else {
                dispatch(onPracticeSubmitA(null, true));
            }
            
        })
        .catch((err)=> {
            console.log('error in submittting test')
            console.log(err)
            dispatch(onPracticeSubmitA(null, true));
        });
    };
};

export const getPracticeTestA = (res, subject_id, unit_id, university_degree_department_id,  err) => {
    return {
        type: GETPRACTICETEST,
        response: res,
        error: err,
        subject_id: subject_id,
        unit_id: unit_id,
        university_degree_department_id: university_degree_department_id
    }
}

export const getPracticeTestAC = (subject_id, unit_id, udd,cudd, semester_id, topic_code, topic_name) => {
    console.log('in action creator')
    setGlobalToken();
    let endpoint;
    console.log(unit_id)
    console.log(topic_name)
    console.log(topic_code)
    if (unit_id) {
        endpoint = `getUnitPracticeTest?subject_id=${subject_id}&unit_id=${unit_id}&university_degree_department_id=${udd}`

    } else if (topic_code && topic_name) {
        endpoint = `getTopicPracticeTest?code=${topic_code}&name=${topic_name}&college_university_degree_department_id=${cudd}&semester_id=${semester_id}`
    }
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/units/v2/${endpoint}`)
        .then((res)=> {
            if (res.status == 200) {
                if (res.data.status == 200 ) {
                    dispatch(getPracticeTestA(res.data, subject_id, unit_id, udd, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/units/v2/${endpoint}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    dispatch(getPracticeTestA(res.data, subject_id, unit_id, udd,  false));
                                } else {
                                    dispatch(getPracticeTestA(null, subject_id, unit_id, udd,  true))
                                }
                            } else {
                                dispatch(getPracticeTestA(null,  subject_id, unit_id, udd, true))
                            }
                        })
                        .catch((err)=>{
                            dispatch(getPracticeTestA(null,  subject_id, unit_id, udd, true))
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    });
                } else {
                    dispatch(getPracticeTestA(null, subject_id, unit_id, udd,  true))
                }
            } else {
                dispatch(getPracticeTestA(null,  subject_id, unit_id, udd, true))
            }
            
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(getPracticeTestA(null, true))
        });
    };
};

export const onQuestionClick = (res) => {
    return {
        type: ONQUESTIONCLICK,
        question_option_id: res.question_option_id,
        qid: res.qid
    }
}

export const onPracticeOptionSelected = (res) => {
    console.log('on practie option selected')
    return {
        type: ONPRACTICEOPTIONSELECTED,
        qid: res.qid,
        option_id: res.option_id
    }
}