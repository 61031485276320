import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, Cell } from 'recharts';


const barColors = ["url(#colorPass)", "url(#colorFail)"]
export default class Barchart extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      data: [{ name: 1, time: 450, status: false }]
    }

  }

  componentDidMount() {

    let chartData = this.props.chartData;
    let chartData1 = chartData.map((dat)=> {
      return {
        name: dat.run_number,
        time: dat.time_elapsed,
        status: dat.status
      }
    })
    this.setState({ data: chartData1 });

    this.forceUpdate()

  }

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={this.state.data}
          margin={{
            top: 5,
            right: 30,
            left: 30,
            bottom: 25,
          }}
        >
          {/* linear-gradient(136.19deg, #DCFF73 -20.18%, #8ECA6B 92.7%) */}
          {/* linear-gradient(144.48deg, #F38FA2 9.88%, #EB5C6F 54.05%, #F1825C 116.8%) */}
          <defs>
            <linearGradient id="colorPass" x1="0" y1="0" x2="0" y2="1">
              <stop offset="-20.18%" stopColor="#DCFF73" />
              <stop offset="92.7%" stopColor="#8ECA6B" />
            </linearGradient>
            <linearGradient id="colorFail" x1="0" y1="0" x2="0" y2="1">
              <stop offset="9.88%" stopColor="#F38FA2" />
              <stop offset="116.8%" stopColor="#EB5C6F" />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" >
            <Label value="Executions" offset={-10} position="insideBottom" />
          </XAxis>
          <YAxis >
            <Label angle={-90} value="Time(in Seconds)" offset={-10} position="left" style={{ textAnchor: 'middle' }} />
          </YAxis>
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          <Bar name="Time to code" barSize={20} dataKey="time" fill="url(#colorPass)" radius={[10, 10, 0, 0]} >

            {

              this.state.data.map((b, index) => (

                <Cell key={`cell-${index}`} fill={b.status ? barColors[0] : barColors[1]} />
              ))
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
