import React, { useEffect, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import { useRemedialContext } from '../../hooks/useRemedial';
import ModalWithNoHeader from '../../../../components/common/ModalWithNoHeader/ModalWithNoHeader';



const WheelUnlockedModal = ({ show, onClose , percentage }) => {
const [move , setMove] = useState(false);
const {studyProgress} = useRemedialContext()

useEffect(() => {

    setTimeout(() => setMove(true), 10);

},[])


    return (
        <ModalWithNoHeader
            closeButtonText='Continue'
            show={show}
            headBodyGap={32}
            buttonTextColor='primary.contrastText'
            buttonColor='primary.800'
            onCloseHandle={onClose}
        >
            <Stack alignItems={'center'} justifyContent={'center'} gap={'16px'}>
                <Typography
                    component={'p'}
                    sx={{
                        fontFamily: 'Poppins-Medium',
                        fontSize: '22px',
                        color: theme => theme.palette.primary[800],
                    }}>Spin Wheel Unlocked!</Typography>




                <Box sx={{
                    width: '100%',
                    height: '17px',
                    backgroundColor: theme => theme.palette.success[100],
                    borderRadius: '20px',
                    overflow: 'hidden'
                }}>
                    <Stack className='move' sx={{
                        height: '100%',
                        width: '0%',
                        backgroundColor: theme => theme.palette.success[600],
                        borderRadius: '20px',
                        overflow: 'hidden',
                        transitionTimingFunction: 'cubic-bezier(0.64, 0.57, 0.67, 1.53)',
                        transitionDuration: '1s',
                        '&.move': {
                            width: move ? `${percentage}%` : '0%'
                        }
                    }} >
                        {/* bar */}
                    </Stack>

                </Box>

                <Typography
                    component={'p'}
                    sx={{
                        fontFamily: 'Poppins-Medium',
                        fontSize: '12px',
                        color: theme => theme.palette.grey[700],
                    }}>You have completed {studyProgress}% of study and unlocked spin wheel.</Typography>
            </Stack>
        </ModalWithNoHeader>
    )
}

export default WheelUnlockedModal