import { Drawer } from '@mui/material'
import React from 'react'

const RemedialDrawer = ({ children, anchor = 'right', show = true, onCloseHandle }) => {
    return (
        <Drawer anchor={anchor} open={show} onClose={onCloseHandle} sx={{
            '.css-5lufiz-MuiPaper-root-MuiDrawer-paper': {
                background: theme => theme.palette.grey[1000]
            }
        }}>
            {children}
        </Drawer>
    )
}

export default RemedialDrawer