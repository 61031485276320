import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import { getSubjectiveAssessmentAC, submitSubjectiveQuestionAC } from '../../store/actions/subjectiveActions';
import SubjectiveInstructions from '../../components/SubjectiveInstructions';
import moment from 'moment';
import { Redirect } from "react-router-dom";
import Modal from './Modal';
import ImageModal from './ImageModal';
import Flex from 'styled-flex-component';
import AppLayout from '../../components/Layout/Layout';
import MonogramTimer from '../../components/MonogramTimer/MonogramTimer';
import styled from 'styled-components';
import Equation from '../../components/Equation';
import ReportDialog from '../../components/reportModal';
import RichTextEditor from 'react-rte';

const Rectangle = () => (
    <svg height="560" width="360">
        <polygon points="180,10 360,80 360,560 10,560 10,80 180,10">
        </polygon>
        Sorry, your browser does not support inline SVG.
    </svg>
);

const RectangleBig = () => (
    <svg height="580" width="380">
        <polygon points="190,10 380,80 380,580 10,580 10,80 190,10">
        </polygon>
        Sorry, your browser does not support inline SVG.
    </svg>
);

const Rectangle2 = () => (
    <svg height="960" width="360">
        <polygon points="180,10 360,80 360,960 10,960 10,80 180,10">
        </polygon>
        Sorry, your browser does not support inline SVG.
    </svg>
);

const RectangleBig2 = () => (
    <svg height="980" width="380">
        <polygon points="190,10 380,80 380,980 10,980 10,80 190,10">
        </polygon>
        Sorry, your browser does not support inline SVG.
    </svg>
);

const RectangleWrapper = styled.div`
    color: #FFFFFF;
    position: absolute;
    top: 10px;
    right: 8px;
    svg {
        // position: absolute;?
        display: block;
        fill: #FFFFFF;
        width: 100%;
        height: 100%;
        polygon {
            stroke: #FFFFFF;
            fill: #FFFFFF;
            stroke-width: 1px;
            transition: all 0.3s esae;
        }
        transition: all 0.3s esae;
    }
`

const RectangleWrapperBig = styled.div`
    // color: #000000;
    svg {
        display: block;
        width: 100%;
        fill: #EEEEEE;
        filter: blur(5px);
            -webkit-filter: blur(5px);
        height: 100%;
        // border: 2px solid rgba(#000, .2);
        polygon {
            // stroke-width: 1px;
            fill: #EEEEEE;
            
            transition: all 0.3s esae;
        }
        transition: all 0.3s esae;
    }
`

const TextArea = styled.textarea`
    &:focus{
        outline: none;
    }
`;

class Subjective extends Component {

    state = {
        loading: true,
        testSubmittedFromMulitpleFraud:false,
        pageMounted:false,
        event:null,
        noOfWindowSwitches:1,
        testSubmittedModalOpen: false,
        windowChangeWarningModalOpen: false,
        reportDialogOpened: false,
        dialogTitle: 'Are you sure you want to submit the test ?',
        showHint: false,
        showFullQuestionsView: false,
        openMarkedDialog: false,
        currentTime: 0,
        openInstructions: false,
        warningWindow: false,
        showCalc: false,
        openChangeAnswerTypeDialog: false,
        changeAnswerDialog: null,
        openImage: false,
        fileData: null,
        reportItemId: null,
        openReportDialog: false,
        itemToBeReported: null,
        ques_image_open: false
    }

    componentDidMount = ()=> {
        // let test_id = 80;
        let test_id = this.props.location.state.test_id;
        this.props.getSubjectiveAssessment(test_id);
        if(this.state.testSubmitted) {
            // this.closeFullscreen();
        } else {
            // this.askBeforeUserLeaving();
            return;
        }
    }

    askBeforeUserLeaving(){
        try {
          let pathname,array = [];
          this.onVisibilityChange(this.onVisibilityChangeCallBack);
        } catch (error) {
          console.log(error);
        }
        // window.addEventListener("beforeunload",(event)=> this.beforeUnloadFunction(event));
    }

    onCloseWargningWindow= ()=> {
        this.setState({windowChangeWarningModalOpen:false});
    }

    onVisibilityChangeCallBack = (visible) => {   
        // if (this.state.noOfWindowSwitches === 2) {            
        //   this.setState({
        //     windowChangeWarningModalOpen: true,
        //     noOfWindowSwitches: this.state.noOfWindowSwitches + 1,
        //     dialogTitle:
        //       "Warning: You are NOT allowed to switch apps during the test! ",
        //   });
        // }
        // if (!visible && this.state.noOfWindowSwitches == 3) {
        //   this.setState({ testSubmittedFromMulitpleFraud: true });
        //   this.confirmSubmitTest();
        //   this.setState({
        //     testSubmittedModalOpen: true,
        //     dialogTitle: "Your Test has been submitted!!",
        //     windowChangeWarningModalOpen: false,
        //   });
        // }
      };


    focused = (callback) => {
        if (!this.state.visible) {
            this.setState({visible : true})
            callback( true);
        }
    }
    
    unfocused = (callback) => {
        if (this.state.visible) {
            this.setState({visible: false})
            callback( false);

        }
    }
    
    mozVisiblityChangeFunction = (callBack) => {
        (document.mozHidden ? this.unfocused : this.focused)(callBack)
    }
    visiblityChangeFunction = (callBack) => {
        (document.hidden ? this.unfocused : this.focused)(callBack)
    }
    webkitVisiblityChangeFunction = (callBack) => {
        (document.webkitHidden ? this.unfocused : this.focused)(callBack)
    }
    msVisiblityChangeFunction = (callBack) => {
        (document.msHidden ? this.unfocused : this.focused)(callBack)
    }
    
    onVisibilityChange(callback) {
        var visible = true;

        if (!callback) {
            throw new Error('no callback given');
        }

        // Standards:
        if ('hidden' in document) {
            document.addEventListener('visibilitychange',() => this.visiblityChangeFunction(callback));
        }
        if ('mozHidden' in document) {
            document.addEventListener('mozvisibilitychange',()=> this.mozVisiblityChangeFunction(callback));
        }
        if ('webkitHidden' in document) {
            document.addEventListener('webkitvisibilitychange',()=>this.webkitVisiblityChangeFunction(callback));
        }
        if ('msHidden' in document) {
            document.addEventListener('msvisibilitychange',()=>this.msVisiblityChangeFunction(callback));
        }
        // IE 9 and lower:
        if ('onfocusin' in document) {
            document.onfocusin = () =>this.focused(callback);
            document.onfocusout = () =>this.unfocused(callback);
        }
        // All others:
        window.onpageshow = window.onfocus = ()=>this.focused(callback);
        window.onpagehide = window.onblur = ()=>this.unfocused(callback);
    };

    removeEventListeners = () => {
        try {
          window.removeEventListener("beforeunload", (event) =>
            this.beforeUnloadFunction(event)
          );
          document.removeEventListener("beforeunload", this.beforeUnloadFunction);
          window.removeEventListener("beforeunload", this.beforeUnloadFunction);
          window.removeEventListener("beforeunload", this.beforeUnloadFunction);
          window.removeEventListener("beforeunload", this.beforeUnloadFunction);
          window.onbeforeunload = null;
          window.removeEventListener("pagehide", this.unfocused);
          window.removeEventListener("pageshow", this.focused);
          window.removeEventListener("focus", this.focused);
          window.removeEventListener("blur", this.unfocused);
          window.onblur = null;
          window.onfocus = null;
          window.onpagehide = null;
          window.onpageshow = null;
          if ("onfocusin" in document) {
            document.onfocusin = null;
            document.onfocusout = null;
          }
          document.removeEventListener(
            "visibilitychange",
            this.visiblityChangeFunction
          );
          document.removeEventListener(
            "mozvisibilitychange",
            this.mozVisiblityChangeFunction
          );
          document.removeEventListener("focusin", this.focused);
          document.removeEventListener("focusout", this.unfocused);
    
          document.removeEventListener(
            "webkitvisibilitychange",
            this.webkitVisiblityChangeFunction
          );
          document.removeEventListener(
            "msvisibilitychange",
            this.msVisiblityChangeFunction
          );
        } catch (error) {}
      };

    openInstructionsHandler = () => {
        this.setState({openInstructions: true});
    }

    closeInstructions = () => {
        this.setState({openInstructions: false});
    }

    setCurrentTime = (time, timer) => {
        // console.log(time);
        if (time == 0) {
            this.confirmSubmitTest();
        }
        this.setState({ currentTime: time, timer: timer });
    }

    triggerFileUpload = (index)=> {
        document.getElementById('fileUpload').click();
    }

    uploadImage = (event, index) => {
        let files = event.target.files;
        this.props.addQuestionImage(files, index);
    }

    imageUploadClick = () => {
        // this.openFullscreen();

    }

    submitQuestion =(event, index)=> {
        event.stopPropagation();
        event.preventDefault();
        this.props.submitQuestion(index)
    }

    onSubmitTest = () => {
        this.setState({openSubmitTestDialog: true, dialogTitle: 'Are you sure you want to submit the Test? '})
    }

    closeSubmitTestDialog = () => {
        this.setState({openSubmitTestDialog: false, dialogTitle: ''})

    }

    confirmSubmitTest = ()=> {
        this.removeEventListeners();
        // this.closeFullscreen();
        clearInterval(this.state.timer);
        this.props.history.push('/home');
    }

    onInputClick = () => {
        this.setState({noOfWindowSwitches: this.state.noOfWindowSwitches -1});
        // this.openFullscreen();
    }

    openFullscreen=()=> {
        try {
          var elem = document.documentElement;
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.mozRequestFullScreen) {
            /* Firefox */
            elem.mozRequestFullScreen();
          } else if (elem.webkitRequestFullscreen) {
            /* Chrome, Safari & Opera */
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) {
            /* IE/Edge */
            elem.msRequestFullscreen();
          }
        } catch (error) {
          console.log(error);
        }
      }

    closeFullscreen = () => {
        try {
            if (document.fullscreenElement) {
                if (document.exitFullscreen) {
                document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                /* Safari */
                document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                /* IE11 */
                document.msExitFullscreen();
                }
            }
        } catch (error) {
        console.log(error);
        }
    };
    changeAnswerType =(index, type) => {
        if (type == 'text' && this.props.answers[index].answer_type != 'text') {
            if (this.props.answers[index].answer_url != null && this.props.answers[index].answer_url.length != 0) {
                this.setState({answer_index: index, answer_type_change: type, openChangeAnswerTypeDialog: true, changeAnswerDialog: 'Would you discard the files uploaded and replace with text answer ? '})
            } else {
                this.props.changeAnswerType(index, type);
            }
        } else if (type == 'file' && this.props.answers[index].answer_type != 'file'){
            if (this.props.answers[index].answer.toString('html') != null && this.props.answers[index].answer.toString('html') != '') {
                this.setState({answer_index: index, answer_type_change: type, openChangeAnswerTypeDialog: true, changeAnswerDialog: 'Would you discard your text answer and replace with a file ? '})
            } else {
                this.props.changeAnswerType(index, type);
            }
        } else {
            return
        }
        
    }

    confirmChangeAnswerType = () => {
        this.props.changeAnswerType(this.state.answer_index, this.state.answer_type_change);
        this.setState({answer_index: null, answer_type_change: null, openChangeAnswerTypeDialog: false, changeAnswerDialog: null})
    }

    cancelChangeAnswerType = () => {
        this.setState({answer_index: null, answer_type_change: null, openChangeAnswerTypeDialog: false, changeAnswerDialog: null})
    }

    onImageClick = (index, file_index)=> {
        // this.props.deleteUploadedImage(index, file_index);
        if(index !== "question")
            this.setState({openImage: true, fileData: this.props.answers[index].answer_url[file_index]});
        else
            this.setState({openImage: true, fileData: file_index, ques_image_open: true});
    }

    closeImage = () => {
        this.setState({openImage: false, fileData: null, ques_image_open: false});
    }

    deleteUploadedImage = (event,index, file_index)=> {
        event.stopPropagation();
        event.preventDefault();
        console.log('delete');
        this.props.deleteUploadedImage(index, file_index);
    }
    reportItem = (item, id)=> {
        document.body.style.overflow = 'hidden';
        this.setState({openReportDialog: true, itemToBeReported: item, reportItemId: id});
    }

    closeReport = ()=> {
        document.body.style.overflow = 'auto';
        this.setState({openReportDialog: false, itemToBeReported: null, reportItemId: null});
    }

    updateAnswer = (index,editor) => {
        // console.log(editor.toString('html'));
        this.props.updateAnswer(index, editor)
    }

    render () {
        console.log(this.props);
        if (this.props.loading) {
            return <div>Loading</div>
        }
        if (!this.props.loading && this.props.error_loading_sub) {
            return <div>Error Loading Test</div>
        }
        if (!this.props.loading && this.props.test_already_submitted) {
            return <div>Test is Submitted </div>
        }
        if (!this.props.loading && !this.props.test_started) {
            return <SubjectiveInstructions showStart={true}></SubjectiveInstructions>
        }

        let testSubmitting = this.props.testSubmitting;
        if (this.props.testSubmitted) {
            testSubmitting = false;
            return <Redirect to='/home' exact />
        } 
        let timelimit = this.props.timelimit;
        if (moment(this.props.date_of_expiry).diff(moment(), 'seconds') < timelimit) {
            // console.log('not less ')
            timelimit = moment(this.props.date_of_expiry).diff(moment(), 'seconds');
        }
        let questions = this.props.questions;
        let answers = this.props.answers;
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
              {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
              {label: 'Italic', style: 'ITALIC'},
              {label: 'Underline', style: 'UNDERLINE'}
            ],
            BLOCK_TYPE_DROPDOWN: [
              {label: 'Normal', style: 'unstyled'},
              {label: 'Heading Large', style: 'header-one'},
              {label: 'Heading Medium', style: 'header-two'},
              {label: 'Heading Small', style: 'header-three'},
              {label: 'Heading Smaller', style: 'header-four'},
              {label: 'Heading Smallest', style: 'header-five'},
              {label: 'Block Quote', style: 'blockquote'},
              {label: 'Code Block', style: 'code-block'},
              {label: 'Atomic', style: 'atomic'},
              {label: 'List', style: 'unordered-list-item,ordered-list-item'},
              {label: 'Table', style: 'table'},
            ],
            BLOCK_TYPE_BUTTONS: [
              {label: 'UL', style: 'unordered-list-item'},
              {label: 'OL', style: 'ordered-list-item'}
            ]
          };
        return (
            <div style={{overflow: 'auto'}}>
                <AppLayout logoutDisable={true}></AppLayout>
                <Flex row style = {{marginTop: '96px', marginLeft: '30px', width: '98%',overflow: 'auto', paddingBottom:'50px'}}>
                    <div style={{width: '72%'}}>
                        <Flex row style = {{margin: '8px', marginTop: '50px'}}>
                            <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                                {this.props.name} 
                            </div>
                            <Flex row style={{fontSize: '14px', margin: 'auto', marginLeft: '5px'}}>
                                <div style={{margin: 'auto', marginBottom: '4px'}}>
                                    &nbsp;Description: {this.props.description} | Questions: {this.props.questions.length}

                                </div>
                                <div onClick={this.openInstructionsHandler} style={{marginLeft:'8px'}}>
                                    <img src='/images/Info.svg'></img>
                                </div>
                            </Flex>
                        </Flex>
                        {questions.map((que, index)=> {
                            return <div style={{margin: '20px 0'}}>
                                <Flex row style={{margin: '10px 10px', width: '90%'}}>
                                    <Flex row style={{width: '70%'}}>
                                        <div style={{marginRight: '10px', fontWeight: '600', fontSize: '18px'}}>{index+1}.</div>
                                        <Equation math_type={1} bold equation={que.question}></Equation>
                                        {/* <div>{que.question}</div> */}
                                    </Flex>
                                    <div style={{textAlign: 'right', width: '23%'}}>
                                       Section - {que.section_name}
                                    </div>
                                    <div style={{width: '7%'}}>
                                        <div style={{width: '100%'}}>
                                            <img style={{marginLeft: '0'}} onClick={()=>this.reportItem('subjective_questions', que.id)} src='/images/report.svg'></img>
                                        </div>
                                    </div>
                                    
                                </Flex>
                                {que.question_img ? <Flex row style={{margin: '10px 10px', width: '70%', height: '300px', marginLeft: '40px'}}>
                                    <img style={{width: '50%',height: '100%'}} alt='question' onClick={(event)=>this.onImageClick("question", que.question_img)} src={que.question_img}></img>
                                    
                                </Flex> : null}
                                <Flex row style={{width: 'auto', marginTop: '20px'}}>
                                    <div style={{display: 'flex', flexDirection: 'row',width: '90%', height: '260px',padding: '0', margin: 'auto', marginLeft: '40px', borderRadius: '4px', border: '1px solid #969696'}}>
                                        {this.props.answers[index].answer_type == 'file' && (this.props.answers[index].answer_url == null || this.props.answers[index].answer_url.length == 0) ? <div style={{width: '90%', margin: 'auto'}}>
                                            <div style={{color: '#909090', fontSize: '18px', fontWeight: '400'}}>
                                                Drag and drop the file 
                                            </div>
                                            <div style={{color: '#909090', fontSize: '18px', fontWeight: '400',marginBottom: '10px'}}>
                                                or
                                            </div>
                                            <div>
                                                <input onChange={(event)=>this.uploadImage(event, index)} multiple={true} accept="image/*" id='fileUpload' onClick={this.imageUploadClick} type='file' src='/images/upload_image.svg' style={{display: 'none'}}></input>
                                                
                                                <img onClick={()=>this.triggerFileUpload(index)} src='images/upload_image.svg'></img>
                                            </div>
                                        </div> : this.props.answers[index].answer_type == 'file' && this.props.answers[index].answer_url.length !=0 ?
                                            <div style={{width: '90%', marginRight: '20px', display: 'grid', gridTemplateColumns: 'repeat(4,25%)', overflow: 'auto'}}>
                                                {Array.from(this.props.answers[index].answer_url).map((file, file_index)=>{
                                                    return <div style={{position: 'relative',  margin: 'auto 15px', boxSizing: 'border-box'}}>
                                                            <img onClick={(event)=>this.onImageClick(index, file_index)} style={{width: '100%', height: '140px'}} src={URL.createObjectURL(file)}></img>
                                                            <div onClick={(event)=>this.deleteUploadedImage(event,index,file_index )} style={{position: 'absolute', top: '0', right: '10px', fontWeight: '600', color: 'red', fontSize: '20px'}}>x</div>
                                                        </div>
                                                })}
                                            </div>
                                        : <div style={{width: '90%', marginRight: '20px', textAlign: 'left'}}>
                                            <RichTextEditor 
                                                className='RTE'
                                                value={this.props.answers[index].answer}
                                                onChange={(editor)=>this.updateAnswer(index, editor)}
                                            />
                                        </div>}
                                        <div style={{width: '10%'}}>
                                            <Flex row style={{width: '64px',borderRadius: '20px',border: '1px solid #4D50CB', height: '32px', margin: '20px 10px auto auto' }}>
                                                <div onClick={()=>this.changeAnswerType(index, 'text')} style={{width: '50%',color:this.props.answers[index].answer_type == 'text' ? 'white' : '#4D50CB' , backgroundColor: this.props.answers[index].answer_type == 'text' ? '#4D50CB' : '#fff', borderRadius: '50%'}}>
                                                    <div style={{marginTop: '5px'}}>T</div></div>
                                                <div onClick={()=>this.changeAnswerType(index, 'file')} style={{width: '50%',backgroundColor: this.props.answers[index].answer_type == 'text' ? '#fff' : '#4D50CB', borderRadius: '50%'}}>
                                                    {this.props.answers[index].answer_type == 'text' ?
                                                    <img style={{margin: 'auto', marginTop: '5px'}} src='images/upload_blue_icon.png'></img> :
                                                    <img style={{margin: 'auto', marginTop: '5px'}} src='/images/upload_icon.svg'></img>}
                                                </div>
                                            </Flex>
                                            <div style={{bottom: '0', margin: '0 auto'}}>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </Flex>
                                {this.props.answers[index].just_saved ? <div style={{margin: '10px auto', color: 'green', width: '90%', textAlign: 'left'}}>Your answer is saved</div> : null}
                                <div style={{width: '90%', margin: 'auto'}}>
                                    <div onClick={(event)=>this.submitQuestion(event,index)} style={{border: '1px solid #4E54C8', borderRadius: '20px', fontSize: '18px', fontWeight: '400', color: '#4E54C8', width: '102px', height: '40px', textAlign: 'center', boxSizing: 'border-box', margin: 'auto', marginTop: '20px', marginRight: '20px'}}>
                                        <div style={{margin: 'auto', marginTop: '8px'}}>Save</div>
                                    </div>
                                </div>
                            </div>
                        })}
                        
                        
                    </div>
                    <div style={{width: '380px', height: '580px', marginTop: '50px', position: 'absolute', top: '96px', left: '71%'}}>
                        {this.state.showFullQuestionsView ? 
                        <>
                            <RectangleWrapperBig>
                                <RectangleBig2>

                                </RectangleBig2>
                                
                            </RectangleWrapperBig>
                            <RectangleWrapper>
                                <Rectangle2>
                                    
                                </Rectangle2>
                            </RectangleWrapper>
                        </>
                        :
                        <>
                            <RectangleWrapperBig>
                                <RectangleBig>

                                </RectangleBig>
                                
                            </RectangleWrapperBig>
                            <RectangleWrapper>
                                <Rectangle>
                                    
                                </Rectangle>
                            </RectangleWrapper>
                        </> 
                        }
                        
                        <div style={{position: 'absolute', top: '-40px', right: '30px', justifyContent: 'center', alignItems: 'center'}}>
                            <div>
                                <div style={{widht: '275px', height: '160px'}}>
                                    {/* <img width='275px' height='160px' src='/images/Group 8.svg'></img> */}
                                    <MonogramTimer seconds={timelimit} onTimerUpdate={this.setCurrentTime} />

                                </div>
                                <div style={{marginTop: '20px', borderBottom: '2px solid #eee', width: '290px', marginLeft: '10px'}}></div>
                                <Flex style={{width: '312px', height: '53px', backgroundColor: 'white', boxShadow: '5px 10px 20px #eee', padding: '17px 5px', boxSizing: 'border-box',borderRadius: '11px', marginTop: '20px'}}>
                                    <Flex row style={{width: '50%'}}>
                                        <div style={{width: '70%', margin: 'auto'}}>answered</div>
                                        <div style={{width: '30%', fontSize: '24px', fontWeight:'500', color:'#4E54C8', marginTop: '-5px'}}>{this.props.answered.length}</div>
                                    </Flex>
                                    <Flex row style={{width: '50%'}}>
                                        <div style={{width: '70%', margin: 'auto'}}>remaining</div>
                                        <div style={{width: '30%', fontSize: '24px', fontWeight:'500', marginTop: '-5px'}}>{this.props.remaining_questions.length}</div>
                                    </Flex>
                                </Flex>
                                <Flex style={{width: '312px', height: 'auto', backgroundColor: 'white', boxShadow: '5px 10px 20px #eee', padding: '12px 5px', boxSizing: 'border-box',borderRadius: '11px', marginTop: '20px'}}>
                                    <Flex column style={{width: '50%'}}>
                                        <Flex row>
                                            <div style={{width: '70%', margin: 'auto'}}>skipped</div>
                                            <div style={{width: '30%', fontSize: '24px', fontWeight:'500', color: '#A5A4A4', marginTop: '-5px'}}>{this.props.skipped.length}</div>
                                        </Flex>
                                        {this.props.skipped && this.props.skipped.length != 0 ? <Flex row style={{fontSize: '14px', color: '#4E54C8'}}>
                                            <div style={{width: '70%'}} onClick={this.viewSkipped}>See All
                                                <img style={{marginLeft: '10px'}} src='/images/Right_arrow_2.svg'></img>
                                            </div>
                                        </Flex> : null}
                                    </Flex>
                                    
                                </Flex>
                                <div onClick={this.onSubmitTest} style={{display: 'flex', flexDirection: 'row',marginTop: '20px', color: '#6568d9'}}>
                                    <div style={{fontWeight: 'bold', fontSize: '20px', margin: 'auto 0'}}>Submit exam</div>
                                    <div style={{margin: 'auto', marginTop: '10px',marginLeft:'10px'}}>
                                        <img width='18px' height='18px' src='/images/Right_arrow_blue.svg'></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </Flex>
                {this.state.openSubmitTestDialog ?  <Modal dontShowClose={true} padding='20px' width='500px'  open={this.state.openSubmitTestDialog} closeDialog = {this.closeSubmitTestDialog} submit={this.confirmSubmitTest}>
                    <div style={{marginTop: '30px', color: 'red'}}>Note: Please Save all your questions before Submitting the test.</div>
                    <div style={{marginTop: '10px'}}>{this.state.dialogTitle}</div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '40px 20px'}}>
                        <div onClick={this.closeSubmitTestDialog} style={{margin:'auto', marginLeft: '0', color: 'red'}}>Cancel</div>
                        <div onClick={this.confirmSubmitTest} style={{margin: 'auto', marginRight: '10px', color: '#6568d9'}}>Submit</div>
                    </div>
                </Modal> : null}
                {/* <Modal open={this.props.testSubmitted} closeDialog={this.closeTestSubmitted}>
                    <div>Your test has been submitted</div>
                </Modal> */}
                {this.state.windowChangeWarningModalOpen && <Modal style={{zIndex:12}} closeDialog={this.onCloseWargningWindow} open={this.state.windowChangeWarningModalOpen}>
                    <div>
                        Warning: You are NOT allowed to switch apps during the test! If you
                        do one more time your test will be submitted.
                    </div>
                    <div
                        onClick={this.onCloseWargningWindow}
                        style={{ margin: "auto", marginRight: "10px", color: "#6568d9" }}
                    >
                        OK
                    </div>
                </Modal>}

                {this.state.openImage && <ImageModal width='500px' height='70%' padding='none' style={{zIndex:12}} closeDialog={this.closeImage} open={this.state.openImage} url={!this.state.ques_image_open ? URL.createObjectURL(this.state.fileData) : this.state.fileData}>
                </ImageModal>}

                
                {testSubmitting && <Modal open={testSubmitting}>
                    <div>Test is Submitting</div>
                </Modal>}

                {this.state.openInstructions && <Modal padding='20px' open={this.state.openInstructions} closeDialog={this.closeInstructions} width='750px' height='80%' marginTop='50px'>
                    <SubjectiveInstructions showStart={false}></SubjectiveInstructions>
                </Modal>}
                {this.state.openChangeAnswerTypeDialog && <Modal padding='20px' width='400px' height='180px' style={{zIndex:12}} closeDialog={this.cancelChangeAnswerType} dontShowClose={true} open={this.state.openChangeAnswerTypeDialog}>
                    <div style={{margin: '10px auto'}}>
                        {this.state.changeAnswerDialog}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent:'flex-end', margin: '10px auto'}}>
                        <div style={{margin: 'auto', marginRight: '10px', color: '#7175E0', fontSize: '18px', fontWeight: '600px', cursor: 'pointer'}} onClick={this.cancelChangeAnswerType}>Cancel</div>
                        <div onClick={this.confirmChangeAnswerType} className='discardButton'>Discard</div>
                    </div>
                </Modal>}
                {this.state.openReportDialog ? <ReportDialog 
                    open={this.state.openReportDialog} 
                    close={this.closeReport} 
                    reportItem={this.state.itemToBeReported}
                    reportItemId={this.state.reportItemId}>
                </ReportDialog> : null}
                <style  jsx scoped>{`
                    .rightpane {
                        background: url('/images/dashboard_right_pane.png');
                        height: auto;
                        background-size: 100% 100%;
                        background-repeat-x: no-repeat;
                        background-position-x: center;
                        padding-bottom: 50px;

                    }
                    .submitButton{
                        border: 1px solid #6568D9;
                        // border-image: linear-gradient(to right bottom, #6568D9, #9055CA) 1;
                        margin: auto;
                        // margin-left: 20px;
                        float: right;
                        width: 100px;
                        height: 23px; 
                        padding: 0.3em 1em;
                        padding-top: 8px;
                        padding-left: 20px;
                        border-radius: 20px; 
                        font-size: 14px;
                        color: #6568D9;
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        &:focus{
                            outline:0;
                        }
                          
                      }
                    .nextButton {
                        background:
                            linear-gradient(to right bottom, #7175E0 0%, #4D50CB 32.912%, #E25BC8 100%) padding-box, 
                            linear-gradient(to right bottom, #7175E0 0%, #4D50CB 32.912%, #E25BC8 100%) border-box;
                        margin: auto;
                        float: right;
                        padding: 0.3em 1em;
                        border-radius: 20px; 
                        font-size: 14px;
                        width: 100px;
                        display: flex;
                        flex-direction: row;
                        height: min-content;
                        box-sizing: border-box;
                        font-weight: 500;
                        color: white;
                        border: none;
                        cursor: pointer;
                    }

                    .discardButton{
                        background: linear-gradient(131.37deg, #7175E0 -8.1%, #4D50CB 45.77%, #E25BC8 155.59%) padding-box,
                                    linear-gradient(131.37deg, #7175E0 -8.1%, #4D50CB 45.77%, #E25BC8 155.59%) border-box;
                        text-align: center;
                        border-radius: 20px;
                        margin: auto 20px;
                        padding: 10px 20px;
                        color: white;
                        font-size: 18px;
                        font-weight: 600;
                        cursor: pointer;
                    }
                    .RTE{
                        height: 258px;
                        overflow: auto;
                    }
                `}

                </style>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.sub.loading,
        error_loading_sub: state.sub.error_loading_sub,
        test_started: state.sub.test_started,
        test_id: state.sub.test_id,
        name: state.sub.name,
        description: state.sub.description,
        timelimit: state.sub.timelimit,
        created_at: state.sub.created_at,
        date_of_expiry: state.sub.date_of_expiry,
        subject_id: state.sub.subject_id,
        questions: state.sub.questions,
        sections: state.sub.sections,
        testSubmitted: state.sub.testSubmitted,
        testSubmitting: state.sub.testSubmitting,
        answers: state.sub.answers,
        remaining_questions: state.sub.remaining_questions,
        answered: state.sub.answered,
        skipped: state.sub.skipped,
        test_already_submitted: state.sub.test_already_submitted

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSubjectiveAssessment: (test_id)=>dispatch(getSubjectiveAssessmentAC(test_id)),
        addQuestionImage: (files, index)=>dispatch({type: 'ADD_QUESTION_IMAGE', index: index, files: files}),
        changeAnswerType: (index, type)=>dispatch({type: 'CHANGE_ANSWER_TYPE', index: index, answer_type: type}),
        updateAnswer: (index, text)=>dispatch({type: 'UPDATE_ANSWER', index: index, text: text}),
        submitQuestion: (index)=>dispatch(submitSubjectiveQuestionAC(index)),
        deleteUploadedImage: (question_index, file_index)=> dispatch({type: 'DELETE_UPLOADED_IMAGE', index: question_index, file_index: file_index})
        

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Subjective));
