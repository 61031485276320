import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IoArrowForwardOutline } from "react-icons/io5";
import './autoLibHome.css';
import axios from "../../interceptor";

let http = axios;

const AutoLibHome = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const history = useHistory();
    const [memberCode, setMemberCode] = useState('');
    const [token, setToken] = useState('');
    const [flag, setFlag] = useState(true);
    useEffect(() => {
        if (flag) {
            authenticateAutoLibFun();
        }
    }, []);

    const authenticateAutoLibFun = async () => {
        // const res = await authenticateAutoLib(user?.roll_number);
        // const res = dispatch(authenticateAutoLib('N0009'));
        const roll_number = user?.roll_number;
        // const roll_number = 'N0009';
        const formdata = {
            "ChannelName": "WEB",
            "memberCode": roll_number,
            "secretKey": "Exr595yzhnhiJiloUcwVRScM9BtRwWe5jck8SZylIY"
        }
        let result = "";
        let url = "";
        if (user?.college_id === 29) {
            url = "https://opac.rmd.ac.in:8443/autolib-api/selfChickin/authenticate";
        } else if (user?.college_id === 32) {
            url = "https://opac.rmkcet.ac.in:8443/autolib-api/selfChickin/authenticate";
        }else if (user?.college_id === 27) {
            url = "https://opac.rmkec.ac.in:8443/autolib-api/selfChickin/authenticate";
        }
        await http
            .post(url, formdata)
            .then((res) => {
                if (res) {
                    console.log("success!", res.data);
                    result = res.data;
                } else {
                    console.log("in error autloLib Auth!");
                    result = "Failed";
                }
            })
            .catch((err) => {
                console.log("error in autloLib Auth 2!");
                console.log(err);
            });

        console.log(result);
        if (result.StatusCode === "00") {
            setMemberCode(result.memberCode);
            setToken(result.token);
            setFlag(false);
        } else {
            console.log("Authentication failed!");
            setMemberCode('');
            setToken('');
            setFlag(false);
        }
    };

    const gotoDashBoard = () => {
        history.push('/home')
    }

    return (
        <div>
            <nav class="navbar">
                <div class="d-flex align-items-center">
                    <a href="index.html" class="ml-1" style={{ textDecoration: 'none', overflow: 'hidden', width: 'max-content' }}>
                        <img width="190" height="80" src="rmklogo1.png" class="nav-logo" />
                        <div class="nav-text">Powered by Edwisely</div>
                    </a>
                    <p id="greetingsNav"><span>Hello,</span>&nbsp;<b
                        id="greetingsNavName">{user?.name ? user.name : 'User'}</b></p>
                </div>
                <div>
                    <button type="button" className="btn btn-primary btn-sm backbutton" onClick={gotoDashBoard}>Back to Dashboard <IoArrowForwardOutline /></button>
                </div>
            </nav>

            <div>
                {
                    flag === false
                        ? memberCode && token
                            ? user?.college_id === 29
                                ? (
                                    <iframe frameborder="0" width='100%' height="660px" style={{ marginTop: "60px", overflowX: 'hidden' }} src={`https://opac.rmd.ac.in:8443/member/authenticate/${memberCode}/${token}`}></iframe>
                                )
                                : user?.college_id === 32
                                    ? (
                                        <iframe frameborder="0" width='100%' height="660px" style={{ marginTop: "60px", overflowX: 'hidden' }} src={`https://opac.rmkcet.ac.in:8443/member/authenticate/${memberCode}/${token}`}></iframe>
                                    )
                                    : user?.college_id === 27
                                        ? (
                                            <iframe frameborder="0" width='100%' height="660px" style={{ marginTop: "60px", overflowX: 'hidden' }} src={`https://opac.rmkec.ac.in:8443/member/authenticate/${memberCode}/${token}`}></iframe>
                                        )
                                        : (
                                            <div class="loader-wrapper" style={{ height: '660px' }}>
                                                <h5>Something is wrong, Please try again later!</h5>
                                            </div>
                                        )
                            : (
                                <div class="loader-wrapper" style={{ height: '660px' }}>
                                    <h5>Error 404, You don`t have access to this!</h5>
                                </div>
                            )
                        : (
                            <div class="loader-wrapper" style={{ height: '660px' }}>
                                <div class="loader"></div>
                            </div>
                        )
                }
            </div>
        </div>
    )
}

export default AutoLibHome;