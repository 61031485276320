import { GETSEMESTERFEEDBACKQUESTIONS,SUBMITSEMESTERFEEDBACK} from "../actions/semesterfeedbackActions"

let initial_state = {
    loading: true,
    error_loading_sem_feedbacks:false,
    feedback_answers : [],
    categeory_count:[],
    loading_submission:false,
    submitted_response : false,
    error_submitted_response:false,
}

const reducer = (state = initial_state, action)=> {
    if (action.type == GETSEMESTERFEEDBACKQUESTIONS) {
        if (action.err) {
            return {
                ...state,
                error_loading_sem_feedbacks: true,
                loading: false
            }
        }

        let categeory_count=[];
        let categeory_state=[];
        console.log(action.res.data[0].feedback_questions[0].category_name.replace(" ","_"))
        for(let i=0; i<action.res.data[0].feedback_questions.length; i++){
        if (action.res.data[0]?.feedback_questions[i]?.category_name!="") { 
            // console.log(i)
        let replacedcategeory_name=action.res.data[0].feedback_questions[i].category_name.replace(" ","_");
        let question_id=action.res.data[0].feedback_questions[i].id;
        console.log(replacedcategeory_name)
          if (replacedcategeory_name in categeory_count) {
            categeory_count[replacedcategeory_name]= categeory_count[replacedcategeory_name]+1;
            categeory_state[replacedcategeory_name]=0;
          }else{
            categeory_count[replacedcategeory_name]= 1;
            categeory_state[replacedcategeory_name]= 0;
            
          }
          categeory_state[question_id]= "N";
        }
        }
        return {
            ...state,
            error_loading_sem_feedbacks: false,
            loading: false,
            sem_feedback_questions:action.res.data[0],
            categeory_count:categeory_count,
            categeory_state:categeory_state,
            submitted_response: false,
            error_submitted_response: false,
        }
        console.log(state)
    }
    if (action.type == SUBMITSEMESTERFEEDBACK) {
        if (action.err) {
            return {
                ...state,
                loading_submission:false,
                submitted_response : false,
                error_submitted_response:true,
            }
        }
        return {
            ...state,
            loading_submission:action.loading,
            submitted_response : true,
            error_submitted_response:action.err,
        }
      
    }
    // if (action.type == 'FEEDBACK_OPTION_SELECTED') {
    //     let feedback_answers = {...state.feedback_answers};
    //     feedback_answers[action.question_id] = action.option_id;
    //     return{
    //         ...state,
    //         feedback_answers: feedback_answers
    //     }
    //     console.log(state)
    // }

    if (action.type === 'FEEDBACK_OPTION_SELECTED') {
        const index = state.feedback_answers.findIndex(answer => answer.question_id === action.question_id);
      
        // If the question_id exists in the list, update its question_answer property
        if (index !== -1) {
          return {
            ...state,
            feedback_answers: [
              ...state.feedback_answers.slice(0, index), 
              {
                ...state.feedback_answers[index],
                question_answer: action.option_id,
                type: action.question_type,
              },
              ...state.feedback_answers.slice(index + 1) 
            ]
          };
        } else {
          // If the question_id doesn't exist, add a new item to the list
          return {
            ...state,
            feedback_answers: [
              ...state.feedback_answers,
              {
                question_id: action.question_id,
                question_answer: action.option_id,
                type: action.question_type,
              }
            ]
          };
        }
      }

    if (action.type == 'ON_SUBJECT_CHANGE') {
        return {
            ...state,
            feedback_answers: [],
        }
    }
   
   
   

    return {...state}
}

export default reducer