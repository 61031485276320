import React, { Component, useEffect } from "react";
import "./App.css";
import Routes from "./Routes";
import * as Sentry from "@sentry/react";
	 import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter} from "react-router-dom";
import { connect } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import ThemeProvider from "./theme";
import { history } from "./utils";
import { CssBaseline } from '@mui/material';
import palette from "./theme/palette";
// import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//

// import shadows from './shadows';
import typography from "./theme/typography";
import shadows from "./theme/shadows";
import GlobalStyles from "./theme/globalstyles";
import customShadows from "./theme/customShadows";
import ComponentsOverrides from "./theme/overrides";
import {Button} from "@mui/material";


function App(props) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    });
//   const themeOptions = {
//     palette,
//     shape: { borderRadius: 6 },
//     shadows: shadows(),
//     typography,
//     customShadows: customShadows(),
//   }

// const theme = createTheme(themeOptions);

// theme.components = ComponentsOverrides(theme);

    //Re-running New Relic Script when ever a user has logged in or out.
    useEffect(() => {
      // console.log("Running the new relic scrip in useEffect");
      const script = document.createElement('script');
      script.src = '/newrelicScript.js';
      script.async = true;
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, [props.user_logged_in_successfully]);

    return (
          <HelmetProvider>
          <BrowserRouter history={history}>
          <div className="App">
            <ThemeProvider >
            <CssBaseline />
            {/* <main>This app is using the dark mode</main> */}
            <Routes />
            </ThemeProvider>
          </div>
          </BrowserRouter>
           </HelmetProvider>
    );
  // }
}

const mapStateToProps = (state) => {
  return {
    user_logged_in_successfully: state.login.user_logged_in_successfully,
  };
};


export default connect(mapStateToProps)(App);
