import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL_TAKE_TEST;

// let TOKEN = localStorage.getItem("refresh_token");
// console.log(TOKEN)
// const headers = {
// 	Authorization: 'Bearer ' + TOKEN,
// 	"Content-Type": "application/json"
// };

export const fetchDataFromApi = async (url, params) => {
	try {
		let TOKEN = localStorage.getItem("token");
console.log(TOKEN)
const headers = {
	Authorization: 'Bearer ' + TOKEN,
	"Content-Type": "application/json"
};
		const { data } = await axios.get(BASE_URL + url, { headers, params: params });
		return data;
	} catch (error) {
		console.log(error, 'error');
		return error;
	}
};

export const PostDataToApi = async (url, formData) => {
	try {
		let TOKEN = localStorage.getItem("token");
console.log(TOKEN)
const headers = {
	Authorization: 'Bearer ' + TOKEN,
	"Content-Type": "application/json"
};
		const  { data }  = await axios.post(BASE_URL + url, formData , {headers, body:formData , redirect: "follow"});
		return data;
	} catch (error) {
		console.log(error, 'error');
		return error;
	}
};
