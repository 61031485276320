import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppLayout from "../Layout/Layout";
import AssessmentInfo from "../CodingComponents/components/AssessmentInfo/AssessmentInfo";
import { updateSeenStatus } from "../../store/actions/questionnaireActions";
import Modal from "../../pages/assessment/Modal";
import "./QuestionnaireInstructions.css";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
// import "swiper/modules/pagination/pagination.min.css";
import { Pagination } from "swiper";
import { SwiperButton } from "./SwiperButton";
import styled from "styled-components";
import { init, identify, Identify, track } from '@amplitude/analytics-browser';
const AMPLITUDE_API_KEY=process.env.REACT_APP_AMPLITUDE_KEY;
const TestDescriptionWrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: auto;
  }
`;

class Instructons extends Component {
  state = {
    reattempt_msg: false,
    keyboardLoading: true,
    systemLoading: true,
    networkLoading: true,
    systemLoadingError: false,
    startButtonDisable: false,
    rulesAccepted: 0,
    stepError: false,
  };

  componentDidMount() {
    let time = 0;
    const timer = setInterval(() => {
      time++;
      if (time === 1) this.setState({ keyboardLoading: false });
      else if (time === 2) {
        const current = moment();
        const serverTime = moment(this.props.current_server_time);
        serverTime.add(1, "minute");
        // console.log(current.format());
        // console.log(serverTime.format());
        // console.log(moment.duration(serverTime.diff(current)).asMilliseconds().toFixed(3));
        // console.log(Math.round(moment.duration(serverTime.diff(current)).asSeconds()).toFixed(3));
        // console.log(serverTime.diff(current, "seconds"));
        if (
          moment.duration(current.diff(serverTime)).asSeconds().toFixed(0) <= 60
        )
          this.setState({ systemLoading: false });
        else this.setState({ systemLoading: false, systemLoadingError: true });
      } else if (time === 3) {
        this.setState({ networkLoading: false });
      } else clearInterval(timer);
    }, 2000);
  }

  goBack = () => {
    this.props.history.goBack();
  };

  openFullscreen = () => {
    try {
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
      this.props.onTestStart();
      updateSeenStatus(this.props.testId, "mcq");
      console.log(this.state);
      if (this.props.askBefore) this.props.askBefore();
    } catch (error) {
      console.log(error);
    }
  };

  showReattemptModal = () => this.setState({ reattempt_msg: true });

  onCloseReattemptMessage = () => {
    if (!this.state.systemLoadingError) {
      this.setState({ reattempt_msg: false, stepError: false });
      this.openFullscreen();
    } else this.setState({ reattempt_msg: false, stepError: true });
  };

  onAcceptButtonClick = (ruleNo) => {
    this.setState((prevState) => ({
      rulesAccepted: ruleNo,
    }));
    console.log(ruleNo);
    if (ruleNo === 4){
      this.onCloseReattemptMessage();
      let user = JSON.parse(localStorage.getItem("user"));
      const identifyObj = new Identify();
      identifyObj.setOnce('User ID', `${user.user_id}`);
      identify(identifyObj);
      init(AMPLITUDE_API_KEY,`${user.user_id}`);
        const eventProperties = {
          type: this.props.resume ? "Resume" : "Start",
        };
         track('instructions_accepted', eventProperties);
    } 
  };

  render() {
    let timelimit = this.props.timelimit;
    let new_time = this.props.new_time;
    if (new_time && !this.props.showStart) {
      timelimit = new_time;
    }

    if (!this.props.showStart) {
      return (
        <div
          style={{
            padding: "10px",
            margin: "16px",
            marginTop:  "16px",
            paddingBottom: "0",
          }}
        >
          <div
            style={{
              textAlign: "left",
              background: "#ffffff",
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          >
            {this.props.name ? (
              <div>
                <AssessmentInfo
                  title={this.props.name}
                  portion={this.props.subject_name}
                  time={timelimit}
                  qcount={this.props.questions.length}
                  doe={this.props.date_of_expiry}
                />
                <TestDescriptionWrapper>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <h4
                        style={{
                          fontSize: "18px",
                          margin: "0.1em 0",
                          textAlign: "left",
                          fontWeight: "normal",
                          marginBottom: "20px",
                        }}
                      >
                        Points to Remember
                      </h4>
                      <ul style={{ marginTop: "0.2em" }}>
                        {this.props.sections.length !== 0 ? (
                          <li>
                            There are {this.props.sections.length} sections in
                            this test.
                            <ul style={{ paddingLeft: "20px" }}>
                              {this.props.sections.map((sec, index) => {
                                return (
                                  <li key={index}>
                                    <div style={{ fontWeight: "bold" }}>
                                      {" "}
                                      Section {index + 1} :
                                    </div>
                                    <div style={{ fontStyle: "italic" }}>
                                      Name: {sec.name}
                                    </div>
                                    <div style={{ fontStyle: "italic" }}>
                                      Marks: {sec.marks}
                                    </div>
                                    {sec.instructions && (
                                      <div>
                                        Instructions: {sec.instructions}
                                      </div>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        ) : null}
                        <li>
                          The test will have multiple different options with a single correct answer.
                        </li>
                        <li>
                          Because of any unwanted or unexpected reasons, If you get suddenly disconnected from the test 
                            environment, all your submissions until then will be taken into consideration.
                        </li>
                        <li>
                          The test will be auto-submitted once the allocated duration of the test completes.
                        </li>
                        <li>
                          The results for the test will auto-appear after the test results are released.
                        </li>
                        <li>
                          The detailed report for the test and correct answers will be shown in the results.
                        </li>
                        <li>
                          You are strictly prohibited from taking screenshots during your test.
                        </li>
                      </ul>
                      <div className="doDontTable row w-100">
                        <div className="col-6" style={{borderRight: '1px solid darkgray'}}>
                          <h4>Do's</h4>
                          <div className="list">
                            <ul>
                              <li>
                                Turn off system notifications on your system.
                              </li>
                              <li>
                                Put your mobile on DND (do-not-disturb) mode.
                              </li>
                              <li>
                                Charge your device fully.
                              </li>
                              <li>
                                Have stable internet before starting the test.
                              </li>
                              <li>
                                Set Your lock screen timeout to maximum.
                              </li>
                              <li>
                                Set the manual brightness of your device before starting the test.
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-6">
                          <h4>Don'ts</h4>
                          <div className="list">
                            <ul>
                              <li>Switching tabs on Web or Mobile devices</li>
                              <li>Switching the Internet during the test</li>
                              <li>
                                Going back and forth from the test environment
                              </li>
                              <li>Viewing system alerts/notifications</li>
                              <li>
                                Using keyboard functionalities or any shortcuts.
                              </li>
                              <li>
                                Clicking the Esc key or doing a Two-finger swipe
                                on the mouse pad
                              </li>
                              <li>
                                Clicking the back button on mobile while taking
                                the test.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TestDescriptionWrapper>
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    return (
      <div>
        {this.props.showStart ? <AppLayout></AppLayout> : null}
        <div
          style={{
            padding: this.props.showStart ? "20px" : "10px",
            margin: this.props.showStart ? "16px 64px" : "16px",
            marginTop: this.props.showStart ? "96px" : "16px",
            paddingBottom: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              background: "#ffffff",
              boxShadow: this.props.showStart
                ? "0px 1px 14px rgba(143, 143, 143, 0.2)"
                : null,
              borderRadius: "8px",
              padding: "30px",
              marginBottom: "16px",
            }}
          >
            {this.props.name ? (
              <div>
                <AssessmentInfo
                  title={this.props.name}
                  portion={this.props.subject_name}
                  time={timelimit}
                  qcount={this.props.questions.length}
                  doe={this.props.date_of_expiry}
                />
                <TestDescriptionWrapper>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ul style={{ marginTop: "0.2em" }}>
                      {this.props.sections.length !== 0 ? (
                        <li>
                          There are {this.props.sections.length} sections in
                          this test.
                          <ul style={{ paddingLeft: "20px" }}>
                            {this.props.sections.map((sec, index) => {
                              return (
                                <li key={index}>
                                  <div style={{ fontWeight: "bold" }}>
                                    {" "}
                                    Section {index + 1} :
                                  </div>

                                  <div style={{ fontStyle: "italic" }}>
                                    Name: {sec.name}
                                  </div>
                                  <div style={{ fontStyle: "italic" }}>
                                    Marks: {sec.marks}
                                  </div>
                                  {sec.instructions && (
                                    <div>Instructions: {sec.instructions}</div>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </TestDescriptionWrapper>
                <div className="row py-2">
                  <div className="col-3"></div>
                  <div className="col-6 row text-center">
                    <div className="col-6 text-center">
                      Disabling your keyboard functionalities
                    </div>
                    <div className="col-2">
                      {this.state.keyboardLoading ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <img
                          className="img-reponsive img-assessment"
                          width="20"
                          height="20"
                          src="images/Correct_answer.svg"
                          alt="done"
                        />
                      )}
                    </div>
                    <div className="col-4">
                      {!this.state.keyboardLoading ? (
                        <span className="text-danger">Disabled</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-3"></div>
                  <div className="col-3"></div>
                  <div className="col-6 row text-center">
                    <div className="col-6 text-center">
                      Checking system time configuration
                    </div>
                    <div className="col-2">
                      {this.state.systemLoading ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <img
                          className="img-reponsive img-assessment"
                          width="20"
                          height="20"
                          src={
                            this.state.systemLoadingError
                              ? "images/Wrong_answer.svg"
                              : "images/Correct_answer.svg"
                          }
                          alt="done"
                        />
                      )}
                    </div>
                    <div className="col-4">
                      {!this.state.systemLoading &&
                      !this.state.systemLoadingError ? (
                        <span className="text-danger">Completed!</span>
                      ) : !this.state.systemLoading ? (
                        <span className="text-danger">
                          System time incorrect
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-3"></div>
                  <div className="col-3"></div>
                  {/* <div className="col-6 row text-center">
                    <div className="col-6 text-center">
                      Checking your internet stability
                    </div>
                    <div className="col-2">
                      {this.state.networkLoading ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <img
                          className="img-reponsive img-assessment"
                          width="20"
                          height="20"
                          src={
                            this.props.networkSpeed < 2
                              ? "images/Wrong_answer.svg"
                              : "images/Correct_answer.svg"
                          }
                          alt="done"
                        />
                      )}
                    </div>
                    <div className="col-4">
                      {!this.state.networkLoading &&
                      this.props.networkSpeed > 2 ? (
                        <span className="text-danger">Stable Network!</span>
                      ) : !this.state.networkLoading ? (
                        <span className="text-danger">
                          Slow Network Detected!
                        </span>
                      ) : null}
                    </div>
                  </div> */}
                  <div className="col-3"></div>
                </div>
                {/* <div className="row py-2" style={{border: (this.state.stepError ? "1px solid red" : "none")}}>
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    Disabling your keyboard
                    {this.state.keyboardLoading ? 
                      <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    : <img className="img-reponsive img-assessment" width="20" height="20" src="images/Correct_answer.svg" alt="done"/>
                    }
                    {!this.state.keyboardLoading ? <span className="text-danger">Disabled</span> : null}
                  </div>
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    Checking System
                    {this.state.systemLoading ? 
                      <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    : <img className="img-reponsive img-assessment" width="20" height="20" src={this.state.systemLoadingError ? "images/Wrong_answer.svg" : "images/Correct_answer.svg"} alt="done"/>
                    }
                    {!this.state.systemLoading && !this.state.systemLoadingError ? <span className="text-danger">Completed!</span> : null}
                    {this.state.systemLoadingError ? <span className="text-danger">System time incorrect</span> : null}
                  </div>
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    Checking your network
                    {this.state.networkLoading ? 
                      <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    : <img className="img-reponsive img-assessment" width="20" height="20" src={this.props.networkSpeed < 1 ? "images/Wrong_answer.svg" : "images/Correct_answer.svg"} alt="done"/>
                    }
                    {!this.state.networkLoading && this.props.networkSpeed > 1 ? <span className="text-danger">Stable Network!</span> : 
                      !this.state.networkLoading ? <span className="text-danger">Slow Network Detected!</span> : null}
                  </div>
                </div> */}
                <Swiper
                  pagination={{
                    clickable: true,
                    dynamicBullets: true,
                  }}
                  modules={[Pagination]}
                  spaceBetween={50}
                  slidesPerView={1}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <div className="swiper-slide__img">
                        <img
                          src="images/gif/assessment_1.gif"
                          alt="about the Test"
                        />
                      </div>
                      <ul className="swiper-slide__content">
                        <div className="swiper-slide__title">
                          About the Test
                        </div>
                        <li className="swiper-slide__text">
                          The test will have multiple different options with a single correct answer.
                        </li>
                        <li className="swiper-slide__text">
                          There is no negative marking for incorrectly answered question.
                        </li>
                        <li className="swiper-slide__text">
                          Because of any unwanted or unexpected reasons, If you get suddenly disconnected from the test 
                          environment, all your submissions until then will be taken into consideration.
                        </li>
                        <li className="swiper-slide__text">
                          The test will be auto-submitted once the allocated duration of the test completes.
                        </li>
                        <li className="swiper-slide__text">
                          The results for the test will auto-appear after the test results are released.
                        </li>
                        <li className="swiper-slide__text">
                          The detailed report for the test and correct answers will be shown in the results.
                        </li>
                        <SwiperButton
                          disabled={false}
                          accepted={this.state.rulesAccepted >= 1}
                          onButtonClick={() => this.onAcceptButtonClick(1)}
                        >
                          I Accept
                        </SwiperButton>
                      </ul>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <div className="swiper-slide__img">
                        <img
                          src="images/gif/assessment_2.gif"
                          alt="Things to do before the test starts"
                        />
                      </div>
                      <ul className="swiper-slide__content">
                        <div className="swiper-slide__title">
                          Things to do before the test starts
                        </div>
                        <li className="swiper-slide__text">
                          Turn off system notifications on Windows or MacBook
                          <ul>
                            <li>
                              On Windows OS
                              <ul>
                                <li>
                                  Select Start &gt; Settings &gt; System &gt;
                                  Notifications &gt; Turnoff
                                </li>
                              </ul>
                            </li>
                            <li>
                              On MacBook
                              <ul>
                                <li>
                                  Choose Apple menu &gt; System Preferences,
                                  then click Notifications & Focus. Click
                                  Notifications and deselect as required.
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className="swiper-slide__text">
                          If you are taking the test from mobile, make sure to put it on DND (do-not-disturb) mode.
                        </li>
                        <li className="swiper-slide__text">
                          Make sure your device is fully charged.
                        </li>
                        <li className="swiper-slide__text">
                          Make sure you have stable internet before starting the test.
                        </li>
                        <SwiperButton
                          disabled={this.state.rulesAccepted <= 0}
                          accepted={this.state.rulesAccepted >= 2}
                          onButtonClick={() => this.onAcceptButtonClick(2)}
                        >
                          I Accept
                        </SwiperButton>
                      </ul>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <div className="swiper-slide__img">
                        <img
                          src="images/gif/assessment_2.gif"
                          alt="Things to do before the test starts"
                        />
                      </div>
                      <ul className="swiper-slide__content">
                        <div className="swiper-slide__title">
                          Things to do before the test starts
                        </div>
                        <li className="swiper-slide__text">
                          Set Your lock screen timeout to at least 30-45minutes.
                          <ul>
                            <li>
                              On Windows OS
                              <ul>
                                <li>
                                  {" "}
                                  Control panel &gt; Power options &gt; Change
                                  advanced power settings &gt; console lock
                                  display of timeout &gt; set 45
                                </li>
                              </ul>
                            </li>
                            <li>
                              On MacBook
                              <ul>
                                <li>
                                  Choose Apple menu &gt; System Preferences,
                                  click Screen Time, then click Options in the
                                  sidebar.
                                </li>
                              </ul>
                            </li>
                            <li>
                              On Android
                              <ul>
                                <li>
                                  Settings &gt; display &gt; screen timeout
                                </li>
                              </ul>
                            </li>
                            <li className="swiper-slide__text">
                              Set the brightness of your PC/Computer/phone before the test, You can't change the brightness
                              of your screen during the test.
                            </li>
                          </ul>
                        </li>
                        <SwiperButton
                          disabled={this.state.rulesAccepted <= 1}
                          accepted={this.state.rulesAccepted >= 3}
                          onButtonClick={() => this.onAcceptButtonClick(3)}
                        >
                          I Accept
                        </SwiperButton>
                      </ul>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <div className="swiper-slide__img">
                        <img
                          src="images/gif/assessment_3.gif"
                          alt="Things Not to do during test"
                        />
                      </div>
                      <ul className="swiper-slide__content">
                        <div className="swiper-slide__title">
                          Things Not to do during test
                        </div>
                        <li className="swiper-slide__text">
                          The below actions will be considered interruptions and the test will be submitted automatically after 
                          an initial warning!!
                          <ul>
                            <li>Switching tabs on Web or Mobile devices</li>
                            <li>Switching the Internet during the test</li>
                            <li>
                              Going back and forth from the test environment
                            </li>
                            <li>Viewing system alerts/notifications</li>
                            <li>
                              Using keyboard functionalities or any shortcuts.
                            </li>
                            <li>
                              Clicking the Esc key or doing a Two-finger swipe
                              on the mouse pad
                            </li>
                            <li>
                              Clicking the back button on mobile while taking
                              the test.
                            </li>
                          </ul>
                        </li>
                        <li className="swiper-slide__text">
                          You are strictly prohibited from taking screenshots during your test.
                        </li>
                        <SwiperButton
                          disabled={this.state.rulesAccepted <= 2}
                          accepted={this.state.rulesAccepted >= 4}
                          onButtonClick={() => this.onAcceptButtonClick(4)}
                        >
                          Accept & {this.props.resume ? "Resume" : "Start"}
                        </SwiperButton>
                      </ul>
                    </div>
                  </SwiperSlide>
                </Swiper>
                {/* {this.props.showStart ? (
                  <div style={{paddingBottom: '20px'}}>
                    <div
                      className="justify-content-between align-items=center"
                      style={{
                        width: "fit-content",
                        margin: "20px auto"
                      }}
                    >
                      <button
                        className="startbutton"
                        disabled={this.state.startButtonDisable}
                        onClick={this.showReattemptModal}
                      >{`START TEST`}</button>
                    </div>
                  </div>
                ) : null} */}
              </div>
            ) : null}
          </div>
        </div>
        {this.state.reattempt_msg ? (
          <Modal style={{ zIndex: 12 }} open={this.state.reattempt_msg}>
            <div>
              <b className="text-danger" style={{ fontSize: "large" }}>
                MUST READ
              </b>
              <br />
              <p className="text-left">
                1. The test once opened either on your mobile or web
                application, will need <b>to be continued on the same device</b>
                , else you will be aborted from the test.
              </p>
              <p className="text-left">
                2. Once the test is started, you will <b>NOT</b> be allowed to
                change the device, attempt the test from other device or
                reattempt from same device.
              </p>
              <p className="text-left">
                3. Please be careful with your Keyboard since screen
                minimization or tab switches <b>will lead to submission</b>.
              </p>
            </div>
            <div
              onClick={this.onCloseReattemptMessage}
              style={{
                margin: "auto",
                marginRight: "10px",
                color: "#6568d9",
                cursor: "pointer",
              }}
            >
              <b>I Confirm</b>
            </div>
          </Modal>
        ) : null}
        {this.props.showNetworkModal ? (
          <Modal style={{ zIndex: 12 }} open={this.props.showNetworkModal}>
            <div>
              Your network speed is slow that can cause issues while attempting
              the test. Please consider switching to better network before
              starting the test.
            </div>
            <div
              onClick={this.props.closeNetworkModal}
              style={{
                margin: "auto",
                marginRight: "10px",
                color: "#6568d9",
                cursor: "pointer",
              }}
            >
              <b>Sure</b>
            </div>
          </Modal>
        ) : null}
        <style jsx="true" scoped>
        {`
            .startbutton {
              background: linear-gradient(
                131.37deg,
                #7175e0 -8.1%,
                #4d50cb 45.77%,
                #e25bc8 155.59%
              );
              border: none;
              color: white;
              padding: 15px 32px;
              text-align: center;
              text-decoration: none;
              display: inline-block;
              font-size: 16px;
              border-radius: 8px;
              width: auto;
            }
            .swiper-slide>div {
              display: flex;
              align-items: center;
              max-width: 800px !important;
              max-height: 450px !important;
              box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2) !important;
            }
            .swiper-pagination{
              margin-bottom: 29px;
            }
          `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    questions: state.ques.questions,
    answers: state.ques.answers,
    test_id: state.ques.practice_id,
    qid: state.ques.qid,
    timelimit: state.ques.timelimit,
    name: state.ques.name,
    sections: state.ques.sections,
    description: state.ques.description,
    loading: state.ques.loading_practice_test,
    date_of_expiry: state.ques.date_of_expiry,
    current_server_time: state.ques.current_server_time,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTestStart: () => dispatch({ type: "TEST_START_FROM_INSTRUCTIONS" }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Instructons)
);
