import moment from 'moment';
import { GETPRACTICESERIESRESULTS } from '../actions/courseAssessmentActions';

const initial_state = {
    loading_assessments: true,
    no_assessments: false,
    loading: true
};

const reducer = (state = initial_state, action) =>{
    if (action.type === 'GET_ASSESSMENTS') {
        if (action.error) {
            return {
                ...state,
                error_loading_assessments : true,
                loading_practice_test: false,
                subject_id: action.subject_id,
                student_name: action.student_name
            }
        }
        // console.log(action)
        let data = action.response.data;
        if (!data || data == 'null' || data == null || data.length == 0) {
            return {
                ...state,
                no_assessments: true,
                loading_assessments: false,
                error_loading_assessments: false,
                subject_id: action.subject_id,
                student_name: action.student_name

            }
        }
        let graphData = [];
        let total_questions = 0;
        let total_correct_answered_questions = 0;
        let total_assessments = 0;
        let taken_assessments = 0;
        data.forEach((assessment,index)=>{
            if (assessment.submitted == 1 && graphData.length != 5) {
                let result = {}
                result['your score'] = assessment.percentage;
                result['average'] = assessment.class_average_percentage;
                result['marks'] = (assessment.marks) +  '/' + assessment.total_questions ;
                result['date'] = moment(assessment.created_at).format("DD MMM");
                graphData.push(result);
            }
            if (assessment.submitted == 1) {
                total_correct_answered_questions = total_correct_answered_questions + assessment.marks;
                total_questions = total_questions + assessment.total_questions;
                taken_assessments = taken_assessments + 1;
            }
            total_assessments = total_assessments + 1;
        })
        return {
            ...state,
            assessments: [...action.response.data],
            graphData: graphData,
            loading_assessments: false,
            error_loading_assessments: false,
            total_questions: total_questions,
            total_correct_answered_questions: total_correct_answered_questions,
            total_assessments: total_assessments,
            taken_assessments: taken_assessments,
            no_assessments: false,
            error_fetching_assessment: false,
            subject_id: action.subject_id,
            student_name: action.student_name
        }
    }
    else if (action.type === 'GET_PRACTICE_ASSESSMENTS') {
        if (action.error) {
            return {
                ...state,
                error_loading_assessments : true,
                loading_practice_test: false,
                subject_id: action.subject_id,
                student_name: action.student_name
            }
        }
        // console.log(action)
        let data = action.response.data;
        if (!data || data == 'null' || data == null || data.length == 0) {
            return {
                ...state,
                no_assessments: true,
                loading_assessments: false,
                error_loading_assessments: false,
                subject_id: action.subject_id,
                student_name: action.student_name

            }
        }
        let graphData = [];
        let total_questions = 0;
        let total_correct_answered_questions = 0;
        let total_assessments = 0;
        let taken_assessments = 0;
        data.forEach((assessment,index)=>{
            if (assessment.submitted == 1 && graphData.length != 5) {
                let result = {}
                result['your score'] = assessment.percentage;
                result['average'] = assessment.class_average_percentage;
                result['marks'] = (assessment.marks) +  '/' + assessment.total_questions ;
                result['date'] = moment(assessment.created_at).format("DD MMM");
                graphData.push(result);
            }
            if (assessment.submitted == 1) {
                total_correct_answered_questions = total_correct_answered_questions + assessment.marks;
                total_questions = total_questions + assessment.total_questions;
                taken_assessments = taken_assessments + 1;
            }
            total_assessments = total_assessments + 1;
        })
        return {
            ...state,
            assessments: [...action.response.data],
            graphData: graphData,
            loading_assessments: false,
            error_loading_assessments: false,
            total_questions: total_questions,
            total_correct_answered_questions: total_correct_answered_questions,
            total_assessments: total_assessments,
            taken_assessments: taken_assessments,
            no_assessments: false,
            error_fetching_assessment: false,
            subject_id: action.subject_id,
            student_name: action.student_name
        }
    }
    else if (action.type === GETPRACTICESERIESRESULTS) {
        if (action.error) {
            return {
                ...state,
                error_loading_results : true,
                loading: false,
            }
        }
        // console.log(action)
        let data = action.response.data;
        if (!data || !data?.student) {
            return {
                ...state,
                loading: false,
                error_loading_results: true,
            }
        }
        return {
            ...state,
            results: data,
            loading: false,
            error_loading_results: false,
        }
    }
    return state;
}

export default reducer;