import { createSlice } from '@reduxjs/toolkit'

const careerPathReducer = createSlice({
  name: 'careerpath',
  initialState: {
    loadingCareerpath: false,
    errorLoadingCareerPath:false,
    careerPathData:[],
    loadingCareerData: false,
    errorLoadingCareerData: false,
    careerData: {},
    loadingSearch:false,
    searchData:[],
    errorLoadingSearch:false,
    recentSearchesData: [],
    loadingRecentSearchesData: false,
    errorLoadingRecentSearchesData: false,
    addedNode:false
  },
  reducers: {
    setPathData: (state, action)  => {
      const { loadingCareerpath,careerPathData,errorLoadingCareerPath } = action.payload
      state.loadingCareerpath = loadingCareerpath
      state.careerPathData = careerPathData
      state.errorLoadingCareerPath = errorLoadingCareerPath
      return state
    },
    setSearchData: (state, action)  => {
      const { searchData,loading,error } = action.payload
      console.log(searchData)
      state.searchData = searchData?.length>0 ?searchData:'No results found'
      state.loadingSearch = loading
      state.errorLoadingSearch = error
      return state
    },
    setSearchDataEmpty: (state, action)  => {
      state.searchData = []
      state.loadingSearch = false
      state.errorLoadingSearch = false
      return state
    },
    setRecentSearchesData: (state, action)  => {
      const { recentSearchesData , loading, error } = action.payload
      state.recentSearchesData = recentSearchesData
      state.loadingRecentSearchesData = loading
      state.errorLoadingRecentSearchesData = error
      return state
    },
    //Data of individual career (for the drawer)
    setCareerData: (state, action) => {
      const { careerData, loadingCareerData,errorLoadingCareerData } = action.payload
      console.log(action.payload)
      state.careerData = careerData
      state.loadingCareerData = loadingCareerData
      // state.errorLoadingCareerData = errorLoadingCareerData
      return state
    },
    setNewNode: (state, action) => {
      const { data,fit } = action.payload
      const newData = { ...data, fit: fit };
      state.addedNode = newData
      return state
    },
    setNewNodeEmpty: (state, action) => {
      state.addedNode = false
      return state
    },
    
  },
})

export const {
    setPathData,
    setCareerData,
    setSearchData,
    setSearchDataEmpty,
    setRecentSearchesData,
    setNewNode,
    setNewNodeEmpty
} = careerPathReducer.actions
export default careerPathReducer.reducer
