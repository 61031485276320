import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export const NewFeatureIndicator = ({}) => {
        const [backgroundColor, setBackgroundColor] = useState('#3C79F7');
    const [color, setColor] = useState('#fff');
    const [borderColor, setBorderColor] = useState('#fff')

    useEffect(() => {
        // Function to toggle colors every 2 seconds
        const interval = setInterval(() => {
            setBackgroundColor((prevColor) => prevColor === '#3C79F7' ? '#fff' : '#3C79F7');
            setColor((prevColor) => prevColor === '#3C79F7' ? '#fff' : '#3C79F7');
            setBorderColor((prevColor) => prevColor === '#3C79F7' ? '#fff' : '#3C79F7');
        }, 1600);

        return () => clearInterval(interval); // Clear interval on component unmount

    }, []);

    return (
        <>
            <Box 
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '5px',
                    padding: '3.5px 5.5px',
                    // background: '#0B58F5',
                    backgroundColor,
                    marginLeft: '5px',
                    borderRadius: '25px',
                    border: `1px solid ${borderColor}`,
                    transition: 'background-color 0.5s, borderColor 0.5s',
                }}
            >
                <>
                    <img src={backgroundColor === '#fff' ? '/images/gift_box_blue.svg' : '/images/gift_box.svg'} alt='New'/>
                </>
                <Typography variant="caption2" sx={{marginTop: '1.5px', color, transition: 'color 0.5s'}}>New</Typography>
            </Box>
        </>
    )
}


// import { keyframes } from "@emotion/react"; // Import keyframes from emotion/react
// import { Box, Typography } from "@mui/material";
// import React from "react";

// // Define the keyframes for the sweeping animation
// const sweepAnimation = keyframes`
//   0% {
//     transform: translateX(-50%) skewX(30deg);
//   }
//   100% {
//     transform: translateX(320%) skewX(30deg);
//   }
// `;

// export const NewFeatureIndicator = ({}) => {
//     return (
//         <Box 
//             sx={{
//                 position: 'relative',
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 gap: '5px',
//                 padding: '3.5px 5.5px',
//                 background: '#3C79F7',
//                 marginLeft: '5px',
//                 borderRadius: '25px',
//                 border: `1px solid #fff`,
//                 overflow: 'hidden', // Hide overflow for the wave effect
//             }}
//         >
//             <img src={'/images/gift_box.svg'} alt='New'/>
//             <Typography variant="caption2" sx={{ marginTop: '1.5px', color: '#fff' }}>New</Typography>
//             {/* Sweep effect overlay */}
//             <Box 
//                 sx={{
//                     position: 'absolute',
//                     top: 0,
//                     left: '-50%', // Start the overlay outside the element
//                     width: '50%', // Double the width to cover the entire element
//                     height: '100%',
//                     background: 'linear-gradient(to right, transparent 0%, rgba(250, 250, 250, 0.75) 20%, rgba(250, 250, 250, 0.75) 80%, transparent 100%)',
//                     animation: `${sweepAnimation} 1.3s linear infinite`, // Sweep animation
//                 }}
//             />
//         </Box>
//     );
// };