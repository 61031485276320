import axios from '../../interceptor';

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
// let CORS = 'https://cors-anywhere.herokuapp.com/';

export function setGlobalToken() {
    try {
        let token = localStorage.getItem('token');
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    } catch (error) {
      console.log(error);
    }
}

export const GETTEST = 'GET_TEST_DETAILS';

export const getTestDetailsA = (res,type, err) => {
    return {
        type: GETTEST,
        response: res,
        error: err,
        test_type: type
    }
}

export const getQuestionnaireTestDetailsAC = (test_id, type) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/questionnaire/v3/getTest?test_id=${test_id}`)
        .then((res)=> {
            if (res.status ==200) {
                if (res.data.status == 200) {
                    dispatch(getTestDetailsA(res.data, type,false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/questionnaire/v3/getTest?test_id=${test_id}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    dispatch(getTestDetailsA(res.data,type, false));
                                } else {
                                    dispatch(getTestDetailsA(null,type, true));
                                }
                            } else {
                                dispatch(getTestDetailsA(null, type, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(getTestDetailsA(null,type,  true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    });
                } else {
                    dispatch(getTestDetailsA(null, type, true))
                }
            } else {
                dispatch(getTestDetailsA(null, type, true))
            }
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(getTestDetailsA(null, type, true));
        });
    };
};


export const getSubjectiveTestDetailsAC = (test_id, type) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/questionnaire/v2/getSubjectiveTest?test_id=${test_id}`)
        .then((res)=> {
            if (res.status ==200) {
                if (res.data.status == 200) {
                    dispatch(getTestDetailsA(res.data,type, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/questionnaire/v2/getSubjectiveTest?test_id=${test_id}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    
                                    dispatch(getTestDetailsA(res.data,type, false));
                                } else {
                                    dispatch(getTestDetailsA(null, type, true));
                                }
                            } else {
                                dispatch(getTestDetailsA(null, type, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(getTestDetailsA(null, type, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                        dispatch(getTestDetailsA(null, type, true));
                    });
                } else {
                    dispatch(getTestDetailsA(null, type, true));
                }
            } else {
                dispatch(getTestDetailsA(null, type, true));
            }
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(getTestDetailsA(null, type, true));
        });
    };
  };

export const getCodingTestDetailsAC = (test_id, type) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/codeEditor/v2/getCodingTest?test_id=${test_id}`)
        .then((res)=> {
            if (res.status ==200) {
                if (res.data.status == 200) {
                    dispatch(getTestDetailsA(res.data,type, false));
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/codeEditor/v2/getCodingTest?test_id=${test_id}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    
                                    dispatch(getTestDetailsA(res.data,type, false));
                                } else {
                                    dispatch(getTestDetailsA(null, type, true));
                                }
                            } else {
                                dispatch(getTestDetailsA(null, type, true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(getTestDetailsA(null, type, true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                        dispatch(getTestDetailsA(null, type, true));
                    });
                } else {
                    dispatch(getTestDetailsA(null, type, true));
                }
            } else {
                dispatch(getTestDetailsA(null, type, true));
            }
        })
        .catch((err)=> {
            console.log('error in getting practice test')
            console.log(err)
            dispatch(getTestDetailsA(null, type, true));
        });
    };
};