import axios from "axios";


export const calculateRemedialApi = async (questionnaireId) => {
    let userdetails = localStorage.getItem("user");
    userdetails=JSON.parse(userdetails)
    let endpoint = `https://ymqq4dkeif.execute-api.ap-south-1.amazonaws.com/prod/?test_id=${questionnaireId}&student_id=${userdetails.user_id}`;
   
    const response = await axios.get(endpoint);
    if (response.data.statusCode !== 200) {
        throw response;
    } else{
       // do this
    }
    return response;
};