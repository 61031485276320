import React from "react";
import { Component } from "react";
import styled from "styled-components";
import Flex from "styled-flex-component";
import axios from '../interceptor';
let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;

const ModalWrapper = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.1);
`;

const ModalTextWrapper = styled(Flex)`
//   width: 50%;
//   padding: 2em;
//   border: 1px solid #ccc;
//   margin: auto;
  background: #fff;
//   margin-top: 120px;
//   height: max-content;
//   max-height: 650px;
//   overflow-y: scroll;
//   border-radius: 8px;
`;

const IssueDiv = styled.div`
    cursor:pointer;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #bbb3b3;
    padding: 15px; 
    // height: 60px;
    margin: 15px 10px;
    box-sizing: border-box;
`;

const SubmitButton = styled.div`
    border: 1px solid #6568D9;
    margin: auto 20px;
    float: right;
    width: 100px;
    padding: 0.3em 1em;
    padding-top: 8px;
    border-radius: 20px; 
    font-size: 14px;
    color: #6568D9;
    cursor: pointer;
    text-align: center;
    cursor: pointer;
    &:focus{
        outline:0;
    }
`;

const report_issues = ['irrelevant to topic', 'Inappropriate Content', 'Too Tough to Understand', 'Cannot access the file', 'Inadequate content', "Incorrect Options","Other"];
const report_items = ['learning_snippets', 'learning_content', 'academic_materials', 'objective_questions', 'subjective_questions', 'coding_questions', 'question_of_the_day', 'topic_of_the_day'];

class Modal extends Component {
    state = {
        open_content: false,
        subject: null,
        comments: null
    }

    componentDidMount(){

    }
    reportIssueSelected=(issue) => {
        this.setState({issue: issue})
    }
    subjectChangeHandler = (event) => {
        console.log(event.target.value)
        let subject = event.target.value;
        console.log(subject)
        this.setState({subject: subject});
    }
    commentChangeHandler = (event) => {
        let comments = event.target.value;
        this.setState({comments: comments});
    }
    submitReport = () => {
        let issue = this.state.issue;
        console.log(this.props.reportItem)
        let catergory_id = report_items.findIndex((item)=>{
            console.log(item)
            return item == this.props.reportItem
        });
        catergory_id = catergory_id + 1;
        let content_id = this.props.reportItemId;
        let additional_comments = '';
        if (this.state.issue == 'Other') {
            additional_comments = additional_comments + this.state.subject + '\\' + this.state.comments
        }
        const formdata = new FormData();
        formdata.append('category_id', catergory_id);
        formdata.append('content_id', content_id);
        formdata.append('issue', issue);
        formdata.append('additional_comments', additional_comments);
        formdata.append('device', 3);

        let token = localStorage.getItem('token');
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        http.post(`${BASE_API_URL}/user/v2/reportIssue`, formdata)
        .then((res)=> {
            if (res.status == 200) {
                if (res.data.status == 200) {
                    this.setState({reportSubmitted: true});
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                        http.post(`${BASE_API_URL}/user/v2/reportIssue`, formdata)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    this.setState({reportSubmitted: true});

                                } else {
                                    this.setState({reportSubmitted: false, errorSubmitReport: true});
                                }
                            } else {
                                this.setState({reportSubmitted: false, errorSubmitReport: true});
                            }
                        })
                        .catch((err)=>{
                            this.setState({reportSubmitted: false, errorSubmitReport: true});
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                        this.setState({reportSubmitted: false, errorSubmitReport: true});
                    })
                } else {
                    this.setState({reportSubmitted: false, errorSubmitReport: true});
                }
            } else {
                this.setState({reportSubmitted: false, errorSubmitReport: true});
            }
            
        })
        .catch((err)=> {
            console.log('error in submittting report')
            console.log(err)
            this.setState({reportSubmitted: false, errorSubmitReport: true});
        });
    }
    
    render () {
        console.log(this.state);
        if (this.state.reportSubmitted) {
            return(
                <ModalTextWrapper column>
                {/* <div onClick={this.props.close} style={{textAlign: 'right', cursor: 'pointer', fontSize: '30px'}}>&times;</div> */}
                <div style={{margin: '20px'}}>Thanks for your feedback. We have raised your concern to the team and will resolve it at the earliest.</div>
            </ModalTextWrapper>
            )
               
        }
        if (this.state.errorSubmitReport) {
            return (
                <ModalTextWrapper column>
                {/* <div onClick={this.props.close} style={{textAlign: 'right', cursor: 'pointer', fontSize: '30px'}}>&times;</div> */}
                <div style={{margin: '20px'}}>Error submitting report. Please try again after sometime.</div>
            </ModalTextWrapper>
            )
        }        
        return (
            // <ModalWrapper center open={this.props.open}>
                    <ModalTextWrapper column>
                        {/* <div onClick={this.props.close} className="w-100 text-right" style={{cursor: 'pointer', fontSize: '30px'}}>&times;</div> */}
                        {/* {this.state.issue != 'Other' ? <div style={{textAlign: 'center', fontWeight: 'bold'}}>Please select issue to be reported</div> : null} */}
                        {this.state.issue != 'Other' ? <div style={{}}>
                            {report_issues.map((issue)=> {
                                return <IssueDiv style={{backgroundColor: this.state.issue == issue ? '#D6D8FF' : null}} onClick={()=>this.reportIssueSelected(issue)}>
                                    {issue}
                                </IssueDiv>
                            })}
                        </div> : <div>
                        <div style={{display: 'flex', flexDirection: 'row', margin: '20px 0'}}>
                            <div style={{width: '20%', textAlign: 'left'}}>Issue : Other</div>
                                
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', margin: '20px 0'}}>
                                <div style={{width: '20%', textAlign: 'left'}}>Subject : </div>
                                <div style={{width: '80%'}}>
                                    <input onChange={(event)=>this.subjectChangeHandler(event)} style={{width: '100%', padding: '5px',height: '28px', margin: 'auto', border: '1px solid #ccc', borderRadius: '4px', boxSizing: 'border-box'}} placeholder='Please enter subject'></input>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', margin: '20px 0'}}>
                                <div style={{width: '20%', textAlign: 'left'}}>Comments : </div>
                                <div style={{width: '80%'}}>
                                    <textarea onChange={(event)=>this.commentChangeHandler(event)} style={{width: '100%', padding: '5px', height: '100px', margin: 'auto', border: '1px solid #ccc', borderRadius: '4px', boxSizing: 'border-box'}} placeholder='Please enter comments'></textarea>
                                </div>
                            </div>
                        </div>}

                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            {/* <div onClick={this.props.close} style={{margin: 'auto 0',cursor: 'pointer'}}>Cancel</div> */}
                            { (this.state.issue == 'Other' && this.state.subject && this.state.comments) || (this.state.issue && this.state.issue != 'Other') ? <SubmitButton onClick={this.submitReport}>Submit</SubmitButton> : null}
                        </div>
                    </ModalTextWrapper>
                // </ModalWrapper>

        )
    }
}

export default Modal;