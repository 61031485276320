import axios from '../../interceptor';
import { getPersonalityQuestionAtTriggerAC } from './personalityAssessmentActions';

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;


export function setGlobalToken() {
    try {
        let token = localStorage.getItem('token');
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    } catch (error) {
      console.log(error);
    }
}

export const GETTESTRESULTSDATA = 'GET_TEST_RESULTS_DATA';
// export const GETRESULTSANALYTICS = 'GET_RESULTS_ANALYTICS';

export const getTestResultsDataA = (summary, results,analytics, err) => {
    return {
        type: GETTESTRESULTSDATA,
        summary: summary,
        results: results,
        analytics: analytics,
        error: err
    }
}

// export const getTestResultsAnalyticsA = (analytics, err) => {
//     return {
//         type: GETRESULTSANALYTICS,
//         analytics: analytics,
//         error: err
//     }
// }

export const getTestResultsDataAC = (test_id) => {
    console.log('in action creator')
    setGlobalToken();
    return (dispatch, getState) =>{
        http.get(`${BASE_API_URL}/questionnaire/v2/questionnaireSummaryDetails?questionnaire_id=${test_id}`)
        .then((res)=> {
            if (res.status == 200 ) {
                if (res.data.status == 200 ) {
                    http.get(`${BASE_API_URL}/questionnaire/v2/testSubmittedAnswers?test_id=${test_id}`) 
                    .then((res1)=>{
                        console.log(res1)
                        if (res1.status == 200){
                            http.get(` https://4rhe2vuoda.execute-api.ap-south-1.amazonaws.com/prod/student_testleaderboard?test_id=${test_id}&test_type=mcq`)
                            .then((res2)=>{
                                console.log(res1)
                                if (res2.status == 200){
                                    dispatch(getTestResultsDataA(res.data,res1.data,res2.data, false));
                                    if (res.data.summary[0].percentage >= 80) {
                                        dispatch(getPersonalityQuestionAtTriggerAC(2));
                                    }                                    
                                } else {
                                    console.log('in error dispatch 1');
                                    dispatch(getTestResultsDataA(null, null,null,true));
                                }
                            })
                            .catch((err1)=> {
                                console.log(err1)
                                console.log('in error dispatch 2');
                                dispatch(getTestResultsDataA(null, null,null,true));
                            })

                        } else {
                            console.log('in error dispatch 1');
                            dispatch(getTestResultsDataA(null, null,null,true));
                        }
                    })
                    .catch((err1)=> {
                        console.log(err1)
                        console.log('in error dispatch 2');
                        dispatch(getTestResultsDataA(null, null,null,true));
                    })
                } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
                    let refresh_token = localStorage.getItem('refresh_token');
                    http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
                    http.get(`${BASE_API_URL}/auth/refresh`)
                    .then((refres)=> {
                        localStorage.setItem('token', refres.data.token);
                        setGlobalToken();
                        http.get(`${BASE_API_URL}/questionnaire/v2/questionnaireSummaryDetails?questionnaire_id=${test_id}`)
                        .then((res)=> {
                            if (res.status == 200) {
                                if (res.data.status == 200 ) {
                                    http.get(`${BASE_API_URL}/questionnaire/v2/testSubmittedAnswers?test_id=${test_id}`)
                                    .then((res1)=>{
                                        console.log(res1)
                                        if (res1.status == 200){
                                            http.get(` https://4rhe2vuoda.execute-api.ap-south-1.amazonaws.com/prod/student_testleaderboard?test_id=${test_id}&test_type=mcq`)
                                            .then((res2)=>{
                                                console.log(res2)
                                                if (res1.status == 200){
                                                    dispatch(getTestResultsDataA(res.data,res1.data,res2.data,false));
                                                    if (res.data.summary[0].percentage >= 80) {
                                                        dispatch(getPersonalityQuestionAtTriggerAC(2));
                                                    }                                                    
                                                } else {
                                                    console.log('in error dispatch 1');
                                                    dispatch(getTestResultsDataA(null, null,null,true));
                                                }
                                            })
                                            .catch((err1)=> {
                                                console.log(err1)
                                                console.log('in error dispatch 2');
                                                dispatch(getTestResultsDataA(null, null,null,true));
                                            })
                                            dispatch(getTestResultsDataA(res.data,res1.data, false));
                                        } else {
                                            console.log('in error dispatch 1');
                                            dispatch(getTestResultsDataA(null, null,null,true));
                                        }
                                    })
                                    .catch((err1)=> {
                                        console.log(err1)
                                        console.log('in error dispatch 2');
                                        dispatch(getTestResultsDataA(null, null,null,true));
                                    })
                                } else {
                                    dispatch(getTestResultsDataA(null, null,null,true));
                                }
                            } else {
                                dispatch(getTestResultsDataA(null, null,null,true));
                            }
                        })
                        .catch((err)=>{
                            dispatch(getTestResultsDataA(null, null,null,true));
                        })
                    })
                    .catch((err)=> {
                        console.log(err);
                    });
                } else {
                    dispatch(getTestResultsDataA(null, null,null,true));
                }                
            } else {
                dispatch(getTestResultsDataA(null, null,null,true));
            }            
        })
        .catch((err)=> {
            console.log(err)
            dispatch(getTestResultsDataA(null, null,null,true));
        });
    };
};


// export const getTestResultsAnalyticsAC = (test_id) => {
//     console.log('in action creator')
//     setGlobalToken();
//     return (dispatch, getState) =>{
//         http.get(`https://ct0mr3a08i.execute-api.ap-south-1.amazonaws.com/prod/student_testleaderboard?test_id=${test_id}&test_type=mcq`)
//         .then((res)=> {
//             if (res.status == 200 ) {
//                 if (res.data.status == 200 ) {
//                     dispatch(getTestResultsAnalyticsA(res.data, false));
//                 } else if (res.data.status == 401 && res.data.message == 'Signature expired. Please log in again.') {
//                     let refresh_token = localStorage.getItem('refresh_token');
//                     http.defaults.headers.common['Authorization'] = `Bearer ${refresh_token}`;
//                     http.get(`${BASE_API_URL}/auth/refresh`)
//                     .then((refres)=> {
//                         localStorage.setItem('token', refres.data.token);
//                         setGlobalToken();
//                         http.get(`https://ct0mr3a08i.execute-api.ap-south-1.amazonaws.com/prod/student_testleaderboard?test_id=${test_id}&test_type=mcq`)
//                         .then((res)=> {
//                             if (res.status == 200) {
//                                 if (res.data.status == 200 ) {
//                                     dispatch(getTestResultsAnalyticsA(res.data, false));
//                                 } else {
//                                     dispatch(getTestResultsAnalyticsA(null, true));
//                                 }
//                             } else {
//                                 dispatch(getTestResultsAnalyticsA(null, true));
//                             }
//                         })
//                         .catch((err)=>{
//                             dispatch(getTestResultsAnalyticsA(null, true));
//                         })
//                     })
//                     .catch((err)=> {
//                         console.log(err);
//                     });
//                 } else {
//                     dispatch(getTestResultsAnalyticsA(null, true));
//                 }                
//             } else {
//                 dispatch(getTestResultsAnalyticsA(null, true));
//             }            
//         })
//         .catch((err)=> {
//             console.log(err)
//             dispatch(getTestResultsAnalyticsA(null, true));
//         });
//     };
// };
