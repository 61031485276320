import React, {Component} from 'react';
import {getCodingTestA, onProblemSubmissionAC, onProblemFinalSubmissionAC} from '../../store/actions/codingTestActions';
import AppLayout from '../../components/Layout/Layout';
import {connect} from 'react-redux';

class CodingTest extends Component {
    state = {
        settingtimer: true
    }
    componentDidMount = async () => {
        // await this.addScript();
        document.body.style.backgroundColor = '#f5f5f5';
        // localStorage.setItem('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozNDcxMiwicm9sbF9udW1iZXIiOiJjZTAyIiwiYWN0aXZlX3N0YXR1cyI6MSwiaW5pIjoiMTYxNDY5ODg5MyIsImV4cCI6IjE2MTU5OTQ4OTMifQ.jHEoEcRIeTxBM2IWXxffWhfJMyUvl-yPgqAz5F8kgcU');
        console.log('calling action dispatcher');
        this.setState({settingtimer: true})
        await this.props.getCodingTest();
        let time = this.secondsToTime(this.props.timelimit);
        console.log(time);
        this.setState({timeRemaining: time, time: this.props.timelimit});
        this.setTimer();
        this.setState({settingtimer: false, addingWidgets: true});
        await this.addProblemEditors();
        this.setState({addingWidgets: false});
        setTimeout(() => {
            this.callSubscriptionEvents();
            
        }, 3000);
    }

    callSubscriptionEvents = () => {
        let problems = this.props.problems;
        console.log('adding widgets')
        let SE = window.SE;
        let thisObject = this;
        problems.map((prob, index)=> {
            SE.ready(async function() {

                var afterSendSubmission = async function(data) {
        
                    console.log(data)
                    if (data.status.number != 5 && data.status.number != 3) {
                        await thisObject.props.onProblemSubmission(data.apiSubmissionId)
                    }
                    return true;
                };
        
                var SEWidget = SE.widget("se-widget-" + (index+1) );
                SEWidget.events.subscribe('checkStatus', afterSendSubmission);
            });
        });
    }

    addProblemEditors = async () => {
        let problems = this.props.problems;
        console.log('adding widgets')
        problems.map((prob, index)=> {
            let div = document.createElement('div');
            div.setAttribute('class', 'se-widget');
            div.setAttribute('data-widget', prob.hash);
            div.setAttribute('style', "minHeight: 500px;")
            document.getElementById('problem' + (index+1)).appendChild(div);
        });
    }

    addScript= async () => {
        let SE_BASE = "241fe65b.widgets.sphere-engine.com";
        let SE_HTTPS = true;
        let SE = window.SE || (window.SE = []);
        let id = 'sphere-engine-jssdk'
        var js, fjs = document.getElementsByTagName('script')[0];
        if (document.getElementById(id)) return;
        js = document.createElement('script'); js.id = id;
        js.src = (SE_HTTPS ? "https" : "http") + "://" + SE_BASE + "/static/sdk/sdk.min.js";
        await fjs.parentNode.insertBefore(js, fjs);
    }

    setTimer = () => {
        let timerInterval = setInterval(()=> {
            let seconds = this.state.timeRemaining['s'];
            let minutes = this.state.timeRemaining['m'];
            let hours = this.state.timeRemaining['h'];
            if (seconds === 0) {
                if (minutes === 0) {
                    if (hours == 0) {
                        clearInterval(timerInterval)
                    }
                    else {
                        let timeRemaining = {...this.state.timeRemaining};
                        timeRemaining['s'] = 59;
                        timeRemaining['m'] = 59;
                        timeRemaining['h'] = hours - 1;
                        this.setState({timeRemaining: timeRemaining});
                    }
                } else {
                    let timeRemaining = {...this.state.timeRemaining};
                    timeRemaining['s'] = 59;
                    timeRemaining['m'] = minutes - 1;
                    this.setState({timeRemaining: timeRemaining});
                }
            } else {
                let timeRemaining = {...this.state.timeRemaining};
                timeRemaining['s'] = seconds - 1;
                this.setState({timeRemaining: timeRemaining});
            }
        },1000);
        this.setState({timerInterval : timerInterval});
    }

    secondsToTime = (secs)=>{
        let hours = Math.floor(secs / (60 * 60));
    
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
    
        let obj = {
          "h": hours,
          "m": minutes,
          "s": seconds
        };
        return obj;
    }

    render () {
        if (this.props.test_loading) {
           return  <div>Loading.....</div>
        }
        if (this.state.settingtimer) {
            return <div> Loading ,...</div>
        }
        let seconds = this.state.timeRemaining['s'];
        let minutes = this.state.timeRemaining['m'];
        let hours = this.state.timeRemaining['h'];
        if (hours != 0 && hours < 10) {
            hours = '0' + hours;
        } 
        if (minutes != 0 && minutes < 10) {
            minutes = '0' + minutes;
        }
        if (seconds!=0 && seconds < 10) {
            seconds = '0' + seconds
        }
        return (
            <div>
                <AppLayout></AppLayout>
                <div style={{margin: 'auto', position: 'absolute',paddingLeft:'50px', top: '96px', width: '96%'}}>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                        <div style={{textAlign: 'left', width: '50%', marginLeft: '0'}}><strong>Test Name: </strong> {this.props.name}</div>
                        <div style={{textAlign: 'right', width: '50%', marginRight: '20px'}}><strong>Time: </strong> {hours ? hours + ':' + minutes + ':' + seconds : minutes ? minutes + ':' + seconds : '00' + ':' + seconds } </div>
                    </div>
                    <div style={{ marginTop: '20px', textAlign: 'left'}}><strong>Test Description: </strong>{this.props.description}</div>
                    <div style={{marginTop: '20px', border: '1px solid #ccc'}}>
                        {this.props.problems.map((prob, index)=>{
                            return <div style={{margin: '20px',paddingBottom: '20px', borderBottom: '1px solid #ccc', height: '500px'}}>
                                {/* <div>{prob.name}</div>
                                <div>{prob.body}</div> */}
                                <div id={'problem' + (index + 1)}  style={{marginBottom: '20px', height: '450px'}}></div>
                                {prob.submissions.length > 0 ? <button style={{margin: 'auto', marginRight: '10px',backgroundColor: '#7175E0', height: '35px', width: '80px', borderRadius: '8px',fontSize: '14px', fontWeight: '600', color: 'white', float:'right'}} onClick={()=>this.props.onProblemFinalSubmission(prob.id)}>Submit</button> : null}
                            </div>
                        })}
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        test_id: state.ct.test_id,
        test_loading: state.ct.test_loading,
        problems: state.ct.problems,
        name: state.ct.name,
        description: state.ct.description,
        subject_id: state.ct.subject_id,
        subject_name: state.ct.subject_name,
        test_img: state.ct.test_img,
        start_time: state.ct.start_time,
        doe: state.ct.doe,
        timelimit: state.ct.timelimit,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCodingTest: ()=>dispatch(getCodingTestA()),
        onProblemSubmission: (submission_id)=>dispatch(onProblemSubmissionAC(submission_id)),
        onProblemFinalSubmission: (problem_id)=>dispatch(onProblemFinalSubmissionAC(problem_id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CodingTest);