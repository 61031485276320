import React, { useEffect, useState } from 'react'
import RemedialContainer from '../../features/RemedialPath/components/RemedialContainer/RemedialContainer'
import Grid from '@mui/system/Unstable_Grid';
import RemedialHeader from '../../features/RemedialPath/components/RemedialHeader/RemedialHeader'
import ScoreCard from '../../features/RemedialPath/components/ScoreCard/ScoreCard'
import TimerCard from '../../features/RemedialPath/components/TimerCard/TimerCard'
import { Stack } from '@mui/material';
import SpinWheelResultCard from '../../features/RemedialPath/components/SpinWheelResultCard/SpinWheelResultCard';
import SpinResultCard from '../../features/RemedialPath/components/SpinResultCard/SpinResultCard';
import ActiveStudentsCard from '../../features/RemedialPath/components/ActiveStudentsCard';
import { useRemedialContext } from '../../features/RemedialPath/hooks/useRemedial';
import useFetch from '../../hooks/useFetch';
import updateRemedialBanner from '../../features/RemedialPath/utils/helpers/updateRemedialBanner';
import { updateBannerApi } from '../../helpers/updateBanner';
import { fetchDataFromApi } from '../../helpers/Api';
import { useParams } from 'react-router-dom';
import JackpotWrapper from '../../features/RemedialPath/components/JackpotWrapper/JackpotWrapper';

const SpinWheelPage = ({ endpoint }) => {
    const { timer, bannerData, testResultPageData, updateBannerIndex, setBannerData, setDirectBonusPoints } = useRemedialContext();
    const [spinWheelData, setSpinWheelData] = useState();
    const [spinWheelCategory, setSpinWheelCategory] = useState();
    const [prizeIdIndex, setPrizeIdIndex] = useState(false);
    const { testId } = useParams();
    const [jackpot, setJackpot] = useState(false)
    const [headerData, setHeaderData] = useState()
    console.log('spin wheel page -------------------')
console.log(testResultPageData)
    const { data } = useFetch(endpoint);

    const handleDataForSpinWheel = (data) => {
        let arrayData = [];
        data.forEach((item) => {
            arrayData.push({ option: item.category_name, id: item.category_id })
        })
        return arrayData
    }

    const getPrizeIdIndex = (data) => {
        let prizeId = null;
        data.spinwheel_categories.forEach((item, index) => {
            if (item.category_id === data.selected_category_id) {
                prizeId = index;
            }

        })
        return prizeId
    }

    useEffect(() => {
        if (data) {
            setSpinWheelData(data)
            setSpinWheelCategory(handleDataForSpinWheel(data.spinwheel_categories))
            setPrizeIdIndex(getPrizeIdIndex(data))
        }
    }, [data])



    const handleUpdateBanner = async (index, type) => {     //type is for 'direct bonus points'
        if (type && type === 'direct bonus points') {
            console.log("In update banner func - calling setDirectBonusPoints - 1")
            setDirectBonusPoints('1');
            updateBannerIndex('4');
        }
        else {
            console.log("In update banner func - calling setDirectBonusPoints - 0")
            // setDirectBonusPoints(0);
            updateBannerIndex(index);
        }
        console.log("In Spin wheel page -->")
        console.log(testId);
        console.log(bannerData);
        const res = await updateBannerApi(testId, bannerData.stages[index].id);
        const { status } = await res.data
        if (status === 200) {
            const { data } = await fetchDataFromApi(`/questionnaire/v2/getRemedialPathStages?test_id=${testId}`);

            setBannerData(data)
        }
    }


    useEffect(() => {
        if (spinWheelData?.selected_category_name === 'Direct Bonus Points') {
            setJackpot(true)
        }
        if(spinWheelData){
            setHeaderData({percentage:spinWheelData.percentage_scored,grade_achieved:spinWheelData.grade_achieved})
        }
        
        setTimeout(() => {
            setJackpot(false)
        }, 4000);
        setTimeout(() => {
            //Update Banner Cal
            // handleUpdateBanner(bannerData.stages.length)
        }, 3300);
    }, [spinWheelData])

console.log(prizeIdIndex)
console.log(spinWheelCategory)
console.log(spinWheelData)
    return (
        <>
            {jackpot && <JackpotWrapper />}
            <RemedialContainer>
                <Stack sx={{ gap: '24px' }}>
                    <RemedialHeader heading={'Spin Wheel'} variant='page-header' percentage={spinWheelData?.sections} total={spinWheelData?.total_questions} />
                    <Grid container spacing={3}>
                        <Grid xs={2.7}>
                            <Stack gap={'24px'}>
                                <TimerCard timeLeft={timer} />
                                {headerData ?
                               <ScoreCard
                               showButton={false}
                               data={{
                                 sections: spinWheelData?.sections,
                                 total_questions_count: spinWheelData?.total_questions,
                                 percentage_scored: spinWheelData?.percentage_scored,
                                 grade_achieved: spinWheelData?.grade_achieved,
                               }}
                             />
                             
                            :null}
                            </Stack>
                        </Grid>
                        <Grid xs={3.5}>
                            {spinWheelCategory && (prizeIdIndex>=0) && <SpinWheelResultCard prizeNo={prizeIdIndex} spinWheelCategory={spinWheelCategory} />}
                        </Grid>
                        <Grid xs={3.3}>
                            {spinWheelData && (prizeIdIndex>=0) && <SpinResultCard jackpot={spinWheelData?.selected_category_name === 'Direct Bonus Points' ? true : false} data={spinWheelData} updateBanner={(value, type) => { handleUpdateBanner(value, type) }} />}
                        </Grid>
                        <Grid xs={2.5}>
                            <ActiveStudentsCard bannerData={bannerData} showList />
                        </Grid>
                    </Grid>
                </Stack>
            </RemedialContainer>
        </>
    )
}

export default SpinWheelPage