import React, { useEffect } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import {
  Assessment,
  NoMatch,
  Practice,
  courseAssessmentDashboard,
  coursePracticeDashboard,
  courseContent,
  Editor,
  CodingTest,
  AssessmentResults,
  PracticeResults,
  Dashboard,
  Auth,
  Classroom,
  CodingStartPage,
  CodingAssessment,
  CodingAssessmentResults,
  Profile,
  SubjectiveAssessment,
  SubjectiveResults,
  QuestionnaireExamDetails,
  EditProfile,
  LiveAssessment,
  LiveAssessmentResults,
  LiveSurvey,
  LiveSurveyResults,
  coursePracticeResults,
  Joinchallange,
  Challengeresults,
  // RemedialPath,
  Semesterfeedback,
  UnitFeedbacks,
} from "./pages";
import { isUserAuthenticated } from "./helpers/Auth";
import AutoLibHome from "./pages/AutoLib/autoLibHome";
import CodingScreen from "./pages/codingscreen/index";
import CodingTrackLevel from "./pages/codingscreen/CodingTrackLevel";
import CodingTrack from "./pages/codingscreen/CodingTrackScreen";
import CodingTrackConsole from "./pages/codingscreen/CodingTrackConsole";
import PersonalityAssessment from "./pages/PersonalityAssessment";
import RemedialPath from "./pages/RemedialPath/RemedialPath";
import RemedialCalculation from "./pages/RemedialPath/RemedialCalculation";
import PerfectScore from "./pages/PerfectScore/PerfectScore";
import LandingPage from "./pages/CareerPath/LandingPage";
import OverviewFlow from "./pages/CareerPath/CareerPath";

const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
});

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isUserAuthenticated() === true ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

const Routes = () => (
  <ScrollToTop>
    <Switch>
      {/* <Route exact path="/" component={Home} /> */}
      <Route path="/" component={Auth} exact />
      <PrivateRoute path="/assessment" component={Assessment} exact />
      <PrivateRoute
        path="/assessmentResults"
        component={AssessmentResults}
        exact
      />
      <PrivateRoute
        path="/practiceTestResults"
        component={PracticeResults}
        exact
      />
      <PrivateRoute path="/practice" component={Practice} exact />
      <PrivateRoute
        path="/course/practice"
        component={coursePracticeDashboard}
        exact
      />
      <PrivateRoute
        path="/course/practice/results"
        component={coursePracticeResults}
        exact
      />
      <PrivateRoute
        path="/course/assessment"
        component={courseAssessmentDashboard}
        exact
      />
      <PrivateRoute path="/course/content" component={courseContent} exact />
      <PrivateRoute path="/codeEditor" component={Editor} exact />
      <PrivateRoute path="/codingTest" component={CodingTest} exact />
      <PrivateRoute path="/home" component={Dashboard} exact />
      <PrivateRoute path="/classroom" component={Classroom} exact />
      <PrivateRoute path="/startCoding" component={CodingStartPage} exact />
      <PrivateRoute
        path="/codingAssessment"
        component={CodingAssessment}
        exact
      />
      <PrivateRoute
        path="/codingAssessmentSummary"
        component={CodingAssessmentResults}
        exact
      />
      <PrivateRoute path="/profile" component={Profile} exact />
      <PrivateRoute
        path="/subjectiveAssessment"
        component={SubjectiveAssessment}
        exact
      />
      <PrivateRoute
        path="/subjectiveResults"
        component={SubjectiveResults}
        exact
      />
      <PrivateRoute
        path="/questionnaireDetails"
        component={QuestionnaireExamDetails}
        exact
      />
      <PrivateRoute path="/editProfile" component={EditProfile} exact />
      <PrivateRoute path="/liveAssessment" component={LiveAssessment} exact />
      <PrivateRoute
        path="/liveAssessmentResults"
        component={LiveAssessmentResults}
        exact
      />
      <PrivateRoute path="/liveSurvey" component={LiveSurvey} exact />
      <PrivateRoute
        path="/liveSurveyResults"
        component={LiveSurveyResults}
        exact
      />
      <PrivateRoute path="/joinchallange" component={Joinchallange} exact />
      <PrivateRoute
        path="/challengeResults"
        component={Challengeresults}
        exact
      />
      {/* <PrivateRoute path="/remedial" component={RemedialPath} exact /> */}
      <PrivateRoute path="/semesterfeedback" component={Semesterfeedback} exact />
      <PrivateRoute path="/autoLib" component={AutoLibHome} exact/>
      <PrivateRoute path="/codingscreen" component={CodingScreen} exact/>
      <PrivateRoute path="/codingTrackLevel" component={CodingTrackLevel} exact />
      <PrivateRoute path="/codingTrack" component={CodingTrack} exact />
      <PrivateRoute path="/codingTrackConsole" component={CodingTrackConsole} exact />
      <PrivateRoute path="/personalityAssessment" component={PersonalityAssessment} exact/>
      <PrivateRoute path="/unitFeedbacks/:subjectId/:unitId" component={UnitFeedbacks} exact />

      <PrivateRoute path="/remedial/:testId" component={RemedialCalculation} exact />
      <PrivateRoute path="/testresult/:testId" component={PerfectScore} exact />
      <PrivateRoute path="/remedial/testresult/:testId" component={RemedialPath} exact />
      <PrivateRoute path="/career-get-started" component={LandingPage} exact/>
      <PrivateRoute path="/overviewflow" component={OverviewFlow} exact/>
      <Route component={NoMatch} />
    </Switch>
  </ScrollToTop>
);

export default withRouter(Routes);
