import React, { useState } from 'react'
import RemedialCard from '../RemedialCard/RemedialCard'
import {Skeleton, Stack, Typography } from '@mui/material'
import QuestionnaireSvg from './../../../RemedialPath/../../assets/remedial/svg/Questionnaire.svg'
import ViewButton from '../RemedialButtons/ViewButton'
import QuestionSheet from '../QuestionSheet/QuestionSheet'
import QuestionnaireModal from './QuestionnaireModal'
import useFetch from '../../../../hooks/useFetch'

const Questionnaire = ({ title = 'Questionnaire', modalTitle = 'Questionnaire', width, height, endpoint }) => {

  const [modal, setModal] = useState(false)


  const { data } = useFetch(endpoint); //Todo: add id in future , since data is only coming for id = 45

  return (
    <>
      <RemedialCard>
        {/* head */}
        <Stack gap={'32px'}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
              <img src={QuestionnaireSvg} width={20} height={20} alt='Questionnaire'></img>
              <Typography component={'p'} sx={{
                fontFamily: 'Poppins-SemiBold',
                fontSize: '16px',
                color: 'grey.900'
              }}>{title}</Typography>
            </Stack>
            <span onClick={() => { setModal(true) }}><ViewButton text='View Results' /></span>
          </Stack>
          {/* QuestionSheet */}
          {data ? <QuestionSheet data={data} columnWidth={34} /> : <QuestionSheetPlaceHolder/>}
        </Stack>
      </RemedialCard>

      {modal && <QuestionnaireModal data={data} modalTitle={modalTitle} show={modal} handleClose={() => setModal(false)} />}

    </>
  )
}

export default Questionnaire



const QuestionSheetPlaceHolder = () => {
  return (
      <Stack direction={'row'} spacing={1}>
          {[...Array(10)].map((item) => (
               <Skeleton variant="rounded" width={40} height={40} sx={{background: theme => theme.palette.grey[200]}} />
          ))}
      </Stack>
  )
}