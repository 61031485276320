import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import practiceReducer from "./reducers/practiceReducer";
import questionnaireReducer from "./reducers/questionnaireReducer";
import courseAssessmentDashboardReducer from "./reducers/courseAssessmentReducer";
import courseContentReducer from "./reducers/courseContentReducer";
import codingTestReducer from "./reducers/codingTestReducer";
import questionnaireResultsReducer from "./reducers/questionnaireResultsReducer";
import practiceResultsReducer from "./reducers/practiceResultsReducer";
import dashboardReducer from "./reducers/dashboardReducer";
import loginReducer from "./reducers/loginReducer";
import classroomReducer from "./reducers/classroomReducer";
import profileReducer from "./reducers/profileReducer";
import subjectiveReducer from "./reducers/subjectiveReducer";
import subjectiveResultsReducer from "./reducers/subjectiveResultsReducer";
import QuestionnaireDetailsReducer from "./reducers/QuestionnaireDetailsReducer";
import liveReducer from "./reducers/liveReducer";
import joinchallangereducer from "./reducers/joinchallangereducer";
import remedialReducer from "./reducers/remedialReducer";
import semesterfeedbackreducer from "./reducers/semesterfeedbackreducer";
import codingTrackReducer from "./reducers/codingTrackReducer";
import personalityAssessmentReducer from "./reducers/personalityAssessmentReducer";
import unitFeedbacksReducer from './reducers/unitFeedbacksReducer';
import commonReducer from "./reducers/commonReducer";
import thunk from "redux-thunk";
import careerPathReducer from "./reducers/careerPathReducer";
import themeChangeReducer from './reducers/themeChangeReducer';

let rootReducer = combineReducers({
  prc: practiceReducer,
  ques: questionnaireReducer,
  cad: courseAssessmentDashboardReducer,
  cc: courseContentReducer,
  ct: codingTestReducer,
  queRes: questionnaireResultsReducer,
  praRes: practiceResultsReducer,
  dash: dashboardReducer,
  login: loginReducer,
  class: classroomReducer,
  prof: profileReducer,
  sub: subjectiveReducer,
  subRes: subjectiveResultsReducer,
  queDetails: QuestionnaireDetailsReducer,
  live: liveReducer,
  joinchallenge: joinchallangereducer,
  remedial: remedialReducer,
  semfeedback:semesterfeedbackreducer,
  codingTrack: codingTrackReducer,
  personalityAssessment: personalityAssessmentReducer,
  unitFeedback: unitFeedbacksReducer,
  common: commonReducer,
  themeChangeReducer,
  careerpath:careerPathReducer,
});

const initial_state = {};

const logger = (store) => {
  return (next) => {
    return (action) => {
      console.log("inside middleware");
      const result = next(action);
      return result;
    };
  };
};

const store = createStore(
  rootReducer,
  initial_state,
  compose(applyMiddleware(thunk))
);

export default store;
