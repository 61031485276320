import { Typography, styled } from '@mui/material';
import React, { useState } from 'react'
// import dummyImage from './../../../../../assets/remedial/images/DemoImageChoice.png'
import ImagePreviewModal from '../../ImagePreviewModal/ImagePreviewModal';
import Equation from '../../../../../utils';

const QuestionType2Choice = ({ index, questionData, selectedIndex, title, id , newSelection , handleClicked, img }) => {
    const [imageModal, setImageModal] = useState(false)

    const Choice = styled('div')(({ theme }) => ({
        width: '100%',
        height: '188px',
        borderRadius: '4px',
        border: `1px solid ${theme.palette.grey[200]}`,
        cursor: 'pointer'
    }));

    const ChoiceImage = styled('div')(({ theme }) => ({
        width: '100%',
        height: 'calc(100% - 50px)',
        paddingTop: '10px',
        img: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
        }
    }));

    const ChoiceWrapper = styled('div')(({ theme }) => ({
        width: '100%',
        height: '50px',
        background: newSelection === id ?  theme.palette.primary[100] : selectedIndex === id ? theme.palette.primary[100] : theme.palette.grey[100],
        color: newSelection === id ? theme.palette.primary[800] : selectedIndex === id ? theme.palette.primary[800] : theme.palette.grey[800],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '10px'

    }));

    return (
        <>
            <Choice>
                {img && <ChoiceImage onClick={() => setImageModal(true)}><img src={img} alt='DemoImageChoice' /></ChoiceImage>}
                <ChoiceWrapper onClick={() => handleClicked(id)}>
                    <Typography component={'p'} sx={{
                        fontFamily: 'Poppins-Medium',
                        fontSize: '14px',
                        lineHeight: '10px'
                    }}><Equation equation={title}  math_type={title?.includes('$') ? 1 : 0} /></Typography>
                </ChoiceWrapper>
            </Choice>
            {imageModal && <ImagePreviewModal img={img} show={imageModal} onImageClose={() => { setImageModal(false) }} />}
        </>

    )
}

export default QuestionType2Choice




