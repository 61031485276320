import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import RemedialBanner from '../../features/RemedialPath/components/RemedialBanner/RemedialBanner';
import RemedialStudyPage from './RemedialStudyPage';
import TestResultPage from './TestResultPage';
import SpinWheelPage from './SpinWheelPage';
import { useRemedialContext } from '../../features/RemedialPath/hooks/useRemedial';
import RemedialTestPage from './RemedialTestPage';
import RevisedTestResultPage from './RevisedTestResultPage';
import { useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';

const RemedialAllStages = () => {
    const { activeBannerIndex, setTestResultPageData, directBonusPoints } = useRemedialContext();
    const { testId } = useParams();
    let { user_id } = JSON.parse(localStorage.getItem("user"))
    
    
    // const { data } = useFetch(`/questionnaire/v2/remedialSummary?questionnaire_id=${testId}`);
    // const { remedial } = useFetch(`https://ymqq4dkeif.execute-api.ap-south-1.amazonaws.com/test/?test_id=${testId}&student_id=${user_id}`);
    // useEffect(() => {
    //     if (data) {
    //         setTestResultPageData(data)
    //     }
    // }, [data])

    return (
        <Stack direction={'column'} width={'100%'}>
            <RemedialBanner endpoint={`/questionnaire/v2/getRemedialPathStages?test_id=${testId}`} />
            {/* Screens */}
            {activeBannerIndex === 0 && <TestResultPage endpoint={`/questionnaire/v2/remedialSummary?questionnaire_id=${testId}`} />}
            {activeBannerIndex === 1 && <RemedialStudyPage endpoint={`/questionnaire/v2/getRemedialStudy?test_id=${testId}&student_id=${user_id}`} />}
            {activeBannerIndex === 2 && <SpinWheelPage endpoint={`/questionnaire/v2/getRemedialPathTestDetailsMock?questionnaire_id=${testId}`} />}
            {/* {activeBannerIndex === 3 && <RemedialTestPage endpoint={`/questionnaire/getRemedialPathTest?questionnaire_id=${testId}`} />} */}
            {activeBannerIndex === 3 
            ?   
                directBonusPoints === '1' 
                ?  
                    <RevisedTestResultPage endpoint={`/questionnaire/v2/remedialSummary?questionnaire_id=${testId}`} /> 
                : 
                    <RemedialTestPage endpoint={`/questionnaire/getRemedialPathTest?questionnaire_id=${testId}`} /> 
            : null}
            {activeBannerIndex === 4 && <RevisedTestResultPage endpoint={`/questionnaire/v2/remedialSummary?questionnaire_id=${testId}`} />}
        </Stack>
    )
}

export default RemedialAllStages