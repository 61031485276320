import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import styled from '@mui/system/styled';
import CircularProgress from '@mui/material/CircularProgress';
import colors2 from '../../pages/codingscreen/colors2';



const SearchTextField = styled(TextField)(({ theme }) => ({
  borderRadius: '6.64px',
  border: `1px solid ${colors2.grey[100]}`,
  background: '#FFF',
  boxShadow: '3.7px 3.7px 14.2px 0px rgba(0, 0, 0, 0.05)',
  '& .MuiOutlinedInput-notchedOutline':{
  borderWidth: '0px',
  },
  }));

SearchField2.defaultProps = {
  onChange: null,
  size: 'medium',
  loading:false,
}

export default function SearchField2({ onChange, variant, size, placeholder,loading,width,height}) {
  const[searchText,setSearchText]=React.useState('')
const handleSearchTextChange=(event)=>{
  setSearchText(event.target.value)
  onChange(event)
}
  return (
    <SearchTextField
      fullWidth
      sx={{ '& .MuiOutlinedInput-root': { borderRadius: 1,height:height ? height :35},width:width, }}
      value={searchText}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <img src='/images/search_icon_grey.svg' alt="search"/>
          </InputAdornment>
        ),
        endAdornment:  loading ? (
            <InputAdornment position='end'>
            <CircularProgress size={20} sx={{color: colors2.grey[500]}} />
          </InputAdornment>
        ):null,
      }}
      variant={variant}
      placeholder={placeholder}
      onChange={handleSearchTextChange}
    />

  );
}