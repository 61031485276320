import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import Grid from '@mui/system/Unstable_Grid';
import ScoreCard from '../../features/RemedialPath/components/ScoreCard/ScoreCard';
import TimerCard from '../../features/RemedialPath/components/TimerCard/TimerCard';
import FlukeCard from '../../features/RemedialPath/components/FlukeCard/FlukeCard';
import RemedialHeader from '../../features/RemedialPath/components/RemedialHeader/RemedialHeader'
import RemedialContainer from '../../features/RemedialPath/components/RemedialContainer/RemedialContainer';
import RecordCard from '../../features/RemedialPath/components/RecordCard/RecordCard';
import Questionnaire from '../../features/RemedialPath/components/Questionnaire/Questionnaire';
import AllTopicsCard from '../../features/RemedialPath/components/AllTopicsCard/AllTopicsCard';
import LeaderBoardCard from '../../features/RemedialPath/components/LeaderBoardCard/LeaderBoardCard';
import TimeAnalysisGraph from '../../features/RemedialPath/components/TimeAnalysisGraph/TimeAnalysisGraph';
import useFetch from '../../hooks/useFetch';
import { useParams } from 'react-router-dom'
import { useRemedialContext } from '../../features/RemedialPath/hooks/useRemedial';
import { updateBannerApi } from '../../helpers/updateBanner';
import { fetchDataFromApi } from '../../helpers/Api';




const TestResultPage = ({ endpoint }) => {
    const { testResultPageData, setTestResultPageData, setBannerData, bannerData, updateBannerIndex, timer } = useRemedialContext();

    const { testId } = useParams();
    const { data, loading, error } = useFetch(endpoint);

    useEffect(() => {
        if (data) {
            console.log('---------------')
            console.log(data)
            setTestResultPageData(data)
        }
    }, [data, loading, error])


    const ScoreCardClick = async (index) => {
        updateBannerIndex(index);
        const res = await updateBannerApi(testId, bannerData.stages[index].id);
        const { status } = await res.data
        if (status === 200) {
            const { data } = await fetchDataFromApi(`/questionnaire/v2/getRemedialPathStages?test_id=${testId}`);
            setBannerData(data)
        }
    }


    const onLoadBanner = async (bannerId) => {
        const res = await updateBannerApi(testId, bannerId);
        const { status } = await res.data
        if (status === 200) {
            const { data } = await fetchDataFromApi(`/questionnaire/v2/getRemedialPathStages?test_id=${testId}`);
            setBannerData(data)
        }
    }


    useEffect(() => {
        updateBannerIndex(0);
        if (bannerData?.stages.length > 0) {
            onLoadBanner(bannerData.stages[0].id)
        }
    }, [])

    return (
        <RemedialContainer>
            <Stack sx={{ gap: '24px' }}>
                <Grid container spacing={3}>
                <Grid xs={12}>
                <RemedialHeader heading={'Test Result'} testName={testResultPageData?.test_details?.name} subject={testResultPageData?.test_details?.subject} />
                </Grid>
                    <Grid xs={8.8}>
                        <Stack gap={'12px'}>
                            <Stack gap={'24px'}>
                                <Grid container spacing={3} padding={0}>
                                    <Grid xs={4}><ScoreCard data={testResultPageData} onClick={() => ScoreCardClick(1)} /></Grid>
                                    <Grid xs={4}>
                                        <Stack gap={'24px'}>
                                            <TimerCard timeLeft={timer} />
                                            <FlukeCard value={testResultPageData?.fluke_percentage} points={testResultPageData?.test_details?.remedial_bonus_points} />
                                        </Stack>
                                    </Grid>
                                    <Grid xs={4} container spacing={2} >
                                        <Grid xs={6}><RecordCard variant='time' value={testResultPageData?.total_time} text='Total time spent by you' /></Grid>
                                        <Grid xs={6}><RecordCard variant='rank' value={testResultPageData?.rank} text='Your Class rank' /></Grid>
                                        <Grid xs={6}><RecordCard variant='performance' value={testResultPageData?.percentage} text='average performance' /></Grid>
                                        <Grid xs={6}><RecordCard variant='answered' value={testResultPageData?.unanswered_count} text='unanswered count' /></Grid>
                                    </Grid>
                                </Grid>
                                <Questionnaire title='Questionnaire' modalTitle='Questionnaire' endpoint={`/questionnaire/v2/getTestQuestions?test_id=${testId}`} />
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid xs={3.2}>
                        <Stack gap={'24px'}>
                            <AllTopicsCard data={testResultPageData?.topicWise_data} />
                            <LeaderBoardCard data={testResultPageData?.leader_board} />
                        </Stack>
                    </Grid>
                </Grid>
                {testResultPageData?.time_per_question && <TimeAnalysisGraph data={testResultPageData?.time_per_question} />}
            </Stack>
        </RemedialContainer>
    )
}

export default TestResultPage