import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Grid, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import colors2 from '../../pages/codingscreen/colors2'

const HeaderGrid = styled(Grid)(
    ({ theme }) => ({
        alignItems: 'center',
        height: '65px',
        padding: 0,
    })
)

const PoweredText = styled(Typography)(
    ({ theme }) => ({
        fontSize: '9px',
        fontStyle: 'italic',
        marginTop: '0px',
        marginLeft: '25px',
        color: colors2.grey[500],
        textAlign: 'center',
        // display: 'none',
    })
)


export default function CodingScreenHeader(props){
    const history = useHistory();

    const goHome = () => {
        history.push('/home');
    }
    
    return(
        <>
            <Box>
            <HeaderGrid container sx={{background: props?.background ? props.background : '#fff'}}>
                <Grid item sx={{marginLeft: '30px', marginTop: '10px'}}>
                    <Box component="img" alt="Logo" src={props.logo} sx={{ height: '40px', width: '135px', cursor: 'pointer' }} onClick={goHome}/>
                    <PoweredText>Powered by Edwisely</PoweredText>
                </Grid>
            </HeaderGrid>
            </Box>
        </>
    )
}