import { Box, Typography, styled } from '@mui/material';
import React from 'react'
import { useParams } from 'react-router-dom';
import { PathInnerCircle, PathOuterCircle, PathCircleWrapper } from './RemedialPathBoxStyled';
import TestResultCircleAnimation from '../RemedialPathAnimations/TestResultCircleAnimation/TestResultCircleAnimation';
import RemedialStudyAnimation from '../RemedialPathAnimations/RemedialStudyAnimation/RemedialStudyAnimation';
import SpinWheelAnimation from '../RemedialPathAnimations/SpinWheelAnimation/SpinWheelAnimation';
import RemedialTestAnimation from '../RemedialPathAnimations/RemedialTestAnimation/RemedialTestAnimation';
import RevisedTestAnimation from '../RemedialPathAnimations/RevisedTestAnimation/RevisedTestAnimation';
import { useRemedialContext } from '../../hooks/useRemedial';
import { fetchDataFromApi } from '../../../../helpers/Api';
import { updateBannerApi } from '../../../../helpers/updateBanner';

const animationObject = {
    '.enabled-animation': {
        display: 'block'
    },
    '.disabled-animation': {
        display: 'none'
    },
}

// Main Box
const PathBox = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    '.enabled-animation': {
        display: 'none'
    },
    '&.intial': {
        '.draw-yellow': {
            width: '100%'
        },
        '.pathOuterCircle': {
            backgroundColor: theme.palette.primary.contrastText
        },
        '.pathInnerCircle': {
            background: theme.palette.success.gradient1,
        },
        ...animationObject,
        cursor: 'pointer'
    },
    '&.remedialtest': {
     pointerEvents:'none'
    },
    
    '&.active': {
        '.draw-green': {
            width: '100%'
        },
        '.pathOuterCircle': {
            backgroundColor: `${theme.palette.success[600]} !important`
        },
        '.pathInnerCircle': {
            background: theme.palette.success.gradient1,
        },
        ...animationObject,
        cursor: 'pointer'
    },
    '&.active + div': {
        '.draw-yellow': {
            width: '100%'
        },
        '.pathOuterCircle': {
            backgroundColor: theme.palette.primary.contrastText
        },
        '.pathInnerCircle': {
            background: theme.palette.success.gradient1,
        },
        ...animationObject,
        cursor: 'pointer'
    },
}));



const GetAnimationCircle = ({ type }) => {
    if (type === "actual_test") return <TestResultCircleAnimation />;
    if (type === "study") return <RemedialStudyAnimation />;
    if (type === "spinwheel") return <SpinWheelAnimation />;
    if (type === "remedial_test") return <RemedialTestAnimation />;
    if (type === "revised_test_result") return <RevisedTestAnimation />;


    return null;
};

const PathInfo = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '100px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
}));



const RemedialPathBox = ({ data, index, isActive }) => {
    const { testId } = useParams();
    const { bannerData, updateBannerIndex, activeBannerIndex,setBannerData, setDirectBonusPoints, directBonusPoints } = useRemedialContext();


    const handlePathRedirect = (data1, index) => {
        console.log(data1)
        // console.log(typeof data.id);
        console.log("in handlePathrRedirect --> " + index);
        console.log("in handlePathRedirect - activeBannerIndex: " + activeBannerIndex)
        if (bannerData.stages[activeBannerIndex]?.type !== 'remedial_test') {
            console.log("efewfejfgeyfgeygfeyfgyufgyue")
            if (isActive) {
                updateBannerIndex(index)
                updateBannerIndex(index)
                changeCurrentPosition(index)
            }
            if (data1.type === 'study') {
                updateBannerIndex(index)
                updateBannerIndex(index)
                changeCurrentPosition(index)
            }
            if (!isActive && data1.type === 'revised_test_result' && bannerData.stages[3].id === 5 && bannerData.stages[2].is_completed === 1) { //only applicable when direct bonus points are awarded
                updateBannerIndex('4')
                setDirectBonusPoints('1')
                changeCurrentPosition('3')
            }
        }
        else {
            if (bannerData.stages[3].id === 5 && activeBannerIndex === '4') {
                updateBannerIndex(index)
                changeCurrentPosition(index)
            }
        }
    }
const handleNone=()=>{
    console.log('in test')
}
const changeCurrentPosition = async (index) => {
    const res = await updateBannerApi(testId, bannerData.stages[index].id);
    const { status } = await res.data
    if (status === 200) {
        const { data } = await fetchDataFromApi(`/questionnaire/v2/getRemedialPathStages?test_id=${testId}`);
        setBannerData(data)
    }
}

    return (
        <PathBox className={[index === 0 ? 'intial' : '', isActive ? 'active' : '',data.type == 'remedial_test' ? 'remedialtest' : ''].join(" ")} onClick={() => {data.type !== 'remedial_test' ? handlePathRedirect(data, index, isActive) :handleNone()}} >
            <MovingLines isRotate={!(index % 2 === 0)} />
            <PathCircleWrapper>
                <PathOuterCircle className='pathOuterCircle'>
                    <PathInnerCircle className='pathInnerCircle'>
                        {/* {index === 0 && <TestResultCircleAnimation isActive />}
                        {index === 1 && <RemedialStudyAnimation isActive />}
                        {index === 2 && <SpinWheelAnimation isActive />}
                        {index === 3 && <RemedialTestAnimation isActive />}
                        {index === 4 && <RevisedTestAnimation isActive />} */}

                        <GetAnimationCircle type={data?.type} />
                    </PathInnerCircle>
                </PathOuterCircle>
                <PathInfo>
                    <Typography component={'p'} sx={{
                        fontFamily: 'Poppins-SemiBold',
                        fontSize: '16px',
                        color: (theme) => theme.palette.primary.contrastText
                    }}>{data.name}</Typography>
                    {data.is_completed === 1 && <Box>
                        <Typography component={'p'} sx={{
                            fontFamily: 'Poppins-SemiBold',
                            fontSize: '16px',
                            color: (theme) => theme.palette.primary.contrastText
                        }}>{data.progress}</Typography>
                    </Box>}
                </PathInfo>
            </PathCircleWrapper>
        </PathBox>
    )
}

export default RemedialPathBox


const MovingLines = ({ isRotate }) => {

    const PathLine = styled('div')(({ theme }) => ({
        width: '100%',
        height: '100%',
        position: 'relative'
    }));
    const DottedLine = styled('div')(({ theme }) => ({
        width: '264px',
        height: '64px',
        zIndex: -1,
        position: 'relative'
    }));

    const IntialSolidLine = styled('div')(({ theme }) => ({
        width: '0%',
        position: 'absolute',
        left: '0',
        top: '0',
        overflow: 'hidden',
        zIndex: 2,
        transition: 'width 0.5s linear',
        svg: {
            path: {
                fill: theme.palette.warning[450],
            }
        }
    }));

    const ActiveSolidLine = styled('div')(({ theme }) => ({
        width: '0%',
        position: 'absolute',
        left: '0',
        top: '0',
        overflow: 'hidden',
        zIndex: 3,
        transition: 'width 0.5s linear',
        svg: {
            path: {
                fill: theme.palette.success[600]
            }
        }
    }));

    return (
        <>
            {!isRotate ? <PathLine className='path-line'>
                <DottedLine>
                    <svg width="267" height="61" viewBox="0 0 267 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 1.5C89.6667 78.8333 177.333 78.8333 265 1.5" stroke="white" stroke-opacity="0.6" stroke-width="3" stroke-linecap="round" stroke-dasharray="12 12" />
                    </svg>
                </DottedLine>
                <IntialSolidLine className='draw-yellow'>
                    <svg className='yellow' width="268" height="64" viewBox="0 0 268 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.01542 5.24976C-0.227092 4.15371 -0.345818 2.25793 0.750237 1.01542C1.84629 -0.227092 3.74207 -0.345818 4.98458 0.750237L1.01542 5.24976ZM4.98458 0.750237C48.4552 39.0969 91.6117 58 134.5 58C177.388 58 220.545 39.0969 264.015 0.750237L267.985 5.24976C223.789 44.2364 179.278 64 134.5 64C89.7216 64 45.2115 44.2364 1.01542 5.24976L4.98458 0.750237Z" fill="#4C75F2" />
                    </svg>

                </IntialSolidLine>
                <ActiveSolidLine className='draw-green'>
                    <svg className='green' width="268" height="64" viewBox="0 0 268 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.01542 5.24976C-0.227092 4.15371 -0.345818 2.25793 0.750237 1.01542C1.84629 -0.227092 3.74207 -0.345818 4.98458 0.750237L1.01542 5.24976ZM4.98458 0.750237C48.4552 39.0969 91.6117 58 134.5 58C177.388 58 220.545 39.0969 264.015 0.750237L267.985 5.24976C223.789 44.2364 179.278 64 134.5 64C89.7216 64 45.2115 44.2364 1.01542 5.24976L4.98458 0.750237Z" fill="#4C75F2" />
                    </svg>
                </ActiveSolidLine>
            </PathLine> :
                <PathLine className='path-line' sx={{ top: '-63px' }}>
                    <DottedLine>
                        <svg width="267" height="62" viewBox="0 0 267 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.04004 60C89.7067 -17.3333 177.373 -17.3333 265.04 60" stroke="white" stroke-opacity="0.6" stroke-width="3" stroke-linecap="round" stroke-dasharray="12 12" />
                        </svg>
                    </DottedLine>
                    <IntialSolidLine className='draw-yellow'>
                        <svg className='yellow' width="268" height="64" viewBox="0 0 268 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M266.985 58.7502C268.227 59.8463 268.346 61.7421 267.25 62.9846C266.154 64.2271 264.258 64.3458 263.015 63.2498L266.985 58.7502ZM263.015 63.2498C219.545 24.9031 176.388 6 133.5 6C90.6117 6 47.4552 24.9031 3.98458 63.2498L0.015418 58.7502C44.2115 19.7636 88.7216 -1.90735e-06 133.5 -1.90735e-06C178.278 -1.90735e-06 222.789 19.7636 266.985 58.7502L263.015 63.2498Z" fill="#4C75F2" />
                        </svg>
                    </IntialSolidLine>
                    <ActiveSolidLine className='draw-green'>
                        <svg className='green' width="268" height="64" viewBox="0 0 268 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M266.985 58.7502C268.227 59.8463 268.346 61.7421 267.25 62.9846C266.154 64.2271 264.258 64.3458 263.015 63.2498L266.985 58.7502ZM263.015 63.2498C219.545 24.9031 176.388 6 133.5 6C90.6117 6 47.4552 24.9031 3.98458 63.2498L0.015418 58.7502C44.2115 19.7636 88.7216 -1.90735e-06 133.5 -1.90735e-06C178.278 -1.90735e-06 222.789 19.7636 266.985 58.7502L263.015 63.2498Z" fill="#4C75F2" />
                        </svg>
                    </ActiveSolidLine>
                </PathLine>

            }
        </>
    )
}