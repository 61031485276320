import React, { createContext, useEffect, useRef, useState } from 'react'
import { Skeleton, Stack } from '@mui/material'
import Grid from '@mui/system/Unstable_Grid';
import RemedialContainer from '../../features/RemedialPath/components/RemedialContainer/RemedialContainer'
import RemedialHeader from '../../features/RemedialPath/components/RemedialHeader/RemedialHeader'
import TimerCard from '../../features/RemedialPath/components/TimerCard/TimerCard';
import TestProgressCard from '../../features/RemedialPath/components/TestProgressCard/TestProgressCard';
import RemedialTestSection from '../../features/RemedialPath/components/RemedialTestSection/RemedialTestSection';
import useFetch from '../../hooks/useFetch';
import { useParams } from 'react-router-dom'
import { useRemedialContext } from '../../features/RemedialPath/hooks/useRemedial';
import RemedialCard from '../../features/RemedialPath/components/RemedialCard/RemedialCard';

const RemedialTestPage = ({ endpoint }) => {
    const { testId } = useParams();
    const { setQuestions, questions, questionsStatus, testResultPageData, activeBannerIndex, setDirectBonusPoints,timer } = useRemedialContext();
    const { data } = useFetch(endpoint);
    const mainRef= useRef()

    useEffect(() => {
        //Adding newSelectedOption value in each array
        if (data?.questions) {
            const array = data?.questions;
            const newArray = [];
            array.forEach((item, index) => {
                let value = { ...item, isVisited: index === 0 ? true : false, newSelectedOption: '' }
                newArray.push(value)
            })
            setQuestions(newArray)
        }
        else if (data === "Test not created Bounus Points Awarded") {
            setDirectBonusPoints('1');
        }

    }, [data])



    const handleBeforeUnload = (e) => {
        const confirmationMessage = 'Are you sure you want to leave?';
        e.returnValue = confirmationMessage; // Standard for most browsers
        return confirmationMessage; // For some older browsers
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            // Cleanup by removing the event listener when the component is unmounted
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <div id='hello' ref={mainRef}>
            <RemedialContainer height='calc(100vh - 254px)'>
                <Stack sx={{ gap: '24px' }}>
                    <RemedialHeader heading={'Remedial Test'} variant='page-header' percentage={testResultPageData?.percentage} />
                    <Grid container spacing={3}>
                        <Grid xs={3}>
                            <Stack gap={'24px'}>
                                <TimerCard timeLeft={timer} />
                                <TestProgressCard data={questionsStatus} />
                            </Stack>
                        </Grid>
                        <Grid xs={9}>

                            {data ? <RemedialTestSection data={questions} questionnaireId={data.questionnaire_remedial_path_id} /> :
                                <RemedialCard>
                                    <Stack gap={'16px'}>
                                        <Skeleton variant='rounded' width={'100%'} height={'30px'} sx={{background: theme => theme.palette.grey[200]}} />
                                        <Skeleton variant='rounded' width={'100%'} height={'30px'} sx={{background: theme => theme.palette.grey[200]}} />
                                        <Skeleton variant='rounded' width={'100%'} height={'230px'} sx={{background: theme => theme.palette.grey[200]}} />
                                    </Stack>
                                </RemedialCard>
                            }
                        </Grid>
                    </Grid>
                </Stack>
            </RemedialContainer>
        </div>
    )
}

export default RemedialTestPage