import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';

import { ADDSKILLS, getSkillsListAC, itemUpdateRemoveAddAC } from '../../store/actions/profileActions';

const SaveButton = styled.button`
    border-radius: 32px;
    background:  ${({ active }) => (active ? "linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%)" : "grey")};
    margin: auto;
    text-align: center;
    width: 98px;
    padding: 8px;
    color: white;
    margin-right: 0;
    border: none;
`

class AddSkills extends Component {

    state = {
        openContent : false,
        contentUrl: '',
        contentType: null,
        new_skills: [],
        new_skills_data: [],

    }

    componentDidMount = () => {
        this.props.getSkillsList();
    }

    

    handleChange = (e)=> {
        let skills = this.state.new_skills;
        let new_list = [];
        let deleted_list = [];
        if (Array.isArray(e)) {
            e.map((x)=> {
                if (skills.indexOf(x.value)==-1) {
                    skills.push(x.value);
                    new_list.push(x.value);
                }
            });
            skills = skills.filter((skill)=> {
                let index = e.findIndex((x)=> {
                    return x.value == skill
                })
                if (index == -1) {
                    deleted_list.push(skill);
                    return false
                } else {
                    return true
                }
            })
        }

        let skills_list = this.props.skills_list;
        let skills_data = this.state.new_skills_data;
        
        let new_list_data = skills_list.filter((skill)=> {
            return new_list.includes(skill.id);
        });
        new_list_data.forEach((skill)=> {
            skill['level'] = 'Learning';
        });
        skills_data = skills_data.concat(new_list_data);
        skills_data = skills_data.filter((skill)=> {
            if (!deleted_list.includes(skill.id)) {
                return true
            } else {
                return false
            }
        })
        this.setState({new_skills: skills, new_skills_data: skills_data});

    }

    onLevelChangeHandler = (event, level, id, index) => {
        let skills = [...this.state.new_skills_data];
        skills[index].level = level;
        this.setState({new_skills_data: skills})
    }

    deleteSkillHandler = (event,id)=> {
        let skills = this.state.new_skills;
        let skills_data = this.state.new_skills_data;
        let skills_index = skills.indexOf(id);
        let skills_data_index = skills_data.findIndex((skill)=> {
            return skill.id == id
        });
        skills_data.splice(skills_data_index, 1);
        skills.splice(skills_index, 1);
        this.setState({new_skills: skills, new_skills_data: skills_data});
    }

    closeSkillHandler = () => {
        this.setState({
            new_skills_data: [],
            new_skills: []
        });
        this.props.clearStatus();
        this.props.close();
    }

    addSkillsHandler = () => {
        const formdata = new FormData();
        let skills = [];
        let skills_data = this.state.new_skills_data;
        skills = skills_data.map((skill)=> {
            let obj = {};
            obj[skill.id] = obj.level ? obj.level : 'Learning';
            return obj;
        })
        console.log(skills)
        formdata.append('skills', JSON.stringify(skills));
        formdata.append('custom_skill', JSON.stringify(skills));

        let item = {};
        item.type = ADDSKILLS;
        item.skills = skills_data;
        this.props.addSkills(item, formdata);
    }

    render () {
        console.log(this.state);
        if (this.props.loading_skills) {
            return <div>Loading...</div>
        } else if (!this.props.loading_skills && this.props.error_loading_list_of_skills) {
            return <div>Error loading skills. Please try after some time!!!!</div>;
        }
        let options = this.props.skills_list.map((skill)=> {
            let opt = {};
            opt['value'] = skill.id;
            opt['label'] = skill.name;
            return opt

        })
        options = options.filter((opt)=> {
            let index = this.props.skills.findIndex((skill)=> {
                return skill.master_id == opt.value
            });
            if (index != -1) {
                return false;
            } else {
                return true
            }
        })
        let selected_options = [];
        if (this.state.new_skills && this.state.new_skills.length != 0) {
            selected_options = []
            selected_options = options.filter((opt)=> {
                return this.state.new_skills.includes(opt.value);
            })
        }
        console.log(options);
        console.log(selected_options);
        console.log(this.state);
        return (
            <div style={{}}>                    
                <div  style={{ textAlign: 'left', margin: '20px 0'}}>
                    <div style={{ margin: '0 auto 20px auto', fontWeight: 'bold',textAlign: 'left', fontSize: '20px'}}>Add Skills: </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: '25%', margin: 'auto', marginRight:'10px', textAlign: 'left'}}>
                            Select Skills : 
                        </div>
                        <div style={{margin: 'auto auto', width: '75%'}}>
                            {/* <select data-placeholder="Begin typing a name to filter..." multiple onChange={this.addSkill} style={{width: '70%', height: '35px', border: '1px solid black', borderRadius: '4px'}}>
                                <option selected disabled>Select Language</option>
                                {this.props.skills_list.map((skill, index)=> {
                                    return <option onChange={(event)=>this.addSkill(event)} value={skill.name} id={index} key={index}>{skill.name}</option>
                                })}
                            </select> */}
                            <Select
                                value={selected_options}
                                onChange={this.handleChange}
                                options={options}
                                isMulti
                                isClearable
                            />
                        </div>
                    </div>

                    {this.state.new_skills && this.state.new_skills.length > 0? <div style={{marginTop:'25px'}}>
                        <div style={{display: 'flex'}}>
                            <div style={{width: '30%'}}>Skills</div>
                            <div style={{width: '20%'}}>Learning</div>
                            <div style={{width: '20%'}}>Working</div>
                            <div style={{width: '20%'}}>Advanced</div>
                            <div style={{width: '10%'}}></div>
                        </div>
                        {this.state.new_skills_data.map((skill, index)=> { return <div style={{margin: '15px auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{width: '30%', textAlign: 'left'}}>{skill.name}</div>
                            {skill.level == 'Learning' || skill.level =='Working' || skill.level == 'Advanced' ? <div onClick={(event)=> this.onLevelChangeHandler(event,'Learning', skill.id, index)} style={{width: '20%'}}>
                                <img src='/images/profile/star_one.png' />
                            </div> : <div onClick={(event)=> this.onLevelChangeHandler(event,'Learning', skill.id, index)}  style={{width: '20%'}}>
                                <img src='/images/profile/star_zero.png' />
                            </div> }
                            {skill.level =='Working' || skill.level == 'Advanced' ? <div onClick={(event)=> this.onLevelChangeHandler(event,'Working', skill.id, index)}  style={{width: '20%'}}>
                                <img src='/images/profile/star_one.png' />
                            </div> : <div onClick={(event)=> this.onLevelChangeHandler(event,'Working', skill.id, index)}  style={{width: '20%'}}>
                                <img src='/images/profile/star_zero.png' />
                            </div> }
                            {skill.level == 'Advanced' ? <div onClick={(event)=> this.onLevelChangeHandler(event,'Advanced', skill.id, index)} style={{width: '20%'}}>
                                <img src='/images/profile/star_one.png' />
                            </div> : <div onClick={(event)=> this.onLevelChangeHandler(event,'Advanced', skill.id, index)}  style={{width: '20%'}}>
                                <img src='/images/profile/star_zero.png' />
                            </div> }
                            <div style={{width: '10%'}} onClick={(event)=>this.deleteSkillHandler(event, skill.id)}>
                                <img src='/images/profile/delete_icon.svg'></img>
                            </div>
                        </div>

                        })}
                    </div> : null}
                    {this.props.error_adding_skills? <div style={{color: 'red', textAlign: 'left'}}>Error Adding New Skills. Please try again after some time!!!!</div> : null}
                    {this.props.added_skills_successfully? <div style={{color: 'green', textAlign: 'left'}}>Skills Added Successfully.</div> : null}
                    
                    {this.props.added_skills_successfully?  
                    <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto', marginTop: '25px'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeSkillHandler}>OK</div>
                    </div>
                    :<div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '10px auto'}}>
                        <div style={{color: 'blue', margin: 'auto', marginRight: '20px'}} onClick={this.closeSkillHandler}>Cancel</div>
                        <div>
                            <SaveButton active={this.state.new_skills && this.state.new_skills.length > 0} onClick={()=>this.addSkillsHandler()}>Save</SaveButton>
                        </div>
                    </div>}
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.prof.loading,
        college: state.prof.college,
        college_university_degree_department_id: state.prof.college_university_degree_department_id,
        department_details: state.prof.department_details,

        id: state.prof.id,
        roll_number: state.prof.roll_number,
        semester_id: state.prof.semester_id,
        university_degree_department_id: state.prof.university_degree_department_id,
        
        skills_list: state.prof.skills_list,
        error_loading_list_of_skills: state.prof.error_loading_list_of_skills,
        loading_skills:  state.prof.loading_skills,

        added_skills_successfully: state.prof.added_skills_successfully,
        error_adding_skills: state.prof.error_adding_skills,
        skills: state.prof.skills

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSkillsList:()=> dispatch(getSkillsListAC()),
        addSkills: (item, formdata) => dispatch(itemUpdateRemoveAddAC(item, formdata)),
        clearStatus: () => dispatch({type:'CLEAR_ADD_SKILLS_STATUS'})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddSkills));