import React, {useReducer } from 'react'
import RemedialContext from './RemedialContext'
import remedialReducer, { remedialIntialState } from './RemedialReducer'
import {SET_BANNER_DATA, SET_QUESTIONS, SET_QUESTIONS_STATUS, SET_REVISED_RESULT_PAGE_DATA, SET_SPIN_PRIZE_NUMBER, SET_SPIN_WHEEL_DATA, SET_DIRECT_BONUS_POINTS, SET_STUDY_PAGE_DATA, SET_STUDY_PROGRESS, SET_TEST_RESULT_PAGE_DATA, SET_TIMER, UPDATE_BANNER_INDEX,SET_TEST_DATA } from '../../constant/remedialActionConstant';


const RemedialProvider = ({ children }) => {
    const [state, dispatch] = useReducer(remedialReducer, remedialIntialState);

    const setTimer = (data) => {
        dispatch({
            type: SET_TIMER,
            payload: {
                timer: data
            }
        });
    };

    const updateBannerIndex = (index) => {
        dispatch({
            type: UPDATE_BANNER_INDEX,
            payload: {
                activeBannerIndex: index
            }
        });
    };

    const setBannerData = (data) => {
        dispatch({
            type: SET_BANNER_DATA,
            payload: {
                bannerData: data
            }
        });
    };
    
    const setTestResultPageData = (data) => {
        dispatch({
            type: SET_TEST_RESULT_PAGE_DATA,
            payload: {
                testResultPageData: data
            }
        });
    };

    const setStudyPageData = (data) => {
        dispatch({
            type: SET_STUDY_PAGE_DATA,
            payload: {
                studyPageData: data
            }
        });
    };

    const setStudyProgress = (data) => {
        dispatch({
            type: SET_STUDY_PROGRESS,
            payload: {
                studyProgress: data
            }
        });
    };
    const setTestData = (data) => {
        console.log(data+"in data ===========------------------------------------------=======")
        dispatch({
            type: SET_TEST_DATA,
            payload: {
                fluke_percentage: data
            }
        });
    };

    const setSpinWheelData = (data) => {
        dispatch({
            type: SET_SPIN_WHEEL_DATA,
            payload: {
                spinWheelData: data
            }
        });
    };
    const setSpinPrizeNumber = (number) => {
        dispatch({
            type: SET_SPIN_PRIZE_NUMBER,
            payload: {
                spinPrizeNumber: number
            }
        });
    };

    const setDirectBonusPoints = (number) => {
        dispatch({
            type: SET_DIRECT_BONUS_POINTS,
            payload: {
                directBonusPoints: number
            }
        });
    };

    const setQuestions = (data) => {
        dispatch({
            type: SET_QUESTIONS,
            payload: {
                questions: data
            }
        });
    };

    const setQuestionsStatus = (data) => {
        dispatch({
            type: SET_QUESTIONS_STATUS,
            payload: {
                questionsStatus: data
            }
        });
    };


    const setRevisedResultPageData = (data) => {
        dispatch({
            type: SET_REVISED_RESULT_PAGE_DATA,
            payload: {
                revisedResultPageData: data
            }
        });
    };

    const value = {
        timer: state.timer,
        activeBannerIndex: state.activeBannerIndex,
        bannerData: state.bannerData,
        testResultPageData: state.testResultPageData,
        studyPageData: state.studyPageData,
        spinWheelData: state.spinWheelData,
        spinPrizeNumber: state.spinPrizeNumber,
        directBonusPoints: state.directBonusPoints,
        questions: state.questions,
        questionsStatus: state.questionsStatus,
        revisedResultPageData: state.revisedResultPageData,
        studyProgress:state.studyProgress,
        flukePercentage:state.flukePercentage,
        setTimer,
        updateBannerIndex,
        setBannerData,
        setTestResultPageData,
        setStudyProgress,
        setStudyPageData,
        setSpinWheelData,
        setSpinPrizeNumber,
        setDirectBonusPoints,
        setQuestions,
        setQuestionsStatus,
        setRevisedResultPageData,
        setTestData
    };


    return <RemedialContext.Provider value={value}>{children}</RemedialContext.Provider>

}

export default RemedialProvider