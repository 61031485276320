import { Box, Typography } from '@mui/material'
import React from 'react'
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import useWindowSize from '../../../../hooks/useWindowSize'
import { keyframes } from '@mui/system';

export const treesLayer1 = keyframes`
from {
  transform: scale(1); 

}
to {
    transform: scale(2);
}
`;


const JackpotWrapper = ({numberOfPieces = 200 }) => {
    const { width, height } = useWindowSize()
    return (
        <>
            <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 100, backgroundColor: 'rgba(0 ,0 ,0 , 0.5)' }}></Box>
            <Box sx={{ position: 'fixed', zIndex: 101, top: 0, left: 0, right: 0, bottom: 0 }}>
                <Confetti
                    width={width}
                    height={height}
                    numberOfPieces={numberOfPieces}
                />
            </Box>
            {/* {children} */}
            <Box sx={{ position: 'fixed', zIndex: 101, top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                <Typography component={'p'} sx={{
                    fontFamily: 'Poppins-SemiBold',
                    fontSize: '60px',
                    color: theme => theme.palette.primary.contrastText,
                    animation: `${treesLayer1} linear 2s infinite alternate`
                }}>JACKPOT</Typography>
            </Box>
        </>
    )
}

export default JackpotWrapper