import axios from "../../interceptor";

let http = axios;
const BASE_API_URL = process.env.REACT_APP_API_URL_TAKE_TEST;
// const BASE_API_URL = "https://studentpython.edwisely.com";
// let CORS = 'https://cors-anywhere.herokuapp.com/';

export function setGlobalToken() {
  try {
    let token = localStorage.getItem("token");
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } catch (error) {
    console.log(error);
  }
}

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const JOINED_CHALLENGE = "JOINED_CHALLENGE";
export const GET_TEAMDETAILS = "GET_TEAMDETAILS";
export const UPDATE_TEAMS = "UPDATE_TEAMS";
export const SUBMIT_OPTION = "SUBMIT_OPTION";
export const CHALLENGE_DETAILS = "CHALLENGE_DETAILS";
export const LEADERBOARD_DETAILS = "LEADERBOARD_DETAILS";
export const QUESTION_ORDER_DETAILS = "QUESTION_ORDER_DETAILS";

export const refreshTokenA = (err) => {
  return {
    type: REFRESH_TOKEN,
    error: err,
  };
};

export const refreshTokenAC = (code) => {
  // test_id = 9;
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const refresh_token = localStorage.getItem("refresh_token");
    http.defaults.headers.common["Authorization"] = `Bearer ${refresh_token}`;
    http
      .get(`${BASE_API_URL}/auth/refresh`)
      .then((refres) => {
        localStorage.setItem("token", refres.data.token);
        setGlobalToken();
        dispatch(refreshTokenA(false));
      })
      .catch((err) => {
        dispatch(refreshTokenA(true));
        console.log(err);
      });
  };
};

export const getalldetails = (res, err) => {
  console.log(res);
  return {
    type: CHALLENGE_DETAILS,
    response: res,
    error: err,
  };
};
export const getQuestionSubmissionOrderAC = (res, err) => {
  console.log(res);
  return {
    type: QUESTION_ORDER_DETAILS,
    response: res,
    error: err,
  };
};

export const getchallengedetails = (challengeid) => {
  setGlobalToken();
  return (dispatch, getState) => {
    http
      .get(
        `${BASE_API_URL}/weekly_challenge/getWeeklyChallenge?weekly_challenge_id=${challengeid}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(getalldetails(res.data.data, true));
        } else if (
          res.data.status === 401 &&
          res.data.message === "Signature expired. Please log in again."
        ) {
          dispatch(getalldetails(res.data.data, false));
        }
      })
      .catch((err) => {
        dispatch(getalldetails(null, false));
        console.log(err);
      });
  };
};

export const joinChallenge = (res, err) => {
  // console.log(res);
  return {
    type: JOINED_CHALLENGE,
    response: res,
    error: err,
  };
};
export const joinedchallenge = (challengeid) => {
  console.log(challengeid);
  setGlobalToken();
  return (dispatch, getState) => {
    http
      .get(
        `${BASE_API_URL}/weekly_challenge/getAllEnrolledMembers?weekly_challenge_id=${challengeid}&enrollment_type=0`
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            console.log(dispatch(joinChallenge(res.data, true)));
            dispatch(joinChallenge(res.data, true));
          } else if (
            res.data.status === 401 &&
            res.data.message === "Signature expired. Please log in again."
          ) {
            const refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .get(
                    `${BASE_API_URL}/weekly_challenge/enrollWeeklyChallenge?weekly_challenge_id=${challengeid}`
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data.status === 200) {
                        dispatch(joinChallenge(res.data, true));
                      } else {
                        dispatch(joinChallenge(res.data, false));
                      }
                    } else {
                      dispatch(joinChallenge(null, false));
                    }
                  })
                  .catch((err) => {
                    dispatch(joinChallenge(null, false));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            http
              .get(
                `${BASE_API_URL}/weekly_challenge/getAllEnrolledMembers?weekly_challenge_id=${challengeid}`
              )
              .then((resp) => {
                console.log(resp.data);
                dispatch(joinChallenge(resp.data, true));
              })
              .catch((err) => {
                console.log("error in getting enrolled details");
                console.log(err);
                dispatch(joinChallenge(null, false));
              });
            // dispatch(joinChallenge(res.data, true))
          }
        } else {
          dispatch(joinChallenge(null, false));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(joinChallenge(null, false));
      });
  };
};

export const getTeamDetails = (res, resp, err) => {
  console.log(res);
  return {
    type: GET_TEAMDETAILS,
    response: res.data,
    allteams: resp.data,
    error: err,
  };
};
export const getTeamMembers = (challengeid) => {
  console.log(challengeid);
  setGlobalToken();
  return (dispatch, getState) => {
    http
      .get(
        `${BASE_API_URL}/weekly_challenge/getMyTeam?weekly_challenge_id=${challengeid}`
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            http
              .get(
                `${BASE_API_URL}/weekly_challenge/getAllTeams?weekly_challenge_id=${challengeid}`
              )
              .then((resp) => {
                if (resp.status === 200) {
                  if (resp.data.status === 200) {
                    dispatch(getTeamDetails(res.data, resp.data, true));
                  }
                }
              });
          } else if (
            res.data.status === 401 &&
            res.data.message === "Signature expired. Please log in again."
          ) {
            const refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .get(
                    `${BASE_API_URL}/weekly_challenge/getMyTeam?weekly_challenge_id=${challengeid}`
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data.status === 200) {
                        dispatch(getTeamDetails(res.data, true));
                      } else {
                        dispatch(getTeamDetails(res.data, false));
                      }
                    } else {
                      dispatch(getTeamDetails(null, false));
                    }
                  })
                  .catch((err) => {
                    dispatch(getTeamDetails(null, false));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(getTeamDetails(res.data, true));

            // dispatch(joinChallenge(res.data, true))
          }
        } else {
          dispatch(getTeamDetails(null, false));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(getTeamDetails(null, false));
      });
  };
};

export const UPDATETEAMDETAILS = (res, err) => {
  console.log(res);
  return {
    type: UPDATE_TEAMS,
    response: res,
    error: err,
  };
};
export const UpdateTeamName = (teamdata, teamid, challengeid,vicecaptainid) => {
  console.log("fdgdrg");
  setGlobalToken();
  return (dispatch, getState) => {
    const form = new FormData();
    // form.append("test_id",getState().live.testId);
    form.append("team_id", teamid);
    form.append("team_name", teamdata);
    form.append("is_captain", "1");
    form.append("weekly_challenge_id", challengeid);
    form.append("vice_captain", vicecaptainid);
    http
      .post(`${BASE_API_URL}/weekly_challenge/confirmCaptainStatus`, form)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            dispatch(UPDATETEAMDETAILS(res.data.message, true));
          } else if (res.data.status === 204) {
            dispatch(UPDATETEAMDETAILS(res.data.message, true));
          } else if (
            res.data.status === 401 &&
            res.data.message === "Signature expired. Please log in again."
          ) {
            const refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(
                    `${BASE_API_URL}/weekly_challenge/confirmCaptainStatus`,
                    form
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data.status === 200) {
                        dispatch(UPDATETEAMDETAILS(res.data, true));
                      } else {
                        dispatch(UPDATETEAMDETAILS(res.data, false));
                      }
                    } else {
                      dispatch(UPDATETEAMDETAILS(null, false));
                    }
                  })
                  .catch((err) => {
                    dispatch(UPDATETEAMDETAILS(null, false));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(UPDATETEAMDETAILS(res.data.Message, true));

            // dispatch(joinChallenge(res.data, true))
          }
        } else {
          dispatch(UPDATETEAMDETAILS(null, false));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(UPDATETEAMDETAILS(null, false));
      });
  };
};

// export const ONOPTIONSELECTED = (res, err) => {
//     console.log(res)
//     return {
//         type: SUBMIT_OPTION,
//         response: res.data,
//         error: err
//     }
// }
export const onOptionSelectedSurveyAC = (
  weekly_challenge_id,
  team_id,
  qid,
  option_id,
  answer,
  timetaken,
  field_type
) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const form = new FormData();
    if (answer == null) {
      answer = "";
    }
    if (team_id == undefined || team_id === undefined || team_id == null) {
      team_id = "";
    }
    if (option_id == null) {
      option_id = "";
    }
    form.append("team_id", team_id);
    form.append("weekly_challenge_id", weekly_challenge_id);
    form.append("question_id", qid);
    form.append("option_id", option_id);
    form.append("answer", answer);
    form.append("time_taken", timetaken);
    http
      .post(
        `${BASE_API_URL}/weekly_challenge/submitWeeklyChallengeAnswer`,
        form
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            console.log(res.data);
            // dispatch(onOptionSelectedA(res.data.data, false));
          } else if (
            res.data.status === 401 &&
            res.data.message === "Signature expired. Please log in again."
          ) {
            const refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(
                    `${BASE_API_URL}/weekly_challenge/submitWeeklyChallengeAnswer`,
                    form
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data.status === 200) {
                        // dispatch(onOptionSelectedA(res.data.data, false));
                      } else {
                        // dispatch(onOptionSelectedA(null, true));
                      }
                    } else {
                      //   dispatch(onOptionSelectedA(null, true));
                    }
                  })
                  .catch((err) => {
                    // dispatch(onOptionSelectedA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            // dispatch(onOptionSelectedA(null, false));
          }
        } else {
          //   dispatch(onOptionSelectedA(null, true));
        }
      })
      .catch((err) => {
        // console.log('error in getting practice test')
        console.log(err);
        // dispatch(onOptionSelectedA(null, true));
      });
  };
};

export const submitWeeklyChallengeViewerAnswer = (
  weekly_challenge_id,
  qid,
  option_id,
  answer,
  timetaken,
  field_type
) => {
  console.log("in action creator");
  setGlobalToken();
  return (dispatch, getState) => {
    const form = new FormData();
    if (answer == null) {
      answer = "";
    }
    
    if (option_id == null) {
      option_id = "";
    }
    form.append("weekly_challenge_id", weekly_challenge_id);
    form.append("question_id", qid);
    form.append("option_id", option_id);
    form.append("answer", answer);
    form.append("time_taken", timetaken);
    http
      .post(
        `${BASE_API_URL}/weekly_challenge/submitWeeklyChallengeViewerAnswer`,
        form
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            console.log(res.data);
            // dispatch(onOptionSelectedA(res.data.data, false));
          } else if (
            res.data.status === 401 &&
            res.data.message === "Signature expired. Please log in again."
          ) {
            const refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(
                    `${BASE_API_URL}/weekly_challenge/submitWeeklyChallengeAnswer`,
                    form
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data.status === 200) {
                        // dispatch(onOptionSelectedA(res.data.data, false));
                      } else {
                        // dispatch(onOptionSelectedA(null, true));
                      }
                    } else {
                      //   dispatch(onOptionSelectedA(null, true));
                    }
                  })
                  .catch((err) => {
                    // dispatch(onOptionSelectedA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            // dispatch(onOptionSelectedA(null, false));
          }
        } else {
          //   dispatch(onOptionSelectedA(null, true));
        }
      })
      .catch((err) => {
        // console.log('error in getting practice test')
        console.log(err);
        // dispatch(onOptionSelectedA(null, true));
      });
  };
};

export const LEADERBOARDDATA = (res, err) => {
  // console.log(res);
  return {
    type: LEADERBOARD_DETAILS,
    response: res,
    error: err,
  };
};
export const getLeaderboardDetails = (challengeid, questionid) => {
  setGlobalToken();
  let getquestiondata;
  if (questionid || questionid != undefined) {
    getquestiondata = `&question_id=${questionid}`;
  } else {
    getquestiondata = "";
  }
  return (dispatch, getState) => {
    http
      .get(
        `${BASE_API_URL}/weekly_challenge/getLeaderBoard?weekly_challenge_id=${challengeid}${getquestiondata}`
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            // console.log(res.data.data);
            dispatch(LEADERBOARDDATA(res.data.data, true));
          } else if (
            res.data.status === 401 &&
            res.data.message === "Signature expired. Please log in again."
          ) {
            const refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .get(
                    `${BASE_API_URL}/weekly_challenge/getLeaderBoard?weekly_challenge_id=${challengeid}`
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data.status === 200) {
                        dispatch(LEADERBOARDDATA(res.data.data, true));
                      } else {
                        dispatch(LEADERBOARDDATA(res.data.data, false));
                      }
                    } else {
                      dispatch(LEADERBOARDDATA(null, false));
                    }
                  })
                  .catch((err) => {
                    dispatch(LEADERBOARDDATA(null, false));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(LEADERBOARDDATA(res.data.data, true));

            // dispatch(joinChallenge(res.data, true))
          }
        } else {
          dispatch(LEADERBOARDDATA(null, false));
        }
      })
      .catch((err) => {
        console.log("error in getting practice test");
        console.log(err);
        dispatch(LEADERBOARDDATA(null, false));
      });
  };
};

export const SendChatData = (weekly_challenge_id, messages, team_id) => {
  setGlobalToken();
  return (dispatch, getState) => {
    const form = new FormData();

    form.append("chats", JSON.stringify(messages));
    form.append("weekly_challenge_id", weekly_challenge_id);
    form.append("team_id", team_id);

    http
      .post(`${BASE_API_URL}/weekly_challenge/setTeamChat`, form)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            console.log(res.data);
          } else if (
            res.data.status === 401 &&
            res.data.message === "Signature expired. Please log in again."
          ) {
            const refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .post(`${BASE_API_URL}/weekly_challenge/setTeamChat`, form)
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data.status === 200) {
                        // dispatch(onOptionSelectedA(res.data.data, false));
                      } else {
                        // dispatch(onOptionSelectedA(null, true));
                      }
                    } else {
                      //   dispatch(onOptionSelectedA(null, true));
                    }
                  })
                  .catch((err) => {
                    // dispatch(onOptionSelectedA(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            // dispatch(onOptionSelectedA(null, false));
          }
        } else {
          //   dispatch(onOptionSelectedA(null, true));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};


export const getQuestionSubmissionOrder = (weekly_Challenge_id,question_id) => {
  setGlobalToken();
  return (dispatch, getState) => {
    http
      .get(`${BASE_API_URL}/weekly_challenge/getSubmissionOrder?weekly_challenge_id=${weekly_Challenge_id}&question_id=${question_id}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 200) {
            console.log(res.data);
            dispatch(getQuestionSubmissionOrderAC(res.data, false));
          } else if (
            res.data.status === 401 &&
            res.data.message === "Signature expired. Please log in again."
          ) {
            const refresh_token = localStorage.getItem("refresh_token");
            http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refresh_token}`;
            http
              .get(`${BASE_API_URL}/auth/refresh`)
              .then((refres) => {
                localStorage.setItem("token", refres.data.token);
                setGlobalToken();
                http
                  .get(`${BASE_API_URL}/weekly_challenge/getSubmissionOrder?weekly_challenge_id=${weekly_Challenge_id}&question_id=${question_id}`)
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data.status === 200) {
                        dispatch(getQuestionSubmissionOrderAC(res.data, false));
                      } else {
                        dispatch(getQuestionSubmissionOrderAC(null, true));
                      }
                    } else {
                      dispatch(getQuestionSubmissionOrderAC(null, true));
                    }
                  })
                  .catch((err) => {
                    dispatch(getQuestionSubmissionOrderAC(null, true));
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            dispatch(getQuestionSubmissionOrderAC(null, true));
          }
        } else {
          dispatch(getQuestionSubmissionOrderAC(null, true));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
