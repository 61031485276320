import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LiveInstructions from "../../components/LiveInstructions";
// import AppLayout from '../../components/Layout/Layout';
import Flex from "styled-flex-component";
import Equation from "../../components/Equation";
import * as liveActions from "../../store/actions/liveActions";
import * as Ably from "ably/promises";
import { getLoggedInUser } from "../../helpers/Auth";
import styled, { keyframes, css } from "styled-components";
import moment from "moment";
import ImageModal from "../subjective/ImageModal";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import "./results.css";
import ReactCountdownClock from "react-countdown-clock";
let realtime = null;
let channel = null;
let timeInterval = null;

const OptionFlex = styled.div`
  display:flex;
  margin: 10px;
  padding: 10px;
  margin-left: 0;
  align-items: center;
   width:70%;
  height: auto;
  border-radius: 15px;
  border: 1px solid #b1cee3;
  justify-content:center;
  background-color: white;
    &:hover{
        border: 2px solid #0000ff7d;
    }
`;
const Timerdiv = styled.div`
 ${(props) =>
    props.remainingtime<15 &&
    css`
     animation: shake 0.8s infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    `}
    @media only screen and (max-width: 1025px) {
      left:80% !important;
    }
`;
// const ReactCountdownClock= styled.div`
//   animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
//     transform: translate3d(0, 0, 0);
//     backface-visibility: hidden;
//     perspective: 1000px;
// `;
// const countdowncssrules={
//     animation: "shake 0.82s cubic-bezier(.36, .07, .19, .97) both !important",
//     transform: "translate3d(0, 0, 0) !important",
//     backfaceVisibility: "hidden !important",
//     perspective: "1000px !important"

// };
  

// const Timerdiv = styled.div`
//  ${(props) =>
//     props.duration &&
//     css`
//         background: -webkit-linear-gradient(left, skyBlue 50%, #eee 50%);
//     border-radius: 100%;
//     height: calc(var(--size) * 1px);
//     width: calc(var(--size) * 1px);
//     position: relative;
//     -webkit-animation: time calc(var(${props.duration}) * 1s) steps(1000, start) infinite;
//     -webkit-mask: radial-gradient(transparent 50%, #000 50%);
//     mask: radial-gradient(transparent 50%, #000 50%);

//     @-webkit-keyframes time {
//     100% {
//         -webkit-transform: rotate(360deg);
//     }
// }
//     `}
  
// `;

const Timermask = styled.div`
${(props) =>
    props.size &&
    css`
    border-radius: 100% 0 0 100% / 50% 0 0 50%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
    -webkit-animation: mask calc(var(${props.size}) * 1s) steps(500, start) infinite;
    -webkit-transform-origin: 100% 50%;
    @-webkit-keyframes mask {
    0% {
        background: #eee;
        -webkit-transform: rotate(0deg);
    }

    50% {
        background: #eee;
        -webkit-transform: rotate(-180deg);
    }

    50.01% {
        background: skyBlue;
        -webkit-transform: rotate(0deg);
    }

    100% {
        background: skyBlue;
        -webkit-transform: rotate(-180deg);
    }
}
 `}
`;
   
class LiveSurvey extends Component {
  state = {
    loading_ques: false,
    testCode: "",
    ablyConn: false,
    question: null,
    current_ques_id: null,
    selected_option: null,
    time: 0,
    question_count: 0,
    question_submit: false,
    testComplete: false,
    result: false,
    correct_ans: null,
    submitted: false,
    openImage: false,
    image_url: null,
    answer: "",
    codeError: false,
    question_time: 0,
    timetocompoennt:0,
    showtimealert: false,
    closedonce: false,
  };
  hidetimeralert = () => {
    this.setState({ showtimealert: false, closedonce: true });
  };
  componentDidMount = async () => {
    const user = getLoggedInUser();
    realtime = new Ably.Realtime({
      authUrl: `${process.env.REACT_APP_API_URL_TAKE_TEST}/questionnaire/liveTestAuth?user_id=${user.user_id}&roll_number=${user.roll_number}`,
    });
    realtime.connection.once("connected", () => this.updateAblyConn(false));
  };

  updateAblyConn = (loading) =>
    this.setState({
      ablyConn: !this.state.ablyConn,
      loading_ques: loading,
      testStarted: loading,
    });

  updateQuestion = (data) => {
    if (timeInterval) clearInterval(timeInterval);
    if (data.started) {
      // if (
      //   this.state.question &&
      //   !this.state.selected_option &&
      //   !this.state.question_submit
      // ){
      //   this.onSubmitAnswer();
      // }
      let time = 5;
      console.log("questiontimer"+ time)
      console.log("next question")
      timeInterval=null;
      timeInterval = setInterval(() => {

        console.log(time);
        if (time <= 0) {
          clearInterval(timeInterval);
          let ques_count = this.state.question_count + 1;
          this.setState({
            time: 0,
            testStarted: true,
            loading_ques: false,
            question: data.question,
            current_ques_id: data.curr_ques_id,
            question_count: ques_count,
            result: false,
            correct_ans: null,
            timetaken: moment(),
            selected_option: null,
            submitted: false,
            answer: "",
            question_time: 0,
            timetocompoennt:0,
            showtimealert: false,
            closedonce: false,
             question_submit:false,
          });
          return;
        }
        this.setState({ time: time, loading_ques: true});
        time--;
      }, 1000);
    } else {
      let time = 10;
      timeInterval = setInterval(() => {
        if (time <= 0) {
          clearInterval(timeInterval);
          this.setState({
            testStarted: false,
            testComplete: true,
            loading_ques: false,
            question: null,
            current_ques_id: null,
            question_count: null,
            timetaken: null,
            result: false,
            correct_ans: null,
            selected_option: null,
            question_time: 0,
            timetocompoennt:0,
            showtimealert: false,
            closedonce: false,
            time: 0,
          });
          return;
        }
        this.setState({
          time: time,
          testStarted: false,
          testComplete: true,
          question: null,
        });
        time--;
      }, 1000);
    }
  };
  goToHomePage = () => {
    this.props.history.push("/home");
  };

  startQuestionTimer = (data) => {
    if (this.state.question?.id === data.ques_id) {
      let time = data.time;
      let timetocompoennt = data.time;
       this.setState({ timetocompoennt: timetocompoennt });
      const endTime = moment(data.end_timestamp);
      timeInterval = setInterval(() => {
        if (time <= 0 || moment().isAfter(endTime)) {
          clearInterval(timeInterval);
          this.onSubmitAnswer();
          return;
        }
        if (time <= 10 && time >= 1 && !this.state.closedonce) {
          this.setState({ showtimealert: true });
        }
        this.setState({ question_time: time });
        
        time--;
      }, 1000);
    }
  };

  componentDidUpdate = () => {
    if (
      this.props.channel_id &&
      !this.props.error_loading_live &&
      this.state.ablyConn
    ) {
      // console.log("presence given");
      channel = realtime.channels.get(String(this.props.channel_id));
      channel.presence.enter();
      channel.subscribe((msg) => {
        console.log(msg);
        if (msg.name === "content") this.updateQuestion({ ...msg.data });
        else if (msg.name === "content_result")
          this.showResult({ ...msg.data });
        else if (msg.name === "ques_timer")
          this.startQuestionTimer({ ...msg.data });
      });
      this.updateAblyConn(true);
    }
    if (
      channel &&
      !this.props.error_submitting_answer &&
      this.state.question_submit
    ) {
      const user = getLoggedInUser();
      if (this.state.selected_option)
        channel.publish("student", {
          student_id: user.user_id,
          question_id: this.state.current_ques_id,
          answer: this.state.selected_option,
        });
      this.setState({ question_submit: false, submitted: true });
    }
    if (this.state.testComplete && this.state.time === 0) {
      this.props.history.push({
        pathname: "/liveAssessmentResults",
        state: { testId: this.props.testId },
      });
    }
  };
  // componentWillUnmount = () => {
  //  channel.
  // };
  showResult = (msg) => {
    if (this.state.question && this.state.question.id === msg.ques_id)
      this.setState({ result: true, correct_ans: msg.correct_ans_id });
  };

  onCodeChange = (event) =>
    this.setState({ testCode: event.target.value.trim() });

  startTest = async () => {
    if (this.state.testCode) {
      await this.props.checkCode(this.state.testCode);
    } else this.setState({ codeError: true });
  };

  onOptionSelected = (option_id) => {
    if (!this.state.result) this.setState({ selected_option: option_id });
  };

  onAnswerChange = (event) => {
    let answer = "";
    if (this.state.question.field_type === 3) answer = event;
    else if (this.state.question.field_type === 2)
      answer = event.target.value.replace(/ /g, "").substring(0, 15);
    this.setState({ answer: answer });
  };

  onSubmitAnswer = async () => {
    if (channel) {
      const answer =
        this.state.question.field_type === 3
          ? this.state.answer.toString("html")
          : this.state.answer;
      await this.props.onOptionSelected(
        this.state.current_ques_id,
        this.state.selected_option ? this.state.selected_option : "",
        this.state.question.question_type,
        moment().diff(this.state.timetaken, "seconds")
      );
      clearInterval(timeInterval);
      this.setState({
        question_submit: true,
        loading_ques: true,
        time: 0,
        answer: answer,
        question_time: 0,
        timetocompoennt:0,
        showtimealert: false,
      });
    }
  };

  closeTestAfterSubmission = () =>
    this.props.history.push({ pathname: "/home" });

  onImageClick = (url) => this.setState({ openImage: true, image_url: url });

  closeImage = () => this.setState({ openImage: false, image_url: null });

  render() {
    console.log(this.props);
    console.log(this.state);
    const option_numbers = {
      0: "A",
      1: "B",
      2: "C",
      3: "D",
      4: "E",
      5: "F",
      6: "G",
      7: "H",
      8: "I",
      9: "J",
    };

    if (
      this.state.loading_ques &&
      this.state.time === 0 &&
      !this.state.testComplete &&
      !this.state.result &&
      (!this.state.question || this.state.submitted)
    ) {
      return (
        <div
          style={{
            height: "inherit",
            background: "white",
            overflow: "hidden",
            padding: "70px",
          }}
         >
          <div className="loader"></div>
          <div className="zoom-in-out-box">
            Please wait until question is loaded
          </div>
        </div>
      );
    }

    if (!this.state.testStarted && !this.state.testComplete) {
      return (
        <LiveInstructions
          showStart
          startTest={this.startTest}
          code={this.state.testCode}
          error={this.props.error_msg}
          onCodeChange={this.onCodeChange}
          buttonName="JOIN LIVE ASSESSMENT"
        ></LiveInstructions>
      );
    }

    if (this.state.time && this.state.testStarted) {
      return (
        <div
          style={{
            height: "inherit",
            background: "white",
            overflow: "hidden",
            padding: "70px",
          }}
        >
          <div className="loader"></div>
          <div style={{ padding: "20px" }}>
            Next question loading in - {this.state.time} seconds
          </div>
        </div>
      );
    }

    if (this.state.time && !this.state.testStarted && this.state.testComplete) {
      return (
        <div style={{ marginLeft: "8px", marginTop: "25px", width: "100%" }}>
          Survey results loading in - {this.state.time} seconds
        </div>
      );
    }

    if (
      this.state.question &&
      this.state.current_ques_id === this.state.question.id &&
      this.state.testStarted
    )
      return (
        <div
          className="liveassesment_maindiv"
          style={{
            overflow: "auto",
            height: "inherit",
            background: "white",
            padding: "15px",
          }}
        >
          <Flex
            column
            style={{
              // marginTop: "96px",
              // marginLeft: "30px",
              // width: "98%",
              overflowY: "auto",
              overflowX: "hidden",
              // paddingBottom: "50px",
            }}
          >
            {this.state.question_time ? (
              <>
              <Timerdiv
                style={{
                  // marginRight: "25px",
                  // marginTop: "20px",
                  // width: "100%",
                  // textAlign: "right",
                  position:"relative",
                  marginTop:"28px",
                  left:"90%"
                }}
                remainingtime={this.state.question_time}
              >
                <div className="clockindication1"></div>
                <div className="half-circle"></div>
                <div className="clockindication2"></div>
                {/* <h4 style={{ marginRight: "25px" }}> */}
                {/* <Timerdiv className="timer" duration={10}>
                   <Timermask className="mask" size={100}></Timermask>
                    </Timerdiv> */}
                 <ReactCountdownClock
                className="customclock"   
              seconds={this.state.timetocompoennt}
              alpha={0.9}
              color="red"
              size={70}
              weight={5}
              showMilliseconds={false}
            />
                  {/* Auto submitting in - {this.state.question_time}s
                </h4> */}
                
              </Timerdiv>
              <ToastContainer className="p-3" position="top-center">
                  <Toast
                    animation={true}
                    show={this.state.showtimealert}
                    onClose={this.hidetimeralert}
                  >
                    <Toast.Header
                      style={{ paddingLeft: "23px", background: "#f43e3e" }}
                    >
                      <img
                        src="/gifs/time_alert.gif"
                        style={{ height: "70px" }}
                      />
                      <strong className="me-auto" style={{ color: "white" }}>
                        Alert! Alert! <br /> Running out of time!
                      </strong>
                    </Toast.Header>
                  </Toast>
                </ToastContainer> </>
            ) : null}
            <div
              style={
                {
                  //  marginLeft: "8px",
                  //  marginTop: "20px"
                }
              }
            >
              <Flex row style={{ width: "100%",justifyContent:"space-evenly"}}>
                <Flex column style={{ marginLeft: "10px", width: "98%",alignItems:"center"}}>
                  <Flex
                    column
                    style={{
                      marginBottom: "20px",
                      textAlign: "left",
                    //   border: "1px solid grey",
                      borderRadius: "7px",
                      padding: "20px",
                    }}
                  >
                    <Equation
                      math_type={this.state.question.math_type}
                      bold
                      equation={this.state.question.name}
                    ></Equation>
                    {this.state.question.question_image &&
                    this.state.question.question_image !== " " &&
                    ["mp3", "wav", "aac"].includes(
                      this.state.question.question_image
                        .split(/[#?]/)[0]
                        .split(".")
                        .pop()
                        .trim()
                    ) ? (
                      <div
                        style={{
                          width: "324px",
                          height: "216px",
                          margin: "10px",
                        }}
                      >
                        <audio
                          controls
                          style={{ width: "324px" }}
                          src={encodeURI(this.state.question.question_image)}
                        ></audio>
                      </div>
                    ) : this.state.question.question_image &&
                      this.state.question.question_image !== " " ? (
                      <div
                        style={{
                          width: "324px",
                          height: "216px",
                          margin: "10px",
                        }}
                      >
                        <img
                          width="324px"
                          height="216px"
                          src={this.state.question.question_image}
                          alt="question"
                          onClick={() =>
                            this.onImageClick(
                              this.state.question.question_image
                            )
                          }
                        ></img>
                      </div>
                    ) : null}
                  </Flex>

                  <div className="row" style={{justifyContent:"center"}}>
                    {this.state.question.options.map(
                      ({ id, name, media, option_image }, i) => {
                        const is_selected = this.state.selected_option === id;
                        const is_selected_border = this.state.selected_option === id ? "2px solid #0000ff7d" : "";
                        const color =
                          this.state.result && this.state.correct_ans === id
                            ? "green"
                            : is_selected &&
                              this.state.result &&
                              this.state.correct_ans !== id
                            ? "red"
                            : is_selected && !this.state.result
                            ? "#0000ff7d"
                            : null;
                        return (
                          <OptionFlex
                            onClick={() => this.onOptionSelected(id)}
                            style={{
                              color: this.state.selected_option
                                ? "#4E54C8"
                                : null,
                              fontWeight: is_selected ? "600" : "400",
                              alignItems: "center",
                              backgroundColor: "White",
                              cursor: "pointer",
                              border:is_selected_border,
                              // width:this.state.question.question_image ? "calc(50% - 3rem)":"",
                            }}
                            key={id}
                            hasimage={this.state.question.question_image? true:false}
                          >
                            <Flex row key={i}>
                              <div
                                onClick={() => this.onOptionSelected(id)}
                                style={{
                                  margin: "auto",
                                  marginRight: "8px",
                                  marginLeft: "10px",
                                  background:color,
                                  
                                }}
                                className="optioncircle"
                              >
                                {option_numbers[i]}
                              </div>
                              <div>
                                {media === 1 &&
                                ["mp3", "wav", "aac"].includes(
                                  option_image
                                    .split(/[#?]/)[0]
                                    .split(".")
                                    .pop()
                                    .trim()
                                ) ? (
                                  <div
                                    style={{
                                      margin: "10px",
                                      marginTop: "0px",
                                      width: "150px",
                                      height: "100px",
                                    }}
                                  >
                                    <audio
                                      controls
                                      style={{ width: "150px" }}
                                      src={encodeURI(option_image)}
                                    ></audio>
                                  </div>
                                ) : media === 1 ? (
                                  <div
                                    style={{
                                      margin: "10px",
                                      marginTop: "0px",
                                      width: "150px",
                                      height: "100px",
                                    }}
                                  >
                                    <img
                                      width="150px"
                                      height="100px"
                                      src={encodeURI(option_image)}
                                      alt="option"
                                      onClick={() =>
                                        this.onImageClick(option_image)
                                      }
                                    />
                                  </div>
                                ) : null}
                              </div>
                              <div
                                style={{ margin: "auto", marginLeft: "10px" }}
                                onClick={() => this.onOptionSelected(id)}
                              >
                                <Equation
                                  bold={is_selected}
                                  answered={is_selected}
                                  math_type={this.state.question.math_type}
                                  equation={name}
                                ></Equation>
                              </div>
                            </Flex>
                          </OptionFlex>
                        );
                      }
                    )}
                  </div>
                </Flex>
              </Flex>
              {!this.state.question_submit && !this.state.result ? (
                <div style={{ display: "flex", justifyContent: "center",marginTop: "30px" }}>
                  <button
                    onClick={this.onSubmitAnswer}
                    className="btn btn-danger"
                    style={{  }}
                  >
                    Submit Answer
                  </button>
                </div>
              ) : // <div
              //   onClick={this.onSubmitAnswer}
              //   style={{
              //     width: "100%",
              //     paddingRight: "25px",
              //     textAlign: "right",
              //     color: "#6568d9",
              //     cursor: "pointer",
              //   }}
              // >
              //   <div style={{ fontWeight: "bold", fontSize: "20px" }}>
              //     Submit Answer
              //   </div>
              //   <div style={{ margin: "auto", marginLeft: "10px" }}>
              //     <img
              //       width="18px"
              //       height="18px"
              //       src="/images/Right_arrow_blue.svg"
              //       alt="submit"
              //     ></img>
              //   </div>
              // </div>
              null}
            </div>
          </Flex>
          {this.state.openImage && (
            <ImageModal
              width="500px"
              height="70%"
              padding="none"
              style={{ zIndex: 12 }}
              closeDialog={this.closeImage}
              open={this.state.openImage}
              url={this.state.image_url}
            ></ImageModal>
          )}
        </div>
      );
    if (this.state.testComplete)
      return (
        <div style={{ marginTop: "25px" }}>
          {/* <Modal open={this.props.testSubmitted}> */}
          <div style={{ marginTop: "20px" }}>Live Assesment is Submitted</div>
          <div
            onClick={this.closeTestAfterSubmission}
            style={{ margin: "20px auto", color: "#6568d9", cursor: "pointer" }}
          >
            OK
          </div>
          {/* </Modal> */}
        </div>
      );
  }
}
const mapStateToProps = (state) => {
  return {
    error_loading_live: state.live.error_loading_live,
    error_submitting_answer: state.live.error_submitting_answer,
    testId: state.live.testId,
    channel_id: state.live.channel_id,
    questions: state.live.questions,
    title: state.live.title,
    error_msg: state.live.error_msg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    refreshToken: () => dispatch(liveActions.refreshTokenAC()),
    checkCode: (code) => dispatch(liveActions.checkCodeAC(code)),
    onOptionSelected: (qid, option_id, type, timetaken) =>
      dispatch(liveActions.onOptionSelectedAC(qid, option_id, type, timetaken)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LiveSurvey)
);
