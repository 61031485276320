import React, { useState,useRef,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SearchIcon from '../../../assets/common/SearchIcon';
import SearchResultList from './SearchResultList'; 
import { Box,IconButton } from '@mui/material';
import Loading from './Loading';
import CircularProgress from '@mui/material/CircularProgress';
import {InputAdornment} from '@mui/material';
import { CloseX } from '../../../assets/common/CloseX';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
const dummyData = [
  {
      careerId: 23,
      careerName: "Python Developer",
      type: 'careerpath',
      skills: [
          {
              skillId: 84,
              skillName: "Python Programming"
          }
      ]
  },
  {
      careerId: 45,
      careerName: "Machine Python",
      type: 'careerpath',
      skills: []
  },
  {
      careerId: 44,
      careerName: "TIBCO Developer",
      type: 'careerpath',
      skills: [
          {
              skillId: 128,
              skillName: "Python"
          }
      ]
  },

];

const SearchField1 = ({ placeholderValue = "Careers, keywords, skills...", onSearch, onRecordClick,searchedData,loading }) => {
 console.log(loading)
 const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
//   const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchResultListOpen, setIsSearchResultListOpen] = useState(false);


//   useEffect(() => {
//     const handleClickOutside = (event) => {
//         if (textFieldRef.current && !textFieldRef.current.contains(event.target)) {
//             setIsSearchResultListOpen(false);
//         }
//     };

//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//         document.removeEventListener('mousedown', handleClickOutside);
//     };
// }, []);

  useEffect(() => {
    if(searchedData?.length>0){
        setIsSearchResultListOpen(true)
    }
    if(searchQuery?.length==0){
        setIsSearchResultListOpen(false)
    }
    }, [searchedData,searchQuery]);
  const textFieldRef = useRef(null);
  const handleSearch = async () => {
    //   setLoading(true);
      setIsSearchResultListOpen(true)
      onSearch(searchQuery)   
  
      //------Testing purpose---------------
    //   await new Promise(resolve => setTimeout(resolve, 2000));
    //   setSearchResults(dummyData)
    //   setLoading(false);
  };

  const handleRecordClick = (id, value) => {
    setIsSearchResultListOpen(false); 
      onRecordClick(id, value);
      console.log(id);
  };


  const handleInputChange = (value) => {
    setIsSearchResultListOpen(true)
      onSearch(value)   
      setIsSearchResultListOpen(false); 
      setSearchQuery(value);
  };

  const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
          handleSearch();
      }
  };
//   console.log(textFieldRef)
  return (
      <Box sx={{position:'relative'}}>
    <TextField
    fullWidth
    variant="outlined"
    inputRef={textFieldRef}
    placeholder={placeholderValue}
    value={searchQuery}
    style={{ height: '45px', width: '496px', boxShadow: '4px 4px 15px 0px rgba(0, 0, 0, 0.05)', borderRadius: '30px'}}
    onChange={(e) => handleInputChange(e.target.value)}
    onKeyPress={handleKeyPress}
    InputProps={{
        startAdornment: (
            <InputAdornment position='start'>
            <SearchIcon />
            </InputAdornment>
        ),
        endAdornment: loading ? (
            <InputAdornment position='end'>
                <CircularProgress
                    size={20}
                    sx={{ color: (theme) => theme.palette.grey[600],mr:'10px' }}
                />
            </InputAdornment>
        ) :!loading && searchQuery?.length>0 ? (
            <InputAdornment position='end'>
          <IconButton disableRipple={true} onClick={()=>setSearchQuery('')} sx={{p:'2px !important',ml:'0 !important'}}>
              <CloseOutlinedIcon
                    sx={{ color: (theme) => theme.palette.grey[600],pr:'10px',width:'34px',height:'34px' }}
                />
        </IconButton>
            </InputAdornment>
        ) :null,
        style: { borderRadius: '30px', height: '100%', paddingRight: '5px' }
    }}
/>



          <Box>
          {isSearchResultListOpen  &&
              (<Box sx={{position:'absolute', top: textFieldRef.current?.offsetHeight ?? 45, 
              left: '0px',zIndex:9999,maxHeight:'200px',overflowY
              :'auto',overflowX:'clip',borderRadius: '10px',
              boxShadow: '4px 4px 15px 0px rgba(0, 0, 0, 0.05)',
              backgroundColor:'#ffff'}}>
                
                {!loading && searchedData?.length>0 ?
                  <SearchResultList 
                  loading={loading}
                  searchResults={searchedData} 
                  searchQuery={searchQuery}
                  handleRecordClick={handleRecordClick}
                  handleInputChange={handleInputChange}/>
                   :null} 
              </Box>)
          }   
          </Box>
      </Box>
  );
};

export default SearchField1;