import { styled } from '@mui/material';

export const PathCircleWrapper = styled('div')(({ theme }) => ({
    width: '122px',
    height: '100%',
    position: 'absolute',
    top: '-40px',
    right: '-61px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5,
    transition: 'all 1s linear',
    transformOrigin: 'center'
}));

export const PathOuterCircle = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    minWidth: '122px',
    minHeight: '122px',
    maxWidth: '122px',
    maxHeight: '122px',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5,
    transition: 'all 1s linear',
    transformOrigin: 'center'
}));
export const PathInnerCircle = styled('div')(({ theme }) => ({
    minWidth: '108px',
    minHeight: '108px',
    maxWidth: '108px',
    maxHeight: '108px',
    background: theme.palette.grey[200],
    // background: theme.palette.success.gradient1,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 6,
    overflow: 'hidden',
    transition: 'all 1s linear',
    transformOrigin: 'center'
}));
