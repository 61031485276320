import { fetchDataFromApi } from "../../../../helpers/Api";
import { updateBannerApi } from "../../../../helpers/updateBanner";
import { useRemedialContext } from "../../hooks/useRemedial";

const {setBannerData } = useRemedialContext
// needed -> activeIndex ,testId, bannerData.stages[1].id
const updateRemedialBanner = async ({ index, testId, stageId }) => {
    const res = await updateBannerApi(testId, stageId);
    const { status } = await res.data
    if (status === 200) {
        const { data } = await fetchDataFromApi(`/questionnaire/v2/getRemedialPathStages?test_id=${testId}`);

        setBannerData(data)
    }
}


export default updateRemedialBanner