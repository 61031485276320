import React, { useState, useEffect } from 'react';
import Editor from "@monaco-editor/react";
import {styled} from '@mui/material/styles';
import {CiLight} from "react-icons/ci";
import {BsMoon} from "react-icons/bs";
import { Box, MenuItem, Grid, FormControl, Select, Button, Typography, Tooltip } from '@mui/material';
import colors2 from '../../pages/codingscreen/colors2';
import { darkModeColors } from '../../pages/codingscreen/colors2';
import { HintBulb } from '../../assets/codingModule/HintBulb';
import { keyframes } from '@emotion/core';
import { RightArrow } from '../../assets/codingModule/RightArrow';

const moveSideways = keyframes`
  0% {
    transform: translateX(0); // Start position
  }
  50% {
    transform: translateX(8px); // Middle position (move to the right)
  }
  100% {
    transform: translateX(0); // End position (back to start)
  }
`;

const CodingEditor = (props) => {
    const [language, setLanguage] = useState('c');
    const [compilerId, setCompilerId] = useState(props.languages[0].language_id);
    const [code, setCode] = useState('');
    const [subHistory, setSubHistory] = useState(null);
    const [paletteColors, setPaletteColors] = useState(colors2);
    // const [codeInLanguages, setCodeInLanguages] = useState(null);

    useEffect(() => {
        const handleKeyDown = (e) => {
          // Prevent Ctrl+C (or Cmd+C on Mac) for copying
          if ((e.ctrlKey || e.metaKey) && (e.key === 'c' || e.key === 'v')) {
            e.preventDefault();
          }
        };
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        props.handleCompilerChange(compilerId);
    }, []);

    useEffect(() => {
        setCompilerId(props.languages[0].language_id);
        props.handleCompilerChange(props.languages[0].language_id);
    }, [props.languages]);

    useEffect(() => {
        if (props.isDarkMode) {
            setPaletteColors(darkModeColors);
        }
        else {
            setPaletteColors(colors2);
        }
    }, [props.isDarkMode]);

    useEffect(() => {
        if (props.submissionHistory) {
            const sub = props.submissionHistory.slice().reverse();
            setSubHistory(sub);
            const language_latest_submission = sub.find(submission => submission.compiler_id === compilerId);
            if (language_latest_submission && Object.keys(language_latest_submission).length > 0) {
                setCode(language_latest_submission?.source);
            }
            else {
                const language = props.languages.find(language => language.language_id === compilerId);
                if (language.name !== 'C++14 [GCC]' && language.name !== 'Python 3.x') {
                    setLanguage(language.name.toLowerCase());
                }
                else {
                    if (language.name === 'C++14 [GCC]') {
                        setLanguage('cpp');
                    }
                    else {
                        setLanguage('python');
                    }
                }

                if (language.boiler_plate === "None" || language.boiler_plate === "") {
                    if (language.name === 'Python 3.x') {
                        setCode('#Start typing your code here');
                    }
                    else {
                        setCode('//Start typing your code here');
                    }
                }
                else {
                    setCode(language.boiler_plate);
                }
            }
        }
    }, [props.submissionHistory]);

    useEffect(() => {
        if (props.isRunCode) {
            // console.log("In use effect of coding editor which is invoked w.r.t isRunCode");
            if (!props.isCodeSwitchingSub) {
                // console.log(code);
                props.handleCodeChange(code);
            }
        }
    }, [props.isRunCode]);
    
    const handleLanguageChange = (event) => {
        const language = props.languages.find(language => language.language_id === event.target.value);
        if (language.name !== 'C++14 [GCC]' && language.name !== 'Python 3.x') {
            setLanguage(language.name.toLowerCase());
        }
        else {
            if (language.name === 'C++14 [GCC]') {
                setLanguage('cpp');
            }
            else {
                setLanguage('python');
            }
        }
        if (code !== '' && code !== '//Start typing your code here' && code !== '#Start typing your code here') {
            props.handleAlternateSubmissions('language_switch', code, compilerId);
        }
        setCompilerId(event.target.value);
        props.handleCompilerChange(event.target.value);
        let language_latest_submission = {};
        if (subHistory && subHistory.length > 0) {
            language_latest_submission = subHistory.find((submission => submission.compiler_id === language.language_id))
        }
        if (language_latest_submission?.source) {
            setCode(language_latest_submission?.source);
        }
        else {
            if (language.boiler_plate === "None" || language.boiler_plate === "") {
                if (language.name === 'Python 3.x') {
                    setCode('#Start typing your code here');
                }
                else {
                    setCode('//Start typing your code here');
                }
            }
            else {
                setCode(language.boiler_plate);
            }
        }
    }

    const updateCode = (event) => {
        setCode(event);
        // props.handleCodeChange(event);
    }

    const handleResetCode = () => {
        setCode('');
    }

    // const handleEditorValidation = (markers) => {
    //     markers.forEach((marker) => console.log('onValidate:', marker.message));
    // }

    return (
        <>
            <div id="editor-container" style={{height: 'calc(100vh - 65px)', width: '100%', background: !props.isDarkMode ? paletteColors.common.white : paletteColors.backgroundPrimary}}>
            <Grid container direction='column' sx={{p:0, paddingTop: '20px'}}>
                <Grid item>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                            <Box width="120px">
                                <FormControl fullWidth sx={{ '& .MuiOutlinedInput-notchedOutline': { border: 'none', } }}>
                                    <Select
                                        labelId="demo-select-small2-label"
                                        id="demo-select-small2"
                                        value={compilerId}
                                        // inputProps={{ 'aria-label': 'Without label', style: { border: 'none' } }}
                                        onChange={handleLanguageChange}
                                        sx={{
                                            height: '30px', 
                                            fontSize: '12px', 
                                            fontWeight: '500', 
                                            marginLeft: '10px', 
                                            background: !props.isDarkMode ? paletteColors.secondary : paletteColors.grey1, 
                                            color: !props.isDarkMode ? paletteColors.primary : paletteColors.textPrimary,
                                        }}
                                        inputProps={{
                                            'aria-label': 'Without label', style: { border: 'none' },
                                            MenuProps: {
                                                MenuListProps: {
                                                    sx: {
                                                        backgroundColor: !props.isDarkMode ? paletteColors.backgroundPrimary : paletteColors.grey1,
                                                        color: paletteColors.textPrimary,
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        {props?.languages.map((language) => {
                                            return (
                                                <MenuItem key={language.language_id} value={language.language_id}>{language.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item >
                            <Grid container justifyContent='space-evenly' alignItems='center' wrap='nowrap'>
                                {props.showHint ?
                                    <Grid item sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '15px'}}>
                                            <Typography 
                                                variant='body1' 
                                                color={paletteColors.textPrimary}
                                                // sx={{
                                                //     animation: `${moveSideways} 1s ease-in-out infinite`, // Apply the animation
                                                // }}
                                            >
                                                Hint <RightArrow backgroundColor={paletteColors.textPrimary} />
                                            </Typography>
                                            <Tooltip 
                                                title={props.hint !== null ? props.hint : ''} 
                                                placement='bottom-end' 
                                                arrow 
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            bgcolor: !props.isDarkMode ? paletteColors.backgroundSecondary : paletteColors.grey1, 
                                                            color: paletteColors.hintBulb,
                                                        }
                                                    },
                                                    arrow: {
                                                        color: paletteColors.hintBulb
                                                    }
                                                }}
                                            >
                                                <Button
                                                    variant='filled' 
                                                    size='small' 
                                                    sx={{background: !props.isDarkMode ? paletteColors.grey[100] : paletteColors.grey1 , padding: '5px', '&:hover': {background: !props.isDarkMode ? paletteColors.secondary : paletteColors.grey1}}}
                                                >
                                                    <HintBulb backgroundColor={paletteColors.hintBulb} />
                                                </Button>
                                            </Tooltip>
                                    </Grid>
                                : null}
                                <Grid item sx={{marginLeft: '10px'}}>
                                    <Button 
                                        variant='filled' 
                                        size='small' 
                                        sx={{background: !props.isDarkMode ? (theme) => paletteColors.grey[100] : paletteColors.grey1 , padding: '5px 15px', '&:hover': {background: !props.isDarkMode ? paletteColors.secondary : paletteColors.grey1}}} 
                                        onClick={handleResetCode}
                                    >
                                        <Typography variant={!props.isDarkMode ? "caption2" : "caption1"} color={paletteColors.textPrimary} sx={{display: 'flex', justifyContent: "center", flexWrap: 'nowrap'}}>
                                            <Box component="img" src={!props.isDarkMode ? `/images/reset_code.svg`: `/images/reset_code_white.svg`}/>&nbsp;Reset code
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item sx={{marginLeft: '10px', marginRight: '10px'}}>
                                    {/* <SecondaryButton onClick={props.handleDarkMode}>
                                        <CiLight sx={{height: '15px', width: '25px'}}/>&nbsp;Dark
                                    </SecondaryButton> */}
                                    <Button 
                                        variant='filled' 
                                        size='small' 
                                        sx={{background: !props.isDarkMode ? paletteColors.secondary : paletteColors.grey1, padding: '5px 15px', '&:hover': {background: !props.isDarkMode ? paletteColors.secondary : paletteColors.grey1}}} 
                                        onClick={props.handleDarkMode}
                                        >
                                        <Typography variant={!props.isDarkMode ? "caption2" : "caption1"} color={!props.isDarkMode ? paletteColors.primary : paletteColors.textPrimary}>{props.isDarkMode ? <CiLight sx={{height: '25px', width: '25px'}}/> : <BsMoon sx={{height: '25px', width: '25px'}}/>}&nbsp;{props.isDarkMode ? 'Light' : 'Dark'}</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{marginTop: '2rem', width:"100%"}}>
                <Editor
                    height="80vh"
                    // width="100%"
                    language={language}
                    defaultValue={`//Start typing your code here...`}
                    suggestOnTriggerCharacters={true}
                    value={code}
                    onChange={updateCode}
                    theme={props.isDarkMode ? "vs-dark":"va--light"} 
                    options={{ contextmenu: false }}
                    // onValidate={handleEditorValidation}
                />
                </Grid>
            </Grid>
            </div>
        </>
    )
}

export default CodingEditor;