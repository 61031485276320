import { useEffect,useState,useMemo} from "react";
import ReactFlow, { Controls, Background,useNodesState,useEdgesState, ReactFlowProvider,useReactFlow} from "reactflow";
import "reactflow/dist/style.css";
import CareerDrawer from "./components/CareerDrawer";
import { Slide } from "@mui/material";
import useForceLayout from "./hooks/useForceLayout";
import { useDispatch,useSelector } from "react-redux";
import { setNewNodeEmpty } from "../../store/reducers/careerPathReducer";
import {Box} from "@mui/material";
import MuiCustomLinearProgress from "../../components/common/MuiCustomLinearProgress";

function LayoutFlow({ strength = -1300, distance = 900,apiData }) {
 const dispatch=useDispatch()
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [allNodes, setAllNodes] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentCareer, setCurrentCareer] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [addNewNode, setAddNewNode] = useState(false);
  let gatherEdges=[]
  useForceLayout({ strength, distance});
  
  const {addedNode} = useSelector((state) => state.careerpath);
//  console.log(addedNode)
 useEffect(() => {
  if(addedNode){
    setAddNewNode(true)
  const index = allNodes?.length

  const newNode={
    "id": `${index+1}`,
    data: {   label: (
      <div>
          {addedNode.name}
          <Box sx={{ml:'auto',mr:'auto',mt:1.2}}>
          <MuiCustomLinearProgress value={addedNode?.fit} tooltip={false} width={37} sx={{m:'auto'}} />
          </Box>
        
      </div>
  ), },
    "position": {
        "x": (index % 5 === 0) ? 100 : ((index % 5) * 200) + 100,
        "y": Math.floor(index / 5) * 100 + ((index % 5 === 0 || index % 5 === 4) ? 280 : ((index % 5 === 1 || index % 5 === 3) ? 310 : 330)),
    },
    "className": "career-box",
    "careerId": addedNode.id
}
console.log(newNode)
const isIdExists = allNodes.some(node => node.id === newNode.id);
if (!isIdExists) {
  console.log('inside if')
  setAllNodes(prevNodes => [...prevNodes, newNode]);
  setNodes(prevNodes => [...prevNodes, newNode])
  const edge = {
    id: `1-${index+1}`,
    source: "1",
    target: `${index+1}`,
    animated: true,
  };
  setEdges(prevEdges => [...prevEdges, edge]);
  
} else {
  console.log(`Object with ID ${newNode.id} already exists.`);
}
dispatch(setNewNodeEmpty())
    
}
}, [addedNode]);
  useEffect(() => {
    const nodes1 = [
      {
        id: "1",
        data: { label: "You" },
        position: { x: 590, y: 60 },
        // type: "custombox",
        className:'career-box-you',
      },
      ...apiData.map((career, index) => ({
        id: (index + 2).toString(),
        data: {   label: (
          <div>
              {career.name}
              <Box sx={{ml:'auto',mr:'auto',mt:1.2}}>
              <MuiCustomLinearProgress value={career?.final_score} tooltip={false} width={37} sx={{m:'auto'}} />
              </Box>
            
          </div>
      ), },
        position: {
          x: (index % 5 === 0) ? 100 : ((index % 5) * 200) + 100,
          y: Math.floor(index / 5) * 100 + ((index % 5 === 0 || index % 5 === 4) ? 280 : ((index % 5 === 1 || index % 5 === 3) ? 310 : 330)),
        },
        className:'career-box',
        careerId: career.id
      }))
    ];
    // console.log(nodes1)
    setCurrentIndex(1)
    setAllNodes(nodes1)
    setNodes([nodes1[0]])
   
  }, [apiData]);
  
  useEffect(() => {
    if(allNodes?.length>0 && edges?.length!=allNodes?.length-1 && !addNewNode){
     
      setTimeout(() => {
  //  console.log('in edges set')
    const edge = {
      id: `1-${allNodes[currentIndex].id}`,
      source: "1",
      key:`1-${allNodes[currentIndex].id}`,
      target: allNodes[currentIndex].id.toString(),
      animated: true,
    };
    // console.log(edges)
    console.log(gatherEdges)
    const targetExists = gatherEdges.some(edgedswdw => edgedswdw.target === edge.target);
    if (!targetExists) {
      // If the target doesn't exist, append the edge
      gatherEdges.push(edge)
      setCurrentIndex(currentIndex + 1);
      console.log('inside edges set');
      setEdges(prevEdges => [...prevEdges, edge]);
  } else {
      // If the target already exists, log a message or handle it accordingly
      console.log('Edge with the same target already exists');
  }
  }, 1000);
    }
    },[nodes.length,allNodes])

    useEffect(() => {
      if(allNodes?.length>0 && nodes?.length!=allNodes?.length && !addNewNode){
      setNodes(prevNodes => [...prevNodes, allNodes[currentIndex]])
      }
      },[edges.length,allNodes])


 
 
  const onNodeClick = (event, node) => {
    // console.log(node)
    if (node.careerId) {
      // console.log('fefwefwef----------------')
      // dispatch(getCareerData(node.careerId))
      setCurrentCareer(node.careerId)
      setOpenDrawer(true)
    }
  };
  const handleDrawer=()=>{
    setOpenDrawer(!openDrawer)
  }

  
  const middleX = typeof window !== 'undefined' ? window.innerWidth / 2 : 0;
  const middleY = typeof window !== 'undefined' ? window.innerHeight / 2 : 0;
//  console.log(edges)
  return (
    <>
   
    <div style={{ height: "87%",background:'#fff' }}>
      <Slide in={true} direction="up" timeout={800}>
      <ReactFlow 
      nodes={nodes} 
      edges={edges}  
      nodesDraggable={true}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onNodeClick={(event, node)=>onNodeClick(event, node)}
      defaultViewport={{
        x: middleX,
        y: middleY-50,
        zoom: middleY>600 ?1:0.5,
      }}
      zoomOnScroll={true} 
      >
        <Background />
        <Controls />
      </ReactFlow>
      </Slide>
    </div>
    <CareerDrawer open={openDrawer} careerId={currentCareer} nodes={nodes} type={'path'} onClose={handleDrawer} />
    </>
  );
}



export default function Flow ({data}) {
 
  return (
    <ReactFlowProvider>
      <LayoutFlow apiData={data} />
    </ReactFlowProvider>
  );
}
