import React from 'react'
import RemedialCard from '../RemedialCard/RemedialCard'
import { Stack, Typography } from '@mui/material'
import BooksSvg from './../../../../assets/remedial/svg/books.svg'
import BonusStarSvg from './../../../../assets/remedial/svg/bonus-star.svg'
import BorderLinearProgress from '../../../../components/common/BorderLinearProgress/BorderLinearProgress'

const StudyProgressCard = ({ studyProgress, width = '100%', height = '100%', variant = 'study' }) => {
  return (
    <RemedialCard width={width} height={height}>
      <Stack gap={'20px'}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Stack direction={'row'} gap={'8px'}>
            <img src={variant === 'study' ? BooksSvg :  BonusStarSvg} width={20} height={20} alt='clover-leaf' />
            <Typography
              component={'h4'}
              sx={{
                fontFamily: 'Poppins-SemiBold',
                fontSize: '16px',
                color: 'grey.900'
              }}>
          {  variant === 'study' ?   `Study Progress Bar` : 'Bonus Reward Progress'}
            </Typography>
          </Stack>
          <Typography
            component={'h4'}
            sx={{
              fontFamily: 'Poppins-SemiBold',
              fontSize: '18px',
              color: 'primary.800'
            }}>
            {studyProgress}%
          </Typography>
        </Stack>
        <BorderLinearProgress value={studyProgress} barColor={'primary.100'} progressColor={'primary.800'} />
{   variant === 'study' ?     <Typography
          component={'h4'}
          sx={{
            fontFamily: 'Poppins-Medium',
            fontSize: '12px',
            color: 'grey.600',
            textAlign: 'start',
            span: {
              fontFamily: 'Poppins-SemiBold',
              fontSize: '12px',
              color: 'grey.800'
            }
          }}>
          Study <span>75%</span>  or more of the topics below to <span>activate the spin wheel</span>  to continue your remedial test journey.
        </Typography> :
        <Typography
        component={'h4'}
        sx={{
          fontFamily: 'Poppins-Medium',
          fontSize: '12px',
          color: 'grey.600',
          textAlign: 'start',
          span: {
            fontFamily: 'Poppins-SemiBold',
            fontSize: '12px',
            color: 'grey.800'
          }
        }}>
Study <span>75%</span> or more of the topics below to <span>activate the spin wheel</span> to continue your remedial test journey.
      </Typography>
        }
      </Stack>
    </RemedialCard>
  )
}

export default StudyProgressCard