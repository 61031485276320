import { Box, Typography } from '@mui/material';
import GroupedAvatars from './GroupedAvatars';

const SwiperChild = ({ data }) => {

    return (
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
            {data.type === 'avatars' ? <GroupedAvatars /> : <Typography variant="body7" sx={{marginTop: '4px', marginRight: '0px', color: (theme) => theme.palette.primary[600]}}>{data.primaryText}</Typography>}
            <Typography variant="caption1" sx={{marginTop: '4px', marginLeft: '5px', color: (theme) => theme.palette.grey[800]}}>{data.secondaryText}</Typography>
        </Box>
    )
};

export default SwiperChild;