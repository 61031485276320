import { Box, Typography } from '@mui/material'
import React from 'react'

//variant -> right, wrong , notAttempt

const QuestionNumber = ({ variant, qNumber , boxPadding}) => {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: (theme) => variant === 'right' ? theme.palette.success[200] : variant === 'wrong' ? theme.palette.extraredpalette[200] : theme.palette.grey[200],
            color: (theme) => variant === 'right' ? theme.palette.success[800] : variant === 'wrong' ? theme.palette.extraredpalette[700] : theme.palette.grey[600],
            padding: `${boxPadding}px`,
            borderRadius: '4px'
        }}>
            <Typography component={'p'} sx={{
                fontFamily: 'Poppins-Medium',
                fontSize: '14px',
            }}>
                {qNumber}
            </Typography>
        </Box>
    )
}

export default QuestionNumber