import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import colors from "../../pages/PersonalityAssessment/colors";
import Circle from "../../components/common/circle";

const Question = ({id, name, option, handleAnswerSelection, type}) => {

    const [selectedAnswer, setSelectedAnswer] = useState(0);

    useEffect(() => {
        if (option) {
            setSelectedAnswer(option);
        }
    }, []);

    const answerSelection = (option_id) => {
        setSelectedAnswer(option_id);
        handleAnswerSelection(id, option_id);
    };

    return (
        <>
            <Box
                sx={{
                    padding: type === "inList" ? '20px 12%' : '5px 30px 20px 30px',
                    // margin: type === 'inList' ? '0px 6%' : '0',
                    margin: '0',
                    borderBottom: type === 'inList' ? `0.5px solid ${colors.questionBorderColor}`: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    // opacity: in_focus ? 1 : 0.5,
                    // pointerEvents: in_focus ? 'auto' : 'none',
                }}
            >
                <Typography variant={type === "inList" ? "h5" : "body5"} textAlign={type === "inList" ? "center" : "left"} sx={{marginBottom: '15px', marginLeft: type === "inList" ? '0px' : '10px', marginRight: type === "inList" ? '0px' : '10px'}}>
                    {name}
                </Typography>
                <Grid
                    container 
                    direction="row"
                    // justifyContent="space-evenly"
                    alignItems="center"
                >
                    <Grid item width="20%" onClick={() => answerSelection(1)}>
                        <Circle 
                            color={colors.disagree}
                            hoverColor={colors.disagree_secondary}
                            size={type === "inList" ? '50px' : "35px"}
                            isFilled={selectedAnswer === 1 ? true : false}
                        />
                    </Grid>
                    <Grid item width="20%" onClick={() => answerSelection(2)}>
                        <Circle 
                            color={colors.disagree}
                            hoverColor={colors.disagree_secondary}
                            size={type === "inList" ? '45px' : '30px'}
                            isFilled={selectedAnswer === 2 ? true : false}
                        />
                    </Grid>
                    <Grid item width="20%" onClick={() => answerSelection(3)}>
                        <Circle 
                            color={colors.neutral}
                            hoverColor={colors.neutral_secondary}
                            size={type === "inList" ? '35px' : '25px'}
                            isFilled={selectedAnswer === 3 ? true : false}
                        />
                    </Grid>
                    <Grid item width="20%" onClick={() => answerSelection(4)}>
                        <Circle 
                            color={colors.agree}
                            hoverColor={colors.agree_secondary}
                            size={type === "inList" ? '45px' : '30px'}
                            isFilled={selectedAnswer === 4 ? true : false}
                        />
                    </Grid> 
                    <Grid item width="20%" onClick={() => answerSelection(5)}>
                        <Circle 
                            color={colors.agree}
                            hoverColor={colors.agree_secondary}
                            size={type === "inList" ? '50px' : "35px"}
                            isFilled={selectedAnswer === 5 ? true : false}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container 
                    direction="row"
                    // justifyContent="space-evenly"
                    alignItems="flex-start"
                >
                    <Grid item sx={{width: "20%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography variant={type === "inList" ? "body4" : "caption1"} textAlign="center" color={colors.disagree}>Strongly</Typography>
                        <Typography variant={type === "inList" ? "body4" : "caption1"} textAlign="center" color={colors.disagree} sx={{lineHeight: 'inList' ? 'normal' : ''}}>Disagree</Typography>
                    </Grid>
                    <Grid item sx={{width: "20%", display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                        <Typography variant={type === "inList" ? "body4" : "caption1"} textAlign="center" color={colors.disagree}>Disagree</Typography>
                    </Grid>
                    <Grid sx={{width: "20%", display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                        <Typography variant={type === "inList" ? "body4" : "caption1"} textAlign="center" color={colors.neutral}>Neutral</Typography>
                    </Grid>
                    <Grid sx={{width: "20%", display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                        <Typography variant={type === "inList" ? "body4" : "caption1"} textAlign="center" color={colors.agree}>Agree</Typography>
                    </Grid> 
                    <Grid item sx={{width: "20%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography variant={type === "inList" ? "body4" : "caption1"} textAlign="center" color={colors.agree}>Strongly</Typography>
                        <Typography variant={type === "inList" ? "body4" : "caption1"} textAlign="center" color={colors.agree} sx={{lineHeight: 'inList' ? 'normal' : ''}}>Agree</Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Question;