import React, { Component } from 'react';
import AssessmentMarkedReview from '../../components/AssessmentMarkedReview';
import AssessmentSkippedReview from '../../components/AssessmentSkippedReview';

class Dialog extends Component {
  state = {
    open: false
  }

  //to close dialog box
  closeDialog = () => {
    try {
      document.body.style.overflow = 'auto';
      this.props.closeDialog();
    } catch (error) {
      console.log(error);
    }
  }

  onModalContentClick = (event) => {
      event.stopPropagation();
      return;
  }
  onModalClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.closeDialog();
  }
  render() {

    return <>
        <div>
            <div onClick = {this.onModalClick} id="myModal" className="modal2" style={{ display: this.props.open == true ? 'block' : 'none' }} >
                <div className="modal-content2" onClick={this.onModalContentClick}>
                    <div>
                        {this.props.open && this.props.marked ? <AssessmentMarkedReview closeDialog = {this.closeDialog} timeremaining={this.props.timelimit}/> : 
                        this.props.open && this.props.skipped ? <AssessmentSkippedReview closeDialog = {this.closeDialog} timeremaining={this.props.timelimit}/>:null}
                    </div>
                </div>
            </div>
        <style jsx scoped='true'>{`
                    .modal2 {
                    position: fixed; /* Stay in place */
                    z-index: 1005; /* Sit on top */
                    left: 0;
                    top: 0;
                    width: 100%; /* Full width */
                    height: 100%; /* Full height */
                    overflow: auto; /* Enable scroll if needed */
                    background-color: rgb(0,0,0); /* Fallback color */
                    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
                  }
                  
                  /* Modal Content */
                  .modal-content2 {
                    margin-top: 20px;
                    margin-left: 40px;

                    border-radius:20px;
                    max-width: ${ 'width' in this.props ? this.props.width : '90%'};
                    width: 90%;
                    height: 90%;
                    border-radius: 20px;
                    margin-top: 90px;
                  }
                  
                  /* The Close Button */
                  .close {
                    color: #ffffff;
                    float: right;
                    font-size: 28px;
                    font-weight: bold;
                  }
                  
                  .close:hover,
                  .close:focus {
                    color: #ffff;
                    text-decoration: none;
                    cursor: pointer;
                  }
                `}</style>
      </div>
    </>
  }
}

export default Dialog;