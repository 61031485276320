import React,{useState,useEffect} from "react";
import SearchField1 from "../../components/common/Elements/SearchField1";
import { useDispatch,useSelector } from "react-redux";
import { addSearchQuery, getRecentSearchesData, getSearchData } from "../../store/actions/careerPathActions";
import CareerDrawer from "./components/CareerDrawer";
import { Grid,Stack } from "@mui/material";
import MuiCustomChip from "../../components/common/Elements/MuiCustomChip";
import { setSearchDataEmpty } from "../../store/reducers/careerPathReducer";
import { useDebounce } from "../../hooks/useDebounce";
import { AnimatedNumber } from "../../lib/AnimatedNumber";

const  SearchBar=()=> {
  const dispatch=useDispatch()
  const[openDrawer,setOpenDrawer]=useState(false)
  const[currentCareer,setCurrentCareer]=useState(1)
  const[searchText,setSearchTest]=useState('')
  const { searchData,loadingSearch,errorLoadingSearch, recentSearchesData } = useSelector((state) => state.careerpath);
  const searchQuery = useDebounce(searchText, 800)
  useEffect(() => {
    if(searchText?.length>0){
      dispatch(getSearchData(searchText))
    }
}, [searchQuery]);

  const handleSearch=(text)=>{
    setSearchTest(text)
    // dispatch(getSearchData(text))
  }
  const handleRecoredClick=(careerId, text)=>{
    dispatch(setSearchDataEmpty())
    setOpenDrawer(true)
    console.log(careerId)
    setCurrentCareer(careerId)
    if (text) {
      dispatch(addSearchQuery(text, careerId))
      dispatch(getRecentSearchesData())
    }
  }
  const handleDrawer=()=>{
    setOpenDrawer(!openDrawer)
  }
  return (
    <>
     <Grid item >
     <SearchField1 onSearch={handleSearch} onRecordClick={handleRecoredClick} loading={loadingSearch} searchedData={searchData} />
            </Grid>
            {recentSearchesData.length > 0 ? 
              <Grid item sx={{ '&.MuiGrid-item': { mr: 3 } }}>
                    <Stack direction={'row'} spacing={1.9}>
                    
                      {recentSearchesData.map((career, index) => {
                        if (index < 3) {
                          return <MuiCustomChip variant="outlined" label={career.keyword} onClick={() => handleRecoredClick(career.type_id)} />
                        }
                      })}
                  </Stack>
              </Grid>
            : null}
   
    <CareerDrawer open={openDrawer} careerId={currentCareer} nodes={[]} type={'search'} onClose={handleDrawer}  />
    </>
  );
}
export default SearchBar