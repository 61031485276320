import "./joinchallange.css";

import React from "react";
import ReactDOM from "react-dom";
// import song from "../bgtrack.wav";
import WheelComponent from "./wheelcomponent";
import styled, { keyframes, css } from "styled-components";
import ReactCountdownClock from "react-countdown-clock";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Component } from "react";
import { AiFillWechat } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import { AiOutlineSend } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import Chatwidget from "./chatwindow/chatroom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

class Wheel extends Component {
  state = {
    segments: [
      "Aptitude",
      "Coding",
      "Objective",
      "Multiple choice",
      "Reasoning",
    ],
    // audio: new Audio(song),
    isPlaying: false,

    segColors: [
      "#aec7e8",
      "#ffbb78",
      "#98df8a",
      "#ff9896",
      "#c5b0d5",
      "#c49c94",
      "#f7b6d2",
      "#c7c7c7",
      "#dbdb8d",
      "9edae5",
    ],
    spinwheelstatus: "notstarted",
    showResults: false,
    newmsgrecieved: false,
    spinwheelrotate:true,
  };

  openchatwidget = () => {
    this.setState({ showResults: true, newmsgrecieved: false });
    setTimeout(() => {
      document
        .getElementById("scrollintoview")
        .scrollIntoView({ behavior: "smooth" });
    }, 10);
  };
  closechatwidget = () =>
    this.setState({ showResults: false, newmsgrecieved: false });
  playPause = () => {
    // Get state of song
    let isPlaying = this.state.isPlaying;
  };
  msgrecieved = () => {
    if (!this.state.showResults) {
      this.setState({ newmsgrecieved: true });
    }
  };
  onFinished = (winner) => {
    // console.log("winner" + winner);
    this.setState({ spinwheelstatus: winner });
  };
  
  render() {
    // console.log(this.props.data);
    // let teamid;
    // if (this.props.challengedetails.participation_type) {
    //   if (this.props.challangedetails.team_id) {
    //     teamid = this.props.challangedetails.team_id;
    //   } else {
    //     teamid = this.props.teamdetails.id;
    //   }
    // }

    let segmentsjson, segmentsjson1;
    if (this.props.data) {
      segmentsjson = JSON.parse(this.props.data);
      segmentsjson1 = JSON.parse(this.props.data);
    } else {
      segmentsjson = this.props.challengedetails.topics;
      segmentsjson1 = this.props.challengedetails.topics;
    }

    // console.log(segmentsjson1);
    let segments = segmentsjson.map((data) => data.name);
    // console.log(segments);
    let segmenttobestopped;
    segmentsjson1.map((data) => {
      if (data.id == this.props.topicselected) {
        segmenttobestopped = data.name;
      }
    });
    // console.log("selected" + segmenttobestopped);
    return (
      <>
        <Maindiv>
          <h2 style={{ color: "red" }}>{this.props.challengedetails.title}</h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexDirection: "row-reverse",
              marginTop:'7rem',
            }}
          >
            <WheelComponent
              segments={segments}
              weeklychallenge={true}
              segColors={this.state.segColors}
              winningSegment={segmenttobestopped}
              onFinished={(winner) => this.onFinished(winner)}
              primaryColor="black"
              contrastColor="white"
              buttonText="Spin"
              isOnlyOnce={false}
              size={290}
              upDuration={100}
              downDuration={300}
              isPlaying={this.state.isPlaying}
              style={{ marginTop: "20px" }}
            />

                

            {this.state.spinwheelstatus == "notstarted" ? (
              <div style={{ display: "flex" }}>
                {" "}
                <div style={{ color: "#455a64", fontSize: "25px" }}>
                  Surprise pick for you
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", fontSize: "25px" }}>
                {" "}
                <div class="slide-right">
                  Generating question in <br />
                  <b style={{ color: "red" }}>{this.state.spinwheelstatus}</b>
                  <br></br>
                  <b>
                    <span>Team Get ready</span>
                  </b>
                </div>
              </div>
            )}
            {/* <div style={{ fontSize: "25px" }}>
              Q{this.props.questionnumber}{" "}
              <BsArrowRight style={{ fontSize: "25px" }} />
            </div> */}
          </div>
          {this.props.challengedetails.participation_type &&
          this.props.challengedetails.is_member == 1 &&
          this.props.challengedetails.is_viewer == 0 ? (
            <>
              <div
                style={{
                  float: "right",
                  cursor: "pointer",
                  position: "relative",
                  bottom: "50px",
                }}
                onClick={this.openchatwidget}
                title={`Chat with team`}
              >
                {this.state.newmsgrecieved ? (
                  <img
                    src="/gifs/new-message-recieved.gif"
                    style={{
                      position: "absolute",
                      top: "-15px",
                      left: "-39px",
                      width: "41px",
                      borderRadius: "20px",
                    }}
                  ></img>
                ) : null}
                <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
                {/* <div>{`Team`}</div> */}
              </div>

              <div
                className={this.state.showResults ? "fadeIn" : "fadeOut"}
                id="supportmonboxid"
              >
                <div
                  className="supportmonbox_head col-md-12 col-sm-12"
                  id="supbox"
                  style={{ display: "block", height: "41px" }}
                >
                  <div className="col-xs-1" style={{ float: "right" }}>
                    <AiFillCloseCircle
                      onClick={this.closechatwidget}
                      style={{ fontSize: "26px", cursor: "pointer" }}
                    />
                  </div>
                </div>

                <Chatwidget
                  roomid={`${this.props.challengedetails.id}_${this.props.teamdetails.id}`}
                  foundnewmsg={this.msgrecieved}
                />
              </div>
            </>
          ) :this.props.challengedetails.is_member == 0 &&
          this.props.challengedetails.is_viewer == 1  ?
          (
            <>
              <div
                style={{
                  float: "right",
                  cursor: "pointer",
                  position: "relative",
                  bottom: "50px",
                }}
                onClick={this.openchatwidget}
                title={`Chat with team`}
              >
                {this.state.newmsgrecieved ? (
                  <img
                    src="/gifs/new-message-recieved.gif"
                    style={{
                      position: "absolute",
                      top: "-15px",
                      left: "-39px",
                      width: "41px",
                      borderRadius: "20px",
                    }}
                  ></img>
                ) : null}
                <HiOutlineChatAlt2 style={{ fontSize: "50px" }} />
                {/* <div>{`Team`}</div> */}
              </div>

              <div
                className={this.state.showResults ? "fadeIn" : "fadeOut"}
                id="supportmonboxid"
              >
                <div
                  className="supportmonbox_head col-md-12 col-sm-12"
                  id="supbox"
                  style={{ display: "block", height: "41px" }}
                >
                  <div className="col-xs-1" style={{ float: "right" }}>
                    <AiFillCloseCircle
                      onClick={this.closechatwidget}
                      style={{ fontSize: "26px", cursor: "pointer" }}
                    />
                  </div>
                </div>

                <Chatwidget
                  roomid={`${this.props.challengedetails.id}_Viewer`}
                  foundnewmsg={this.msgrecieved}
                />
              </div>
            </>
          )
          :null}
        </Maindiv>
      </>
    );
  }
}
const Maindiv = styled.div`
  height: auto;
  border-radius: 50px;
  margin: 0px 30px 0px 30px;
  padding: 12px;
  @media (max-width: 768px) {
    height: auto;
  }
  @media (max-width: 1024px) {
    height: auto;
  }
`;
function mapStateToProps(state) {
  return {
    challengedetails: state.joinchallenge.challangedetails,
    teamdetails: state.joinchallenge.teamdetails,
  };
}

export default withRouter(connect(mapStateToProps, null)(Wheel));
