// const colors2 = {
//     primary: '#7260D6',
//     secondary: '#E9E7F8',
//     easy: '#4ECD56',
//     medium: '#FFB63E',
//     hard: '#F44336',
//     common: {
//         white: '#FFF',
//         black: '#000',
//     }
// }

export const colors2 = {
    primary: '#0B58F5',
    primaryDarker: '#074AD2',
    primaryLighter: '#3C79F7',
    secondary: '#E7EEFE',
    easy: '#4ECD56',
    medium: '#FFB63E',
    hard: '#F44336',
    completedPrimary: '#40A846',
    completedSecondary: '#DCF5DD',
    errorPrimary: '#CB372C',
    errorSecondary: '#FEECEB',
    common: {
        white: '#FFF',
        black: '#000',
    },
    textPrimary: '#000',
    textSecondary: '#909090',
    backgroundPrimary: '#fff',
    backgroundSecondary: '#F4F6F8',
    disabled: '#DFE3E8',
    disabledText: '#919EAB',
    companyTagPrimary: '#212B36',
    plusTagHover: '#DFE3E8',
    skeletonColor: 'rgba(33,43,54,0.11)',
    hintBulb: '#212B36',

    grey1: '#303030',
    grey2: '#505050', 


    grey : {
        0: '#F9FAFB',
        100: '#F4F6F8',
        200: '#DFE3E8',
        300: '#C4CDD5',
        400: '#919EAB',
        500: '#919EAB',
        600: '#637381',
        700: '#454F5B',
        800: '#212B36',
        900: '#161C24',
      },

      primary_2 : {
        lighter: '#D8E6F6',
        light: '#B1CCED',
        main: '#3D80D2',
        dark: '#265083',
        darker: '#1A375C',
        contrastText: '#fff',
      },

      secondary_2 : {
        lighter: '#ECF2FA',
        light: '#ECF2FA',
        main: '#ECF2FA',
        dark: '#ECF2FA',
        darker: '#ECF2FA',
        contrastText: '#000000',
      },
}

export const darkModeColors = {
    primary: '#0946C4',
    primaryDarker: '#074AD2',
    primaryLighter: '#3C79F7',
    secondary: '#031A4A',
    secondaryLighter: '#9DBCFB',
    easy: '#4ECD56',
    medium: '#FFB63E',
    hard: '#F44336',
    completedPrimary: '#40A846',
    completedSecondary: '#DCF5DD',
    errorPrimary: '#CB372C',
    errorSecondary: '#290502',
    common: {
        white: '#FFF',
        black: '#000',
    },
    textPrimary: '#FFF',
    textSecondary: '#909090',
    backgroundPrimary: '#1E1E1E',
    backgroundSecondary: '#252525',
    backgroundDarkest: '#101010',
    
    grey1: '#303030',
    grey2: '#505050',

    disabled: '#DFE3E8',
    disabledText: '#919EAB',

    companyTagPrimary: '#E7EEFE',
    skeletonColor: '#303030',
    hintBulb: '#FFC565',

    grey : {
        0: '#F9FAFB',
        100: '#F4F6F8',
        200: '#DFE3E8',
        300: '#C4CDD5',
        400: '#919EAB',
        500: '#919EAB',
        600: '#637381',
        700: '#454F5B',
        800: '#212B36',
        900: '#161C24',
      },

      primary_2 : {
        lighter: '#D8E6F6',
        light: '#B1CCED',
        main: '#3D80D2',
        dark: '#265083',
        darker: '#1A375C',
        contrastText: '#fff',
      },

      secondary_2 : {
        lighter: '#ECF2FA',
        light: '#ECF2FA',
        main: '#ECF2FA',
        dark: '#ECF2FA',
        darker: '#ECF2FA',
        contrastText: '#000000',
      },
}

export default colors2;