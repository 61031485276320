import React, { Component } from 'react';
import { v4 } from 'uuid';
import monogram from '../../../../assets/coding/monogram.svg'
import styles from './QuestionPagination.module.css';




export default class QuestionPagination extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedQuestion: this.props.selectedQuestion,
      selectedGroup: this.props.selectedQuestion,
      assessments: this.props.assessments,
      group: []
    }
  }

  componentDidUpdate() {
    // this.updateGroups()
  }

  componentDidMount() {
    this.updateGroups()
  }

  updateGroups() {

    let group = []

    for (let i = 1; i <= this.props.groupCount; i++) {

      group.push(this.props.assessments.filter(p => p.groupId === i))

    }
    this.setState({ group: group })

  }


  render() {

    let buttons = this.state.group.map((q, index) => {
      return (
        (index + 1 !== this.props.selectedGroup) ?
          <div className={styles.groupButton} key={v4()} onClick={() => this.props.onUpdateSelectGroup(q[0], index + 1)}>
            <div></div>
          </div>
          : q.map((r) => {
            let classes = r.completed ? styles.completed : (r.marked ? styles.marked : styles.button)
            return (
              r.id !== this.props.selectedAssessment.id ?
                <div className={classes} key={v4()} onClick={() => this.props.onUpdateSelectAssessment(r)}>
                  <span>{r.number}</span>
                </div> : <div className={styles.selectedButton} key={v4()}>
                  <img className={styles.monogram} src={monogram} alt="monogram" />
                  <div className={styles.button} >
                    <span>{r.number}</span>
                  </div>
                </div>
            )
          })

      )

    })

    return (
      <div className={styles.QuestionPagination}>
        {buttons}
      </div>
    )
  }

};
