import React, { useEffect } from 'react'
import RemedialCard from '../RemedialCard/RemedialCard'
import { Skeleton, Stack, Typography } from '@mui/material'
import ClockSvg from './../../../../assets/remedial/svg/timer.svg'
import PodiumSvg from './../../../../assets/remedial/svg/podium.svg'
import MeterSvg from './../../../../assets/remedial/svg/testmeter.svg'
import QuestionSvg from './../../../../assets/remedial/svg/questions.svg'
import {AnimatedNumber} from './../../../../utils'

const RecordCard = ({ data, width = '100%', height = '100%', variant, value, text = '' }) => {


    return (
        <RemedialCard width={width} height={height}>
            <Stack gap={'16px'}>
                <Stack direction={'row'} alignItems={'center'} gap={'12px'}>
                    <img width={20} height={20} src={variant === 'time' ? ClockSvg : variant === 'rank' ? PodiumSvg : variant === 'performance' ? MeterSvg : QuestionSvg} alt='RankImage' />
                    {variant === 'time' && <Typography
                        component={'h4'}
                        sx={{
                            fontFamily: 'Poppins-Bold',
                            fontSize: '24px',
                            color: 'primary.800',
                            lineHeight: '1.5'
                        }}>
                        {value >= 0 ? <AnimatedNumber n={~~(Number(value) / 60)} time /> : <Skeleton variant='text' width={30} height={30} sx={{background: theme => theme.palette.grey[200]}} />}
                    </Typography>}
                    {variant === 'rank' && <Typography
                        component={'h4'}
                        sx={{
                            fontFamily: 'Poppins-Bold',
                            fontSize: '24px',
                            color: 'primary.800',
                            lineHeight: '1.5'
                        }}>
                        {value}
                    </Typography>}
                    {variant === 'performance' && <Typography
                        component={'h4'}
                        sx={{
                            fontFamily: 'Poppins-Bold',
                            fontSize: '24px',
                            color: 'primary.800',
                            lineHeight: '1.5'
                        }}>
                        {value >= 0 ? <AnimatedNumber n={value} showPercentage /> : <Skeleton variant='text' width={30} height={30} sx={{background: theme => theme.palette.grey[200]}} />}
                    </Typography>}
                    {variant === 'answered' && <Typography
                        component={'h4'}
                        sx={{
                            fontFamily: 'Poppins-Bold',
                            fontSize: '24px',
                            color: 'primary.800',
                            lineHeight: '1.5'
                        }}>
                        {value >= 0 ? <AnimatedNumber n={value}  /> : <Skeleton variant='text' width={30} height={30} sx={{background: theme => theme.palette.grey[200]}} />}
                    </Typography>}
                </Stack>
                <Typography
                    component={'h4'}
                    sx={{
                        fontFamily: 'Poppins-Medium',
                        fontSize: '14px',
                        color: 'grey.700',
                        textTransform: 'capitalize',
                        textAlign: 'start',
                        lineHeight: '1.57'
                    }}>
                    {text}
                </Typography>
            </Stack>
        </RemedialCard>
    )
}

export default RecordCard