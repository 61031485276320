import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import {getProfileDataAC} from '../../store/actions/profileActions';
import AppLayout from '../../components/Layout/Layout';
import styled from 'styled-components';
import Modal from '../course/content/Modal';

const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 25%);

    @media(max-width: 1180px) {
        grid-template-columns: repeat(2, 50%);
    }

`;

const EditButton = styled.div`
    background: linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%);
    border-radius: 32px;
    // margin: auto;
    text-align: center;
    border: 1px solid linear-gradient(139.5deg, #7175E0 -50.69%, #3235C0 60.2%, #E25BC8 189.26%);
    width: 98px;
    padding: 8px;
    color: white;
    // margin-right: 0;
`

class Profile extends Component {

    state = {
        openContent : false,
        contentUrl: '',
        contentType: null
    }

    onContentOpen = async (url, type) => {
        let template;
        // if (type != 'MP4') {
        //     template = await getTemplateData(url);
        // }
        document.body.style.overflow = 'hidden';
        this.setState({openContent: true, contentUrl: url, contentType: type, template: template})
    }

    closeContent = () => {
        this.setState({openContent : false,
            contentUrl: '',
            contentType: null,
            template: null})
        document.body.style.overflow = 'auto';
        
    }

    componentDidMount = ()=> {
        this.props.getProfileData();
        document.body.style.backgroundColor = '#f2f2f2';
    }

    editProfile = () => {
        this.props.history.push({
            pathname: '/editProfile',
        });
    }
    goback= () => {
        this.props.history.push({
            pathname: '/home',
        });
    }
    render () {
        if (this.props.loading) {
            return <div>Loading....</div>
        } else if (this.props.error_loading_data && !this.props.loading) {
            return <div>Error loading profile data</div>
        }
        return (
            <div style={{color:'#191648'}}>
                <Modal 
                    open={this.state.openContent} 
                    url={this.state.contentUrl} 
                    close={this.closeContent} 
                    type={this.state.contentType}
                    template={this.state.template}>

                </Modal>
                {/* <AppLayout></AppLayout> */}
                <div style={{width: '95%', marginLeft: 'auto', marginRight: 'auto'}}>
                    <div style={{margin: '20px auto', marginRight: '0',display:"flex",justifyContent:"space-between"}}>
                       <EditButton onClick={this.goback}>Back</EditButton>
                        <EditButton onClick={this.editProfile}>Edit</EditButton>
                    </div>
                    <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', display: 'flex'}}>
                        <div style={{top: '140px', left: '40px',width:'1s0%',float:'left'}}>
                            <img style={{borderRadius: '50% 50%', width: '90px', height: '90px'}} src={this.props.profile_pic} alt='profile'></img>
                            <div style={{position: 'absolute', right: '5px', bottom: '0'}}>
                                <img width='24px' height='24px' src='/images/profile/edit_icon.svg' alt='edit'></img>
                            </div>
                        </div>
                        <div style={{width:'90%'}}>
                            <div style={{textAlign: 'left', fontSize: '14px', fontWeight: '400', margin: '10px', marginLeft: '70px'}}><strong style={{fontSize: '20px', fontWeight: '500', textAlign: 'left'}}>{this.props.name}&nbsp;&nbsp;&nbsp;&nbsp;</strong>{this.props.roll_number}</div>
                            <div style={{borderBottom: '1px solid #D4D4D4', margin: '10px', marginLeft: '70px'}}></div>
                            <div style={{margin: '10px',display: 'flex', flexDirection: 'row', textAlign: 'left', fontSize:'14px', marginLeft: '70px'}}>
                                <div style={{width: '40%'}}>{this.props.department_details.full_name}</div>
                                <div style={{width: '50%'}}>{this.props.email}</div>
                            </div>
                            <div style={{margin: '10px',display: 'flex', flexDirection: 'row', textAlign: 'left', fontSize:'14px', marginLeft: '70px'}}>
                                <div style={{width: '40%'}}>{this.props.college.college_full_name}</div>
                                <div style={{width: '40%'}}>{this.props.mobile_number}</div>
                            </div>
                        </div>
                    </div>
                    {this.props.splash && this.props.splash.category_name && this.props.splash.core_name ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{}}>
                                <img src='/images/profile/your_interest.png' alt='your interest'></img>
                            </div>
                            <div style={{marginLeft: '20px'}}>
                                <div style={{fontSize: '20px', fontWeight:'500', color: 'rgb(25, 22, 72)'}}>Your Interest</div>
                                <div>{`You are interested in ${this.props.splash.category_name} and would like to become a ${this.props.splash.core_name}`}</div>
                            </div>
                        </div>
                    </div> : null}
                    {this.props.video_resume && this.props.video_resume.video_thumb && this.props.video_resume.resume_video ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{}}>
                                <img src='/images/profile/video.svg' alt='video'></img>
                            </div>
                            <div style={{marginLeft: '20px'}}>
                                <div style={{fontSize: '20px', fontWeight:'500', color: 'rgb(25, 22, 72)'}}>Video Resume</div>
                                {/* <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</div> */}
                                <div style={{marginTop: '20px'}}>
                                    <img style={{width: '281px', height: '158px', borderRadius: '4px'}} src={this.props.video_resume.video_thumb}></img>
                                </div>
                                <div style={{marginTop: '-100px', marginBottom: '30px'}}>
                                    <img style={{width: '100px', height: '100px', marginLeft:'90px', marginTop: '-26px'}} onClick={() => this.onContentOpen(this.props.video_resume.resume_video,"mp4")} src='/images/profile/video-play-icon-transparent-27.jpg'/>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                    {this.props.summary ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{}}>
                                <img src='/images/profile/career_objective.svg'></img>
                            </div>
                            <div style={{marginLeft: '20px'}}>
                                <div style={{fontSize: '20px', fontWeight:'500', color: 'rgb(25, 22, 72)'}}>Career Objective</div>
                                <div style={{wordBreak: 'break-word', marginTop: '10px'}}>{this.props.summary}</div>
                            </div>
                        </div>
                    </div> : null}

                    {this.props.education && this.props.education.length != 0 ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{}}>
                                    <img src='/images/profile/education.svg'></img>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div style={{fontSize: '20px', fontWeight:'500', color: 'rgb(25, 22, 72)'}}>Education</div>
                                </div>
                            </div>

                            {this.props.education.map((edu)=> {
                                return <div style={{display: 'flex', flexDirection: 'row'}} key={edu.id}>
                                    <div style={{color: '#191648', fontSize: '14px', marginRight: '10px', width: 'auto'}}>{edu.from_year} - {edu.to_year ? edu.to_year : 'present'}</div>
                                    <div style={{margin: 'auto', borderLeft: '1px solid black', position: 'relative', width: '100%', marginLeft: '10px'}}>
                                        <div style={{width:'19px', height: '19px', position: 'absolute', left:'-10px'}}>
                                           <span class="dot"></span>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', marginLeft: '20px', marginTop: '-10px', width: 'auto'}}>
                                            <div style={{marginRight: '10px', width: '5%'}}>
                                                <img src='/images/profile/education_college.svg' />
                                            </div>
                                            <div style={{width: '95%'}}>
                                                <div style={{margin: '10px 10px', fontSize: '18px', color: '#4E54C8', }}>
                                                    {edu.institute}
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {edu.field}
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {edu.degree}
                                                </div>
                                                <div  style={{margin: '10px 10px', color:'#191648', fontSize: '14px', opacity: '0.6'}}>
                                                    {edu.description}
                                                </div>
                                                {edu.files && edu.files.length != 0 ? <ImageGrid>
                                                {edu.files.map((file)=> {                    
                                                    const re = /(?:\.([^.]+))?$/;
                                                    const fileType = re.exec(file.file_url)[1];                              
                                                    return <div style={{marginLeft: '20px'}} key={file.id}>
                                                        <img style={{width: '150px'}} height='114' src={file.thumb_url} alt="" onClick={() => this.onContentOpen(file.file_url,fileType)} />
                                                    </div>
                                                })}
                                                </ImageGrid> : null}
                                            </div>
                                        </div>
                                           

                                    </div>
                                </div>
                            })}
                        </div>
                    </div> : null}

                    {this.props.experience && this.props.experience.length != 0 ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{}}>
                                    <img src='/images/profile/experience.svg'></img>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div style={{fontSize: '20px', fontWeight:'500', color: 'rgb(25, 22, 72)'}}>Experience and Volunteering</div>
                                </div>
                            </div>

                            {this.props.experience.map((exp)=> {
                                return <div style={{display: 'flex', flexDirection: 'row'}} key={exp.id}>
                                    <div style={{color: '#191648', fontSize: '14px', marginRight: '10px', width: 'auto'}}>{exp.from_year} - {exp.to_year ? exp.to_year : 'present'}</div>
                                    <div style={{margin: 'auto', borderLeft: '1px solid black', position: 'relative', width:'100%', marginLeft: '10px'}}>
                                        <div style={{width:'19px', height: '19px', position: 'absolute', left:'-10px'}}>
                                           <span class="dot"></span>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', marginLeft: '20px', marginTop: '-10px', width: 'auto'}}>
                                            <div style={{marginRight: '10px', width: '5%'}}>
                                                <img src='/images/profile/experince_company.svg' />
                                            </div>
                                            <div style={{width:"95%"}}>
                                                <div style={{margin: '10px 10px', fontSize: '18px', color: '#4E54C8', }}>
                                                    {exp.title}
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {exp.company}
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {exp.type}
                                                </div>
                                                <div  style={{margin: '10px 10px', color:'#191648', fontSize: '14px', opacity: '0.6'}}>
                                                    {exp.description}
                                                </div>
                                                {exp.files && exp.files.length != 0 ? <ImageGrid>
                                                {exp.files.map((file)=> {                    
                                                    const re = /(?:\.([^.]+))?$/;
                                                    const fileType = re.exec(file.file_url)[1];                              
                                                    return <div style={{marginLeft: '20px'}} key={file.id}>
                                                        <img style={{width: '150px'}} height='114' src={file.thumb_url} alt="" onClick={() => this.onContentOpen(file.file_url,fileType)} />
                                                    </div>
                                                })}
                                                </ImageGrid> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div> : null}

                    {this.props.certificate && this.props.certificate.length != 0 ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{}}>
                                    <img src='/images/profile/certificates.svg' alt="certificate" />
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div style={{fontSize: '20px', fontWeight:'500', color: 'rgb(25, 22, 72)'}}>Certificates and Training</div>
                                </div>
                            </div>

                            {this.props.certificate.map((cer)=> {
                                return <div style={{display: 'flex', flexDirection: 'row'}} key={cer.id}>
                                    <div style={{color: '#191648', fontSize: '14px', marginRight: '10px', width: 'auto'}}>{cer.from_year} - {cer.to_year ? cer.to_year : 'present'}</div>
                                    <div style={{margin: 'auto', borderLeft: '1px solid black', position: 'relative', width: '100%', marginLeft: '10px'}}>
                                        <div style={{width:'19px', height: '19px', position: 'absolute', left:'-10px'}}>
                                        <span class="dot"></span>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', marginLeft: '20px', width: 'auto'}}>
                                            <div style={{marginRight: '10px',width: '5%'}}>
                                                <img src='/images/profile/certificates_certificate.svg' alt="certificate" />
                                            </div>
                                            <div style={{width: '95%'}}>
                                                <div style={{margin: '10px 10px', fontSize: '18px', color: '#4E54C8',marginTop: '0' }}>
                                                    {cer.title}
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {cer.company}
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {cer.location}
                                                </div>
                                                <div  style={{margin: '10px 10px', color:'#191648', fontSize: '14px', opacity: '0.6'}}>
                                                    {cer.description}
                                                </div>
                                                {cer.files && cer.files.length != 0 ? <ImageGrid >
                                                {cer.files.map((file)=> {
                                                    const re = /(?:\.([^.]+))?$/;
                                                    const fileType = re.exec(file.file_url)[1]; 
                                                    return <div style={{marginLeft: '20px'}} key={file.id}>
                                                        <img style={{width: '150px'}}  height='114' alt="" src={file.thumb_url} onClick={() => this.onContentOpen(file.file_url,fileType)} />
                                                    </div>
                                                })}
                                                </ImageGrid> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div> : null}

                    {this.props.projects && this.props.projects.length != 0 ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{}}>
                                    <img src='/images/profile/projects.svg'></img>
                                </div>
                                <div style={{marginLeft: '20px'}}>
                                    <div style={{fontSize: '20px', fontWeight:'500', color: 'rgb(25, 22, 72)'}}>Projects</div>
                                </div>
                            </div>

                            {this.props.projects.map((proj)=> {
                                return <div style={{display: 'flex', flexDirection: 'row'}} key={proj.id}>
                                    <div style={{color: '#191648', fontSize: '14px', marginRight: '10px', width: 'auto'}}>{proj.from_year} - {proj.to_year ? proj.to_year : 'present'}</div>
                                    <div style={{margin: 'auto', borderLeft: '1px solid black', position: 'relative', width: '100%', marginLeft: '10px'}}>
                                        <div style={{width:'19px', height: '19px', position: 'absolute', left:'-10px'}}>
                                           <span class="dot"></span>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', marginLeft: '20px', marginTop: '-10px', width: 'auto'}}>
                                            <div style={{marginRight: '10px', width: '5%'}}>
                                                <img src='/images/profile/projects_project.svg' />
                                            </div>
                                            <div style={{width: '95%'}}>
                                                <div style={{margin: '10px 10px', fontSize: '18px', color: '#4E54C8', }}>
                                                    {proj.title}
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {proj.company}
                                                </div>
                                                <div style={{margin: '10px 10px', color:'#191648', fontSize: '14px'}}>
                                                    {proj.location}
                                                </div>
                                                <div  style={{margin: '10px 10px', color:'#191648', fontSize: '14px', opacity: '0.6'}}>
                                                    {proj.description}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div> : null}

                    {this.props.languages && this.props.languages.length != 0 ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{}}>
                                <img src='/images/profile/languages.svg' alt='languages'></img>
                            </div>
                            <div style={{marginLeft: '20px', width:'-webkit-fill-available'}}>
                                <div style={{fontSize: '20px', fontWeight:'500', color: 'rgb(25, 22, 72)'}}>Languages</div>
                                <div style={{display: 'gird', gridTemplateColumns: 'repeat(3,33.3%)', marginTop: '10px'}}>
                                    {this.props.languages.map((lang,lang_index)=> {
                                        let proficiency = Math.round(((lang.listening ? lang.listening : 0 ) + (lang.speaking ? lang.speaking : 0 ) + (lang.read ? lang.read : 0) +  (lang.write ? lang.write : 0))/4);
                                        
                                        return <div style={{ display:'flex', flexDirection:'row'}} key={lang_index}>{lang.name} | {proficiency < 2 ? 'Basic' : proficiency > 3 ? 'Fluent' : 'Intermediate'}</div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div> : null}

                    {this.props.skills && this.props.skills.length != 0 ? <div style={{backgroundColor: 'white', padding: '20px', borderRadius: '4px', marginTop: '20px', textAlign:'left'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{}}>
                                <img src='/images/profile/skills.svg'></img>
                            </div>
                            <div style={{marginLeft: '20px', width:'-webkit-fill-available'}}>
                                <div style={{fontSize: '20px', fontWeight:'500', color: 'rgb(25, 22, 72)'}}>Skills</div>
                                {this.props.learning_skills && this.props.learning_skills.length != 0 ? <div>
                                    <div style={{color: 'rgb(25, 22, 72)', margin: '20px 0'}}>Learning</div>
                                    <div style={{}}>{this.props.learning_skills}</div>
                                </div> : null}
                                {this.props.working_skills && this.props.working_skills.length != 0 ? <div>
                                    <div style={{color: 'rgb(25, 22, 72)', margin: '20px 0'}}>Working</div>
                                    <div style={{}}>{this.props.working_skills}</div>
                                </div> : null}
                                {this.props.advance_skills && this.props.advance_skills.length != 0 ? <div>
                                    <div style={{color: 'rgb(25, 22, 72)', margin: '20px 0'}}>Advance</div>
                                    <div style={{}}>{this.props.advance_skills}</div>
                                </div> : null}
                                {this.props.other_skills && this.props.other_skills.length != 0 ? <div>
                                    <div style={{color: 'rgb(25, 22, 72)', margin: '20px 0'}}>Others</div>
                                    <div style={{}}>{this.props.other_skills}</div>
                                </div> : null}
                            </div>
                        </div>
                    </div> : null}
                </div>
                
                <style jsx="true" scoped>
          {`
            .dot {
                height: 22px;
                width: 22px;
                background-color: #bbb;
                border-radius: 50%;
                display: inline-block;
            }
          `}
        </style>
            </div>
            
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.prof.loading,
        name: state.prof.name,
        certificate: state.prof.certificate,
        college: state.prof.college,
        college_university_degree_department_id: state.prof.college_university_degree_department_id,
        course_id: state.prof.course_id,
        course_title: state.prof.course_title,
        department_details: state.prof.department_details,
        education: state.prof.education,
        email: state.prof.email,
        email_verified: state.prof.email_verified,
        experience: state.prof.experience,
        gender: state.prof.gender,
        id: state.prof.id,
        is_ambassador: state.prof.is_ambassador,
        languages: state.prof.languages,
        mobile_number: state.prof.mobile_number,
        mobile_verified: state.prof.mobile_verified,
        profile_pic: state.prof.profile_pic,
        projects: state.prof.projects,
        roll_number: state.prof.roll_number,
        section_details: state.prof.section_details,
        semester_id: state.prof.semester_id,
        skills: state.prof.skills,
        splash: state.prof.splash,
        summary: state.prof.summary,
        university_degree_department_id: state.prof.university_degree_department_id,
        video_resume: state.prof.video_resume,
        error_loading_data: state.prof.error_loading_data,
        working_skills: state.prof.working_skills,
        advance_skills: state.prof.advance_skills,
        other_skills: state.prof.other_skills,
        learning_skills: state.prof.learning_skills
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProfileData:()=> dispatch(getProfileDataAC()),
        
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));