import React from 'react';

const studentCourseDetails = (props) => {
    return <div className='mainBox'>
        <div className='studentDiv'>Hi {props.name}! You are viewing {props.subject_name} </div>
        {/* <div className='profDiv'>Your Professor is deveeprasad acharya, Sem dates from 20 Nove'20 to 03 Dec'20</div> */}
        <style jsx scoped>{
            `
                .mainBox{
                    display: block;
                    width: 100%;
                    background-color: white;
                    border-radius: 8px;
                    margin-top: 10px;
                    text-align: left;
                    padding: 15px;
                    box-sizing: border-box;
                    box-shadow: 0 0 10px #ccc;

                }
                .studentDiv{
                    font-size: 18px;
                    color: black;
                    font-weight: 600;
                    margin: 10px
                }
                .profDiv{
                    font-size: 12px;
                    color: black;
                    margin: 5px 10px;
                }
            
            `
        }

        </style>
    </div>
};

export default studentCourseDetails;