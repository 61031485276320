import React, { useEffect, useState } from 'react';
import Pill from '../../../../components/common/Pill/Pill';
import { Box, Stack, styled } from '@mui/material';
import ReadingLeasons from './modalSections/ReadingLeasons';
import VideoLessons from './modalSections/VideoLessons';
import StudySnippets from './modalSections/StudySnippets';
import BorderLinearProgress from '../../../../components/common/BorderLinearProgress/BorderLinearProgress';
import RemedialModal from '../../../../components/common/RemedialModal/RemedialModal';

const RemedialStudyModal = ({index, data,show, title, onCloseHandle, size , percentage }) => {
  const [activePill, setActivePill] = useState();
  const [pillArray , setPillArray] = useState([]);
  // const [noOfReading, setNoOfReading] = useState(0)
  // const [noOfVideos, setNoOfVideos] = useState(0)
  // const [noOfSnippets, setNoOfSnippets] = useState(0)

  // const PillArray = ['Snippets', 'Video Lessons', 'Documents'];

  const PillWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    padding: '8px 12px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.grey[100]}`
  }));



  const handlePillClicked = (key) => {
    setActivePill(key);
  };

useEffect(() => {
  const newArray = [];
  if(data?.snippet_url.length > 0){
    newArray.push('Snippets')
  }
  if(data?.video_url.length > 0){
    newArray.push('Video Lessons')
  }
  if(data?.document_url.length > 0){
    newArray.push('Documents')
  }

  setPillArray(newArray)

  if(!activePill){
    setActivePill(newArray[0])
  }

} , [data])





  return (
    <RemedialModal show={show} onCloseHandle={onCloseHandle} size={size} title={title} headBodyGap={20}>
      <Stack gap={'20px'}>
        <BorderLinearProgress value={percentage} height={12} progressColor={'primary.600'} barColor={'primary.100'} />
        {/* <Box sx={{width:'100%' , height: '12px'}}></Box> */}
        {/* Pills */}
        {/* <PillWrapper>
          {pillArray && pillArray.map((item, index) => {
            return (
              <Pill
                isActive={item === activePill}
                onClick={() => {
                  handlePillClicked(item);
                }}
                backgroundColor={'primary.contrastText'}
                color={'grey.900'}
                activeBackgroundColor={'primary.800'}
                activeColor={'primary.contrastText'}>
                {item}
              </Pill>
            );
          })}
        </PillWrapper> */}
        {/* Content */}
        <Box>
          {activePill === 'Snippets' && data?.snippet_url.length > 0 && <ReadingLeasons index={index} data={data?.snippet_url} />}
          {activePill === 'Video Lessons' && data?.video_url.length > 0 && <VideoLessons index={index} data={data?.video_url}  />}
          {activePill === 'Documents' && data?.document_url.length > 0 && <StudySnippets index={index} data={data?.document_url}  />}

        </Box>

      </Stack>
    </RemedialModal>
  );
};

export default RemedialStudyModal;
