import React, { useEffect, useState } from 'react'
import LevelCard from '../../features/codingModule/levelCard.jsx'
import CodingScreenHeader from '../../features/codingModule/codingScreenHeader.jsx'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import { getCodingTracksAC } from '../../store/actions/codingTrackActions.js'
import { styled } from '@mui/material/styles'
import { Divider, Container, Grid, Box, Typography, Skeleton } from '@mui/material'
import { StyledGrid, VerticalLine } from './styles.js';
import Layout1 from '../../components/Layout/Layout1.js';
import ErrorPage from '../../components/common/ErrorPage.jsx';
import colors2 from './colors2.js';

const TagContainer = styled(Box)(({ theme }) => ({
  width: 200,
  height: 30,
  position: 'absolute',
  top: -5.5,
  left: '83px',
  background: colors2.secondary,
  borderRadius: '5px 5px 0px 0px',
}));

const CodingTrackLevel = (props) => {
  const [tracksData, setTracksData] = useState([]);
  useEffect(() => {
    console.log("in coding level select component");
    // console.log(props.codingTrackStatus);
    const getCodingTracksA = async () => await props.getCodingTracks();
    getCodingTracksA();
  }, []);

  useEffect(() => {
    let locked = false;
    if (props.codingTracks.length > 0) {
      const tracks = props.codingTracks.map(track => {
        if (locked === true) {
          track.locked = true;
        }
        else {
          track.locked = false;
        }
        if (track.next_level_locked) {
          locked = true;
        }
        else {
          locked = false;
        }
        return {
          ...track
        }
      })
      console.log("tracks");
      console.log(tracks);
      setTracksData(tracks);
    }
  }, [props.codingTracks]);

  if (props.error_loading_codingTracks) {
    return (
      <>
        <Grid container wrap="nowrap" sx={{background: '#f4f6f8'}}>
            <Layout1 active_page="coding_track"/>
            <Grid container justifyContent={'center'} alignItems={'center'} sx={{marginTop: '100px', marginLeft: '200px'}}>
              <Grid item>
                <ErrorPage />
              </Grid>
            </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid container wrap="nowrap" sx={{background: '#fff'}}>
        {/* <CodingScreenHeader /> */}
        <Layout1 active_page="coding_track"/>
        <StyledGrid container sx={{p:0}}>
          <Grid item sx={{position: 'relative', 'flexGrow': 1}}>
            {/* <VerticalLine /> */}
            <Container maxWidth={false} sx={{height: '100%'}}>
              <Typography variant="h3" sx={{marginRight: '20%', marginBottom: '75px', marginLeft: '5%', textAlign: 'left'}}>Practice coding to enhance your skills and knowledge</Typography>
              {props.loading_coding_tracks ? 
              <Grid container direction="row" spacing={3} alignItems="flex-end" justifyContent="center" wrap='nowrap'>
                <Grid item sx={{position: 'relative'}}>
                  <TagContainer />
                  <Skeleton variant='rounded' height={'380px'} width={'320px'} />
                </Grid>
                <Grid item sx={{position: 'relative'}}>
                  <TagContainer />
                  <Skeleton variant='rounded' height={'380px'} width={'320px'} />
                </Grid>
                <Grid item sx={{position: 'relative'}}>
                  <TagContainer />
                  <Skeleton variant='rounded' height={'380px'} width={'320px'} />
                </Grid>
              </Grid>
              :
              <Grid container direction="row" spacing={3} alignItems="flex-end" justifyContent="center" wrap='nowrap'>
                {
                  tracksData.map(track => (
                    <Grid item key={track.level}>
                      <LevelCard card={track}/>
                    </Grid>
                  ))
                }
              </Grid>
              }
            </Container>
          </Grid>
        </StyledGrid>
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    codingTrackStatus: state.codingTrack.codingTrackStatus,
    codingTracks: state.codingTrack.codingTracksData,
    error_loading_codingTracks: state.codingTrack.error_loading_codingTracks,
    loading_coding_tracks: state.codingTrack.loading_coding_tracks,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCodingTracks: () => dispatch(getCodingTracksAC()),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CodingTrackLevel)
);