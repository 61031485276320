import React, { Component } from "react";
import classes from "./login.module.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserDetailsAC,
  getLoginOtpAC,
  OPENENTERROLLNUMBERAGAIN,
  getUserPasswordDetailsAC,
  updatePasswordAC,
  CHANGELOGINMETHODTOOTP,
  OPENFORGOTPASSWORD,
} from "../../store/actions/loginActions";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpSent: false,
      roll_number: null,
      otp: null,
      resend: false,
      otpList: ["", "", "", ""],
      password: null,
      confirm_password: null,
      password_error: false,
    };
  }

  onRollNumberChange = (event) => {
    this.setState({ roll_number: event.target.value });
    console.log(event.target.value);
  };

  onPasswordChange = (event) => {
    // console.log(event)
    // console.log(event.target.value)
    this.setState({ password: event.target.value });
  };

  onConfirmPasswordChange = (event) => {
    // console.log(event)
    // console.log(event.target.value)
    this.setState({ confirm_password: event.target.value });
  };

  onSubmitMobileNo = () => {
    console.log(this.state.roll_number);
    this.props.getPasswordDetails(this.state.roll_number, false);
  };

  onRollNumberEnter = (event) => {
    if (event.key === "Enter") {
      console.log("enter pressed");
      this.onSubmitMobileNo();
    }
  };

  onEnterOtpClickHandler = (event) => {
    event.target.nextSibling.children[0].focus();
  };
  onSubmitPassword = () => {
    console.log(this.state);
    if (this.state.password === this.state.confirm_password) {
      this.setState({ password_error: false });
      this.props.updatePassword(this.state.password);
    } else this.setState({ password_error: true });
  };

  openEnterRollnumber = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.openEnterRollnumberAgain();
  };

  render() {
    // if (this.props.user_logged_in_successfully) {
    //     return <Redirect to='/home' exact/>
    // }
    console.log(this.props);
    console.log(this.state);
    // let otp = this.state.otpList;

    // console.log(otp);
    return (
      <>
        <div className={classes.dFlex}>
          <div className={classes.loginArea}>
            <fieldset
              className={classes.otpFieldSet}
              style={{ height: "630px" }}
            >
              <legend style={{ width: "auto", float: "none" }}>
                <img
                  src="/rmklogo.png"
                  alt="logo"
                  width="200px"
                  style={{ padding: "0px 10px", borderRadius: "20px" }}
                  height="75px"
                />
              </legend>
              <div style={{ margin: "25px", padding: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "20px 0",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    {/* {this.props.login_otp_message ? this.props.login_otp_message : 'Otp has been sent to ****727828 and to the email divyareddy973@gmail.com'} */}
                    Roll Number - {this.props.roll_number}
                  </div>
                  {/* <div style={{cursor: 'pointer'}} onClick={this.openEnterRollnumber}>
                                    <img src='/images/login/edit_button.png' alt="edit"></img>
                                </div> */}
                </div>
                <div style={{ margin: "20px 0 0 0", justifyContent: "center" }}>
                  <div>
                    <div style={{ width: "320px", margin: "10px auto" }}>
                      <div style={{ textAlign: "left", width: "50%" }}>
                        <div className={classes.subtext}>New Password</div>
                      </div>
                    </div>
                    <input
                      className={classes.username}
                      style={{ marginBottom: "0" }}
                      type="password"
                      placeholder="Enter password"
                      value={this.state.password}
                      onChange={this.onPasswordChange}
                    />
                  </div>
                </div>
                <div style={{ margin: "20px 0 0 0", justifyContent: "center" }}>
                  <div>
                    <div style={{ width: "320px", margin: "10px auto" }}>
                      <div style={{ textAlign: "left", width: "50%" }}>
                        <div
                          className={classes.subtext}
                          style={{ marginTop: "0" }}
                        >
                          Confirm Password
                        </div>
                      </div>
                    </div>
                    <input
                      className={classes.username}
                      type="password"
                      placeholder="Confirm password"
                      value={this.state.confirm_password}
                      onChange={this.onConfirmPasswordChange}
                    />
                  </div>
                </div>
                {this.state.password_error ? (
                  <p
                    style={{
                      color: "red",
                      marginTop: "0",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "28px",
                      width: "320px",
                    }}
                  >
                    Passwords do not match
                  </p>
                ) : null}
                {this.props.error_updating_password &&
                this.props.error_message ? (
                  <p
                    style={{
                      color: "red",
                      marginTop: "0",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "28px",
                      width: "320px",
                    }}
                  >
                    {this.props.error_message}
                  </p>
                ) : null}
                <button
                  className={classes.loginButton}
                  style={{ width: "270px", height: "56px", margin: "20px" }}
                  onClick={this.onSubmitPassword}
                >
                  Submit
                </button>
                <div style={{ clear: "both" }}></div>
                <div
                  style={{
                    float: "right",
                    marginRight: "-160px",
                    position: "relative",
                    top: "-82px",
                  }}
                >
                  <img src="/images/login/monkey.svg" width="170px" alt="" />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roll_number: state.login.roll_number,
    login_otp_message: state.login.login_otp_message,
    user_logged_in_successfully: state.login.user_logged_in_successfully,
    error_in_roll_number: state.login.error_in_roll_number,
    error_getting_details: state.login.error_getting_details,
    error_message: state.login.error_message,
    resent_otp: state.login.resent_otp,
    has_password: state.login.has_password,
    is_user: state.login.is_user,
    password_updated_successfully: state.login.password_updated_successfully,
    error_updating_password: state.login.error_updating_password,
    user_password_details_message: state.login.user_password_details_message,
    user_password_status: state.login.user_password_status,
    login_through_otp: state.login.login_through_otp,
    login_through_password: state.login.login_through_password,
    got_user_password_details: state.login.got_user_password_details,
    got_user_details: state.login.got_user_details,
    error_loading_user_password_details:
      state.login.error_loading_user_password_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginOtp: (roll_number, resend) =>
      dispatch(getLoginOtpAC(roll_number, resend)),
    getUserDetails: (otp, password) =>
      dispatch(getUserDetailsAC(otp, password)),
    openEnterRollnumberAgain: () =>
      dispatch({ type: OPENENTERROLLNUMBERAGAIN }),
    getPasswordDetails: (roll_number) =>
      dispatch(getUserPasswordDetailsAC(roll_number)),
    updatePassword: (password) => dispatch(updatePasswordAC(password)),
    changeLoginMethodToOtp: () => dispatch({ type: CHANGELOGINMETHODTOOTP }),
    openForgotPassword: () => dispatch({ type: OPENFORGOTPASSWORD }),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
