import React, { useReducer, useState } from "react";
import styles from "./joinchallange.css";
import { AiFillCloseCircle } from "react-icons/ai";
import { propTypes } from "react-bootstrap/esm/Image";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RiTeamLine } from "react-icons/ri";
function Studentspanel(props) {
  const allteams = [
    { name: "Geek Squad", teammembers: ["Mark(C)", "Vinay", "Kumar", "Abhi"] },
    { name: "Net Surfers", teammembers: ["Mark(C)", "Vinay", "Kumar", "Abhi"] },
    { name: "B-Team", teammembers: ["Mark(C)", "Vinay", "Kumar", "Abhi"] },
    { name: "Scorpions", teammembers: ["Mark(C)", "Vinay", "Kumar", "Abhi"] },
    { name: "Subham", teammembers: ["Mark(C)", "Vinay", "Kumar", "Abhi"] },
  ];
  const [teamdetails, setteam] = useState(allteams);
  const [showResults, setShowResults] = React.useState(false);
  const openchatwidget = () => setShowResults(true);
  const closechatwidget = () => setShowResults(false);
  // const [open, dispatchOpen] = useReducer((prev) => !prev, false);
  // const [boolOptions, setBoolOptions] = useState({
  //   disableBackdropClick: false,
  //   disableEscapeKeyDown: false,
  //   disableRestoreFocus: false,
  //   hideBackdrop: false,
  //   allPinkColoured: false
  // });
  // const [options, setOptions] = useState({
  //   duration: 250,
  //   offset: 10,
  //   width: 30
  // });

  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          cursor: "pointer",
          left: "15px",
        }}
        onClick={openchatwidget}
      >
        <RiTeamLine style={{ fontSize: "40px" }} />
        <div style={{ fontWeight: "600" }} title="View other teams">
          Other teams
        </div>
      </div>
      <div
        className={
          showResults ? "fadeIn1 allteamspanel" : "fadeOut1 allteamspanel"
        }
      >
        <div className={styles.pane} style={{ padding: "10px" }}>
          <AiFillCloseCircle
            onClick={closechatwidget}
            style={{ fontSize: "26px", cursor: "pointer", float: "left" }}
          />
          <h3>Other teams</h3>
          <div className="col-md-12 row" style={{ height: "560px" }}>
            {props.data.map((teamdetails, index) => {
              return (
                <div
                  className="col-md-6"
                  style={{ padding: "20px" }}
                  key={index}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">{teamdetails.team_name}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {teamdetails.members.map((eacheteamdetails, index) => {
                        let teammembername;
                        if (eacheteamdetails.faculty_name) {
                          if (eacheteamdetails.is_captain) {
                            teammembername = `${eacheteamdetails.faculty_name}(C)`;
                          } else {
                            teammembername = `${eacheteamdetails.faculty_name}`;
                          }
                        } else {
                          if (eacheteamdetails.is_captain) {
                            teammembername = `${eacheteamdetails.student_name}(C)`;
                          } else {
                            teammembername = `${eacheteamdetails.student_name}`;
                          }
                        }
                        return (
                          <tr key={index}>
                            <td style={{ fontWeight: "500" }}>
                              {teammembername}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/*  <Component
          onClose={dispatchOpen}
          {...options}
          {...boolOptions}
          backdropStyle={
            boolOptions.allPinkColoured
              ? { backgroundColor: "rgba(255,192,203,0.4)" }
              : {}
          }
        />*/}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    challengedetails: state.joinchallenge.challangedetails,
  };
};
export default withRouter(connect(mapStateToProps, null)(Studentspanel));
