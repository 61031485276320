import React, { useEffect, useState } from 'react';
import StudyContentSwiper from '../StudyContentSwiper/StudyContentSwiper';
import useFetch from '../../../../hooks/useFetch';
import { remedialStudyCardRenderer } from '../StudyContentSwiper/studyRenderers';
import { Skeleton, Stack } from '@mui/material';
import RemedialCard from '../RemedialCard/RemedialCard';

const dummyArray = [1, 2, 3, 4, 5, 6]

const RemedialStudyContent = ({ studyData }) => {
    // const [studyData, setStudyData] = useState()
    // const { data } = useFetch('/questionnaire/getRemedialStudy?test_id=45&student_id=1254')

    // const handleRemedialStudyData = (studyData) => {
    //     let studyDataArray = [];
    //     // first Map 
    //     studyData.forEach((item1) => {
    //         let firstMapData = { ...item1 };
    //         delete firstMapData.topics
    //         studyDataArray.push({ ...firstMapData });
    //         // Second Map 
    //         item1.topics.forEach((item2) => {
    //             let secondMapData = { ...item2 };
    //             delete secondMapData.subTopics
    //             studyDataArray.push({ ...secondMapData });
    //             // Third Map 
    //             item2.subTopics.forEach((item3) => {
    //                 studyDataArray.push(item3);
    //             })
    //         })
    //     })
    //     return studyDataArray.filter((item) =>  item.code)
    // }

    // useEffect(() => {
    //     if (data) {
    //         setStudyData(handleRemedialStudyData(data?.grandTopics))
    //     }

    // }, [data])

    console.log(studyData, 'studyData')

    return (
        <>
            {studyData ? <StudyContentSwiper
                arrayData={studyData}
                renderer={remedialStudyCardRenderer}
            /> : <PlaceHolder />
            }
        </>
    )
}

export default RemedialStudyContent





const PlaceHolder = () => {
    return (
        <Stack direction={'row'} spacing={3}>
            {[...Array(6)].map((item) => (
                <Stack spacing={1} key={item}>
                    <Skeleton variant="rounded" width={210} height={100} sx={{background: theme => theme.palette.grey[200]}} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem',background: theme => theme.palette.grey[200] }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem',background: theme => theme.palette.grey[200] }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem',background: theme => theme.palette.grey[200] }} />
                </Stack>
            ))}
        </Stack>
    )
}