import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import colors from "./colors";
import Question from "../../features/PersonalityAssessment/question";
import { withRouter, useHistory } from 'react-router-dom';
import { getAllPersonalityQuestionsAC, leavingPersonalityPage, submitPersonalityQuestionAC } from "../../store/actions/personalityAssessmentActions";
import { connect, useDispatch } from 'react-redux';
import NotificationPrompt from "../../components/common/NotificationPrompt";
import Layout1 from "../../components/Layout/Layout1";
// import '../../fonts/axiforma_mui.css'; //  <--- This is the line (for Axiforma) 

// const all_questions = [
//     {
//         order: 0,
//         question_id: 1,
//         name: 'Is talkative',
//         is_answered: 1,
//         option: 2,
//     },
//     {
//         order: 1,
//         question_id: 2,
//         name: 'Tends to find fault with others',
//         is_answered: 1,
//         option: 4,
//     },
//     {
//         order: 2,
//         question_id: 3,
//         name: 'Does a thorough job',
//         is_answered: 0,
//         option: -1,
//     },
//     {
//         order: 3,
//         question_id: 5,
//         name: 'Is Depressed, Blue',
//         is_answered: 0,
//         option: -1,
//     },
//     {
//         order: 4,
//         question_id: 7,
//         name: 'Is original, comes up with new ideas',
//         is_answered: 0,
//         option: -1,
//     },
//     {
//         order: 5,
//         question_id: 10,
//         name: 'Is reserved',
//         is_answered: 0,
//         option: -1,
//     },
//     {
//         order: 6,
//         question_id: 12,
//         name: 'Is helpful and unselfish with others',
//         is_answered: 0,
//         option: -1,
//     },
//     {
//         order: 7,
//         question_id: 17,
//         name: 'Can be somewhat careless',
//         is_answered: 0,
//         option: -1,
//     },
//     {
//         order: 8,
//         question_id: 21,
//         name: 'Is reserved',
//         is_answered: 0,
//         option: -1,
//     },
//     {
//         order: 9,
//         question_id: 23,
//         name: 'Is helpful and unselfish with others',
//         is_answered: 0,
//         option: -1,
//     },
//     {
//         order: 10,
//         question_id: 25,
//         name: 'Can be somewhat careless',
//         is_answered: 0,
//         option: -1,
//     },
// ]


const PersonalityAssessment = (props) => {
    const [questions, setQuestions] = useState(null);
    const [lastAnsweredOrder, setLastAnsweredOrder] = useState(0);
    const questionRefs = useRef([]);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const getAllPersonalityQuestionsA = async() => await props.getAllPersonalityQuestions();
        getAllPersonalityQuestionsA();
    }, []);

    useEffect(() => {
        if (props.questions) {
            setQuestions(props.questions);
        }
    }, [props.questions]);

    useEffect(() => {
        return () => {
            setQuestions(null);
            dispatch(leavingPersonalityPage());
        }
    }, []);

    useEffect(() => {
        if (props.submitted_question_id !==null && props.submitted_question_option_id !==null) {
            if (questions) {
                const questionsArray = questions.map((ques) => {
                    if (ques.question_id === props.submitted_question_id) {
                        setLastAnsweredOrder(ques.order-1);
                        ques.is_answered = 1;
                        ques.option_id = props.submitted_question_option_id;
                    }
                    return {
                        ...ques,
                    };
                });
                setQuestions(questionsArray);
            }   
        }
    }, [props.submitted_question_id, props.submitted_question_option_id]);

    useEffect(() => {
        if (questions) {
            const nextUnansweredQuestion = findNextUnansweredQuestion();

            if (nextUnansweredQuestion !== null) {
                // Scroll to the ref of the next unanswered question
                if (questionRefs.current[nextUnansweredQuestion]) {
                    questionRefs.current[nextUnansweredQuestion].scrollIntoView({ behavior: "smooth" });
                }
                
                // Update the last answered order
                setLastAnsweredOrder(nextUnansweredQuestion-1);
            }
        }
    }, [questions]);

    const findNextUnansweredQuestion = () => {
        for (let i = lastAnsweredOrder; i < questions.length; i++) {
            if (questions[i].is_answered === 0) {
                return i-1;
            }
        }
        return null; // No unanswered questions found
    };

    const handleAnswerSelection = (id, option_id) => {
        const submitPersonalityQuestionA = async(trigger_id, question_id, option_id) => await props.submitPersonalityQuestion(trigger_id, question_id, option_id);
        submitPersonalityQuestionA(6, id, option_id);
    };

    const goBack = () => {
        history.push('/home');
    }

    return (
        <>
            <Box sx={{background: '#fff'}}>
            {!props.loading_all_questions ?
            <>
                <Layout1 active_page="personality_test"/>
                <Box sx={{marginLeft: '214px', position: 'relative',}}>
                    {/* <Box 
                        sx={{
                            position: 'absolute',
                            top: '90px',
                            left: '40px',
                        }}
                    > 
                        <img src={`/images/left_arrow_black.svg`} width="24px" alt="Back" onClick={goBack} />
                    </Box> */}
                    <Box
                        sx={{
                            width: '600px',
                            height: 'auto',
                            margin: 'auto',
                            paddingTop: '80px',
                            flexGrow: 1,
                            ['@media (min-width: 1536px)']: {
                                width: '50%', // Adjust the width for screens greater than 1536px
                            },
                        }}
                    >
                        <Box
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                // padding: '0px 35px'
                            }}
                        >
                            <Typography variant="h3" color={colors.primary} style={{margin: '15px 0px', textAlign: 'center', lineHeight: 'normal'}}>Personality Test</Typography>
                            <Box
                                sx={{
                                    borderRadius: '9px',
                                    background: colors.secondary,
                                    padding: '25px 45px',
                                    margin: '10px 35px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    textAlign: 'left'
                                }}
                            >
                                <Typography variant="body5" color={colors.primary} sx={{marginBottom: '5px'}}>Instructions</Typography>
                                <Typography variant="body1">Please answer the following questions truthfully about yourself, and we will provide you with a score to indicate how others perceive you.</Typography>
                            </Box>
                            <Typography variant="body5" color={colors.primary} sx={{margin: '10px 35px 0px 35px', textAlign: 'left'}}>I See Myself as Someone Who...</Typography>
                            {questions ? 
                                <Grid 
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    sx={{overflowY: 'auto'}}
                                >
                                    {questions.map((question, index) => {
                                        return (
                                            <Grid item key={question.question_id} width="100%" ref={(ref) => (questionRefs.current[question.order-1] = ref)} style={{opacity: question.is_answered === 1 ? 0.4 : 1, pointerEvents: question.is_answered === 1 ? 'none' : 'auto'}}>
                                                <Question 
                                                    id={question.question_id}
                                                    name={question.name}
                                                    option={question.option_id}
                                                    handleAnswerSelection={handleAnswerSelection}
                                                    type={'inList'}
                                                />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            : null }
                        </Box>
                    </Box>
                </Box>
            </>
            :
            <Box sx={{height: '100vh', width: '100vw', position: 'relative', background: '#fff'}}>
                <img src={'/gifs/animation_dots_loader.gif'} width="150px" alt="Loading" style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}></img>
            </Box>
            }
            {/* <NotificationPrompt /> */}
            </Box>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      questions: state.personalityAssessment.questions,
      loading_all_questions: state.personalityAssessment.loading_all_questions,
      error_loading_all_questions: state.personalityAssessment.error_loading_all_questions,
      question_submitted: state.personalityAssessment.question_submitted,
      submitted_question_id: state.personalityAssessment.submitted_question_id,
      submitted_question_option_id: state.personalityAssessment.submitted_question_option_id,
      changed: state.personalityAssessment.changed,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getAllPersonalityQuestions: () => dispatch(getAllPersonalityQuestionsAC()), 
      submitPersonalityQuestion: (trigger_id, question_id, option_id) => dispatch(submitPersonalityQuestionAC(trigger_id, question_id, option_id)),
    }
  }
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PersonalityAssessment)
  );