export const IntermediateIcon = ({backgroundColor}) => {
    return (
        <svg width="28" height="10" viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 5H18" stroke={backgroundColor ? backgroundColor : "black"} stroke-width="3"/>
        <path d="M6 0L6 10" stroke={backgroundColor ? backgroundColor : "black"} stroke-width="3"/>
        <path d="M2 1L2 9" stroke={backgroundColor ? backgroundColor : "black"} stroke-width="3"/>
        <path d="M22 0L22 10" stroke={backgroundColor ? backgroundColor : "black"} stroke-width="3"/>
        <path d="M26 1L26 9" stroke={backgroundColor ? backgroundColor : "black"} stroke-width="3"/>
        </svg>
    )
}