import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import styled, { keyframes, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { AiOutlineFieldTime } from "react-icons/ai";
import { VscGraph } from "react-icons/vsc";
import { GiRank3 } from "react-icons/gi";
import { GrDocumentPerformance } from "react-icons/gr";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import moment from "moment";
import Leaderboardresults from "./LeaderboardResult";
import ReactTooltip from "react-tooltip";
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import ProgressBar from 'react-bootstrap/ProgressBar';
const Leaderboardrow = styled.div`
    display: flex;
    font-size: 10px;
   align-items: center;
    font-weight: 600;
    border-bottom: 1px solid #80808052;
    padding: 12px 0px 12px 0px;
    padding-left: 0px !important;
  `;
function Resultsrightpanel(props) {
   const[rank,setRank]=useState('')
    useEffect(() => {
        let userId
        if (localStorage.getItem("user")) {
             userId = JSON.parse(localStorage.getItem("user")).user_id;
           
          }
        if (props.rank) {
            // console.log(props.userId)
            console.log(props.userId)
            const index = props.rank.findIndex(student => student.student_id == userId);
            
            setRank(`${index}/${props.rank?.length}`)
        }
    }, [props]);
    const [performanceshow,setperformanceshow]=useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
  
    const showperformance= () => {
        setperformanceshow(true);
      }
      const showparticipation= () => {
        setperformanceshow(false);
      }

      const secondsToHms=(second)=> {
       let dateObj = new Date(second * 1000);
       let hours = dateObj.getUTCHours();
       let minutes = dateObj.getUTCMinutes();
       let seconds = dateObj.getSeconds();
      
       let timeString = hours.toString().padStart(2, '0')
            + ':' + minutes.toString().padStart(2, '0')
            + ':' + seconds.toString().padStart(2, '0');
            return timeString;
      }
      const open = Boolean(anchorEl);
      // console.log(props.questiondata);
    return (
        <>

            <div className="col-lg-12 row" style={{
                fontSize: "12px", margin: "13px 0px 0px 7px", background: "white"
                , boxShadow: "#595e59a3 1px 1px 4px", padding: "0px"
            }}>
                <div className="col-lg-6 col-md-6" data-tip data-for="registerTip" style={{
                    boxShadow: "4px 3px 1px -2px #8888889c",
                    paddingTop: "8px", background: "rgb(82 195 255 / 58%)", height: "70px"
                }}>
            
                    <div className="timespent" style={{ paddingTop: "3px", fontSize: "10px" }}>Total time spent</div>
                    <div className="timespent_icon" style={{ float: "left", padding: "11px 0px 0px 0px" }}>
                        <AiOutlineFieldTime  
                        style={{ fontSize: "26px", color: "#0eb10e" }} />
                       
                        </div>
                    <div className="timespent_content" style={{ fontWeight: "600", padding: "17px 28px 0px 0px" }}>
                        {secondsToHms(props?.timespent)}
                    </div>
                </div>
                <Tooltip 
                // title={<><div>Subjective: {props.data.academic_performance.subjective_attempted}</div>
                // <div>Mcq: {props.data.academic_performance.mcq_attempted}</div>
                // <div>Coding: {props.data.academic_performance.coding_attempted}</div></>}
                 followCursor>
                <div className="col-lg-6 col-md-6" style={{
                    boxShadow: "0px 1px 4px -2px #8888889c",
                    paddingTop: "8px", background: "rgb(255 203 87 / 68%)", height: "70px"
                }}>
                    <div className="timespent" style={{ paddingTop: "3px", fontSize: "10px" }}>Your rank</div>
                    <div className="timespent_icon" style={{ padding: "11px 0px 0px 0px", float: "left" }}>
                        <GiRank3 style={{ fontSize: "26px", color: "" }} /></div>
                    <div className="timespent_content" style={{ fontWeight: "600", padding: "17px 28px 0px 0px" }}>
                        {rank}
                        </div>
                </div>
             </Tooltip>
             <Tooltip 
//              title={<><div>Subjective: {props.data.academic_performance.avg_subjective_performance
// } %</div>
//                 <div>Mcq: {props.data.academic_performance.avg_mcq_performance} %</div>
//                 <div>Coding: {props.data.academic_performance.avg_coding_performance} %</div>
//                 <div>Live tests: {props.data.academic_performance.avg_live_performance} %</div></>} 
                followCursor>
                <div className="col-lg-6 col-md-6" style={{
                    boxShadow: "rgb(136 136 136 / 61%) 4px 0px 1px -2px",
                    padding: "4px 0px 0px 2px", background: "rgb(255, 223, 214)", height: "70px"
                }}>
                    <div style={{ paddingTop: "3px", fontSize: "10px" }}>Average performance</div>
                    <div style={{ padding: "5px", float: "left" }}>
                        <VscGraph style={{ fontSize: "26px", color: "#b5b51c" }} /></div>
                    <div style={{ fontWeight: "600", padding: "6px 28px 0px 0px" }}>
                    {props?.percentage}
                    </div>
                </div>
                </Tooltip>
                <Tooltip
                //  title={<><div>Total: {props.data.aptitude_performance.total_questions}</div>
                // <div>Attempted: {props.data.aptitude_performance.attempted_questions}</div>
                // <div>Correct: {props.data.aptitude_performance.correct_answers}</div></>} 
                followCursor>
                <div className="col-lg-6 col-md-6" style={{ padding: "4px 0px 0px 2px", background: "rgb(252 119 160 / 58%)", height: "70px" }}>
                    <div  style={{ paddingTop: "3px", fontSize: "10px" }}>Unanswered Count</div>
                    <div style={{ padding: "5px", float: "left" }}>
                        <TbDeviceDesktopAnalytics style={{ fontSize: "26px", color: "#a16363" }} />
                    </div>
                    <div className="aptitudediv" style={{ fontWeight: "600", padding: "6px 28px 0px 0px" }}>
                    {props?.unanswered}
                        </div>
                        </div>
                        </Tooltip> 
               </div>
               {/* </Tooltip> */}

            {/* comments for results page issue conflict */}
            {props.remedial ?
            <div
                style={{ marginTop: 30}}
                ><div style={{ fontWeight: "bold", float: "left",fontSize: 14,}}>Question Wise results</div>
                <br />
                <div style={{display:"flex",justifyContent:"flex-start",marginTop:10,flexWrap:"wrap"}}>
                {props.questiondata.questions.map((questiondata,index) => {

                     return(
                        <>
                        <span class="questionresults" style={{background:questiondata.correctly_answered?"#00800094":"rgb(255 0 0 / 61%)"}}>{index+1}</span>
                        </>
                     )
                 })}
                
              
                </div>
            </div> 
            :null}
      {props.remedial ?
            <div
  style={{
    textDecoration: "underline",
    color: "blue",
    fontSize: 16,
    cursor: "pointer"
  }}
  onClick={() =>
    props.history.push({
      pathname: "/assessmentResults",
      state: { test_id: props.test_id},
    })
  }
>
  View detailed test details -&gt;
</div>
:props.results && props.remedial_present?
<>  
{props.bonuspoints?
   <div style={{ marginTop: 19, fontWeight: 600 }}>
   Bonus points accumulated: {props.bonuspoints}
 </div>
 
:null
}
<div
style={{
  textDecoration: "underline",
  color: "blue",
  fontSize: 16,
  cursor: "pointer"
}}
onClick={() =>
  props.history.push({
    pathname: "/remedial",
    state: { remedial_test_id: props.test_id},
  })
}
>
View remedial -&gt;
</div>
</>
:null}


            <div
                style={{ fontWeight: "bold", float: "left", marginTop: 30, fontSize: 14,marginBottom:10}}
            >
                Leaderboard
            </div>
            <div
                className="col-lg-12 row"
                style={{
                    background: "white",
                    paddingLeft: 0,
                    marginTop: 36,
                    borderRadius: 20,
                    paddingRight: 0,
                    marginLeft:3,
                }}
             >
              
              <Leaderboardresults
                 data={props?.data.performance_leaderboard} performace={true} 
                 />
                {/* <Leaderboardresults
                  data={props.analytics_data.performance_leaderboard} performace={true}  
                 /> */}
                <style jsx="true" scoped={true}>{`
                .avatar {
                vertical-align: middle;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                position:absolute;
                }
                .attended_count{
                position:relative;
                margin-top: 2%;
                margin-left: 100px;
                } 
                .questionresults{
                    height: 25px;
                    width: 25px;
                    border-radius: 50%;
                    color: white;
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
                .triangle-up {
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 30px solid #11cb24;
                }
                
                `}
                </style>
            </div>
           
       

        </>
    );
}

function mapStateToProps(state) {
    return {
        query_date: state.dash.query_date,
        loading_analyticsdata: state.dash.loading_analyticsdata,
        error_loading_analyticsdata: state.dash.error_loading_analyticsdata,
        analytics_data: state.dash.analytics_data,
         analytics_data_semester:state.dash.analytics_data_semester,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        // getWallDatares:(query_date)=>dispatch(getWallData(query_date)),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Resultsrightpanel)
);
